import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import { FormikProps } from 'formik';

import { LeadForm } from '+app/+lead/store/types';
import { LeadAutosuggestionData } from '+shared/store/lead/types/leadAutosuggestion.interface';

interface NoResultsProps {
  onClick: () => void;
}

export const NoResults: React.FC<NoResultsProps> = ({ onClick }) => (
  <>
    <p className={'c-lead-autosuggested-address__no-results'}>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.cantFindAddress)}
    </p>
    <p className={'c-lead-autosuggested-address__btn'} onClick={onClick}>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.addManually)}
    </p>
  </>
);

export const getAutosuggestedAddress = (
  form: FormikProps<LeadForm>,
  addressType: string
): string | undefined => {
  return addressType === 'deliveryAddress'
    ? form.values.autosuggestedDeliveryAddress
    : form.values.autosuggestedInvoiceAddress;
};

export const renderNoResultsComponent = (
  switchToManualAddress: () => void,
  debouncedSearchValue: string,
  isPending: boolean,
  addressAutosuggestions: LeadAutosuggestionData[] | null
) => {
  if (isPending || !addressAutosuggestions) {
    return <Loader />;
  }

  if (debouncedSearchValue) {
    return <NoResults onClick={switchToManualAddress} />;
  }

  return null;
};

export const clearAddressFields = (form: FormikProps<LeadForm>, addressType: string) => {
  form.setFieldValue(`${addressType}.street`, '');
  form.setFieldValue(`${addressType}.houseNumber`, '');
  form.setFieldValue(`${addressType}.zipCode`, '');
  form.setFieldValue(`${addressType}.city`, '');
};
