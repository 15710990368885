import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import { PATHS } from '+app/router';
import { history } from '+app/router/store/router.reducer';
import { RoundButton } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';

import './CustomersTableAccessInfo.component.scss';

interface Props {
  hideNotification: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CustomersTableAccessInfo: React.FC<Props> = ({ hideNotification }) => (
  <div className={'c-customers-table-access-info'}>
    <RoundButton
      className={'c-customers-table-access-info__padlock'}
      color="inactive-dark"
      label={<Icon.Padlock className={'c-customers-table-access-info__padlock-icon'} />}
    />
    <p className={'c-customers-table-access-info__content'}>
      <span className={'c-customers-table-access-info__title'}>
        {I18n.t(T.customerSingle.accessDenied.header)}.&nbsp;
      </span>
      {I18n.t(T.dashboard.customersTable.accessInfo.description)}
      &nbsp;&nbsp;
      <Button
        className={'c-customers-table-access-info__link'}
        label={I18n.t(T.dashboard.customersTable.accessInfo.detailsLink).toUpperCase()}
        type={ButtonType.TERTIARY}
        onClick={() => history.push(`${PATHS.HELP()}#partner-portal&open=0`)}
      />
    </p>
    <div className={'c-customers-table-access-info__close'}>
      <button className={'c-customers-table-access-info__close-button'} onClick={hideNotification}>
        <Icon.Close className={'c-customers-table-access-info__close-icon'} />
      </button>
    </div>
  </div>
);
