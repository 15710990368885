import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { TableCell, TableRow, TableStack } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { Header } from '../CustomersTable/CustomersTable.interface';

import './CustomersTableHeader.component.scss';

interface Props {
  headerList: Header[];
}

export const CustomersTableHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ headerList }, ref) => (
    <TableRow ref={ref} className={'c-customers-table-header'}>
      <div className={'c-customers-table-header__inner'}>
        {headerList.map((header) => (
          <TableCell
            key={header.label}
            className={classNames(
              'c-customers-table-header__cell',
              `c-customers-table-header__cell--${header.name}`
            )}
            stack={TableStack.HORIZONTAL}
          >
            {header.icon}
            <p className={'c-customers-table-header__cell-label'}>{I18n.t(header.label)}</p>
          </TableCell>
        ))}
      </div>
    </TableRow>
  )
);
