import * as React from 'react';

import { SetupToolStepsListItem } from '+setupTool/components/SetupToolStepsListItem/SetupToolStepsListItem.component';
import { SetupToolStep } from '+setupTool/store/types';

interface Props {
  items: SetupToolStep[];
}

export const SetupToolStepsList: React.FC<Props> = ({ items }) => (
  <>
    {items.map((item) => (
      <SetupToolStepsListItem key={item.route} item={item} />
    ))}
  </>
);
