import { __assign } from "tslib";
import { calcDimension, MATCH_PARENT, Rect, WRAP_CONTENT } from '../canvas';
import { PointerAction } from '../pointer-event';
import { removeUndefinedProps } from '../utils';
import { xor } from '../utils/boolean.util';
import { LayoutParams, PARENT_ID } from './layout-params';
import { horizontalLayoutDependencies, removeDefaultProps, resolveDimensionDependencies, resolveLayoutParamsIds, verticalLayoutDependencies, } from './layout-params.utils';
export var RequiredViewChanges;
(function (RequiredViewChanges) {
    RequiredViewChanges[RequiredViewChanges["MEASURE"] = 3] = "MEASURE";
    RequiredViewChanges[RequiredViewChanges["LAYOUT"] = 2] = "LAYOUT";
    RequiredViewChanges[RequiredViewChanges["DRAW"] = 1] = "DRAW";
    RequiredViewChanges[RequiredViewChanges["NOTHING"] = 0] = "NOTHING";
})(RequiredViewChanges || (RequiredViewChanges = {}));
export var Visibility;
(function (Visibility) {
    Visibility[Visibility["VISIBLE"] = 1] = "VISIBLE";
    Visibility[Visibility["INVISIBLE"] = 2] = "INVISIBLE";
    Visibility[Visibility["GONE"] = 3] = "GONE";
})(Visibility || (Visibility = {}));
var View = (function () {
    function View(context, name) {
        if (name === void 0) { name = 'View'; }
        this.context = context;
        this.name = name;
        this.offsetRect = new Rect(0);
        this.lp = new LayoutParams();
        this.visibility = Visibility.VISIBLE;
        this.width = 0;
        this.height = 0;
        this.rect = new Rect(0);
        this.innerRect = new Rect(0);
        this.oldWidth = 0;
        this.oldHeight = 0;
        this.parent = null;
        this.requiredChanges = RequiredViewChanges.MEASURE;
        this.hasCapturedPointer = false;
        this.mounted = false;
        this.children = [];
        this.childrenOrdered = { h: [], v: [] };
        this.childrenIdMap = {};
        this.childrenGraphChanged = false;
    }
    View.prototype.dispatchPointerEvent = function (event) {
        var action = event.action, primaryPointer = event.primaryPointer;
        for (var i = this.children.length - 1; i >= 0; i--) {
            var child = this.children[i];
            var isPointerInside = child.innerRect.contains(primaryPointer.x, primaryPointer.y);
            if (child.hasCapturedPointer || isPointerInside) {
                var offsetX = child.innerRect.l + child.lp.paddingRect.l;
                var offsetY = child.innerRect.t + child.lp.paddingRect.t;
                event.offsetPointers(-offsetX, -offsetY);
                if (event.action === PointerAction.MOVE) {
                    if (!child.hasCapturedPointer) {
                        event.action = event.primaryPointer.pressure > 0 ? PointerAction.DOWN : PointerAction.OVER;
                    }
                    else if (!isPointerInside) {
                        event.action = PointerAction.CANCEL;
                    }
                }
                child.hasCapturedPointer = isPointerInside;
                var dispatched = child.dispatchPointerEvent(event);
                event.offsetPointers(offsetX, offsetY);
                event.action = action;
                if (dispatched) {
                    return true;
                }
            }
        }
        event.target = this;
        return this.onPointerEvent(event);
    };
    View.prototype.onPointerEvent = function (_event) {
        return false;
    };
    View.prototype.layout = function (force) {
        if (!this.requireGuardAndTake(RequiredViewChanges.LAYOUT, force)) {
            return;
        }
        var _a = this, children = _a.children, map = _a.childrenIdMap, context = _a.context, innerHeight = _a.innerHeight, innerWidth = _a.innerWidth;
        if (context.debugEnabled) {
            console.log("".concat(this.name, "[").concat(this.id, "]: layout()"));
        }
        var horizontallyOrderedChildren = this.childrenOrdered.h;
        var maxWidth = 0;
        for (var i = 0, l = horizontallyOrderedChildren.length; i < l; i++) {
            var child = horizontallyOrderedChildren[i];
            var lp = child.lp, rect = child.rect;
            var w = calcDimension(lp.wDimension, innerWidth);
            if (w !== undefined) {
                child.width = w;
            }
            if (lp.isAbsolute) {
                rect.l = calcDimension(lp.xDimension, innerWidth);
                rect.r = rect.l + child.width;
                continue;
            }
            if (lp.centerH) {
                rect.l = (innerWidth - child.width) / 2;
                rect.r = rect.l + child.width;
            }
            else {
                var r = void 0;
                var l_1 = void 0;
                if (lp.toStartOfId !== undefined) {
                    r = map[lp.toStartOfId].rect.l;
                }
                else if (lp.endId !== undefined) {
                    r = lp.endId === PARENT_ID ? innerWidth : map[lp.endId].rect.r;
                }
                if (lp.toEndOfId !== undefined) {
                    l_1 = map[lp.toEndOfId].rect.r;
                }
                else if (lp.startId !== undefined) {
                    l_1 = lp.startId === PARENT_ID ? 0 : map[lp.startId].rect.l;
                }
                if (r === undefined && l_1 === undefined) {
                    l_1 = 0;
                    r = child.width;
                }
                else if (r === undefined) {
                    r = l_1 + child.width;
                }
                else if (l_1 === undefined) {
                    l_1 = r - child.width;
                }
                rect.l = l_1;
                rect.r = r;
            }
            if (rect.l > rect.r) {
                var l_2 = rect.r;
                rect.r = rect.l;
                rect.l = l_2;
            }
            maxWidth = Math.max(maxWidth, rect.r);
        }
        if (this.lp.w === WRAP_CONTENT) {
            var _b = this.lp, padding = _b.paddingRect, margin = _b.marginRect, min = _b.minW, max = _b.maxW;
            var widthSpacing = padding.l + padding.r + margin.l + margin.r;
            var dimension = maxWidth + widthSpacing;
            if (dimension > max) {
                dimension = max;
            }
            else if (dimension < min) {
                dimension = min;
            }
            this.width = Math.round(Math.max(this.width, dimension));
        }
        var verticallyOrderedChildren = this.childrenOrdered.v;
        var maxHeight = 0;
        for (var i = 0, l = verticallyOrderedChildren.length; i < l; i++) {
            var child = verticallyOrderedChildren[i];
            var lp = child.lp, rect = child.rect;
            var h = calcDimension(lp.hDimension, innerHeight);
            if (h !== undefined) {
                child.height = h;
            }
            if (lp.isAbsolute) {
                rect.t = calcDimension(lp.yDimension, innerHeight);
                rect.b = rect.t + child.height;
                continue;
            }
            if (lp.centerV) {
                rect.t = (innerHeight - child.height) / 2;
                rect.b = rect.t + child.height;
            }
            else {
                var t = void 0;
                var b = void 0;
                if (lp.aboveId !== undefined) {
                    b = map[lp.aboveId].rect.t;
                }
                else if (lp.bottomId !== undefined) {
                    b = lp.bottomId === PARENT_ID ? innerHeight : map[lp.bottomId].rect.b;
                }
                if (lp.belowId !== undefined) {
                    t = map[lp.belowId].rect.b;
                }
                else if (lp.topId !== undefined) {
                    t = lp.topId === PARENT_ID ? 0 : map[lp.topId].rect.t;
                }
                if (t === undefined && b === undefined) {
                    t = 0;
                    b = child.height;
                }
                else if (b === undefined) {
                    b = t + child.height;
                }
                else if (t === undefined) {
                    t = b - child.height;
                }
                rect.t = t;
                rect.b = b;
            }
            if (rect.t > rect.b) {
                var t = rect.b;
                rect.b = rect.t;
                rect.t = t;
            }
            maxHeight = Math.max(maxHeight, rect.b);
        }
        if (this.lp.h === WRAP_CONTENT) {
            var _c = this.lp, padding = _c.paddingRect, margin = _c.marginRect, min = _c.minH, max = _c.maxH;
            var heightSpacing = padding.t + padding.b + margin.t + margin.b;
            var dimension = maxHeight + heightSpacing;
            if (dimension > max) {
                dimension = max;
            }
            else if (dimension < min) {
                dimension = min;
            }
            this.height = Math.round(Math.max(this.height, dimension));
        }
        for (var i = 0, l = children.length; i < l; i++) {
            var child = children[i];
            child.width = child.rect.width;
            child.height = child.rect.height;
            var width = child.width, height = child.height, oldWidth = child.oldWidth, oldHeight = child.oldHeight;
            var sizeChanged = width !== oldWidth || height !== oldHeight;
            child.innerRect = child.rect.clone().inset(child.lp.marginRect);
            child.offsetRect = this.rect.clone()
                .offset(child.innerRect)
                .offset(child.lp.paddingRect);
            child.layout(sizeChanged);
            if (sizeChanged) {
                child.onSizeChanged(width, height, oldWidth, oldHeight);
            }
        }
        this.onLayout();
    };
    View.prototype.onLayout = function () {
    };
    View.prototype.resolvePositionDependencies = function () {
        if (!this.childrenGraphChanged) {
            return;
        }
        var _a = this, children = _a.children, context = _a.context;
        if (context.debugEnabled) {
            console.log("".concat(this.name, "[").concat(this.id, "]: resolvePositionDependencies()"));
        }
        for (var i = 0, l = children.length; i < l; i++) {
            resolveLayoutParamsIds(children[i].lp, context);
        }
        var h = resolveDimensionDependencies(children, horizontalLayoutDependencies, context);
        var v = resolveDimensionDependencies(children, verticalLayoutDependencies, context);
        this.childrenOrdered = { h: h, v: v };
        this.childrenGraphChanged = false;
        if (this.context.debugEnabled) {
            console.log({
                h: this.childrenOrdered.h,
                v: this.childrenOrdered.v,
            });
        }
    };
    View.prototype.measure = function (canvas, force) {
        if (!this.requireGuardAndTake(RequiredViewChanges.MEASURE, force)) {
            return;
        }
        this.resolvePositionDependencies();
        var _a = this, context = _a.context, children = _a.children, lp = _a.lp, visibility = _a.visibility;
        var w = lp.w, h = lp.h, minH = lp.minH, minW = lp.minW, maxH = lp.maxH, maxW = lp.maxW;
        if (context.debugEnabled) {
            console.log("".concat(this.name, "[").concat(this.id, "]: measure()"));
        }
        this.oldWidth = this.width;
        this.oldHeight = this.height;
        if (visibility === Visibility.GONE) {
            this.width = this.height = 0;
            return;
        }
        var _b = this.lp, padding = _b.paddingRect, margin = _b.marginRect;
        var heightSpacing = padding.t + padding.b + margin.t + margin.b;
        var widthSpacing = padding.l + padding.r + margin.l + margin.r;
        var width = 0;
        var height = 0;
        if (w === MATCH_PARENT) {
            width = this.getMatchParentWidth();
        }
        else if (w === WRAP_CONTENT) {
            var wrappedWidth = this.getInternalWrappedWidth(canvas);
            if (wrappedWidth !== undefined) {
                width = wrappedWidth + widthSpacing;
            }
            else if (children.some(function (child) { return !child.lp.isAbsolute && child.lp.w === MATCH_PARENT; })) {
                width = this.getMatchParentWidth();
            }
            else {
                var foundWidth = 0;
                for (var i = children.length - 1; i >= 0; i--) {
                    var child = this.children[i];
                    if (child.lp.isAbsolute) {
                        continue;
                    }
                    if (child.lp.w === WRAP_CONTENT) {
                        child.measure(canvas);
                    }
                    if (child.width > foundWidth) {
                        foundWidth = child.width;
                    }
                }
                width = foundWidth + widthSpacing;
            }
        }
        else {
            width = calcDimension(this.lp.wDimension, this.getMatchParentWidth());
        }
        if (h === MATCH_PARENT) {
            height = this.getMatchParentHeight();
        }
        else if (h === WRAP_CONTENT) {
            var wrappedHeight = this.getInternalWrappedHeight(canvas);
            if (wrappedHeight !== undefined) {
                height = wrappedHeight + heightSpacing;
            }
            else if (children.some(function (child) { return !child.lp.isAbsolute && child.lp.h === MATCH_PARENT; })) {
                height = this.getMatchParentHeight();
            }
            else {
                var foundHeight = 0;
                for (var i = children.length - 1; i >= 0; i--) {
                    var child = this.children[i];
                    if (child.lp.isAbsolute) {
                        continue;
                    }
                    if (child.lp.h === WRAP_CONTENT) {
                        child.measure(canvas);
                    }
                    if (child.height > foundHeight) {
                        foundHeight = child.height;
                    }
                }
                height = foundHeight + heightSpacing;
            }
        }
        else {
            height = calcDimension(this.lp.hDimension, this.getMatchParentHeight());
        }
        if (width > maxW) {
            width = maxW;
        }
        else if (width < minW) {
            width = minW;
        }
        if (height > maxH) {
            height = maxH;
        }
        else if (height < minH) {
            height = minH;
        }
        width = Math.round(width);
        height = Math.round(height);
        var measuredDimensions = this.onMeasure(width, height);
        var sizeChanged = this.oldWidth !== measuredDimensions.width || this.oldHeight !== measuredDimensions.height;
        this.width = measuredDimensions.width;
        this.height = measuredDimensions.height;
        for (var i = 0, l = children.length; i < l; i++) {
            children[i].measure(canvas, sizeChanged);
        }
    };
    View.prototype.onMeasure = function (width, height) {
        return { width: width, height: height };
    };
    View.prototype.onSizeChanged = function (_width, _height, _oldWidth, _oldHeight) {
    };
    View.prototype.getMatchParentWidth = function () {
        var p = this.parent;
        while (p) {
            if (p.lp.w !== WRAP_CONTENT) {
                return p.innerWidth;
            }
            p = p.parent;
        }
        return 0;
    };
    View.prototype.getMatchParentHeight = function () {
        var p = this.parent;
        while (p) {
            if (p.lp.w !== WRAP_CONTENT) {
                return p.innerHeight;
            }
            p = p.parent;
        }
        return 0;
    };
    View.prototype.getInternalWrappedHeight = function (_canvas) {
        return undefined;
    };
    View.prototype.getInternalWrappedWidth = function (_canvas) {
        return undefined;
    };
    View.prototype.draw = function (canvas, force) {
        if (force === void 0) { force = false; }
        if (!this.requireGuardAndTake(RequiredViewChanges.DRAW, force)) {
            return;
        }
        var _a = this, visibility = _a.visibility, context = _a.context, rect = _a.rect, _b = _a.lp, margin = _b.marginRect, padding = _b.paddingRect;
        var l = rect.l, t = rect.t, r = rect.r, b = rect.b;
        if (visibility !== Visibility.VISIBLE || l === r || t === b) {
            if (context.debugEnabled) {
                console.log("".concat(this.name, "[").concat(this.id, "]: skipping draw()"));
            }
            return;
        }
        var ctx = canvas.context;
        if (context.debugEnabled) {
            console.log("".concat(this.name, "[").concat(this.id, "]: draw()"));
        }
        ctx.save();
        if (context.debugEnabled) {
            ctx.strokeStyle = '#F0F';
            ctx.fillStyle = '#F0F';
            ctx.lineWidth = 1;
            ctx.strokeRect(l, t, r - l, b - t);
            ctx.textAlign = 'end';
            ctx.textBaseline = 'top';
            ctx.font = '9px monospace';
            var boundsText = "".concat((r - l).toFixed(1), "x").concat((b - t).toFixed(1));
            var width = ctx.measureText(boundsText).width;
            ctx.fillRect(Math.max(l, r - width), t, width, 9);
            ctx.fillStyle = '#FFF';
            ctx.fillText(boundsText, r, t, r - l);
        }
        l += margin.l;
        t += margin.t;
        r -= margin.r;
        b -= margin.b;
        if (this.backgroundColor) {
            ctx.fillStyle = this.backgroundColor;
            ctx.fillRect(l, t, r - l, b - t);
        }
        if (this.borderColor && this.borderRect) {
            var border = this.borderRect;
            ctx.fillStyle = this.borderColor;
            if (border.t) {
                ctx.fillRect(l, t, r - l, border.t);
            }
            if (border.b) {
                ctx.fillRect(l, b - border.b, r - l, border.b);
            }
            if (border.l) {
                ctx.fillRect(l, t, border.l, b - t);
            }
            if (border.r) {
                ctx.fillRect(r - border.r, t, border.r, b - t);
            }
        }
        if (context.debugEnabled) {
            ctx.strokeStyle = '#0F0';
            ctx.fillStyle = '#0F0';
            ctx.lineWidth = 1;
            ctx.strokeRect(l, t, r - l, b - t);
            var boundsText = "".concat((r - l).toFixed(1), "x").concat((b - t).toFixed(1));
            var width = ctx.measureText(boundsText).width;
            ctx.fillRect(Math.max(l, r - width), t, width, 9);
            ctx.fillStyle = '#FFF';
            ctx.fillText(boundsText, r, t, r - l);
        }
        ctx.beginPath();
        ctx.rect(l, t, r - l, b - t);
        ctx.clip();
        l += padding.l;
        t += padding.t;
        r -= padding.r;
        b -= padding.b;
        if (context.debugEnabled) {
            ctx.strokeStyle = '#F00';
            ctx.fillStyle = '#F00';
            ctx.lineWidth = 1;
            ctx.strokeRect(l, t, r - l, b - t);
            var boundsText = "".concat((r - l).toFixed(1), "x").concat((b - t).toFixed(1));
            var width = ctx.measureText(boundsText).width;
            ctx.fillRect(Math.max(l, r - width), t, width, 9);
            ctx.fillStyle = '#FFF';
            ctx.fillText(boundsText, r, t, r - l);
        }
        ctx.translate(l, t);
        this.onDraw(canvas);
        var children = this.children;
        for (var i = 0, l_3 = children.length; i < l_3; i++) {
            children[i].draw(canvas, true);
        }
        ctx.restore();
        this.mount();
    };
    View.prototype.onDraw = function (_canvas) {
    };
    View.prototype.getOnMount = function () {
        return this.onMount;
    };
    View.prototype.setOnMount = function (callback) {
        this.onMount = callback;
    };
    View.prototype.mount = function () {
        if (this.onMount && !this.mounted) {
            this.onMount(this);
        }
        this.mounted = true;
    };
    View.prototype.addChild = function (child, position) {
        if (position === void 0) { position = -1; }
        if (child.parent) {
            throw new Error('Child element can not be added to two containers at a time.');
        }
        if (position < 0) {
            this.children.push(child);
        }
        else {
            this.children.splice(position, 0, child);
        }
        child.attach(this);
        this.require(RequiredViewChanges.MEASURE);
    };
    View.prototype.setChildAt = function (child, position) {
        var oldChild = this.children[position];
        if (child === oldChild) {
            return;
        }
        this.children[position] = child;
        child.attach(this);
        if (oldChild) {
            child.detach();
        }
        this.require(RequiredViewChanges.MEASURE);
    };
    View.prototype.removeChild = function (child) {
        var id;
        if (typeof child === 'number') {
            id = child;
            child = this.childrenIdMap[id];
        }
        else {
            id = this.children.indexOf(child);
        }
        if (!child || child.parent !== this) {
            throw new Error('Element is not a child of this container.');
        }
        this.children.splice(id, 1);
        child.detach();
        this.require(RequiredViewChanges.MEASURE);
    };
    View.prototype.removeChildAt = function (startIndex, endIndex) {
        if (endIndex === void 0) { endIndex = startIndex + 1; }
        var children = this.children;
        if (startIndex < 0 || startIndex >= children.length || endIndex < 0 || endIndex > children.length) {
            throw new Error("removeChildAt: Invalid range (".concat(startIndex, ", ").concat(endIndex, "). Children length: ").concat(children.length));
        }
        for (var i = endIndex; i >= startIndex; i++) {
            children[i].detach();
        }
        children.splice(startIndex, endIndex - startIndex);
        this.require(RequiredViewChanges.MEASURE);
    };
    Object.defineProperty(View.prototype, "innerHeight", {
        get: function () {
            var lp = this.lp;
            var height = Math.round(this.height - (lp.marginRect.t + lp.marginRect.b + lp.paddingRect.t + lp.paddingRect.b));
            return height > 0 ? height : 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(View.prototype, "innerWidth", {
        get: function () {
            var lp = this.lp;
            var width = Math.round(this.width - (lp.marginRect.l + lp.marginRect.r + lp.paddingRect.l + lp.paddingRect.r));
            return width > 0 ? width : 0;
        },
        enumerable: false,
        configurable: true
    });
    View.prototype.getLayoutParams = function () {
        return this.lp;
    };
    View.prototype.setLayoutParams = function (lp) {
        this.lp = lp;
        if (this.lp.dependenciesModified && this.parent) {
            this.parent.childrenGraphChanged = true;
        }
        this.require(RequiredViewChanges.MEASURE);
    };
    View.prototype.getVisibility = function () {
        return this.visibility;
    };
    View.prototype.setVisibility = function (visibility) {
        if (visibility === void 0) { visibility = Visibility.VISIBLE; }
        var oldVisibility = this.visibility;
        if (oldVisibility === visibility) {
            return;
        }
        this.visibility = visibility;
        if (xor(oldVisibility === Visibility.GONE, visibility === Visibility.GONE)) {
            this.require(RequiredViewChanges.MEASURE);
        }
        else {
            this.require(RequiredViewChanges.DRAW);
        }
    };
    View.prototype.require = function (requiredChanges) {
        if (this.requiredChanges < requiredChanges) {
            this.requiredChanges = requiredChanges;
            if (this.parent && requiredChanges > RequiredViewChanges.NOTHING) {
                this.parent.require(requiredChanges);
            }
        }
    };
    View.prototype.requireGuard = function (requiredChanges) {
        return this.requiredChanges === requiredChanges;
    };
    View.prototype.requireGuardAndTake = function (requiredChanges, force) {
        if (this.requiredChanges === requiredChanges) {
            this.requiredChanges = this.requiredChanges - 1;
            return true;
        }
        else if (force) {
            return true;
        }
        return false;
    };
    View.prototype.getBackgroundColor = function () {
        return this.backgroundColor;
    };
    View.prototype.setBackgroundColor = function (backgroundColor) {
        if (this.backgroundColor !== backgroundColor) {
            this.backgroundColor = backgroundColor;
            this.require(RequiredViewChanges.DRAW);
        }
    };
    View.prototype.getBorderColor = function () {
        return this.borderColor;
    };
    View.prototype.setBorderColor = function (borderColor) {
        if (this.borderColor !== borderColor) {
            this.borderColor = borderColor;
            this.require(RequiredViewChanges.DRAW);
        }
    };
    View.prototype.getBorder = function () {
        return this.backgroundColor;
    };
    View.prototype.setBorder = function (borderRect) {
        if (this.borderRect !== borderRect) {
            this.borderRect = borderRect ? Rect.from(borderRect) : undefined;
            this.require(RequiredViewChanges.DRAW);
        }
    };
    View.prototype.getId = function () {
        return this.context.getId(this.id);
    };
    View.prototype.setId = function (id) {
        var parent = this.parent;
        if (this.id !== undefined && parent) {
            delete parent.childrenIdMap[this.id];
        }
        this.id = id === undefined ? undefined : this.context.registerView(id);
        if (this.id !== undefined && parent) {
            parent.childrenIdMap[this.id] = this;
        }
    };
    View.prototype.destroy = function () {
        var children = this.children;
        if (this.parent) {
            this.parent.removeChild(this);
        }
        for (var i = children.length - 1; i >= 0; i--) {
            var child = children[i];
            this.removeChild(child);
            child.destroy();
        }
        this.onDestroy();
    };
    View.prototype.onDestroy = function () {
    };
    View.prototype.snapshot = function () {
        return removeUndefinedProps(__assign(__assign({}, this.onSnapshot()), { id: this.id, name: this.name, layoutParams: removeDefaultProps(this.lp.asProps()), visibility: Visibility[this.visibility], children: this.children.map(function (c) { return c.snapshot(); }) }));
    };
    View.prototype.screenshot = function () {
        var viewOffset = this.offsetRect;
        var rootView = this.getRootView();
        var rootViewScale = rootView.getScale();
        var w = this.innerWidth * rootViewScale;
        var h = this.innerHeight * rootViewScale;
        if (!w || !h) {
            return undefined;
        }
        var imageData = rootView.getCanvas().getContext('2d')
            .getImageData(viewOffset.l * rootViewScale, viewOffset.t * rootViewScale, w, h);
        var canvas = this.context.canvasCreator({
            width: w,
            height: h,
        });
        var ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0);
        return canvas.toDataURL();
    };
    View.prototype.onSnapshot = function () {
        return {};
    };
    View.prototype.getRootView = function () {
        var parent = this;
        while (parent.hasParent()) {
            parent = parent.getParent();
        }
        return parent;
    };
    View.prototype.getParent = function () {
        return this.parent;
    };
    View.prototype.hasParent = function () {
        return Boolean(this.parent);
    };
    View.prototype.attach = function (parent) {
        if (this.parent) {
            throw new Error('A view can have only one parent.');
        }
        this.parent = parent;
        if (this.id !== undefined) {
            var childWithTheSameId = this.parent.childrenIdMap[this.id];
            if (childWithTheSameId) {
                throw new Error("The parent already has a child with the same id: ".concat(this.id, "=").concat(this.context.getId(this.id)));
            }
            this.parent.childrenIdMap[this.id] = this;
        }
        this.parent.childrenGraphChanged = true;
    };
    View.prototype.detach = function () {
        if (!this.parent) {
            return;
        }
        if (this.id !== undefined) {
            delete this.parent.childrenIdMap[this.id];
        }
        this.parent.childrenGraphChanged = true;
        this.parent = null;
    };
    return View;
}());
export { View };
