import React from 'react';

import { Loader } from '+shared/basicComponents/Loader/Loader';

type Props = {
  tableRows: number;
  footerHeightForLoader: number;
  isLoading: boolean;
};

const LoaderWidgetForTable: React.FC<Props> = ({ tableRows, footerHeightForLoader, isLoading }) => (
  <span
    style={{
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: tableRows === 0 ? footerHeightForLoader : 'calc(100% - 49px)',
      top: 49,
      backgroundColor: 'rgba(255,255,255, 0.8)',
      alignItems: tableRows > 4 ? 'start' : 'center',
      paddingTop: tableRows > 4 ? 100 : 0,
      opacity: isLoading ? 1 : 0,
      transition: 'opacity 90ms ease-out',
      /* trick to send the loader under the table, so that we can keep the animation
       * removing it from the DOM with display: "none" or visibility: "hidden"
       * would prevent the fading animation
       */
      zIndex: isLoading ? 2 : -1,
    }}
  >
    <Loader />
  </span>
);

export default LoaderWidgetForTable;
