import * as React from 'react';

import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container } from '+shared/components';

import { HelpContact } from '../HelpContact';
import { HelpFaq } from '../HelpFaq';
import { HelpSupport } from '../HelpSupport';

import './Help.component.scss';

export const Help: React.FC = () => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.HELP, PageName.Help.FAQ_PAGE);

  return (
    <Container className={'c-help'}>
      <div className={'c-help__content'}>
        <div className={'c-help__section'}>
          <HelpFaq />
        </div>
        <div className={'c-help__spacer'} />
        <div className={'c-help__section'}>
          <HelpSupport />
          <HelpContact />
        </div>
      </div>
    </Container>
  );
};
