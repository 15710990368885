export enum NewsPlacementType {
  DASHBOARD = 'dashboard',
  NEWS_INBOX_SIDEBAR = 'news-inbox-sidebar',
  NEWS_INBOX_DETAIL = 'news-inbox-details',
  NOTIFICATION_MODAL = 'news-notifications-modal',
}

export interface NewsMainState {
  activeNews?: string;
}

export const newsMainInitialState: NewsMainState = {
  activeNews: undefined,
};
