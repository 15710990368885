import { ContentType } from '@coolio/http';
export var Headers = {
    'Content-Type': ContentType.VND_JSON,
    'Accept': ContentType.VND_JSON,
};
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASCENDING"] = "";
    SortOrder["DESCENDING"] = "-";
})(SortOrder || (SortOrder = {}));
