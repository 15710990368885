import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isFlatDirectConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { TestId } from '+config/testIds';
import { LeadConfigurationResponseAttributes } from '+shared/store/lead/types/leadConfigurationResponse.interface';
import { insertIf } from '+utils/array.util';

export enum VppQuestionsFormFields {
  SEMI_INDIRECT = 'semiIndirect',
  GENERATION_PLANTS = 'generationPlants',
  PRODUCTION_METERING_MANDATORY = 'productionMeteringMandatory',
}

export type VppQuestionsForm = Record<VppQuestionsFormFields, boolean>;

export const VppQuestionsInitialValues: Record<VppQuestionsFormFields, boolean> = {
  [VppQuestionsFormFields.SEMI_INDIRECT]: false,
  [VppQuestionsFormFields.GENERATION_PLANTS]: false,
  [VppQuestionsFormFields.PRODUCTION_METERING_MANDATORY]: false,
};

export type VppQuestionType = {
  question: string;
  optionType: string;
  dataTestIdYes: string;
  dataTestIdNo: string;
};

export const getVppQuestions = (configuration: LeadConfigurationResponseAttributes | undefined) => [
  {
    question: I18n.t(T.lead.offer._salessolution_.vppQuestions.transformerMeteringMandatory),
    optionType: VppQuestionsFormFields.SEMI_INDIRECT,
    dataTestIdYes: TestId.Lead.VppBonusCheckPopup.FirstQuestionYesRadioButton,
    dataTestIdNo: TestId.Lead.VppBonusCheckPopup.FirstQuestionNoRadioButton,
  },
  {
    question: I18n.t(
      T.lead.offer._salessolution_.vppQuestions.nonPhotovoltaicEnergyProducingSystemsInstalled
    ),
    optionType: VppQuestionsFormFields.GENERATION_PLANTS,
    dataTestIdYes: TestId.Lead.VppBonusCheckPopup.SecondQuestionYesRadioButton,
    dataTestIdNo: TestId.Lead.VppBonusCheckPopup.SecondQuestionNoRadioButton,
  },
  ...insertIf(isFlatDirectConfiguration(configuration), {
    question: I18n.t(T.lead.offer._salessolution_.vppQuestions.furtherMeterBehindSonnenFlatMeter),
    optionType: VppQuestionsFormFields.PRODUCTION_METERING_MANDATORY,
    dataTestIdYes: TestId.Lead.VppBonusCheckPopup.ThirdQuestionYesRadioButton,
    dataTestIdNo: TestId.Lead.VppBonusCheckPopup.ThirdQuestionNoRadioButton,
  }),
];
