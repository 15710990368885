import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { FormCheckbox } from '@sonnen/shared-web';

import { getIsBatteryBackupBufferInProgress } from '+customer-battery/store';
import { TooltipHoverable, WidgetAccordion } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { BatteryActions } from '+shared/store/battery';
import { hasBatteryBackupDevice } from '+shared/store/battery/battery.helpers';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { OpenWidgetTitle } from '../../components';
import { getBattery, isEatonBattery } from '../../store';

import './BatteryBackupPowerBufferOperation.scss';

const BUFFER_LOWER_BOUND = 5;

export const rangeSliderConfig = {
  step: 1,
  min: 0,
  max: 100,
};

const mapStateToProps = (state: StoreState) => ({
  battery: getBattery(state),
  isBatteryBackupBufferInProgress: getIsBatteryBackupBufferInProgress(state),
  isEatonBattery: isEatonBattery(state),
});

const mapDispatchToProps = mapActions({
  setBatteryBackupBuffer: BatteryActions.setBatteryBackupBuffer,
  setBatteryBackupBufferInProgress: BatteryActions.setIsBatteryBackupBufferInProgress,
});

interface OwnProps {
  onRangeChange: (sliderBackupValues: number[]) => void;
  isMoreThanOneWidgetActive: boolean;
  onWidgetOpening: (openedWidget: OpenWidgetTitle[]) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const BatteryBackupPowerBufferOperationComponent: React.VFC<Props> = ({
  actions,
  battery,
  isBatteryBackupBufferInProgress,
  isEatonBattery,
  onRangeChange,
  isMoreThanOneWidgetActive,
  onWidgetOpening,
}) => {
  const [isCustomerInformed, setIsCustomerInformed] = useState<boolean>(false);
  const [isBackupBufferAccordionOpen, setIsBackupBufferAccordionOpen] = useState<boolean>(false);
  const sliderInitialValue = battery?.backupPowerBuffer || 0;
  const [sliderBackupLevel, setSliderBackupLevel] = useState<number[]>([sliderInitialValue]);

  const hasBackupDevice = hasBatteryBackupDevice(battery);

  const selfConsumptionAvailabilityInitial: number = 100 - sliderBackupLevel[0];
  const [selfConsumptionAvailabilityValue, setSelfConsumptionAvailabilityValue] = useState<number>(
    selfConsumptionAvailabilityInitial
  );

  const [isBackupBufferEdited, setIsBackupBufferEdited] = useState<boolean>(false);

  const { batteryOff } = T.customerSingle.batteryDetails.batteryOperations.changeBackupBuffer;
  const minSliderText = sliderBackupLevel[0] === 0 ? I18n.t(batteryOff) : `${sliderBackupLevel}%`;

  useEffect(() => {
    battery?.backupPowerBuffer !== sliderBackupLevel[0]
      ? setIsBackupBufferEdited(true)
      : setIsBackupBufferEdited(false);
  }, [sliderBackupLevel]);

  useEffect(() => {
    isBackupBufferAccordionOpen
      ? onWidgetOpening([OpenWidgetTitle.BACKUP_BUFFER])
      : onWidgetOpening([]);
  }, [isBackupBufferAccordionOpen]);

  const handleOnSubmitClick = (newBackupLevel: number): void => {
    if (battery) {
      actions.setBatteryBackupBuffer({
        backupBuffer: newBackupLevel,
        batteryId: battery.id,
      });
      actions.setBatteryBackupBufferInProgress(true);
      setIsBackupBufferAccordionOpen(false);
    }
  };

  const handleOnCloseClick = (): void => {
    setIsBackupBufferAccordionOpen(!isBackupBufferAccordionOpen);
    setIsCustomerInformed(false);
    setSliderBackupLevel([sliderInitialValue]);
    setSelfConsumptionAvailabilityValue(selfConsumptionAvailabilityInitial);
    onRangeChange([sliderInitialValue]);
  };

  const handleOnSliderUpdate = (backupLevel: number[]): void => {
    // battery API does not accept a buffer value smaller than 5
    if (backupLevel[0] < BUFFER_LOWER_BOUND) backupLevel[0] = 0;

    setSliderBackupLevel(backupLevel);
    setSelfConsumptionAvailabilityValue(100 - backupLevel[0]);
    onRangeChange(backupLevel);
  };

  const handleOnConfirmationBoxClick = (): void => setIsCustomerInformed(!isCustomerInformed);

  const isWidgetAccordionDisabled: boolean =
    isBatteryBackupBufferInProgress ||
    isMoreThanOneWidgetActive ||
    isEatonBattery ||
    !hasBackupDevice;

  const {
    notAvailableTooltip,
    changeBackupBuffer: {
      headline,
      sliderTitle,
      selfConsumptionAvailability,
      confirmationCheckbox,
      buttonCancel,
      buttonSave,
      notAvailableWhenNoBackupDevice,
    },
  } = T.customerSingle.batteryDetails.batteryOperations;

  return (
    <TooltipHoverable
      title={isEatonBattery ? I18n.t(notAvailableTooltip) : I18n.t(notAvailableWhenNoBackupDevice)}
      isHoveringDisabled={hasBackupDevice && !isEatonBattery}
    >
      <WidgetAccordion
        className={'c-battery-backup-buffer-list-item'}
        title={I18n.t(headline)}
        isOpen={isBackupBufferAccordionOpen}
        onHeaderClick={handleOnCloseClick}
        isDisabled={isWidgetAccordionDisabled}
      >
        <div className={'c-battery-backup-buffer__battery-slider'}>
          <div className={'c-battery-backup-buffer__slider'}>
            <div className={'c-battery-backup-buffer__slider-header'}>
              <div className={'c-battery-backup-buffer__slider-backup'}>{I18n.t(sliderTitle)}</div>
              <div className={'c-battery-backup-buffer__slider-selfcons'}>
                {I18n.t(selfConsumptionAvailability)}
              </div>
            </div>
            <div className={'c-battery-backup-buffer__slider-range'}>
              <Range
                {...rangeSliderConfig}
                values={sliderBackupLevel}
                onChange={handleOnSliderUpdate}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      background: applyColorToTrackBar(sliderBackupLevel[0]),
                    }}
                    className={'c-battery-backup-buffer__range-track'}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div {...props} className={'c-battery-backup-buffer__range-thumb'} />
                )}
              />
            </div>
            <div className={'c-battery-backup-buffer__slider-footer'}>
              <div className={'c-battery-backup-buffer__slider-footer-backup'}>{minSliderText}</div>
              <div className={'c-battery-backup-buffer__slider-footer-selfcons'}>
                {selfConsumptionAvailabilityValue}%
              </div>
            </div>
            <div className={'c-battery-backup-buffer__confirmation'}>
              <FormCheckbox
                name={'customer-informed-confirmation'}
                checked={isCustomerInformed}
                onClick={handleOnConfirmationBoxClick}
                label={
                  <div className={'c-battery-backup-buffer__confirmation-label'}>
                    {I18n.t(confirmationCheckbox)}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className={'c-battery-backup-buffer__footer'}>
          <Button
            label={I18n.t(buttonCancel)}
            size={ButtonSize.SMALL}
            type={ButtonType.TERTIARY}
            onClick={handleOnCloseClick}
          />
          <Button
            label={I18n.t(buttonSave)}
            size={ButtonSize.SMALL}
            type={ButtonType.PRIMARY}
            disabled={!isCustomerInformed || !isBackupBufferEdited}
            status={
              !isCustomerInformed || !isBackupBufferEdited
                ? ButtonStatus.DISABLED
                : ButtonStatus.ENABLED
            }
            onClick={() => handleOnSubmitClick(sliderBackupLevel[0])}
          />
        </div>
      </WidgetAccordion>
    </TooltipHoverable>
  );
};

export const BatteryBackupPowerBufferOperation = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryBackupPowerBufferOperationComponent);

const applyColorToTrackBar = (value: number) =>
  `linear-gradient(to right, ${
    value < BUFFER_LOWER_BOUND ? '#D3D5D8' : '#85BCE1'
  } 4.5%, white 4.5% 5%, #0B7AC2 5% ${value}%, #D3D5D8 ${value}% 100%)`;
