import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { ToolbarConfig } from './FormRichTextInput.types';

import './FormRichTextInput.component.scss';

const RichTextEditor = React.lazy(() => import('react-rte'));

interface Props {
  className?: string;
  hasNoGap?: boolean;
  label?: string;
  error?: string;
  // TODO in the future handle this type properly,
  //  EditorValue from react-rte loaded asynchroniously;
  value: any;
  toolbarConfig: ToolbarConfig;
  onChange: (value: any) => void; // TODO;
}

export const FormRichTextInput: React.FC<Props> = ({
  className,
  hasNoGap,
  label,
  error,
  value,
  toolbarConfig,
  onChange,
}) => (
  <div
    className={classNames('c-form-rich-text-input', className, {
      'c-form-rich-text-input--no-gap': hasNoGap && error,
    })}
  >
    {label && <label className={'c-form-rich-text-input__label'}>{label}</label>}
    <div className={'c-form-rich-text-input__warning'}>
      <Icon.Warning className={'c-form-rich-text-input__warning-icon'} />
      <div className={'c-form-rich-text-input__warning-text'}>
        IMPORTANT: in order to make proper redirection, before each link, there has to be either
        http:// or https://
      </div>
    </div>
    <React.Suspense fallback={<div>Loading...</div>}>
      <RichTextEditor
        value={value}
        onChange={onChange}
        toolbarConfig={toolbarConfig}
        className={classNames('c-form-rich-text-input__field', {
          'c-form-rich-text-input__field--error': error,
        })}
      />
    </React.Suspense>
    <div className={'c-form-rich-text-input__error'}>
      {error && <div className={'c-form-rich-text-input__error-message'}>{error}</div>}
    </div>
  </div>
);
