import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import classNames from 'classnames';

import analysisSvg from '+assets/img/analysis.svg';
import { DetailList, StatusTile, StatusTileColors, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { QueryState } from '+shared/store/query';
import { SiteLiveState } from '+shared/store/site/types';
import { formatPower } from '+utils/format.util';
import { useLocaleContext } from '+utils/react/locale.provider';

import './CustomerAnalysisWidget.component.scss';

interface Props {
  pollingSiteLiveState: SiteLiveState | undefined;
  initialSiteLiveState: SiteLiveState | undefined;
  initialQueryStatus: QueryState;
  pollingQueryStatus: QueryState;
  action: () => void;
  className?: ClassValue;
  link: () => void;
}

export const CustomerAnalysisWidget: React.FC<Props> = ({
  pollingSiteLiveState,
  pollingQueryStatus,
  initialQueryStatus,
  initialSiteLiveState,
  action,
  link,
  className,
}) => {
  const { locale } = useLocaleContext();
  const { headline, currentConsumption, currentProduction, error } =
    T.customerSingle.overview.analysis;

  const liveState = pollingSiteLiveState ?? initialSiteLiveState;
  const isOnline = liveState?.online;
  const isQuerySuccess = pollingQueryStatus.success || initialQueryStatus.success;
  const isQueryError = pollingQueryStatus.error || initialQueryStatus.error;
  const isInitialQueryLoading = initialQueryStatus.pending;

  const getLiveMeasurementValue = (liveMeasurementValue: number): string | undefined => {
    return isQuerySuccess && isOnline ? formatPower({ locale })(liveMeasurementValue) : undefined;
  };

  const onlineHeaderStatus = () => (
    <div className={'customer-analysis-widget__header'}>
      {I18n.t(headline)}
      <StatusTile
        additionalClass={'customer-analysis-widget__header-status'}
        label={'Live Data'}
        color={StatusTileColors.GREEN}
        hasDot={true}
      />
    </div>
  );
  const offlineHeaderStatus = I18n.t(headline);
  const warningMessage = () => (
    <WidgetWarning type={'warning'} icon={'warning'}>
      {I18n.t(error)}
    </WidgetWarning>
  );

  return (
    <Widget
      className={classNames('c-customer-overview__tile', className)}
      icon={'Analysis'}
      heading={
        !isInitialQueryLoading
          ? liveState?.online
            ? onlineHeaderStatus()
            : offlineHeaderStatus
          : ''
      }
      image={analysisSvg}
      onImageClick={action}
      link={link}
    >
      {isQueryError && warningMessage()}
      {!isInitialQueryLoading && !isOnline && warningMessage()}
      {!isInitialQueryLoading && isOnline && (
        <DetailList
          list={[
            {
              label: I18n.t(currentConsumption),
              value: getLiveMeasurementValue(liveState.consumptionPower),
            },
            {
              label: I18n.t(currentProduction),
              value: getLiveMeasurementValue(liveState.productionPower),
            },
          ]}
        />
      )}
    </Widget>
  );
};
