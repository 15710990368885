import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { dateUtil } from '+utils/date.util';

import './DashboardGreeting.scss';

interface Props {
  userName: string;
  city: string;
  countryCode: string;
}

const DashboardGreeting: React.FC<Props> = ({ userName, city, countryCode }) => {
  const dateString = dateUtil.format(dateUtil.now(), 'Do MMMM YYYY');

  return (
    <div className="dashboard-greeting">
      <p className="dashboard-greeting__hello" data-testid="dashboard-greeting-msg">
        {I18n.t(T.dashboard.greeting.hello, { userName })}
      </p>
      <p>
        <span className="dashboard-greeting__date" data-testid="dashboard-current-date">
          {dateString}
        </span>
        <span className="dashboard-greeting__location">{`${city}, ${countryCode}`}</span>
      </p>
    </div>
  );
};

export default DashboardGreeting;
