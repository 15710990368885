import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CustomerActions } from '+app/+customer/store';
import { ROUTES } from '+router/routes';
import { RouterActions } from '+router/store';
import { isLocationChangeFromPath } from '+router/store/router.selectors';
import { SiteRepository } from '+shared/store/site/site.repository';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, makeQuery, mapPathToParams, mapToState, ofType, polling } from '+utils/index';

import { HeatPumpActions } from './+heatPump.actions';
import { GET_HEATPUMP_POLLING_QUERY } from './+heatPump.state';

type Action$ = ActionsObservable<HeatPumpActions>;
type State$ = StateObservable<StoreState>;

/* 
  Starts, stops and performs live state v2 polling every five seconds
*/
const liveStateV2Polling$ = (action$: Action$) =>
  action$.pipe(
    polling({
      startOn: HeatPumpActions.startHeatPumpLiveStatePolling,
      stopOn: [HeatPumpActions.stopHeatPumpLiveStatePolling, CustomerActions.clearCustomerData],
      interval: 5000,
    })(({ siteId }) =>
      makeQuery(GET_HEATPUMP_POLLING_QUERY)({
        call: () => SiteRepository.getSiteLiveStateV2(siteId),
        onSuccess: (res) => dataGuard(HeatPumpActions.setHeatpumpFromSiteLiveStateV2)(res.element),
        onFailure: () => of(HeatPumpActions.setHeatpumpFromSiteLiveStateV2(undefined)),
      })
    )
  );

/*
  Initiates live state polling if setCustomer actions is dispatched and route matches customer 
  heatpump route
*/
const initiateLiveStateV2OnHeatpump$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    mapToState(state$),
    mapPathToParams(ROUTES.CUSTOMER_HEAT_PUMP[0]),
    map(([_, siteId]) => HeatPumpActions.startHeatPumpLiveStatePolling(siteId))
  );

const stopHeatpumpLiveStatePolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange),
    mapToState(state$),
    map(isLocationChangeFromPath(ROUTES.CUSTOMER_HEAT_PUMP)),
    filter((isMatch) => !!isMatch),
    map(() => HeatPumpActions.stopHeatPumpLiveStatePolling())
  );

export const epics = combineEpics(
  liveStateV2Polling$,
  initiateLiveStateV2OnHeatpump$,
  stopHeatpumpLiveStatePolling$
);
