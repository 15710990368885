import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { useCountryFeature } from '@sonnen/shared-web';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { CustomerRouteParams } from '+app/router';
import { CountryFeatureName } from '+config/countryFlags';
import {
  backupPowerBuffer,
  getBattery,
  getBatteryElectricUnits,
  getBatteryIcStatus,
  getBatteryQueryStatus,
  getIsBatteryBackupBufferInProgress,
  getIsBatteryIntelligentChargingInProgress,
  getIsBatteryOperatingModeInProgress,
  getPvGridFeedInLimit,
  isBackupDeviceTypeIsBackupBoxOrProtect,
  isHeaterStatusConnected,
} from '+customer-battery/store/+battery.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import TwoColumnsLayout from '+shared/containers/Layout/TwoColumnsLayout';
import { BatteryActions } from '+shared/store/battery';
import { getSiteLiveState, getSiteLiveStateQueryStatus } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import {
  BackupBoxCard,
  BatteryInstallationCard,
  BatteryLiveCard,
  BatteryOperationsCard,
  BatteryTechSpecsCard,
  HeaterCard,
} from '../../components';

type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState) => ({
  battery: getBattery(state),
  batteryElectricUnits: getBatteryElectricUnits(state),
  siteLiveState: getSiteLiveState(state),
  batteryIcStatus: getBatteryIcStatus(state),
  siteLiveStateQueryStatus: getSiteLiveStateQueryStatus(state),
  batteryQueryStatus: getBatteryQueryStatus(state),
  isHeaterConnected: isHeaterStatusConnected(state),
  isBackupProperType: isBackupDeviceTypeIsBackupBoxOrProtect(state),
  powerBuffer: backupPowerBuffer(state),
  isBatteryOperatingModeInProgress: getIsBatteryOperatingModeInProgress(state),
  isBatteryIntelligentChargingInProgress: getIsBatteryIntelligentChargingInProgress(state),
  isBatteryBackupBufferInProgress: getIsBatteryBackupBufferInProgress(state),
  pvGridFeedInLimit: getPvGridFeedInLimit(state),
});

const mapDispatchToProps = mapActions({
  setBatteryRemoteSettingsAllowed: BatteryActions.setBatteryRemoteSettingsAllowed,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps;

const CustomerBatteryComponent: React.FC<Props> = ({
  actions,
  battery,
  batteryElectricUnits,
  siteLiveState,
  batteryIcStatus,
  batteryQueryStatus,
  siteLiveStateQueryStatus,
  isHeaterConnected,
  isBackupProperType,
  powerBuffer,
  isBatteryOperatingModeInProgress,
  isBatteryIntelligentChargingInProgress,
  isBatteryBackupBufferInProgress,
  pvGridFeedInLimit,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.BATTERY_DETAILS);

  const sliderInitialValue: number = battery?.backupPowerBuffer || 0;
  const [sliderBackupLevel, setSliderBackupLevel] = React.useState<number[]>([sliderInitialValue]);

  const { batteryRemoteSettingsAllowedFlag } = useFlags();

  React.useEffect(() => {
    actions.setBatteryRemoteSettingsAllowed(batteryRemoteSettingsAllowedFlag);
  }, [batteryRemoteSettingsAllowedFlag]);

  const isBatteryCountryCodeAuOrNz =
    (!isNil(battery) && battery.installationCountryCode === CountryCode.AU) ||
    (!isNil(battery) && battery.installationCountryCode === CountryCode.NZ);
  const isSonnenBackupBoxHidden = isBatteryCountryCodeAuOrNz && battery.hardwareVersion === '10.0';
  const isBackupBoxConnected =
    isBackupProperType && !isNil(powerBuffer) && !isSonnenBackupBoxHidden;
  const isAccessoriesSectionEnabled = useCountryFeature(CountryFeatureName.ACCESSORIES).isEnabled;
  const isAccessoriesSectionVisible =
    isAccessoriesSectionEnabled && (isHeaterConnected || isBackupBoxConnected);
  const isPvFeedInLimitSet = !isNil(pvGridFeedInLimit) ? pvGridFeedInLimit < 100 : false;
  const isBatteryIntelligentChargingVisible =
    isPvFeedInLimitSet &&
    useCountryFeature(CountryFeatureName.BATTERY_INTELLIGENT_CHARGING).isEnabled;

  return (
    <>
      {battery && siteLiveState && (
        <>
          <TwoColumnsLayout>
            <BatteryLiveCard
              battery={battery}
              siteLiveState={siteLiveState}
              queryStatus={siteLiveStateQueryStatus}
              batteryIcStatus={batteryIcStatus}
              sliderValue={sliderBackupLevel}
              isBatteryOperatingModeInProgress={isBatteryOperatingModeInProgress}
              isBatteryIntelligentChargingInProgress={isBatteryIntelligentChargingInProgress}
              isBatteryBackupBufferInProgress={isBatteryBackupBufferInProgress}
              isPvFeedInLimitSet={isPvFeedInLimitSet}
              isBatteryIntelligentChargingVisible={isBatteryIntelligentChargingVisible}
            />
            <BatteryOperationsCard onRangeChange={setSliderBackupLevel} />
          </TwoColumnsLayout>

          <TwoColumnsLayout>
            <BatteryTechSpecsCard
              battery={battery}
              batteryElectricUnits={batteryElectricUnits}
              queryStatus={batteryQueryStatus}
            />
            <BatteryInstallationCard battery={battery} queryStatus={batteryQueryStatus} />
          </TwoColumnsLayout>
          {isAccessoriesSectionVisible && (
            <div className={'o-grid'}>
              {isHeaterConnected && (
                <div className={'o-grid__column o-grid__column--lg-6'}>
                  <HeaterCard
                    heater={{
                      timestamp: siteLiveState.timestamp,
                      power: siteLiveState.heaterPower,
                      waterTemperature: siteLiveState.heaterWaterTemp,
                      maxWaterTemperature: battery.heaterMaxTemperature,
                    }}
                  />
                </div>
              )}
              {isBackupBoxConnected && (
                <div className={'o-grid__column o-grid__column--lg-6'}>
                  <BackupBoxCard
                    backupBox={{
                      isActive: siteLiveState.backupActive,
                      nominalPower: battery.backupNominalPower,
                      powerBuffer: battery.backupPowerBuffer,
                      deviceType: battery.backupDeviceType,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export const CustomerBattery = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerBatteryComponent);
