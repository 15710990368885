import { __assign } from "tslib";
import { isNil, omit } from 'lodash';
import { CanvasPointerEvent, MouseButton } from './canvas-pointer-event';
import { domEventToPointerAction, getElementOffset, supportedDomPointerEvents } from './dom-pointer-event';
export var DEBUG_POINTER_EVENTS = false;
export var logPointerEvent = function (pointerEvent) {
    var event = omit(pointerEvent, 'pointers');
    console.group("PointerEvent:".concat(pointerEvent.action));
    console.log(event);
    pointerEvent.pointers
        .map(function (pointer) { return (__assign({}, pointer)); })
        .slice(0, pointerEvent.pointerCount)
        .forEach(function (pointer) { return console.log("xy: ".concat(pointer.x, ", ").concat(pointer.y, ", ") +
        "\u2206xy: ".concat(pointer.deltaX, ", ").concat(pointer.deltaY) +
        "\uD83D\uDC46 ".concat(pointer.pressure)); });
    console.groupEnd();
};
export var isTouchEvent = function (event) { return !!event.touches; };
export var isMouseEvent = function (event) { return !!event.initMouseEvent; };
export var isWheelEvent = function (event) { return !isNil(event.deltaX); };
var roundCoordinate = function (coordinate) { return Math.round(coordinate); };
var touchToPointer = function (touch, offset, canvasPointer) {
    if (!touch) {
        return;
    }
    canvasPointer.mouseButton = MouseButton.LEFT;
    canvasPointer.pressure = touch.force;
    canvasPointer.x = roundCoordinate(touch.pageX - offset.left);
    canvasPointer.y = roundCoordinate(touch.pageY - offset.top);
    canvasPointer.deltaX = 0;
    canvasPointer.deltaY = 0;
};
var mouseToPointer = function (event, offset, canvasPointer) {
    canvasPointer.mouseButton = event.button;
    canvasPointer.pressure = event.buttons ? 0.5 : 0;
    canvasPointer.x = roundCoordinate(event.pageX - offset.left);
    canvasPointer.y = roundCoordinate(event.pageY - offset.top);
    canvasPointer.deltaX = 0;
    canvasPointer.deltaY = 0;
};
export var fillPointerEventData = function (pointerEvent, view, event) {
    var element = event.target;
    var offset = getElementOffset(element, pointerEvent.offset);
    var action = domEventToPointerAction(event);
    if (action === undefined) {
        return;
    }
    pointerEvent.action = action;
    pointerEvent.target = view;
    pointerEvent.scrollX = 0;
    pointerEvent.scrollY = 0;
    pointerEvent.scrollZ = 0;
    if (isTouchEvent(event)) {
        pointerEvent.pointerCount = event.touches.length;
        for (var i = 0; i < pointerEvent.pointerCount; i++) {
            touchToPointer(event.touches[i], offset, pointerEvent.pointers[i]);
        }
    }
    else if (isMouseEvent(event)) {
        pointerEvent.pointerCount = 1;
        mouseToPointer(event, offset, pointerEvent.pointers[0]);
        if (isWheelEvent(event)) {
            pointerEvent.scrollX = event.deltaX;
            pointerEvent.scrollY = event.deltaY;
            pointerEvent.scrollZ = event.deltaZ;
        }
    }
};
export var registerEventDispatcher = function (element, dispatcher) {
    for (var _i = 0, supportedDomPointerEvents_1 = supportedDomPointerEvents; _i < supportedDomPointerEvents_1.length; _i++) {
        var eventType = supportedDomPointerEvents_1[_i];
        element.addEventListener(eventType, dispatcher);
    }
    return function () {
        for (var _i = 0, supportedDomPointerEvents_2 = supportedDomPointerEvents; _i < supportedDomPointerEvents_2.length; _i++) {
            var eventType = supportedDomPointerEvents_2[_i];
            element.removeEventListener(eventType, dispatcher);
        }
    };
};
export var createEventDispatcher = function (view) {
    var oldPointerEvent = new CanvasPointerEvent();
    var pointerEvent = new CanvasPointerEvent();
    var tempEvent;
    return function (event) {
        fillPointerEventData(pointerEvent, view, event);
        for (var i = 0; i < pointerEvent.pointerCount; i++) {
            var pointer = pointerEvent.pointers[i];
            var oldPointer = oldPointerEvent.pointers[i];
            pointer.deltaX = roundCoordinate(pointer.x - oldPointer.x) || 0;
            pointer.deltaY = roundCoordinate(pointer.y - oldPointer.y) || 0;
        }
        if (DEBUG_POINTER_EVENTS) {
            logPointerEvent(pointerEvent);
        }
        if (view.dispatchPointerEvent(pointerEvent)) {
            event.stopPropagation();
            if (event.cancelable) {
                event.preventDefault();
            }
        }
        tempEvent = oldPointerEvent;
        oldPointerEvent = pointerEvent;
        pointerEvent = tempEvent;
    };
};
