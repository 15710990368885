import * as React from 'react';
import Media from 'react-media';

import { Loader, MediaQuery } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { Container } from '../Container';

import './PageHeader.component.scss';

interface Props {
  className?: ClassValue;
  breadcrumb?: React.ReactNode;
  tabNav?: React.ReactNode;
  isExpanded?: boolean;
  isLoading?: boolean;
  withVerticalPadding?: boolean;
  withHorizontalPadding?: boolean;
}

export const PageHeader: React.SFC<Props> = ({
  tabNav,
  children,
  className,
  breadcrumb,
  isExpanded = false,
  isLoading = false,
  withVerticalPadding = false,
  withHorizontalPadding,
}) => (
  <header className={classNames('c-page-header', className)}>
    <Media query={{ minWidth: MediaQuery.UP_SM.minWidth }}>
      {(isDesktop) => (
        <Container
          withVerticalPadding={withVerticalPadding}
          withHorizontalPadding={isDesktop && withHorizontalPadding}
        >
          <div className={'c-page-header__container'}>
            {breadcrumb && <div className={'c-page-header__breadcrumb'}>{breadcrumb}</div>}
            <div
              className={classNames('c-page-header__headline', {
                'c-page-header__headline--expanded': isExpanded,
              })}
            >
              {isLoading ? <Loader className={'c-page-header__loader'} /> : children}
            </div>
          </div>
          {tabNav && <div className={'c-page-header__tab-nav'}>{tabNav}</div>}
        </Container>
      )}
    </Media>
  </header>
);
