import { createSelector } from 'reselect';

import { getStatus } from '+shared/store/query/query.utils';

import { getLeadOffer } from '../../store/+lead.selectors';
import {
  GET_OFFER_QUERY,
  PATCH_UTILITY_DATA_QUERY,
  POST_OFFER_QUERY,
  SEND_OFFER_QUERY,
} from './+offer.state';

export const getLeadOfferData = createSelector(getLeadOffer, (state) => state.offer);

export const getLeadOfferPostOfferQuery = createSelector(
  getLeadOffer,
  (state) => state[POST_OFFER_QUERY]
);

export const getLeadOfferPostOfferQueryStatus = createSelector(
  getLeadOfferPostOfferQuery,
  getStatus
);

export const getLeadOfferSendOfferQuery = createSelector(
  getLeadOffer,
  (state) => state[SEND_OFFER_QUERY]
);

export const getLeadOfferSendOfferQueryStatus = createSelector(
  getLeadOfferSendOfferQuery,
  getStatus
);

export const getLeadChangeUtilityDataQuery = createSelector(
  getLeadOffer,
  (state) => state[PATCH_UTILITY_DATA_QUERY]
);

export const getLeadChangeUtilityDataQueryStatus = createSelector(
  getLeadChangeUtilityDataQuery,
  getStatus
);

export const getEnergyProviders = createSelector(getLeadOffer, (state) => state.energyProviders);

export const getUtilityData = createSelector(getLeadOffer, (state) => state.utilityData);

export const getGetLeadOfferQuery = createSelector(getLeadOffer, (state) => state[GET_OFFER_QUERY]);

export const getGetLeadOfferQueryStatus = createSelector(getGetLeadOfferQuery, getStatus);
