import { mergeElementData } from '../helpers';
var JsonResponse = (function () {
    function JsonResponse(raw, response) {
        this.raw = raw;
        this.response = response;
    }
    Object.defineProperty(JsonResponse.prototype, "element", {
        get: function () {
            return this.raw && mergeElementData(this.raw.data);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JsonResponse.prototype, "meta", {
        get: function () {
            return this.raw && this.raw.meta;
        },
        enumerable: true,
        configurable: true
    });
    return JsonResponse;
}());
export { JsonResponse };
