import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Checkmark: React.FC<Props> = ({ color, size = 18, className }) => (
  <svg className={className} width={size} height={size} fill="none" viewBox="0 0 18 18">
    <path
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17 1L7 17L1 10"
      stroke={color}
    />
  </svg>
);
