import * as React from 'react';

import { Notification } from '+app/shared/components';
import { NotificationVariant, useLayoutManagement } from '+app/shared/store/layout';

const AUTO_HIDE_DURATION = 5000;

type NotificationOutletState = {
  message: string;
  variant: NotificationVariant;
  isOpen: boolean;
  isClosing: boolean;
};

const initialState: NotificationOutletState = {
  message: '',
  variant: 'info',
  isOpen: false,
  isClosing: false,
};

export const NotificationOutlet: React.VFC = () => {
  const [state, setState] = React.useState<NotificationOutletState>(initialState);
  const timeout = React.useRef<number>();
  const { dequeueNotification, notificationQueue } = useLayoutManagement();

  const handleClose = (): void => {
    clearTimeout(timeout.current);
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      isClosing: true,
    }));
  };

  const handleExited = (): void => {
    dequeueNotification();
    setState(initialState);
  };

  React.useEffect(() => {
    if (!state.isOpen && !state.isClosing && notificationQueue.length > 0) {
      const [notification] = notificationQueue;

      setState({
        message: notification.message,
        variant: notification.variant || 'info',
        isOpen: true,
        isClosing: false,
      });
    }
  }, [state, notificationQueue]);

  React.useEffect(() => {
    if (state.isOpen) {
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(handleClose, AUTO_HIDE_DURATION);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [state]);

  return (
    <Notification
      isOpen={state.isOpen}
      message={state.message}
      variant={state.variant}
      onExited={handleExited}
      onCloseClick={handleClose}
      onNotificationClick={handleClose}
    />
  );
};
