import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { useFormikContext } from 'formik';

import { VppDocumentationAgreementsTestIds as testIds } from '+config/testIds';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store';
import { FormCheckbox, FormSectionParagraph } from '+shared/components';

interface VppDocumentationAgreementsProps {
  isMeterCabinetPreparedFieldVisible: boolean;
}

export const VppDocumentationAgreements: React.VFC<VppDocumentationAgreementsProps> = ({
  isMeterCabinetPreparedFieldVisible,
}) => {
  const form = useFormikContext<VppDocumentationInterface>();

  return (
    <>
      <FormSectionParagraph>
        <div className={'c-vpp-documentation__checkbox'}>
          <FormCheckbox
            form={form}
            name={'pv_reduction'}
            label={I18n.t(T.setupTool.vppDocumentation.fields.agreement1)}
            dataTestId={testIds.pvReductionAgreementCheckbox}
          />
        </div>
      </FormSectionParagraph>

      {isMeterCabinetPreparedFieldVisible && (
        <FormSectionParagraph>
          <div className={'c-vpp-documentation__checkbox'}>
            <FormCheckbox
              form={form}
              name={'meter_cabinet_prepared'}
              label={I18n.t(T.setupTool.vppDocumentation.fields.agreement2)}
              dataTestId={testIds.meterCabinetPreparedAgreementCheckbox}
            />
          </div>
        </FormSectionParagraph>
      )}
    </>
  );
};
