import { range } from 'lodash/fp';

type GetPagesCountOptions = {
  recordsPerPage?: number;
  recordsTotal?: number;
};

export const minMaxBoundaries = 5;
export const middleBoundaries = 2;

export const getPagesCount = ({ recordsTotal, recordsPerPage }: GetPagesCountOptions) =>
  Math.ceil((recordsTotal || 0) / (recordsPerPage || 1));

export const getPages = (min: number) => (max: number) => range(min, max + 1);

export const getPagesToRender = (activePage: number) => (pages: number[]) => {
  const { length } = pages;

  if (isPaginationHead(activePage)) {
    return pages.slice(0, minMaxBoundaries);
  }

  if (isPaginationTail(activePage, length)) {
    return pages.slice(length - minMaxBoundaries, length);
  }

  return pages.slice(activePage - middleBoundaries - 1, activePage + middleBoundaries);
};

export const isPaginationHead = (activePage: number) =>
  0 < activePage && activePage < minMaxBoundaries;

export const isPaginationTail = (activePage: number, length: number) =>
  length - minMaxBoundaries + 1 < activePage && activePage <= length;

export const DOTS: string = '...';

export const getPaginationPages = (activePage: number, totalPages: number) => {
  if (totalPages < 5) {
    return range(1, totalPages + 1);
  } else {
    // if activePage is first/first+1 or last/last-1, show [1, 2, ..., 3, 4]
    if (
      (0 < activePage && activePage < 3) ||
      (totalPages - 2 < activePage && activePage <= totalPages)
    ) {
      const head = [1, 2];
      const tail = [totalPages - 1, totalPages];
      return [...head, DOTS, ...tail];
    }

    // if activePage is third from beginning, show [1, 2, 3, 4, ..., 10]
    if (activePage === 3) {
      const head = [1, 2, 3, 4];
      const tail = [totalPages];
      return [...head, DOTS, ...tail];
    }

    // if activePage is third from end, show [1, ..., 7, 8, 9, 10]
    if (activePage === totalPages - 2) {
      const head = [1];
      const tail = [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      return [...head, DOTS, ...tail];
    }

    // if active page is on any page between the first three or last three: middle three shift
    const middle = [activePage - 1, activePage, activePage + 1];
    return [1, DOTS, ...middle, DOTS, totalPages];
  }
};
