import * as React from 'react';

import { Bubble, Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { Field, FieldProps } from 'formik';

import useElementDimensions from '+shared/hooks/useElementDimensions';
import useInputEvent from '+shared/hooks/useInputEvent';

import { validateField } from '../Form.helpers';
import { FormFieldError } from '../FormFieldError';
import { FormFieldLabel } from '../FormFieldLabel';
import { FormInputIcon, FormInputProps } from './FormInput.types';

import './FormInput.component.scss';

const ObservableInputElement: React.FC<FieldProps> = ({ form, field, ...props }) => {
  const { onBlur, onChange } = useInputEvent(field);

  return <input {...props} {...field} onBlur={onBlur} onChange={onChange} />;
};

export const FormInput = <T extends any = any>({
  form,
  name,
  unit,
  children,
  label,
  info,
  placeholder,
  step,
  type = 'text',
  disabled = false,
  className,
  hasNoGap = false,
  id,
  onInputChange,
  isRequired = true,
  icon,
  warningMessage,
  bottomWarningText = '',
  helperLabel = '',
  onClick,
  readOnly = false,
  dataTestId,
}: FormInputProps<T>) => {
  const { unitElementRef, unitDimensions } = useElementDimensions();
  const [isWarningActive, toggleWarningActivity] = React.useState<boolean>(false);
  const [isInfoActive, toggleInfoActivity] = React.useState<boolean>(false);
  const validation = validateField({ name, form });
  const iconWidth = 40;

  const renderIcon = (icon: FormInputIcon, warningMessage?: string) => {
    switch (icon) {
      case FormInputIcon.CHECKMARK:
        return <Icon.Checkmark className={'c-form-input__icon-checkmark'} />;
      case FormInputIcon.WARNING:
        return (
          <div
            className={classNames('c-form-input__icon-warning-wrapper', {
              'c-form-input__icon-warning-wrapper--hoverable': warningMessage,
            })}
            onMouseOver={() => toggleWarningActivity(true)}
            onMouseOut={() => toggleWarningActivity(false)}
          >
            <Icon.Warning className={'c-form-input__icon-warning'} />
            {isWarningActive && warningMessage && (
              <div className={'c-form-input__bubble-wrapper'}>
                <Bubble side={'top-right'} isThemeDense={false}>
                  {warningMessage}
                </Bubble>
              </div>
            )}
          </div>
        );
      default:
        return;
    }
  };

  const renderInfo = (infoMsg: string) => (
    <div
      className={'c-form-input__info'}
      onMouseOver={() => toggleInfoActivity(true)}
      onMouseOut={() => toggleInfoActivity(false)}
    >
      <Icon.Info className={'c-form-input__info-icon'} />
      {isInfoActive && (
        <div className={'c-form-input__info-bubble-wrapper'}>
          <Bubble side={'top'} isThemeDense={false}>
            {infoMsg}
          </Bubble>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classNames('c-form-input', className, {
        'c-form-input--no-gap': hasNoGap && !validation.hasError,
      })}
    >
      {label && (
        <div
          className={classNames('c-form-input__label-wrapper', {
            'c-form-input__label-wrapper--disabled': disabled,
          })}
        >
          <FormFieldLabel label={label} isRequired={isRequired} isDisabled={disabled}>
            {info && renderInfo(info)}
          </FormFieldLabel>
        </div>
      )}

      <div className={'c-form-input__container'} data-hj-suppress={true}>
        <Field
          component={ObservableInputElement}
          style={{
            paddingRight: unitDimensions
              ? icon
                ? unitDimensions.width + iconWidth
                : unitDimensions.width
              : undefined,
          }}
          className={classNames('c-form-input__field', {
            'c-form-input__field--error': validation.hasError,
            'c-form-input__field--disabled': disabled,
            'c-form-input__field--clickable': onClick,
          })}
          step={step}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          onClick={(e: React.FormEvent) => {
            if (type === 'date') {
              e.preventDefault();
            } // prevents default datePicker from popping up
            if (onClick) {
              onClick();
            }
          }}
          onSelect={
            // should be onChange instead of onSelect but there is a bug in Formik
            onInputChange
              ? (event: React.BaseSyntheticEvent) => {
                  onInputChange(event.target.value);
                }
              : undefined
          }
          readOnly={readOnly}
          data-testid={dataTestId}
        />

        {unit && (
          <div
            className={classNames('c-form-input__unit-wrapper', {
              'c-form-input__unit-wrapper--gap-right': icon,
            })}
            ref={unitElementRef}
          >
            <span className={'c-form-input__unit'}>{unit}</span>
          </div>
        )}
        {icon && (
          <div className={'c-form-input__icon-wrapper'}>{renderIcon(icon, warningMessage)}</div>
        )}
        <div className={'c-form-input__component'}>{children}</div>
      </div>
      <FormFieldError name={name} form={form} />
      {helperLabel && !validation.hasError && (
        <p className="c-form-input__helper-label">{helperLabel}</p>
      )}
      {bottomWarningText && (
        <div
          className={classNames('c-form-input__bottom-warning', {
            'c-form-input__bottom-warning--lowered': validation.hasError,
          })}
        >
          <div className={'c-form-input__bottom-warning-icon-wrapper'}>
            <Icon.Warning className={'c-form-input__bottom-warning-icon'} />
          </div>
          {bottomWarningText}
        </div>
      )}
    </div>
  );
};
