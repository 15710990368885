import { AdobeDataLayer, Events, Metrics } from './adobeAnalytics.type';

export const AA_LOAD_EVENT = 'loadAdobeAnalytics';

export const trackLogin = (userRole: string, userId: string): void => {
  pushEvent({
    event: Events.LOGIN,
    metric: Metrics.ATTRACT,
    login: {
      userRole,
      userId,
    },
  });
};

export const trackLogout = (userRole: string, userId: string): void => {
  pushEvent({
    event: Events.LOGOUT,
    metric: Metrics.ATTRACT,
    logout: {
      userRole,
      userId,
    },
  });
};

export const pushEvent = (dataLayer: AdobeDataLayer): void => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(dataLayer);

  window.dispatchEvent(new CustomEvent(AA_LOAD_EVENT));
};
