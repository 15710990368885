import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Search: React.FC<Props> = ({ color, size = 24, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 9.5C17 13.0899 14.0899 16 10.5 16C6.91015 16 4 13.0899 4 9.5C4 5.91015 6.91015 3 10.5 3C14.0899 3 17 5.91015 17 9.5ZM14.881 15.5881C13.6485 16.4766 12.1354 17 10.5 17C6.35786 17 3 13.6421 3 9.5C3 5.35786 6.35786 2 10.5 2C14.6421 2 18 5.35786 18 9.5C18 11.6456 17.0991 13.5807 15.6548 14.9477L20.8536 20.1464C21.0488 20.3417 21.0488 20.6583 20.8536 20.8536C20.6583 21.0488 20.3417 21.0488 20.1464 20.8536L14.881 15.5881Z"
      fill={color}
    />
  </svg>
);
