import * as React from 'react';
import { connect } from 'react-redux';

import { TableCell } from '@sonnen/shared-web';

import { Contact } from '+shared/store/contact/types';
import { Role } from '+shared/store/role/types/role.interface';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { ContactRole } from '../../components/ContactRole';
import { ContactListPageActions } from '../../store';
import {
  getContactAssignRoleQueryStatus,
  getContactDenyRoleQueryStatus,
  getCurrentlyEditedRole,
} from '../../store/+contactList.selectors';
import { sortedRoles } from './ContactListTableItem.helper';

import './ContactListTableItem.component.scss';

const mapStateToProps = (state: StoreState) => ({
  contactAssignRoleQueryStatus: getContactAssignRoleQueryStatus(state),
  contactDenyRoleQueryStatus: getContactDenyRoleQueryStatus(state),
  currentlyEditedRole: getCurrentlyEditedRole(state),
});

const mapDispatchToProps = mapActions({
  assignRole: ContactListPageActions.assignRole,
  denyRole: ContactListPageActions.denyRole,
});

interface ItemProps {
  item: Contact;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ItemProps;

const ContactListTableItemComponent: React.FC<Props> = ({
  item,
  contactAssignRoleQueryStatus,
  contactDenyRoleQueryStatus,
  actions,
  currentlyEditedRole,
}) => {
  const isCurrentlyEditedRole = item.id === currentlyEditedRole.contactId;
  const isError =
    isCurrentlyEditedRole &&
    (contactAssignRoleQueryStatus.error || contactDenyRoleQueryStatus.error);

  const isLoading = (role: Role) =>
    isCurrentlyEditedRole &&
    role.name === currentlyEditedRole.roleName &&
    (contactAssignRoleQueryStatus.pending || contactDenyRoleQueryStatus.pending);

  const onChange = (role: Role, isChecked: boolean) =>
    isChecked ? actions.denyRole(role, item.id) : actions.assignRole(role, item.id);

  return (
    <div className={`c-contact-list-table-item`} data-hj-suppress={true}>
      <TableCell className={`c-contact-list-table-item__cell`}>
        {item.firstName} {item.lastName}
      </TableCell>
      <TableCell className={`c-contact-list-table-item__cell`}>{item.email}</TableCell>
      <TableCell className={`c-contact-list-table-item__cell`}>{item.accountName}</TableCell>
      <TableCell className={`c-contact-list-table-item__cell`}>
        {item.salesforceContactId}
      </TableCell>
      <TableCell className={`c-contact-list-table-item__cell`}>
        <div className={'c-contact-list-table-item__roles'}>
          {sortedRoles(item).map((role) => (
            <ContactRole
              key={`${item.id}-${role.name}`}
              role={role}
              isChecked={!!role.id}
              isLoading={isLoading(role)}
              onChange={onChange}
            />
          ))}
          {isError && <div className={'c-contact-list-table-item__error'}>Error</div>}
        </div>
      </TableCell>
    </div>
  );
};

export const ContactListTableItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactListTableItemComponent);
