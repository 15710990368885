import * as React from 'react';

import * as classNames from 'classnames';

import { CaseListHelper } from '+app/+case/containers/CaseList/CaseList.helper';
import {
  CaseAggregatedStatus,
  CaseStatus as CaseStatusProps,
} from '+shared/store/case/types/case.interface';

import { setCaseStatusAggregated } from './CaseStatus.component.helper';

import './CaseStatus.component.scss';

type Props = {
  status: CaseStatusProps;
  dataTestId?: string;
};

export const CaseStatus: React.FC<Props> = ({ status, dataTestId }) => {
  const aggregatedStatus = React.useMemo(() => CaseListHelper.getAggregatedName(status), []);

  return (
    <div className={'c-case-status'} data-testid={dataTestId}>
      <div
        className={classNames('c-case-status__trigger', {
          'c-case-status__trigger--open': aggregatedStatus === CaseAggregatedStatus.OPEN,
          'c-case-status__trigger--resolved': aggregatedStatus === CaseAggregatedStatus.RESOLVED,
          'c-case-status__trigger--in-progress':
            aggregatedStatus === CaseAggregatedStatus.IN_PROGRESS,
        })}
      >
        {aggregatedStatus ? setCaseStatusAggregated(aggregatedStatus) : '-'}
      </div>
    </div>
  );
};
