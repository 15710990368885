import React from 'react';

type Props = {
  color?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
};

export const ArrowDown: React.FC<Props> = ({ color, width, height, className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 14 5" fill="none">
    <path d="M7 5L0 0L14 1.17772e-06L7 5Z" fill={color} />
  </svg>
);
