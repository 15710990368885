import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { BatteryModelName } from '+shared/store/lead/types';

import { ConfigurationBatteryForm } from '../types';

export const ConfigurationBatterySchema = () =>
  Yup.object().shape({
    capacityGross: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    modelName: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    newBattery: Yup.boolean().required(I18n.t(T.register.alert.requiredField)),
  });

export const configurationBatteryFormInitial = (
  isNewBatteryAllowed: boolean
): ConfigurationBatteryForm => ({
  capacityGross: '',
  modelName: BatteryModelName.SONNENBATTERIE_10_AC,
  newBattery: isNewBatteryAllowed,
});
