import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import './ErrorMessage.component.scss';

interface Props {
  message?: string;
}

export const ErrorMessage: React.FC<Props> = ({
  message = I18n.t(T.general.errors.submitForm),
}) => <span className={'c-error-message'}>{message}</span>;
