import uuid from 'uuid';

import { createPersistedReducer } from '+app/utils';
import { CUSTOMER_ACTIONS, CustomerActions } from '+customer/store';
import { TariffWindow, tariffWindowManipulator } from '+customer-battery/store/battery-tariff';
import { BATTERY_ACTIONS, BatteryActions } from '+shared/store/battery';
import { queryReducer } from '+shared/store/query';

import {
  BatteryPageState,
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  GET_BATTERY_QUERY,
  GET_BATTERY_STATUSES_QUERY,
  initialState,
  POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY,
  POST_BATTERY_INTELLIGENT_CHARGING_QUERY,
  POST_BATTERY_OPERATING_MODE_QUERY,
  PUT_BATTERY_NOTE_QUERY,
} from './+battery.state';

export const reducer = createPersistedReducer<BatteryPageState, BatteryActions | CustomerActions>(
  (state = initialState, action): BatteryPageState => {
    switch (action.type) {
      case BATTERY_ACTIONS.SET_BATTERY:
        const timeOfUseSchedule = tariffWindowManipulator.convertTariffRes(
          action?.battery?.timeOfUseSchedule,
          uuid.v4
        );

        const highTariffWindows = tariffWindowManipulator.convertTariffRes(
          action?.battery?.highTariffWindows,
          uuid.v4
        );

        const data = tariffWindowManipulator.sortTariffWindows([
          ...timeOfUseSchedule,
          ...highTariffWindows,
        ]);

        return {
          ...state,
          isTariffWindowScheduleEditedButNotSaved: false,
          tariffWindows: data,
          tariffWindowsBackUp: data,
          battery: action.battery,
        };
      case BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS:
        return {
          ...state,
          batteryElectricUnits: action.batteryElectricUnits,
        };
      case BATTERY_ACTIONS.SET_BATTERY_IC_STATUS:
        return {
          ...state,
          batteryIcStatus: action.batteryIcStatus,
        };
      case BATTERY_ACTIONS.SET_IS_BATTERY_INTELLIGENT_CHARGING_IN_PROGRESS:
        return {
          ...state,
          isBatteryIntelligentChargingInProgress: action.isBatteryIntelligentChargingInProgress,
        };
      case BATTERY_ACTIONS.SET_IS_BATTERY_OPERATING_MODE_IN_PROGRESS:
        return {
          ...state,
          isBatteryOperatingModeInProgress: action.isBatteryOperatingModeInProgress,
        };
      case BATTERY_ACTIONS.SET_BATTERY_BACKUP_BUFFER_IN_PROGRESS:
        return {
          ...state,
          isBatteryBackupBufferInProgress: action.isBatteryBackupBufferInProgress,
        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      case BATTERY_ACTIONS.BATTERY_TARIFF_WINDOW_EDIT:
        return {
          ...state,
          editedTariffWindow: action.tariffWindow,
        };
      case BATTERY_ACTIONS.SINGLE_BATTERY_TARIFF_WINDOW_EDIT:
        const newTariffWindows: TariffWindow[] = state.tariffWindows.map((value) => {
          if (value.id === action.tariffWindow.id) {
            return action.tariffWindow;
          } else {
            return value;
          }
        });
        return {
          ...state,
          isTariffWindowScheduleEditedButNotSaved: true,
          tariffWindows: tariffWindowManipulator.sortTariffWindows(newTariffWindows),
        };
      case BATTERY_ACTIONS.SINGLE_BATTERY_TARIFF_WINDOW_DELETE:
        return {
          ...state,
          isTariffWindowScheduleEditedButNotSaved: true,
          tariffWindows: state.tariffWindows.filter((value) => value.id !== action.tariffWindowId),
        };
      case BATTERY_ACTIONS.SINGLE_BATTERY_TARIFF_WINDOW_NEW:
        return {
          ...state,
          isTariffWindowScheduleEditedButNotSaved: true,
          tariffWindows: tariffWindowManipulator.sortTariffWindows([
            ...state.tariffWindows,
            action.tariffWindow,
          ]),
        };
      case BATTERY_ACTIONS.UPDATE_TARIFF_WINDOW_SCHEDULE_EDIT:
        return {
          ...state,
          isTariffWindowScheduleEditedButNotSaved: action.isTariffWindowScheduleEditedButNotSaved,
        };
      case BATTERY_ACTIONS.RESET_TARIFF_WINDOW_LOCAL_CHANGES: {
        return {
          ...state,
          tariffWindows: state.tariffWindowsBackUp,
        };
      }
      case BATTERY_ACTIONS.UPDATE_TARIFF_WINDOW_LOCAL_CHANGES: {
        return {
          ...state,
          tariffWindowsBackUp: state.tariffWindows,
        };
      }
      case BATTERY_ACTIONS.SET_IS_BATTERY_REMOTE_SETTINGS_ALLOWED:
        return {
          ...state,
          isBatteryRemoteSettingsAllowed: action.isBatteryRemoteSettingsAllowed,
        };
      default:
        return queryReducer(state, action, [
          GET_BATTERY_QUERY,
          GET_BATTERY_ELECTRIC_UNITS_QUERY,
          PUT_BATTERY_NOTE_QUERY,
          GET_BATTERY_STATUSES_QUERY,
        ]);
    }
  }
)({
  key: 'customer-page-battery',
  blacklist: [
    GET_BATTERY_QUERY,
    GET_BATTERY_ELECTRIC_UNITS_QUERY,
    PUT_BATTERY_NOTE_QUERY,
    POST_BATTERY_INTELLIGENT_CHARGING_QUERY,
    POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY,
    'isBatteryIntelligentChargingInProgress',
    POST_BATTERY_OPERATING_MODE_QUERY,
    'isBatteryOperatingModeInProgress',
  ],
});
