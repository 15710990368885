import React, { RefObject, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Close, Search } from '+shared/basicComponents/Icons';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { BodyMedium, BodySmall } from '+shared/basicComponents/Typography/Bodies';
import Checkbox from '+shared/components/Checkbox/Checkbox';
import DropdownWrapper from '+shared/containers/DropdownWrapper/DropdownWrapper';

import {
  Button,
  ButtonSize,
  ButtonStatus,
  ButtonType,
  MainType,
} from '../../../../../../shared/components/Button';
import { SelectablePostcode } from '../SitesFiltersArea';

import './FilterDropdownPostcodeSearchAndFilter.scss';

const {
  postcodeTitle,
  searchPostcodeMessage,
  noResultsFoundMessage,
  cancelSearchButton,
  applyFiltersButton,
  filtersSelected,
} = T.customers.filter.dropdownMenu;

type Props = {
  postcodeSearchInputRef: RefObject<HTMLInputElement>;
  postcodeSearchValue: string;
  postcodesFound: SelectablePostcode[];
  arePostcodesSelected: boolean;
  isPostalCodeDropdownOpen: boolean;
  handlePostcodeSearchValue: () => void;
  selectPostcode: (id: string) => void;
  applyPostcodeFilters: () => void;
  resetPostcodeSearchValue: () => void;
  cancelPostcodeSearch: () => void;
  toggleDropdown: (isOpen?: boolean) => void;
  appliedPostcodesCount: number;
  isLoading: boolean;
  inputPlaceholder: string;
  inputLength: number;
};

const FilterDropdownPostcodeSearchAndFilter: React.FC<Props> = ({
  postcodeSearchInputRef,
  postcodeSearchValue,
  postcodesFound,
  arePostcodesSelected,
  isPostalCodeDropdownOpen,
  handlePostcodeSearchValue,
  selectPostcode,
  applyPostcodeFilters,
  resetPostcodeSearchValue,
  cancelPostcodeSearch,
  toggleDropdown,
  isLoading,
  appliedPostcodesCount,
  inputPlaceholder,
  inputLength,
}) => {
  const widthDropdownMenuAndContent = 230;

  const selected = `(${appliedPostcodesCount} ${I18n.t(filtersSelected)})`;
  const titleWithSelectionCount =
    I18n.t(postcodeTitle) + ` ${appliedPostcodesCount > 0 ? selected : ''}`;

  useEffect(() => {
    // UX: when user opens dropdown, input automatically receives focus
    if (isPostalCodeDropdownOpen && postcodeSearchInputRef) postcodeSearchInputRef.current?.focus();
  }, [isPostalCodeDropdownOpen, postcodeSearchInputRef]);

  return (
    <DropdownWrapper
      label={titleWithSelectionCount}
      width={widthDropdownMenuAndContent}
      isDropdownOpen={isPostalCodeDropdownOpen}
      toggleDropdown={toggleDropdown}
    >
      <div
        className="reusable-class__dropdown-content postcode-search-select__list"
        style={{ width: widthDropdownMenuAndContent }}
      >
        <div className="postcode-search__input" data-testid="post-code-dropdown">
          <input
            id="postcode-search"
            name="postcode-search"
            maxLength={inputLength}
            autoComplete="off"
            placeholder={inputPlaceholder}
            ref={postcodeSearchInputRef}
            value={postcodeSearchValue}
            onChange={handlePostcodeSearchValue}
            data-testid="post-code-field"
          />
          <button
            type="reset"
            className="postcode-search__input--button"
            data-testid="search-button-postal-code"
            onClick={resetPostcodeSearchValue}
          >
            {postcodeSearchValue ? <Close /> : <Search />}
          </button>
        </div>

        {isLoading && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}

        {postcodesFound.length && !isLoading ? (
          <>
            <ul className="postcode-search__dropdown-content">
              {postcodesFound.map((postcode: SelectablePostcode, index: number) => (
                <li key={postcode.id} className="postcode-search__dropdown-content--result">
                  <Checkbox
                    id={postcode.id}
                    name="postcode-select"
                    isChecked={postcode.selected}
                    className="label-takes-full-width-of-parent"
                    onChange={() => selectPostcode(postcode.id)}
                    label={highlightMatchingSubstring(postcodeSearchValue, postcode.postalCode)}
                    dataTestId={`postal-code-check-box-${index}`}
                  />
                </li>
              ))}
            </ul>

            {postcodeSearchValue && !isLoading && (
              // don't show buttons if no search input has been entered
              <div className="postcode-search__buttons-container">
                <Button
                  type={ButtonType.TERTIARY}
                  mainType={MainType.RESET}
                  size={ButtonSize.MEDIUM}
                  label={I18n.t(cancelSearchButton)}
                  onClick={cancelPostcodeSearch}
                  className="cancel-postcode-selection"
                  dataTestId="cancel-postcode-selection"
                />
                <Button
                  type={ButtonType.PRIMARY}
                  mainType={MainType.SUBMIT}
                  size={ButtonSize.SMALL}
                  status={arePostcodesSelected ? ButtonStatus.ENABLED : ButtonStatus.DISABLED}
                  label={I18n.t(applyFiltersButton)}
                  onClick={applyPostcodeFilters}
                  dataTestId="apply-postcode-selection"
                  disabled={!arePostcodesSelected}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {!isLoading && postcodeSearchValue && !postcodesFound.length && (
              <BodyMedium text={I18n.t(noResultsFoundMessage)} className="no-postcode-found" />
            )}
            {!isLoading && !postcodeSearchValue && !postcodesFound.length && (
              <BodySmall
                text={I18n.t(searchPostcodeMessage)}
                className="postcode-input-helper-text"
              />
            )}
          </>
        )}
      </div>
    </DropdownWrapper>
  );
};

export default FilterDropdownPostcodeSearchAndFilter;

/*
 * Helpers
 */
function highlightMatchingSubstring(input: string, postcode: string): JSX.Element | void {
  if (!postcode.startsWith(input)) return;

  const restOfString = postcode.substring(input.length);

  return (
    <span className="postcode-label">
      <span className="search-match">{input}</span>
      {restOfString}
    </span>
  );
}
