import React, { useState } from 'react';

import { Bubble } from '@sonnen/shared-web/src/components/Bubble/Bubble.component';
import { breakpointUp } from '@sonnen/shared-web/src/utils/breakpoints';

import classNames from 'classnames';

import { StatusTileColors } from '.';

import './StatusTile.component.scss';

export interface StatusTileProps {
  label: string | JSX.Element;
  color?: StatusTileColors;
  id?: string;
  additionalClass?: string;
  compact?: boolean;
  transparent?: boolean;
  inline?: boolean;
  tooltipText?: string;
  onClick?: () => void;
  noMargin?: boolean;
  hasDot?: boolean;
  dataTestId?: string;
}

export const StatusTile: React.FC<StatusTileProps> = ({
  label,
  color = StatusTileColors.GRAY,
  additionalClass,
  compact = false,
  transparent = false,
  tooltipText,
  id,
  onClick,
  noMargin = false,
  hasDot = false,
  dataTestId,
}) => {
  const [tooltipActive, setTooltipActive] = useState(false);

  return (
    <div
      onMouseOver={() => setTooltipActive(true)}
      onMouseOut={() => setTooltipActive(false)}
      onClick={onClick}
      className={classNames('c-status-tile', {
        'c-status-tile--compact': compact,
        'c-status-tile--hoverable': tooltipText || onClick,
        'c-status-tile--no-margin': noMargin,
      })}
      data-testid={dataTestId}
    >
      <div
        id={id}
        className={classNames('c-status-tile__status-wrapper', {
          [`${additionalClass}`]: additionalClass,
          [`c-status-tile__status-wrapper--${color}`]: color,
          'c-status-tile__status-wrapper--transparent': transparent,
        })}
      >
        {hasDot && <span className={'c-status-tile__dot'} />}
        {label}
      </div>
      {tooltipText && tooltipActive && (
        <div className={'c-status-tile__bubble-wrapper'}>
          <Bubble
            side={window.innerWidth >= breakpointUp('SM') ? 'top' : 'top-left'}
            isThemeDense={false}
          >
            {tooltipText}
          </Bubble>
        </div>
      )}
    </div>
  );
};
