import React from 'react';

type Props = {
  color?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
};

export const ArrowUp: React.FC<Props> = ({ color, width, height, className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 14 5" fill="none">
    <path d="M7 0L14 5H0L7 0Z" fill={color} />
  </svg>
);
