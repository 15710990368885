import { removeEqualProps } from '../utils';
import { LayoutParams, PARENT_ID } from './layout-params';
export var resolveLayoutParamsIds = function (layoutParams, context) {
    if (!layoutParams.dependenciesModified) {
        return;
    }
    layoutParams.topId = context.resolve(layoutParams.topId);
    layoutParams.bottomId = context.resolve(layoutParams.bottomId);
    layoutParams.belowId = context.resolve(layoutParams.belowId);
    layoutParams.aboveId = context.resolve(layoutParams.aboveId);
    layoutParams.startId = context.resolve(layoutParams.startId);
    layoutParams.endId = context.resolve(layoutParams.endId);
    layoutParams.toStartOfId = context.resolve(layoutParams.toStartOfId);
    layoutParams.toEndOfId = context.resolve(layoutParams.toEndOfId);
    layoutParams.dependenciesModified = false;
};
export var resolveDimensionDependencies = function (children, dependencySelector, context) {
    var dimension = children.map(function (child) { return ({
        view: child,
        id: child.id,
        dependencies: dependencySelector(child.getLayoutParams()),
    }); });
    var orderedDimension = [];
    var unresolvedDependencies = function (depId) { return !orderedDimension.find(function (v) { return v.id === depId; }); };
    while (dimension.length) {
        var dependenciesResolvedInCycle = 0;
        for (var i = dimension.length - 1; i >= 0; i--) {
            var child = dimension[i];
            child.dependencies = child.dependencies.filter(unresolvedDependencies);
            if (!child.dependencies.length) {
                dependenciesResolvedInCycle++;
                orderedDimension.push(child.view);
                dimension.splice(i, 1);
            }
        }
        if (dependenciesResolvedInCycle === 0) {
            var getChildById = function (dependency) { return ({
                view: children.find(function (child) { return child.id === dependency.id; }),
                dependencies: dependency.dependencies,
            }); };
            throw new Error("Dependencies between views can't be resolved. " +
                "This may be a result of a circular dependency or not including referred view in a container. " +
                "Children that can't be processed:\n" +
                dimension
                    .map(getChildById)
                    .map(function (child) { return ("".concat(child.view.name, "[").concat(child.view.id || '-', "] that has unresolved references to \"") +
                    child.dependencies
                        .map(function (id) {
                        var child = children.find(function (child) { return child.id === id; });
                        return "".concat(context.getId(id), ": ").concat(child ? child.name : 'a view that is not a sibling');
                    })
                        .join('", "') +
                    "\""); })
                    .join('\n'));
        }
    }
    return orderedDimension;
};
var existingNonParentDependency = function (id) { return id !== undefined && id !== PARENT_ID; };
export var horizontalLayoutDependencies = function (lp) { return ([
    lp.startId,
    lp.toStartOfId,
    lp.endId,
    lp.toEndOfId,
]).filter(existingNonParentDependency); };
export var verticalLayoutDependencies = function (lp) { return ([
    lp.topId,
    lp.aboveId,
    lp.bottomId,
    lp.belowId,
]).filter(existingNonParentDependency); };
var defaultLayoutProps = new LayoutParams().asProps();
export var removeDefaultProps = function (props) { return removeEqualProps(props, defaultLayoutProps); };
