import { FileCategoryName, FileMimeType } from '+setupTool/+form/store';
import {
  CouplingType,
  DsoRegistrationSetupDataStatusesInterface,
} from '+setupTool/+form/store/types';
import { PvRegisterState } from '+setupTool/+pvRegister/store/types';
import { VppState } from '+setupTool/+vppDocumentation/store/types';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { RegistrationSubjectType, SetupToolStepsUpdatedAt } from '+setupTool/store/types';

export enum AdditionalFeatures {
  DSO_REFERENCE_NUMBER_PV_FIELD = 'dsoReferenceNumberPvField',
  VPP_COMPLETION_NOTIFICATION = 'vppCompletionNotification',
  METER_CABINET_PREPARED_FIELD = 'meterCabinetPreparedField',
  FASTENING_TYPE_CONSUMPTION_FIELD = 'fasteningTypeConsumptionField',
  PRODUCTION_METER_FIELD = 'productionMeterField',
  GSM_MEASUREMENTS_REQUIRED_FIELDS = 'gsmMeasurementsRequiredFields',
  GSM_MEASUREMENTS_OPTIONAL_FIELDS = 'gsmMeasurementsOptionalFields',
  VPP_WIRING_DIAGRAM = 'vppWiringDiagram',
}

export type AdditionalFeaturesValuesUnion = `is${Capitalize<AdditionalFeatures>}Visible`;

export enum DocumentSource {
  INPUT = 'input',
  OUTPUT = 'output',
}

type DocumentUpload = {
  category: FileCategoryName;
  customerId: string;
  extension: string;
  fileName: string;
  id: string;
  isPublic: boolean;
  mimeType: FileMimeType;
  type: number;
  url: string;
  userId: string;
};

export interface Document {
  fileName: string;
  id: string;
  source: DocumentSource;
  status: boolean;
  upload: DocumentUpload;
  stepType?: SubmissionStep;
}

export interface Dso {
  portalType: string;
  salesSolutionId: string;
  name: string;
  docSpringId: string;
  id: string;
}

export interface Installers {
  id: string;
  company: string;
  firstName: string;
  lastName: string;
  fullName: string;
  companyStreet: string;
  companyStreetNumber: string;
  zipCode: string;
  city: string;
  email: string;
  phone: string;
  certificationNumber: string;
  certificationAuthority: string;
}

export interface DsoRegistrationFormResponse {
  id: string;
  userId: string;
  customerId: string;
  documents: Document[];
  createdAt: string;
  updatedAt: string;
  fields: any;
  pvr: any;
  vpp: object;
  vppState: VppState;
  pvrState: PvRegisterState;
  additionalFeatures: AdditionalFeatures[];
  vppAdditionalFeatures: AdditionalFeatures[];
  stepsUpdatedAt: SetupToolStepsUpdatedAt;
  setupDataStatuses: DsoRegistrationSetupDataStatusesInterface;
  registrationSubject: RegistrationSubjectType;
}

export interface DsoRegistrationPatchSubmissionResponse {
  submission: DsoRegistrationFormResponse;
}

export interface DsoListResponse {
  dsos: Dso[];
}

export interface PVModuleResponse {
  id: string;
  pvModuleType: string;
  vendorId: string;
  type: string;
  peakPowerWp: number;
}

export interface PVInverterResponse {
  id: string;
  inverterType: string;
  vendorId: string;
  type: string;
}

export interface BatteryNames {
  name: string;
}

export interface BatteriesResponseAttributes {
  attributes: {
    id: string;
    name: string;
    nominalCapacitykWh: number;
    nominalPowerkW: number;
    couplingType: CouplingType;
  };
}
