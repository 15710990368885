import React, { useEffect, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { HttpResponse } from '@coolio/http';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CountryCode } from 'src/types/country.type';

import { Config } from '+config/config';
import { BodyLarge } from '+shared/basicComponents/Typography/Bodies';
import { Button, ButtonSize, ButtonType, MainType } from '+shared/components/Button';
import { httpClient } from '+shared/network/network.client';
import { getPostcodesValidations } from '+utils/market.util';

import { PartnerSitesGeoLocation } from '../../../store/types/partnerSitesCustomer.interface';
import AppliedFiltersSection from './AppliedFiltersSection/AppliedFiltersSection';
import FilterDropdownMeterInstallationStatuses from './FilterDropdownMeterInstallationStatuses/FilterDropdownMeterInstallationStatuses';
import FilterDropdownPostcodeSearchAndFilter from './FilterDropdownPostcodeSearchAndFilter/FilterDropdownPostcodeSearchAndFilter';

import './SitesFiltersArea.scss';

const { filterBy, resetAllFiltersButton } = T.customers.filter;

export type FilterType = 'postalCodes' | 'meterInstallationStatuses' | 'all';

export type PartnerSitesGeoLocationResponse = {
  type: string;
  id: string;
  attributes: {
    postalCode: string;
    countryCode: string | null;
    city: string | null;
  };
};

export type Translations = {
  [key: string]: {
    title: string;
    content: string;
  };
};

export const meterInstallationTranslations: Translations = {
  missing: {
    title: T.warning.meterPreparationMissing,
    content: T.warning.meterPreparationMissingExtended,
  },
  in_clearing: {
    title: T.warning.meterPreparationInClearing,
    content: T.warning.meterPreparationInClearingExtended,
  },
  no: {
    title: T.warning.fstNotYetTested,
    content: T.warning.fstNotYetTestedExtended,
  },
};
export interface SelectablePostcode extends PartnerSitesGeoLocation {
  selected: boolean;
}

export interface SelectableMeterInstallationStatus {
  [key: string]: boolean;
}

type Props = {
  appliedFilters: AppliedFilters;
  buildQueryParams: (appliedFilters: AppliedFilters) => void;
  countryCode: string;
};

export interface AppliedFilters {
  postalCodes: Set<string> | undefined;
  meterPreparationStatuses: string[] | undefined;
  fstCheckSuccessful: string | undefined;
}

const SitesFiltersArea: React.FC<Props> = ({ appliedFilters, buildQueryParams, countryCode }) => {
  const featureFlags = useFlags();

  const { placeholder, length, regEx } = getPostcodesValidations(countryCode as CountryCode);

  const postcodeSearchInputRef = useRef<HTMLInputElement>(null);

  const [postcodeSearchValue, setPostcodeSearchValue] = useState<string>('');
  const [postcodesFound, setPostcodesFound] = useState<SelectablePostcode[]>([]);

  const [meterPreparationStatusesState, setMeterPreparationStatusesState] =
    useState<SelectableMeterInstallationStatus>({ missing: false, in_clearing: false });
  const [fstCheckSuccessfulState, setFstCheckSuccessfulState] =
    useState<SelectableMeterInstallationStatus>({ no: false });

  const [arePostcodesSelected, setArePostcodesSelected] = useState<boolean>(false);
  const [areFiltersApplied, setAreFiltersApplied] = useState<boolean>(false);
  const [isPostalCodeDropdownOpen, setIsPostalCodeDropdownOpen] = useState<boolean>(false);
  const [isMeterPrepDropdownOpen, setIsMeterPrepDropdownOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const appliedPostcodesCount = appliedFilters.postalCodes?.size || 0;
  const appliedMeterInstallationCount = getAppliedMeterInstallationStatusesCount(appliedFilters);

  useEffect(() => {
    const { meterPreparationStatuses, fstCheckSuccessful: fstCheckSuccessfulFilter } =
      appliedFilters;

    // if no filters are applied, "reset all filters" button is not displayed
    setAreFiltersApplied(anyFiltersAreApplied(appliedFilters));

    // map meterPreparationStatus and fstCheckSuccessful filters from URL to component state
    const updatedMeterPreparationStatuses = { ...meterPreparationStatusesState };
    const updatedFstCheckSuccessful = { ...fstCheckSuccessfulState };

    Object.keys(meterPreparationStatusesState).forEach((key) => {
      updatedMeterPreparationStatuses[key] = Boolean(meterPreparationStatuses?.includes(key));
    });
    updatedFstCheckSuccessful.no = Boolean(fstCheckSuccessfulFilter);

    setMeterPreparationStatusesState(updatedMeterPreparationStatuses);
    setFstCheckSuccessfulState(updatedFstCheckSuccessful);
  }, [appliedFilters]);

  // POSTCODE HANDLERS
  const handlePostcodeSearchValue = async () => {
    const input = String(postcodeSearchInputRef.current?.value);

    if (input.length <= length && regEx.test(input)) {
      setPostcodeSearchValue(input.toUpperCase());

      if (!input.length) {
        setPostcodesFound([]);
      } else {
        let foundPostcodes: PartnerSitesGeoLocation[];

        setIsLoading(true);

        const { data } = await httpClient
          .get<{ data: PartnerSitesGeoLocationResponse[] }>(
            `${Config.API_URL}/partners/sites-geo-locations?filter%5Bcriteria%5D=${input}`
          )
          .then((res: HttpResponse<any>) => res.parsedBody());

        setIsLoading(false);

        foundPostcodes = data.map((item: PartnerSitesGeoLocationResponse) => {
          const newItem: PartnerSitesGeoLocation = {
            id: item.id,
            type: item.type,
            postalCode: item.attributes.postalCode,
            countryCode: item.attributes.countryCode,
            city: item.attributes.city,
            self: '',
          };
          return newItem;
        });

        let foundPostcodesExtended: SelectablePostcode[] = [];

        foundPostcodesExtended = foundPostcodes.map((postcode: PartnerSitesGeoLocation) => ({
          ...postcode,
          selected: false,
        }));

        setPostcodesFound(foundPostcodesExtended);
      }
    }
  };
  const selectPostcode = (id: string) => {
    const selectedPostcodes = postcodesFound.map((postcode) =>
      postcode.id === id ? { ...postcode, selected: !postcode.selected } : { ...postcode }
    );

    // find out if any postcodes are selected to disable "Apply" button in dropdown
    const selectedPostcodesCount = Boolean(
      selectedPostcodes.filter((postcode) => postcode.selected).length
    );

    setPostcodesFound(selectedPostcodes);
    setArePostcodesSelected(selectedPostcodesCount);
  };
  const applyPostcodeFilters = () => {
    let noPostcodesSelected = true;

    // using Set prevents selected filters that are already applied, to become duplicates
    const appliedPostcodes: Set<string> = appliedFilters.postalCodes
      ? appliedFilters.postalCodes
      : new Set();

    postcodesFound.forEach((postcode: SelectablePostcode) => {
      if (postcode.selected) {
        appliedPostcodes.add(postcode.postalCode);
        noPostcodesSelected = false;
      }
    });

    if (noPostcodesSelected) return;
    if (appliedPostcodes) buildQueryParams({ ...appliedFilters, postalCodes: appliedPostcodes });

    setPostcodeSearchValue('');
    setPostcodesFound([]);
    setIsPostalCodeDropdownOpen(false);
    setArePostcodesSelected(false);
  };
  const resetPostcodeSearchValue = () => {
    // UX: after resetting search value, input field automatically receives focus again
    postcodeSearchInputRef.current?.focus();
    setPostcodeSearchValue('');
    setPostcodesFound([]);
    setArePostcodesSelected(false);
  };
  const cancelPostcodeSearch = () => {
    resetPostcodeSearchValue();
    setIsPostalCodeDropdownOpen(false);
    setArePostcodesSelected(false);
  };

  // METER INSTALLATION STATUS HANDLER
  const applyMeterInstallationStatusFilter = (status: string) => {
    const updatedMeterPrepState = { ...meterPreparationStatusesState };
    const updatedFstCheckSuccessful = { ...fstCheckSuccessfulState };

    if (status in meterPreparationStatusesState) {
      updatedMeterPrepState[status] = !meterPreparationStatusesState[status];
      setMeterPreparationStatusesState(updatedMeterPrepState);
    } else {
      updatedFstCheckSuccessful[status] = !fstCheckSuccessfulState[status];
      setFstCheckSuccessfulState(updatedFstCheckSuccessful);
    }

    const meterPrepStatusesArray = Object.keys(updatedMeterPrepState).filter(
      (key) => updatedMeterPrepState[key]
    );

    const updatedFilters: AppliedFilters = {
      ...appliedFilters,
      meterPreparationStatuses: meterPrepStatusesArray.length ? meterPrepStatusesArray : undefined,
      fstCheckSuccessful: updatedFstCheckSuccessful.no ? 'no' : undefined,
    };

    buildQueryParams(updatedFilters);
  };

  // RESET FILTER(S) HANDLER
  const removeSingleFilter = (filter: string, filterType: FilterType) => {
    const { postalCodes, meterPreparationStatuses } = appliedFilters;
    const updatedFilters = { ...appliedFilters };

    if (filterType === 'postalCodes') {
      postalCodes!.size > 1
        ? updatedFilters.postalCodes!.delete(filter)
        : (updatedFilters.postalCodes = undefined);
    }
    if (filterType === 'meterInstallationStatuses') {
      if (filter === appliedFilters.fstCheckSuccessful) {
        updatedFilters.fstCheckSuccessful = undefined;
      } else {
        updatedFilters.meterPreparationStatuses =
          meterPreparationStatuses?.length === 1
            ? undefined
            : meterPreparationStatuses?.filter((status) => status !== filter);
      }
    }
    buildQueryParams(updatedFilters);
  };
  const resetFilters = (filterType: FilterType) => {
    const updatedFilters: AppliedFilters = {
      postalCodes:
        filterType === 'all' || filterType === 'postalCodes'
          ? undefined
          : appliedFilters.postalCodes,
      meterPreparationStatuses:
        filterType === 'all' || filterType === 'meterInstallationStatuses'
          ? undefined
          : appliedFilters.meterPreparationStatuses,
      fstCheckSuccessful:
        filterType === 'all' || filterType === 'meterInstallationStatuses'
          ? undefined
          : appliedFilters.fstCheckSuccessful,
    };
    buildQueryParams(updatedFilters);
  };

  // TODO: consider combining them in one state object and one function
  const toggleDropdownPostalCodes = (isOpen?: boolean) => {
    isOpen !== undefined
      ? setIsPostalCodeDropdownOpen(false)
      : setIsPostalCodeDropdownOpen((prev) => !prev);
  };
  const toggleDropdownMeterInstallations = (isOpen?: boolean) => {
    isOpen !== undefined
      ? setIsMeterPrepDropdownOpen(false)
      : setIsMeterPrepDropdownOpen((prev) => !prev);
  };

  return (
    <div className="filters-area">
      <div className="filters-area__menus">
        <BodyLarge text={`${I18n.t(filterBy)}:`} className="filters-area__filter-by" />

        <div className="filters-area__menus--postal-code">
          <FilterDropdownPostcodeSearchAndFilter
            postcodeSearchInputRef={postcodeSearchInputRef}
            postcodeSearchValue={postcodeSearchValue}
            postcodesFound={postcodesFound}
            arePostcodesSelected={arePostcodesSelected}
            isPostalCodeDropdownOpen={isPostalCodeDropdownOpen}
            handlePostcodeSearchValue={handlePostcodeSearchValue}
            selectPostcode={selectPostcode}
            applyPostcodeFilters={applyPostcodeFilters}
            resetPostcodeSearchValue={resetPostcodeSearchValue}
            cancelPostcodeSearch={cancelPostcodeSearch}
            toggleDropdown={toggleDropdownPostalCodes}
            appliedPostcodesCount={appliedPostcodesCount}
            isLoading={isLoading}
            inputPlaceholder={placeholder}
            inputLength={length}
          />
        </div>
        {featureFlags.customerListContractWarningVisible && (
          <div className="filters-area__menus--meter-installation-status">
            <FilterDropdownMeterInstallationStatuses
              applyMeterInstallationStatusFilter={applyMeterInstallationStatusFilter}
              isMeterPrepDropdownOpen={isMeterPrepDropdownOpen}
              toggleDropdown={toggleDropdownMeterInstallations}
              meterInstallationTranslations={meterInstallationTranslations}
              meterPreparationStatusesState={meterPreparationStatusesState}
              fstCheckSuccessfulState={fstCheckSuccessfulState}
              appliedMeterInstallationCount={appliedMeterInstallationCount}
            />
          </div>
        )}
        {areFiltersApplied && (
          <Button
            type={ButtonType.TERTIARY}
            mainType={MainType.RESET}
            size={ButtonSize.LARGE}
            label={I18n.t(resetAllFiltersButton)}
            onClick={() => resetFilters('all')}
            dataTestId="reset-filters-button"
            className="reusable-class__no-padding"
          />
        )}
      </div>
      {areFiltersApplied && (
        <AppliedFiltersSection
          appliedFilters={appliedFilters}
          meterInstallationTranslations={meterInstallationTranslations}
          appliedMeterInstallationCount={appliedMeterInstallationCount}
          removeSingleFilter={removeSingleFilter}
          resetFilters={resetFilters}
        />
      )}
    </div>
  );
};

export default SitesFiltersArea;

/*
 * Helpers
 */
const anyFiltersAreApplied = (appliedFilters: AppliedFilters) =>
  !Object.values(appliedFilters).every((filter) => filter === undefined);

export const getAppliedMeterInstallationStatusesCount = (
  appliedFilters: AppliedFilters
): number => {
  let count = appliedFilters.meterPreparationStatuses?.length ?? 0;
  return appliedFilters.fstCheckSuccessful ? count + 1 : count;
};
