import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Button, ButtonSize, ButtonType } from '+shared/components/Button';

import './MissingBatteryButton.component.scss';

interface Props {
  openMissingBatteryModal: () => void;
}

export const MissingBatteryButton: React.FC<Props> = ({ openMissingBatteryModal }) => {
  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    openMissingBatteryModal();
  };

  return (
    <div className={'c-missing-battery-button'}>
      <span className={'c-missing-battery-button__info'}>
        <Translate value={T.missingBattery.startProcessInfo} dangerousHTML={true} />
      </span>
      <div className={'c-missing-battery-button__content'}>
        <Button
          onClick={onButtonClick}
          size={ButtonSize.SMALL}
          label={I18n.t(T.missingBattery.startProcessButton)}
          type={ButtonType.SECONDARY}
        />
      </div>
    </div>
  );
};
