import { CountryFlagProvider } from '@sonnen/shared-web';

import { CountryCode } from 'src/types/country.type';

import { getMarketCountries, Market } from '+utils/market.util';

export enum CountryFeatureName {
  CUSTOMER_CONTRACT = 'CustomerContract',
  CONTRACT_CASE_CREATION = 'ContractCaseCreation',
  CONTRACT_NOTIFICATIONS = 'ContractNotifications',
  COMPANY_LEGAL_DOCUMENTS = 'CompanyLegalDocuments',
  SETUP_PHASE = 'SetupPhase',
  TUTORIAL_COMPONENTS = 'TutorialComponents',
  LEAD_PHASE = 'LeadPhase',
  CERTIFICATIONS = 'Certifications',
  VPP_ACTIVITY_GRAPH = 'VppActivityGraph',
  OPERATING_MODE_LINK = 'OperatingModeLink',
  ACCESSORIES = 'Accessories',
  SET_MODULE_EXTENSION_MODE = 'SetModuleExtensionMode',
  SET_TIME_OF_USE_MODE = 'SetTimeOfUseMode',
  BATTERY_INTELLIGENT_CHARGING = 'BatteryIntelligentCharging',
  EVENTS_AND_TRAININGS = 'EventsAndTrainings',
}

CountryFlagProvider.createFeaturesConfiguration({
  [CountryFeatureName.CUSTOMER_CONTRACT]: {
    whitelist: {
      user: [CountryCode.DE, CountryCode.IT],
    },
  },
  [CountryFeatureName.CONTRACT_CASE_CREATION]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.SETUP_PHASE]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.TUTORIAL_COMPONENTS]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.COMPANY_LEGAL_DOCUMENTS]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.LEAD_PHASE]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.CONTRACT_NOTIFICATIONS]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.CERTIFICATIONS]: {
    whitelist: {
      user: [CountryCode.DE, CountryCode.IT, CountryCode.AT, CountryCode.CH],
    },
  },
  [CountryFeatureName.VPP_ACTIVITY_GRAPH]: {
    whitelist: {
      user: [CountryCode.DE, CountryCode.AU, CountryCode.NZ],
    },
  },
  [CountryFeatureName.ACCESSORIES]: {
    whitelist: {
      user: [CountryCode.DE, CountryCode.AT, CountryCode.CH],
    },
  },
  [CountryFeatureName.OPERATING_MODE_LINK]: {
    whitelist: {
      user: [CountryCode.DE, CountryCode.AT, CountryCode.CH],
    },
  },
  [CountryFeatureName.BATTERY_INTELLIGENT_CHARGING]: {
    blacklist: {
      user: getMarketCountries(Market.US),
    },
  },
  [CountryFeatureName.SET_MODULE_EXTENSION_MODE]: {
    // @TODO: In future use Market Mapper for all countries which are part of US market
    blacklist: {
      battery: getMarketCountries(Market.US),
    },
  },
  [CountryFeatureName.SET_TIME_OF_USE_MODE]: {
    blacklist: {
      battery: [CountryCode.DE, CountryCode.AT],
    },
  },
  [CountryFeatureName.EVENTS_AND_TRAININGS]: {
    blacklist: {
      user: [CountryCode.IT],
    },
  },
});
