import React from 'react';

export const useSuccessTimingHook = (isSuccess: boolean = false) => {
  const [status, setStatus] = React.useState(isSuccess);

  React.useEffect(() => {
    if (isSuccess) {
      const mark = setTimeout(() => setStatus(false), 1500);
      return () => clearTimeout(mark);
    }
    return () => {};
  }, [isSuccess]);

  return status;
};
