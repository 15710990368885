import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BarChartSeries } from '@sonnen/shared-web';

import { Point } from '@kanva/core';

import { dateUtil, TimeUnit } from '+utils/date.util';

import { CsvExportableData } from '../CustomerAnalysis/CustomerAnalysis.helpers';

export const DATE_FORMATS = {
  day: I18n.t(T.general.date.default),
  month: I18n.t(T.general.date.month),
  year: I18n.t(T.general.date.year),
};

export const getFormattedDate = (
  date?: Date,
  format: string = T.general.date.default
): string | null =>
  date ? I18n.l(dateUtil.getUnixFromDateInSeconds(date) * 1000, { dateFormat: format }) : null;

export const formatDate = (date: Date, format?: string) => {
  if (dateUtil.isToday(date)) {
    return I18n.t(T.customerSingle.analysis.dateLabels.today);
  }
  if (dateUtil.isYesterday(date)) {
    return I18n.t(T.customerSingle.analysis.dateLabels.yesterday);
  }

  return String(getFormattedDate(date, format));
};

export const getFilenameFormat = (date: Date, period: TimeUnit): string => {
  let dateFormat = 'YYYY-MM-DD';
  switch (period) {
    case TimeUnit.MONTH:
      dateFormat = 'YYYY-MM';
      break;
    case TimeUnit.YEAR:
      dateFormat = 'YYYY';
      break;
  }
  return getFormattedDate(date, dateFormat) || '';
};

export const preformatCsvData = (
  date: Date,
  series: BarChartSeries,
  period: TimeUnit
): CsvExportableData => {
  const entries: CsvExportableData = {};
  const startTime = new Date(date);
  // We have to use UTC here because otherwise the start time would be
  // relative to the timezone of the currently user.
  startTime.setUTCHours(0, 0, 0, 0);

  if (period === TimeUnit.MONTH) {
    startTime.setUTCDate(1);
  }
  if (period === TimeUnit.YEAR) {
    startTime.setUTCMonth(1, 1);
  }

  Object.keys(series).forEach((key) => {
    entries[key] = series[key];
    entries[key].forEach((row: Point, index: number) => {
      switch (period) {
        case TimeUnit.DAY:
          row.x = startTime.getTime() / 1000 + row.x * 60 * 60;
          break;
        case TimeUnit.MONTH:
          row.x = startTime.getTime() / 1000 + (row.x + 1) * 60 * 60 * 24;
          break;
        case TimeUnit.YEAR:
          row.x = startTime.setUTCMonth(row.x + 1) / 1000;
          break;
      }
      entries[key][index] = row;
    });
    return null;
  });

  return entries;
};
