import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { FormCheckbox, Icon, Modal, ModalLayout } from '@sonnen/shared-web';

import { CountryCode } from 'src/types/country.type';

import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { BatteryOperatingMode, BatteryOperatingModeOptions } from '+shared/store/site/types';

import { mapOperatingModeToInfo } from './BatteryOperatingModeConfirmModal.helper';

import './BatteryOperatingModeConfirmModal.component.scss';

interface Props {
  isOpen: boolean;
  activeOperatingMode: BatteryOperatingMode | undefined;
  onCancel: () => void;
  onConfirm: () => void;
  userCountry: CountryCode;
}

export const BatteryOperatingModeConfirmModal: React.FC<Props> = ({
  isOpen,
  activeOperatingMode,
  onCancel,
  onConfirm,
}) => {
  const [isCustomerInformed, setIsCustomerInformed] = React.useState(false);

  const handleOnConfimClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onConfirm();
  };

  const handleOnCancelClick = () => {
    setIsCustomerInformed(false);
    onCancel();
  };

  const handleOnClickConfirmBox = () => {
    setIsCustomerInformed(!isCustomerInformed);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnCancelClick} size={'medium'}>
      <ModalLayout
        footer={
          <div className={'c-battery-operating-mode-confirm-modal__footer'}>
            <Button
              label={I18n.t(
                T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
                  .confirmationModal.cancelButton
              )}
              type={ButtonType.TERTIARY}
              size={ButtonSize.SMALL}
              onClick={handleOnCancelClick}
            />
            <Button
              label={I18n.t(
                T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
                  .confirmationModal.confirmationButton
              )}
              size={ButtonSize.SMALL}
              type={ButtonType.PRIMARY}
              disabled={!isCustomerInformed}
              status={!isCustomerInformed ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              onClick={handleOnConfimClick}
            />
          </div>
        }
      >
        <div className={'c-battery-operating-mode-confirm-modal'}>
          <div className={'c-battery-operating-mode-confirm-modal__icon-stack'}>
            <Icon.SonnenBattery
              className={'c-battery-operating-mode-confirm-modal__icon-self-consumption'}
            />
            {activeOperatingMode === BatteryOperatingModeOptions.MODULE_EXTENSION && (
              <Icon.SonnenBattery
                className={'c-battery-operating-mode-confirm-modal__icon-module-extension'}
              />
            )}
          </div>
          <div className={'c-battery-operating-mode-confirm-modal__info-wrapper'}>
            <div className={'c-battery-operating-mode-confirm-modal__info'}>
              {mapOperatingModeToInfo(activeOperatingMode)}
            </div>
            {activeOperatingMode === BatteryOperatingModeOptions.MODULE_EXTENSION && (
              <div className={'c-battery-operating-mode-confirm-modal__warning'}>
                {I18n.t(
                  T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
                    .confirmationModal.extensionModeWarning
                )}
              </div>
            )}
          </div>
          <div className={'c-battery-operating-mode-confirm-modal__confirmation-container'}>
            <FormCheckbox
              name={'customer-informed-confirmation'}
              checked={isCustomerInformed}
              onClick={handleOnClickConfirmBox}
              label={
                <div className={'c-battery-operating-mode-confirm-modal__confirmation'}>
                  {I18n.t(
                    T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
                      .confirmationModal.confirmationCheckbox
                  )}
                </div>
              }
            />
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};
