import { PointerAction } from './canvas-pointer-event';
export var getElementOffset = function (element, offset) {
    offset.left = element.offsetLeft;
    offset.top = element.offsetTop;
    var reference = element.offsetParent;
    while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
    }
    return offset;
};
export var supportedDomPointerEvents = [
    'touchmove',
    'mousemove',
    'mousedown',
    'touchstart',
    'mouseover',
    'mouseenter',
    'touchcancel',
    'mouseout',
    'mouseup',
    'touchend',
    'wheel',
];
export var domEventToPointerAction = function (event) {
    switch (event.type) {
        case 'touchmove':
        case 'mousemove':
            return PointerAction.MOVE;
        case 'mousedown':
        case 'touchstart':
            return PointerAction.DOWN;
        case 'mouseover':
        case 'mouseenter':
            return PointerAction.OVER;
        case 'touchcancel':
        case 'mouseout':
            return PointerAction.CANCEL;
        case 'mouseup':
        case 'touchend':
            return PointerAction.UP;
        case 'wheel':
            return PointerAction.SCROLL;
        default:
            return undefined;
    }
};
