import { from } from 'rxjs';

import { Config } from '../../../../config';
import { jsonApiClient } from '../../network';
import { ContractData } from './types/contract.interface';

const getContract = (id: string) =>
  from(jsonApiClient.get<ContractData>(`${Config.API_URL}/contracts/${id}`).expectOne().send());

export const ContractRepository = {
  getContract,
};
