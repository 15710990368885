import { ActionsUnion, createAction } from '../../../utils';
import { ModalId } from './layout.state';
import type { NotificationVariant } from './types/notification.interface';

export enum LAYOUT_ACTIONS {
  TOGGLE_NAVIGATION = '[Layout] TOGGLE_NAVIGATION',
  SCROLL_TO_TOP = '[Layout] SCROLL_TO_TOP',
  TOGGLE_MODAL = '[Layout] TOGGLE_MODAL',
  IS_LANGUAGE_LOADING = '[Layout] IS_LANGUAGE_LOADING',
  ENQUEUE_NOTIFICATION = '[Layout] ENQUEUE_NOTIFICATION',
  DEQUEUE_NOTIFICATION = '[Layout] DEQUEUE_NOTIFICATION',
}

export const LayoutActions = {
  toggleNavigation: createAction(LAYOUT_ACTIONS.TOGGLE_NAVIGATION, (isActive: boolean) => ({
    isActive,
  })),
  scrollToTop: createAction(LAYOUT_ACTIONS.SCROLL_TO_TOP),
  toggleModal: createAction(
    LAYOUT_ACTIONS.TOGGLE_MODAL,
    (isModalOpen: boolean, openModalId?: ModalId) => ({ isModalOpen, openModalId })
  ),
  isLanguageLoading: createAction(LAYOUT_ACTIONS.IS_LANGUAGE_LOADING, (isLoading: boolean) => ({
    isLoading,
  })),
  enqueueNotification: createAction(
    LAYOUT_ACTIONS.ENQUEUE_NOTIFICATION,
    (message: string, variant: NotificationVariant | undefined) => ({ message, variant })
  ),
  dequeueNotification: createAction(LAYOUT_ACTIONS.DEQUEUE_NOTIFICATION),
};

export type LayoutActions = ActionsUnion<typeof LayoutActions>;
