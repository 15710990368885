import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loadable, PageSubheadline } from '@sonnen/shared-web';

import { isEmpty } from 'lodash';

import { FileCategoryName } from '+app/+setupTool/+form/store';
import {
  mapAdditionalFeaturesToFlags,
  VppDocumentationInterface,
} from '+app/+setupTool/+vppDocumentation/store';
import { isGsmMeasurementsFieldsVisible } from '+setupTool/+vppDocumentation/store/+vppDocumentation.helpers';
import { DetailListTable, FormSectionParagraph } from '+shared/components';
import { AdditionalFeatures } from '+shared/store/setupTool/types';

import {
  getVppDocumentationData,
  getVppDocumentationGsmData,
  renderUploadedDocuments,
} from './VppDocumentationRecap.helper';

interface Props {
  fields: VppDocumentationInterface;
  vppAdditionalFeatures: AdditionalFeatures[];
}

export const VppDocumentationRecap: React.FC<Props> = ({ fields, vppAdditionalFeatures }) => {
  const {
    isVppWiringDiagramVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
  } = mapAdditionalFeaturesToFlags(vppAdditionalFeatures);

  const isGsmFieldsVisible = isGsmMeasurementsFieldsVisible(
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible
  );

  return (
    <>
      <div className={'c-vpp-documentation__success-banner'}>
        {I18n.t(T.setupTool.vppDocumentation.status.success)}
      </div>
      <div className={'c-vpp-documentation__container'}>
        <div className={'c-setup-tool__static-info'}>
          <PageSubheadline>{I18n.t(T.setupTool.vppDocumentation.form.header)}</PageSubheadline>
          <Loadable predicate={!fields}>
            <DetailListTable list={getVppDocumentationData(fields, vppAdditionalFeatures)} />
          </Loadable>
        </div>

        {isGsmFieldsVisible && (
          <div className={'c-setup-tool__static-info'}>
            <PageSubheadline>
              {I18n.t(T.setupTool.vppDocumentation.form.signalMeasurementsHeader)}
            </PageSubheadline>
            <Loadable predicate={!fields}>
              <DetailListTable list={getVppDocumentationGsmData(fields)} />
            </Loadable>
          </div>
        )}

        <div className={'c-overlay__inner-content--without-top-padding'}>
          <FormSectionParagraph>
            {I18n.t(T.setupTool.vppDocumentation.documentsHeader)}
          </FormSectionParagraph>

          <div className={'c-dso-registration-summary__container-files'}>
            {renderUploadedDocuments(fields.SUBMISSION_VPP_METER_PICTURE)}
            {isVppWiringDiagramVisible && (
              <>
                {renderUploadedDocuments(
                  fields.SUBMISSION_VPP_WIRING_DIAGRAM,
                  I18n.t(T.setupTool.form.wiringDiagramDefaultFileName)
                )}
              </>
            )}
            {!isEmpty(fields[FileCategoryName.COMPLETION_NOTIFICATION]) &&
              renderUploadedDocuments(fields[FileCategoryName.COMPLETION_NOTIFICATION])}
            {isGsmFieldsVisible && (
              <>
                {renderUploadedDocuments(fields.SUBMISSION_VPP_LAN_CONNECTION_PICTURE)}
                {renderUploadedDocuments(fields.SUBMISSION_VPP_SIGNAL_MEASUREMENTS_PICTURE)}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
