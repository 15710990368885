import * as React from 'react';

import * as classNames from 'classnames';

import './Hamburger.component.scss';

interface Props {
  isActive: boolean;
  className?: ClassValue;
  onClick: () => void;
}

export const Hamburger: React.SFC<Props> = ({ isActive, className, onClick }) => (
  <button
    className={classNames('c-hamburger', { 'is-active': isActive }, className)}
    onClick={() => onClick()}
  >
    <span className={'c-hamburger__icon'}>
      <span />
    </span>
  </button>
);
