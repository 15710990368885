import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteProps, withRouter } from 'react-router-dom';

import { T } from '@sonnen/shared-i18n/service';
import { Accordion, Loader, PageSubheadline, Select } from '@sonnen/shared-web';

import { isEmpty } from 'lodash/fp';
import { EMPTY } from 'rxjs';
import * as uuid from 'uuid/v4';

import { getFaqList } from '+shared/store/faq/faq.repository';
import { StoreState } from '+shared/store/store.interface';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { matchQueryState, useQuery } from '+utils/useQuery.util';

import {
  aggregateFaqTopics,
  checkAccordionOpen,
  getSelectedAggregatedFaqTopic,
} from './HelpFaq.helper';

import './HelpFaq.component.scss';

const mapStateToProps = (state: StoreState) => ({
  countryCode: getUserCountryCode(state),
});

type Props = ReturnType<typeof mapStateToProps> & RouteProps;

const HelpFaqComponent: React.FC<Props> = ({ countryCode }) => {
  const matchFaqQuery = matchQueryState(
    useQuery({
      call: () => (countryCode ? getFaqList({ countryCode }) : EMPTY),
    })
  );

  return (
    <section className={'c-help-faq'}>
      <PageSubheadline smallGap={true}>{I18n.t(T.help.faq)}</PageSubheadline>
      {matchFaqQuery({
        onInit: () => <Loader className={'c-help-faq__loader'} />,
        onPending: () => <Loader className={'c-help-faq__loader'} />,
        onFailure: () => <div>Cannot fetch data</div>,
        onSuccess: (state) => {
          if (isEmpty(state.result.faqPagingQuery.items)) return;

          const aggregatedFaqTopics = aggregateFaqTopics(state.result.faqPagingQuery.items);
          const selectedFaqTopic = getSelectedAggregatedFaqTopic(aggregatedFaqTopics);

          return (
            <>
              <h3 className={'c-help-faq__bold-header'}>{I18n.t(T.help.selectTopic)}</h3>
              <div className={'gtm-help__faq-menu'}>
                <Select
                  className={'c-help-faq__select'}
                  items={aggregatedFaqTopics}
                  itemsSelected={selectedFaqTopic}
                  itemFactory={(item) => item.title}
                  onSelect={(item) => (window.location.hash = item.hash)}
                />
              </div>
              <div className={'gtm-help__faq-topic'}>
                {selectedFaqTopic &&
                  selectedFaqTopic.questions.map((q, i) => (
                    <Accordion
                      key={uuid()}
                      isOpen={checkAccordionOpen(i)}
                      header={<p className={'c-help-faq__accordion-title'}>{q.question}</p>}
                    >
                      <div
                        className={'c-help-faq__answer'}
                        dangerouslySetInnerHTML={{ __html: q.answer }}
                      />
                    </Accordion>
                  ))}
              </div>
            </>
          );
        },
      })}
    </section>
  );
};

export const HelpFaq = withRouter(connect(mapStateToProps)(HelpFaqComponent));
