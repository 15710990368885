import { isEmpty } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import {
  bufferCount,
  concatMap,
  filter,
  map,
  mergeMap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';

import { Video, VideoFirebase } from '+app/shared/store/firebase/types/video.interface';
import { YoutubeRepository } from '+app/shared/store/youtube/youtube.repository';
import { ofType, processQuery } from '+app/utils';
import { StoreState } from '+shared/store/store.interface';

import { VideoYoutube } from '../youtube/types/youtube.interface';
import { FirebaseActions } from './firebase.actions';
import { FirebaseRepository } from './firebase.repository';
import { getVideoList } from './firebase.selectors';
import { MARK_NEWS_AS_READ_QUERY } from './firebase.state';

type Action$ = ActionsObservable<FirebaseActions>;
type State$ = StateObservable<StoreState>;

const YOUTUBE_BATCH_SIZE = 50;

export const getCombinedVideoList$ = (action$: Action$) =>
  action$.pipe(
    ofType(FirebaseActions.getCombinedVideoList),
    mergeMap(({ videoListFirebase }) =>
      from(videoListFirebase).pipe(
        bufferCount(YOUTUBE_BATCH_SIZE),
        concatMap((videoListFirebaseChunk) =>
          of(videoListFirebaseChunk).pipe(
            mergeMap((videoListFirebaseChunk) => {
              const youtubeIds = videoListFirebaseChunk
                .map((videoFirebase: VideoFirebase) => videoFirebase.youtubeId)
                .join();

              return forkJoin({
                videoListFirebaseChunk: of(videoListFirebaseChunk),
                youtubeResponse: YoutubeRepository.getYoutubeVideoList(youtubeIds),
              });
            }),
            mergeMap(({ videoListFirebaseChunk, youtubeResponse }) => {
              const youtubeResponseItems = youtubeResponse.items as VideoYoutube[];
              const fetchedYoutubeIds = youtubeResponseItems.map((video) => video.id);

              return from(
                videoListFirebaseChunk
                  .filter((videoFirebase) => fetchedYoutubeIds.includes(videoFirebase.youtubeId))
                  .map((videoFirebase) => ({
                    videoFirebase,
                    videoYoutube: youtubeResponseItems.find(
                      (videoYoutube) => videoYoutube.id === videoFirebase.youtubeId
                    )!,
                  }))
              );
            }),
            map(
              ({ videoFirebase, videoYoutube }): Video => ({
                ...videoYoutube,
                youtubeId: videoYoutube.id,
                id: videoFirebase.id,
                categories: [videoFirebase.categories[0]],
                // @TODO categories[0] -> decide: multi-categories or
                //    single category for each video
                // multi-categories are not supported since beginning :/
                // we have multiple 'other' instead
                // The Video typing is screwed up &
                // doesn't enforce all the properties of the interface
                markets: videoFirebase.markets,
              })
            )
          )
        ),
        toArray(),
        map(FirebaseActions.setCombinedVideoList)
      )
    )
  );

export const addVideoToCombinedVideoList$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(FirebaseActions.addVideo),
    map((action) => action.video),
    mergeMap((video) =>
      of(video).pipe(
        map((video) => video.youtubeId),
        mergeMap((youtubeId) => YoutubeRepository.getYoutubeVideoList(youtubeId)),
        map((youtubeResponse) => youtubeResponse.items),
        filter((youtubeVideoList) => !isEmpty(youtubeVideoList)),
        withLatestFrom(state$),
        map(([youtubeVideos, state]) => [
          ...getVideoList(state),
          {
            ...youtubeVideos[0],
            youtubeId: youtubeVideos[0].id,
            id: video.id,
            categories: video.categories,
            markets: video.markets,
          },
        ]),
        map(FirebaseActions.setCombinedVideoList)
      )
    )
  );

export const markNewsAsRead$ = (action$: Action$) =>
  action$.pipe(
    ofType(FirebaseActions.markNewsAsRead),
    mergeMap(({ newsId }) =>
      of({}).pipe(
        processQuery(MARK_NEWS_AS_READ_QUERY, () => FirebaseRepository.postNewsMarkAsRead(newsId), {
          onSuccess: () => of(FirebaseActions.markNewsAsReadSuccess()),
        })
      )
    )
  );

export const epics = combineEpics(
  getCombinedVideoList$,
  addVideoToCombinedVideoList$,
  markNewsAsRead$
);
