import React from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';

import { mapActions } from '+app/utils';
import { BatteryTimeOfUseForm } from '+customer-battery/components/BatteryTimeOfUseForm';
import {
  batteryTariffWindowSchema,
  convertData,
  getSeparatedTime,
} from '+customer-battery/components/BatteryTimeOfUseForm/BatteryTimeOfUseForm.helper';
import { BatteryOperatingModeView } from '+customer-battery/containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import { getBatteryTariffWindows, getEditBatteryTariffWindow } from '+customer-battery/store';
import { TariffWindow } from '+customer-battery/store/battery-tariff';
import { BatteryActions } from '+shared/store/battery';
import { StoreState } from '+shared/store/store.interface';

interface SimpleProps {
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
}

type Props = SimpleProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: StoreState) => ({
  editBatteryTariffWindow: getEditBatteryTariffWindow(state),
  tariffWindows: getBatteryTariffWindows(state),
});

const mapDispatchToProps = mapActions({
  saveEditedBatteryTariffWindow: BatteryActions.saveEditedBatteryTariffWindow,
});

const BatteryTimeOfUseEditComponent: React.FC<Props> = ({
  actions,
  setBatteryOperatingModeView,
  editBatteryTariffWindow,
  tariffWindows,
}) => {
  const submitHandler = (data: TariffWindow) => {
    actions.saveEditedBatteryTariffWindow(convertData(data));
    setBatteryOperatingModeView(BatteryOperatingModeView.TIME_OF_USE_PREVIEW);
  };

  return (
    <Formik
      initialValues={{
        ...editBatteryTariffWindow,
        startHour: getSeparatedTime(editBatteryTariffWindow.start).hour,
        startMinutes: getSeparatedTime(editBatteryTariffWindow.start).minutes,
        stopHour: getSeparatedTime(editBatteryTariffWindow.stop).hour,
        stopMinutes: getSeparatedTime(editBatteryTariffWindow.stop).minutes,
      }}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={batteryTariffWindowSchema}
      onSubmit={submitHandler}
    >
      {(form) => (
        <>
          <BatteryTimeOfUseForm
            form={form}
            setBatteryOperatingModeView={setBatteryOperatingModeView}
            tariffWindows={tariffWindows}
          />
        </>
      )}
    </Formik>
  );
};

export const BatteryTimeOfUseEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryTimeOfUseEditComponent);
