import { StatisticsSelectedDate, StatisticsSeriesKey } from '@sonnen/shared-web';

import { DataContainer, XYPoint } from '@kanva/charts';
import { isEmpty, isNil, last } from 'lodash/fp';

import { dateUtil, TimeUnit } from '+utils/date.util';

export const setYAxisPrecision = (
  statisticsSelectedDate: StatisticsSelectedDate,
  dataContainer: DataContainer<XYPoint>
) => {
  if (statisticsSelectedDate.period === TimeUnit.DAY) {
    dataContainer.setYAxisParameters({
      ...dataContainer.getYAxisParameters(),
      roundTo: 100,
    });
  }
};

export const resolveBarXPosition = (
  dataContainer: DataContainer<any>,
  statisticsSelectedDate: StatisticsSelectedDate
) => {
  const { period, date } = statisticsSelectedDate;
  const series = dataContainer.getDataSeries(StatisticsSeriesKey.CONSUMED_ENERGY);
  if (isNil(series)) {
    return 0;
  }

  const data = series.data.filter((point) => !isNil(point.y));
  if (isEmpty(data)) {
    return 0;
  }

  return dateUtil.isCurrent(period)(date) ? last(data)!.x : data[Math.floor(data.length / 2)].x;
};
