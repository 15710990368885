import isNil from 'lodash/isNil';
export var DimensionType;
(function (DimensionType) {
    DimensionType[DimensionType["RELATIVE"] = 0] = "RELATIVE";
    DimensionType[DimensionType["PERCENT"] = 1] = "PERCENT";
    DimensionType[DimensionType["PX"] = 2] = "PX";
})(DimensionType || (DimensionType = {}));
export var MATCH_PARENT = -1;
export var WRAP_CONTENT = -2;
export var parseDimension = function (value) {
    if (isNil(value)) {
        return { type: DimensionType.PX, value: 0 };
    }
    if (typeof value === 'number') {
        return value < 0
            ? { type: DimensionType.RELATIVE, value: value }
            : { type: DimensionType.PX, value: Math.round(value) };
    }
    var match = value.match(/^([-+]?)(\d*\.?\d+)(?:(px)|(%))$/);
    if (!match) {
        throw new Error("Invalid dimension '".concat(value, "'. Enter a valid value, i.e. 100px or 50%."));
    }
    var sign = match[1], numericValue = match[2], isPx = match[3], isPercent = match[4];
    if (sign === '-') {
        throw new Error("Invalid dimension '".concat(value, "'. Dimension can not be negative."));
    }
    if (isPx) {
        return { type: DimensionType.PX, value: Math.round(parseFloat(numericValue)) };
    }
    else if (isPercent) {
        return { type: DimensionType.PERCENT, value: parseFloat(numericValue) / 100 };
    }
    throw new Error("Invalid dimension '".concat(value, "'."));
};
export var calcDimension = function (dimension, parentDimension) {
    switch (dimension.type) {
        case DimensionType.PERCENT:
            return Math.round(parentDimension * dimension.value);
        case DimensionType.RELATIVE:
            if (dimension.value === MATCH_PARENT) {
                return parentDimension;
            }
            return undefined;
        case DimensionType.PX:
        default:
            return dimension.value;
    }
};
