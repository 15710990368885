import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { dataGuard, ofType, processQuery } from '+app/utils';
import { StoreState } from '+shared/store/store.interface';

import { CustomerActions } from './customer.actions';
import { CustomerRepository } from './customer.repository';
import { GET_CUSTOMER_LIST_QUERY } from './customer.state';

type Action$ = ActionsObservable<CustomerActions>;
type State$ = StateObservable<StoreState>;

export const getCustomerList$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.getCustomerList),
    mergeMap((params) =>
      of(params).pipe(
        processQuery(
          GET_CUSTOMER_LIST_QUERY,
          () => CustomerRepository.getCustomerCollection(params),
          {
            onSuccess: (res) => dataGuard(CustomerActions.setCustomerList)(res!.elements),
          }
        )
      )
    )
  );

export const epics = combineEpics<any>(getCustomerList$);
