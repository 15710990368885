import { isArray, isObjectLike, isPlainObject, map, set, transform } from 'lodash';

type Converter = (value: string) => string;

const createIteratee =
  (converter: Converter, self: typeof toCamelCase) => (result: any, value: any, key: any) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value);

const underscoreToCamelCase = (text: string) =>
  text.replace(/([-_]\w)/g, (m) => m[1].toUpperCase());

export const toCamelCase = (node: object | []): any => {
  if (isArray(node)) {
    return map(node, toCamelCase);
  }

  if (isPlainObject(node)) {
    return transform(node, createIteratee(underscoreToCamelCase, toCamelCase));
  }

  return node;
};
