import React from 'react';

type Props = {
  size?: string | number;
  className?: string;
};

export const Padlock: React.FC<Props> = ({ size = 16, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16" fill="none">
    <rect x="2.5" y="6.5" width="11" height="7" rx="0.5" stroke="#0A1535" />
    <path
      d="M7 2.5H9C10.3807 2.5 11.5 3.61929 11.5 5V6C11.5 6.27614 11.2761 6.5 11 6.5H5C4.72386 6.5 4.5 6.27614 4.5 6V5C4.5 3.61929 5.61929 2.5 7 2.5Z"
      stroke="#0A1535"
    />
    <path
      d="M7 9C7 8.44772 7.44772 8 8 8V8C8.55228 8 9 8.44772 9 9V9.14645C9 9.37282 8.91007 9.58993 8.75 9.75V9.75C8.58993 9.91007 8.5 10.1272 8.5 10.3536V11C8.5 11.2761 8.27614 11.5 8 11.5V11.5C7.72386 11.5 7.5 11.2761 7.5 11V10.3536C7.5 10.1272 7.41007 9.91007 7.25 9.75V9.75C7.08993 9.58993 7 9.37282 7 9.14645V9Z"
      fill="#0A1535"
    />
  </svg>
);
