import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { HttpCode } from '@coolio/http';
import { FormikProps } from 'formik';

import { LeadAutosuggestedAddress } from '+app/+lead/containers/LeadAutosuggestedAddress';
import { LeadForm } from '+app/+lead/store/types';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { FormInput, FormInputIcon, FormInputSelect } from '+shared/components';
import Warning from '+shared/components/Warning/Warning';
import { getOpenModalId, isModalOpen, LayoutActions, ModalId } from '+shared/store/layout';
import { LeadDeliveryAddressSuggestions } from '+shared/store/lead/types/leadDeliveryAddress.interface';
import { Query } from '+shared/store/query';
import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { postcodesValidations } from '+utils/market.util';
import { mapActions } from '+utils/redux';
import { Reporter } from '+utils/reporter.util';
import { toKebabCase } from '+utils/toKebabCase.util';

import { LeadNewManualAddress } from '../LeadNewManualAddress';
import { LeadZipCodeNotFoundModal } from '../LeadZipCodeNotFoundModal';
import { filterStreetsLowerCase, searchStreet } from './LeadNewAddress.helper';

import './LeadNewAddress.scss';

const {
  addressWarning,
  zipCode,
  city,
  street,
  houseNo,
  houseNoWarning,
  zipCodeNotFound,
  selectCity,
  selectStreet,
  invalidAddress,
} = T.lead.boc._salessolution_.form.personalDetails;

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    addressType: 'deliveryAddress' | 'invoiceAddress';
    form: FormikProps<LeadForm>;
    addressSuggestions?: LeadDeliveryAddressSuggestions;
    addressQuery: Query<LeadDeliveryAddressSuggestions>;
    getAddressSuggestions: (zipCodeValue: string, cityValue?: string) => void;
  };

const LeadNewAddressComponent: React.FC<Props> = ({
  addressType,
  form,
  addressSuggestions,
  addressQuery,
  getAddressSuggestions,
  actions,
  openModalId,
}) => {
  const [zipCodeValue, setZipCodeValue] = useState<string>('');
  const [cityValue, setCityValue] = useState<string>('');
  const [streetValue, setStreetValue] = useState<string>('');
  const [houseNumberValue, setHouseNumberValue] = useState<string>('');
  const [citiesCollection, setCitiesCollection] = useState<string[]>([]);
  const [streetsCollection, setStreetsCollection] = useState<string[]>([]);
  const [hasInvalidAddress, setHasInvalidAddress] = useState<boolean>(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState<boolean>(true);
  const [isZipCodeNotFound, setIsZipCodeNotFound] = useState<boolean>(false);
  const [isGoogleUserflowUsed, setIsGoogleUserflowUsed] = useState<boolean>(false);
  const [enableInput, setEnableInput] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [zipCodeNotFoundCount, setZipCodeNotFoundCount] = useState<number>(0);

  const getModalId = (addressType: string): ModalId => {
    if (addressType === 'deliveryAddress') {
      return ModalId.DELIVERY_ZIP_CODE_NOT_FOUND;
    }
    if (addressType === 'invoiceAddress') {
      return ModalId.INVOICE_ZIP_CODE_NOT_FOUND;
    }
    Reporter.log(`unknown addressType '${addressType}'`);
    return ModalId.DELIVERY_ZIP_CODE_NOT_FOUND;
  };

  const modalId = getModalId(addressType);

  const onClose = () => {
    actions.toggleModal(false);
    form.setFieldValue(`${addressType}.zipCode`, '');
    form.setFieldTouched(`${addressType}.zipCode`, false, false);
    setZipCodeNotFoundCount(0);
  };

  const onSubmit = () => {
    actions.toggleModal(false);
    setIsGoogleUserflowUsed(true);
  };

  useEffect(() => {
    if (zipCodeValue.length === postcodesValidations.DE.length) {
      getAddressSuggestions(zipCodeValue);
      resetAddressStates();
    }
  }, [zipCodeValue]);

  useEffect(() => {
    if (isServiceAvailable && (citiesCollection.length === 1 || !!cityValue)) {
      form.setFieldValue(`${addressType}.city`, citiesCollection[0]);
      getAddressSuggestions(zipCodeValue, cityValue);
    } else {
      form.setFieldValue(`${addressType}.city`, cityValue);
    }
  }, [cityValue]);

  useEffect(() => {
    const searchNotMatchesStreetCollection = searchStreet.length === 0;

    if (
      streetValue.length > 0 &&
      streetsCollection.length > 0 &&
      searchNotMatchesStreetCollection
    ) {
      setHasInvalidAddress(true);
    } else {
      setHasInvalidAddress(false);
    }
  }, [streetValue]);

  useEffect(() => {
    if (addressSuggestions) {
      const { cities, streets } = addressSuggestions;
      form.setFieldError(`${addressType}.zipCode`, '');
      setCitiesCollection(cities);
      setStreetsCollection(streets);
      setCityValue(cities.length === 1 ? cities[0] : '');
      setZipCodeNotFoundCount(0);
    }
  }, [addressSuggestions]);

  useEffect(() => {
    if (getStatus(addressQuery).error) {
      if (addressQuery.error?.status === HttpCode.NOT_FOUND) {
        if (!isZipCodeNotFound) setIsZipCodeNotFound(true);
        const newCount = zipCodeNotFoundCount + 1;
        setZipCodeNotFoundCount(newCount);
      } else {
        setIsServiceAvailable(false);
        actions.toggleModal(true, modalId);
      }
    }
  }, [addressQuery]);

  useEffect(() => {
    if (zipCodeNotFoundCount >= 3) {
      actions.toggleModal(true, modalId);
    }
  }, [zipCodeNotFoundCount]);

  const resetAddressStates = () => {
    form.setFieldValue(`${addressType}.city`, '');
    form.setFieldValue(`${addressType}.street`, '');
    form.setFieldTouched(`${addressType}.street`, false, false);
    form.setFieldValue(`${addressType}.houseNumber`, '');
    setCityValue('');
    setStreetValue('');
    setHouseNumberValue('');
    setCitiesCollection([]);
    setStreetsCollection([]);
    setIsZipCodeNotFound(false);
    setHasInvalidAddress(false);
    setIsServiceAvailable(true);
  };

  const setStreetInput = (input: string) => {
    form.setFieldValue(`${addressType}.street`, input);
    setStreetValue(input);
  };

  const validateStreetInputOnBlur = () => {
    const searchNotMatchesStreetCollection =
      filterStreetsLowerCase(streetsCollection, streetValue).length === 0;
    if (searchNotMatchesStreetCollection) {
      setHasInvalidAddress(true);
    }
  };

  return isGoogleUserflowUsed ? (
    <>
      <LeadAutosuggestedAddress
        form={form}
        setEnableInput={setEnableInput}
        setIsLoading={setIsLoading}
        addressType={addressType}
      />
      <LeadNewManualAddress
        form={form}
        isDisabled={!enableInput}
        isLoading={isLoading}
        addressType={addressType}
      />
    </>
  ) : (
    <>
      <div id={`lead-new-${addressType}-address`} className="lead-new-address">
        <div className="lead-new-address--pair">
          <FormInput
            form={form}
            id={`lead-new-${addressType}-zip-code-field`}
            className="lead-new-address__input-field--short"
            dataTestId={`${toKebabCase(addressType)}-zip-code-field`}
            name={`${addressType}.zipCode`}
            label={I18n.t(zipCode)}
            type="text"
            onInputChange={setZipCodeValue}
            bottomWarningText={
              zipCodeValue.length === postcodesValidations.DE.length &&
              !form.errors[addressType]?.['zipCode'] &&
              isZipCodeNotFound &&
              !getStatus(addressQuery).pending
                ? I18n.t(zipCodeNotFound)
                : undefined
            }
          />

          {isServiceAvailable ? (
            <FormInputSelect
              form={form}
              id={`lead-new-${addressType}-city-field`}
              className="lead-new-address__input-field"
              dataTestId={`${toKebabCase(addressType)}-city-field`}
              dropDownOptionsDataTestId={`${toKebabCase(addressType)}-city-field-dropdown-options`}
              name={`${addressType}.city`}
              label={I18n.t(city)}
              isDisabled={citiesCollection.length === 0}
              collection={citiesCollection}
              noResultsComponent={
                getStatus(addressQuery).pending ? (
                  <Loader className="lead-new-address__loader" />
                ) : null
              }
              disableSearchForEmptyCollection={true}
              prefilledSelectedItem={cityValue}
              placeholder={
                getStatus(addressQuery).pending ? (
                  <Loader className="lead-new-address__loader" />
                ) : citiesCollection.length > 1 ? (
                  I18n.t(selectCity)
                ) : undefined
              }
              onSelect={setCityValue}
            />
          ) : (
            <FormInput
              form={form}
              id={`lead-new-${addressType}-city-field`}
              className="lead-new-address__input-field"
              dataTestId={`${toKebabCase(addressType)}-city-field`}
              name={`${addressType}.city`}
              label={I18n.t(city)}
              type="text"
              onInputChange={setCityValue}
            />
          )}
        </div>

        <div className="lead-new-address--pair">
          {isServiceAvailable ? (
            <FormInputSelect
              form={form}
              id={`lead-new-${addressType}-street-field`}
              className="lead-new-address__input-field"
              dataTestId={`${toKebabCase(addressType)}-street-field`}
              dropDownOptionsDataTestId={`${toKebabCase(
                addressType
              )}-street-field-dropdown-options`}
              name={`${addressType}.street`}
              label={I18n.t(street)}
              isDisabled={!cityValue}
              collection={streetsCollection}
              noResultsComponent={
                getStatus(addressQuery).pending ? (
                  <Loader className="lead-new-address__loader" />
                ) : null
              }
              disableSearchForEmptyCollection={true}
              placeholder={
                getStatus(addressQuery).pending && cityValue ? (
                  <Loader className="lead-new-address__loader" />
                ) : streetsCollection.length > 1 ? (
                  I18n.t(selectStreet)
                ) : undefined
              }
              onBlur={validateStreetInputOnBlur}
              onInputChange={setStreetInput}
              warningMessage={I18n.t(addressWarning)}
              search={(val) => searchStreet(val, streetsCollection)}
            />
          ) : (
            <FormInput
              form={form}
              id={`lead-new-${addressType}-street-field`}
              className="lead-new-address__input-field"
              dataTestId={`${toKebabCase(addressType)}-street-field`}
              name={`${addressType}.street`}
              label={I18n.t(street)}
              disabled={!cityValue}
              onInputChange={setStreetInput}
              warningMessage={I18n.t(addressWarning)}
            />
          )}

          <FormInput
            form={form}
            id={`lead-new-${addressType}-house-number-field`}
            className="lead-new-address__input-field--short"
            label={I18n.t(houseNo)}
            disabled={!streetValue}
            icon={houseNumberValue || !streetValue ? undefined : FormInputIcon.WARNING}
            name={`${addressType}.houseNumber`}
            onInputChange={setHouseNumberValue}
            warningMessage={I18n.t(houseNoWarning)}
            dataTestId={`${toKebabCase(addressType)}-house-number-field`}
          />
        </div>
      </div>

      {hasInvalidAddress && isServiceAvailable && (
        <div className="lead-new__warning-wrapper">
          <Warning textMessageAsHtml={I18n.t(invalidAddress)} warningType={'warning'} />
        </div>
      )}

      <LeadZipCodeNotFoundModal
        isModalOpen={openModalId === modalId}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </>
  );
};

export const LeadNewAddress = connect(mapStateToProps, mapDispatchToProps)(LeadNewAddressComponent);
