import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { isNil } from 'lodash/fp';

import { getLeadOfferData } from '+app/+lead/+offer/store/+offer.selectors';
import { determineTaxRegulation } from '+app/+lead/components/LeadFlatConfigurationTile/LeadFlatConfigurationTile.helper';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { ConfigurationPageActions } from '+lead-configuration/store';
import { FormInputInfo } from '+shared/components';
import { Button, ButtonSize, ButtonStatus } from '+shared/components/Button';
import { LeadOfferTaxRegulation, LeadProductType } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { LeadConfigurationAutarky, LeadConfigurationPreview } from '../../components';
import { LeadConfigurationPreviewInitial } from '../../components/LeadConfigurationPreviewInitial';
import { getConfigurationTypeAndVersion } from '../../store/+configuration.helper';
import {
  getConfigurationForm,
  getConfigurationProposal,
  getConfigurationSubmitQueryStatus,
  getSaveConfigurationQueryStatus,
  getSetConfigurationProposalQueryStatus,
} from '../../store/+configuration.selectors';

import './LeadConfigurationSummary.component.scss';

const mapStateToProps = (state: StoreState) => ({
  configurationProposal: getConfigurationProposal(state),
  lead: getLead(state),
  setConfigurationProposalQueryStatus: getSetConfigurationProposalQueryStatus(state),
  saveConfigurationQueryStatus: getSaveConfigurationQueryStatus(state),
  configurationSubmitQueryStatus: getConfigurationSubmitQueryStatus(state),
  configurationForm: getConfigurationForm(state),
  offer: getLeadOfferData(state),
});

const mapDispatchToProps = mapActions({
  saveConfiguration: ConfigurationPageActions.saveConfiguration,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const LeadConfigurationSummaryComponent: React.FC<Props> = ({
  configurationProposal,
  lead,
  configurationForm,
  setConfigurationProposalQueryStatus,
  saveConfigurationQueryStatus,
  configurationSubmitQueryStatus,
  actions,
  offer,
  location,
}) => {
  const [loaderText, setLoaderText] = React.useState<string | null>(null);
  const isPending = !!loaderText;
  const expectedAutarky =
    configurationProposal && configurationProposal.powerPlant.expectedAutarky?.value;
  const isSubmitDisabled =
    setConfigurationProposalQueryStatus.pending || saveConfigurationQueryStatus.pending;

  const taxRegulation: LeadOfferTaxRegulation = configurationProposal
    ? determineTaxRegulation(configurationProposal)
    : LeadOfferTaxRegulation.SMALL_ENTERPRISE;

  React.useEffect(() => {
    // Autarky change
    if (setConfigurationProposalQueryStatus.pending) {
      setLoaderText(I18n.t(T.lead.configuration._salessolution_.configurations.recalculating));
    } else {
      setLoaderText(null);
    }
  }, [setConfigurationProposalQueryStatus]);

  React.useEffect(() => {
    if (configurationSubmitQueryStatus.pending) {
      configurationProposal
        ? setLoaderText(I18n.t(T.lead.configuration._salessolution_.configurations.recalculating))
        : setLoaderText(I18n.t(T.lead.configuration._salessolution_.configurations.calculating));
    } else {
      setLoaderText(null);
    }
  }, [configurationSubmitQueryStatus]);

  const onSave = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (configurationProposal) {
      if (configurationForm && configurationForm.newBattery) {
        // TODO: replace capacityGross with a proper form name
        const batteryId = configurationForm.capacityGross;
        actions.saveConfiguration(configurationProposal.id, taxRegulation, offer?.id, batteryId);
      } else {
        actions.saveConfiguration(configurationProposal.id, taxRegulation, offer?.id);
      }
    }
  };

  const { productType } = getConfigurationTypeAndVersion(location);

  return (
    <div
      className={classNames('c-lead-configuration-summary', {
        'c-lead-configuration-summary--initial': !configurationProposal,
      })}
    >
      {isPending && (
        <div className={'c-lead-configuration-summary__loader-wrapper'}>
          <Loader className={'c-lead-configuration-summary__loader'} />
          <p>{loaderText}</p>
        </div>
      )}
      <div className={'c-lead-configuration-summary__header'}>
        <h2 className={'c-lead-configuration-summary__headline'}>
          {productType === LeadProductType.FLAT_DIRECT
            ? I18n.t(T.lead.configuration._salessolution_.summary.flatDirectHeader)
            : I18n.t(T.lead.configuration._salessolution_.summary.header)}
        </h2>
        <p className={'c-lead-configuration-summary__description'}>
          {configurationProposal
            ? I18n.t(T.lead.configuration._salessolution_.summary.initialText)
            : I18n.t(T.lead.configuration._salessolution_.summary.summaryText)}
        </p>
      </div>
      {configurationProposal ? (
        <>
          <LeadConfigurationPreview lead={lead!} configProposal={configurationProposal!} />
          {!isNil(expectedAutarky) && (
            <LeadConfigurationAutarky expectedAutarky={expectedAutarky} flatType={productType} />
          )}

          {taxRegulation === LeadOfferTaxRegulation.STANDARD && (
            <div className={'c-lead-configuration-summary__vat'}>
              <FormInputInfo isBold={true}>
                {I18n.t(T.lead.configuration._salessolution_.preview.vatInfo, {
                  vat: parseFloat(configurationProposal.valueAddedTax.value.toString()),
                })}
              </FormInputInfo>
            </div>
          )}

          <div className={'c-lead-configuration-summary__separator'} />
          <div className={'c-lead-configuration-summary__btn'}>
            <Button
              label={I18n.t(T.lead.configuration._salessolution_.summary.save)}
              size={ButtonSize.LARGE}
              onClick={onSave}
              disabled={isSubmitDisabled}
              status={isSubmitDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              loading={saveConfigurationQueryStatus.pending}
            />
          </div>
        </>
      ) : (
        <LeadConfigurationPreviewInitial productType={productType} />
      )}
    </div>
  );
};

export const LeadConfigurationSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadConfigurationSummaryComponent));
