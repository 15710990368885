import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { BodyLarge, BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import ExpandingInfo from '+shared/components/ExpandingInfo/ExpandingInfo';

import { Alert, AlertTheme } from '../Alert/Alert';

import './DynamicTariffInfo.scss';

const { dynamicTariff } = T.lead.configuration._salessolution_.products;

type DynamicTariffInfoProps = {
  hasDynamicTariff: boolean;
};

const DynamicTariffInfo = ({ hasDynamicTariff }: DynamicTariffInfoProps) => {
  if (!hasDynamicTariff) return null;

  return (
    <div className="dynamic-tariff">
      <Alert
        theme={AlertTheme.INFO}
        text={
          <div className="expanding">
            <div className="expanding__info">
              <BodyLarge text={I18n.t(dynamicTariff.info)} />
            </div>
            <ExpandingInfo label={<LabelMedium text={I18n.t(dynamicTariff.explanationTitle)} />}>
              <BodyMedium text={I18n.t(dynamicTariff.explanationText)} />
            </ExpandingInfo>
          </div>
        }
      />
    </div>
  );
};

export default DynamicTariffInfo;
