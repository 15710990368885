import { Lead } from '+shared/store/lead/types';
import { isStatusSet } from '+utils/status.util';

export const formFields = {
  DSO_REGISTRATION_COMPLETED: 'dsoRegistrationCompleted',
  DSO_COMMISSIONING_COMPLETED: 'dsoCommissioningCompleted',
  BNETZA_REGISTRATION_COMPLETED: 'bNetzARegistrationCompleted',
  TAX_OFFICE_REGISTRATION_COMPLETED: 'taxOfficeRegistrationCompleted',
  HW_COMMISSIONING_COMPLETED: 'hardwareCommissioningCompleted',
  DSO_METER_INSTALLED: 'dsoMeterInstalled',
  FLAT_OFFER_SENT: 'flatOfferSent',
  FLAT_OFFER_ACCEPTED: 'flatOfferAccepted',
  FLAT_ORDER_CONFIRMED: 'flatOrderConfirmed',
  START_OF_DELIVERY_FIXED: 'startOfDeliveryFixed',
  VPP_PREPARATION_DONE: 'vppPreparationDone',
};

export const mapUserStatusToInitialValues = (lead: Lead) => {
  const {
    status: { summary },
  } = lead;

  return {
    dsoRegistrationCompleted: isStatusSet(summary.dsoRegistrationCompleted),
    dsoCommissioningCompleted: isStatusSet(summary.dsoCommissioningCompleted),
    bNetzARegistrationCompleted: isStatusSet(summary.bNetzARegistrationCompleted),
    taxOfficeRegistrationCompleted: isStatusSet(summary.taxOfficeRegistrationCompleted),
    hardwareCommissioningCompleted: isStatusSet(summary.hardwareCommissioningCompleted),
    dsoMeterInstalled: isStatusSet(summary.dsoMeterInstalled),
    flatOfferSent: isStatusSet(summary.flatOfferSent),
    flatOfferAccepted: isStatusSet(summary.flatOfferAccepted),
    flatOrderConfirmed: isStatusSet(summary.flatOrderConfirmed),
    startOfDeliveryFixed: isStatusSet(summary.startOfDeliveryFixed),
    vppPreparationDone: isStatusSet(summary.vppPreparationDone),
  };
};
