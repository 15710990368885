import { createSelector } from 'reselect';

import { StoreState } from '../store.interface';

export const setupToolPage = (state: StoreState) => state.page.setupTool;

export const getPreCommissioning = createSelector(setupToolPage, (state) => state.preCommissioning);

export const getDsoRegisterForm = createSelector(setupToolPage, (state) => state.form);

export const getConfiguration = createSelector(setupToolPage, (state) => state.configuration);

export const getDsoRegistration = createSelector(setupToolPage, (state) => state.dsoRegistration);

export const getDsoCommissioning = createSelector(setupToolPage, (state) => state.dsoCommissioning);

export const getPVRegister = createSelector(setupToolPage, (state) => state.pvRegister);

export const getVppDocumentation = createSelector(setupToolPage, (state) => state.vppDocumentation);
