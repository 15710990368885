import React from 'react';

import classNames from 'classnames';

import { ArrowDown, ArrowUp } from '+shared/basicComponents/Icons';
import { LabelLarge } from '+shared/basicComponents/Typography/Labels';

import { SortingOrder } from '../../TabSites';

import './SitesTableHeaderCell.scss';

interface Props {
  name: string;
  order: SortingOrder;
  clickOrder?: 'asc->desc' | 'desc->asc';
  onOrderChange: (newOrder: 'ascending' | 'descending') => void;
  disabledSorting: boolean;
}

const SitesTableHeaderCell: React.FC<Props> = ({
  name,
  order,
  clickOrder = 'asc->desc',
  onOrderChange,
  disabledSorting,
}: Props) => {
  const handleClick = () => {
    if (disabledSorting) return;
    if (!order) return onOrderChange(clickOrder === 'desc->asc' ? 'descending' : 'ascending');
    return onOrderChange(order === 'descending' ? 'ascending' : 'descending');
  };

  return (
    <span
      className={`sortable-header-cell ${disabledSorting ? 'disabled-sorting' : ''}`}
      onClick={handleClick}
    >
      <LabelLarge text={name} />

      {!disabledSorting && (
        <span className="sortable-header-cell__arrows">
          <ArrowUp
            className={classNames(
              'sortable-header-cell__arrow',
              'sortable-header-cell__arrow-up',
              order === 'ascending'
                ? ''
                : !order
                ? 'sortable-header-cell__arrow-greyed-out'
                : 'sortable-header-cell__arrow-hidden'
            )}
          />
          <ArrowDown
            className={classNames(
              'sortable-header-cell__arrow',
              order === 'descending'
                ? ''
                : !order
                ? 'sortable-header-cell__arrow-greyed-out'
                : 'sortable-header-cell__arrow-hidden'
            )}
          />
        </span>
      )}
    </span>
  );
};

export default SitesTableHeaderCell;
