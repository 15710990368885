import React, { useState } from 'react';

import classNames from 'classnames';

import { InfoFilled } from '+shared/basicComponents/Icons';
import { Arrow } from '+shared/basicComponents/Icons/Arrow';

import './index.scss';

type BannerProps = {
  headlineMessage: string;
  textMessageAsHtml: string;
};

const Banner: React.FC<BannerProps> = ({ headlineMessage, textMessageAsHtml }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="alert">
      <div className="alert__icon">
        <InfoFilled />
      </div>
      <div
        className={classNames('alert__content', {
          truncation: !isOpen,
        })}
      >
        <p className="alert__content-header">{headlineMessage}</p>
        <p
          className="alert__content-text"
          dangerouslySetInnerHTML={{
            __html: textMessageAsHtml,
          }}
        />
      </div>
      <div className="alert__icon">
        <button
          className={classNames('alert__icon-arrow', {
            'is-rotated': isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          <Arrow />
        </button>
      </div>
    </div>
  );
};

export default Banner;
