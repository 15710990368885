import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Formik } from 'formik';

import { FormInput } from '+shared/components';
import { Button, ButtonSize, MainType } from '+shared/components/Button';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';

import { loginToFirebaseSchema } from './LoginToFirebase.helper';

import './LoginToFirebase.component.scss';

interface OwnProps {
  onLogin: () => void;
}

type Props = OwnProps & FirebaseProps;

interface State {
  error: string;
  loading: boolean;
}

interface Error {
  code: string;
  message: string;
}

interface FormValues {
  email: string;
  password: string;
}

class LoginToFirebaseComponent extends React.Component<Props, State> {
  state = {
    error: '',
    loading: false,
  };

  loginToFirebase = (values: FormValues) => {
    this.setState({ loading: true });
    const { firebase, onLogin } = this.props;
    const { email, password } = values;
    firebase
      .firebaseLogin(email, password)
      .then(() => {
        this.setState({ loading: false });
        onLogin();
      })
      .catch((err: Error) =>
        this.setState({
          loading: false,
          error: err.message,
        })
      );
  };

  render() {
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={this.loginToFirebase}
        validationSchema={loginToFirebaseSchema}
        render={(form) => (
          <form onSubmit={form.handleSubmit}>
            <div className={'c-login-to-firebase__error'}>{this.state.error}</div>
            <FormInput
              form={form}
              label={I18n.t(T.news.channel.loginForm.email)}
              name={'email'}
              type={'text'}
            />
            <FormInput
              form={form}
              label={I18n.t(T.news.channel.loginForm.password)}
              name={'password'}
              type={'password'}
            />
            <Button
              mainType={MainType.SUBMIT}
              size={ButtonSize.MEDIUM}
              label={I18n.t(T.news.channel.loginForm.signIn)}
              loading={this.state.loading}
            />
          </form>
        )}
      />
    );
  }
}

export const LoginToFirebase = withFirebase(LoginToFirebaseComponent);
