import * as React from 'react';

import './NavSelectTrigger.component.scss';

interface Props {
  name: string;
}

export const NavSelectTrigger: React.FC<Props> = ({ name }) => (
  <div className={'c-nav-select-trigger'}>
    <div className={'c-nav-select-trigger__content'}>
      <div className={'c-nav-select-trigger__content-item'}>{name}</div>
    </div>
    <div className={'c-nav-select-trigger__arrow'} />
  </div>
);
