import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { FormCheckbox, PasswordTooltip, PasswordVerifier } from '@sonnen/shared-web';

import { Field, FieldProps, Formik } from 'formik';

import { Button, ButtonType, MainType } from '+shared/components/Button';
import { getStatus } from '+shared/store/query/query.utils';
import { UserRegistrationForm } from '+shared/store/user';

import { Input } from '../../../shared/components';
import { registerSchema } from './RegisterForm.helper';

import './RegisterForm.component.scss';

interface Props {
  queryStatus: ReturnType<typeof getStatus>;
  setRegisterForm: (values: UserRegistrationForm) => void;
}

export const RegisterForm: React.SFC<Props> = ({ queryStatus, setRegisterForm }) => {
  const [passwordVerification, setPasswordVerification] = React.useState<number>(0);
  const onFormSubmit = (values: UserRegistrationForm) => {
    setRegisterForm(values);
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: '',
        termsOfService: false,
        privacyPolicy: false,
      }}
      validationSchema={registerSchema}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <form className={'c-register-form'} onSubmit={handleSubmit}>
          <div className={'c-register-form__group'}>
            <Field
              name={'password'}
              className={'c-input__field'}
              render={({ field }: FieldProps) => {
                return (
                  <Input
                    name={'password'}
                    value={field.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    floatingLabel={true}
                    label={I18n.t(T.register.form.password)}
                    type={'password'}
                    error={errors.password}
                    isTouched={touched.password}
                  />
                );
              }}
            />
            <Field
              name={'passwordConfirmation'}
              className={'c-input__field'}
              render={({ field }: FieldProps) => (
                <Input
                  name={'passwordConfirmation'}
                  value={field.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  floatingLabel={true}
                  label={I18n.t(T.register.form.confirmPassword)}
                  type={'password'}
                  error={errors.passwordConfirmation}
                  isTouched={touched.passwordConfirmation}
                />
              )}
            />
            <PasswordVerifier input={values.password} onVerify={setPasswordVerification} />
            <PasswordTooltip
              title={I18n.t(T.passwordTooltip.title)}
              passwordVerification={passwordVerification}
              complexityMap={[
                I18n.t(T.passwordTooltip.passwordStrength.poor),
                I18n.t(T.passwordTooltip.passwordStrength.weak),
                I18n.t(T.passwordTooltip.passwordStrength.medium),
                I18n.t(T.passwordTooltip.passwordStrength.good),
                I18n.t(T.passwordTooltip.passwordStrength.strong),
              ]}
              description={I18n.t(T.passwordTooltip.description)}
            />
          </div>
          <div className={'c-register-form__checkbox'}>
            <Field
              name={'termsOfService'}
              render={({ field, form }: any) => (
                // @TODO Create custom Checkbox and then edit ts type
                <FormCheckbox
                  label={<Translate value={T.register.form.termsCheckbox} dangerousHTML={true} />}
                  checked={field.value}
                  isError={Boolean(errors.termsOfService && touched.termsOfService)}
                  {...field}
                  onChange={() => form.setFieldValue(field.name, !field.value)}
                />
              )}
            />
          </div>
          <div className={'c-register-form__checkbox'}>
            <Field
              name={'privacyPolicy'}
              render={({ field, form }: any) => (
                <FormCheckbox
                  label={
                    <Translate value={T.register.form.privacyPolicyCheckbox} dangerousHTML={true} />
                  }
                  checked={field.value}
                  isError={Boolean(errors.privacyPolicy && touched.privacyPolicy)}
                  {...field}
                  onChange={() => form.setFieldValue(field.name, !field.value)}
                />
              )}
            />
          </div>
          {!!(
            (errors.privacyPolicy && touched.privacyPolicy) ||
            (errors.termsOfService && touched.termsOfService)
          ) && <div className={'c-input__alert'}>{errors.privacyPolicy}</div>}
          <div className={'c-register-form__submit-btn'}>
            <Button
              label={I18n.t(T.register.form.signUpButton)}
              type={ButtonType.PRIMARY}
              loading={queryStatus.pending}
              mainType={MainType.SUBMIT}
            />
          </div>
        </form>
      )}
    />
  );
};
