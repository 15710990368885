import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { isEmpty, isEqual, isUndefined, omitBy } from 'lodash';

import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { getLeadId } from '+app/+lead/store/+lead.selectors';
import { PATHS } from '+app/router';
import { getDsoCommissioningPatchSubmissionQueryStatus } from '+setupTool/+dsoCommissioning';
import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import {
  getLanConnectionPictureSubmitQueryStatus,
  getMeterPreparationPicturesDocumentSubmitQueryStatus,
  getSignalMeasurementsPictureSubmitQueryStatus,
  getWiringDiagramDocumentSubmitQueryStatus,
} from '+setupTool/+form/store/+form.selectors';
import {
  getVppConfigQueryStatus,
  getVppDocumentationFields,
  getVppDocumentationInitialValues,
  getVppState,
  getVppSubmissionStatus,
  getVppUploadedDocuments,
  VppDocumentationActions,
  vppDocumentationFileCategories,
  VppDocumentationInterface,
  VppRegisterStatus,
  VppState,
} from '+setupTool/+vppDocumentation/store';
import { ErrorBox } from '+setupTool/components/ErrorBox';
import { Overlay } from '+setupTool/components/Overlay';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { useAdditionalFeatures } from '+setupTool/store/+setupTool.hooks';
import { getErrors } from '+setupTool/store/+setupTool.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';

import { VppDocumentationForm, VppDocumentationRecap } from '../../components';

import './VppDocumentation.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  submissionStatus: getVppSubmissionStatus(state),
  fields: getVppDocumentationFields(state),
  uploadedDocuments: getVppUploadedDocuments(state),
  errors: getErrors(state),
  vppState: getVppState(state),
  shouldRefetchLeadData:
    getVppConfigQueryStatus(state).success ||
    getDsoCommissioningPatchSubmissionQueryStatus(state).success,
  isLoading:
    getVppConfigQueryStatus(state).pending ||
    getDsoCommissioningPatchSubmissionQueryStatus(state).pending,

  // Query statuses
  meterPreparationPicturesDocumentSubmitQueryStatus:
    getMeterPreparationPicturesDocumentSubmitQueryStatus(state),
  wiringDiagramDocumentSubmitQueryStatus: getWiringDiagramDocumentSubmitQueryStatus(state),
  signalMeasurementsPictureSubmitQueryStatus: getSignalMeasurementsPictureSubmitQueryStatus(state),
  lanConnectionPictureSubmitQueryStatus: getLanConnectionPictureSubmitQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goToSetupDashboard: (leadId: string, leadStage?: string) =>
    push(PATHS.SETUP_TOOL({ leadId }, leadStage)),
  toggleModal: LayoutActions.toggleModal,
  setFields: VppDocumentationActions.setFields,
  setSubmissionStatus: VppDocumentationActions.setSubmissionStatus,
  saveData: SetupToolActions.saveSubmission,
  saveDataOnlyVppFields: SetupToolActions.saveSubmissionOnlyVppFields,
  saveDataAndPerformVpp: VppDocumentationActions.saveDataAndPerformVpp,
  saveDataAndPerformVppOnlyVppFields: VppDocumentationActions.saveDataAndPerformVppOnlyVppFields,
  getLead: LeadActions.getLead,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const VppDocumentationComponent: React.FC<Props> = ({
  actions,
  leadId,
  leadStage,
  submissionStatus,
  fields,
  uploadedDocuments,
  errors,
  vppState,
  isLoading,
  shouldRefetchLeadData,
  meterPreparationPicturesDocumentSubmitQueryStatus,
  wiringDiagramDocumentSubmitQueryStatus,
  signalMeasurementsPictureSubmitQueryStatus,
  lanConnectionPictureSubmitQueryStatus,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.SETUP, PageName.Setup.VPP_DOCUMENTATION_MODAL);

  const formRef = React.useRef<FormikProps<VppDocumentationInterface>>({} as any);
  const { vppAdditionalFeatures, isDsoReferenceNumberPvFieldVisible } = useAdditionalFeatures();

  const vppDocumentationInitialValues = getVppDocumentationInitialValues(
    isDsoReferenceNumberPvFieldVisible
  );

  const isVppSubmittable =
    vppState === VppState.ALLOWED_TO_PERFORM || vppState === VppState.ALLOWED_TO_PERFORM_AGAIN;
  const isFormVisible = submissionStatus !== VppRegisterStatus.FINISH || isVppSubmittable;

  /**
   * Handlers
   */

  const isFormChanged = <T extends object>(allValues: T) => {
    const allValuesFiltered =
      allValues &&
      omitBy(
        allValues,
        (value, key) =>
          isUndefined(value) ||
          vppDocumentationFileCategories.includes(key as any) ||
          (value as any) === ''
      );
    const fieldValuesFiltered = omitBy(
      fields,
      (value, key) =>
        isUndefined(value) || vppDocumentationFileCategories.includes(key as any) || value === ''
    );

    return (
      isFormVisible &&
      !isEqual(allValuesFiltered, fieldValuesFiltered) &&
      (submissionStatus === VppRegisterStatus.IN_PROGRESS ||
        !isEqual(vppDocumentationInitialValues, allValues))
    );
  };

  const setSynchronizedDocuments = (): void => {
    if (isEmpty(formRef.current)) {
      return;
    }

    const values = {
      ...formRef.current.values,
      ...vppDocumentationFileCategories.reduce((prev, category) => {
        const documents = uploadedDocuments[category];
        const documentsPreparedData =
          documents &&
          uploadedDocuments[category].map((item: any) => ({
            id: item.id,
            fileName: item.fileName,
          }));

        return { ...prev, [category]: documentsPreparedData };
      }, {}),
    };

    formRef.current.setValues(values);
  };

  const setSynchronizedFieldValuesAndDocuments = (): void => {
    if (isEmpty(formRef.current)) {
      return;
    }

    const savedValues = { ...fields };

    let values = {
      ...formRef.current.values,
      ...omitBy(savedValues, isUndefined),
    };

    values = {
      ...values,
      ...vppDocumentationFileCategories.reduce((prev, category) => {
        const documents = uploadedDocuments[category];
        const documentsPreparedData =
          documents &&
          uploadedDocuments[category].map((item: any) => ({
            id: item.id,
            fileName: item.fileName,
          }));

        return { ...prev, [category]: documentsPreparedData };
      }, {}),
    };

    formRef.current.setValues(values);
  };

  const handleSubmit = (values: VppDocumentationInterface): void => {
    actions.setFields(values);
    if (vppState === VppState.ALLOWED_TO_PERFORM_AGAIN) {
      actions.saveDataAndPerformVppOnlyVppFields(values);
    } else {
      actions.saveDataAndPerformVpp(values);
    }
  };

  const handleSaveAndClose = (): void => {
    actions.goToSetupDashboard(leadId!, leadStage);

    if (formRef && formRef.current) {
      const { values } = formRef.current;

      if (isFormChanged(values)) {
        actions.setFields(values);
        actions.setSubmissionStatus(VppRegisterStatus.IN_PROGRESS);
        if (vppState === VppState.ALLOWED_TO_PERFORM_AGAIN) {
          actions.saveDataOnlyVppFields(SubmissionStep.VPP_DOCUMENTATION);
        } else {
          actions.saveData(SubmissionStep.VPP_DOCUMENTATION);
        }
      }
    }
  };

  /**
   * Effects
   */

  React.useEffect(() => {
    actions.toggleModal(true);
    return () => {
      actions.toggleModal(false);
    };
  }, []);

  React.useEffect(() => {
    if (leadId && shouldRefetchLeadData) {
      actions.getLead(leadId, LEAD_UPDATE_QUERY);
    }
  }, [shouldRefetchLeadData]);

  React.useEffect(() => {
    if (fields && formRef.current && !isEmpty(formRef.current)) {
      setSynchronizedDocuments();
    }
  }, [
    uploadedDocuments[FileCategoryName.METER_PICTURES],
    uploadedDocuments[FileCategoryName.WIRING_DIAGRAM_VPP],
    uploadedDocuments[FileCategoryName.COMPLETION_NOTIFICATION],
    uploadedDocuments[FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE],
    uploadedDocuments[FileCategoryName.LAN_CONNECTION_PICTURE],
  ]);

  React.useEffect(() => {
    if (fields && formRef.current && !isEmpty(formRef.current)) {
      setSynchronizedFieldValuesAndDocuments();
    }
  }, [
    fields.pv_commissioning_date,
    fields.battery_commissioning_date,
    fields.production_meter,
    fields.number_production_meter,
    fields.fastening_type_grid,
    fields.fastening_type_consumption,
    fields.meter_number,
    fields.type_of_grid_reference_meter,
    fields.dso_reference_number_pv,
    fields.pv_reduction,
    fields.meter_cabinet_prepared,
    fields.free_space_in_the_upper_part_of_the_meter_cabinet,
    fields.virtual_pv_system,
    fields.virtual_pv_system_subsystem_1_name,
    fields.virtual_pv_system_subsystem_2_name,
    fields.virtual_pv_system_subsystem_1_commissioning_date,
    fields.virtual_pv_system_subsystem_2_commissioning_date,
    fields.upload_speed_mbits,
    fields.download_speed_mbits,
    fields.ping_ms,
    fields.signal_strength_dbm,
    fields.lan_connection_available,
  ]);

  return (
    <Overlay title={I18n.t(T.setupTool.step.vppDocumentation)} onClose={handleSaveAndClose}>
      <ErrorBox errors={errors} />

      {isFormVisible ? (
        <VppDocumentationForm
          formRef={formRef}
          fields={fields}
          isVppSubmittable={isVppSubmittable}
          isLoading={isLoading}
          meterPreparationPicturesDocumentSubmitQueryStatus={
            meterPreparationPicturesDocumentSubmitQueryStatus
          }
          signalMeasurementsPictureSubmitQueryStatus={signalMeasurementsPictureSubmitQueryStatus}
          lanConnectionPictureSubmitQueryStatus={lanConnectionPictureSubmitQueryStatus}
          wiringDiagramDocumentSubmitQueryStatus={wiringDiagramDocumentSubmitQueryStatus}
          onSave={handleSaveAndClose}
          onSubmit={handleSubmit}
        />
      ) : (
        <VppDocumentationRecap fields={fields} vppAdditionalFeatures={vppAdditionalFeatures} />
      )}
    </Overlay>
  );
};

export const VppDocumentation = connect(
  mapStateToProps,
  mapDispatchToProps
)(VppDocumentationComponent);
