import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useParams } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';
import { CountryCode } from 'src/types/country.type';

import { CustomerBatteryUrlParams } from '+app/router/store/types/customerBatteryUrlParams.interface';
import { BatteryTimeOfUseEdit } from '+customer-battery/components/BatteryTimeOfUseEdit';
import { BatteryTimeOfUseNew } from '+customer-battery/components/BatteryTimeOfUseNew';
import {
  getBattery,
  getBatterySoftwareVersion,
  getBatteryTariffWindows,
  getIsBatteryOperatingModeInProgress,
  isBatteryVppActive,
  isEatonBattery,
} from '+customer-battery/store';
import { TooltipHoverable, WidgetAccordion } from '+shared/components';
import { isImpersonated } from '+shared/store/auth/auth.selectors';
import { BatteryActions } from '+shared/store/battery';
import { getSiteOperatingMode } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';

import {
  BatteryOperatingModeForm,
  BatteryOperatingModeOperationsForm,
  BatteryTimeOfUseSchedule,
  OpenWidgetTitle,
} from '../../components';
import { BatteryOperatingModeView } from './BatteryOperatingModeOperation.const';

import './BatteryOperatingModeOperation.component.scss';

const mapStateToProps = (state: StoreState) => ({
  battery: getBattery(state),
  tariffWindows: getBatteryTariffWindows(state),
  operatingMode: getSiteOperatingMode(state),
  userCountry: getUserCountryCode(state) ?? CountryCode.DE,
  isBatteryVppActive: isBatteryVppActive(state),
  isEaton: isEatonBattery(state),
  isBatteryOperatingModeInProgress: getIsBatteryOperatingModeInProgress(state),
  isImpersonated: isImpersonated(state),
  batterySoftwareVersion: getBatterySoftwareVersion(state),
});

const mapDispatchToProps = mapActions({
  setBatteryOperatingMode: BatteryActions.setBatteryOperatingMode,
});

interface OwnProps {
  isMoreThanOneWidgetActive: boolean;
  onWidgetOpening: (openedWidget: OpenWidgetTitle[]) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const BatteryOperatingModeOperationComponent: React.FC<Props> = ({
  actions,
  battery,
  tariffWindows,
  operatingMode,
  userCountry,
  isBatteryVppActive,
  isEaton,
  isBatteryOperatingModeInProgress,
  isImpersonated,
  batterySoftwareVersion,
  isMoreThanOneWidgetActive,
  onWidgetOpening,
}) => {
  const { siteId } = useParams<CustomerBatteryUrlParams>();
  const [isBatteryOperatingModeOpen, setIsBatteryOperatingModeOpen] =
    React.useState<boolean>(false);
  const [batteryOperatingModeView, setBatteryOperatingModeView] =
    React.useState<BatteryOperatingModeView>(BatteryOperatingModeView.OPERATIONS_FORM);

  const handleResetFormSubmittingState =
    (form: FormikProps<BatteryOperatingModeForm>) => (): void => {
      form.setSubmitting(false);
    };

  React.useEffect(() => {
    if (isBatteryOperatingModeOpen) {
      onWidgetOpening([OpenWidgetTitle.OPERATING_MODE]);
    } else onWidgetOpening([]);
  }, [isBatteryOperatingModeOpen]);

  const handleFormSubmit = (form: FormikProps<BatteryOperatingModeForm>) => (): void => {
    if (battery && siteId && form.values.operatingMode) {
      actions.setBatteryOperatingMode({
        operatingMode: form.values.operatingMode,
        batteryId: battery.id,
        siteId,
        batterySerialNumber: battery.serialNumber,
      });
    }

    handleResetFormSubmittingState(form)();
    setIsBatteryOperatingModeOpen(false);
  };

  const isWidgetAccordionDisabled: boolean =
    isBatteryOperatingModeInProgress || isEaton || isImpersonated || isMoreThanOneWidgetActive;

  return (
    <TooltipHoverable
      title={I18n.t(T.customerSingle.batteryDetails.batteryOperations.notAvailableTooltip)}
      isHoveringDisabled={!isEaton}
    >
      <WidgetAccordion
        className={'c-battery-operating-mode-list-item'}
        title={I18n.t(
          T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.headline
        )}
        isOpen={isBatteryOperatingModeOpen}
        isDisabled={isWidgetAccordionDisabled}
        onHeaderClick={() => setIsBatteryOperatingModeOpen(!isBatteryOperatingModeOpen)}
      >
        {batteryOperatingModeView === BatteryOperatingModeView.OPERATIONS_FORM && (
          <BatteryOperatingModeOperationsForm
            operatingMode={operatingMode}
            userCountry={userCountry}
            isBatteryVppActive={isBatteryVppActive}
            batterySoftwareVersion={batterySoftwareVersion}
            onCancel={handleResetFormSubmittingState}
            onSubmit={handleFormSubmit}
            setIsWidgetOpen={setIsBatteryOperatingModeOpen}
            setBatteryOperatingModeView={setBatteryOperatingModeView}
          />
        )}

        {batteryOperatingModeView === BatteryOperatingModeView.TIME_OF_USE_PREVIEW && (
          <BatteryTimeOfUseSchedule
            tariffWindows={tariffWindows}
            setBatteryOperatingModeView={setBatteryOperatingModeView}
          />
        )}

        {batteryOperatingModeView === BatteryOperatingModeView.TIME_OF_USE_EDIT && (
          <BatteryTimeOfUseEdit setBatteryOperatingModeView={setBatteryOperatingModeView} />
        )}

        {batteryOperatingModeView === BatteryOperatingModeView.TIME_OF_USE_NEW && (
          <BatteryTimeOfUseNew setBatteryOperatingModeView={setBatteryOperatingModeView} />
        )}
      </WidgetAccordion>
    </TooltipHoverable>
  );
};

export const BatteryOperatingModeOperation = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryOperatingModeOperationComponent);
