import * as React from 'react';
import { Transition } from 'react-transition-group';

import {
  TabsTrigger,
  TimeRangePicker as TimeRangePickerShared,
  TimeRangePickerDates,
  TimeRangePickerQuickLink,
} from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { getLocale } from '+app/i18n/i18n.helpers';

import { DatepickerArrowSide } from '../DatePicker/DatepickerArrowSide';

import './TimeRangePicker.component.scss';

interface TimeRangePickerProps {
  isActive: boolean;
  arrowPosition?: DatepickerArrowSide;
  minDate?: Date;
  logicalMaxDate?: Date;
  visualMaxDate?: Date;
  selectedDates: TimeRangePickerDates;
  dayViewQuickLinks?: TimeRangePickerQuickLink[];
  monthViewQuickLinks?: TimeRangePickerQuickLink[];
  tabs?: TabsTrigger[];
  applyLabel: string;
  cancelLabel: string;
  onApplyClick: (selectedDates: TimeRangePickerDates) => void;
  onCancelClick: () => void;
}

export const TimeRangePicker: React.VFC<TimeRangePickerProps> = ({
  isActive,
  arrowPosition = DatepickerArrowSide.TOP,
  minDate,
  logicalMaxDate,
  visualMaxDate,
  selectedDates,
  dayViewQuickLinks = [],
  monthViewQuickLinks = [],
  tabs,
  applyLabel,
  cancelLabel,
  onApplyClick,
  onCancelClick,
}) => (
  <Transition in={isActive} timeout={{ enter: 0, exit: 350 }} unmountOnExit={true}>
    {(status) => (
      <div
        className={classNames('c-time-range-picker', {
          'is-active': status === 'entered',
          [`c-time-range-picker--arrow-${arrowPosition}`]: arrowPosition,
        })}
      >
        <div
          className={classNames('c-time-range-picker__content', {
            [`c-time-range-picker__content--arrow-${arrowPosition}`]: arrowPosition,
          })}
        >
          <div className={'c-time-range-picker__day-picker'}>
            <div className="wrapper-for-shared-web-modified-classes">
              <TimeRangePickerShared
                locale={getLocale()}
                minDate={minDate}
                logicalMaxDate={logicalMaxDate}
                visualMaxDate={visualMaxDate}
                isSelectionHighlighted={true}
                selectedDates={selectedDates}
                dayViewQuickLinks={dayViewQuickLinks}
                monthViewQuickLinks={monthViewQuickLinks}
                tabs={tabs}
                applyLabel={applyLabel}
                cancelLabel={cancelLabel}
                onApplyClick={onApplyClick}
                onCancelClick={onCancelClick}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </Transition>
);
