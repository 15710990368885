import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
export var BodyCasing;
(function (BodyCasing) {
    BodyCasing["CAMEL_CASE"] = "CAMEL_CASE";
    BodyCasing["SNAKE_CASE"] = "SNAKE_CASE";
    BodyCasing["SCREAMING_SNAKE_CASE"] = "SCREAMING_SNAKE_CASE";
    BodyCasing["PASCAL_CASE"] = "PASCAL_CASE";
    BodyCasing["KEBAB_CASE"] = "KEBAB_CASE";
})(BodyCasing || (BodyCasing = {}));
export var splitWords = function (text) {
    var words = (text.toUpperCase() === text
        ? text.split(/(?:[ _-]+)/)
        : text.split(/(?:[ _-]+)|(?=[A-Z]+)/)).filter(Boolean);
    return words.length === 0 ? [text] : words;
};
export var deepKeyMap = function (object, mapper) {
    if (isArray(object)) {
        return object.map(function (value) { return deepKeyMap(value, mapper); });
    }
    if (isPlainObject(object)) {
        var result = {};
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                result[mapper(key)] = deepKeyMap(object[key], mapper);
            }
        }
        return result;
    }
    return object;
};
export var toCamelCase = function (object) { return deepKeyMap(object, function (key) { return splitWords(key)
    .map(function (word, index) { return index > 0
    ? word[0].toUpperCase() + word.slice(1).toLowerCase()
    : word.toLowerCase(); })
    .join(''); }); };
export var toPascalCase = function (object) { return deepKeyMap(object, function (key) { return splitWords(key)
    .map(function (word) { return word[0].toUpperCase() + word.slice(1).toLowerCase(); })
    .join(''); }); };
export var toSnakeCase = function (object) { return deepKeyMap(object, function (key) { return splitWords(key)
    .map(function (word) { return word.toLowerCase(); })
    .join('_'); }); };
export var toScreamingSnakeCase = function (object) { return deepKeyMap(object, function (key) { return splitWords(key)
    .map(function (word) { return word.toUpperCase(); })
    .join('_'); }); };
export var toKebabCase = function (object) { return deepKeyMap(object, function (key) { return splitWords(key)
    .map(function (word) { return word.toLowerCase(); })
    .join('-'); }); };
export var noConversion = function (object) { return object; };
export var getCaseConverter = function (bodyCasing) {
    switch (bodyCasing) {
        case BodyCasing.CAMEL_CASE:
            return toCamelCase;
        case BodyCasing.PASCAL_CASE:
            return toPascalCase;
        case BodyCasing.SNAKE_CASE:
            return toSnakeCase;
        case BodyCasing.SCREAMING_SNAKE_CASE:
            return toScreamingSnakeCase;
        case BodyCasing.KEBAB_CASE:
            return toKebabCase;
        default:
            return noConversion;
    }
};
