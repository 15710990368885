import {
  aggregateMeasurement,
  BarChartSeries,
  factorizeBarChartSeries,
  PieChartSeries,
  StatisticsResolution,
  StatisticsSelectedDate,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';

import { SiteStatistics } from '+app/shared/store/site/types/siteStatistics.interface';
import { dateUtil } from '+utils/date.util';
import { DateAdapterTimezone } from '+utils/timezone-types';

import { ChartDataSeries } from '../types/chartDataSeries.interface';

export const transformToSiteStatisticsFilters = (
  statisticsSelectedDate: StatisticsSelectedDate,
  batteryTimeZone: DateAdapterTimezone
) => {
  const { date, resolution, period } = statisticsSelectedDate;

  return {
    start: dateUtil.toTimezone(dateUtil.getStartOf(date, period), batteryTimeZone),
    end: dateUtil.toTimezone(dateUtil.getEndOf(date, period), batteryTimeZone),
    resolution,
  };
};

export const createDefaultStatisticsFilters = (batteryTimeZone: DateAdapterTimezone) => {
  return {
    start: dateUtil.toTimezone(dateUtil.getStartOfDate(dateUtil.now()), batteryTimeZone),
    end: dateUtil.toTimezone(dateUtil.getEndOfDate(dateUtil.now()), batteryTimeZone),
    resolution: StatisticsResolution.HOUR,
  };
};

export const transformStatisticsIntoSeries = (
  statistics: SiteStatistics,
  statisticsSelectedDate: StatisticsSelectedDate
) => {
  const { consumedEnergy, producedEnergy, gridPurchaseEnergy, gridFeedinEnergy } = statistics;

  const pieChart = {
    autonomy: {
      consumedEnergy:
        aggregateMeasurement(consumedEnergy) - aggregateMeasurement(gridPurchaseEnergy),
      gridPurchaseEnergy: aggregateMeasurement(gridPurchaseEnergy),
    },
    selfConsumption: {
      producedEnergy: aggregateMeasurement(producedEnergy) - aggregateMeasurement(gridFeedinEnergy),
      gridFeedinEnergy: aggregateMeasurement(gridFeedinEnergy),
    },
  } as PieChartSeries;

  const barChart = {
    producedEnergy: factorizeBarChartSeries(producedEnergy, statisticsSelectedDate),
    consumedEnergy: factorizeBarChartSeries(consumedEnergy, statisticsSelectedDate),
  } as BarChartSeries;

  return { pieChart, barChart };
};

// TODO: move this and the method from CP to SW - connect in the future
export const mapDataSeries = (dataSeries: Partial<BarChartSeries | ChartDataSeries>) =>
  Object.keys(dataSeries).map((key) => ({
    data: dataSeries[key],
    name: key,
  }));

export const visibleStatisticsSeriesKeys = [
  StatisticsSeriesKey.PRODUCED_ENERGY,
  StatisticsSeriesKey.CONSUMED_ENERGY,
];
