import { createPersistedReducer } from '+app/utils';

import { queryReducer } from '../../../shared/store/query';
import { SITE_ACTIONS, SiteActions } from '../../../shared/store/site';
import { CUSTOMER_ACTIONS, CustomerActions } from '../../store/+customer.actions';
import { ANALYSIS_ACTIONS, AnalysisActions } from './+analysis.actions';
import {
  AnalysisPageState,
  GET_FORECAST_CONSUMPTION_QUERY,
  GET_FORECAST_PRODUCTION_QUERY,
  GET_SITE_MEASUREMENTS_QUERY,
  initialState,
} from './+analysis.state';

export const reducer = createPersistedReducer<
  AnalysisPageState,
  SiteActions | AnalysisActions | CustomerActions
>((state = initialState, action): AnalysisPageState => {
  switch (action.type) {
    case SITE_ACTIONS.SET_SITE_MEASUREMENTS:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          isUnsupportedBattery: false,
          siteMeasurements: action.siteMeasurements,
        },
      };

    case ANALYSIS_ACTIONS.SET_INITIAL_DATA_SERIES:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          initialDataSeries: {
            ...state.dayChart.initialDataSeries,
            resolution: action.resolution
              ? action.resolution
              : state.dayChart.initialDataSeries.resolution,
            data: {
              ...state.dayChart.initialDataSeries.data,
              ...action.dataSeries,
            },
          },
        },
      };

    case ANALYSIS_ACTIONS.SET_CURRENT_DATA_SERIES:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          currentDataSeries: {
            ...state.dayChart.currentDataSeries,
            resolution: action.resolution
              ? action.resolution
              : state.dayChart.currentDataSeries.resolution,
            data: {
              ...state.dayChart.currentDataSeries.data,
              ...action.dataSeries,
            },
          },
        },
      };

    case ANALYSIS_ACTIONS.TOGGLE_DATA_SERIES:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          selectedDataSeriesKeys: state.dayChart.selectedDataSeriesKeys.includes(
            action.dataSeriesKey
          )
            ? state.dayChart.selectedDataSeriesKeys.filter(
                (dataSerie) => dataSerie !== action.dataSeriesKey
              )
            : [...state.dayChart.selectedDataSeriesKeys, action.dataSeriesKey],
        },
      };

    case ANALYSIS_ACTIONS.SET_INITIAL_CHART_DATES:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          initialDataSeries: {
            ...state.dayChart.initialDataSeries,
            selectedDates: action.dates,
          },
        },
      };

    case ANALYSIS_ACTIONS.SET_CURRENT_CHART_DATES:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          currentDataSeries: {
            ...state.dayChart.currentDataSeries,
            selectedDates: action.dates,
          },
          isResolutionChanged: true,
        },
      };

    case ANALYSIS_ACTIONS.CLEAR_ZOOM_STATE:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          initialDataSeries: {
            ...state.dayChart.initialDataSeries,
            selectedDates: state.dayChart.initialDataSeries.selectedDates,
          },
          currentDataSeries: {
            ...initialState.dayChart.currentDataSeries,
          },
          isResolutionChanged: false,
        },
      };

    case ANALYSIS_ACTIONS.SET_FORECAST_PRODUCTION_FULL:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          forecastProductionFull: action.forecastProductionFull,
        },
      };

    case ANALYSIS_ACTIONS.SET_FORECAST_CONSUMPTION_FULL:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          forecastConsumptionFull: action.forecastConsumptionFull,
        },
      };

    case ANALYSIS_ACTIONS.CLEAR_FORECAST_DATA:
      return {
        ...state,
        dayChart: {
          ...state.dayChart,
          forecastProductionFull: [],
          forecastConsumptionFull: [],
          initialDataSeries: {
            ...state.dayChart.initialDataSeries,
            data: {
              ...state.dayChart.initialDataSeries.data,
              forecastConsumptionPower: [],
              forecastProductionPower: [],
            },
          },
        },
      };

    case ANALYSIS_ACTIONS.SET_STATISTICS_DATE:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          selectedDate: action.statisticsSelectedDate,
        },
      };

    case ANALYSIS_ACTIONS.SET_PIE_CHART_SERIES:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          pieChartSeries: action.pieChartSeries,
        },
      };

    case ANALYSIS_ACTIONS.SET_BAR_CHART_SERIES:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          barChartSeries: action.barChartSeries,
        },
      };

    case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
      return {
        ...initialState,
        dayChart: {
          ...initialState.dayChart,
          selectedDataSeriesKeys: state.dayChart.selectedDataSeriesKeys,
        },
      };

    default:
      return queryReducer(state, action, [
        GET_SITE_MEASUREMENTS_QUERY,
        GET_FORECAST_PRODUCTION_QUERY,
        GET_FORECAST_CONSUMPTION_QUERY,
      ]);
  }
})({
  key: 'analysis',
  whitelist: ['dayChart'],
  transforms: [
    {
      in: (state, key) => {
        if (key === 'dayChart') {
          return {
            selectedDataSeriesKeys: state.selectedDataSeriesKeys,
          };
        }

        return state;
      },
      out: (state) => state,
    },
  ],
});
