import { ListTableHeaderItem } from '+shared/components';

export const getTableHeaders = (): ListTableHeaderItem[] => [
  { label: 'Thumbnail' },
  { label: 'Title' },
  { label: 'Categories' },
  { label: 'Youtube id' },
  { label: 'Market' },
  { label: 'Actions' },
];
