import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import classNames from 'classnames';
import { debounce } from 'lodash/fp';

import { SiteListRouteQueryParams } from '+router/routes';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';

import { Button, ButtonSize, ButtonStatus, ButtonStyle, ButtonType } from '../Button';
import { InputBox } from '../InputBox';
import { getPaginationPages } from './Pagination.helper';

import './Pagination.component.scss';

const MIN_PAGE = 1;
const PAGES_AMOUNT_FOR_DETAILED_PAGINATION = 4;

interface Props {
  totalPages: number;
  currentPage: number;
  onPageChange: (
    page: number,
    recordsPerPage: string,
    routerQueryParams: SiteListRouteQueryParams
  ) => void;
  routerQueryParams: SiteListRouteQueryParams;
  recordsPerPage: string;
}

export const PaginationNew: React.FC<Props> = ({
  currentPage,
  totalPages,
  onPageChange,
  routerQueryParams,
  recordsPerPage,
}) => {
  const limitPageNumber = (page: number): number => {
    page = Math.max(page, MIN_PAGE);
    page = Math.min(page, totalPages);
    return page;
  };
  // needed in case page attribute in route is out of bounds
  currentPage = limitPageNumber(currentPage);

  const isArrowVisible: boolean = totalPages > MIN_PAGE;
  const isJumpToPageVisible: boolean = totalPages > PAGES_AMOUNT_FOR_DETAILED_PAGINATION;
  const [jumpToPageInput, setJumpToPageInput] = useState('');

  useEffect(() => {
    setJumpToPageInput(String(currentPage));
  }, [currentPage]);

  const debouncePageJump = useCallback(
    debounce(
      750,
      (value: number, recordsPerPage: string, routerQueryParams: SiteListRouteQueryParams) =>
        onPageChange(value, recordsPerPage, routerQueryParams)
    ),
    []
  );

  const onJumpToPageChange = (value: string) => {
    if (Number.isNaN(Number(value)) || Number(value) > totalPages || parseInt(value) === 0) {
      return;
    }

    setJumpToPageInput(value);

    if (value) {
      debouncePageJump(limitPageNumber(Number(value)), recordsPerPage, routerQueryParams);
    }
  };

  return (
    <div className="c-pagination">
      {isJumpToPageVisible && (
        <>
          <LabelMedium
            text={I18n.t(T.dashboard.customersTable.pagination.jumpToPage)}
            className="c-pagination__jump-to-page-label"
          />

          <InputBox
            className={'c-pagination__jump-to-page-input'}
            placeholder={I18n.t(T.dashboard.customersTable.pagination.jumpToPageInputPlaceholder)}
            value={jumpToPageInput}
            onChange={onJumpToPageChange}
          />
        </>
      )}

      <span className="c-pagination__page-list-container">
        {isArrowVisible && (
          <Button
            style={ButtonStyle.LEFT_ICON}
            className={'c-pagination__btn-prev'}
            size={ButtonSize.SMALL}
            type={ButtonType.TERTIARY}
            disabled={MIN_PAGE === currentPage}
            onClick={() => onPageChange(currentPage - 1, recordsPerPage, routerQueryParams)}
          />
        )}
        <span className={'c-pagination__page-list'}>
          {getPaginationPages(currentPage, totalPages).map((page, index) => {
            if (typeof page === 'number') {
              return (
                <Button
                  label={`${page}`}
                  key={index}
                  size={ButtonSize.SMALL}
                  type={ButtonType.TERTIARY}
                  className={classNames('c-pagination__page', {
                    'is-active': currentPage === page,
                  })}
                  onClick={() =>
                    currentPage !== page && onPageChange(page, recordsPerPage, routerQueryParams)
                  }
                />
              );
            }
            return (
              <span key={index} className={'c-pagination__rest'}>
                {'...'}
              </span>
            );
          })}
        </span>
        {isArrowVisible && (
          <Button
            style={ButtonStyle.LEFT_ICON}
            size={ButtonSize.SMALL}
            type={ButtonType.TERTIARY}
            className={'c-pagination__btn-next'}
            disabled={totalPages === currentPage}
            status={totalPages === currentPage ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
            onClick={() => onPageChange(currentPage + 1, recordsPerPage, routerQueryParams)}
          />
        )}
      </span>
    </div>
  );
};
