import { Market } from '+utils/market.util';

export const getImprintLink = (market: Market): string => {
  switch (market) {
    case Market.DE:
    case Market.AT:
    case Market.CH:
      return 'https://sonnen.de/impressum/';
    case Market.US:
      return 'https://sonnenusa.com/en/imprint/';
    case Market.AU:
      return 'https://sonnen.com.au/imprint/';
    case Market.IT:
      return 'https://sonnen.it/imprint-sonnen/';
    default:
      return 'https://sonnengroup.com/imprint/';
  }
};

export const getFacebookLink = (market: Market): string => {
  switch (market) {
    case Market.DE:
    case Market.AT:
    case Market.CH:
    case Market.IT:
      return 'https://www.facebook.com/sonnenit';
    case Market.GME:
    case Market.ES:
    case Market.US:
      return 'https://www.facebook.com/sonnende/';
    case Market.AU:
      return 'https://www.facebook.com/sonnenau/';
    default:
      return 'https://www.facebook.com/sonnende/';
  }
};

export const getInstagramLink = (market: Market): string => {
  switch (market) {
    case Market.DE:
    case Market.AT:
    case Market.CH:
    case Market.IT:
    case Market.GME:
    case Market.ES:
    case Market.US:
      return '';
    case Market.AU:
      return 'https://www.instagram.com/sonnenaustralia/';
    default:
      return '';
  }
};

export const getTwitterLink = (market: Market): string => {
  switch (market) {
    case Market.DE:
    case Market.AT:
    case Market.CH:
    case Market.IT:
      return 'https://twitter.com/sonnenCommunity';
    case Market.GME:
    case Market.ES:
    case Market.US:
      return 'https://twitter.com/sonnenCommunity';
    case Market.AU:
      return '';
    default:
      return 'https://twitter.com/sonnenCommunity';
  }
};

export const getLinkedInLink = (market: Market): string => {
  switch (market) {
    case Market.DE:
    case Market.AT:
    case Market.CH:
    case Market.IT:
    case Market.GME:
    case Market.ES:
    case Market.US:
      return '';
    case Market.AU:
      return 'https://www.linkedin.com/company/sonnen-australia/';
    default:
      return '';
  }
};

export const getYoutubeLink = (market: Market): string => {
  switch (market) {
    case Market.DE:
    case Market.AT:
    case Market.CH:
    case Market.IT:
      return 'https://www.youtube.com/c/sonnenItalia';
    case Market.GME:
    case Market.ES:
    case Market.US:
      return 'https://www.youtube.com/user/Sonnenbatterie';
    case Market.AU:
      return 'https://www.youtube.com/channel/UCy7-jRwuxKQt2mq1HsP8ibw';
    default:
      return 'https://www.youtube.com/user/Sonnenbatterie';
  }
};

export const getFacebookAvailableCountries = (): Market[] => [
  Market.DE,
  Market.AT,
  Market.CH,
  Market.IT,
  Market.GME,
  Market.ES,
  Market.US,
  Market.AU,
];
export const getInstagramAvailableCountries = (): Market[] => [Market.AU];
export const getTwitterAvailableCountries = (): Market[] => [
  Market.DE,
  Market.AT,
  Market.CH,
  Market.IT,
  Market.GME,
  Market.ES,
  Market.US,
];
export const getLinkedInAvailableCountries = (): Market[] => [Market.AU];
export const getYoutubeAvailableCountries = (): Market[] => [
  Market.DE,
  Market.AT,
  Market.CH,
  Market.IT,
  Market.GME,
  Market.ES,
  Market.US,
  Market.AU,
];
