import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { CountryCode } from 'src/types/country.type';

import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { useLocaleContext } from '+utils/react/locale.provider';

import './CertificateEmptyMessage.scss';

interface Props {
  userCountry: CountryCode;
}

const CertificateEmptyMessage: React.FC<Props> = ({ userCountry }) => {
  const { locale } = useLocaleContext();
  const isLanguageGerman = locale === 'de';
  const isCountryItaly = userCountry === CountryCode.IT;

  const {
    noCertificatesTextBegin,
    link,
    noCertificatesTextLinkLabel,
    noCertificatesTextEnd,
    noCertificatesTextBeginItalian,
    italianCourseLabel,
    italianCourse,
    noCertificatesTextEndItalian,
    trainingMailItaly,
  } = T.myAccount.certification;

  const textWithHtmlLineBreak = (
    <span
      dangerouslySetInnerHTML={{
        __html: I18n.t(isCountryItaly ? noCertificatesTextBeginItalian : noCertificatesTextBegin),
      }}
    />
  );

  return (
    <p className="certificate-empty-message">
      <BodyMedium
        text={textWithHtmlLineBreak}
        className="certificate-empty-message__display-inline"
      />

      <a
        href={I18n.t(isCountryItaly ? italianCourse : link)}
        className="certificate-empty-message__link"
        target="_blank"
        rel="noreferrer"
      >
        {I18n.t(isCountryItaly ? italianCourseLabel : noCertificatesTextLinkLabel)}
      </a>

      {!isLanguageGerman && ' '}

      <BodyMedium
        text={I18n.t(isCountryItaly ? noCertificatesTextEndItalian : noCertificatesTextEnd)}
        className="certificate-empty-message__display-inline"
      />

      {isCountryItaly && (
        <>
          {' '}
          <a
            href={`mailto:${I18n.t(trainingMailItaly)}`}
            className="certificate-empty-message__link"
            target="_blank"
            rel="noreferrer"
          >
            {I18n.t(trainingMailItaly)}
          </a>
        </>
      )}
    </p>
  );
};

export default CertificateEmptyMessage;
