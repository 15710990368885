import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web/src/components/Status/Status.component';

import { get } from 'lodash';

export const setHintColor = (level: number): StatusType =>
  get(
    {
      1: StatusType.DANGER,
      2: StatusType.WARNING,
      3: StatusType.INFO,
    },
    level,
    StatusType.DEFAULT
  );

export const setHintCategory = (level: number): string =>
  get(
    {
      1: I18n.t(T.newsChannel.level.failure),
      2: I18n.t(T.newsChannel.level.warning),
      3: I18n.t(T.newsChannel.level.info),
    },
    level,
    I18n.t(T.newsChannel.level.info)
  );

export const setTagName = (tag: string): string =>
  get(
    {
      important: I18n.t(T.newsChannel.tag.important),
      new: I18n.t(T.newsChannel.tag.new),
      information: I18n.t(T.newsChannel.tag.information),
      urgentUpdate: I18n.t(T.newsChannel.tag.urgentUpdate),
      sonnenNews: I18n.t(T.newsChannel.tag.sonnenNews),
      sales: I18n.t(T.newsChannel.tag.sales),
      technicalDepartment: I18n.t(T.newsChannel.tag.technicalDepartment),
      partnerPortal: I18n.t(T.newsChannel.tag.partnerPortal),
    },
    tag,
    ''
  );
