import React, { useEffect, useMemo, useState } from 'react';

import { useResizeObserver } from '@sonnen/shared-web';

import { BatteryTariffWindow } from '+customer-battery/components/BatteryTariffWindow';
import {
  TARIFF_WINDOW_CHUNKS_IN_DAY,
  TariffWindow,
  tariffWindowManipulator,
} from '+customer-battery/store/battery-tariff';

import './BatteryTimeOfUseScheduleGraph.component.scss';

interface Props {
  tariffWindows: TariffWindow[];
}

export const BatteryTimeOfUseScheduleGraph: React.FC<Props> = ({ tariffWindows }) => {
  const { ref, width: scheduleWidth } = useResizeObserver<HTMLDivElement>();
  const [pixelsPerChunk, setPixelsPerChunk] = useState<number>(0);

  useEffect(() => {
    setPixelsPerChunk(scheduleWidth / TARIFF_WINDOW_CHUNKS_IN_DAY);
  }, [scheduleWidth]);

  const internalTariffWindows = useMemo(() => {
    const collisionFreeTariffWindows =
      tariffWindowManipulator.removeDuplicateTariffWindows(tariffWindows);

    return tariffWindowManipulator.mapTariffWindowsToInternalRepresentation(
      collisionFreeTariffWindows
    );
  }, [tariffWindows]);

  return (
    <div ref={ref} className={'battery__schedule__graph'}>
      <div className="battery__schedule__graph--start_label">12am</div>
      {internalTariffWindows.map((internalTariffWindow, index) => (
        <BatteryTariffWindow
          key={index}
          {...internalTariffWindow}
          pixelsPerChunk={pixelsPerChunk}
        />
      ))}
      <div className="battery__schedule__graph--end_label">12pm</div>
    </div>
  );
};
