import * as React from 'react';

import { TableCell } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { DeleteButton } from '+shared/components/DeleteButton';
import { CategoryName } from '+shared/store/firebase/types';
import { Market } from '+utils/market.util';

import './VideoListItem.component.scss';

interface Props {
  id: string;
  youtubeId: string;
  title: string;
  thumbnail: string;
  categories: CategoryName[];
  markets: Market[];
  deleteVideo: (videoId: string) => void;
}

export const VideoListItem: React.FC<Props> = ({
  id,
  youtubeId,
  title,
  thumbnail,
  categories,
  markets,
  deleteVideo,
}) => (
  <div className={'c-video-list-item'}>
    <TableCell className={'c-video-list-item__cell'}>
      <img src={thumbnail} />
    </TableCell>
    <TableCell className={classNames('c-video-list-item__cell', 'c-video-list-item__cell--title')}>
      {title}
    </TableCell>
    <TableCell className={'c-video-list-item__cell'}>
      {categories.map((category) => `${category}`)}
    </TableCell>
    <TableCell
      className={classNames('c-video-list-item__cell', 'c-video-list-item__cell--youtube-id')}
    >
      {youtubeId}
    </TableCell>
    <TableCell className={'c-video-list-item__cell'}>{markets && markets.join(', ')}</TableCell>
    <TableCell
      className={classNames('c-video-list-item__cell', 'c-video-list-item__cell--actions')}
    >
      <DeleteButton onDelete={() => deleteVideo(id)} />
    </TableCell>
  </div>
);
