import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const HeatPump: React.FC<Props> = ({ color, size = 24, className }) => (
  <svg className={className} width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path
      d="M20 5H4C2.89543 5 2 5.89543 2 7V17.2989C2 18.4035 2.89543 19.2989 4 19.2989H20C21.1046 19.2989 22 18.4035 22 17.2989V7C22 5.89543 21.1046 5 20 5Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5327 19.3985V19.9999H18.3896V19.3984"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.55463 19.4409V19.9999L3.41162 19.9999V19.441"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04202 16.3389C10.584 16.3389 12.6446 14.2782 12.6446 11.7363C12.6446 9.19442 10.584 7.13379 8.04202 7.13379C5.50009 7.13379 3.43945 9.19442 3.43945 11.7363C3.43945 14.2782 5.50009 16.3389 8.04202 16.3389Z"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.96378 10.6114L4.8374 8.45508"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2904 14.9979L9.14893 12.8262"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.17676 10.6943L11.2901 8.55615"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.92285 14.9982L6.99814 12.8984"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.54834 11.7363H12.6455"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.43945 11.7363H6.52456"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04297 13.249V16.3386"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04297 7.13379V10.2095"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0438 13.2497C8.8797 13.2497 9.55733 12.5721 9.55733 11.7362C9.55733 10.9003 8.8797 10.2227 8.0438 10.2227C7.2079 10.2227 6.53027 10.9003 6.53027 11.7362C6.53027 12.5721 7.2079 13.2497 8.0438 13.2497Z"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
