import * as React from 'react';

import { WarningText, WarningTextSize } from '@sonnen/shared-web';

import { ConfigurationBoxLine } from '../LeadConfigurationPreview';
import LeadConfigurationBoxLine from './components/LeadConfigurationBoxLine';

import './LeadConfigurationBox.component.scss';

interface Props {
  parameters: ConfigurationBoxLine[];
  warningText?: string;
  areSectionsOpen: boolean;
}

export const LeadConfigurationBox: React.FC<Props> = ({
  parameters,
  warningText,
  areSectionsOpen,
}) =>
  parameters.length > 0 ? (
    <section className={'c-lead-configuration-box'}>
      <div className={'c-lead-configuration-box__body'}>
        {parameters.map(({ title, value, size, alwaysVisible, helperText }) =>
          areSectionsOpen ? (
            <LeadConfigurationBoxLine
              title={title}
              value={value}
              size={size}
              helperText={helperText}
            />
          ) : (
            alwaysVisible && (
              <LeadConfigurationBoxLine
                title={title}
                value={value}
                size={size}
                helperText={helperText}
              />
            )
          )
        )}
      </div>
      {warningText && (
        <div className={'c-lead-configuration-box__warning'}>
          <WarningText text={warningText} size={WarningTextSize.MEDIUM} />
        </div>
      )}
    </section>
  ) : null;
