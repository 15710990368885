import React from 'react';

type Props = {
  color?: string;
  size?: string;
  className?: string;
};

export const WarningFilled: React.FC<Props> = ({ color = '#0B7AC2', size = '24', className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1297 3.53829C11.5126 2.86154 12.4874 2.86154 12.8703 3.53829L21.9056 19.5076C22.2828 20.1742 21.8012 21 21.0352 21H2.96475C2.19882 21 1.71723 20.1742 2.0944 19.5076L11.1297 3.53829Z"
      fill={color}
    />
    <path
      d="M11.8489 14.0282H12.1655C12.295 14.0282 12.4245 13.9155 12.4388 13.8028L12.8561 9.84507V8.26761C12.8561 8.12676 12.7266 8 12.5827 8H11.4317C11.2878 8 11.1583 8.12676 11.1583 8.26761V9.87324L11.5755 13.8028C11.5899 13.9155 11.7194 14.0282 11.8489 14.0282ZM11 17.0282C11 17.5634 11.4604 18 11.9928 18C12.554 18 13 17.5634 13 17.0282C13 16.4789 12.554 16.0423 11.9928 16.0423C11.4604 16.0423 11 16.4789 11 17.0282Z"
      fill="white"
    />
  </svg>
);
