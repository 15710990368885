import { ActionsObservable, combineEpics } from 'redux-observable';
import { ignoreElements, map, tap } from 'rxjs/operators';

import { ofType } from '../utils';
import { I18nActions, I18nActionsType } from './i18n.actions';
import { setLocale } from './i18n.helpers';

type Action$ = ActionsObservable<I18nActionsType>;

export const setLocale$ = (action$: Action$) =>
  action$.pipe(
    ofType(I18nActions.setLocale),
    map((action) => action.locale),
    tap(setLocale),
    tap(() => window.location.reload()),
    ignoreElements()
  );

export const epics = combineEpics<any>(setLocale$);
