import { ActionsUnion, createAction } from '+app/utils';
import { PvRegisterState } from '+setupTool/+pvRegister/store/types';
import { Document } from '+shared/store/setupTool/types';

import { PV_REGISTER_MANUALLY_QUERY } from './+pvRegister.state';

export enum PV_REGISTER_ACTIONS {
  INIT = '[+PV_Register] INIT',
  SUBMIT_REGISTER_PV = '[+PV_Register] SUBMIT_REGISTER_PV',
  REGISTER_PVR_MANUALLY = '[+PV_Register] REGISTER_PVR_MANUALLY',
  SAVE_SUBMISSION_AND_REGISTER_PV = '[+PV_Register] SAVE_SUBMISSION_AND_REGISTER_PV',
  SUBMIT_REGISTER_PV_SUCCESS = '[+PV_Register] SUBMIT_REGISTER_PV_SUCCESS',
  SUBMIT_REGISTER_PV_ERROR = '[+PV_Register] SUBMIT_REGISTER_PV_ERROR',
  SUBMIT_REGISTER_PV_FINISHED = '[+PV_Register] SUBMIT_REGISTER_PV_FINISHED',

  GET_SUBMISSIONS_SUCCESS = '[+PV_Register] GET_SUBMISSIONS_SUCCESS',
  GET_SUBMISSIONS_FAILURE = '[+PV_Register] GET_SUBMISSIONS_FAILURE',
  STOP_GETTING_SUBMISSIONS = '[+PV_Register] STOP_GETTING_SUBMISSIONS',
  START_GETTING_SUBMISSIONS = '[+PV_Register] START_GETTING_SUBMISSIONS',

  SET_GENERATED_DOCUMENTS = '[+PV_Register] SET_GENERATED_DOCUMENTS',
  SET_MODIFICATION_DATE = '[+PV_Register] SET_MODIFICATION_DATE',
  SET_PV_REGISTER_STATE = '[+PV_Register] SET_PV_REGISTER_STATE',
}

export const PVRegisterActions = {
  init: createAction(PV_REGISTER_ACTIONS.INIT, (data: any) => ({ data })),
  submitRegisterPV: createAction(PV_REGISTER_ACTIONS.SUBMIT_REGISTER_PV),
  registerPvrManually: createAction(
    PV_REGISTER_ACTIONS.REGISTER_PVR_MANUALLY,
    (submissionId: string) => ({ submissionId, queryKey: PV_REGISTER_MANUALLY_QUERY })
  ),
  saveSubmissionAndRegisterPV: createAction(PV_REGISTER_ACTIONS.SAVE_SUBMISSION_AND_REGISTER_PV),
  submitRegisterPVSuccess: createAction(
    PV_REGISTER_ACTIONS.SUBMIT_REGISTER_PV_SUCCESS, // TODO Redux
    (response: any /* @TODO */) => ({ response })
  ),
  submitRegisterPVError: createAction(
    PV_REGISTER_ACTIONS.SUBMIT_REGISTER_PV_ERROR, // TODO Redux
    (error: any /* @TODO */) => ({ error })
  ),
  submitRegisterPVFinished: createAction(
    PV_REGISTER_ACTIONS.SUBMIT_REGISTER_PV_FINISHED,
    (submission: any) => ({ submission })
  ),
  getSubmissionsSuccess: createAction(
    PV_REGISTER_ACTIONS.GET_SUBMISSIONS_SUCCESS,
    (response: any /* @TODO */) => ({ response })
  ),
  getSubmissionsFailure: createAction(
    PV_REGISTER_ACTIONS.GET_SUBMISSIONS_FAILURE,
    (error: any /* @TODO */) => ({ error })
  ),
  stopGettingSubmissions: createAction(PV_REGISTER_ACTIONS.STOP_GETTING_SUBMISSIONS),
  startGettingSubmissions: createAction(
    PV_REGISTER_ACTIONS.START_GETTING_SUBMISSIONS,
    (response: any) => ({ response })
  ),
  setPVRegisterGeneratedDocuments: createAction(
    PV_REGISTER_ACTIONS.SET_GENERATED_DOCUMENTS,
    (documents: Document[]) => ({ documents })
  ),
  setPVRegisterModificationDate: createAction(
    PV_REGISTER_ACTIONS.SET_MODIFICATION_DATE,
    (date: string) => ({ date })
  ),
  setPVRegisterState: createAction(
    PV_REGISTER_ACTIONS.SET_PV_REGISTER_STATE,
    (pvRegisterState: PvRegisterState) => ({
      pvRegisterState: pvRegisterState,
    })
  ),
};

export type PVRegisterActions = ActionsUnion<typeof PVRegisterActions>;
