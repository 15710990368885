import { T } from '@sonnen/shared-i18n/service';
import { CountryCode } from '@sonnen/shared-web';

import { isObject } from 'lodash/fp';

import { mapUserCountryToCaseCountry } from '+shared/store/case/case.helper';
import {
  CaseOrigin,
  CasePriority,
  CaseStatus,
  CaseType,
  EServicesCaseCategory,
  EServicesCaseSubcategory,
  TechnicalCaseCategory,
  TechnicalCaseSubcategory,
} from '+shared/store/case/types/createCase.interface';

import {
  CategorySelectItem,
  RecordType,
  RecordTypeSupport,
  SubcategorySelectItem,
} from './CaseCommon.types';

export const priority = CasePriority.NORMAL;
export const origin = CaseOrigin.PARTNER_PORTAL;
export const status = CaseStatus.NEW;

const generalCaseLabel = T.report.form.labels.category;
const technicalCaseLabel = T.cases.creation.technicalCase.title;

export const getTechnicalCaseLabel = (technicalCase: boolean) =>
  technicalCase ? technicalCaseLabel : generalCaseLabel;

export const isEServices = (type?: RecordType) => type === 'eServices';

export const getCommonSubmitFields = (
  category: CategorySelectItem | string,
  subcategory: SubcategorySelectItem | undefined,
  recordType: RecordType,
  isSpanishMarketEnabled: boolean,
  userCountry: CountryCode | undefined
) => {
  // This function hides a mismatch between types
  let mappedCategory = undefined;
  let mappedSubcategory = undefined;

  const eServices = recordType === RecordTypeSupport.E_SERVICES;
  const technical = recordType === RecordTypeSupport.TECHNICAL;

  if (eServices) {
    if (isObject(category)) mappedCategory = category.value;
    mappedSubcategory = EServicesCaseSubcategory.REMAINING;
  }

  if (technical) {
    if (isObject(subcategory)) mappedCategory = subcategory.value;
    mappedSubcategory = 'other';
  }

  return {
    status,
    priority,
    origin,
    type: eServices ? CaseType.REQUEST : CaseType.INCIDENT,
    country: mapUserCountryToCaseCountry(isSpanishMarketEnabled, userCountry),
    category: mappedCategory as unknown as
      | TechnicalCaseCategory
      | EServicesCaseCategory
      | undefined,
    subcategory: mappedSubcategory as
      | TechnicalCaseSubcategory
      | EServicesCaseSubcategory
      | undefined,
  };
};
