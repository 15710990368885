import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';

import { CostRow, getSignByValue } from '../LeadImpactAnalysisCalculationResults.helper';
import { PageDetailedCalculation20YearsData } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

const CumulativeSavingsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <div
    className={`c-impact-analysis-results
      c-impact-analysis-results-box-green
      c-impact-analysis-results-background-green-tint-3`}
  >
    <CostRow
      sign={getSignByValue(data.savingsAfter20Years)}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.savings.title)}
      textType={'labelLarge'}
      value={Math.abs(data.savingsAfter20Years)}
    />
    <LineDivider className="c-impact-analysis-results__line-divider" />
    <CostRow
      sign={'+'}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.savings.info)}
      textType={'bodyMedium'}
    />
  </div>
);

export default CumulativeSavingsAfter20Years;
