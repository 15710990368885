import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { DetailListTable } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Lead } from '+shared/store/lead/types';

import { getLeadAdditionalDetailsList } from './LeadOverviewAdditionalDetails.helper';

interface Props {
  lead: Lead;
}

export const LeadOverviewAdditionalDetails = ({ lead }: Props) => (
  <Widget
    icon="ThreeDotsCircle"
    heading={I18n.t(T.lead.boc._salessolution_.additionalDetails.heading)}
  >
    <DetailListTable
      list={getLeadAdditionalDetailsList(lead)}
      filterNil={false}
      lead={lead}
      hasBottomBorder={false}
    />
  </Widget>
);
