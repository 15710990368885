import { HttpResponseError } from '@coolio/http';

import { ActionsUnion } from '+utils/redux/actionsUnion.util';
import { createAction } from '+utils/redux/createAction.util';

export enum QUERY_ACTIONS {
  INIT = '[Query] INIT',
  PENDING = '[Query] PENDING',
  SUCCESS = '[Query] SUCCESS',
  FAILURE = '[Query] FAILURE',
}

export const QueryActions = {
  init: createAction(QUERY_ACTIONS.INIT, (queryName: string) => ({ queryName })),

  pending: createAction(QUERY_ACTIONS.PENDING, (queryName: string) => ({ queryName })),

  success: createAction(QUERY_ACTIONS.SUCCESS, (queryName: string, response: any | undefined) => ({
    queryName,
    response,
  })),

  failure: createAction(QUERY_ACTIONS.FAILURE, (queryName: string, error: HttpResponseError) => ({
    queryName,
    error,
  })),
};

export type QueryActions = ActionsUnion<typeof QueryActions>;
