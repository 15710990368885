import * as React from 'react';

import { BoldParagraph } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import './FormSectionParagraph.component.scss';

interface Props {
  className?: string;
  sectionDisabled?: boolean;
}

export const FormSectionParagraph: React.FC<Props> = ({
  className,
  children,
  sectionDisabled = false,
}) => (
  <BoldParagraph
    className={classNames('c-form-section-paragraph', className, {
      'c-form-section-paragraph--disabled': sectionDisabled,
    })}
  >
    {children}
  </BoldParagraph>
);
