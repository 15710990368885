import { deepFreeze, isBright, parseColor, removeEqualProps } from '../utils';
import { parseFont } from './font';
var defaultFont = { fontFamily: 'Arial', fontSize: 12 };
var defaultPaintOptions = {
    font: parseFont(defaultFont),
    textDirection: 'ltr',
    textAlign: 'left',
    textBaseline: 'bottom',
    lineWidth: 0,
    lineDash: [],
    lineRounding: false,
};
var Paint = (function () {
    function Paint() {
        this.fontString = defaultPaintOptions.font;
        this.font = defaultFont;
        this.textDirection = defaultPaintOptions.textDirection;
        this.textAlign = defaultPaintOptions.textAlign;
        this.textBaseline = defaultPaintOptions.textBaseline;
        this.lineWidth = defaultPaintOptions.lineWidth;
        this.lineDash = defaultPaintOptions.lineDash;
        this.lineRounding = defaultPaintOptions.lineRounding;
    }
    Paint.prototype.setFont = function (font) {
        this.font = font;
        this.fontString = parseFont(font);
        return this;
    };
    Paint.prototype.getLineHeight = function () {
        return this.font &&
            this.font.lineHeight ||
            this.font.fontSize ||
            defaultFont.lineHeight ||
            defaultFont.fontSize;
    };
    Paint.prototype.setTextDirection = function (textDirection) {
        this.textDirection = textDirection;
        return this;
    };
    Paint.prototype.setTextAlign = function (textAlign) {
        this.textAlign = textAlign;
        return this;
    };
    Paint.prototype.setTextBaseline = function (textBaseline) {
        this.textBaseline = textBaseline;
        return this;
    };
    Paint.prototype.setFillStyle = function (fill) {
        this.fillStyle = fill;
        return this;
    };
    Paint.prototype.setStrokeStyle = function (stroke) {
        this.strokeStyle = stroke;
        return this;
    };
    Paint.prototype.setLineWidth = function (lineWidth) {
        if (lineWidth < 0) {
            throw new Error("Paint's lineWidth property must be greater than or equal 0: got ".concat(lineWidth));
        }
        this.lineWidth = lineWidth;
        return this;
    };
    Paint.prototype.setLineRounding = function (lineRounding) {
        this.lineRounding = lineRounding;
        return this;
    };
    Paint.prototype.setLineDash = function (lineDash) {
        this.lineDash = lineDash;
        return this;
    };
    Paint.prototype.canDrawFill = function () {
        return !!this.fillStyle;
    };
    Paint.prototype.canDrawStroke = function () {
        return !!(this.strokeStyle && this.lineWidth);
    };
    Paint.prototype.canDrawText = function () {
        return !!this.fontString && (this.canDrawStroke() || this.canDrawFill());
    };
    Paint.prototype.isBright = function () {
        var color = parseColor(this.fillStyle) || parseColor(this.strokeStyle);
        return !color || isBright(color);
    };
    Paint.prototype.snapshot = function () {
        return removeEqualProps({
            font: this.font,
            textDirection: this.textDirection,
            textAlign: this.textAlign,
            textBaseline: this.textBaseline,
            fillStyle: this.fillStyle,
            strokeStyle: this.strokeStyle,
            lineWidth: this.lineDash,
            lineDash: this.lineDash,
            lineRounding: this.lineRounding,
        }, defaultPaintOptions);
    };
    Paint.prototype.clone = function () {
        var paint = new Paint();
        paint.fontString = this.fontString;
        paint.font = this.font;
        paint.textDirection = this.textDirection;
        paint.textAlign = this.textAlign;
        paint.textBaseline = this.textBaseline;
        paint.fillStyle = this.fillStyle;
        paint.strokeStyle = this.strokeStyle;
        paint.lineWidth = this.lineWidth;
        paint.lineDash = this.lineDash;
        paint.lineRounding = this.lineRounding;
        return paint;
    };
    Paint.DEFAULT = new Paint();
    return Paint;
}());
export { Paint };
deepFreeze(Paint.DEFAULT);
