import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';

import { Formik, FormikProps } from 'formik';
import { defaultTo } from 'lodash/fp';

import { mapActions } from '+app/utils';
import { BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { HeadlineLarge } from '+shared/basicComponents/Typography/Headlines';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { Form, FormInput } from '+shared/components';
import Banner from '+shared/components/Banner';
import { Card } from '+shared/components/Card/Card';
import { StoreState } from '+shared/store/store.interface';

import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { ImpactAnalysisActions } from '../../store/+impactAnalysis.actions';
import { ConsumptionForm } from '../../store/+impactAnalysis.interface';
import { getConsumptionFormForView } from '../../store/+impactAnalysis.selectors';
import { ImpactAnalysisConsumptionFormSchema } from '../../store/schemas';
import { defaultValues, formFields } from './LeadImpactAnalysisConsumption.helper';

import './LeadImpactAnalysisConsumption.component.scss';

const mapDispatchToProps = mapActions({
  setConsumption: ImpactAnalysisActions.setConsumptionForm,
});

const mapStateToProps = (state: StoreState) => ({
  consumption: getConsumptionFormForView(state),
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const LeadImpactAnalysisConsumptionComponent: React.FC<Props> = ({ actions, consumption }) => {
  const wizard = React.useContext(WizardContext);

  const onSubmit = (values: ConsumptionForm) => {
    actions.setConsumption(values);
    if (wizard.next) {
      wizard.next();
    }
  };

  const initialValues = defaultTo(defaultValues)(consumption);

  const {
    title,
    electricityCostDetails,
    currentHouseholdElectricity,
    currentElectricityBaseFee,
    electricityPriceIncrease,
    expectedElectricityPriceIncreasePerYear,
    electricityPriceIncreaseInformationText,
    electricityPriceIncreaseInformationHeader,
  } = T.lead.impactAnalysis._salessolution_.consumption;
  const { nineteenPercentValueAddedTaxes } = T.lead.impactAnalysis._salessolution_;

  return (
    <Formik<ConsumptionForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ImpactAnalysisConsumptionFormSchema}
    >
      {(form: FormikProps<ConsumptionForm>) => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <HeadlineLarge
              className="c-impact-analysis-consumption-headline-large"
              text={I18n.t(title)}
            />

            <LabelLarge
              className="c-impact-analysis-consumption-label-large"
              text={I18n.t(electricityCostDetails)}
            />

            <div className={'c-impact-analysis-consumption-input-group'}>
              <div className={'c-impact-analysis-consumption__column'}>
                <FormInput
                  label={<LabelMedium text={I18n.t(currentHouseholdElectricity)} />}
                  className={'c-impact-analysis-consumption-input'}
                  type={'number'}
                  unit={I18n.t(T.units.centsPerKwh)}
                  name={formFields.CURRENT_TARIFF_GROSS}
                  form={form}
                  helperLabel={<BodySmall text={I18n.t(nineteenPercentValueAddedTaxes)} />}
                  dataTestId="current-household-electricity-field"
                />
              </div>
              <div className={'c-impact-analysis-consumption__column'}>
                <FormInput
                  label={<LabelMedium text={I18n.t(currentElectricityBaseFee)} />}
                  className={'c-impact-analysis-consumption-input'}
                  type={'number'}
                  unit={I18n.t(T.units.currencyPerMonth, { value: '€' })}
                  name={formFields.CURRENT_ELECTRICITY_COSTS_GROSS}
                  form={form}
                  helperLabel={<BodySmall text={I18n.t(nineteenPercentValueAddedTaxes)} />}
                  dataTestId="current-electricity-base-fee-field"
                />
              </div>
            </div>

            <LabelLarge
              className="c-impact-analysis-consumption-label-large"
              text={I18n.t(electricityPriceIncrease)}
            />

            <div className={'c-impact-analysis-consumption__alert'}>
              <Banner
                headlineMessage={I18n.t(electricityPriceIncreaseInformationHeader)}
                textMessageAsHtml={I18n.t(electricityPriceIncreaseInformationText)}
              />
            </div>

            <div className="c-impact-analysis-consumption-input-group">
              <div className={'c-impact-analysis-consumption__column'}>
                <FormInput
                  label={<LabelMedium text={I18n.t(expectedElectricityPriceIncreasePerYear)} />}
                  // Disabled for Impact Analysis 2.0 MVP - see SON-35054
                  disabled={true}
                  type={'number'}
                  className={'c-impact-analysis-consumption-input'}
                  unit={I18n.t(T.units.percentPerYear)}
                  name={formFields.AVERAGE_ELECTRICITY_PRICE_INCREASE}
                  form={form}
                />
              </div>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export const LeadImpactAnalysisConsumption = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadImpactAnalysisConsumptionComponent);
