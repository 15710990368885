import { Rect } from '@kanva/core';
import { isEmpty, isNil, isNumber } from 'lodash';
import { DataScaleType } from '../chart.types';
import { findBestMatchInSortedArray, getContinuousNumericScale, isXYArray, isTupleOfNumbers, prepareAxisValues, } from '../utils';
import { DataContainerEventType } from './data-container.events';
var DataContainer = (function () {
    function DataContainer() {
        this.namedData = {};
        this.xBoundsExtension = [];
        this.yBoundsExtension = [];
        this.boundsMargin = new Rect(0);
        this.xScaleType = DataScaleType.LINEAR;
        this.yScaleType = DataScaleType.LINEAR;
        this.xAxisParameters = {};
        this.yAxisParameters = {};
        this.hasChanged = false;
        this.eventListeners = Object.values(DataContainerEventType)
            .reduce(function (listeners, id) {
            listeners[id] = [];
            return listeners;
        }, {});
        this.extensions = [];
        this.series = [];
        this.total = 0;
        this.seriesLength = 0;
        this.xScale = getContinuousNumericScale(this.xScaleType);
        this.yScale = getContinuousNumericScale(this.xScaleType);
        this.xAxis = [];
        this.yAxis = [];
    }
    DataContainer.prototype.getData = function () {
        return this.rawData;
    };
    DataContainer.prototype.setData = function (data) {
        var _this = this;
        if (this.rawData === data) {
            return this;
        }
        this.rawData = data;
        this.namedData = {};
        this.rawData.forEach(function (data, index) { return _this.namedData[data.name] = index; });
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.addEventListener = function (eventType, listener) {
        if (this.eventListeners[eventType].includes(listener)) {
            return;
        }
        this.eventListeners[eventType].push(listener);
    };
    DataContainer.prototype.removeEventListener = function (eventType, listener) {
        var index = this.eventListeners[eventType].indexOf(listener);
        if (index < 0) {
            return;
        }
        this.eventListeners[eventType].splice(index, 1);
    };
    DataContainer.prototype.addExtension = function () {
        var extensions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            extensions[_i] = arguments[_i];
        }
        for (var _a = 0, extensions_1 = extensions; _a < extensions_1.length; _a++) {
            var extension = extensions_1[_a];
            if (this.extensions.indexOf(extension) >= 0) {
                continue;
            }
            this.extensions.push(extension);
            extension.attach(this);
        }
        return this;
    };
    DataContainer.prototype.getExtension = function (name) {
        return this.extensions.find(function (extension) { return extension.name === name; });
    };
    DataContainer.prototype.removeExtension = function () {
        var extensions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            extensions[_i] = arguments[_i];
        }
        for (var _a = 0, extensions_2 = extensions; _a < extensions_2.length; _a++) {
            var extension = extensions_2[_a];
            var index = this.extensions.indexOf(extension);
            if (index < 0) {
                continue;
            }
            extension.detach(this);
            this.extensions.splice(index, 1);
        }
        return this;
    };
    DataContainer.prototype.getPointAccessor = function () {
        return this.pointAccessor;
    };
    DataContainer.prototype.setPointAccessor = function (pointAccessor) {
        this.pointAccessor = pointAccessor;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getXScaleType = function () {
        return this.xScaleType;
    };
    DataContainer.prototype.setXScaleType = function (scaleType) {
        this.xScaleType = scaleType;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getYScaleType = function () {
        return this.yScaleType;
    };
    DataContainer.prototype.setYScaleType = function (scaleType) {
        this.yScaleType = scaleType;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getXAxisParameters = function () {
        return this.xAxisParameters;
    };
    DataContainer.prototype.setXAxisParameters = function (xAxisParameters) {
        this.xAxisParameters = xAxisParameters;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getYAxisParameters = function () {
        return this.yAxisParameters;
    };
    DataContainer.prototype.setYAxisParameters = function (yAxisParameters) {
        this.yAxisParameters = yAxisParameters;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getXBoundsExtension = function () {
        return this.xBoundsExtension;
    };
    DataContainer.prototype.setXBoundsExtension = function (xBounds) {
        this.xBoundsExtension = xBounds;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getYBoundsExtension = function () {
        return this.yBoundsExtension;
    };
    DataContainer.prototype.setYBoundsExtension = function (yBounds) {
        this.yBoundsExtension = yBounds;
        this.hasChanged = true;
        this.postEvent(DataContainerEventType.DATA_CHANGE);
        return this;
    };
    DataContainer.prototype.getBoundsMargin = function () {
        return this.boundsMargin;
    };
    DataContainer.prototype.setBoundsMargin = function (boundsMargin) {
        this.boundsMargin = Rect.from(boundsMargin);
        return this;
    };
    DataContainer.prototype.getDataSeries = function (name) {
        if (!name) {
            return undefined;
        }
        this.processData();
        return this.series[this.namedData[name]];
    };
    DataContainer.prototype.getAllDataSeries = function () {
        this.processData();
        return this.series;
    };
    DataContainer.prototype.getScales = function (width, height) {
        this.processData();
        return this.postEvent(DataContainerEventType.GET_SCALES, {
            xScale: this.xScale.range([0, width]),
            yScale: this.yScale.range([height, 0]),
        });
    };
    DataContainer.prototype.getYValuesMatch = function (x, match) {
        this.processData();
        var matcher = function (element) { return element.x - x; };
        var primary = match && match.primary;
        var values = this.series.reduce(function (yValues, series) {
            if (isNil(series) || isEmpty(series.data)) {
                return yValues;
            }
            var filteredData = series.data.filter(Boolean);
            if (filteredData.length
                && (x < filteredData[0].x || x > filteredData[series.data.length - 1].x)) {
                yValues[series.name] = undefined;
            }
            else {
                var match_1 = findBestMatchInSortedArray(filteredData, matcher);
                yValues[series.name] = match_1;
            }
            return yValues;
        }, {});
        primary = this.findBestMatch(primary, values, matcher);
        if (!primary) {
            return undefined;
        }
        if (isNil(match)) {
            return {
                x: x,
                primary: primary,
                values: values,
            };
        }
        Object.assign(match.values, values);
        return match;
    };
    DataContainer.prototype.getTotal = function () {
        this.processData();
        return this.total;
    };
    DataContainer.prototype.getSeriesLength = function () {
        this.processData();
        return this.seriesLength;
    };
    DataContainer.prototype.getXAxisData = function () {
        this.processData();
        return this.xAxis;
    };
    DataContainer.prototype.getYAxisData = function () {
        this.processData();
        return this.yAxis;
    };
    DataContainer.prototype.postEvent = function (eventType, payload) {
        var listeners = this.eventListeners[eventType];
        var event = { type: eventType, payload: payload };
        for (var i = 0, l = listeners.length; i < l; i++) {
            event.payload = listeners[i](event);
        }
        return event.payload;
    };
    DataContainer.prototype.onChartPointerEvent = function (event) {
        var extensions = this.extensions;
        for (var i = 0, l = extensions.length; i < l; i++) {
            if (extensions[i].onChartPointerEvent(event)) {
                return true;
            }
        }
        return false;
    };
    DataContainer.prototype.findBestMatch = function (primary, values, matcher) {
        var matchValuesArray = [];
        Object.keys(values).forEach(function (key) { return values[key] && matchValuesArray.push(values[key]); });
        if (primary) {
            matchValuesArray.push(primary);
        }
        return findBestMatchInSortedArray(matchValuesArray.sort(), matcher);
    };
    DataContainer.prototype.processData = function () {
        if (!this.hasChanged) {
            return;
        }
        var rawData = this.rawData;
        this.hasChanged = false;
        if (!rawData || !rawData.length) {
            this.series = [];
            return;
        }
        var series = new Array(rawData.length);
        var minX = Math.min.apply(Math, this.xBoundsExtension);
        var maxX = Math.max.apply(Math, this.xBoundsExtension);
        var minY = Math.min.apply(Math, this.yBoundsExtension);
        var maxY = Math.max.apply(Math, this.yBoundsExtension);
        var total = 0;
        var seriesLength = 0;
        for (var i = 0, l = rawData.length; i < l; i++) {
            var rawSeries = rawData[i];
            var data = this.getNormalizedData(rawSeries);
            var sum = 0;
            if (data.length) {
                for (var i_1 = 0, l_1 = data.length; i_1 < l_1; i_1++) {
                    var _a = data[i_1], x = _a.x, y = _a.y;
                    if (x < minX) {
                        minX = x;
                    }
                    if (x > maxX) {
                        maxX = x;
                    }
                    if (isNumber(y)) {
                        if (y < minY) {
                            minY = y;
                        }
                        if (y > maxY) {
                            maxY = y;
                        }
                        sum += y;
                    }
                    if (isTupleOfNumbers(y)) {
                        var y1 = y[0], y2 = y[1];
                        if (y1 < minY) {
                            minY = y1;
                        }
                        if (y2 > maxY) {
                            maxY = y2;
                        }
                    }
                }
                if (seriesLength < data.length) {
                    seriesLength = data.length;
                }
            }
            series[i] = {
                name: rawSeries.name,
                sum: sum,
                data: data,
            };
            total += sum;
        }
        this.series = series;
        this.total = total;
        this.seriesLength = seriesLength;
        this.xScale = getContinuousNumericScale(this.xScaleType)
            .domain([minX - this.boundsMargin.l, maxX + this.boundsMargin.r]);
        this.yScale = getContinuousNumericScale(this.yScaleType)
            .domain([minY - this.boundsMargin.b, maxY + this.boundsMargin.t]);
        this.xAxis = prepareAxisValues(this.xScale, this.xAxisParameters, seriesLength);
        this.yAxis = prepareAxisValues(this.yScale, this.yAxisParameters, seriesLength);
    };
    DataContainer.prototype.getNormalizedData = function (rawSeries) {
        if (this.pointAccessor) {
            return rawSeries.data.map(this.pointAccessor);
        }
        if (isXYArray(rawSeries.data)) {
            return rawSeries.data.map(function (_a) {
                var x = _a.x, y = _a.y;
                return ({ x: x, y: y });
            });
        }
        console.warn('You are probably missing PointAccessor in your DataContainer configuration.');
        return [];
    };
    return DataContainer;
}());
export { DataContainer };
