import { ActionsUnion, createAction } from '../../../utils';
import { ChargerLiveState } from './types/chargerLiveState.interface';

export enum CHARGER_ACTIONS {
  GET_CHARGER_LIVE_STATE = '[Charger] GET_CHARGER_LIVE_STATE',
  SET_CHARGER_LIVE_STATE = '[Charger] SET_CHARGER_LIVE_STATE',
  STOP_CHARGER = '[Charger] STOP_CHARGER',
}

export const ChargerActions = {
  getChargerLiveState: createAction(
    CHARGER_ACTIONS.GET_CHARGER_LIVE_STATE,
    (opts: { queryKey: string; chargerId: string }) => ({
      queryKey: opts.queryKey,
      chargerId: opts.chargerId,
    })
  ),
  setChargerLiveState: createAction(
    CHARGER_ACTIONS.SET_CHARGER_LIVE_STATE,
    (chargerLiveState?: ChargerLiveState) => ({ chargerLiveState })
  ),
  stopCharger: createAction(CHARGER_ACTIONS.STOP_CHARGER, () => ({})),
};

export type ChargerActions = ActionsUnion<typeof ChargerActions>;
