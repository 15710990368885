import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import { FormBanner } from '+app/shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';

import './MissingBatteryBanner.component.scss';

interface Props {
  message: string;
  buttonAction: () => void;
}

export const MissingBatteryBanner: React.FC<Props> = ({ message, buttonAction }) => {
  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    buttonAction();
  };

  return (
    <FormBanner
      isVisible={true}
      type={'default'}
      message={<span dangerouslySetInnerHTML={{ __html: message }} />}
      icon={<Icon.CloseCircle className={'c-missing-battery-banner__close-icon'} />}
      iconPositionTop={true}
      button={
        <div className={'c-missing-battery-banner__button-wrapper'}>
          <Button
            label={I18n.t(T.missingBattery.banner.createCaseButton)}
            size={ButtonSize.SMALL}
            type={ButtonType.SECONDARY}
            onClick={onButtonClick}
          />
        </div>
      }
      boxShadow={false}
    />
  );
};
