export enum FeedbackButtonIndex {
  DEVELOP = 'd8d287424f6d',
  STAGING = 'f5a7ae395976',
  PRODUCTION = 'e3cff7bf9412',
}

export const getFeedbackButtonIndex = (): string => {
  switch (process.env.REACT_APP_CONFIG) {
    case 'development':
      return FeedbackButtonIndex.DEVELOP;
    case 'staging':
      return FeedbackButtonIndex.STAGING;
    case 'production':
      return FeedbackButtonIndex.PRODUCTION;
    default:
      return '';
  }
};
