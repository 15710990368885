import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DS, Modal, ModalLayout } from '@sonnen/shared-web';

import { Button, ButtonSize, ButtonType } from '+shared/components/Button';

import './BatteryIntelligentChargingConfirmModal.component.scss';

interface Props {
  isOpen: boolean;
  isActivating: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const BatteryIntelligentChargingConfirmModal: React.FC<Props> = ({
  isActivating,
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const handleOnConfimClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onConfirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      size={'medium'}
      className={'c-battery-intelligent-charging-confirm-modal'}
    >
      <ModalLayout
        title={
          isActivating
            ? I18n.t(
                T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                  .confirmationModal.headlineWhenActivate
              )
            : I18n.t(
                T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                  .confirmationModal.headlineWhenDeactivate
              )
        }
        footer={
          <div className={'c-battery-intelligent-charging-confirm-modal__footer'}>
            <Button
              label={I18n.t(
                T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                  .buttonCancel
              )}
              type={ButtonType.SECONDARY}
              size={ButtonSize.SMALL}
              onClick={onCancel}
            />
            <Button
              label={
                isActivating
                  ? I18n.t(
                      T.customerSingle.batteryDetails.batteryOperations
                        .intelligentChargingManagement.buttonActivate
                    )
                  : I18n.t(
                      T.customerSingle.batteryDetails.batteryOperations
                        .intelligentChargingManagement.buttonDeactivate
                    )
              }
              size={ButtonSize.SMALL}
              onClick={handleOnConfimClick}
            />
          </div>
        }
      >
        <div className={'c-battery-intelligent-charging-confirm-modal__body'}>
          <DS.Alert
            theme={DS.AlertTheme.WARNING}
            text={
              <span
                dangerouslySetInnerHTML={{
                  __html: isActivating
                    ? I18n.t(
                        T.customerSingle.batteryDetails.batteryOperations
                          .intelligentChargingManagement.warningWhenActivate
                      )
                    : I18n.t(
                        T.customerSingle.batteryDetails.batteryOperations
                          .intelligentChargingManagement.warningWhenDeactivate
                      ),
                }}
              />
            }
          />
          <div className={'c-battery-intelligent-charging-confirm-modal__info'}>
            {isActivating
              ? I18n.t(
                  T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                    .confirmationWhenActivate
                )
              : I18n.t(
                  T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                    .confirmationWhenDeactivate
                )}
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};
