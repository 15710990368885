import * as React from 'react';

import { FormikProps } from 'formik';

import { validateField } from '../Form.helpers';

import './FormFieldError.component.scss';

interface Props<T> {
  name: string;
  form: FormikProps<T>;
}

export const FormFieldError = <T,>({ name, form }: Props<T>) => {
  const validation = validateField({ name, form });

  return (
    <div className={'c-form-field-error'}>
      {validation.hasError && (
        <div className={'c-form-field-error__message'}>{validation.error}</div>
      )}
    </div>
  );
};
