import * as React from 'react';

import * as classNames from 'classnames';

import './DsoRegistrationFormContainer.component.scss';

interface Props {
  children: React.ReactNode;
  className?: ClassValue;
}

export const DsoRegistrationFormContainer: React.FC<Props> = ({ className, children }) => (
  <div className={classNames('c-dso-registration-container', className)}>{children}</div>
);
