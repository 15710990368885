import { createSelector } from 'reselect';

import { getCustomerPageState } from '+customer/store/+customer.selectors';
import { getStatus } from '+shared/store/query/query.utils';

import { GET_SITES_GEO_LOCATION_QUERY, GET_SITES_QUERY } from './siteList.state';

export const getSiteListPageState = createSelector(getCustomerPageState, (state) => state.siteList);

export const getSiteListMetaData = createSelector(
  getSiteListPageState,
  (state) => state.siteMetaData
);

export const getSiteListTotalPages = createSelector(
  getSiteListMetaData,
  (state) => state.totalPages
);

export const getSiteListCurrentPage = createSelector(
  getSiteListMetaData,
  (state) => state.currentPage
);

export const getSiteListTotalResultsCount = createSelector(
  getSiteListMetaData,
  (state) => state.totalCount
);

export const getSiteListQueryStatus = createSelector(getSiteListPageState, (state) =>
  getStatus(state[GET_SITES_QUERY])
);

export const getSiteCollection = createSelector(
  getSiteListPageState,
  (state) => state.siteCollection
);

export const getSiteGeoLocations = createSelector(
  getSiteListPageState,
  (state) => state.siteGeoLocations
);

export const getSiteGeoLocationQueryStatus = createSelector(getSiteListPageState, (state) =>
  getStatus(state[GET_SITES_GEO_LOCATION_QUERY])
);

export const getPartnerInstalledBatteriesCount = createSelector(
  getSiteListPageState,
  (state) => state.installedBatteriesCount
);
