import { PersistedState } from 'redux-persist';

import { CustomerData } from '+shared/store/customer';
import { Customer } from '+shared/store/customer/types/customer.interface';
import { Query } from '+shared/store/query/index';

export const GET_COLLECTION_QUERY = 'getCollectionQuery';

export interface CustomerListPageState extends PersistedState {
  collection: Customer[];
  [GET_COLLECTION_QUERY]: Query<CustomerData>;
  installedBatteriesCount: number;
  totalResourceCount: number;
}

export const initialState = {
  collection: [],
  [GET_COLLECTION_QUERY]: {},
  installedBatteriesCount: 0,
  totalResourceCount: 0,
};
