import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web/src/components/Status/Status.component';

import { isNil } from 'lodash/fp';

import { CustomerContractWidgetStatusTile } from '+app/+customer/+overview/components/CustomerContractWidgetStatusTile';
import { ContractType } from '+shared/store/contract/contract.helpers';
import {
  Contract,
  MeterInstallationAttributes,
} from '+shared/store/contract/types/contract.interface';
import { getContractWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { dateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';

const getFormattedDate = (date: string) => formatDate(date);

export const getMaxEnergyAmount = (amountOfFreeEnergy: number | null) =>
  isNil(amountOfFreeEnergy) ? 0 : amountOfFreeEnergy;

export const getDeliveryStatus = (deliveryDate: string | undefined) => {
  const formattedDeliveryDate = deliveryDate && getFormattedDate(deliveryDate);

  if (!deliveryDate) {
    return I18n.t(T.customerSingle.contract.sonnenFlat.tableValues.inactive);
  }
  if (dateUtil.now() > dateUtil.of(deliveryDate)) {
    return I18n.t(T.customerSingle.contract.sonnenFlat.tableValues.active, {
      start: formattedDeliveryDate,
    });
  }

  return I18n.t(T.customerSingle.contract.sonnenFlat.tableValues.notDelivered, {
    start: formattedDeliveryDate,
  });
};

export const getFormattedContractType = (contract: Contract): string => {
  const contractType: string = contract.contractType ?? contract.name ?? '';

  if (contractType.includes(ContractType.ENERGY_GLOBAL)) {
    if (contract.tariffVersion === 'direkt 2.0' || contract.tariffVersion === 'direkt') {
      return I18n.t(T.customerSingle.overview.contractHeader.sonnenFlatDirect);
    }
    return I18n.t(T.customerSingle.overview.contractHeader.sonnenFlatX);
  }

  if (contractType.includes(ContractType.SONNEN_FLAT_IT)) {
    return I18n.t(T.customerSingle.overview.contractHeader.sonnenFlatIt);
  }

  if (contractType.includes(ContractType.SONNEN_FLAT)) {
    return I18n.t(T.customerSingle.overview.contractHeader.sonnenFlat);
  }

  if (contractType.includes(ContractType.SONNEN_STROM)) {
    return I18n.t(T.customerSingle.overview.contractHeader.sonnenStrom);
  }

  if (contractType.includes(ContractType.ENERGY)) {
    return I18n.t(T.customerSingle.overview.contractHeader.sonnenEnergy);
  }

  return '—';
};

export const getDefaultContractDetailsItems = (contract: Contract) => [
  {
    label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.first),
    value: getFormattedContractType(contract),
  },
  {
    label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.third),
    value: contract.contractNumber,
  },
  {
    label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.forth),
    value: contract.orderedAt ? getFormattedDate(contract.orderedAt) : '-',
  },
];

export const getFlatProductDetailsItems = (
  contract: Contract,
  meterInstallation: MeterInstallationAttributes | undefined
) => {
  const warnings = getContractWarnings({ contract, meterInstallation });
  const items = [
    ...getDefaultContractDetailsItems(contract),
    {
      label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.fifth),
      value: contract.deliveryStartAt ? getDeliveryStatus(contract.deliveryStartAt) : '-',
    },
    {
      label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.second),
      // TODO: format with getKiloPowerObj
      value: contract.amountOfFreeEnergy ? `${contract.amountOfFreeEnergy} kWh` : null,
    },
    {
      label: I18n.t(T.customerSingle.overview.contract.meter),
      value: warnings.meterInstallationWarningFactory && (
        <CustomerContractWidgetStatusTile
          message={warnings.meterInstallationWarningFactory.message}
          messageExtended={warnings.meterInstallationWarningFactory.messageExtended}
          type={warnings.meterInstallationWarningFactory.type || StatusType.DEFAULT}
        />
      ),
    },
  ];

  if (meterInstallation) {
    items.push({
      label: I18n.t(T.customerSingle.overview.contract.fst),
      value: warnings.missingPvRemoteControl && (
        <CustomerContractWidgetStatusTile
          message={warnings.missingPvRemoteControl.message}
          messageExtended={warnings.missingPvRemoteControl.messageExtended}
          type={warnings.missingPvRemoteControl.type || StatusType.DEFAULT}
        />
      ),
    });
  }

  return items;
};
