import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { dateUtil } from '+utils/date.util';

import { DsoCommissioningInterface } from '../types';

export const DsoCommissioningSchemaPvAndBattery = () =>
  Yup.object().shape({
    dso_reference_number: Yup.string().trim().max(50, I18n.t(T.register.alert.max50Characters)),
    pv_commissioning_date: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), dateUtil.of('1899-12-31')) : true)
      )
      .test(
        'invalidDateNewerThan',
        I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
        (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
      )
      .required(I18n.t(T.register.alert.requiredField)),
    battery_commissioning_date: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), dateUtil.of('1899-12-31')) : true)
      )
      .test(
        'invalidDateNewerThan',
        I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
        (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
      )
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const DsoCommissioningInitialPvAndBattery: DsoCommissioningInterface = {
  dso_reference_number: '',
  pv_commissioning_date: '',
  battery_commissioning_date: '',
};

export const DsoCommissioningSchemaBatteryOnly = () =>
  Yup.object().shape({
    dso_reference_number: Yup.string().trim().max(50, I18n.t(T.register.alert.max50Characters)),
    battery_commissioning_date: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), dateUtil.of('1899-12-31')) : true)
      )
      .test(
        'invalidDateNewerThan',
        I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
        (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
      )
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const DsoCommissioningInitialBatteryOnly: DsoCommissioningInterface = {
  dso_reference_number: '',
  battery_commissioning_date: '',
};

export const getDsoCommissioningSchema = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoCommissioningSchemaPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return DsoCommissioningSchemaBatteryOnly;
    default:
      return DsoCommissioningSchemaPvAndBattery;
  }
};

export const getDsoCommissioningInitial = (registrationSubjectType?: RegistrationSubjectType) => {
  if (!registrationSubjectType) {
    return DsoCommissioningInitialPvAndBattery;
  }
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoCommissioningInitialPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return DsoCommissioningInitialBatteryOnly;
    default:
      return DsoCommissioningInitialPvAndBattery;
  }
};
export const saveableDsoCommissioningFields = [
  'dso_reference_number',
  'pv_commissioning_date',
  'battery_commissioning_date',
];
