import { from } from 'rxjs';

import { Config } from '+config/config';

import { jsonApiClient } from '../../network';
import { AssignedBatteryData } from './types/assignedBattery.interface';
import { ClaimBatteryAttributes } from './types/claimBattery.interface';

const findMissingBattery = (serialNumber: string) =>
  from(
    jsonApiClient
      .get(`${Config.API_URL}/partners/claim-battery-systems/${serialNumber}`)
      .expectOne()
      .send()
  );

const claimBatteryOwnership = (claimBattery: ClaimBatteryAttributes) =>
  from(
    jsonApiClient
      .post<AssignedBatteryData>(`${Config.API_URL}/partners/claim-battery-systems`)
      .ofType('battery')
      .withAttributes(claimBattery)
      .expectOne()
      .resolveIncluded(true)
      .send()
  );

export const PartnerRepository = {
  findMissingBattery,
  claimBatteryOwnership,
};
