import { Data, MergedData, Relationship, ResolvedRelationship } from '@coolio/json-api';

import { LeadConfigurationResponseData, LeadImpactAnalysisResponseData, LeadProductType } from '.';
import { LeadDocument } from './leadDocument.interface';

/**
 * @deprecated: Use `OfferProductStatus` instead
 */
export enum OfferStatus {
  DELETED = 'deleted',
  BLOCKED = 'blocked',
  DRAFT = 'draft',
  SENT = 'sent',
  BINDINGLY_ACCEPTED = 'bindingly-accepted',
}

export enum OfferProductStatus {
  UNKNOWN = 'unknown',
  DRAFT = 'draft',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  SENT_EXPIRED = 'sent-expired',
  DRAFT_EXPIRED = 'draft-expired',
}

export interface OfferAcceptance {
  type: string;
  date: string;
  identifier: string | null;
}

export interface LeadOfferProduct {
  productId: string;
  productType: LeadProductType;
  status: OfferProductStatus;
  draftExpiresAt?: string;
  sentExpiresAt?: string;
}

export enum LeadOfferTaxRegulation {
  SMALL_ENTERPRISE = 'small_enterprise',
  STANDARD = 'standard',
}

export interface LeadOfferAttributes {
  status: OfferStatus;
  createdAt: string;
  updatedAt: string;
  bankAccountHolder?: { firstName: string; lastName: string };
  bankBic?: string;
  bankIban?: string;
  bankPayment?: string;
  flatOffers: LeadDocument[];
  flatConfigurations: LeadDocument[];
  accepted?: OfferAcceptance[];
  products: LeadOfferProduct[];
  taxRegulation?: LeadOfferTaxRegulation;
}

export type LeadOfferMetaData = {
  hasActiveDynamicTariffContract: boolean;
};

export type LeadOfferRelationships = {
  configuration: ResolvedRelationship<LeadConfigurationResponseData>;
  impactAnalysis: ResolvedRelationship<LeadImpactAnalysisResponseData>;
  battery: Relationship;
};

export interface LeadOfferData extends Data<LeadOfferAttributes, LeadOfferRelationships> {}
export interface LeadOffer extends MergedData<LeadOfferData> {}
