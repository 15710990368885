export var processMultipartBody = function (body, boundary) {
    body.split(boundary);
    throw new Error('Multipart response parsing is not implemented yet.');
};
export var getBoundaryFromContentTypeHeader = function (header) {
    var _a;
    var boundary = (_a = header.find(function (element) { return element.startsWith('boundary='); })) === null || _a === void 0 ? void 0 : _a.replace('boundary=', '');
    if (!boundary) {
        throw new Error('Could not find multipart boundary in Content-Type header.');
    }
    return boundary;
};
