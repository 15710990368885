import {
  GET_SETUP_TOOL_SUBMISSION_DATA_QUERY,
  StatusName,
  SubmissionStepCamelCase,
} from '+setupTool/store/+setupTool.dictionary';
import { Query } from '+shared/store/query';
import { AdditionalFeatures } from '+shared/store/setupTool/types';

export interface SetupToolStep {
  index: string;
  uniqueIndexForID?: string;
  name: string;
  disabled: boolean;
  route: string;
  appendix?: () => React.ReactNode;
  date?: string;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  buttonVisible?: boolean;
  status?: StatusName;
  dataTestId: string;
}

export enum RegistrationSubjectType {
  PV_AND_BATTERY = 'pv-and-battery',
  BATTERY = 'battery',
}

export interface SetupToolConfigurationState {
  submissionId?: string;
  registrationSubject?: RegistrationSubjectType;
  additionalFeatures: AdditionalFeatures[];
  errors: string[];
  [GET_SETUP_TOOL_SUBMISSION_DATA_QUERY]: Query;
}

export const configurationInitialState: SetupToolConfigurationState = {
  submissionId: undefined,
  registrationSubject: undefined,
  additionalFeatures: [],
  errors: [],
  [GET_SETUP_TOOL_SUBMISSION_DATA_QUERY]: {},
};

export interface SetupToolStepsUpdatedAt {
  [SubmissionStepCamelCase.SETUP_DATA]?: string;
  [SubmissionStepCamelCase.DSO_REGISTRATION]?: string;
  [SubmissionStepCamelCase.DSO_COMMISSIONING_DATA]?: string;
  [SubmissionStepCamelCase.DSO_COMMISSIONING_DOCUMENTS]?: string;
  [SubmissionStepCamelCase.PV_REGISTER]?: string;
  [SubmissionStepCamelCase.VPP_DOCUMENTATION]?: string;
  [SubmissionStepCamelCase.VPP_PERFORMANCE]?: string;
}
