import { floor } from 'lodash';

import { roundToNextMultipleOfFive } from '+utils/number.util';

export const quotaAlmostExceededPercentage = 95;

export const getUsedEnergyPercentage = (total: number, maxEnergyAmount: number) => {
  const usedEnergyAmount = total / 1000;
  const percent = maxEnergyAmount ? (usedEnergyAmount / maxEnergyAmount) * 100 : 0;
  const roundedPercent = floor(roundToNextMultipleOfFive(percent), 2);
  return Math.min(roundedPercent, 100);
};
