import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

export const initialValues = { password: '', passwordConfirmation: '', previousPassword: '' };
const requiredPasswordLength = 5;

export const resetPasswordSchema = () =>
  Yup.object({
    previousPassword: Yup.string().required(I18n.t(T.resetPassword.alert.requiredField)),
    password: Yup.string()
      .min(
        requiredPasswordLength,
        I18n.t(T.resetPassword.alert.shortPassword, { length: requiredPasswordLength })
      )
      .required(I18n.t(T.resetPassword.alert.requiredField))
      .matches(/^\S*$/, I18n.t(T.resetPassword.alert.noSpaces)),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], I18n.t(T.resetPassword.alert.differentPasswords))
      .required(I18n.t(T.resetPassword.alert.requiredField))
      .matches(/^\S*$/, I18n.t(T.resetPassword.alert.noSpaces)),
  });
