import { __assign, __extends, __spreadArray } from "tslib";
import { Line, Paint, RequiredViewChanges } from '@kanva/core';
import { flatten, isArray, isNumber } from 'lodash';
import { LabelsHelper, segmentizePoints } from '../utils';
import { ChartView } from './chart.view';
export var DataDisplayType;
(function (DataDisplayType) {
    DataDisplayType[DataDisplayType["POINTS"] = 0] = "POINTS";
    DataDisplayType[DataDisplayType["LINE"] = 1] = "LINE";
    DataDisplayType[DataDisplayType["LINE_STAIRS"] = 2] = "LINE_STAIRS";
    DataDisplayType[DataDisplayType["AREA"] = 3] = "AREA";
    DataDisplayType[DataDisplayType["BAND"] = 4] = "BAND";
})(DataDisplayType || (DataDisplayType = {}));
var defaultStyle = {
    type: DataDisplayType.LINE,
    paint: new Paint().setLineWidth(1.5),
};
var AreaChartView = (function (_super) {
    __extends(AreaChartView, _super);
    function AreaChartView(context) {
        var _this = _super.call(this, context, 'AreaChartView', defaultStyle) || this;
        _this.labelsHelper = new LabelsHelper();
        _this.center = { x: 0, y: 0 };
        _this.data = [];
        return _this;
    }
    AreaChartView.prototype.getLabelOptions = function () {
        return this.labelsHelper.getOptions();
    };
    AreaChartView.prototype.setLabelOptions = function (labels) {
        this.labelsHelper.setOptions(labels);
    };
    AreaChartView.prototype.getCenterPoint = function () {
        return this.center;
    };
    AreaChartView.prototype.setCenterPoint = function (center) {
        if (!center) {
            return;
        }
        this.center = center;
        this.require(RequiredViewChanges.DRAW);
    };
    AreaChartView.prototype.onLayout = function () {
        var _a = this, dataContainer = _a.dataContainer, dataSeries = _a.dataSeries, style = _a.style;
        if (!dataContainer || !dataSeries) {
            this.data = [];
            return;
        }
        var series = dataContainer.getDataSeries(dataSeries[0]);
        if (!series || !series.data.length) {
            this.data = [];
            return;
        }
        var dataSegments = segmentizePoints(series.data, null);
        this.data = dataSegments.map(function (segment) {
            if (!segment.length) {
                return new Float64Array(0);
            }
            switch (style.type) {
                case DataDisplayType.LINE_STAIRS: {
                    var array = new Float64Array(segment.length * 4);
                    var point = segment[0];
                    var offset = 2;
                    var start = point.x;
                    var scale = (segment.length - 1) / segment.length;
                    array[0] = start + (point.x - start) * scale;
                    array[1] = point.y;
                    for (var i = 1; i < segment.length; i++) {
                        point = segment[i];
                        array[offset] = start + (point.x - start) * scale;
                        array[offset + 1] = array[offset - 1];
                        array[offset + 2] = array[offset];
                        array[offset + 3] = point.y;
                        offset += 4;
                    }
                    array[offset] = point.x;
                    array[offset + 1] = array[offset - 1];
                    return array;
                }
                default: {
                    return Float64Array.from(flatten(segment.map(function (_a) {
                        var x = _a.x, y = _a.y;
                        return isArray(y) ? __spreadArray([x], y, true) : [x, y];
                    })));
                }
            }
        });
    };
    AreaChartView.prototype.onDraw = function (canvas) {
        var _a = this, dataContainer = _a.dataContainer, style = _a.style, center = _a.center;
        var type = style.type, paint = style.paint;
        var ctx = canvas.context;
        var dataSegments = this.data;
        var halfLineWidth = paint.lineWidth / 2;
        if (!dataContainer || !dataSegments.length) {
            return;
        }
        var _b = this.getScales(), xScale = _b.xScale, yScale = _b.yScale;
        ctx.translate(halfLineWidth, halfLineWidth);
        ctx.beginPath();
        for (var s = 0, sl = dataSegments.length; s < sl; s++) {
            var data = dataSegments[s];
            switch (type) {
                case DataDisplayType.AREA:
                    ctx.moveTo(xScale(data[0]), yScale(center.y));
                    for (var i = 0, l = data.length; i < l; i += 2) {
                        ctx.lineTo(xScale(data[i]), yScale(data[i + 1]));
                    }
                    ctx.lineTo(xScale(data[data.length - 2]), yScale(center.y));
                    ctx.lineTo(xScale(data[0]), yScale(center.y));
                    break;
                case DataDisplayType.BAND:
                    ctx.moveTo(xScale(data[0]), yScale(data[1]));
                    for (var i = 0, l = data.length; i < l; i += 3) {
                        ctx.lineTo(xScale(data[i]), yScale(data[i + 1]));
                    }
                    ctx.lineTo(xScale(data[data.length - 3]), yScale(data[data.length - 1]));
                    for (var i = data.length - 1; i > 1; i -= 3) {
                        ctx.lineTo(xScale(data[i - 2]), yScale(data[i]));
                    }
                    ctx.closePath();
                    break;
                case DataDisplayType.POINTS:
                    var size = paint.lineWidth || 1;
                    var useStroke = paint.canDrawStroke();
                    var useFill = paint.canDrawFill();
                    var radius = size / 2;
                    canvas.setPaint(paint);
                    for (var i = 0, l = data.length; i < l; i += 2) {
                        var x = xScale(data[i]) - radius;
                        var y = yScale(data[i + 1]) - radius;
                        if (useFill) {
                            ctx.fillRect(x, y, size, size);
                        }
                        if (useStroke) {
                            ctx.strokeRect(x, y, size, size);
                        }
                    }
                    break;
                default:
                case DataDisplayType.LINE_STAIRS:
                case DataDisplayType.LINE:
                    ctx.moveTo(xScale(data[0]), yScale(data[1]));
                    for (var i = 2, l = data.length; i < l; i += 2) {
                        ctx.lineTo(xScale(data[i]), yScale(data[i + 1]));
                    }
                    break;
            }
        }
        canvas.drawPath(paint);
        if (this.getLabelOptions()) {
            var pointLine = new Line();
            var index = 0;
            for (var s = 0, sl = dataSegments.length; s < sl; s++) {
                var data = dataSegments[s];
                for (var i = 0, l = data.length; i < l; i += 2) {
                    pointLine.startX = pointLine.endX = xScale(data[i]);
                    pointLine.startY = pointLine.endY = yScale(data[i + 1]);
                    pointLine.endY += 1;
                    this.labelsHelper.draw(canvas, data[i + 1], index++, pointLine, paint);
                }
            }
        }
    };
    AreaChartView.prototype.getCanvasPositionForPoint = function (point) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        var x = xScale(point.x);
        var y = isNumber(point.y) ? yScale(point.y) : 0;
        return {
            x: x,
            y: y,
            absoluteX: this.offsetRect.l + x,
            absoluteY: this.offsetRect.t + y,
        };
    };
    AreaChartView.prototype.getPointForCanvasPosition = function (position) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        return {
            x: xScale.invert(position.x),
            y: yScale.invert(position.y),
        };
    };
    AreaChartView.prototype.getScales = function () {
        var halfLineWidth = (this.style.paint.lineWidth || 0) / 2;
        return this.dataContainer.getScales(this.innerWidth - halfLineWidth, this.innerHeight - halfLineWidth);
    };
    AreaChartView.prototype.snapshot = function () {
        var snapshot = _super.prototype.snapshot.call(this);
        return __assign(__assign({}, snapshot), { style: __assign(__assign({}, snapshot.style), { paint: this.style.paint.snapshot() }) });
    };
    return AreaChartView;
}(ChartView));
export { AreaChartView };
