import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { JsonResponse, RawResponse } from '@coolio/json-api';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as uuid from 'uuid';

import { LeadForm } from '+app/+lead/store/types';
import { FormInputSelect } from '+shared/components';
import useDebounce from '+shared/hooks/useDebounce';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { LeadAddress, LeadDetailedAddress } from '+shared/store/lead/types';
import {
  LeadAddressDetailsAttributes,
  LeadAddressDetailsData,
  LeadAutosuggestionData,
} from '+shared/store/lead/types/leadAutosuggestion.interface';

import { clearAddressFields, renderNoResultsComponent } from './LeadAutosuggestedAddress.helper';

import './LeadAutosuggestedAddress.component.scss';

interface ComponentProps {
  form: FormikProps<LeadForm>;
  setEnableInput: (arg: boolean) => void;
  setIsLoading: (arg: boolean) => void;
  addressType: 'deliveryAddress' | 'invoiceAddress';
}

export const LeadAutosuggestedAddress: React.FC<ComponentProps> = ({
  form,
  setEnableInput,
  setIsLoading,
  addressType,
}) => {
  const [sessionId, setSessionId] = React.useState<string>(uuid.v4());
  const [isFinderFocused, setIsFinderFocused] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedAddress, setSelectedAddress] = React.useState<string>('');
  const [selectedAddressId, setSelectedAddressId] = React.useState<string>('');
  const [userVisibleAddress, setUserVisibleAddress] = React.useState<string>('');
  const [addressAutosuggestions, setAddressAutosuggestions] = React.useState<
    LeadAutosuggestionData[] | null
  >(null);
  const [addressDetails, setAddressDetails] = React.useState<LeadAddressDetailsAttributes | null>(
    null
  );
  const [isPending, setIsPending] = React.useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 400);

  const switchToManualAddress = () => {
    setEnableInput(true);
    clearAddressFields(form, addressType);

    setUserVisibleAddress('');
    setSearchValue('');
  };

  const onFocus = () => {
    setIsFinderFocused(true);
  };

  const onBlur = () => {
    setIsFinderFocused(false);
  };

  const getAddress = (): LeadDetailedAddress | LeadAddress => {
    return form.values[addressType] || form.values.deliveryAddress;
  };

  // get autocomplete address suggestions
  React.useEffect(() => {
    const fetchAddressAutosuggestions = async () => {
      setIsPending(true);
      try {
        const request = LeadRepository.getAddressAutosuggestions(
          debouncedSearchValue,
          getAddress().country,
          sessionId
        );
        const res = await request.toPromise();
        const data = await res.response.parsedBody();
        setAddressAutosuggestions(data.data);
        setIsPending(false);
      } catch (error) {
        console.log(error);
        setIsPending(false);
      }
    };

    if (form.dirty) {
      fetchAddressAutosuggestions();
    }
  }, [debouncedSearchValue]);

  // set error or address on focus out
  React.useEffect(() => {
    if (addressAutosuggestions && !isEmpty(addressAutosuggestions) && !isFinderFocused) {
      setEnableInput(true);
      setSelectedAddress(addressAutosuggestions[0].attributes.description);
      setSelectedAddressId(addressAutosuggestions[0].id);
      setUserVisibleAddress(addressAutosuggestions[0].attributes.description);
    }

    if (
      isEmpty(addressAutosuggestions) &&
      !isFinderFocused &&
      (addressType === 'deliveryAddress'
        ? form.touched.autosuggestedDeliveryAddress
        : form.touched.autosuggestedInvoiceAddress)
    ) {
      setEnableInput(true);
      clearAddressFields(form, addressType);
    }
  }, [isFinderFocused]);

  // get address details for selected address
  React.useEffect(() => {
    if (!isEmpty(selectedAddressId) && !isFinderFocused) {
      setIsLoading(true);
      const request = LeadRepository.getAddressDetails(
        selectedAddressId,
        getAddress().country,
        sessionId,
        selectedAddress
      );
      request
        .toPromise()
        .then((res: JsonResponse<RawResponse<LeadAddressDetailsData, {}>>) => {
          res.response.parsedBody().then((data: RawResponse<LeadAddressDetailsData, {}>) => {
            setAddressDetails(data.data.attributes);
            setIsLoading(false);
          });
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [selectedAddressId]);

  // set address details to form fields
  React.useEffect(() => {
    if (addressDetails && addressDetails.street && addressDetails.streetNumber) {
      // first case covers scenarios for both street and house number existing
      // atm street returned from API is a glued street + number,
      // so we need a substring to have street only
      // TODO: it will be changed on the backend once we decide on this version of autosuggestion
      form.setFieldValue(
        `${addressType}.street`,
        addressDetails.street.substring(
          0,
          addressDetails.street.length - addressDetails.streetNumber.length
        )
      );
      form.setFieldValue(`${addressType}.houseNumber`, addressDetails.streetNumber);
    } else if (addressDetails && addressDetails.street) {
      // then house number doesn't exist,
      // but we need to clear houseNumber if it was found previously
      form.setFieldValue(`${addressType}.street`, addressDetails.street);
      form.setFieldValue(`${addressType}.houseNumber`, '');
    } else {
      // if there's ONLY city name typed, all others need to be cleared
      form.setFieldValue(`${addressType}.street`, '');
      form.setFieldValue(`${addressType}.houseNumber`, '');
    }

    if (addressDetails && addressDetails.zipCode) {
      form.setFieldValue(`${addressType}.zipCode`, addressDetails.zipCode);
    } else {
      form.setFieldValue(`${addressType}.zipCode`, '');
    }

    if (addressDetails) {
      // set timeout is required because Formik (as of version 2.2.0)
      // has troubles when setting field value
      // and it still thinks the field is empty without it
      setTimeout(() => {
        // city will always be returned, so no need to check it
        form.setFieldValue(`${addressType}.city`, addressDetails.city);
      }, 0);
      form.setFieldValue(`${addressType}.autosuggestedAddress`, addressDetails.description);

      // session id has to be changed after making req to google places
      setSessionId(uuid.v4());
    }
  }, [addressDetails]);

  return (
    <FormInputSelect
      className={'c-lead-autosuggested-address__select'}
      form={form}
      name={`${addressType}.autosuggestedAddress`}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.findAdress)}
      noResultsComponent={renderNoResultsComponent(
        switchToManualAddress,
        debouncedSearchValue,
        isPending,
        addressAutosuggestions
      )}
      collection={addressAutosuggestions || []}
      mapper={(key) => key.attributes.description}
      onInputChange={(query) => {
        setSearchValue(query);
        form.setFieldValue(`${addressType}.autosuggestedAddress`, query);
        setUserVisibleAddress(query);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      controlledValue={userVisibleAddress}
      isSearchIcon={true}
      onSelect={(selected) => {
        // prepares data for address details when selected from list
        setSelectedAddress(selected.attributes.description);
        setSelectedAddressId(selected.id);
        setUserVisibleAddress(selected.attributes.description);
        setEnableInput(true);
      }}
      suppressValuesInHotjar={true}
      dataTestId="find-address-field"
    />
  );
};

export default LeadAutosuggestedAddress;
