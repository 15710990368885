import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';

import { DetailList } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Case } from '+shared/store/case';

import { getClosedCaseCount } from './CustomerCasesWidget.helper';

import './CustomerCasesWidget.scss';

const { headline, openedCases, resolvedCases } = T.customerSingle.overview.cases;

interface Props {
  cases: Case[];
  className?: ClassValue;
  link: () => void;
}

export const CustomerCasesWidget = ({ className, cases, link }: Props) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icon.OpenFolder className="report-icon" />}
    heading={I18n.t(headline)}
    link={link}
  >
    <DetailList
      list={[
        {
          label: I18n.t(openedCases),
          value: cases.length - getClosedCaseCount(cases),
        },
        {
          label: I18n.t(resolvedCases),
          value: getClosedCaseCount(cases),
        },
      ]}
    />
  </Widget>
);
