import * as React from 'react';

import { Loader } from '@sonnen/shared-web';

import { isEmpty } from 'lodash/fp';

import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import { QueryState } from '+shared/store/query';

import { CaseReportSearchNoResults } from '../CaseReportSearchNoResults';
import { ErrorMessage } from '../ErrorMessage';

import './CaseReportSearchResults.component.scss';

interface Props {
  queryStatus: QueryState;
  results: CaseSite[];
  renderResult: (item: CaseSite) => React.ReactNode;
}

export const CaseReportSearchResults: React.FC<Props> = ({
  queryStatus,
  results,
  renderResult,
}) => {
  const noResults = queryStatus.success && isEmpty(results);

  const renderComponent = () => {
    if (queryStatus.pending) {
      return (
        <div className={'c-case-report-search-results__wrapper'}>
          <Loader />
        </div>
      );
    }
    if (queryStatus.error) {
      return (
        <div className={'c-case-report-search-results__wrapper'}>
          <ErrorMessage />
        </div>
      );
    }
    if (noResults) {
      return <CaseReportSearchNoResults />;
    }
    return results.map(renderResult);
  };

  return <div className={'c-case-report-search-results'}>{renderComponent()}</div>;
};
