export enum GroupName {
  INLINE_STYLE_BUTTONS = 'INLINE_STYLE_BUTTONS',
  BLOCK_TYPE_BUTTONS = 'BLOCK_TYPE_BUTTONS',
  LINK_BUTTONS = 'LINK_BUTTONS',
  BLOCK_TYPE_DROPDOWN = 'BLOCK_TYPE_DROPDOWN',
  HISTORY_BUTTONS = 'HISTORY_BUTTONS',
  IMAGE_BUTTON = 'IMAGE_BUTTON',
}

interface StyleConfig {
  label: string;
  style: string;
  className?: string;
}

type StyleConfigList = StyleConfig[];

export interface ToolbarConfig {
  display: GroupName[];
  extraProps?: object;
  INLINE_STYLE_BUTTONS: StyleConfigList;
  BLOCK_TYPE_DROPDOWN: StyleConfigList;
  BLOCK_TYPE_BUTTONS: StyleConfigList;
}
