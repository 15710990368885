import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import './AssetCountBadge.component.scss';

interface Props {
  count: number;
  labelTop: string;
  labelBottom: string;
}

export const AssetCountBadge: React.SFC<Props> = ({ count, labelTop, labelBottom }) => (
  <div className={'c-asset-count-badge'}>
    <Icon.SonnenBattery className={'c-asset-count-badge__icon'} />
    <span className={'c-asset-count-badge__number'}>{count}</span>
    <div className={'c-asset-count-badge__text-container'}>
      <span className={'c-asset-count-badge__text'}>{labelTop}</span>
      <span className={'c-asset-count-badge__text'}>{labelBottom}</span>
    </div>
  </div>
);
