import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as uuid from 'uuid/v4';

import { Button, ButtonSize, MainType } from '+shared/components/Button';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { News, Portal } from '+shared/store/firebase/types/news.interface';

import { NewsFormPartner } from '../../components/NewsFormPartner';

import './SendNewsPartner.component.scss';

type Props = FirebaseProps;

export class SendNewsPartnerComponent extends React.Component<Props> {
  state = {
    success: false,
    loading: false,
    error: '',
  };

  onSubmit = (values: News) => {
    this.setState({ loading: true });

    const { firebase } = this.props;
    const {
      shortMessage,
      introduction,
      message,
      markets,
      level,
      tag,
      expiryDate,
      roles,
      isDisplayedAsNotification,
      notificationExpiryDate,
    } = values;
    const date = new Date();
    const id = uuid();
    const levelValue = level ? level.value : 0;
    const tagValue = tag ? tag.value : '';

    const news = {
      id,
      shortMessage,
      introduction,
      message,
      markets,
      date,
      levelValue,
      tagValue,
      expiryDate,
      roles,
      portals: [Portal.SP],
      level,
      tag,
      isDisplayedAsNotification,
      notificationExpiryDate,
    } as unknown as News;
    // TODO: create seperate interfaces for read and write news or unify date format for both

    firebase
      .addNews(Portal.SP, news)
      .then(() =>
        this.setState({
          loading: false,
          success: true,
          error: '',
        })
      )
      .catch((err: Error) =>
        this.setState({
          loading: false,
          error: err.message,
        })
      );
  };

  showEmptyForm = () => this.setState({ success: false });

  render() {
    return (
      <div className={'c-send-news'}>
        {this.state.success ? (
          <>
            <div className={'c-send-news-partner__success-text'}>
              {I18n.t(T.news.channel.sendForm.newsHasBeenSent)}
            </div>
            <Button
              mainType={MainType.SUBMIT}
              label={I18n.t(T.news.channel.sendForm.sendAnotherNews)}
              size={ButtonSize.MEDIUM}
              onClick={this.showEmptyForm}
            />
          </>
        ) : (
          <NewsFormPartner
            onSubmit={this.onSubmit}
            loading={this.state.loading}
            error={this.state.error}
          />
        )}
      </div>
    );
  }
}

export const SendNewsPartner = withFirebase(SendNewsPartnerComponent);
