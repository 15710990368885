import * as React from 'react';

import './BatteryLiveCardSoc.component.scss';

interface Props {
  soc: string;
}

export const BatteryLiveCardSoc: React.FC<Props> = ({ soc }) => {
  return <span className={'c-battery-live-card-soc'}>{soc}</span>;
};
