import React from 'react';

import { MeterInstallationAttributes } from '+shared/store/contract';

import { Translations } from '../../SitesFiltersArea/SitesFiltersArea';

/**
 * This function applies styling to the substring that matches the search input.
 * Below logic is necessary to keep the original spaces and the letter casing,
 * if matching string is at the beginning or in the middle of the original string
 * (this is more for names that have capital letters and spaces).
 *
 * @param {string} searchValue - value which comes from the search input field
 * @param {string} resultValue - value which is returned by the BE as search result
 * @param {string} className - needed for styling the highlighted substring
 *
 * @returns {(string | JSX.Element)} Returns the original, unaltered value
 * or a JSX.Element with styles applied to it
 */
// TODO: (SON-35085) write tests for it
export const highlightMatchingSubstring = (
  searchValue: string,
  resultValue: string,
  className: string
): JSX.Element | string => {
  const parsedResult = resultValue.toLocaleLowerCase().match(searchValue.toLowerCase());

  /* This check is only necessary because match() can return RegExpMatchArray | null,
   * but we know that there will always be a match because if searchValue is null,
   * this highlightMatchingSubstring() function will not be called at all.
   * Moreover, we need to check that it is a number, because the index can also be 0,
   * which is interpreted as false.
   */
  if (typeof parsedResult?.index === 'number') {
    const beginning = resultValue.substring(0, parsedResult.index);
    const middle = resultValue.substring(parsedResult.index, beginning.length + searchValue.length);
    const end = resultValue.substring((beginning + middle).length);

    return (
      <>
        {beginning}
        <span className={className}>{middle}</span>
        {end}
      </>
    );
  }

  return resultValue;
};

export type StatusWarningTranslations = {
  title: string;
  content: string;
};

export const getContractWarnings = (
  meterInstallation: MeterInstallationAttributes,
  translations: Translations,
  meterInstallationWarningsVisible: boolean
): StatusWarningTranslations[] | null => {
  const { meterPreparationStatus, fstCheckSuccessful } = meterInstallation;
  const warnings: StatusWarningTranslations[] = [];

  if (meterInstallationWarningsVisible) {
    if (meterPreparationStatus === 'missing') warnings.push(translations['missing']);
    if (meterPreparationStatus === 'in_clearing') warnings.push(translations['in_clearing']);
    if (fstCheckSuccessful === 'no') warnings.push(translations['no']);
  }

  return warnings.length ? warnings : null;
};
