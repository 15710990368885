import { PersistedState } from 'redux-persist';

import { BatteryWithProductData } from '+shared/store/battery';
import { BatteryOperationsData } from '+shared/store/battery/types/batteryOperations.interface';
import {
  PowerMeterLiveState,
  PowerMeterLiveStateData,
} from '+shared/store/battery/types/batteryPowerMeterLiveState.interface';
import { Query } from '+shared/store/query';

export const GET_BATTERY_POWER_METER_LIVE_STATES_QUERY = 'getBatteryPowerMeterLiveStatesQuery';
export const POST_BATTERY_PV_GRID_FEED_IN_LIMIT_QUERY = 'postBatteryPvFeedInLimitQuery';
export const POST_BATTERY_PV_GRID_FEED_IN_LIMIT_POLLING_QUERY =
  'postBatteryPvFeedInLimitPollingQuery';

export interface PvSystemsPageState extends PersistedState {
  powerMeterLiveStates: PowerMeterLiveState[];
  isBatteryPvGridFeedInLimitInProgress: boolean;
  [GET_BATTERY_POWER_METER_LIVE_STATES_QUERY]: Query<PowerMeterLiveStateData>;
  [POST_BATTERY_PV_GRID_FEED_IN_LIMIT_QUERY]: Query<BatteryOperationsData>;
  [POST_BATTERY_PV_GRID_FEED_IN_LIMIT_POLLING_QUERY]: Query<BatteryWithProductData>;
}

export const initialState: PvSystemsPageState = {
  powerMeterLiveStates: [],
  isBatteryPvGridFeedInLimitInProgress: false,
  [GET_BATTERY_POWER_METER_LIVE_STATES_QUERY]: {},
  [POST_BATTERY_PV_GRID_FEED_IN_LIMIT_QUERY]: {},
  [POST_BATTERY_PV_GRID_FEED_IN_LIMIT_POLLING_QUERY]: {},
};
