import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Button, ButtonType } from '+shared/components/Button';

import './LeadOverviewRecommendLead.component.scss';

export const LeadOverviewRecommendLead: React.FC = () => {
  return (
    <>
      <p className={'c-overview-recommend-lead__paragraph'}>
        {I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.text)}
      </p>
      <div className={'c-overview-recommend-lead__button-wrapper'}>
        <a
          target={'_blank'}
          href={I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.link)}
          rel="noreferrer"
        >
          <Button
            label={I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.button)}
            type={ButtonType.PRIMARY}
          />
        </a>
      </div>
    </>
  );
};
