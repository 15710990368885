export var PointerAction;
(function (PointerAction) {
    PointerAction["UP"] = "UP";
    PointerAction["OVER"] = "OVER";
    PointerAction["DOWN"] = "DOWN";
    PointerAction["MOVE"] = "MOVE";
    PointerAction["SCROLL"] = "SCROLL";
    PointerAction["CANCEL"] = "CANCEL";
})(PointerAction || (PointerAction = {}));
export var MouseButton;
(function (MouseButton) {
    MouseButton[MouseButton["LEFT"] = 0] = "LEFT";
    MouseButton[MouseButton["MIDDLE"] = 1] = "MIDDLE";
    MouseButton[MouseButton["RIGHT"] = 2] = "RIGHT";
    MouseButton[MouseButton["BACK"] = 3] = "BACK";
    MouseButton[MouseButton["FORWARD"] = 4] = "FORWARD";
})(MouseButton || (MouseButton = {}));
var CanvasPointerEvent = (function () {
    function CanvasPointerEvent() {
        this.pointers = new Array(10).fill(null).map(function () { return ({}); });
        this.pointerCount = 0;
        this.scrollX = 0;
        this.scrollY = 0;
        this.scrollZ = 0;
        this.target = null;
        this.action = PointerAction.OVER;
        this.offset = { left: 0, top: 0 };
    }
    CanvasPointerEvent.prototype.offsetPointers = function (offsetX, offsetY) {
        var pointers = this.pointers;
        for (var i = 0, l = this.pointerCount; i < l; i++) {
            var pointer = pointers[i];
            pointer.x += offsetX;
            pointer.y += offsetY;
        }
    };
    Object.defineProperty(CanvasPointerEvent.prototype, "primaryPointer", {
        get: function () {
            return this.pointers[0];
        },
        enumerable: false,
        configurable: true
    });
    CanvasPointerEvent.prototype.clone = function () {
        return this.cloneTo(new CanvasPointerEvent());
    };
    CanvasPointerEvent.prototype.cloneTo = function (event) {
        event.offset.left = this.offset.left;
        event.offset.top = this.offset.top;
        event.pointerCount = this.pointerCount;
        for (var i = 0, l = this.pointerCount; i < l; i++) {
            var dst = event.pointers[i];
            var src = this.pointers[i];
            dst.pressure = src.pressure;
            dst.mouseButton = src.mouseButton;
            dst.deltaX = src.deltaX;
            dst.deltaY = src.deltaY;
            dst.x = src.x;
            dst.y = src.y;
        }
        event.target = this.target;
        event.action = this.action;
        event.scrollX = this.scrollX;
        event.scrollY = this.scrollY;
        event.scrollZ = this.scrollZ;
        return event;
    };
    return CanvasPointerEvent;
}());
export { CanvasPointerEvent };
