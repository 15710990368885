import { defaultCanvasCreator, defaultImageClass } from '../canvas/canvas';
var Context = (function () {
    function Context(options) {
        if (options === void 0) { options = {}; }
        this.debugEnabled = false;
        this.idMap = {};
        this.imageClass = options.imageClass || defaultImageClass;
        this.canvasCreator = options.canvasCreator || defaultCanvasCreator;
    }
    Context.prototype.registerView = function (idName) {
        var existingId = this.idMap[idName];
        if (existingId !== undefined) {
            return existingId;
        }
        var id = Context.idCounter++;
        this.idMap[id] = idName;
        this.idMap[idName] = id;
        return id;
    };
    Context.prototype.deregisterView = function (id) {
        var idName = this.idMap[id];
        delete this.idMap[idName];
        delete this.idMap[id];
    };
    Context.prototype.getId = function (id) {
        return id === undefined ? undefined : this.idMap[id];
    };
    Context.prototype.resolve = function (id) {
        return id !== undefined ? typeof id === 'string' ? this.registerView(id) : id : undefined;
    };
    Context.idCounter = 0;
    return Context;
}());
export { Context };
var noContextError = function () { return new Error('No Kanva context has been set. Make sure that you put all Views inside Kanva component.'); };
var NoContext = (function () {
    function NoContext() {
    }
    NoContext.prototype.deregisterView = function (_id) {
        throw noContextError();
    };
    NoContext.prototype.getId = function (_id) {
        throw noContextError();
    };
    NoContext.prototype.registerView = function (_idName) {
        throw noContextError();
    };
    NoContext.prototype.resolve = function (_id) {
        throw noContextError();
    };
    return NoContext;
}());
export { NoContext };
