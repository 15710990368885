import { ROUTES } from './routes';

export const DASHBOARD_ROUTES = [ROUTES.DASHBOARD, ROUTES.NEWS_INBOX];

export const CUSTOMER_ROUTES = [
  ROUTES.CUSTOMERS,
  ROUTES.CUSTOMER,
  ROUTES.CUSTOMER_ANALYSIS,
  ROUTES.CUSTOMER_BATTERY,
  ROUTES.CUSTOMER_PV_SYSTEMS,
  ROUTES.CUSTOMER_HEAT_PUMP,
  ROUTES.CUSTOMER_CONTRACT,
  ROUTES.CUSTOMER_CASES,
];

export const LEAD_ROUTES = [
  ROUTES.LEADS,
  ROUTES.LEADS_INBOX,
  ROUTES.LEAD_NEW,
  ROUTES.LEAD_OVERVIEW,
  ROUTES.LEAD_CONFIGURATION,

  ROUTES.LEAD_CONFIGURATION_NEW,

  ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT,
  ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW,
  ROUTES.LEAD_HARDWARE_NEW,
  ROUTES.LEAD_OFFER,
  ROUTES.LEAD_IMPACT_ANALYSIS,
  ROUTES.LEAD_SETUP_TOOL,
  ROUTES.LEAD_SETUP_TOOL_SETUP_DATA,
  ROUTES.LEAD_SETUP_TOOL_METER_PREPARATION,
  ROUTES.LEAD_SETUP_TOOL_VPP_DOCUMENTATION,
];

export const SETUP_ROUTES = [
  ROUTES.SETUP_LEADS,
  ROUTES.SETUP_LEAD_OVERVIEW,
  ROUTES.SETUP_LEAD_CONFIGURATION,

  ROUTES.SETUP_LEAD_CONFIGURATION_NEW,

  ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT,
  ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW,
  ROUTES.SETUP_LEAD_HARDWARE_NEW,
  ROUTES.SETUP_LEAD_OFFER,
  ROUTES.SETUP_LEAD_IMPACT_ANALYSIS,
  ROUTES.SETUP_LEAD_SETUP_TOOL,
  ROUTES.SETUP_LEAD_SETUP_TOOL_SETUP_DATA,
  ROUTES.SETUP_LEAD_SETUP_TOOL_DSO_REGISTRATION,
  ROUTES.SETUP_LEAD_SETUP_TOOL_DSO_COMMISSIONING,
  ROUTES.SETUP_LEAD_SETUP_TOOL_PV_REGISTER,
  ROUTES.SETUP_LEAD_SETUP_TOOL_VPP_DOCUMENTATION,
];
