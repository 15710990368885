import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';

import { FormInputRadio, FormSectionParagraph } from '+shared/components';

import { VppQuestionsForm } from '../../containers/LeadOverviewVppCheckModal/LeadOverviewVppCheckModal.helper';

import './LeadOverviewVppQuestion.component.scss';

interface Props {
  form: FormikProps<VppQuestionsForm>;
  question: string;
  optionType: string;
  questionsFormValues: VppQuestionsForm;
  dataTestIdYes?: string;
  dataTestIdNo?: string;
}

export const LeadOverviewVppQuestion: React.FC<Props> = ({
  form,
  question,
  optionType,
  dataTestIdYes,
  dataTestIdNo,
}) => {
  return (
    <div className={'c-lead-vpp-question'}>
      <FormSectionParagraph>{question}</FormSectionParagraph>

      <div className={'c-lead-vpp-question__radio-group'}>
        <FormInputRadio
          form={form}
          label={I18n.t(T.lead.offer._salessolution_.yes)}
          name={optionType}
          value={true}
          dataTestId={dataTestIdYes}
        />
        <FormInputRadio
          form={form}
          label={I18n.t(T.lead.offer._salessolution_.no)}
          name={optionType}
          value={false}
          dataTestId={dataTestIdNo}
        />
      </div>
    </div>
  );
};
