import { ActionsUnion, createAction } from '+app/utils/redux';

import { VideoYoutube } from './types/youtube.interface';

export enum YOUTUBE_ACTIONS {
  GET_YOUTUBE_VIDEO_LIST = '[Youtube] GET_YOUTUBE_VIDEO_LIST',
  SET_YOUTUBE_VIDEO_LIST = '[Youtube] SET_YOUTUBE_VIDEO_LIST',
  CLEAR_YOUTUBE_VIDEO_LIST = '[Youtube] CLEAR_YOUTUBE_VIDEO_LIST',
}

export const YoutubeActions = {
  getYoutubeVideoList: createAction(YOUTUBE_ACTIONS.GET_YOUTUBE_VIDEO_LIST, (videoIds: string) => ({
    videoIds,
  })),
  setYoutubeVideoList: createAction(
    YOUTUBE_ACTIONS.SET_YOUTUBE_VIDEO_LIST,
    (videoList: VideoYoutube[]) => ({ videoList })
  ),
  clearYoutubeVideoList: createAction(YOUTUBE_ACTIONS.CLEAR_YOUTUBE_VIDEO_LIST),
};

export type YoutubeActions = ActionsUnion<typeof YoutubeActions>;
