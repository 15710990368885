import type { Notification } from './types/notification.interface';

export enum ModalId {
  LOGO = 'Logo',
  RESET_PASSWORD = 'ResetPassword',
  REQUEST_ACCESS = 'RequestAccess',
  DOCS_UPLOAD = 'DocsUpload',
  LEAD_INVALID_ADDRESS = 'LeadInvalidAddress',
  LEAD_EDIT_STATUS = 'LeadEditStatus',
  VPP_BONUS_CHECK = 'VppBonusCheck',
  ADD_VIDEO = 'AddVideo',
  EMAIL_CHANGED = 'EmailChanged',
  DSO_VALIDATION = 'DsoValidation',
  MISSING_BATTERY = 'MissingBattery',
  CASE_REPORT = 'CaseReport',
  REGISTRATION_SUBJECT = 'RegistrationSubject',
  HARDWARE_STATUS_UPDATE_NEW_OFFER = 'HardwareStatusUpdateNewOffer',
  HARDWARE_STATUS_UPDATE_OVERVIEW = 'HardwareStatusUpdateOverview',
  HARDWARE_STATUS_UPDATE_OFFER_FORM = 'HardwareStatusUpdateOfferForm',
  SMALL_ENTERPRISE_INFORMATION = 'SmallEnterpriseInformation',
  DELIVERY_ZIP_CODE_NOT_FOUND = 'DeliveryZipCodeNotFound',
  INVOICE_ZIP_CODE_NOT_FOUND = 'InvoiceZipCodeNotFound',
}

export interface LayoutState {
  isNavOpened: boolean;
  isModalOpen: boolean;
  openModalId: ModalId | undefined;
  isLanguageLoading: boolean;
  notificationQueue: Notification[];
}

export const initialState: LayoutState = {
  isNavOpened: false,
  isModalOpen: false,
  openModalId: undefined,
  isLanguageLoading: true,
  notificationQueue: [],
};
