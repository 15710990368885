import { FormikProps } from 'formik';

import { getOfferProductStatus } from '+app/+lead/+offer/store/+offer.helper';
import { LeadEditHardwareStatus } from '+app/+lead/store/types';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { LeadOffer, LeadProductType, OfferProductStatus } from '+shared/store/lead/types';

export const formFields = {
  HARDWARE_OFFER_SENT: 'hardwareOfferSent',
  HARDWARE_ORDER_CONFIRMED: 'hardwareOrderConfirmed',
} as const;

type LeadHardwareStatusFormFieldsMap = typeof formFields;
type LeadHardwareStatusFormFields =
  LeadHardwareStatusFormFieldsMap[keyof LeadHardwareStatusFormFieldsMap];

export const mapOfferProductStatusToInitialValues = (
  offer?: LeadOffer
): Record<LeadHardwareStatusFormFields, boolean> => {
  const status = offer && getOfferProductStatus(offer, LeadProductType.BATTERY);

  return {
    hardwareOfferSent: status
      ? [
          OfferProductStatus.SENT,
          OfferProductStatus.ACCEPTED,
          OfferProductStatus.CONFIRMED,
        ].includes(status)
      : false,
    hardwareOrderConfirmed: status === OfferProductStatus.CONFIRMED,
  };
};

export const mapNewHardwareStatusToInitialValues = (
  newHardwareStatus?: OfferProductStatus
): Record<LeadHardwareStatusFormFields, boolean> => {
  return {
    hardwareOfferSent: newHardwareStatus
      ? [
          OfferProductStatus.SENT,
          OfferProductStatus.ACCEPTED,
          OfferProductStatus.CONFIRMED,
        ].includes(newHardwareStatus)
      : false,
    hardwareOrderConfirmed: newHardwareStatus === OfferProductStatus.CONFIRMED,
  };
};

export const onHwSentValueChange =
  (form: FormikProps<LeadEditHardwareStatus>) =>
  ({ value }: FormInputSubscriptionPayload<LeadEditHardwareStatus>) => {
    if (!value) {
      form.setFieldValue(formFields.HARDWARE_ORDER_CONFIRMED, false);
    }
  };

export const onHwConfirmedValueChange =
  (form: FormikProps<LeadEditHardwareStatus>) =>
  ({ value }: FormInputSubscriptionPayload<LeadEditHardwareStatus>) => {
    if (value) {
      form.setFieldValue(formFields.HARDWARE_OFFER_SENT, true);
    }
  };

const findHighestStatus = (
  initialValues: LeadEditHardwareStatus,
  values: LeadEditHardwareStatus
) => {
  const sortedStatuses = [formFields.HARDWARE_ORDER_CONFIRMED, formFields.HARDWARE_OFFER_SENT];

  return sortedStatuses.find((rank) => values[rank] && !initialValues[rank]);
};

const mapToProductStatus = (formStatus: LeadHardwareStatusFormFields) =>
  ({
    [formFields.HARDWARE_ORDER_CONFIRMED]: OfferProductStatus.CONFIRMED,
    [formFields.HARDWARE_OFFER_SENT]: OfferProductStatus.SENT,
  }[formStatus]);

export const findStatusToUpdate = (
  initialValues: LeadEditHardwareStatus,
  values: LeadEditHardwareStatus
) => {
  const formStatus = findHighestStatus(initialValues, values);

  return formStatus ? mapToProductStatus(formStatus) : undefined;
};
