import { defaultTo } from 'lodash/fp';

import { createPersistedReducer } from '../../../utils';
import { queryReducer } from '../query';
import { USER_ACTIONS, UserActions } from './user.actions';
import {
  GET_LOGO_QUERY,
  GET_USER_PASSWORD_QUERY,
  GET_USER_PROFILE_QUERY,
  GET_USER_QUERY,
  initialState,
  UserState,
} from './user.state';

export const reducer = createPersistedReducer<UserState, UserActions>(
  (state = initialState, action): UserState => {
    switch (action.type) {
      case USER_ACTIONS.SET_PROFILE:
        return {
          ...state,
          profile: defaultTo(state.profile)(action.profile),
        };
      case USER_ACTIONS.SET_COMPANY:
        return {
          ...state,
          company: defaultTo(state.company)(action.company),
        };
      case USER_ACTIONS.SET_USER_PROFILE:
        if (state.profile) {
          return {
            ...state,
            profile: {
              ...state.profile,
              image: action.profileExtended.image || undefined,
            },
          };
        }
        return { ...state };
      default:
        return queryReducer(state, action, [
          GET_USER_QUERY,
          GET_USER_PASSWORD_QUERY,
          GET_LOGO_QUERY,
          GET_USER_PROFILE_QUERY,
        ]);
    }
  }
)({
  key: 'user',
  blacklist: ['getUserQuery'],
});
