import { Query } from '+shared/store/query';
import { Document } from '+shared/store/setupTool/types';

import { PvRegisterState, PVRegisterSubmissionInterface } from './types';

export const PV_REGISTER_PATCH_SUBMISSION_QUERY = 'pvRegisterPatchSubmissionQuery';
export const PV_REGISTER_CONFIG_SUBMIT_QUERY = 'pvRegisterConfigQuery';
export const PV_REGISTER_POLLING_SUBMIT_QUERY = 'pvRegisterPollingConfigQuery';
export const PV_REGISTER_MANUALLY_QUERY = 'pvRegisterManuallyQuery';
export const SOLVE_CAPTCHA_PV_QUERY = 'solveCaptchaPVQuery';

export interface PVRegisterState {
  documents: Document[];
  updatedAt: string;
  submission?: PVRegisterSubmissionInterface;
  registrationError?: any;
  registrationCaptcha?: any;
  pvRegisterState: PvRegisterState;
  [PV_REGISTER_PATCH_SUBMISSION_QUERY]: Query;
  [PV_REGISTER_CONFIG_SUBMIT_QUERY]: Query;
  [PV_REGISTER_POLLING_SUBMIT_QUERY]: Query;
  [PV_REGISTER_MANUALLY_QUERY]: Query;
  [SOLVE_CAPTCHA_PV_QUERY]: Query;
}

export const initialState: PVRegisterState = {
  documents: [],
  updatedAt: '',
  submission: undefined,
  registrationError: undefined,
  registrationCaptcha: undefined,
  pvRegisterState: PvRegisterState.DISABLED,
  [PV_REGISTER_PATCH_SUBMISSION_QUERY]: {},
  [PV_REGISTER_CONFIG_SUBMIT_QUERY]: {},
  [PV_REGISTER_POLLING_SUBMIT_QUERY]: {},
  [PV_REGISTER_MANUALLY_QUERY]: {},
  [SOLVE_CAPTCHA_PV_QUERY]: {},
};
