import { LeadConfigurationResponse } from '+shared/store/lead/types';
import { Query } from '+shared/store/query';

import { ConfigurationForm, ConfigurationFormHint } from './types';

export const CONFIGURATION_SUBMIT_QUERY = 'submitConfigurationQuery';
export const CONFIGURATION_SAVE_QUERY = 'saveConfigurationQuery';
export const CONFIGURATION_REMOVE_QUERY = 'removeConfigurationQuery';
export const CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY = 'submitConfigurationRecommendationQuery';
export const CONFIGURATION_OPTIONAL_RECOMMENDATION_SUBMIT_QUERY =
  'submitConfigurationOptionalRecommendationQuery';
export const SET_CONFIGURATION_PROPOSAL_QUERY = 'setConfigurationProposalQuery';

export interface ConfigurationPageState {
  configurationForm?: ConfigurationForm;
  configurationProposal?: LeadConfigurationResponse;
  configurationHint?: ConfigurationFormHint;
  [CONFIGURATION_SUBMIT_QUERY]: Query<any>;
  [CONFIGURATION_REMOVE_QUERY]: Query<any>;
  [CONFIGURATION_SAVE_QUERY]: Query<any>;
  [CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY]: Query<any>;
  [CONFIGURATION_OPTIONAL_RECOMMENDATION_SUBMIT_QUERY]: Query<any>;
  [SET_CONFIGURATION_PROPOSAL_QUERY]: Query<any>;
}

export const initialState: ConfigurationPageState = {
  configurationForm: undefined,
  configurationProposal: undefined,
  configurationHint: undefined,
  [CONFIGURATION_SUBMIT_QUERY]: {},
  [CONFIGURATION_REMOVE_QUERY]: {},
  [CONFIGURATION_SAVE_QUERY]: {},
  [CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY]: {},
  [CONFIGURATION_OPTIONAL_RECOMMENDATION_SUBMIT_QUERY]: {},
  [SET_CONFIGURATION_PROPOSAL_QUERY]: {},
};
