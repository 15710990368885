import { Data, MergedData } from '@coolio/json-api';

export enum FlatDocumentType {
  OFFER = 'flat-offers',
  CONFIGURATION = 'flat-configurations',
}

export enum DocumentStatus {
  PENDING = 'pending',
  CREATED = 'created',
  FAILED = 'failed',
}

export interface LeadDocumentAttributes {
  key: string;
  createdAt: Date;
  status: DocumentStatus;
  fileName: string;
}

export type LeadDocumentData = Data<LeadDocumentAttributes>;
export type LeadDocument = MergedData<LeadDocumentData>;
