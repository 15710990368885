import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader, Modal, ModalLayout } from '@sonnen/shared-web';

import { Form, Formik, FormikProps } from 'formik';

import { DeclineLeadSchema } from '+app/+lead/store/schemas';
import { LeadModalLeadName } from '+lead/components/LeadModalLeadName';
import { FormErrorBanner } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, MainType } from '+shared/components/Button';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { Lead } from '+shared/store/lead/types';

import './LeadListDeclineModal.component.scss';

type LeadListDeclineModalForm = { declineReason: string };

interface Props {
  isModalOpen: boolean;
  chosenInboxLead?: Lead;
  isPending: boolean;
  isError: boolean;
  toggleDeclineLeadModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
  declineLead: (leadId: string, reason: string) => void;
}

export const LeadListDeclineModal: React.FC<Props> = ({
  isModalOpen,
  toggleDeclineLeadModal,
  toggleModal,
  chosenInboxLead,
  declineLead,
  isPending,
  isError,
}) => {
  const closeLeadDeclineModal = () => {
    toggleDeclineLeadModal(false);
    toggleModal(false);
  };

  const onSubmit = (values: { declineReason: string }) => {
    if (chosenInboxLead) {
      declineLead(chosenInboxLead.id, values.declineReason);
    }
  };

  const onFieldValueChange =
    (form: FormikProps<LeadListDeclineModalForm>) =>
    (payload: FormInputSubscriptionPayload<LeadListDeclineModalForm>) => {
      if (!form.touched.declineReason) {
        form.setFieldTouched('declineReason', true);
      }
    };

  return chosenInboxLead ? (
    <Modal
      isOpen={isModalOpen}
      onClose={closeLeadDeclineModal}
      size={'medium'}
      dataTestId="leads-inbox-decline-lead-modal"
    >
      {isPending && <Loader className={'c-lead-list-decline-modal__accept-loader'} />}
      <div className={'c-lead-list-decline-modal'}>
        <Formik
          initialValues={{ declineReason: '' }}
          validationSchema={DeclineLeadSchema}
          validateOnChange={true}
          onSubmit={onSubmit}
          render={(form) => (
            <Form className={'c-lead-list-decline-modal-form'}>
              <ModalLayout
                title={I18n.t(T.lead.list._salessolution_.declineModal.header)}
                subtitle={I18n.t(T.lead.list._salessolution_.declineModal.description)}
                footer={
                  <div className={'c-lead-list-decline-modal__buttons'}>
                    <div className={'c-lead-list-decline-modal__buttons-wrapper'}>
                      <Button
                        label={I18n.t(T.lead.list._salessolution_.declineModal.btn)}
                        size={ButtonSize.SMALL}
                        mainType={MainType.SUBMIT}
                        disabled={!form.isValid || isPending}
                        status={
                          !form.isValid || isPending ? ButtonStatus.DISABLED : ButtonStatus.ENABLED
                        }
                        dataTestId="leads-inbox-decline-lead-modal-decline-btn"
                      />
                    </div>
                  </div>
                }
              >
                <LeadModalLeadName lead={chosenInboxLead} />

                <FormFieldObserver<LeadListDeclineModalForm> onChange={onFieldValueChange(form)}>
                  <FormTextarea
                    form={form}
                    label={I18n.t(T.lead.list._salessolution_.declineModal.label)}
                    placeholder={I18n.t(T.lead.list._salessolution_.declineModal.placeholder)}
                    name={'declineReason'}
                    className={'c-lead-list-decline-modal__rejection'}
                    dataTestId="leads-inbox-decline-lead-modal-text-area"
                  />
                </FormFieldObserver>

                <FormErrorBanner
                  isVisible={isError}
                  error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
                />
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  ) : null;
};
