import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';

import { CountryFeatureName } from '+config/countryFlags';
import { BatteryIntelligentChargingOperation } from '+customer/+battery';
import { Widget } from '+shared/components/Widget/Widget';

import { BatteryBackupPowerBufferOperation } from '../../containers/BatteryBackupPowerBufferOperation/BatteryBackupPowerBufferOperation';
import { BatteryOperatingModeOperation } from '../../containers/BatteryOperatingModeOperation';
import { isWidgetDisabled, OpenWidgetTitle } from './BatteryOperationsCard.helper';

import './BatteryOperationsCard.component.scss';

interface Props {
  onRangeChange: (sliderBackupValues: number[]) => void;
}

export const BatteryOperationsCard: React.FC<Props> = ({ onRangeChange }) => {
  const isBatteryIntelligentChargingVisible = useCountryFeature(
    CountryFeatureName.BATTERY_INTELLIGENT_CHARGING
  ).isEnabled;
  const [activeWidget, setActiveWidget] = React.useState<OpenWidgetTitle[]>([]);

  return (
    <Widget
      className={'c-battery-operations-card'}
      heading={I18n.t(T.customerSingle.batteryDetails.batteryOperations.headline)}
      icon={'Settings'}
    >
      <ul className={'c-battery-operations-card__operations'}>
        {isBatteryIntelligentChargingVisible && (
          <BatteryIntelligentChargingOperation
            isMoreThanOneWidgetActive={isWidgetDisabled(
              OpenWidgetTitle.INTELLIGENT_CHARGING,
              activeWidget
            )}
            onWidgetOpening={setActiveWidget}
          />
        )}
        <BatteryOperatingModeOperation
          isMoreThanOneWidgetActive={isWidgetDisabled(OpenWidgetTitle.OPERATING_MODE, activeWidget)}
          onWidgetOpening={setActiveWidget}
        />
        <BatteryBackupPowerBufferOperation
          onRangeChange={onRangeChange}
          isMoreThanOneWidgetActive={isWidgetDisabled(OpenWidgetTitle.BACKUP_BUFFER, activeWidget)}
          onWidgetOpening={setActiveWidget}
        />
      </ul>
    </Widget>
  );
};
