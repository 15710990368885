import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, InfoBanner, Loadable, useCountryFeature } from '@sonnen/shared-web';

import { getContractQueryStatus } from '+app/+customer/+contract/store/+contract.selectors';
import { CustomerHeatPump } from '+app/+customer/+heatPump/CustomerHeatPump';
import { hasSiteHeatpump } from '+app/+customer/+overview/store/+overview.selector';
import CustomerPvSystems from '+app/+customer/+pvSystems/CustomerPvSystems';
import { getAssetsAvailability, getSite } from '+app/+customer/store/+customer.helper';
import { NotFound } from '+app/+static';
import { mapActions } from '+app/utils';
import { CountryFeatureName } from '+config/countryFlags';
import { Container } from '+shared/components';
import { CaseReport, CaseReportHelper } from '+shared/containers/CaseReport';
import { isAnyBatteryOnSiteVisible } from '+shared/store/battery/battery.helpers';
import { CaseActions } from '+shared/store/case';
import { Customer as CustomerData, Site } from '+shared/store/customer';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';

import { CustomerRouteParams, PATHS, ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { CustomerAnalysis } from '../../+analysis';
import { CustomerBattery } from '../../+battery';
import { CustomerCases } from '../../+case';
import { CustomerContract } from '../../+contract';
import { CustomerOverview } from '../../+overview';
import { CustomerHeader } from '../../components';
import {
  getSelectedCustomer,
  getSelectedCustomerQueryStatus,
} from '../../store/+customer.selectors';

import './Customer.component.scss';

const mapStateToProps = (state: StoreState, ownProps: RouteProps) => {
  const { siteId } = ownProps.match.params;
  const { location } = ownProps;
  const customer = getSelectedCustomer(state);
  const site = getSite(siteId)(customer);
  const selectedCustomerQueryStatus = getSelectedCustomerQueryStatus(state);
  const assetsAvailability = getAssetsAvailability(site, state);
  const contractQueryStatus = getContractQueryStatus(state);
  const hasHeatpump = hasSiteHeatpump(state);

  return {
    selectedCustomerQueryStatus,
    assetsAvailability,
    customer,
    site,
    contractQueryStatus,
    location,
    hasHeatpump,
  };
};

const mapDispatchToProps = mapActions({
  goToSite: RouterActions.changeCustomerSite,
  goToCustomers: RouterActions.goToCustomers,
  toggleModal: LayoutActions.toggleModal,
  setCaseReportActiveSite: CaseActions.setCaseReportActiveSite,
});

type RouteProps = RouteComponentProps<CustomerRouteParams>;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps;

export const CustomerComponent: React.FC<Props> = ({
  actions,
  customer,
  site,
  assetsAvailability,
  contractQueryStatus,
  selectedCustomerQueryStatus,
  location,
  hasHeatpump,
}) => {
  const handleCaseReportButton = (customer: CustomerData) => (site: Site) => (): void => {
    const activeSite = CaseReportHelper.transformSite(site, customer);
    actions.toggleModal(true, ModalId.CASE_REPORT);
    actions.setCaseReportActiveSite(activeSite);
  };

  const goToSite =
    (customer: CustomerData) =>
    (site: Site): void => {
      actions.goToSite(customer.id, site.id);
    };

  const getRouteParams =
    (customer: CustomerData | undefined) =>
    (site: Site | undefined): CustomerRouteParams => ({
      customerId: customer ? customer.id : '',
      siteId: site ? site.id : '',
    });

  const routeParams = getRouteParams(customer)(site);
  const isCustomerContractFeatureEnabled = useCountryFeature(
    CountryFeatureName.CUSTOMER_CONTRACT
  ).isEnabled;
  const isCustomerQueryFinished =
    selectedCustomerQueryStatus.error || selectedCustomerQueryStatus.success;
  const isCustomerLoaded = !!customer && !!site;
  const isCustomerAnalysisPath = location.pathname === PATHS.CUSTOMER_ANALYSIS(routeParams);

  if (isCustomerQueryFinished && (!customer || !site)) {
    return <NotFound />;
  }

  return (
    <Loadable predicate={!isCustomerLoaded} transition={'fade'}>
      {customer && site ? (
        <>
          <CustomerHeader
            customer={customer}
            siteSelected={site}
            sites={customer.sites.filter((site) => isAnyBatteryOnSiteVisible(site.batterySystems))}
            currentSite={site}
            hasHeatpump={hasHeatpump}
            routeParams={routeParams}
            title={customer.name}
            assetsAvailability={assetsAvailability}
            onGoBack={actions.goToCustomers}
            onSiteChange={goToSite(customer)}
            contractQueryStatus={contractQueryStatus}
            onCaseReportButtonClick={handleCaseReportButton(customer)(site)}
          />
          <CaseReport />
          <Container withVerticalPadding={false} withHorizontalPadding={!isCustomerAnalysisPath}>
            <div className={'c-customer'}>
              <Switch>
                <Redirect exact={true} from={ROUTES.CUSTOMER[1]} to={PATHS.CUSTOMER(routeParams)} />
                <Redirect
                  exact={true}
                  from={ROUTES.CUSTOMER_ANALYSIS[1]}
                  to={PATHS.CUSTOMER_ANALYSIS(routeParams)}
                />
                <Redirect
                  exact={true}
                  from={ROUTES.CUSTOMER_BATTERY[1]}
                  to={PATHS.CUSTOMER_BATTERY(routeParams)}
                />
                {isCustomerContractFeatureEnabled && (
                  <Redirect
                    exact={true}
                    from={ROUTES.CUSTOMER_CONTRACT[1]}
                    to={PATHS.CUSTOMER_CONTRACT(routeParams)}
                  />
                )}
                <Route exact={true} path={ROUTES.CUSTOMER} component={CustomerOverview} />
                <Route path={ROUTES.CUSTOMER_BATTERY} component={CustomerBattery} />
                <Route path={ROUTES.CUSTOMER_HEAT_PUMP} component={CustomerHeatPump} />
                <Route path={ROUTES.CUSTOMER_PV_SYSTEMS} component={CustomerPvSystems} />
                <Route path={ROUTES.CUSTOMER_ANALYSIS} component={CustomerAnalysis} />
                {isCustomerContractFeatureEnabled && (
                  <Route path={ROUTES.CUSTOMER_CONTRACT} component={CustomerContract} />
                )}
                <Route path={ROUTES.CUSTOMER_CASES} component={CustomerCases} />
                <Redirect to={PATHS.CUSTOMER(routeParams)} />
              </Switch>
            </div>
          </Container>
        </>
      ) : (
        <div className={'c-customer c-customer--no-consent-given'}>
          <InfoBanner
            icon={<Icon.NoEntry />}
            title={I18n.t(T.customerSingle.accessDenied.header)}
            subtitle={I18n.t(T.customerSingle.accessDenied.description)}
          />
        </div>
      )}
    </Loadable>
  );
};

export const Customer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerComponent));
