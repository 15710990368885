import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import * as classNames from 'classnames';

import { FormattedText } from '../FormattedText';
import { getPaginationBoundaries } from './PaginationSummary.helper';

import './PaginationSummary.component.scss';

interface Props {
  page: number | string;
  recordsTotal: number;
  i18n: string;
  className?: ClassValue;
}

export const PaginationSummary: React.SFC<Props> = ({ className, i18n, page, recordsTotal }) => (
  <FormattedText
    className={classNames('c-pagination-summary', className)}
    textClassNames={{ c: 'c-pagination-summary--light' }}
  >
    {I18n.t(
      i18n,
      getPaginationBoundaries({
        page: page ? Number(page) : undefined,
        recordsTotal,
      })
    )}
  </FormattedText>
);
