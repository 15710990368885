import { createReducer } from '+app/utils';
import {
  DSO_REGISTRATION_FORM_ACTIONS,
  DsoRegistrationFormActions,
} from '+setupTool/+form/store/+form.actions';
import { trimFieldValues } from '+setupTool/+form/store/+form.helpers';
import { queryReducer } from '+shared/store/query';

import { DSO_COMMISSIONING_ACTIONS, DsoCommissioningActions } from './+dsoCommissioning.actions';
import {
  DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY,
  DsoCommissioningState,
  GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY,
  initialState,
} from './+dsoCommissioning.state';

export const reducer = createReducer<
  DsoCommissioningState,
  DsoCommissioningActions | DsoRegistrationFormActions
>((state = initialState, action): DsoCommissioningState => {
  switch (action.type) {
    case DSO_COMMISSIONING_ACTIONS.SET_GENERATED_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case DSO_COMMISSIONING_ACTIONS.SET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...trimFieldValues(action.fields),
        },
      };
    case DSO_COMMISSIONING_ACTIONS.SET_MODIFICATION_DATE: {
      return {
        ...state,
        updatedAt: action.date || state.updatedAt,
      };
    }
    case DSO_COMMISSIONING_ACTIONS.SET_DOCUMENTS_GENERATION_DATE: {
      return {
        ...state,
        documentsUpdatedAt: action.date || '',
      };
    }
    case DSO_REGISTRATION_FORM_ACTIONS.CLEAR_DATA:
      return initialState;
    default:
      return queryReducer(state, action, [
        GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY,
        DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY,
      ]);
  }
});
