import * as React from 'react';

import { Icon } from '@sonnen/shared-web/src/components/Icon/index';

import * as classNames from 'classnames';

import { OverlayTestIds } from '+config/testIds';
import { Container, Portal } from '+shared/components';

import './Overlay.component.scss';

interface OverlayProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  onClose: () => void;
  onBackdropClick?: () => void;
}

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  ({ children, title, onClose, onBackdropClick, className }, ref) => (
    <Portal>
      <div ref={ref} className={classNames('c-overlay', className)} onClick={onBackdropClick}>
        <Container
          id={'c-overlay-setup'}
          className={'c-overlay__dialog'}
          withHorizontalPadding={false}
        >
          <div className={'c-overlay__content c-overlay__content--setup'}>
            {
              <div className={'c-overlay__close-container'}>
                <button
                  onClick={onClose}
                  className={'c-overlay__close-icon'}
                  data-testid={OverlayTestIds.closeButton}
                >
                  <Icon.Close />
                </button>
              </div>
            }
            {title && <h1 className={'c-overlay__title'}>{title}</h1>}
            {children}
          </div>
        </Container>
      </div>
    </Portal>
  )
);
