import { pick, values } from 'lodash';

import {
  AdobeAnalyticsEnvs,
  Env,
  getLocalBackendServices,
  LocalBackendService,
} from './environment';

interface AuthConfiguration {
  AUTH_URL: string;
  CLIENT_ID: string;
  CLIENT_SECRET?: string;
}

interface AuthSalesforceConfiguration {
  SALESFORCE_URL: string;
  SALESFORCE_TECH_URL: string;
  SALESFORCE_CLIENT_ID: string;
}

interface FirebaseConfiguration {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  firebaseFunctionsUrl: string;
  authorizationSource: string | null;
}

interface LaunchDarklyConfiguration {
  clientSideID: string;
}

export interface EnvConfiguration {
  ENVIRONMENT: string;
  IS_DEV: boolean;
  PORTAL_URL: string;
  API_URL: string;
  API_URL_V2: string;
  API_GATEWAY_URL: string;
  PARTNER_PORTAL_API_URL: string;
  SALES_API_URL: string;
  DSO_REGISTRATION_API_URL: string;
  AUTH: AuthConfiguration;
  AUTH_SALESFORCE: AuthSalesforceConfiguration;
  DOCS_API: string;
  COMPONENTS_DB_API_URL: string;
  FIREBASE: FirebaseConfiguration;
  YOUTUBE_URL: string;
  YOUTUBE_API: string;
  PRODUCTION_FORECAST: string;
  CONSUMPTION_FORECAST: string;
  LAUNCH_DARKLY: LaunchDarklyConfiguration;
  PARTNER_TOOLBOX_URL: string;
}

const AUTH_SALESFORCE_EXTERNAL = {
  SALESFORCE_URL: 'https://sonnen--external.my.salesforce.com',
  SALESFORCE_TECH_URL: 'https://sonnen--extern2.sandbox.my.salesforce.com',
  SALESFORCE_CLIENT_ID:
    '3MVG9sSN_PMn8tjSodyD28jSJp5xDSUQiuM9Ma8oUj_M9jERv4Cf4TvUXzjU9Vqj81B6f9z6NW1mZsa5HfYz7',
};

const AUTH_SALESFORCE_STAGEUAT = {
  SALESFORCE_URL: 'https://sonnen--stageuat.my.salesforce.com',
  SALESFORCE_TECH_URL: 'https://sonnen--stageuat.sandbox.my.salesforce.com',
  SALESFORCE_CLIENT_ID:
    '3MVG9Lu3LaaTCEgJ1gvddPIi1Txr5tMHIRzdV1QEYOoSFyO_2KF.VMh5jy6Q61Ec.Kx.Esaj1fQETSikmg19q',
};

const AUTH_SALESFORCE_PRODUCTION = {
  SALESFORCE_URL: 'https://sonnencommunity.force.com',
  SALESFORCE_TECH_URL: 'https://sonnen.my.salesforce.com',
  SALESFORCE_CLIENT_ID:
    '3MVG9Rd3qC6oMalVMVGU7MyHfOBxhBIXLyWeI5nPxDktnWBABTuOJPVK3YHOieLpiDlJvQxIMawvzMfxZ_E6w',
};

const LAUNCH_DARKLY_DEVELOPMENT = {
  clientSideID: '62306235ce3a5b12cf1ad797',
};
const LAUNCH_DARKLY_DEMO = {
  clientSideID: '622f21096fed78155821a9eb',
};
const LAUNCH_DARKLY_STAGING = {
  clientSideID: '6230622606dce713309f9bcd',
};
const LAUNCH_DARKLY_PRODUCTION = {
  clientSideID: '622f21096fed78155821a9ec',
};

const NON_PRODUCTION_HELP_SECTION_LINK =
  'https://sonnen--stageuat.sandbox.my.site.com/partnerportal/s/helpandsupport?';
export const HELP_SECTION_LINK = {
  local: NON_PRODUCTION_HELP_SECTION_LINK,
  development: NON_PRODUCTION_HELP_SECTION_LINK,
  staging: NON_PRODUCTION_HELP_SECTION_LINK,
  demo: NON_PRODUCTION_HELP_SECTION_LINK,
  production: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport',
};

export const ADOBE_ANALYTICS_SCRIPT: AdobeAnalyticsEnvs = {
  local: 'https://assets.adobedtm.com/fdd3d8394b31/c98cb67c23fc/launch-fb5694b94e61-staging.min.js',
  development:
    'https://assets.adobedtm.com/fdd3d8394b31/c98cb67c23fc/launch-fb5694b94e61-staging.min.js',
  staging:
    'https://assets.adobedtm.com/fdd3d8394b31/c98cb67c23fc/launch-fb5694b94e61-staging.min.js',
  production: 'https://assets.adobedtm.com/fdd3d8394b31/c98cb67c23fc/launch-ce1c5740c0e9.min.js',
};

const isDevEnvironment = (env: Env): boolean => {
  return [Env.DEVELOPMENT, Env.LOCAL].includes(env);
};

const getDevelopmentEnvConfig = (env: Env): EnvConfiguration => {
  return {
    ENVIRONMENT: env,
    IS_DEV: isDevEnvironment(env),
    PORTAL_URL: 'http://my-dev.sonnen.de',
    API_URL: 'https://api.integration.sonnen.de/v1',
    API_URL_V2: 'https://api.integration.sonnen.de/v2',
    API_GATEWAY_URL: 'https://sandbox.api.sonnen.reinblau.dev',
    PARTNER_PORTAL_API_URL: 'https://api-dev.partner-usage.sales.staging.sonnen.de/api',
    PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
    CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
    SALES_API_URL: 'https://api-dev.sales.staging.sonnen.de/api/v1',
    DSO_REGISTRATION_API_URL: 'https://setup-tools-dev.sales.staging.sonnen.de/api',
    COMPONENTS_DB_API_URL: 'https://components-db-dev.sales.staging.sonnen.de/api',
    AUTH: {
      AUTH_URL: 'https://account.integration.sonnen.de',
      CLIENT_ID: '61da2ce586ad54783da12a8aaa397bafe95356eda0cf9ad9ca1cd160659c11ee',
    },
    AUTH_SALESFORCE: AUTH_SALESFORCE_EXTERNAL,
    DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
    YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
    YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
    FIREBASE: {
      firebaseFunctionsUrl: 'https://us-central1-sonnenportal-dev.cloudfunctions.net',
      apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
      authDomain: 'sonnenportal-dev.firebaseapp.com',
      databaseURL: 'https://sonnenportal-dev.firebaseio.com',
      projectId: 'sonnenportal-dev',
      storageBucket: 'sonnenportal-dev.appspot.com',
      messagingSenderId: '215679461555',
      appId: '1:215679461555:web:a2375cc12273c091b428c6',
      authorizationSource: 'integration',
    },
    LAUNCH_DARKLY: LAUNCH_DARKLY_DEVELOPMENT,
    PARTNER_TOOLBOX_URL: 'https://partner.dev.sonnen.de/t',
  };
};

const localBackendEnvConfigs: Record<LocalBackendService, Partial<EnvConfiguration>> = {
  [LocalBackendService.SALES_SOLUTION]: {
    SALES_API_URL: 'http://localhost:8000/api/v1',
  },
  [LocalBackendService.SETUP_TOOLS]: {
    DSO_REGISTRATION_API_URL: 'http://localhost:3010/api',
  },
  [LocalBackendService.COMPONENTS_DATABASE]: {
    COMPONENTS_DB_API_URL: 'http://localhost:8088/api',
  },
};

const getLocalBackendEnvConfig = (): Partial<EnvConfiguration> =>
  Object.assign({}, ...values(pick(localBackendEnvConfigs, getLocalBackendServices())));

export const getEnvConfig = (env: Env): EnvConfiguration => {
  switch (env) {
    case Env.LOCAL:
      return {
        ...getDevelopmentEnvConfig(env),
        ...getLocalBackendEnvConfig(),
      };
    case Env.DEVELOPMENT:
      return getDevelopmentEnvConfig(env);
    case Env.DEMO:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        PORTAL_URL: 'http://my.staging.sonnen.de',
        API_URL: 'https://api.staging.sonnen.de/v1',
        API_URL_V2: 'https://api.staging.sonnen.de/v2',
        API_GATEWAY_URL: 'https://api.sonnen.de',
        PARTNER_PORTAL_API_URL: 'https://api.partner-usage.sales.staging.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api-demo.sales.staging.sonnen.de/api/v1',
        DSO_REGISTRATION_API_URL: 'https://setup-tools-demo.sales.staging.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db-staging.sales.staging.sonnen.de/api',
        AUTH: {
          AUTH_URL: 'https://account.staging.sonnen.de',
          CLIENT_ID: 'Ee5QY5qJkTbKbzTl55PxdXPaS480hQyhkQofSvzMOmo',
        },
        AUTH_SALESFORCE: AUTH_SALESFORCE_STAGEUAT,
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-dev.cloudfunctions.net',
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
          authorizationSource: 'staging',
        },
        LAUNCH_DARKLY: LAUNCH_DARKLY_DEMO,
        PARTNER_TOOLBOX_URL: 'https://partner.staging.sonnen.de/t',
      };
    case Env.STAGING:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        PORTAL_URL: 'http://my.staging.sonnen.de',
        API_URL: 'https://api.staging.sonnen.de/v1',
        API_URL_V2: 'https://api.staging.sonnen.de/v2',
        API_GATEWAY_URL: 'https://api.sonnen.de',
        PARTNER_PORTAL_API_URL: 'https://api.partner-usage.sales.staging.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api.sales.staging.sonnen.de/api/v1',
        DSO_REGISTRATION_API_URL: 'https://setup-tools.sales.staging.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db-staging.sales.staging.sonnen.de/api',
        AUTH: {
          AUTH_URL: 'https://account.staging.sonnen.de',
          CLIENT_ID: 'Ee5QY5qJkTbKbzTl55PxdXPaS480hQyhkQofSvzMOmo',
        },
        AUTH_SALESFORCE: AUTH_SALESFORCE_STAGEUAT,
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-dev.cloudfunctions.net',
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
          authorizationSource: 'staging',
        },
        LAUNCH_DARKLY: LAUNCH_DARKLY_STAGING,
        PARTNER_TOOLBOX_URL: 'https://partner.staging.sonnen.de/t',
      };
    case Env.PRODUCTION:
    default:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        PORTAL_URL: 'https://my.sonnen.de',
        API_URL: 'https://my-api.sonnen.de/v1',
        API_URL_V2: 'https://my-api.sonnen.de/v2', // CHECK
        API_GATEWAY_URL: 'https://api.sonnen.de',
        PARTNER_PORTAL_API_URL: 'https://api.partner-usage.sales.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api.sales.sonnen.de/api/v1',
        DSO_REGISTRATION_API_URL: 'https://setup-tools.sales.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db.sales.sonnen.de/api',
        AUTH: {
          AUTH_URL: 'https://account.sonnen.de',
          CLIENT_ID: 'bbc1c96032c2f02fbaade1c36a672d7f18942b61bba95022501b254b6c45688f',
        },
        AUTH_SALESFORCE: AUTH_SALESFORCE_PRODUCTION,
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-production.cloudfunctions.net',
          apiKey: 'AIzaSyApNzzuJlabXzCg9CQRUaYw8PR2zGbgVKc',
          authDomain: 'sonnenportal-production.firebaseapp.com',
          databaseURL: 'https://sonnenportal-production.firebaseio.com',
          projectId: 'sonnenportal-production',
          storageBucket: 'sonnenportal-production.appspot.com',
          messagingSenderId: '445947885271',
          appId: '1:445947885271:web:3235b7a224b7eb4cf0e292',
          authorizationSource: null,
        },
        LAUNCH_DARKLY: LAUNCH_DARKLY_PRODUCTION,
        PARTNER_TOOLBOX_URL: 'https://partner.sonnen.de/t',
      };
  }
};
