import { OtherUnit } from '+app/App.constants';

import { LeadMeasurement } from './leadMeasurement.interface';

export enum LeadRoofOrientation {
  NORTH = 'north',
  NORTH_EAST = 'north_east',
  EAST = 'east',
  SOUTH_EAST = 'south_east',
  SOUTH = 'south',
  SOUTH_WEST = 'south_west',
  WEST = 'west',
  NORTH_WEST = 'north_west',
  UNKNOWN = '',
}

export enum LeadRoofType {
  FLAT = 'flat',
  GABLE = 'gable',
  HIP = 'hip',
  PITCH = 'pitch',
  TENTED = 'tented',
  OTHER = 'other',
  UNKNOWN = '',
}

export enum LeadRoofMaterial {
  TILES = 'tiles',
  SLATE = 'slate',
  WOOD = 'wood',
  ASBESTOS = 'asbestos',
  IRON_SHEET = 'iron_sheet',
  TAR_PAPER = 'tar_paper',
  OTHER = 'other',
  UNKNOWN = '',
}

export interface LeadRoofDetails {
  material: LeadRoofMaterial;
  orientation: LeadRoofOrientation;
  type: LeadRoofType;
  size: LeadMeasurement<OtherUnit.SQUARE_METER>;
}
