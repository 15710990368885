import * as React from 'react';

import { TableCell } from '@sonnen/shared-web';

import './CustomersTableNoData.component.scss';

interface Props {
  message?: string;
}

export const CustomersTableNoData: React.FC<Props> = ({ message }) => (
  <TableCell className={'c-customers-table-no-data'}>{message || '—'}</TableCell>
);
