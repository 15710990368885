import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Modal } from '@sonnen/shared-web';

import { Button } from '+shared/components/Button';

import './LeadOverviewActionFailedModal.component.scss';

interface Props {
  isFailingDocument: boolean;
  handleClick: () => void;
  handleClose: () => void;
  headline: string;
  description: string;
}

export const LeadOverviewActionFailedModal: React.FC<Props> = ({
  isFailingDocument,
  handleClick,
  handleClose,
  headline,
  description,
}) => (
  <Modal
    size={'medium'}
    isOpen={isFailingDocument}
    onClose={handleClose}
    className={'c-lead-overview-document-modal c-lead-overview-action-failed-modal'}
  >
    <div className={'c-lead-overview-action-failed-failed-modal__inner'}>
      <div>
        <Icon.PaperPlaneError className={'c-lead-overview-action-failed-failed-modal__icon'} />
      </div>
      <p className={'c-lead-overview-action-failed-failed-modal__heading'}>{headline}</p>
      <div className={'c-lead-overview-action-failed-failed-modal__content'}>
        <p className={'c-lead-overview-action-failed-failed-modal__paragraph'}>{description}</p>
      </div>
      <Button onClick={handleClick} label={I18n.t(T.action.retry)} />
    </div>
  </Modal>
);
