import { isString, keysIn, values } from 'lodash/fp';

import { QueryField, QueryNestedField } from '../types/graphQL.types';

export const formatQueryFields = (fields: QueryField[]) =>
  fields.reduce((dataString, field) => {
    if (isString(field)) {
      return `${dataString}${field},`;
    }
    return `${dataString}${formatArray(field)}`;
  }, '');

export const formatArray = (field: QueryNestedField) => `${keysIn(field)[0]}{${values(field)}}`;
