import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { ConfigurationPvType } from '+shared/store/lead/types';
import { dateUtil } from '+utils/date.util';
import { getPrecision } from '+utils/number.util';

import {
  formFields,
  minCommissioningDate,
} from '../../containers/LeadConfigurationPv/LeadConfigurationPv.helper';
import {
  arePhotovoltaicSystemsCompatible,
  maxOldestFlatXCommissioningDate,
} from '../+configuration.helper';
import { ConfigurationPvForm } from '../types';

const PV_SYSTEM_NAME_MAX_CHAR = 30;

const peakPowerSchema = () =>
  Yup.number()
    .test('decimal-places', I18n.t(T.validation.lessDecimalPlaces), (val) => getPrecision(val) <= 3)
    .required(I18n.t(T.register.alert.requiredField));

const specificYieldPerYearSchema = (conditionFormField: string) =>
  Yup.number().when(conditionFormField, {
    is: (val) => val === ConfigurationPvType.SPECIFIC_YIELD,
    then: Yup.number()
      .min(0, I18n.t(T.validation.greaterThanOrEqual))
      .required(I18n.t(T.register.alert.requiredField)),
  });

const inclinationSchema = (conditionFormField: string) =>
  Yup.number().when(conditionFormField, {
    is: (val) => val === ConfigurationPvType.DETAILED,
    then: Yup.number()
      .positive(I18n.t(T.validation.greaterThan0))
      .max(90, I18n.t(T.validation.lessThan))
      .required(I18n.t(T.register.alert.requiredField)),
  });

const orientationSchema = (conditionFormField: string) =>
  Yup.number().when(conditionFormField, {
    is: (val) => val === ConfigurationPvType.DETAILED,
    then: Yup.number()
      .min(-180, I18n.t(T.validation.greaterThanOrEqual))
      .max(180, I18n.t(T.validation.lessThanOrEqual))
      .required(I18n.t(T.register.alert.requiredField)),
  });

// the formatting is necessary in order for it to allow the beginning as well
// otherwise it would allow the next day, that's how Yup works
// also Safari and FF require 'YYYY-MM-DD' format
const multiplePvCommisioningDateSchema = () =>
  Yup.date()
    .min(
      dateUtil.format(minCommissioningDate, 'YYYY-MM-DD'),
      I18n.t(T.lead.configuration._salessolution_.pv.validation.commissioningDate.olderThan, {
        date: dateUtil.format(maxOldestFlatXCommissioningDate(), 'DD.MM.YYYY'),
      })
    )
    .required(I18n.t(T.register.alert.requiredField));

const singlePvCommisioningDateSchema = () =>
  Yup.date()
    .min(dateUtil.format(minCommissioningDate, 'YYYY-MM-DD'), I18n.t(T.validation.dateTooEarly))
    .required(I18n.t(T.register.alert.requiredField));

const commissioningDateSchema = () =>
  Yup.date().when(formFields.MULTIPLE_PV, {
    is: true,
    then: multiplePvCommisioningDateSchema(),
    otherwise: singlePvCommisioningDateSchema(),
  });

export const ConfigurationPvSchema = () =>
  Yup.object().shape({
    peakPower: peakPowerSchema(),
    specificYieldPerYear: specificYieldPerYearSchema(formFields.PV_TYPE),
    inclination: inclinationSchema(formFields.PV_TYPE),
    orientation: orientationSchema(formFields.PV_TYPE),
    commissioningDate: commissioningDateSchema().test(
      formFields.COMMISSIONING_DATE,
      I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.commissioningDatesDifferTooLow),
      function (firstPvCommissioningDate: Date | undefined) {
        const secondPvCommissioningDate: Date | undefined = this.parent.secondPvCommissioningDate;

        if (!firstPvCommissioningDate || !secondPvCommissioningDate || !this.parent.multiplePv) {
          return true;
        }

        return arePhotovoltaicSystemsCompatible(
          firstPvCommissioningDate,
          secondPvCommissioningDate
        );
      }
    ),
    firstPvSystemName: Yup.string().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: Yup.string()
        .max(
          PV_SYSTEM_NAME_MAX_CHAR,
          I18n.t(T.validation.maxCharacters, { max: PV_SYSTEM_NAME_MAX_CHAR })
        )
        .test(
          formFields.FIRST_PV_SYSTEM_NAME,
          I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.differentSystemNames),
          function (firstPvSystemName: string) {
            const secondPvSystemName: string = this.parent.secondPvSystemName;

            if (!firstPvSystemName || !secondPvSystemName) {
              return true;
            }

            return firstPvSystemName.toLowerCase() !== secondPvSystemName.toLowerCase();
          }
        )
        .required(I18n.t(T.register.alert.requiredField)),
    }),

    // SECOND PV
    secondPvPeakPower: Yup.number().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: peakPowerSchema(),
    }),
    secondPvSpecificYieldPerYear: Yup.number().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: specificYieldPerYearSchema(formFields.SECOND_PV_PV_TYPE),
    }),
    secondPvInclination: Yup.number().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: inclinationSchema(formFields.SECOND_PV_PV_TYPE),
    }),
    secondPvOrientation: Yup.number().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: orientationSchema(formFields.SECOND_PV_PV_TYPE),
    }),
    secondPvCommissioningDate: Yup.date()
      .test(
        formFields.SECOND_PV_COMMISSIONING_DATE,
        I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.commissioningDatesDifferTooLow),
        function (secondPvCommissioningDate: Date | undefined) {
          const firstPvCommissioningDate: Date | undefined = this.parent.commissioningDate;

          if (!firstPvCommissioningDate || !secondPvCommissioningDate || !this.parent.multiplePv) {
            return true;
          }

          return arePhotovoltaicSystemsCompatible(
            firstPvCommissioningDate,
            secondPvCommissioningDate
          );
        }
      )
      .when(formFields.MULTIPLE_PV, {
        is: (val) => val,
        then: commissioningDateSchema(),
      }),
    secondPvSystemName: Yup.string().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: Yup.string()
        .max(
          PV_SYSTEM_NAME_MAX_CHAR,
          I18n.t(T.validation.maxCharacters, { max: PV_SYSTEM_NAME_MAX_CHAR })
        )
        .test(
          formFields.SECOND_PV_SYSTEM_NAME,
          I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.differentSystemNames),
          function (secondPvSystemName: string) {
            const firstPvSystemName: string = this.parent.firstPvSystemName;

            if (!firstPvSystemName || !secondPvSystemName) {
              return true;
            }

            return firstPvSystemName.toLowerCase() !== secondPvSystemName.toLowerCase();
          }
        )
        .required(I18n.t(T.register.alert.requiredField)),
    }),

    dsoConsentToCombinePhotovoltaicSystems: Yup.boolean().when(formFields.MULTIPLE_PV, {
      is: (val) => val,
      then: Yup.boolean().oneOf([true]),
    }),
  });

export const configurationPvFormInitial: ConfigurationPvForm = {
  commissioningDate: '',
  inclination: '',
  orientation: '',
  specificYieldPerYear: '',
  peakPower: '',
  pvType: ConfigurationPvType.SPECIFIC_YIELD,
  firstPvSystemName: '',
  multiplePv: false,
  secondPvPeakPower: '',
  secondPvInclination: '',
  secondPvOrientation: '',
  secondPvCommissioningDate: '',
  secondPvSpecificYieldPerYear: '',
  secondPvPvType: ConfigurationPvType.SPECIFIC_YIELD,
  secondPvSystemName: '',
  dsoConsentToCombinePhotovoltaicSystems: false,
};
