import * as React from 'react';

import * as classNames from 'classnames';

import { StatusName } from '+setupTool/store/+setupTool.dictionary';
import { StatusTile, StatusTileProps } from '+shared/components/StatusTile';

import './SetupToolStepStatus.component.scss';

interface OwnProps {
  status: StatusName;
}

type Props = StatusTileProps & OwnProps;

export const SetupToolStepStatus: React.FC<Props> = ({ status, ...statusTileProps }) => (
  <StatusTile
    additionalClass={classNames(`c-setup-tool-step-status--${status}`, 'c-setup-tool-step-status')}
    {...statusTileProps}
  />
);
