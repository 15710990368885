import * as React from 'react';

import { DefaultParagraph } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { Formik } from 'formik';

import { FormInputDate, FormInputMultiselect, FormInputSelect } from '+shared/components';
import { Button, ButtonSize, MainType } from '+shared/components/Button';
import {
  CountryCodeArrayValue,
  NewsCP,
  NewsFormCP,
  optionsValueArray,
  RouteName,
} from '+shared/store/firebase/types';

import { FieldName, NewsFormCustomerHelper } from './NewsFormCustomer.helpers';
import { sendNewsFormValidationSchema } from './NewsFormCustomer.schema';
import { NewsFormCustomerMessages } from './NewsFormCustomerMessages.component';
import { NewsFormCustomerOptionsDetails } from './NewsFormCustomerOptionsDetails.component';

import './NewsFormCustomer.component.scss';

type Props = {
  onSubmit: (data: NewsFormCP) => void;
  isLoading: boolean;
  formError: string;
  isEdit?: boolean;
  news?: NewsCP;
};

export const NewsFormCustomer: React.FC<Props> = ({
  onSubmit,
  news,
  formError,
  isLoading,
  isEdit,
}) => (
  <>
    <Formik<NewsFormCP>
      onSubmit={onSubmit}
      initialValues={
        (news && NewsFormCustomerHelper.mapNewsToForm(news)) || NewsFormCustomerHelper.initialValues
      }
      validationSchema={sendNewsFormValidationSchema}
    >
      {(form) => (
        <form onSubmit={form.handleSubmit}>
          <div
            className={classNames('o-grid', {
              'c-news-form-customer--vertical': isEdit,
            })}
          >
            <div
              className={classNames('o-grid__column o-grid__column--md-4', {
                'c-news-form-customer__section--wide': isEdit,
              })}
            >
              <section>
                <DefaultParagraph className={'c-news-form-customer__section-title'}>
                  {'Options'}
                </DefaultParagraph>
                <FormInputSelect<NewsFormCP, string>
                  className={'c-news-form-customer__news-style-dropdown'}
                  form={form}
                  name={FieldName.STATUS}
                  label={'News style:'}
                  collection={NewsFormCustomerHelper.statusCollection}
                />
                <FormInputDate
                  form={form}
                  className={'c-news-form-customer__date-input'}
                  label={'Expiry date:'}
                  name={FieldName.EXPIRY_DATE_TIMESTAMP}
                />
              </section>
              <section>
                <DefaultParagraph className={'c-news-form-customer__section-title'}>
                  {'Targeting'}
                </DefaultParagraph>
                <FormInputMultiselect
                  className={'c-news-form-customer__countries-dropdown'}
                  form={form}
                  name={FieldName.COUNTRIES}
                  label={'Countries:'}
                  collection={CountryCodeArrayValue}
                  placeholder={'All countires'}
                />
                <FormInputMultiselect
                  form={form}
                  mapper={NewsFormCustomerHelper.getRouteLabel}
                  name={FieldName.INCLUDE_IN_ROUTES}
                  label={'Customer Portal pages:'}
                  collection={Object.values(RouteName)}
                  placeholder={'All pages'}
                />
                {/** TODO rethink if exclude in routes is needed */}
                {/* <FormInputMultiselect
                  form={form}
                  mapper={NewsFormCustomerHelper.getRouteLabel}
                  name={FieldName.EXCLUDE_IN_ROUTES}
                  label={'Hide on pages:'}
                  collection={Object.values(RouteName)}
                /> */}
                <FormInputMultiselect
                  form={form}
                  name={FieldName.OPTIONS}
                  label={'User assets:'}
                  collection={optionsValueArray}
                  placeholder={'Any asset'}
                />
                <NewsFormCustomerOptionsDetails form={form} />
              </section>
            </div>
            <div
              className={classNames('c-news-form-customer__column-separator', {
                'c-news-form-customer__column-separator--horizontal': isEdit,
              })}
            />
            <div
              className={classNames('o-grid__column o-grid__column--md-4', {
                'c-news-form-customer__section--wide': isEdit,
              })}
            >
              <section>
                <DefaultParagraph className={'c-news-form-customer__section-title'}>
                  {'Translations'}
                </DefaultParagraph>
                <NewsFormCustomerMessages form={form} news={news} />
              </section>
            </div>
            <div className={'c-news-form-customer__btn-wrapper'}>
              <Button
                loading={isLoading}
                size={ButtonSize.SMALL}
                label={'Send'}
                mainType={MainType.SUBMIT}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
    {formError}
  </>
);
