import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { set } from 'lodash/fp';

import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { getLeadUpdateQuery, getLeadUpdateQueryStatus } from '+app/+lead/store/+lead.selectors';
import { LeadForm } from '+lead/store/types';
import { DetailListTable } from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { Widget } from '+shared/components/Widget/Widget';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { Lead, LeadAddress, LeadPatchDto } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { LeadOverviewPageActions } from '../../store/+overview.actions';
import {
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityForAddress,
  getNewLeadAddress,
} from '../../store/+overview.selectors';
import { LeadOverviewEditForm } from '../LeadOverviewEditForm';
import {
  getLeadDetailsList,
  isLeadDataMissing,
  LeadDetailsSection,
} from './LeadOverviewBasicDetails.helper';

interface ComponentProps {
  lead: Lead;
  openDeliveryAddress: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

const mapStateToProps = (state: StoreState) => ({
  newLeadAddress: getNewLeadAddress(state),
  productAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityForAddress: getLeadOverviewProductAvailabilityForAddress(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  leadUpdateQuery: getLeadUpdateQuery(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  getProductAvailability: LeadOverviewPageActions.getProductAvailability,
  updateLead: LeadPageActions.updateLead,
  setNewLeadAddress: LeadOverviewPageActions.setNewLeadAddress,
  clearNewLeadAddress: LeadOverviewPageActions.clearNewLeadAddress,
  setNewAddressErrorActive: LeadOverviewPageActions.setNewAddressErrorActive,
  getProductAvailabilityForAddress: LeadOverviewPageActions.getProductAvailabilityForAddress,
});

const LeadOverviewBasicDetailsComponent: React.FC<Props> = ({
  lead,
  leadUpdateQueryStatus,
  leadUpdateQuery,
  openDeliveryAddress,
  productAvailability,
  newLeadAddress,
  productAvailabilityForAddress,
  actions,
}) => {
  const formValues = React.useRef<{ deliveryAddress: Partial<LeadAddress> } | null>(null);

  const {
    toggleModal,
    getProductAvailabilityForAddress,
    setNewLeadAddress,
    updateLead,
    clearNewLeadAddress,
  } = actions;

  React.useEffect(() => {
    return () => {
      actions.clearNewLeadAddress();
      actions.setNewAddressErrorActive(false);
    };
  }, []);

  React.useEffect(() => {
    if (newLeadAddress) {
      if (productAvailabilityForAddress.length === 1) {
        // 1 DSO
        const tso = productAvailabilityForAddress[0].tsos[0];

        if (productAvailabilityForAddress[0].tsos.length === 1) {
          updateLead({
            dso: { id: productAvailabilityForAddress[0].id },
            deliveryAddress: newLeadAddress,
            tso: { name: tso.name },
          });
        } else {
          // TODO: in the future there would need to be a check for multiple TSOs to display a popup
          updateLead({
            dso: { id: productAvailabilityForAddress[0].id },
            deliveryAddress: newLeadAddress,
            tso: null,
          });
        }
      } else if (productAvailabilityForAddress.length > 1) {
        // MULTIPLE DSO
        toggleModal(true, ModalId.DSO_VALIDATION);
      }
    }
  }, [productAvailabilityForAddress]);

  React.useEffect(() => {
    if (leadUpdateQueryStatus.success) {
      clearNewLeadAddress();
      actions.setNewAddressErrorActive(false);
    }
  }, [leadUpdateQueryStatus]);

  const handleDeliveryAddressEdit = (
    { deliveryAddress }: Partial<LeadPatchDto>,
    closeEditForm: () => void
  ) => {
    if (deliveryAddress) {
      setNewLeadAddress(deliveryAddress);
      getProductAvailabilityForAddress(deliveryAddress);
    }
    closeEditForm();
  };

  const onDeliveryAddressFieldChange = (payload: FormInputSubscriptionPayload<LeadForm>) => {
    const { name, value } = payload;

    formValues.current = set(
      name,
      value
    )(
      formValues.current || {
        deliveryAddress: {
          ...(lead && lead.deliveryAddress),
        },
      }
    );
  };

  const onDeliveryAddressFormClose = () => {
    formValues.current = null;
    clearNewLeadAddress();
  };

  return (
    <Widget
      icon={'Account'}
      heading={I18n.t(T.lead.boc._salessolution_.form.personalDetails.headline)}
      dataTestId={'lead-details-table'}
    >
      <DetailListTable
        editItemId={openDeliveryAddress ? LeadDetailsSection.DELIVERY_ADDRESS : undefined}
        list={getLeadDetailsList(lead, true, productAvailability, toggleModal)}
        filterNil={true}
        renderEditComponent={({ index, id }, onClose) => {
          switch (true) {
            case id === LeadDetailsSection.DELIVERY_ADDRESS:
              return (
                <>
                  <FormFieldObserver<LeadForm> onChange={onDeliveryAddressFieldChange}>
                    <LeadOverviewEditForm
                      list={getLeadDetailsList(lead)}
                      index={index}
                      onClose={() => {
                        onDeliveryAddressFormClose();
                        onClose();
                      }}
                      customSubmitAction={(values) => handleDeliveryAddressEdit(values, onClose)}
                    />
                  </FormFieldObserver>
                </>
              );
            default:
              return (
                <LeadOverviewEditForm
                  list={getLeadDetailsList(lead)}
                  index={index}
                  onClose={onClose}
                />
              );
          }
        }}
        isGeneralErrorMsg={isLeadDataMissing(lead)}
        generalErrorMsg={I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryHeader)}
        lead={lead}
        leadUpdateQueryStatus={leadUpdateQueryStatus}
        leadUpdateQuery={leadUpdateQuery}
        hasBottomBorder={false}
        suppressRowValuesInHotjar={true}
      />
    </Widget>
  );
};

export const LeadOverviewBasicDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadOverviewBasicDetailsComponent);
