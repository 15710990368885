import { LanguageCode } from '@sonnen/shared-web';

import { gql, request } from 'graphql-request';
import { defer, Observable } from 'rxjs';
import { CountryCode } from 'src/types/country.type';

import { provideLocale } from '+app/i18n/i18n.helpers';
import { Config } from '+config/config';
import { mapCountryCodeToMarket, Market } from '+utils/market.util';

import { Faq } from './types';

interface FaqPagingQuery {
  faqPagingQuery: {
    items: Faq[];
    pagingInfo: PagingInfo;
  };
}

interface PagingInfo {
  page: number;
  limit: number;
  hasNextPage: boolean;
  hasePreviousPage: boolean;
  pageItemCount: number;
  totalItemCount: number;
  totalPageCount: number;
}

const TARGET_CHANNEL = 'PartnerPortal';
const DEFAULT_PAGE_LIMIT = 20;
const MAX_PAGE_LIMIT = 24;

export const mapCountryCode = (countryCode: CountryCode): string => {
  const market = mapCountryCodeToMarket(countryCode);

  switch (market) {
    case Market.DE:
      return 'deu';
    case Market.AU:
      return 'aus';
    case Market.IT:
      return 'ita';
    case Market.US:
      return 'usa';
    case Market.GME:
    case Market.ES:
      return 'row';
    case Market.CH:
      return 'che';
    case Market.AT:
      return 'aut';
    default:
      return 'row';
  }
};

export const mapLanguageCode = (): LanguageCode => {
  const locale = provideLocale();

  switch (locale) {
    case 'de':
      return LanguageCode.DE;
    case 'it':
      return LanguageCode.IT;
    case 'en-us':
    case 'en-gb':
    default:
      return LanguageCode.EN;
  }
};

const getFaqListPage = (query: {
  countryCode: CountryCode;
  limit: number;
  page: number;
}): Promise<FaqPagingQuery> => {
  if (query.limit > MAX_PAGE_LIMIT) {
    throw new Error(
      `Cannot set query limit higher than: "${MAX_PAGE_LIMIT}". Got: "${query.limit}"`
    );
  }

  return request(
    `${Config.API_GATEWAY_URL}/v1/content/faq?country=${mapCountryCode(query?.countryCode)}`,
    gql`
    {
      faqPagingQuery(
        page: ${query.page},
        limit: ${query.limit},
        language: "${mapLanguageCode()}"
        conditions: [
          { field: channelName, value: "${TARGET_CHANNEL}", operator: CONTAINS },
          { field: language, value: "${mapLanguageCode()}", operator: EQUALS }
      ]) {
        items {
          question
          answer
          topics {
            name
          }
          products {
            name
          }
        }
        pagingInfo {
          page
          limit
          hasNextPage
          hasPreviousPage
          pageItemCount
          totalItemCount
          totalPageCount
        }
      }
    }
  `
  );
};

export const getFaqList = (query: { countryCode: CountryCode }): Observable<FaqPagingQuery> =>
  defer(async () => {
    const aggregatedResult = await getFaqListPage({
      ...query,
      page: 1,
      limit: DEFAULT_PAGE_LIMIT,
    });

    while (aggregatedResult.faqPagingQuery.pagingInfo.hasNextPage) {
      const singlePageResult = await getFaqListPage({
        ...query,
        limit: DEFAULT_PAGE_LIMIT,
        page: ++aggregatedResult.faqPagingQuery.pagingInfo.page,
      });

      aggregatedResult.faqPagingQuery.items = [
        ...aggregatedResult.faqPagingQuery.items,
        ...singlePageResult.faqPagingQuery.items,
      ];

      aggregatedResult.faqPagingQuery.pagingInfo = singlePageResult.faqPagingQuery.pagingInfo;
    }

    return aggregatedResult;
  });
