import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DefaultParagraph, Icofont, PageSubheadline } from '@sonnen/shared-web';

import { goBack } from 'connected-react-router';

import { Button } from '+shared/components/Button';
import { StoreState } from '+shared/store/store.interface';

import { mapActions } from '../../../utils';
import { RegisterCard } from '../../components';
import { isInvalidTokenError } from '../../store/+register.helper';
import { getSubmitRegistrationQuery, getVerifyTokenQuery } from '../../store/+register.selectors';

import './RegisterFailure.component.scss';

const mapStateToProps = (state: StoreState) => ({
  verifyTokenQuery: getVerifyTokenQuery(state),
  submitRegistrationQuery: getSubmitRegistrationQuery(state),
});

const mapDispatchToProps = mapActions({
  goBack,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const RegisterTokenError: React.SFC = () => (
  <>
    <PageSubheadline classNames={'c-register-failure__headline'} smallGap={true} noBorder={true}>
      {I18n.t(T.register.failure.token.headline)}
    </PageSubheadline>
    <DefaultParagraph className={'c-register-failure__paragraph'}>
      {I18n.t(T.register.failure.token.description)}
    </DefaultParagraph>
  </>
);

const RegisterDefaultError: React.SFC<{ goBack: () => void }> = ({ goBack }) => (
  <>
    <PageSubheadline classNames={'c-register-failure__headline'} smallGap={true} noBorder={true}>
      {I18n.t(T.register.failure.default.headline)}
    </PageSubheadline>
    <DefaultParagraph className={'c-register-failure__paragraph'}>
      {I18n.t(T.register.failure.default.description)}
    </DefaultParagraph>
    <Button
      className={'c-register-failure__button c-button--call-to-action'}
      label={I18n.t(T.register.failure.default.button)}
      onClick={goBack}
    />
  </>
);

const RegisterFailureComponent: React.SFC<Props> = ({
  actions,
  verifyTokenQuery,
  submitRegistrationQuery,
}) => {
  const isTokenInvalid = isInvalidTokenError(
    verifyTokenQuery.error || submitRegistrationQuery.error
  );

  return (
    <section className={'c-register-failure'}>
      <RegisterCard>
        <Icofont className={'c-register-failure__icon'} type={'paperplane-error'} />
        {isTokenInvalid ? <RegisterTokenError /> : <RegisterDefaultError goBack={actions.goBack} />}
      </RegisterCard>
    </section>
  );
};

export const RegisterFailure = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterFailureComponent);
