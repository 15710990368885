import React from 'react';

import classNames from 'classnames';

import { InfoFilled, WarningFilled } from '+shared/basicComponents/Icons';

import './Warning.scss';

type WarningProps = {
  textMessageAsHtml: string;
  warningType?: 'info' | 'warning' | 'alert';
};

const Warning: React.FC<WarningProps> = ({ textMessageAsHtml, warningType = 'info' }) => {
  const iconColor = {
    info: '#0B7AC2',
    alert: '#E22929',
    warning: '#FBB000',
  };

  return (
    <div
      className={classNames('warning', {
        warning__info: warningType === 'info',
        warning__warning: warningType === 'warning',
      })}
    >
      <div className="warning__icon">
        {warningType === 'warning' ? (
          <WarningFilled size={'24px'} color={iconColor[warningType]} />
        ) : (
          <InfoFilled color={iconColor[warningType]} />
        )}
      </div>
      <p
        className="warning__text"
        dangerouslySetInnerHTML={{
          __html: textMessageAsHtml,
        }}
      />
    </div>
  );
};

export default Warning;
