import { Query } from '+shared/store/query';

import {
  Site,
  SiteCellDataData,
  SiteChargeLimitsData,
  SiteData,
  SiteLiveState,
  SiteLiveStateData,
  SiteStatistics,
  SiteStatisticsData,
} from './types';
import { SiteLiveStateV2, SiteLiveStateV2Data } from './types/siteLiveStateV2.interface';

export const GET_SITE_QUERY = 'getSiteQuery';
export const GET_SITE_LIVE_STATE_QUERY = 'getSiteLiveQuery';
export const GET_SITE_LIVE_STATE_V2_QUERY = 'getSiteLiveV2Query';
export const GET_SITE_STATISTICS_QUERY = 'getSiteStatisticsQuery';
export const GET_SITE_CHARGE_LIMITS_QUERY = 'getSiteChargeLimitsQuery';
export const GET_SITE_CELL_DATA_QUERY = 'getSiteCellDataQuery';

export interface SiteState {
  site?: Site;
  liveState?: SiteLiveState;
  liveStateV2?: SiteLiveStateV2;
  statistics?: SiteStatistics;
  [GET_SITE_QUERY]: Query<SiteData>;
  [GET_SITE_LIVE_STATE_QUERY]: Query<SiteLiveStateData>;
  [GET_SITE_LIVE_STATE_V2_QUERY]: Query<SiteLiveStateV2Data>;
  [GET_SITE_STATISTICS_QUERY]: Query<SiteStatisticsData>;
  [GET_SITE_CHARGE_LIMITS_QUERY]: Query<SiteChargeLimitsData>;
  [GET_SITE_CELL_DATA_QUERY]: Query<SiteCellDataData>;
}

export const initialState: SiteState = {
  site: undefined,
  liveState: undefined,
  liveStateV2: undefined,
  statistics: undefined,
  [GET_SITE_QUERY]: {},
  [GET_SITE_LIVE_STATE_QUERY]: {},
  [GET_SITE_LIVE_STATE_V2_QUERY]: {},
  [GET_SITE_STATISTICS_QUERY]: {},
  [GET_SITE_CHARGE_LIMITS_QUERY]: {},
  [GET_SITE_CELL_DATA_QUERY]: {},
};
