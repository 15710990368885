import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNotNil } from '+app/utils';
import { companyCoordinatorRoles } from '+lead/store/+lead.helper';
import { ListTableHeaderItem } from '+shared/components';
import { checkRoleRestriction } from '+shared/components/RestrictedToRoles';
import { UserRole } from '+shared/store/user';

export const getLeadListTableHeaders = (userRoles: UserRole[]): ListTableHeaderItem[] => {
  const isCompanyCoordinator = checkRoleRestriction(userRoles, companyCoordinatorRoles);

  return [
    { label: I18n.t(T.lead.list._salessolution_.name), key: 'name' },
    { label: I18n.t(T.lead.list._salessolution_.location), key: 'location' },
    { label: I18n.t(T.lead.list._salessolution_.creationDate), key: 'creation-date' },
    isCompanyCoordinator
      ? { label: I18n.t(T.lead.list._salessolution_.leadOwner), key: 'lead-owner' }
      : null,
    { label: I18n.t(T.lead.list._salessolution_.status), key: 'status' },
  ].filter(isNotNil);
};
