import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader, Modal, ModalLayout, WarningText, WarningTextSize } from '@sonnen/shared-web';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash/fp';

import { LeadModalLeadName } from '+app/+lead/components/LeadModalLeadName';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { getLeadUpdateQueryStatus } from '+app/+lead/store/+lead.selectors';
import { mapActions } from '+app/utils';
import { FormErrorBanner, FormInputRadioGroup } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { getOpenModalId, LayoutActions, ModalId } from '+shared/store/layout';
import { Lead, LeadDso } from '+shared/store/lead/types';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';

import { LeadOverviewPageActions } from '../../store/+overview.actions';
import { mapProductAvailabilityToDsoList } from '../../store/+overview.helper';
import {
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityForAddress,
  getNewLeadAddress,
} from '../../store/+overview.selectors';

import './LeadOverviewDsoValidationModal.component.scss';

interface ComponentProps {
  lead: Lead;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

const mapStateToProps = (state: StoreState) => ({
  openModalId: getOpenModalId(state),
  leadProductAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityForAddress: getLeadOverviewProductAvailabilityForAddress(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  newLeadAddress: getNewLeadAddress(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  setDsoChoicePostponed: LeadOverviewPageActions.setDsoChoicePostponed,
  clearQuery: QueryActions.init,
  setLeadDso: LeadPageActions.setLeadDso,
  updateLead: LeadPageActions.updateLead,
  setNewAddressErrorActive: LeadOverviewPageActions.setNewAddressErrorActive,
});

export interface LeadMultipleDsoForm {
  dsoId: string;
}

const mapDsoDetails = (dso: LeadDso) => ({
  label: dso.name,
  value: dso.id,
});

export const LeadOverviewDsoValidationModalComponent: React.FC<Props> = ({
  lead,
  openModalId,
  actions,
  leadProductAvailability,
  productAvailabilityForAddress,
  newLeadAddress,
  leadUpdateQueryStatus,
}) => {
  const productAvailability = newLeadAddress
    ? productAvailabilityForAddress
    : leadProductAvailability;
  const dsoList = mapProductAvailabilityToDsoList(productAvailability);
  const initialDsoValue = !isEmpty(dsoList) ? dsoList[0].id : '';

  const isPending = leadUpdateQueryStatus.pending;
  const isError = leadUpdateQueryStatus.error;

  React.useEffect(() => {
    if (!leadUpdateQueryStatus.success && openModalId !== ModalId.DSO_VALIDATION) {
      actions.toggleModal(true, ModalId.DSO_VALIDATION);
    }
  }, []);

  const onModalClose = () => {
    if (!lead.dso) {
      actions.setDsoChoicePostponed(lead.id);
    }
    if (newLeadAddress) {
      actions.setNewAddressErrorActive(true);
    }
    actions.toggleModal(false);
  };

  const onSubmit = (formValues: LeadMultipleDsoForm) => {
    const dso = dsoList.find((x) => x.id === formValues.dsoId);
    const deliveryAddress = newLeadAddress || lead.deliveryAddress;
    if (dso && deliveryAddress) {
      // TODO: handle two separate modals for multiple DSO and TSO
      if (productAvailability[0].tsos.length === 1) {
        actions.updateLead({
          dso: { id: dso.id },
          deliveryAddress,
          tso: {
            name: productAvailability[0].tsos[0].name,
          },
        });
      } else {
        actions.updateLead({
          dso: { id: dso.id },
          deliveryAddress,
          tso: null,
        });
      }
    }
  };

  return (
    <Modal
      isOpen={openModalId === ModalId.DSO_VALIDATION}
      onClose={onModalClose}
      size={'medium'}
      className={'c-lead-overview-dso-validation-modal'}
    >
      {isPending && <Loader className={'c-lead-overview-dso-validation-modal__loader'} />}
      <div className={'c-lead-overview-dso-validation-modal__content'}>
        <Formik
          initialValues={{ dsoId: initialDsoValue }}
          onSubmit={onSubmit}
          render={(form) => (
            <Form className={'c-lead-overview-dso-validation-modal__form'}>
              <ModalLayout
                title={I18n.t(T.lead.overview._salessolution_.multipleDsoModal.title)}
                subtitle={I18n.t(T.lead.overview._salessolution_.multipleDsoModal.subtitle)}
                footer={
                  <div className={'c-lead-overview-dso-validation-modal__buttons'}>
                    {!isPending ? (
                      <div className={'c-lead-overview-dso-validation-modal__buttons-wrapper'}>
                        <Button
                          label={I18n.t(
                            T.lead.overview._salessolution_.multipleDsoModal.buttons.discard
                          )}
                          className={'c-lead-overview-dso-validation-modal__footer-button'}
                          onClick={() => onModalClose()}
                          type={ButtonType.TERTIARY}
                        />
                        <Button
                          label={I18n.t(
                            T.lead.overview._salessolution_.multipleDsoModal.buttons.save
                          )}
                          size={ButtonSize.MEDIUM}
                          mainType={MainType.SUBMIT}
                          disabled={!form.values.dsoId}
                          status={!form.values.dsoId ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                        />
                      </div>
                    ) : null}
                  </div>
                }
              >
                <LeadModalLeadName lead={{ ...lead, deliveryAddress: newLeadAddress }} />
                <div className={'c-lead-overview-dso-validation-modal__radio-group'}>
                  <FormInputRadioGroup
                    name={'dsoId'}
                    form={form}
                    collection={dsoList.map((dso) => mapDsoDetails(dso))}
                    isVertical={true}
                  />
                </div>

                <div className={'c-lead-overview-dso-validation-modal__info'}>
                  {newLeadAddress ? (
                    <WarningText
                      text={I18n.t(
                        T.lead.overview._salessolution_.multipleDsoModal.newAddressWarning
                      )}
                      size={WarningTextSize.MEDIUM}
                    />
                  ) : (
                    <>
                      <Icon.Info className={'c-lead-overview-dso-validation-modal__info-icon'} />
                      {I18n.t(T.lead.overview._salessolution_.multipleDsoModal.info)}
                    </>
                  )}
                </div>
                <div className={'c-lead-overview-dso-validation-modal__error'}>
                  <FormErrorBanner
                    isVisible={isError}
                    error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
                  />
                </div>
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

export const LeadOverviewDsoValidationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadOverviewDsoValidationModalComponent);
