import { AreaChartView as KanvaAreaChartView, AxisView as KanvaAxisView, BarChartView as KanvaBarChartView, ChartGridView as KanvaChartGridView, LegendView as KanvaLegendView, LineChartView as KanvaLineChartView, PieChartView as KanvaPieChartView, ChartZoomView as KanvaChartZoomView, } from '@kanva/charts';
import { createReactView } from '@kanva/react';
export var AxisView = createReactView(KanvaAxisView);
export var ChartGridView = createReactView(KanvaChartGridView);
export var AreaChartView = createReactView(KanvaAreaChartView);
export var PieChartView = createReactView(KanvaPieChartView);
export var BarChartView = createReactView(KanvaBarChartView);
export var LegendView = createReactView(KanvaLegendView);
export var LineChartView = createReactView(KanvaLineChartView);
export var ChartZoomView = createReactView(KanvaChartZoomView);
