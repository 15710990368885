import * as React from 'react';

import CustomerWithFlatDirect from '+assets/img/customerWithFlatDirect.svg';

import './LeadConfigurationFlatDirectIllustration.component.scss';

export const LeadConfigurationFlatDirectIllustration = () => (
  <div className={'c-lead-configuration-flat-direct-illustration'}>
    <div className={'c-lead-configuration-flat-direct-illustration__image-wrapper'}>
      <img
        src={CustomerWithFlatDirect}
        className={'c-lead-configuration-flat-direct-illustration__image'}
        alt={'Customer with flat direct'}
      />
    </div>
  </div>
);
