import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DS, Icon, Switch } from '@sonnen/shared-web';

import { Form, FormikProps } from 'formik';
import { isNil } from 'lodash';
import * as uuid from 'uuid/v4';

import { BatteryOperatingModeView } from '+customer-battery/containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import {
  TariffType,
  TariffWindow,
  tariffWindowManipulator,
} from '+customer-battery/store/battery-tariff';
import {
  FormErrorBanner,
  FormInput,
  FormInputRadioGroup,
  HorizontalPositionProps,
  TooltipHoverable,
} from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';

import { zeroPad } from './BatteryTimeOfUseForm.helper';

import './BatteryTimeOfUseForm.component.scss';

interface Props {
  form: FormikProps<any>;
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
  tariffWindows: TariffWindow[];
}

export const BatteryTimeOfUseForm: React.FC<Props> = ({
  form,
  setBatteryOperatingModeView,
  tariffWindows,
}) => {
  React.useEffect(() => {
    form.setFieldValue(
      'thresholdPMaxStatus',
      form.values.thresholdPMax !== 0 && !isNil(form.values.thresholdPMax)
    );
  }, [form.values.type]);

  React.useEffect(() => {
    if (isNil(form.values.thresholdPMax)) {
      form.setFieldValue('thresholdPMaxStatus', false);
      form.setFieldValue('thresholdPMax', 0);
    }
  }, [form.values.type]);

  React.useEffect(() => {
    setTariffCollisionFound(
      tariffWindowManipulator.isOverlappingTariffWindowRangeNew(tariffWindows, { ...form.values })
    );
    setTariffWindowIsTooShort(tariffWindowManipulator.isTariffWindowLongEnough({ ...form.values }));
  });

  React.useEffect(() => {
    form.setFieldValue(
      'start',
      `${zeroPad(form.values.startHour, 2)}:${zeroPad(form.values.startMinutes, 2)}`
    );
  }, [form.values.startHour, form.values.startMinutes]);

  React.useEffect(() => {
    form.setFieldValue(
      'stop',
      `${zeroPad(form.values.stopHour, 2)}:${zeroPad(form.values.stopMinutes, 2)}`
    );
  }, [form.values.stopHour, form.values.stopMinutes]);

  const [tariffChargeState, setTariffChargeState] = React.useState(
    form.values.thresholdPMax !== 0 && !isNil(form.values.thresholdPMax)
  );
  const [tariffCollisionFound, setTariffCollisionFound] = React.useState(false);
  const [tariffWindowIsTooShort, setTariffWindowIsTooShort] = React.useState(false);

  return (
    <Form>
      <div className={'c-battery-time-of-use-form__container'}>
        <FormInputRadioGroup
          className={'c-battery-time-of-use-form__radio-group'}
          name="type"
          form={form}
          text={I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.chooseType)}
          isVertical={true}
          collection={[
            {
              label: I18n.t(T.customerSingle.batteryDetails.timeOfUse.offPeak),
              value: TariffType.OFF_PEAK,
            },
            {
              label: I18n.t(T.customerSingle.batteryDetails.timeOfUse.peak),
              value: TariffType.PEAK,
            },
          ]}
        />

        {form.values.type === TariffType.OFF_PEAK && (
          <div className="c-battery-time-of-use-form__edit-charge-state">
            <span className="c-battery-time-of-use-form__edit-charge-state-description">
              {I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.chargeFromGrid)}
            </span>
            <Switch
              name="thresholdPMaxStatus"
              onToggle={() => {
                form.setFieldValue('thresholdPMaxStatus', !tariffChargeState);
                setTariffChargeState(!tariffChargeState);
              }}
              isChecked={tariffChargeState}
            />
          </div>
        )}

        {tariffChargeState && form.values.type === TariffType.OFF_PEAK && (
          <div className="c-battery-time-of-use-form__edit-charge-value-container">
            <TooltipHoverable
              key={uuid()}
              title={I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.thresholdToolTip)}
              className={'c-battery-time-of-use-form__tooltip'}
              bubbleSide={'bottom'}
              horizontalPosition={HorizontalPositionProps.MIDDLE}
            >
              <div className="c-battery-time-of-use-form__edit-charge-value-label">
                <p>{I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.chargeFromGridLabel)}</p>
                <Icon.InfoCircle
                  className={'c-tariff-window-list-item__edit-info-icon'}
                  color={'white'}
                  secondaryColor={'#0B7AC2'}
                />
              </div>
            </TooltipHoverable>

            <FormInput name="thresholdPMax" id="thresholdPMax" type={'number'} form={form} />
          </div>
        )}

        <div className={'c-battery-time-of-use-form__time-window-container'}>
          <p className={'c-battery-time-of-use-form__time-window-header'}>
            {I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.setTimeSpan)}
            <span className={'c-battery-time-of-use-form__time-window-header-detail'}>
              (0-23
              {I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.hourShortVersion)}) : (0-59
              {I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.minuteShortVersion)})
            </span>
          </p>

          <div className={'c-battery-time-of-use-form__time-window'}>
            <p className={'c-battery-time-of-use-form__time-window-label'}>
              {I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.windowStart)}
            </p>
            <FormInput
              name={'startHour'}
              id={'startHour'}
              form={form}
              type={'number'}
              className={'c-battery-time-of-use-form__time-window-input'}
            />
            <p className={'c-battery-time-of-use-form__time-window-separation'}>:</p>
            <FormInput
              name={'startMinutes'}
              id={'startMinutes'}
              form={form}
              type={'number'}
              className={'c-battery-time-of-use-form__time-window-input'}
            />
          </div>

          <div className={'c-battery-time-of-use-form__time-window'}>
            <p className={'c-battery-time-of-use-form__time-window-label'}>
              {I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.WindowStop)}
            </p>
            <FormInput
              name={'stopHour'}
              id={'stopHour'}
              form={form}
              type={'number'}
              className={'c-battery-time-of-use-form__time-window-input'}
            />
            <p className={'c-battery-time-of-use-form__time-window-separation'}>:</p>
            <FormInput
              name={'stopMinutes'}
              id={'stopMinutes'}
              form={form}
              type={'number'}
              className={'c-battery-time-of-use-form__time-window-input'}
            />
          </div>

          <FormErrorBanner
            isVisible={tariffCollisionFound}
            error={I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.collisionDetected)}
          />

          <FormErrorBanner
            isVisible={
              tariffWindowIsTooShort &&
              form.values.startHour !== '' &&
              form.values.startMinutes !== '' &&
              form.values.stopHour !== '' &&
              form.values.stopMinutes !== ''
            }
            error={I18n.t(
              T.customerSingle.batteryDetails.timeOfUse.form.validation.tariffWindowIsToShort
            )}
          />
        </div>
      </div>
      <div className={'c-battery-operating-mode-operation__footer'}>
        <Button
          label={I18n.t(T.general.basicActions.cancel)}
          type={ButtonType.TERTIARY}
          size={ButtonSize.SMALL}
          onClick={() => setBatteryOperatingModeView(BatteryOperatingModeView.TIME_OF_USE_PREVIEW)}
        />
        <Button
          label={I18n.t(T.customerSingle.batteryDetails.timeOfUse.action.saveAllChanges)}
          mainType={DS.MainType.SUBMIT}
          type={ButtonType.PRIMARY}
          size={ButtonSize.SMALL}
          disabled={tariffCollisionFound || tariffWindowIsTooShort}
          status={
            tariffCollisionFound || tariffWindowIsTooShort
              ? ButtonStatus.DISABLED
              : ButtonStatus.ENABLED
          }
        />
      </div>
    </Form>
  );
};
