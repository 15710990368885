var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as mime from 'mime';
import CombinedStream from 'combined-stream';
import { Readable, Writable } from 'stream';
export var SUPPORTS_BROWSER_FORM_DATA = (function () {
    try {
        return !({} instanceof FormData);
    }
    catch (_a) {
        return false;
    }
})();
export var isBrowserFormData = function (value) {
    return SUPPORTS_BROWSER_FORM_DATA && value instanceof FormData;
};
export var isBlob = function (value) {
    try {
        return value instanceof Blob;
    }
    catch (_a) {
        return false;
    }
};
export var readBlob = function (blob) {
    var reader = new FileReader();
    return new Promise(function (resolve, reject) {
        var onEnd = function (e) {
            reader.removeEventListener('loadend', onEnd);
            if (e.error) {
                reject(e.error);
            }
            else {
                resolve(Buffer.from(reader.result));
            }
        };
        reader.addEventListener('loadend', onEnd);
        reader.readAsArrayBuffer(blob);
    });
};
export var getFileMeta = function (value, existingMeta) {
    var anyValue = value;
    var isHttpRequest = anyValue.readable && anyValue.hasOwnProperty('httpVersion');
    var path = ((existingMeta === null || existingMeta === void 0 ? void 0 : existingMeta.filename) || (existingMeta === null || existingMeta === void 0 ? void 0 : existingMeta.filepath)
        || anyValue.name
        || anyValue.path
        || (isHttpRequest && anyValue.client._httpMessage.path)
        || '');
    var filename = path.substring(Math.max(path.lastIndexOf('/'), path.lastIndexOf('\\')) + 1);
    var contentType = anyValue.type
        || mime.getType(path)
        || (isHttpRequest && anyValue.headers['content-type'])
        || CFormData.DEFAULT_CONTENT_TYPE;
    return __assign(__assign({ contentType: contentType, knownLength: anyValue.size }, existingMeta), { filename: filename });
};
var CFormData = (function () {
    function CFormData(data) {
        var _this = this;
        this.data = new Map();
        if (!data) {
            return;
        }
        if (CFormData.isFormData(data)) {
            data.forEach(function (value, key) { return _this.append(key, value); });
            return;
        }
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                this.append(key, data[key]);
            }
        }
    }
    CFormData.from = function (data, _a) {
        var forceImplementation = (_a === void 0 ? {} : _a).forceImplementation;
        if ((isBrowserFormData(data) && forceImplementation !== 'custom')
            || (data instanceof CFormData && forceImplementation !== 'native')) {
            return data;
        }
        var formData = new CFormData(data);
        if (!SUPPORTS_BROWSER_FORM_DATA || forceImplementation === 'custom') {
            return formData;
        }
        var browserFormData = new FormData();
        formData.forEach(function (value, name) { return browserFormData.append(name, value); });
        return browserFormData;
    };
    CFormData.isFormData = function (data) {
        return isBrowserFormData(data) || data instanceof CFormData;
    };
    CFormData.prototype.append = function (name, value, meta) {
        this.insert(name, value, true, meta);
    };
    CFormData.prototype.delete = function (name) {
        this.data.delete(name);
    };
    CFormData.prototype.getAll = function (name) {
        var _a;
        return ((_a = this.data.get(name)) === null || _a === void 0 ? void 0 : _a.map(function (e) { return e.value; })) || [];
    };
    CFormData.prototype.set = function (name, value, meta) {
        this.insert(name, value, false, meta);
    };
    CFormData.prototype.get = function (name) {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.data.get(name)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : null;
    };
    CFormData.prototype.has = function (name) {
        return this.data.has(name);
    };
    CFormData.prototype.forEach = function (callbackfn) {
        var _this = this;
        this.data.forEach(function (value, key) { return value.forEach(function (entry) { return callbackfn(entry.value, key, _this); }); });
    };
    CFormData.prototype.getBoundary = function () {
        var boundary = this.boundary;
        if (!boundary) {
            boundary = '--------------------------';
            for (var i = 0; i < 24; i++) {
                boundary += Math.floor(Math.random() * 10).toString(16);
            }
            this.boundary = boundary;
        }
        return boundary;
    };
    ;
    CFormData.prototype.getHeaders = function () {
        return {
            'content-type': 'multipart/form-data; boundary=' + this.getBoundary(),
        };
    };
    CFormData.prototype.pipe = function (writable) {
        var _this = this;
        var stream = CombinedStream.create();
        this.data.forEach(function (entries, name) {
            var _loop_1 = function (entry) {
                stream.append(_this.multiPartHeader(name, entry === null || entry === void 0 ? void 0 : entry.meta));
                var value = entry.value;
                if (value instanceof Readable) {
                    stream.append(writable);
                }
                else if (isBlob(value)) {
                    stream.append(function (next) { return readBlob(value).then(next); });
                }
                else {
                    stream.append(value);
                }
                stream.append(CFormData.LINE_BREAK);
            };
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                _loop_1(entry);
            }
        });
        stream.append(this.lastBoundary());
        stream.pipe(writable);
    };
    CFormData.prototype.getBuffer = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var buffer = Buffer.alloc(0);
            var writable = new Writable({
                write: function (chunk, encoding, callback) {
                    buffer = Buffer.concat([buffer, Buffer.from(chunk, encoding)]);
                    callback();
                }
            });
            writable.on('error', reject);
            writable.on('finish', function () { return resolve(buffer); });
            _this.pipe(writable);
        });
    };
    CFormData.prototype.toString = function () {
        return '[object FormData]';
    };
    CFormData.prototype.insert = function (name, value, append, meta) {
        if (value === undefined) {
            return;
        }
        if (value instanceof Date) {
            value = value.toISOString();
        }
        if (Array.isArray(value)) {
            for (var index = 0; index < value.length; index++) {
                this.insert(name + "[" + index + "]", value[index], append);
            }
            return;
        }
        if (typeof value === 'object' && !isBlob(value)) {
            for (var objectKey in value) {
                if (!value.hasOwnProperty(objectKey) || value[objectKey] === undefined) {
                    continue;
                }
                this.append(name + "[" + objectKey + "]", value[objectKey]);
            }
            return;
        }
        if (isBlob(value)) {
            meta = getFileMeta(value, meta);
        }
        if (!append) {
            this.data.set(name, [{ value: value, meta: meta }]);
            return;
        }
        var existingEntries = this.data.get(name);
        var newEntry = { value: value, meta: meta };
        this.data.set(name, existingEntries ? __spreadArrays(existingEntries, [newEntry]) : [newEntry]);
    };
    CFormData.prototype.multiPartHeader = function (name, meta) {
        if (meta === void 0) { meta = {}; }
        if (typeof meta.header === 'string') {
            return meta.header;
        }
        var contentDisposition = meta.filename ? "filename=\"" + meta.filename + "\"" : '';
        var contentType = meta.contentType;
        var contents = '';
        var headers = {
            'Content-Disposition': ['form-data', "name=\"" + name + "\"", contentDisposition],
            'Content-Type': [contentType],
        };
        if (typeof meta.header === 'object') {
            Object.assign(headers, meta.header);
        }
        for (var prop in headers) {
            var header = headers[prop];
            if (!headers.hasOwnProperty(prop) || !header) {
                continue;
            }
            if (!Array.isArray(header)) {
                header = [header];
            }
            header = header.filter(Boolean);
            if (header.length) {
                contents += prop + ': ' + header.join('; ') + CFormData.LINE_BREAK;
            }
        }
        return '--' + this.getBoundary() + CFormData.LINE_BREAK + contents + CFormData.LINE_BREAK;
    };
    ;
    CFormData.prototype.lastBoundary = function () {
        return '--' + this.getBoundary() + '--' + CFormData.LINE_BREAK;
    };
    CFormData.LINE_BREAK = '\r\n';
    CFormData.DEFAULT_CONTENT_TYPE = 'application/octet-stream';
    return CFormData;
}());
export { CFormData };
