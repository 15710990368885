import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash/fp';

import { DetailListType } from '+shared/components';
import { Battery } from '+shared/store/battery';
import { formatDate } from '+utils/format.util';
import { trim } from '+utils/string.util';

export const factorizeInstallationLocation = ({
  installationStreet,
  installationPostalCode,
  installationCity,
  installationCountryCode,
}: Battery) =>
  trim`${installationStreet}` +
  trim`${installationPostalCode && `,\n${installationPostalCode}`}` +
  `${installationCity ? ` ${installationCity}` : ''}` +
  `${installationCountryCode ? `, ${installationCountryCode}` : ''}`;

export const factorizeInstaller = ({
  installerName,
  installerStreet,
  installerPostalCode,
  installerCity,
  installerCountry,
}: Battery): string =>
  trim`${installerName}` +
  `${installerStreet ? `\n${installerStreet}` : ''}` +
  `${installerPostalCode ? `\n${installerPostalCode}` : ''}` +
  `${installerCity ? ` ${installerCity}` : ''}` +
  trim`${installerCountry && `, ${installerCountry}`}`;

export const factorizeBatteryInstallationDetailList = (battery: Battery): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.installation.installationLocation),
    value: factorizeInstallationLocation(battery),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.installation.installationDate),
    value: !isNil(battery.installationDate)
      ? formatDate(battery.installationDate)
      : I18n.t(T.warning.missingInstallationDate),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.installation.installer),
    value: factorizeInstaller(battery),
  },
];
