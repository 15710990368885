"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var httpResponseHeaders_1 = require("../httpResponseHeaders");
var httpRequestError_1 = require("../httpRequestError");
var helpers_1 = require("../helpers");
var formData_1 = require("../formData");
exports.fetchRequestHandler = function (fetchRequestHandlerOptions) {
    if (fetchRequestHandlerOptions === void 0) { fetchRequestHandlerOptions = {}; }
    if ((typeof AbortController === 'undefined') || (typeof fetch === 'undefined') || (typeof Headers === 'undefined')) {
        throw new Error('Fetch API and/or AbortController are not present. ' +
            'Prior to using fetchRequestHandler, make sure that you included necessary polyfills, ' +
            'which are required for old browsers and Node.js.');
    }
    return function (requestOptions) { return __awaiter(void 0, void 0, void 0, function () {
        var abortController, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    abortController = new AbortController();
                    return [4, new Promise(function (resolve, reject) {
                            var _a;
                            var timer = setTimeout(function () {
                                abortController.abort();
                                reject(new httpRequestError_1.HttpRequestError(requestOptions, "Request timed out after " + requestOptions.timeout + "ms."));
                            }, requestOptions.timeout);
                            var isFormData = formData_1.CFormData.isFormData(requestOptions.body);
                            var body = isFormData
                                ? formData_1.CFormData.from(requestOptions.body, { forceImplementation: 'native' })
                                : requestOptions.body;
                            var formDataHeaderOverride = isFormData ? { 'content-type': undefined } : undefined;
                            fetch(requestOptions.url, __assign(__assign(__assign({}, fetchRequestHandlerOptions.defaultRequestOptions), requestOptions), { body: body, headers: helpers_1.sanitizeHeaders((_a = fetchRequestHandlerOptions.defaultRequestOptions) === null || _a === void 0 ? void 0 : _a.headers, requestOptions.headers, formDataHeaderOverride), signal: abortController.signal })).then(function (response) {
                                clearTimeout(timer);
                                resolve(response);
                            }, function (error) {
                                clearTimeout(timer);
                                reject(error);
                            });
                        })];
                case 1:
                    response = _a.sent();
                    return [2, {
                            arrayBuffer: function () { return response.arrayBuffer(); },
                            text: function () { return response.text(); },
                            ok: response.ok,
                            headers: new httpResponseHeaders_1.HttpResponseHeaders(response.headers),
                            redirected: response.redirected,
                            status: response.status,
                            statusText: response.statusText,
                            url: response.url,
                            abort: function () { return abortController.abort(); },
                        }];
            }
        });
    }); };
};
