import { Action } from 'redux';

import { QUERY_ACTIONS, QueryActions } from './query.actions';
import { QueryStatus } from './query.state';

type QueryReducerOpts = {
  suppressResponse: boolean;
};

export const queryReducer = <S extends object>(
  state: S,
  reducerAction: Action,
  queryNames: Array<keyof S>,
  opts: Partial<QueryReducerOpts> = {}
) => {
  const action = reducerAction as QueryActions;

  if (!(queryNames as string[]).includes(action.queryName)) {
    return state;
  }

  switch (action.type) {
    case QUERY_ACTIONS.INIT:
      return {
        ...(state as any),
        [action.queryName]: {},
      };
    case QUERY_ACTIONS.PENDING:
      return {
        ...(state as any),
        [action.queryName]: {
          ...state[action.queryName],
          status: QueryStatus.PENDING,
        },
      };
    case QUERY_ACTIONS.SUCCESS:
      return {
        ...(state as any),
        [action.queryName]: {
          status: QueryStatus.SUCCESS,
          response: opts.suppressResponse ? undefined : action.response,
        },
      };
    case QUERY_ACTIONS.FAILURE:
      return {
        ...(state as any),
        [action.queryName]: {
          status: QueryStatus.FAILURE,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
