import React from 'react';

export const SonnenLogo = () => (
  <svg width="104" height="32" viewBox="0 0 104 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.2398 12.1181C53.8612 12.1181 51.8955 14.0009 51.8955 16.4295C51.8955 18.8574 53.8612 20.7404 56.2398 20.7404C58.6184 20.7404 60.584 18.8574 60.584 16.4295C60.584 14.0009 58.6184 12.1181 56.2398 12.1181ZM56.2319 18.7916C55.0502 18.7916 53.9603 17.9244 53.9603 16.4374C53.9603 14.951 55.0502 14.0837 56.2319 14.0837C57.4124 14.0837 58.5028 14.951 58.5028 16.4374C58.5028 17.9244 57.4124 18.7916 56.2319 18.7916Z"
      fill="white"
    />
    <path
      d="M99.3049 12.1181C97.2565 12.1181 95.2495 13.3648 95.2495 15.8348V20.5591H97.3891V16.0987C97.3891 14.6864 98.347 14.0837 99.3213 14.0837C100.296 14.0837 101.229 14.6864 101.229 16.0987V20.5591H103.368V15.8348C103.368 13.3648 101.345 12.1181 99.3049 12.1181Z"
      fill="white"
    />
    <path
      d="M67.0542 12.1181C65.0058 12.1181 62.9988 13.3648 62.9988 15.8348V20.5591H65.1378V16.0987C65.1378 14.6864 66.0963 14.0837 67.0706 14.0837C68.0455 14.0837 68.9784 14.6864 68.9784 16.0987V20.5591H71.1175V15.8348C71.1175 13.3648 69.094 12.1181 67.0542 12.1181Z"
      fill="white"
    />
    <path
      d="M77.9128 12.1181C75.8645 12.1181 73.8574 13.3648 73.8574 15.8348V20.5591H75.997V16.0987C75.997 14.6864 76.9549 14.0837 77.9292 14.0837C78.9042 14.0837 79.8371 14.6864 79.8371 16.0987V20.5591H81.9761V15.8348C81.9761 13.3648 79.9527 12.1181 77.9128 12.1181Z"
      fill="white"
    />
    <path
      d="M88.6823 12.1178C86.2775 12.1178 84.3909 13.9946 84.3909 16.425C84.3909 18.8553 86.2191 20.7406 88.6488 20.7406C90.2915 20.7406 91.548 19.9562 92.2863 18.9143C92.2967 18.8991 90.7865 17.9412 90.7787 17.9528C90.3444 18.5615 89.6243 18.9551 88.7236 18.9551C87.5048 18.9551 86.7087 18.1528 86.4764 17.1359H92.7772C92.7772 17.1359 92.8349 16.7754 92.8349 16.425C92.8349 13.9795 91.062 12.1178 88.6823 12.1178ZM86.5104 15.593C86.7883 14.5354 87.6739 13.9418 88.6737 13.9418C89.6425 13.9418 90.5505 14.5135 90.837 15.593H86.5104Z"
      fill="white"
    />
    <path
      d="M47.4526 15.6941L45.5983 15.2034C45.2084 15.097 45.0059 14.8184 45.0059 14.5569C45.0059 14.1476 45.4498 13.8927 46.1121 13.8927C46.873 13.8927 47.8528 14.195 48.2688 14.9188L49.681 13.9839C49.0497 12.7232 47.6083 12.1181 46.2216 12.1181C44.5692 12.1181 42.9879 12.9525 42.9879 14.5483C42.9879 15.8007 43.7901 16.5122 45.2315 16.9051L47.0407 17.4019C47.5315 17.5327 47.7389 17.8071 47.7389 18.1409C47.7389 18.5667 47.2686 18.9505 46.4162 18.9505C45.5047 18.9505 44.4926 18.5418 44.0535 17.7085L42.6218 18.6567C43.3151 20.0804 44.8423 20.7348 46.3074 20.7348C48.1775 20.7348 49.7515 19.7367 49.7515 18.1488C49.7515 16.8315 48.9487 16.095 47.4526 15.6941Z"
      fill="white"
    />
    <path
      d="M16.63 0C7.79344 0 0.630005 7.16343 0.630005 16C0.630005 24.8366 7.79344 32 16.63 32C25.4666 32 32.63 24.8366 32.63 16C32.63 7.16344 25.4666 0 16.63 0ZM17.2619 30.8337C9.54379 30.8337 3.28701 24.5769 3.28701 16.8588C3.28701 9.14062 9.54379 2.88377 17.2619 2.88377C24.9801 2.88377 31.2369 9.14062 31.2369 16.8588C31.2369 24.5769 24.9801 30.8337 17.2619 30.8337Z"
      fill="white"
    />
  </svg>
);
