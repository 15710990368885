import React, { createContext, useContext, useEffect, useState } from 'react';

import { useUserIdentity } from '+shared/store/user';
import { useLocaleContext } from '+utils/react/locale.provider';

import { BasicAnalyticsPageData } from '../adobeAnalytics.type';

type AnalyticsData = {
  basicAnalyticsPageData: BasicAnalyticsPageData;
};

const basicAnalyticsData: AnalyticsData = {
  basicAnalyticsPageData: {
    userId: '',
    userRole: '',
    market: '',
    language: '',
    platform: 'Partner Portal',
    cvp: 'sonnen',
    siteKey: window.location.hostname,
  },
};

export const AdobeAnalyticsContext = createContext(basicAnalyticsData);

export const AdobeAnalyticsContextProvider: React.FC = ({ children }) => {
  const { userProfileId, userProfileCountryCode, userProfileRoles } = useUserIdentity();
  const { locale } = useLocaleContext();

  const [basicAnalyticsPageData, setBasicAnalyticsPageData] = useState<BasicAnalyticsPageData>(
    basicAnalyticsData.basicAnalyticsPageData
  );

  useEffect(() => {
    setBasicAnalyticsPageData({
      ...basicAnalyticsPageData,
      userId: userProfileId,
      userRole: userProfileRoles.toString(),
      market: userProfileCountryCode,
      language: locale,
    });
  }, [userProfileId, locale]);

  return (
    <AdobeAnalyticsContext.Provider value={{ basicAnalyticsPageData }}>
      {children}
    </AdobeAnalyticsContext.Provider>
  );
};

export const useAdobeAnalyticsContext = () => useContext(AdobeAnalyticsContext);
