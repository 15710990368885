import * as React from 'react';

import { CurrencyUnit } from '+app/App.constants';
import { fixedWithoutRounding } from '+lead/+impactAnalysis/utils/formatters/attribute/AttributeFormatter.helpers';
import { addDecimalSeparators } from '+lead/+impactAnalysis/utils/formatters/price';

const currencySymbolMap = {
  [CurrencyUnit.CURRENCY_EUR]: '\u20AC',
  ct: 'ct',
};

export type CurrencySymbol = typeof currencySymbolMap;

export interface Attribute {
  value: number;
  unitSymbol?: string;
  currencySymbol?: CurrencySymbol[keyof CurrencySymbol];
  sign: string;
}

export interface ComposedAttribute {
  value: number;
  formatFn: (value: number | string) => string;
}

export interface Props {
  value: number;
  unit?: string;
  currency?: keyof CurrencySymbol;
  currencyTo?: keyof CurrencySymbol;
  decimals?: number;
  stripTrailingZeros?: boolean;
  addThousandSeparator?: boolean;
  children: (attribute: Attribute) => ComposedAttribute | string;
}

const REGULAR_DECIMAL_SEPARATOR = '.';

const getCurrencyTranslation = (currency?: keyof CurrencySymbol) =>
  currency ? currencySymbolMap[currency] : undefined;
const translateDelimiter = (value: string | number) =>
  String(value).replace(REGULAR_DECIMAL_SEPARATOR, ',');

export const formatSeparators = (
  value: string | number,
  addThousandSeparator: boolean,
  currency = CurrencyUnit.CURRENCY_EUR as keyof CurrencySymbol
) => {
  const [integerPart, fractionalPart] = String(value).split(REGULAR_DECIMAL_SEPARATOR);
  const integerFormatted = addThousandSeparator
    ? addDecimalSeparators(integerPart, currency)
    : integerPart;
  const fractionalFormatted = fractionalPart
    ? translateDelimiter(`${REGULAR_DECIMAL_SEPARATOR}${fractionalPart}`)
    : '';
  return `${integerFormatted}${fractionalFormatted}`;
};

export const AttributeFormatter = ({
  children,
  value,
  unit,
  currency,
  currencyTo,
  decimals,
  stripTrailingZeros = false,
  addThousandSeparator = false,
}: Props) => {
  const sign = value === 0 ? '' : value > 0 ? '+' : '-';

  const result = children({
    sign,
    unitSymbol: unit,
    currencySymbol: getCurrencyTranslation(currencyTo || currency),
    value,
  });

  if (typeof result === 'string') {
    return <>{result}</>;
  }

  const { value: composedValue, formatFn } = result;
  const inputValue =
    decimals !== undefined
      ? stripTrailingZeros
        ? // Parse float strips the trailing zeros
          // eslint-disable-next-line max-len
          // Read more: https://stackoverflow.com/questions/3612744/remove-insignificant-trailing-zeros-from-a-number
          parseFloat(fixedWithoutRounding(composedValue, decimals))
        : fixedWithoutRounding(composedValue, decimals)
      : composedValue;

  const formattedValue = formatSeparators(inputValue, addThousandSeparator, currency);

  return <>{formatFn(formattedValue)}</>;
};
