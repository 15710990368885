import { Data, MergedData } from '@coolio/json-api';

import { LeadProductVersion } from '+shared/store/lead/types/leadProductVersion.interface';

import { LeadProductType } from './leadProductType.interface';

export enum ProductAvailability {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  UNKNOWN = 'unknown',
}

export interface Product {
  name: LeadProductType;
  version: LeadProductVersion;
  available: ProductAvailability;
  reason?: string;
}

export enum MarketType {
  DE = 'DE',
  FOREIGN = 'FOREIGN',
}

export interface Tso {
  name: string;
  marketType: MarketType;
  products: Product[];
}

export interface LeadProductAvailabilityAttributes {
  name: string;
  tsos: Tso[];
}

export type LeadProductAvailabilityData = Data<LeadProductAvailabilityAttributes>;

export type LeadProductAvailability = MergedData<LeadProductAvailabilityData>;
