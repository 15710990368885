import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Chevron, ChevronDirection } from '+shared/basicComponents/Icons';
import ClickOutside from '+shared/containers/ClickOutside/ClickOutside';

import './DropdownWrapper.scss';

interface Props {
  children: React.ReactNode;
  width: number;
  label: string;
  isDropdownOpen: boolean;
  toggleDropdown: (isOpen?: boolean) => void;
  dataTestId?: string;
}

const DropdownWrapper: React.FC<Props> = ({
  children,
  width,
  label,
  isDropdownOpen,
  toggleDropdown,
  dataTestId,
}) => (
  <ClickOutside onClick={() => toggleDropdown(false)}>
    <div
      className="dropdown-label-and-button"
      data-testid={dataTestId || 'dropdown-label-and-button'}
      style={{ width }}
    >
      <button onClick={() => toggleDropdown()} className="dropdown-label-and-button__collapsed">
        <label className="label">{label}</label>
        <span className="toggle-dropdown-button">
          <Chevron direction={isDropdownOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
        </span>
      </button>

      <CSSTransition in={isDropdownOpen} timeout={200} classNames="is">
        {React.cloneElement(<div />, { className: 'dropdown-content' }, children)}
      </CSSTransition>
    </div>
  </ClickOutside>
);

export default DropdownWrapper;
