import { Point } from '@kanva/core';

import { DateAdapterTimezone } from '+utils/timezone-types';

import { ChartDataSeries } from '../../store/types/chartDataSeries.interface';

export type CsvExportableData = {
  [key: string]: Point[];
};

const EXCLUDED_KEYS = [
  'forecastProductionPower',
  'forecastConsumptionPower',
  'chargeLimit',
  'vppActivity',
  'cellCare',
  'temperature',
  'voltage',
];

const KEY_UNITS = {
  W: [
    'productionPower',
    'consumptionPower',
    'gridPower',
    'batteryPower',
    'directUsagePower',
    'batteryCharging',
    'batteryDischarging',
  ],
  Wh: ['producedEnergy', 'consumedEnergy'],
  '%': ['batteryUsoc'],
};

const getUnit = (key: string): string => {
  for (const unit in KEY_UNITS) {
    if (KEY_UNITS[unit].includes(key)) return unit;
  }
  return '';
};

export const getCSVData = (
  data: CsvExportableData,
  allKeys: string[],
  timeZone: DateAdapterTimezone | null | undefined = 'UTC',
  countryCode?: string
): string[][] => {
  const keys = allKeys.filter((key) => !EXCLUDED_KEYS.includes(key));
  const keysWithUnit = keys.map((key) => `${key} [${getUnit(key)}]`);
  const csvData: string[][] = [['unix timestamp', 'date & time', ...keysWithUnit]];

  for (let i = 0; i < keys.length; i++) {
    const columnTitle = keys[i];
    data[columnTitle].forEach((value: Point, index: number) => {
      const date = new Date(value.x * 1000);
      const dateWithTimezone = date.toLocaleString(countryCode || window.navigator.language, {
        timeZone: timeZone?.toString(),
      });
      if (!csvData[index + 1]) csvData[index + 1] = [value.x.toString(), dateWithTimezone];
      csvData[index + 1][i + 2] = value.y?.toString() || '';
    });
  }

  return csvData;
};

export const convertToCsvExportableData = (data: ChartDataSeries): CsvExportableData => {
  const csvExportableData: CsvExportableData = {};
  Object.keys(data).forEach((key) => {
    csvExportableData[key] = data[key];
  });
  return csvExportableData;
};
