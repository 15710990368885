import { from } from 'rxjs';

import { Config } from '+config/config';
import { httpClient } from '+shared/network/network.client';

import { Document } from './types';

const getDocument = ({ country, documentType: type, language }: Document) =>
  from(
    httpClient
      .get<string>(Config.DOCS_API, {
        query: {
          type,
          language,
          country,
        },
      })
      .then((res) => res.parsedBody())
  );

export const StaticRepository = {
  getDocument,
};
