import { Locale } from '+app/i18n/i18n.config';
import { ActionsUnion, createAction } from '+app/utils';

import { DocumentType } from './types';

export enum STATIC_ACTIONS {
  GET_DOCUMENT = '[Static] GET_DOCUMENT',
  DOWNLOAD_DOCUMENT = '[Static] DOWNLOAD_DOCUMENT',
}

export const StaticActions = {
  getDocument: createAction(
    STATIC_ACTIONS.GET_DOCUMENT,
    (documentType: DocumentType, language: Locale, country: string) => ({
      documentType,
      language,
      country,
    })
  ),
  download: createAction(
    STATIC_ACTIONS.DOWNLOAD_DOCUMENT,
    (base64Encoded: string, fileName: string) => ({ base64Encoded, fileName })
  ),
};

export type StaticActions = ActionsUnion<typeof StaticActions>;
