import { asyncScheduler, SchedulerLike } from 'rxjs';

export interface RxDependencies {
  readonly delay?: number;
  readonly scheduler: SchedulerLike;
}

export const dependencies: RxDependencies = {
  scheduler: asyncScheduler,
};
