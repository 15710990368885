import React from 'react';

export enum ChevronDirection {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

type Props = {
  color?: string;
  size?: number | string;
  className?: string;
  direction?: ChevronDirection;
};

export const Chevron: React.FC<Props> = ({ color, size, className, direction = 'down' }) => {
  const transformation = {
    up: 'matrix(-1, 0, 0, -1, 0, 0)',
    left: 'matrix(0, 1, -1, 0, 0, 0)',
    right: 'matrix(0, -1, 1, 0, 0, 0)',
    down: 'none',
  };

  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      style={{ transform: transformation[direction] }}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21 7.5l-9 9-9-9"
      ></path>
    </svg>
  );
};
