import * as React from 'react';

import { Modal, ModalSize } from '@sonnen/shared-web';

import { FormFailure, FormSuccess } from '+shared/components';

import { FormState } from './FormModal.helper';

interface Props {
  isModalOpen: boolean;
  size?: ModalSize;
  className?: string;
  status: FormState;
  headlineSuccess: string;
  headlineFailure: string;
  defaultForm: React.ReactNode;
  onClose: () => void;
  onTryAgain: () => void;
}

export const FormModal: React.FC<Props> = ({
  isModalOpen,
  size = 'small',
  className,
  status,
  headlineSuccess,
  headlineFailure,
  defaultForm,
  onClose,
  onTryAgain,
}) => (
  <Modal
    isOpen={isModalOpen}
    onClose={onClose}
    onOutsideClick={onClose}
    size={size}
    className={className}
  >
    {
      {
        [FormState.FORM]: defaultForm,
        [FormState.FORM_ERROR]: <FormFailure onTryAgain={onTryAgain} headline={headlineFailure} />,
        [FormState.FORM_SUCCESS]: <FormSuccess onDone={onClose} headline={headlineSuccess} />,
      }[status]
    }
  </Modal>
);
