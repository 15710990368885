export interface PreCommissioningInterface {
  customerFirstName: string;
  customerLastName: string;
  customerPhoneNumber: string;
  customerEmailAddress: string;
  customerNumber: string;
  partnerCompanyName: string;
  partnerCompanyNumber: string;
  partnerCompanyLogotype: string | null;
  assetStreetNameAndNumber: string;
  assetZipCode: string;
  assetCity: string;
  assetCountryCode: string;
  generationStatus?: PreCommissioningStatus;
}

export enum PreCommissioningStatus {
  NOT_GENERATED = 'not_generated',
  GENERATED = 'generated',
  NEEDS_REGENERATION = 'needs_regeneration',
}
