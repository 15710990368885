import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph } from '@sonnen/shared-web';

import { FormikProps } from 'formik';

import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { useSubmissionFileUploadManagement } from '+setupTool/+form/store/+form.hooks';
import { getCompletionNotificationDocumentSubmitQueryStatus } from '+setupTool/+form/store/+form.selectors';
import {
  getVppCompletionNotificationDocument,
  getVppCompletionNotificationRegeneratedAt,
  getVppGenerateCompletionNotificationQueryStatus,
  VppDocumentationActions,
} from '+setupTool/+vppDocumentation/store';
import { FormUploadInput } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';

import './VppCompletionNotification.component.scss';

interface OwnProps<T> {
  form: FormikProps<T>;
}

const mapStateToProps = (state: StoreState) => ({
  completionNotificationDocument: getVppCompletionNotificationDocument(state),
  completionNotificationRegeneratedAt: getVppCompletionNotificationRegeneratedAt(state),
  generateCompletionNotificationQueryStatus: getVppGenerateCompletionNotificationQueryStatus(state),
  completionNotificationDocumentQueryStatus:
    getCompletionNotificationDocumentSubmitQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  generateCompletionNotification: VppDocumentationActions.generateCompletionNotification,
  sendDsoDocumentsFile: DsoRegistrationFormActions.sendDsoDocumentsFile,
  removeDsoDocumentsFile: DsoRegistrationFormActions.removeDsoDocumentsFile,
});

type Props<T> = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps<T>;

const VppCompletionNotificationComponent = <T,>({
  completionNotificationDocument,
  completionNotificationRegeneratedAt,
  generateCompletionNotificationQueryStatus,
  completionNotificationDocumentQueryStatus,
  actions,
  form,
}: Props<T>) => {
  const isCompletionNotificationUploadVisible = !!completionNotificationRegeneratedAt;
  const [isDownloadButtonClicked, setDownloadButtonClicked] = React.useState(false);
  const { fileUploadSettings, vppUploadedDocuments, uploadSubmissionFile, removeSubmissionFile } =
    useSubmissionFileUploadManagement();

  const completitionNotificationSettings =
    fileUploadSettings[FileCategoryName.COMPLETION_NOTIFICATION];

  const handleUploadFiles =
    (fileCategoryName: FileCategoryName) =>
    (files: File[]): void => {
      uploadSubmissionFile(files, fileCategoryName);
    };

  const handleDeleteFiles =
    (fileCategoryName: FileCategoryName) =>
    (fileId?: string): void => {
      const document = vppUploadedDocuments[fileCategoryName];

      if (document && fileId) {
        removeSubmissionFile(fileId);
      }
    };

  React.useEffect(() => {
    setTimeout(() => {
      const completionNotificationDocumentLink = document.getElementById(
        'vpp-completion-notification__download-link'
      );
      if (isDownloadButtonClicked && completionNotificationDocumentLink) {
        completionNotificationDocumentLink.click();
      }
    }, 1000);
  }, [completionNotificationDocument]);

  return (
    <div className={'vpp-completion-notification'}>
      <p className={'vpp-completion-notification__title'}>
        {I18n.t(T.setupTool.vppDocumentation.completionNotification.title)}
      </p>
      <p className={'vpp-completion-notification__description'}>
        {I18n.t(T.setupTool.vppDocumentation.completionNotification.description)}
      </p>
      <Button
        className={'vpp-completion-notification__download-button'}
        type={ButtonType.SECONDARY}
        label={I18n.t(T.setupTool.form.download)}
        loading={generateCompletionNotificationQueryStatus.pending}
        onClick={() => {
          actions.generateCompletionNotification();
          setDownloadButtonClicked(true);
        }}
      />
      {!!completionNotificationDocument && (
        <a
          id={'vpp-completion-notification__download-link'}
          href={completionNotificationDocument.upload.url}
          download={completionNotificationDocument.fileName}
        />
      )}
      {isCompletionNotificationUploadVisible && (
        <div>
          <p className={'vpp-completion-notification__description'}>
            {I18n.t(T.setupTool.vppDocumentation.completionNotification.pdfDescription)}
          </p>
          <BoldParagraph className={'c-setup-tool__upload-input-label'}>
            {I18n.t(T.setupTool.vppDocumentation.completionNotification.upload)}
          </BoldParagraph>
          <FormUploadInput
            form={form}
            name={FileCategoryName.COMPLETION_NOTIFICATION}
            accept={completitionNotificationSettings.mimeType}
            maxSize={completitionNotificationSettings.maxFileSize}
            maxSizeLabel={completitionNotificationSettings.maxFileSizeLabel}
            label={I18n.t(T.setupTool.info.chooseFile)}
            placeholder={I18n.t(T.setupTool.info.fileUpTo, {
              extension: completitionNotificationSettings.extensions,
              maxFileSize: completitionNotificationSettings.maxFileSizeLabel,
            })}
            isImmediatelySentToBackend={true}
            isLoading={completionNotificationDocumentQueryStatus.pending}
            showErrorOnlyIfTouched={true}
            onUpload={handleUploadFiles(FileCategoryName.COMPLETION_NOTIFICATION)}
            onReset={handleDeleteFiles(FileCategoryName.COMPLETION_NOTIFICATION)}
          />
        </div>
      )}
    </div>
  );
};

export const VppCompletionNotification = connect(
  mapStateToProps,
  mapDispatchToProps
)(VppCompletionNotificationComponent);
