import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import './VideoThumbnail.component.scss';

interface Props {
  src: string;
  category: string;
  length: string;
  onLoad: () => void;
}

export const VideoThumbnail: React.FC<Props> = ({ src, category, onLoad, length }) => (
  <div className={'c-video-thumbnail'}>
    <img className={'c-video-thumbnail__image'} src={src} onLoad={onLoad} />
    <div className={'c-video-thumbnail__category c-video-thumbnail__tip'}>{category}</div>
    <Icon.Play className={'c-video-thumbnail__play-icon'} />
    <div className={'c-video-thumbnail__length c-video-thumbnail__tip'}>{length}</div>
  </div>
);
