import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { remove } from 'lodash';

import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox';

import './DsoRegistrationInstallers.component.scss';

interface Props {
  form: any;
  items: any[];
  installersIdsToRemove: string[];
  setInstallersIdsToRemove: (args: any) => void;
}

export const DsoRegistrationInstallers: React.FC<Props> = ({
  form,
  items,
  installersIdsToRemove,
  setInstallersIdsToRemove,
}) => {
  const removeInstallerId = (installerId: string) => {
    setInstallersIdsToRemove((installersIdsToRemove: string[]) =>
      remove(installersIdsToRemove, (id) => id !== installerId)
    );
  };

  const addInstallerId = (installerId: string) => {
    setInstallersIdsToRemove((installersIdsToRemove: string[]) => [
      ...installersIdsToRemove,
      installerId,
    ]);
  };

  const handleCheckboxChange = (installerId: string, checkboxChecked: boolean) => {
    if (!checkboxChecked && installersIdsToRemove.includes(installerId)) {
      removeInstallerId(installerId);
    } else if (checkboxChecked && !installersIdsToRemove.includes(installerId)) {
      addInstallerId(installerId);
    }
  };

  const toggleCheckboxFieldValue = (checkboxName: string) => {
    const currentCheckboxValue = !!form.values[checkboxName];
    form.setValues({ ...form.values, [checkboxName]: !currentCheckboxValue });
    handleCheckboxChange(checkboxName, !currentCheckboxValue);
  };

  const { installerName, installerCompanyName } = T.setupTool.installerData;

  return (
    <div className={'list-installers'}>
      <div className={'list-installers__row list-installers__row--titles'}>
        <div className={'list-installers__title'} />
        <div className={'list-installers__title'}>
          <p>{I18n.t(installerName)}</p>
        </div>
        <div className={'list-installers__title'}>
          <p>{I18n.t(installerCompanyName)}</p>
        </div>
      </div>
      {items.map((installer) => (
        <div
          className={'list-installers__row'}
          key={installer.id}
          onClick={(e) => {
            e.preventDefault();
            toggleCheckboxFieldValue(installer.id);
          }}
        >
          <FormControlledCheckbox form={form} label={''} name={installer.id} />
          <div className={'list-installers__value'}>{installer.fullName}</div>
          <div className={'list-installers__value'}>{installer.company}</div>
        </div>
      ))}
    </div>
  );
};
