import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};
export const Plus: React.FC<Props> = ({ color, size = 24, className }) => (
  <svg className={className} width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C11.5858 2 11.25 2.33579 11.25 2.75V11.25H2.75C2.33579 11.25 2 11.5858 2 12C2 12.4142 2.33579 12.75 2.75 12.75H11.25V21.25C11.25 21.6642 11.5858 22 12 22C12.4142 22 12.75 21.6642 12.75 21.25V12.75H21.25C21.6642 12.75 22 12.4142 22 12C22 11.5858 21.6642 11.25 21.25 11.25H12.75V2.75C12.75 2.33579 12.4142 2 12 2Z"
      fill={color}
    />
  </svg>
);
