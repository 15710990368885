import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { CountryFlagProvider, Modal, ModalLayout } from '@sonnen/shared-web';

import { Form, Formik, FormikHelpers } from 'formik';
import * as uuid from 'uuid';

import {
  getAddVideoCategoriesByMarkets,
  getCategories,
} from '+app/+help/+videos/containers/Videos/Videos.helper';
import { FormInputMultiselect, FormInputSelect } from '+shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { Firebase } from '+shared/store/firebase/firebase.client';
import { CategoryName } from '+shared/store/firebase/types';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { QueryState } from '+shared/store/query';
import { VideoYoutube } from '+shared/store/youtube/types/youtube.interface';
import { YoutubeActions } from '+shared/store/youtube/youtube.actions';
import { getMarketKeys, mapCountryCodeToMarket } from '+utils/market.util';

import { AddVideoFormButtons } from '../AddVideoFormButtons/AddVideoFormButtons.component';
import { AddVideoLink } from '../AddVideoLink';
import {
  AddVideoForm,
  getYouTubeId,
  validate,
  videoCategory,
  videoMarkets,
  youtubeLink,
} from './AddVideo.helper';

import './AddVideo.component.scss';

interface Props {
  getYoutubeVideo: typeof YoutubeActions.getYoutubeVideoList;
  clearYoutubeVideoList: typeof YoutubeActions.clearYoutubeVideoList;
  toggleModal: typeof LayoutActions.toggleModal;
  addVideo: typeof FirebaseActions.addVideo;
  youtubeVideo?: VideoYoutube;
  firebase: Firebase;
  youtubeVideoQueryStatus: QueryState;
  isModalOpen: boolean;
}

export const AddVideo = React.memo<Props>((props) => {
  const {
    getYoutubeVideo,
    clearYoutubeVideoList,
    toggleModal,
    addVideo,
    youtubeVideo,
    firebase,
    youtubeVideoQueryStatus,
    isModalOpen,
  } = props;

  const { userCountry } = CountryFlagProvider.getConfig();

  const openModal = () => toggleModal(true, ModalId.ADD_VIDEO);
  const closeModal = () => toggleModal(false, ModalId.ADD_VIDEO);

  const handleSubmit = async (values: AddVideoForm, formikActions: FormikHelpers<AddVideoForm>) => {
    const youtubeId = getYouTubeId(values[youtubeLink]);

    if (!values || !youtubeId) return;

    const video = {
      id: uuid.v4(),
      youtubeId,
      categories: [values[videoCategory].hash as CategoryName],
      markets: values[videoMarkets],
    };

    try {
      await firebase.addVideo(video);
      addVideo(video);
      clearYoutubeVideoList();
      formikActions.resetForm();
      toggleModal(false, ModalId.ADD_VIDEO);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className={'c-add-video__open-modal'}>
        <Button
          className={'c-add-video__open-modal-button'}
          label={'Add video'}
          onClick={openModal}
          size={ButtonSize.SMALL}
        />
      </div>
      <Modal size={'small'} isOpen={isModalOpen} onOutsideClick={closeModal}>
        <Formik
          initialValues={
            {
              [videoMarkets]: [],
              [videoCategory]: getCategories(mapCountryCodeToMarket(userCountry, true))[0],
              [youtubeLink]: '',
            } as AddVideoForm
          }
          validateOnBlur={false}
          validateOnChange={true}
          validate={validate}
          onSubmit={handleSubmit}
          render={(form) => (
            <Form className={'c-add-video__modal-form'}>
              <ModalLayout
                title={'Add video'}
                footer={
                  <div className={'c-add-video__footer'}>
                    <Button
                      className={'c-add-video__cancel-button'}
                      label={I18n.t(T.general.basicActions.cancel)}
                      size={ButtonSize.SMALL}
                      type={ButtonType.SECONDARY}
                      onClick={closeModal}
                    />
                    <AddVideoFormButtons
                      form={form}
                      youtubeVideo={youtubeVideo}
                      getYoutubeVideo={getYoutubeVideo}
                    />
                  </div>
                }
              >
                <div>
                  <FormInputMultiselect
                    form={form}
                    label={'Market'}
                    name={videoMarkets}
                    collection={getMarketKeys()}
                  />
                  <FormInputSelect
                    form={form}
                    label={'Category'}
                    name={videoCategory}
                    collection={
                      form.values[videoMarkets]
                        ? getAddVideoCategoriesByMarkets(form.values[videoMarkets])
                        : [{ label: I18n.t(T.videosSubpage.emptyMarketCategory), hash: '' }]
                    }
                    mapper={(el) => el.label}
                  />
                  <AddVideoLink
                    youtubeVideoQueryStatus={youtubeVideoQueryStatus}
                    clearYoutubeVideoList={clearYoutubeVideoList}
                    youtubeVideo={youtubeVideo}
                    form={form}
                  />
                </div>
              </ModalLayout>
            </Form>
          )}
        />
      </Modal>
    </>
  );
});
