import { CaseListRouteQueryParams } from '+app/router';
import { ActionsUnion, createAction } from '+app/utils';

import { CASE_COLLECTION_GET_QUERY, CREATE_CASE_QUERY } from './case.state';
import { Case } from './types/case.interface';
import { CaseSite } from './types/caseSite.interface';
import { CreateCaseAttributes } from './types/createCase.interface';
import { CreatedCaseAttributes } from './types/createCaseResponse.interface';

export enum CASE_ACTIONS {
  GET_CASE_LIST = '[Case] GET_CASE_LIST',
  SET_CASE_LIST = '[Case] SET_CASE_LIST',
  CREATE_CASE = '[Case] CREATE_CASE',
  SET_CREATED_CASE = '[Case] SET_CREATED_CASE',
  CLEAR_CREATED_CASE = '[Case] CLEAR_CREATED_CASE',
  SET_CASE_REPORT_ACTIVE_SITE = '[CASE] SET_CASE_REPORT_ACTIVE_SITE',
}

export const CaseActions = {
  getCaseList: createAction(
    CASE_ACTIONS.GET_CASE_LIST,
    (queryParams: CaseListRouteQueryParams, userProfileCustomerNumber: string) => ({
      queryParams,
      userProfileCustomerNumber,
      queryKey: CASE_COLLECTION_GET_QUERY,
    })
  ),
  setCaseList: createAction(CASE_ACTIONS.SET_CASE_LIST, (collection: Case[], total?: number) => ({
    collection,
    total,
  })),

  createCase: createAction(
    CASE_ACTIONS.CREATE_CASE,
    (createCaseAttributes: CreateCaseAttributes) => ({
      createCaseAttributes,
      queryKey: CREATE_CASE_QUERY,
    })
  ),
  setCreatedCase: createAction(
    CASE_ACTIONS.SET_CREATED_CASE,
    (createCaseAttributes: CreatedCaseAttributes) => ({
      createCaseAttributes,
      queryKey: CREATE_CASE_QUERY,
    })
  ),
  clearCreatedCase: createAction(CASE_ACTIONS.CLEAR_CREATED_CASE),

  setCaseReportActiveSite: createAction(
    CASE_ACTIONS.SET_CASE_REPORT_ACTIVE_SITE,
    (activeSite?: CaseSite) => ({ activeSite })
  ),
};

export type CaseActions = ActionsUnion<typeof CaseActions>;
