import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { HeatPump, HeatPumpIllustration } from '+shared/basicComponents/Icons';
import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { StatusTile, StatusTileColors, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { QueryState } from '+shared/store/query/query.state';
import { Heatpump } from '+shared/store/site/types/siteLiveStateV2.interface';

import './HeatPumpStatusCard.scss';

interface Props {
  heatpump: Heatpump;
  queryStatus: QueryState;
  timestamp: string;
}

const {
  connection,
  energyConsumption,
  statusDetails: statusDetailsTerm,
  status,
  error,
} = T.customerSingle.heatPump.heatPumpStatus;

export const HeatPumpStatusCard: React.FC<Props> = ({ queryStatus, heatpump, timestamp }) => {
  const { connectionState, statusDetails, powerIn } = heatpump;
  const isOnline = connectionState === 'online';
  const [statusDetail] = statusDetails;

  const energyConsumptionValue = isOnline && powerIn ? `${powerIn.value} ${powerIn.unit}` : '—';
  const statusValue =
    isOnline && statusDetail ? I18n.t(statusDetailsTerm[statusDetail.status]) : '—';

  return (
    <Widget
      className="heat-pump-status-card"
      icon={<HeatPump className="heat-pump__icon" size={30} />}
      heading={
        <div className="heat-pump-status-card__header">
          {I18n.t(connection)}
          <StatusTile
            additionalClass="heat-pump-status-card__header-status"
            color={isOnline ? StatusTileColors.GREEN : StatusTileColors.GRAY}
            label={`${connectionState} - ${timestamp}`}
            hasDot={isOnline}
          />
        </div>
      }
      // TODO: this was hidden in SON-42944
      // It will be commented back in when IoT data is available.
      // notification={
      //   <div className="heat-pump-status__footer">
      //     <BodyMedium text={I18n.t(pvSurplusMode)} />
      //     <BodyMedium text={`${pvSurplusIn?.value} ${pvSurplusIn?.unit}`} />
      //   </div>
      // }
    >
      {!queryStatus.error && (
        <div className="heat-pump-status-card__body">
          <div className="heat-pump-status-card__text-on-left-side">
            <BodyMedium text={I18n.t(energyConsumption)} />
            <LabelMedium text={energyConsumptionValue} className="heat-pump-status-card__value" />
          </div>
          <div className="heat-pump-status-card__illustration">
            <HeatPumpIllustration />
          </div>
          <div>
            <BodyMedium text={I18n.t(status)} />
            <LabelMedium text={statusValue} className="heat-pump-status-card__value" />
          </div>
        </div>
      )}

      {queryStatus.error && (
        <WidgetWarning type="warning" icon="warning">
          {I18n.t(error)}
        </WidgetWarning>
      )}
    </Widget>
  );
};
