import isNil from 'lodash/isNil';
import { JsonApiOneSender } from '../senders/jsonApi.one-sender';
import { JsonApiManySender } from '../senders/jsonApi.many-sender';
import { JsonApiRequestData } from '../requestData';
var RequestBuilder = (function () {
    function RequestBuilder(httpClient, options, uri) {
        this.httpClient = httpClient;
        this.requestData = new JsonApiRequestData(uri, options);
    }
    RequestBuilder.prototype.parameter = function (key, value) {
        if (key && !isNil(value)) {
            this.requestData.query[key] = value;
        }
        return this;
    };
    RequestBuilder.prototype.filter = function (key, value) {
        var queryKey = Array.isArray(key)
            ? (key.length ? "filter[" + key.join('][') + "]" : '')
            : "filter[" + key + "]";
        return this.parameter(queryKey, value);
    };
    RequestBuilder.prototype.sort = function (key, order) {
        if (!isNil(key)) {
            this.requestData.sort.push(order + key);
        }
        return this;
    };
    RequestBuilder.prototype.pageLimit = function (limit) {
        this.requestData.limit = limit;
        return this;
    };
    RequestBuilder.prototype.pageOffset = function (offset) {
        this.requestData.offset = offset;
        return this;
    };
    RequestBuilder.prototype.pageNumber = function (page) {
        this.requestData.page = page ? Number(page) : 1;
        return this;
    };
    RequestBuilder.prototype.expectOne = function () {
        this.onBeforeExpect();
        return new JsonApiOneSender(this.httpClient, this.requestData);
    };
    RequestBuilder.prototype.expectMany = function () {
        this.onBeforeExpect();
        return new JsonApiManySender(this.httpClient, this.requestData);
    };
    RequestBuilder.prototype.onBeforeExpect = function () {
    };
    return RequestBuilder;
}());
export { RequestBuilder };
