import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { CustomerActions } from '+customer/store';
import { CaseActions } from '+shared/store/case/case.actions';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';

import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { mapPathToParams, mapToState, ofType } from '../../../utils';

type Action$ = ActionsObservable<RouterActions | CustomerActions>;
type State$ = StateObservable<StoreState>;

export const getCaseCollection$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    mapToState(state$),
    mergeMap((state) =>
      of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER[0], ROUTES.CUSTOMER_CASES[0]),
        map(([customerId]) =>
          CaseActions.getCaseList({ customerId }, getUserProfileCustomerNumber(state$.value))
        )
      )
    )
  );

export const epics = combineEpics(getCaseCollection$);
