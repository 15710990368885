import { Data, MergedData } from '@coolio/json-api';

import { CurrencyUnit, OtherUnit } from '+app/App.constants';

import { LeadCostWithTax } from './leadCost.interface';
import { LeadMeasurement } from './leadMeasurement.interface';

export enum ImpactAnalysisTaxRegulation {
  STANDARD = 'standard',
  SMALL_ENTERPRISE = 'small_enterprise',
}

export interface LeadImpactAnalysisAttributes {
  consumption: {
    baselineTariffPerKwh: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
    baselineFeePerMonth: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
    averageElectricityPriceIncreasePerYear: LeadMeasurement<OtherUnit>;
  };
  selectedPages?: {
    products: {
      benefits: boolean;
      explanation: boolean;
      interaction: boolean;
    };
    sonnenFlat: {
      info: boolean;
      contract: boolean;
    };
    financialAnalysis: {
      providerVsSonnen: boolean;
      detailed: boolean;
      amortization: boolean;
    };
    environmentalContribution: boolean;
    yourSpecialist: boolean;
  };
  costs: {
    basic?: {
      totalInvestmentCosts: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
      totalRunningCostsPerYear: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
    };
    detailed?: {
      investmentCosts: {
        pvSystemPurchase: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        pvSystemInstallation?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        inverterAndOtherComponentsPurchase?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        batteryPurchase: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        batteryInstallation?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        meterReplacement?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        otherInstallation?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
      };
      runningCosts: {
        insurancePerYear?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        hardwareMaintenancePerYear?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
        additionalRunningCostsPerYear?: LeadCostWithTax<CurrencyUnit.CURRENCY_EUR>;
      };
    };
  };
}

export type LeadImpactAnalysisData = Data<LeadImpactAnalysisAttributes>;
export type LeadImpactAnalysis = MergedData<LeadImpactAnalysisData>;
