import { Data, MergedData } from '@coolio/json-api';

import { OtherUnit } from '+app/App.constants';

import { Resolution } from './resolution.interface';

export enum CellDataName {
  MIN_CELL_TEMPERATURE = 'min_cell_temperature',
  MAX_CELL_TEMPERATURE = 'max_cell_temperature',
  MIN_CELL_VOLTAGE = 'min_cell_voltage',
  MAX_CELL_VOLTAGE = 'max_cell_voltage',
}

export interface CellDataFields {
  index: number;
  name: CellDataName;
  unit: OtherUnit.VOLT | OtherUnit.CELSIUS_DEGREE;
}

export interface SiteCellDataFilters {
  start: Date;
  end: Date;
}

export interface SiteCellDataAttributes {
  id: string;
  type: string;
  startAt: string;
  endAt: string;
  resolution: Resolution;
  fields: CellDataFields[];
  values: Record<string, number[]>;
}

export type SiteCellDataData = Data<SiteCellDataAttributes>;
export type SiteCellData = MergedData<SiteCellDataData>;
