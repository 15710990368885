import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Modal, ModalLayout } from '@sonnen/shared-web';

import { CustomerWithFlatDirectSVG } from '+shared/basicComponents/Icons';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { useLocaleContext } from '+utils/react/locale.provider';

interface Props {
  isOpen: boolean;
  onKeep: () => void;
  onSwitch: () => void;
}

export const SwitchFlatModal: React.FC<Props> = ({ isOpen, onKeep, onSwitch }) => {
  const { locale } = useLocaleContext();

  const { title, subtitle, body, keepButton, switchButton } =
    T.lead.configuration._salessolution_.protect.switchToFlatDirectModal;

  return (
    <Modal isOpen={isOpen} size="medium">
      <ModalLayout
        title={I18n.t(title)}
        subtitle={I18n.t(subtitle)}
        footer={
          <div className="flat-switch-modal__buttons">
            <Button type={ButtonType.QUATERNARY} label={I18n.t(keepButton)} onClick={onKeep} />
            <Button size={ButtonSize.MEDIUM} label={I18n.t(switchButton)} onClick={onSwitch} />
          </div>
        }
      >
        <div className="flat-switch-modal__body">
          <CustomerWithFlatDirectSVG locale={locale} size="200px" />

          <div className="flat-switch-modal__body-info">
            <p>{I18n.t(body)}</p>
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};
