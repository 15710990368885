import { CurrencyUnit } from '+app/App.constants';

export interface LeadBudget<T extends CurrencyUnit, U = number | string> {
  value: U;
  currency: T;
}

export enum LeadBudgetInEuro {
  LT_5000 = '(0;5000)',
  BTW_5000_AND_10000 = '<5000;10000)',
  BTW_10000_AND_15000 = '<10000;15000)',
  BTW_15000_AND_20000 = '<15000;20000)',
  BTW_20000_AND_25000 = '<20000;25000)',
  BTW_25000_AND_30000 = '<25000;30000)',
  GT_30000 = '<30000;Infinity)',
  UNKNOWN = '',
}
