import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web/src/components/Icon/index';

import * as classNames from 'classnames';

import './SetupToolContact.component.scss';

export const SetupToolContact: React.FC = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div
      className={classNames(`c-setup-tool-contact`, {
        'is-expanded': isExpanded,
      })}
    >
      <div
        className={`c-setup-tool-contact--minimized`}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className={`c-setup-tool-contact--minimized__inner`}>
          <Icon.PhoneCircle className={`c-setup-tool-contact--minimized__phone-circle-icon`} />
          <span className={`c-setup-tool-contact--minimized__header`}>
            {I18n.t(T.setupTool.infoBox.contactHeaderMinimized)}
          </span>
        </div>
      </div>
      <div className={`c-setup-tool-contact--expanded`}>
        <button
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          className={`c-setup-tool-contact--expanded__close-button`}
        >
          <Icon.Close className={`c-setup-tool-contact--expanded__close-icon`} />
        </button>
        <p className={`c-setup-tool-contact--expanded__header`}>
          {I18n.t(T.setupTool.infoBox.contactHeaderExpanded)}
        </p>
        <p className={`c-setup-tool-contact--expanded__body`}>
          <Translate value={T.setupTool.infoBox.contactBody} dangerousHTML={true} />
        </p>
      </div>
    </div>
  );
};
