import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Modal, ModalLayout } from '@sonnen/shared-web';

import { Button, ButtonSize, ButtonType } from '+shared/components/Button';

import './LeadZipCodeNotFoundModal.component.scss';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const { modalHeadline, modalInfo, okButton, cancelButton } =
  T.lead.boc._salessolution_.form.personalDetails;

export const LeadZipCodeNotFoundModal: React.FC<Props> = ({ isModalOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isModalOpen} size={'medium'}>
      <div className="c-lead-zip-code-not-fount-modal">
        <ModalLayout
          footer={
            <div className="c-lead-zip-code-not-fount-modal__buttons">
              <Button
                className="c-lead-zip-code-not-fount-modal__button-cancel"
                // Should lead to lead overview
                onClick={onClose}
                label={I18n.t(cancelButton)}
                size={ButtonSize.LARGE}
                type={ButtonType.TERTIARY}
              />
              <Button label={I18n.t(okButton)} size={ButtonSize.LARGE} onClick={onSubmit} />
            </div>
          }
        >
          <div className="c-lead-zip-code-not-fount-modal__content">
            <Icon.Ufo className="c-lead-zip-code-not-fount-modal__icon" />
            <div className="c-lead-zip-code-not-fount-modal__text">
              <p className="c-lead-zip-code-not-fount-modal__headline">{I18n.t(modalHeadline)}</p>
              <p className="c-lead-zip-code-not-fount-modal__info">{I18n.t(modalInfo)}</p>
            </div>
          </div>
        </ModalLayout>
      </div>
    </Modal>
  );
};
