import * as React from 'react';

import './TabNav.component.scss';

interface Props {
  items: React.ReactNode;
}

export const TabNav: React.SFC<Props> = ({ items }: Props) => (
  <nav className={'c-tab-nav'}>
    <ul className={'c-tab-nav__list'}>{items}</ul>
  </nav>
);
