import { PersistedState } from 'redux-persist';

import { Query } from '+shared/store/query';

import { PartnerSite, PartnerSiteData, PartnerSiteMetaData } from './types/partnerSite.interface';
import {
  PartnerSitesGeoLocation,
  PartnerSitesGeoLocationData,
} from './types/partnerSitesCustomer.interface';

export const GET_SITES_QUERY = 'getSitesQuery';
export const GET_SITES_GEO_LOCATION_QUERY = 'getSitesGeoLocationsQuery';
export const GET_PARTNER_SITES_SUMMARY = 'getPartnerSitesSummaryQuery';

export interface SiteListPageState extends PersistedState {
  siteCollection: PartnerSite[];
  siteMetaData: PartnerSiteMetaData;
  siteGeoLocations: PartnerSitesGeoLocation[];
  installedBatteriesCount: number;
  [GET_SITES_QUERY]: Query<PartnerSiteData>;
  [GET_SITES_GEO_LOCATION_QUERY]: Query<PartnerSitesGeoLocationData>;
  [GET_PARTNER_SITES_SUMMARY]: Query;
}

const initialSiteMetaData = {
  totalCount: 0,
  limitValue: 0,
  totalPages: 0,
  currentPage: 0,
  nextPage: 0,
  firstPage: false,
  lastPage: false,
  outOfRange: false,
};

export const initialState = {
  siteCollection: [],
  siteMetaData: initialSiteMetaData,
  siteGeoLocations: [],
  installedBatteriesCount: 0,
  [GET_SITES_QUERY]: {},
  [GET_SITES_GEO_LOCATION_QUERY]: {},
  [GET_PARTNER_SITES_SUMMARY]: {},
};
