import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { BatteryModelName } from '+shared/store/lead/types';

import { HardwareForm } from '../types';

export const HardwareFormSchema = () =>
  Yup.object().shape({
    capacityGross: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    modelName: Yup.string().required(I18n.t(T.register.alert.requiredField)),
  });

export const hardwareFormInitial: HardwareForm = {
  capacityGross: '',
  modelName: BatteryModelName.SONNENBATTERIE_10_AC,
};
