import * as React from 'react';
import { connect } from 'react-redux';

import { Loader, SideInfo } from '@sonnen/shared-web';

import { compose } from 'redux';
import { EMPTY } from 'rxjs';

import { getCurrentLocale } from '+app/i18n/i18n.selectors';
import { withFirebase } from '+shared/components/FirebaseContext';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import {
  ContactDetailsContent,
  mapLocaleToContactDetailsLocale,
} from '+shared/store/firebase/types/contact.interface';
import { StoreState } from '+shared/store/store.interface';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { mapCountryCodeToMarket } from '+utils/market.util';
import { matchQueryState, useQuery } from '+utils/useQuery.util';

import './HelpContact.component.scss';

const mapStateToProps = (state: StoreState) => ({
  countryCode: getUserCountryCode(state),
  locale: getCurrentLocale(state),
});

type Props = ReturnType<typeof mapStateToProps> & FirebaseProps;

const HelpContactComponent: React.FC<Props> = ({ countryCode, locale, firebase }) => {
  const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');

  const market = mapCountryCodeToMarket(countryCode, isSpanishMarketEnabled);

  const matchContactQuery = matchQueryState(
    useQuery({
      call: () => (market ? firebase.getContactDetailsByMarket(market) : EMPTY),
    })
  );

  const getLocalizedText = (details: ContactDetailsContent): string =>
    details[mapLocaleToContactDetailsLocale(isSpanishMarketEnabled, locale)] ?? '';

  return (
    <section className={'c-help-contact'}>
      {matchContactQuery({
        onInit: () => <Loader className={'c-help-contact__loader'} />,
        onPending: () => <Loader className={'c-help-contact__loader'} />,
        onFailure: () => <div>Cannot fetch data</div>,
        onSuccess: ({
          result: {
            meterChangeSupport,
            eServiceSupport,
            partnerSupport,
            techSupport,
            returnsSupport,
          },
        }) => (
          <>
            {returnsSupport && (
              <SideInfo className={'c-help-contact__side-info'}>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(returnsSupport) }} />
              </SideInfo>
            )}
            {techSupport && (
              <SideInfo className={'c-help-contact__side-info'}>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(techSupport) }} />
              </SideInfo>
            )}
            {eServiceSupport && (
              <SideInfo className={'c-help-contact__side-info'}>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(eServiceSupport) }} />
              </SideInfo>
            )}
            {meterChangeSupport && (
              <SideInfo className={'c-help-contact__side-info'}>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(meterChangeSupport) }} />
              </SideInfo>
            )}
            {partnerSupport && (
              <SideInfo className={'c-help-contact__side-info'}>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(partnerSupport) }} />
              </SideInfo>
            )}
          </>
        ),
      })}
    </section>
  );
};

export const HelpContact = compose<React.ComponentType>(
  withFirebase,
  connect(mapStateToProps)
)(HelpContactComponent);
