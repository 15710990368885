import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import { Loadable } from '@sonnen/shared-web';

import { LEAD_IN_SETUP_STAGE, ROUTES } from '+app/router';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { getLead } from '+lead/store/+lead.selectors';
import { DsoCommissioning } from '+setupTool/+dsoCommissioning';
import { DsoRegistration } from '+setupTool/+dsoRegistration';
import { DsoRegistrationForm } from '+setupTool/+form';
import { getFormFulfillment } from '+setupTool/+form/store/+form.selectors';
import { PreCommissioning } from '+setupTool/+preCommissioning/containers';
import { PVRegister } from '+setupTool/+pvRegister';
import { VppDocumentation } from '+setupTool/+vppDocumentation/containers';
import { getVppState, VppState } from '+setupTool/+vppDocumentation/store';
import { SetupToolContact } from '+setupTool/components/SetupToolContact';
import { SetupToolDashboard } from '+setupTool/index';
import { getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { StoreState } from '+shared/store/store.interface';

import './SetupTool.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  submissionId: getSubmissionId(state),
  vppState: getVppState(state),
  isFormFulfilled: getFormFulfillment(state),
});

const mapDispatchToProps = () => ({
  markAsSeen: LeadPageActions.markLeadAsSeen,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const SetupToolComponent: React.FC<Props> = ({
  submissionId,
  vppState,
  isFormFulfilled,
  lead,
  markAsSeen,
}) => {
  const { useTrackPageLoadDependingOnRoute } = useAdobeAnalyticsTracking();
  useTrackPageLoadDependingOnRoute('SETUP_CONFIGURATOR');

  useEffect(() => {
    if (lead && lead.isNewlyAssigned) markAsSeen();
  }, []);

  const isVppEnabled = vppState !== VppState.DISABLED && vppState !== VppState.HIDDEN;

  return (
    <>
      <Loadable predicate={!(lead && lead.id)}>
        <SetupToolDashboard />
        <Switch>
          {!!submissionId && (
            <>
              <Route
                exact={true}
                path={ROUTES.LEAD_SETUP_TOOL_SETUP_DATA}
                component={DsoRegistrationForm}
              />
              <Route
                exact={true}
                path={ROUTES.SETUP_LEAD_SETUP_TOOL_SETUP_DATA}
                render={(props) => (
                  <DsoRegistrationForm {...props} leadStage={LEAD_IN_SETUP_STAGE} />
                )}
              />
              {isFormFulfilled && (
                <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_DSO_REGISTRATION}
                  component={DsoRegistration}
                />
              )}
              <Route
                exact={true}
                path={ROUTES.SETUP_LEAD_SETUP_TOOL_DSO_COMMISSIONING}
                component={DsoCommissioning}
              />
              {isFormFulfilled && (
                <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_PV_REGISTER}
                  component={PVRegister}
                />
              )}
              <Route
                exact={true}
                path={ROUTES.LEAD_SETUP_TOOL_METER_PREPARATION}
                component={DsoRegistrationForm}
              />
              {isVppEnabled && (
                <>
                  <Route
                    exact={true}
                    path={ROUTES.LEAD_SETUP_TOOL_VPP_DOCUMENTATION}
                    component={VppDocumentation}
                  />
                  <Route
                    exact={true}
                    path={ROUTES.SETUP_LEAD_SETUP_TOOL_VPP_DOCUMENTATION}
                    render={() => <VppDocumentation leadStage={LEAD_IN_SETUP_STAGE} />}
                  />
                </>
              )}
              <Route
                exact={true}
                path={ROUTES.SETUP_LEAD_SETUP_TOOL_PRE_COMMISSIONING}
                render={() => <PreCommissioning leadStage={LEAD_IN_SETUP_STAGE} />}
              />
            </>
          )}
          {/*
              this one doesn't require submissionId as it's available
              only for some countries if rollout FF is enabled
            */}
          <Route
            exact={true}
            path={ROUTES.LEAD_SETUP_TOOL_PRE_COMMISSIONING}
            component={PreCommissioning}
          />
        </Switch>
      </Loadable>
      <SetupToolContact />
    </>
  );
};

export const SetupTool = connect(mapStateToProps, mapDispatchToProps)(SetupToolComponent);
