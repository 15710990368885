export enum LeadLargeEnergyConsumer {
  ELECTRIC_CAR = 'electricCar',
  HEAT_PUMP = 'heatPump',
  INFRARED_HEATING = 'infraredHeating',
  POOL_OR_SAUNA = 'poolOrSauna',
  STORAGE_HEATER = 'storageHeater',
  OTHER = 'other',
}

export enum LeadEnergyCostInEuro {
  LT_1000 = '(0;1000)',
  BTW_1000_AND_1500 = '<1000;1500)',
  BTW_1500_AND_2000 = '<1500;2000)',
  GT_2000 = '<2000;Infinity)',
  UNKNOWN = '',
}
