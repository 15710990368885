import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { CountryFlagProvider } from '@sonnen/shared-web';

import { HttpResponseError } from '@coolio/http';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, merge, Observable, of, timer } from 'rxjs';
import { filter, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { getIsBatteryRemoteSettingsAllowed } from '+app/+customer/+battery/store/+battery.selectors';
import {
  POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY,
  POST_BATTERY_BACKUP_BUFFER_QUERY,
  POST_BATTERY_HIGH_TARIFF_QUERY,
  POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY,
  POST_BATTERY_INTELLIGENT_CHARGING_QUERY,
  POST_BATTERY_OPERATING_MODE_POLLING_QUERY,
  POST_BATTERY_OPERATING_MODE_QUERY,
  POST_BATTERY_TIME_OF_USE_QUERY,
} from '+app/+customer/+battery/store/+battery.state';
import { PvSystemsActions } from '+app/+customer/+pvSystems/store/pvSystems.actions';
import { CustomerActions } from '+app/+customer/store';
import { HOURS_IN_DAY } from '+app/App.constants';
import { dataGuard, isNotNil, makeQuery, ofType, polling, processQuery } from '+app/utils';
import { tariffWindowManipulator } from '+customer-battery/store/battery-tariff';
import { SiteActions } from '+shared/store/site';
import { dateUtil } from '+utils/date.util';
import { UtilDuration } from '+utils/UtilDuration';

import { LayoutActions } from '../layout';
import { SiteRepository } from '../site/site.repository';
import { getSiteOperatingMode } from '../site/site.selectors';
import { StoreState } from '../store.interface';
import { BatteryActions } from './battery.actions';
import {
  isRequestedValueSet,
  isThresholdValidated,
  prepareBackupPowerBufferNotification,
  prepareBatteryIntelligentChargingNotification,
  prepareBatteryOperatingModeNotification,
  prepareRemoteControlNotification,
} from './battery.helpers';
import { BatteryRepository } from './battery.repository';

type Action$ = ActionsObservable<BatteryActions>;
type State$ = StateObservable<StoreState>;

export const getBattery$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBattery),
    mergeMap(({ batteryId, queryKey }) =>
      isNotNil(batteryId)
        ? makeQuery(queryKey)({
            call: () => BatteryRepository.getBattery(batteryId),
            onSuccess: (res) => {
              CountryFlagProvider.setBatteryCountry(res!.element.installationCountryCode);
              return dataGuard(BatteryActions.setBattery)(res!.element);
            },
            onFailure: (_) => of(BatteryActions.setBattery(undefined)),
          })
        : of(BatteryActions.setBattery(undefined))
    )
  );

export const getBatteryElectricUnits$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBatteryElectricUnits),
    mergeMap(({ batteryId, queryKey }) =>
      of(batteryId).pipe(
        processQuery(queryKey, BatteryRepository.getBatteryElectricUnits, {
          onSuccess: (res) => dataGuard(BatteryActions.setBatteryElectricUnits)(res!.elements),
        })
      )
    )
  );

export const getBatteryStatuses$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBatteryStatuses),
    mergeMap(({ batteryId, queryKey, start, end }) =>
      of(batteryId).pipe(
        filter(() => dateUtil.getDifference(start, end, 'hours') <= HOURS_IN_DAY),
        processQuery(queryKey, () => BatteryRepository.getBatteryStatuses(batteryId, start, end), {
          onSuccess: (res) => dataGuard(BatteryActions.setBatteryStatuses)(res!.elements),
        })
      )
    )
  );

export const getBatteryIcStatus$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBatteryIcStatus),
    mergeMap(({ batteryId, queryKey }) =>
      isNotNil(batteryId)
        ? makeQuery(queryKey)({
            call: () => BatteryRepository.getBatteryIcStatus(batteryId),
            onSuccess: (res) => {
              return dataGuard(BatteryActions.setBatteryIcStatus)(res.element);
            },
            onFailure: (_) => of(BatteryActions.setBatteryIcStatus(undefined)),
          })
        : of(BatteryActions.setBatteryIcStatus(undefined))
    )
  );

export const setBatteryIntelligenCharging$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(BatteryActions.setBatteryIntelligentCharging),
    withLatestFrom(state$),
    filter(([action, state]) => getIsBatteryRemoteSettingsAllowed(state)),
    mergeMap(([{ batteryId, intelligentChargingValue }]) =>
      makeQuery(POST_BATTERY_INTELLIGENT_CHARGING_QUERY)({
        call: () =>
          BatteryRepository.postBatteryIntelligentCharging(batteryId, intelligentChargingValue),
        onSuccess: () =>
          of(
            BatteryActions.startBatteryPolling({
              queryKey: POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY,
              batteryId,
            })
          ),
        onFailure: () =>
          of(
            BatteryActions.setIsBatteryIntelligentChargingInProgress(false),
            LayoutActions.enqueueNotification(
              prepareBatteryIntelligentChargingNotification(null),
              'error'
            )
          ),
      })
    )
  );

const getBatteryPolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    polling({
      startOn: BatteryActions.startBatteryPolling,
      stopOn: [BatteryActions.stopBatteryPolling, CustomerActions.clearCustomerData],
      interval: 5000,
    })(({ queryKey, batteryId }) =>
      makeQuery(queryKey)({
        call: () => BatteryRepository.getBattery(batteryId!),
        onSuccess: (res) =>
          of(res.element).pipe(
            withLatestFrom(state$),
            filter(([battery, state]) => isRequestedValueSet(queryKey, battery, state)),
            mergeMap(([battery]) =>
              merge(
                of(BatteryActions.setBattery(battery)),
                of(BatteryActions.stopBatteryPolling()),
                queryKey === POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY
                  ? of(BatteryActions.setIsBatteryBackupBufferInProgress(false))
                  : queryKey === POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY
                  ? of(BatteryActions.setIsBatteryIntelligentChargingInProgress(false))
                  : of(PvSystemsActions.setIsBatteryPvGridFeedInLimitInProgress(false)),
                of(
                  LayoutActions.enqueueNotification(
                    prepareRemoteControlNotification(queryKey, battery, 'success'),
                    'info'
                  )
                )
              )
            )
          ),
        onFailure: () =>
          of(
            LayoutActions.enqueueNotification(
              prepareRemoteControlNotification(queryKey, null, 'failure'),
              'error'
            )
          ),
      })
    )
  );

const getBatteryPollingTimeout$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.startBatteryPolling),
    mergeMap(({ queryKey }) =>
      timer(UtilDuration.ofMinutes(1).asMilliseconds()).pipe(
        takeUntil(action$.pipe(ofType(BatteryActions.stopBatteryPolling))),
        mergeMap(() =>
          of(
            BatteryActions.stopBatteryPolling(),
            queryKey === POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY
              ? of(BatteryActions.setIsBatteryBackupBufferInProgress(false))
              : queryKey === POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY
              ? of(BatteryActions.setIsBatteryIntelligentChargingInProgress(false))
              : of(PvSystemsActions.setIsBatteryPvGridFeedInLimitInProgress(false)),
            LayoutActions.enqueueNotification(
              prepareRemoteControlNotification(queryKey, null, 'failure'),
              'error'
            )
          )
        )
      )
    )
  );

export const setBatteryOperatingMode$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(BatteryActions.setBatteryOperatingMode),
    withLatestFrom(state$),
    filter(([action, state]) => getIsBatteryRemoteSettingsAllowed(state)),
    mergeMap(([{ batteryId, operatingMode, siteId }]) =>
      makeQuery(POST_BATTERY_OPERATING_MODE_QUERY)({
        call: () => BatteryRepository.postBatteryOperatingMode(batteryId, operatingMode),
        onSuccess: () =>
          of(
            BatteryActions.setIsBatteryOperatingModeInProgress(true),
            BatteryActions.startLiveStatePollingOnBatteryChange({
              siteId,
              queryKey: POST_BATTERY_OPERATING_MODE_POLLING_QUERY,
            })
          ),
        onFailure: () =>
          of(
            BatteryActions.setIsBatteryOperatingModeInProgress(false),
            LayoutActions.enqueueNotification(
              prepareBatteryOperatingModeNotification(null),
              'error'
            )
          ),
      })
    )
  );

export const setBatteryBackupBuffer$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.setBatteryBackupBuffer),
    mergeMap(({ batteryId, backupBuffer }) =>
      makeQuery(POST_BATTERY_BACKUP_BUFFER_QUERY)({
        call: () => BatteryRepository.postBatteryBackupBuffer(batteryId, backupBuffer),
        onSuccess: () =>
          of(
            BatteryActions.startBatteryPolling({
              queryKey: POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY,
              batteryId,
            })
          ),
        onFailure: () =>
          of(
            BatteryActions.setIsBatteryBackupBufferInProgress(false),
            // Change
            LayoutActions.enqueueNotification(prepareBackupPowerBufferNotification(null), 'error')
          ),
      })
    )
  );

const getLiveStatePollingOnBatteryChange$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    polling({
      startOn: BatteryActions.startLiveStatePollingOnBatteryChange,
      stopOn: [
        BatteryActions.stopLiveStatePollingOnBatteryChange,
        CustomerActions.clearCustomerData,
      ],
      interval: 5000,
    })(({ queryKey, siteId }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteLiveState(siteId),
        onSuccess: (res) =>
          of(res.element).pipe(
            withLatestFrom(state$),
            filter(([site, state]) => site.batteryOperatingMode !== getSiteOperatingMode(state)),
            mergeMap(([site]) =>
              of(
                SiteActions.setSiteLiveState(site),
                BatteryActions.stopLiveStatePollingOnBatteryChange(),
                BatteryActions.setIsBatteryOperatingModeInProgress(false),
                LayoutActions.enqueueNotification(
                  prepareBatteryOperatingModeNotification(site.batteryOperatingMode),
                  'info'
                )
              )
            )
          ),
        onFailure: () =>
          of(
            LayoutActions.enqueueNotification(
              prepareBatteryOperatingModeNotification(null),
              'error'
            )
          ),
      })
    )
  );

const getLiveStatePollingOnBatteryChangeTimeout$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.startLiveStatePollingOnBatteryChange),
    mergeMap(() =>
      timer(UtilDuration.ofMinutes(1).asMilliseconds()).pipe(
        takeUntil(action$.pipe(ofType(BatteryActions.stopLiveStatePollingOnBatteryChange))),
        mergeMap(() =>
          of(
            BatteryActions.stopLiveStatePollingOnBatteryChange(),
            BatteryActions.setIsBatteryOperatingModeInProgress(false),
            LayoutActions.enqueueNotification(
              prepareBatteryOperatingModeNotification(null),
              'error'
            )
          )
        )
      )
    )
  );

const postBatteryTimeOfUse$ = (action$: Action$) => {
  return action$.pipe(
    ofType(BatteryActions.postBatteryTimeOfUse),
    mergeMap(({ tariffWindows, batteryId }) =>
      makeQuery(POST_BATTERY_TIME_OF_USE_QUERY)({
        call: () =>
          BatteryRepository.postBatteryTimeOfUseSchedule(
            batteryId,
            tariffWindowManipulator.prepareTimeOfUseDataToSend(tariffWindows)
          ),

        onSuccess: () =>
          concat(
            makeQuery(POST_BATTERY_HIGH_TARIFF_QUERY)({
              call: () =>
                BatteryRepository.postBatteryHighTariffWindowSchedule(
                  batteryId,
                  tariffWindowManipulator.prepareHighTariffWindowsDataToSend(tariffWindows)
                ),
              onSuccess: () =>
                of(
                  LayoutActions.enqueueNotification(
                    I18n.t(T.customerSingle.batteryDetails.timeOfUse.notifications.success, {
                      name: I18n.t(
                        T.customerSingle.batteryDetails.batteryLive.operatingMode.timeOfUse
                      ),
                    }),
                    'success'
                  ),
                  BatteryActions.updateTariffWindowScheduleEditStatus({
                    isTariffWindowScheduleEditedButNotSaved: false,
                  }),
                  BatteryActions.updateLocalChanges()
                ),
              onFailure: () =>
                of(
                  BatteryActions.resetLocalChanges(),
                  LayoutActions.enqueueNotification(
                    I18n.t(T.customerSingle.batteryDetails.timeOfUse.notifications.failed, {
                      name: I18n.t(
                        T.customerSingle.batteryDetails.batteryLive.operatingMode.timeOfUse
                      ),
                    }),
                    'error'
                  )
                ),
            })
          ),

        onFailure: (error: HttpResponseError): Observable<LayoutActions | BatteryActions> =>
          concat(
            isThresholdValidated(error).pipe(
              mergeMap((msg: string) =>
                of(
                  BatteryActions.resetLocalChanges(),
                  LayoutActions.enqueueNotification(msg, 'error')
                )
              )
            )
          ),
      })
    )
  );
};

export const epics = combineEpics(
  getBattery$,
  getBatteryElectricUnits$,
  getBatteryStatuses$,
  getBatteryIcStatus$,
  setBatteryIntelligenCharging$,
  getBatteryPolling$,
  getBatteryPollingTimeout$,
  setBatteryOperatingMode$,
  setBatteryBackupBuffer$,
  getLiveStatePollingOnBatteryChange$,
  getLiveStatePollingOnBatteryChangeTimeout$,
  postBatteryTimeOfUse$
);
