import { isUndefined } from 'lodash/fp';

import { CountryFeatureName } from '+config/countryFlags';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { getOfflineSince } from '+shared/store/battery';
import { SiteLiveState, SiteMeasurements } from '+shared/store/site/types';

import { Battery } from '../../battery/types/battery.interface';
import { isContractSonnenFlat, isContractSonnenFlatX } from '../../contract/contract.helpers';
import { Contract, MeterInstallationAttributes } from '../../contract/types/contract.interface';
import { missingLatestAnalysisDataFactory } from './analysisWarning.factory';
import { missingInstallationDateWarningFactory } from './installationWarning.factory';
import { meterInstallationWarningFactory } from './meterInstallationWarning.factory';
import { missingPvRemoteControlWarningFactory } from './pvRemoteControlWarning.factory';
import { CustomerWarning } from './warnings.types';

interface BatteryWarningsFactoryInput {
  battery: Battery | undefined;
  siteLiveState: SiteLiveState | undefined;
}

interface AnalysisWarningsFactoryInput {
  siteMeasurements: SiteMeasurements | undefined;
  battery: Battery | undefined;
}

type Warnings =
  | ReturnType<typeof getBatteryWarnings>
  | ReturnType<typeof getContractWarnings>
  | ReturnType<typeof getAnalysisWarnings>;

export const getBatteryWarnings = ({ battery, siteLiveState }: BatteryWarningsFactoryInput) => ({
  batteryOffline:
    battery && siteLiveState && !siteLiveState.online
      ? { message: getOfflineSince(siteLiveState.timestamp) }
      : undefined,
  missingInstallationDate: !isUndefined(battery)
    ? missingInstallationDateWarningFactory(battery)
    : undefined,
});

export const getContractWarnings = ({
  contract,
  meterInstallation,
}: {
  contract?: Contract;
  meterInstallation?: MeterInstallationAttributes;
}) => {
  if (
    isUndefined(contract) ||
    !isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.CONTRACT_NOTIFICATIONS)
  ) {
    return {
      missingPvRemoteControl: undefined,
      meterInstallationWarningFactory: undefined,
    };
  }
  return {
    missingPvRemoteControl:
      isContractSonnenFlat(contract) || isContractSonnenFlatX(contract)
        ? missingPvRemoteControlWarningFactory(meterInstallation)
        : undefined,
    meterInstallationWarningFactory:
      isContractSonnenFlat(contract) || isContractSonnenFlatX(contract)
        ? meterInstallationWarningFactory(meterInstallation)
        : undefined,
  };
};

export const getAnalysisWarnings = ({
  siteMeasurements,
  battery,
}: AnalysisWarningsFactoryInput) => ({
  missingLatestAnalysisData: !isUndefined(siteMeasurements)
    ? missingLatestAnalysisDataFactory({ siteMeasurements, battery })
    : undefined,
});

export const getWarningMessages = (warnings: Warnings, extended?: boolean) =>
  Object.values(warnings)
    .filter(Boolean)
    .map((warning) => warning as NonNullable<CustomerWarning>);

export const getOnlyDangerWarnings = (warnings: CustomerWarning[]) =>
  warnings.filter((warning) => warning.type === 'danger');
