export enum PvRegisterStatus {
  IN_PROGRESS = 'in_progress',
  TRIGGERED = 'triggered',
  CAPTCHA = 'captcha',
  ERROR = 'error',
  FINISH = 'finish',
  FINISH_MANUALLY = 'finish_manually',
  SKIPPED = 'skipped',
}

export enum PVRegisterInfoStatus {
  NOT_SENT = 'not-sent',
  SENT = 'sent',
  GENERATED = 'generated',
  GENERATED_BATTERY_ONLY = 'generated-battery-only',
}

export const POLLING_TIME = 10000; /* @TODO STORE IT IN GLOBAL CONFIG*/
