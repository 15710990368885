import * as React from 'react';

import { ScrollableContainer } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { SlideType } from '../../containers/Tutorial/Tutorial.helper';

import './TutorialSlide.component.scss';

interface Props {
  numAllSlides: number;
  slideNumber: number;
  slideType: SlideType;
  title: string;
  description: string;
  linkHref?: string;
  linkText?: string;
  imgSrc?: string;
  videoSrc?: string;
}

export const TutorialSlide: React.FC<Props> = ({
  numAllSlides,
  slideNumber,
  slideType,
  title,
  description,
  linkHref,
  linkText,
  imgSrc,
  videoSrc,
}) => (
  <div
    className={classNames('tutorial-step', {
      'tutorial-step--text-only': slideType === SlideType.TEXT_ONLY,
    })}
  >
    <div className={'tutorial-step__content tutorial-step__content--text'}>
      <p className={'tutorial-step__navigation'}>
        {slideNumber + 1} of {numAllSlides}
      </p>
      <p className={'tutorial-step__title'}>{title}</p>
      <ScrollableContainer height={slideType === SlideType.TEXT_ONLY ? 130 : 80}>
        <p className={'tutorial-step__description'}>{description}</p>
      </ScrollableContainer>
      <a className={'tutorial-step__link'} href={linkHref} target={'_blank'} rel="noreferrer">
        {linkText}
      </a>
    </div>
    {slideType !== SlideType.TEXT_ONLY && (
      <div className={'tutorial-step__content'}>
        {slideType === SlideType.TEXT_AND_VIDEO && (
          <iframe
            className={'tutorial-step__video'}
            width="400"
            height="236"
            src={videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          />
        )}
        {slideType === SlideType.TEXT_AND_IMAGE && <img src={imgSrc} />}
      </div>
    )}
  </div>
);
