import { flow } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, delay, ignoreElements, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { mapPathToParams, mapToState, matchPath, ofType } from '+app/utils';
import { AuthActions } from '+shared/store/auth';
import { StoreState } from '+shared/store/store.interface';
import { decode, download, pdf, revokeUrl, toBuffer } from '+utils/file.util';

import { Locale } from '../../i18n/i18n.config';
import { PUBLIC_ROUTES, ROUTES } from '../../router';
import { RouterActions } from '../../router/store/router.actions';
import { StaticActions } from './+static.actions';
import { mapFilenameToDocumentType } from './+static.helper';
import { StaticRepository } from './+static.repository';

type Action$ = ActionsObservable<StaticActions | RouterActions>;
type State$ = StateObservable<StoreState>;

export const getDocument$ = (action$: Action$) =>
  action$.pipe(
    ofType(StaticActions.getDocument),
    switchMap((params) =>
      StaticRepository.getDocument(params).pipe(
        map((response) => StaticActions.download(response, params.documentType))
      )
    ),
    catchError((err) => {
      console.log(err);
      return of(null);
    })
  );

export const download$ = (action$: Action$) =>
  action$.pipe(
    ofType(StaticActions.download),
    tap(({ base64Encoded, fileName }) =>
      flow(decode, toBuffer, pdf, download(fileName), revokeUrl(0))(base64Encoded)
    ),
    ignoreElements()
  );

export const downloadPage$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange),
    mapToState(state$),
    mapPathToParams(PUBLIC_ROUTES.DOWNLOAD[0]),
    map(([fileName]) =>
      StaticActions.getDocument(
        mapFilenameToDocumentType(fileName),
        Locale.DE,
        Locale.DE.toUpperCase()
      )
    )
  );

export const logout$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange),
    mapToState(state$),
    matchPath(ROUTES.LOGOUT),
    delay(1000),
    mapTo(AuthActions.logout())
  );

export const epics = combineEpics<any>(getDocument$, download$, downloadPage$, logout$);
