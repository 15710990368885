import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Filter: React.FC<Props> = ({ color, size = 24, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M20.352 3.5C20.777 3.5 21.0082 3.99663 20.7346 4.32187L13.9521 12.3846C13.7247 12.655 13.6 12.9969 13.6 13.3502V20.3781C13.6 20.7503 13.208 20.992 12.8754 20.8249L10.6754 19.7191C10.5066 19.6342 10.4 19.4613 10.4 19.2723V13.3502C10.4 12.9969 10.2753 12.655 10.0479 12.3846L3.26536 4.32187C2.99177 3.99664 3.22298 3.5 3.64798 3.5H20.352Z"
      stroke={color}
    />
  </svg>
);
