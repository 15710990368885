import { SelectCategory } from '+shared/store/firebase/types';
import { Market } from '+utils/market.util';

export const videoMarkets = 'videoMarkets';
export const videoCategory = 'videoCategory';
export const youtubeLink = 'youtubeLink';
export interface AddVideoForm {
  [videoMarkets]: Market[];
  [videoCategory]: SelectCategory;
  [youtubeLink]: string;
}

export const isYoutubeLink = (value: string) => new URL(value).hostname.includes('youtube.com');

export const getYouTubeId = (value: string) => new URL(value).searchParams.get('v');

export const validate = (values: AddVideoForm) => {
  const errors = {};

  if (!values[youtubeLink] || !values[youtubeLink].length) {
    errors[youtubeLink] = 'Youtube link cannot be empty';
  }

  if (values[youtubeLink] && !isYoutubeLink(values[youtubeLink])) {
    errors[youtubeLink] = 'Enter correct Youtube link with video id';
  }

  if (!values[videoCategory]) {
    errors[videoCategory] = 'Choose category';
  }

  return errors;
};
