var DataContainerExtension = (function () {
    function DataContainerExtension(name) {
        this.name = name;
        this.dataContainers = [];
    }
    DataContainerExtension.prototype.attach = function (dataContainer) {
        if (this.dataContainers.includes(dataContainer)) {
            return;
        }
        this.dataContainers.push(dataContainer);
        this.onAttach(dataContainer);
    };
    DataContainerExtension.prototype.detach = function (dataContainer) {
        var index = this.dataContainers.indexOf(dataContainer);
        if (index < 0) {
            return;
        }
        this.dataContainers.splice(index, 1);
        this.onDetach(dataContainer);
    };
    DataContainerExtension.prototype.onChartPointerEvent = function (_event) {
        return false;
    };
    DataContainerExtension.prototype.onAttach = function (_dataContainer) {
    };
    DataContainerExtension.prototype.onDetach = function (_dataContainer) {
    };
    DataContainerExtension.prototype.postEvent = function (eventType, payload) {
        for (var i = 0, l = this.dataContainers.length; i < l; i++) {
            payload = this.dataContainers[i].postEvent(eventType, payload);
        }
        return payload;
    };
    return DataContainerExtension;
}());
export { DataContainerExtension };
