import * as React from 'react';

import { useComponentWidth } from './useComponentWidth.hook';

export const useLoadingStateButtonWidth = (
  label?: string | JSX.Element,
  loaderComp?: JSX.Element,
  loadingLabel?: string
) => {
  const [width, setWidth] = React.useState(0);
  React.useLayoutEffect(() => {
    setWidth(Math.max(labelWidth, loaderWidth + loadingLabelWidth));
  });

  const [labelWidth, labelWithRef] = useComponentWidth(label);
  const [loaderWidth, loaderWithRef] = useComponentWidth(loaderComp);
  const [loadingLabelWidth, loadingLabelWithRef] = useComponentWidth(loadingLabel);

  return {
    width,
    componentsToRenderHidden: [loaderWithRef, labelWithRef, loadingLabelWithRef],
  };
};
