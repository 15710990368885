import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import { FormInputInfo } from '+shared/components/FormInputInfo';
import { formatNumber } from '+utils/format.util.old';

import './LeadConfigurationHint.component.scss';

interface Props {
  reference: 'pv' | 'battery';
  isPending: boolean;
  hintValue?: string | number;
  optionalHintValue?: string | number;
}

interface RecommendationProps {
  reference: 'pv' | 'battery';
  hintValue?: string | number;
  optionalHintValue?: string | number;
}

const getHintValueUnit = (reference: 'pv' | 'battery'): string => {
  return reference === 'pv' ? T.units.pvPower : T.units.batteryCapacity;
};

const getHintValuePrecision = (reference: 'pv' | 'battery'): number => {
  return reference === 'pv' ? 3 : 1;
};

const Recommendation: React.FC<RecommendationProps> = ({ reference, hintValue }) => {
  const unit = getHintValueUnit(reference);
  const precision = getHintValuePrecision(reference);

  return (
    <FormInputInfo>
      <span
        className={'c-lead-configuration-pv-hint__text c-lead-configuration-pv-hint__text--dark'}
      >
        {I18n.t(T.lead.configuration._salessolution_.minimum)}{' '}
        {I18n.t(unit, { value: formatNumber({ precision })(Number(hintValue)) })}{' '}
      </span>
      {I18n.t(T.lead.configuration._salessolution_.pv.hint.mainHintForOneValue)}
    </FormInputInfo>
  );
};

const DoubleRecommendation: React.FC<RecommendationProps> = ({
  reference,
  hintValue,
  optionalHintValue,
}) => {
  const unit = getHintValueUnit(reference);
  const precision = getHintValuePrecision(reference);

  const valueWithUnit: string = I18n.t(unit, {
    value: formatNumber({ precision })(Number(hintValue)),
  });
  const optionalValueWithUnit: string = I18n.t(unit, {
    value: formatNumber({ precision })(Number(optionalHintValue)),
  });
  const mainHintParts: string[] = I18n.t(
    T.lead.configuration._salessolution_.pv.hint.mainHintForTwoValues
  ).split(/%{[^}]*}/, 3);

  return (
    <FormInputInfo>
      <span
        className={'c-lead-configuration-pv-hint__text c-lead-configuration-pv-hint__text--dark'}
      >
        {mainHintParts[0]}
        <b>
          {valueWithUnit}
          {mainHintParts[1]}
          {optionalValueWithUnit}
        </b>
        {mainHintParts[2]}
      </span>
      <p className={'c-lead-configuration-pv-hint__text'}>
        {valueWithUnit}{' '}
        <span>
          {I18n.t(T.lead.configuration._salessolution_[reference].hint.basedOnSonnenFlatDirect)}
        </span>
      </p>
      <p className={'c-lead-configuration-pv-hint__text'}>
        {optionalValueWithUnit}{' '}
        <span>{I18n.t(T.lead.configuration._salessolution_[reference].hint.basedOnEegLevel)}</span>
      </p>
    </FormInputInfo>
  );
};

export const LeadConfigurationHint: React.FC<Props> = ({
  hintValue,
  reference,
  isPending,
  optionalHintValue,
}) => {
  const isVisible = isPending || hintValue || optionalHintValue;

  if (!isVisible) return null;

  if (isPending) {
    return (
      <div className={'c-lead-configuration-pv-hint'}>
        <Loader className={'c-lead-configuration-pv-hint__loader'} />
        <p className={'c-lead-configuration-pv-hint__text'}>
          {I18n.t(T.lead.configuration._salessolution_[reference].hint.pending)}
        </p>
      </div>
    );
  }

  if (!optionalHintValue || hintValue === optionalHintValue) {
    return (
      <div className={'c-lead-configuration-pv-hint'}>
        <Recommendation reference={reference} hintValue={hintValue} />
      </div>
    );
  }

  return (
    <div className={'c-lead-configuration-pv-hint'}>
      <DoubleRecommendation
        reference={reference}
        hintValue={hintValue}
        optionalHintValue={optionalHintValue}
      />
    </div>
  );
};
