import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loadable, Loader } from '@sonnen/shared-web';

import './Logout.component.scss';

const LogoutLoader: React.SFC = () => (
  <div className={'c-logout-loader'}>
    <Loader />
    <div className={'c-logout-loader__headline'}>{I18n.t(T.general.logout.title)}</div>
  </div>
);

export const Logout: React.SFC = () => (
  <Loadable predicate={true} transition={'fade'} loader={<LogoutLoader />} />
);
