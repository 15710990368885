import * as React from 'react';

import classNames from 'classnames';

import { getInputType } from './Input.helper';

// @TODO: Delete this, use shared-web version
// @NOTE: This component uses styles from shared-web(!)

export type OnInputChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

interface Props {
  autocomplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  floatingLabel?: boolean;
  label: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'textarea' | string;
  name: string;
  value?: string;
  onChange: (event: OnInputChangeEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  error?: string;
  isTouched: boolean;
  maxLength?: number;
}

interface State {
  isActive: boolean;
  masked: boolean;
}

export class Input extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    autocomplete: 'off',
    disabled: false,
    floatingLabel: false,
    type: 'text',
    value: '',
    autoFocus: false,
  };

  readonly state: State = {
    isActive: false,
    masked: true,
  };

  handleFocus = (e: React.SyntheticEvent) => this.setState({ isActive: true });

  changeMode = () => {
    this.setState((state) => ({ masked: !state.masked }));
  };

  render() {
    const {
      type,
      disabled,
      autocomplete,
      name,
      floatingLabel,
      label,
      value,
      onChange,
      error,
      placeholder,
      onBlur,
      isTouched,
      maxLength,
      autoFocus,
    } = this.props;
    const { isActive, masked } = this.state;

    const classes = {
      input: classNames('sw-input__field', {
        'sw-input__field--disabled': disabled,
        'sw-input__field--textarea': type === 'textarea',
      }),
      label: classNames('sw-input__label', {
        'sw-input__label--floating': floatingLabel,
        'is-active': isActive || !!value,
      }),
      maskButton: classNames('sw-input__password-mask icon-eye', {
        'is-active': !masked,
      }),
    };

    return (
      <div className={'sw-input'}>
        {type === 'textarea' ? (
          <textarea
            value={value}
            className={classes.input}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            onFocus={this.handleFocus}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={maxLength}
            autoFocus={autoFocus}
          />
        ) : (
          <input
            type={getInputType(type, masked)}
            className={classes.input}
            disabled={disabled}
            autoComplete={autocomplete}
            name={name}
            value={value}
            onFocus={this.handleFocus}
            onBlur={onBlur}
            onChange={onChange}
            autoFocus={autoFocus}
          />
        )}
        <label className={classes.label} htmlFor={name}>
          {label}
        </label>
        {type === 'password' && (
          <span
            className={classes.maskButton}
            onClick={() => this.changeMode()}
            role="checkbox"
            tabIndex={-1}
            aria-checked={!masked}
          />
        )}
        {error && isTouched && (
          <div className={'sw-input__info'}>
            <p className={'sw-input__alert'}>{error}</p>
          </div>
        )}
      </div>
    );
  }
}
