import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import { Form, Formik } from 'formik';

import { CustomerNote, FormState } from '+app/+customer/containers/CustomerNote';
import { WidgetWarning } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { Widget } from '+shared/components/Widget/Widget';

import './CustomerNoteWidget.component.scss';

interface Props {
  customerId: string;
  customerNote: string;
}

export const CustomerNoteWidget: React.FC<Props> = ({ customerId, customerNote }) => (
  <CustomerNote
    customerId={customerId}
    customerNote={customerNote}
    renderForm={({ onFormSubmit, note, editNote, formState, isEditActive }) => (
      <Widget
        icon={'NotesWithPencil'}
        heading={I18n.t(T.customerSingle.overview.notes.headline)}
        customAction={
          !isEditActive &&
          customerNote && (
            <Button
              label={I18n.t(T.general.basicActions.edit)}
              type={ButtonType.TERTIARY}
              onClick={editNote}
            />
          )
        }
      >
        <Formik
          initialValues={{ note: note || customerNote }}
          onSubmit={onFormSubmit}
          render={(form) => (
            <Form>
              <FormTextarea
                form={form}
                placeholder={I18n.t(T.customerSingle.overview.notes.placeholder)}
                name={'note'}
                disabled={(!isEditActive && !!customerNote) || formState === FormState.FORM_PENDING}
              >
                {formState === FormState.FORM_PENDING && (
                  <Loader className={'c-customer-note-widget-form__loader'} />
                )}
              </FormTextarea>
              {(isEditActive || !customerNote) && (
                <div
                  className={'c-customer-note-widget-form__btn gtm-customer-note-widget-form__btn'}
                >
                  <Button
                    mainType={MainType.SUBMIT}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.SMALL}
                    label={I18n.t(T.general.basicActions.save)}
                    disabled={formState === FormState.FORM_PENDING}
                    status={
                      formState === FormState.FORM_PENDING
                        ? ButtonStatus.DISABLED
                        : ButtonStatus.ENABLED
                    }
                  />
                </div>
              )}
            </Form>
          )}
        />
      </Widget>
    )}
    renderError={(error) => (
      <WidgetWarning type={'warning'} icon={'warning'}>
        {error}
      </WidgetWarning>
    )}
  />
);
