import { LeadOffer } from '+shared/store/lead/types/leadOffer.interface';
import { UtilityData } from '+shared/store/lead/types/leadUtilityData.interface';
import { ActionsUnion } from '+utils/redux/actionsUnion.util';
import { createAction } from '+utils/redux/createAction.util';

import { EnergyProvider } from '../../../shared/store/lead/types';

export enum LEAD_OFFER_PAGE_ACTIONS {
  SEND_OFFER = '[+LeadOffer] SEND_OFFER',
  GET_OFFER = '[+LeadOffer] GET_OFFER',
  SET_OFFER = '[+LeadOffer] SET_OFFER',
  CLEAR_DATA = '[+LeadOffer] CLEAR_DATA',
  GET_ENERGY_PROVIDERS = '[+LeadOffer] GET_ENERGY_PROVIDERS',
  SET_ENERGY_PROVIDERS = '[+LeadOffer] SET_ENERGY_PROVIDERS',
  GET_UTILITY_DATA = '[+LeadOffer] GET_UTILITY_DATA',
  SET_UTILITY_DATA = '[+LeadOffer] SET_UTILITY_DATA',
  PATCH_UTILITY_DATA = '[+LeadOffer] PATCH_UTILITY_DATA',
  PATCH_UTILITY_DATA_SUCCESS = '[+LeadOffer] PATCH_UTILITY_DATA_SUCCESS',
}

export const LeadOfferPageActions = {
  sendOffer: createAction(
    LEAD_OFFER_PAGE_ACTIONS.SEND_OFFER,
    (leadId: string, offerId: string, productId: string) => ({ leadId, offerId, productId })
  ),
  getOffer: createAction(LEAD_OFFER_PAGE_ACTIONS.GET_OFFER, (leadId: string, offerId: string) => ({
    leadId,
    offerId,
  })),
  setOffer: createAction(LEAD_OFFER_PAGE_ACTIONS.SET_OFFER, (leadId: string, offer: LeadOffer) => ({
    leadId,
    offer,
  })),
  clearData: createAction(LEAD_OFFER_PAGE_ACTIONS.CLEAR_DATA),
  getEnergyProviders: createAction(
    LEAD_OFFER_PAGE_ACTIONS.GET_ENERGY_PROVIDERS,
    (searchQuery: string) => ({ searchQuery })
  ),
  setEnergyProviders: createAction(
    LEAD_OFFER_PAGE_ACTIONS.SET_ENERGY_PROVIDERS,
    (energyProviders: EnergyProvider[]) => ({ energyProviders })
  ),
  getUtilityData: createAction(LEAD_OFFER_PAGE_ACTIONS.GET_UTILITY_DATA, (leadId: string) => ({
    leadId,
  })),
  setUtilityData: createAction(
    LEAD_OFFER_PAGE_ACTIONS.SET_UTILITY_DATA,
    (utilityData: UtilityData) => ({ utilityData })
  ),
  patchUtilityData: createAction(
    LEAD_OFFER_PAGE_ACTIONS.PATCH_UTILITY_DATA,
    (oldProvider: string, meterId: string) => ({ oldProvider, meterId })
  ),
  patchUtilityDataSuccess: createAction(LEAD_OFFER_PAGE_ACTIONS.PATCH_UTILITY_DATA_SUCCESS),
};

export type LeadOfferPageActions = ActionsUnion<typeof LeadOfferPageActions>;
