import * as React from 'react';

import { DefaultParagraph } from '@sonnen/shared-web';

import { LoaderWrapper } from '+shared/components/LoaderWrapper';

import { VideoModal } from '../VideoModal/VideoModal.component';
import { VideoThumbnail } from '../VideoThumbnail';

import './Video.component.scss';

interface Props {
  videoId: string;
  title: string;
  category: string;
  length: string;
  thumbnail: string;
}

export const Video: React.FC<Props> = ({ videoId, title, category, length, thumbnail }) => {
  const [isModalOpen, toggleModalOpen] = React.useState<boolean>(false);

  const handleToggle = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    toggleModalOpen(true);
  };

  return (
    <div className={'c-video'}>
      <button className={'c-video__wrapper'} onClick={handleToggle}>
        <LoaderWrapper
          render={(toggleLoading) => (
            <VideoThumbnail
              src={thumbnail}
              category={category}
              length={length}
              onLoad={() => toggleLoading(false)}
            />
          )}
        />
        <DefaultParagraph className={'c-video__title'}>{title}</DefaultParagraph>
      </button>
      <VideoModal videoId={videoId} isOpen={isModalOpen} onClose={() => toggleModalOpen(false)} />
    </div>
  );
};
