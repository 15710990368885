import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isFlatOnlyOffer } from '+app/+lead/+offer/store/+offer.helper';
import { LabelLarge, LabelMedium, LabelSmall } from '+shared/basicComponents/Typography/Labels';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { LeadConfigurationResponse, LeadOffer } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { useLocaleContext } from '+utils/react/locale.provider';

import { LeadConfigurationTileStatus } from '../..';
import { LeadConfigurationLine } from '../../LeadConfigurationLine';
import {
  mapConfigurationTileStatusForHardwareOffer,
  renderStatus,
} from '../../LeadConfigurationTile/LeadConfigurationTile.helper';
import {
  ConfigurationAttribute,
  factorizeAccessoryDetails,
  factorizeBatteryDetails,
  factorizeMultiplePvSystemDetails,
  factorizePvSystemDetails,
} from '../LeadFlatConfigurationTile.helper';

const DetailsParagraph: React.FC<{
  title: string;
  detailsArray: Array<ConfigurationAttribute> | null;
}> = ({ title, detailsArray }) => {
  if (!detailsArray) return null;

  return (
    <div className={'c-lead-flat-configuration-tile__details-paragraph'}>
      <div className={'c-lead-flat-configuration-tile__config-values-category-name'}>
        <LabelMedium text={title} />
      </div>
      {detailsArray.map(({ title, value, type }, index) => (
        <LeadConfigurationLine title={title} value={value} key={`detail-${index}`} type={type} />
      ))}
    </div>
  );
};

interface Props {
  configuration: LeadConfigurationResponse;
  hardwareStatus?: LeadConfigurationTileStatus;
  battery: LeadProductBattery;
  offer: LeadOffer;
  isStatusUpdateDisabled?: boolean;
  onHardwareStatusModalOpen?: (event: React.MouseEvent) => void;
}

const LeadFlatConfigurationTileHardware: React.FC<Props> = ({
  configuration,
  hardwareStatus = LeadConfigurationTileStatus.PREVIEW,
  battery,
  offer,
  isStatusUpdateDisabled,
  onHardwareStatusModalOpen,
}) => {
  const { locale } = useLocaleContext();

  const pvSystemDetails = factorizePvSystemDetails(configuration);
  const batteryDetails = factorizeBatteryDetails(locale)(battery);
  const mappedHardwareStatus = mapConfigurationTileStatusForHardwareOffer(hardwareStatus);

  const batteryAccessory = configuration.battery.accessories;
  const batteryAccessoryDetails = factorizeAccessoryDetails(batteryAccessory);

  return (
    <>
      <div className={'c-lead-flat-configuration-tile__header'}>
        <div className={'c-lead-flat-configuration-tile__header-subheadline'}>
          <LabelLarge
            text={I18n.t(T.lead.hardware._salessolution_.configurationTile.headline.hardware)}
          />
          {isFlatOnlyOffer(offer) && (
            <LabelSmall
              text={I18n.t(T.lead.configuration._salessolution_.preview.alreadyExisting)}
              className="c-lead-flat-configuration-tile__header-subheadline-span"
            />
          )}
        </div>
        <div className={'c-lead-configuration-tile__header-detail'}>
          {renderStatus(mappedHardwareStatus)}
        </div>
      </div>

      {configuration.photovoltaicSystem.subSystems ? (
        configuration.photovoltaicSystem.subSystems.map((subSystem, index) => (
          <DetailsParagraph
            title={subSystem.systemName!}
            detailsArray={factorizeMultiplePvSystemDetails(subSystem)}
            key={`sub-system-${index}`}
          />
        ))
      ) : (
        <DetailsParagraph
          title={I18n.t(T.lead.configuration._salessolution_.preview.pvSystem)}
          detailsArray={pvSystemDetails}
        />
      )}

      <DetailsParagraph
        title={I18n.t(T.lead.configuration._salessolution_.preview.sonnenBattery)}
        detailsArray={batteryDetails}
      />

      {batteryAccessory && !!batteryAccessory.length && (
        <DetailsParagraph
          title={I18n.t(T.lead.configuration._salessolution_.hardware.sonnenProtect)}
          detailsArray={batteryAccessoryDetails}
        />
      )}
      {onHardwareStatusModalOpen && !isFlatOnlyOffer && (
        <div className={'c-lead-flat-configuration-tile__status-button-wrapper'}>
          <Button
            label={I18n.t(T.lead.hardware._salessolution_.form.statusChange.button.updateStatus)}
            onClick={onHardwareStatusModalOpen}
            size={ButtonSize.SMALL}
            type={ButtonType.SECONDARY}
            disabled={isStatusUpdateDisabled}
            status={isStatusUpdateDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
          />
        </div>
      )}
    </>
  );
};

export default LeadFlatConfigurationTileHardware;
