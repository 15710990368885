import { createSelector } from 'reselect';

import { getLeadOverview } from '+app/+lead/store/+lead.selectors';
import { LeadOffer } from '+shared/store/lead/types';
import { getStatus } from '+shared/store/query/query.utils';

import {
  CLOSE_LEAD_QUERY,
  DELETE_EXPIRED_OFFER,
  DELETE_OFFER_QUERY,
  GET_LEAD_PRODUCT_AVAILABILITY_QUERY,
  GET_OFFER_LIST_QUERY,
  GET_PARTNER_NOTE_QUERY,
  GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY,
  GET_PRODUCT_BATTERY_LIST_QUERY,
  GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY,
  POST_PARTNER_NOTE_QUERY,
  RECALCULATE_CONFIGURATION_QUERY,
  SEND_CG_INVITATION_QUERY,
} from './+overview.state';

export const getOfferList = createSelector(getLeadOverview, (state) => state.offers);

export const getDynamicTariff = createSelector(
  getLeadOverview,
  (state) => state.hasActiveDynamicTariffContract
);

export const hasAnyOfferConfigurations = createSelector(getOfferList, (offers: LeadOffer[]) => {
  return offers.some((offer: LeadOffer) => !!offer.configuration);
});

export const getOfferListQuery = createSelector(
  getLeadOverview,
  (state) => state[GET_OFFER_LIST_QUERY]
);

export const getOfferListQueryStatus = createSelector(getOfferListQuery, (query) =>
  getStatus(query)
);

export const getLeadOfferDeleteQuery = createSelector(
  getLeadOverview,
  (state) => state[DELETE_OFFER_QUERY]
);

export const getLeadOfferDeleteQueryStatus = createSelector(getLeadOfferDeleteQuery, (query) =>
  getStatus(query)
);

export const getLastUpdatedLeadSection = createSelector(
  getLeadOverview,
  (state) => state.lastUpdatedLeadSection
);

export const isOpeningDocument = createSelector(
  getLeadOverview,
  (state) => state.isOpeningDocument
);

export const getFailedDocument = createSelector(getLeadOverview, (state) => state.failedDocument);

export const getIsFailingDocument = createSelector(
  getLeadOverview,
  (state) => state.failedDocumentModal
);

export const getDocumentUrl = createSelector(getLeadOverview, (state) => state.documentUrl);

export const getPartnerNoteQuery = createSelector(
  getLeadOverview,
  (state) => state[GET_PARTNER_NOTE_QUERY]
);

export const getPartnerNoteQueryStatus = createSelector(getPartnerNoteQuery, (query) =>
  getStatus(query)
);

export const postPartnerNoteQuery = createSelector(
  getLeadOverview,
  (state) => state[POST_PARTNER_NOTE_QUERY]
);

export const postPartnerNoteQueryStatus = createSelector(postPartnerNoteQuery, (query) =>
  getStatus(query)
);

export const getPartnerNotes = createSelector(getLeadOverview, (state) => state.partnerNotes);

export const getUpdatingOffer = createSelector(getLeadOverview, (state) => state.updatingOffer);

export const getRecalculateConfigurationQuery = createSelector(
  getLeadOverview,
  (state) => state[RECALCULATE_CONFIGURATION_QUERY]
);

export const getRecalculateConfigurationQueryStatus = createSelector(
  getRecalculateConfigurationQuery,
  getStatus
);

export const getCloseLeadQuery = createSelector(
  getLeadOverview,
  (state) => state[CLOSE_LEAD_QUERY]
);

export const getCloseLeadQueryStatus = createSelector(getCloseLeadQuery, (query) =>
  getStatus(query)
);

export const getLeadOverviewProductAvailability = createSelector(
  getLeadOverview,
  (state) => state.productAvailability
);

const getLeadOverviewProductAvailabilityQuery = createSelector(
  getLeadOverview,
  (state) => state[GET_LEAD_PRODUCT_AVAILABILITY_QUERY]
);

export const getLeadOverviewProductAvailabilityStatus = createSelector(
  getLeadOverviewProductAvailabilityQuery,
  (query) => getStatus(query)
);

export const getLeadOverviewProductAvailabilityForAddress = createSelector(
  getLeadOverview,
  (state) => state.productAvailabilityForAddress
);

const getLeadOverviewProductAvailabilityForAddressQuery = createSelector(
  getLeadOverview,
  (state) => state[GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY]
);

export const getLeadOverviewProductAvailabilityForAddressStatus = createSelector(
  getLeadOverviewProductAvailabilityForAddressQuery,
  (query) => getStatus(query)
);

export const getSendCGInvitationQuery = createSelector(
  getLeadOverview,
  (state) => state[SEND_CG_INVITATION_QUERY]
);

export const getSendCGInvitationQueryStatus = createSelector(getSendCGInvitationQuery, (query) =>
  getStatus(query)
);

export const getNewLeadAddress = createSelector(getLeadOverview, (state) => state.newLeadAddress);

export const getNewAddressErrorActivity = createSelector(
  getLeadOverview,
  (state) => state.isNewAddressErrorActive
);

export const getOfferIdToChangeStatus = createSelector(
  getLeadOverview,
  (state) => state.offerIdToChangeStatus
);

export const getProductBatteryList = createSelector(
  getLeadOverview,
  (state) => state.productBatteryList
);

export const getProductBatteryListQuery = createSelector(
  getLeadOverview,
  (state) => state[GET_PRODUCT_BATTERY_LIST_QUERY]
);

export const getProductBatteryListQueryStatus = createSelector(
  getProductBatteryListQuery,
  (query) => getStatus(query)
);

export const getDisplayMaxOffersWarning = createSelector(
  getLeadOverview,
  (state) => state.displayMaxOffersWarning
);

const getPatchOfferToRecalculateQuery = createSelector(
  getLeadOverview,
  (state) => state[GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY]
);

export const getPatchOfferToRecalculateQueryStatus = createSelector(
  getPatchOfferToRecalculateQuery,
  (query) => getStatus(query)
);

const getDeleteExpireOfferQuery = createSelector(
  getLeadOverview,
  (state) => state[DELETE_EXPIRED_OFFER]
);

export const getDeleteExpireOfferQueryStatus = createSelector(getDeleteExpireOfferQuery, (query) =>
  getStatus(query)
);
