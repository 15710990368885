import { CompanyBatterySystemsCount, Customer } from '+shared/store/customer';
import { CustomerSortKey } from '+shared/store/customer/types/customerSort.type';

import { ActionsUnion, createAction } from '../../../utils';

export enum CUSTOMER_LIST_PAGE_ACTIONS {
  GET_CUSTOMER_COLLECTION = '[+Customer] GET_CUSTOMER_COLLECTION',
  SET_CUSTOMER_COLLECTION = '[+Customer] SET_CUSTOMER_COLLECTION',
  SET_INSTALLED_BATTERIES_COUNT = '[+Customer] SET_INSTALLED_BATTERIES_COUNT',
  SET_TOTAL_RESOURCE_COUNT = '[+Customer] SET_TOTAL_RESOURCE_COUNT',
}

export const CustomerListPageActions = {
  getCustomerCollection: createAction(
    CUSTOMER_LIST_PAGE_ACTIONS.GET_CUSTOMER_COLLECTION,
    (params: { search?: string; sort?: CustomerSortKey }, userProfileCustomerNumber: string) =>
      params
  ),

  setCustomerCollection: createAction(
    CUSTOMER_LIST_PAGE_ACTIONS.SET_CUSTOMER_COLLECTION,
    (collection: Customer[]) => ({ collection })
  ),

  setInstalledBatteriesCount: createAction(
    CUSTOMER_LIST_PAGE_ACTIONS.SET_INSTALLED_BATTERIES_COUNT,
    (count: CompanyBatterySystemsCount) => ({ count })
  ),

  setTotalResourceCount: createAction(
    CUSTOMER_LIST_PAGE_ACTIONS.SET_TOTAL_RESOURCE_COUNT,
    (count: number) => ({ count })
  ),
};

export type CustomerListPageActions = ActionsUnion<typeof CustomerListPageActions>;
