import * as React from 'react';

import { ListTable, ListTableHeader, ListTableHeaderItem } from '+shared/components';
import { Lead } from '+shared/store/lead/types';

import { SetupLeadListTableItem } from '../SetupLeadListTableItem';
import { getSetupLeadListTableHeaders } from './SetupLeadListTable.const';

import './SetupLeadListTable.component.scss';

interface Props {
  items: Lead[];
  isPending: boolean;
  onLeadClick: (leadId: string) => void;
  onLeadOfferClick: (leadId: string) => void;
  isStatusModalOpen: boolean;
  toggleStatusModal: (arg: boolean) => void;
  userCompanyName: string;
}

export const SetupLeadListTable: React.FC<Props> = ({
  items,
  isPending,
  onLeadClick,
  onLeadOfferClick,
  isStatusModalOpen,
  toggleStatusModal,
  userCompanyName,
}) => {
  const SetupLeadListTableComponent = (
    tableClassName: string,
    setupLeadListTableHeaders: ListTableHeaderItem[]
  ) => (
    <ListTable
      className={`c-list-table--lead-list ${tableClassName}`}
      headers={setupLeadListTableHeaders}
      items={items}
      isPending={isPending}
      hoverable={true}
      noBorder={true}
      renderHeader={(header) => (
        <ListTableHeader className={`c-list-table__header--lead-list`} item={header} />
      )}
      renderItem={(item) => (
        <SetupLeadListTableItem
          item={item}
          onLeadClick={onLeadClick}
          onLeadOfferClick={onLeadOfferClick}
          isStatusModalOpen={isStatusModalOpen}
          toggleStatusModal={toggleStatusModal}
          userCompanyName={userCompanyName}
        />
      )}
    />
  );

  const setupLeadListTableHeaders = getSetupLeadListTableHeaders();

  return <>{SetupLeadListTableComponent('c-list-table--setup-list', setupLeadListTableHeaders)}</>;
};
