import { Query } from '+shared/store/query';

import { LegalDocumentAttributes, LegalDocumentData } from './types/legal.interface';

export const GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY = 'getAcceptedLegalDocumentsQuery';

export interface LegalState {
  acceptedLegalDocuments: LegalDocumentAttributes[];
  [GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY]: Query<LegalDocumentData>;
}

export const initialState: LegalState = {
  acceptedLegalDocuments: [],
  [GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY]: {},
};
