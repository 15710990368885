import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, InfoBanner } from '@sonnen/shared-web';

export const CustomerListNoResults: React.FC = () => (
  <InfoBanner
    icon={<Icon.Ufo />}
    title={I18n.t(T.dashboard.noResultsHeadline)}
    isThemeCard={true}
    subtitle={
      <span
        dangerouslySetInnerHTML={{
          __html:
            I18n.t(T.dashboard.noResultsSubtitle, {
              link: I18n.t(T.general.navigation.reportLink),
            }) || '',
        }}
      />
    }
  />
);
