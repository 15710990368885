import { Case, CaseStatus } from '+shared/store/case';

export const getClosedCaseCount = (cases: Case[]) => {
  const closedCasesTypes = [
    CaseStatus.DONE,
    CaseStatus.DONE_PROD,
    CaseStatus.CANCELLED,
    CaseStatus.CANCELLED_PROD,
    CaseStatus.CLOSED_DONE,
    CaseStatus.CLOSED_CANCELLED,
    CaseStatus.CLOSED,
    CaseStatus.CLOSED_PROD,
    CaseStatus.CLOSED_DUPLICATE,
    CaseStatus.CLOSED_WAITING_FOR_CUSTOMER_REPLAY,
  ];
  return cases.filter((cs) => closedCasesTypes.includes(cs.status)).length;
};
