import * as React from 'react';

import classNames from 'classnames';

import { CurrencyUnit } from '+app/App.constants';
import { CostsFormVersion } from '+lead/+impactAnalysis/store/+impactAnalysis.interface';
import { PriceFormatter } from '+lead/+impactAnalysis/utils/formatters/price';
import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge, LabelMedium, LabelSmall } from '+shared/basicComponents/Typography/Labels';
import { LeadImpactAnalysisRecommendationResponse } from '+shared/store/lead/types/leadImpactAnalysisRecommendationResponse.interface';
import { NON_BREAKING_SPACE } from '+utils/string.util';

import {
  Amortization,
  PageDetailedCalculation20YearsData,
} from './LeadImpactAnalysisCalculationResults.types';

import './LeadImpactAnalysisCalculationResults.scss';

export const isAmortizationOver20Years = (amortizationTimeFrame?: Amortization) =>
  !amortizationTimeFrame || amortizationTimeFrame.years > 19;

export const isAmortizationLessThan1Year = (amortizationTimeFrame?: Amortization) =>
  amortizationTimeFrame && amortizationTimeFrame.years === 0 && amortizationTimeFrame.months === 0;

export const getSignByValue = (value: number | undefined) => {
  if (!value) {
    return undefined;
  }
  if (value > 0) {
    return '+';
  }
  return '-';
};

export const mapToPageData = (
  data: LeadImpactAnalysisRecommendationResponse,
  version?: CostsFormVersion
): PageDetailedCalculation20YearsData => {
  const isDetailedVersion = version === CostsFormVersion.DETAILED;

  return {
    hardwareCostsAfter20Years: {
      cumulativeMinusDeductibleVat:
        data.hardwareCostsAfter20Years.cumulativeMinusDeductibleVat.value,
      investmentCosts: {
        cumulative: data.hardwareCostsAfter20Years.investmentCosts.cumulative.value,
        acquisitionCostsPVSAndBattery:
          (isDetailedVersion &&
            data.hardwareCostsAfter20Years.investmentCosts.acquisitionCostsPvsAndBattery?.value) ||
          undefined,
        installationCostsPVSAndBattery:
          (isDetailedVersion &&
            data.hardwareCostsAfter20Years.investmentCosts.installationCostsPvsAndBattery?.value) ||
          undefined,
        otherOneOffCosts:
          isDetailedVersion && data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts
            ? hideIfZero(data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts.value)
            : undefined,
      },
      operatingCosts: {
        cumulative: data.hardwareCostsAfter20Years.operatingCosts.cumulative.value,
        maintenanceAndReplacementCosts:
          (isDetailedVersion &&
            data.hardwareCostsAfter20Years.operatingCosts.maintenanceAndReplacementCosts?.value) ||
          undefined,
        otherRunningCosts:
          isDetailedVersion && data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts
            ? hideIfZero(data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts.value)
            : undefined,
      },
      allowableTaxation: data.hardwareCostsAfter20Years.deductibleVatTotal?.value || undefined,
    },
    sonnenFlatBenefitsAfter20Years: {
      cumulative: data.sonnenFlatCostsAndBenefitsAfter20Years.cumulative.value,
      monthlyPrepaymentExcessEnergy:
        data.sonnenFlatCostsAndBenefitsAfter20Years.monthlyPrepayment.value,
      cashbackSonnenFlat: data.sonnenFlatCostsAndBenefitsAfter20Years.cashbackSonnenFlat.value,
      vppBonus: data.sonnenFlatCostsAndBenefitsAfter20Years.vppBonus.value,
    },
    powerBillSavings: {
      cumulative: data.powerBillSavings.value,
    },
    savingsAfter20Years: data.savingsOver20Years.value,
    sonnenFlatVatToBePaid: {
      cumulative: data.sonnenFlatVatToBePaid.cumulative.value,
    },
    excessTariffNetPerKwh: data.excessTariffNetPerKwh.value,
  };
};

const Value: React.FC<{
  value?: number;
  currency?: CurrencyUnit | 'ct';
  sign?: '+' | '-';
  textComponent: React.FC<{ text: React.ReactNode; className?: string }>;
}> = ({ value, sign, currency = CurrencyUnit.CURRENCY_EUR, textComponent: TextComponent }) =>
  value === 0 || value ? (
    <TextComponent
      text={
        <>
          {sign && value !== 0 ? `${sign}${NON_BREAKING_SPACE}` : ''}
          <PriceFormatter value={value} currency={currency} decimals={0} />
        </>
      }
    />
  ) : (
    <span />
  );

type TextType = 'labelLarge' | 'labelMedium' | 'bodyMedium';

const getText = (textType: TextType, text: string) => {
  switch (textType) {
    case 'labelLarge':
      return <LabelLarge text={text} />;
    case 'labelMedium':
      return <LabelMedium text={text} />;
    case 'bodyMedium':
    default:
      return <BodyMedium text={text} />;
  }
};

const getValueTextType = (textType: TextType) => {
  switch (textType) {
    case 'labelLarge':
      return LabelLarge;
    case 'labelMedium':
      return LabelMedium;
    case 'bodyMedium':
    default:
      return BodyMedium;
  }
};

export const CostRow: React.FC<{
  text: string;
  textType: TextType;
  textHelperLabel?: string;
  value?: number;
  currency?: CurrencyUnit;
  sign?: '+' | '-' | undefined;
  className?: string;
}> = ({
  text,
  textType,
  textHelperLabel,
  value,
  currency = CurrencyUnit.CURRENCY_EUR,
  sign = '+',
  className,
}) => (
  <div className={classNames('c-impact-analysis-results__row', className)}>
    <div className="c-impact-analysis-results__row-text-wrapper">
      {getText(textType, text)}
      {textHelperLabel && <LabelSmall text={textHelperLabel} />}
    </div>
    <div className="c-impact-analysis-results__row-value-wrapper">
      <Value
        value={value}
        currency={currency}
        sign={sign}
        textComponent={getValueTextType(textType)}
      />
    </div>
  </div>
);

const hideIfZero = (value: number | undefined) => (value === 0 ? undefined : value);
