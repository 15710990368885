var Rect = (function () {
    function Rect(value) {
        if (typeof value === 'number') {
            this.l = this.t = this.r = this.b = value;
        }
        else {
            this.l = this.t = this.r = this.b = 0;
            if (value.horizontal) {
                this.l = this.r = value.horizontal;
            }
            else {
                if (value.left) {
                    this.l = value.left;
                }
                if (value.right) {
                    this.r = value.right;
                }
            }
            if (value.vertical) {
                this.t = this.b = value.vertical;
            }
            else {
                if (value.top) {
                    this.t = value.top;
                }
                if (value.bottom) {
                    this.b = value.bottom;
                }
            }
        }
    }
    Rect.from = function (rectLike) {
        if (rectLike instanceof Rect) {
            return rectLike;
        }
        else {
            return new Rect(rectLike);
        }
    };
    Object.defineProperty(Rect.prototype, "width", {
        get: function () {
            return Math.abs(this.r - this.l);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Rect.prototype, "height", {
        get: function () {
            return Math.abs(this.b - this.t);
        },
        enumerable: false,
        configurable: true
    });
    Rect.prototype.inset = function (shrink) {
        if (typeof shrink === 'number') {
            this.r -= shrink;
            this.l += shrink;
            this.t += shrink;
            this.b -= shrink;
        }
        else {
            this.r -= shrink.r;
            this.l += shrink.l;
            this.t += shrink.t;
            this.b -= shrink.b;
        }
        return this;
    };
    Rect.prototype.offset = function (offset, yOffset) {
        var x = 0;
        var y = 0;
        if (typeof offset === 'number') {
            x = offset;
            y = yOffset || 0;
        }
        else if (offset instanceof Rect) {
            x = offset.l;
            y = offset.t;
        }
        else {
            x = offset.x;
            y = offset.y;
        }
        this.l += x;
        this.r += x;
        this.t += y;
        this.b += y;
        return this;
    };
    Rect.prototype.expand = function (expansion) {
        if (typeof expansion === 'number') {
            this.r += expansion;
            this.l -= expansion;
            this.t -= expansion;
            this.b += expansion;
        }
        else {
            this.r += expansion.r;
            this.l -= expansion.l;
            this.t -= expansion.t;
            this.b += expansion.b;
        }
        return this;
    };
    Rect.prototype.intersects = function (rect) {
        return (this.l < rect.r && this.r > rect.l &&
            this.t > rect.b && this.b < rect.t);
    };
    Rect.prototype.contains = function (x, y) {
        return (this.l <= x && x <= this.r &&
            this.t <= y && y <= this.b);
    };
    Rect.prototype.clone = function () {
        return new Rect({
            top: this.t,
            left: this.l,
            bottom: this.b,
            right: this.r,
        });
    };
    Rect.prototype.cloneTo = function (rect) {
        rect.t = this.t;
        rect.l = this.l;
        rect.b = this.b;
        rect.r = this.r;
        return rect;
    };
    Rect.prototype.toString = function () {
        return "(\u2191".concat(this.t, " \u2192").concat(this.r, " \u2193").concat(this.b, " \u2190").concat(this.l, ")");
    };
    return Rect;
}());
export { Rect };
