import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { HeadlineMedium } from '+shared/basicComponents/Typography/Headlines';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { FormInput } from '+shared/components';

import { ImpactAnalysisCostsForm } from './LeadImpactAnalysisCosts.component';
import { formFields } from './LeadImpactAnalysisCosts.helper';

import './LeadImpactAnalysisCosts.component.scss';

const ImpactAnalysisCostsDetailed: React.FC<ImpactAnalysisCostsForm> = ({ form, currency }) => {
  const { nineteenPercentValueAddedTaxes, zeroPercentValueAddedTaxes } =
    T.lead.impactAnalysis._salessolution_;

  const {
    pvSystem,
    pvSystemCosts,
    inverterAndOtherComponentsCosts,
    pvSystemInstallationCosts,
    sonnenBatterie,
    sonnenBatterieCosts,
    sonnenBatterieInstallationCosts,
    meter,
    meterConversionCosts,
    additionalInstallationCosts,
    otherInstallationCosts,
    maintenanceCosts,
    annualInsuranceCosts,
    annualMaintenanceCosts,
    additionalRunningCosts,
    annualAdditionalCosts,
  } = T.lead.impactAnalysis._salessolution_.costs.detailed;

  return (
    <div>
      <HeadlineMedium
        className="c-impact-analysis-costs-headline-medium"
        text={I18n.t(T.lead.impactAnalysis._salessolution_.costs.oneoffInvestmentCosts)}
      />

      <LabelLarge className="c-impact-analysis-costs-label-large" text={I18n.t(pvSystem)} />

      <div>
        <div className={'c-impact-analysis-costs-input-group'}>
          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={<LabelMedium text={I18n.t(pvSystemCosts)} />}
              name={formFields.DETAILED.PV_SYSTEM_COSTS}
              form={form}
              type={'number'}
              unit={currency}
              helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
            />
          </div>

          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={<LabelMedium text={I18n.t(inverterAndOtherComponentsCosts)} />}
              name={formFields.DETAILED.INVERTER_AND_OTHER_COMPONENTS_COSTS}
              form={form}
              type={'number'}
              unit={currency}
              helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
            />
          </div>
        </div>
        <div className="c-impact-analysis-costs-input-group">
          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={<LabelMedium text={I18n.t(pvSystemInstallationCosts)} />}
              name={formFields.DETAILED.PV_SYSTEM_INSTALLATION_COSTS}
              form={form}
              type={'number'}
              unit={currency}
              helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
            />
          </div>
        </div>
      </div>

      <LabelLarge className="c-impact-analysis-costs-label-large" text={I18n.t(sonnenBatterie)} />

      <div className={'c-impact-analysis-costs-input-group'}>
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={<LabelMedium text={I18n.t(sonnenBatterieCosts)} />}
            name={formFields.DETAILED.SONNEN_BATTERIE_COSTS}
            form={form}
            type={'number'}
            unit={currency}
            helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
          />
        </div>

        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={<LabelMedium text={I18n.t(sonnenBatterieInstallationCosts)} />}
            name={formFields.DETAILED.SONNEN_BATTERIE_INSTALLATION_COSTS}
            form={form}
            type={'number'}
            unit={currency}
            helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
          />
        </div>
      </div>

      <LabelLarge className="c-impact-analysis-costs-label-large" text={I18n.t(meter)} />

      <div className="c-impact-analysis-costs-input-group">
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={<LabelMedium text={I18n.t(meterConversionCosts)} />}
            name={formFields.DETAILED.METER_CONVERSION_COSTS}
            form={form}
            type={'number'}
            unit={currency}
            helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
          />
        </div>
      </div>

      <LabelLarge
        className="c-impact-analysis-costs-label-large"
        text={I18n.t(additionalInstallationCosts)}
      />

      <div className="c-impact-analysis-costs-input-group">
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={<LabelMedium text={I18n.t(otherInstallationCosts)} />}
            name={formFields.DETAILED.OTHER_INSTALLATION_COSTS}
            form={form}
            type={'number'}
            unit={currency}
            helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
          />
        </div>
      </div>

      <HeadlineMedium
        className="c-impact-analysis-costs-headline-medium"
        text={I18n.t(T.lead.impactAnalysis._salessolution_.costs.runningCosts)}
      />

      <div>
        <LabelLarge
          className="c-impact-analysis-costs-label-large"
          text={I18n.t(maintenanceCosts)}
        />

        <div className={'c-impact-analysis-costs-input-group'}>
          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={<LabelMedium text={I18n.t(annualInsuranceCosts)} />}
              name={formFields.DETAILED.ANNUAL_INSURANCE_COSTS}
              form={form}
              type={'number'}
              unit={currency}
              helperLabel={<BodySmall text={I18n.t(nineteenPercentValueAddedTaxes)} />}
            />
          </div>

          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={<LabelMedium text={I18n.t(annualMaintenanceCosts)} />}
              name={formFields.DETAILED.ANNUAL_MAINTENANCE_COSTS}
              form={form}
              type={'number'}
              unit={currency}
              helperLabel={<BodySmall text={I18n.t(nineteenPercentValueAddedTaxes)} />}
            />
          </div>
        </div>

        <LabelLarge
          className="c-impact-analysis-costs-label-large"
          text={I18n.t(additionalRunningCosts)}
        />

        <div className={'c-impact-analysis-costs-input-group'}>
          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={<LabelMedium text={I18n.t(annualAdditionalCosts)} />}
              name={formFields.DETAILED.ANNUAL_ADDITIONAL_COSTS}
              form={form}
              type={'number'}
              unit={currency}
              helperLabel={<BodySmall text={I18n.t(nineteenPercentValueAddedTaxes)} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactAnalysisCostsDetailed;
