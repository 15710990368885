import { PowerMeterLiveState } from '+shared/store/battery/types/batteryPowerMeterLiveState.interface';
import { ActionsUnion, createAction } from '+utils/redux';

export enum PV_SYSTEMS_ACTIONS {
  START_BATTERY_POWER_METER_POLLING = '[PvSystems] START_BATTERY_POWER_METER_POLLING',
  STOP_BATTERY_POWER_METER_POLLING = '[PvSystems] STOP_BATTERY_POWER_METER_POLLING',
  SET_BATTERY_POWER_METER_LIVE_STATES = '[PvSystems] SET_BATTERY_POWER_METER_LIVE_STATES',
  SET_BATTERY_PV_GRID_FEED_IN_LIMIT = '[PvSystems] SET_BATTERY_PV_GRID_FEED_IN_LIMIT',
  // eslint-disable-next-line max-len
  SET_BATTERY_PV_GRID_FEED_IN_LIMIT_IN_PROGRESS = '[PvSystems] SET_BATTERY_PV_GRID_FEED_IN_LIMIT_IN_PROGRESS',
}

export const PvSystemsActions = {
  startBatteryPowerMeterPolling: createAction(
    PV_SYSTEMS_ACTIONS.START_BATTERY_POWER_METER_POLLING,
    ({ queryKey, batteryId }: { queryKey: string; batteryId: string }) => ({ queryKey, batteryId })
  ),
  stopBatteryPowerMeterPolling: createAction(PV_SYSTEMS_ACTIONS.STOP_BATTERY_POWER_METER_POLLING),
  setBatteryPowerMeterLiveStates: createAction(
    PV_SYSTEMS_ACTIONS.SET_BATTERY_POWER_METER_LIVE_STATES,
    (powerMeterLiveStates: PowerMeterLiveState[]) => ({ powerMeterLiveStates })
  ),
  setBatteryPvGridFeedInLimit: createAction(
    PV_SYSTEMS_ACTIONS.SET_BATTERY_PV_GRID_FEED_IN_LIMIT,
    ({ pvGridFeedInLimit, batteryId }): { pvGridFeedInLimit: string; batteryId: string } => ({
      pvGridFeedInLimit,
      batteryId,
    })
  ),
  setIsBatteryPvGridFeedInLimitInProgress: createAction(
    PV_SYSTEMS_ACTIONS.SET_BATTERY_PV_GRID_FEED_IN_LIMIT_IN_PROGRESS,
    (isBatteryPvGridFeedInLimitInProgress: boolean) => ({ isBatteryPvGridFeedInLimitInProgress })
  ),
};

export type PvSystemsActions = ActionsUnion<typeof PvSystemsActions>;
