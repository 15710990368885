import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { BatteryIcStatus } from '+shared/store/battery/types/batteryIcStatus.interface';
import { Reporter } from '+utils/reporter.util';

import { BatteryOperationsHeader } from '../BatteryOperationsHeader';

type BatteryStatusWithI18nKey = {
  name: string;
  i18nKey: BatteryModuleStateKey | CoreModuleStateKey | InverterStateKey;
  variant: 'blue' | 'gray' | 'green' | 'red';
};

type TranslatedBatteryStatus = {
  text: string;
  variant: 'blue' | 'gray' | 'green' | 'red';
  tooltip?: string;
};

interface Props {
  batteryIcStatus: BatteryIcStatus;
}

export const DetailedBatteryStatusComponent: React.FC<Props> = ({ batteryIcStatus }) => {
  const { batteryModule, coreModule, inverter } =
    T.customerSingle.batteryDetails.batteryLive.detailedStatus;
  return (
    <>
      <BatteryOperationsHeader
        title={I18n.t(batteryModule.title)}
        label={translateState('batteryModule', batteryModuleStates, batteryIcStatus.bmsState)}
      />
      <BatteryOperationsHeader
        title={I18n.t(coreModule.title)}
        // eslint-disable-next-line max-len, prettier/prettier
        label={translateState('coreModule', coreModuleStates, batteryIcStatus.coreControlModuleState)}
      />
      <BatteryOperationsHeader
        title={I18n.t(inverter.title)}
        label={translateState('inverter', inverterStates, batteryIcStatus.inverterState)}
      />
    </>
  );
};

const translateState = (
  module: 'batteryModule' | 'coreModule' | 'inverter',
  states: readonly BatteryStatusWithI18nKey[],
  stateName: string
): TranslatedBatteryStatus => {
  const state = states.find((state) => state.name === stateName);
  const label = state
    ? T.customerSingle.batteryDetails.batteryLive.detailedStatus[module].label[state.i18nKey]
    : undefined;

  if (!label || !state) {
    Reporter.log(`WARNING: batteryIcStatus '${stateName}' is not translated.`);
    return {
      text: stateName,
      variant: 'red',
    };
  }

  return {
    text: I18n.t(label.name),
    tooltip: I18n.t(label.tooltip),
    variant: state.variant,
  };
};

const batteryModuleStates = [
  {
    name: 'alarm',
    i18nKey: 'alarm',
    variant: 'blue',
  },
  {
    name: 'error',
    i18nKey: 'error',
    variant: 'red',
  },
  {
    name: 'dc module connecting',
    i18nKey: 'dcModuleConnecting',
    variant: 'blue',
  },
  {
    name: 'not ready',
    i18nKey: 'notReady',
    variant: 'blue',
  },
  {
    name: 'ready',
    i18nKey: 'ready',
    variant: 'green',
  },
  {
    name: 'sleep',
    i18nKey: 'sleep',
    variant: 'blue',
  },
  {
    name: 'wrong hw setup',
    i18nKey: 'wrongHardwareSetup',
    variant: 'red',
  },
] as const;
type BatteryModuleStateKey = (typeof batteryModuleStates)[number]['i18nKey'];

const coreModuleStates = [
  {
    name: 'config',
    i18nKey: 'config',
    variant: 'red',
  },
  {
    name: 'critical error',
    i18nKey: 'criticalError',
    variant: 'red',
  },
  {
    name: 'hardwareautodetection',
    i18nKey: 'hardwareAutoDetection',
    variant: 'blue',
  },
  {
    name: 'init',
    i18nKey: 'init',
    variant: 'blue',
  },
  {
    name: 'initvalidation',
    i18nKey: 'initValidation',
    variant: 'blue',
  },
  {
    name: 'manualshutdown',
    i18nKey: 'manualShutdown',
    variant: 'blue',
  },
  {
    name: 'manualstandby',
    i18nKey: 'manualStandby',
    variant: 'gray',
  },
  {
    name: 'microgrid',
    i18nKey: 'microGrid',
    variant: 'green',
  },
  {
    name: 'nooperation',
    i18nKey: 'noOperation',
    variant: 'red',
  },
  {
    name: 'ongrid',
    i18nKey: 'onGrid',
    variant: 'green',
  },
  {
    name: 'automaticstandby',
    i18nKey: 'automaticStandby',
    variant: 'gray',
  },
  {
    name: 'peripheraldeviceupdate',
    i18nKey: 'peripheralDeviceUpdate',
    variant: 'blue',
  },
  {
    name: 'reinit',
    i18nKey: 'reInit',
    variant: 'blue',
  },
  {
    name: 'selftest',
    i18nKey: 'selfTest',
    variant: 'blue',
  },
] as const;
type CoreModuleStateKey = (typeof coreModuleStates)[number]['i18nKey'];

const inverterStates = [
  {
    name: 'error',
    i18nKey: 'error',
    variant: 'red',
  },
  {
    name: 'not ready',
    i18nKey: 'notReady',
    variant: 'blue',
  },
  {
    name: 'needs reset',
    i18nKey: 'needsReset',
    variant: 'red',
  },
  {
    name: 'ready',
    i18nKey: 'ready',
    variant: 'green',
  },
  {
    name: 'running',
    i18nKey: 'running',
    variant: 'green',
  },
] as const;
type InverterStateKey = (typeof inverterStates)[number]['i18nKey'];
