import { __extends } from "tslib";
import * as React from 'react';
import { KanvaContext } from './kanva-context';
var getAllGettersAndSetters = function (obj) {
    var props = [];
    var o = obj;
    do {
        props = props.concat(Object.getOwnPropertyNames(o));
    } while (o = Object.getPrototypeOf(o));
    return props
        .filter(function (name) { return (name.length > 3 &&
        name.startsWith('set')); })
        .map(function (name) { return ({
        name: name.replace('set', ''),
        set: obj[name],
        get: obj[name.replace('set', 'get')],
    }); })
        .filter(function (p) { return typeof p.get === 'function' && typeof p.set === 'function'; });
};
export var createReactView = function (viewClass) {
    var _a;
    return _a = (function (_super) {
            __extends(ReactViewComponent, _super);
            function ReactViewComponent(props) {
                var _this = _super.call(this, props) || this;
                _this.propNames = [];
                _this.propHandlers = {};
                return _this;
            }
            Object.defineProperty(ReactViewComponent.prototype, "internalProps", {
                get: function () {
                    return this.props;
                },
                enumerable: false,
                configurable: true
            });
            Object.defineProperty(ReactViewComponent.prototype, "isDebugEnabled", {
                get: function () {
                    return !!this.view && this.view.context.debugEnabled;
                },
                enumerable: false,
                configurable: true
            });
            ReactViewComponent.prototype.createAndAttachView = function () {
                var _this = this;
                var view = this.view = new viewClass(this.context.ctx);
                var gettersAndSetters = getAllGettersAndSetters(view);
                for (var _i = 0, gettersAndSetters_1 = gettersAndSetters; _i < gettersAndSetters_1.length; _i++) {
                    var _a = gettersAndSetters_1[_i], name_1 = _a.name, get = _a.get, set = _a.set;
                    var propName = name_1[0].toLowerCase() + name_1.substr(1);
                    this.propHandlers[propName] = { get: get, set: set };
                    this.propNames.push(propName);
                }
                this.layoutParams = view.getLayoutParams().asProps();
                this.propHandlers.layoutParams = {
                    get: function () { return _this.layoutParams; },
                    set: function (layoutParams) {
                        _this.layoutParams = layoutParams;
                        var lp = view.getLayoutParams();
                        if (lp.updateWithProps(layoutParams)) {
                            view.setLayoutParams(lp);
                        }
                    },
                };
                var viewRef = this.props.viewRef;
                if (viewRef) {
                    viewRef(view);
                }
            };
            ReactViewComponent.prototype.refreshProps = function () {
                var parent = this.context.parent;
                var _a = this, propNames = _a.propNames, propHandlers = _a.propHandlers, view = _a.view;
                if (!view) {
                    return;
                }
                if (parent && !view.hasParent()) {
                    parent.addChild(view);
                }
                for (var i = 0, l = propNames.length; i < l; i++) {
                    var propName = propNames[i];
                    var handler = propHandlers[propName];
                    var propValue = this.props[propName];
                    if (propValue !== handler.get.call(view)) {
                        handler.set.call(view, propValue);
                    }
                }
                if (this.isDebugEnabled) {
                    console.log(view.snapshot());
                }
            };
            ReactViewComponent.prototype.componentDidMount = function () {
                this.createAndAttachView();
                this.refreshProps();
            };
            ReactViewComponent.prototype.componentDidUpdate = function () {
                this.refreshProps();
            };
            ReactViewComponent.prototype.componentWillUnmount = function () {
                var parent = this.context.parent;
                if (!this.view) {
                    return;
                }
                if (this.view.hasParent()) {
                    return parent.removeChild(this.view);
                }
                this.view.destroy();
                this.view = undefined;
            };
            ReactViewComponent.prototype.render = function () {
                return (React.createElement(KanvaContext.Provider, { value: {
                        ctx: this.context.ctx,
                        parent: this.view,
                    } }, this.props.children));
            };
            return ReactViewComponent;
        }(React.PureComponent)),
        _a.contextType = KanvaContext,
        _a.displayName = viewClass.name,
        _a;
};
