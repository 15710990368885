import { Action } from 'redux';

import { ActionsUnion, createAction } from '+app/utils';
import {
  GET_SETUP_TOOL_SUBMISSION_DATA_QUERY,
  SAVE_REGISTRATION_SUBJECT_TYPE_QUERY,
} from '+setupTool/store/+setupTool.dictionary';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { AdditionalFeatures, Document, SetupDataStepStatuses } from '+shared/store/setupTool/types';

import { SubmissionStep } from './+setupTool.dictionary';
import { SetupToolStepsUpdatedAt } from './types';

export enum SETUP_TOOL_ACTIONS {
  CLEAR_CONFIGURATION_DATA = '[DSO_Register] CLEAR_CONFIGURATION_DATA',
  GET_SETUP_TOOL_SUBMISSION_DATA = '[DSO_Register] GET_SETUP_TOOL_SUBMISSION_DATA',
  SET_SUBMISSION_ID = '[DSO_Register] SET_SUBMISSION_ID',
  SET_LATEST_SUBMISSION = '[DSO_Register] SET_LATEST_SUBMISSION',
  SET_UPLOADED_DOCUMENTS = '[DSO_Register] SET_UPLOADED_DOCUMENTS',
  SET_GENERATED_DOCUMENTS = '[DSO_Register] SET_GENERATED_DOCUMENTS',
  SET_LATEST_MODIFICATION = '[DSO_Register] SET_LATEST_MODIFICATION',
  SAVE_SUBMISSION = '[DSO_Register] SAVE_SUBMISSION',
  SAVE_SUBMISSION_ONLY_VPP_FIELDS = '[DSO_Register] SAVE_SUBMISSION_ONLY_VPP_FIELDS',
  SAVE_SUBMISSION_WITH_CALLBACK = '[DSO_Register] SAVE_SUBMISSION_WITH_CALLBACK',
  // eslint-disable-next-line max-len
  SAVE_SUBMISSION_WITH_CALLBACK_ONLY_VPP_FIELDS = '[DSO_Register] SAVE_SUBMISSION_WITH_CALLBACK_ONLY_VPP_FIELDS',

  SET_REGISTRATION_SUBJECT_TYPE = '[DSO_Register] SET_REGISTRATION_SUBJECT_TYPE',
  SAVE_REGISTRATION_SUBJECT_TYPE = '[DSO_Register] SAVE_REGISTRATION_SUBJECT_TYPE',

  SET_ADDITIONAL_FEATURES = '[DSO_Register] SET_ADDITIONAL_FEATURES',

  APPEND_ERROR = '[DSO_Register] APPEND_ERROR',
  CLEAR_ERRORS = '[DSO_Register] CLEAR_ERRORS',
}

export type SetupToolSubmissionSavePayload = {
  step: SubmissionStep;
  query: string;
  [key: string]: any;
};

export const SetupToolActions = {
  clearConfigurationData: createAction(SETUP_TOOL_ACTIONS.CLEAR_CONFIGURATION_DATA),
  getSetupToolSubmissionData: createAction(
    SETUP_TOOL_ACTIONS.GET_SETUP_TOOL_SUBMISSION_DATA,
    (customerId: string) => ({ queryKey: GET_SETUP_TOOL_SUBMISSION_DATA_QUERY, customerId })
  ),
  setSubmissionId: createAction(
    SETUP_TOOL_ACTIONS.SET_SUBMISSION_ID,
    (submissionId: string | undefined) => ({ submissionId })
  ),
  setLatestSubmission: createAction(SETUP_TOOL_ACTIONS.SET_LATEST_SUBMISSION, (date: string) => ({
    date,
  })),
  setUploadedDocuments: createAction(
    SETUP_TOOL_ACTIONS.SET_UPLOADED_DOCUMENTS,
    (data: Document[]) => ({ data })
  ),
  setGeneratedDocuments: createAction(
    SETUP_TOOL_ACTIONS.SET_GENERATED_DOCUMENTS,
    (data: Document[]) => ({ data })
  ),
  setLatestModificationDate: createAction(
    SETUP_TOOL_ACTIONS.SET_LATEST_MODIFICATION,
    (data: SetupToolStepsUpdatedAt) => ({ data })
  ),
  saveSubmission: createAction(
    SETUP_TOOL_ACTIONS.SAVE_SUBMISSION,
    (step: string, stepStatuses?: SetupDataStepStatuses) => ({ step, stepStatuses })
  ),
  saveSubmissionOnlyVppFields: createAction(
    SETUP_TOOL_ACTIONS.SAVE_SUBMISSION_ONLY_VPP_FIELDS,
    (step: string, stepStatuses?: SetupDataStepStatuses) => ({ step, stepStatuses })
  ),
  saveSubmissionWithCallback: createAction(
    SETUP_TOOL_ACTIONS.SAVE_SUBMISSION_WITH_CALLBACK,
    (callback: Action, payload: SetupToolSubmissionSavePayload) => ({ callback, payload })
  ),
  saveSubmissionWithCallbackOnlyVppFields: createAction(
    SETUP_TOOL_ACTIONS.SAVE_SUBMISSION_WITH_CALLBACK_ONLY_VPP_FIELDS,
    (callback: Action, payload: SetupToolSubmissionSavePayload) => ({ callback, payload })
  ),
  setRegistrationSubjectType: createAction(
    SETUP_TOOL_ACTIONS.SET_REGISTRATION_SUBJECT_TYPE,
    (subject: RegistrationSubjectType) => ({ subject })
  ),
  saveRegistrationSubjectType: createAction(
    SETUP_TOOL_ACTIONS.SAVE_REGISTRATION_SUBJECT_TYPE,
    (submissionId: string, subject: RegistrationSubjectType) => ({
      queryKey: SAVE_REGISTRATION_SUBJECT_TYPE_QUERY,
      submissionId,
      subject,
    })
  ),
  setAdditionalFeatures: createAction(
    SETUP_TOOL_ACTIONS.SET_ADDITIONAL_FEATURES,
    (additionalFeatures: AdditionalFeatures[]) => ({ additionalFeatures })
  ),
  appendError: createAction(SETUP_TOOL_ACTIONS.APPEND_ERROR, (error: string) => ({ error })),
  clearErrors: createAction(SETUP_TOOL_ACTIONS.CLEAR_ERRORS),
};

export type SetupToolActions = ActionsUnion<typeof SetupToolActions>;
