import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import classNames from 'classnames';

import { DetailList, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Battery } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';

import { factorizeDetailList } from './CustomerPvSystemWidget.helper';

const { headline, error } = T.customerSingle.overview.pvSystem;

interface Props {
  battery: Battery | undefined;
  queryStatus: ReturnType<typeof getStatus>;
  className?: ClassValue;
  link?: () => void;
}

export const CustomerPvSystemWidget = ({ className, queryStatus, battery, link }: Props) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon="PV"
    heading={I18n.t(headline)}
    link={link}
  >
    {queryStatus.pending && <Loader />}

    {queryStatus.success && battery && <DetailList list={factorizeDetailList(battery)} />}

    {queryStatus.error && (
      <WidgetWarning type="warning" icon="warning">
        {I18n.t(error)}
      </WidgetWarning>
    )}
  </Widget>
);
