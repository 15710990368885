import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { ActionBoxColors } from '+app/+lead/store/types';
import {
  LeadConfigurationTile,
  LeadConfigurationTileProps,
  LeadConfigurationTileStatus,
} from '+lead/components';
import { BodyLarge } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { LeadOffer } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { useLocaleContext } from '+utils/react/locale.provider';

import { LeadConfigurationActionBox } from '../LeadConfigurationActionBox';
import { LeadConfigurationLine } from '../LeadConfigurationLine';
import {
  mapConfigurationTileStatusForHardwareOffer,
  renderStatus,
} from '../LeadConfigurationTile/LeadConfigurationTile.helper';
import { factorizeBatteryDetails } from './LeadHardwareConfigurationTile.helper';

import './LeadHardwareConfigurationTile.component.scss';

const {
  form,
  configurationTile: { addTariff, headline, subheadline },
} = T.lead.hardware._salessolution_;

interface OwnProps {
  battery: LeadProductBattery;
  isTileActionDisabled: boolean;
  isStatusUpdateDisabled?: boolean;
  addSonnenFlatDirectTariff: () => void;
  isAddFlatDisabled: boolean;
  onHardwareStatusModalOpen?: (event: React.MouseEvent) => void;
  offer: LeadOffer;
}

type Props = Omit<
  LeadConfigurationTileProps,
  'isOfferCreationDisabled' | 'creationDate' | 'offerButtonLabel' | 'productName'
> &
  OwnProps;

export const LeadHardwareConfigurationTile: React.FC<Props> = ({
  battery,
  isTileActionDisabled,
  status = LeadConfigurationTileStatus.PREVIEW,
  isStatusUpdateDisabled = false,
  isAddFlatDisabled,
  onHardwareStatusModalOpen,
  addSonnenFlatDirectTariff,
  offer,
  ...restTileProps
}) => {
  const { createdAt, id: offerId } = offer;
  const { locale } = useLocaleContext();
  const sonnenBatteryDetails = factorizeBatteryDetails(locale)(battery);
  const mappedHardwareStatus = mapConfigurationTileStatusForHardwareOffer(status);

  return (
    <LeadConfigurationTile
      creationDate={createdAt}
      isTileActionDisabled={isTileActionDisabled}
      productName={I18n.t(T.lead._salessolution_.configurations.product.hardwareWithoutContract)}
      status={mappedHardwareStatus}
      offerId={offerId}
      {...restTileProps}
    >
      <LeadConfigurationActionBox
        mainColor={ActionBoxColors.BLUE}
        hoverable={false}
        hasLeftBorder={true}
        disabled={isAddFlatDisabled}
      >
        <BodyLarge text={I18n.t(addTariff.headline)} />

        <div className="add-tariff-button">
          <Button
            size={ButtonSize.MEDIUM}
            type={ButtonType.SECONDARY}
            label={I18n.t(addTariff.button)}
            disabled={isAddFlatDisabled}
            status={isAddFlatDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
            onClick={addSonnenFlatDirectTariff}
            dataTestId="leads-inbox-accept-lead-btn"
          />
        </div>
      </LeadConfigurationActionBox>

      <div className="hw-config-tile">
        <div className="hw-config-tile__header">
          <div className="header-status-wrapper">
            <LabelLarge text={I18n.t(headline.hardware)} />
            {renderStatus(mappedHardwareStatus)}
          </div>
        </div>

        <div className="hw-config-tile__details">
          <LabelMedium text={I18n.t(subheadline.sonnenBatterie)} />
          {sonnenBatteryDetails.map(({ title, value, type }, index) => (
            <LeadConfigurationLine
              title={title}
              value={value}
              type={type}
              key={`detail-${index}`}
            />
          ))}
        </div>

        <div className="hw-config-tile__status-button-wrapper">
          <Button
            label={I18n.t(form.statusChange.button.updateStatus)}
            onClick={onHardwareStatusModalOpen}
            size={ButtonSize.MEDIUM}
            type={ButtonType.SECONDARY}
            disabled={isStatusUpdateDisabled}
            status={isStatusUpdateDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
          />
        </div>
      </div>
    </LeadConfigurationTile>
  );
};
