import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, InfoBanner, Loader, MediaQuery } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { find, getOr, isNil } from 'lodash/fp';

import { findAcceptedHwProduct } from '+app/+lead/+configuration/containers/LeadConfigurationForm/LeadConfigurationForm.helper';
import { HardwarePageActions } from '+app/+lead/+hardware/store';
import { getHardwareStatusUpdateQueryStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import { ImpactAnalysisActions } from '+app/+lead/+impactAnalysis/store/+impactAnalysis.actions';
import {
  getFlatProduct,
  getHardwareProduct,
  getOfferProductStatus,
  isSentOrDraftOfferExpired,
} from '+app/+lead/+offer/store/+offer.helper';
import { LeadFlatConfigurationTile, LeadHardwareConfigurationTile } from '+app/+lead/components';
import { LeadHardwareStatusModal } from '+app/+lead/components/LeadHardwareStatusModal';
import { LEAD_IN_SETUP_STAGE, PATHS } from '+app/router';
import { isEmptyObject, mapActions } from '+app/utils';
import { getImpactAnalysisDeleteQueryStatus } from '+lead/+impactAnalysis/store/+impactAnalysis.selectors';
import { isLeadDataMissing } from '+lead/+overview/containers/LeadOverviewBasicDetails';
import { LeadOverviewPageActions } from '+lead/+overview/store/+overview.actions';
import {
  getDeleteExpireOfferQueryStatus,
  getDisplayMaxOffersWarning,
  getLeadOfferDeleteQueryStatus,
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityStatus,
  getOfferIdToChangeStatus,
  getOfferList,
  getOfferListQueryStatus,
  getPatchOfferToRecalculateQueryStatus,
  getProductBatteryList,
  getProductBatteryListQueryStatus,
  getRecalculateConfigurationQueryStatus,
} from '+lead/+overview/store/+overview.selectors';
import { getDynamicTariff, getLead, getLeadUpdateQueryStatus } from '+lead/store/+lead.selectors';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Button, ButtonType } from '+shared/components/Button';
import { getOpenModalId, LayoutActions, ModalId } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { isLeadFlatOfferAccepted } from '+shared/store/lead/lead.helpers';
import {
  FlatDocumentType,
  Lead,
  LeadConfigurationResponse,
  LeadImpactAnalysisResponse,
  LeadProductType,
  LeadProductVersion,
} from '+shared/store/lead/types';
import { LeadOffer, OfferProductStatus } from '+shared/store/lead/types/leadOffer.interface';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { QueryStatusMap } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { isStatusSet } from '+utils/status.util';

import { LeadOverviewConfigurationEmpty } from '../../components';
import { Alert, AlertTheme } from '../../components/Alert/Alert';
import DynamicTariffInfo from '../../components/DynamicTariffInfo/DynamicTariffInfo';
import { ProductCard } from '../../components/ProductCard/ProductCard';
import { isDsoBlackListed, isHardwareOnlyOffer } from '../../store/+overview.helper';
import { LeadOverviewVppCheckModal } from '../LeadOverviewVppCheckModal';
import {
  configurationWarningMap,
  isInvalidSonnenDirectOffer,
  isLastFlatOnlyOffer,
  isLastFlatSentOffer,
  isLastHardwareOnlySentOffer,
  isOfferBlocked,
  isProductUnavailableButFoundForLead,
  mapOfferStatus,
  reachedOffersLimit,
} from './LeadOverviewConfigurations.helper';
import { useConfigurationsPagination } from './LeadOverviewConfigurations.hook';

import './LeadOverviewConfigurations.component.scss';

const { newConfiguration, startNewConfigurationUnavailableDueToTso, preview } =
  T.lead.configuration._salessolution_;

const style = 'c-lead-overview-configurations';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  offers: getOfferList(state),
  hasDynamicTariff: getDynamicTariff(state),
  offersQueryStatus: getOfferListQueryStatus(state),
  offerDeleteQueryStatus: getLeadOfferDeleteQueryStatus(state),
  impactAnalysisDeleteQueryStatus: getImpactAnalysisDeleteQueryStatus(state),
  recalculateConfigurationQueryStatus: getRecalculateConfigurationQueryStatus(state),
  productAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityQueryStatus: getLeadOverviewProductAvailabilityStatus(state),
  offerIdToChangeStatus: getOfferIdToChangeStatus(state),
  productBatteryList: getProductBatteryList(state),
  productBatteryListQueryStatus: getProductBatteryListQueryStatus(state),
  hardwareStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
  isMaxConfigurationsWarningVisible: getDisplayMaxOffersWarning(state),
  openModalId: getOpenModalId(state),
  getPatchOfferToRecalculateQueryStatus: getPatchOfferToRecalculateQueryStatus(state),
  getDeleteExpireOfferQueryStatus: getDeleteExpireOfferQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  deleteOffer: LeadOverviewPageActions.deleteOffer,
  getDocument: LeadOverviewPageActions.getOfferDocument,
  composeOffer: (leadId: string, offerId: string) => push(PATHS.LEAD_OFFER({ leadId, offerId })),
  startImpactAnalysis: (leadId: string, offerId: string) =>
    push(PATHS.LEAD_IMPACT_ANALYSIS({ leadId, offerId })),
  setupComposeOffer: (leadId: string, offerId: string) =>
    push(PATHS.LEAD_OFFER({ leadId, offerId }, LEAD_IN_SETUP_STAGE)),
  setupStartImpactAnalysis: (leadId: string, offerId: string) =>
    push(PATHS.LEAD_IMPACT_ANALYSIS({ leadId, offerId }, LEAD_IN_SETUP_STAGE)),
  downloadImpactAnalysisFile: ImpactAnalysisActions.downloadImpactAnalysisFile,
  deleteImpactAnalysis: ImpactAnalysisActions.deleteImpactAnalysis,
  sendImpactAnalysis: ImpactAnalysisActions.sendImpactAnalysis,
  goToNewFlatDirectConfiguration: (leadId: string, leadStage?: string) =>
    push(PATHS.LEAD_CONFIGURATION_NEW_FLAT_DIRECT({ leadId }, leadStage)),
  goToNewFlatDirectConfigurationForExistingHardware: (
    leadId: string,
    leadStage?: string,
    offerId?: string
  ) => push(PATHS.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW({ leadId, offerId }, leadStage)),
  goToHardwareConfiguration: (leadId: string, leadStage?: string) =>
    push(PATHS.LEAD_HARDWARE_NEW({ leadId }, leadStage)),
  setAsyncFlatOfferSentStatus: LeadOverviewPageActions.setAsyncFlatOfferSentStatus,
  toggleModal: LayoutActions.toggleModal,
  updateHardwareStatus: HardwarePageActions.updateHardwareStatus,
  clearMaxOffersWarning: LeadOverviewPageActions.clearMaxOffersWarning,
  patchOfferToRecalculate: LeadActions.patchOfferToRecalculate,
  deleteExpiredOffer: LeadActions.deleteExpiredOffer,
});

type ProductData = {
  offer: LeadOffer;
  configuration: LeadConfigurationResponse | undefined;
  battery: LeadProductBattery | undefined;
  configurationName: string;
  status: QueryStatusMap;
  impactAnalysis?: LeadImpactAnalysisResponse;
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

export const LeadOverviewConfigurationsComponent: React.FC<Props> = ({
  actions,
  lead,
  leadStage,
  leadUpdateQueryStatus,
  offers,
  hasDynamicTariff,
  offersQueryStatus,
  offerDeleteQueryStatus,
  impactAnalysisDeleteQueryStatus,
  recalculateConfigurationQueryStatus,
  productAvailability,
  productAvailabilityQueryStatus,
  offerIdToChangeStatus,
  productBatteryList,
  productBatteryListQueryStatus,
  hardwareStatusUpdateQueryStatus,
  isMaxConfigurationsWarningVisible,
  openModalId,
  getPatchOfferToRecalculateQueryStatus,
  getDeleteExpireOfferQueryStatus,
}) => {
  const { useTrackPageLoadDependingOnRoute } = useAdobeAnalyticsTracking();
  useTrackPageLoadDependingOnRoute('OFFER_OVERVIEW');

  const offerToUpdateRef = useRef<LeadOffer | undefined>(undefined);

  useEffect(
    () => () => {
      actions.clearMaxOffersWarning();
    },
    []
  );

  useEffect(() => {
    if (offersQueryStatus.success && offerIdToChangeStatus) {
      actions.setAsyncFlatOfferSentStatus(offerIdToChangeStatus);
    }
  }, [offersQueryStatus]);

  const {
    shouldEnablePagination,
    shouldRenderConfiguration,
    shouldEnableNext,
    shouldEnablePrev,
    handleNextPage,
    handlePrevPage,
  } = useConfigurationsPagination(offers.length, {
    1: [0, MediaQuery.DOWN_SM.maxWidth],
    2: [MediaQuery.UP_SM.minWidth, MediaQuery.DOWN_MD.maxWidth],
    3: [MediaQuery.UP_MD.minWidth],
  });
  const isLeadHardwareOrderConfirmed = (lead?: Lead) =>
    isStatusSet(lead?.status.summary.hardwareOrderConfirmed);

  // DSO warnings (blacklisted or empty DSO), SonnenNow warning
  const isConfigurationWarningVisible = !!configurationWarningMap(lead, productAvailability).find(
    (warning) => warning.condition
  );

  const isCreatingNewProductDisabled = (productType: LeadProductType) => {
    if (productType === LeadProductType.BATTERY) {
      return isLeadHardwareOrderConfirmed(lead);
    }
    return (
      isLeadFlatOfferAccepted(lead) ||
      (lead && isLeadDataMissing(lead)) ||
      isConfigurationWarningVisible
    );
  };

  const getImpactAnalysis = (offerId: string) => {
    const impactAnalysis = offers.find((offer) => offer.id === offerId)?.impactAnalysis;
    return isEmptyObject(impactAnalysis || {}) ? undefined : impactAnalysis;
  };

  const productsMap: Record<string, ProductData> = offers.reduce(
    (acc, offer, i) => ({
      ...acc,
      [offer.id]: {
        offer,
        status: isHardwareOnlyOffer(offer) ? productBatteryListQueryStatus : offersQueryStatus,
        configuration: offer.configuration,
        configurationName: I18n.t(T.lead.overview._salessolution_.configurations.title, {
          configurationName: String.fromCharCode(65 + i),
        }),
        impactAnalysis: isEmptyObject(offer.impactAnalysis) ? undefined : offer.impactAnalysis,
        battery: productBatteryList.find((battery) => battery.id === offer.battery),
      },
    }),
    {}
  );

  const mappedProducts: ProductData[] = offers
    .map((offer) => productsMap[offer.id])
    .filter(Boolean);

  const isComposingOfferDisabled = (offer: LeadOffer) =>
    leadUpdateQueryStatus.pending ||
    isLeadFlatOfferAccepted(lead) ||
    (lead !== undefined && !isNil(lead.dso) ? isLeadDataMissing(lead) : false) ||
    (!!lead && isDsoBlackListed(productAvailability, lead.dso)) ||
    isOfferBlocked(offer) ||
    isSentOrDraftOfferExpired(offer);

  const isRemoveHwOfferDisabled = (offer: LeadOffer): boolean => {
    const status = getOfferProductStatus(offer, LeadProductType.BATTERY);
    return status
      ? [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(status)
      : false;
  };

  const isRemoveFlatOfferDisabled = (offer: LeadOffer): boolean => {
    const status =
      getOfferProductStatus(offer, LeadProductType.FLAT_X) ||
      getOfferProductStatus(offer, LeadProductType.FLAT_DIRECT);
    const isStatusAcceptedOrConfirmed = status
      ? [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(status)
      : false;

    return isStatusAcceptedOrConfirmed || leadUpdateQueryStatus.pending;
  };

  const getImpactAnalysisHandlers = (offerId: string) => {
    const impactAnalysis = getImpactAnalysis(offerId);

    if (!impactAnalysis) {
      return {
        onImpactAnalysisStart:
          leadStage === LEAD_IN_SETUP_STAGE
            ? () => actions.setupStartImpactAnalysis(lead!.id, offerId)
            : () => actions.startImpactAnalysis(lead!.id, offerId),
      };
    }

    return {
      onImpactAnalysisDelete: () => actions.deleteImpactAnalysis(lead!.id, impactAnalysis.id),
      onImpactAnalysisDownload: (event: React.SyntheticEvent<HTMLButtonElement>) => {
        actions.downloadImpactAnalysisFile(lead!.id, impactAnalysis.id);
      },
      onImpactAnalysisSend: () => actions.sendImpactAnalysis(lead!.id, offerId, impactAnalysis.id),
    };
  };

  const onNewConfiguration = () => {
    if (!lead) return;
    actions.goToNewFlatDirectConfiguration(lead.id, leadStage);
  };

  const updateHardwareStatus = (status: OfferProductStatus) => {
    const offer = offerToUpdateRef.current;
    if (!lead || !offer) return;

    const product = getHardwareProduct(offer);
    if (!product) return;

    actions.updateHardwareStatus(lead.id, [
      {
        offerId: offer.id,
        productId: product.productId,
        status,
      },
    ]);
  };

  const openHardwareStatusModal = (event: React.MouseEvent, offer: LeadOffer) => {
    offerToUpdateRef.current = offer;
    event.stopPropagation();
    actions.toggleModal(true, ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW);
  };

  const isHwStatusUpdateDisabled = (offer: LeadOffer): boolean =>
    isLeadHardwareOrderConfirmed(lead) || isOfferBlocked(offer);

  const flatDirectUnavailable: boolean =
    !!lead &&
    isProductUnavailableButFoundForLead(
      lead,
      productAvailability,
      LeadProductType.FLAT_DIRECT,
      LeadProductVersion.V_2_0
    );

  if (
    offersQueryStatus.pending ||
    productAvailabilityQueryStatus.pending ||
    productBatteryListQueryStatus.pending ||
    getPatchOfferToRecalculateQueryStatus.pending ||
    getDeleteExpireOfferQueryStatus.pending
  ) {
    return (
      <div className={`o-grid__column o-grid__column--md-12 ${style}__loader`}>
        <Loader />
      </div>
    );
  }

  const hasReachedOffersLimit = reachedOffersLimit(offers.length);

  return (
    <>
      {lead && isLeadDataMissing(lead) && !isNil(lead.dso) && (
        <p className={`${style}__general-error`}>
          {I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryHeader)}
        </p>
      )}

      {isMaxConfigurationsWarningVisible && hasReachedOffersLimit && (
        <div className={`${style}__max-configurations-warning-wrapper`}>
          <Alert
            theme={AlertTheme.WARNING}
            text={I18n.t(T.lead.overview._salessolution_.configurations.maxConfigurationsWarning)}
          />
        </div>
      )}

      <DynamicTariffInfo hasDynamicTariff={hasDynamicTariff} />

      <p className={`${style}__header`}>{I18n.t(newConfiguration.section.products)}</p>

      <div className={`${style}__products`}>
        <ProductCard productType={LeadProductType.FLAT_X} />

        <ProductCard
          onClick={onNewConfiguration}
          productType={LeadProductType.FLAT_DIRECT}
          disabled={
            hasDynamicTariff ||
            isCreatingNewProductDisabled(LeadProductType.FLAT_DIRECT) ||
            hasReachedOffersLimit ||
            flatDirectUnavailable
          }
          onHoverText={
            flatDirectUnavailable ? I18n.t(startNewConfigurationUnavailableDueToTso) : undefined
          }
        />
      </div>

      {isConfigurationWarningVisible && (
        <div className={`${style}__warning`}>
          <Alert
            theme={AlertTheme.WARNING}
            text={getOr(
              '',
              'msg',
              find(
                (warning) => warning.condition,
                configurationWarningMap(lead, productAvailability)
              )
            )}
          />
        </div>
      )}

      {shouldEnablePagination() && (
        <div className={`${style}__pagination__nav`}>
          <div className={`${style}__pagination__btn`}>
            {shouldEnablePrev() && (
              <Button
                onClick={handlePrevPage}
                type={ButtonType.TERTIARY}
                label={
                  <>
                    <Icon.Angle />
                    {I18n.t(preview.prevConfig)}
                  </>
                }
              />
            )}
          </div>
          <div className={`${style}__pagination__btn`}>
            {shouldEnableNext() && shouldEnablePrev() && (
              <Button
                onClick={handleNextPage}
                type={ButtonType.TERTIARY}
                className={`${style}__pagination__btn-action`}
                label={
                  <>
                    {I18n.t(preview.nextConfig)}
                    <Icon.Angle className={`${style}__pagination__btn-icon`} />
                  </>
                }
              />
            )}
          </div>
        </div>
      )}

      <p className={`${style}__header`}>{I18n.t(newConfiguration.section.offers)}</p>

      {hasReachedOffersLimit && (
        <div className={`${style}__max-offers-warning`}>
          <Alert theme={AlertTheme.WARNING} text={I18n.t(newConfiguration.maxOffersWarning)} />
        </div>
      )}

      {!mappedProducts.length ? (
        <InfoBanner
          icon={<Icon.Ufo />}
          title={I18n.t(newConfiguration.noOffersCreated.title)}
          subtitle={I18n.t(newConfiguration.noOffersCreated.text)}
        />
      ) : (
        <div className={`o-grid ${style}`}>
          <>
            {mappedProducts.map(
              (
                { configuration, configurationName, offer, status, impactAnalysis, battery },
                index
              ) =>
                shouldRenderConfiguration(index) && (
                  <div
                    key={offer.id}
                    className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}
                  >
                    {isHardwareOnlyOffer(offer) ? (
                      battery ? (
                        <LeadHardwareConfigurationTile
                          offer={offer}
                          title={configurationName}
                          status={mapOfferStatus(
                            getOfferProductStatus(offer, LeadProductType.BATTERY)
                          )}
                          battery={battery}
                          isTileActionDisabled={isRemoveHwOfferDisabled(offer)}
                          isPending={status.pending || offerDeleteQueryStatus.pending}
                          onRemove={() =>
                            actions.deleteOffer({
                              leadId: lead!.id,
                              offerId: offer.id,
                              isLastFlatOfferSent: false,
                              isLastHardwareOnlyOfferSent: isLastHardwareOnlySentOffer(
                                offer.id,
                                offers
                              ),
                              isLastFlatOnlyOffer: false,
                            })
                          }
                          isStatusUpdateDisabled={isHwStatusUpdateDisabled(offer)}
                          addSonnenFlatDirectTariff={() =>
                            actions.goToNewFlatDirectConfigurationForExistingHardware(
                              lead!.id,
                              leadStage,
                              offer.id
                            )
                          }
                          isAddFlatDisabled={
                            isCreatingNewProductDisabled(LeadProductType.FLAT_X) ||
                            isOfferBlocked(offer)
                          }
                          onHardwareStatusModalOpen={(event: React.MouseEvent) =>
                            openHardwareStatusModal(event, offer)
                          }
                        />
                      ) : (
                        <LeadOverviewConfigurationEmpty isLoading title={configurationName} />
                      )
                    ) : configuration ? (
                      <LeadFlatConfigurationTile
                        title={configurationName}
                        status={mapOfferStatus(
                          getOfferProductStatus(offer, LeadProductType.FLAT_X) ||
                            getOfferProductStatus(offer, LeadProductType.FLAT_DIRECT),
                          !!lead && isInvalidSonnenDirectOffer(offer, lead, productAvailability)
                        )}
                        hardwareStatus={mapOfferStatus(
                          getOfferProductStatus(offer, LeadProductType.BATTERY)
                        )}
                        configuration={configuration}
                        impactAnalysis={impactAnalysis}
                        isTileActionDisabled={isRemoveFlatOfferDisabled(offer)}
                        isComposingOfferDisabled={isComposingOfferDisabled(offer)}
                        isHardwareProductAccepted={!!findAcceptedHwProduct(offer.products)}
                        isPending={
                          status.pending ||
                          offerDeleteQueryStatus.pending ||
                          recalculateConfigurationQueryStatus.pending ||
                          impactAnalysisDeleteQueryStatus.pending
                        }
                        onRemove={() =>
                          actions.deleteOffer({
                            leadId: lead!.id,
                            offerId: offer.id,
                            isLastFlatOfferSent: isLastFlatSentOffer(offer.id, offers),
                            isLastHardwareOnlyOfferSent: isLastHardwareOnlySentOffer(
                              offer.id,
                              offers
                            ),
                            isLastFlatOnlyOffer: isLastFlatOnlyOffer(offer.id, offers),
                          })
                        }
                        onCompose={
                          leadStage === LEAD_IN_SETUP_STAGE
                            ? () => actions.setupComposeOffer(lead!.id, offer.id)
                            : () => actions.composeOffer(lead!.id, offer.id)
                        }
                        {...getImpactAnalysisHandlers(offer.id)}
                        onDownload={() =>
                          actions.getDocument({
                            leadId: lead!.id,
                            offerId: offer.id,
                            documentType: FlatDocumentType.CONFIGURATION,
                          })
                        }
                        onRecalculation={() => {
                          actions.patchOfferToRecalculate(lead!.id, configuration.id);
                        }}
                        onDeleteExpiredOffer={() => {
                          actions.deleteExpiredOffer(
                            lead!.id,
                            offer.id,
                            getFlatProduct(offer)?.productId || ''
                          );
                        }}
                        isAnyOfferAccepted={isLeadFlatOfferAccepted(lead)}
                        isDsoBlackListed={!!lead && isDsoBlackListed(productAvailability, lead.dso)}
                        isOfferInvalid={
                          !!lead && isInvalidSonnenDirectOffer(offer, lead, productAvailability)
                        }
                        isStatusUpdateDisabled={isHwStatusUpdateDisabled(offer)}
                        onHardwareStatusModalOpen={(event: React.MouseEvent) =>
                          openHardwareStatusModal(event, offer)
                        }
                        isOfferBlocked={isOfferBlocked(offer)}
                        productBatteryList={productBatteryList}
                        offer={offer}
                      />
                    ) : (
                      <LeadOverviewConfigurationEmpty isLoading title={configurationName} />
                    )}
                  </div>
                )
            )}

            <LeadOverviewVppCheckModal />

            {openModalId === ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW && (
              <LeadHardwareStatusModal
                modalId={ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW}
                offerRef={offerToUpdateRef}
                submitAction={(status) => updateHardwareStatus(status)}
                queryStatus={hardwareStatusUpdateQueryStatus}
              />
            )}
          </>
        </div>
      )}
    </>
  );
};

export const LeadOverviewConfigurations = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadOverviewConfigurationsComponent);
