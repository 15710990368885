import { isNil } from 'lodash/fp';
import { EMPTY, of } from 'rxjs';

export const dataGuard =
  <Payload extends any>(action: (payload: Payload) => void) =>
  (data?: Payload) =>
    !isNil(data) ? of(action(data)) : EMPTY;

export const anyValueGuard = (obj: any) =>
  Object.values(obj).find((val) => !!val) ? obj : undefined;
