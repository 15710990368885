import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as classNames from 'classnames';
import { Formik } from 'formik';

import { getNewsFormPartnerInitialValues, sendNewsSchema } from '+app/+newsChannel';
import {
  FormInput,
  FormInputCheckboxGroup,
  FormInputDate,
  FormInputMultiselect,
  FormInputRadioGroup,
  FormInputSelect,
} from '+shared/components';
import { Button, ButtonSize, MainType } from '+shared/components/Button';
import { FormInputEditor } from '+shared/components/Form';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { News } from '+shared/store/firebase/types/news.interface';
import { dateUtil } from '+utils/date.util';
import { getMarketKeys } from '+utils/market.util';

import { SendNewsPartnerHelper } from '../../containers/SendNewsPartner/SendNewsPartner.helper';

import './NewsFormPartner.component.scss';

interface Props {
  onSubmit: (data: News) => void;
  loading: boolean;
  error: string;
  isEdit?: boolean;
  news?: News;
}

export const NewsFormPartner: React.FC<Props> = ({ onSubmit, loading, error, isEdit, news }) => {
  const [messageValue, setMessageValue] = React.useState<any>(undefined);
  const [messageError, setMessageError] = React.useState<string>('');

  const initialValues = news || getNewsFormPartnerInitialValues();

  React.useEffect(() => {
    const setDefaultMessageValue = () => {
      const defaultMessageValue = news ? news.message : {};
      setMessageValue(defaultMessageValue);
    };

    setDefaultMessageValue();
  }, []);

  const onMessageChange = (value: any) => {
    const numBytesInMB = 1024 * 1024;
    const maxMessageSizeInMB: number = 1;
    const messageSizeInMB = value && value.length ? (value.length / numBytesInMB).toFixed(2) : 0;

    setMessageValue(value);
    if (parseFloat(messageSizeInMB.toString()) > maxMessageSizeInMB) {
      setMessageError(
        // eslint-disable-next-line max-len
        `The message is too large. The content may not exceed ${maxMessageSizeInMB} MB, including text and images. Current message size: ${messageSizeInMB} MB.`
      );
    } else {
      setMessageError('');
    }
  };
  const onNewsFormSubmit = (news: News) => {
    const isMessageEmpty = Object.keys(messageValue).length === 0;
    const message = messageValue;
    if (isMessageEmpty) {
      return setMessageError(I18n.t(T.news.channel.sendNews.fields.message.requiredText));
    }

    onSubmit({ ...news, message });
  };

  if (!messageValue) {
    return null;
  }

  return (
    <div className={classNames('c-news-form', { 'c-news-form--edit': isEdit })}>
      <Formik
        initialValues={initialValues}
        validationSchema={sendNewsSchema}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={onNewsFormSubmit}
        render={(form) => (
          <form onSubmit={form.handleSubmit}>
            <div className={'c-form-section'}>
              <h2 className={'c-form-section__title'}>
                {I18n.t(T.news.channel.sendNews.section.newsDetails)}
              </h2>
              <FormInputSelect
                className={'c-form-input-field--width45 c-form-input-field--with-star'}
                form={form}
                label={I18n.t(T.news.channel.sendNews.fields.newsType.text)}
                name={'level'}
                collection={SendNewsPartnerHelper.getNewsType()}
                mapper={(key) => key.label}
              />
              <FormInputSelect
                className={`
                  c-form-input-field--width45
                  c-form-input-field--with-star
                  c-news-form-partner__news-style-dropdown
                `}
                form={form}
                label={I18n.t(T.news.channel.sendNews.fields.newsTag.text)}
                name={'tag'}
                collection={SendNewsPartnerHelper.getNewsTag()}
                mapper={(key) => key.label}
              />
              <FormInputMultiselect
                className={'c-form-input-field--width45 c-form-input-field--with-star'}
                form={form}
                label={I18n.t(T.news.channel.sendNews.fields.market.text)}
                name={'markets'}
                collection={getMarketKeys()}
              />
              <div className={'c-form-input-field c-form-input-field--width45'}>
                <FormInputDate
                  form={form}
                  minDate={isEdit ? undefined : dateUtil.todayDate()}
                  label={I18n.t(T.news.channel.sendNews.fields.expirationDate.text)}
                  name={'expiryDate'}
                  className={'c-news-form__input-date'}
                />
                <p className={'c-news-form__description'}>
                  {I18n.t(T.news.channel.sendNews.fields.expirationDate.description)}
                </p>
              </div>
            </div>

            <div className={'c-form-section'}>
              <h2 className={'c-form-section__title'}>
                {I18n.t(T.news.channel.sendNews.section.newsTarget)}
              </h2>
              <FormInputCheckboxGroup
                className={''}
                form={form}
                collection={SendNewsPartnerHelper.rolesCollection()}
                name={'roles'}
              />
            </div>

            <div className={'c-form-section'}>
              <h2 className={'c-form-section__title'}>
                {I18n.t(T.news.channel.sendNews.section.notification)}
              </h2>
              <FormInputRadioGroup
                form={form}
                name={'isDisplayedAsNotification'}
                text={''}
                collection={SendNewsPartnerHelper.isDisplayedAsNotificationCollection()}
              />

              {form.values.isDisplayedAsNotification && (
                <>
                  <FormInputDate
                    form={form}
                    minDate={isEdit ? undefined : dateUtil.todayDate()}
                    label={I18n.t(T.news.channel.sendNews.fields.notificationExpiryDate.text)}
                    name={'notificationExpiryDate'}
                    className={'c-news-form__input-date c-form-input-field--with-star'}
                  />
                  <p className={'c-news-form__description'}>
                    {I18n.t(T.news.channel.sendNews.fields.notificationExpiryDate.description)}
                  </p>
                </>
              )}
            </div>

            <div className={'c-form-section'}>
              <h2 className={'c-form-section__title'}>
                {I18n.t(T.news.channel.sendNews.section.message)}
              </h2>
              <FormInput
                className={'c-form-input-field--width100 c-form-input-field--with-star'}
                form={form}
                label={I18n.t(T.news.channel.sendNews.fields.title.text)}
                name={'shortMessage'}
                type={'text'}
              />
              <FormTextarea
                className={'c-form-input-field--width100'}
                form={form}
                name={'introduction'}
                label={I18n.t(T.news.channel.sendNews.fields.introduction.text)}
              />
              <FormInputEditor
                className={'c-form-input-field--width100 c-form-input-field--with-star'}
                value={messageValue}
                label={I18n.t(T.news.channel.sendNews.fields.message.text)}
                onChange={onMessageChange}
                error={messageError}
              />
            </div>

            <Button
              className={'c-news-form__button'}
              mainType={MainType.SUBMIT}
              label={
                isEdit
                  ? I18n.t(T.news.channel.sendNews.editButton.text)
                  : I18n.t(T.news.channel.sendNews.publishButton.text)
              }
              size={ButtonSize.MEDIUM}
              loading={loading}
            />
            <br />
            {error}
          </form>
        )}
      />
    </div>
  );
};
