import { Action } from 'redux';
import { Observable, ObservableInput, timer } from 'rxjs';
import { exhaustMap, switchMap, takeUntil } from 'rxjs/operators';

import { ActionCreator, ofType } from '+app/utils';

interface PollingOptions<P> {
  startOn: ActionCreator<string, P>;
  stopOn: Array<ActionCreator<string, any>>;
  interval: number;
}

export const polling =
  <P extends unknown>({ startOn, stopOn, interval }: PollingOptions<P>) =>
  <Output extends any>(effect: (params: ReturnType<typeof startOn>) => ObservableInput<Output>) =>
  <Input extends any>(o: Observable<Action<Input>>) =>
    o.pipe(
      ofType(startOn),
      switchMap((params) =>
        timer(0, interval).pipe(
          takeUntil(
            o.pipe(
              (ofType as any)(...stopOn) // @TODO: Resolve typing issues :(
            )
          ),
          exhaustMap(() => effect(params as any)) // @TODO: Resolve typing issues :(
        )
      )
    );
