import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { PVRegisterInfoStatus } from '../../store/+pvRegister.dictionary';

export const mapInfoStatus = (step: PVRegisterInfoStatus): string | null => {
  switch (step) {
    case PVRegisterInfoStatus.NOT_SENT:
      return I18n.t(T.setupTool.pvRegister.help.textBottomNotSent);
    case PVRegisterInfoStatus.SENT:
      return I18n.t(T.setupTool.pvRegister.help.textBottomSent);
    case PVRegisterInfoStatus.GENERATED:
      return I18n.t(T.setupTool.pvRegister.help.textBottomGenerated);
    case PVRegisterInfoStatus.GENERATED_BATTERY_ONLY:
      return I18n.t(T.setupTool.pvRegister.help.textBottomGeneratedBatteryOnly);
    default:
      return null;
  }
};
