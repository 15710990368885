import { LanguageSelectItem } from '@sonnen/shared-web';

import { Market } from '+utils/market.util';

import flagES from '../../assets/img/flag-es.svg';
import flagDE from '../../assets/img/flag-germany.svg';
import flagIT from '../../assets/img/flag-italy.svg';
import flagUK from '../../assets/img/flag-uk.svg';
import flagUSA from '../../assets/img/flag-usa.svg';

export enum Locale {
  EN_US = 'en-us',
  EN_GB = 'en-gb',
  DE = 'de',
  IT = 'it',
  ES = 'es',
}

export const LANGUAGES: LanguageSelectItem[] = [
  {
    locale: Locale.EN_US,
    name: 'English (US)',
    icon: flagUSA,
  },
  {
    locale: Locale.EN_GB,
    name: 'English (UK)',
    icon: flagUK,
  },
  {
    locale: Locale.DE,
    name: 'Deutsch',
    icon: flagDE,
  },
  {
    locale: Locale.IT,
    name: 'Italiano',
    icon: flagIT,
  },
  {
    locale: Locale.ES,
    name: 'Español',
    icon: flagES,
  },
];

export const DEFAULT_LOCALE = Locale.EN_US;
export const DEFAULT_LANGUAGE = LANGUAGES.find((lang) => lang.locale === DEFAULT_LOCALE);

export const DEFAULT_SUPPORTED_LOCALES: string[] = [
  Locale.EN_US,
  Locale.EN_GB,
  Locale.DE,
  Locale.IT,
  Locale.ES,
];

export const getSupportedLocales = (isSpanishMarketEnabled: boolean) => ({
  [Market.IT]: [Locale.IT, Locale.EN_GB],
  [Market.AU]: [Locale.EN_US, Locale.EN_GB],
  [Market.DE]: [Locale.DE, Locale.EN_US, Locale.EN_GB],
  [Market.AT]: [Locale.DE, Locale.EN_US, Locale.EN_GB],
  [Market.CH]: [Locale.DE, Locale.EN_US, Locale.EN_GB],
  ...(isSpanishMarketEnabled
    ? {
        [Market.US]: [Locale.EN_US, Locale.EN_GB, Locale.ES],
        [Market.GME]: [Locale.DE, Locale.EN_US, Locale.EN_GB],
        [Market.ES]: [Locale.ES, Locale.EN_GB],
      }
    : {
        [Market.US]: [Locale.EN_US, Locale.EN_GB],
        [Market.GME]: [Locale.DE, Locale.EN_US, Locale.EN_GB],
      }),
});
