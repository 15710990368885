import { createSelector } from 'reselect';

import { getStatus } from '../query/query.utils';
import { StoreState } from '../store.interface';
import { CLAIM_BATTERY_OWNERSHIP_QUERY, IDENTIFY_MISSING_BATTERY_QUERY } from './partner.state';

const getPartnerState = (state: StoreState) => state.shared.partner;

export const getPartnerMissingBattery = createSelector(
  getPartnerState,
  (partner) => partner.missingBattery
);

export const getAssignedBattery = createSelector(
  getPartnerMissingBattery,
  (missingBattery) => missingBattery.assignedBattery
);

export const getIdentifyMissingBatteryQueryStatus = createSelector(getPartnerState, (partner) =>
  getStatus(partner[IDENTIFY_MISSING_BATTERY_QUERY])
);

export const getClaimBatteryOwnershipQuery = createSelector(
  getPartnerState,
  (partner) => partner[CLAIM_BATTERY_OWNERSHIP_QUERY]
);

export const getClaimBatteryOwnershipQueryErrorStatus = createSelector(
  getClaimBatteryOwnershipQuery,
  (query) => query.error?.status
);

export const getClaimBatteryOwnershipQueryStatus = createSelector(
  getClaimBatteryOwnershipQuery,
  (query) => getStatus(query)
);

export const getClaimBatteryData = createSelector(
  getPartnerMissingBattery,
  ({ serialNumber, postalCode, pvPeakPower, customerLastName }) => ({
    serialNumber: serialNumber || '',
    postalCode: postalCode || '',
    pvPeakPower: pvPeakPower || 0,
    customerLastName: customerLastName || '',
  })
);

export const hasAllClaimBatteryData = createSelector(
  getPartnerMissingBattery,
  ({ serialNumber, postalCode, pvPeakPower, customerLastName }) =>
    !!(serialNumber && postalCode && pvPeakPower && customerLastName)
);
