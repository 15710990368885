import * as React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';

import { Header, MediaQuery } from '@sonnen/shared-web';

import classNames from 'classnames';

import { ROUTES } from '+app/router/routes';
import { PartnerApplications } from '+shared/store/auth';

import { Hamburger } from '../Hamburger';
import { SonnenLogo } from '../SonnenLogo/SonnenLogo';
import ToolboxButton from './ToolboxButton';

import './Nav.component.scss';

interface Props {
  onToggle: (arg0: boolean) => void;
  companyLogo: string;
  isSticky?: boolean;
  isMobileNavActive?: boolean;
  navItems?: React.ReactNode;
  partnerApplications: string | undefined;
  impersonateUserId: string | undefined;
}

export const Nav: React.FC<Props> = ({
  isSticky = false,
  isMobileNavActive = false,
  navItems,
  onToggle,
  companyLogo,
  partnerApplications,
  impersonateUserId,
}) => {
  const mobileNavClassNames = classNames('c-nav--overlayed', { 'is-active': isMobileNavActive });
  const hasToolboxAccess =
    partnerApplications?.includes(PartnerApplications.PARTNER_TOOLBOX) || impersonateUserId;

  return (
    <Media query={MediaQuery.UP_NAV}>
      {(isDesktop) => (
        <Header
          isSticky={isSticky}
          logo={
            <Link to={ROUTES.DASHBOARD[0]}>
              {companyLogo ? (
                <img
                  className="c-nav__logo"
                  src={companyLogo}
                  alt="Company Logotype"
                  data-hj-suppress={true}
                />
              ) : (
                <SonnenLogo />
              )}
            </Link>
          }
        >
          {hasToolboxAccess && (
            <div className="switch-to-toolbox-btn">
              <ToolboxButton impersonateUserId={impersonateUserId} />
            </div>
          )}

          {isDesktop ? (
            <nav>
              <ul className="c-nav__navigation">{navItems}</ul>
            </nav>
          ) : (
            <Hamburger
              className="c-nav__hamburger"
              isActive={isMobileNavActive}
              onClick={() => onToggle(!isMobileNavActive)}
            />
          )}
          {!isDesktop && (
            <nav className={mobileNavClassNames}>
              <ul className="c-nav__navigation c-nav__navigation--mobile">{navItems}</ul>
            </nav>
          )}
        </Header>
      )}
    </Media>
  );
};
