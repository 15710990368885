import { uniq } from 'lodash';

import {
  isBatteryAvailableForSale,
  isOrWasBatteryAvailableForSale,
} from '+shared/store/lead/lead.helpers';
import { BatteryModelName } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';

export const getAvailableBatteryCapacitiesForBatteryModel = (
  productBatteryList: LeadProductBattery[],
  isNewBattery: boolean,
  batteryModel: BatteryModelName
): LeadProductBattery[] =>
  uniq(
    productBatteryList
      .filter((productBattery) =>
        isNewBattery
          ? isBatteryAvailableForSale(productBattery)
          : isOrWasBatteryAvailableForSale(productBattery)
      )
      .filter((battery) => battery.modelName === batteryModel)
  );
