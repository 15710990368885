import * as React from 'react';

import * as classNames from 'classnames';

import './FormErrorBanner.component.scss';

interface Props {
  isVisible: boolean;
  error: string;
  toLeft?: boolean;
}

export const FormErrorBanner: React.FC<Props> = ({ isVisible, error, toLeft }) => {
  return isVisible ? (
    <div
      className={classNames('c-form-error-banner', {
        'c-form-error-banner--left': toLeft,
      })}
      id={'form-error-banner'}
    >
      {error}
    </div>
  ) : null;
};
