import { createSelector } from 'reselect';

import { sortAlphabetically } from '+app/utils';
import { getStatus } from '+shared/store/query/query.utils';
import { getDsoRegisterForm } from '+shared/store/setupTool';

import {
  DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
  GET_BATTERIES_QUERY,
  GET_BATTERY_NAMES_QUERY,
  GET_DSO_LIST_QUERY,
  GET_INSTALLERS_QUERY,
  GET_PV_INVERTER_VENDORS_QUERY,
  GET_PV_INVERTERS_QUERY,
  GET_PV_MODULE_VENDORS_QUERY,
  GET_PV_MODULES_QUERY,
  REMOVE_INSTALLERS_QUERY,
  SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY,
  SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY,
  SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY,
  SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY,
  SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY,
  SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY,
  SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY,
  SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY,
  SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY,
  SUBMIT_INSTALLER_QUERY,
} from './+form.dictionary';

export const getDsoRegistrationFormSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY]
);

export const getDsoRegistrationFormSubmitQueryStatus = createSelector(
  getDsoRegistrationFormSubmitQuery,
  (query) => getStatus(query)
);

export const getMeterPreparationPicturesDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY]
);

export const getMeterPreparationPicturesDocumentSubmitQueryStatus = createSelector(
  getMeterPreparationPicturesDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getWiringDiagramDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY]
);

export const getWiringDiagramDocumentSubmitQueryStatus = createSelector(
  getWiringDiagramDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getSignalMeasurementsPictureSubmitQueryStatus = createSelector(
  getDsoRegisterForm,
  (state) => getStatus(state[SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY])
);

export const getLanConnectionPictureSubmitQueryStatus = createSelector(
  getDsoRegisterForm,
  (state) => getStatus(state[SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY])
);

export const getCompletionNotificationDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY]
);

export const getCompletionNotificationDocumentSubmitQueryStatus = createSelector(
  getCompletionNotificationDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getSitePlanDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY]
);

export const getSitePlanDocumentSubmitQueryStatus = createSelector(
  getSitePlanDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getAdditionalDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY]
);

export const getAdditionalDocumentSubmitQueryStatus = createSelector(
  getAdditionalDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getPowerOfAttorneyDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY]
);

export const getPowerOfAttorneyDocumentSubmitQueryStatus = createSelector(
  getPowerOfAttorneyDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getPvrConfirmationDocumentSubmitQuery = createSelector(
  getDsoRegisterForm,
  (state) => state[SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY]
);

export const getPvrConfirmationDocumentSubmitQueryStatus = createSelector(
  getPvrConfirmationDocumentSubmitQuery,
  (query) => getStatus(query)
);

export const getSubmitInstallerQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[SUBMIT_INSTALLER_QUERY])
);

export const getRemoveInstallersQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[REMOVE_INSTALLERS_QUERY])
);

export const getGetBatteryNamesQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_BATTERY_NAMES_QUERY])
);

export const getGetBatteriesQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_BATTERIES_QUERY])
);

export const getGetDsoListQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_DSO_LIST_QUERY])
);

export const getGetInstallersQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_INSTALLERS_QUERY])
);

export const getGetPvInverterVendorsQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_PV_INVERTER_VENDORS_QUERY])
);

export const getGetPvInvertersQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_PV_INVERTERS_QUERY])
);

export const getGetPvModuleVendorsQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_PV_MODULE_VENDORS_QUERY])
);

export const getGetPvModulesQueryStatus = createSelector(getDsoRegisterForm, (state) =>
  getStatus(state[GET_PV_MODULES_QUERY])
);

export const getCustomerDataForm = createSelector(
  getDsoRegisterForm,
  (state) => state.customerData
);

export const getIsOnlinePortal = createSelector(
  getCustomerDataForm,
  (state) => state && state.is_online_portal
);

export const getPvSystemForm = createSelector(getDsoRegisterForm, (state) => state.pvSystem);

export const getSonnenBatteryForm = createSelector(
  getDsoRegisterForm,
  (state) => state.sonnenBattery
);

export const getMeasuringDeviceForm = createSelector(
  getDsoRegisterForm,
  (state) => state.measuringDevice
);

export const getInstallerDataForm = createSelector(
  getDsoRegisterForm,
  (state) => state.installerData
);

export const getDocumentsDataForm = createSelector(getDsoRegisterForm, (state) => state.documents);

export const getDsoList = createSelector(getDsoRegisterForm, (state) => state.dsoList);

export const getPVModules = createSelector(getDsoRegisterForm, (state) =>
  state.pvModules.sort(sortAlphabetically('name'))
);
export const getPVInverters = createSelector(getDsoRegisterForm, (state) =>
  state.pvInverters.sort(sortAlphabetically('name'))
);

export const getBatteryNames = createSelector(getDsoRegisterForm, (state) => state.batteryNames);

export const getInstallers = createSelector(getDsoRegisterForm, (state) => state.installersList);

export const makeGetBatteries = (name: string) =>
  createSelector(getBatteryNames, (state) => {
    const battery = state.find((el) => el.name === name);
    return battery ? battery.batteries : null;
  });

export const getBatteryNamesNonUniqueCount = createSelector(
  getDsoRegisterForm,
  (state) => state.batteryNamesNonUniqueCount
);

export const getIsRadioSerialNumberFieldVisible = createSelector(
  getDsoRegisterForm,
  (state) => state.isRadioSerialNumberFieldVisible
);

export const getFormFulfillment = createSelector(getDsoRegisterForm, (state) => state.fulfilled);

export const getSetupDataStatuses = createSelector(
  getDsoRegisterForm,
  (state) => state.setupDataStatuses
);

export const getActiveStep = createSelector(getDsoRegisterForm, (state) => state.activeStep);

export const getUploadedDocumentsArray = createSelector(
  getDsoRegisterForm,
  (state) => state.uploadedDocuments
);

export const getUploadedDocuments = createSelector(getUploadedDocumentsArray, (uploadedDocuments) =>
  uploadedDocuments.reduce(
    (prev, document) => ({ ...prev, [document.upload.category]: document }),
    {}
  )
);

export const getLatestModificationDate = createSelector(
  getDsoRegisterForm,
  (state) => state.updatedAt
);

export const getPvModuleVendorsAllFetched = createSelector(
  getDsoRegisterForm,
  (state) => state.pvModuleVendorsAllFetched
);

export const getPvInverterVendorsAllFetched = createSelector(
  getDsoRegisterForm,
  (state) => state.pvInverterVendorsAllFetched
);
