import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import { BatteryIntelligentCharging } from '+shared/store/battery/types/battery.interface';

import type { BatteryOperationsHeaderLabel } from '../../components/BatteryOperationsHeader';

export interface IntelligentChargingForm {
  isActive: boolean;
}

export enum IntelligentChargingFormKeys {
  IS_ACTIVE = 'isActive',
}

export const defaultValues: IntelligentChargingForm = {
  isActive: false,
};

export const mapBatteryIntelligentChargingToBoolean = (
  batteryIntelligentCharging?: BatteryIntelligentCharging
): boolean => {
  switch (batteryIntelligentCharging) {
    case 'on':
      return true;
    case 'off':
      return false;
    default:
      return false;
  }
};

export const mapBatteryIntelligentChargingToTitle = (
  batteryIntelligentCharging?: BatteryIntelligentCharging
): string => {
  const isActive = mapBatteryIntelligentChargingToBoolean(batteryIntelligentCharging);

  return isActive
    ? I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .headlineWhenActive
      )
    : I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .headlineWhenInactive
      );
};

export const mapBatteryIntelligentChargingToLabel = (
  isPending: boolean,
  isPvFeedInLimitSet: boolean,
  batteryIntelligentCharging?: BatteryIntelligentCharging
): BatteryOperationsHeaderLabel => {
  const isActive = mapBatteryIntelligentChargingToBoolean(batteryIntelligentCharging);
  const isNotDefined = isNil(batteryIntelligentCharging) || !isPvFeedInLimitSet;

  if (isNotDefined) {
    return '';
  }

  if (isPending) {
    return {
      text: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .statusPending
      ),
      variant: 'gray',
    };
  }

  if (isActive) {
    return {
      text: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement.statusOn
      ),
      variant: 'blue',
    };
  }

  return {
    text: I18n.t(
      T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement.statusOff
    ),
    variant: 'gray',
  };
};

export const mapNotAvailableReasonToTooltip = (isEaton: boolean): string =>
  isEaton
    ? I18n.t(T.customerSingle.batteryDetails.batteryOperations.notAvailableTooltip)
    : I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .notAvailableWhenNoFeedInLimit
      );
