import * as React from 'react';

import { Icon, ModalLayout, PageSubheadline } from '@sonnen/shared-web';

import './FormStatus.component.scss';

interface Props {
  headline: string | JSX.Element;
  children?: React.ReactNode;
  footer: React.ReactNode;
  isSuccess: boolean;
}

export const FormStatus: React.FC<Props> = ({ headline, footer, isSuccess, children }) => (
  <div className={'c-form-status'}>
    <ModalLayout footer={footer}>
      <div className={'c-form-status__content'}>
        {isSuccess ? (
          <Icon.PaperPlane className={'c-form-status__icon'} />
        ) : (
          <Icon.Error className={'c-form-status__icon'} />
        )}
        <div className={'c-form-status__headline'}>
          <PageSubheadline smallGap={true} noBorder={true}>
            {headline}
          </PageSubheadline>
        </div>
        {children && <div className={'c-form-status__body'}>{children}</div>}
      </div>
    </ModalLayout>
  </div>
);
