import { FeatureProvider } from '@sonnen/shared-web';

import { AppConfiguration, getAppConfig } from './app.config';
import { EnvConfiguration, getEnvConfig } from './env.config';
import { Env, getEnvironment } from './environment';

type Configuration = EnvConfiguration & AppConfiguration;

export const getConfig = (environment: Env): Configuration => ({
  ...getAppConfig(environment),
  ...getEnvConfig(environment),
});

export const Config = getConfig(getEnvironment());

FeatureProvider.setEnvironmentAccessor(getEnvironment);

export const switchEnvironment = (environment: Env) => {
  Object.assign(Config, getConfig(environment));
};
