import { DocumentType } from './types';

export const mapFilenameToDocumentType = (file: string) => {
  switch (file) {
    case 'terms-of-service':
      return DocumentType.TERMS_AND_CONDITIONS;
    case 'privacy-policy':
      return DocumentType.PRIVACY_POLICY;
    default:
      return '' as DocumentType;
  }
};
