import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

const MIN_CHARACTERS = 3;

export const caseListSearchSchema = () =>
  Yup.object({
    search: Yup.string()
      .min(MIN_CHARACTERS, I18n.t(T.validation.minCharacters, { min: MIN_CHARACTERS }))
      .trim(),
  });
