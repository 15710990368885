import { isEmpty } from 'lodash/fp';

export enum OpenWidgetTitle {
  INTELLIGENT_CHARGING = 'intelligentCharging',
  OPERATING_MODE = 'operatingMode',
  BACKUP_BUFFER = 'backupBuffer',
}

export const isWidgetDisabled = (
  widgetType: OpenWidgetTitle,
  activeWidget: OpenWidgetTitle[]
): boolean => {
  switch (widgetType) {
    case OpenWidgetTitle.BACKUP_BUFFER:
      return !isEmpty(activeWidget) && !activeWidget.includes(OpenWidgetTitle.BACKUP_BUFFER);
    case OpenWidgetTitle.OPERATING_MODE:
      return !isEmpty(activeWidget) && !activeWidget.includes(OpenWidgetTitle.OPERATING_MODE);
    case OpenWidgetTitle.INTELLIGENT_CHARGING:
      return !isEmpty(activeWidget) && !activeWidget.includes(OpenWidgetTitle.INTELLIGENT_CHARGING);
    default:
      return false;
  }
};
