import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, LinkButton, NewsMessage, StatusDecorator, TableCell } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { setHintColor } from '+app/+newsChannel/containers/NewsSection/News.helper';
import { removeDuplicateSpaces, removeHtmlTags } from '+app/+newsMain/store/newsMain.helpers';
import { DeleteButton } from '+shared/components/DeleteButton';
import { GenericNews, ListItemNews, News } from '+shared/store/firebase/types/news.interface';
import { dateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';

import './NewsListTableItem.component.scss';

interface Props {
  item: ListItemNews<News>;
  deleteNews: (news: GenericNews) => void;
  openNewsEditMode: (news: GenericNews) => void;
}

export const NewsListTableItem: React.FC<Props> = ({ item, deleteNews, openNewsEditMode }) => {
  const messageWithoutHtmlTags = removeHtmlTags(
    item.introduction ? item.introduction : item.message
  );
  const messageWithoutDuplicateSpaces = removeDuplicateSpaces(messageWithoutHtmlTags);
  const expiryDate = item.expiryDate ? dateUtil.of(item.expiryDate) : undefined;
  const notificationExpiryDate = item.notificationExpiryDate
    ? dateUtil.of(item.notificationExpiryDate)
    : undefined;

  return (
    <>
      {item.isActive && (
        <div className={'c-news-list-table-item__status-decorator'}>
          <StatusDecorator type={setHintColor(item.levelValue)} />
        </div>
      )}
      <div className={'c-news-list-table-item'}>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>{item.shortMessage}</div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div
            className={
              'c-news-list-table-item__text-wrapper c-news-list-table-item__text-wrapper--ellipsis'
            }
          >
            <NewsMessage message={messageWithoutDuplicateSpaces} />
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>
            {item.roles &&
              item.roles.map((role) => (
                <React.Fragment key={role}>
                  {I18n.t(T.news.channel.rolesNames[role])}
                  <br />
                </React.Fragment>
              ))}
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>
            {item.markets && item.markets.join(', ')}
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {item.date && formatDate(dateUtil.ofSecondsSince1970(item.date.seconds))}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {expiryDate ? (
            <>
              <i
                className={classNames('c-news-list-table-item__dot', {
                  'c-news-list-table-item__dot--active': dateUtil.isTodayOrAfter(expiryDate),
                  'c-news-list-table-item__dot--inactive': !dateUtil.isTodayOrAfter(expiryDate),
                })}
              />
              {formatDate(expiryDate)}
            </>
          ) : (
            <>
              <i className={'c-news-list-table-item__dot c-news-list-table-item__dot--active'} />
              {I18n.t(T.news.channel.table.notApplicable)}
            </>
          )}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {notificationExpiryDate ? (
            <>
              <i
                className={classNames('c-news-list-table-item__dot', {
                  'c-news-list-table-item__dot--active':
                    dateUtil.isTodayOrAfter(notificationExpiryDate),
                  'c-news-list-table-item__dot--inactive':
                    !dateUtil.isTodayOrAfter(notificationExpiryDate),
                })}
              />
              {formatDate(notificationExpiryDate)}
            </>
          ) : (
            I18n.t(T.news.channel.table.notApplicable)
          )}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__icon-wrapper'}>
            <LinkButton
              onClick={() => openNewsEditMode(item)}
              className={'c-news-list-table-item__icon-with-text'}
            >
              <Icon.Pen className={'c-news-list-table-item__icon'} />
              {I18n.t(T.news.channel.edit)}
            </LinkButton>
          </div>
          <DeleteButton onDelete={() => deleteNews(item)} />
        </TableCell>
      </div>
    </>
  );
};
