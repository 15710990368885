import { loadTranslations } from 'react-redux-i18n';

import { StoreType } from '+shared/store/store.interface';
import { storeProvider } from '+shared/store/store.provider';
import { Market } from '+utils/market.util';

import {
  DEFAULT_LOCALE,
  DEFAULT_SUPPORTED_LOCALES,
  getSupportedLocales,
  LANGUAGES,
  Locale,
} from './i18n.config';

export const getLanguage = async (languageCode: string) => {
  switch (languageCode) {
    case Locale.DE:
      return await import('@sonnen/shared-i18n/dist/service/languages/de');
    case Locale.EN_GB:
      return await import('@sonnen/shared-i18n/dist/service/languages/en-gb');
    case Locale.EN_US:
      return await import('@sonnen/shared-i18n/dist/service/languages/en-us');
    case Locale.IT:
      return await import('@sonnen/shared-i18n/dist/service/languages/it');
    case Locale.ES:
      return await import('@sonnen/shared-i18n/dist/service/languages/es');
    default:
      return await import('@sonnen/shared-i18n/dist/service/languages/en-us');
  }
};

export const LOCALE_KEY = 'languageCode';

export const getLocale = (): Locale => {
  const urlLocale = new URLSearchParams(window.location.search).get('lang');
  const storageLocale = localStorage.getItem(LOCALE_KEY);
  const navigatorLocale = navigator.language.toLocaleLowerCase(); // language set in the browser

  const locale = (urlLocale || storageLocale || navigatorLocale) as Locale;

  return (Object.values(Locale).includes(locale) ? locale : DEFAULT_LOCALE) as Locale;
};

export const setLocale = (locale: Locale) => localStorage.setItem(LOCALE_KEY, locale);

export const loadCurrentTranslations = async (store: StoreType, locale: Locale) => {
  const translations = await getLanguage(locale);
  loadTranslations({ [locale]: translations.locale } as any)(store.dispatch);
};

export const provideLocale = () => storeProvider.getState().i18n.locale;

const getLocalesForMarket = (isSpanishMarketEnabled: boolean, userMarket: Market) => {
  for (const [market, locales] of Object.entries(getSupportedLocales(isSpanishMarketEnabled))) {
    if (market.includes(userMarket)) {
      return locales;
    }
  }
  return DEFAULT_SUPPORTED_LOCALES;
};

export const getForMarketAvailableLanguages = (isSpanishMarketEnabled: boolean, market: Market) =>
  LANGUAGES.filter((lang) =>
    getLocalesForMarket(isSpanishMarketEnabled, market).includes(lang.locale)
  );
