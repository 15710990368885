import { queryReducer } from '+shared/store/query';

import { createReducer } from '../../utils';
import { ACCOUNT_PAGE_ACTIONS, AccountPageActions } from './+account.actions';
import {
  ACCOUNT_PASSWORD_QUERY,
  AccountPageState,
  COMPANY_DOCS_UPLOAD_QUERY,
  COMPANY_LOGO_UPLOAD_QUERY,
  GET_CERTIFICATES_QUERY,
  initialState,
  PROFILE_LOGO_UPLOAD_QUERY,
} from './+account.state';

type Actions = AccountPageActions;

export const reducer = createReducer<AccountPageState, Actions>(
  (state = initialState, action): AccountPageState => {
    switch (action.type) {
      case ACCOUNT_PAGE_ACTIONS.RESET_EDIT_PASSWORD_QUERY:
        return {
          ...state,
          accountPasswordQuery: {},
        };
      case ACCOUNT_PAGE_ACTIONS.RESET_UPLOAD_COMPANY_LOGO_QUERY:
        return {
          ...state,
          companyLogoUploadQuery: {},
        };
      case ACCOUNT_PAGE_ACTIONS.RESET_UPLOAD_PROFILE_LOGO_QUERY:
        return {
          ...state,
          profileLogoUploadQuery: {},
        };
      case ACCOUNT_PAGE_ACTIONS.RESET_UPLOAD_COMPANY_DOCS_QUERY:
        return {
          ...state,
          companyDocsUploadQuery: {},
        };
      case ACCOUNT_PAGE_ACTIONS.SET_CERTIFICATE_LIST:
        return {
          ...state,
          certificates: action.certificates,
        };
      default:
        return queryReducer(state, action, [
          ACCOUNT_PASSWORD_QUERY,
          COMPANY_DOCS_UPLOAD_QUERY,
          COMPANY_LOGO_UPLOAD_QUERY,
          PROFILE_LOGO_UPLOAD_QUERY,
          GET_CERTIFICATES_QUERY,
        ]);
    }
  }
);
