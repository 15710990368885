import { defer } from 'rxjs';

import { Config } from '+config/config';

import { jsonApiClient, partnerPortalJsonApiClient } from '../../network';
import { CaseData } from './types/case.interface';
import { CreateCaseAttributes } from './types/createCase.interface';
import { CreatedCaseData } from './types/createCaseResponse.interface';

const createCase = (createCaseAttributes: CreateCaseAttributes) =>
  defer(() =>
    partnerPortalJsonApiClient
      .post<CreatedCaseData>(`${Config.PARTNER_PORTAL_API_URL}/cases`)
      .ofType('cases')
      .withAttributes(createCaseAttributes)
      .expectOne()
      .send()
  );

const getCaseStatistics = () =>
  defer(() => jsonApiClient.get<CaseData>(`${Config.API_URL}/cases/statistics`).expectOne().send());

export const CaseRepository = {
  getCaseStatistics,
  createCase,
};
