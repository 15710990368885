import { queryReducer } from '+shared/store/query';

import { createReducer } from '../../../utils';
import { HEAT_PUMP_ACTIONS, HeatPumpActions } from './+heatPump.actions';
import { GET_HEATPUMP_POLLING_QUERY, HeatpumpState, initialState } from './+heatPump.state';

export const reducer = createReducer<HeatpumpState, HeatPumpActions>(
  (state = initialState, action): HeatpumpState => {
    switch (action.type) {
      case HEAT_PUMP_ACTIONS.SET_SITE_LIVE_STATE_V2_HEATPUMP:
        return {
          ...state,
          siteLiveStateV2: action.siteLiveStateV2,
        };
      default:
        return queryReducer(state, action, [GET_HEATPUMP_POLLING_QUERY]);
    }
  }
);
