import { CostsForm, CostsFormVersion } from '../../store/+impactAnalysis.interface';

export const defaultValues: CostsForm = {
  version: CostsFormVersion.BASIC,
  basic: {
    totalInvestmentCosts: '',
    totalRunningCostsPerYear: '',
  },
  detailed: {
    pvSystemPurchase: '',
    pvSystemInstallation: '',
    inverterAndOtherComponentsPurchase: '',
    batteryPurchase: '',
    batteryInstallation: '',
    meterReplacement: '',
    otherInstallation: '',
    insurancePerYear: '',
    hardwareMaintenancePerYear: '',
    additionalRunningCostsPerYear: '',
  },
};

export const formFields = {
  VERSION: 'version',
  BASIC: {
    TOTAL_ONE_OFF_INVESTMENT_COSTS: 'basic.totalInvestmentCosts',
    ANNUAL_RUNNING_COSTS: 'basic.totalRunningCostsPerYear',
  },
  DETAILED: {
    PV_SYSTEM_COSTS: 'detailed.pvSystemPurchase',
    PV_SYSTEM_INSTALLATION_COSTS: 'detailed.pvSystemInstallation',
    INVERTER_AND_OTHER_COMPONENTS_COSTS: 'detailed.inverterAndOtherComponentsPurchase',
    SONNEN_BATTERIE_COSTS: 'detailed.batteryPurchase',
    SONNEN_BATTERIE_INSTALLATION_COSTS: 'detailed.batteryInstallation',
    METER_CONVERSION_COSTS: 'detailed.meterReplacement',
    OTHER_INSTALLATION_COSTS: 'detailed.otherInstallation',
    ANNUAL_INSURANCE_COSTS: 'detailed.insurancePerYear',
    ANNUAL_MAINTENANCE_COSTS: 'detailed.hardwareMaintenancePerYear',
    ANNUAL_ADDITIONAL_COSTS: 'detailed.additionalRunningCostsPerYear',
  },
};
