export var defaultImageClass = (function () {
    try {
        return Image;
    }
    catch (_a) {
        return undefined;
    }
})();
export var defaultCanvasCreator = function (_a) {
    var width = _a.width, height = _a.height;
    try {
        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        return canvas;
    }
    catch (_b) {
        throw new Error('Could not create a new canvas element. You may not be in a browser environment.');
    }
};
