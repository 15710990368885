import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { CountryCode } from 'src/types/country.type';

import ExpandableSearchField from '+app/+customer/+listNew/components/TabSites/SitesControlsMenu/ExpandableSearchField/ExpandableSearchField';
import { SiteListRouteQueryParams } from '+router/routes';
import { Filter } from '+shared/basicComponents/Icons';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { NewFeatureTooltip } from '+shared/components';
import NewFeatureTooltipContent from '+shared/components/NewFeatureTooltipContent/NewFeatureTooltipContent';
import useNewFeatureTooltipIntroduction from '+shared/hooks/useNewFeatureTooltipIntroduction';

import './SitesControlsMenu.scss';

const ID_FOR_CONTROL_MENU = 'id-for-control-menu';
const IS_SEARCH_AND_FILTER_INTRODUCED = 'isNewSearchAndFilterIntroduced';

type Props = {
  totalResultsCount: number;
  batteriesInstalledCount: number;
  toggleSettings: () => void;
  handleSearch: (search: string, routeQueryParams: SiteListRouteQueryParams) => void;
  routerQueryParams: SiteListRouteQueryParams;
  toggleFilterControls: () => void;
  filterControlsIsOpen: boolean;
  countryCode: string;
};

const SitesControlsMenu: React.FC<Props> = ({
  totalResultsCount,
  batteriesInstalledCount,
  routerQueryParams,
  toggleSettings,
  handleSearch,
  toggleFilterControls,
  filterControlsIsOpen,
  countryCode,
}) => {
  const { isFeatureIntroduced, setIsFeatureIntroduced } = useNewFeatureTooltipIntroduction(
    IS_SEARCH_AND_FILTER_INTRODUCED
  );

  return (
    <div className="controls-menu">
      <div className="controls-menu--count">
        <LabelMedium
          text={`${totalResultsCount} ${I18n.t(T.customers.summary.totalResults)}`}
          className="total"
        />
        <LineDivider width={1} height={24} />
        <span className="count-highlight">{batteriesInstalledCount}</span>
        <BodyMedium text={I18n.t(T.customers.summary.totalBatteriesInstalled)} />
      </div>

      <NewFeatureTooltip
        bubbleChildren={getNewFeatureTooltipContent(countryCode, setIsFeatureIntroduced)}
        isOverlayActive={!isFeatureIntroduced}
        tooltipId={ID_FOR_CONTROL_MENU}
      >
        <div data-tooltip-id={ID_FOR_CONTROL_MENU} className="controls-menu--control-buttons">
          <ExpandableSearchField
            searchQueryParam={routerQueryParams.search}
            onSearch={handleSearch}
            routerQueryParams={routerQueryParams}
            dataTestId="search-field"
            dataTestIdButton="search-expand-button"
          />

          <button
            data-testid="filter-button"
            className="button-icon"
            onClick={toggleFilterControls}
          >
            <Filter className={`filter ${filterControlsIsOpen ? 'open' : ''}`} />
          </button>

          {/* TODO(SON-25644): button has been hidden since feature is not part of for MVP
          <button className="button-icon" onClick={toggleSettings}>
            <Settings className="settings" />
          </button> */}
        </div>
      </NewFeatureTooltip>
    </div>
  );
};

export default SitesControlsMenu;

const getNewFeatureTooltipContent = (
  countryCode: string,
  setFilterNewFeatureDismissed: (arg: boolean) => void
): JSX.Element => {
  return (
    <NewFeatureTooltipContent
      header={I18n.t(T.customers.filter.newFeatureTooltip.header)}
      paragraphOne={
        countryCode === CountryCode.DE
          ? I18n.t(T.customers.filter.newFeatureTooltip.paragraphOne)
          : I18n.t(T.customers.filter.newFeatureTooltip.nonDeParagraphOne)
      }
      paragraphTwo={
        countryCode === CountryCode.DE
          ? I18n.t(T.customers.filter.newFeatureTooltip.paragraphTwo)
          : undefined
      }
      buttonLabel={I18n.t(T.customers.filter.newFeatureTooltip.buttonLabel)}
      setFilterNewFeatureDismissed={setFilterNewFeatureDismissed}
    />
  );
};
