import { connectRouter, LocationChangeAction, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { findLastIndex } from 'lodash';

import { locationEquals } from './router.helpers';
import { initialState, RouterState } from './router.state';

const HISTORY_SIZE_LIMIT = 100;
const POP_ACTION = 'POP';

export const history = createBrowserHistory();

const routerReducer = connectRouter(history);

const getInitialState = (): RouterState => ({
  ...routerReducer(undefined, { type: '' } as any),
  ...initialState,
});

export const reducer = (state: RouterState = getInitialState(), action: LocationChangeAction) => {
  // We can't really POP when there is no history - that makes no sense.
  // This is a solution for POP event coming up after reloading the page,
  // replacing POP with a more proper PUSH.
  if (
    action &&
    action.payload &&
    action.payload.action === POP_ACTION &&
    state.history.length === 0
  ) {
    action.payload.action = 'PUSH';
  }

  const newState: RouterState = routerReducer(state, action) as RouterState;
  if (
    newState !== state &&
    (!state.history.length || !locationEquals(newState.location, state.location))
  ) {
    const popIndex =
      action.payload.action === POP_ACTION
        ? findLastIndex(newState.history, (location) => locationEquals(location, newState.location))
        : -1;
    const lastIndex = popIndex < 0 ? state.history.length : popIndex;
    const firstIndex = Math.max(0, lastIndex + 1 - HISTORY_SIZE_LIMIT);
    newState.history = state.history.slice(firstIndex, lastIndex);
    newState.history.push(newState.location);
    newState.previousAction = state.action;
  }
  return newState;
};
export const middleware = routerMiddleware(history);
