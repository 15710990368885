import * as React from 'react';

import { TableCell, TableStack } from '@sonnen/shared-web';

import { Customer } from '+shared/store/customer';

import { CustomersTableName } from '../CustomersTableName';
import { CustomersTableCustomerAddress } from '../CustomerTableCustomerAdress';

type Props = {
  customer: Customer;
  consentGiven: boolean;
};

export const CustomersTableWithoutSite = ({ customer, consentGiven }: Props) => {
  return (
    <div className={'c-customers-table-user__row'} data-hj-suppress={true}>
      <TableCell className={'c-customers-table-site__table-cell'} stack={TableStack.HORIZONTAL}>
        <CustomersTableName consentGiven={consentGiven} customer={customer} />
        <CustomersTableCustomerAddress customer={customer} />
      </TableCell>
    </div>
  );
};
