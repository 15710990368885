import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import classNames from 'classnames';

import { getFormattedContractType } from '+app/+customer/+contract/containers/CustomerContract/CustomerContract.helper';
import { DetailList, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Contract, MeterInstallationAttributes } from '+shared/store/contract';
import { ContractType } from '+shared/store/contract/contract.helpers';
import { getStatus } from '+shared/store/query/query.utils';

import {
  FactorizeFlatContractDetails,
  factorizeFlatContractTypeDetailList,
  factorizeSonnenEnergyDetailList,
  factorizeSonnenItalianDetailList,
  factorizeSonnenStromDetailList,
} from './CustomerContractWidget.helper';

interface Props {
  contract: Contract | undefined;
  queryStatus: ReturnType<typeof getStatus>;
  className?: ClassValue;
  meterInstallation?: MeterInstallationAttributes;
  link: () => void;
}

const renderDetailList = (data: Parameters<FactorizeFlatContractDetails>[0]) =>
  ({
    [ContractType.SONNEN_FLAT]: <DetailList list={factorizeFlatContractTypeDetailList(data)} />,
    [ContractType.ENERGY_GLOBAL]: <DetailList list={factorizeFlatContractTypeDetailList(data)} />,
    [ContractType.SONNEN_STROM]: <DetailList list={factorizeSonnenStromDetailList(data)} />,
    [ContractType.ENERGY]: <DetailList list={factorizeSonnenEnergyDetailList(data)} />,
    [ContractType.SONNEN_FLAT_IT]: <DetailList list={factorizeSonnenItalianDetailList(data)} />,
  }[data.contract.contractType]);

export const CustomerContractWidget: React.FC<Props> = ({
  link,
  contract,
  queryStatus,
  className,
  meterInstallation,
}) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon="Contract"
    heading={contract ? getFormattedContractType(contract) : 'Contract'}
    link={link}
  >
    {queryStatus.pending && <Loader />}
    {queryStatus.success && contract && renderDetailList({ contract, meterInstallation })}
    {queryStatus.error && (
      <WidgetWarning type="warning" icon="warning">
        {I18n.t(T.customerSingle.overview.sonnenFlat.error)}
      </WidgetWarning>
    )}
  </Widget>
);
