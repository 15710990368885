import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DefaultParagraph, Icofont, PageSubheadline } from '@sonnen/shared-web';

import { mapActions } from '+app/utils';
import { Button } from '+shared/components/Button';
import { AuthActions } from '+shared/store/auth';
import { StoreState } from '+shared/store/store.interface';

import { RegisterCard } from '../../components';

import './RegisterSuccess.component.scss';

const mapStateToProps = (state: StoreState) => ({
  // @TODO: fill when needed
});

const mapDispatchToProps = mapActions({
  login: AuthActions.login,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const RegisterSuccessComponent: React.SFC<Props> = ({ actions }) => (
  <section className={'c-register-success'}>
    <RegisterCard>
      <Icofont className={'c-register-success__icon'} type={'party'} />
      <PageSubheadline classNames={'c-register-success__headline'} smallGap={true} noBorder={true}>
        {I18n.t(T.register.success.headline)}
      </PageSubheadline>
      <DefaultParagraph className={'c-register-success__paragraph'}>
        {I18n.t(T.register.success.description)}
      </DefaultParagraph>
      <Button
        className={'c-register-success__button c-button--call-to-action'}
        label={I18n.t(T.register.success.button)}
        onClick={actions.login}
      />
    </RegisterCard>
  </section>
);

export const RegisterSuccess = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSuccessComponent);
