import { HttpResponse } from '@coolio/http';
import { from } from 'rxjs';

import { Config } from '+config/config';
import { forecastHttpClient } from '+shared/network';

import {
  SiteForecastConsumptionResponse,
  SiteForecastProductionResponse,
} from './types/forecast.interface';

export const getSiteForecastProduction = ({ id }: { id: string }) =>
  from(
    forecastHttpClient
      .get(Config.PRODUCTION_FORECAST, {
        query: {
          jwt: id,
          site: id,
        },
        headers: {
          Authorization: `Bearer ${id}`,
        },
      })
      .then((res: HttpResponse<SiteForecastProductionResponse>) => res.parsedBody())
  );

export const getSiteForecastConsumption = ({ id }: { id: string }) =>
  from(
    forecastHttpClient
      .get(Config.CONSUMPTION_FORECAST, {
        query: {
          jwt: id,
          site: id,
        },
        headers: {
          Authorization: `Bearer ${id}`,
        },
      })
      .then((res: HttpResponse<SiteForecastConsumptionResponse>) => res.parsedBody())
  );

export const AnalysisRepository = {
  getSiteForecastConsumption,
  getSiteForecastProduction,
};
