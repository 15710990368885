import React from 'react';
import { CSVLink } from 'react-csv';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';

import './CsvDownloadButton.styles.scss';

type CsvDownloadButtonProps = {
  data: string[][];
  filename: string;
  prepareCSVData: () => void;
  isLoading?: boolean;
};

export const CsvDownloadButton = ({
  data,
  filename,
  prepareCSVData,
  isLoading = false,
}: CsvDownloadButtonProps) => {
  return (
    <CSVLink
      data={data}
      onClick={prepareCSVData}
      filename={filename}
      asyncOnClick
      className={classNames('csv-download-button', { 'csv-download-button__disabled': isLoading })}
    >
      <Icon.Download width={24} height={14} color="#0A1535" />
      <strong>{I18n.t(T.downloadCsv)}</strong>
    </CSVLink>
  );
};
