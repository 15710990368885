import { useEffect, useState } from 'react';

const useScroll = (): boolean => {
  const [isScrolling, setIsScrolling] = useState(false);
  let timeout: NodeJS.Timeout | null = null;

  const onScroll = () => {
    setIsScrolling(true);
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return isScrolling;
};

export default useScroll;
