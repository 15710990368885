import { Contact } from '+shared/store/contact/types';
import {
  Lead,
  LeadAddress,
  LeadDso,
  LeadPartnerNotes,
  LeadPatchDto,
  LeadStatusName,
} from '+shared/store/lead/types';
import {
  LeadAddressDetails,
  LeadAutosuggestion,
} from '+shared/store/lead/types/leadAutosuggestion.interface';
import { LeadDeliveryAddressSuggestions } from '+shared/store/lead/types/leadDeliveryAddress.interface';
import { ActionsUnion, createAction } from '+utils/redux';

import { LeadForm } from './types';

export enum LEAD_PAGE_ACTIONS {
  CREATE_LEAD = '[+Lead] CREATE_LEAD',
  UPDATE_LEAD = '[+Lead] UPDATE_LEAD',
  CHANGE_LEAD_STATUS = '[+Lead] CHANGE_LEAD_STATUS',
  // eslint-disable-next-line max-len
  TOGGLE_CONFIGURATION_DOCUMENT_DOWNLOAD_MODAL = '[+Lead] TOGGLE_CONFIGURATION_DOCUMENT_DOWNLOAD_MODAL',
  MARK_LEAD_AS_SEEN = '[+Lead] MARK_LEAD_AS_SEEN',
  MARK_LEAD_AS_SEEN_SUCCESS = '[+Lead] MARK_LEAD_AS_SEEN_SUCCESS',
  GET_PARTNERS_EMPLOYEE_LIST = '[+Lead] GET_PARTNERS_EMPLOYEE_LIST',
  SET_PARTNER_EMPLOYEE_LIST = '[+Lead] SET_PARTNER_EMPLOYEE_LIST',
  SET_ASSIGN_LEAD_MODAL = '[+Lead] SET_ASSIGN_LEAD_MODAL',
  SET_DECLINE_LEAD_MODAL = '[+Lead] SET_DECLINE_LEAD_MODAL',
  SET_SELECTED_LEAD = '[+Lead] SET_SELECTED_LEAD',
  REASSIGN_PARTNER_TO_LEAD = '[+Lead] REASSIGN_PARTNER_TO_LEAD',
  GET_DELIVERY_ADDRESS_SUGGESTIONS = '[+Lead] GET_DELIVERY_ADDRESS_SUGGESTIONS',
  GET_INVOICE_ADDRESS_SUGGESTIONS = '[+Lead] GET_INVOICE_ADDRESS_SUGGESTIONS',
  SET_DELIVERY_ADDRESS_SUGGESTIONS = '[+Lead] SET_DELIVERY_ADDRESS_SUGGESTIONS',
  SET_INVOICE_ADDRESS_SUGGESTIONS = '[+Lead] SET_INVOICE_ADDRESS_SUGGESTIONS',
  GET_ADDRESS_AUTOSUGGESTIONS = '[+Lead] GET_ADDRESS_AUTOSUGGESTIONS',
  SET_ADDRESS_AUTOSUGGESTIONS = '[+Lead] SET_ADDRESS_AUTOSUGGESTIONS',
  GET_ADDRESS_DETAILS = '[+Lead] GET_ADDRESS_DETAILS',
  SET_ADDRESS_DETAILS = '[+Lead] SET_ADDRESS_DETAILS',
  CLEAR_ADDRESS_DATA = '[+Lead] CLEAR_ADDRESS_DATA',
  VALIDATE_LEAD_ADDRESS = '[+Lead] VALIDATE_LEAD_ADDRESS',
  VALIDATE_LEAD_ADDRESS_SUCCESS = '[+Lead] VALIDATE_LEAD_ADDRESS_SUCCESS',
  VALIDATE_LEAD_ADDRESS_CLEAR = '[+Lead] VALIDATE_LEAD_ADDRESS_CLEAR',
  EDIT_LEAD_ADDRESS = '[+Lead] EDIT_LEAD_ADDRESS',
  SET_PARTNER_NOTE = '[+Lead] SET_PARTNER_NOTE',
  CLEAR_LEAD_DATA = '[+Lead] CLEAR_LEAD_DATA',
  SET_DELETED_LEAD_STATUS = '[+Lead] SET_DELETED_LEAD_STATUS',
  SET_CG_INVITATION_SENT = '[+Lead] SET_CG_INVITATION_SENT',
  SET_OFFER_SENT_STATUS_INACTIVE = '[+Lead] SET_OFFER_SENT_STATUS_INACTIVE',
  SET_HARDWARE_OFFER_SENT_STATUS_INACTIVE = '[+Lead] SET_HARDWARE_OFFER_SENT_STATUS_INACTIVE',
  SET_HARDWARE_ALREADY_SOLD_STATUS_INACTIVE = '[+Lead] SET_HARDWARE_ALREADY_SOLD_STATUS_INACTIVE',
  SET_LEAD_DSO = '[+Lead] setLeadDso',
  SAVE_ASYNC_LEAD_STATUS = '[+Lead] SAVE_ASYNC_LEAD_STATUS',
  CLEAR_ASYNC_LEAD_STATUS = '[+Lead] CLEAR_ASYNC_LEAD_STATUS',
  SET_ASYNC_FLAT_OFFER_SENT_STATUS = '[+Lead] SET_ASYNC_FLAT_OFFER_SENT_STATUS',
  SET_LEAD_HARDWARE_STATUS = '[+Lead] SET_HARDWARE_STATUS',
}

export const LeadPageActions = {
  createLead: createAction(LEAD_PAGE_ACTIONS.CREATE_LEAD, (leadForm: LeadForm) => ({ leadForm })),
  updateLead: createAction(LEAD_PAGE_ACTIONS.UPDATE_LEAD, (leadData: Partial<LeadPatchDto>) => ({
    leadData,
  })),
  setDeletedLeadStatus: createAction(
    LEAD_PAGE_ACTIONS.SET_DELETED_LEAD_STATUS,
    (statusNames: LeadStatusName[]) => ({ statusNames })
  ),
  setOfferSentStatusInactive: createAction(LEAD_PAGE_ACTIONS.SET_OFFER_SENT_STATUS_INACTIVE),
  setHardwareOfferSentStatusInactive: createAction(
    LEAD_PAGE_ACTIONS.SET_HARDWARE_OFFER_SENT_STATUS_INACTIVE
  ),
  setHardwareAlreadySoldStatusInactive: createAction(
    LEAD_PAGE_ACTIONS.SET_HARDWARE_ALREADY_SOLD_STATUS_INACTIVE
  ),
  changeLeadStatus: createAction(
    LEAD_PAGE_ACTIONS.CHANGE_LEAD_STATUS,
    (leadId: string, statusNames: LeadStatusName[]) => ({ leadId, statusNames })
  ),
  toggleConfigurationDocumentDownloadModal: createAction(
    LEAD_PAGE_ACTIONS.TOGGLE_CONFIGURATION_DOCUMENT_DOWNLOAD_MODAL,
    (isOpen: boolean) => ({ isOpen })
  ),
  markLeadAsSeen: createAction(LEAD_PAGE_ACTIONS.MARK_LEAD_AS_SEEN),
  markLeadAsSeenSuccess: createAction(LEAD_PAGE_ACTIONS.MARK_LEAD_AS_SEEN_SUCCESS),
  validateLeadAddress: createAction(
    LEAD_PAGE_ACTIONS.VALIDATE_LEAD_ADDRESS,
    (address: LeadAddress) => ({ address })
  ),
  validateLeadAddressSuccess: createAction(
    LEAD_PAGE_ACTIONS.VALIDATE_LEAD_ADDRESS_SUCCESS,
    (isAddressValid: boolean) => ({ isAddressValid })
  ),
  editLeadAddress: createAction(LEAD_PAGE_ACTIONS.EDIT_LEAD_ADDRESS),
  validateLeadAddressClear: createAction(LEAD_PAGE_ACTIONS.VALIDATE_LEAD_ADDRESS_CLEAR),
  getPartnersEmployeeList: createAction(LEAD_PAGE_ACTIONS.GET_PARTNERS_EMPLOYEE_LIST),
  setPartnerEmployeeList: createAction(
    LEAD_PAGE_ACTIONS.SET_PARTNER_EMPLOYEE_LIST,
    (partnerEmployeeList: Contact[]) => ({ partnerEmployeeList })
  ),
  toggleAssignLeadModal: createAction(
    LEAD_PAGE_ACTIONS.SET_ASSIGN_LEAD_MODAL,
    (isAssignLeadModalOpen: boolean) => ({ isAssignLeadModalOpen })
  ),
  toggleDeclineLeadModal: createAction(
    LEAD_PAGE_ACTIONS.SET_DECLINE_LEAD_MODAL,
    (isDeclineLeadModalOpen: boolean) => ({ isDeclineLeadModalOpen })
  ),
  setSelectedLead: createAction(LEAD_PAGE_ACTIONS.SET_SELECTED_LEAD, (selectedLead: Lead) => ({
    selectedLead,
  })),
  reassignPartnerToLead: createAction(
    LEAD_PAGE_ACTIONS.REASSIGN_PARTNER_TO_LEAD,
    (leadId: string, partnerSalesforceContactId: string) => ({ leadId, partnerSalesforceContactId })
  ),
  getDeliveryAddressSuggestions: createAction(
    LEAD_PAGE_ACTIONS.GET_DELIVERY_ADDRESS_SUGGESTIONS,
    (postcode: string, city?: string, street?: string) => ({
      postcode,
      city,
      street,
    })
  ),
  getInvoiceAddressSuggestions: createAction(
    LEAD_PAGE_ACTIONS.GET_INVOICE_ADDRESS_SUGGESTIONS,
    (postcode: string, city?: string, street?: string) => ({
      postcode,
      city,
      street,
    })
  ),
  setDeliveryAddressSuggestions: createAction(
    LEAD_PAGE_ACTIONS.SET_DELIVERY_ADDRESS_SUGGESTIONS,
    (deliveryAddressSuggestions: LeadDeliveryAddressSuggestions) => ({
      deliveryAddress: deliveryAddressSuggestions,
    })
  ),
  setInvoiceAddressSuggestions: createAction(
    LEAD_PAGE_ACTIONS.SET_INVOICE_ADDRESS_SUGGESTIONS,
    (deliveryAddressSuggestions: LeadDeliveryAddressSuggestions) => ({
      invoiceAddress: deliveryAddressSuggestions,
    })
  ),
  getAddressAutosuggestions: createAction(
    LEAD_PAGE_ACTIONS.GET_ADDRESS_AUTOSUGGESTIONS,
    (query: string, country: string, sessionId: string) => ({ query, country, sessionId })
  ),
  setAddressAutosuggestions: createAction(
    LEAD_PAGE_ACTIONS.SET_ADDRESS_AUTOSUGGESTIONS,
    (autosuggestions: LeadAutosuggestion[]) => ({ autosuggestions })
  ),
  getAddressDetails: createAction(
    LEAD_PAGE_ACTIONS.GET_ADDRESS_DETAILS,
    (placeId: string, sessionId: string, query: string, lang: string) => ({
      placeId,
      sessionId,
      query,
      lang,
    })
  ),
  setAddressDetails: createAction(
    LEAD_PAGE_ACTIONS.SET_ADDRESS_DETAILS,
    (addressDetails: LeadAddressDetails) => ({ addressDetails })
  ),
  setPartnerNote: createAction(
    LEAD_PAGE_ACTIONS.SET_PARTNER_NOTE,
    ({ id, content }: LeadPartnerNotes) => ({ noteId: id, note: content })
  ),
  clearAddressData: createAction(LEAD_PAGE_ACTIONS.CLEAR_ADDRESS_DATA),
  clearLeadData: createAction(LEAD_PAGE_ACTIONS.CLEAR_LEAD_DATA),
  setCGInvitationSent: createAction(LEAD_PAGE_ACTIONS.SET_CG_INVITATION_SENT),
  setLeadDso: createAction(LEAD_PAGE_ACTIONS.SET_LEAD_DSO, (dso: LeadDso) => ({ dso })),
  // saves proposed async status in store
  saveAsyncLeadStatus: createAction(
    LEAD_PAGE_ACTIONS.SAVE_ASYNC_LEAD_STATUS,
    (status: LeadStatusName) => ({ status })
  ),
  // sets the status from store to lead
  setAsyncFlatOfferSentStatus: createAction(LEAD_PAGE_ACTIONS.SET_ASYNC_FLAT_OFFER_SENT_STATUS),
  clearAsyncLeadStatus: createAction(LEAD_PAGE_ACTIONS.CLEAR_ASYNC_LEAD_STATUS),
  setLeadHardwareStatus: createAction(
    LEAD_PAGE_ACTIONS.SET_LEAD_HARDWARE_STATUS,
    (status: LeadStatusName) => ({ status })
  ),
};

export type LeadPageActions = ActionsUnion<typeof LeadPageActions>;
