import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader, MediaQuery, Modal } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import { getHardwareStatusUpdateQueryStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import DynamicTariffInfo from '+app/+lead/+overview/components/DynamicTariffInfo/DynamicTariffInfo';
import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import {
  getDynamicTariff,
  getOfferList,
  getOfferListQueryStatus,
  getProductBatteryList,
} from '+app/+lead/+overview/store/+overview.selectors';
import { PATHS, ROUTES } from '+app/router';
import { TestId } from '+config/testIds';
import { HardwarePageActions } from '+lead/+hardware/store';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { getChangeLeadStatusQueryStatus } from '+lead/store/+lead.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { SelectButton } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import ModalLayout from '+shared/components/ModalLayout/ModalLayout';
import { Lead } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { getUserCompanyName } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';

import { LeadEditStatusHardware } from './LeadEditStatusHardware/LeadEditStatusHardware.component';
import {
  canMoveLeadToSetup,
  findProductsStatusesToUpdate,
  getLeadStatusListToUpdate,
  LeadEditStatusForm,
  leadEditStatusSchema,
  mapUserStatusToInitialValues,
} from './LeadEditStatusModal.helper';
import { LeadEditStatusSonnenFlat } from './LeadEditStatusSonnenFlat';

import './LeadEditStatusModal.component.scss';

const {
  description,
  errors,
  moveToSetupBtn,
  switch: { hardware, sonnenFlat },
} = T.lead.list._salessolution_.editStatusModal;

const mapStateToProps = (state: StoreState) => ({
  userCompanyName: getUserCompanyName(state),
  offers: getOfferList(state),
  hasDynamicTariff: getDynamicTariff(state),
  productBatteryList: getProductBatteryList(state),
  changeLeadQueryStatus: getChangeLeadStatusQueryStatus(state),
  offerListQueryStatus: getOfferListQueryStatus(state),
  hardwareProductStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goToCreateHardwareOffer: (leadId: string) => push(PATHS.LEAD_HARDWARE_NEW({ leadId })),
  getOfferList: LeadOverviewPageActions.getOfferList,
  clearOffers: LeadOverviewPageActions.clearOffers,
  changeLeadStatus: LeadPageActions.changeLeadStatus,
  changeHardwareProductStatus: HardwarePageActions.updateHardwareStatus,
  displayMaxOffersWarning: LeadOverviewPageActions.displayMaxOffersWarning,
});

type ComponentProps = {
  modalOpen: boolean;
  lead: Lead;
  toggleEditModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps &
  RouteComponentProps;

enum LeadStatus {
  HARDWARE = 'hardware',
  SONNEN_FLAT = 'sonnen-flat',
}

const LeadEditStatusModalComponent = ({
  actions,
  modalOpen,
  lead,
  hasDynamicTariff,
  changeLeadQueryStatus,
  userCompanyName,
  offers,
  location,
  productBatteryList,
  offerListQueryStatus,
  hardwareProductStatusUpdateQueryStatus,
  toggleEditModal,
  toggleModal,
}: Props) => {
  const [selectedStatus, setSelectedStatus] = useState<LeadStatus>(LeadStatus.HARDWARE);
  const initialValues = mapUserStatusToInitialValues(lead, offers);

  const { pending: hardwarePending, error: hardwareError } = hardwareProductStatusUpdateQueryStatus;
  const { pending: changeLeadPending, error: changeLeadError } = changeLeadQueryStatus;
  const {
    pending: offerListPending,
    error: offerListError,
    success: offerListSuccess,
  } = offerListQueryStatus;
  const loading =
    changeLeadPending ||
    hardwarePending ||
    offerListPending ||
    (!offerListSuccess && !offerListError);
  const hasErrors = hardwareError || changeLeadError;

  useEffect(() => {
    actions.getOfferList(lead.id);
    return () => closeModal();
  }, []);

  const closeModal = () => {
    toggleModal(false);
    toggleEditModal(false);
    // DO NOT REMOVE
    // For some reason, on the lead list, offers have to be cleared each time the modal is closed
    // in order to fetch new ones when opening it for a different lead.
    if (location.pathname === ROUTES.LEADS[0]) actions.clearOffers();
  };

  const isMoveToSetupEnabled = (
    statusSummary: Lead['status']['summary'],
    values: LeadEditStatusForm
  ): boolean => {
    if (changeLeadPending) return false;
    return hasDynamicTariff || canMoveLeadToSetup({ statusSummary, values, offers, initialValues });
  };

  const onSubmit = (values: LeadEditStatusForm) => {
    if (!lead) return;

    const productsStatusesToUpdate = findProductsStatusesToUpdate(offers)(initialValues, values);
    if (!isEmpty(productsStatusesToUpdate))
      actions.changeHardwareProductStatus(lead.id, productsStatusesToUpdate);

    const leadStatusList = getLeadStatusListToUpdate({
      initialValues,
      values,
      productsStatusesToUpdate,
    });
    if (!isEmpty(leadStatusList)) actions.changeLeadStatus(lead.id, leadStatusList);
  };

  const moveToSetup = (form: FormikProps<LeadEditStatusForm>) => {
    onSubmit({ ...form.values, inSetup: true });
  };

  const renderStatusModalFooter = (form: FormikProps<LeadEditStatusForm>) => {
    const moveToSetupEnabled = isMoveToSetupEnabled(lead.status.summary, form.values);
    const disabledBtn = changeLeadPending || !form.dirty;
    return (
      <>
        {hasErrors && !hardwarePending && (
          <div className="lead-status-modal__errors">
            <div>{I18n.t(errors.errorWhileSaving)}</div>
            <div>{I18n.t(errors.refreshPage)}</div>
          </div>
        )}

        <Media query={MediaQuery.UP_MD}>
          {(isMedium: boolean) => (
            <div className="lead-status-modal__buttons">
              <Button
                label={I18n.t(T.general.basicActions.save)}
                type={ButtonType.SECONDARY}
                size={isMedium ? ButtonSize.MEDIUM : ButtonSize.SMALL}
                mainType={MainType.SUBMIT}
                disabled={disabledBtn}
                status={disabledBtn ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                className="lead-status-modal__button-setup"
                dataTestId={TestId.Lead.StatusManager.SaveButton}
              />
              {!initialValues.inSetup && (
                <Button
                  label={I18n.t(moveToSetupBtn)}
                  size={isMedium ? ButtonSize.MEDIUM : ButtonSize.SMALL}
                  disabled={!moveToSetupEnabled}
                  status={!moveToSetupEnabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                  onClick={() => moveToSetup(form)}
                  dataTestId={TestId.Lead.StatusManager.MoveToSetupButton}
                />
              )}
            </div>
          )}
        </Media>
      </>
    );
  };

  const selectedStatusHW = selectedStatus === LeadStatus.HARDWARE;
  const selectedStatusFlat = selectedStatus === LeadStatus.SONNEN_FLAT;

  return (
    <Modal
      dataTestId="update-and-move-to-setup-modal"
      isOpen={modalOpen}
      onClose={closeModal}
      className="lead-status-modal__wrapper"
    >
      {loading ? (
        <Loader className="lead-status-modal__loader" />
      ) : (
        <div className="lead-status-modal" data-hj-suppress={true}>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={leadEditStatusSchema}
            onSubmit={onSubmit}
          >
            {(form) => (
              <Form className="lead-status-modal-form">
                <ModalLayout
                  title={`${lead.firstName && lead.firstName} ${lead.lastName && lead.lastName}`}
                  subtitle={I18n.t(description)}
                  footer={renderStatusModalFooter(form)}
                  dataTestIdTitle="update-and-move-to-setup-title"
                  section={Sections.LEADS}
                  page={PageName.Leads.LEAD_STATUS_MANAGER_MODAL}
                >
                  <div className="lead-status-modal__input">
                    {!hasDynamicTariff && (
                      <div className="lead-status-modal__widget-wrapper">
                        <div className="lead-status-modal__statuses-container">
                          <SelectButton
                            className="lead-status-modal__statuses-item"
                            onClick={() => setSelectedStatus(LeadStatus.HARDWARE)}
                            isActive={selectedStatusHW}
                            icon={<Icon.SonnenBattery />}
                            label={I18n.t(hardware)}
                            dataTestId="hardware-tab-button"
                          />
                          <SelectButton
                            className="lead-status-modal__statuses-item"
                            onClick={() => setSelectedStatus(LeadStatus.SONNEN_FLAT)}
                            isActive={selectedStatusFlat}
                            icon={<Icon.NotesWithPencil />}
                            label={I18n.t(sonnenFlat)}
                            dataTestId="sonnenflat-tab-button"
                          />
                        </div>
                      </div>
                    )}

                    <DynamicTariffInfo hasDynamicTariff={hasDynamicTariff} />

                    {selectedStatusHW && (
                      <LeadEditStatusHardware
                        form={form}
                        userCompanyName={userCompanyName}
                        isMoveToSetupEnabled={isMoveToSetupEnabled}
                        hasErrors={changeLeadError}
                        leadStatusSummary={lead.status.summary}
                        offers={offers}
                        productBatteryList={productBatteryList}
                        goToCreateHardwareOffer={() => actions.goToCreateHardwareOffer(lead.id)}
                        displayMaxOffersWarning={() => actions.displayMaxOffersWarning(lead.id)}
                        hasDynamicTariff={hasDynamicTariff}
                      />
                    )}

                    {!hasDynamicTariff && selectedStatusFlat && (
                      <LeadEditStatusSonnenFlat
                        leadStatusSummary={lead.status.summary}
                        userCompanyName={userCompanyName}
                      />
                    )}
                  </div>
                </ModalLayout>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export const LeadEditStatusModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadEditStatusModalComponent));
