import { toCamelCase } from './toCamelCase.util';

const decodeParam = (object: object, hashParam: string) => {
  const [key, value] = hashParam.split('=');
  return { ...object, [key]: decodeURIComponent(value) };
};

export const getHashParams = <T extends object>(url: string): T | {} => {
  if (!url.includes('#')) {
    return {};
  }

  const params = url.split('#')[1].split('&').reduce(decodeParam, {});

  return toCamelCase(params);
};
