import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
  onClick: () => void;
  children: React.ReactChild;
}

export class ClickOutside extends React.PureComponent<Props> {
  private appRef = document.getElementsByTagName('body')[0];

  componentDidMount() {
    if (this.appRef) {
      this.appRef.addEventListener('click', this.handleDocumentClick);
    }
  }

  componentWillUnmount() {
    if (this.appRef) {
      this.appRef.removeEventListener('click', this.handleDocumentClick);
    }
  }

  handleDocumentClick = (evt: any) => {
    const area = ReactDOM.findDOMNode(this);

    if (area && !area.contains(evt.target)) {
      this.props.onClick();
    }
  };

  render() {
    return this.props.children;
  }
}
