import { Data, MergedData, Relationship, RelationshipArray } from '@coolio/json-api';
import { Omit } from 'lodash';

import { CurrencyUnit, EnergyUnit } from '+app/App.constants';

import {
  LeadBudget,
  LeadCost,
  LeadEnergyCostInEuro,
  LeadMeasurement,
  LeadMotivation,
  LeadProductInterest,
  LeadPurchasingTimeframe,
  LeadPvSystem,
} from '.';
import { LeadAddress } from './leadAddress.interface';
import { LeadRoofDetails } from './leadRoof.interface';
import { LeadStatus } from './leadStatus.interface';

export enum LeadSource {
  PARTNER = 'partner',
  SONNEN = 'sonnen',
}

export enum LeadCustomerType {
  PRIVATE = 'private',
  BUSINESS = 'business',
}

export enum LeadCustomerSalutation {
  MR = 'mr',
  MS = 'ms',
}

export interface LeadCustomerCompany {
  name: string;
}

export interface LeadLargeConsumers {
  electricCar: boolean;
  heatPump: boolean;
  infraredHeating: boolean;
  poolOrSauna: boolean;
  storageHeater: boolean;
  other: boolean;
}

export enum LeadHomeOwnership {
  YES = 'yes',
  NO = 'no',
  PLANNED = 'planned',
  NEW = 'new',
  EXISTING = 'existing',
  UNKNOWN = '',
}

export enum LeadFinancingInterest {
  YES = 'yes',
  NO = 'no',
  UNKNOWN = '',
}

export interface LeadDso {
  id: string;
  name: string;
}

export interface LeadTso {
  name: string;
}

export type LeadProductInterests = Record<LeadProductInterest, boolean>;
export type LeadMotivations = Record<LeadMotivation, boolean>;

export interface LeadAttributes {
  customerNumber: string;
  source: LeadSource;
  customerType: LeadCustomerType;
  salutation: LeadCustomerSalutation;
  title: string | null;
  company?: LeadCustomerCompany;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  mobilePhone: string | null;
  deliveryAddress?: LeadAddress;
  invoiceAddress?: LeadAddress;
  agreedToGdpr: boolean;
  optedOutOfMarketingMailing: boolean;
  status: LeadStatus;
  dueTimestamp?: Date;
  alreadyInContact?: boolean;
  consumption: LeadMeasurement<EnergyUnit.KWH> | null;
  description: string | null;
  isNewlyAssigned: boolean;
  automaticallyConverted?: boolean;
  roofDetails: LeadRoofDetails | null;
  energyConsumption: {
    approxConsumption?: LeadMeasurement<EnergyUnit.KWH>;
    costsPerYear?: LeadCost<CurrencyUnit.CURRENCY_EUR, LeadEnergyCostInEuro>;
    largeConsumers: LeadLargeConsumers;
  } | null;
  pvSystem: LeadPvSystem | null;
  additionalData: {
    productInterest: LeadProductInterests | null;
    motivation: LeadMotivations | null;
    homeOwnership: LeadHomeOwnership | null;
    purchasingTimeframe: LeadPurchasingTimeframe | null;
    budget: LeadBudget<CurrencyUnit.CURRENCY_EUR, LeadEnergyCostInEuro> | null;
    financingInterest: LeadFinancingInterest | null;
    numberOfResidents: number | null;
  };
  partner: {
    contactId: string;
  } | null;
  submissions?: any;
  batteryData?: {
    batteryType?: string;
    batteryNominalCapacitykWh?: string;
    batteryName?: string;
  };
  createdAt?: string;
  dso?: LeadDso | null;
  tso?: LeadTso | null;
}

export type LeadCreateDto = Omit<
  LeadAttributes,
  | 'status'
  | 'roofDetails'
  | 'source'
  | 'energyConsumption'
  | 'pvSystem'
  | 'description'
  | 'isNewlyAssigned'
  | 'partner'
  | 'customerNumber'
  | 'additionalData'
  | 'consumption'
  | 'dso'
  | 'tso'
>;

export interface LeadPatchDto extends LeadCreateDto {
  dso: {
    id: string;
  };
  tso: LeadTso | null;
}

export type LeadRelationships = {
  offers: RelationshipArray;
  note: Relationship;
};

export type LeadMetaData = {
  hasActiveDynamicTariffContract: boolean;
  permissions: {
    notes: {
      create: boolean;
    };
    leads: {
      editPersonalData: boolean;
    };
  };
};

export interface LeadData extends Data<LeadAttributes, LeadRelationships> {}
export interface Lead extends MergedData<LeadData> {}
