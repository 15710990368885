import * as React from 'react';
import { connect } from 'react-redux';

import { CaseReportError } from '+app/shared/components/CaseReportError';
import { CaseReportSuccess } from '+app/shared/components/CaseReportSuccess';
import { RecordTypeSupport } from '+shared/components/CaseReportForm/CaseCommon.types';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import { CaseActions, getCreateCaseQueryStatus, getCreatedCaseNumber } from '+shared/store/case';
import { mapUserCountryToCaseCountry } from '+shared/store/case/case.helper';
import {
  CaseOrigin,
  CasePriority,
  CaseStatus,
  CaseType,
} from '+shared/store/case/types/createCase.interface';
import { getClaimBatteryData } from '+shared/store/partner/partner.selectors';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserCountryCode,
  getUserProfileSalesforceContactId,
} from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';

import { MissingBatteryCaseForm } from '../../components/MissingBatteryCaseForm';
import { MissingBatteryCaseFormValues } from '../../components/MissingBatteryCaseForm/MissingBatteryCaseForm.interface';

const mapStateToProps = (state: StoreState) => ({
  claimBatteryData: getClaimBatteryData(state),
  caseCreateQueryStatus: getCreateCaseQueryStatus(state),
  contactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
  userCountry: getUserCountryCode(state),
});

const mapDispatchToProps = mapActions({
  createCase: CaseActions.createCase,
  clearCreatedCase: CaseActions.clearCreatedCase,
});

interface CaseReportingProps {
  hideCaseReporting: () => void;
  closeMissingBatteryModal: () => void;
}

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  CaseReportingProps;

export const MissingBatteryCaseReportingComponent: React.FC<Props> = ({
  claimBatteryData,
  caseCreateQueryStatus,
  hideCaseReporting,
  closeMissingBatteryModal,
  contactId,
  caseNumber,
  userCountry,
  actions,
}) => {
  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');

  React.useEffect(() => {
    if (caseCreateQueryStatus.success) {
      return setFormState(FormState.FORM_SUCCESS);
    }
    if (caseCreateQueryStatus.error) {
      return setFormState(FormState.FORM_ERROR);
    }
    return setFormState(FormState.FORM);
  }, [caseCreateQueryStatus]);

  const onTryAgain = (): void => {
    actions.clearCreatedCase();
    setFormState(FormState.FORM);
  };

  const onSubmit = (values: MissingBatteryCaseFormValues): void => {
    actions.createCase({
      recordType: RecordTypeSupport.TECHNICAL,
      // eslint-disable-next-line max-len
      subject: `Partner Portal | Partner needs battery assigned | look into knowledge article | ${values.serialNumber}`,
      description: `Serial number: ${values.serialNumber},
        Last name: ${values.customerLastName || '-'},
        ZIP code: ${values.postalCode || '-'},
        PV size: ${values.pvPeakPower || '-'},
        Description: ${values.description}`,
      type: CaseType.REQUEST,
      priority: CasePriority.NORMAL,
      status: CaseStatus.NEW,
      country: mapUserCountryToCaseCountry(isSpanishMarketEnabled, userCountry),
      origin: CaseOrigin.PARTNER_PORTAL,
      contactId,
    });
  };

  return (
    <>
      {
        {
          [FormState.FORM]: (
            <MissingBatteryCaseForm
              claimBatteryData={claimBatteryData}
              hideCaseReporting={hideCaseReporting}
              onSubmit={onSubmit}
              isQueryPending={caseCreateQueryStatus.pending}
            />
          ),
          [FormState.FORM_ERROR]: <CaseReportError onTryAgain={onTryAgain} />,
          [FormState.FORM_SUCCESS]: (
            <CaseReportSuccess onClose={closeMissingBatteryModal} caseNumber={caseNumber} />
          ),
        }[formState]
      }
    </>
  );
};

export const MissingBatteryCaseReporting = connect(
  mapStateToProps,
  mapDispatchToProps
)(MissingBatteryCaseReportingComponent);
