import mapValues from 'lodash/mapValues';
import { mergeElementData } from '../helpers';
var JsonListResponse = (function () {
    function JsonListResponse(raw, response, limit, offset, rawIncludedGroups) {
        this.raw = raw;
        this.response = response;
        this.limit = limit;
        this.offset = offset;
        this.rawIncludedGroups = rawIncludedGroups;
    }
    Object.defineProperty(JsonListResponse.prototype, "elements", {
        get: function () {
            return this.raw.data.map(mergeElementData);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JsonListResponse.prototype, "meta", {
        get: function () {
            return this.raw.meta;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JsonListResponse.prototype, "included", {
        get: function () {
            return mapValues(this.rawIncludedGroups, function (data) { return data.map(mergeElementData); });
        },
        enumerable: true,
        configurable: true
    });
    return JsonListResponse;
}());
export { JsonListResponse };
