import * as React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as classNames from 'classnames';
import { push } from 'connected-react-router';

import { NewsReadTag } from '+app/+newsMain/components/NewsReadTag';
import { NewsTag } from '+app/+newsMain/components/NewsTag';
import {
  removeDuplicateSpaces,
  removeHtmlTags,
  setNewsUrl,
} from '+app/+newsMain/store/newsMain.helpers';
import { NewsInboxListRouteQueryParams, PATHS } from '+app/router';
import { News } from '+app/shared/store/firebase/types/news.interface';
import { getActiveNewsId, NewsMainActions } from '+app/shared/store/newsMain';
import { NewsPlacementType } from '+app/shared/store/newsMain/types/newsMain.interface';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { dateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';
import { mapActions } from '+utils/redux';

import './NewsItem.component.scss';

const mapStateToProps = (state: StoreState) => ({
  activeNewsId: getActiveNewsId(state),
  userProfile: getUserProfile(state),
});

const mapDispatchToProps = mapActions({
  goToNewsDetails: (urlParams: NewsInboxListRouteQueryParams) => push(PATHS.NEWS_INBOX(urlParams)),
  setActiveNewsId: NewsMainActions.setActiveNewsId,
  markNewsAsRead: FirebaseActions.markNewsAsRead,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    item: News;
    showPreviewOnly: boolean;
    newsPlacement: NewsPlacementType;
    isRead?: boolean;
  };

export const NewsItemComponent: React.FC<Props> = ({
  actions,
  item,
  showPreviewOnly,
  newsPlacement,
  activeNewsId,
  isRead = false,
  userProfile,
}) => {
  const messageWithoutHtmlTags = removeHtmlTags(
    item.introduction ? item.introduction : item.message
  );
  const messageWithoutDuplicateSpaces = removeDuplicateSpaces(messageWithoutHtmlTags);

  const clickHandler = (title: any, id: string) => {
    actions.goToNewsDetails(setNewsUrl(title, id));
    actions.setActiveNewsId(id);
    if (!isRead && userProfile) {
      actions.markNewsAsRead(id, userProfile.id);
    }
  };

  return (
    <div
      className={classNames(
        'news-item ql-editor',
        `news-item--${newsPlacement}`,
        { 'news-item--active': item.id === activeNewsId },
        { 'news-item--unread': !isRead }
      )}
      onClick={() => {
        clickHandler(item.shortMessage, item.id);
      }}
    >
      <div className={'news-item__header'}>
        <NewsTag category={item.levelValue} tag={item.tagValue} />
        {item.date && (
          <p className={'news-item__date'}>
            {formatDate(dateUtil.ofSecondsSince1970(item.date.seconds), 'DD.MM.YYYY')}
          </p>
        )}
      </div>

      <p className={'news-item__title'}>
        {item.shortMessage}
        {!isRead && showPreviewOnly ? (
          <span className={'news-item__new-tag'}>
            <NewsReadTag />
          </span>
        ) : null}
      </p>

      {item.introduction && !showPreviewOnly && (
        <div className={'news-item__introduction'}>
          <div dangerouslySetInnerHTML={{ __html: item.introduction }} />
        </div>
      )}

      <div className={'news-item__message'}>
        {showPreviewOnly ? (
          <LinesEllipsis
            text={messageWithoutDuplicateSpaces}
            maxLine={'2'}
            ellipsis={'...'}
            basedOn={'letters'}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: item.message }} />
        )}
      </div>
      {showPreviewOnly && (
        <p className={'news-item__read-more'}>{I18n.t(T.general.basicActions.readMore)}</p>
      )}
    </div>
  );
};

export const NewsItem = connect(mapStateToProps, mapDispatchToProps)(NewsItemComponent);
