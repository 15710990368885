import * as React from 'react';

import { CellDataSeriesKey, SharedChartColors, useFeature } from '@sonnen/shared-web';

import { AxisOrientation, DataContainer, GridLines } from '@kanva/charts';
import {
  AreaChartView as AreaChartViewComponent,
  AxisView,
  ChartGridView,
} from '@kanva/charts-react';
import { Visibility } from '@kanva/core';
import { View as ViewComponent } from '@kanva/react';

import { FeatureName } from '+config/featureFlags';

import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import { ChartVisibleElements } from '../../store/types/chartVisibleElements.interface';
import { Views } from '../AnalysisDayChart/AnalysisDayChart.layout';
import { bandChartLayout } from './AnalysisBandChart.layout';
import {
  bandChartGridStyle,
  bandChartStyles,
  bandChartXAxisStyle,
  bandChartYAxisLeftStyle,
  bandChartYAxisRightStyle,
} from './AnalysisBandChart.styles';

interface AnalysisBandChartProps {
  cellTemperatureDataContainer: DataContainer<any>;
  cellVoltageDataContainer: DataContainer<any>;
  selectedDataSeriesKeys: ChartDataSeriesKey[];
  chartVisibleElements: ChartVisibleElements;
  isChartZoomed: boolean;
}

export const AnalysisBandChart: React.FC<AnalysisBandChartProps> = ({
  cellTemperatureDataContainer,
  cellVoltageDataContainer,
  selectedDataSeriesKeys,
  chartVisibleElements,
  isChartZoomed,
}) => {
  const { isEnabled: isBatteryCellDataEnabled } = useFeature(FeatureName.BATTERY_CELL_DATA);

  const isBandChartVisible =
    chartVisibleElements.includes(CellDataSeriesKey.TEMPERATURE) ||
    chartVisibleElements.includes(CellDataSeriesKey.VOLTAGE);
  const bandChartVisibility =
    isBandChartVisible && isBatteryCellDataEnabled ? Visibility.VISIBLE : Visibility.INVISIBLE;

  const isChartVisible = (seriesKey: CellDataSeriesKey): Visibility => {
    return chartVisibleElements.includes(seriesKey) ? Visibility.VISIBLE : Visibility.INVISIBLE;
  };

  const isTemperatureFilterActivated = selectedDataSeriesKeys.includes(
    CellDataSeriesKey.TEMPERATURE
  );
  const isVoltageFilterActivated = selectedDataSeriesKeys.includes(CellDataSeriesKey.VOLTAGE);

  return (
    <>
      <ViewComponent
        id={Views.BAND_CHART_WRAPPER}
        layoutParams={bandChartLayout.bandChartWrapper}
        visibility={bandChartVisibility}
      >
        <ChartGridView
          layoutParams={bandChartLayout.bandChartGrid}
          dataContainer={cellTemperatureDataContainer}
          gridLines={GridLines.HORIZONTAL}
          style={bandChartGridStyle}
        />
        <AreaChartViewComponent
          layoutParams={bandChartLayout.bandChart}
          dataContainer={cellTemperatureDataContainer}
          dataSeries={CellDataSeriesKey.TEMPERATURE}
          visibility={isChartVisible(CellDataSeriesKey.TEMPERATURE)}
          style={bandChartStyles[CellDataSeriesKey.TEMPERATURE]}
        />
        <AreaChartViewComponent
          layoutParams={bandChartLayout.bandChart}
          dataContainer={cellVoltageDataContainer}
          dataSeries={CellDataSeriesKey.VOLTAGE}
          visibility={isChartVisible(CellDataSeriesKey.VOLTAGE)}
          style={bandChartStyles[CellDataSeriesKey.VOLTAGE]}
        />
      </ViewComponent>

      <AxisView
        id={Views.BAND_CHART_X_AXIS}
        layoutParams={bandChartLayout.xAxis}
        dataContainer={cellTemperatureDataContainer}
        orientation={AxisOrientation.HORIZONTAL}
        border={{ top: 2 }}
        borderColor={SharedChartColors.Axis}
        style={{ ...bandChartXAxisStyle, wrapLabelsOnEdge: !isChartZoomed }}
        visibility={bandChartVisibility}
      />
      {isTemperatureFilterActivated && (
        <AxisView
          id={Views.BAND_CHART_Y_AXIS_LEFT}
          layoutParams={bandChartLayout.yAxisLeft}
          dataContainer={cellTemperatureDataContainer}
          orientation={AxisOrientation.VERTICAL}
          style={bandChartYAxisLeftStyle}
          visibility={bandChartVisibility}
        />
      )}
      {isVoltageFilterActivated && (
        <AxisView
          id={Views.BAND_CHART_Y_AXIS_RIGHT}
          layoutParams={bandChartLayout.yAxisRight}
          dataContainer={cellVoltageDataContainer}
          orientation={AxisOrientation.VERTICAL}
          style={bandChartYAxisRightStyle}
          visibility={bandChartVisibility}
        />
      )}
    </>
  );
};
