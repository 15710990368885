import React from 'react';

import classNames from 'classnames';

import { BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';

import './LeadConfigurationLine.scss';

export type LeadConfigurationLineType = 'bodySmall' | 'labelMedium';

type Props = {
  title: string;
  value?: string;
  helperLabel?: string;
  type: LeadConfigurationLineType;
  className?: string;
};

export const LeadConfigurationLine: React.FC<Props> = ({
  title,
  value,
  helperLabel,
  type,
  className,
}) => (
  <div className={classNames('c-lead-configuration-line', className)}>
    <div className="c-lead-configuration-line__title">
      {type === 'bodySmall' && <BodySmall text={title} />}
      {type === 'labelMedium' && <LabelMedium text={title} />}
      {helperLabel && (
        <BodySmall text={helperLabel} className="c-lead-configuration-line__helper-label" />
      )}
    </div>
    {type === 'bodySmall' && (
      <BodySmall text={value} className="c-lead-configuration-line__value" />
    )}
    {type === 'labelMedium' && (
      <LabelMedium text={value} className="c-lead-configuration-line__value" />
    )}
  </div>
);
