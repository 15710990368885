import * as React from 'react';

import { Loader } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import './LoaderWrapper.component.scss';

interface Props {
  render: (toggleLoading: React.Dispatch<any>) => React.ReactNode;
}

export const LoaderWrapper: React.FC<Props> = ({ render }) => {
  const [isLoading, toggleLoading] = React.useState<boolean>(true);

  return (
    <div className={classNames('c-loader-wrapper', { 'c-loader-wrapper--loading': isLoading })}>
      <div className={classNames({ 'c-loader-wrapper__content--hide': isLoading })}>
        {render(toggleLoading)}
      </div>
      {isLoading && (
        <div className={'c-loader-wrapper__loader'}>
          <Loader />
        </div>
      )}
    </div>
  );
};
