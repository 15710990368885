import * as React from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

import { Accordion } from '@sonnen/shared-web';

import { FormikProps } from 'formik';

import { FormInput, FormRichTextInput } from '+shared/components';
import { Language, NewsCarrera, NewsFormCarreraType } from '+shared/store/firebase/types';

import { FieldName, NewsFormCarreraHelper } from './NewsFormCarrera.helpers';

type Props = {
  form: FormikProps<NewsFormCarreraType>;
  news?: NewsCarrera;
};

type OnMessageChangeLangSection = (message: EditorValue) => void;

type OnMessageChange = (
  setMessage: React.Dispatch<React.SetStateAction<EditorValue>>,
  fieldName: FieldName
) => OnMessageChangeLangSection;

const LangSection: React.FC<{
  form: FormikProps<NewsFormCarreraType>;
  label: string;
  messageLong: EditorValue;
  messageShortFieldName: FieldName;
  messageLongFieldName: FieldName;
  onMessageChange: OnMessageChangeLangSection;
}> = ({
  form,
  label,
  messageLong,
  messageShortFieldName,
  messageLongFieldName,
  onMessageChange,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <>
      <Accordion
        header={label}
        onToggle={() => setIsExpanded(!isExpanded)}
        isOpen={isExpanded}
        isThemeNoTopBorder={true}
        isThemeNoBottomBorder={true}
      >
        <FormInput form={form} label={'Title*'} name={messageShortFieldName} type={'text'} />
        <FormRichTextInput
          value={messageLong}
          label={'Message*'}
          onChange={onMessageChange}
          toolbarConfig={NewsFormCarreraHelper.richTextInputToolbarConfig}
          error={form.errors[messageLongFieldName] && form.errors[messageLongFieldName]!.toString()}
        />
      </Accordion>
    </>
  );
};

export const NewsFormCarreraMessages: React.FC<Props> = ({ form, news }) => {
  const [messageLongUk, setMessageLongUk] = React.useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );
  const [messageLongUs, setMessageLongUs] = React.useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );
  const [messageLongDe, setMessageLongDe] = React.useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );
  const [messageLongIt, setMessageLongIt] = React.useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );

  const onMessageChange: OnMessageChange = (setMessage, fieldName) => (message) => {
    setMessage(message);
    form.setFieldTouched(fieldName);
    form.setFieldValue(fieldName, message.toString('html'));
  };

  React.useEffect(() => {
    const setDefaultMessageValue = async () => {
      const richTextEditor = (await import('react-rte')).default;
      const defaultUKLong = news
        ? richTextEditor.createValueFromString(news.messages[Language.UK].long, 'html')
        : richTextEditor.createEmptyValue();
      const defaultUSLong = news
        ? richTextEditor.createValueFromString(news.messages[Language.US].long, 'html')
        : richTextEditor.createEmptyValue();
      const defaultDELong = news
        ? richTextEditor.createValueFromString(news.messages[Language.DE].long, 'html')
        : richTextEditor.createEmptyValue();
      const defaultITLong = news
        ? richTextEditor.createValueFromString(news.messages[Language.IT].long, 'html')
        : richTextEditor.createEmptyValue();
      setMessageLongUk(defaultUKLong);
      setMessageLongUs(defaultUSLong);
      setMessageLongDe(defaultDELong);
      setMessageLongIt(defaultITLong);
      form.setFieldValue(FieldName.MESSAGE_LONG_UK, defaultUKLong.toString('html'));
      form.setFieldValue(FieldName.MESSAGE_LONG_US, defaultUSLong.toString('html'));
      form.setFieldValue(FieldName.MESSAGE_LONG_DE, defaultDELong.toString('html'));
      form.setFieldValue(FieldName.MESSAGE_LONG_IT, defaultITLong.toString('html'));
    };

    setDefaultMessageValue();
  }, []);

  return (
    <>
      <LangSection
        form={form}
        label={'EN-UK'}
        messageLong={messageLongUk}
        messageShortFieldName={FieldName.MESSAGE_SHORT_UK}
        messageLongFieldName={FieldName.MESSAGE_LONG_UK}
        onMessageChange={onMessageChange(setMessageLongUk, FieldName.MESSAGE_LONG_UK)}
      />

      <LangSection
        form={form}
        label={'EN-US'}
        messageLong={messageLongUs}
        messageShortFieldName={FieldName.MESSAGE_SHORT_US}
        messageLongFieldName={FieldName.MESSAGE_LONG_US}
        onMessageChange={onMessageChange(setMessageLongUs, FieldName.MESSAGE_LONG_US)}
      />

      <LangSection
        form={form}
        label={'DE'}
        messageLong={messageLongDe}
        messageShortFieldName={FieldName.MESSAGE_SHORT_DE}
        messageLongFieldName={FieldName.MESSAGE_LONG_DE}
        onMessageChange={onMessageChange(setMessageLongDe, FieldName.MESSAGE_LONG_DE)}
      />

      <LangSection
        form={form}
        label={'IT'}
        messageLong={messageLongIt}
        messageShortFieldName={FieldName.MESSAGE_SHORT_IT}
        messageLongFieldName={FieldName.MESSAGE_LONG_IT}
        onMessageChange={onMessageChange(setMessageLongIt, FieldName.MESSAGE_LONG_IT)}
      />
    </>
  );
};
