import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import { SetupLeadStatusTooltipWrapper } from '+lead/+list';
import { Button, ButtonType } from '+shared/components/Button';

import './SetupLeadStatuses.component.scss';

interface Statuses {
  registration: string[];
  hardwareSetup: string[];
  sonnenFlatSetup: string[];
  registrationCompleted: boolean;
  hardwareSetupCompleted: boolean;
  sonnenFlatSetupCompleted: boolean;
}

interface Props {
  statuses: Statuses;
  openModal: (e: any) => void;
}

type StatusKey = 'registration' | 'hardwareSetup' | 'sonnenFlatSetup';

const displayStatuses: StatusKey[] = ['registration', 'hardwareSetup', 'sonnenFlatSetup'];

export const SetupLeadStatuses = ({ statuses, openModal }: Props) => {
  return (
    <div className="c-setup-lead-statuses">
      {displayStatuses.map((item) => (
        <SetupLeadStatusItem key={item} item={item} statuses={statuses} />
      ))}

      <Button
        type={ButtonType.SECONDARY}
        label={I18n.t(T.setupTool.statusManagement.button.update)}
        className={'c-setup-lead-statuses__button'}
        dataTestId="update-button"
        onClick={openModal}
      />
    </div>
  );
};

interface SetupLeadStatusIconProps {
  completionStatus: 'completed' | 'inProgress' | 'notStarted';
}

const SetupLeadStatusIcon = ({ completionStatus }: SetupLeadStatusIconProps) => {
  const className = 'c-setup-lead-statuses__item__icon';
  return completionStatus === 'completed' ? (
    <Icon.TickCircle color="#338707" className={className} />
  ) : completionStatus === 'inProgress' ? (
    <Icon.TwoThirdsOfCircle color="#338707" className={className} />
  ) : (
    <Icon.Zigzag color="#61687A" className={className} />
  );
};

interface SetupLeadStatusItemProps {
  statuses: Statuses;
  item: StatusKey;
}

const SetupLeadStatusItem = ({ statuses, item }: SetupLeadStatusItemProps) => {
  const state = statuses[item];
  const completionStatus = statuses[`${item}Completed`]
    ? 'completed'
    : state.length
    ? 'inProgress'
    : 'notStarted';

  return (
    <SetupLeadStatusTooltipWrapper statuses={statuses[item]}>
      <div className="c-setup-lead-statuses__item" data-testid={`${item}-status`}>
        <SetupLeadStatusIcon completionStatus={completionStatus} />
        {I18n.t(T.setupTool.statusManagement.section.header[item])}
      </div>
    </SetupLeadStatusTooltipWrapper>
  );
};
