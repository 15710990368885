import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loadable, PageSubheadline } from '@sonnen/shared-web';

import { mapActions } from '+app/utils';
import { RegisterActions } from '+register/store';
import { StoreState } from '+shared/store/store.interface';

import { RegisterCard, RegisterForm } from '../../components';
import {
  getRegistrationEmail,
  getSubmitRegistrationQueryStatus,
  getVerifyTokenQueryStatus,
} from '../../store/+register.selectors';

import './Register.component.scss';

const mapStateToProps = (state: StoreState) => ({
  email: getRegistrationEmail(state),
  tokenVerificationStatus: getVerifyTokenQueryStatus(state),
  submitRegistrationQueryStatus: getSubmitRegistrationQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  setRegisterForm: RegisterActions.setRegisterForm,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class RegisterComponent extends React.PureComponent<Props> {
  render() {
    const {
      email,
      tokenVerificationStatus,
      submitRegistrationQueryStatus,
      actions: { setRegisterForm },
    } = this.props;
    return (
      <section className={'c-register'}>
        <RegisterCard>
          <PageSubheadline classNames={'c-register__headline'} smallGap={true} noBorder={true}>
            {I18n.t(T.register.title)}
          </PageSubheadline>
          <p className={'c-register__email'}>{email}</p>
          <Loadable transition={'fade'} predicate={tokenVerificationStatus.pending}>
            <RegisterForm
              queryStatus={submitRegistrationQueryStatus}
              setRegisterForm={setRegisterForm}
            />
          </Loadable>
        </RegisterCard>
      </section>
    );
  }
}

export const Register = connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
