// @TODO It was copied from Customer Portal move to shared web
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { TemperatureUnit } from '@sonnen/shared-web';

const getCelsiusFromKelvin = (kelvin: number) => Math.round(kelvin - 273.15);

export const getFahrenheitFromCelsius = (celsius: number) => Math.round(celsius * (9 / 5) + 32);

export const celsiusToFahrenheit = (celsius: number) => celsius * (9 / 5) + 32;

export const getFormattedTemperature = (temp: number): string => {
  const translatedTempUnit = I18n.t(T.general.temperatureUnit);
  // @TODO temporary fix - remove choosing unit based on value after unit problems solved.
  // Use formatTemperature() from format.util.old.tsx instead of entire temperature.tsx
  const unit = temp > 150 ? TemperatureUnit.K : TemperatureUnit.C;
  const celsius = unit === TemperatureUnit.K ? getCelsiusFromKelvin(temp) : Math.round(temp);

  if (celsius <= 0) {
    return '-';
  }

  return translatedTempUnit === TemperatureUnit.F
    ? `${getFahrenheitFromCelsius(celsius)} °${translatedTempUnit}`
    : `${celsius} °${translatedTempUnit}`;
};
