import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { DetailListType } from '+shared/components';
import { ChargerStatus, onlineStatuses } from '+shared/store/charger';
import { formatDate } from '+utils/format.util';

import { Charger } from './CustomerChargerWidget.interface';

const hasOnlineStatus = (charger: Charger) =>
  charger.liveState && onlineStatuses.includes(charger.liveState.status);

const hasChargingStatus = (charger: Charger) =>
  charger.liveState && charger.liveState.status === ChargerStatus.CHARGING;

const setLastSeenDate = (date: string) => formatDate(date);

const setOfflineStatus = (charger: Charger) =>
  charger.lastSeenAt
    ? `${I18n.t(T.customerSingle.overview.sonnenCharger.states.offlineWithDate, {
        date: `${setLastSeenDate(charger.lastSeenAt)}`,
      })}`
    : `${I18n.t(T.customerSingle.overview.sonnenCharger.states.offline)}`;

const setMode = (charger: Charger) =>
  charger.liveState && charger.liveState.smartmode
    ? I18n.t(T.customerSingle.overview.sonnenCharger.states.smartMode)
    : I18n.t(T.customerSingle.overview.sonnenCharger.states.powerMode);

const setStatus = (charger: Charger) => {
  if (hasChargingStatus(charger)) {
    return setMode(charger);
  }
  if (hasOnlineStatus(charger)) {
    return I18n.t(T.customerSingle.overview.sonnenCharger.states.online);
  }
  return setOfflineStatus(charger);
};

export const factorizeDetailList = (charger: Charger): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.overview.sonnenCharger.serialNumber),
    value: charger.serialNumber,
  },
  {
    label: I18n.t(T.customerSingle.overview.sonnenCharger.state),
    value: setStatus(charger),
    type: hasOnlineStatus(charger) ? 'success' : undefined,
  },
];
