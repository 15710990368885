import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { ModalLayout } from '@sonnen/shared-web';

import { Formik } from 'formik';

import { Button, ButtonType, MainType } from '+shared/components/Button';
import { maxAttachmentSize } from '+shared/components/Form/Form.helpers';
import { FormUploadInput } from '+shared/components/Form/FormUploadInput';

import { getInitialValues } from './DocUploadForm.helper';
import { DocumentsUploadForm } from './types/DocUploadForm.interface';

import './DocUploadForm.component.scss';

interface Props {
  submitForm: (values: DocumentsUploadForm) => void;
  onClose: () => void;
  isLoading: boolean;
  documents: DocumentsUploadForm;
}

export const DocUploadForm: React.FC<Props> = ({ documents, isLoading, onClose, submitForm }) => {
  const [termsAndConditions, setTermsAndConditions] = React.useState<null | string | undefined>(
    documents.termsAndConditions
  );
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    React.useState<boolean>(false);

  const [cancellationPolicy, setCancellationPolicy] = React.useState<null | string | undefined>(
    documents.cancellationPolicy
  );
  const [isCancellationPolicyChanged, setIsCancellationPolicyChanged] =
    React.useState<boolean>(false);

  const [dataPrivacyPolicy, setDataPrivacyPolicy] = React.useState<null | string | undefined>(
    documents.dataPrivacyPolicy
  );
  const [isDataPrivacyPolicyChanged, setIsDataPrivacyPolicyChanged] =
    React.useState<boolean>(false);

  const onFormSubmit = () => {
    const values: DocumentsUploadForm = {};
    if (isTermsAndConditionsChanged) {
      values.termsAndConditions = termsAndConditions;
    }

    if (isCancellationPolicyChanged) {
      values.cancellationPolicy = cancellationPolicy;
    }

    if (isDataPrivacyPolicyChanged) {
      values.dataPrivacyPolicy = dataPrivacyPolicy;
    }
    submitForm(values);
  };

  const onUpload = (file: File, type: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      if (result) {
        const stringResult = result.toString();
        const base64Result = stringResult.slice(stringResult.indexOf(',') + 1);
        if (type === 'termsAndConditions') {
          setTermsAndConditions(base64Result);
          setIsTermsAndConditionsChanged(true);
        }
        if (type === 'cancellationPolicy') {
          setCancellationPolicy(base64Result);
          setIsCancellationPolicyChanged(true);
        }
        if (type === 'dataPrivacyPolicy') {
          setDataPrivacyPolicy(base64Result);
          setIsDataPrivacyPolicyChanged(true);
        }
      }
    };

    return file.name;
  };

  const onTermsAndConditionsReset = () => {
    setTermsAndConditions(null);
    setIsTermsAndConditionsChanged(true);

    return null;
  };

  const onCancellationPolicyReset = () => {
    setCancellationPolicy(null);
    setIsCancellationPolicyChanged(true);

    return null;
  };

  const onDataPrivacyPolicyReset = () => {
    setDataPrivacyPolicy(null);
    setIsDataPrivacyPolicyChanged(true);

    return null;
  };

  return (
    <Formik
      initialValues={getInitialValues(documents)}
      onSubmit={onFormSubmit}
      render={(form) => (
        <form onSubmit={form.handleSubmit} className={'c-doc-upload-form'}>
          <ModalLayout
            title={I18n.t(T.companyAccount.legalDocuments.title)}
            footer={
              <div className={'c-reset-password-form__button-wrapper'}>
                <Button
                  label={I18n.t(T.general.basicActions.discardChanges)}
                  type={ButtonType.TERTIARY}
                  onClick={onClose}
                  className={'c-reset-password-form__button'}
                />
                <Button
                  label={I18n.t(T.general.basicActions.save)}
                  type={ButtonType.PRIMARY}
                  loading={isLoading}
                  mainType={MainType.SUBMIT}
                  className={'c-reset-password-form__button'}
                />
              </div>
            }
          >
            <div>
              <p className={'c-doc-upload-form__hint'}>
                {I18n.t(T.companyAccount.legalDocuments.modal.hint)}
              </p>
              <FormUploadInput
                label={I18n.t(T.companyAccount.legalDocuments.modal.placeholderLabel)}
                placeholder={I18n.t(T.companyAccount.legalDocuments.modal.placeholderDesc, {
                  size: maxAttachmentSize.label,
                })}
                title={I18n.t(T.companyAccount.legalDocuments.termsAndConditions)}
                form={form}
                name={'termsAndConditions'}
                className={'c-doc-upload-form__upload-input'}
                onUpload={(file) => onUpload(file[0], 'termsAndConditions')}
                accept={['application/pdf']}
                onReset={onTermsAndConditionsReset}
                maxSize={maxAttachmentSize.value}
                maxSizeLabel={maxAttachmentSize.label}
              />
              <FormUploadInput
                label={I18n.t(T.companyAccount.legalDocuments.modal.placeholderLabel)}
                placeholder={I18n.t(T.companyAccount.legalDocuments.modal.placeholderDesc, {
                  size: maxAttachmentSize.label,
                })}
                title={I18n.t(T.companyAccount.legalDocuments.cancellationPolicy)}
                form={form}
                name={'cancellationPolicy'}
                className={'c-doc-upload-form__upload-input'}
                onUpload={(file) => onUpload(file[0], 'cancellationPolicy')}
                accept={['application/pdf']}
                onReset={onCancellationPolicyReset}
                maxSize={maxAttachmentSize.value}
                maxSizeLabel={maxAttachmentSize.label}
              />
              <FormUploadInput
                label={I18n.t(T.companyAccount.legalDocuments.modal.placeholderLabel)}
                placeholder={I18n.t(T.companyAccount.legalDocuments.modal.placeholderDesc, {
                  size: maxAttachmentSize.label,
                })}
                title={I18n.t(T.companyAccount.legalDocuments.dataPrivacyPolicy)}
                form={form}
                name={'dataPrivacyPolicy'}
                className={'c-doc-upload-form__upload-input'}
                onUpload={(file) => onUpload(file[0], 'dataPrivacyPolicy')}
                accept={['application/pdf']}
                onReset={onDataPrivacyPolicyReset}
                maxSize={maxAttachmentSize.value}
                maxSizeLabel={maxAttachmentSize.label}
              />
            </div>
          </ModalLayout>
        </form>
      )}
    />
  );
};
