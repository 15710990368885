import * as React from 'react';

import { Loader } from '@sonnen/shared-web';

import './LeadOverviewConfigurationEmpty.component.scss';

interface Props {
  title: string;
  isLoading?: boolean;
}

export const LeadOverviewConfigurationEmpty: React.FC<Props> = ({ title, isLoading, children }) => (
  <div className={'c-lead-configuration-tile'}>
    <div className={'c-lead-configuration-tile__header'}>
      <div className={'c-lead-configuration-tile__header-upper'}>{title}</div>
    </div>
    <div className={'c-lead-configuration-tile__body c-lead-overview-configuration-empty'}>
      {isLoading ? <Loader /> : children}
    </div>
  </div>
);
