import { firestore } from 'firebase/app';
import { CountryCode } from 'src/types/country.type';

import { ContractType, TariffType } from '+shared/store/contract/contract.helpers';
import { UserRole } from '+shared/store/user';
import { getValueArrayFromEnum } from '+utils/array.util';
import { Market } from '+utils/market.util';

export interface News {
  id: string;
  shortMessage: string;
  introduction?: string;
  message: string;
  markets: Market[];
  date?: Timestamp;
  expiryDate?: string;
  level?: Status;
  tag?: TagStatus;
  levelValue: number;
  tagValue: string;
  roles: UserRole[];
  portals: Portal[];
  isDisplayedAsNotification?: boolean;
  notificationExpiryDate?: string;
}

export type NewsCP = {
  id: string;
  countries: CountryCode[];
  excludeInRoutes: RouteName[];
  expiryDateTimestamp: Date | firestore.Timestamp;
  includeInRoutes: RouteName[];
  levelValue: number;
  messages: CountriesMessages;
  portals: Portal[];
  options: Options[];
  optionsDetails: OptionsDetails;
  isDisplayedAsNotification?: boolean;
};

export type NewsCarrera = {
  id: string;
  countries: CountryCode[];
  excludeInRoutes: RouteNameCarrera[];
  expiryDateTimestamp: Date | firestore.Timestamp;
  includeInRoutes: RouteNameCarrera[];
  levelValue: number;
  messages: CountriesMessages;
  portals: Portal[];
  options: Options[];
  optionsDetails: OptionsDetails;
  isDisplayedAsNotification?: boolean;
};

export interface ReadNewsPP {
  userId: string;
  newsId: string;
}

export type GenericNews = News | NewsCP | NewsCarrera;
export type ListItemNews<T extends GenericNews = GenericNews> = T & { isActive: boolean };

export enum STATUS {
  INFO = 'Info',
  WARNING = 'Warning',
  DANGER = 'Danger',
}

export type NewsFormCP = {
  id?: string;
  countries: CountryCode[];
  excludeInRoutes: RouteName[];
  expiryDateTimestamp: string;
  includeInRoutes: RouteName[];
  status: STATUS;
  messageLongUk: string;
  messageLongUs: string;
  messageLongDe: string;
  messageLongIt: string;
  messageShortUk: string;
  messageShortUs: string;
  messageShortDe: string;
  messageShortIt: string;
  options: Options[];
  optionsBatteriesControllerType: ControllerType[];
  optionsBatteriesAccessories: BatteryAccessories[];
  optionsContractType: ContractType[];
  optionsTariffType: TariffType[];
};

export type NewsFormCarreraType = {
  id?: string;
  countries: CountryCode[];
  excludeInRoutes: RouteNameCarrera[];
  expiryDateTimestamp: string;
  includeInRoutes: RouteNameCarrera[];
  status: STATUS;
  messageLongUk: string;
  messageLongUs: string;
  messageLongDe: string;
  messageLongIt: string;
  messageShortUk: string;
  messageShortUs: string;
  messageShortDe: string;
  messageShortIt: string;
  options: Options[];
  optionsBatteriesControllerType: ControllerType[];
  optionsBatteriesAccessories: BatteryAccessories[];
  optionsContractType: ContractType[];
  optionsTariffType: TariffType[];
};

export const CountryCodeArrayValue = getValueArrayFromEnum<typeof CountryCode>(CountryCode);

export enum Language {
  UK = 'en-gb',
  US = 'en-us',
  DE = 'de',
  IT = 'it',
}

export type CountriesMessages = {
  [key in Language]: CountriesMessagesAttributes;
};

export type CountriesMessagesAttributes = {
  long: string;
  short: string;
};

export enum Portal {
  SP = 'SP',
  CP = 'CP',
  Carrera = 'Carrera',
}

export type Status = {
  value: number;
  label: string;
};

export type TagStatus = {
  value: string;
  label: string;
};

export type PortalArrayValue = {
  value: Portal;
  label: string;
};

export enum RouteName {
  DASHBOARD = 'DASHBOARD',
  ANALYSIS = 'ANALYSIS',
  LIVE_STATE = 'LIVE_STATE',
  COMMUNITY = 'COMMUNITY',
  FLAT_OVERVIEW = 'FLAT_OVERVIEW',
  TARIFF_OVERVIEW = 'TARIFF_OVERVIEW',
  ENERGY_OVERVIEW = 'ENERGY_OVERVIEW',
  BATTERY_OVERVIEW = 'BATTERY_OVERVIEW',
}

export enum RouteNameCarrera {
  LOGIN = 'LOGIN',
  HOME = 'HOME',
  LIVE_STATE = 'LIVE_STATE',
  BATTERY_DETAILS = 'BATTERY_DETAILS',
  CONTRACT = 'CONTRACT',
  HISTORICAL_DATA = 'HISTORICAL_DATA',
  SUPPORT = 'SUPPORT',
  PROFILE = 'PROFILE',
  SETTINGS = 'SETTINGS',
}

export type Timestamp = {
  nanoseconds: number;
  seconds: number;
};

export enum Options {
  CHARGERS = 'chargers',
  BATTERIES = 'batteries',
  CONTRACTS = 'contracts',
  READINGS = 'readings',
  SMARTMETERS = 'smartmeters',
}

export enum BatteryAccessories {
  HEATER = 'heater',
  BACKUP = 'backup',
  PROTECT = 'protect',
}

export enum ControllerType {
  EATON = 'eaton',
  SPREE = 'spree',
}

export const optionsValueArray = getValueArrayFromEnum<typeof Options>(Options);
export const batteryAccessoriesValueArray =
  getValueArrayFromEnum<typeof BatteryAccessories>(BatteryAccessories);
export const batteryControllerTypeValueArray =
  getValueArrayFromEnum<typeof ControllerType>(ControllerType);
export const contractTypeValueArray = getValueArrayFromEnum<typeof ContractType>(ContractType);
export const tariffTypeValueArray = getValueArrayFromEnum<typeof TariffType>(TariffType);

export type OptionsDetailsBatteries = {
  controllerType?: ControllerType[];
  accessories?: BatteryAccessories[];
};

export type OptionsDetailsContracts = {
  contractType?: ContractType[];
  tariffType?: TariffType[];
};

export interface OptionsDetails {
  [Options.BATTERIES]: OptionsDetailsBatteries;
  [Options.CONTRACTS]: OptionsDetailsContracts;
}
