import { uniq } from 'lodash/fp';

import {
  setCaseStatusAggregated,
  setCaseStatusAggregatedColor,
} from '+shared/components/CaseStatus/CaseStatus.component.helper';
import {
  CaseAggregatedStatus,
  CaseStatus,
  caseStatusFilterGroup,
  statusFilterAggregates,
} from '+shared/store/case/types/case.interface';

export const getStringifiedStatuses = (statuses: CaseAggregatedStatus[]): string =>
  statuses.join(',');

export const getInitialFilters = (queryStatus: string = ''): CaseAggregatedStatus[] =>
  uniq(
    queryStatus
      .split(',')
      .filter((status) => caseStatusFilterGroup.includes(status as CaseAggregatedStatus))
      .map((status) => status as CaseAggregatedStatus)
  );

export const getStatusGroups = () =>
  caseStatusFilterGroup.map((status) => ({
    name: status,
    label: setCaseStatusAggregated(status),
    color: setCaseStatusAggregatedColor(status),
  }));

export const getAggregatedName = (status: CaseStatus): CaseAggregatedStatus | undefined =>
  caseStatusFilterGroup.find((aggregatedStatus) =>
    statusFilterAggregates[aggregatedStatus].includes(status)
  );

export const CaseListHelper = {
  getStringifiedStatuses,
  getInitialFilters,
  getStatusGroups,
  getAggregatedName,
};
