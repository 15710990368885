import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { getPrecision } from '+utils/number.util';

const parseReactTextToFloat = (reactText: React.ReactText) => parseFloat(reactText.toString());
const minValue = 0;
const errorMsg = (maxValue: number) =>
  `${I18n.t(
    T.lead.configuration._salessolution_.configurations.outOfRange
  )} (${minValue} - ${maxValue}%)`;

export const ConfigurationAutarkySchema = (maxValue: React.ReactText) =>
  Yup.object().shape({
    autarky: Yup.number()
      .required(I18n.t(T.register.alert.requiredField))
      .min(minValue, errorMsg(parseReactTextToFloat(maxValue)))
      .max(parseReactTextToFloat(maxValue), errorMsg(parseReactTextToFloat(maxValue)))
      .test(
        'decimal-places',
        I18n.t(T.validation.lessDecimalPlaces),
        (val) => getPrecision(val) <= 2
      ),
  });
