var JsonApiSender = (function () {
    function JsonApiSender(httpClient, requestData) {
        this.httpClient = httpClient;
        this.requestData = requestData;
        this.resolveIncludedRelationships = false;
    }
    JsonApiSender.prototype.request = function (options) {
        return this.httpClient.request(this.requestData.uri, this.requestData.getRequestOptions(options));
    };
    JsonApiSender.prototype.resolveIncluded = function (resolveIncluded) {
        this.resolveIncludedRelationships = resolveIncluded === undefined || resolveIncluded;
        return this;
    };
    return JsonApiSender;
}());
export { JsonApiSender };
