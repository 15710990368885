export const ORDER = 'order';

export enum QueryTypes {
  CASES = 'cases',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type QueryNestedField = {
  [K in string]: string[];
};

export type QueryNestedArg = {
  [K in string]: string;
};

export type QueryPrimitive = string | number;

export type QueryReference = string[] | QueryNestedArg;

type QueryKeys = QueryPrimitive | QueryReference;

export type QueryArgs = {
  [K in string]: QueryKeys;
};

export type QueryField = string | QueryNestedField;

export type QueryParams = {
  type: QueryTypes;
  fields: QueryField[];
  args?: QueryArgs;
};

export type GraphQLError = {
  extensions: {
    argumentName: string;
    code: string;
    name: string;
    typeName: string;
  };
  message: string;
  path: string[];
};
