import * as React from 'react';

import { Bubble, DefaultParagraph } from '@sonnen/shared-web';

import * as uuid from 'uuid/v4';

import { getSetupLeadStatusTooltipTranslations } from './SetupLeadStatusTooltipWrapper.const';

import './SetupLeadStatusTooltipWrapper.component.scss';

interface TooltipWrapperProps {
  children: React.ReactNode;
  statuses: string[];
}

export const SetupLeadStatusTooltipWrapper = ({ children, statuses }: TooltipWrapperProps) => {
  const setupLeadStatusTooltipTranslations = getSetupLeadStatusTooltipTranslations();
  return (
    <div className={'c-setup-lead-statuses-tooltip__wrapper'}>
      <div className={'c-setup-lead-statuses-tooltip__trigger'}>{children}</div>
      <div className={'c-setup-lead-statuses-tooltip__bubble'}>
        {!!statuses.length && (
          <Bubble side={'bottom'}>
            <DefaultParagraph>
              {statuses.map((status) => (
                <p key={uuid()} className={'c-setup-lead-statuses-tooltip__bubble__status-name'}>
                  {setupLeadStatusTooltipTranslations[status]}
                </p>
              ))}
            </DefaultParagraph>
          </Bubble>
        )}
      </div>
    </div>
  );
};
