import { Data, MergedData } from '@coolio/json-api';

import { MeasurementMethod } from './siteMeasurements.interface';

export enum SiteOptions {
  CHARGERS = 'chargers',
  BATTERIES = 'batteries',
  CONTRACTS = 'contracts',
  READINGS = 'readings',
  SMARTMETERS = 'smartmeters',
}

export interface SiteAttributes {
  street: string | null;
  postalCode: string | null;
  city: string | null;
  countryCode: string | null;
  streetNumber: string | null;
  deliveryStartAt: Date;
  sonnenCommunityMember: boolean;
  options: SiteOptions[];
  measurementMethod: MeasurementMethod;
  batterySystems: string[];
}

export type SiteData = Data<SiteAttributes>;
export type Site = MergedData<SiteData>;
