import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Logo } from '+app/+account/containers/Logo';
import { AccountPageActions } from '+app/+account/store';
import { getUploadCompanyLogoQueryStatus } from '+app/+account/store/+account.selector';
import { mapActions } from '+app/utils';
import { CountryFeatureName } from '+config/countryFlags';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container, RolloutLimiter } from '+shared/components';
import { StoreState } from '+shared/store/store.interface';
import { getUserCompany } from '+shared/store/user/user.selectors';

import { LegalDocuments } from '../LegalDocuments';
import { RequestAccess } from '../RequestAccess';

import './AccountCompany.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userCompany: getUserCompany(state),
  queryStatus: getUploadCompanyLogoQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  submitCompanyLogo: AccountPageActions.submitCompanyLogo,
  resetUploadCompanyLogoQuery: AccountPageActions.resetUploadCompanyLogoQuery,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const AccountCompanyComponent: React.FC<Props> = ({ userCompany, queryStatus, actions }) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.ACCOUNT, PageName.Account.ACCOUNT_COMPANY_DETAILS);

  return (
    <Container className={'c-account-company'} withHorizontalPadding={false}>
      {/* @TODO: uncomment when needed */}
      {/* <div className={'o-grid'}>
      <div className={'o-grid__column o-grid__column--md-6 c-account-company__column'}>
        <CompanyDetails user={userProfile} />
      </div>
      <div className={'o-grid__column o-grid__column--md-6 c-account-company__column'}>
        <ContactDetails user={userProfile} />
      </div>
    </div> */}
      <div className={'o-grid'}>
        <div
          className={'o-grid__column o-grid__column--md-6 c-account-company__column'}
          data-hj-suppress={true}
        >
          <Logo
            title={I18n.t(T.companyAccount.companyLogo.modal.title)}
            hint={I18n.t(T.companyAccount.companyLogo.modal.hint)}
            headline={I18n.t(T.companyAccount.companyLogo.title)}
            logotypeUrl={userCompany?.logotypeUrl}
            queryStatus={queryStatus}
            submitLogo={actions.submitCompanyLogo}
            resetUploadLogoQuery={actions.resetUploadCompanyLogoQuery}
          />
        </div>
        <div className={'o-grid__column o-grid__column--md-6 c-account-company__column'}>
          <RequestAccess />
        </div>
      </div>
      <RolloutLimiter countryFeatureName={CountryFeatureName.COMPANY_LEGAL_DOCUMENTS}>
        <div className={'o-grid'}>
          <div className={'o-grid__column o-grid__column--md-6 c-account-company__column'}>
            <LegalDocuments userCompany={userCompany} />
          </div>
        </div>
      </RolloutLimiter>
    </Container>
  );
};

export const AccountCompany = connect(mapStateToProps, mapDispatchToProps)(AccountCompanyComponent);
