import React from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
};

const LineDivider: React.FC<Props> = ({
  width = '100%',
  height = 1,
  color = '#D3D5D8',
  className,
}) => (
  <hr
    style={{ width: width, height: height, backgroundColor: color, border: 'none', margin: 0 }}
    className={className}
  />
);

export default LineDivider;
