export enum RoleNames {
  COMPANY_COORDINATOR = 'company_coordinator',
  SALES_AGENT = 'sales_agent',
  SETUP_COORDINATOR = 'setup_coordinator',
  SERVICE_COORDINATOR = 'service_coordinator',
}

export interface Role {
  name: RoleNames;
  id?: string;
  contact?: string;
  createdAt?: string;
}
