import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Accordion, Loader, PageSubheadline } from '@sonnen/shared-web';

import { isEmpty } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { UserCertificate } from '+shared/store/user/types/userCertificate.interface';

import CertificateEmptyMessage from '../CertificateEmptyMessage/CertificateEmptyMessage';
import { CertificateListItem } from '../CertificateListItem';
import { CertificateListItemHeader } from '../CertificateListItemHeader';

import './CertificateList.component.scss';

interface Props {
  certificates: UserCertificate[];
  userCountry: CountryCode;
  isLoading: boolean;
}

export const CertificateList: React.FC<Props> = ({ certificates, isLoading, userCountry }) => {
  return (
    <div className={'c-certificate-list'}>
      <PageSubheadline mediumGap={true}>{I18n.t(T.myAccount.certification.title)}</PageSubheadline>
      {isLoading ? (
        <Loader />
      ) : !isEmpty(certificates) ? (
        <div className={'c-certificate-list__table'}>
          {certificates.map((certificate, index) => (
            <Accordion
              key={certificate.id}
              header={<CertificateListItemHeader certificate={certificate} />}
              isHeaderVerticallyCentered={true}
              isThemeNoTopBorder={index === 0}
              isThemeNoTopPadding={index === 0}
            >
              <CertificateListItem
                key={certificate.id}
                certificate={certificate}
                userCountry={userCountry}
              />
            </Accordion>
          ))}
        </div>
      ) : (
        <CertificateEmptyMessage userCountry={userCountry} />
      )}
    </div>
  );
};
