import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { dataGuard, ofType, processQuery } from '../../../utils';
import { UserActions } from './user.actions';
import { UserRepository } from './user.repository';
import { GET_COMPANY_QUERY, GET_USER_PROFILE_QUERY, UserProfile } from './user.state';

type Action$ = ActionsObservable<UserActions>;

export const putUserPassword$ = (action$: Action$) =>
  action$.pipe(
    ofType(UserActions.editPassword),
    mergeMap(({ queryKey, passwordForm, userId }) =>
      of({}).pipe(
        processQuery(queryKey, () => UserRepository.putUserPassword(userId, passwordForm))
      )
    )
  );

export const getUserProfile$ = (action$: Action$) =>
  action$.pipe(
    ofType(UserActions.getUserProfile),
    mergeMap((data: UserProfile) =>
      of({}).pipe(
        processQuery(GET_USER_PROFILE_QUERY, () => UserRepository.getUserProfile(data.profile.id), {
          onSuccess: (res) => dataGuard(UserActions.setUserProfile)(res!.element),
        })
      )
    )
  );

export const getUserCompany$ = (action$: Action$) =>
  action$.pipe(
    ofType(UserActions.getCompany),
    mergeMap((data: UserProfile) =>
      of({}).pipe(
        processQuery(GET_COMPANY_QUERY, () => UserRepository.getUserCompany(data.profile.id), {
          onSuccess: (res) => dataGuard(UserActions.setCompany)(res!.element),
        })
      )
    )
  );

export const epics = combineEpics<any>(getUserCompany$, getUserProfile$, putUserPassword$);
