import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BatteryStatusesKey, Feature } from '@sonnen/shared-web';

import { DataContainer } from '@kanva/charts';
import { LineChartView as LineChartViewComponent } from '@kanva/charts-react';
import { Visibility } from '@kanva/core';
import { TextView, View as ViewComponent } from '@kanva/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureName } from '+config/featureFlags';

import { ChartVisibleElements } from '../../store/types/chartVisibleElements.interface';
import { Views } from '../AnalysisDayChart/AnalysisDayChart.layout';
import { createLineChartLayout } from './AnalysisLineChart.layout';
import { lineLabelPaint, LineSeriesStyle } from './AnalysisLineChart.styles';

interface AnalysisLineChartProps {
  measurementsDataContainer: DataContainer<any>;
  chartVisibleElements: ChartVisibleElements;
  chargeLimitTextViewRef: React.RefObject<InstanceType<typeof TextView>>;
  vppActivityTextViewRef: React.RefObject<InstanceType<typeof TextView>>;
  cellCareTextViewRef: React.RefObject<InstanceType<typeof TextView>>;
}

export const AnalysisLineChart: React.FC<AnalysisLineChartProps> = ({
  measurementsDataContainer,
  chartVisibleElements,
  chargeLimitTextViewRef,
  vppActivityTextViewRef,
  cellCareTextViewRef,
}) => {
  const lineChartLayout = createLineChartLayout(chartVisibleElements);

  const isChartVisible = (seriesKey: BatteryStatusesKey): Visibility => {
    return chartVisibleElements.includes(seriesKey) ? Visibility.VISIBLE : Visibility.INVISIBLE;
  };

  const featureFlags = useFlags();
  const chargeLimitsEnabled = featureFlags.chargeLimits;

  return (
    <ViewComponent id={Views.LINE_CHART_WRAPPER} layoutParams={lineChartLayout.lineChartWrapper}>
      {chargeLimitsEnabled && (
        <LineChartViewComponent
          id={Views.CHARGE_LIMIT_LINE}
          layoutParams={lineChartLayout.chargeLimitLineChart}
          dataContainer={measurementsDataContainer}
          dataSeries={BatteryStatusesKey.CHARGE_LIMIT}
          visibility={isChartVisible(BatteryStatusesKey.CHARGE_LIMIT)}
          style={LineSeriesStyle[BatteryStatusesKey.CHARGE_LIMIT]}
        >
          <TextView
            ref={chargeLimitTextViewRef}
            layoutParams={lineChartLayout.lineChartLabel}
            text={I18n.t(T.customerSingle.analysis.dayChart.labels.chargeLimit.name)}
            textPaint={lineLabelPaint}
          />
        </LineChartViewComponent>
      )}
      <LineChartViewComponent
        id={Views.VPP_ACTIVITY_LINE}
        layoutParams={lineChartLayout.vppActivityLineChart}
        dataContainer={measurementsDataContainer}
        dataSeries={BatteryStatusesKey.VPP_ACTIVITY}
        visibility={isChartVisible(BatteryStatusesKey.VPP_ACTIVITY)}
        style={LineSeriesStyle[BatteryStatusesKey.VPP_ACTIVITY]}
      >
        <TextView
          ref={vppActivityTextViewRef}
          layoutParams={lineChartLayout.lineChartLabel}
          text={I18n.t(T.customerSingle.analysis.dayChart.labels.vppActivity.name)}
          textPaint={lineLabelPaint}
        />
      </LineChartViewComponent>
      <Feature name={FeatureName.BATTERY_CELL_CARE}>
        <LineChartViewComponent
          id={Views.CELL_CARE_LINE}
          layoutParams={lineChartLayout.cellCareLineChart}
          dataContainer={measurementsDataContainer}
          dataSeries={BatteryStatusesKey.CELL_CARE}
          visibility={isChartVisible(BatteryStatusesKey.CELL_CARE)}
          style={LineSeriesStyle[BatteryStatusesKey.CELL_CARE]}
        >
          <TextView
            ref={cellCareTextViewRef}
            layoutParams={lineChartLayout.lineChartLabel}
            text={I18n.t(T.customerSingle.analysis.dayChart.labels.cellCare.name)}
            textPaint={lineLabelPaint}
          />
        </LineChartViewComponent>
      </Feature>
    </ViewComponent>
  );
};
