import {
  EServicesCaseCategory,
  TechnicalCaseCategory,
  TechnicalCaseSubcategory,
} from '+shared/store/case/types/createCase.interface';

export type SelectItem<T = string> = {
  name: string;
  value: T;
};
export type CategorySelectItem = SelectItem<EServicesCaseCategory | TechnicalCaseCategory> & {
  subcategories?: SelectItem[];
};
export type SubcategorySelectItem = SelectItem<TechnicalCaseSubcategory>;

export enum RecordTypeCase {
  TECHNICAL = 'TechnicalSupportCase',
  E_SERVICES = 'eServices',
  OTHER = 'other',
}
export enum RecordTypeSupport {
  TECHNICAL = 'TechnicalSupportCase',
  E_SERVICES = 'eServices',
  SETUP_CONFIG = 'SetupConfiguration',
}
export type RecordType = RecordTypeCase | RecordTypeSupport;
export interface RecordTypeData {
  key: RecordType;
  label: string;
  categories?: CategorySelectItem[];
  subcategories?: SubcategorySelectItem[];
  description?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export type RecordTypesData = {
  [key in RecordType]?: RecordTypeData;
};
export enum CaseFormField {
  RECORD_TYPE = 'recordType',
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  DESCRIPTION = 'description',
}

export interface CaseFormValues {
  [CaseFormField.RECORD_TYPE]: RecordTypeData | undefined;
  [CaseFormField.CATEGORY]: string | CategorySelectItem;
  [CaseFormField.SUBCATEGORY]: SubcategorySelectItem | undefined;
  [CaseFormField.DESCRIPTION]: string;
}

export const initialValues: CaseFormValues = {
  [CaseFormField.RECORD_TYPE]: undefined,
  [CaseFormField.CATEGORY]: '',
  [CaseFormField.SUBCATEGORY]: undefined,
  [CaseFormField.DESCRIPTION]: '',
};
