import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';

import { RequestAccessForm } from '+app/+account/+company/components';
import { mapActions } from '+app/utils';
import { Button, ButtonType } from '+shared/components/Button';
import { RecordTypeSupport } from '+shared/components/CaseReportForm/CaseCommon.types';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import {
  CaseActions as SharedCaseActions,
  getCaseSubmitQueryStatus,
  getCreatedCaseNumber,
} from '+shared/store/case';
import { mapUserCountryToCaseCountry } from '+shared/store/case/case.helper';
import {
  CaseOrigin,
  CasePriority,
  CaseStatus,
  CaseType,
} from '+shared/store/case/types/createCase.interface';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserCountryCode,
  getUserProfileSalesforceContactId,
} from '+shared/store/user/user.selectors';

import {
  RequestAccessFormHelper,
  RequestPartnerAccessForm,
} from '../../components/RequestAccessForm/RequestAccessForm.helper';

import './RequestAccess.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  queryStatus: getCaseSubmitQueryStatus(state),
  contactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
  userCountry: getUserCountryCode(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  submitRequestAccess: SharedCaseActions.createCase,
  clearCaseStore: SharedCaseActions.clearCreatedCase,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const RequestAccessComponent: React.FC<Props> = ({
  actions,
  openModalId,
  queryStatus,
  contactId,
  caseNumber,
  userCountry,
}) => {
  const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');
  const onOpen = (): void => {
    actions.toggleModal(true, ModalId.REQUEST_ACCESS);
  };

  const onClose = (): void => {
    actions.clearCaseStore();
    setFormState(FormState.FORM);
    actions.toggleModal(false);
  };

  const onTryAgain = (): void => {
    actions.clearCaseStore();
    setFormState(FormState.FORM);
  };

  const onSubmit = (values: RequestPartnerAccessForm): void => {
    actions.submitRequestAccess({
      recordType: RecordTypeSupport.TECHNICAL,
      subject: 'Request for a new Partner Portal User',
      description: RequestAccessFormHelper.setRequestAccessDesc(
        values.companyName,
        values.userName,
        values.userLastName,
        values.email,
        values.roles
      ),
      type: CaseType.REQUEST,
      priority: CasePriority.NORMAL,
      status: CaseStatus.NEW,
      origin: CaseOrigin.PARTNER_PORTAL,
      country: mapUserCountryToCaseCountry(isSpanishMarketEnabled, userCountry),
      contactId,
    });
  };

  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  React.useEffect(() => {
    if (queryStatus.success) {
      setFormState(FormState.FORM_SUCCESS);
    }

    if (queryStatus.error) {
      setFormState(FormState.FORM_ERROR);
    }
  }, [queryStatus]);

  return (
    <div className={'c-request-access'}>
      <PageSubheadline smallGap={true}>
        <div className={'c-request-access__wrapper'}>
          {I18n.t(T.companyAccount.requestAccess.title)}
          <Button
            onClick={onOpen}
            className={'c-request-access__button'}
            label={I18n.t(T.companyAccount.requestAccess.buttons.open)}
            type={ButtonType.TERTIARY}
          />
        </div>
      </PageSubheadline>
      <FormModal
        isModalOpen={openModalId === ModalId.REQUEST_ACCESS}
        status={formState}
        headlineSuccess={I18n.t(T.companyAccount.requestAccess.status.success, {
          caseId: caseNumber,
        })}
        headlineFailure={I18n.t(T.companyAccount.requestAccess.status.failure)}
        onClose={onClose}
        onTryAgain={onTryAgain}
        defaultForm={
          <RequestAccessForm
            onSubmit={onSubmit}
            isLoading={queryStatus.pending}
            userCountry={userCountry}
          />
        }
      />
    </div>
  );
};

export const RequestAccess = connect(mapStateToProps, mapDispatchToProps)(RequestAccessComponent);
