import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Lead } from '+shared/store/lead/types';

import './LeadModalLeadName.component.scss';

interface Props {
  lead?: Lead;
}

export const LeadModalLeadName: React.FC<Props> = ({ lead }) =>
  lead ? (
    <div className={'c-lead-modal-lead-name'}>
      <span className={'c-lead-modal-lead-name__lead-title'} data-hj-suppress={true}>
        {I18n.t(T.lead.overview._salessolution_.multipleDsoModal.lead)}:
      </span>
      <div data-hj-suppress={true}>
        {lead.company ? `${lead.company.name}, ` : ''}
        {lead.firstName ? `${lead.firstName} ` : ''}
        {lead.lastName ? `${lead.lastName} ` : ''}
        <span className={'c-lead-modal-lead-name__lead-address'} data-hj-suppress={true}>
          {lead.deliveryAddress ? (
            <>
              / {lead.deliveryAddress.street ? `${lead.deliveryAddress.street}, ` : ''}
              {lead.deliveryAddress.zipCode && lead.deliveryAddress.city
                ? `${lead.deliveryAddress.zipCode} ${lead.deliveryAddress.city}, `
                : ''}
              {lead.deliveryAddress.country
                ? `${I18n.t(
                    T.lead.boc._salessolution_.dictionary.countries[
                      lead.deliveryAddress.country.toLowerCase()
                    ]
                  )}`
                : ''}
            </>
          ) : (
            ''
          )}
        </span>
      </div>
    </div>
  ) : null;
