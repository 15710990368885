import * as React from 'react';

import { Customer } from '+shared/store/customer';
import { formatTextWithSpecialCharacters } from '+utils/format.util.old';

import { CustomersTableAccessBadge } from '../CustomersTableAccessBadge';

import './CustomersTableName.component.scss';

type Props = {
  customer: Customer;
  consentGiven: boolean;
};

export const CustomersTableName = ({ customer, consentGiven }: Props) => {
  const { name, firstName, lastName } = customer;

  return (
    <div className="c-customers-table-name">
      <div data-hj-suppress={true}>
        {lastName && firstName ? (
          <>
            <div
              className="c-customers-table-name__lastname"
              dangerouslySetInnerHTML={{ __html: formatTextWithSpecialCharacters(lastName) }}
            />
            <div>{firstName}</div>
          </>
        ) : (
          <div>{name}</div>
        )}
      </div>
      {!consentGiven && <CustomersTableAccessBadge />}
    </div>
  );
};
