import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import { User } from '+shared/store/user';
import { getUserEmail, getUserMobile, getUserPhone } from '+shared/store/user/user.helpers';

export const getContactDetailsList = (user: User) => [
  {
    label: I18n.t(T.myAccount.contactDetails.email),
    value: !isNil(getUserEmail(user)) ? getUserEmail(user) : '-',
  },
  {
    label: I18n.t(T.myAccount.contactDetails.mobilePhone),
    value: !isNil(getUserMobile(user)) ? getUserMobile(user) : '-',
  },
  {
    label: I18n.t(T.myAccount.contactDetails.phone),
    value: !isNil(getUserPhone(user)) ? getUserPhone(user) : '-',
  },
];
