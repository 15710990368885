import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Form, Formik } from 'formik';

import { FormInput } from '+shared/components';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { ClaimBatteryAttributes } from '+shared/store/partner/types/claimBattery.interface';

import { FieldNames } from '../../containers/MissingBatteryModal/types';
import { MissingBatteryModalLayout } from '../MissingBatteryModalLayout';
import { MissingBatteryCaseFormValues } from './MissingBatteryCaseForm.interface';
import { missingBatteryCaseSchema } from './MissingBatteryCaseForm.schema';

import './MissingBatteryCaseForm.component.scss';

interface Props {
  claimBatteryData: ClaimBatteryAttributes;
  isQueryPending: boolean;
  hideCaseReporting: () => void;
  onSubmit: (values: MissingBatteryCaseFormValues) => void;
}

export const MissingBatteryCaseForm: React.FC<Props> = ({
  onSubmit,
  hideCaseReporting,
  isQueryPending,
  claimBatteryData: { serialNumber, postalCode, customerLastName, pvPeakPower },
}) => (
  <Formik
    initialValues={{
      serialNumber,
      postalCode,
      customerLastName,
      pvPeakPower: pvPeakPower ? pvPeakPower.toString() : '',
      description: '',
    }}
    validateOnBlur={false}
    validateOnChange={true}
    validationSchema={missingBatteryCaseSchema}
    onSubmit={onSubmit}
    render={(form) => (
      <Form className={'c-missing-battery-case-form'}>
        <MissingBatteryModalLayout
          isLoading={isQueryPending}
          canSubmit={form.isValid}
          buttonLabel={I18n.t(T.missingBattery.createCase.submitButton)}
          goBack={hideCaseReporting}
        >
          <span className={'c-missing-battery-case-form__info'}>
            {I18n.t(T.missingBattery.createCase.headline)}
          </span>
          <FormInput
            form={form}
            name={FieldNames.SERIAL_NUMBER}
            label={I18n.t(T.missingBattery.serialNumber)}
            disabled={true}
          />
          <FormInput
            form={form}
            name={FieldNames.POSTAL_CODE}
            label={I18n.t(T.missingBattery.zipCode)}
            disabled={!!postalCode || isQueryPending}
            isRequired={false}
          />
          <FormInput
            form={form}
            name={FieldNames.LAST_NAME}
            label={I18n.t(T.missingBattery.lastName)}
            disabled={!!customerLastName || isQueryPending}
            isRequired={false}
          />
          <FormInput
            form={form}
            name={FieldNames.PV_POWER}
            label={I18n.t(T.missingBattery.pvSize)}
            type={'number'}
            disabled={!!pvPeakPower || isQueryPending}
            isRequired={false}
          />
          <FormTextarea
            form={form}
            name={FieldNames.DESCRIPTION}
            label={I18n.t(T.missingBattery.description)}
            disabled={isQueryPending}
            placeholder={I18n.t(T.missingBattery.descriptionPlaceholder)}
          />
        </MissingBatteryModalLayout>
      </Form>
    )}
  />
);
