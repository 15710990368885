import { from } from 'rxjs';

import { CustomerListRouteQueryParams } from '+app/router';
import { Config } from '+config/config';
import { CustomerSortKey, CustomerSortOrder } from '+shared/store/customer/types/customerSort.type';

import { jsonApiClient } from '../../network';
import { CustomerData, CustomerMetadata } from './types/customer.interface';

const getCustomerCollection = ({
  search,
  sort = CustomerSortKey.INSTALLATION_DATE,
  page,
}: CustomerListRouteQueryParams) =>
  from(
    jsonApiClient
      .get<CustomerData, CustomerMetadata>(`${Config.API_URL}/customers/`)
      .filter('battery-systems.asset_status', 'installed,registered')
      .filter('query', search)
      .pageNumber(page)
      .sort(sort, CustomerSortOrder[sort])
      .expectMany()
      .resolveIncluded()
      .send()
  );

const putCustomerNote = (id: string, note: string) =>
  from(
    jsonApiClient
      .put(`${Config.API_URL}/customers/${id}`)
      .ofType('customer')
      .withAttributes({
        installer_note: note,
      })
      .expectOne()
      .send()
  );

const getCustomer = (id: string) =>
  from(
    jsonApiClient
      .get<CustomerData>(`${Config.API_URL}/customers/${id}`)
      .expectOne()
      .resolveIncluded()
      .send()
  );

export const CustomerRepository = {
  getCustomerCollection,
  getCustomer,
  putCustomerNote,
};
