import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { MissingBatteryModal } from '+customer/+list/+missingBattery/containers/MissingBatteryModal';
import { HeadlineLarge } from '+shared/basicComponents/Typography/Headlines';
import { PageHeader } from '+shared/components';
import { Button, ButtonSize } from '+shared/components/Button/Button.component';
import { getOpenModalId, LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';

import { Plus } from '../../../../shared/basicComponents/Icons';

import './SitesPageHeader.scss';

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
});

const mapStateToProps = (state: StoreState) => ({
  openModalId: getOpenModalId(state),
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const SitesPageHeaderComponent: React.FC<Props> = ({ actions, openModalId }) => {
  //TODO:  Uncomment and add the tabNavItems to the PageHeader after second NavItem is added
  // const navLinkProps = {
  //   border: false,
  //   type: 'desktop' as NavLinkType,
  // };

  // TODO: @SON-35771: translation key
  // const tabNavItems = (
  //   <>
  //     <NavLink {...navLinkProps} action={PATHS.CUSTOMERS_NEW()}>
  //       <User className="sites-page-header__sites-tab" /> All customers
  //     </NavLink>
  //   </>
  // );

  const buttonIconAndText = (
    <span className="sites-page-header__battery-button">
      <Plus className="button-icon" />
      {I18n.t(T.missingBattery.startProcessButton)}
    </span>
  );

  // TODO(shared-web): NavLink still have references to shared-web
  return (
    <PageHeader tabNav={null}>
      <div className="sites-page-header">
        <HeadlineLarge text={I18n.t(T.dashboard.pageHeadline)} />
        <Button
          label={buttonIconAndText}
          size={ButtonSize.MEDIUM}
          dataTestId="assign-new-battery-button"
          onClick={() => actions.toggleModal(true, ModalId.MISSING_BATTERY)}
        />
      </div>

      {openModalId === ModalId.MISSING_BATTERY && <MissingBatteryModal />}
    </PageHeader>
  );
};
const SitesPageHeader = connect(mapStateToProps, mapDispatchToProps)(SitesPageHeaderComponent);

export default SitesPageHeader;
