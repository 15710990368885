import { isNil } from 'lodash';

export const trim = (strings: TemplateStringsArray, ...values: any[]) => {
  const notNullValues = values.map((value) => (!isNil(value) ? value : ''));

  const interpolation = strings.reduce(
    (prev, current) => prev + current + (notNullValues.length ? notNullValues.shift() : ''),
    ''
  );

  return interpolation.trim();
};

export const snakeToCamel = (value: string) =>
  !isNil(value) ? value.replace(/(\_\w)/g, (m) => m[1].toUpperCase()) : '';

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .replace(/ü/g, 'u')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/ß/g, 's')
    .replace(/[^a-z0-9]+/g, '-');

export const firstToUppercase = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

// Non breaking space (&nbsp;). Learn more: http://www.htmlbasictutor.ca/non-breaking-space.htm
export const NON_BREAKING_SPACE = '\u00a0';

// German special letters - add other languages, when needed.
export const specialLetters = 'äÄöÖüÜßẞ';
