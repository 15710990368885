import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DS } from '@sonnen/shared-web';

import { LeadImpactAnalysisTile } from '+app/+lead/+impactAnalysis/components/LeadImpactAnalysisTile';
import { getFlatProduct, isSentOrDraftOfferExpired } from '+app/+lead/+offer/store/+offer.helper';
import { LeadOverviewVppActionBox } from '+app/+lead/+overview/containers/LeadOverviewVppActionBox';
import { isConfigurationMultiplePv } from '+app/+lead/store/+lead.helper';
import {
  LeadConfigurationTile,
  LeadConfigurationTileProps,
  LeadConfigurationTileStatus,
} from '+lead/components';
import {
  LeadConfigurationResponse,
  LeadImpactAnalysisResponse,
  LeadOffer,
} from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';

import LeadFlatConfigurationTileHardware from './components/LeadFlatConfigurationTileHardware';
import LeadFlatConfigurationTileTariff from './components/LeadFlatConfigurationTileTariff';
import {
  getFlatOfferExpiredAtDate,
  getImpactAnalysisNotAvailableMsg,
  getProductName,
  isImpactAnalysisAllowedForTaxRegulation,
} from './LeadFlatConfigurationTile.helper';

import './LeadFlatConfigurationTile.scss';

interface OwnProps {
  configuration: LeadConfigurationResponse;
  isTileActionDisabled: boolean;
  isAnyOfferAccepted?: boolean;
  impactAnalysis?: LeadImpactAnalysisResponse;
  isDsoBlackListed?: boolean;
  isStatusUpdateDisabled?: boolean;
  hardwareStatus?: LeadConfigurationTileStatus;
  isHardwareProductAccepted?: boolean;
  canConfigurationBeDeleted?: boolean;
  isOfferBlocked?: boolean;
  isComposingOfferDisabled?: boolean;
  offer: LeadOffer;
  productBatteryList: LeadProductBattery[];
  onImpactAnalysisStart?: () => void;
  onImpactAnalysisDownload?: (event: React.MouseEvent<any>) => void;
  onImpactAnalysisDelete?: () => void;
  onImpactAnalysisSend?: () => void;
  onHardwareStatusModalOpen?: (event: React.MouseEvent) => void;
  onRecalculation?: () => void;
  onDeleteExpiredOffer?: () => void;
}

type Props = Omit<
  LeadConfigurationTileProps,
  'isOfferCreationDisabled' | 'creationDate' | 'offerButtonLabel' | 'productName'
> &
  OwnProps;

export const LeadFlatConfigurationTile: React.FC<Props> = ({
  configuration,
  isTileActionDisabled,
  isAnyOfferAccepted,
  impactAnalysis,
  isDsoBlackListed = false,
  status = LeadConfigurationTileStatus.PREVIEW,
  isStatusUpdateDisabled,
  hardwareStatus = LeadConfigurationTileStatus.PREVIEW,
  isHardwareProductAccepted = false,
  canConfigurationBeDeleted = false,
  isOfferBlocked = false,
  isComposingOfferDisabled = false,
  productBatteryList,
  onImpactAnalysisStart,
  onImpactAnalysisDelete,
  onImpactAnalysisDownload,
  onImpactAnalysisSend,
  onHardwareStatusModalOpen,
  offer,
  onRecalculation,
  onDeleteExpiredOffer,
  ...restTileProps
}) => {
  const isOfferSentOrAccepted = () =>
    [LeadConfigurationTileStatus.SENT, LeadConfigurationTileStatus.ACCEPTED].includes(status);

  const battery = productBatteryList.find(
    (battery) => battery.batteryConfigurationId === configuration.battery.batteryConfigurationId
  )!;

  const isVppCheckDone = configuration.vppCheckDone || isOfferSentOrAccepted();
  const { onCompose } = restTileProps;
  const shouldDisplayVppBonusCheck =
    onCompose && (configuration.powerPlant.vppMeteringRecommended || isVppCheckDone);

  const isFlatOfferCreationDisabled =
    isComposingOfferDisabled || (!!shouldDisplayVppBonusCheck && !isVppCheckDone);

  const isIaCreationDisabled: boolean =
    (isAnyOfferAccepted && !(impactAnalysis && impactAnalysis.status === 'sent')) ||
    (!!shouldDisplayVppBonusCheck && !isVppCheckDone) ||
    isSentOrDraftOfferExpired(offer) ||
    !isImpactAnalysisAllowedForTaxRegulation(configuration) ||
    isDsoBlackListed ||
    isConfigurationMultiplePv(configuration) ||
    isOfferBlocked ||
    // SON-37623: Block IA for offers created before release without taxRegulation. Remove later
    !offer.taxRegulation;

  const shouldDisplayImpactAnalysisTile = [
    onImpactAnalysisStart,
    onImpactAnalysisDelete,
    onImpactAnalysisDownload,
    onImpactAnalysisSend,
  ].some((action) => action !== undefined);

  return (
    <div className={'c-lead-flat-configuration-tile'}>
      <LeadConfigurationTile
        creationDate={configuration.createdAt}
        isOfferCreationDisabled={isFlatOfferCreationDisabled}
        isTileActionDisabled={isTileActionDisabled || isHardwareProductAccepted}
        offerButtonLabel={I18n.t(T.lead.overview._salessolution_.composeAnOffer)}
        productName={getProductName(configuration, offer)}
        status={status} // @Future: change place of displaying status
        hasDeletionWarning={status === LeadConfigurationTileStatus.SENT}
        offerId={offer?.id}
        expirationDate={getFlatOfferExpiredAtDate(getFlatProduct(offer))}
        {...restTileProps}
      >
        <div className={'c-lead-flat-configuration-tile__body'}>
          <LeadFlatConfigurationTileTariff
            configuration={configuration}
            status={status}
            isHardwareProductAccepted={isHardwareProductAccepted}
            isOfferBlocked={isOfferBlocked}
            leadOfferTaxRegulation={offer.taxRegulation}
            onRecalculation={onRecalculation}
            onDeleteExpiredOffer={onDeleteExpiredOffer}
          />
        </div>
        <div className={'c-lead-flat-configuration-tile__body'}>
          <LeadFlatConfigurationTileHardware
            configuration={configuration}
            hardwareStatus={hardwareStatus}
            isStatusUpdateDisabled={isStatusUpdateDisabled}
            battery={battery}
            offer={offer}
            onHardwareStatusModalOpen={onHardwareStatusModalOpen}
          />
        </div>

        {shouldDisplayVppBonusCheck && (
          <LeadOverviewVppActionBox
            isVppCheckDone={isVppCheckDone}
            isConfigurationTileDisabled={isComposingOfferDisabled}
            offer={offer}
          />
        )}
        {shouldDisplayImpactAnalysisTile && (
          <LeadImpactAnalysisTile
            impactAnalysis={impactAnalysis}
            onStart={onImpactAnalysisStart}
            onDelete={onImpactAnalysisDelete}
            onDownload={onImpactAnalysisDownload}
            onSend={onImpactAnalysisSend}
            isSendable={[
              LeadConfigurationTileStatus.SENT,
              LeadConfigurationTileStatus.ACCEPTED,
            ].includes(status)}
            isDisabled={isIaCreationDisabled}
            isOfferAccepted={status === LeadConfigurationTileStatus.ACCEPTED}
            warningText={getImpactAnalysisNotAvailableMsg(offer, configuration)}
            offerId={offer.id}
          />
        )}
      </LeadConfigurationTile>

      {shouldDisplayVppBonusCheck &&
        !isVppCheckDone &&
        !isTileActionDisabled &&
        !isOfferBlocked &&
        !isSentOrDraftOfferExpired(offer) && (
          <div className={'c-lead-flat-configuration-tile__vpp-warning'}>
            <DS.Alert
              theme={DS.AlertTheme.WARNING}
              text={I18n.t(T.lead.overview._salessolution_.fillVppWarning)}
            />
          </div>
        )}
    </div>
  );
};
