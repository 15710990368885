import { BatteryOperatingMode } from '+shared/store/site/types';

export interface BatteryOperatingModeForm {
  operatingMode: BatteryOperatingMode | undefined;
}

export enum BatteryOperatingModeFormKeys {
  OPERATING_MODE = 'operatingMode',
}

export const ALLOWED_SOFTWARE_VERSION = '1.6.24';
