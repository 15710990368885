import { CUSTOMER_ACTIONS, CustomerActions } from '+customer/store/+customer.actions';
import { queryReducer } from '+shared/store/query/index';
import { createPersistedReducer, updateCollection } from '+utils/redux';

import { CUSTOMER_LIST_PAGE_ACTIONS, CustomerListPageActions } from './+customerList.actions';
import { CustomerListPageState, GET_COLLECTION_QUERY, initialState } from './+customerList.state';

export const reducer = createPersistedReducer<
  CustomerListPageState,
  CustomerListPageActions | CustomerActions
>((state = initialState, action): CustomerListPageState => {
  switch (action.type) {
    case CUSTOMER_LIST_PAGE_ACTIONS.SET_CUSTOMER_COLLECTION:
      return {
        ...state,
        collection: action.collection,
      };
    case CUSTOMER_LIST_PAGE_ACTIONS.SET_INSTALLED_BATTERIES_COUNT:
      return {
        ...state,
        installedBatteriesCount: action.count.installed + action.count.registered,
      };
    case CUSTOMER_LIST_PAGE_ACTIONS.SET_TOTAL_RESOURCE_COUNT:
      return {
        ...state,
        totalResourceCount: action.count,
      };
    case CUSTOMER_ACTIONS.SET_CUSTOMER_NOTE:
      return updateCollection(
        state,
        ['collection', action.customer.id, 'installerNote'],
        action.customer.installerNote
      );
    default:
      return queryReducer(state, action, [GET_COLLECTION_QUERY], { suppressResponse: true });
  }
})({
  key: 'customer',
});
