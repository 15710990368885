import { __extends } from "tslib";
import { Line, Paint } from '@kanva/core';
import { LabelsHelper } from '../utils';
import { ChartView } from './chart.view';
var defaultStyle = {
    paints: {},
    backgroundPaint: new Paint()
        .setLineWidth(1.5),
    innerRadius: 0,
    padding: 0,
};
var PieChartView = (function (_super) {
    __extends(PieChartView, _super);
    function PieChartView(context) {
        var _this = _super.call(this, context, 'PieChartView', defaultStyle) || this;
        _this.labelsHelper = new LabelsHelper();
        _this.pieFragments = [];
        return _this;
    }
    PieChartView.prototype.getLabelOptions = function () {
        return this.labelsHelper.getOptions();
    };
    PieChartView.prototype.setLabelOptions = function (labels) {
        this.labelsHelper.setOptions(labels);
    };
    PieChartView.prototype.onLayout = function () {
        var _a = this, innerWidth = _a.innerWidth, innerHeight = _a.innerHeight, dataContainer = _a.dataContainer, style = _a.style;
        if (!dataContainer) {
            return;
        }
        var allSeries = dataContainer.getAllDataSeries();
        var total = dataContainer.getTotal();
        var centerX = innerWidth / 2;
        var centerY = innerHeight / 2;
        var radius = Math.min(centerX, centerY);
        var inner = this.style.innerRadius || 0;
        var outerRadius = Math.max(0, radius - (style.backgroundPaint ? style.backgroundPaint.lineWidth / 2 : 0));
        var innerRadius = Math.max(0, inner <= 1 ? inner * outerRadius : inner);
        var pi2 = Math.PI * 2;
        var padding = this.style.padding || 0;
        var maxRingThickness = this.getMaxRingThickness(allSeries);
        var background = this.getBackgroundLayout(maxRingThickness, innerRadius, outerRadius);
        this.pieLayout = {
            centerX: centerX,
            centerY: centerY,
            background: background,
        };
        var pad = padding * 0.5 * pi2;
        var angle = -.25 * pi2;
        this.pieFragments = allSeries.map(function (series) {
            var paint = style.paints[series.name] || Paint.DEFAULT;
            var start = angle;
            var slice = series.sum / total;
            var end = start + slice * pi2;
            var halfLineThickness = (paint.lineWidth || 0) / 2;
            var outerRadius = Math.max(0, radius - halfLineThickness);
            var ringThickness = paint.lineWidth || 0;
            var ringShift = (maxRingThickness - ringThickness) / 2;
            var maxPad = Math.min((end - start) / 4, pad);
            var outer = Math.max(0, outerRadius - ringShift);
            var inner = Math.min(outer, Math.max(0, innerRadius - ringShift));
            var centerAngle = (start + end) / 2;
            angle = end;
            return {
                paint: paint,
                value: slice,
                inner: inner > 0 ? {
                    radius: inner,
                    startAngle: end - maxPad,
                    endAngle: start + maxPad,
                } : undefined,
                outer: {
                    radius: outer,
                    startAngle: start + maxPad,
                    endAngle: end - maxPad,
                },
                labelLine: new Line(centerX + Math.cos(centerAngle) * inner, centerY + Math.sin(centerAngle) * inner, centerX + Math.cos(centerAngle) * outer, centerY + Math.sin(centerAngle) * outer),
            };
        });
    };
    PieChartView.prototype.onDraw = function (canvas) {
        var _a = this, layout = _a.pieLayout, pieFragments = _a.pieFragments, style = _a.style;
        var ctx = canvas.context;
        if (!layout || !pieFragments.length) {
            return;
        }
        var centerX = layout.centerX, centerY = layout.centerY, background = layout.background;
        if (background && style.backgroundPaint) {
            var inner = background.inner, outer = background.outer;
            ctx.beginPath();
            if (inner > 0) {
                ctx.arc(centerX, centerY, inner, Math.PI * 2, 0, true);
            }
            else {
                ctx.moveTo(centerX, centerY);
            }
            ctx.arc(centerX, centerY, outer, 0, Math.PI * 2, false);
            ctx.closePath();
            canvas.drawPath(style.backgroundPaint);
        }
        for (var i = 0, l = this.pieFragments.length; i < l; i++) {
            var _b = pieFragments[i], inner = _b.inner, outer = _b.outer, paint = _b.paint;
            ctx.beginPath();
            if (inner) {
                ctx.arc(centerX, centerY, inner.radius, inner.startAngle, inner.endAngle, true);
            }
            else {
                ctx.moveTo(centerX, centerY);
            }
            ctx.arc(centerX, centerY, outer.radius, outer.startAngle, outer.endAngle, false);
            ctx.closePath();
            canvas.drawPath(paint);
        }
        if (this.getLabelOptions()) {
            for (var i = 0, l = this.pieFragments.length; i < l; i++) {
                var _c = pieFragments[i], labelLine = _c.labelLine, value = _c.value, paint = _c.paint;
                this.labelsHelper.draw(canvas, value, i, labelLine, paint);
            }
        }
    };
    PieChartView.prototype.getCanvasPositionForPoint = function () {
        return { x: 0, y: 0, absoluteX: 0, absoluteY: 0 };
    };
    PieChartView.prototype.getPointForCanvasPosition = function () {
        return { x: 0, y: 0 };
    };
    PieChartView.prototype.getMaxRingThickness = function (allSeries) {
        var maxRingThickness = 0;
        for (var i = 0, l = allSeries.length; i < l; i++) {
            var s = this.style.paints[allSeries[i].name] || Paint.DEFAULT;
            var lineThickness = s.lineWidth || 0;
            if (maxRingThickness < lineThickness) {
                maxRingThickness = lineThickness;
            }
        }
        return maxRingThickness;
    };
    PieChartView.prototype.getBackgroundLayout = function (maxRingThickness, innerRadius, outerRadius) {
        var style = this.style;
        if (style.backgroundPaint) {
            var ringThickness = style.backgroundPaint.lineWidth || 0;
            var ringShift = (maxRingThickness - ringThickness) / 2;
            var outer = Math.max(0, outerRadius - ringShift);
            var inner = Math.min(outer, Math.max(0, innerRadius - ringShift));
            return {
                outer: outer,
                inner: inner,
            };
        }
        return undefined;
    };
    return PieChartView;
}(ChartView));
export { PieChartView };
