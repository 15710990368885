import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, SideInfo } from '@sonnen/shared-web';

import { Form, Formik, FormikProps } from 'formik';

import { VppDocumentationFormTestIds as testIds } from '+config/testIds';
import {
  SignalMeasurements,
  VppDocumentationAgreements,
  VppDocumentationData,
} from '+setupTool/+vppDocumentation/components';
import {
  getVppDocumentationInitialValues,
  getVppDocumentationSchema,
  VppDocumentationInterface,
} from '+setupTool/+vppDocumentation/store';
import { useAdditionalFeatures } from '+setupTool/store/+setupTool.hooks';
import { Button, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { getStatus } from '+shared/store/query/query.utils';

import './VppDocumentationForm.component.scss';

interface Props {
  formRef: React.MutableRefObject<FormikProps<VppDocumentationInterface>>;
  fields: VppDocumentationInterface;
  isVppSubmittable: boolean;
  isLoading: boolean;
  meterPreparationPicturesDocumentSubmitQueryStatus: ReturnType<typeof getStatus>;
  signalMeasurementsPictureSubmitQueryStatus: ReturnType<typeof getStatus>;
  lanConnectionPictureSubmitQueryStatus: ReturnType<typeof getStatus>;
  wiringDiagramDocumentSubmitQueryStatus: ReturnType<typeof getStatus>;
  onSave: () => void;
  onSubmit: (values: VppDocumentationInterface) => void;
}

export const VppDocumentationForm: React.FC<Props> = ({
  formRef,
  fields,
  isVppSubmittable,
  isLoading,
  meterPreparationPicturesDocumentSubmitQueryStatus,
  signalMeasurementsPictureSubmitQueryStatus,
  lanConnectionPictureSubmitQueryStatus,
  wiringDiagramDocumentSubmitQueryStatus,
  onSave,
  onSubmit,
}) => {
  const {
    additionalFeatures,
    isVppCompletionNotificationVisible,
    isDsoReferenceNumberPvFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    isMeterCabinetPreparedFieldVisible,
    isProductionMeterFieldVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
    isVppWiringDiagramVisible,
  } = useAdditionalFeatures();

  const vppDocumentationInitialValues = getVppDocumentationInitialValues(
    isDsoReferenceNumberPvFieldVisible
  );
  const vppDocumentationValidationSchema = getVppDocumentationSchema(
    additionalFeatures,
    !!fields.virtual_pv_system
  );

  return (
    <>
      <div className={'c-overlay__inner-content c-setup-tool__help-content'}>
        {/* todo unify SideInfo for register and commissioning */}
        <SideInfo className={'c-help__side-info'}>
          <div className={`c-help__side-info-icon`}>
            <Icon.Info />
          </div>
          <div>
            <h3>{I18n.t(T.setupTool.vppDocumentation.help.header)}</h3>
            <p>
              <Translate value={T.setupTool.vppDocumentation.help.text} dangerousHTML={true} />
            </p>
            <br />
          </div>
        </SideInfo>
      </div>

      <div className={'c-vpp-documentation__container'}>
        <Formik
          innerRef={formRef}
          initialValues={vppDocumentationInitialValues}
          validationSchema={vppDocumentationValidationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <VppDocumentationData
                fields={fields}
                isProductionMeterFieldVisible={isProductionMeterFieldVisible}
                isFasteningTypeConsumptionFieldVisible={isFasteningTypeConsumptionFieldVisible}
                isDsoReferenceNumberPvFieldVisible={isDsoReferenceNumberPvFieldVisible}
                isCompletionNotificationVisible={isVppCompletionNotificationVisible}
                isGsmMeasurementsRequiredFieldsVisible={isGsmMeasurementsRequiredFieldsVisible}
                isGsmMeasurementsOptionalFieldsVisible={isGsmMeasurementsOptionalFieldsVisible}
                isMeterPreparationPictureLoading={
                  meterPreparationPicturesDocumentSubmitQueryStatus.pending
                }
              />

              <SignalMeasurements
                isWiringDiagramVisible={isVppWiringDiagramVisible}
                isGsmMeasurementsRequiredFieldsVisible={isGsmMeasurementsRequiredFieldsVisible}
                isGsmMeasurementsOptionalFieldsVisible={isGsmMeasurementsOptionalFieldsVisible}
                isSignalMeasurementsPictureLoading={
                  signalMeasurementsPictureSubmitQueryStatus.pending
                }
                isLanConnectionPictureLoading={lanConnectionPictureSubmitQueryStatus.pending}
                isWiringDiagramDocumentLoading={wiringDiagramDocumentSubmitQueryStatus.pending}
              />

              <VppDocumentationAgreements
                isMeterCabinetPreparedFieldVisible={isMeterCabinetPreparedFieldVisible}
              />

              <div className={'c-vpp-documentation-form__action-buttons-container'}>
                <Button
                  className={'c-vpp-documentation-form__save-button'}
                  mainType={MainType.BUTTON}
                  label={I18n.t(T.setupTool.vppDocumentation.form.save)}
                  onClick={onSave}
                  type={ButtonType.SECONDARY}
                  dataTestId={testIds.saveButton}
                />
                <Button
                  mainType={MainType.SUBMIT}
                  label={I18n.t(T.setupTool.vppDocumentation.submit)}
                  disabled={!isVppSubmittable}
                  status={!isVppSubmittable ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                  loading={isLoading}
                  dataTestId={testIds.sendDocumentationButton}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
