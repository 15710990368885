import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { debounceTime, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { CustomerActions } from '+customer/store/+customer.actions';
import { isLocationChangeFromPath } from '+router/store/router.selectors';
import { ChargerActions } from '+shared/store/charger/charger.actions';
import { GET_SITE_LIVE_STATE_V2_QUERY, SiteActions } from '+shared/store/site';
import { SiteRepository } from '+shared/store/site/site.repository';
import { StoreState } from '+shared/store/store.interface';

import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { dataGuard, makeQuery, mapPathToParams, mapToState, ofType, polling } from '../../../utils';
import { OverviewActions } from './+overview.actions';
import { getChargerId } from './+overview.selector';
import { GET_CHARGERS_QUERY, GET_OVERVIEW_SITE_LIVE_STATE_QUERY } from './+overview.state';

type Action$ = ActionsObservable<OverviewActions>;
type State$ = StateObservable<StoreState>;

export const getChargerList$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    mapToState(state$),
    mapPathToParams(ROUTES.CUSTOMER[0]),
    map(([_, siteId]) =>
      SiteActions.getSiteChargerList({
        queryKey: GET_CHARGERS_QUERY,
        siteId,
      })
    )
  );

export const getChargerLiveState$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSiteChargerList),
    mapToState(state$),
    mergeMap((state) =>
      of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER[0]),
        map(() => getChargerId(state)),
        map((chargerId) =>
          chargerId
            ? ChargerActions.getChargerLiveState({
                queryKey: GET_CHARGERS_QUERY,
                chargerId,
              })
            : ChargerActions.stopCharger()
        )
      )
    )
  );

const getHeatPumpDataOnOverview$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    mapToState(state$),
    mapPathToParams(ROUTES.CUSTOMER[0]),
    map(([_, siteId]) =>
      SiteActions.getSiteLiveStateV2({
        queryKey: GET_SITE_LIVE_STATE_V2_QUERY,
        siteId,
      })
    )
  );

const triggerOverviewLiveStatePolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    debounceTime(5000),
    withLatestFrom(state$),
    mergeMap(([_, state]) =>
      of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER[0]),
        mergeMap(([_, siteId]) =>
          of(siteId).pipe(map(() => OverviewActions.startOverviewLiveStatePolling(siteId)))
        )
      )
    )
  );

const startOverviewLiveStatePolling$ = (action$: Action$) =>
  action$.pipe(
    polling({
      startOn: OverviewActions.startOverviewLiveStatePolling,
      stopOn: [OverviewActions.stopOverviewLiveStatePolling, CustomerActions.clearCustomerData],
      interval: 5000,
      // TODO: pass this queryKey as parameter if possible
    })(({ siteId }) =>
      makeQuery(GET_OVERVIEW_SITE_LIVE_STATE_QUERY)({
        call: () => SiteRepository.getSiteLiveState(siteId),
        onSuccess: (res) => dataGuard(OverviewActions.setOverviewLiveState)(res.element),
      })
    )
  );

const stopAnalysisPolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange),
    mapToState(state$),
    map(isLocationChangeFromPath(ROUTES.CUSTOMER)),
    filter((isMatch) => !!isMatch),
    map(() => OverviewActions.stopOverviewLiveStatePolling())
  );

export const epics = combineEpics(
  triggerOverviewLiveStatePolling$,
  startOverviewLiveStatePolling$,
  stopAnalysisPolling$,
  getChargerList$,
  getChargerLiveState$,
  getHeatPumpDataOnOverview$
);
