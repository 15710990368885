import { Rect } from '../canvas';
import { parseDimension, WRAP_CONTENT } from '../canvas/dimension';
export var PARENT_ID = -1;
var DefaultProps = {
    BELOW: undefined,
    ABOVE: undefined,
    TO_START_OF: undefined,
    TO_END_OF: undefined,
    ALIGN_TOP: undefined,
    ALIGN_BOTTOM: undefined,
    ALIGN_START: undefined,
    ALIGN_END: undefined,
    CENTER_VERTICAL: false,
    CENTER_HORIZONTAL: false,
    WIDTH: WRAP_CONTENT,
    MIN_WIDTH: 0,
    MAX_WIDTH: Number.MAX_SAFE_INTEGER,
    HEIGHT: WRAP_CONTENT,
    MIN_HEIGHT: 0,
    MAX_HEIGHT: Number.MAX_SAFE_INTEGER,
    IS_ANIMATED: false,
    PADDING: new Rect(0),
    MARGIN: new Rect(0),
    IS_ABSOLUTE: false,
    X: 0,
    Y: 0,
};
var LayoutParams = (function () {
    function LayoutParams() {
        this.belowId = DefaultProps.BELOW;
        this.aboveId = DefaultProps.ABOVE;
        this.toStartOfId = DefaultProps.TO_START_OF;
        this.toEndOfId = DefaultProps.TO_END_OF;
        this.topId = DefaultProps.ALIGN_TOP;
        this.bottomId = DefaultProps.ALIGN_BOTTOM;
        this.startId = DefaultProps.ALIGN_START;
        this.endId = DefaultProps.ALIGN_END;
        this.centerV = DefaultProps.CENTER_VERTICAL;
        this.centerH = DefaultProps.CENTER_HORIZONTAL;
        this.x = DefaultProps.X;
        this.y = DefaultProps.Y;
        this.w = DefaultProps.WIDTH;
        this.h = DefaultProps.HEIGHT;
        this.xDimension = parseDimension(DefaultProps.X);
        this.yDimension = parseDimension(DefaultProps.Y);
        this.wDimension = parseDimension(DefaultProps.WIDTH);
        this.hDimension = parseDimension(DefaultProps.HEIGHT);
        this.minW = DefaultProps.MIN_WIDTH;
        this.minH = DefaultProps.MIN_HEIGHT;
        this.maxW = DefaultProps.MAX_WIDTH;
        this.maxH = DefaultProps.MAX_HEIGHT;
        this.paddingRect = DefaultProps.PADDING;
        this.marginRect = DefaultProps.MARGIN;
        this.isAnimated = DefaultProps.IS_ANIMATED;
        this.isAbsolute = DefaultProps.IS_ABSOLUTE;
        this.dependenciesModified = false;
    }
    LayoutParams.prototype.below = function (id) {
        if (this.belowId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.belowId = id;
        return this;
    };
    LayoutParams.prototype.above = function (id) {
        if (this.aboveId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.aboveId = id;
        return this;
    };
    LayoutParams.prototype.toStartOf = function (id) {
        if (this.toStartOfId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.toStartOfId = id;
        return this;
    };
    LayoutParams.prototype.toLeftOf = function (id) {
        return this.toStartOf(id);
    };
    LayoutParams.prototype.toEndOf = function (id) {
        if (this.toEndOfId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.toEndOfId = id;
        return this;
    };
    LayoutParams.prototype.toRightOf = function (id) {
        return this.toEndOf(id);
    };
    LayoutParams.prototype.alignTop = function (id) {
        if (this.topId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.topId = id;
        return this;
    };
    LayoutParams.prototype.alignParentTop = function () {
        return this.alignTop(PARENT_ID);
    };
    LayoutParams.prototype.alignBottom = function (id) {
        if (this.bottomId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.bottomId = id;
        return this;
    };
    LayoutParams.prototype.alignParentBottom = function () {
        return this.alignBottom(PARENT_ID);
    };
    LayoutParams.prototype.alignStart = function (id) {
        if (this.startId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.startId = id;
        return this;
    };
    LayoutParams.prototype.alignParentStart = function () {
        return this.alignStart(PARENT_ID);
    };
    LayoutParams.prototype.alignEnd = function (id) {
        if (this.endId === id) {
            return this;
        }
        this.dependenciesModified = true;
        this.endId = id;
        return this;
    };
    LayoutParams.prototype.alignParentEnd = function () {
        return this.alignEnd(PARENT_ID);
    };
    LayoutParams.prototype.center = function (center) {
        return this
            .centerHorizontal(center)
            .centerVertical(center);
    };
    LayoutParams.prototype.centerVertical = function (center) {
        this.centerV = center !== false;
        return this;
    };
    LayoutParams.prototype.centerHorizontal = function (center) {
        this.centerH = center !== false;
        return this;
    };
    LayoutParams.prototype.width = function (width) {
        if (this.w !== width) {
            this.w = width;
            this.wDimension = parseDimension(width);
        }
        return this;
    };
    LayoutParams.prototype.minWidth = function (minWidth) {
        this.minH = minWidth;
        return this;
    };
    LayoutParams.prototype.maxWidth = function (maxWidth) {
        this.maxH = maxWidth;
        return this;
    };
    LayoutParams.prototype.height = function (height) {
        if (this.h !== height) {
            this.h = height;
            this.hDimension = parseDimension(height);
        }
        return this;
    };
    LayoutParams.prototype.minHeight = function (minHeight) {
        this.minH = minHeight;
        return this;
    };
    LayoutParams.prototype.maxHeight = function (maxHeight) {
        this.maxH = maxHeight;
        return this;
    };
    LayoutParams.prototype.animate = function (animate) {
        this.isAnimated = animate !== false;
        return this;
    };
    LayoutParams.prototype.padding = function (padding) {
        this.paddingRect = Rect.from(padding || 0);
        return this;
    };
    LayoutParams.prototype.margin = function (margin) {
        this.marginRect = Rect.from(margin || 0);
        return this;
    };
    LayoutParams.prototype.absolute = function (absolute) {
        this.isAbsolute = absolute !== false;
        return this;
    };
    LayoutParams.prototype.posX = function (x) {
        if (this.x !== x) {
            this.x = x;
            this.xDimension = parseDimension(x);
        }
        return this;
    };
    LayoutParams.prototype.posY = function (y) {
        if (this.x !== y) {
            this.y = y;
            this.yDimension = parseDimension(y);
        }
        return this;
    };
    LayoutParams.prototype.asProps = function () {
        return {
            below: this.belowId,
            above: this.aboveId,
            toStartOf: this.toStartOfId,
            toEndOf: this.toEndOfId,
            alignTop: this.topId,
            alignBottom: this.bottomId,
            alignStart: this.startId,
            alignEnd: this.endId,
            centerVertical: this.centerV,
            centerHorizontal: this.centerH,
            width: this.w,
            minWidth: this.minW,
            maxWidth: this.maxW,
            height: this.h,
            minHeight: this.minH,
            maxHeight: this.maxH,
            isAnimated: this.isAnimated,
            padding: this.paddingRect,
            margin: this.marginRect,
            isAbsolute: this.isAbsolute,
            posX: this.x,
            posY: this.y,
        };
    };
    LayoutParams.prototype.updateWithProps = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.below, below = _c === void 0 ? DefaultProps.BELOW : _c, _d = _b.above, above = _d === void 0 ? DefaultProps.ABOVE : _d, _e = _b.toStartOf, toStartOf = _e === void 0 ? DefaultProps.TO_START_OF : _e, _f = _b.toEndOf, toEndOf = _f === void 0 ? DefaultProps.TO_END_OF : _f, _g = _b.alignTop, alignTop = _g === void 0 ? DefaultProps.ALIGN_TOP : _g, _h = _b.alignBottom, alignBottom = _h === void 0 ? DefaultProps.ALIGN_BOTTOM : _h, _j = _b.alignStart, alignStart = _j === void 0 ? DefaultProps.ALIGN_START : _j, _k = _b.alignEnd, alignEnd = _k === void 0 ? DefaultProps.ALIGN_END : _k, _l = _b.centerVertical, centerVertical = _l === void 0 ? DefaultProps.CENTER_VERTICAL : _l, _m = _b.centerHorizontal, centerHorizontal = _m === void 0 ? DefaultProps.CENTER_HORIZONTAL : _m, _o = _b.width, width = _o === void 0 ? DefaultProps.WIDTH : _o, _p = _b.minWidth, minWidth = _p === void 0 ? DefaultProps.MIN_WIDTH : _p, _q = _b.maxWidth, maxWidth = _q === void 0 ? DefaultProps.MAX_WIDTH : _q, _r = _b.height, height = _r === void 0 ? DefaultProps.HEIGHT : _r, _s = _b.minHeight, minHeight = _s === void 0 ? DefaultProps.MIN_HEIGHT : _s, _t = _b.maxHeight, maxHeight = _t === void 0 ? DefaultProps.MAX_HEIGHT : _t, _u = _b.isAnimated, isAnimated = _u === void 0 ? DefaultProps.IS_ANIMATED : _u, _v = _b.padding, padding = _v === void 0 ? DefaultProps.PADDING : _v, _w = _b.margin, margin = _w === void 0 ? DefaultProps.MARGIN : _w, _x = _b.isAbsolute, isAbsolute = _x === void 0 ? DefaultProps.IS_ABSOLUTE : _x, _y = _b.posX, posX = _y === void 0 ? DefaultProps.X : _y, _z = _b.posY, posY = _z === void 0 ? DefaultProps.Y : _z;
        var oldProps = this.props || this.asProps();
        var props = {
            below: below,
            above: above,
            toStartOf: toStartOf,
            toEndOf: toEndOf,
            alignTop: alignTop,
            alignBottom: alignBottom,
            alignStart: alignStart,
            alignEnd: alignEnd,
            centerVertical: centerVertical,
            centerHorizontal: centerHorizontal,
            width: width,
            minWidth: minWidth,
            maxWidth: maxWidth,
            height: height,
            minHeight: minHeight,
            maxHeight: maxHeight,
            padding: padding,
            margin: margin,
            isAnimated: isAnimated,
            isAbsolute: isAbsolute,
            posX: posX,
            posY: posY,
        };
        var modified = false;
        var keys = Object.keys(props);
        for (var i = 0, l = keys.length; i < l; i++) {
            var key = keys[i];
            var value = props[key];
            var oldValue = oldProps[key];
            if (value !== oldValue) {
                this[key](value);
                modified = true;
            }
        }
        this.props = props;
        return modified;
    };
    return LayoutParams;
}());
export { LayoutParams };
export function createLayoutMap(t) {
    return t;
}
