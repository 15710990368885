import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';

import { FormikProps } from 'formik';

import { BatteryAccessoryInfo, LeadOffer, LeadProductType } from '+shared/store/lead/types';
import {
  LeadProductAccessoryAttributes,
  LeadProductBattery,
} from '+shared/store/lead/types/leadProductBattery.interface';

import { LeadConfigurationBattery } from '../../components/LeadConfigurationBattery';
import { LeadConfigurationInputsWithRecommendation, LeadConfigurationPv } from '../../containers';
import {
  BatteriesWithAccessories,
  formFields,
  shouldSkipRecommendation,
} from '../../containers/LeadConfigurationForm/LeadConfigurationForm.helper';
import { ConfigurationForm } from '../../store/types';
import { LeadConfigurationProtect } from '../LeadConfigurationProtect';

import './LeadConfigurationHardware.component.scss';

interface Props {
  form: FormikProps<ConfigurationForm>;
  productBatteryList: LeadProductBattery[];
  // this one is when we're adding flat tariff to an existing offer
  existingOffer?: LeadOffer;
  // here we're creating another bundle offer while HW only was previously accepted/confirmed
  previouslyAcceptedHardwareOffer?: LeadOffer;
  isHardwareAlreadySold?: boolean;
  flatType: LeadProductType;
  shouldSetMultiplePv: boolean;
  batteriesWithAccessories: {};
  updateBatteriesWithAccessories: (arg: BatteriesWithAccessories) => void;
}

export const LeadConfigurationHardware: React.FC<Props> = ({
  form,
  productBatteryList,
  existingOffer,
  previouslyAcceptedHardwareOffer,
  isHardwareAlreadySold,
  flatType,
  shouldSetMultiplePv,
  batteriesWithAccessories,
  updateBatteriesWithAccessories,
}) => {
  const {
    multiplePvHeadline,
    pvAndCapacity,
    pleaseWaitForRecommendation,
    title,
    pvSystem,
    sonnenBattery,
  } = T.lead.configuration._salessolution_.hardware;

  const [selectedBatteryHasAccessories, setSelectedBatteryHasAccessories] = useState(true);
  const { modelName, multiplePv, protectInstalled, protectMode } = form.values;

  const isPerformanceBattery = modelName.includes(BatteryAccessoryInfo.PERFORMANCE);
  const isProtectModeAC = protectMode === BatteryAccessoryInfo.AC_ISLAND;
  const isProtectModeBuffer = protectMode === BatteryAccessoryInfo.BACKUP_BUFFER;

  useEffect(() => {
    // Set multiplePV setting based on info we got from being redirected to configuration view
    form.setFieldValue(formFields.MULTIPLE_PV, shouldSetMultiplePv);

    const batteries: BatteriesWithAccessories = {};

    productBatteryList.forEach((battery) => {
      if (battery.modelName === modelName) {
        batteries[battery.id] = {
          accessories: battery.accessory ?? [],
          idSelectedAccessory: '',
        };
      }
    });

    updateBatteriesWithAccessories(batteries);
  }, []);

  useEffect(() => {
    // reset default value for "is sonnenProtect installed" question if user changes battery model
    form.setFieldValue(formFields.PROTECT_INSTALLED, false);

    /* Background
     * - we know that older batteries (eco8,7,6) do not have accessories,
     * so batteryHasAccessories is always false for these models
     * - only SB10AC and SB10AC Performance have accessories
     * - however, this generic filtering is done in case at some point the product catalogue
     * might expand with other battery models
     */
    let batteryHasAccessories = true;
    const batteries: BatteriesWithAccessories = {};

    productBatteryList.forEach((battery) => {
      if (battery.modelName === modelName) {
        batteries[battery.id] = {
          accessories: battery.accessory ?? [],
          idSelectedAccessory: '',
        };

        batteryHasAccessories = Boolean(batteries[battery.id].accessories.length);
      }
    });

    setSelectedBatteryHasAccessories(batteryHasAccessories);
    updateBatteriesWithAccessories(batteries);
  }, [modelName]);

  useEffect(() => {
    if (protectInstalled && !isPerformanceBattery) {
      const batteries: BatteriesWithAccessories = { ...batteriesWithAccessories };

      Object.keys(batteries).forEach((batteryId) => {
        batteries[batteryId].idSelectedAccessory = batteries[batteryId].accessories[0].id;
      });

      updateBatteriesWithAccessories(batteries);
    }
  }, [protectInstalled]);

  useEffect(() => {
    if (protectInstalled) {
      const batteries = { ...batteriesWithAccessories };

      Object.keys(batteries).forEach((batteryId) => {
        if (isProtectModeAC) {
          const selectedAccessory = batteries[batteryId].accessories.find(
            (accessory: LeadProductAccessoryAttributes) =>
              accessory.parameters.operatingMode === BatteryAccessoryInfo.AC_ISLAND
          );
          batteries[batteryId].idSelectedAccessory = selectedAccessory.id;
        }
        if (isProtectModeBuffer) {
          const selectedAccessory = batteries[batteryId].accessories.find(
            (accessory: LeadProductAccessoryAttributes) =>
              accessory.parameters.operatingMode === BatteryAccessoryInfo.BACKUP_BUFFER
          );
          batteries[batteryId].idSelectedAccessory = selectedAccessory.id;
        }
      });

      updateBatteriesWithAccessories(batteries);
    }
  }, [protectMode]);

  useEffect(() => {
    if (existingOffer || previouslyAcceptedHardwareOffer) {
      const battery = productBatteryList.find((battery) =>
        [existingOffer?.battery, previouslyAcceptedHardwareOffer?.battery].includes(battery.id)
      );

      form.setFieldValue(formFields.MODEL_NAME, battery?.modelName);
      form.setFieldValue(formFields.CAPACITY_GROSS, battery?.id);
    }
  }, [existingOffer, previouslyAcceptedHardwareOffer]);

  const renderRecommendationSubheadline = () => {
    if (form.values.multiplePv) {
      return I18n.t(multiplePvHeadline);
    }

    if (
      shouldSkipRecommendation({
        batteryName: form.values[formFields.MODEL_NAME],
        commissioningDate: form.values[formFields.COMMISSIONING_DATE],
        isNewBattery: form.values[formFields.NEW_BATTERY],
      })
    ) {
      return I18n.t(pvAndCapacity);
    }

    return I18n.t(pleaseWaitForRecommendation);
  };

  return (
    <div className={'c-lead-configuration-hardware'}>
      <div className={'c-lead-configuration-hardware__headline'}>
        <PageSubheadline>{I18n.t(title)}</PageSubheadline>
      </div>

      <div className={'c-lead-configuration-hardware__subheadline'}>{I18n.t(pvSystem)}</div>
      <LeadConfigurationPv form={form} flatType={flatType} />

      <div className={'c-lead-configuration-hardware__subheadline'}>{I18n.t(sonnenBattery)}</div>
      <LeadConfigurationBattery
        form={form}
        productBatteryList={productBatteryList}
        existingOffer={existingOffer}
        previouslyAcceptedHardwareOffer={previouslyAcceptedHardwareOffer}
        isHardwareAlreadySold={isHardwareAlreadySold}
      />

      {selectedBatteryHasAccessories && (
        <LeadConfigurationProtect
          form={form}
          flatType={flatType}
          isPerformanceBattery={isPerformanceBattery}
          isProtectModeAC={isProtectModeAC}
        />
      )}

      {!multiplePv && (
        <>
          <div className={'c-lead-configuration-hardware__subheadline'}>
            {renderRecommendationSubheadline()}
          </div>
          <LeadConfigurationInputsWithRecommendation
            form={form}
            existingOffer={existingOffer}
            previouslyAcceptedHardwareOffer={previouslyAcceptedHardwareOffer}
          />
        </>
      )}
    </div>
  );
};
