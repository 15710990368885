import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Select } from '@sonnen/shared-web';

import { LeadListEmpty } from '+app/+lead/+list/components/LeadListEmpty';
import { SiteListRouteQueryParams } from '+router/routes';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import {
  getResultsPerPageItems,
  getSelectedResultsPerPage,
  getSelectedResultsPerPageItem,
} from '+shared/components/Pagination/PaginationConfiguration.interface';
import { PaginationNew } from '+shared/components/Pagination/PaginationNew.component';

import './SitesFooter.scss';

type Props = {
  totalCount: number;
  routerQueryParams: SiteListRouteQueryParams;
  totalPages: number;
  currentPage: number;
  handlePageChange: (
    pageNumber: number,
    recordsPerPage: string,
    routerQueryParams: SiteListRouteQueryParams
  ) => void;
  footerHeightRef: any;
};

const SitesFooter: React.FC<Props> = ({
  totalCount,
  routerQueryParams,
  totalPages,
  currentPage,
  handlePageChange,
  footerHeightRef,
}) => {
  const count = totalCount.toString();
  const totalResults = I18n.t(T.dashboard.customersTable.pagination.results, { count });
  const { size: selectedResultsPerPage } = routerQueryParams;
  const recordsPerPage = getSelectedResultsPerPage({ selectedResultsPerPage });
  const showPagination = totalCount > Number(recordsPerPage);

  return (
    <div className="table-footer-wrapper" ref={footerHeightRef}>
      {!totalCount ? (
        <LeadListEmpty />
      ) : (
        <>
          <LineDivider />

          <div className="table-footer">
            <div className="table-footer__left">
              <LabelMedium text={totalResults} className="total-results" />

              {showPagination && (
                <Select
                  className="rows-per-page-select"
                  items={getResultsPerPageItems({ selectedResultsPerPage })}
                  itemsSelected={getSelectedResultsPerPageItem({ selectedResultsPerPage })}
                  itemFactory={(item) => item.name}
                  onSelect={(recordsPerPage) =>
                    handlePageChange(1, recordsPerPage.value, routerQueryParams)
                  }
                />
              )}
            </div>

            {showPagination && (
              <PaginationNew
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                routerQueryParams={routerQueryParams}
                recordsPerPage={recordsPerPage}
              />
            )}
          </div>

          <LineDivider />
        </>
      )}
    </div>
  );
};

export default SitesFooter;
