import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import Warning from '+shared/components/Warning/Warning';

import { isAmortizationOver20Years } from '../LeadImpactAnalysisCalculationResults.helper';
import { Amortization } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

type Props = {
  amortizationTimeFrame: Amortization | undefined;
  warningText: string;
};

const AmortizationWarning: React.FC<Props> = ({ amortizationTimeFrame, warningText }) => (
  <div className="c-impact-analysis-results__amortization-warning">
    <Warning
      textMessageAsHtml={
        isAmortizationOver20Years(amortizationTimeFrame)
          ? warningText
          : I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.warningCorrectValues)
      }
      warningType={'alert'}
    />
  </div>
);

export default AmortizationWarning;
