import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const PvSystem: React.FC<Props> = ({ color, size = 166, className }) => (
  <svg className={className} width={size} height={size} fill={color} viewBox="0 0 166 166">
    <path
      fill={color}
      fillRule="evenodd"
      d="M130.636 35.583l20.25 81H124.5v-2.458h-3.458l.197-.036-4.988-27.431h25.541v-.4h-25.614l-4.957-27.267h23.654v-.4h-23.727l-3.743-20.586-.393.072 3.73 20.514H83.2v-20.55h-.4v20.55H55.259l3.73-20.514-.393-.072-3.743 20.586H31.125v.4H54.78l-4.958 27.267H24.209v.4h25.541l-4.987 27.431.197.036H41.5v2.458H15.114l20.25-81h95.272l.606-.151-.606.151zm-85.474 78.542h3.255v2.458h69.167v-2.458h3.255l-4.994-27.467h.406l-.073-.4h-.406l-4.958-27.267h.407l-.073-.4h-.406l.072.4H83.2v-.4h-.4v.4H55.186l.073-.4h-.407l-.073.4h.407l-4.958 27.267h-.407l-.073.4h.407l-4.994 27.467zM82.8 86.658H50.156l.073-.4H82.8v.4zm.4 0h-.4v27.467h.4V86.658zm0-.4h32.572l.073.4H83.2v-.4zm0 0h-.4V58.992h.4v27.267zm68.326 31.325H124.5v10.375a3.46 3.46 0 0 1-3.458 3.459 3.46 3.46 0 0 1-3.458-3.459v-10.375H48.417v10.375a3.46 3.46 0 0 1-3.458 3.459 3.46 3.46 0 0 1-3.458-3.459v-10.375H14.474a.5.5 0 0 1-.485-.621l20.406-81.621a1 1 0 0 1 .97-.757h95.272a1 1 0 0 1 .97.757l20.406 81.621a.5.5 0 0 1-.486.621z"
      clipRule="evenodd"
    />
  </svg>
);
