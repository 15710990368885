import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { get } from 'lodash/fp';
import * as Yup from 'yup';

const enum CloseLeadIssueTypes {
  CUSTOMER = 'customer',
  HARDWARE = 'hardware',
  TARIFF = 'tariff',
  GENERAL = 'general',
}

export interface CloseLeadForm {
  issueType: CloseLeadIssueTypes;
  reason: string;
}

export type CloseLeadIssueType = {
  label: string;
  type: string;
};

type CloseLeadReason = {
  value: string;
  translation: string;
};

export const initialValues: CloseLeadForm = {
  issueType: CloseLeadIssueTypes.CUSTOMER,
  reason: '',
};

export const CloseLeadSchema = () =>
  Yup.object().shape({
    issueType: Yup.string().required(() =>
      I18n.t(T.companyAccount.requestAccess.alert.requiredField)
    ),
    reason: Yup.string().required(() => I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
  });

const getCloseLeadCustomerReasons = (): CloseLeadReason[] => [
  {
    value: 'Customer didn’t give any feedback',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.customer.feedback
    ),
  },
  {
    value: 'Decision of purchasing postponed',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.customer.decisionPostponed
    ),
  },
  {
    value: 'Customer doesn’t have budget for investment',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.customer.budget
    ),
  },
  {
    value: 'System is unprofitable',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.customer.unprofitableSystem
    ),
  },
  {
    value: 'There is no financing option for customer',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.customer.noFinancingOption
    ),
  },
];

const getCloseLeadHardwareReasons = (): CloseLeadReason[] => [
  {
    value: 'Technology not convincing',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.hardware.technologyNotConvincing
    ),
  },
  {
    value: 'Features, accessories or services missing',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.hardware.featuresMissing
    ),
  },
  {
    value: 'Incompatible with home and existing assets',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.hardware.incompatible
    ),
  },
];

const getCloseLeadTariffReasons = (): CloseLeadReason[] => [
  {
    value: 'Battery control through external parties undesirable',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.tariff.batteryControl
    ),
  },
  {
    value: 'Tariff structure too complicated',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.tariff.structureComplicated
    ),
  },
  {
    value: 'Switch of energy supplier not possible within the next months',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.tariff.supplierNotPossible
    ),
  },
];

const getCloseLeadGeneralReasons = (): CloseLeadReason[] => [
  {
    value: 'Sonnen as a company not convincing',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.general.notConvincing
    ),
  },
  {
    value: 'Customer chose a competitor',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.general.choseCompetitor
    ),
  },
  {
    value: 'Duplicate/Test',
    translation: I18n.t(
      T.lead.overview._salessolution_.leadManager.closeLead.reasons.general.duplicateOrTest
    ),
  },
];

const getAllReasons = () =>
  getCloseLeadCustomerReasons().concat(
    getCloseLeadHardwareReasons(),
    getCloseLeadTariffReasons(),
    getCloseLeadGeneralReasons()
  );

export const getCloseLeadIssuesCollection = () => [
  {
    label: I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.issues.customer),
    type: 'customer',
  },
  {
    label: I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.issues.hardware),
    type: 'hardware',
  },
  {
    label: I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.issues.tariff),
    type: 'tariff',
  },
  {
    label: I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.issues.general),
    type: 'general',
  },
];

export const mapReasonToTranslation = (name: string) => {
  return get('translation')(getAllReasons().find((reason) => reason.value === name));
};

export const getReasonValue = (reasons: CloseLeadReason[]) => reasons.map((reason) => reason.value);

export const mapDropdownItems = (fieldName: string) => {
  switch (fieldName) {
    case CloseLeadIssueTypes.CUSTOMER:
      return getReasonValue(getCloseLeadCustomerReasons());
    case CloseLeadIssueTypes.HARDWARE:
      return getReasonValue(getCloseLeadHardwareReasons());
    case CloseLeadIssueTypes.TARIFF:
      return getReasonValue(getCloseLeadTariffReasons());
    case CloseLeadIssueTypes.GENERAL:
      return getReasonValue(getCloseLeadGeneralReasons());
    default:
      return [];
  }
};
