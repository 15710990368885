import isNil from 'lodash/isNil';
export var getHeader = function (headers, header) {
    if (!headers) {
        return undefined;
    }
    var lowercaseHeader = header.toLowerCase();
    var foundKey = Object.keys(headers).find(function (key) { return key.toLowerCase() === lowercaseHeader; });
    return foundKey ? String(headers[foundKey]) : undefined;
};
export var parseHeaders = function (headers) {
    var result = {};
    headers.forEach(function (value, key) { return result[key.toLowerCase()] = value; });
    return result;
};
export var sanitizeHeaders = function () {
    var multipleHeaders = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        multipleHeaders[_i] = arguments[_i];
    }
    var result = {};
    var keys = {};
    for (var _a = 0, multipleHeaders_1 = multipleHeaders; _a < multipleHeaders_1.length; _a++) {
        var headers = multipleHeaders_1[_a];
        if (!headers) {
            continue;
        }
        for (var key in headers) {
            if (headers.hasOwnProperty(key)) {
                var matchKey = key.toLowerCase();
                var originalKey = keys[matchKey];
                var value = headers[key];
                if (originalKey) {
                    delete result[originalKey];
                }
                if (isNil(value)) {
                    delete keys[matchKey];
                }
                else {
                    keys[matchKey] = key;
                    result[key] = value.toString();
                }
            }
        }
    }
    return result;
};
