import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Dashboard: React.FC<Props> = ({ color = '#0A1535', size = 24, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 11.6H20.9913C20.8958 9.41621 20.022 7.43444 18.6406 5.92515L17.2326 7.33314C17.0764 7.48935 16.8231 7.48935 16.6669 7.33314C16.5107 7.17693 16.5107 6.92366 16.6669 6.76745L18.0749 5.35947C16.5656 3.97798 14.5838 3.10424 12.4 3.00873V5C12.4 5.22091 12.2209 5.4 12 5.4C11.7791 5.4 11.6 5.22091 11.6 5V3.00873C9.41615 3.10424 7.43433 3.97801 5.92503 5.35954L7.33309 6.7676C7.4893 6.92381 7.4893 7.17708 7.33309 7.33329C7.17688 7.4895 6.92361 7.4895 6.7674 7.33329L5.35935 5.92523C3.97793 7.43451 3.10424 9.41625 3.00873 11.6H5C5.22091 11.6 5.4 11.7791 5.4 12C5.4 12.2209 5.22091 12.4 5 12.4H3.00873C3.09453 14.3617 3.8083 16.1604 4.95399 17.6H5H19H19.046C20.1917 16.1604 20.9055 14.3617 20.9913 12.4H19C18.7791 12.4 18.6 12.2209 18.6 12C18.6 11.7791 18.7791 11.6 19 11.6ZM12 21C9.5331 21 7.29802 20.0075 5.67228 18.4H18.3277C16.702 20.0075 14.4669 21 12 21ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.677 13.091C12.2083 13.5597 11.4485 13.5597 10.9799 13.091C10.5113 12.6224 10.5113 11.8626 10.9799 11.394C11.0299 11.344 11.1858 11.23 11.4887 11.0628C11.7714 10.9067 12.1245 10.7351 12.5131 10.5593C13.0497 10.3165 13.6344 10.0747 14.1601 9.86569C13.9588 10.4012 13.7248 10.998 13.4885 11.5451C13.318 11.9401 13.1509 12.2983 12.9982 12.5843C12.8339 12.8919 12.7228 13.0452 12.677 13.091ZM13.2427 13.6567C12.4616 14.4378 11.1953 14.4378 10.4142 13.6567C9.63318 12.8757 9.63318 11.6093 10.4142 10.8283C10.9586 10.2839 13.2148 9.36715 14.5321 8.86156C14.9226 8.71169 15.2994 9.08101 15.157 9.4743C14.6731 10.8113 13.7883 13.1111 13.2427 13.6567Z"
      fill={color}
    />
  </svg>
);
