import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Notifications } from '@sonnen/shared-web';

import { orderBy } from 'lodash';
import { isEmpty } from 'lodash/fp';

import { NotificationModal } from '+app/+newsNotifications/components/NotificationModal';
import { Container } from '+shared/components';
import { withFirebase } from '+shared/components/FirebaseContext';
import { isAuthenticated } from '+shared/store/auth/auth.selectors';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { getFirebaseNotificationsList } from '+shared/store/firebase/firebase.selectors';
import { News } from '+shared/store/firebase/types/news.interface';
import { StoreState } from '+shared/store/store.interface';
import { UserRole } from '+shared/store/user';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import { isSPListNews } from '+utils/news.util';

import { setHintCategory, setHintColor, setTagName } from './News.helper';

import './NewsSection.component.scss';

export interface NewsProps extends FirebaseProps {
  userRoles: UserRole[];
}

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: isAuthenticated(state),
  userRoles: getUserProfileRoles(state),
  notificationsList: getFirebaseNotificationsList(state),
});

type Props = ReturnType<typeof mapStateToProps> & NewsProps;

const NewsSectionComponent: React.FC<Props> = ({
  isAuthenticated,
  userRoles,
  notificationsList,
}) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [activeNotification, setActiveNotification] = React.useState<News>();

  const prepareNotificationModal = (isModalOpen: boolean, notificationId: string) => {
    setActiveNotification(notificationsList.find((item) => item.id === notificationId));
    setIsModalOpened(true);
  };

  if (isEmpty(notificationsList) || !isAuthenticated || !userRoles) {
    return null;
  }

  const orderedList = orderBy(notificationsList, 'date.seconds', 'desc');

  return (
    <Container className={'c-news__section'}>
      <Notifications
        items={orderedList.filter(isSPListNews).map((item) => ({
          longmessage: item.message,
          message: item.shortMessage,
          key: item.id,
          type: setHintColor(item.levelValue),
          category: item.tagValue ? setTagName(item.tagValue) : setHintCategory(item.levelValue),
          action: () => prepareNotificationModal(true, item.id),
          btnText: I18n.t(T.newsChannel.section.more),
        }))}
      />

      {activeNotification && (
        <NotificationModal
          notification={activeNotification}
          isOpen={isModalOpened}
          onClose={() => setIsModalOpened(false)}
        />
      )}
    </Container>
  );
};

export const NewsSection = connect(mapStateToProps)(withFirebase(NewsSectionComponent));
