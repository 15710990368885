import * as React from 'react';

import './HighlightChangeElement.component.scss';

interface Props {
  value: React.ReactNode;
}

interface State {
  value: React.ReactNode;
}

export class HighlightChangeElement extends React.Component<Props> {
  isMountedComponent = false;

  state = {
    isChanged: false,
    value: this.props.value,
  };

  componentDidMount() {
    this.isMountedComponent = true;
  }

  componentWillUnmount() {
    this.isMountedComponent = false;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.value !== this.props.value) {
      this.setState(
        {
          isChanged: true,
          value: this.props.value,
        },
        this.stopHighlight
      );
    }
  }

  stopHighlight = () => {
    const highlightTime = 2000;
    setTimeout(() => {
      if (this.isMountedComponent) {
        this.setState({ isChanged: false });
      }
    }, highlightTime);
  };

  render() {
    const { children } = this.props;
    const { isChanged } = this.state;

    return <div className={isChanged ? 'c-battery-live-card__highlight' : ''}>{children}</div>;
  }
}
