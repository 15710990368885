import { Action } from 'redux';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ActionCreator } from '../redux/createAction.util';

// 3 action types
export function ofType<
  T extends string,
  Payload extends {},
  T2 extends string,
  Payload2 extends {},
  T3 extends string,
  Payload3 extends {}
>(
  creator1: ActionCreator<T, Payload>,
  creator2: ActionCreator<T2, Payload2>,
  creator3: ActionCreator<T3, Payload3>
): (
  o: Observable<Action<any>>
) => Observable<
  ReturnType<typeof creator1> | ReturnType<typeof creator2> | ReturnType<typeof creator3>
>;

// 2 action types
export function ofType<
  T extends string,
  Payload extends {},
  T2 extends string,
  Payload2 extends {}
>(
  creator1: ActionCreator<T, Payload>,
  creator2: ActionCreator<T2, Payload2>
): (
  o: Observable<Action<any>>
) => Observable<ReturnType<typeof creator1> | ReturnType<typeof creator2>>;

// 1 action type
export function ofType<T extends string, Payload extends {}>(
  creator: ActionCreator<T, Payload>
): (o: Observable<Action<any>>) => Observable<ReturnType<typeof creator>>;

export function ofType(
  ...actionCreators: Array<ActionCreator<string, any>>
): (o: Observable<any>) => Observable<any> {
  const types = actionCreators.map((creator) => creator.type);
  return filter((action) => types.includes(action.type));
}
