import { Customer } from '+shared/store/customer';

import { ActionsUnion, createAction } from '../../utils';

export enum CUSTOMER_ACTIONS {
  GET_CUSTOMER = '[Customer] GET_CUSTOMER',
  SET_CUSTOMER = '[Customer] SET_CUSTOMER',
  CLEAR_CUSTOMER_DATA = '[Customer] CLEAR_CUSTOMER_DATA',
  PUT_CUSTOMER_NOTE = '[Customer] PUT_CUSTOMER_NOTE',
  SET_CUSTOMER_NOTE = '[Customer] SET_CUSTOMER_NOTE',
}

export const CustomerActions = {
  getCustomer: createAction(
    CUSTOMER_ACTIONS.GET_CUSTOMER,
    (customerId: string, queryKey: string) => ({ customerId, queryKey })
  ),
  setCustomer: createAction(CUSTOMER_ACTIONS.SET_CUSTOMER, (customer: Customer) => ({ customer })),
  clearCustomerData: createAction(CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA),
  putCustomerNote: createAction(
    CUSTOMER_ACTIONS.PUT_CUSTOMER_NOTE,
    (queryKey: string, customerId: string, note: string) => ({ queryKey, customerId, note })
  ),
  setCustomerNote: createAction(CUSTOMER_ACTIONS.SET_CUSTOMER_NOTE, (customer: Customer) => ({
    customer,
  })),
};

export type CustomerActions = ActionsUnion<typeof CustomerActions>;
