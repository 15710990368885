import * as React from 'react';

import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import { Button, ButtonSize, ButtonStatus, MainType } from '+shared/components/Button';
import { VideoYoutube } from '+shared/store/youtube/types/youtube.interface';
import { YoutubeActions } from '+shared/store/youtube/youtube.actions';

import { AddVideoForm, getYouTubeId, youtubeLink } from '../AddVideo/AddVideo.helper';

interface Props {
  youtubeVideo: VideoYoutube | undefined;
  form: FormikProps<AddVideoForm>;
  getYoutubeVideo: typeof YoutubeActions.getYoutubeVideoList;
}

export const AddVideoFormButtons: React.FC<Props> = ({ youtubeVideo, form, getYoutubeVideo }) => {
  const verifyVideo = () => {
    if (form.values) {
      const id = getYouTubeId(form.values[youtubeLink]);
      if (id) {
        getYoutubeVideo(id);
      }
    }
  };

  const isButtonDisabled = !form.touched[youtubeLink] || !isEmpty(form.errors);

  return youtubeVideo ? (
    <Button
      label={'Submit'}
      size={ButtonSize.SMALL}
      mainType={MainType.SUBMIT}
      disabled={isButtonDisabled}
      status={isButtonDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
    />
  ) : (
    <Button
      label={'Verify video'}
      size={ButtonSize.SMALL}
      onClick={verifyVideo}
      disabled={isButtonDisabled}
      status={isButtonDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
    />
  );
};
