import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import { Button, ButtonType } from '+shared/components/Button';

import { PageHeader } from '../../PageHeader';

import './FormHeader.component.scss';

interface Props {
  onClick: () => void;
  title: string;
  breadcrumb?: string;
  isLoading?: boolean;
  suppressTitleInHotjar?: boolean;
}

export const FormHeader: React.FC<Props> = ({
  onClick,
  breadcrumb,
  title,
  isLoading = false,
  suppressTitleInHotjar = false,
}) => (
  <PageHeader
    breadcrumb={
      <Button
        className={'c-form-header__wrapper'}
        dataTestId="go-back-btn"
        type={ButtonType.TERTIARY}
        label={
          <>
            <Icon.Angle className={'c-form-header__icon'} />
            {breadcrumb}
          </>
        }
        onClick={onClick}
      />
    }
    isLoading={isLoading}
  >
    <p
      className={'c-form-header__title'}
      data-hj-suppress={suppressTitleInHotjar}
      data-testid="title-text"
    >
      {title}
    </p>
  </PageHeader>
);
