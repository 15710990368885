import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { getDsoCommissioningSchema } from '+setupTool/+dsoCommissioning/store/schemas';
import { PVRegisterFormInterface } from '+setupTool/+pvRegister/store/types';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';

const PVRegisterTOCSchema = () =>
  Yup.object().shape({
    toc1: Yup.boolean().oneOf([true]),
    toc2: Yup.boolean().oneOf([true]),
  });

export const getPVRegisterSchema = (registrationSubjectType?: RegistrationSubjectType) =>
  Yup.object()
    .concat(getDsoCommissioningSchema(RegistrationSubjectType.PV_AND_BATTERY)())
    .concat(PVRegisterTOCSchema());

export const PVRegisterInitialPvAndBattery: PVRegisterFormInterface = {
  dso_reference_number: '',
  pv_commissioning_date: '',
  battery_commissioning_date: '',
  toc1: false,
  toc2: false,
};

export const PVRegisterInitialBatteryOnly: PVRegisterFormInterface = {
  dso_reference_number: '',
  pv_commissioning_date: '',
  battery_commissioning_date: '',
  toc1: false,
  toc2: false,
};

export const PVRegisterCaptchaSchema = () =>
  Yup.object().shape({
    captcha: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
  });

export const getPVRegisterInitial = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return PVRegisterInitialPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return PVRegisterInitialBatteryOnly;
    default:
      return PVRegisterInitialPvAndBattery;
  }
};
