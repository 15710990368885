import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';

import { getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { FormInput } from '+shared/components';
import { FormInputInfo } from '+shared/components/FormInputInfo';
import { LeadOffer } from '+shared/store/lead/types/leadOffer.interface';
import { StoreState } from '+shared/store/store.interface';
import { isEdgeBrowser, isFirefoxBrowser } from '+utils/browser.util';

import { LeadConfigurationHint } from '../../components';
import { LeadConfigurationBatteryCapacities } from '../../components/LeadConfigurationBatteryCapacities';
import {
  getConfigurationHint,
  getConfigurationOptionalRecommendationSubmitQueryStatus,
  getConfigurationRecommendationSubmitQueryStatus,
} from '../../store/+configuration.selectors';
import { ConfigurationForm } from '../../store/types';
import {
  formFields,
  shouldSkipRecommendation,
} from '../LeadConfigurationForm/LeadConfigurationForm.helper';
import { formFields as PvformFields } from '../LeadConfigurationPv/LeadConfigurationPv.helper';

import './LeadConfigurationInputsWithRecommendation.component.scss';

const mapStateToProps = (state: StoreState) => ({
  hint: getConfigurationHint(state),
  configurationRecommendationSubmitQueryStatus:
    getConfigurationRecommendationSubmitQueryStatus(state),
  configurationOptionalRecommendationSubmitQueryStatus:
    getConfigurationOptionalRecommendationSubmitQueryStatus(state),
  productBatteryList: getProductBatteryList(state),
});

interface ComponentProps {
  form: FormikProps<ConfigurationForm>;
  // this one is when we're adding flat tariff to an existing offer
  existingOffer?: LeadOffer;
  // here we're creating another bundle offer while HW only was previously accepted/confirmed
  previouslyAcceptedHardwareOffer?: LeadOffer;
}

type Props = ReturnType<typeof mapStateToProps> & ComponentProps;

const getCommaWarning = () =>
  isEdgeBrowser() || isFirefoxBrowser()
    ? I18n.t(T.lead.configuration._salessolution_.pv.noComma)
    : '';

const LeadConfigurationInputsWithRecommendationComponent: React.FC<Props> = ({
  form,
  configurationRecommendationSubmitQueryStatus,
  configurationOptionalRecommendationSubmitQueryStatus,
  hint,
  productBatteryList,
  existingOffer,
  previouslyAcceptedHardwareOffer,
}) => {
  const commaWarning = getCommaWarning();
  const shouldHideRecommendationHint = () =>
    shouldSkipRecommendation({
      batteryName: form.values[formFields.MODEL_NAME],
      commissioningDate: form.values[formFields.COMMISSIONING_DATE],
      isNewBattery: form.values[formFields.NEW_BATTERY],
    });

  const optionalRecommendationStatus = configurationOptionalRecommendationSubmitQueryStatus;
  const isOptionalRecommendationReadyAndPending =
    !optionalRecommendationStatus.notReady && optionalRecommendationStatus.pending;
  const isRecommendationPending =
    configurationRecommendationSubmitQueryStatus.pending || isOptionalRecommendationReadyAndPending;

  return (
    <div className={'c-lead-configuration-inputs-with-recommendation'}>
      {shouldHideRecommendationHint() && (
        <div className={'c-lead-configuration-inputs-with-recommendation__info'}>
          <FormInputInfo>
            {I18n.t(T.lead.configuration._salessolution_.recommendationInfo)}
          </FormInputInfo>
        </div>
      )}
      <div className={'c-lead-configuration-inputs-with-recommendation__columns'}>
        {/* PV POWER */}
        <div className={'c-lead-configuration-inputs-with-recommendation__column'}>
          <div className={'c-lead-configuration-inputs-with-recommendation__input'}>
            <FormInput
              form={form}
              label={`${I18n.t(
                T.lead.configuration._salessolution_.pv.systemPower
              )} ${commaWarning}`}
              unit={I18n.t(T.units.kwp)}
              name={PvformFields.PEAK_POWER}
              type={'number'}
              hasNoGap={true}
              id={'lead-configuration-pv-power'}
            />
            {!shouldHideRecommendationHint() && (
              <LeadConfigurationHint
                reference={'pv'}
                isPending={isRecommendationPending}
                hintValue={hint?.peakPower}
                optionalHintValue={hint?.peakPowerOptional}
              />
            )}
          </div>
        </div>

        {/* BATTERY CAPACITY */}
        <div className={'c-lead-configuration-inputs-with-recommendation__column'}>
          <div className={'c-lead-configuration-inputs-with-recommendation__input'}>
            <LeadConfigurationBatteryCapacities
              form={form}
              productBatteryList={productBatteryList}
              existingOffer={existingOffer}
              previouslyAcceptedHardwareOffer={previouslyAcceptedHardwareOffer}
            />
            {!shouldHideRecommendationHint() && (
              <LeadConfigurationHint
                reference={'battery'}
                isPending={isRecommendationPending}
                hintValue={hint?.capacityGross}
                optionalHintValue={hint?.capacityGrossOptional}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeadConfigurationInputsWithRecommendation = connect(mapStateToProps)(
  LeadConfigurationInputsWithRecommendationComponent
);
