import React from 'react';

import { FormikProps } from 'formik';

import { ListTable, ListTableHeader } from '+shared/components';
import { Contact } from '+shared/store/contact/types';

import { InboxLeadListTableRadioBtn } from '../InboxLeadListTableRadioBtn';
import {
  getPartnerEmployeeListTableHeaders,
  LeadListAssignModalFormType,
} from './LeadListAssignModalForm.helper';

import './LeadListAssignModalForm.component.scss';

interface Props {
  partnerEmployeeList: Contact[];
  form: FormikProps<LeadListAssignModalFormType>;
}

export const LeadListAssignModalForm: React.FC<Props> = ({ partnerEmployeeList, form }) => {
  const partnerEmployeeListTableHeaders = getPartnerEmployeeListTableHeaders();
  return (
    <ListTable
      className={`c-lead-list-assign-modal-form__table`}
      headers={partnerEmployeeListTableHeaders}
      items={partnerEmployeeList}
      isPending={false}
      hoverable={true}
      fitWidth={true}
      noBorder={true}
      smallHeader={true}
      renderHeader={(header) => <ListTableHeader item={header} />}
      renderItem={(item, index) => (
        <InboxLeadListTableRadioBtn
          form={form}
          partner={item}
          name={'chosenPartnerId'}
          value={item.salesforceContactId}
          key={index}
        />
      )}
      suppressItemsInHotjar={true}
      dataTestIdTable="leads-inbox-accept-lead-modal-partner-name-table"
      dataTestIdHeader="leads-inbox-accept-lead-modal-partner-name-table-header"
    />
  );
};
