import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { FieldNames } from '../../containers/MissingBatteryModal/types';

export interface MissingBatteryIdentifyFormValues {
  [FieldNames.SERIAL_NUMBER]: string;
}

export const missingBatteryIdentifySchema = () =>
  Yup.object({
    serialNumber: Yup.string().required(
      I18n.t(T.missingBattery.validateSerialNumber.noEmptyValues)
    ),
  });
