import React from 'react';

import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { Lead } from '+shared/store/lead/types';

import './LeadName.scss';

type Props = {
  lead: Pick<Lead, 'firstName' | 'lastName' | 'company'>;
};

export const LeadName = ({ lead }: Props) => {
  const { lastName, firstName, company } = lead;

  return (
    <div data-hj-suppress={true} className="lead-name" data-testid="lean-name">
      {Boolean(company?.name) && <LabelMedium text={company?.name} className="company" />}
      <LabelMedium text={`${lastName} ${firstName}`} />
    </div>
  );
};
