"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var symbol = Symbol('HttpRequestError');
var HttpRequestError = (function (_super) {
    __extends(HttpRequestError, _super);
    function HttpRequestError(options, message) {
        var _this = _super.call(this, options.method + " " + options.url + (message ? ": " + message : '')) || this;
        _this.options = options;
        _this.__symbol = symbol;
        _this.name = 'HttpRequestError';
        return _this;
    }
    return HttpRequestError;
}(Error));
exports.HttpRequestError = HttpRequestError;
exports.isHttpRequestError = function (error) { return (error === null || error === void 0 ? void 0 : error.__symbol) === symbol; };
