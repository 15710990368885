import * as React from 'react';
import { createPortal } from 'react-dom';

export class Portal extends React.Component {
  private modalRoot = document.getElementById('portal');
  private el = document.createElement('div');

  componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
