import * as React from 'react';
import { connect } from 'react-redux';

import { CountryFlagProvider } from '@sonnen/shared-web';

import { firestore } from 'firebase/app';
import { CountryCode } from 'src/types/country.type';

import { mapActions } from '+app/utils/redux/mapActions.util';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { ListItemNews, News, Portal } from '+shared/store/firebase/types/news.interface';
import { StoreState } from '+shared/store/store.interface';
import { UserRole } from '+shared/store/user';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import { dateUtil } from '+utils/date.util';

const mapStateToProps = (state: StoreState) => ({
  userRoles: getUserProfileRoles(state),
});

const mapDispatchToProps = mapActions({
  setNewsList: FirebaseActions.setNewsList,
  setNotificationsList: FirebaseActions.setNotificationsList,
});

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  FirebaseProps & { isSpanishMarketEnabled: boolean };

class FirebaseSubscribeComponent extends React.PureComponent<Props> {
  unsubscribeNews?: () => void;

  constructor(props: Props) {
    super(props);

    window.addEventListener('beforeunload', () => {
      if (this.unsubscribeNews) {
        this.unsubscribeNews();
      }
    });

    this.subscribeNewsIfPossible();
  }

  componentDidUpdate() {
    this.subscribeNewsIfPossible();
  }

  componentWillUnmount() {
    if (this.unsubscribeNews) {
      this.unsubscribeNews();
    }
  }

  subscribeNewsIfPossible = () => {
    const userCountryCode = CountryFlagProvider.getConfig().userCountry;

    if (this.props.userRoles.length && !this.unsubscribeNews && userCountryCode) {
      this.subscribeNews(userCountryCode);
    }
  };

  subscribeNews = (userCountryCode: CountryCode) => {
    const { firebase, actions, userRoles } = this.props;

    this.unsubscribeNews = firebase
      .getCurrentNewsCollection(this.props.isSpanishMarketEnabled, Portal.SP, userCountryCode)
      .onSnapshot(
        (querySnapshot: firestore.QuerySnapshot) => {
          const newsList: Array<ListItemNews<News>> = [];
          const notificationsList: Array<ListItemNews<News>> = [];

          querySnapshot.forEach((doc: any) => {
            const news: News = doc.data();
            const shouldDisplayNews =
              ((news.expiryDate && news.expiryDate >= dateUtil.todayFormatted()) ||
                news.expiryDate === '') &&
              (news.roles
                ? userRoles.some(
                    (role) => news.roles.indexOf(role) > -1 || role === UserRole.SUPER_USER
                  )
                : true);

            if (shouldDisplayNews) {
              newsList.push({ ...news, isActive: true });
            }

            const shouldDisplayNotifications =
              news.isDisplayedAsNotification &&
              news.notificationExpiryDate &&
              news.notificationExpiryDate >= dateUtil.todayFormatted() &&
              (news.roles
                ? userRoles.some(
                    (role) => news.roles.indexOf(role) > -1 || role === UserRole.SUPER_USER
                  )
                : true);

            if (shouldDisplayNotifications) {
              notificationsList.push({ ...news, isActive: true });
            }
          });
          actions.setNewsList(newsList);
          actions.setNotificationsList(notificationsList);
        },
        (error: any) => console.log('Firebase error:', error)
      );
  };

  render() {
    return null;
  }
}

export const FirebaseSubscribe = connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(FirebaseSubscribeComponent));
