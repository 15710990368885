import { isArray, isObject, isString, keysIn } from 'lodash/fp';

import { ORDER, QueryArgs, QueryNestedArg, QueryPrimitive } from '../types/graphQL.types';

const formatPrimitive = (value: QueryPrimitive) => {
  if (isString(value)) {
    return `"${value}"`;
  }
  return value;
};

export const formatArray = (arr: string[]) => {
  const formatedArray = arr.reduce((dataString, key) => `${dataString}"${key}",`, '');
  return `[${formatedArray}]`;
};

export const formatObject = (obj: QueryNestedArg, hasQuotes: boolean = true) => {
  const formatedObject = keysIn(obj).reduce((dataString, key) => {
    if (hasQuotes) {
      return `${dataString}${key}:"${obj[key]}",`;
    }
    return `${dataString}${key}:${obj[key]},`;
  }, '');
  return `{${formatedObject}}`;
};

export const formatQueryArgs = (args: QueryArgs) =>
  keysIn(args).reduce((dataString, key) => {
    const argsKey = args[key];
    const noQuotes = key === ORDER;
    const value = isObject(argsKey)
      ? isArray(argsKey)
        ? formatArray(argsKey)
        : formatObject(argsKey, !noQuotes)
      : formatPrimitive(argsKey);
    return `${dataString}${key}:${value},`;
  }, '');
