import { createSelector } from 'reselect';

import { getStatus } from '+shared/store/query/query.utils';
import { getVppDocumentation } from '+shared/store/setupTool/setupTool.selectors';

import { VPP_CONFIG_SUBMIT_QUERY, VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY } from './';

const getVppUploadedDocumentsArray = createSelector(
  getVppDocumentation,
  (state) => state.uploadedDocuments
);

export const getVppUploadedDocuments = createSelector(
  getVppUploadedDocumentsArray,
  (uploadedDocuments) =>
    uploadedDocuments.reduce(
      (prev, document) => ({
        ...prev,
        [document.upload.category]: prev[document.upload.category]
          ? [...prev[document.upload.category], document]
          : [document],
      }),
      {}
    )
);

export const getVppCompletionNotificationDocument = createSelector(
  getVppDocumentation,
  (state) => state.completionNotificationDocument
);

export const getVppCompletionNotificationRegeneratedAt = createSelector(
  getVppDocumentation,
  (state) => state.completionNotificationRegeneratedAt
);

export const getVppDocumentationFields = createSelector(
  getVppDocumentation,
  getVppUploadedDocuments,
  (vppData, uploadedDocuments) => ({ ...vppData.fields, ...uploadedDocuments })
);

export const getVppSubmissionStatus = createSelector(
  getVppDocumentation,
  (state) => state.submissionStatus
);

export const getVppConfigQueryStatus = createSelector(getVppDocumentation, (state) =>
  getStatus(state[VPP_CONFIG_SUBMIT_QUERY])
);

export const getVppGenerateCompletionNotificationQueryStatus = createSelector(
  getVppDocumentation,
  (state) => getStatus(state[VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY])
);

export const getVppAdditionalFeatures = createSelector(
  getVppDocumentation,
  (state) => state.vppAdditionalFeatures
);

export const getVppState = createSelector(getVppDocumentation, (state) => state.vppState);
