export enum LeadProductInterest {
  ECO = 'eco',
  HYBRID = 'hybrid',
  PRO = 'pro',
  BATTERY_EXTENSION = 'batteryExtension',
  PV = 'photovoltaics',
  BACK_UP_BOX = 'backUpBox',
  CHARGER = 'charger',
  HEATER = 'heater',
  SONNEN_FLAT_CITY = 'sonnenBatteryCity',
  SONNEN_FLAT_HOME = 'sonnenFlat',
  SONNEN_FLAT_X = 'sonnenFlatX',
  SONNEN_DRIVE = 'sonnenDrive',
  SONNEN_NOW = 'sonnenNow',
  SONNEN_STROM_START = 'sonnenStromStart',
  SONNEN_STROM_SOLAR = 'sonnenStromSolar',
  SONNEN_STROM_PLUS = 'sonnenStromPlus',
  SONNEN_BATTERIE_10 = 'sonnenBatterie10',
  SONNEN_BATTERIE_10_PERFORMANCE = 'sonnenBatterie10Performance',
  PRODUCER_SONNEN_COMMUNITY = 'producerSonnenCommunity',
  PV_UPGRADE = 'pvUpgrade',
}
