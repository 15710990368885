import * as React from 'react';

import * as classNames from 'classnames';
import { FormikProps } from 'formik';

import { FormFieldError } from '../FormFieldError';
import { FormInputCheckbox } from '../FormInputCheckbox';

import './FormInputCheckboxGroup.component.scss';

interface CheckboxInput {
  label: string;
  value: string;
}

interface Props<T> {
  name: string;
  text?: string;
  form: FormikProps<T>;
  className?: ClassValue;
  collection: CheckboxInput[];
}

export const FormInputCheckboxGroup = <T extends any = any>({
  form,
  name,
  text,
  className,
  collection,
}: Props<T>) => (
  <div className={classNames('c-form-input-checkbox-group', className)}>
    {text && <p className={'c-form-input-checkbox-group__text'}>{text}</p>}
    <div className={'c-form-input-checkbox-group__container'}>
      {collection.map((checkbox) => (
        <FormInputCheckbox
          key={checkbox.value}
          form={form}
          label={checkbox.label}
          name={name}
          value={checkbox.value}
        />
      ))}
    </div>
    <FormFieldError name={name} form={form} />
  </div>
);
