import { LeadStatusName } from '+shared/store/lead/types';

export const leadStatusGroups = [
  [LeadStatusName.OPEN],
  [
    LeadStatusName.CONTACTED,
    LeadStatusName.ON_SITE_VISIT_ARRANGED,
    LeadStatusName.ON_SITE_VISIT_DONE,
  ],
  [LeadStatusName.HW_OFFER_SENT, LeadStatusName.HW_ORDER_CONFIRMED],
  [
    LeadStatusName.FLAT_OFFER_SENT,
    LeadStatusName.FLAT_OFFER_ACCEPTED,
    LeadStatusName.FLAT_ORDER_CONFIRMED,
  ],
];

export const setupLeadStatusGroups = [
  [
    LeadStatusName.DSO_REGISTRATION_COMPLETED,
    LeadStatusName.DSO_COMMISSIONING_COMPLETED,
    LeadStatusName.BNETZA_REGISTRATION_COMPLETED,
    LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED,
  ],
  [LeadStatusName.HW_COMMISSIONING_COMPLETED, LeadStatusName.DSO_METER_INSTALLED],
  [
    LeadStatusName.FLAT_OFFER_SENT,
    LeadStatusName.FLAT_OFFER_ACCEPTED,
    LeadStatusName.FLAT_ORDER_CONFIRMED,
    LeadStatusName.VPP_PREPARATION_DONE,
  ],
];

export const getStatusGroups = (inSetup?: boolean) =>
  inSetup ? setupLeadStatusGroups : leadStatusGroups;
