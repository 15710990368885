/* eslint-disable max-len */
import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { Market } from '+utils/market.util';

import { NavSonnenWorld } from './NavSonnenWorld.component';
import type { ActionLinks } from './NavSonnenWorld.context';

const marketActionLinkMap: Record<Market, Partial<ActionLinks>> = {
  [Market.DE]: {
    partnerAppLink: 'https://sonnen.de/partner-app/',
    marketingLink: 'https://marketing-support.sonnen.de',
    generalDocumentsLink: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
    technicalDocumentsLink: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
    eAcademyLink: 'https://sonnen.breuerundnohr.de',
    releaseNotesLink: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.IT]: {
    eAcademyLink: 'https://www.tecnoacademy.it/sonnen-academy-installatori/',
    releaseNotesLink: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.AT]: {
    partnerAppLink: 'https://sonnen.de/partner-app/',
    marketingLink: 'https://marketing-support.sonnen.de',
    generalDocumentsLink: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
    technicalDocumentsLink: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
    eAcademyLink: 'https://sonnen.breuerundnohr.de',
    releaseNotesLink: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.CH]: {
    partnerAppLink: 'https://sonnen.de/partner-app/',
    marketingLink: 'https://marketing-support.sonnen.de',
    generalDocumentsLink: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
    technicalDocumentsLink: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
    eAcademyLink: 'https://sonnen.breuerundnohr.de',
    releaseNotesLink: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.GME]: {
    marketingLink: 'https://marketing-support.sonnen.de',
    releaseNotesLink: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.ES]: {
    marketingLink: 'https://marketing-support.sonnen.de',
    releaseNotesLink: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.AU]: {
    generalDocumentsLink: 'https://owncloud.sonnenbatterie.de/f/24117913',
    technicalDocumentsLink: 'https://www.sonnensupportaustralia.com.au',
  },
  [Market.US]: {
    marketingLink:
      'https://sonnnenbatterieus-my.sharepoint.com/:f:/g/personal/sbi_marketing_sonnen_team/EvDbwA-9fTBFsacFg6hKqooBuRJlcKQLCICTUGG5k-CAQQ?e=DfbNPB',
    technicalDocumentsLink:
      'https://sonnnenbatterieus-my.sharepoint.com/:f:/g/personal/sbi_marketing_sonnen_team/EtfP-nAEWdRNus9Qpmn3H64ByTgVT68EmUqe47Ma_NDZtQ?e=kZPa3m',
    eAcademyLink: 'https://sonnenuniversity.talentlms.com/index',
  },
};

export const mapMarketToSonnenWorldSections = (market: Market): React.ReactNode => {
  const actionLinkMap = marketActionLinkMap[market];
  const { eAcademyDisabled } = useFlags();

  switch (market) {
    case Market.DE:
      return (
        <NavSonnenWorld actionLinkMap={actionLinkMap}>
          <NavSonnenWorld.PartnerApp />
          <NavSonnenWorld.Marketing />
          <NavSonnenWorld.GeneralDocuments />
          <NavSonnenWorld.TechnicalDocuments />
          {eAcademyDisabled && <NavSonnenWorld.EAcademy />}
          <NavSonnenWorld.ReleaseNotes />
        </NavSonnenWorld>
      );
    case Market.IT:
      return (
        <NavSonnenWorld actionLinkMap={actionLinkMap}>
          <NavSonnenWorld.EAcademy />
          <NavSonnenWorld.ReleaseNotes />
        </NavSonnenWorld>
      );

    case Market.AT:
    case Market.CH:
      return (
        <NavSonnenWorld actionLinkMap={actionLinkMap}>
          <NavSonnenWorld.PartnerApp />
          <NavSonnenWorld.Marketing />
          <NavSonnenWorld.GeneralDocuments />
          <NavSonnenWorld.TechnicalDocuments />
          {eAcademyDisabled && <NavSonnenWorld.EAcademy />}
          <NavSonnenWorld.ReleaseNotes />
        </NavSonnenWorld>
      );

    case Market.GME:
    case Market.ES:
      return (
        <NavSonnenWorld actionLinkMap={actionLinkMap}>
          <NavSonnenWorld.Marketing />
          <NavSonnenWorld.ReleaseNotes />
        </NavSonnenWorld>
      );

    case Market.AU:
      return (
        <NavSonnenWorld actionLinkMap={actionLinkMap}>
          <NavSonnenWorld.GeneralDocuments />
          <NavSonnenWorld.TechnicalDocuments />
        </NavSonnenWorld>
      );

    case Market.US:
      return (
        <NavSonnenWorld actionLinkMap={actionLinkMap}>
          <NavSonnenWorld.Marketing />
          <NavSonnenWorld.TechnicalDocuments />
          <NavSonnenWorld.EAcademy />
        </NavSonnenWorld>
      );

    default:
      return null;
  }
};
