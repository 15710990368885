import { useSelector } from 'react-redux';

import { mapAdditionalFeaturesToFlags } from '../+vppDocumentation/store/+vppDocumentation.helpers';
import { getVppAdditionalFeatures } from '../+vppDocumentation/store/+vppDocumentation.selectors';
import { getAdditionalFeatures } from './+setupTool.selectors';

export const useAdditionalFeatures = () => {
  const additionalFeatures = useSelector(getAdditionalFeatures);
  const vppAdditionalFeatures = useSelector(getVppAdditionalFeatures);

  const {
    isVppCompletionNotificationVisible,
    isDsoReferenceNumberPvFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    isMeterCabinetPreparedFieldVisible,
    isProductionMeterFieldVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
    isVppWiringDiagramVisible,
  } = mapAdditionalFeaturesToFlags(additionalFeatures);

  return {
    additionalFeatures,
    vppAdditionalFeatures,

    isVppCompletionNotificationVisible,
    isDsoReferenceNumberPvFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    isMeterCabinetPreparedFieldVisible,
    isProductionMeterFieldVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
    isVppWiringDiagramVisible,
  };
};
