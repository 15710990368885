import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { dataGuard, ofType, processQuery } from '+app/utils';

import { PartnerActions } from './partner.actions';
import { PartnerRepository } from './partner.repository';

type Action$ = ActionsObservable<PartnerActions>;

const identifyMissingBattery$ = (action$: Action$) =>
  action$.pipe(
    ofType(PartnerActions.identifyMissigBattery),
    mergeMap(({ serialNumber, queryKey }) =>
      of({}).pipe(processQuery(queryKey, () => PartnerRepository.findMissingBattery(serialNumber)))
    )
  );

const claimBatteryOwnership$ = (action$: Action$) =>
  action$.pipe(
    ofType(PartnerActions.claimMissingBatteryOwnership),
    mergeMap(({ claimBattery, queryKey }) =>
      of({}).pipe(
        processQuery(queryKey, () => PartnerRepository.claimBatteryOwnership(claimBattery), {
          onSuccess: (res) =>
            // TODO: remove assignedBatteryRaw and use assignedBattery data when fixed in coolio
            dataGuard(PartnerActions.setAssignedBattery)({
              assignedBattery: res!.element,
              assignedBatteryRaw: res!.raw,
            }),
        })
      )
    )
  );

export const epics = combineEpics<any>(identifyMissingBattery$, claimBatteryOwnership$);
