import { PersistedState } from 'redux-persist';

import { TariffType, TariffWindow } from '+customer-battery/store/battery-tariff';
import {
  BatteryElectricUnits,
  BatteryElectricUnitsData,
  BatteryWithProduct,
  BatteryWithProductData,
} from '+shared/store/battery';
import {
  BatteryIcStatus,
  BatteryIcStatusData,
} from '+shared/store/battery/types/batteryIcStatus.interface';
import { BatteryOperationsData } from '+shared/store/battery/types/batteryOperations.interface';
import { BatteryStatusesData } from '+shared/store/battery/types/batteryStatuses.interface';
import { Query } from '+shared/store/query';
import { SiteLiveState } from '+shared/store/site/types/siteLiveState.interface';

export const GET_BATTERY_QUERY = 'getBatteryQuery';
export const GET_BATTERY_ELECTRIC_UNITS_QUERY = 'getBatteryElectricUnitsQuery';
export const PUT_BATTERY_NOTE_QUERY = 'putBatteryNoteQuery';
export const GET_BATTERY_STATUSES_QUERY = 'getBatteryStatusesQuery';
export const POST_BATTERY_INTELLIGENT_CHARGING_QUERY = 'postBatteryIntelligentChargingQuery';
export const POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY =
  'postBatteryIntelligentChargingPollingQuery';
export const POST_BATTERY_OPERATING_MODE_QUERY = 'postBatteryOperatingModeQuery';
export const POST_BATTERY_OPERATING_MODE_POLLING_QUERY = 'postBatteryOperatingModePollingQuery';
export const POST_BATTERY_TIME_OF_USE_QUERY = 'postBatteryTimeOfUseQuery';
export const POST_BATTERY_HIGH_TARIFF_QUERY = 'postBatteryHighTariffQuery';
export const POST_BATTERY_BACKUP_BUFFER_QUERY = 'postBatteryBackupBufferQuery';
export const POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY = 'postBatteryBackupBufferPollingQuery';
export const GET_BATTERY_IC_STATUS_QUERY = 'getBatteryIcStatusQuery';

export interface BatteryPageState extends PersistedState {
  battery?: BatteryWithProduct;
  tariffWindows: TariffWindow[];
  tariffWindowsBackUp: TariffWindow[];
  editedTariffWindow: TariffWindow;
  timeOfUsePostSuccess: {
    offPeakSuccess: boolean;
    peakSuccess: boolean;
  };
  isTariffWindowScheduleEditedButNotSaved: boolean;
  batteryElectricUnits: BatteryElectricUnits[] | [];
  batteryIcStatus: BatteryIcStatus | undefined;
  isBatteryIntelligentChargingInProgress: boolean;
  isBatteryOperatingModeInProgress: boolean;
  isBatteryRemoteSettingsAllowed: boolean;
  isBatteryBackupBufferInProgress: boolean;
  [GET_BATTERY_QUERY]: Query<BatteryWithProductData>;
  [GET_BATTERY_ELECTRIC_UNITS_QUERY]: Query<BatteryElectricUnitsData>;
  [PUT_BATTERY_NOTE_QUERY]: Query<string>;
  [GET_BATTERY_STATUSES_QUERY]: Query<BatteryStatusesData>;
  [POST_BATTERY_INTELLIGENT_CHARGING_QUERY]: Query<BatteryOperationsData>;
  [POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY]: Query<BatteryWithProductData>;
  [POST_BATTERY_OPERATING_MODE_QUERY]: Query<BatteryOperationsData>;
  [POST_BATTERY_OPERATING_MODE_POLLING_QUERY]: Query<SiteLiveState>;
  [POST_BATTERY_TIME_OF_USE_QUERY]: Query<TariffWindow[]>;
  [POST_BATTERY_HIGH_TARIFF_QUERY]: Query<TariffWindow[]>;
  [POST_BATTERY_BACKUP_BUFFER_QUERY]: Query<BatteryOperationsData>;
  [POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY]: Query<BatteryWithProductData>;
  [GET_BATTERY_IC_STATUS_QUERY]: Query<BatteryIcStatusData>;
}

export const initialState: BatteryPageState = {
  battery: undefined,
  batteryElectricUnits: [],
  batteryIcStatus: undefined,
  tariffWindows: [],
  tariffWindowsBackUp: [],
  isTariffWindowScheduleEditedButNotSaved: false,
  editedTariffWindow: {
    id: '',
    start: '12:00',
    thresholdPMax: 0,
    stop: '14:00',
    type: TariffType.OFF_PEAK,
    thresholdPMaxStatus: false,
  },
  timeOfUsePostSuccess: {
    offPeakSuccess: false,
    peakSuccess: false,
  },
  isBatteryIntelligentChargingInProgress: false,
  isBatteryOperatingModeInProgress: false,
  isBatteryRemoteSettingsAllowed: false,
  isBatteryBackupBufferInProgress: false,
  [GET_BATTERY_QUERY]: {},
  [GET_BATTERY_ELECTRIC_UNITS_QUERY]: {},
  [PUT_BATTERY_NOTE_QUERY]: {},
  [GET_BATTERY_STATUSES_QUERY]: {},
  [POST_BATTERY_INTELLIGENT_CHARGING_QUERY]: {},
  [POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY]: {},
  [POST_BATTERY_OPERATING_MODE_QUERY]: {},
  [POST_BATTERY_OPERATING_MODE_POLLING_QUERY]: {},
  [POST_BATTERY_TIME_OF_USE_QUERY]: {},
  [POST_BATTERY_HIGH_TARIFF_QUERY]: {},
  [POST_BATTERY_BACKUP_BUFFER_QUERY]: {},
  [POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY]: {},
  [GET_BATTERY_IC_STATUS_QUERY]: {},
};
