import { UserRegistrationForm } from '../../shared/store/user';
import { ActionsUnion, createAction } from '../../utils';

export enum REGISTER_ACTIONS {
  SET_REGISTER_CREDENTIALS = '[Register] SET_REGISTER_CREDENTIALS',
  SET_REGISTER_FORM = '[Register] SET_REGISTER_FORM',
}

export const RegisterActions = {
  setRegisterCredentials: createAction(
    REGISTER_ACTIONS.SET_REGISTER_CREDENTIALS,
    (token: string, email: string) => ({ token, email })
  ),
  setRegisterForm: createAction(
    REGISTER_ACTIONS.SET_REGISTER_FORM,
    (form: UserRegistrationForm) => ({ form })
  ),
};

export type RegisterActions = ActionsUnion<typeof RegisterActions>;
