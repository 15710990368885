import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { fileUploadSettings } from '+setupTool/+form/store/+form.consts';
import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { DsoRegistrationRadioType } from '+setupTool/+form/store/types';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store/types';
import { AdditionalFeatures } from '+shared/store/setupTool/types';
import { dateUtil } from '+utils/date.util';

import {
  isGsmMeasurementsFieldsVisible,
  mapAdditionalFeaturesToFlags,
} from '../+vppDocumentation.helpers';

type AnySchema =
  | Yup.MixedSchema
  | Yup.StringSchema
  | Yup.NumberSchema
  | Yup.BooleanSchema
  | Yup.DateSchema
  | Yup.ArraySchema<any>
  | Yup.ObjectSchema<any>;

type VppDocumentationFields = keyof VppDocumentationInterface;
export type VppDocumentationSchema = Record<VppDocumentationFields, AnySchema>;

// Note: this has to be a function, otherwise translations are resolved at the point of time
// when they are not available
const vppDocumentationStandardSchema = (): Partial<VppDocumentationSchema> => ({
  battery_commissioning_date: Yup.string()
    .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
      value ? dateUtil.isValidDateFormat(value) : true
    )
    .test(
      'earliestDate',
      I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
      (value) => (value ? dateUtil.isAfter(dateUtil.of(value), '1899-12-31') : true)
    )
    .test(
      'invalidDateNewerThan',
      I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
      (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
    )
    .required(I18n.t(T.register.alert.requiredField)),
  fastening_type_grid: Yup.string().required(I18n.t(T.register.alert.requiredField)),
  meter_number: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
  type_of_grid_reference_meter: Yup.string().required(I18n.t(T.register.alert.requiredField)),
  [FileCategoryName.METER_PICTURES]: Yup.array()
    .min(1)
    .max(3)
    .required(I18n.t(T.register.alert.requiredField)),
  pv_reduction: Yup.boolean().oneOf([true]).required(I18n.t(T.register.alert.requiredField)),
});

/**
 * @warning potential side-effects
 */
const extendVppDocumentationSchema = (
  schema: Partial<VppDocumentationSchema>,
  additionalSchema: Partial<VppDocumentationSchema>
): void => {
  Object.assign(schema, additionalSchema);
};

export const getVppDocumentationSchema = (
  additionalFeatures: AdditionalFeatures[],
  isMultiplePvSystemsEnabled: boolean
): Yup.ObjectSchema<Partial<VppDocumentationSchema>> => {
  const vppDocumentationSchema = { ...vppDocumentationStandardSchema() };

  const {
    isVppCompletionNotificationVisible,
    isDsoReferenceNumberPvFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    isMeterCabinetPreparedFieldVisible,
    isProductionMeterFieldVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
    isVppWiringDiagramVisible,
  } = mapAdditionalFeaturesToFlags(additionalFeatures);

  if (isMultiplePvSystemsEnabled) {
    extendVppDocumentationSchema(vppDocumentationSchema, {
      virtual_pv_system_subsystem_1_commissioning_date: Yup.string()
        .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
          value ? dateUtil.isValidDateFormat(value) : true
        )
        .test(
          'earliestDate',
          I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
          (value) => (value ? dateUtil.isAfter(dateUtil.of(value), '1899-12-31') : true)
        )
        .test(
          'invalidDateNewerThan',
          I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
          (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
        )
        .required(I18n.t(T.register.alert.requiredField)),
      virtual_pv_system_subsystem_2_commissioning_date: Yup.string()
        .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
          value ? dateUtil.isValidDateFormat(value) : true
        )
        .test(
          'earliestDate',
          I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
          (value) => (value ? dateUtil.isAfter(dateUtil.of(value), '1899-12-31') : true)
        )
        .test(
          'invalidDateNewerThan',
          I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
          (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
        )
        .required(I18n.t(T.register.alert.requiredField)),
    });
  } else {
    extendVppDocumentationSchema(vppDocumentationSchema, {
      pv_commissioning_date: Yup.string()
        .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
          value ? dateUtil.isValidDateFormat(value) : true
        )
        .test(
          'earliestDate',
          I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
          (value) => (value ? dateUtil.isAfter(dateUtil.of(value), '1899-12-31') : true)
        )
        .test(
          'invalidDateNewerThan',
          I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
          (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
        )
        .required(I18n.t(T.register.alert.requiredField)),
    });
  }

  if (isVppCompletionNotificationVisible) {
    const completitionNotificationSettings =
      fileUploadSettings[FileCategoryName.COMPLETION_NOTIFICATION];

    extendVppDocumentationSchema(vppDocumentationSchema, {
      [FileCategoryName.COMPLETION_NOTIFICATION]: Yup.array()
        .min(completitionNotificationSettings.minUploadCount)
        .max(completitionNotificationSettings.maxUploadCount)
        .required(I18n.t(T.register.alert.requiredField)),
    });
  }

  if (isDsoReferenceNumberPvFieldVisible) {
    extendVppDocumentationSchema(vppDocumentationSchema, {
      dso_reference_number_pv: Yup.string()
        .max(20, I18n.t(T.validation.maxCharacters, { max: 20 }))
        .required(I18n.t(T.register.alert.requiredField)),
    });
  }

  if (isFasteningTypeConsumptionFieldVisible) {
    extendVppDocumentationSchema(vppDocumentationSchema, {
      fastening_type_consumption: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    });
  }

  if (isMeterCabinetPreparedFieldVisible) {
    extendVppDocumentationSchema(vppDocumentationSchema, {
      meter_cabinet_prepared: Yup.boolean()
        .oneOf([true])
        .required(I18n.t(T.register.alert.requiredField)),
    });
  }

  if (isProductionMeterFieldVisible) {
    extendVppDocumentationSchema(vppDocumentationSchema, {
      production_meter: Yup.string()
        .matches(/(Ja|Nein)/) // FIX ASAP
        .required(I18n.t(T.register.alert.requiredField)),
      number_production_meter: Yup.string().when('production_meter', {
        is: DsoRegistrationRadioType.YES,
        then: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
      }),
    });
  }

  if (
    isGsmMeasurementsFieldsVisible(
      isGsmMeasurementsRequiredFieldsVisible,
      isGsmMeasurementsOptionalFieldsVisible
    )
  ) {
    const signalMeasurementsPicturesSettings =
      fileUploadSettings[FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE];
    const lanConnectionPicturesSettings =
      fileUploadSettings[FileCategoryName.LAN_CONNECTION_PICTURE];

    extendVppDocumentationSchema(vppDocumentationSchema, {
      free_space_in_the_upper_part_of_the_meter_cabinet: Yup.boolean().required(
        I18n.t(T.register.alert.requiredField)
      ),
      upload_speed_mbits: Yup.number(),
      download_speed_mbits: Yup.number()
        .integer(I18n.t(T.validation.wholeNumber))
        .min(0, I18n.t(T.validation.greaterThanOrEqual))
        .max(2000, I18n.t(T.validation.lessThanOrEqual))
        .test(
          'is required based on signal_strength_dbm',
          I18n.t(T.register.alert.requiredField),
          function (value: number | undefined) {
            const { signal_strength_dbm } = this.parent;
            const isRequiredBasedOnSignalStrength = signal_strength_dbm
              ? true
              : value !== undefined;

            return isGsmMeasurementsOptionalFieldsVisible || isRequiredBasedOnSignalStrength;
          }
        ),
      ping_ms: Yup.number()
        .integer(I18n.t(T.validation.wholeNumber))
        .min(0, I18n.t(T.validation.greaterThanOrEqual))
        .max(1000, I18n.t(T.validation.lessThanOrEqual))
        .test(
          'is required based on signal_strength_dbm',
          I18n.t(T.register.alert.requiredField),
          function (value: number | undefined) {
            const { signal_strength_dbm } = this.parent;
            const isRequiredBasedOnSignalStrength = signal_strength_dbm
              ? true
              : value !== undefined;

            return isGsmMeasurementsOptionalFieldsVisible || isRequiredBasedOnSignalStrength;
          }
        ),
      signal_strength_dbm: Yup.number()
        .integer(I18n.t(T.validation.wholeNumber))
        .min(-200, I18n.t(T.validation.greaterThanOrEqual))
        .max(0, I18n.t(T.validation.lessThanOrEqual))
        .test(
          'is required based on ping_ms and download_speed_mbits',
          I18n.t(T.register.alert.requiredField),
          function (value: number | undefined) {
            const { download_speed_mbits, ping_ms } = this.parent;
            const isRequiredBasedOnPingAndDownloadSpeed =
              ping_ms && download_speed_mbits ? true : value !== undefined;

            return isGsmMeasurementsOptionalFieldsVisible || isRequiredBasedOnPingAndDownloadSpeed;
          }
        ),
      lan_connection_available: Yup.boolean().required(I18n.t(T.register.alert.requiredField)),
      [FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE]: Yup.array()
        .min(signalMeasurementsPicturesSettings.minUploadCount)
        .max(signalMeasurementsPicturesSettings.maxUploadCount)
        .test(
          'is required based on isGsmMeasurementsOptionalFieldsVisible',
          I18n.t(T.register.alert.requiredField),
          (value) => value !== undefined || isGsmMeasurementsOptionalFieldsVisible
        ),
      [FileCategoryName.LAN_CONNECTION_PICTURE]: Yup.mixed().when('lan_connection_available', {
        is: true,
        then: Yup.array()
          .min(lanConnectionPicturesSettings.minUploadCount)
          .max(lanConnectionPicturesSettings.maxUploadCount)
          .test(
            'is required based on isGsmMeasurementsOptionalFieldsVisible',
            I18n.t(T.register.alert.requiredField),
            (value) => value !== undefined || isGsmMeasurementsOptionalFieldsVisible
          ),
      }),
    });
  }

  if (isVppWiringDiagramVisible) {
    const wiringDiagramSettings = fileUploadSettings[FileCategoryName.WIRING_DIAGRAM_VPP];

    extendVppDocumentationSchema(vppDocumentationSchema, {
      [FileCategoryName.WIRING_DIAGRAM_VPP]: Yup.array()
        .min(wiringDiagramSettings.minUploadCount)
        .max(wiringDiagramSettings.maxUploadCount)
        .required(I18n.t(T.register.alert.requiredField)),
    });
  }

  return Yup.object().shape(vppDocumentationSchema);
};
