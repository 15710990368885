import { Locale } from '+app/i18n/i18n.config';
import { Market } from '+utils/market.util';

export enum ContactDetailsLocale {
  EN = 'EN',
  DE = 'DE',
  IT = 'IT',
  ES = 'ES',
}

export type ContactDetailsContent = Partial<Record<ContactDetailsLocale, string>>;

export interface ContactDetails {
  market: Market;
  eServiceSupport?: ContactDetailsContent;
  meterChangeSupport?: ContactDetailsContent;
  partnerSupport?: ContactDetailsContent;
  techSupport?: ContactDetailsContent;
  returnsSupport?: ContactDetailsContent;
}

export const mapLocaleToContactDetailsLocale = (
  isSpanishMarketEnabled: boolean,
  locale: string
): ContactDetailsLocale => {
  const mappedLocale = !isSpanishMarketEnabled && locale === Locale.ES ? Locale.EN_GB : locale;

  switch (mappedLocale) {
    case Locale.IT:
      return ContactDetailsLocale.IT;
    case Locale.DE:
      return ContactDetailsLocale.DE;
    case Locale.ES:
      return ContactDetailsLocale.ES;
    default:
      return ContactDetailsLocale.EN;
  }
};
