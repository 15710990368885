import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Icon, SideInfo } from '@sonnen/shared-web';

import { push } from 'connected-react-router';

import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { getLeadId } from '+app/+lead/store/+lead.selectors';
import { LEAD_IN_SETUP_STAGE, PATHS } from '+app/router';
import { DsoRegistrationTestIds as testIds } from '+config/testIds';
import { DsoSummaryFiles } from '+setupTool/+form/components';
import { DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY } from '+setupTool/+form/store';
import { getLatestModificationDate } from '+setupTool/+form/store/+form.selectors';
import { Overlay } from '+setupTool/components/Overlay';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { FormErrorBanner, FormSectionParagraph } from '+shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { dateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';
import { mapActions } from '+utils/redux/mapActions.util';

import { GENERATE_DOCUMENTS_QUERY } from '../../store';
import { DsoRegistrationActions } from '../../store/+dsoRegistration.actions';
import {
  getDsoGenerateDocumentsQuery,
  getDsoGenerateDocumentsQueryStatus,
  getDsoRegistrationDocuments,
  getLatestDocumentsGenerationDate,
} from '../../store/+dsoRegistration.selectors';

import './DsoRegistration.component.scss';

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  getDsoGenerateDocumentsQueryStatus: getDsoGenerateDocumentsQueryStatus(state),
  getDsoGenerateDocumentsQuery: getDsoGenerateDocumentsQuery(state),
  documents: getDsoRegistrationDocuments(state),
  latestDocumentsGenerationDate: getLatestDocumentsGenerationDate(state),
  latestFormModification: getLatestModificationDate(state),
});

const mapDispatchToProps = mapActions({
  generateDocuments: DsoRegistrationActions.generateDocuments,
  goToSetupDashboard: (leadId: string) => push(PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)),
  goToSetupData: (leadId: string) =>
    push(PATHS.SETUP_TOOL_SETUP_DATA({ leadId }, LEAD_IN_SETUP_STAGE)),
  clearQuery: QueryActions.init,
  toggleModal: LayoutActions.toggleModal,
  getLead: LeadActions.getLead,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const DsoRegistrationComponent: React.FC<Props> = ({
  actions,
  leadId,
  getDsoGenerateDocumentsQueryStatus,
  documents,
  latestDocumentsGenerationDate,
  latestFormModification,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.SETUP, PageName.Setup.DSO_REGISTRATION_MODAL);

  const overlayRef = React.useRef<HTMLDivElement>(null);
  const latestFormModificationDate = dateUtil.of(latestFormModification ?? dateUtil.now());
  const canGenerateDocuments =
    getDsoGenerateDocumentsQueryStatus.success ||
    (documents.length &&
      dateUtil.isBefore(latestFormModificationDate, latestDocumentsGenerationDate));

  useEffect(() => {
    if (getDsoGenerateDocumentsQueryStatus.success) {
      actions.clearQuery(DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY);
      if (leadId) {
        actions.getLead(leadId, LEAD_UPDATE_QUERY);
      }
    }
  }, [getDsoGenerateDocumentsQueryStatus]);

  useEffect(() => {
    actions.toggleModal(true);
    return () => {
      actions.toggleModal(false);
      actions.clearQuery(GENERATE_DOCUMENTS_QUERY);
    };
  }, []);

  return (
    <Overlay
      className={'c-setup-tool__dso-summary'}
      title={I18n.t(T.setupTool.step.dsoRegistration)}
      onClose={() => actions.goToSetupDashboard(leadId!)}
      ref={overlayRef}
    >
      <div className={'c-overlay__inner-content c-setup-tool__help-content'}>
        <SideInfo className={'c-help__side-info'}>
          <div className={`c-help__side-info-icon`}>
            <Icon.Info />
          </div>
          <div>
            <h3>{I18n.t(T.setupTool.dsoRegistration.help.header)}</h3>
            <p>
              <Translate value={T.setupTool.dsoRegistration.help.text} dangerousHTML={true} />
            </p>
            <br />
            <Button
              className={'c-dso-registration-go-to-setup-button'}
              label={I18n.t(T.setupTool.dsoRegistration.help.link)}
              type={ButtonType.TERTIARY}
              size={ButtonSize.MEDIUM}
              onClick={() => actions.goToSetupData(leadId!)}
              dataTestId={testIds.generateDocumentsButton}
            />
          </div>
        </SideInfo>
        {!canGenerateDocuments && (
          <div>
            <Button
              onClick={actions.generateDocuments}
              label={
                dateUtil.isBefore(
                  dateUtil.of(latestDocumentsGenerationDate),
                  latestFormModificationDate
                )
                  ? I18n.t(T.setupTool.dsoRegistration.regenerateDocuments)
                  : I18n.t(T.setupTool.dsoRegistration.generateDocuments)
              }
              loading={getDsoGenerateDocumentsQueryStatus.pending}
              dataTestId={testIds.generateDocumentsButton}
            />
          </div>
        )}
        {getDsoGenerateDocumentsQueryStatus.error && (
          <FormErrorBanner
            isVisible={getDsoGenerateDocumentsQueryStatus.error}
            error={I18n.t(T.setupTool.dsoRegistration.status.error)}
          />
        )}
      </div>
      {!!canGenerateDocuments && (
        <div className={'c-overlay__inner-content'}>
          <FormSectionParagraph>
            {I18n.t(T.setupTool.dsoRegistration.documentsHeader)}
            {!!canGenerateDocuments && (
              <span className={'c-setup-tool__dso-summary-status'}>
                <Icofont type={'tick'} /> {I18n.t(T.setupTool.dsoRegistration.status.success)}
              </span>
            )}
          </FormSectionParagraph>
          <h4>
            {I18n.t(T.setupTool.dsoRegistration.createdAt)}{' '}
            {formatDate(latestDocumentsGenerationDate)}
          </h4>

          <DsoSummaryFiles
            documents={documents}
            pending={getDsoGenerateDocumentsQueryStatus.pending}
          />

          <div className={'c-dso-registration-summary__help'}>
            <Icon.Info className={'c-dso-registration-summary__help-icon'} />
            <p>{I18n.t(T.setupTool.dsoRegistration.help.textBottom)}</p>
          </div>
        </div>
      )}
    </Overlay>
  );
};

export const DsoRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(DsoRegistrationComponent);
