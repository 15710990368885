import { Data, MergedData } from '@coolio/json-api';

import { RecordType } from '+shared/components/CaseReportForm/CaseCommon.types';

export const SF_ACCOUNT_FIELD_NAME = 'AccountId';
export const SF_CONTRACT_FIELD_NAME = 'Contract__c';

export enum CasePriority {
  NORMAL = 'Normal',
  HIGH = 'High',
}

export enum CaseType {
  INCIDENT = 'Incident',
  REQUEST = 'Request',
}

export enum CaseStatus {
  NEW = 'New',
}

export enum CaseCountry {
  AU = 'AU',
  DACH = 'DACH',
  IT = 'IT',
  ROW = 'ROW',
  UK = 'UK',
  US = 'US',
  ES = 'ES',
}

export enum CaseOrigin {
  PARTNER_PORTAL = 'Partner Portal',
}

/*
 *
 * Technical Case
 */
// Below enums as key-values pairs of "POEditor translation key = value sent to SalesForce"
export enum SonnenBatterieCategory {
  SYSTEM_OFFLINE_OR_FAULTY_DATA_1 = 'System offline or no/faulty data portal/app',
  SYSTEM_OFF_1 = 'System off or red eclipse',
  SYSTEM_OFF_2 = 'System off or red eclipse',
  NO_CHARGE_1 = 'No charge/discharge or wrong charge/discharge',
  SYSTEM_OFFLINE_OR_FAULTY_DATA_2 = 'System offline or no/faulty data portal/app',
  VISIBLE_AUDIBLE_FAULT = 'Visible /audible fault',
  OTHER_1 = 'Other',
  OTHER_2 = 'Other',
}
export enum SonnenChargerCategory {
  SONNEN_CHARGER_NOT_WORK_1 = 'sonnenCharger does not work',
  SONNEN_CHARGER_NOT_WORK_2 = 'sonnenCharger does not work',
  SONNEN_CHARGER_NOT_WORK_3 = 'sonnenCharger does not work',
  SONNEN_CHARGER_NOT_WORK_4 = 'sonnenCharger does not work',
  SYSTEM_OFFLINE_OR_FAULTY_DATA_3 = 'System offline or no/faulty data portal/app',
  SYSTEM_OFFLINE_OR_FAULTY_DATA_4 = 'System offline or no/faulty data portal/app',
  OTHER_3 = 'Other',
}
export enum PVSystemCategory {
  OTHER_4 = 'Other',
}
export enum HeatPumpCategory {
  OTHER_5 = 'Other',
}
export enum OtherCategory {
  ISSUES_SONNEN_PROTECT = 'Issues with sonnenProtect (emergency power device)',
  ISSUES_ACCESSORIES_1 = 'Accessories - issues related to them',
  ISSUES_ACCESSORIES_2 = 'Accessories - issues related to them',
  SYSTEM_OFFLINE_OR_FAULTY_DATA_5 = 'System offline or no/faulty data portal/app',
  NO_CHARGE_2 = 'No charge/discharge or wrong charge/discharge',
  NO_CHARGE_3 = 'No charge/discharge or wrong charge/discharge',
  OTHER_6 = 'Other',
  OTHER_7 = 'Other',
}
export enum TechnicalCaseCategory {
  SONNEN_BATTERY = 'sonnenBatterie',
  SONNEN_CHARGER = 'sonnenCharger',
  PV_SYSTEM = 'pvSystem',
  HEAT_PUMP = 'heatpump',
  OTHER = 'other',
}

export type TechnicalCaseSubcategory =
  | SonnenBatterieCategory
  | SonnenChargerCategory
  | PVSystemCategory
  | HeatPumpCategory
  | OtherCategory;
export type TechnicalCaseSubcategoryType =
  | typeof SonnenBatterieCategory
  | typeof SonnenChargerCategory
  | typeof PVSystemCategory
  | typeof HeatPumpCategory
  | typeof OtherCategory;

/*
 *
 * Energy Contract Case
 */
export enum EServicesCaseCategory {
  BOOKING = 'Booking / Billing',
  CESSION = 'Cession / Free amount',
  CUSTOMER_DATA = 'Customer data',
  DIRECT_MARKETING = 'Direct marketing',
  METER_CHANGE = 'Meter change',
  PRODUCT_INFORMATION = 'Product information',
  REMAINING = 'Remaining',
  SUPPLY = 'Supply',
  VPP = 'VPP',
}

export enum EServicesCaseSubcategory {
  REMAINING = 'Remaining',
}

export interface DynamicField {
  field: string;
  value: string;
}

export interface CreateCaseAttributes {
  recordType: RecordType;
  subject: string;
  description: string;
  type: CaseType;
  priority: CasePriority;
  status: CaseStatus;
  contactId: string;
  country: CaseCountry | null;
  origin: CaseOrigin;
  category?: EServicesCaseCategory | TechnicalCaseCategory | undefined;
  subcategory?: EServicesCaseSubcategory | TechnicalCaseSubcategory | undefined;
  asset?: string;
  dynamicFields?: DynamicField[];
}

export type CreateCaseData = Data<CreateCaseAttributes>;
export type CreateCase = MergedData<CreateCaseData>;
