import { __assign, __extends } from "tslib";
import { Context, RequiredViewChanges, RootCanvasView } from '@kanva/core';
import * as React from 'react';
import { KanvaContext } from './kanva-context';
var Kanva = (function (_super) {
    __extends(Kanva, _super);
    function Kanva(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        _this.htmlDivElement = null;
        _this.htmlCanvasElement = null;
        _this.resizeObserver = null;
        _this.setDivElement = function (ref) { return _this.htmlDivElement = ref; };
        _this.ctx = new Context(props.contextOptions);
        return _this;
    }
    Kanva.prototype.componentDidMount = function () {
        var enablePointerEvents = this.props.enablePointerEvents;
        var view = this.state.view;
        if (!view && this.htmlCanvasElement && this.htmlDivElement) {
            var view_1 = new RootCanvasView(this.ctx, this.htmlCanvasElement);
            this.setState({ view: view_1 });
            if (enablePointerEvents) {
                view_1.setupPointerEvents();
            }
            requestAnimationFrame(view_1.run);
            this.resizeObserver = new ResizeObserver(function () {
                view_1.require(RequiredViewChanges.MEASURE);
            });
            this.resizeObserver.observe(this.htmlDivElement);
        }
    };
    Kanva.prototype.componentDidUpdate = function () {
        var view = this.state.view;
        if (!view) {
            return;
        }
        var debug = this.props.debug;
        view.setDebugEnabled(debug || false);
    };
    Kanva.prototype.componentWillUnmount = function () {
        var view = this.state.view;
        if (view) {
            view.destroy();
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    };
    Kanva.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, children = _a.children, canvasRef = _a.canvasRef, style = _a.style;
        var view = this.state.view;
        return (React.createElement("div", { ref: this.setDivElement, style: __assign(__assign({}, style), { position: 'relative' }), className: className },
            React.createElement("canvas", { style: { position: 'absolute', left: 0, top: 0 }, ref: function (ref) {
                    _this.htmlCanvasElement = ref;
                    if (canvasRef) {
                        canvasRef(ref);
                    }
                } },
                React.createElement(KanvaContext.Provider, { value: {
                        ctx: this.ctx,
                        parent: view,
                    } }, children))));
    };
    return Kanva;
}(React.PureComponent));
export { Kanva };
