import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { LeadStatusName, OfferProductStatus } from '+shared/store/lead/types';

export const mapProductStatusToLeadStatus = (
  hardwareStatus: OfferProductStatus
): LeadStatusName | undefined =>
  ({
    [OfferProductStatus.SENT]: LeadStatusName.HW_OFFER_SENT,
    [OfferProductStatus.ACCEPTED]: LeadStatusName.HW_OFFER_ACCEPTED,
    [OfferProductStatus.CONFIRMED]: LeadStatusName.HW_ORDER_CONFIRMED,

    [OfferProductStatus.DRAFT]: undefined,
    [OfferProductStatus.DECLINED]: undefined,
    [OfferProductStatus.UNKNOWN]: undefined,
    [OfferProductStatus.DRAFT_EXPIRED]: undefined,
    [OfferProductStatus.SENT_EXPIRED]: undefined,
  }[hardwareStatus]);

export const hardwareProductStatusNameMapper = (
  hardwareStatus: OfferProductStatus
): string | undefined =>
  ({
    [OfferProductStatus.SENT]: I18n.t(T.shared._salessolution_.lead.status.hwOfferSent),
    [OfferProductStatus.ACCEPTED]: I18n.t(T.shared._salessolution_.lead.status.hwOfferAccepted),
    [OfferProductStatus.CONFIRMED]: I18n.t(T.shared._salessolution_.lead.status.hwOrderConfirmed),

    [OfferProductStatus.DRAFT]: undefined,
    [OfferProductStatus.DECLINED]: undefined,
    [OfferProductStatus.UNKNOWN]: undefined,
    [OfferProductStatus.DRAFT_EXPIRED]: undefined,
    [OfferProductStatus.SENT_EXPIRED]: undefined,
  }[hardwareStatus]);
