import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';

import { isEmpty } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { Logo } from '+app/+account/containers/Logo';
import { AccountPageActions } from '+app/+account/store';
import {
  getCertificates,
  getCertificatesQueryStatus,
  getUploadProfileLogoQueryStatus,
} from '+app/+account/store/+account.selector';
import { mapActions } from '+app/utils';
import { CountryFeatureName } from '+config/countryFlags';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { RolloutLimiter } from '+shared/components';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { StoreState } from '+shared/store/store.interface';
import { getUserCountryCode, getUserProfile } from '+shared/store/user/user.selectors';

import { CertificateList } from '../../components/CertificateList';
import { CertificateWarning } from '../../components/CertificateWarning';
import { ContactDetails } from '../../components/ContactDetails';
import { EventsAndTrainings } from '../../components/EventsAndTrainings';
import { PersonalDetails } from '../../components/PersonalDetails';
import { ResetPassword } from '../ResetPassword';

import './MyAccount.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userProfile: getUserProfile(state),
  logoQueryStatus: getUploadProfileLogoQueryStatus(state),
  certificates: getCertificates(state),
  certificatesQueryStatus: getCertificatesQueryStatus(state),
  userCountry: getUserCountryCode(state),
});

const mapDispatchToProps = mapActions({
  submitProfileLogo: AccountPageActions.submitProfileLogo,
  resetUploadProfileLogoQuery: AccountPageActions.resetUploadProfileLogoQuery,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const MyAccountComponent: React.FC<Props> = ({
  userProfile,
  logoQueryStatus,
  certificates,
  actions,
  certificatesQueryStatus,
  userCountry,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.ACCOUNT, PageName.Account.ACCOUNT_PERSONAL_DETAILS);

  const isCertificateListEmpty = isEmpty(certificates) && certificatesQueryStatus.success;
  const isCertificatesFeatureEnabled = useCountryFeature(
    CountryFeatureName.CERTIFICATIONS
  ).isEnabled;
  return (
    <div className={'c-my-account'}>
      {isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.CONTRACT_NOTIFICATIONS) &&
        isCertificateListEmpty && <CertificateWarning />}
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <PersonalDetails user={userProfile} />
        </div>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <ContactDetails user={userProfile} />
        </div>
      </div>
      <div className={'o-grid'}>
        <div
          className={'o-grid__column o-grid__column--md-6 c-account-company__column'}
          data-hj-suppress={true}
        >
          <Logo
            title={I18n.t(T.myAccount.logo.modal.title)}
            hint={I18n.t(T.myAccount.logo.modal.hint)}
            headline={I18n.t(T.myAccount.logo.title)}
            logotypeUrl={userProfile?.image}
            queryStatus={logoQueryStatus}
            submitLogo={actions.submitProfileLogo}
            resetUploadLogoQuery={actions.resetUploadProfileLogoQuery}
          />
        </div>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <ResetPassword />
        </div>
      </div>
      {isCertificatesFeatureEnabled && (
        <div className={'o-grid'}>
          <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
            {
              <CertificateList
                certificates={certificates}
                isLoading={certificatesQueryStatus.pending}
                userCountry={userCountry || CountryCode.DE}
              />
            }
          </div>
          <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
            <RolloutLimiter countryFeatureName={CountryFeatureName.EVENTS_AND_TRAININGS}>
              <EventsAndTrainings />
            </RolloutLimiter>
          </div>
        </div>
      )}
    </div>
  );
};

export const MyAccount = connect(mapStateToProps, mapDispatchToProps)(MyAccountComponent);
