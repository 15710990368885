import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';

import img from '+assets/img/community-events.png';
import { Button, ButtonType } from '+shared/components/Button';
import { goTo } from '+utils/browser.util';

import './EventsAndTrainings.component.scss';

export const EventsAndTrainings: React.FC<{}> = () => (
  <>
    <PageSubheadline mediumGap={true}>
      {I18n.t(T.myAccount.eventsAndTrainings.title)}
    </PageSubheadline>
    <div className={'c-events-and-trainings__container'}>
      <img className={'c-events-and-trainings__img'} src={img} />
      <div className={'c-events-and-trainings__data'}>
        <p className={'c-events-and-trainings__text'}>
          {I18n.t(T.myAccount.eventsAndTrainings.text)}
        </p>
        <Button
          label={I18n.t(T.myAccount.eventsAndTrainings.buttonText)}
          type={ButtonType.SECONDARY}
          onClick={() => goTo(I18n.t(T.myAccount.eventsAndTrainings.buttonLink), '_blank')}
        />
      </div>
    </div>
  </>
);
