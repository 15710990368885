import * as React from 'react';

import { Bubble, BubbleSideProps } from '@sonnen/shared-web';

import classNames from 'classnames';

import { HorizontalPositionProps } from './TooltipHoverable.type';

import './TooltipHoverable.component.scss';

interface TooltipHoverableProps {
  className?: ClassValue;
  title: React.ReactNode;
  isHoveringDisabled?: boolean;
  bubbleSide?: BubbleSideProps;
  horizontalPosition?: HorizontalPositionProps;
  topHeight?: number;
}

const calculateBubblePosition = (
  element: HTMLDivElement | null,
  horizontalPosition: HorizontalPositionProps,
  topHeight: number
) => {
  const rect = element?.getBoundingClientRect();

  return {
    top: rect ? -topHeight : 0,
    left: rect ? horizontalPosition : 0,
  };
};

export const TooltipHoverable: React.FC<TooltipHoverableProps> = ({
  children,
  className,
  title,
  isHoveringDisabled = false,
  bubbleSide = 'bottom',
  horizontalPosition = HorizontalPositionProps.MIDDLE,
  topHeight = 75,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const { top, left } = calculateBubblePosition(wrapperRef.current, horizontalPosition, topHeight);

  return (
    <div
      ref={wrapperRef}
      className={classNames('tooltip-hoverable', className)}
      {...(!isHoveringDisabled && {
        onMouseEnter: () => setIsActive(true),
        onMouseLeave: () => setIsActive(false),
      })}
    >
      {children}
      {isActive && (
        <div style={{ top, left }} className={'tooltip-hoverable__bubble'}>
          <Bubble side={bubbleSide}>{title}</Bubble>
        </div>
      )}
    </div>
  );
};
