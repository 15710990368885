import * as React from 'react';

interface SetupLeadContextProps {
  leadStage?: string;
}

export const SetupLeadContext = React.createContext<SetupLeadContextProps>({
  leadStage: undefined,
});

export const useSetupLeadContext = () => React.useContext(SetupLeadContext);
