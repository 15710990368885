import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';

export interface VppDocumentationInterface {
  pv_commissioning_date: string;
  battery_commissioning_date: string;
  production_meter: string;
  number_production_meter: string;
  fastening_type_grid: string;
  fastening_type_consumption: string;
  meter_number: string;
  type_of_grid_reference_meter: string;
  dso_reference_number_pv?: string;
  pv_reduction: boolean;
  meter_cabinet_prepared: boolean;
  free_space_in_the_upper_part_of_the_meter_cabinet?: boolean;
  virtual_pv_system?: boolean;
  virtual_pv_system_subsystem_1_name?: string;
  virtual_pv_system_subsystem_2_name?: string;
  virtual_pv_system_subsystem_1_commissioning_date?: string;
  virtual_pv_system_subsystem_2_commissioning_date?: string;
  upload_speed_mbits?: number;
  download_speed_mbits?: number;
  ping_ms?: number;
  signal_strength_dbm?: number;
  lan_connection_available?: boolean;
  [FileCategoryName.METER_PICTURES]?: any;
  [FileCategoryName.WIRING_DIAGRAM_VPP]?: any;
  [FileCategoryName.COMPLETION_NOTIFICATION]?: any;
  [FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE]?: any;
  [FileCategoryName.LAN_CONNECTION_PICTURE]?: any;
}

export enum VppState {
  DISABLED = 'disabled',
  ALLOWED_TO_DRAFT = 'allowed_to_draft',
  ALLOWED_TO_PERFORM = 'allowed_to_perform',
  ALLOWED_TO_PERFORM_AGAIN = 'allowed_to_perform_again',
  PERFORMED = 'performed',
  HIDDEN = 'hidden',
}
