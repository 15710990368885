import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { dataGuard, mapToState, matchPath, ofType, processQuery } from '+app/utils';
import { StoreState } from '+shared/store/store.interface';
import { UserActions } from '+shared/store/user';
import { UserRepository } from '+shared/store/user/user.repository';
import { getUserProfileId } from '+shared/store/user/user.selectors';

import { ROUTES } from '../../router';
import { RouterActions } from '../../router/store';
import { AccountPageActions } from './+account.actions';
import {
  ACCOUNT_PASSWORD_QUERY,
  COMPANY_DOCS_UPLOAD_QUERY,
  COMPANY_LOGO_UPLOAD_QUERY,
  GET_CERTIFICATES_QUERY,
  PROFILE_LOGO_UPLOAD_QUERY,
} from './+account.state';

type Action$ = ActionsObservable<AccountPageActions>;
type State$ = StateObservable<StoreState>;

const editPassword$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AccountPageActions.editPassword),
    map((action) => action.passwordForm),
    withLatestFrom(state$),
    map(([passwordForm, state]) => ({
      passwordForm,
      userId: getUserProfileId(state),
    })),
    map(({ passwordForm, userId }) =>
      UserActions.editPassword({
        queryKey: ACCOUNT_PASSWORD_QUERY,
        passwordForm,
        userId,
      })
    )
  );

const updateCompanyDocs$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AccountPageActions.submitCompanyDocs),
    map((action) => action.uploadDocsForm),
    withLatestFrom(state$),
    map(([data, state]) => ({
      userId: getUserProfileId(state),
      termsAndCondition: data.termsAndConditions,
      cancellationPolicy: data.cancellationPolicy,
      dataPrivacyPolicy: data.dataPrivacyPolicy,
    })),
    mergeMap(({ userId, termsAndCondition, cancellationPolicy, dataPrivacyPolicy }) =>
      of({}).pipe(
        processQuery(
          COMPANY_DOCS_UPLOAD_QUERY,
          () =>
            UserRepository.putUserCompanyDocs(
              userId,
              termsAndCondition,
              cancellationPolicy,
              dataPrivacyPolicy
            ),
          { onSuccess: (res) => dataGuard(UserActions.setCompany)(res!.element) }
        )
      )
    )
  );

const updateCompanyLogo$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AccountPageActions.submitCompanyLogo),
    map((action) => action.uploadLogoForm),
    withLatestFrom(state$),
    map(([data, state]) => ({
      userId: getUserProfileId(state),
      attachment: data.attachment,
    })),
    mergeMap(({ userId, attachment }) =>
      of({}).pipe(
        processQuery(
          COMPANY_LOGO_UPLOAD_QUERY,
          () => UserRepository.putUserCompanyLogo(userId, attachment),
          { onSuccess: (res) => dataGuard(UserActions.setCompany)(res!.element) }
        )
      )
    )
  );

const updateProfileLogo$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AccountPageActions.submitProfileLogo),
    map((action) => action.uploadLogoForm),
    withLatestFrom(state$),
    map(([data, state]) => ({
      userId: getUserProfileId(state),
      attachment: data.attachment,
    })),
    mergeMap(({ userId, attachment }) =>
      of({}).pipe(
        processQuery(
          PROFILE_LOGO_UPLOAD_QUERY,
          () => UserRepository.putUserProfileImage(userId, attachment),
          { onSuccess: (res) => dataGuard(UserActions.setUserProfile)(res!.element) }
        )
      )
    )
  );

export const getCertificateList$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    mapToState(state$),
    matchPath(ROUTES.ACCOUNT[0]),
    withLatestFrom(state$),
    map(([_, state]) =>
      AccountPageActions.getCertificateList({
        contactId: getUserProfileId(state),
        queryKey: GET_CERTIFICATES_QUERY,
      })
    )
  );

export const epics = combineEpics(
  editPassword$,
  updateCompanyLogo$,
  updateCompanyDocs$,
  updateProfileLogo$,
  getCertificateList$
);
