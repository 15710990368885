import { tail } from 'lodash/fp';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { getPathParams } from '../../router/store/router.helpers';
import { getRouterLocationPath } from '../../router/store/router.selectors';
import { StoreState } from '../../shared/store/store.interface';
import { matchPath } from './matchPath.operator';

export const mapPathToParams =
  (...pathsToMatch: string[]) =>
  (o: Observable<StoreState>) =>
    o.pipe(
      matchPath(pathsToMatch),
      map(getRouterLocationPath),
      map(getPathParams(pathsToMatch)),
      filter((params) => !!params),
      map((params) => params as NonNullable<typeof params>),
      map(tail)
    );
