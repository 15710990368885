import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Button, ButtonSize, MainType } from '+shared/components/Button';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';

import './LogoutFromFirebase.component.scss';

interface OwnProps {
  onLogout: () => void;
}

type Props = FirebaseProps & OwnProps;

interface Error {
  code: string;
  message: string;
}

export class LogoutFromFirebaseComponent extends React.Component<Props> {
  state = {
    error: '',
  };

  logout = () => {
    const { firebase, onLogout } = this.props;
    firebase
      .firestoreSignout()
      .then(() => onLogout())
      .catch((err: Error) => this.setState({ error: err.message }));
  };

  render() {
    return (
      <>
        <Button
          className={'c-logout-from-firebase__button'}
          mainType={MainType.SUBMIT}
          label={I18n.t(T.news.channel.logout)}
          size={ButtonSize.SMALL}
          onClick={this.logout}
        />
        {this.state.error}
      </>
    );
  }
}

export const LogoutFromFirebase = withFirebase(LogoutFromFirebaseComponent);
