import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { LabelLarge } from '+shared/basicComponents/Typography/Labels';
import { IconWarningCircleComponent } from '+shared/components';

import {
  isAmortizationLessThan1Year,
  isAmortizationOver20Years,
} from '../LeadImpactAnalysisCalculationResults.helper';
import { PropsAmortizationInformation } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

const AmortizationInformation: React.FC<PropsAmortizationInformation> = ({
  amortizationTimeFrame,
}) => (
  <>
    <div
      className={`c-impact-analysis-results
        c-impact-analysis-results-box-mint`}
    >
      <div className="c-impact-analysis-results__row">
        <LabelLarge
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.title)}
        />
        <div className="c-impact-analysis-results__row-value-wrapper">
          <LabelLarge
            text={
              <div className="c-impact-analysis-results-amortization-info">
                {(isAmortizationOver20Years(amortizationTimeFrame) ||
                  isAmortizationLessThan1Year(amortizationTimeFrame)) && (
                  <IconWarningCircleComponent />
                )}
                {isAmortizationOver20Years(amortizationTimeFrame)
                  ? I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.warning)
                  : isAmortizationLessThan1Year(amortizationTimeFrame)
                  ? I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.lessThan1year)
                  : I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.info, {
                      years: amortizationTimeFrame!.years,
                      months: amortizationTimeFrame!.months,
                    })}
              </div>
            }
          />
        </div>
      </div>
    </div>
  </>
);

export default AmortizationInformation;
