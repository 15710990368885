import React, { memo, useMemo, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { ModalLayout } from '@sonnen/shared-web';

import { Formik, FormikProps } from 'formik';
import { isObject } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { FormInputRadioBoxGroup, FormInputSelect } from '+shared/components';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import { CaseActions as SharedCaseActions } from '+shared/store/case';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';

import { Button, ButtonStatus, MainType } from '../Button';
import { FormInput } from '../Form';
import { getCommonSubmitFields, getTechnicalCaseLabel, isEServices } from './CaseCommon.helper';
import { CaseFormField, CaseFormValues, initialValues, RecordTypeCase } from './CaseCommon.types';
import { CaseFormHelper } from './CaseReportForm.helper';

import './CaseReportForm.component.scss';

interface Props {
  activeSite?: CaseSite;
  partnerContactId: string;
  isLoading: boolean;
  submitCaseForm: typeof SharedCaseActions.createCase;
  siteComponent: React.ReactNode;
  userCountry: CountryCode | undefined;
}

const {
  validationSchema,
  resetFormValues,
  getCaseTypesComponentData,
  getSubject,
  getDynamicFields,
} = CaseFormHelper;

export const CaseReportForm: React.FC<Props> = memo(
  ({ isLoading, submitCaseForm, partnerContactId, userCountry, activeSite, siteComponent }) => {
    const { useTrackPageLoadDependingOnRoute } = useAdobeAnalyticsTracking();
    useTrackPageLoadDependingOnRoute('REPORT_CASE_FORM_MODAL');

    const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');

    const reportFormRef = useRef<FormikProps<CaseFormValues>>(null);

    const [isFormValid, setFormValidationState] = useState(false);

    const recordTypesObject = useMemo(() => {
      if (reportFormRef.current) resetFormValues(reportFormRef.current);
      return getCaseTypesComponentData(activeSite);
    }, [activeSite]);

    const onFormSubmit = (values: CaseFormValues) => {
      if (!values.recordType) return;

      const { contract, battery, salesforceAccountId } = activeSite || {};
      const { recordType, description, category, subcategory } = values;

      const eServices = isEServices(recordType.key);
      const subject = getSubject({
        recordType,
        category: values.category,
        batteryNumber: battery?.serialNumber,
        contractNumber: contract?.contractNumber,
      });

      submitCaseForm({
        recordType: eServices ? RecordTypeCase.E_SERVICES : RecordTypeCase.TECHNICAL,
        subject,
        description,
        contactId: partnerContactId,
        asset: battery?.salesforceAssetId,
        dynamicFields: getDynamicFields(contract?.salesforceContractId, salesforceAccountId),
        ...getCommonSubmitFields(
          category,
          subcategory,
          recordType.key,
          isSpanishMarketEnabled,
          userCountry
        ),
      });
    };

    const clearField = (form: FormikProps<CaseFormValues>, field: CaseFormField) => {
      form.setFieldValue(field, '');
    };

    const handleSubmitClick = () => {
      if (reportFormRef.current) reportFormRef.current.submitForm();
    };

    return (
      <ModalLayout
        title={I18n.t(T.report.headline)}
        subtitle={I18n.t(T.report.paragraph)}
        footer={
          <Button
            className="c-case-report-form__button"
            mainType={MainType.SUBMIT}
            loading={isLoading}
            label={I18n.t(T.report.submitButton)}
            onClick={handleSubmitClick}
            disabled={!isFormValid}
            dataTestId="send-case-btn"
            status={!isFormValid ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
          />
        }
      >
        {siteComponent}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
          onSubmit={onFormSubmit}
          innerRef={reportFormRef}
          render={(form) => {
            const {
              handleSubmit,
              values: { recordType, category },
              isValid,
            } = form;

            const technicalCase = recordType?.key === RecordTypeCase.TECHNICAL;
            const caseLabel = I18n.t(getTechnicalCaseLabel(technicalCase));
            setFormValidationState(isValid);

            const recordTypes = Object.values(recordTypesObject);
            const categories = recordType
              ? recordTypesObject[recordType.key]?.categories
              : undefined;
            const subcategories = isObject(category) ? category.subcategories : undefined;

            return (
              <form onSubmit={handleSubmit} className="c-case-report-form">
                <div className="c-case-report-form__body">
                  <div className="c-case-report-form__section">
                    <FormInputRadioBoxGroup
                      name={CaseFormField.RECORD_TYPE}
                      form={form}
                      collection={recordTypes}
                      onCheck={() => clearField(form, CaseFormField.CATEGORY)}
                      label={I18n.t(T.report.form.labels.type)}
                      isDisabled={!activeSite}
                    />
                  </div>

                  <div className="c-case-report-form__section">
                    {categories ? (
                      <FormInputSelect
                        name={CaseFormField.CATEGORY}
                        form={form}
                        className="c-case-report-form__topic"
                        collection={categories}
                        mapper={(category) => category.name}
                        label={caseLabel}
                        dataTestId="case-category-field"
                        onSelect={() => clearField(form, CaseFormField.SUBCATEGORY)}
                      />
                    ) : (
                      <FormInput
                        name={CaseFormField.CATEGORY}
                        className="c-case-report-form__topic"
                        form={form}
                        disabled={!activeSite}
                        label={I18n.t(T.report.form.labels.category)}
                        dataTestId="case-category-field"
                      />
                    )}
                  </div>

                  {subcategories && (
                    <div className="c-case-report-form__section">
                      <FormInputSelect
                        name={CaseFormField.SUBCATEGORY}
                        form={form}
                        className="c-case-report-form__topic"
                        collection={subcategories}
                        mapper={(category) => category.name}
                        label={I18n.t(T.report.form.labels.category)}
                        dataTestId="case-category-field"
                      />
                    </div>
                  )}

                  <div className="c-case-report-form__section">
                    <div className="c-case-report-form__input">
                      <FormTextarea
                        form={form}
                        placeholder={I18n.t(T.report.form.placeholders.description)}
                        name={CaseFormField.DESCRIPTION}
                        className="c-case-report-form__textarea"
                        disabled={!activeSite}
                        dataTestId="case-description-field"
                        label={I18n.t(T.report.form.labels.description)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </ModalLayout>
    );
  }
);
