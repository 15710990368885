var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BodyCasing, bodyParser, bodySerializer, ContentType, HttpClient } from '@coolio/http';
import { createAuthInterceptor } from './authInterceptor';
import { AuthError } from './authError';
export var createOAuth2Interceptor = function (_a) {
    var _b = _a.oauth, clientId = _b.clientId, clientSecret = _b.clientSecret, contentType = _b.contentType, refreshTokenUrl = _b.refreshTokenUrl, httpClientOptions = _b.httpClientOptions, authStorage = _a.authStorage, canAuthorize = _a.canAuthorize, onAuthorizationFailure = _a.onAuthorizationFailure;
    var authHttpClient = new HttpClient(__assign({ requestHandler: httpClientOptions.requestHandler, bodyParser: bodyParser({ bodyCasing: BodyCasing.CAMEL_CASE }), bodySerializer: bodySerializer({ bodyCasing: BodyCasing.SNAKE_CASE }) }, httpClientOptions));
    var getAuthData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var authData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, Promise.resolve(authStorage.getData())];
                case 1:
                    authData = _a.sent();
                    if (!authData) {
                        throw new AuthError('Data received from AuthStorage is undefined.');
                    }
                    return [2, authData];
            }
        });
    }); };
    return createAuthInterceptor({
        reauthorize: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, _a, _b, _c, _d, _e, oauth2Data;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _b = (_a = authHttpClient).post;
                        _c = [refreshTokenUrl];
                        _d = {
                            headers: {
                                'Content-Type': contentType || ContentType.URL_ENCODED,
                            }
                        };
                        _e = {
                            clientId: clientId,
                            clientSecret: clientSecret,
                            grantType: 'refresh_token'
                        };
                        return [4, getAuthData()];
                    case 1: return [4, _b.apply(_a, _c.concat([(_d.body = (_e.refreshToken = (_f.sent()).refreshToken,
                                _e),
                                _d)]))];
                    case 2:
                        response = _f.sent();
                        return [4, response.parsedBody()];
                    case 3:
                        oauth2Data = _f.sent();
                        return [4, Promise.resolve(authStorage.setData(oauth2Data))];
                    case 4:
                        _f.sent();
                        return [2];
                }
            });
        }); },
        setAuthorizationData: function (options) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, accessToken, tokenType;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, getAuthData()];
                    case 1:
                        _a = _b.sent(), accessToken = _a.accessToken, tokenType = _a.tokenType;
                        options.headers['Authorization'] = tokenType ? tokenType + " " + accessToken : accessToken;
                        return [2];
                }
            });
        }); },
        onAuthorizationFailure: onAuthorizationFailure,
        canAuthorize: canAuthorize,
    });
};
