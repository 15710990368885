import { Views } from '@sonnen/shared-web';

import { createLayoutMap, MATCH_PARENT, PARENT_ID, WRAP_CONTENT } from '@kanva/core';

const DESKTOP_PADDING = 10;
const TOP_DISTANCE = 25;
const Y_AXIS_PADDING = 4;
const X_AXIS_PADDING = 4;
const X_AXIS_MARGIN = 10;

export const webLayout = createLayoutMap({
  chartWrapper: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    above: Views.X_AXIS,
    alignEnd: PARENT_ID,
    margin: {
      top: TOP_DISTANCE,
    },
  },
  grid: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  barChart: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: WRAP_CONTENT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
    minHeight: X_AXIS_MARGIN + 20,
    padding: {
      top: X_AXIS_PADDING + DESKTOP_PADDING,
    },
  },
  yAxis: {
    width: WRAP_CONTENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignStart: PARENT_ID,
    alignBottom: PARENT_ID,
    above: Views.X_AXIS,
    padding: {
      top: TOP_DISTANCE,
      left: Y_AXIS_PADDING + DESKTOP_PADDING,
    },
  },
});

export const mobileLayout = createLayoutMap({
  chartWrapper: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    above: Views.X_AXIS,
    alignEnd: PARENT_ID,
    margin: {
      top: TOP_DISTANCE,
    },
  },
  grid: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  barChart: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: WRAP_CONTENT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
    minHeight: X_AXIS_MARGIN + 20,
    padding: {
      top: X_AXIS_PADDING,
    },
  },
  yAxis: {
    width: WRAP_CONTENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignStart: PARENT_ID,
    alignBottom: PARENT_ID,
    above: Views.X_AXIS,
    padding: {
      top: TOP_DISTANCE,
      left: Y_AXIS_PADDING,
    },
  },
});
