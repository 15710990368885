import * as React from 'react';

import { DefaultParagraph } from '@sonnen/shared-web/src/components/DefaultParagraph';

import classNames from 'classnames';

import { PageNames, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';

import './ModalLayout.scss';

export type ModalLayoutProps = {
  title?: string;
  subtitle?: string;
  status?: React.ReactNode;
  footer?: React.ReactNode;
  bodyForwarderRef?: React.RefObject<any>;
  withFooterBorder?: boolean;
  dataTestIdTitle?: string;
  section?: Sections;
  page?: PageNames;
};

const ModalLayout: React.FC<ModalLayoutProps> = ({
  title,
  subtitle,
  status,
  footer,
  children,
  bodyForwarderRef,
  withFooterBorder = true,
  dataTestIdTitle,
  section,
  page,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(section, page);

  return (
    <div className={'sw-modal-layout'}>
      {(title || subtitle) && (
        <div className={'sw-modal-layout__header'}>
          {title && (
            <div className={'sw-modal-layout__title-wrapper'}>
              <h2
                className={classNames('sw-modal-layout__title', {
                  'sw-modal-layout__title--big': subtitle,
                })}
                data-testid={dataTestIdTitle}
              >
                {title}
              </h2>
              {status && <div className={'sw-modal-layout__status'}>{status}</div>}
            </div>
          )}
          {subtitle && (
            <div className={'sw-modal-layout__subtitle'}>
              <DefaultParagraph>{subtitle}</DefaultParagraph>
            </div>
          )}
        </div>
      )}
      <div className={'sw-modal-layout__body'} ref={bodyForwarderRef}>
        <div className={'sw-modal-layout__body-inner'}>{children}</div>
      </div>
      {footer && (
        <div
          className={classNames('sw-modal-layout__footer', {
            'sw-modal-layout__footer--with-border': withFooterBorder,
          })}
        >
          {footer}
        </div>
      )}
    </div>
  );
};

export default ModalLayout;
