import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { dateUtil } from '+utils/date.util';

import { FileCategoryName } from '../+form.dictionary';
import { DsoRegistrationCustomerDataInterface, DsoRegistrationRadioType } from '../types';

export const DsoRegistrationCustomerDataSchema = () =>
  Yup.object().shape({
    customer_birthday: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test('minDOB', I18n.t(T.setupTool.validation.minDOB), (value) =>
        value ? dateUtil.getDifference(value, dateUtil.now(), 'years') >= 18 : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), '1899-12-31') : true)
      )
      .required(I18n.t(T.register.alert.requiredField)),
    customer_type: Yup.string()
      .matches(/(Ja|Nein)/)
      .required(I18n.t(T.register.alert.requiredField)),
    lot_number: Yup.string()
      .trim()
      .max(10, I18n.t(T.register.alert.max10Characters))
      .required(I18n.t(T.register.alert.requiredField)),
    dso_id: Yup.string().required(I18n.t(T.register.alert.requiredField)),
  });

export const DsoRegistrationCustomerDataInitial: DsoRegistrationCustomerDataInterface = {
  customer_salutation: '',
  customer_first_name: '',
  customer_last_name: '',
  origin_customer_street_and_number: '',
  customer_zip_code: '',
  customer_city: '',
  customer_email: '',
  customer_phone: '',
  customer_birthday: '',
  customer_type: DsoRegistrationRadioType.YES,
  origin_asset_street_and_number: '',
  asset_zip_code: '',
  asset_city: '',
  lot_number: '',
  dso_id: '',
  is_online_portal: false,
  [FileCategoryName.POWER_OF_ATTORNEY]: undefined,
};

export const DsoRegistrationCustomerDataFiles = [FileCategoryName.POWER_OF_ATTORNEY];

export const saveableCustomerDataFields = [
  'customer_birthday',
  'customer_type',
  'lot_number',
  'dso_id',
  'is_online_portal',
];
