import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux/createReducer.util';

import { LEAD_OFFER_PAGE_ACTIONS, LeadOfferPageActions } from './+offer.actions';
import {
  GET_OFFER_QUERY,
  initialState,
  LeadOfferPageState,
  PATCH_UTILITY_DATA_QUERY,
  POST_OFFER_QUERY,
  SEND_OFFER_QUERY,
} from './+offer.state';

export const reducer = createReducer<LeadOfferPageState, LeadOfferPageActions>(
  (state = initialState, action): LeadOfferPageState => {
    switch (action.type) {
      case LEAD_OFFER_PAGE_ACTIONS.SET_OFFER:
        return {
          ...state,
          offer: action.offer,
        };
      case LEAD_OFFER_PAGE_ACTIONS.SET_ENERGY_PROVIDERS:
        return {
          ...state,
          energyProviders: action.energyProviders,
        };
      case LEAD_OFFER_PAGE_ACTIONS.SET_UTILITY_DATA:
        return {
          ...state,
          utilityData: action.utilityData,
        };
      case LEAD_OFFER_PAGE_ACTIONS.CLEAR_DATA:
        return initialState;
      default:
        return queryReducer(state, action, [
          POST_OFFER_QUERY,
          SEND_OFFER_QUERY,
          GET_OFFER_QUERY,
          PATCH_UTILITY_DATA_QUERY,
        ]);
    }
  }
);
