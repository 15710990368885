import * as React from 'react';

import { PageWidthWrapper } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import './Container.component.scss';

interface Props {
  withVerticalPadding?: boolean;
  withHorizontalPadding?: boolean;
  className?: ClassValue;
  id?: string;
}

/**
 * @deprecated
 * use instead ResponsivePageWidth wrapper from 'shared/templates/ResponsivePageWidth'
 */
export const Container: React.SFC<Props> = ({
  children,
  className,
  id,
  withVerticalPadding = true,
  withHorizontalPadding = true,
}) => (
  <PageWidthWrapper>
    <section
      className={classNames(
        'c-container',
        {
          'c-container--with-v-padding': withVerticalPadding,
          'c-container--with-h-padding': withHorizontalPadding,
        },
        className
      )}
      id={id}
    >
      {children}
    </section>
  </PageWidthWrapper>
);
