import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import './AccordionButton.component.scss';

interface Props {
  isAccordionOpen: boolean;
  setAccordionOpen: (
    isAccordionOpen: boolean,
    event?: React.SyntheticEvent<HTMLButtonElement>
  ) => void;
  messageOpen: string;
  messageClosed: string;
  additionalIcon?: React.ReactNode;
}

export const AccordionButton: React.FC<Props> = ({
  isAccordionOpen,
  setAccordionOpen,
  messageOpen,
  messageClosed,
  additionalIcon,
}) => (
  <button
    onClick={(event) => setAccordionOpen(!isAccordionOpen, event)}
    className={'c-accordion-button'}
  >
    {additionalIcon && (
      <div className={'c-accordion-button__start-adornment'}>{additionalIcon}</div>
    )}
    <div className={'c-accordion-button__text'}>
      {isAccordionOpen ? messageOpen : messageClosed}
    </div>
    <div
      className={classNames('c-accordion-button__icon', {
        'is-rotated': isAccordionOpen,
      })}
    >
      <Icon.Angle />
    </div>
  </button>
);
