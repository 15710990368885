var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpMethod, } from './httpClient.types';
import { bodySerializer } from './bodySerializer';
import { cacheParsedBody } from './helpers/parsedBodyCache.helper';
import { urlCombine, urlDestruct } from './helpers/urlEncoding.helper';
import { sanitizeHeaders } from './helpers';
import { bodyParser } from './bodyParser';
export var DEFAULT_REQUEST_TIMEOUT_MS = 5 * 60 * 1000;
var isHttpInterceptorInterface = function (interceptor) {
    var method = interceptor.onIntercept;
    return method !== null && method !== undefined;
};
var useInterceptor = function (normalizedOptions) { return function (req, interceptor) {
    if (isHttpInterceptorInterface(interceptor)) {
        return interceptor.onIntercept(req, normalizedOptions);
    }
    return interceptor(req, normalizedOptions);
}; };
var HttpClient = (function () {
    function HttpClient(config) {
        var _this = this;
        this.interceptors = [];
        this.addInterceptor = function (interceptor) {
            _this.interceptors.push(interceptor);
            return _this;
        };
        this.get = function (uri, options) {
            return _this.request(uri, __assign(__assign({}, options), { body: undefined, method: HttpMethod.GET }));
        };
        this.post = function (uri, options) {
            return _this.request(uri, __assign(__assign({}, options), { method: HttpMethod.POST }));
        };
        this.put = function (uri, options) {
            return _this.request(uri, __assign(__assign({}, options), { method: HttpMethod.PUT }));
        };
        this.patch = function (uri, options) {
            return _this.request(uri, __assign(__assign({}, options), { method: HttpMethod.PATCH }));
        };
        this.delete = function (uri, options) {
            return _this.request(uri, __assign(__assign({}, options), { body: undefined, method: HttpMethod.DELETE }));
        };
        this.remove = function (uri, options) { return _this.delete(uri, options); };
        this.handle = config.requestHandler;
        this.bodyParser = config.bodyParser || bodyParser();
        this.bodySerializer = config.bodySerializer || bodySerializer();
        this.headers = config.headers;
        this.baseUrl = config.baseUrl ? config.baseUrl.replace(/\/+$/, '') : undefined;
        this.queryParserOptions = config.queryParserOptions;
        this.querySerializerOptions = config.querySerializerOptions;
        this.defaultRequestTimeout = config.requestTimeout || DEFAULT_REQUEST_TIMEOUT_MS;
    }
    HttpClient.prototype.request = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var urlBreakdown, normalizedOptions, chain;
            var _this = this;
            return __generator(this, function (_a) {
                if (this.baseUrl && url.startsWith('/')) {
                    url = "" + this.baseUrl + url;
                }
                urlBreakdown = urlDestruct(urlCombine(url, options && options.query), this.queryParserOptions);
                normalizedOptions = __assign(__assign({}, options), { timeout: options.timeout || this.defaultRequestTimeout, method: options.method, url: urlBreakdown.url, query: urlBreakdown.query, headers: sanitizeHeaders(this.headers, options && options.headers), body: options && options.body });
                normalizedOptions.body = this.bodySerializer(normalizedOptions);
                chain = this.interceptors.reduce(useInterceptor(normalizedOptions), function () { return __awaiter(_this, void 0, void 0, function () {
                    var response, parsedResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                normalizedOptions.url = urlCombine(normalizedOptions.url, normalizedOptions.query, this.querySerializerOptions);
                                return [4, this.handle(normalizedOptions)];
                            case 1:
                                response = _a.sent();
                                parsedResponse = this.bodyParser(response);
                                parsedResponse.parsedBody = cacheParsedBody(parsedResponse.parsedBody);
                                return [2, parsedResponse];
                        }
                    });
                }); });
                return [2, chain()];
            });
        });
    };
    return HttpClient;
}());
export { HttpClient };
