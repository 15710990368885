var _a;
export var HttpCode;
(function (HttpCode) {
    HttpCode[HttpCode["CONTINUE"] = 100] = "CONTINUE";
    HttpCode[HttpCode["SWITCHING_PROTOCOLS"] = 101] = "SWITCHING_PROTOCOLS";
    HttpCode[HttpCode["OK"] = 200] = "OK";
    HttpCode[HttpCode["CREATED"] = 201] = "CREATED";
    HttpCode[HttpCode["ACCEPTED"] = 202] = "ACCEPTED";
    HttpCode[HttpCode["NONAUTHORITATIVE_INFORMATION"] = 203] = "NONAUTHORITATIVE_INFORMATION";
    HttpCode[HttpCode["NO_CONTENT"] = 204] = "NO_CONTENT";
    HttpCode[HttpCode["RESET_CONTENT"] = 205] = "RESET_CONTENT";
    HttpCode[HttpCode["PARTIAL_CONTENT"] = 206] = "PARTIAL_CONTENT";
    HttpCode[HttpCode["MULTIPLE_CHOICES"] = 300] = "MULTIPLE_CHOICES";
    HttpCode[HttpCode["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
    HttpCode[HttpCode["FOUND"] = 302] = "FOUND";
    HttpCode[HttpCode["SEE_OTHER"] = 303] = "SEE_OTHER";
    HttpCode[HttpCode["NOT_MODIFIED"] = 304] = "NOT_MODIFIED";
    HttpCode[HttpCode["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
    HttpCode[HttpCode["PERMANENT_REDIRECT"] = 308] = "PERMANENT_REDIRECT";
    HttpCode[HttpCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HttpCode[HttpCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HttpCode[HttpCode["FORBIDDEN"] = 403] = "FORBIDDEN";
    HttpCode[HttpCode["NOT_FOUND"] = 404] = "NOT_FOUND";
    HttpCode[HttpCode["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
    HttpCode[HttpCode["NOT_ACCEPTABLE"] = 406] = "NOT_ACCEPTABLE";
    HttpCode[HttpCode["PROXY_AUTHENTICATION_REQUIRED"] = 407] = "PROXY_AUTHENTICATION_REQUIRED";
    HttpCode[HttpCode["REQUEST_TIMEOUT"] = 408] = "REQUEST_TIMEOUT";
    HttpCode[HttpCode["CONFLICT"] = 409] = "CONFLICT";
    HttpCode[HttpCode["GONE"] = 410] = "GONE";
    HttpCode[HttpCode["LENGTH_REQUIRED"] = 411] = "LENGTH_REQUIRED";
    HttpCode[HttpCode["PRECONDITION_FAILED"] = 412] = "PRECONDITION_FAILED";
    HttpCode[HttpCode["PAYLOAD_TOO_LARGE"] = 413] = "PAYLOAD_TOO_LARGE";
    HttpCode[HttpCode["URI_TOO_LONG"] = 414] = "URI_TOO_LONG";
    HttpCode[HttpCode["UNSUPPORTED_MEDIA_TYPE"] = 415] = "UNSUPPORTED_MEDIA_TYPE";
    HttpCode[HttpCode["RANGE_NOT_SATISFIABLE"] = 416] = "RANGE_NOT_SATISFIABLE";
    HttpCode[HttpCode["EXPECTATION_FAILED"] = 417] = "EXPECTATION_FAILED";
    HttpCode[HttpCode["IM_A_TEAPOT"] = 418] = "IM_A_TEAPOT";
    HttpCode[HttpCode["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    HttpCode[HttpCode["TOO_EARLY"] = 425] = "TOO_EARLY";
    HttpCode[HttpCode["UPGRADE_REQUIRED"] = 426] = "UPGRADE_REQUIRED";
    HttpCode[HttpCode["PRECONDITION_REQUIRED"] = 428] = "PRECONDITION_REQUIRED";
    HttpCode[HttpCode["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
    HttpCode[HttpCode["REQUEST_HEADER_FIELDS_TOO_LARGE"] = 431] = "REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpCode[HttpCode["UNAVAILABLE_FOR_LEGAL_REASONS"] = 451] = "UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpCode[HttpCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    HttpCode[HttpCode["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
    HttpCode[HttpCode["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    HttpCode[HttpCode["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    HttpCode[HttpCode["GATEWAY_TIMEOUT"] = 504] = "GATEWAY_TIMEOUT";
    HttpCode[HttpCode["HTTP_VERSION_NOT_SUPPORTED"] = 505] = "HTTP_VERSION_NOT_SUPPORTED";
    HttpCode[HttpCode["NETWORK_AUTHENTICATION_REQUIRED"] = 511] = "NETWORK_AUTHENTICATION_REQUIRED";
})(HttpCode || (HttpCode = {}));
export var HttpStatusText = (_a = {},
    _a[HttpCode.CONTINUE] = 'Continue',
    _a[HttpCode.SWITCHING_PROTOCOLS] = 'Switching Protocols',
    _a[HttpCode.OK] = 'OK',
    _a[HttpCode.CREATED] = 'Created',
    _a[HttpCode.ACCEPTED] = 'Accepted',
    _a[HttpCode.NONAUTHORITATIVE_INFORMATION] = 'Non-Authoritative Information',
    _a[HttpCode.NO_CONTENT] = 'No Content',
    _a[HttpCode.RESET_CONTENT] = 'Reset Content',
    _a[HttpCode.PARTIAL_CONTENT] = 'Partial Content',
    _a[HttpCode.MULTIPLE_CHOICES] = 'Multiple Choices',
    _a[HttpCode.MOVED_PERMANENTLY] = 'Moved Permanently',
    _a[HttpCode.FOUND] = 'Found',
    _a[HttpCode.SEE_OTHER] = 'See Other',
    _a[HttpCode.NOT_MODIFIED] = 'Not Modified',
    _a[HttpCode.TEMPORARY_REDIRECT] = 'Temporary Redirect',
    _a[HttpCode.PERMANENT_REDIRECT] = 'Permanent Redirect',
    _a[HttpCode.BAD_REQUEST] = 'Bad Request',
    _a[HttpCode.UNAUTHORIZED] = 'Unauthorized',
    _a[HttpCode.FORBIDDEN] = 'Forbidden',
    _a[HttpCode.NOT_FOUND] = 'Not Found',
    _a[HttpCode.METHOD_NOT_ALLOWED] = 'Method Not Allowed',
    _a[HttpCode.NOT_ACCEPTABLE] = 'Not Acceptable',
    _a[HttpCode.PROXY_AUTHENTICATION_REQUIRED] = 'Proxy Authentication Required',
    _a[HttpCode.REQUEST_TIMEOUT] = 'Request Timeout',
    _a[HttpCode.CONFLICT] = 'Conflict',
    _a[HttpCode.GONE] = 'Gone',
    _a[HttpCode.LENGTH_REQUIRED] = 'Length Required',
    _a[HttpCode.PRECONDITION_FAILED] = 'Precondition Failed',
    _a[HttpCode.PAYLOAD_TOO_LARGE] = 'Payload Too Large',
    _a[HttpCode.URI_TOO_LONG] = 'URI Too Long',
    _a[HttpCode.UNSUPPORTED_MEDIA_TYPE] = 'Unsupported Media Type',
    _a[HttpCode.RANGE_NOT_SATISFIABLE] = 'Range Not Satisfiable',
    _a[HttpCode.EXPECTATION_FAILED] = 'Expectation Failed',
    _a[HttpCode.IM_A_TEAPOT] = 'I\'m a teapot',
    _a[HttpCode.UNPROCESSABLE_ENTITY] = 'Unprocessable Entity',
    _a[HttpCode.TOO_EARLY] = 'Too Early',
    _a[HttpCode.UPGRADE_REQUIRED] = 'Upgrade Required',
    _a[HttpCode.PRECONDITION_REQUIRED] = 'Precondition Required',
    _a[HttpCode.TOO_MANY_REQUESTS] = 'Too Many Requests',
    _a[HttpCode.REQUEST_HEADER_FIELDS_TOO_LARGE] = 'Request Header Fields Too Large',
    _a[HttpCode.UNAVAILABLE_FOR_LEGAL_REASONS] = 'Unavailable For Legal Reasons',
    _a[HttpCode.INTERNAL_SERVER_ERROR] = 'Internal Server Error',
    _a[HttpCode.NOT_IMPLEMENTED] = 'Not Implemented',
    _a[HttpCode.BAD_GATEWAY] = 'Bad Gateway',
    _a[HttpCode.SERVICE_UNAVAILABLE] = 'Service Unavailable',
    _a[HttpCode.GATEWAY_TIMEOUT] = 'Gateway Timeout',
    _a[HttpCode.HTTP_VERSION_NOT_SUPPORTED] = 'HTTP Version Not Supported',
    _a[HttpCode.NETWORK_AUTHENTICATION_REQUIRED] = 'Network Authentication Required',
    _a);
