import * as React from 'react';

import { Bubble, ClickOutside, Icon } from '@sonnen/shared-web/src';

import { mapBatteryOperatingModeToLabel } from '+app/+customer/store/+customer.helper';
import { PATHS } from '+app/router/routes';
import { Button, ButtonType } from '+shared/components/Button';
import {
  BatteryOperatingMode,
  BatteryOperatingModeOptions,
} from '+shared/store/site/types/siteLiveState.interface';
import { goTo } from '+utils/browser.util';

import './CustomerBatteryMode.component.scss';

interface Props {
  tooltipText: string;
  linkText: string;
  batteryOperatingMode: BatteryOperatingMode;
}

export const CustomerBatteryMode: React.FC<Props> = ({
  tooltipText,
  linkText,
  batteryOperatingMode,
}) => {
  const [isInfoActive, setTooltipActivity] = React.useState<boolean>(false);

  return (
    <div className={'c-battery-mode'}>
      <div>{mapBatteryOperatingModeToLabel(batteryOperatingMode)}</div>
      {batteryOperatingMode === BatteryOperatingModeOptions.MODULE_EXTENSION && (
        <ClickOutside onClick={() => setTooltipActivity(false)}>
          <div className={'c-battery-mode__info'} onClick={() => setTooltipActivity(!isInfoActive)}>
            <Icon.Info className={'c-battery-mode__info-icon'} />
            {isInfoActive && (
              <div className={'c-battery-mode__info-bubble-wrapper'}>
                <Bubble side={'top'} isThemeDense={false}>
                  <div className={'c-battery-mode__content'}>
                    <p>{tooltipText}</p>
                    <Button
                      label={linkText}
                      type={ButtonType.TERTIARY}
                      onClick={() => goTo(PATHS.HELP('sonnenBatterie'), '_blank')}
                    />
                  </div>
                </Bubble>
              </div>
            )}
          </div>
        </ClickOutside>
      )}
    </div>
  );
};
