import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';

import { CostRow, getSignByValue } from '../LeadImpactAnalysisCalculationResults.helper';
import { PageDetailedCalculation20YearsData } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

const SonnenFlatBenefitsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  const { zeroPercentValueAddedTaxes } = T.lead.impactAnalysis._salessolution_;

  return data.sonnenFlatBenefitsAfter20Years.cumulative !== undefined ? (
    <div
      className={`c-impact-analysis-results
        c-impact-analysis-results-box-dark-blue`}
    >
      <CostRow
        sign={getSignByValue(data.sonnenFlatBenefitsAfter20Years.cumulative)}
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.benefitsTitle)}
        textType={'labelLarge'}
        textHelperLabel={`(${I18n.t(zeroPercentValueAddedTaxes)})`}
        value={Math.abs(data.sonnenFlatBenefitsAfter20Years.cumulative)}
      />
      <LineDivider className="c-impact-analysis-results__line-divider" />
      {!isNil(data.sonnenFlatBenefitsAfter20Years.cashbackSonnenFlat) && (
        <CostRow
          sign={'+'}
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.cashback)}
          textType={'labelMedium'}
          value={data.sonnenFlatBenefitsAfter20Years.cashbackSonnenFlat}
        />
      )}
      {!isNil(data.sonnenFlatBenefitsAfter20Years.vppBonus) && (
        <CostRow
          sign={'+'}
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.vppbonus)}
          textType={'labelMedium'}
          value={data.sonnenFlatBenefitsAfter20Years.vppBonus}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default SonnenFlatBenefitsAfter20Years;
