import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { VppDocumentationFormTestIds as testIds } from '+config/testIds';
import { DsoRegistrationRadioType } from '+setupTool/+form/store';
import { VppDocumentationInterface, VppState } from '+setupTool/+vppDocumentation/store/types';
import { SetupToolStepsUpdatedAt } from '+setupTool/store/types';
import { RadioButton } from '+shared/components';
import {
  AdditionalFeatures,
  AdditionalFeaturesValuesUnion,
} from '+shared/store/setupTool/types/dsoRegistrationResponse.interface';

import { VppRegisterStatus } from './+vppDocumentation.dictionary';

export const getVppDocumentationProps = (data: any): VppDocumentationInterface => {
  // vpp data should be used if vpp was performed at least one
  const isVppPerformed = [VppState.PERFORMED, VppState.ALLOWED_TO_PERFORM_AGAIN].includes(
    data.vppState
  );
  const fields = isVppPerformed ? data.vpp : data.fields;

  return {
    pv_commissioning_date: fields.pvCommissioningDate,
    battery_commissioning_date: fields.batteryCommissioningDate,
    production_meter: fields.productionMeter,
    number_production_meter: fields.numberProductionMeter,
    fastening_type_grid: fields.fasteningTypeGrid,
    fastening_type_consumption: fields.fasteningTypeConsumption,
    meter_number: fields.meterNumber,
    type_of_grid_reference_meter: fields.typeOfGridReferenceMeter,
    dso_reference_number_pv: fields.dsoReferenceNumberPv,
    free_space_in_the_upper_part_of_the_meter_cabinet:
      fields.freeSpaceInTheUpperPartOfTheMeterCabinet,
    virtual_pv_system: fields.virtualPvSystem,
    virtual_pv_system_subsystem_1_name: fields.virtualPvSystemSubsystem1Name,
    virtual_pv_system_subsystem_2_name: fields.virtualPvSystemSubsystem2Name,
    virtual_pv_system_subsystem_1_commissioning_date:
      fields.virtualPvSystemSubsystem1CommissioningDate,
    virtual_pv_system_subsystem_2_commissioning_date:
      fields.virtualPvSystemSubsystem2CommissioningDate,
    download_speed_mbits: fields.downloadSpeedMbits,
    upload_speed_mbits: fields.uploadSpeedMbits,
    ping_ms: fields.pingMs,
    signal_strength_dbm: fields.signalStrengthDbm,
    lan_connection_available: fields.lanConnectionAvailable,

    // fields below were previously handled only on FE and later not stored in VPP data,
    // therefore if they are undefined but VPP was performed they should be set to true
    pv_reduction: isVppPerformed && fields.pvReduction === undefined ? true : fields.pvReduction,
    meter_cabinet_prepared:
      isVppPerformed && fields.meterCabinetPrepared === undefined
        ? true
        : fields.meterCabinetPrepared,
  };
};

export const getVppSubmissionStatusFromStepsUpdatedAt = (data: SetupToolStepsUpdatedAt) => {
  return data.vppPerformance
    ? VppRegisterStatus.FINISH
    : data.vppDocumentation
    ? VppRegisterStatus.IN_PROGRESS
    : undefined;
};

export const mapAdditionalFeaturesToFlags = (
  additionalFeatures: AdditionalFeatures[]
): Record<AdditionalFeaturesValuesUnion, boolean> => {
  const isVppCompletionNotificationVisible = additionalFeatures.includes(
    AdditionalFeatures.VPP_COMPLETION_NOTIFICATION
  );
  const isDsoReferenceNumberPvFieldVisible = additionalFeatures.includes(
    AdditionalFeatures.DSO_REFERENCE_NUMBER_PV_FIELD
  );
  const isFasteningTypeConsumptionFieldVisible = additionalFeatures.includes(
    AdditionalFeatures.FASTENING_TYPE_CONSUMPTION_FIELD
  );
  const isMeterCabinetPreparedFieldVisible = additionalFeatures.includes(
    AdditionalFeatures.METER_CABINET_PREPARED_FIELD
  );
  const isProductionMeterFieldVisible = additionalFeatures.includes(
    AdditionalFeatures.PRODUCTION_METER_FIELD
  );
  const isGsmMeasurementsRequiredFieldsVisible = additionalFeatures.includes(
    AdditionalFeatures.GSM_MEASUREMENTS_REQUIRED_FIELDS
  );
  const isGsmMeasurementsOptionalFieldsVisible = additionalFeatures.includes(
    AdditionalFeatures.GSM_MEASUREMENTS_OPTIONAL_FIELDS
  );
  const isVppWiringDiagramVisible = additionalFeatures.includes(
    AdditionalFeatures.VPP_WIRING_DIAGRAM
  );

  return {
    isVppCompletionNotificationVisible,
    isDsoReferenceNumberPvFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    isMeterCabinetPreparedFieldVisible,
    isProductionMeterFieldVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
    isVppWiringDiagramVisible,
  };
};

export const isGsmMeasurementsFieldsVisible = (
  isGsmMeasurementsRequiredFieldsVisible: boolean,
  isGsmMeasurementsOptionalFieldsVisible: boolean
) => {
  return isGsmMeasurementsRequiredFieldsVisible || isGsmMeasurementsOptionalFieldsVisible;
};

export const getProductionMeterCollection: () => RadioButton[] = () => [
  {
    value: DsoRegistrationRadioType.YES,
    label: I18n.t(T.setupTool.common.yes),
    dataTestId: testIds.yesProductionMeterExistingRadioButton,
  },
  {
    value: DsoRegistrationRadioType.NO,
    label: I18n.t(T.setupTool.common.no),
    dataTestId: testIds.noProductionMeterExistingRadioButton,
  },
];
