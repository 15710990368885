import { isNil } from 'lodash';

export const parseInteger = (value: number | string | undefined, fallback: number) => {
  if (isNil(value)) {
    return fallback;
  }

  const maybeInteger = parseFloat(String(value));

  return isNaN(maybeInteger) || maybeInteger % 1 !== 0 ? fallback : maybeInteger;
};

export const getPrecision = (num: number) => {
  if (!isFinite(num)) {
    return 0;
  }
  let e = 1;
  let p = 0;
  while (Math.round(num * e) / e !== num) {
    e *= 10;
    p++;
  }
  return p;
};

export const roundToNextMultipleOfFive = (val: number) => Math.ceil(val / 5) * 5;
