import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { BatteryAccessoryInfo } from '+shared/store/lead/types';

import { formFields as batteryFields } from '../../components/LeadConfigurationBattery/LeadConfigurationBattery.helper';
import { formFields as protectFields } from '../../components/LeadConfigurationProtect';
import { ConfigurationProtectForm } from '../types';

export const ConfigurationProtectSchema = () =>
  Yup.object().shape({
    protectInstalled: Yup.boolean().required(),
    protectMode: Yup.string().when([protectFields.PROTECT_INSTALLED, batteryFields.MODEL_NAME], {
      is: (protectInstalled: boolean, batteryModel: string) =>
        protectInstalled && batteryModel.includes(BatteryAccessoryInfo.PERFORMANCE),
      then: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    }),
  });

export const configurationProtectFormInitial: ConfigurationProtectForm = {
  protectInstalled: false,
  protectMode: undefined,
  // "accessories" is not used in the form UI
  // it is only used "under the hood" to select the accessory id based on the battery id
  accessories: [],
};
