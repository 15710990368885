import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { CountryCode, Link, Table, TableCell, TableRow } from '@sonnen/shared-web';

import * as uuid from 'uuid';

import {
  UserCertificate,
  UserCertificateStatus,
} from '+shared/store/user/types/userCertificate.interface';

import { getCertificateDetails } from '../CertificateList/CertificateList.helper';

import './CertificateListItem.component.scss';

interface Props {
  certificate: UserCertificate;
  userCountry: CountryCode;
}

export const CertificateListItem: React.FC<Props> = ({ userCountry, certificate }) => {
  const certificateDetails = getCertificateDetails(certificate);

  return (
    <div>
      <Table className={'c-certificate-list-item'}>
        {certificateDetails.map(({ label, value }) => {
          return (
            <TableRow key={uuid.v4()} className={'c-certificate-list-item__row'}>
              <TableCell className={'c-certificate-list-item__key'}>{label}</TableCell>
              <TableCell className={'c-certificate-list-item__value'}>{value}</TableCell>
            </TableRow>
          );
        })}
        {certificate.status === UserCertificateStatus.EXPIRED && (
          <TableRow className={'c-certificate-list-item__row--renew'}>
            {userCountry === CountryCode.IT ? (
              <p>
                {I18n.t(T.myAccount.certification.contactToRenew)}
                <Link
                  label={I18n.t(T.myAccount.certification.trainingMailItaly)}
                  href={`mailto:${I18n.t(T.myAccount.certification.trainingMailItaly)}`}
                />
              </p>
            ) : (
              <Link
                label={I18n.t(T.myAccount.certification.renewCertification)}
                href={I18n.t(T.myAccount.certification.link)}
              />
            )}
          </TableRow>
        )}
      </Table>
    </div>
  );
};
