import { queryReducer } from '+shared/store/query';
import { createPersistedReducer } from '+utils/redux';

import { SITE_LIST_PAGE_ACTIONS, SiteListPageActions } from './siteList.actions';
import { GET_SITES_QUERY, initialState, SiteListPageState } from './siteList.state';

export const reducer = createPersistedReducer<SiteListPageState, SiteListPageActions>(
  (state = initialState, action): SiteListPageState => {
    switch (action.type) {
      case SITE_LIST_PAGE_ACTIONS.SET_SITES_COLLECTION:
        return {
          ...state,
          siteCollection: action.siteCollection,
        };
      case SITE_LIST_PAGE_ACTIONS.SET_SITES_COLLECTION_META_DATA:
        return {
          ...state,
          siteMetaData: action.siteListMetaData,
        };
      case SITE_LIST_PAGE_ACTIONS.SET_SITES_GEO_LOCATION:
        return {
          ...state,
          siteGeoLocations: action.siteGeoLocations,
        };
      case SITE_LIST_PAGE_ACTIONS.SET_INSTALLED_BATTERIES_COUNT:
        return {
          ...state,
          installedBatteriesCount: action.installedBatteries,
        };
      default:
        return queryReducer(state, action, [GET_SITES_QUERY], {
          suppressResponse: true,
        });
    }
  }
)({
  key: 'site-list',
});
