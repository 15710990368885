import { RouterActionType } from 'connected-react-router';
import { Location } from 'history';
import { AnyAction } from 'redux';

import { ActionsUnion } from '+utils/redux/actionsUnion.util';
import { createAction } from '+utils/redux/createAction.util';

export enum ROUTER_ACTIONS {
  LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
  IS_READY = '[Router] IS_READY',
  GO_TO_DASHBOARD = '[Router] GO_TO_DASHBOARD',
  GO_TO_CUSTOMERS = '[Router] GO_TO_CUSTOMERS',
  GO_BACK_TO_LEADS = '[Router] GO_BACK_TO_LEADS',
  GO_BACK_TO_SETUP_LEADS = '[Router] GO_BACK_TO_SETUP_LEADS',
  CHANGE_CUSTOMER_SITE = '[Router] CHANGE_CUSTOMER_SITE',
  GO_TO_NEWS_INBOX = '[Router] GO_TO_NEWS_INBOX',
}

export const RouterActions = {
  locationChange: createAction(
    ROUTER_ACTIONS.LOCATION_CHANGE,
    (action: RouterActionType, location: Location) => ({ payload: { action, location } })
  ),
  isReady: createAction(ROUTER_ACTIONS.IS_READY, (navigated: boolean) => ({ navigated })),
  goToDashboard: createAction(ROUTER_ACTIONS.GO_TO_DASHBOARD),
  goToCustomers: createAction(ROUTER_ACTIONS.GO_TO_CUSTOMERS),
  // go to /leads with prev query params
  goBackToLeads: createAction(ROUTER_ACTIONS.GO_BACK_TO_LEADS),
  // go to /setup with prev query params
  goBackToSetupLeads: createAction(ROUTER_ACTIONS.GO_BACK_TO_SETUP_LEADS),
  changeCustomerSite: createAction(
    ROUTER_ACTIONS.CHANGE_CUSTOMER_SITE,
    (customerId: string, siteId: string) => ({ customerId, siteId })
  ),
  goToNewsInbox: createAction(ROUTER_ACTIONS.GO_TO_NEWS_INBOX),
};

export function isLocationChangeAction(
  action: AnyAction
): action is ReturnType<typeof RouterActions.changeCustomerSite> {
  return action.type === ROUTER_ACTIONS.LOCATION_CHANGE;
}

export type RouterActions = ActionsUnion<typeof RouterActions>;
