import React, { Children } from 'react';

import { isNil } from 'lodash';

const TwoColumnsLayout: React.FC = ({ children }) => (
  <div className={'o-grid'}>
    {Children.map(children, (child) => {
      if (!isNil(child)) {
        return <div className={'o-grid__column o-grid__column--lg-6'}>{child}</div>;
      }
      return;
    })}
  </div>
);

export default TwoColumnsLayout;
