import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Select } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { Field, FormikProps } from 'formik';

import { useDispatchInputEvent } from '+shared/hooks/useDispatchInputEvent';

import { validateField } from '../Form.helpers';
import { FormFieldError } from '../FormFieldError';

import './FormInputMultiselect.component.scss';

interface Props<T, U> {
  name: string;
  collection: U[];
  form: FormikProps<T>;
  mapper?: (val: U) => string;
  label?: string;
  placeholder?: string;
  className?: ClassValue;
  onSelect?: (val: U) => void;
  hasNoGap?: boolean;
  id?: string;
}

export const FormInputMultiselect = <T extends any = any, U extends any = string>({
  form,
  mapper,
  name,
  collection,
  label,
  placeholder,
  className,
  hasNoGap = false,
  id,
}: Props<T, U>) => {
  const validation = validateField({ name, form });
  const [selectedItems, setSelectedItems] = React.useState(form.values[name] as U[]);
  const { onChange: onChangeDispatch } = useDispatchInputEvent();

  const handleSelect = (selectedValue: U) => {
    const newSelectedItems = selectedItems.includes(selectedValue)
      ? selectedItems.filter((item) => item !== selectedValue)
      : [...selectedItems, selectedValue];

    setSelectedItems(newSelectedItems);
    form.setFieldValue(name, newSelectedItems);
    form.setFieldTouched(name, true);
    onChangeDispatch(name, selectedValue);
  };

  return (
    <div
      className={classNames('c-form-input-multiselect', className, {
        'c-form-input-multiselect--no-gap': hasNoGap && !validation.hasError,
      })}
    >
      {label && <label className={'c-form-input-multiselect__label'}>{label}</label>}
      <Field
        name={name}
        component={Select}
        className={classNames({ 'c-form-input-multiselect__field--error': validation.hasError })}
        items={collection}
        placeholder={placeholder}
        itemsSelected={selectedItems}
        itemFactory={mapper || String}
        onSelect={handleSelect}
        isControlled={true}
        hasBottomGap={true}
        id={id}
        info={I18n.t(T.general.multiselect.info)}
      />
      <FormFieldError name={name} form={form} />
    </div>
  );
};
