import { concat, uniq } from 'lodash';

import { createReducer } from '+app/utils/redux';

import { FIREBASE_ACTIONS, FirebaseActions } from './firebase.actions';
import { FirebaseState, initialState } from './firebase.state';

export const reducer = createReducer<FirebaseState, FirebaseActions>(
  (state = initialState, action): FirebaseState => {
    switch (action.type) {
      case FIREBASE_ACTIONS.SET_NEWS_LIST:
        return {
          ...state,
          newsList: action.newsList,
          isNewsListFetched: true,
        };
      case FIREBASE_ACTIONS.SET_READ_NEWS_LIST:
        return {
          ...state,
          readNewsList: uniq(concat(state.readNewsList, action.readNewsList)),
          isReadNewsListFetched: true,
        };
      case FIREBASE_ACTIONS.SET_NOTIFICATIONS_LIST:
        return {
          ...state,
          notificationsList: action.notificationsList,
          isNotificationsListFetched: true,
        };
      case FIREBASE_ACTIONS.SET_COMBINED_FB_AND_YT_VIDEO_LIST:
        return {
          ...state,
          videoList: action.videoList,
        };
      case FIREBASE_ACTIONS.REMOVE_VIDEO:
        return {
          ...state,
          videoList: state.videoList.filter((video) => video.id !== action.videoId),
        };
      case FIREBASE_ACTIONS.MARK_NEWS_AS_READ:
        return {
          ...state,
          readNewsList: [
            ...state.readNewsList,
            {
              newsId: action.newsId,
              userId: action.userId,
            },
          ],
        };
      default:
        return state;
    }
  }
);
