import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import { Container } from '+shared/components';
import { restrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { StoreState } from '+shared/store/store.interface';
import { companyTabAllowedRoles } from '+shared/store/user';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';

import { ROUTES } from '../../../router';
import { AccountCompany } from '../../+company/containers/AccountCompany';
import { MyAccount } from '../../+personal/containers/MyAccount';
import { AccountHeader } from '../../components';

const mapStateToProps = (state: StoreState) => ({
  userRoles: getUserProfileRoles(state),
});

type Props = ReturnType<typeof mapStateToProps>;

export const AccountComponent: React.FC<Props> = ({ userRoles }) => (
  <>
    <AccountHeader userRoles={userRoles} />
    <Container withVerticalPadding={false}>
      <Switch>
        <Route exact={true} path={ROUTES.ACCOUNT} component={MyAccount} />
        <Route
          exact={true}
          path={ROUTES.ACCOUNT_COMPANY}
          component={restrictedToRolesOr404(AccountCompany, companyTabAllowedRoles, userRoles)}
        />
      </Switch>
    </Container>
  </>
);

export const Account = connect(mapStateToProps, null)(AccountComponent);
