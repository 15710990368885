import * as React from 'react';

import { ListTable, ListTableHeader } from '+shared/components';
import { Lead } from '+shared/store/lead/types';

import { InboxLeadListTableItem } from '../InboxLeadListTableItem';
import { getInboxLeadListTableHeaders } from './InboxLeadListTable.const';

interface Props {
  items: Lead[];
  isPending: boolean;
  toggleAssignLeadModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
  setChosenInboxLead: (arg: Lead) => void;
  toggleDeclineLeadModal: (arg: boolean) => void;
}

export const InboxLeadListTable: React.FC<Props> = ({
  items,
  isPending,
  toggleAssignLeadModal,
  toggleModal,
  setChosenInboxLead,
  toggleDeclineLeadModal,
}) => {
  const inboxLeadListTableHeaders = getInboxLeadListTableHeaders();

  return (
    <ListTable
      className={`c-inbox-list-table--lead-list`}
      headers={inboxLeadListTableHeaders}
      items={items}
      isPending={isPending}
      noBorder={true}
      renderHeader={(header) => (
        <ListTableHeader className={`c-inbox-list-table__header--lead-list`} item={header} />
      )}
      renderItem={(item) => (
        <InboxLeadListTableItem
          item={item}
          toggleAssignLeadModal={toggleAssignLeadModal}
          toggleDeclineLeadModal={toggleDeclineLeadModal}
          toggleModal={toggleModal}
          setChosenInboxLead={setChosenInboxLead}
        />
      )}
      dataTestIdTable="leads-inbox-table"
      dataTestIdHeader="leads-inbox-table-header"
    />
  );
};
