import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { DetailListTable } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Lead } from '+shared/store/lead/types';

import { getLeadEnergyConsumptionDetailsList } from './LeadOverviewEnergyConsumptionDetails.helper';

import './LeadOverviewEnergyConsumptionDetails.component.scss';

interface Props {
  lead: Lead;
}

export const LeadOverviewEnergyConsumptionDetails = ({ lead }: Props) => (
  <Widget
    icon="House"
    heading={I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.headline)}
  >
    <DetailListTable
      list={getLeadEnergyConsumptionDetailsList(lead)}
      filterNil={false}
      lead={lead}
      hasBottomBorder={false}
    />
  </Widget>
);
