import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { head, isEmpty } from 'lodash';

import { PartnerSite } from '+app/+customer/+listNew/store/types/partnerSite.interface';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import { Customer, Site } from '+shared/store/customer';
import { getSiteAddress } from '+shared/store/site/site.helpers';

const transformPartnerSite = (site: PartnerSite): CaseSite => {
  const { name, salesforceAccountId, id } = site.customer;
  const { street, postalCode, city, countryCode } = site;
  const battery = head(site.batteries);
  const contract = head(site.contracts);

  return {
    customerId: id,
    salesforceAccountId,
    name,
    address: getSiteAddress({ street, postalCode, city, countryCode }),
    battery,
    contract,
  };
};

const transformSite = (site: Site, customer: Customer): CaseSite => {
  const { batterySystems, contracts } = site;
  const { name, salesforceAccountId, id, partnerMonitoringAccepted, privacyPolicyAccepted } =
    customer;

  const isConsentGiven = partnerMonitoringAccepted && privacyPolicyAccepted;

  const { street, postalCode, city, countryCode } = isConsentGiven ? site : customer;

  const batterySystem = head(batterySystems);
  const contract = head(contracts);

  return {
    customerId: id,
    salesforceAccountId,
    name,
    address: getSiteAddress({ street, postalCode, city, countryCode }),
    battery: batterySystem,
    contract: contract,
  };
};

const transformToSiteList = (customer: Customer) => {
  const { sites } = customer;
  return isEmpty(sites)
    ? [transformSite({} as Site, customer)]
    : sites.map((site) => transformSite(site, customer));
};

const getCaseSiteList = (customerList: Customer[]) =>
  customerList.reduce(
    (results, customer) => results.concat(transformToSiteList(customer)),
    [] as CaseSite[]
  );

const generateActiveSiteInfo = (site: CaseSite): string => {
  const { name, address = '', battery, contract } = site;
  const batterySerialNumber = battery ? battery.serialNumber : '-';
  const contractNumber = contract ? contract.contractNumber : '-';

  return `${I18n.t(T.report.site.name.label)}: ${name}, ${address}, ${I18n.t(
    T.report.site.battery.label
  )}: ${batterySerialNumber}, ${I18n.t(T.report.site.contract.label)}: ${contractNumber}`;
};

export const CaseReportHelper = {
  transformPartnerSite,
  transformSite,
  transformToSiteList,
  getCaseSiteList,
  generateActiveSiteInfo,
};
