import { CountryFlagProvider } from '@sonnen/shared-web';

import { ActionsObservable, combineEpics } from 'redux-observable';
import { concat, EMPTY } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RouterActions } from '+app/router/store/router.actions';

import { dataGuard, ofType, processQuery } from '../../../utils';
import { AuthActions } from '../auth/auth.actions';
import { UserActions } from '../user/user.actions';
import { SyncRepository } from './sync.repository';
import { SYNC_QUERY } from './sync.state';

type Action$ = ActionsObservable<AuthActions>;

export const sync$ = (action$: Action$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    filter((action) => !action.navigated),
    processQuery(SYNC_QUERY, () => SyncRepository.getUserMe({}), {
      onSuccess: (data) => {
        if (!data.profile) return EMPTY;

        CountryFlagProvider.setUserCountry(data.profile?.accountBillingCountryCode);

        return concat(
          dataGuard(UserActions.setProfile)(data.profile),
          dataGuard(UserActions.getCompany)(data.profile),
          dataGuard(UserActions.getUserProfile)(data.profile)
        );
      },
    })
  );

export const epics = combineEpics(sync$);
