import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Card, Icon, WizardContext } from '@sonnen/shared-web';

import { TestId } from '+config/testIds';
import { FormSubmitButton } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';

import './FormWizardStep.component.scss';

interface Props {
  isPending?: boolean;
  isSubmitButtonDisabled?: boolean;
  showPreviousNavButton?: boolean;
  showCancelButton?: boolean;
  submitButtonLabel?: string;
  previousButtonLabel?: string;
  cancelButtonLabel?: string;
  onBeforeSubmit?: (...args: any[]) => void;
  cancelButtonHandler?: (...args: any[]) => void;
}

export const FormWizardStep: React.FC<Props> = ({
  children,
  isPending,
  isSubmitButtonDisabled = false,
  showPreviousNavButton = true,
  showCancelButton,
  submitButtonLabel,
  previousButtonLabel,
  cancelButtonLabel,
  onBeforeSubmit,
  cancelButtonHandler,
}) => {
  const wizard = React.useContext(WizardContext);
  const isFirstStep = wizard.progress === 1;

  const { back, saveAndContinue } = T.setupTool.step;

  return (
    <div className={'c-form-wizard-step'}>
      <Card
        footerContent={
          <div className={'c-form-wizard-step__navigation'}>
            {wizard.prev && !isFirstStep && showPreviousNavButton && (
              <div className={'c-form-wizard-step__navigation-prev'}>
                <Button
                  onClick={() => wizard.prev!()}
                  className={'c-form-wizard-step__navigation-prev__button'}
                  label={
                    <>
                      <Icon.Angle className={'button-label-arrow-left'} />
                      {previousButtonLabel || I18n.t(back)}
                    </>
                  }
                  type={ButtonType.TERTIARY}
                />
              </div>
            )}
            {showCancelButton && (
              <div className={'c-form-wizard-step__navigation-cancel'}>
                <Button
                  label={cancelButtonLabel || I18n.t(T.general.basicActions.cancel)}
                  onClick={cancelButtonHandler}
                  type={ButtonType.TERTIARY}
                  className={'c-form-wizard-step__navigation-cancel__button'}
                  dataTestId={TestId.FormWizardStep.backBtn}
                />
              </div>
            )}
            <div className={'c-form-wizard-step__navigation-next'}>
              <FormSubmitButton
                onClick={onBeforeSubmit}
                label={submitButtonLabel ? submitButtonLabel : I18n.t(saveAndContinue)}
                isSubmitting={isPending}
                isDisabled={isSubmitButtonDisabled}
                dataTestId={
                  submitButtonLabel
                    ? TestId.FormWizardStep.continueBtn
                    : TestId.FormWizardStep.saveAndContinueBtn
                }
              />
            </div>
          </div>
        }
      >
        <div className={'c-form-wizard-step__content'}>{children}</div>
      </Card>
    </div>
  );
};
