import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { ModalLayout } from '@sonnen/shared-web';

import { Formik } from 'formik';

import { FormUploadInput } from '+shared/components';
import { Button, ButtonType, MainType } from '+shared/components/Button';
import { UploadLogoForm } from '+shared/store/user/types/companyLogo.interface';

import './ChangeLogoForm.component.scss';

interface Props {
  submitForm: (values: UploadLogoForm) => void;
  onClose: () => void;
  isLoading: boolean;
  logo: string | undefined;
  title: string;
  hint: string;
}

const changeLogoFormConfig = {
  maxSize: 4048576,
  maxSizeLabel: '4MB',
  accept: ['image/png', 'image/jpg', 'image/jpeg'],
};

const convertFileToBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const ChangeLogoForm: React.FC<Props> = ({ submitForm, isLoading, logo, title, hint }) => {
  const [attachment, setAttachment] = React.useState<null | string>(null);
  const [attachmentError, setAttachmentError] = React.useState<string>('');

  const onFormSubmit = () => {
    if (attachment === null) {
      setAttachmentError(I18n.t(T.report.attachmentRequiredError));
    } else {
      submitForm({ attachment });
    }
  };

  const onRemoveLogo = () => submitForm({ attachment: null });

  // @TODO: move to shared
  const onUpload = async (file: File) => {
    try {
      const result = await convertFileToBase64(file);
      if (!result) {
        return;
      }

      const stringResult = result.toString();
      const base64Result = result.toString().slice(stringResult.indexOf(',') + 1);
      setAttachment(base64Result);
      return file.name;
    } catch (error) {
      return;
    }
  };

  // @TODO: move to shared
  const onReset = () => {
    setAttachment(null);
    setAttachmentError('');

    return null;
  };

  return (
    <Formik
      initialValues={{ attachment: null }}
      onSubmit={onFormSubmit}
      render={(form) => (
        <form onSubmit={form.handleSubmit} className={'c-change-logo-form'}>
          <ModalLayout
            title={title}
            footer={
              <div className={'c-change-logo-form__button-wrapper'}>
                {logo && (
                  <Button
                    label={I18n.t(T.companyAccount.companyLogo.modal.removeCurrentLogo)}
                    type={ButtonType.TERTIARY}
                    onClick={onRemoveLogo}
                    className={'c-change-logo-form__button'}
                  />
                )}
                <Button
                  label={I18n.t(T.general.basicActions.save)}
                  type={ButtonType.PRIMARY}
                  loading={isLoading}
                  mainType={MainType.SUBMIT}
                  className={'c-change-logo-form__button'}
                />
              </div>
            }
          >
            <div>
              <p className={'c-change-logo-form__hint'}>{hint}</p>
              <FormUploadInput
                form={form}
                name={'attachment'}
                className={'c-change-logo-form__upload-input'}
                onUpload={(file) => onUpload(file[0])}
                onReset={onReset}
                attachmentError={attachmentError}
                {...changeLogoFormConfig}
              />
            </div>
          </ModalLayout>
        </form>
      )}
    />
  );
};
