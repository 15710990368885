import { isArray, isNumber } from 'lodash';
export var segmentizePoints = function (data, filterBy) {
    var dataSegments = [];
    var firstIndex;
    var lastIndex;
    for (var i = 0, l = data.length; i < l; i++) {
        if (firstIndex === undefined && data[i].y !== filterBy) {
            firstIndex = i;
        }
        else if (firstIndex !== undefined && lastIndex === undefined && data[i].y === filterBy) {
            lastIndex = i;
        }
        else if (i === l - 1) {
            lastIndex = i + 1;
        }
        if (firstIndex !== undefined && lastIndex !== undefined && firstIndex !== lastIndex) {
            dataSegments.push(data.slice(firstIndex, lastIndex));
            firstIndex = lastIndex = undefined;
        }
    }
    if (dataSegments.length === 0) {
        dataSegments.push(data);
    }
    return dataSegments;
};
export var findBestMatchInSortedArray = function (array, matcher) {
    if (!array || !array.length) {
        return undefined;
    }
    var nearestMatchDistance = Infinity;
    var nearestMatch;
    for (var l = 0, r = array.length - 1; l <= r;) {
        var i = Math.floor((l + r) / 2);
        var distance = matcher(array[i]);
        if (Math.abs(distance) < Math.abs(nearestMatchDistance)) {
            nearestMatchDistance = distance;
            nearestMatch = array[i];
        }
        if (distance === 0 || l >= r) {
            break;
        }
        else if (distance > 0) {
            r = i - 1;
        }
        else {
            l = i + 1;
        }
    }
    return nearestMatch;
};
export var isTupleOfNumbers = function (value) {
    return isArray(value) && value.length === 2 && (isNumber(value[0]) && isNumber(value[1]));
};
