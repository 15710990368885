import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Data, MergedData } from '@coolio/json-api';

import { StatusTileColors } from '+shared/components';

export enum MeterMeasurementDirection {
  PRODUCTION = 'production',
  CONSUMPTION = 'consumption',
  GRID = 'grid',
  DIFFERENCE = 'difference',
}

interface PowerMeterAttributes {
  modbusId: number;
  channel: number;
  direction: MeterMeasurementDirection;
  exportedKwh: number;
  importedKwh: number;
  currentL1: number;
  currentL2: number;
  currentL3: number;
  voltageL1L2: number;
  voltageL1N: number;
  voltageL2L3: number;
  voltageL2N: number;
  voltageL3L1: number;
  voltageL3N: number;
  apparentPowerTotal: number;
  reactivePowerTotal: number;
  powerL1: number;
  powerL2: number;
  powerL3: number;
  powerTotal: number;
  error: number;
}

export type PowerMeterLiveStateData = Data<PowerMeterAttributes>;
export type PowerMeterLiveState = MergedData<PowerMeterLiveStateData>;

// PowerMeter Helpers

export const getFilteredPowerMeterErrorCodes = (powerMeterError: number): string => {
  const displayedPowerMeterErrorCodes = [3, 0, -1];
  return String(!displayedPowerMeterErrorCodes.includes(powerMeterError) ? 0 : powerMeterError);
};

export const getFilteredPowerMeterErrorMessages = (powerMeterError: number): string => {
  const { noError, noPowerMeterConnected, communicationError } =
    T.customerSingle.pvSystemsAndMeters.meterErrors;

  switch (powerMeterError) {
    case 3:
      return I18n.t(noPowerMeterConnected);
    case -1:
      return I18n.t(communicationError);
    default:
      return I18n.t(noError);
  }
};

export const getFilteredPowerMeterErrorColor = (powerMeterError: number): StatusTileColors => {
  switch (powerMeterError) {
    case 3:
      return StatusTileColors.YELLOW;
    case -1:
      return StatusTileColors.RED;
    default:
      return StatusTileColors.GREEN;
  }
};
