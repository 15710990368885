import { FormikProps } from 'formik';
import { uniq } from 'lodash';

import {
  isBatteryAvailableForSale,
  isOrWasBatteryAvailableForSale,
} from '+shared/store/lead/lead.helpers';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';

import { ConfigurationForm } from '../../store/types/configuration.interface';

export const formFields = {
  NEW_BATTERY: 'newBattery' as 'newBattery',
  MODEL_NAME: 'modelName' as 'modelName',
  CAPACITY_GROSS: 'capacityGross' as 'capacityGross',
};

export const mapBatteryModelNameCollection = (
  productBatteryList: LeadProductBattery[],
  form: FormikProps<ConfigurationForm>
) =>
  uniq(
    productBatteryList
      .filter((productBattery) =>
        form.values.newBattery
          ? isBatteryAvailableForSale(productBattery)
          : isOrWasBatteryAvailableForSale(productBattery)
      )
      .map((productBattery) => productBattery.modelName)
  );
