import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { dateUtil } from '+utils/date.util';

import { DsoRegistrationSonnenBatteryInterface } from '../types';

export const DsoRegistrationSonnenBatterySchemaPvAndBattery = () =>
  Yup.object().shape({
    battery_name: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
    battery_id: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
    battery_nominal_capacity_kWh: Yup.string()
      .trim()
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const DsoRegistrationSonnenBatterySchemaBatteryOnly = () =>
  Yup.object().shape({
    battery_name: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
    battery_id: Yup.string().trim().required(I18n.t(T.register.alert.requiredField)),
    battery_nominal_capacity_kWh: Yup.string()
      .trim()
      .required(I18n.t(T.register.alert.requiredField)),
    preferred_installation_date: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), dateUtil.of('1899-12-31')) : true)
      )
      .test(
        'invalidDateNewerThan',
        I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
        (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
      )
      .trim()
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const getDsoRegistrationSonnenBatterySchema = (
  registrationSubjectType?: RegistrationSubjectType
) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationSonnenBatterySchemaPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationSonnenBatterySchemaBatteryOnly;
    default:
      return DsoRegistrationSonnenBatterySchemaPvAndBattery;
  }
};

// eslint-disable-next-line max-len
export const DsoRegistrationSonnenBatteryInitialPvAndBattery: DsoRegistrationSonnenBatteryInterface =
  {
    battery_name: '',
    battery_id: '',
    battery_nominal_capacity_kWh: '',
  };

export const DsoRegistrationSonnenBatteryInitialBatteryOnly: DsoRegistrationSonnenBatteryInterface =
  {
    battery_name: '',
    battery_id: '',
    battery_nominal_capacity_kWh: '',
    preferred_installation_date: '',
  };

export const getDsoRegistrationSonnenBatteryInitial = (
  registrationSubjectType?: RegistrationSubjectType
) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationSonnenBatteryInitialPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationSonnenBatteryInitialBatteryOnly;
    default:
      return DsoRegistrationSonnenBatteryInitialPvAndBattery;
  }
};

export const saveableSonnenBatteryFieldsPvAndBattery = [
  'battery_name',
  'battery_id',
  'battery_nominal_capacity_kWh',
];

export const saveableSonnenBatteryFieldsBatteryOnly = [
  'battery_name',
  'battery_id',
  'battery_nominal_capacity_kWh',
  'preferred_installation_date',
];

export const getSaveableSonnenBatteryFields = (
  registrationSubjectType?: RegistrationSubjectType
) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return saveableSonnenBatteryFieldsPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return saveableSonnenBatteryFieldsBatteryOnly;
    default:
      return saveableSonnenBatteryFieldsPvAndBattery;
  }
};
