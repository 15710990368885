var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RequestBuilder } from './requestBuilder';
var CreationalRequestBuilder = (function (_super) {
    __extends(CreationalRequestBuilder, _super);
    function CreationalRequestBuilder(httpClient, options, uri) {
        return _super.call(this, httpClient, options, uri) || this;
    }
    CreationalRequestBuilder.prototype.ofType = function (type) {
        this.type = type;
        return this;
    };
    CreationalRequestBuilder.prototype.withId = function (id) {
        this.id = id;
        return this;
    };
    CreationalRequestBuilder.prototype.withAttributes = function (attributes) {
        this.attributes = attributes;
        return this;
    };
    CreationalRequestBuilder.prototype.withRelationship = function (rel) {
        if (!this.relationships) {
            this.relationships = {};
        }
        if (rel) {
            this.relationships[rel.name] = { data: { id: rel.uuid, type: rel.type } };
        }
        return this;
    };
    CreationalRequestBuilder.prototype.onBeforeExpect = function () {
        if (!this.type) {
            throw new Error('Missing object type. Did you forget to call ofType()?');
        }
        this.requestData.body = {
            data: {
                id: this.id,
                type: this.type,
                attributes: this.attributes,
                relationships: this.relationships,
            },
        };
    };
    return CreationalRequestBuilder;
}(RequestBuilder));
export { CreationalRequestBuilder };
