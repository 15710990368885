import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';

import { MediaQuery } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { isNil, isString } from 'lodash';

import { mapActions } from '+app/utils';
import { LayoutActions } from '+shared/store/layout';

import { classNamePrefix, NavLinkColor, renderButton, renderLink } from './NavLink.helpers';
import { MatchMultiplePaths, NavLinkAction, NavLinkPath, NavLinkType } from './NavLink.interface';

import './NavLink.component.scss';

const mapDispatchToProps = mapActions({
  toggleNavigation: LayoutActions.toggleNavigation,
});

type Props = ReturnType<typeof mapDispatchToProps> & ComponentProps;

interface ComponentProps {
  children: React.ReactNode;
  action?: NavLinkPath | NavLinkAction;
  type?: NavLinkType;
  border?: boolean;
  matchMultiplePaths?: MatchMultiplePaths;
  warningCount?: number;
  nowrap?: boolean;
  onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  navLinkColor?: NavLinkColor;
  dataTestId?: string;
}

const NavLinkComponent: React.FC<Props> = ({
  children,
  action,
  type,
  border = true,
  matchMultiplePaths,
  nowrap = false,
  warningCount = 0,
  actions,
  onClick,
  navLinkColor = NavLinkColor.DARK,
  dataTestId,
}) => (
  <Media query={MediaQuery.UP_NAV}>
    {(isDesktopMedia: boolean) => (
      <li
        className={classNames(classNamePrefix, {
          [`${classNamePrefix}--mobile`]:
            (!isDesktopMedia && isNil(type)) || (!isNil(type) && type === 'mobile'),
          [`${classNamePrefix}--desktop ${classNamePrefix}--desktop-${navLinkColor}`]:
            (isDesktopMedia && isNil(type)) || (!isNil(type) && type === 'desktop'),
          [`${classNamePrefix}--no-border`]: !border,
          [`${classNamePrefix}--w-warning`]: !!warningCount,
          [`${classNamePrefix}--nowrap`]: nowrap,
        })}
        data-testid={dataTestId}
      >
        {isString(action)
          ? renderLink({
              children,
              action,
              match: matchMultiplePaths,
              onClick: (e) => {
                actions.toggleNavigation(false);
                if (onClick) onClick(e);
              },
            })
          : renderButton(children, action)}
      </li>
    )}
  </Media>
);

export const NavLink = connect(null, mapDispatchToProps)(NavLinkComponent);
