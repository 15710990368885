import { ActionsUnion, createAction } from '+app/utils';

import { Customer } from './types/customer.interface';

export enum CUSTOMER_ACTIONS {
  GET_CUSTOMER_LIST = '[Customer] GET_CUSTOMER_LIST',
  SET_CUSTOMER_LIST = '[Customer] SET_CUSTOMER_LIST',
  CLEAR_CUSTOMER_LIST = '[Customer] CLEAR_CUSTOMER_LIST',
}

export const CustomerActions = {
  getCustomerList: createAction(
    CUSTOMER_ACTIONS.GET_CUSTOMER_LIST,
    (params: { search?: string }) => params
  ),
  setCustomerList: createAction(CUSTOMER_ACTIONS.SET_CUSTOMER_LIST, (list: Customer[]) => ({
    list,
  })),
  clearCustomerList: createAction(CUSTOMER_ACTIONS.CLEAR_CUSTOMER_LIST),
};

export type CustomerActions = ActionsUnion<typeof CustomerActions>;
