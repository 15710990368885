import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, iif, of, throwError } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { PATHS, ROUTES } from '+app/router';
import { dataGuard, mapPathToParams, mapToState, ofType, processQuery } from '+app/utils';
import { LeadActions } from '+shared/store/lead';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { LeadStatusName } from '+shared/store/lead/types';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';

import { LayoutActions } from '../../shared/store/layout';
import { epics as configuration$ } from '../+configuration/store/+configuration.epics';
import { epics as hardware$ } from '../+hardware/store/+hardware.epics';
import { epics as impactAnalysis$ } from '../+impactAnalysis/store/+impactAnalysis.epics';
import { LeadListPageActions } from '../+list/store';
import { epics as leadList$ } from '../+list/store/+leadList.epics';
import { epics as offer$ } from '../+offer/store/+offer.epics';
import { epics as overview$ } from '../+overview/store/+overview.epics';
import {
  GET_PARTNER_EMPLOYEE_COLLECTION_QUERY,
  MARK_AS_SEEN_QUERY,
} from '../+overview/store/+overview.state';
import { LeadPageActions } from './+lead.actions';
import { LeadPageHelper } from './+lead.helper';
import {
  ASSIGN_PARTNER_TO_LEAD_QUERY,
  CHANGE_LEAD_STATUS_QUERY,
  GET_ADDRESS_AUTOSUGGESTIONS_QUERY,
  GET_ADDRESS_DETAILS_QUERY,
  GET_DELIVERY_ADDRESS_QUERY,
  GET_INVOICE_ADDRESS_QUERY,
  GET_LEAD_QUERY,
  LEAD_SUBMIT_QUERY,
  LEAD_UPDATE_QUERY,
  VALIDATE_LEAD_ADDRESS_QUERY,
} from './+lead.state';

type Action$ = ActionsObservable<LeadPageActions>;
type State$ = StateObservable<StoreState>;

const createLead$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadPageActions.createLead),
    map((action) => action.leadForm),
    map(LeadPageHelper.mapFormToLead),
    map((lead) => LeadActions.postLead({ queryKey: LEAD_SUBMIT_QUERY, lead }))
  );

const createLeadSuccess$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postLeadSuccess),
    map((action) => action.lead.id),
    mergeMap((leadId) =>
      concat(of(push(PATHS.LEAD_OVERVIEW({ leadId }))), of(QueryActions.init(LEAD_SUBMIT_QUERY)))
    )
  );

const updateLead$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadPageActions.updateLead),
    map((action) => action.leadData),
    mergeMap((leadData) =>
      of(leadData).pipe(
        mapToState(state$),
        mergeMap((state) =>
          forkJoin(
            of(state).pipe(
              mapPathToParams(
                ROUTES.LEAD_OVERVIEW[0],
                ROUTES.SETUP_LEAD_OVERVIEW[0],
                ROUTES.LEAD_CONFIGURATION[0],
                ROUTES.SETUP_LEAD_CONFIGURATION[0]
              ),
              map(([leadId]) => leadId)
            )
          )
        ),
        mergeMap(([leadId]) =>
          !leadId
            ? throwError(new Error('updateLead$ :: cannot update lead'))
            : of({
                leadId: leadId as NonNullable<typeof leadId>,
              })
        ),
        map((data) =>
          LeadActions.patchLead({ queryKey: LEAD_UPDATE_QUERY, leadData, leadId: data.leadId })
        )
      )
    )
  );

const changeLeadStatus$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadPageActions.changeLeadStatus),
    mergeMap(({ leadId, statusNames }) =>
      of({}).pipe(
        processQuery(
          CHANGE_LEAD_STATUS_QUERY,
          // @TODO: Get rid of it when the backend API allows changing multiple statuses at once
          () =>
            forkJoin(
              statusNames.map((statusName) =>
                LeadRepository.postLeadStatus({ name: statusName }, leadId)
              )
            ),
          {
            onSuccess: () =>
              concat(
                of(LeadListPageActions.triggerGetLeadList()),
                of(LeadActions.getLead(leadId, GET_LEAD_QUERY)),
                iif(() => statusNames.includes(LeadStatusName.IN_SETUP), of(push(PATHS.LEADS())))
              ),
          }
        )
      )
    )
  );

const markLeadAsSeen$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadPageActions.markLeadAsSeen),
    mapToState(state$),
    mapPathToParams(
      ROUTES.LEAD_OVERVIEW[0],
      ROUTES.SETUP_LEAD_OVERVIEW[0],
      ROUTES.LEAD_CONFIGURATION[0],
      ROUTES.SETUP_LEAD_CONFIGURATION[0],
      ROUTES.SETUP_LEAD_SETUP_TOOL[0]
    ),
    filter((params) => !isEmpty(params)),
    mergeMap(([leadId]) =>
      of(leadId).pipe(
        processQuery(MARK_AS_SEEN_QUERY, () => LeadRepository.patchLeadAsSeen(leadId), {
          onSuccess: () => of(LeadPageActions.markLeadAsSeenSuccess()),
        })
      )
    )
  );

export const getPartnerEmployees$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadPageActions.getPartnersEmployeeList),
    processQuery(
      GET_PARTNER_EMPLOYEE_COLLECTION_QUERY,
      () => LeadRepository.getPartnerEmployees(),
      { onSuccess: (res) => of(LeadPageActions.setPartnerEmployeeList(res!.elements)) }
    )
  );

export const reassignPartnerToLead$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadPageActions.reassignPartnerToLead),
    mergeMap((action) =>
      of(action).pipe(
        processQuery(
          ASSIGN_PARTNER_TO_LEAD_QUERY,
          () =>
            LeadRepository.patchReassignLeadsPartner(
              action.leadId,
              action.partnerSalesforceContactId
            ),
          {
            onSuccess: () =>
              concat(
                of(LeadActions.getLead(action.leadId, GET_LEAD_QUERY)),
                of(LayoutActions.toggleModal(false)),
                of(LeadPageActions.toggleAssignLeadModal(false))
              ),
          }
        )
      )
    )
  );

const getAddressAutosuggestions$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadPageActions.getAddressAutosuggestions),
    mergeMap((action) =>
      of(action).pipe(
        processQuery(
          GET_ADDRESS_AUTOSUGGESTIONS_QUERY,
          () =>
            LeadRepository.getAddressAutosuggestions(
              action.query,
              action.country,
              action.sessionId
            ),
          { onSuccess: (res) => of(LeadPageActions.setAddressAutosuggestions(res!.elements)) }
        )
      )
    )
  );

const getAddressDetails$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadPageActions.getAddressDetails),
    mergeMap((action) =>
      of(action).pipe(
        processQuery(
          GET_ADDRESS_DETAILS_QUERY,
          () =>
            LeadRepository.getAddressDetails(
              action.placeId,
              action.lang,
              action.sessionId,
              action.query
            ),
          { onSuccess: (res) => dataGuard(LeadPageActions.setAddressDetails)(res!.element) }
        )
      )
    )
  );

const getDeliveryAddress$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadPageActions.getDeliveryAddressSuggestions),
    mergeMap((action) =>
      of(action).pipe(
        processQuery(
          GET_DELIVERY_ADDRESS_QUERY,
          () => LeadRepository.getDeliveryAddress(action.postcode, action.city, action.street),
          {
            onSuccess: (res) =>
              dataGuard(LeadPageActions.setDeliveryAddressSuggestions)(res!.element),
          }
        )
      )
    )
  );

const getInvoiceAddress$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadPageActions.getInvoiceAddressSuggestions),
    mergeMap((action) =>
      of(action).pipe(
        processQuery(
          GET_INVOICE_ADDRESS_QUERY,
          () => LeadRepository.getDeliveryAddress(action.postcode, action.city, action.street),
          {
            onSuccess: (res) =>
              dataGuard(LeadPageActions.setInvoiceAddressSuggestions)(res!.element),
          }
        )
      )
    )
  );

const validateLeadAddress$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadPageActions.validateLeadAddress),
    mergeMap((action) =>
      of(action.address).pipe(
        processQuery(
          VALIDATE_LEAD_ADDRESS_QUERY,
          (address) =>
            LeadRepository.getAddressValidation(
              address.country,
              address.city,
              address.zipCode,
              address.street
            ),
          { onSuccess: (res) => of(LeadPageActions.validateLeadAddressSuccess(res.meta!.isValid)) }
        )
      )
    )
  );

// after each setting of the status we have to clear store, so we don't set it in wrong places
const clearAsyncOfferSentStatus$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadPageActions.setAsyncFlatOfferSentStatus),
    map(() => LeadPageActions.clearAsyncLeadStatus())
  );

export const epics = combineEpics(
  createLead$,
  createLeadSuccess$,
  configuration$,
  hardware$,
  overview$,
  leadList$,
  offer$,
  updateLead$,
  changeLeadStatus$,
  markLeadAsSeen$,
  impactAnalysis$,
  getPartnerEmployees$,
  reassignPartnerToLead$,
  getAddressAutosuggestions$,
  getAddressDetails$,
  getDeliveryAddress$,
  getInvoiceAddress$,
  validateLeadAddress$,
  clearAsyncOfferSentStatus$
);
