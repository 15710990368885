import * as React from 'react';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { AdobeAnalyticsContextProvider } from '+shared/AdobeAnalytics/AdobeAnalyticsContext/adobeAnalyticsContext';
import { FirebaseContext } from '+shared/components/FirebaseContext';
import { GetFeedbackWrapper } from '+shared/containers/GetFeedbackWrapper/GetFeedbackWrapper';
import { Layout } from '+shared/containers/Layout';
import { Firebase } from '+shared/store/firebase/firebase.client';
import { persistor, store } from '+shared/store/store.config';
import { LaunchDarklyProvider } from '+utils/react/launchDarkly.provider';

import { Routing } from './router/Routing.component';
import { history } from './router/store';
import { LocaleProvider } from './utils/react/locale.provider.component';

export const App = () => {
  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <ConnectedRouter history={history}>
          <PersistGate persistor={persistor}>
            {(bootstrapped: boolean) => (
              <LocaleProvider>
                <AdobeAnalyticsContextProvider>
                  <LaunchDarklyProvider>
                    <GetFeedbackWrapper>
                      <Layout isBootstrapped={bootstrapped}>
                        <Routing />
                      </Layout>
                    </GetFeedbackWrapper>
                  </LaunchDarklyProvider>
                </AdobeAnalyticsContextProvider>
              </LocaleProvider>
            )}
          </PersistGate>
        </ConnectedRouter>
      </FirebaseContext.Provider>
    </Provider>
  );
};
