import React from 'react';

import './ResponsivePageWidth.scss';

/*
 * This is a simple, straightforward container
 * which provides responsive resizing
 * of width according to media queries and
 * centers its content horizontally.
 * 
 * It behaves like the existing Container,
 * but without nested components and indirect styling.
  
 * It can be used for:
 *    - an entire page,
 *    - sections (like PageHeader),
 *    - components
 * 
 * It does not provide vertical spacing or padding.
 **/

const ResponsivePageWidth: React.FC = ({ children }) => (
  <section className="responsive-page-width">{children}</section>
);

export default ResponsivePageWidth;
