import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { omit } from 'lodash/fp';

import { EnergyUnit } from '+app/App.constants';
import {
  formatMeasurementEnergy,
  formatPricePerKwh,
  getCurrencyForMarket,
} from '+lead-configuration/components/LeadConfigurationPreview';
import {
  getMeasurementFallback,
  HardwareOfferAccepted,
  HardwareOfferSent,
  HardwareOrderConfirmed,
  Lead,
  LeadConfigurationResponse,
  LeadConfigurationResponseAttributes,
  LeadCreateDto,
  LeadProductInterest,
  LeadStage,
  LeadStatusName,
  LeadTso,
} from '+shared/store/lead/types';
import { UserRole } from '+shared/store/user';
import { formatDate } from '+utils/format.util';
import { formatCurrency, formatEnergy } from '+utils/format.util.old';
import { isStatusSet } from '+utils/status.util';

import { LeadForm } from './types';

type LeadEnergyConsumption = Lead['energyConsumption'];

const mapFormToLead = (form: LeadForm): LeadCreateDto => {
  const mappedLeadWithCompany = { ...form, company: { name: form.company } };

  const {
    deliveryAddress: { houseNumber, street, ...restOfDelivery },
    agreedToMarketingMailing,
    ...otherValues
  } = mappedLeadWithCompany;

  const mappedLeadWithCompanyAndAddress = {
    // needs negation here since checkbox agrees to marketing mailing
    optedOutOfMarketingMailing: !agreedToMarketingMailing,
    ...otherValues,
    deliveryAddress: {
      street: `${street.trim()} ${houseNumber.trim()}`,
      ...restOfDelivery,
    },
  };

  return form.hasDifferentInvoiceAddress
    ? omit(['hasDifferentInvoiceAddress'], mappedLeadWithCompanyAndAddress)
    : omit(['hasDifferentInvoiceAddress', 'invoiceAddress'], mappedLeadWithCompanyAndAddress);
};

export const LeadPageHelper = {
  mapFormToLead,
};

export const getConfigurationDetailList = (configuration: LeadConfigurationResponse) => {
  const currency = getCurrencyForMarket(configuration.market);

  return [
    {
      label: I18n.t(T.lead.configuration._salessolution_.freeAmountOfEnergy),
      value: formatMeasurementEnergy(configuration.usageAllowancePerYear),
    },
    {
      label: I18n.t(T.lead.configuration._salessolution_.priceWithinFreeAmountOfEnergy),
      value: formatPricePerKwh(0, currency),
    },
    {
      label: I18n.t(T.lead.configuration._salessolution_.expectedAdditionalGridConsumption),
      value: formatMeasurementEnergy(
        configuration.powerPlant
          ? configuration.powerPlant.expectedGridConsumptionPerYear
          : getMeasurementFallback(EnergyUnit.KWH)
      ),
    },
    {
      label: I18n.t(T.lead.configuration._salessolution_.excessRate),
      value: formatPricePerKwh(configuration.excessTariffGrossPerKwh.value, currency),
    },
    {
      label: I18n.t(T.lead.configuration._salessolution_.monthlyRate),
      value: `${formatCurrency(
        configuration.powerPlant ? configuration.initialAdvancePaymentGrossPerMonth.value : 0,
        currency
      )} ${I18n.t(T.lead.configuration._salessolution_.perMonth)}`,
    },
    {
      label: I18n.t(T.lead.configuration._salessolution_.sonnenBonus),
      value: `${formatCurrency(configuration.cashbackTariffGrossPerKwh.value, currency)} ${I18n.t(
        T.lead.configuration._salessolution_.sonnenBonusDescription
      )}`,
    },
    {
      label: I18n.t(T.lead.configuration._salessolution_.vpp.title),
      value: isVppBonusGranted(configuration)
        ? `${I18n.t(T.lead.configuration._salessolution_.vpp.minimum)} ` +
          `${formatCurrency(
            configuration.powerPlant.guaranteedVppBonusGrossPerYear!.value,
            currency
          )}` +
          `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
        : I18n.t(T.lead.configuration._salessolution_.vpp.notEligible),
    },
  ];
};

export const mapLocation = (item: Lead) =>
  item.deliveryAddress
    ? `${item.deliveryAddress.zipCode ? item.deliveryAddress.zipCode : ''}
    ${item.deliveryAddress.city ? item.deliveryAddress.city : ''}`
    : '';

export const mapConsumption = (energyConsumption: LeadEnergyConsumption) => {
  const consumption = energyConsumption && energyConsumption.approxConsumption;

  return consumption
    ? formatEnergy({
        whValue: Number(consumption.value) * 1000,
        unit: consumption.unit,
        precision: 0,
      }) + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
    : '';
};

const getLeadCreationDate = (lead: Lead) => {
  const status = lead.status.summary.open;
  return status ? status.createdAt : null;
};

export const mapCreationDate = (lead: Lead) => {
  const creationDate = getLeadCreationDate(lead);
  if (!creationDate) {
    return null;
  }

  return formatDate(creationDate, 'DD.MM.YYYY');
};

export const companyCoordinatorRoles = [UserRole.COMPANY_COORDINATOR];

export const isVppBonusGranted = (configuration: LeadConfigurationResponse) =>
  configuration && configuration.powerPlant.guaranteedVppBonusGranted;

export const isVppMeteringMandatory = (configuration: LeadConfigurationResponse) =>
  configuration.powerPlant.vppMeteringMandatory;

export const isLeadSonnenNowInterested = ({ additionalData }: Lead) =>
  additionalData && additionalData.productInterest
    ? Object.keys(additionalData.productInterest)
        .filter((key) => Boolean(additionalData!.productInterest![key]))
        .includes(LeadProductInterest.SONNEN_NOW)
    : false;

export const hasInSetupStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.inSetup);

export const hasFlatOfferSentStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.flatOfferSent);

export const hasFlatOfferAcceptedStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.flatOfferAccepted);

export const hasFlatOfferConfirmedStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.flatOrderConfirmed);

export const hasHardwareOfferAcceptedStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.hardwareOfferAccepted);

export const hasHardwareOfferConfirmedStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.hardwareOrderConfirmed);

export const hasNotAcceptedOfferYet = (lead: Lead | undefined) =>
  lead !== undefined && lead.status.summary.flatOfferAccepted === null;

export const hasPVRegisteredStatus = (lead: Lead | undefined) =>
  lead !== undefined && isStatusSet(lead.status.summary.bNetzARegistrationCompleted);

export const isTsoAssigned = (tso?: LeadTso | null) => tso && tso.name;

export const getLeadHwStatusesToUpdate = (updatedStatus: LeadStatusName) => ({
  [LeadStatusName.HW_OFFER_SENT]: [
    LeadStatusName.HW_OFFER_SENT,
    LeadStatusName.HW_OFFER_ACCEPTED,
    LeadStatusName.HW_ORDER_CONFIRMED,
  ].includes(updatedStatus)
    ? ({
        stage: LeadStage.OFFER_SENT,
        name: LeadStatusName.HW_OFFER_SENT,
        isSet: true,
        createdAt: new Date(),
      } as HardwareOfferSent)
    : null,
  [LeadStatusName.HW_OFFER_ACCEPTED]: [
    LeadStatusName.HW_OFFER_ACCEPTED,
    LeadStatusName.HW_ORDER_CONFIRMED,
  ].includes(updatedStatus)
    ? ({
        stage: LeadStage.OFFER_ACCEPTED,
        name: LeadStatusName.HW_OFFER_ACCEPTED,
        isSet: true,
        createdAt: new Date(),
      } as HardwareOfferAccepted)
    : null,
  [LeadStatusName.HW_ORDER_CONFIRMED]:
    updatedStatus === LeadStatusName.HW_ORDER_CONFIRMED
      ? ({
          stage: LeadStage.ORDER_CONFIRMED,
          name: LeadStatusName.HW_ORDER_CONFIRMED,
          isSet: true,
          createdAt: new Date(),
        } as HardwareOrderConfirmed)
      : null,
});

export const isConfigurationMultiplePv = (configuration: LeadConfigurationResponseAttributes) =>
  !!configuration.photovoltaicSystem.subSystems;
