import { createSelector } from 'reselect';

import { StoreState } from '../store.interface';

export const getFirebase = (state: StoreState) => state.shared.firebase;

export const getFirebaseNewsList = createSelector(getFirebase, (firebase) => firebase.newsList);

export const getFirebaseReadNewsList = createSelector(
  getFirebase,
  (firebase) => firebase.readNewsList
);

export const getFirebaseNewsListFetched = createSelector(
  getFirebase,
  (firebase) => firebase.isNewsListFetched
);

export const getFirebaseReadNewsListFetched = createSelector(
  getFirebase,
  (firebase) => firebase.isReadNewsListFetched
);

export const getFirebaseNotificationsList = createSelector(
  getFirebase,
  (firebase) => firebase.notificationsList
);

export const getFirebaseNotificationsListFetched = createSelector(
  getFirebase,
  (firebase) => firebase.isNotificationsListFetched
);

export const getVideoList = createSelector(getFirebase, (firebase) => firebase.videoList);
