import { StatusType } from '@sonnen/shared-web/src/components/Status/Status.component';

import { StatusTileColors } from '+app/shared/components/StatusTile';

export const setLabelColor = (type: StatusType) =>
  ({
    default: StatusTileColors.GRAY,
    warning: StatusTileColors.YELLOW,
    danger: StatusTileColors.RED,
    success: StatusTileColors.GREEN,
    info: StatusTileColors.BLUE,
  }[type]);
