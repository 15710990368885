import React, { useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { getCurrencyForMarket } from '+app/+lead/+configuration/components';
import { isFlatDirectConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { isVppMeteringMandatory } from '+app/+lead/store/+lead.helper';
import { LeadFlatConfigurationExpiredTile } from '+lead/components/LeadFlatConfigurationExpiredTile';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { FormInputInfo } from '+shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import SwitchButton from '+shared/components/SwitchButton/SwitchButton';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { LeadConfigurationResponse, LeadOfferTaxRegulation } from '+shared/store/lead/types';
import { mapActions } from '+utils/redux';

import { LeadConfigurationTileStatus } from '../..';
import { LeadConfigurationLine } from '../../LeadConfigurationLine';
import { renderStatus } from '../../LeadConfigurationTile/LeadConfigurationTile.helper';
import {
  factorizeConfMainDetails,
  factorizeHouseholdDetails,
  getSmallEnterpriseTaxationConfigurationDetails,
  getStandardTaxationConfigurationDetails,
} from '../LeadFlatConfigurationTile.helper';
import LeadFlatConfigurationTileTariffModal from './LeadFlatConfigurationTileTariffModal';

import '../LeadFlatConfigurationTile.scss';

interface OwnProps {
  configuration: LeadConfigurationResponse;
  isHardwareProductAccepted?: boolean;
  isOfferBlocked?: boolean;
  status?: LeadConfigurationTileStatus;
  leadOfferTaxRegulation?: LeadOfferTaxRegulation;
  onRecalculation: (() => void) | undefined;
  onDeleteExpiredOffer?: () => void;
}

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;

const LeadFlatConfigurationTileTariffComponent: React.FC<Props> = ({
  configuration,
  isHardwareProductAccepted = false,
  isOfferBlocked = false,
  status = LeadConfigurationTileStatus.PREVIEW,
  // leadOfferTaxRegulation: standard as default, because old configs lack the attribute
  leadOfferTaxRegulation = LeadOfferTaxRegulation.STANDARD,
  onRecalculation,
  onDeleteExpiredOffer,
  actions,
}) => {
  const [detailedViewOpen, setDetailedViewOpen] = useState<boolean>(false);

  const {
    basedOnSmallBusinessRegulation,
    whatDoesThatMean,
    vatInfo,
    detailedView,
    calculationBasedOn,
  } = T.lead.configuration._salessolution_.preview;
  const { meterDocumented, meterPreparationRequired, noMeterPreparation } =
    T.lead.configuration._salessolution_;
  const { headline } = T.customerSingle.overview.sonnenFlat;

  const currency = getCurrencyForMarket(configuration.market);
  const sonnenFlatHeaderDetails = factorizeConfMainDetails(configuration, currency);
  const sonnenFlatDetails =
    leadOfferTaxRegulation === LeadOfferTaxRegulation.SMALL_ENTERPRISE
      ? getSmallEnterpriseTaxationConfigurationDetails(configuration, currency)
      : getStandardTaxationConfigurationDetails(configuration, currency);
  const householdDetails = factorizeHouseholdDetails(configuration);

  const handleOnClick = () => actions.toggleModal(true, ModalId.SMALL_ENTERPRISE_INFORMATION);

  const informationBoxText = (): JSX.Element | string => {
    if (leadOfferTaxRegulation === LeadOfferTaxRegulation.SMALL_ENTERPRISE) {
      return (
        <>
          {I18n.t(basedOnSmallBusinessRegulation)}
          <Button
            onClick={handleOnClick}
            className={'c-lead-flat-configuration-tile__link'}
            label={I18n.t(whatDoesThatMean)}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
          />
        </>
      );
    }

    return I18n.t(vatInfo, {
      vat: parseFloat(configuration.valueAddedTax.value.toString()),
    });
  };

  const getMeterPreparationInfo = (): string => {
    if (isVppMeteringMandatory(configuration)) {
      const meterInformation = isFlatDirectConfiguration(configuration)
        ? meterDocumented
        : meterPreparationRequired;
      return meterInformation;
    }

    return noMeterPreparation;
  };

  return (
    <>
      <div className={'c-lead-flat-configuration-tile__header'}>
        <LabelLarge text={I18n.t(headline)} />
        <div className={'c-lead-configuration-tile__header-detail'}>{renderStatus(status)}</div>
      </div>
      <div className={'c-lead-flat-configuration-tile__header-details'}>
        {sonnenFlatHeaderDetails.map(({ title, value, type }, index) => (
          <LeadConfigurationLine title={title} value={value} type={type} key={`detail-${index}`} />
        ))}
      </div>

      <LineDivider />

      <div className="c-lead-flat-configuration-tile__detailed-view">
        <LabelMedium text={I18n.t(detailedView)} />
        <SwitchButton
          id={`detailed-toggle-${configuration.id}`}
          isChecked={detailedViewOpen}
          onChange={() => setDetailedViewOpen((prev) => !prev)}
          dataTestId="expand-detail-view-btn"
        />
      </div>

      {sonnenFlatDetails.map(({ title, value, type, inCollapsedViewVisible, helperLabel }, index) =>
        detailedViewOpen ? (
          <LeadConfigurationLine
            title={title}
            value={value}
            type={type}
            key={`detail-${index}`}
            helperLabel={helperLabel}
            className={
              type === 'labelMedium' ? 'c-lead-flat-configuration__header-line' : undefined
            }
          />
        ) : (
          inCollapsedViewVisible && (
            <LeadConfigurationLine
              title={title}
              value={value}
              type={type}
              key={`detail-${index}`}
            />
          )
        )
      )}

      <div className={'c-lead-flat-configuration-tile__information'}>
        <FormInputInfo>
          <BodySmall text={informationBoxText()} />
        </FormInputInfo>
      </div>

      <LeadFlatConfigurationTileTariffModal />

      <LineDivider />

      <LabelMedium
        text={I18n.t(calculationBasedOn)}
        className="c-lead-flat-configuration-tile__calculation-based-on"
      />
      {householdDetails.map(({ title, value, type }, index) => (
        <LeadConfigurationLine title={title} value={value} key={`detail-${index}`} type={type} />
      ))}

      {(configuration.vppCheckDone || !isVppMeteringMandatory(configuration)) && (
        <div className={'c-lead-flat-configuration-tile__information'}>
          <FormInputInfo>
            <BodySmall text={I18n.t(getMeterPreparationInfo())} />
          </FormInputInfo>
        </div>
      )}
      {onRecalculation &&
        onDeleteExpiredOffer &&
        status === LeadConfigurationTileStatus.EXPIRED && (
          <LeadFlatConfigurationExpiredTile
            onRecalculation={onRecalculation}
            onDelete={onDeleteExpiredOffer}
            status={status}
            isHardwareProductAccepted={isHardwareProductAccepted}
            isOfferBlocked={isOfferBlocked}
          />
        )}
    </>
  );
};

const LeadFlatConfigurationTileTariff = connect(
  null,
  mapDispatchToProps
)(LeadFlatConfigurationTileTariffComponent);

export default LeadFlatConfigurationTileTariff;
