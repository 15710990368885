import { isEqual } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { from, of, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { dataGuard, mapToState, ofType } from '+app/utils';
import { getDsoRegistrationMeasuringDeviceInitial } from '+setupTool/+form/store/schemas';
import {
  getVppDocumentationFields,
  getVppSubmissionStatus,
  VppDocumentationActions,
  VppRegisterStatus,
} from '+setupTool/+vppDocumentation/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStepCamelCase } from '+setupTool/store/+setupTool.dictionary';
import { DsoRegisterActions } from '+shared/store/setupTool';
import { DocumentSource } from '+shared/store/setupTool/types';
import { StoreState } from '+shared/store/store.interface';

import { DsoRegistrationFormActions } from './+form.actions';
import { getSetupDataSchemas } from './+form.helpers';
import {
  getCustomerDataForm,
  getDocumentsDataForm,
  getInstallerDataForm,
  getMeasuringDeviceForm,
  getPvSystemForm,
  getSonnenBatteryForm,
} from './+form.selectors';
import { DsoRegistrationCustomerDataFiles, DsoRegistrationDocumentsFiles } from './schemas';

type Action$ = ActionsObservable<DsoRegistrationFormActions | DsoRegisterActions>;
type State$ = StateObservable<StoreState>;

const registerDsoSuccess$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoRegisterActions.postRegisterDsoSuccess),
    map((action) => action.config.data),
    map(DsoRegistrationFormActions.submitDsoRegistrationFormSuccess)
  );

const sendDsoDocumentsFile$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoRegistrationFormActions.sendDsoDocumentsFile),
    map((action) => action.config),
    map((data) =>
      DsoRegisterActions.postSendDsoDocument({
        userId: data.userId,
        customerId: data.customerId,
        submissionId: data.submissionId,
        category: data.category,
        file: data.file,
        queryKey: data.queryKey,
      })
    )
  );

const removeDsoDocumentsFile$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoRegistrationFormActions.removeDsoDocumentsFile),
    map((action) => action.config),
    map((data) =>
      DsoRegisterActions.removeDsoDocument({
        submissionId: data.submissionId,
        documentId: data.documentId,
        queryKey: data.queryKey,
      })
    )
  );

const registerDsoError$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoRegisterActions.postRegisterDsoFailure),
    map((action) => action.error),
    map(DsoRegistrationFormActions.submitDsoRegistrationFormError)
  );

const handleDsoFormFulfillment$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(DsoRegistrationFormActions.checkFormFulfillment),
    mergeMap((action) =>
      of(action).pipe(
        mapToState(state$),
        mergeMap((state) =>
          zip(
            of(getCustomerDataForm(state)),
            of(getPvSystemForm(state)),
            of(getMeasuringDeviceForm(state)),
            of(getSonnenBatteryForm(state)),
            of(getInstallerDataForm(state)),
            of(getDocumentsDataForm(state))
          )
        ),
        map((v) => v.reduce((merged, formChunk) => ({ ...merged, ...formChunk }), {})),
        mergeMap((formState) =>
          from(
            getSetupDataSchemas(action.registrationSubject).validate(formState, {
              abortEarly: false,
            })
          ).pipe(
            map((v) => !!v),
            catchError(() => of(false)) // TODO: add errors to store?
          )
        )
      )
    ),
    map(DsoRegistrationFormActions.setFormFulfillment)
  );

const setUploadedDocuments$ = (action$: Action$) =>
  action$.pipe(
    ofType(SetupToolActions.setUploadedDocuments),
    map((action) => action.data),
    map((documents) =>
      documents.filter(
        (document) =>
          document.source === DocumentSource.INPUT &&
          document.upload &&
          [...DsoRegistrationCustomerDataFiles, ...DsoRegistrationDocumentsFiles].indexOf(
            document.upload.category
          ) !== -1
      )
    ),
    mergeMap(dataGuard(DsoRegistrationFormActions.setFormUploadedDocuments))
  );

const setLatestModification$ = (action$: Action$) =>
  action$.pipe(
    ofType(SetupToolActions.setLatestModificationDate),
    map((action) => action.data[SubmissionStepCamelCase.SETUP_DATA]),
    mergeMap(dataGuard(DsoRegistrationFormActions.setFormModificationDate))
  );

const synchronizeMeasuringDeviceFieldsWithVpp$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(VppDocumentationActions.setFields),
    mapToState(state$),
    map((state) => {
      if (getVppSubmissionStatus(state) !== VppRegisterStatus.FINISH) {
        const measuringDeviceFields =
          getMeasuringDeviceForm(state) || getDsoRegistrationMeasuringDeviceInitial();
        const vppFields = getVppDocumentationFields(state);

        const newFields = {
          ...measuringDeviceFields,
          meter_number: vppFields.meter_number,
          type_of_grid_reference_meter: vppFields.type_of_grid_reference_meter,
        };

        if (!isEqual(measuringDeviceFields, newFields)) {
          return DsoRegistrationFormActions.setDsoMeasuringDevice(newFields);
        }
      }
      return { type: '' };
    })
  );

export const epics = combineEpics(
  registerDsoSuccess$,
  registerDsoError$,
  sendDsoDocumentsFile$,
  removeDsoDocumentsFile$,
  handleDsoFormFulfillment$,
  setUploadedDocuments$,
  setLatestModification$,
  synchronizeMeasuringDeviceFieldsWithVpp$
);
