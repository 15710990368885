import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Select } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { CustomerSortKey } from '../../../shared/store/customer/types/customerSort.type';

import './SortField.component.scss';

interface SortOption {
  name: string;
  key: string;
}

interface Props<T extends SortOption> {
  options: T[];
  selectedKey: string;
  className?: ClassValue;
  onOptionChange: (sort: CustomerSortKey, e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  handleShow?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  dropDownDataTestId?: string;
  dropDownOptionsDataTestId?: string;
}

export const SortField = <T extends SortOption>({
  options,
  selectedKey,
  onOptionChange,
  className,
  handleShow,
  dropDownDataTestId,
  dropDownOptionsDataTestId,
}: Props<T>) => (
  <div className={classNames('c-sort', className)}>
    <span className={'c-sort__label'}>{I18n.t(T.dashboard.sortBy)}</span>
    <div className={'c-sort__select'} data-testid={dropDownDataTestId}>
      <Select<SortOption>
        items={options}
        itemsSelected={
          selectedKey
            ? options.find((option) => option.key === selectedKey) || options[0]
            : options[0]
        }
        itemFactory={(option) => I18n.t(option.name)}
        onSelect={(option: SortOption, e: React.SyntheticEvent<HTMLAnchorElement>) =>
          selectedKey !== option.key && onOptionChange(option.key as any, e)
        }
        handleShow={handleShow}
        dataTestId={dropDownOptionsDataTestId}
      />
    </div>
  </div>
);
