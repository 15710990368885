import React, { useState } from 'react';

import * as classNames from 'classnames';
import { isUndefined } from 'lodash';

import { SetupToolStepsList } from '+setupTool/components';
import { SetupToolStep } from '+setupTool/store/types';

import './SetupToolTable.component.scss';

interface Props {
  items: SetupToolStep[];
  headline?: string;
  description?: string;
  isH1Headline?: boolean;
  isCollapsed?: boolean;
  additionalHeaderContent?: React.ReactNode;
  dataTestId: string;
}

export const SetupToolTable: React.FC<Props> = ({
  items,
  headline,
  description,
  isH1Headline,
  isCollapsed,
  additionalHeaderContent,
  dataTestId,
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed || false);

  if (items.some(isUndefined)) {
    return null;
  }

  return (
    <div
      className={classNames(`c-setup-tool-step-list-table`, {
        'c-setup-tool-step-list-table--collapsed': collapsed,
      })}
    >
      <div className={'c-list-table__header'}>
        <div className={'c-list-table__header__main-content'}>
          {isH1Headline ? (
            <h1 className={'c-list-table__headline'}>{headline}</h1>
          ) : (
            <h2 className={'c-list-table__headline'}>{headline}</h2>
          )}
          <p className={'c-list-table__description'}>{description}</p>
          <span
            className={'c-list-table__arrow'}
            onClick={() => setCollapsed(!collapsed)}
            data-testid={dataTestId}
          />
        </div>
      </div>

      {!collapsed && additionalHeaderContent && (
        <div className={'c-list-table__header__additional-content'}>{additionalHeaderContent}</div>
      )}

      {!collapsed && (
        <div className={'c-list-table__table'}>
          <SetupToolStepsList items={items} />
        </div>
      )}
    </div>
  );
};
