import { SiteLiveState } from '+shared/store/site/types';
import { ActionsUnion, createAction } from '+utils/redux';

export enum OVERVIEW_ACTIONS {
  START_OVERVIEW_LIVE_STATE_POLLING = '[Site] START_OVERVIEW_LIVE_STATE_POLLING',
  STOP_OVERVIEW_LIVE_STATE_POLLING = '[Site] STOP_OVERVIEW_LIVE_STATE_POLLING',
  SET_OVERVIEW_LIVE_STATE = '[Site] SET_OVERVIEW_LIVE_STATE',
}

export const OverviewActions = {
  startOverviewLiveStatePolling: createAction(
    OVERVIEW_ACTIONS.START_OVERVIEW_LIVE_STATE_POLLING,
    (siteId: string) => ({ siteId })
  ),
  stopOverviewLiveStatePolling: createAction(OVERVIEW_ACTIONS.STOP_OVERVIEW_LIVE_STATE_POLLING),
  setOverviewLiveState: createAction(
    OVERVIEW_ACTIONS.SET_OVERVIEW_LIVE_STATE,
    (liveState: SiteLiveState | undefined) => ({ liveState })
  ),
};

export type OverviewActions = ActionsUnion<typeof OverviewActions>;
