import { EnergyFlowSeriesKey, SharedChartColors } from '@sonnen/shared-web';

import {
  AreaChartViewStyle,
  AxisViewStyle,
  ChartGridViewStyle,
  DataDisplayType,
} from '@kanva/charts';
import { Paint, TextAlign, TextBaseline } from '@kanva/core';

import { CHART_FONT } from '+app/+customer/+analysis/+analysis.consts';

type PPEnergyFlowSeriesKey = Exclude<EnergyFlowSeriesKey, 'gridPurchase' | 'gridFeedin'>;

/**
 * Area charts styling
 */

export const areaChartStyles: Record<PPEnergyFlowSeriesKey, AreaChartViewStyle> = {
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(SharedChartColors.ProductionFill)
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.ProductionStroke),
  },
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(SharedChartColors.ConsumptionFill)
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.ConsumptionStroke),
  },
  [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint().setFillStyle(SharedChartColors.DirectUsageFill),
  },
  [EnergyFlowSeriesKey.BATTERY_CHARGING]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(SharedChartColors.BatteryChargingFill)
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.BatteryChargingStroke),
  },
  [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(SharedChartColors.BatteryDischargingFill)
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.BatteryDischargingStroke),
  },
  [EnergyFlowSeriesKey.BATTERY_USOC]: {
    type: DataDisplayType.LINE,
    paint: new Paint().setLineWidth(2).setStrokeStyle(SharedChartColors.Usoc),
  },
  [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: {
    type: DataDisplayType.LINE,
    paint: new Paint().setLineWidth(1).setStrokeStyle(SharedChartColors.ForecastProduction),
  },
  [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: {
    type: DataDisplayType.LINE,
    paint: new Paint().setLineWidth(1).setStrokeStyle(SharedChartColors.ForecastConsumption),
  },
};

/**
 * Area chart grid styling
 */

export const areaChartGridStyle: ChartGridViewStyle = {
  paint: new Paint().setStrokeStyle(SharedChartColors.Axis).setLineWidth(1),
};

/**
 * Area chart axis styling
 */

const axisPaint = new Paint()
  .setFont(CHART_FONT)
  .setFillStyle(SharedChartColors.Labeling)
  .setLineWidth(2);

export const areaChartXAxisStyle: AxisViewStyle = {
  labelPaint: axisPaint.clone().setTextAlign(TextAlign.CENTER).setTextBaseline(TextBaseline.TOP),
  labelPadding: 0,
};

export const areaChartYAxisLeftStyle: AxisViewStyle = {
  labelPaint: axisPaint.clone().setTextAlign(TextAlign.LEFT).setTextBaseline(TextBaseline.BOTTOM),
};

export const areaChartYAxisRightStyle: AxisViewStyle = {
  labelPaint: axisPaint.clone().setTextAlign(TextAlign.RIGHT).setTextBaseline(TextBaseline.BOTTOM),
};
