import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Close: React.FC<Props> = ({ color, size = 24, className }) => (
  <svg className={className} width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21006 3.21005C2.92999 3.49012 2.92999 3.9442 3.21006 4.22427L10.9858 12L3.21005 19.7758C2.92998 20.0559 2.92998 20.51 3.21005 20.79C3.49012 21.0701 3.9442 21.0701 4.22427 20.79L12 13.0143L19.7757 20.7899C20.0558 21.07 20.5099 21.07 20.7899 20.7899C21.07 20.5098 21.07 20.0558 20.7899 19.7757L13.0143 12L20.7899 4.22438C21.07 3.94431 21.07 3.49023 20.7899 3.21016C20.5098 2.93009 20.0558 2.93009 19.7757 3.21016L12 10.9858L4.22428 3.21005C3.94421 2.92998 3.49013 2.92998 3.21006 3.21005Z"
      fill={color}
    />
  </svg>
);
