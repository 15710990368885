import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointDown, breakpointUp, Card, TableCell } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { push } from 'connected-react-router';

import { mapActions } from '+app/utils';
import { SetupToolStepStatus } from '+setupTool/components';
import { StatusName } from '+setupTool/store/+setupTool.dictionary';
import { SetupToolStep } from '+setupTool/store/types';
import { ButtonDirection, RoundButton } from '+shared/components';
import { Button, ButtonStatus, ButtonType } from '+shared/components/Button';

import './SetupToolStepsListItem.component.scss';

const mapDispatchToProps = mapActions({
  goTo: (path: string) => push(path),
});

interface ComponentProps {
  item: SetupToolStep;
}

type Props = ReturnType<typeof mapDispatchToProps> & ComponentProps;

const SetupToolStepsListItemComponent: React.FC<Props> = ({ actions, item }) => {
  const { info, step } = T.setupTool;

  const statusTableCell = (item: SetupToolStep) => {
    const { completed, dataNotCurrent, inProgress } = T.setupTool.info;
    const itemId = item.uniqueIndexForID || item.index;
    let label: string = inProgress;
    switch (item.status) {
      case StatusName.DONE:
        label = completed;
        break;
      case StatusName.NEEDS_REGENERATION:
        label = dataNotCurrent;
        break;
    }
    return (
      <TableCell
        className={`
        c-setup-tool-step-list-table-item__cell
        c-setup-tool-step-list-table-item__cell--status
      `}
      >
        <SetupToolStepStatus
          status={item.status!}
          label={I18n.t(label)}
          dataTestId={`setup-tool-step${itemId}-status`}
          id={`setup_tool_step_${itemId}_status`}
        />
      </TableCell>
    );
  };

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    actions.goTo(item.route);
  };

  const buttonDisabledStatus = item.buttonDisabled || item.disabled;

  return (
    <div
      className={classNames(`c-setup-tool-step-list-table-item`, {
        'c-setup-tool-step-list-table-item--disabled': item.disabled,
      })}
    >
      <Card
        footerContent={
          item.appendix && (
            <div className={'c-setup-tool-step-list-table-item__appendix'}>{item.appendix()}</div>
          )
        }
      >
        <TableCell
          className={`
              c-setup-tool-step-list-table-item__cell
              c-setup-tool-step-list-table-item__cell--info
            `}
        >
          <RoundButton
            label={item.index}
            outline={true}
            direction={ButtonDirection.LEFT}
            size={30}
          />
          <div>
            <h4>{item.name}</h4>
            {item.date && (
              <h5>
                {I18n.t(step.lastModification)} {item.date}
              </h5>
            )}

            <Media query={{ maxWidth: breakpointDown('LG') }}>
              {(isMedium: boolean) => (isMedium ? !!item.status && statusTableCell(item) : null)}
            </Media>
          </div>
        </TableCell>
        <Media query={{ minWidth: breakpointUp('LG') }}>
          {(isMedium: boolean) => (isMedium ? !!item.status && statusTableCell(item) : null)}
        </Media>
        <TableCell
          className={`
              c-setup-tool-step-list-table-item__cell
              c-setup-tool-step-list-table-item__cell--start
            `}
        >
          {item.buttonVisible && (
            <Button
              name={`setup_tool_step_${item.uniqueIndexForID || item.index}_display`}
              type={ButtonType.SECONDARY}
              label={item.buttonLabel || I18n.t(info.start)}
              disabled={buttonDisabledStatus}
              status={buttonDisabledStatus ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              onClick={onButtonClick}
              dataTestId={item.dataTestId}
            />
          )}
        </TableCell>
      </Card>
    </div>
  );
};

export const SetupToolStepsListItem = connect(
  null,
  mapDispatchToProps
)(SetupToolStepsListItemComponent);
