import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import {
  batteryModelNameMap,
  batteryParametersToLabelMapper,
} from '+shared/store/lead/lead.helpers';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';

import { ConfigurationAttribute } from '../LeadFlatConfigurationTile/LeadFlatConfigurationTile.helper';

export const factorizeBatteryDetails =
  (locale: string) =>
  (battery: LeadProductBattery): ConfigurationAttribute[] =>
    [
      // Model
      {
        title: `${I18n.t(T.lead.hardware._salessolution_.configurationTile.details.model)}`,
        value: batteryModelNameMap(battery.modelName),
        type: 'bodySmall',
      },
      // Capacity
      {
        title: `${I18n.t(T.lead.hardware._salessolution_.configurationTile.details.capacity)}`,
        value: batteryParametersToLabelMapper(locale)(battery.parameters),
        type: 'bodySmall',
      },
    ];
