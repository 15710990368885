import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

export const Info: React.FC<Props> = ({ color = '#0B7AC2', className }) => (
  <svg className={className} viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M14 4a9.94 9.94 0 019.21 6.1 9.93 9.93 0 010 7.8 9.95 9.95 0 01-5.32 5.31 9.94 9.94 0 01-7.78 0 9.95 9.95 0 01-5.32-5.32 9.93 9.93 0 010-7.78 9.95 9.95 0 015.32-5.32A9.93 9.93 0 0114 4zm0 1a8.94 8.94 0 00-6.36 2.64A8.95 8.95 0 005.7 17.5 8.97 8.97 0 0014 23a8.94 8.94 0 008.3-5.5 8.93 8.93 0 000-7A8.97 8.97 0 0014 5zm.25 7.13c.28 0 .5.22.5.5v6.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-6.5c0-.28.22-.5.5-.5h.5zm-.24-3.76c.52 0 .93.42.93.95 0 .51-.4.93-.93.93a.94.94 0 110-1.88z"
    />
  </svg>
);
