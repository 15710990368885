import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader, Modal } from '@sonnen/shared-web';

import './LeadOverviewDocumentModal.component.scss';

interface Props {
  isOpeningDocument: boolean;
  documentUrl: string | undefined;
  onDocumentOpen: () => void;
}

export const LeadOverviewDocumentModal: React.FC<Props> = ({
  isOpeningDocument,
  documentUrl,
  onDocumentOpen,
}) => (
  <Modal
    size={'small'}
    isOpen={isOpeningDocument}
    onClose={onDocumentOpen}
    onOutsideClick={onDocumentOpen}
    className={'c-lead-overview-document-modal'}
  >
    <div className={'c-lead-overview-document-modal__inner'}>
      {documentUrl ? (
        <a
          href={documentUrl}
          target={'_blank'}
          onClick={onDocumentOpen}
          className={
            'c-lead-overview-document-modal__content c-lead-overview-document-modal__content--link'
          }
          rel="noreferrer"
        >
          <span className="c-lead-overview-document-modal__icon-and-text">
            <Icon.Contract className={'c-lead-overview-document-modal__icon'} />
            <p className={'c-lead-overview-document-modal__paragraph'}>
              {I18n.t(T.lead.overview._salessolution_.documentDownloadModal.clickToDownload)}
            </p>
          </span>
        </a>
      ) : (
        <span className={'c-lead-overview-document-modal__content'}>
          <Loader />
          <p className={'c-lead-overview-document-modal__paragraph'}>
            {I18n.t(T.lead.overview._salessolution_.documentDownloadModal.generating)}
          </p>
        </span>
      )}
    </div>
  </Modal>
);
