import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '@sonnen/shared-web';

import { LDFlagSet, LDUser } from 'launchdarkly-js-client-sdk';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';

import { Config, setFeatureFlags } from '+config/index';
import { isRestrictedRoute } from '+router/store/router.helpers';
import { getRouterLocationPath } from '+router/store/router.selectors';
import { isAuthenticated } from '+shared/store/auth/auth.selectors';
import { isLanguageLoading } from '+shared/store/layout';
import { useUserIdentity } from '+shared/store/user';

export const LDFlagsContext = React.createContext<LDFlagSet>({});

const anonymousUser: LDUser = {
  key: 'anonymous',
};

const LDConfig = () => ({
  ...Config.LAUNCH_DARKLY,
  user: anonymousUser,
});

const withLaunchDarklyProvider = withLDProvider({
  ...LDConfig(),
});

const launchDarklyStateKeeper: FC<LDProps> = ({ children, ldClient, flags }) => {
  const [isLDInitialized, setIsLDInitialized] = useState<boolean>(false);
  const [isLDInitializedError, setLDInitializedError] = useState<boolean>(false);
  const [isLDUserSet, setIsLDUserSet] = useState<boolean>(false);
  const [isLDUserSetError, setLDUserSetError] = useState<boolean>(false);
  const [localFlags, setLocalFlags] = useState<LDFlagSet>({});
  const {
    userProfileId,
    userProfileCountryCode,
    userProfileCustomerNumber,
    userProfileLanguage,
    userProfileSalesforceUserId,
    userProfileSalesforceContactId,
  } = useUserIdentity();
  const isUserAuthenticated = useSelector(isAuthenticated);
  const isRestrictedContext = isRestrictedRoute(useSelector(getRouterLocationPath));
  const isLanguageReady = !useSelector(isLanguageLoading);
  const ldUserSetOrError = isLDUserSet || isLDUserSetError;
  const ldReady = isLDInitialized && (!isRestrictedContext || ldUserSetOrError);
  const shouldRender = isLanguageReady && (isLDInitializedError || ldReady);

  const getUser = (): LDUser => ({
    key: userProfileId,
    country: userProfileCountryCode,
    custom: {
      'profile id': userProfileId,
      'profile customer number': userProfileCustomerNumber,
      'profile country code': userProfileCountryCode,
      'profile language': userProfileLanguage,
      'profile salesforce user id': userProfileSalesforceUserId,
      'profile salesforce contact id': userProfileSalesforceContactId,
    },
  });

  useEffect(() => {
    if (ldClient === undefined) {
      return;
    }

    ldClient
      .waitForInitialization()
      .then(() => {
        setFeatureFlags(ldClient.allFlags());
        setIsLDInitialized(true);
      })
      .catch(() => {
        console.error('LaunchDarkly initialization failed.');
        setLDInitializedError(true);
      });
  }, [ldClient]);

  useEffect(() => {
    if (ldClient === undefined || !isLDInitialized) {
      return;
    }
    setIsLDUserSet(false);
    setLDUserSetError(false);

    if (!isRestrictedContext || !isUserAuthenticated || !userProfileId) {
      ldClient
        .identify(anonymousUser)
        .then(() => setFeatureFlags(ldClient.allFlags()))
        .catch(() => setLDUserSetError(true));
      return;
    }

    ldClient
      .identify(getUser())
      .then(() => {
        setFeatureFlags(ldClient.allFlags());
        setIsLDUserSet(true);
      })
      .catch(() => setLDUserSetError(true));
  }, [isUserAuthenticated, userProfileId, isLDInitialized]);

  useEffect(() => setLocalFlags(flags || {}), [flags]);

  return (
    <>
      <LDFlagsContext.Provider value={localFlags}>
        {shouldRender ? children : <Loader />}
      </LDFlagsContext.Provider>
    </>
  );
};

export const LaunchDarklyProvider = withLaunchDarklyProvider(
  withLDConsumer({ clientOnly: false })(launchDarklyStateKeeper)
);
