import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';

import { Formik, FormikProps } from 'formik';
import { defaultTo } from 'lodash/fp';

import { mapActions } from '+app/utils';
import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { HeadlineLarge } from '+shared/basicComponents/Typography/Headlines';
import { Form, FormInputRadioGroup } from '+shared/components';
import Banner from '+shared/components/Banner';
import { Card } from '+shared/components/Card/Card';
import { StoreState } from '+shared/store/store.interface';

import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { ImpactAnalysisActions } from '../../store/+impactAnalysis.actions';
import { CostsForm, CostsFormVersion } from '../../store/+impactAnalysis.interface';
import { getCostsForm } from '../../store/+impactAnalysis.selectors';
import { ImpactAnalysisCostsFormSchema } from '../../store/schemas';
import { defaultValues, formFields } from './LeadImpactAnalysisCosts.helper';
import ImpactAnalysisCostsBasic from './LeadImpactAnalysisCostsBasic';
import ImpactAnalysisCostsDetailed from './LeadImpactAnalysisCostsDetailed';

import './LeadImpactAnalysisCosts.component.scss';

const mapDispatchToProps = mapActions({
  setCosts: ImpactAnalysisActions.setCostsForm,
});

const mapStateToProps = (state: StoreState) => ({
  costs: getCostsForm(state),
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export interface ImpactAnalysisCostsForm {
  form: FormikProps<CostsForm>;
  currency: string;
}

const LeadImpactAnalysisCostsComponent: React.FC<Props> = ({ actions, costs, dispatch }) => {
  const wizard = React.useContext(WizardContext);

  const { taxationInformationHeadline, taxationInformationText, title, versionQuestion } =
    T.lead.impactAnalysis._salessolution_.costs;
  const { basic, detailed } = T.lead.impactAnalysis._salessolution_.costs.version;

  const onSubmit = (values: CostsForm) => {
    actions.setCosts(values);
    if (wizard.next) {
      wizard.next();
    }
  };

  React.useEffect(() => {
    dispatch(ImpactAnalysisActions.clearImpactAnalysisRecommendation());
  }, []);

  // @TODO: REPLACE WITH DYNAMIC VALUE
  const currency = '€';

  const renderForm = (form: FormikProps<CostsForm>) => {
    switch (form.values.version) {
      case CostsFormVersion.BASIC:
        return <ImpactAnalysisCostsBasic form={form} currency={currency} />;
      case CostsFormVersion.DETAILED:
        return <ImpactAnalysisCostsDetailed form={form} currency={currency} />;
      default:
        return null;
    }
  };

  const initialValues = defaultTo(defaultValues)(costs);

  return (
    <Formik<CostsForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ImpactAnalysisCostsFormSchema}
    >
      {(form: FormikProps<CostsForm>) => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <div className="c-impact-analysis-costs-alert">
              <Banner
                headlineMessage={I18n.t(taxationInformationHeadline)}
                textMessageAsHtml={I18n.t(taxationInformationText)}
              />
            </div>

            <HeadlineLarge
              className="c-impact-analysis-costs-headline-large"
              text={I18n.t(title)}
            />

            <BodyMedium text={I18n.t(versionQuestion)} />

            <div className={'c-impact-analysis-costs-question'}>
              <FormInputRadioGroup
                name={formFields.VERSION}
                form={form}
                collection={[
                  {
                    label: <BodyMedium text={I18n.t(basic)} />,
                    value: CostsFormVersion.BASIC,
                    dataTestId: 'basic-costs-radio-button',
                  },
                  {
                    label: <BodyMedium text={I18n.t(detailed)} />,
                    value: CostsFormVersion.DETAILED,
                    dataTestId: 'detailed-costs-radio-button',
                  },
                ]}
              />
            </div>

            {renderForm(form)}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export const LeadImpactAnalysisCosts = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadImpactAnalysisCostsComponent);
