import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import {
  LeadRoofDetails,
  LeadRoofMaterial,
  LeadRoofOrientation,
  LeadRoofType,
} from '+shared/store/lead/types/leadRoof.interface';
import { formatSquareMeters } from '+utils/format.util.old';

const mapRoofOrientation = (orientation: LeadRoofOrientation): string | undefined => {
  switch (orientation) {
    case LeadRoofOrientation.EAST:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.east);
    case LeadRoofOrientation.NORTH:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.north);
    case LeadRoofOrientation.NORTH_EAST:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.north_east);
    case LeadRoofOrientation.NORTH_WEST:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.north_west);
    case LeadRoofOrientation.SOUTH:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.south);
    case LeadRoofOrientation.SOUTH_EAST:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.south_east);
    case LeadRoofOrientation.SOUTH_WEST:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.south_west);
    case LeadRoofOrientation.WEST:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.orientation.west);
    default:
      return undefined;
  }
};

const mapRoofType = (type: LeadRoofType): string | undefined => {
  switch (type) {
    case LeadRoofType.FLAT:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.type.flat);
    case LeadRoofType.GABLE:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.type.gable);
    case LeadRoofType.HIP:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.type.hip);
    case LeadRoofType.OTHER:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.type.other);
    case LeadRoofType.PITCH:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.type.pitch);
    case LeadRoofType.TENTED:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.type.tented);
    default:
      return undefined;
  }
};

const mapRoofMaterial = (material: LeadRoofMaterial): string | undefined => {
  switch (material) {
    case LeadRoofMaterial.ASBESTOS:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.asbestos);
    case LeadRoofMaterial.IRON_SHEET:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.iron_sheet);
    case LeadRoofMaterial.OTHER:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.other);
    case LeadRoofMaterial.SLATE:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.slate);
    case LeadRoofMaterial.TAR_PAPER:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.tar_paper);
    case LeadRoofMaterial.TILES:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.tiles);
    case LeadRoofMaterial.WOOD:
      return I18n.t(T.lead.boc._salessolution_.roofDetails.material.wood);
    default:
      return undefined;
  }
};

export const factorizeLeadHouseDetails = (roofDetails: LeadRoofDetails | null) => [
  {
    label: I18n.t(T.lead.boc._salessolution_.form.houseDetails.roofOrientation),
    value: roofDetails ? mapRoofOrientation(roofDetails.orientation) : undefined,
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.houseDetails.roofType),
    value: roofDetails && mapRoofType(roofDetails.type),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.houseDetails.roofSize),
    value: roofDetails && roofDetails.size ? formatSquareMeters(roofDetails.size.value) : undefined,
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.houseDetails.roofMaterial),
    value: roofDetails && mapRoofMaterial(roofDetails.material),
  },
];
