import React, { useEffect } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { MediaQuery } from '@sonnen/shared-web/index';

import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';

import { NewsContainer } from '+app/+newsMain/containers/NewsContainer/NewsContainer.component';
import { sortList } from '+app/+newsMain/store/newsMain.helpers';
import { PATHS } from '+app/router';
import { NewsPlacementType } from '+app/shared/store/newsMain/types/newsMain.interface';
import { mapActions } from '+app/utils';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { withFirebase } from '+shared/components/FirebaseContext/Firebase.context';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { fetchReadNews } from '+shared/store/firebase/firebase.helpers';
import {
  getFirebaseNewsList,
  getFirebaseReadNewsListFetched,
} from '+shared/store/firebase/firebase.selectors';
import { NewsMainActions } from '+shared/store/newsMain/newsMain.actions';
import { getActiveNewsId } from '+shared/store/newsMain/newsMain.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';

import DashboardGreeting from './DashboardGreeting';

import './Dashboard.scss';

const mapStateToProps = (state: StoreState) => ({
  userProfile: getUserProfile(state),
  newsList: getFirebaseNewsList(state),
  isFirebaseReadNewsListFetched: getFirebaseReadNewsListFetched(state),
  activeNewsId: getActiveNewsId(state),
});

const mapDispatchToProps = mapActions({
  setReadNewsList: FirebaseActions.setReadNewsList,
  goToNewsInbox: () => push(PATHS.NEWS_INBOX()),
  markNewsAsRead: FirebaseActions.markNewsAsRead,
  setActiveNewsId: NewsMainActions.setActiveNewsId,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FirebaseProps;

const DashboardComponent: React.FC<Props> = ({
  actions,
  userProfile,
  newsList,
  firebase,
  isFirebaseReadNewsListFetched,
  activeNewsId,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.DASHBOARD, PageName.Dashboard.DASHBOARD);

  const { setActiveNewsId, setReadNewsList, markNewsAsRead, goToNewsInbox } = actions;

  const sortedNewsList = sortList(newsList);
  const listIsEmpty = isEmpty(sortedNewsList);

  useEffect(() => {
    if (userProfile) {
      fetchReadNews(userProfile.id, firebase, setReadNewsList);
    }
  }, [userProfile]);

  useEffect(() => {
    setActiveNewsId(listIsEmpty ? '' : sortedNewsList[0].id);
  }, [sortedNewsList]);

  if (!userProfile || !isFirebaseReadNewsListFetched) return null;

  return (
    <Container>
      <DashboardGreeting
        userName={`${userProfile.firstName} ${userProfile.lastName}`}
        city={userProfile.city}
        countryCode={userProfile.countryCode}
      />

      <div className="news-container" data-testid="dashboard-news-container">
        <NewsContainer maxNumNews={3} newsPlacement={NewsPlacementType.DASHBOARD} />

        <Media query={MediaQuery.UP_LG}>
          {(isDesktop: boolean) => (
            <Button
              className="news-item__read-all-news"
              label={I18n.t(T.news.channel.readAllNews)}
              size={ButtonSize.SMALL}
              type={ButtonType.SECONDARY}
              onClick={() => {
                if (isDesktop) markNewsAsRead(activeNewsId, userProfile.id);
                goToNewsInbox();
              }}
              disabled={listIsEmpty}
              status={listIsEmpty ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              dataTestId="dashboard-news-btn"
            />
          )}
        </Media>
      </div>
    </Container>
  );
};

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(withFirebase(DashboardComponent));

export default Dashboard;
