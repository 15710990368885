import { isEqual } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { dataGuard, mapToState, ofType, processQuery } from '+app/utils';
import {
  getVppDocumentationFields,
  getVppSubmissionStatus,
  VppDocumentationActions,
  VppRegisterStatus,
} from '+setupTool/+vppDocumentation/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep, SubmissionStepCamelCase } from '+setupTool/store/+setupTool.dictionary';
import { getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { DsoRegisterRepository } from '+shared/store/setupTool';
import { StoreState } from '+shared/store/store.interface';

import { DsoCommissioningActions, getDsoCommissioningFields } from './';
import {
  DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY,
  GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY,
} from './+dsoCommissioning.state';

type Action$ = ActionsObservable<DsoCommissioningActions>;
type State$ = StateObservable<StoreState>;

const saveSubmissionAndGenerateDocuments$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoCommissioningActions.saveSubmissionAndGenerateDocuments),
    map(() =>
      SetupToolActions.saveSubmissionWithCallback(DsoCommissioningActions.generateDocuments(), {
        step: SubmissionStep.DSO_COMMISSIONING_DATA,
        query: DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY,
      })
    )
  );

const generateDocuments$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(DsoCommissioningActions.generateDocuments),
    mapToState(state$),
    map(getSubmissionId),
    mergeMap((submissionId) =>
      of({}).pipe(
        processQuery(
          GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY,
          () =>
            DsoRegisterRepository.dsoGenerateDocuments(submissionId!, [
              SubmissionStep.DSO_COMMISSIONING_DOCUMENTS,
            ]),
          {
            onSuccess: (res) =>
              merge(
                dataGuard(DsoCommissioningActions.setDsoCommissioningModificationDate)(
                  res!.data.stepsUpdatedAt[SubmissionStepCamelCase.DSO_COMMISSIONING_DATA]
                ),
                dataGuard(DsoCommissioningActions.setDsoCommissioningGeneratedDocuments)(
                  res!.data.documents.filter(
                    (document) => document.stepType === SubmissionStep.DSO_COMMISSIONING_DOCUMENTS
                  )
                ),
                dataGuard(DsoCommissioningActions.setDsoCommissioningDocumentsGenerationDate)(
                  res!.data.stepsUpdatedAt[SubmissionStepCamelCase.DSO_COMMISSIONING_DOCUMENTS]
                )
              ),
          }
        )
      )
    )
  );

const setGeneratedDocuments$ = (action$: Action$) =>
  action$.pipe(
    ofType(SetupToolActions.setGeneratedDocuments),
    map((action) => action.data),
    map((documents) =>
      documents.filter(
        (document) => document.stepType === SubmissionStep.DSO_COMMISSIONING_DOCUMENTS
      )
    ),
    mergeMap(dataGuard(DsoCommissioningActions.setDsoCommissioningGeneratedDocuments))
  );

const setLatestModification$ = (action$: Action$) =>
  action$.pipe(
    ofType(SetupToolActions.setLatestModificationDate),
    map((action) => action.data[SubmissionStepCamelCase.DSO_COMMISSIONING_DATA]),
    mergeMap(dataGuard(DsoCommissioningActions.setDsoCommissioningModificationDate))
  );

const setLatestDocumentsGeneration$ = (action$: Action$) =>
  action$.pipe(
    ofType(SetupToolActions.setLatestModificationDate),
    map((action) => action.data[SubmissionStepCamelCase.DSO_COMMISSIONING_DOCUMENTS]),
    mergeMap(dataGuard(DsoCommissioningActions.setDsoCommissioningDocumentsGenerationDate))
  );

const synchronizeDsoCommissioningFieldsWithVpp$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(VppDocumentationActions.setFields),
    mapToState(state$),
    map((state) => {
      if (getVppSubmissionStatus(state) !== VppRegisterStatus.FINISH) {
        const dsoCommissioningFields = getDsoCommissioningFields(state);
        const vppFields = getVppDocumentationFields(state);

        const newFields = {
          ...dsoCommissioningFields,
          battery_commissioning_date: vppFields.battery_commissioning_date,
          pv_commissioning_date: vppFields.pv_commissioning_date,
        };

        if (!isEqual(dsoCommissioningFields, newFields)) {
          return DsoCommissioningActions.setFields(newFields);
        }
      }
      return { type: '' };
    })
  );

export const epics = combineEpics(
  generateDocuments$,
  saveSubmissionAndGenerateDocuments$,
  setGeneratedDocuments$,
  setLatestModification$,
  setLatestDocumentsGeneration$,
  synchronizeDsoCommissioningFieldsWithVpp$
);
