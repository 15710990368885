import {
  BatteryStatusesKey,
  CellDataSeriesKey,
  EnergyFlowSeriesKey,
  useFeature,
} from '@sonnen/shared-web';

import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { GET_BATTERY_STATUSES_QUERY } from '+app/+customer/+battery/store';
import {
  getBattery,
  getBatteryTimezone,
  isEatonBattery,
} from '+app/+customer/+battery/store/+battery.selectors';
import { CustomerActions } from '+app/+customer/store';
import { DAYS_IN_WEEK, DAYS_IN_YEAR, HOURS_IN_DAY } from '+app/App.constants';
import { hasSiteReadingsOption } from '+app/shared/store/site/site.selectors';
import {
  GET_SITE_CELL_DATA_QUERY,
  GET_SITE_CHARGE_LIMITS_QUERY,
  GET_SITE_QUERY,
} from '+app/shared/store/site/site.state';
import { FeatureName } from '+config/featureFlags';
import { isLocationChangeFromPath } from '+router/store/router.selectors';
import { AuthActions } from '+shared/store/auth';
import { BatteryActions } from '+shared/store/battery';
import { SiteActions } from '+shared/store/site';
import {
  getCellDataPropertyIndex,
  getMeasurementsPropertyIndex,
  getResolutionForTimeRange,
  normalizeBatteryStatusesToTime,
  normalizeCellDataToTime,
  normalizeChargeLimitsToTime,
  normalizeMeasurementsToTime,
} from '+shared/store/site/site.helpers';
import { getSiteLiveState, siteHasBattery } from '+shared/store/site/site.selectors';
import {
  CellDataName,
  MeasurementName,
  SiteLiveState,
  SiteMeasurements,
} from '+shared/store/site/types';
import { StoreState } from '+shared/store/store.interface';
import { dateUtil } from '+utils/date.util';
import { dataGuard, mapPathToParams, mapToState, ofType, processQuery } from '+utils/index';

import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { AnalysisActions, isSetStatisticsDateAction } from './+analysis.actions';
import { AnalysisRepository } from './+analysis.repository';
import {
  areDataSeriesEmpty,
  getAreaChartConsumption,
  getAreaChartProduction,
  getCurrentSelectedDates,
  getForecastConsumptionFull,
  getForecastConsumptionSeries,
  getForecastProductionFull,
  getForecastProductionSeries,
  getInitialDataSeries,
  getInitialSelectedDates,
  getInitialSiteChargeLimitSeries,
  getIsResolutionChanged,
  getStatisticsSelectedDate,
  hasSiteMeasurements,
} from './+analysis.selector';
import {
  GET_FORECAST_CONSUMPTION_QUERY,
  GET_FORECAST_PRODUCTION_QUERY,
  GET_SITE_MEASUREMENTS_QUERY,
  INITIAL_DATA_SERIES_DATA,
} from './+analysis.state';
import {
  ensureBatteryTimeZone,
  getForecastStartDate,
  getLastDataPoint,
  getLiveSeriesPoint,
  transformForecastData,
  updateForecastSeries,
} from './helpers/+analysis.helpers';
import {
  createDefaultStatisticsFilters,
  transformStatisticsIntoSeries,
  transformToSiteStatisticsFilters,
} from './helpers/+analysisStatistics.helpers';
import { FORECAST_VALUE_CONSUMPTION, FORECAST_VALUE_PRODUCTION } from './types/forecast.interface';

type Action$ = ActionsObservable<RouterActions | AnalysisActions | SiteActions | CustomerActions>;
type State$ = StateObservable<StoreState>;

// TODO: cover error handling in case of errored requests - vpp and charge limits

const getChartDataOnPageLoad$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    mapToState(state$),
    map((state) => getInitialSelectedDates(state)),
    map((initialSelectedDates) => AnalysisActions.setInitialChartDates(initialSelectedDates))
  );

const getSiteMeasurements$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AnalysisActions.setInitialChartDates, AnalysisActions.setCurrentChartDates),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          dates: [startDate, endDate],
        },
        state,
      ]) =>
        of(state).pipe(
          mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
          mergeMap(([_, siteId]) =>
            of(siteId).pipe(
              // INFO: the start date has to be in the past or today,
              // the end date doesn't matter in this case because we might take a time range
              // from yesterday until tomorrow and it still should fetch the data
              filter(() => dateUtil.isTodayOrBefore(startDate)),
              // INFO: do not fetch the data when a selected time range is longer than 1 year
              filter(() => dateUtil.getDifference(startDate, endDate, 'days') <= DAYS_IN_YEAR),
              map(() => getBattery(state)),
              filter(ensureBatteryTimeZone),
              map((battery) =>
                SiteActions.getSiteMeasurements({
                  queryKey: GET_SITE_MEASUREMENTS_QUERY,
                  siteId,
                  start: dateUtil.toTimezone(startDate, battery.timeZone),
                  end: dateUtil.toTimezone(endDate, battery.timeZone),
                })
              )
            )
          )
        )
    )
  );

const getBatteryStatuses$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AnalysisActions.setInitialChartDates, AnalysisActions.setCurrentChartDates),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          dates: [startDate, endDate],
        },
        state,
      ]) =>
        of(state).pipe(
          mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
          mergeMap(([_, siteId]) =>
            of(siteId).pipe(
              filter(() => dateUtil.isTodayOrBefore(startDate)),
              filter(() => dateUtil.getDifference(startDate, endDate, 'hours') <= HOURS_IN_DAY),
              map(() => getBattery(state)),
              filter(ensureBatteryTimeZone),
              map((battery) =>
                BatteryActions.getBatteryStatuses({
                  batteryId: battery.id,
                  queryKey: GET_BATTERY_STATUSES_QUERY,
                  start: dateUtil.toTimezone(startDate, battery.timeZone),
                  end: dateUtil.toTimezone(endDate, battery.timeZone),
                })
              )
            )
          )
        )
    )
  );

const getSiteChargeLimits$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AnalysisActions.setInitialChartDates, AnalysisActions.setCurrentChartDates),
    filter(() => useFeature(FeatureName.BATTERY_CHARGE_LIMIT).isEnabled),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          dates: [startDate, endDate],
        },
        state,
      ]) =>
        of(state).pipe(
          mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
          mergeMap(([_, siteId]) =>
            of(siteId).pipe(
              filter(() => dateUtil.getDifference(startDate, endDate, 'days') <= DAYS_IN_YEAR),
              map(() => getBattery(state)),
              filter(ensureBatteryTimeZone),
              map((battery) =>
                SiteActions.getSiteChargeLimits({
                  queryKey: GET_SITE_CHARGE_LIMITS_QUERY,
                  siteId,
                  start: dateUtil.toTimezone(startDate, battery.timeZone),
                  end: dateUtil.toTimezone(endDate, battery.timeZone),
                })
              )
            )
          )
        )
    )
  );

const getSiteCellData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AnalysisActions.setInitialChartDates, AnalysisActions.setCurrentChartDates),
    withLatestFrom(state$),
    filter(() => useFeature(FeatureName.BATTERY_CELL_DATA).isEnabled),
    filter(([, state]) => !isEatonBattery(state)),
    mergeMap(
      ([
        {
          dates: [startDate, endDate],
        },
        state,
      ]) =>
        of(state).pipe(
          mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
          mergeMap(([_, siteId]) =>
            of(siteId).pipe(
              filter(() => dateUtil.getDifference(startDate, endDate, 'days') < DAYS_IN_WEEK),
              map(() => getBattery(state)),
              filter(ensureBatteryTimeZone),
              map((battery) =>
                SiteActions.getSiteCellData({
                  queryKey: GET_SITE_CELL_DATA_QUERY,
                  siteId,
                  start: dateUtil.toTimezone(startDate, battery.timeZone),
                  end: dateUtil.toTimezone(endDate, battery.timeZone),
                })
              )
            )
          )
        )
    )
  );

const getAnalysisStatistics$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer, AnalysisActions.setStatisticsDate),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
        mergeMap(([_, siteId]) =>
          of(siteId).pipe(
            map(() => getBattery(state)),
            filter(ensureBatteryTimeZone),
            map((battery) =>
              SiteActions.getSiteStatistics(
                siteId,
                isSetStatisticsDateAction(action)
                  ? transformToSiteStatisticsFilters(
                      action.statisticsSelectedDate,
                      battery.timeZone
                    )
                  : createDefaultStatisticsFilters(battery.timeZone)
              )
            )
          )
        )
      )
    )
  );

const setAnalysisStatistics$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSiteStatistics),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(state).pipe(
        map(() =>
          transformStatisticsIntoSeries(action.statistics, getStatisticsSelectedDate(state))
        ),
        mergeMap(({ pieChart, barChart }) =>
          concat(
            of(AnalysisActions.setPieChartSeries(pieChart)),
            of(AnalysisActions.setBarChartSeries(barChart))
          )
        )
      )
    )
  );

const getSiteDetails$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    withLatestFrom(state$),
    mergeMap(([_, state]) =>
      of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
        mergeMap(([_, siteId]) =>
          of(siteId).pipe(
            map(() =>
              SiteActions.getSite({
                queryKey: GET_SITE_QUERY,
                siteId,
              })
            )
          )
        )
      )
    )
  );

const normalizeMeasurements$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSiteMeasurements),
    map((action) => action.siteMeasurements),
    filter(Boolean),
    map((measurements) => measurements as NonNullable<SiteMeasurements>),
    withLatestFrom(state$),
    map(([measurements, state]) => ({
      measurements,
      isEaton: isEatonBattery(state),
    })),
    map(({ measurements, isEaton }) => ({
      [EnergyFlowSeriesKey.PRODUCTION_POWER]: normalizeMeasurementsToTime(
        measurements.values,
        dateUtil.of(measurements.startAt),
        dateUtil.of(measurements.endAt),
        isEaton,
        getMeasurementsPropertyIndex(MeasurementName.PRODUCTION, measurements.fields)
      ),
      [EnergyFlowSeriesKey.CONSUMPTION_POWER]: normalizeMeasurementsToTime(
        measurements.values,
        dateUtil.of(measurements.startAt),
        dateUtil.of(measurements.endAt),
        isEaton,
        getMeasurementsPropertyIndex(MeasurementName.CONSUMPTION, measurements.fields)
      ),
      [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: normalizeMeasurementsToTime(
        measurements.values,
        dateUtil.of(measurements.startAt),
        dateUtil.of(measurements.endAt),
        isEaton,
        getMeasurementsPropertyIndex(MeasurementName.DIRECT_CONSUMPTION, measurements.fields)
      ),
      [EnergyFlowSeriesKey.BATTERY_USOC]: normalizeMeasurementsToTime(
        measurements.values,
        dateUtil.of(measurements.startAt),
        dateUtil.of(measurements.endAt),
        isEaton,
        getMeasurementsPropertyIndex(MeasurementName.BATTERY_STATE_OF_CHARGE, measurements.fields)
      ),
      [EnergyFlowSeriesKey.BATTERY_CHARGING]: normalizeMeasurementsToTime(
        measurements.values,
        dateUtil.of(measurements.startAt),
        dateUtil.of(measurements.endAt),
        isEaton,
        getMeasurementsPropertyIndex(MeasurementName.BATTERY_CHARGE, measurements.fields)
      ),
      [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: normalizeMeasurementsToTime(
        measurements.values,
        dateUtil.of(measurements.startAt),
        dateUtil.of(measurements.endAt),
        isEaton,
        getMeasurementsPropertyIndex(MeasurementName.BATTERY_DISCHARGE, measurements.fields)
      ),
      startAt: measurements.startAt,
      endAt: measurements.endAt,
    })),
    withLatestFrom(state$),
    mergeMap(([{ startAt, endAt, ...dataSeries }, state]) =>
      concat(
        of(
          getIsResolutionChanged(state)
            ? AnalysisActions.setCurrentDataSeries(
                dataSeries,
                getResolutionForTimeRange(new Date(startAt), new Date(endAt))
              )
            : AnalysisActions.setInitialDataSeries(
                dataSeries,
                getResolutionForTimeRange(new Date(startAt), new Date(endAt))
              )
        ),
        of(AnalysisActions.triggerLiveState())
      )
    )
  );

// TODO: add selectedDates type guard
const normalizeBatteryStatuses$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(BatteryActions.setBatteryStatuses),
    map(({ batteryStatuses }) =>
      batteryStatuses.filter((batteryStatus) =>
        batteryStatus.value.split(',').some((status) => status === 'vpp')
      )
    ),
    withLatestFrom(state$),
    map(([batteryStatuses, state]) => ({
      batteryStatuses,
      selectedDates: getIsResolutionChanged(state)
        ? getCurrentSelectedDates(state)
        : getInitialSelectedDates(state),
      isResolutionChanged: getIsResolutionChanged(state),
    })),
    map(({ batteryStatuses, selectedDates, isResolutionChanged }) => ({
      dataSeries: {
        [BatteryStatusesKey.VPP_ACTIVITY]: normalizeBatteryStatusesToTime(
          batteryStatuses,
          selectedDates![0]
        ),
      },
      isResolutionChanged,
    })),
    map(({ dataSeries, isResolutionChanged }) =>
      isResolutionChanged
        ? AnalysisActions.setCurrentDataSeries(dataSeries)
        : AnalysisActions.setInitialDataSeries(dataSeries)
    )
  );

const normalizeCellCareStatus$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(BatteryActions.setBatteryStatuses),
    map(({ batteryStatuses }) =>
      batteryStatuses.filter((batteryStatus) =>
        batteryStatus.value.split(',').some((status) => status === 'maintenance')
      )
    ),
    withLatestFrom(state$),
    map(([batteryStatuses, state]) => ({
      batteryStatuses,
      selectedDates: getIsResolutionChanged(state)
        ? getCurrentSelectedDates(state)
        : getInitialSelectedDates(state),
      isResolutionChanged: getIsResolutionChanged(state),
      batteryTimezone: getBatteryTimezone(state),
    })),
    map(({ batteryStatuses, selectedDates, isResolutionChanged, batteryTimezone }) => ({
      dataSeries: {
        [BatteryStatusesKey.CELL_CARE]: normalizeBatteryStatusesToTime(
          batteryStatuses,
          dateUtil.toTimezone(selectedDates![0], batteryTimezone!)
        ),
      },
      isResolutionChanged,
    })),
    map(({ dataSeries, isResolutionChanged }) =>
      isResolutionChanged
        ? AnalysisActions.setCurrentDataSeries(dataSeries)
        : AnalysisActions.setInitialDataSeries(dataSeries)
    )
  );

// TODO: add selectedDates type guard
const normalizeSiteChargeLimits$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSiteChargeLimits),
    withLatestFrom(state$),
    map(([{ siteChargeLimits }, state]) => ({
      siteChargeLimits,
      selectedDates: getIsResolutionChanged(state)
        ? getCurrentSelectedDates(state)
        : getInitialSelectedDates(state),
      isResolutionChanged: getIsResolutionChanged(state),
    })),
    map(({ siteChargeLimits, selectedDates, isResolutionChanged }) => ({
      dataSeries: {
        [BatteryStatusesKey.CHARGE_LIMIT]: normalizeChargeLimitsToTime(
          siteChargeLimits,
          selectedDates![0]
        ),
      },
      isResolutionChanged,
    })),
    map(({ dataSeries, isResolutionChanged }) =>
      isResolutionChanged
        ? AnalysisActions.setCurrentDataSeries(dataSeries)
        : AnalysisActions.setInitialDataSeries(dataSeries)
    )
  );

const updateMeasurements$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSiteLiveState),
    map((action) => action.liveState as SiteLiveState),
    withLatestFrom(state$),
    map(([liveState, state]) => ({ liveState, dataSeries: getInitialDataSeries(state).data })),
    filter(({ liveState }) => !!liveState),
    map(({ liveState, dataSeries }) => ({
      [EnergyFlowSeriesKey.PRODUCTION_POWER]: [
        ...dataSeries[EnergyFlowSeriesKey.PRODUCTION_POWER],
        getLiveSeriesPoint(liveState.timestamp, liveState.productionPower),
      ],
      [EnergyFlowSeriesKey.CONSUMPTION_POWER]: [
        ...dataSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER],
        getLiveSeriesPoint(liveState.timestamp, liveState.consumptionPower),
      ],
      [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: [
        ...dataSeries[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
        getLiveSeriesPoint(
          liveState.timestamp,
          Math.min(liveState.productionPower, liveState.consumptionPower)
        ),
      ],
      [EnergyFlowSeriesKey.BATTERY_USOC]: [
        ...dataSeries[EnergyFlowSeriesKey.BATTERY_USOC],
        getLiveSeriesPoint(liveState.timestamp, liveState.batteryUsoc),
      ],
      [EnergyFlowSeriesKey.BATTERY_CHARGING]: [
        ...dataSeries[EnergyFlowSeriesKey.BATTERY_CHARGING],
        getLiveSeriesPoint(liveState.timestamp, liveState.batteryCharging),
      ],
      [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: [
        ...dataSeries[EnergyFlowSeriesKey.BATTERY_DISCHARGING],
        getLiveSeriesPoint(liveState.timestamp, liveState.batteryDischarging),
      ],
      [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: updateForecastSeries({
        forecastSeries: dataSeries[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER] || [],
        liveDataTimestamp: liveState.timestamp,
        liveDataPower: liveState.consumptionPower,
      }),
      [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: updateForecastSeries({
        forecastSeries: dataSeries[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER] || [],
        liveDataTimestamp: liveState.timestamp,
        liveDataPower: liveState.productionPower,
      }),
    })),
    map((dataSeries) => AnalysisActions.setInitialDataSeries(dataSeries, undefined))
  );

const normalizeSiteCellData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSiteCellData),
    withLatestFrom(state$),
    map(([{ siteCellData }, state]) => ({
      siteCellData,
      isResolutionChanged: getIsResolutionChanged(state),
    })),
    map(({ siteCellData, isResolutionChanged }) => ({
      dataSeries: {
        [CellDataSeriesKey.TEMPERATURE]: normalizeCellDataToTime(
          siteCellData.values,
          dateUtil.of(siteCellData.startAt),
          dateUtil.of(siteCellData.endAt),
          getCellDataPropertyIndex(CellDataName.MIN_CELL_TEMPERATURE, siteCellData.fields),
          getCellDataPropertyIndex(CellDataName.MAX_CELL_TEMPERATURE, siteCellData.fields)
        ),
        [CellDataSeriesKey.VOLTAGE]: normalizeCellDataToTime(
          siteCellData.values,
          dateUtil.of(siteCellData.startAt),
          dateUtil.of(siteCellData.endAt),
          getCellDataPropertyIndex(CellDataName.MIN_CELL_VOLTAGE, siteCellData.fields),
          getCellDataPropertyIndex(CellDataName.MAX_CELL_VOLTAGE, siteCellData.fields)
        ),
      },
      isResolutionChanged,
    })),
    map(({ dataSeries, isResolutionChanged }) =>
      isResolutionChanged
        ? AnalysisActions.setCurrentDataSeries(dataSeries)
        : AnalysisActions.setInitialDataSeries(dataSeries)
    )
  );

const startPolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SiteActions.setSite, AnalysisActions.triggerLiveState),
    mapToState(state$),
    mergeMap((state) =>
      of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
        map(([_, siteId]) => ({
          siteId,
          hasReadings: hasSiteReadingsOption(state),
          isSelectedDateToday:
            dateUtil.isToday(getInitialSelectedDates(state)[0]) &&
            dateUtil.isToday(getInitialSelectedDates(state)[1]),
          isEaton: isEatonBattery(state),
          hasBattery: siteHasBattery(state),
          hasMeasurements: hasSiteMeasurements(state),
          areDataSeriesEmpty: areDataSeriesEmpty(state),
        })),
        map(
          ({
            siteId,
            hasReadings,
            isSelectedDateToday,
            isEaton,
            hasBattery,
            hasMeasurements,
            areDataSeriesEmpty,
          }) => ({
            siteId,
            canStartPolling:
              hasReadings &&
              isSelectedDateToday &&
              hasBattery &&
              hasMeasurements &&
              !areDataSeriesEmpty &&
              !isEaton,
          })
        )
      )
    ),
    mergeMap(({ siteId, canStartPolling }) =>
      canStartPolling ? of(SiteActions.startPolling(siteId)) : of(SiteActions.stopPolling())
    )
  );

const stopPolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange, AnalysisActions.setInitialChartDates),
    mapToState(state$),
    map(isLocationChangeFromPath(ROUTES.CUSTOMER_ANALYSIS)),
    filter((isMatch) => isMatch),
    map(() => SiteActions.stopPolling())
  );

const getForecastProductionFull$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AuthActions.saveReverseChannelToken),
    mergeMap(({ reverseChannelToken }) =>
      of(reverseChannelToken).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
            map(() => getBattery(state)),
            // NOTE: block forecast if the battery time zone is different than user's local one
            filter((battery) =>
              dateUtil.isTimezoneUsersLocale(battery?.timeZone || 'Europe/Berlin')
            ),
            processQuery(
              GET_FORECAST_PRODUCTION_QUERY,
              () => AnalysisRepository.getSiteForecastProduction({ id: reverseChannelToken.token }),
              {
                onSuccess: (res) =>
                  dataGuard(AnalysisActions.setForecastProductionFull)(res && res.data),
                onFailure: (_) => of(AnalysisActions.setForecastProductionFull([])),
              }
            )
          )
        )
      )
    )
  );

const getForecastConsumptionFull$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AuthActions.saveReverseChannelToken),
    mergeMap(({ reverseChannelToken }) =>
      of(reverseChannelToken).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
            map(() => getBattery(state)),
            // NOTE: block forecast if the battery time zone is different than user's local one
            filter((battery) =>
              dateUtil.isTimezoneUsersLocale(battery?.timeZone || 'Europe/Berlin')
            ),
            processQuery(
              GET_FORECAST_CONSUMPTION_QUERY,
              () =>
                AnalysisRepository.getSiteForecastConsumption({ id: reverseChannelToken.token }),
              {
                onSuccess: (res) =>
                  dataGuard(AnalysisActions.setForecastConsumptionFull)(res && res.data),
                onFailure: (_) => of(AnalysisActions.setForecastConsumptionFull([])),
              }
            )
          )
        )
      )
    )
  );

const setForecastProductionSeries$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AnalysisActions.setForecastProductionFull, AnalysisActions.setInitialChartDates),
    mergeMap(() =>
      of({}).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            map(() => ({
              forecastProduction: getForecastProductionFull(state),
              selectedDates: getInitialSelectedDates(state),
              liveState: getSiteLiveState(state),
              isEaton: isEatonBattery(state),
              lastDataPoint: getLastDataPoint(getAreaChartProduction(state)),
              lastLivePoint: getForecastStartDate(getSiteLiveState(state)),
            }))
          )
        )
      )
    ),
    mergeMap(
      ({
        forecastProduction,
        selectedDates: [startDate, endDate],
        liveState,
        isEaton,
        lastDataPoint,
        lastLivePoint,
      }) =>
        of(
          AnalysisActions.setInitialDataSeries({
            [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: transformForecastData({
              startDate: dateUtil.of(startDate),
              endDate: dateUtil.of(endDate),
              lastDataPointTimestamp: !isEaton && liveState ? lastLivePoint : lastDataPoint.x,
              lastDataPointPower:
                !isEaton && liveState ? liveState.consumptionPower : lastDataPoint.y!,
              forecasts: forecastProduction || [],
              forecastDataKey: FORECAST_VALUE_PRODUCTION,
            }),
          })
        )
    )
  );

const setForecastConsumptionSeries$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(AnalysisActions.setForecastConsumptionFull, AnalysisActions.setInitialChartDates),
    mergeMap(() =>
      of({}).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            map(() => ({
              forecastConsumption: getForecastConsumptionFull(state),
              selectedDates: getInitialSelectedDates(state),
              liveState: getSiteLiveState(state),
              isEaton: isEatonBattery(state),
              lastDataPoint: getLastDataPoint(getAreaChartConsumption(state)),
              lastLivePoint: getForecastStartDate(getSiteLiveState(state)),
            }))
          )
        )
      )
    ),
    mergeMap(
      ({
        forecastConsumption,
        selectedDates: [startDate, endDate],
        liveState,
        isEaton,
        lastDataPoint,
        lastLivePoint,
      }) =>
        of(
          AnalysisActions.setInitialDataSeries({
            [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: transformForecastData({
              startDate: dateUtil.of(startDate),
              endDate: dateUtil.of(endDate),
              lastDataPointTimestamp: !isEaton && liveState ? lastLivePoint : lastDataPoint.x,
              lastDataPointPower:
                !isEaton && liveState ? liveState.consumptionPower : lastDataPoint.y!,
              forecasts: forecastConsumption || [],
              forecastDataKey: FORECAST_VALUE_CONSUMPTION,
            }),
          })
        )
    )
  );

const clearAnalysisZoomData$ = (actions$: Action$) =>
  actions$.pipe(ofType(AnalysisActions.setInitialChartDates), map(AnalysisActions.clearZoomState));

const clearInitialDataSeriesForForecast$ = (actions$: Action$, state$: State$) =>
  actions$.pipe(
    ofType(AnalysisActions.setInitialChartDates),
    filter(({ dates: [startDate] }) => dateUtil.isAfterToday(startDate)),
    mapToState(state$),
    map((state) =>
      AnalysisActions.setInitialDataSeries({
        ...INITIAL_DATA_SERIES_DATA,
        chargeLimit: getInitialSiteChargeLimitSeries(state),
        forecastProductionPower: getForecastProductionSeries(state),
        forecastConsumptionPower: getForecastConsumptionSeries(state),
      })
    )
  );

export const epics = combineEpics(
  getChartDataOnPageLoad$,
  getSiteMeasurements$,
  getSiteChargeLimits$,
  getBatteryStatuses$,
  normalizeMeasurements$,
  updateMeasurements$,
  startPolling$,
  stopPolling$,
  getForecastProductionFull$,
  getForecastConsumptionFull$,
  setForecastProductionSeries$,
  setForecastConsumptionSeries$,
  getSiteDetails$,
  getAnalysisStatistics$,
  setAnalysisStatistics$,
  normalizeBatteryStatuses$,
  normalizeSiteChargeLimits$,
  clearAnalysisZoomData$,
  clearInitialDataSeriesForForecast$,
  normalizeSiteCellData$,
  normalizeCellCareStatus$,
  getSiteCellData$
);
