import React, { useCallback, useEffect, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';
import { debounce } from 'lodash/fp';

import { SiteListRouteQueryParams } from '+router/routes';
import ClickOutside from '+shared/containers/ClickOutside/ClickOutside';

import { Close, Search } from '../../../../../../shared/basicComponents/Icons';

import './ExpandableSearchField.scss';

interface Props {
  searchQueryParam: string | undefined;
  routerQueryParams: SiteListRouteQueryParams;
  onSearch: (search: string, routeQueryParams: SiteListRouteQueryParams) => void;
  dataTestId?: string;
  dataTestIdButton?: string;
}

const ExpandableSearchField: React.FC<Props> = ({
  searchQueryParam,
  onSearch,
  routerQueryParams,
  dataTestId,
  dataTestIdButton,
}) => {
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const [showSearchValueReset, setShowSearchValueReset] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const placeholderKey = I18n.t(T.dashboard.searchPlaceholder);

  const debounceSearch = useCallback(
    debounce(500, (search: string, routerQueryParams: SiteListRouteQueryParams) =>
      onSearch(search, routerQueryParams)
    ),
    []
  );

  useEffect(() => {
    if (!isNil(searchQueryParam)) {
      inputRef.current!.value = searchQueryParam;
      toggleSearchField(true);
    }
  }, []);

  useEffect(() => {
    if (searchQueryParam === undefined) inputRef.current!.value = '';
    /* When resetting the search value by clicking the X button, page number is persisted,
     * but when user clicks on Customers tab in navbar, page is removed from routerQueryParams.
     * Therefore, search should be closed and page should display default state */
    if (!routerQueryParams.page) toggleSearchField(false);
  }, [searchQueryParam]);

  useEffect(() => {
    isNil(searchQueryParam) ? setShowSearchValueReset(false) : setShowSearchValueReset(true);
    if (isSearchVisible && inputRef.current) {
      // sets focus on the input field when input field is opened
      inputRef.current.focus();
      inputRef.current.placeholder = placeholderKey;

      if (inputRef.current.value) setShowSearchValueReset(true);
    }
  }, [isSearchVisible]);

  const handleSearch = () => {
    const searchValue = String(inputRef.current?.value);

    if (searchValue === '') {
      debounceSearch('', routerQueryParams);
      setShowSearchValueReset(false);
    }

    // this min. 3 chars restriction is imposed atm by the BE
    if (searchValue.length > 2) {
      debounceSearch(searchValue, routerQueryParams);
      setShowSearchValueReset(true);
    }
  };

  const toggleSearchField = (open: boolean) =>
    open ? setIsSearchVisible(true) : setIsSearchVisible(false);

  const resetInputValue = () => {
    if (inputRef.current) {
      debounceSearch('', routerQueryParams);
      inputRef.current.value = '';
      inputRef.current.focus();
      inputRef.current.placeholder = placeholderKey;
      setShowSearchValueReset(false);
    }
  };

  return (
    <ClickOutside onClick={() => toggleSearchField(!!inputRef.current?.value)}>
      <div className="expandable-search-field">
        <input
          name="search"
          type="text"
          autoComplete="off"
          ref={inputRef}
          className={`expandable-search-field__input ${isSearchVisible ? 'open' : 'closed'}`}
          onChange={handleSearch}
          data-testid={dataTestId}
        />

        <hr className={`expandable-search-field__line ${isSearchVisible ? 'expand' : ''}`} />

        <button
          className={`expandable-search-field__search-button ${isSearchVisible ? 'move' : ''}`}
          onClick={() => toggleSearchField(true)}
          disabled={isSearchVisible}
          data-testid={dataTestIdButton}
        >
          <Search className="search-button-icon" />
        </button>

        {isSearchVisible && showSearchValueReset && (
          <button className="expandable-search-field__clear-text-button" onClick={resetInputValue}>
            <Close />
          </button>
        )}
      </div>
    </ClickOutside>
  );
};

export default ExpandableSearchField;
