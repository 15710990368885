/**
 * @const TestId contains component IDs used to simplify
 * querying html elements when writing E2E tests
 * by providing unique identifiers
 */
export const TestId = {
  Lead: {
    List: {
      UpdateAndMoveToSetupButton: 'leads-overview-update-and-move-to-setup-btn',
    },
    StatusManager: {
      ContactedCheckbox: 'lead-status-manager-contacted-checkbox',
      OnSiteVisitArrangedCheckbox: 'lead-status-manager-on-site-visit-arranged-checkbox',
      OnSiteVisitDoneCheckbox: 'lead-status-manager-on-site-visit-done-checkbox',
      CustomerAlreadyOwnsBatteryCheckbox:
        'lead-status-manager-customer-already-owns-battery-checkbox',
      SaveButton: 'lead-status-manager-save-button',
      MoveToSetupButton: 'lead-status-manager-move-to-setup-button',
    },
    ConfigurationOverview: {
      StartNewConfigurationButton: 'lead-configuration-overview-start-new-configuration-button',
      DeleteOfferButton: 'lead-configuration-overview-delete-offer-button',
      DownloadOfferButton: 'lead-configuration-overview-download-offer-button',
      OpenVppBonusCheckButton: 'lead-configuration-overview-open-vpp-bonus-check-button',
      CreateImpactAnalysisButton: 'lead-configuration-overview-create-impact-analysis-button',
    },
    VppBonusCheckPopup: {
      FirstQuestionYesRadioButton: 'lead-vpp-bonus-check-popup-first-question-yes-radio-button',
      FirstQuestionNoRadioButton: 'lead-vpp-bonus-check-popup-first-question-no-radio-button',
      SecondQuestionYesRadioButton: 'lead-vpp-bonus-check-popup-second-question-yes-radio-button',
      SecondQuestionNoRadioButton: 'lead-vpp-bonus-check-popup-second-question-no-radio-button',
      ThirdQuestionYesRadioButton: 'lead-vpp-bonus-check-popup-third-question-yes-radio-button',
      ThirdQuestionNoRadioButton: 'lead-vpp-bonus-check-popup-third-question-no-radio-button',
      SaveButton: 'lead-vpp-bonus-check-popup-save-button',
    },
    ConfigurationCreation: {
      HeatPumpCheckbox: 'lead-configuration-creation-heat-pump-checkbox',
      ElectricVehicleCheckbox: 'lead-configuration-creation-electric-vehicle-checkbox',
      SinglePvRadioButton: 'lead-configuration-creation-single-pv-radio-button',
      MultiplePvRadioButton: 'lead-configuration-creation-multiple-pv-radio-button',
      DsoConsentCheckbox: 'lead-configuration-creation-dso-consent-checkbox',
      NewBatteryRadioButton: 'lead-configuration-creation-new-battery-radio-button',
      ExistingBatteryRadioButton: 'lead-configuration-creation-existing-battery-radio-button',
      ProtectInstalledYesRadioButton:
        'lead-configuration-creation-protect-installed-yes-radio-button',
      ProtectInstalledNoRadioButton:
        'lead-configuration-creation-protect-installed-no-radio-button',
      ProtectModeACRadioButton: 'lead-configuration-creation-protect-mode-ac-radio-button',
      ProtectModeBufferRadioButton: 'lead-configuration-creation-protect-mode-buffer-radio-button',
    },
    SalesConfigurator: {
      Tab: 'sales-configurator-tab',
    },
    SetupConfigurator: {
      Tab: 'setup-configurator-tab',
      ShowGuideButton: 'show-guide-btn',
      ToolsPreInstallationExpandBtn: 'tools-pre-installation-expand-btn',
      RegistrationToolsExpandButton: 'registration-tools-expand-btn',
      PVPlantBatterySelectIcon: 'pv-plant-battery-select-icon',
      BatterySelectIcon: 'battery-select-icon',
      SetupDataStartButton: 'setup-data-start-btn',
      SetupDataCompleteButton: 'setup-data-complete-btn',
      SetupDataContinueButton: 'setup-data-continue-btn',
      DSORegistrationStartButton: 'dso-registration-start-btn',
      DSOCommissionStartButton: 'dso-commission-start-btn',
      PVRegisterStartButton: 'pv-register-start-btn',
      SonnenFlatToolsExpandButton: 'sonnen-flat-tools-expand-btn',
      SonnenVPPDocumentationStartButton: 'sonnen-vpp-documentation-start-btn',
      PreCommissioningStartButton: 'pre-commissioning-start-btn',
    },
  },
  FormWizardStep: {
    continueBtn: 'continue-btn',
    backBtn: 'back-btn',
    saveAndContinueBtn: 'save-and-continue-btn',
  },
};
export const DsoRegistrationCustomerDataTestIds = {
  customerBirthday: 'birthday-field',
  customerTypeYesRadioButton: 'customer-type-ja-radio-btn',
  customerTypeNoRadioButton: 'customer-type-nein-radio-btn',
  lotNumber: 'parcel-number-field',
  dsoField: 'network-operator-field',
  uploadAttachmentButton: 'upload-attachment-btn',
};

export const DsoRegistrationPvSystemTestIds = {
  newInvestment: 'new-investment-radio-btn',
  existingInvestment: 'existing-investment-radio-btn',
  preferredInstallationDate: 'pv-anlage-planned-commissioning-date',
  panelLocation: 'pv-anlage-position-dropdown',
  panelLocationDropdownOptions: 'pv-anlage-position-dropdown-options',
  panelAlignment: 'pv-anlage-alignment-dropdown',
  panelAlignmentDropdownOptions: 'pv-anlage-alignment-dropdown-options',
  tiltAngle: 'pv-anlage-tilt-dropdown',
  tiltAngleoptions: 'pv-anlage-tilt-dropdown-options',
  pvAnlageManufacturerDropdown: 'pv-manufacter-dropdown',
  pvAnlageManufacturerDropdownOptions: 'pv-manufacter-dropdown-options',
  pvAnlageTypeDropdown: 'pv-type-dropdown',
  pvAnlageTypeDropdownOption: 'pv-type-dropdown-options',
  pvAnlageQuantityField: 'pv-quantity-field',
  newPvSize: 'new-pv-size-field',
  dcCoupledBatteryYesRadioButton: 'dc-coupled-battery-yes-radio-btn',
  dcCoupledBatteryNoRadioButton: 'dc-coupled-battery-no-radio-btn',
  inverterQuantityField: 'inverter-quantity-field',
};

export const DsoRegistrationPvSystemExtensionTestIds = {
  existingPvSize: 'existing-pv-size-field',
  mastrNumber: 'mastr-number-field',
  dateOfApplication: 'date-of-application-field',
  totalPvSize: 'total-pv-size-field',
  existingPvInverterCount: 'existing-pv-inverter-count-field',
};

export const DsoRegistrationInverterTestIds = {
  inverterManufacturerDropdown: 'manufacter-dropdown',
  inverterManufacturerDropdownOptions: 'manufacter-dropdown-options',
  inverterTypeDropdown: 'inverter-type-dropdown',
  inverterTypeDropdownOptions: 'inverter-type-dropdown-options',
};

export const DsoRegistrationBatteryTestIds = {
  preferredInstallationDate: 'preferred-installation-date-field',
  sonnenBatteryType: 'sonnenBatterie-type-dropdown',
  sonnenBatteryTypeOptions: 'sonnenBatterie-type-dropdown-options',
  sonnenBatterieStorageCapacityDropdown: 'sonnenBatterie-storage-capacity-dropdown',
  sonnenBatterieStorageCapacityDropdownOptions: 'sonnenBatterie-storage-capacity-dropdown-options',
};

export const DsoRegistrationMeasuringDeviceTestIds = {
  meterNumber: 'number-of-the-purchased-electricity-meter-field',
  typeOfGridReferenceMeter: 'type-of-purchased-electricity-counter-dropdown',
  typeOfGridReferenceMeterOptions: 'type-of-purchased-electricity-counter-dropdown-options',
  measuringConcept: 'type-of-feed-dropdown',
  measuringConceptOptions: 'type-of-feed-dropdown-options',
  feedInManagement: 'feed-in-management-dropdown',
  feedInManagementOptions: 'feed-in-management-dropdown-options',
  houseConnectionPower: 'service-connection-field',
  meterFuse: 'counter-fuse-field',
  meterFasteningType: 'counter-suspension-dropdown',
  meterFasteningTypeOptions: 'counter-suspension-dropdown-options',
};

export const DsoRegistrationDocumentsTestIds = {
  sitePlan: 'lageplan-upload-attachment-btn',
  wiringDiagram: 'schaltplan-upload-attachment-btn',
  additionalDocuments: 'zusätzliche-dokumente-upload-attachment-btn',
};

export const DsoRegistrationInstallerTestIds = {
  installerSelection: 'installer-section-dropdown',
  installerSelectionOptions: 'installer-section-dropdown-options',
  companyName: 'company-name-field',
  street: 'street-field',
  houseNumber: 'house-number-field',
  zipCode: 'zip-code-field',
  city: 'city-field',
  firstName: 'firstname-field',
  lastName: 'lastname-field',
  email: 'email-field',
  phoneNumber: 'phone-field',
  installerCertificationAuthority: 'registered-with-network-operator-dropdown',
  installerCertificationAuthorityOptions: 'registered-with-network-operator-dropdown-options',
  certificationNumber: 'installer-badge-number-field',
};

export const DsoCommissioningTestIds = {
  goToSetupDataStepButton: 'go-to-setup-data-step-button',
  generateDocumentsButton: 'generate-documents-button',
};

export const DsoRegistrationTestIds = {
  goToSetupDataStepButton: 'go-to-setup-data-step-button',
  generateDocumentsButton: 'generate-documents-button',
};

export const DsoSummaryFilesTestIds = {
  downloadDocumentPrefix: 'download-document-',
};

export const PVRegisterTestId = {
  goToSetupDataStepButton: 'go-to-setup-data-step-button',
  agreementCheckBox1: 'agreement-check-box1',
  agreementCheckBox2: 'agreement-check-box2',
  RegisterPVButton: 'Register-PV-button',
};

export const SignalMeasurementsTestIds = {
  uploadSpeedField: 'upload-speed-field',
  downloadSpeedField: 'download-speed-field',
  pingField: 'ping-field',
  signalField: 'signal-field',
  measurementsPictureUploadButton: 'measurements-picture-upload-button',
  yesLanConnectionAvailableRadioButton: 'yes-lan-connection-available-radio-button',
  noLanConnectionAvailableRadioButton: 'no-lan-connection-available-radio-button',
  wiringDiagramUploadButton: 'wiring-diagram-upload-button',
};

export const VppDocumentationAgreementsTestIds = {
  pvReductionAgreementCheckbox: 'pv-reduction-agreement-checkbox',
  meterCabinetPreparedAgreementCheckbox: 'meter-cabinet-prepared-agreement-checkbox',
};

export const VppDocumentationTestIds = {
  pvCommissioningDateField: 'pv-commissioning-date-field',
  batteryCommissioningDateField: 'battery-commissioning-date-field',
  sonnenMeterGRIDDropdown: 'sonnenMeter-GRID-dropdown',
  sonnenMeterGRIDDropdownOptions: 'sonnenMeter-GRID-dropdown-options',
  sonnenMeterCONSUMPTIONDropdown: 'sonnenMeter-CONSUMPTION-dropdown',
  sonnenMeterCONSUMPTIONDropdownOptions: 'sonnenMeter-CONSUMPTION-dropdown-options',
  meterNumberField: 'meter-number-field',
  typeOfGridReferenceMeterDropdown: 'type-of-grid-reference-meter-dropdown',
  typeOfGridReferenceMeterDropdownOptions: 'type-of-grid-reference-meter-dropdown-options',
  meterPicturesUploadButton: 'meter-pictures-upload-button',
};

export const VppDocumentationFormTestIds = {
  saveButton: 'save-button',
  sendDocumentationButton: 'send-documentation-button',
  yesProductionMeterExistingRadioButton: 'yes-production-meter-existing-radio-button',
  noProductionMeterExistingRadioButton: 'no-production-meter-existing-radio-button',
};

export const OverlayTestIds = {
  closeButton: 'close-button',
};

export const CommissioningDataFormTestIds = {
  processingNumberNetworkField: 'processing-number-network-field',
  pvCommissioningDateField: 'pv-commissioning-date-field',
  batteryCommissioningDateField: 'battery-commissioning-date-field',
};
