import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormStatus } from '+shared/components/FormStatus';

import { Button, ButtonSize } from '../Button';

import './FormSuccess.component.scss';

interface Props {
  onDone: () => void;
  headline: string;
}

export const FormSuccess: React.FC<Props> = ({ onDone, headline }) => (
  <FormStatus
    headline={headline}
    isSuccess={true}
    footer={
      <Button
        label={I18n.t(T.general.basicActions.done)}
        size={ButtonSize.SMALL}
        onClick={onDone}
        className={'c-form-success__button'}
      />
    }
  />
);
