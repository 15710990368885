import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { getPrecision } from '+utils/number.util';

import { CostsFormVersion } from '../+impactAnalysis.interface';

export const MIN_VALUE = 0;
// Basic view
export const MAX_TOTAL_ONE_OFF_INVESTMENT_COSTS_EUR = 500000;
export const MAX_ANNUAL_RUNNING_COSTS_EUR = 5000;
// Detailed view
export const MAX_PRICE_OF_PV_SYSTEM_EUR = 500000;
export const MAX_PRICE_OF_THE_INVERTER_AND_COMPONENTS_EUR = 500000;
export const MAX_INSTALLATION_COSTS_OF_PV_SYSTEM_EUR = 500000;
export const MAX_PRICE_OF_SONNEN_BATERIE_EUR = 500000;
export const MAX_INSTALLATION_COSTS_OF_SONNEN_BATERIE_EUR = 500000;
export const MAX_METER_CONVERSION_COSTS_EUR = 50000;
export const MAX_OTHER_INSTALLATION_COSTS_EUR = 500000;
export const MAX_INSURANCE_COSTS_EUR = 50000;
export const MAX_ANNUAL_MAINTENANCE_COSTS_EUR = 50000;
export const MAX_OTHER_ANNUAL_RUNNING_COSTS_EUR = 50000;

const CostsInputSchema = () =>
  Yup.number()
    .transform((value, originalValue) => (isNaN(value) ? undefined : originalValue))
    .min(MIN_VALUE, I18n.t(T.validation.greaterThanOrEqual))
    .test(
      'decimal-places',
      I18n.t(T.validation.lessDecimalPlaces),
      (val) => getPrecision(val) <= 2
    );

const RequiredCostsInputSchema = () =>
  CostsInputSchema()
    .required(I18n.t(T.register.alert.requiredField))
    .positive(I18n.t(T.validation.greaterThan0));

const ImpactAnalysisCostsBasicFormSchema = () =>
  Yup.object().shape({
    totalInvestmentCosts: RequiredCostsInputSchema().max(
      MAX_TOTAL_ONE_OFF_INVESTMENT_COSTS_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_TOTAL_ONE_OFF_INVESTMENT_COSTS_EUR })
    ),
    totalRunningCostsPerYear: RequiredCostsInputSchema().max(
      MAX_ANNUAL_RUNNING_COSTS_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_ANNUAL_RUNNING_COSTS_EUR })
    ),
  });

const ImpactAnalysisCostsDetailedFormSchema = () =>
  Yup.object().shape({
    pvSystemPurchase: Yup.number()
      .transform((value, originalValue) => (isNaN(value) ? undefined : originalValue))
      .test(
        'decimal-places',
        I18n.t(T.validation.lessDecimalPlaces),
        (val) => getPrecision(val) <= 2
      )
      .required(I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.requiredPvCostsError))
      .max(
        MAX_PRICE_OF_PV_SYSTEM_EUR,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_PRICE_OF_PV_SYSTEM_EUR })
      ),
    pvSystemInstallation: CostsInputSchema().max(
      MAX_INSTALLATION_COSTS_OF_PV_SYSTEM_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_INSTALLATION_COSTS_OF_PV_SYSTEM_EUR })
    ),
    inverterAndOtherComponentsPurchase: CostsInputSchema().max(
      MAX_PRICE_OF_THE_INVERTER_AND_COMPONENTS_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_PRICE_OF_THE_INVERTER_AND_COMPONENTS_EUR })
    ),
    batteryPurchase: RequiredCostsInputSchema().max(
      MAX_PRICE_OF_SONNEN_BATERIE_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_PRICE_OF_SONNEN_BATERIE_EUR })
    ),
    batteryInstallation: CostsInputSchema().max(
      MAX_INSTALLATION_COSTS_OF_SONNEN_BATERIE_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_INSTALLATION_COSTS_OF_SONNEN_BATERIE_EUR })
    ),
    meterReplacement: CostsInputSchema().max(
      MAX_METER_CONVERSION_COSTS_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_METER_CONVERSION_COSTS_EUR })
    ),
    otherInstallation: CostsInputSchema().max(
      MAX_OTHER_INSTALLATION_COSTS_EUR,
      I18n.t(T.validation.lessThanOrEqual, { max: MAX_OTHER_INSTALLATION_COSTS_EUR })
    ),
    insurancePerYear: CostsInputSchema()
      .max(
        MAX_INSURANCE_COSTS_EUR,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_INSURANCE_COSTS_EUR })
      )
      .test(
        'annual-costs',
        I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.requiredRunningCostsError),
        function (insuranceCosts: number) {
          return [
            insuranceCosts,
            this.parent.hardwareMaintenancePerYear,
            this.parent.additionalRunningCostsPerYear,
          ].some((value) => value !== undefined && value !== 0);
        }
      ),
    hardwareMaintenancePerYear: CostsInputSchema()
      .max(
        MAX_ANNUAL_MAINTENANCE_COSTS_EUR,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_ANNUAL_MAINTENANCE_COSTS_EUR })
      )
      .test(
        'annual-costs',
        I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.requiredRunningCostsError),
        function (maintenanceCosts: number) {
          return [
            this.parent.insurancePerYear,
            maintenanceCosts,
            this.parent.additionalRunningCostsPerYear,
          ].some((value) => value !== undefined && value !== 0);
        }
      ),
    additionalRunningCostsPerYear: CostsInputSchema()
      .max(
        MAX_OTHER_ANNUAL_RUNNING_COSTS_EUR,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_OTHER_ANNUAL_RUNNING_COSTS_EUR })
      )
      .test(
        'annual-costs',
        I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.requiredRunningCostsError),
        function (additionalCosts: number) {
          return [
            this.parent.insurancePerYear,
            this.parent.hardwareMaintenancePerYear,
            additionalCosts,
          ].some((value) => value !== undefined && value !== 0);
        }
      ),
  });

export const ImpactAnalysisCostsFormSchema = () =>
  Yup.object().shape({
    basic: Yup.object().when('version', {
      is: (version) => version === CostsFormVersion.BASIC,
      then: ImpactAnalysisCostsBasicFormSchema(),
    }),
    detailed: Yup.object().when('version', {
      is: (version) => version === CostsFormVersion.DETAILED,
      then: ImpactAnalysisCostsDetailedFormSchema(),
    }),
  });
