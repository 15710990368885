import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  Card,
  Icon,
  PageHeadline,
  PageSubheadline,
  WarningText,
  WizardContext,
} from '@sonnen/shared-web';

import { Formik } from 'formik';
import { defaultTo } from 'lodash/fp';

import { mapActions } from '+app/utils';
import { Form, FormCheckbox } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';
import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { StoreState } from '+shared/store/store.interface';

import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { ImpactAnalysisActions } from '../../store/+impactAnalysis.actions';
import { PdfCreationForm } from '../../store/+impactAnalysis.interface';
import {
  getImpactAnalysisRecommendation,
  getPdfCreationForm,
} from '../../store/+impactAnalysis.selectors';
import {
  defaultValues,
  defaultValuesNotProfitable,
  formFields,
  getOnChangeFunction,
} from './LeadImpactAnalysisPdfCreation.helper';

import './LeadImpactAnalysisPdfCreation.component.scss';

const mapDispatchToProps = mapActions({
  setPdfCreation: ImpactAnalysisActions.setPdfCreationForm,
});

const mapStateToProps = (state: StoreState) => ({
  pdfCreation: getPdfCreationForm(state),
  recommendation: getImpactAnalysisRecommendation(state),
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const LeadImpactAnalysisPdfCreationComponent: React.FC<Props> = ({
  actions,
  pdfCreation,
  recommendation,
}) => {
  const wizard = React.useContext(WizardContext);
  const [pageToPreview, setPageToPreview] = React.useState<string | null>(null);

  const isSonnenFlatProfitable = recommendation && recommendation.isSonnenFlatProfitable;

  const onSubmit = (values: PdfCreationForm) => {
    actions.setPdfCreation(values);
    if (wizard.complete) {
      wizard.complete();
    }
  };

  const initialValues = defaultTo(
    isSonnenFlatProfitable ? defaultValues : defaultValuesNotProfitable
  )(pdfCreation);

  const controlledCheckboxPages = [
    formFields.pages.energyFutureStandardPage,
    formFields.pages.energyFutureSonnenFlatPage,
  ];

  return (
    <Formik<PdfCreationForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(form) => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <PageHeadline>
              {I18n.t(T.lead.impactAnalysis._salessolution_.pdfCreation.title)}
            </PageHeadline>
            <PageSubheadline noBorder={true} mediumGap={true}>
              {I18n.t(T.lead.impactAnalysis._salessolution_.pdfCreation.heading)}
            </PageSubheadline>
            {!isSonnenFlatProfitable && (
              <div className="c-impact-analysis-page-select-warning-wrapper">
                <WarningText
                  text={I18n.t(
                    T.lead.impactAnalysis._salessolution_.pdfCreation.notProfitableWarning
                  )}
                />
              </div>
            )}
            <div className="c-impact-analysis-page-select-wrapper">
              <div className="c-impact-analysis-page-select-inputs-wrapper">
                {Object.keys(formFields.pages).map((pageKey) => (
                  <div
                    className="c-impact-analysis-page-select-input-wrapper"
                    key={formFields.pages[pageKey].name}
                  >
                    <div className="c-impact-analysis-page-select-checkbox">
                      {controlledCheckboxPages.includes(formFields.pages[pageKey]) ? (
                        <FormFieldObserver<PdfCreationForm>
                          onChange={getOnChangeFunction(pageKey, form)}
                        >
                          <FormControlledCheckbox
                            form={form}
                            label={<Translate value={formFields.pages[pageKey].label} />}
                            name={formFields.pages[pageKey].name}
                          />
                        </FormFieldObserver>
                      ) : (
                        <div className={'c-lead-impact-analysis-pdf-creation__checkbox'}>
                          <FormCheckbox
                            form={form}
                            isDisabled={!formFields.pages[pageKey].isRemovable}
                            label={<Translate value={formFields.pages[pageKey].label} />}
                            name={formFields.pages[pageKey].name}
                          />
                        </div>
                      )}
                    </div>
                    <div className="c-impact-analysis-page-select-show-btn">
                      {pageToPreview !== pageKey && (
                        <Button
                          type={ButtonType.TERTIARY}
                          onClick={() => setPageToPreview(pageKey)}
                          label={I18n.t(T.lead.impactAnalysis._salessolution_.pdfCreation.show)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="c-impact-analysis-page-select-image-wrapper">
                {pageToPreview && (
                  <>
                    <div>
                      <img
                        src={formFields.pages[pageToPreview].previewImage}
                        className="c-impact-analysis-page-select-image"
                        alt={formFields.pages[pageToPreview].label}
                      />
                    </div>
                    <div className="c-impact-analysis-page-select-image-info">
                      <div>
                        <Icon.Info className="c-impact-analysis-page-select-image-info-icon" />
                      </div>
                      <div className="c-impact-analysis-page-select-image-info-text">
                        {I18n.t(T.lead.impactAnalysis._salessolution_.pdfCreation.previewInfo)}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Card>
        </Form>
      )}
    />
  );
};

export const LeadImpactAnalysisPdfCreation = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadImpactAnalysisPdfCreationComponent);
