import * as React from 'react';
import Media from 'react-media';

import { MediaQuery } from '@sonnen/shared-web';

import { Customer } from '+shared/store/customer';

import { getInlineAddress } from '../../store/customer.helpers';

import './CustomersTableCustomerAddress.component.scss';

interface Props {
  customer: Customer;
}

export const CustomersTableCustomerAddress: React.FC<Props> = ({ customer }) => (
  <div className={'c-customers-table-address'}>
    <Media query={MediaQuery.UP_XS}>
      {(isMedium: boolean) =>
        isMedium ? (
          <>
            <p className={'c-customers-table__paragraph'}>{customer.street}</p>
            <p className={'c-customers-table__paragraph'}>
              {customer.postalCode} {customer.city}
            </p>
          </>
        ) : (
          <p className={'c-customers-table__paragraph'}>{getInlineAddress(customer)}</p>
        )
      }
    </Media>
  </div>
);
