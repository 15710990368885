import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import classNames from 'classnames';
import { defaultTo } from 'lodash/fp';

import { ClickOutside } from '../ClickOutside';

import './Dropdown.component.scss';

interface TriggerBag {
  toggle: (isActive?: boolean) => void;
}

interface Props {
  trigger: ((triggerBag: TriggerBag) => React.ReactNode) | React.ReactNode;
  isOpen?: boolean;
  className?: ClassValue;
  id?: string;
  isClosingOnContentClick?: boolean;
  handleShow?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
}

interface State {
  isOpen: boolean;
}

//TODO: possible to merge with DropdownWrapper?
export class Dropdown extends React.PureComponent<Props, State> {
  readonly state = { isOpen: false };

  toggleDropdown = (isActive?: boolean) =>
    this.setState({
      isOpen: defaultTo(!this.state.isOpen)(isActive),
    });

  handleLinkClick = (e: React.SyntheticEvent<HTMLAnchorElement>, isActive?: boolean) => {
    if (!!this.props.handleShow && !this.state.isOpen) {
      this.props.handleShow(e);
    }
    this.toggleDropdown(isActive);
  };

  render() {
    const { isOpen } = this.state;
    const { className, children, trigger, id, isClosingOnContentClick = true } = this.props;
    const containerClassNames = classNames('shared-dropdown', { 'is-active': isOpen }, className);
    const triggerClassNames = classNames('shared-dropdown__trigger', { 'is-active': isOpen });

    return (
      <ClickOutside onClick={() => this.toggleDropdown(false)}>
        <div className={containerClassNames}>
          <a
            className={triggerClassNames}
            onClick={(e) => (trigger instanceof Function ? null : this.handleLinkClick(e))}
            id={id}
          >
            {trigger instanceof Function ? trigger({ toggle: this.toggleDropdown }) : trigger}
          </a>
          <CSSTransition in={isOpen} timeout={200} classNames={'is'}>
            {React.cloneElement(
              <div
                onClick={isClosingOnContentClick ? () => this.toggleDropdown(false) : undefined}
              />,
              { className: 'shared-dropdown__content' },
              children
            )}
          </CSSTransition>
        </div>
      </ClickOutside>
    );
  }
}
