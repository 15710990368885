import { createReducer } from '+app/utils';
import { trimFieldValues } from '+setupTool/+form/store/+form.helpers';
import { queryReducer } from '+shared/store/query';

import { VPP_DOCUMENTATION_ACTIONS, VppDocumentationActions } from './+vppDocumentation.actions';
import {
  initialState,
  VPP_CONFIG_SUBMIT_QUERY,
  VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY,
  VppDocumentationState,
} from './+vppDocumentation.state';

export const reducer = createReducer<
  VppDocumentationState,
  VppDocumentationActions | VppDocumentationActions
>((state = initialState, action): VppDocumentationState => {
  switch (action.type) {
    case VPP_DOCUMENTATION_ACTIONS.SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...trimFieldValues(action.fields),
        },
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_UPLOADED_DOCUMENTS:
      return {
        ...state,
        uploadedDocuments: action.documents,
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_NOTIFICATION_DOCUMENT:
      return {
        ...state,
        completionNotificationDocument: action.document,
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_MODIFICATION_REGENERATED_AT:
      return {
        ...state,
        completionNotificationRegeneratedAt: action.regeneratedAt,
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_SUBMISSION_STATUS:
      return {
        ...state,
        submissionStatus: action.submissionStatus,
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_VPP_ADDITIONAL_FEATURES:
      return {
        ...state,
        vppAdditionalFeatures: action.vppAdditionalFeatures,
      };
    case VPP_DOCUMENTATION_ACTIONS.SET_VPP_STATE:
      return {
        ...state,
        vppState: action.vppState,
      };
    default:
      return queryReducer(state, action, [
        VPP_CONFIG_SUBMIT_QUERY,
        VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY,
      ]);
  }
});
