import {
  BarChartSeries,
  EnergyFlowSeriesKey,
  PieChartSeries,
  StatisticsResolution,
  StatisticsSelectedDate,
  TimeRangePickerDates,
  TimeUnit,
} from '@sonnen/shared-web';

import { PersistedState } from 'redux-persist';

import { SiteMeasurements, SiteMeasurementsData } from '+shared/store/site/types';
import { Resolution } from '+shared/store/site/types/resolution.interface';
import { SiteLiveStateData } from '+shared/store/site/types/siteLiveState.interface';
import { dateUtil } from '+utils/date.util';

import { Query } from '../../../shared/store/query';
import { ChartDataSeries, ChartDataSeriesKey } from './types/chartDataSeries.interface';
import { SiteForecastConsumption, SiteForecastProduction } from './types/forecast.interface';

export const GET_SITE_MEASUREMENTS_QUERY = 'getSiteMeasurementsQuery';
export const GET_SITE_LIVE_STATE_QUERY = 'getSiteLiveStateQuery';
export const GET_FORECAST_PRODUCTION_QUERY = 'getForecastProductionQuery';
export const GET_FORECAST_CONSUMPTION_QUERY = 'getForecastConsumptionQuery';

export const INITIAL_DATA_SERIES_DATA: ChartDataSeries = {
  productionPower: [],
  consumptionPower: [],
  directUsagePower: [],
  batteryUsoc: [],
  batteryCharging: [],
  batteryDischarging: [],
  forecastProductionPower: [],
  forecastConsumptionPower: [],
  chargeLimit: [],
  vppActivity: [],
  cellCare: [],
  temperature: [],
  voltage: [],
};

type InitialDataSeries = {
  data: ChartDataSeries;
  resolution: Resolution;
  selectedDates: TimeRangePickerDates;
};

type CurrentDataSeries = {
  data: ChartDataSeries;
  resolution?: Resolution;
  selectedDates?: TimeRangePickerDates;
};

export interface AnalysisPageState extends PersistedState {
  dayChart: {
    selectedDataSeriesKeys: ChartDataSeriesKey[];
    forecastProductionFull?: SiteForecastProduction[];
    forecastConsumptionFull?: SiteForecastConsumption[];
    initialDataSeries: InitialDataSeries;
    currentDataSeries: CurrentDataSeries;
    siteMeasurements?: SiteMeasurements;
    isUnsupportedBattery: boolean;
    isResolutionChanged: boolean;
  };
  statistics: {
    selectedDate: StatisticsSelectedDate;
    pieChartSeries?: PieChartSeries;
    barChartSeries?: BarChartSeries;
  };
  [GET_SITE_MEASUREMENTS_QUERY]: Query<SiteMeasurementsData>;
  [GET_SITE_LIVE_STATE_QUERY]: Query<SiteLiveStateData>;
  [GET_FORECAST_PRODUCTION_QUERY]: Query<SiteForecastProduction[]>;
  [GET_FORECAST_CONSUMPTION_QUERY]: Query<SiteForecastConsumption[]>;
}

export const initialState: AnalysisPageState = {
  dayChart: {
    selectedDataSeriesKeys: [
      EnergyFlowSeriesKey.PRODUCTION_POWER,
      EnergyFlowSeriesKey.CONSUMPTION_POWER,
      EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
      EnergyFlowSeriesKey.BATTERY_USOC,
    ],
    initialDataSeries: {
      data: INITIAL_DATA_SERIES_DATA,
      resolution: '1-minute',
      selectedDates: [
        dateUtil.getStartOfDate(dateUtil.now()),
        dateUtil.getEndOfDate(dateUtil.now()),
      ],
    },
    currentDataSeries: {
      data: INITIAL_DATA_SERIES_DATA,
    },
    forecastProductionFull: [],
    forecastConsumptionFull: [],
    isUnsupportedBattery: false,
    siteMeasurements: undefined,
    isResolutionChanged: false,
  },
  statistics: {
    barChartSeries: {
      consumedEnergy: [],
      producedEnergy: [],
    },
    pieChartSeries: {
      autonomy: {
        gridPurchaseEnergy: 0,
        consumedEnergy: 0,
      },
      selfConsumption: {
        gridFeedinEnergy: 0,
        producedEnergy: 0,
      },
    },
    selectedDate: {
      period: TimeUnit.DAY,
      date: new Date(),
      resolution: StatisticsResolution.HOUR,
    },
  },
  [GET_SITE_MEASUREMENTS_QUERY]: {},
  [GET_SITE_LIVE_STATE_QUERY]: {},
  [GET_FORECAST_PRODUCTION_QUERY]: {},
  [GET_FORECAST_CONSUMPTION_QUERY]: {},
};
