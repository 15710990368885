import React from 'react';

import SitesPageHeader from './components/SitesPageHeader/SitesPageHeader';
import TabSites from './components/TabSites/TabSites';

const SitesPage: React.FC = () => (
  <>
    <SitesPageHeader />
    <TabSites />
  </>
);

export default SitesPage;
