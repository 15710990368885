import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Widget } from '+shared/components/Widget/Widget';
import { Lead } from '+shared/store/lead/types';

import './LeadOverviewSonnenNotes.component.scss';

interface Props {
  lead: Lead;
}

export const LeadOverviewSonnenNotes: React.FC<Props> = ({ lead }) => (
  <Widget
    icon={'Notes'}
    className={'c-lead-overview-sonnen-notes'}
    heading={I18n.t(T.lead.boc._salessolution_.form.sonnenNotes.headline)}
  >
    <div className={'c-lead-overview-sonnen-notes__shadow'}>
      <div className={'c-lead-overview-sonnen-notes__text-wrapper'}>
        {lead.description ? `${lead.description}\n\n` : ''}
      </div>
    </div>
  </Widget>
);
