import React from 'react';

type Props = {
  className?: string;
};

export const HeatPumpIllustration: React.FC<Props> = ({ className }) => (
  <svg className={className} width="140" height="79" viewBox="0 0 140 79" fill="none">
    <g filter="url(#filter0_d_995_18408)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.2 5.8689L134.2 26.2378L134.2 53.3963L134.2 73.7652L5.00018 73.7652L5.00018 5.86889L134.2 5.8689Z"
        fill="white"
      />
    </g>
    <ellipse cx="102" cy="42.1983" rx="19" ry="18.971" fill="#D3D5D8" />
    <path
      d="M102 18.8494V42.1983M102 42.1983L83.7305 53.8728M102 42.1983L121 53.8728"
      stroke="white"
      strokeWidth="3"
    />
    <ellipse cx="102" cy="42.812" rx="4" ry="3.9939" fill="white" />
    <path
      d="M48 33.8265C51 31.8296 58.2 27.8357 63 27.8357C68.9999 27.8357 74 33.8265 80 33.8265C84.8 33.8265 90 30.8311 92 29.3334"
      stroke="#0A1535"
      strokeWidth="2"
    />
    <path
      d="M48.5 41.814C51.5 39.8171 58.7 35.8232 63.5 35.8232C69.4999 35.8231 74.5 41.814 80.5 41.814C85.3 41.814 90.5 38.8186 92.5 37.3209"
      stroke="#0A1535"
      strokeWidth="2"
    />
    <path
      d="M48.5 51.7992C51.5 49.8023 58.7 45.8084 63.5 45.8083C69.4999 45.8083 74.5 51.7992 80.5 51.7992C85.3 51.7992 90.5 48.8038 92.5 47.3061"
      stroke="#0A1535"
      strokeWidth="2"
    />
    <defs>
      <filter
        id="filter0_d_995_18408"
        x="0.00012207"
        y="0.868896"
        width="139.2"
        height="77.8963"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_995_18408"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.827451 0 0 0 0 0.835294 0 0 0 0 0.847059 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_995_18408" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_995_18408"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
