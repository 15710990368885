import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader } from '@sonnen/shared-web';

import { isNil } from 'lodash';

import { PeakPowerUnit } from '+app/App.constants';
import { PvSystem } from '+shared/basicComponents/Icons';
import { QueryState } from '+shared/store/query/query.state';
import { formatPeakPower, formatPercentage } from '+utils/format.util';
import { useLocaleContext } from '+utils/react/locale.provider';

import './PvSystemCard.scss';

interface Props {
  pvPeakPower: number | undefined;
  pvGridFeedInLimit: number | undefined | null;
  queryStatus: QueryState;
  isBatteryPvGridFeedInLimitInProgress: boolean;
}

export const PvSystemCard: React.FC<Props> = ({
  pvPeakPower,
  pvGridFeedInLimit,
  queryStatus,
  isBatteryPvGridFeedInLimitInProgress,
}) => {
  const { headline, pvSize, feedinLimit } = T.customerSingle.overview.pvSystem;
  const { inProgress } = T.customerSingle.pvSystemsAndMeters.pvSystemOperations.pvFeedInOperation;
  const { locale } = useLocaleContext();

  const pvHeadingIcon = Icon['PV']({});
  const widgetHeading = I18n.t(headline);

  const unknownValueLabel = I18n.t(T.general.errors.general.attributeUnknown);

  const systemSizeLabel = I18n.t(pvSize);
  const systemSizeValue = !isNil(pvPeakPower)
    ? formatPeakPower({ locale, unit: PeakPowerUnit.KWP })(pvPeakPower)
    : unknownValueLabel;
  const feedinLimitLabel = I18n.t(feedinLimit);
  const feedinLimitValue = !isNil(pvGridFeedInLimit)
    ? formatPercentage({ locale })(pvGridFeedInLimit)
    : unknownValueLabel;

  const InProgressLoader = (): JSX.Element => (
    <>
      <div className="loader">{I18n.t(inProgress)}</div>
      <div className="loader">
        <Loader />
      </div>
    </>
  );

  return (
    <div className="c-widget">
      <div className="c-widget__header">
        <div className="c-widget__header-inner">
          <div className="c-widget__icon">{pvHeadingIcon}</div>
          <h4 className="c-widget__heading">{widgetHeading}</h4>
        </div>
      </div>
      <div className="c-widget__body-wrapper">
        <div className="pv-specs-card">
          <div className="loader">{queryStatus.pending && <Loader />}</div>

          {!queryStatus.error && !queryStatus.pending && (
            <>
              <div className="pv-specs-card__list pv-specs-card__list--align-right">
                <p className="label">{systemSizeLabel}</p>
                <p className="value">{systemSizeValue}</p>
              </div>

              <div className="pv-specs__svg">
                <PvSystem />
              </div>

              <div className="pv-specs-card__list">
                <p className="label">{feedinLimitLabel}</p>
                <div className="value">
                  {isBatteryPvGridFeedInLimitInProgress ? (
                    <InProgressLoader />
                  ) : (
                    <p>{feedinLimitValue}</p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
