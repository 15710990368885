import { createReducer } from '+app/utils';

import { TUTORIAL_ACTIONS, TutorialActions } from './tutorial.actions';
import { initialState, TutorialState } from './tutorial.state';

type Actions = TutorialActions;

export const reducer = createReducer<TutorialState, Actions>(
  (state = initialState, action): TutorialState => {
    switch (action.type) {
      case TUTORIAL_ACTIONS.SET_INITIAL_SLIDE:
        return {
          ...state,
          initialSlide: {
            ...state.initialSlide,
            [action.tutorialId]: action.initialSlide,
          },
        };
      case TUTORIAL_ACTIONS.TOGGLE_TUTORIAL:
        return {
          ...state,
          isOpened: {
            ...state.isOpened,
            [action.tutorialId]: action.isOpened,
          },
        };
      case TUTORIAL_ACTIONS.APPEND_CURRENTLY_DISPLAYED_SLIDES:
        return {
          ...state,
          currentlyDisplayedSlides: {
            ...state.currentlyDisplayedSlides,
            ...action.slides,
          },
        };
      case TUTORIAL_ACTIONS.REMOVE_CURRENTLY_DISPLAYED_SLIDES:
        return {
          ...state,
          currentlyDisplayedSlides: [],
        };
      default:
        return state;
    }
  }
);
