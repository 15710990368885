import { defer, from } from 'rxjs';

import { ContactListRouteQueryParams } from '+app/router';
import { Config } from '+config/config';

import { jsonApiClient } from '../../network/network.client';
import { UserCertificateData } from '../user/types/userCertificate.interface';
import { ContactData } from './types';

const BASE_URL = `${Config.API_URL}/contacts?include=roles&page[size]=10`;

const getContactList = ({ page, search }: ContactListRouteQueryParams = {}) => {
  const searchParam = search ? `%${search}%` : undefined;

  return from(
    jsonApiClient
      .get<ContactData>(`${BASE_URL}`)
      .filter('email', searchParam)
      .pageNumber(page)
      .expectMany()
      .resolveIncluded()
      .send()
  );
};

const getContactCertificateList = (id: string) =>
  defer(() =>
    jsonApiClient
      .get<UserCertificateData>(`${Config.API_URL}/contacts/${id}/certificates`)
      .expectMany()
      .send()
  );

export const ContactRepository = {
  getContactList,
  getContactCertificateList,
};
