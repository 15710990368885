export const searchStreet = (input: string, streetsCollection: string[]) => {
  const searchResultStartsWith = searchAndSortStreetsStartsWith(streetsCollection, input);

  return searchResultStartsWith.length > 0
    ? searchResultStartsWith
    : searchAndSortStreetsIncludes(streetsCollection, input);
};

export const searchAndSortStreetsStartsWith = (streetsCollection: string[], input: string) =>
  streetsCollection.filter((value) => value.toLowerCase().startsWith(input.toLowerCase())).sort();

export const searchAndSortStreetsIncludes = (streetsCollection: string[], input: string) =>
  streetsCollection.filter((value) => value.toLowerCase().includes(input.toLowerCase())).sort();

export const filterStreetsLowerCase = (streetsCollection: string[], input: string) =>
  streetsCollection.filter((value) => value.toLowerCase() === input.toLowerCase());
