import { Dispatch } from 'redux';

export const mapActions =
  <T extends object>(actions: T) =>
  (dispatch: Dispatch): { actions: T; dispatch: Dispatch } => {
    const newActions = {};
    if (actions) {
      Object.entries(actions).forEach(
        ([key, action]) => (newActions[key] = (...args: any[]) => dispatch(action(...args)))
      );
    }

    return { actions: newActions as T, dispatch };
  };
