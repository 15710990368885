import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { User } from '+shared/store/user';
import {
  getUserAddress,
  getUserCertificationId,
  getUserCompany,
  getUserName,
  getUserNumber,
  getUserRoles,
} from '+shared/store/user/user.helpers';

const getTranslatedRoles = (roles: string[]) => {
  const rolesTranslated = roles.map((role) => I18n.t(T.myAccount.personalDetails.rolesNames[role]));
  return rolesTranslated.join(', ');
};

export const getPersonalDetailsList = (user: User) => [
  {
    label: I18n.t(T.myAccount.personalDetails.company),
    value: getUserCompany(user),
  },
  {
    label: I18n.t(T.myAccount.personalDetails.number),
    value: getUserNumber(user),
  },
  {
    label: I18n.t(T.myAccount.personalDetails.name),
    value: getUserName(user),
  },
  {
    label: I18n.t(T.myAccount.personalDetails.address),
    value: getUserAddress(user),
  },
  {
    label: I18n.t(T.myAccount.personalDetails.roles),
    value: getTranslatedRoles(getUserRoles(user)),
  },
  {
    label: I18n.t(T.myAccount.personalDetails.certificationId),
    value: getUserCertificationId(user),
  },
];
