import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { isLocationChangeAction } from '+app/router/store/router.actions';
import { isPathChangingFromCustomerToAnother } from '+app/router/store/router.selectors';
import { CustomerRepository } from '+shared/store/customer/customer.repository';
import { GET_SITE_LIVE_STATE_V2_QUERY } from '+shared/store/site';
import { SiteActions } from '+shared/store/site/site.actions';

import { ROUTES } from '../../router';
import { RouterActions } from '../../router/store';
import { StoreState } from '../../shared/store/store.interface';
import {
  dataGuard,
  mapPathToParams,
  mapToState,
  matchPath,
  ofType,
  processQuery,
} from '../../utils';
import { epics as analysisEpics } from '../+analysis/store/+analysis.epics';
import { epics as batteryEpics } from '../+battery/store/+battery.epics';
import { epics as casesEpics } from '../+case/store/+case.epics';
import { epics as contractEpics } from '../+contract/store/+contract.epics';
import { epics as heatPumpEpics } from '../+heatPump/store/+heatPump.epics';
import { epics as overviewEpics } from '../+overview/store/+overview.epics';
import { epics as pvSystemsEpics } from '../+pvSystems/store/pvSystems.epics';
import { CustomerActions, CustomerActions as CustomerPageActions } from './+customer.actions';
import { GET_CUSTOMER_QUERY } from './+customer.state';

type Action$ = ActionsObservable<RouterActions | CustomerActions>;
type State$ = StateObservable<StoreState>;

export const getSelectedCustomerData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    mapToState(state$),
    mapPathToParams(
      ROUTES.CUSTOMER[0],
      ROUTES.CUSTOMER_BATTERY[0],
      ROUTES.CUSTOMER_PV_SYSTEMS[0],
      ROUTES.CUSTOMER_HEAT_PUMP[0],
      ROUTES.CUSTOMER_CONTRACT[0],
      ROUTES.CUSTOMER_ANALYSIS[0],
      ROUTES.CUSTOMER_CASES[0]
    ),
    mergeMap(([customerId, siteId]) =>
      of(
        CustomerActions.getCustomer(customerId, GET_CUSTOMER_QUERY),
        SiteActions.getSiteLiveStateV2({ queryKey: GET_SITE_LIVE_STATE_V2_QUERY, siteId })
      )
    )
  );

export const getCustomer$ = (action$: Action$) =>
  action$.pipe(
    ofType(CustomerActions.getCustomer),
    mergeMap(({ queryKey, customerId }) =>
      of(customerId).pipe(
        processQuery(queryKey, () => CustomerRepository.getCustomer(customerId), {
          onSuccess: (res) => dataGuard(CustomerActions.setCustomer)(res!.element),
        })
      )
    )
  );

export const putBatteryNote$ = (action$: Action$) =>
  action$.pipe(
    ofType(CustomerActions.putCustomerNote),
    mergeMap(({ queryKey, customerId, note }) =>
      of({}).pipe(
        processQuery(queryKey, () => CustomerRepository.putCustomerNote(customerId, note), {
          onSuccess: (res) => dataGuard(CustomerActions.setCustomerNote)(res!.element),
        })
      )
    )
  );

export const clearSelectedCustomerData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(
      RouterActions.locationChange,
      RouterActions.changeCustomerSite,
      RouterActions.goToCustomers
    ),
    withLatestFrom(state$),
    filter(([action, state]) =>
      isLocationChangeAction(action) ? isPathChangingFromCustomerToAnother(state) : true
    ),
    map(() => CustomerPageActions.clearCustomerData())
  );

export const clearSelectedCustomerDataOnExactRoute$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange),
    mapToState(state$),
    matchPath(ROUTES.CUSTOMERS[0]),
    map(() => CustomerPageActions.clearCustomerData())
  );

export const epics = combineEpics<any>(
  getSelectedCustomerData$,
  clearSelectedCustomerData$,
  clearSelectedCustomerDataOnExactRoute$,
  analysisEpics,
  batteryEpics,
  pvSystemsEpics,
  contractEpics,
  casesEpics,
  overviewEpics,
  getCustomer$,
  putBatteryNote$,
  heatPumpEpics
);
