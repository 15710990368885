import { from } from 'rxjs';

import { Config } from '+config/config';

import { jsonApiClient } from '../../network/network.client';

const postRole = (roleName: string, contactId: string) =>
  from(
    jsonApiClient
      .post(`${Config.API_URL}/roles`)
      .ofType('roles')
      .withAttributes({
        name: roleName,
      })
      .withRelationship({
        uuid: contactId,
        name: 'contact',
        type: 'contact',
      })
      .expectOne()
      .send()
  );

// @TODO refaktor roleId is mandatory
const deleteRole = (roleId?: string) =>
  from(jsonApiClient.delete(`${Config.API_URL}/roles/${roleId}`).expectOne().send());

export const RoleRepository = {
  postRole,
  deleteRole,
};
