import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { getAggregatedEnergy, PieChartSeries, StatisticsSeriesKey } from '@sonnen/shared-web';

import { flow } from 'lodash/fp';

import { formatNumber } from '+app/utils/format.util.old';

export const CHART_HEIGHT = {
  WEB: 400,
  MOBILE: 250,
};

export interface ToLinearFontSize {
  text: string;
  baseFontSize: number;
  multiplier: number;
  maxBaseSizeTextLength: number;
}

export const toLinearlyScaleDown = ({
  text,
  baseFontSize,
  multiplier,
  maxBaseSizeTextLength,
}: ToLinearFontSize) => {
  return text.length <= maxBaseSizeTextLength
    ? baseFontSize
    : baseFontSize - (text.length - maxBaseSizeTextLength) * multiplier;
};

const getValueLength = (value: number) => `${value}`.split('.')[0].length;

const getFormattingPrecision = (
  value: number,
  maxChars: number,
  minPrecision: number,
  maxPrecision: number
) => (getValueLength(value) >= maxChars ? minPrecision : maxPrecision);

const transformToKWH = (value: number) => value / 1000;

export const getPieChartValueProperties = ({ autonomy, selfConsumption }: PieChartSeries) => {
  const consumptionTotalKWH = flow(getAggregatedEnergy, transformToKWH)(autonomy);
  const productionTotalKWH = flow(getAggregatedEnergy, transformToKWH)(selfConsumption);
  const biggerValue =
    getValueLength(consumptionTotalKWH) >= getValueLength(productionTotalKWH)
      ? consumptionTotalKWH
      : productionTotalKWH;
  const precision = getFormattingPrecision(biggerValue, 4, 0, 1);
  const fontSize = toLinearlyScaleDown({
    text: formatNumber({ precision })(biggerValue),
    baseFontSize: 23,
    multiplier: 2.5,
    maxBaseSizeTextLength: 4,
  });

  return {
    fontSize,
    consumption: formatNumber({ precision })(consumptionTotalKWH),
    production: formatNumber({ precision })(productionTotalKWH),
  };
};

export const getSeriesKeyTranslations = () => ({
  [StatisticsSeriesKey.CONSUMED_ENERGY]: I18n.t(
    T.customerSingle.analysis.statistics.pieCharts.legendLabels.independence
  ),
  [StatisticsSeriesKey.GRID_PURCHASE_ENERGY]: I18n.t(
    T.customerSingle.analysis.statistics.pieCharts.legendLabels.gridPurchase
  ),
  [StatisticsSeriesKey.PRODUCED_ENERGY]: I18n.t(
    T.customerSingle.analysis.statistics.pieCharts.legendLabels.selfConsumption
  ),
  [StatisticsSeriesKey.GRID_FEEDIN_ENERGY]: I18n.t(
    T.customerSingle.analysis.statistics.pieCharts.legendLabels.gridFeedIn
  ),
});
