import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import uuid from 'uuid';

import { PartnerSite } from '+app/+customer/+listNew/store/types/partnerSite.interface';
import { PartnerSitesContract } from '+app/+customer/+listNew/store/types/partnerSitesContract.interface';
import { OfflineConnection } from '+shared/basicComponents/Icons';

import { meterInstallationTranslations } from '../../SitesFiltersArea/SitesFiltersArea';
import {
  TooltipOnLockedIcon,
  TooltipOnWarningIcon,
} from '../TooltipsStatusColumn/TooltipsStatusColumn';
import { getContractWarnings, StatusWarningTranslations } from './SitesTableDataCell.helper';

import './SitesTableDataCells.scss';

type PropsStatusCell = {
  data: PartnerSite;
  isSuperUser: boolean;
};

const SitesTableStatusCell: React.FC<PropsStatusCell> = ({ data, isSuperUser }) => {
  const featureFlags = useFlags();
  const { contracts, locked, id } = data;
  const [contractWithMeterInstallation] = contracts.filter(
    (contract: PartnerSitesContract) => contract.meterInstallation
  );

  const contractWarnings: StatusWarningTranslations[] | null = contractWithMeterInstallation
    ? getContractWarnings(
        contractWithMeterInstallation.meterInstallation,
        meterInstallationTranslations,
        featureFlags.customerListContractWarningVisible
      )
    : null;

  return (
    <div className="sites-list__status">
      {locked && <TooltipOnLockedIcon id={uuid()} isSuperUser={isSuperUser} />}

      {/* hidden by https://sonnen2020.atlassian.net/browse/SON-39529 */}
      {false && <OfflineConnection size={20} />}

      {contractWarnings && <TooltipOnWarningIcon id={id} warnings={contractWarnings} />}
    </div>
  );
};

export default SitesTableStatusCell;
