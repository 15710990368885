import { filter, groupBy, isNil, last, mapValues, pipe, sortBy, values } from 'lodash/fp';

import {
  isFlatStatusUpdate,
  isHardwareStatusUpdate,
  isInSetupStatusUpdate,
} from '+shared/store/lead/leadStatus.helpers';
import {
  LeadStatusName,
  UNSUPPORTED_STATUS_NAMES,
} from '+shared/store/lead/types/leadStatus.interface';
import { isNotNil } from '+utils/array.util';

import { LeadStatusGroup, LeadStatusValues } from './LeadCurrentStatus.interface';

const groupByStatusName = groupBy<LeadStatusValues>((status) => {
  // @FUTURE: remove status 'invitation sent' if still not displayed anywhere
  if (isNil(status) || isNil(status.name) || status.name === LeadStatusName.INVITATION_SENT) {
    return LeadStatusGroup.IGNORED;
  }

  if (isFlatStatusUpdate(status)) {
    return LeadStatusGroup.FLAT;
  }

  if (isHardwareStatusUpdate(status)) {
    return LeadStatusGroup.HARDWARE;
  }

  if (isInSetupStatusUpdate(status)) {
    return LeadStatusGroup.IN_SETUP;
  }

  return LeadStatusGroup.COMMON;
});

const filterNotSupportedStatuses = filter<LeadStatusValues>(
  (status) => isNotNil(status) && !UNSUPPORTED_STATUS_NAMES.includes(status.name)
);

const sortByHierarchy = sortBy<NonNullable<LeadStatusValues>>((status) => status.hierarchy);

export const getLatestStatusForEachGroup = pipe(
  values,
  groupByStatusName,
  mapValues(pipe(filterNotSupportedStatuses, sortByHierarchy, last))
);
