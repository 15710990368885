import { LeadImpactAnalysisResponse } from '+shared/store/lead/types';

import { ActionsUnion, createAction } from '../../../utils';
import {
  ConsumptionForm,
  CostsForm,
  PdfCreationForm,
  ProductSetupForm,
} from './+impactAnalysis.interface';

export enum IMPACT_ANALYSIS_ACTIONS {
  SET_CURRENT_STEP = '[+impactAnalysis] SET_CURRENT_STEP',
  SET_CONSUMPTION_FORM = '[+impactAnalysis] SET_CONSUMPTION_FORM',
  SET_PRODUCT_SETUP_FORM = '[+impactAnalysis] SET_PRODUCT_SETUP_FORM',
  SET_COSTS_FORM = '[+impactAnalysis] SET_COSTS_FORM',
  SET_PDF_CREATION = '[+impactAnalysis] SET_PDF_CREATION',
  SET_IMPACT_ANALYSIS = '[+impactAnalysis] SET_IMPACT_ANALYSIS',
  CLEAR_DATA = '[+impactAnalysis] CLEAR_DATA',
  CREATE_IMPACT_ANALYSIS = '[+impactAnalysis] CREATE_IMPACT_ANALYSIS',
  DOWNLOAD_IMPACT_ANALYSIS_FILE = '[+impactAnalysis] DOWNLOAD_IMPACT_ANALYSIS_FILE',
  DELETE_IMPACT_ANALYSIS = '[+impactAnalysis] DELETE_IMPACT_ANALYSIS',
  DELETE_IMPACT_ANALYSIS_SUCCESS = '[+impactAnalysis] DELETE_IMPACT_ANALYSIS_SUCCESS',
  SEND_IMPACT_ANALYSIS = '[+impactAnalysis] SEND_IMPACT_ANALYSIS',
  CLEAR_IMPACT_ANALYSIS_RECOMMENDATION = '[+impactAnalysis] CLEAR_IMPACT_ANALYSIS_RECOMMENDATION',
  CREATE_IMPACT_ANALYSIS_RECOMMENDATION = '[+impactAnalysis] CREATE_IMPACT_ANALYSIS_RECOMMENDATION',
  // eslint-disable-next-line max-len
  CREATE_IMPACT_ANALYSIS_RECOMMENDATION_SUCCESS = '[+impactAnalysis] CREATE_IMPACT_ANALYSIS_RECOMMENDATION_SUCCESS',
}

export const ImpactAnalysisActions = {
  setConsumptionForm: createAction(
    IMPACT_ANALYSIS_ACTIONS.SET_CONSUMPTION_FORM,
    (consumptionForm: ConsumptionForm) => ({ consumptionForm })
  ),
  setProductSetupForm: createAction(
    IMPACT_ANALYSIS_ACTIONS.SET_PRODUCT_SETUP_FORM,
    (productSetupForm: ProductSetupForm) => ({ productSetupForm })
  ),
  setCostsForm: createAction(IMPACT_ANALYSIS_ACTIONS.SET_COSTS_FORM, (costsForm: CostsForm) => ({
    costsForm,
  })),
  setPdfCreationForm: createAction(
    IMPACT_ANALYSIS_ACTIONS.SET_PDF_CREATION,
    (pdfCreationForm: PdfCreationForm) => ({ pdfCreationForm })
  ),
  setImpactAnalysis: createAction(
    IMPACT_ANALYSIS_ACTIONS.SET_IMPACT_ANALYSIS,
    (leadId: string, offerId: string, impactAnalysis: LeadImpactAnalysisResponse) => ({
      leadId,
      offerId,
      impactAnalysis,
    })
  ),
  clearData: createAction(IMPACT_ANALYSIS_ACTIONS.CLEAR_DATA),
  createImpactAnalysis: createAction(
    IMPACT_ANALYSIS_ACTIONS.CREATE_IMPACT_ANALYSIS,
    (leadId: string, offerId: string) => ({ leadId, offerId })
  ),
  downloadImpactAnalysisFile: createAction(
    IMPACT_ANALYSIS_ACTIONS.DOWNLOAD_IMPACT_ANALYSIS_FILE,
    (leadId: string, impactAnalysisId: string) => ({ leadId, impactAnalysisId })
  ),
  deleteImpactAnalysis: createAction(
    IMPACT_ANALYSIS_ACTIONS.DELETE_IMPACT_ANALYSIS,
    (leadId: string, impactAnalysisId: string) => ({ leadId, impactAnalysisId })
  ),
  deleteImpactAnalysisSuccess: createAction(
    IMPACT_ANALYSIS_ACTIONS.DELETE_IMPACT_ANALYSIS_SUCCESS,
    (deletedImpactAnalysisId: string) => ({ deletedImpactAnalysisId })
  ),
  sendImpactAnalysis: createAction(
    IMPACT_ANALYSIS_ACTIONS.SEND_IMPACT_ANALYSIS,
    (leadId: string, offerId: string, impactAnalysisId: string) => ({
      leadId,
      offerId,
      impactAnalysisId,
    })
  ),
  clearImpactAnalysisRecommendation: createAction(
    IMPACT_ANALYSIS_ACTIONS.CLEAR_IMPACT_ANALYSIS_RECOMMENDATION
  ),
  createImpactAnalysisRecommendation: createAction(
    IMPACT_ANALYSIS_ACTIONS.CREATE_IMPACT_ANALYSIS_RECOMMENDATION,
    (leadId: string, offerId: string) => ({ leadId, offerId })
  ),
  createImpactAnalysisRecommendationSuccess: createAction(
    IMPACT_ANALYSIS_ACTIONS.CREATE_IMPACT_ANALYSIS_RECOMMENDATION_SUCCESS,
    (leadId: string, impactAnalysisRecommendation: any) => ({
      leadId,
      impactAnalysisRecommendation,
    })
  ),
};

export type ImpactAnalysisActions = ActionsUnion<typeof ImpactAnalysisActions>;
