import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';

import { CostRow } from '../LeadImpactAnalysisCalculationResults.helper';
import { PageDetailedCalculation20YearsData } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

const { nineteenPercentValueAddedTaxes, zeroPercentValueAddedTaxes } =
  T.lead.impactAnalysis._salessolution_;

const HardwareCostsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <div
    className={`
      c-impact-analysis-results
      c-impact-analysis-results-box-blue
    `}
  >
    <CostRow
      sign={'-'}
      textType={'labelLarge'}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.title)}
      value={data.hardwareCostsAfter20Years.cumulativeMinusDeductibleVat}
    />
    <LineDivider className="c-impact-analysis-results__line-divider" />
    <InvestmentCosts data={data} />
    <OperatingCosts data={data} />
    {/*  // Let's keep this, because we might need it again for standard_taxation use case
    {!isNil(data.hardwareCostsAfter20Years.allowableTaxation) && (
      <CostRow
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.allowableTaxation)}
        value={data.hardwareCostsAfter20Years.allowableTaxation}
        sign={'+'}
      />
    )}
    */}
  </div>
);

const InvestmentCosts: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  const isAnyRowVisible =
    !isNil(data.hardwareCostsAfter20Years.investmentCosts.acquisitionCostsPVSAndBattery) ||
    !isNil(data.hardwareCostsAfter20Years.investmentCosts.installationCostsPVSAndBattery) ||
    !isNil(data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts);
  return (
    <>
      <CostRow
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.investment.title)}
        textType={'labelMedium'}
        value={data.hardwareCostsAfter20Years.investmentCosts.cumulative}
        textHelperLabel={`(${I18n.t(zeroPercentValueAddedTaxes)})`}
        sign={'-'}
      />
      {isAnyRowVisible && (
        <div className="c-impact-analysis-results-background-gray">
          {!isNil(data.hardwareCostsAfter20Years.investmentCosts.acquisitionCostsPVSAndBattery) && (
            <CostRow
              sign={'-'}
              text={I18n.t(
                T.lead.impactAnalysis._salessolution_.results.hardware.investment.acquisition
              )}
              textType={'bodyMedium'}
              value={data.hardwareCostsAfter20Years.investmentCosts.acquisitionCostsPVSAndBattery}
              className="c-impact-analysis-results__row--gray"
            />
          )}
          {!isNil(
            data.hardwareCostsAfter20Years.investmentCosts.installationCostsPVSAndBattery
          ) && (
            <CostRow
              sign={'-'}
              text={I18n.t(
                T.lead.impactAnalysis._salessolution_.results.hardware.investment.installation
              )}
              textType={'bodyMedium'}
              value={data.hardwareCostsAfter20Years.investmentCosts.installationCostsPVSAndBattery}
              className="c-impact-analysis-results__row--gray"
            />
          )}
          {!isNil(data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts) && (
            <CostRow
              sign={'-'}
              text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.investment.other)}
              textType={'bodyMedium'}
              value={data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts}
              className="c-impact-analysis-results__row--gray"
            />
          )}
        </div>
      )}
    </>
  );
};

const OperatingCosts: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  const isAnyRowVisible =
    !isNil(data.hardwareCostsAfter20Years.operatingCosts.maintenanceAndReplacementCosts) ||
    !isNil(data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts);
  return (
    <>
      {!isNil(data.hardwareCostsAfter20Years.operatingCosts.cumulative) && (
        <CostRow
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.operating.title)}
          textType={'labelMedium'}
          textHelperLabel={`(${I18n.t(nineteenPercentValueAddedTaxes)})`}
          value={data.hardwareCostsAfter20Years.operatingCosts.cumulative}
          sign={'-'}
        />
      )}
      {isAnyRowVisible && (
        <div className="c-impact-analysis-results-background-gray">
          {!isNil(data.hardwareCostsAfter20Years.operatingCosts.maintenanceAndReplacementCosts) && (
            <CostRow
              sign={'-'}
              text={I18n.t(
                T.lead.impactAnalysis._salessolution_.results.hardware.operating.maintenance
              )}
              textType={'bodyMedium'}
              value={data.hardwareCostsAfter20Years.operatingCosts.maintenanceAndReplacementCosts}
              className="c-impact-analysis-results__row--gray"
            />
          )}
          {!isNil(data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts) && (
            <CostRow
              sign={'-'}
              text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.operating.other)}
              textType={'bodyMedium'}
              value={data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts}
              className="c-impact-analysis-results__row--gray"
            />
          )}
        </div>
      )}
    </>
  );
};

export default HardwareCostsAfter20Years;
