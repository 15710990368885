import * as React from 'react';

import { DS } from '@sonnen/shared-web';

import { SocialIcon } from './FooterSocial.component';

export const getSocialIcon = (iconName: SocialIcon): React.ReactNode => {
  switch (iconName) {
    case SocialIcon.FACEBOOK:
      return <DS.Icon.Facebook />;
    case SocialIcon.TWITTER:
      return <DS.Icon.Twitter />;
    case SocialIcon.YOUTUBE:
      return <DS.Icon.Youtube />;
    case SocialIcon.LINKED_IN:
      return <DS.Icon.LinkedIn />;
    case SocialIcon.INSTAGRAM:
      return <DS.Icon.Instagram />;
    default:
      return null;
  }
};
