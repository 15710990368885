import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { Reporter } from '+utils/reporter.util';

import './NotFound.component.scss';

interface OwnProps {
  isFullScreen?: boolean;
}

type Props = RouteComponentProps<{}> & OwnProps;

const NotFoundComponent: React.FC<Props> = ({ location, isFullScreen = true }) => {
  React.useEffect(() => {
    Reporter.reportError(
      new Error(I18n.t(T.static._salessolution_.notFound.message, { route: location.pathname }))
    );
  }, []);

  return (
    <div
      className={classNames('c-not-found', {
        'c-not-found--full-screen': isFullScreen,
      })}
    >
      <div className={'c-not-found__icon'}>
        <Icon.Ufo />
      </div>
      <h1 className={'c-not-found__header'}>{I18n.t(T.static.notFound.headline)}</h1>
      <p className={'c-not-found__text'}>{I18n.t(T.static.notFound.message)}</p>
    </div>
  );
};

export const NotFound = withRouter(NotFoundComponent);
