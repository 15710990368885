import { defaultTo } from 'lodash/fp';

import { createReducer } from '+app/utils';
import { LeadStatusName } from '+shared/store/lead/types';
import { queryReducer } from '+shared/store/query';

import { LEAD_LIST_PAGE_ACTIONS, LeadListPageActions } from './+leadList.actions';
import {
  INBOX_LEAD_COLLECTION_GET_QUERY,
  initialState,
  LEAD_COLLECTION_GET_QUERY,
  LeadListPageState,
  SETUP_LEAD_COLLECTION_GET_QUERY,
} from './+leadList.state';

/**
 * For now `hardwareOfferAccepted` status should not be displayed in the UI,
 * which means that in order to preserve filtering/results compatibility
 * we have to query for `hardwareOfferSent` + `hardwareOfferAccepted`
 */
const mapStatusFilters = (statusFilters: LeadStatusName[]): LeadStatusName[] =>
  statusFilters.flatMap((statusName) =>
    statusName === LeadStatusName.HW_OFFER_SENT
      ? [statusName, LeadStatusName.HW_OFFER_ACCEPTED]
      : [statusName]
  );

export const reducer = createReducer<LeadListPageState, LeadListPageActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case LEAD_LIST_PAGE_ACTIONS.SET_LEAD_LIST:
        return {
          ...state,
          collection: action.collection,
          totalResourceCount: defaultTo(state.totalResourceCount)(action.total),
        };
      case LEAD_LIST_PAGE_ACTIONS.SET_LEAD_STATUS_FILTERS:
        return {
          ...state,
          statusFilters: mapStatusFilters(action.statusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.SET_SETUP_LEAD_STATUS_FILTERS:
        return {
          ...state,
          setupStatusFilters: action.statusFilters,
        };
      case LEAD_LIST_PAGE_ACTIONS.SET_LEAD_PREVIOUS_STATUS_FILTERS:
        return {
          ...state,
          previousStatusFilters: mapStatusFilters(action.statusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.SET_SETUP_LEAD_PREVIOUS_STATUS_FILTERS:
        return {
          ...state,
          previousSetupStatusFilters: mapStatusFilters(action.statusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.CLEAR_LEAD_STATUS_FILTERS:
        return {
          ...state,
          statusFilters: mapStatusFilters(initialState.statusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.CLEAR_SETUP_LEAD_STATUS_FILTERS:
        return {
          ...state,
          setupStatusFilters: mapStatusFilters(initialState.setupStatusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.CLEAR_PREVIOUS_LEAD_STATUS_FILTERS:
        return {
          ...state,
          previousStatusFilters: mapStatusFilters(initialState.previousStatusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.CLEAR_PREVIOUS_SETUP_LEAD_STATUS_FILTERS:
        return {
          ...state,
          previousSetupStatusFilters: mapStatusFilters(initialState.previousSetupStatusFilters),
        };
      case LEAD_LIST_PAGE_ACTIONS.SET_INBOX_LEAD_LIST:
        return {
          ...state,
          inboxCollection: action.collection,
          totalInboxResourceCount: defaultTo(state.totalResourceCount)(action.total),
        };
      case LEAD_LIST_PAGE_ACTIONS.SET_SETUP_LEAD_LIST:
        return {
          ...state,
          setupCollection: action.collection,
          totalSetupResourceCount: defaultTo(state.totalResourceCount)(action.total),
        };
      default:
        return queryReducer(state, action, [
          LEAD_COLLECTION_GET_QUERY,
          INBOX_LEAD_COLLECTION_GET_QUERY,
          SETUP_LEAD_COLLECTION_GET_QUERY,
        ]);
    }
  }
);
