import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Modal, ModalLayout } from '@sonnen/shared-web';

import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { getOpenModalId, isModalOpen, LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import '../LeadFlatConfigurationTile.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LeadFlatConfigurationTileTariffModalComponent: React.FC<Props> = ({
  isModalOpen,
  openModalId,
  actions,
}) => {
  const { title, subtitle, smallBusinessRegulationExplanation } =
    T.lead.configuration._salessolution_.preview.modal;
  const { close } = T.general.basicActions;

  const handleCloseModal = () => actions.toggleModal(false, ModalId.SMALL_ENTERPRISE_INFORMATION);

  return (
    <Modal
      isOpen={isModalOpen && openModalId === ModalId.SMALL_ENTERPRISE_INFORMATION}
      onClose={handleCloseModal}
      size={'medium'}
    >
      <div className="c-lead-flat-configuration-tile-modal">
        <ModalLayout
          title={I18n.t(title)}
          subtitle={I18n.t(subtitle)}
          footer={
            <Button
              className={'c-lead-flat-configuration-tile-modal__close-button'}
              onClick={handleCloseModal}
              label={I18n.t(close)}
              type={ButtonType.PRIMARY}
              size={ButtonSize.LARGE}
            />
          }
        >
          <BodyMedium
            className={'c-lead-flat-configuration-tile-modal__text'}
            text={
              <span
                dangerouslySetInnerHTML={{
                  __html: I18n.t(smallBusinessRegulationExplanation),
                }}
              />
            }
          />
        </ModalLayout>
      </div>
    </Modal>
  );
};

const LeadFlatConfigurationTileTariffModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadFlatConfigurationTileTariffModalComponent);

export default LeadFlatConfigurationTileTariffModal;
