import { FeatureProvider } from '@sonnen/shared-web/src/feature-flags/Feature.provider';

import { LDFlagSet } from 'launchdarkly-js-client-sdk';

import { Env } from './environment';

export enum FeatureName {
  CONTRACT_ENERGY_METER_SECTION = 'contractEnergyMeterSection',
  HARDWARE_ONLY_NEW_TILE = 'hardwareOnlyNewTile',
  ROLLOUT = 'rollout',
  BATTERY_CHARGE_LIMIT = 'batteryChargeLimit',
  BATTERY_CELL_DATA = 'batteryCellData',
  BATTERY_CELL_CARE = 'batteryCellCare',
}

FeatureProvider.createFeatureConfiguration({
  [FeatureName.ROLLOUT]: [Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.BATTERY_CHARGE_LIMIT]: [Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.CONTRACT_ENERGY_METER_SECTION]: [],
  [FeatureName.HARDWARE_ONLY_NEW_TILE]: [],
  [FeatureName.BATTERY_CELL_DATA]: [Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.BATTERY_CELL_CARE]: [],
});

export const { featureFlagsProxy: FeatureFlags, bypassedSetter: setFeatureFlags } = (() => {
  const flags: LDFlagSet = {};
  const accessHandler = {
    get: (target: LDFlagSet, name: string) => target[name],
    set: () => {
      console.warn('Feature flags object is read-only.');

      return true;
    },
  };
  const featureFlagsProxy = new Proxy(flags, accessHandler);
  const bypassedSetter = (featureFlags: LDFlagSet | undefined) => {
    if (!featureFlags) {
      return;
    }

    for (const [flagName, flagValue] of Object.entries(featureFlags)) {
      flags[flagName] = flagValue;
    }
  };

  return { featureFlagsProxy, bypassedSetter };
})();
