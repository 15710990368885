import { __extends } from "tslib";
import { Paint, RequiredViewChanges, rgba, Rect, } from '@kanva/core';
import { isNumber } from 'lodash';
import { ChartView } from './chart.view';
import { DataContainerEventType } from '../data-container/data-container.events';
var defaultStyle = {
    paint: new Paint().setFillStyle(rgba('#FFF', 0.5)).setStrokeStyle('#FFF'),
    borders: new Rect({ left: 1, right: 1, top: 1, bottom: 1 }),
};
var ChartZoomView = (function (_super) {
    __extends(ChartZoomView, _super);
    function ChartZoomView(context) {
        var _this = _super.call(this, context, 'ChartZoomView', defaultStyle) || this;
        _this.drawnArea = new Rect({});
        _this.onAreaSelect = function (area) {
            _this.area = area.payload;
            _this.require(RequiredViewChanges.DRAW);
            return area.payload;
        };
        return _this;
    }
    ChartZoomView.prototype.onDataContainerChanged = function (dataContainer, oldDataContainer) {
        if (oldDataContainer) {
            oldDataContainer.removeEventListener(DataContainerEventType.AREA_SELECT, this.onAreaSelect);
        }
        dataContainer.addEventListener(DataContainerEventType.AREA_SELECT, this.onAreaSelect);
    };
    ChartZoomView.prototype.onDestroy = function () {
        if (this.dataContainer) {
            this.dataContainer.removeEventListener(DataContainerEventType.AREA_SELECT, this.onAreaSelect);
        }
    };
    ChartZoomView.prototype.onDraw = function (canvas) {
        if (this.area) {
            this.drawnArea.l = this.area.l;
            this.drawnArea.r = this.area.r;
            this.drawnArea.b = this.innerHeight;
            this.drawnArea.t = 0;
            canvas.drawRect(this.drawnArea, this.style.paint, this.style.borders);
        }
    };
    ChartZoomView.prototype.getCanvasPositionForPoint = function (point) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        var x = xScale(point.x);
        var y = isNumber(point.y) ? yScale(point.y) : 0;
        return {
            x: x,
            y: y,
            absoluteX: this.offsetRect.l + x,
            absoluteY: this.offsetRect.t + y,
        };
    };
    ChartZoomView.prototype.getPointForCanvasPosition = function (position) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        return {
            x: xScale.invert(position.x),
            y: yScale.invert(position.y),
        };
    };
    return ChartZoomView;
}(ChartView));
export { ChartZoomView };
