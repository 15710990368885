import { OfferProductStatus } from '+shared/store/lead/types';
import { Query } from '+shared/store/query';

export const HARDWARE_SAVE_QUERY = 'hardwareSaveQuery';
export const UPDATE_HARDWARE_STATUS_QUERY = 'updateHardwareStatusQuery';

export interface HardwarePageState {
  newHardwareStatus?: OfferProductStatus;
  [HARDWARE_SAVE_QUERY]: Query<any>;
  [UPDATE_HARDWARE_STATUS_QUERY]: Query<any>;
}

export const initialState: HardwarePageState = {
  newHardwareStatus: undefined,
  [HARDWARE_SAVE_QUERY]: {},
  [UPDATE_HARDWARE_STATUS_QUERY]: {},
};
