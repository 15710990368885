import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import { FormInputControlledError } from '+app/shared/components/Form';
import { FormInput } from '+shared/components';
import { PartnerActions } from '+shared/store/partner';
import { QueryState } from '+shared/store/query';

import { FieldNames } from '../../containers/MissingBatteryModal/types';
import { MissingBatteryBanner } from '../MissingBatteryBanner';
import { MissingBatteryModalLayout } from '../MissingBatteryModalLayout';
import {
  MissingBatteryIdentifyFormValues,
  missingBatteryIdentifySchema,
} from './MissingBatteryIdentifyForm.helper';

import './MissingBatteryIdentifyForm.component.scss';

interface Props {
  identifyMissingBattery: typeof PartnerActions.identifyMissigBattery;
  queryState: QueryState;
  serialNumber: string;
  showCaseReporting: () => void;
}

export const MissingBatteryIdentifyForm: React.FC<Props> = ({
  identifyMissingBattery,
  queryState,
  showCaseReporting,
  serialNumber,
}) => {
  const handleSubmit = (values: MissingBatteryIdentifyFormValues) => {
    identifyMissingBattery(values[FieldNames.SERIAL_NUMBER]);
  };

  return (
    <Formik
      initialValues={{
        serialNumber,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={missingBatteryIdentifySchema}
      onSubmit={handleSubmit}
      render={(form) => (
        <Form className={'c-missing-battery-identify-form'}>
          <MissingBatteryModalLayout
            isLoading={queryState.pending}
            canSubmit={!!form.values[FieldNames.SERIAL_NUMBER] && isEmpty(form.errors)}
            buttonLabel={
              queryState.error
                ? I18n.t(T.missingBattery.modal.checkAgainButton)
                : I18n.t(T.missingBattery.modal.nextButton)
            }
          >
            <span className={'c-missing-battery-identify-form__info'}>
              {I18n.t(T.missingBattery.modal.info)}
            </span>
            <FormInputControlledError
              form={form}
              fieldName={FieldNames.SERIAL_NUMBER}
              errorMessage={I18n.t(T.missingBattery.validateSerialNumber.notFound)}
              isError={queryState.error}
            >
              <FormInput
                disabled={queryState.pending}
                form={form}
                name={FieldNames.SERIAL_NUMBER}
                label={I18n.t(T.missingBattery.serialNumber)}
                dataTestId="serial-number-field"
              />
            </FormInputControlledError>
            {queryState.error && !isEmpty(form.errors) && (
              <MissingBatteryBanner
                message={I18n.t(T.missingBattery.banner.serialNumberNotFound)}
                buttonAction={showCaseReporting}
              />
            )}
          </MissingBatteryModalLayout>
        </Form>
      )}
    />
  );
};
