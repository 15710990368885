import * as React from 'react';
import Media from 'react-media';

import { breakpointUp, ClickOutside, Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import { get } from 'lodash/fp';

import { DatePicker } from '+shared/components/DatePicker';
import { DatepickerArrowSide } from '+shared/components/DatePicker/DatepickerArrowSide';
import { useDispatchInputEvent } from '+shared/hooks/useDispatchInputEvent';
import { formatDate } from '+utils/format.util';

import { FormInput } from '../FormInput';

import './FormInputDate.component.scss';

interface Props<T> {
  name: string;
  label?: string;
  form: FormikProps<T>;
  minDate?: Date;
  maxDate?: Date;
  className?: ClassValue;
  id?: string;
  arrowPosition?: DatepickerArrowSide;
  readOnly?: boolean;
  tooltipInfo?: string;
  dataTestId?: string;
}

export const FormInputDate = <T extends any = any>({
  form,
  label,
  name,
  maxDate,
  minDate,
  className,
  id,
  arrowPosition,
  readOnly = false,
  tooltipInfo,
  dataTestId,
}: Props<T>) => {
  const [isDatePickerActive, setDatePickerActive] = React.useState(false);
  const { onChange: onChangeDispatch } = useDispatchInputEvent();

  const onDateClick = (date: Date) => {
    if (minDate && date < minDate) {
      return;
    }
    onDateChange(date);
  };

  const onDateChange = (date: Date) => {
    const dateString = formatDate(date, 'YYYY-MM-DD');
    form.setFieldValue(name, dateString);
    form.setFieldTouched(name);
    onChangeDispatch(name, dateString);
    setDatePickerActive(false);
  };

  const onDatePickerOutsideClick = () => setDatePickerActive(false);

  const onInputDateClick = () => setDatePickerActive(!isDatePickerActive);

  return (
    <Media query={{ minWidth: breakpointUp('SM') }}>
      {(isDesktopMedia: boolean) => (
        <div className={classNames('c-form-input-date', className)}>
          <FormInput
            form={form}
            label={label}
            name={name}
            type={'date'}
            id={id}
            onClick={isDesktopMedia ? onInputDateClick : undefined}
            readOnly={readOnly}
            info={tooltipInfo}
            dataTestId={dataTestId}
          >
            <ClickOutside onClick={onDatePickerOutsideClick}>
              <span className={'c-form-input-date__datepicker-container'}>
                <span
                  className={classNames('c-form-input-date__datepicker-trigger', {
                    'c-form-input-date__datepicker-trigger--disabled': !isDesktopMedia,
                  })}
                  onClick={onInputDateClick}
                >
                  <Icon.Calendar />
                </span>
                {isDatePickerActive && (
                  <div className={'c-form-input-date__datepicker-wrapper'}>
                    <DatePicker
                      isActive={isDatePickerActive}
                      date={new Date(get(name)(form.values))}
                      onDayClick={onDateClick}
                      minDate={minDate}
                      maxDate={maxDate}
                      arrowPosition={arrowPosition}
                    />
                  </div>
                )}
              </span>
            </ClickOutside>
          </FormInput>
        </div>
      )}
    </Media>
  );
};
