import * as Yup from 'yup';

import { ConfigurationForm } from '../types';
import {
  configurationBatteryFormInitial,
  ConfigurationBatterySchema,
} from './configurationBatteryForm.schema';
import {
  configurationConsumptionFormInitial,
  ConfigurationConsumptionSchema,
} from './configurationConsumptionForm.schema';
import {
  configurationProtectFormInitial,
  ConfigurationProtectSchema,
} from './configurationProtectForm.schema';
import { configurationPvFormInitial, ConfigurationPvSchema } from './configurationPvForm.schema';

export const ConfigurationSchema = () =>
  Yup.object()
    .concat(ConfigurationConsumptionSchema())
    .concat(ConfigurationPvSchema())
    .concat(ConfigurationBatterySchema())
    .concat(ConfigurationProtectSchema());

export const configurationFormInitial = (isNewBatteryAllowed: boolean): ConfigurationForm => ({
  ...configurationConsumptionFormInitial,
  ...configurationPvFormInitial,
  ...configurationProtectFormInitial,
  ...configurationBatteryFormInitial(isNewBatteryAllowed),
});
