import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { FormikProps } from 'formik';

import { filterByValue, mapActions, searchByKey } from '+app/utils';
import { DsoRegistrationPvSystemTestIds as testIds } from '+config/testIds';
import {
  DsoRegistrationFormActions,
  getGetPvModulesQueryStatus,
  getGetPvModuleVendorsQueryStatus,
  getPVModules,
  getPvModuleVendorsAllFetched,
} from '+setupTool/+form/store';
import { FormInputSelect, FormSectionParagraph } from '+shared/components';
import { StoreState } from '+shared/store/store.interface';

interface OwnProps<T> {
  form: FormikProps<T>;
  isNew?: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  pvModules: getPVModules(state),
  pvModuleVendorsAllFetched: getPvModuleVendorsAllFetched(state),
  getPvModuleVendorsQueryStatus: getGetPvModuleVendorsQueryStatus(state),
  getPvModulesQueryStatus: getGetPvModulesQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getPVModules: DsoRegistrationFormActions.getPVModules,
  setPvModuleVendorsAllFetched: DsoRegistrationFormActions.setPvModuleVendorsAllFetched,
});

type Props<T> = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps<T>;

type DsoRegistrationPvModuleComponentI<T = any> = React.FC<Props<T>>;

export const DsoRegistrationPvModuleComponent: DsoRegistrationPvModuleComponentI = ({
  form,
  pvModules,
  pvModuleVendorsAllFetched,
  getPvModuleVendorsQueryStatus,
  getPvModulesQueryStatus,
  actions,
  isNew,
}) => {
  const [currentVendorId, setCurrentVendorId] = React.useState(form.values.pv_module_type || '');
  const [currentModuleId, setCurrentModuleId] = React.useState(form.values.pv_module_id || '');

  const currentVendor = pvModules.find((vendor) => vendor.id === currentVendorId);
  const currentModule = currentVendor
    ? currentVendor.modules && currentVendor.modules.find((module) => module.id === currentModuleId)
    : undefined;

  React.useEffect(() => {
    if (form.values.pv_module_type) {
      setCurrentVendorId(form.values.pv_module_type);
    }
    if (currentVendorId && form.values.pv_module_type !== currentVendorId) {
      form.setFieldValue('pv_module_id', '');
      setCurrentVendorId('');
    }
  }, [form.values.pv_module_type]);

  React.useEffect(() => {
    if (pvModuleVendorsAllFetched) {
      const vendor = pvModules.length
        ? pvModules.find((vendor) => vendor.id === currentVendorId)
        : null;
      if (currentVendorId && !!vendor && !vendor.modules) {
        actions.getPVModules(currentVendorId);
      }
    }
  }, [currentVendorId, pvModuleVendorsAllFetched]);

  React.useEffect(() => {
    if (form.values.pv_module_id) {
      setCurrentModuleId(form.values.pv_module_id);
    }
  }, [form.values.pv_module_id]);

  React.useEffect(() => {
    const existingPvSize = form.values.existing_pv_size ? form.values.existing_pv_size : 0;

    if (currentModule && currentModule.peakPowerkWp) {
      if (isNew) {
        form.setFieldValue(
          'new_pv_size',
          form.values.pv_module_count
            ? parseFloat((form.values.pv_module_count * currentModule.peakPowerkWp).toFixed(3))
            : currentModule.peakPowerkWp
        );
      } else {
        form.setFieldValue(
          'total_pv_size',
          form.values.pv_module_count
            ? parseFloat(
                (form.values.pv_module_count * currentModule.peakPowerkWp + existingPvSize).toFixed(
                  3
                )
              )
            : parseFloat((currentModule.peakPowerkWp + existingPvSize).toFixed(3))
        );
      }
    } else {
      form.setFieldValue('new_pv_size', null);
      form.setFieldValue('total_pv_size', form.values.existing_pv_size);
    }
  }, [currentModule, form.values.existing_pv_size, form.values.pv_module_count]);

  return (
    <>
      <FormSectionParagraph>{I18n.t(T.setupTool.form.pvSystem)}</FormSectionParagraph>
      <FormInputSelect
        className={classNames('c-setup-tool-pv-system__item', {
          'c-form-input-select--error': getPvModuleVendorsQueryStatus.error,
        })}
        form={form}
        label={I18n.t(T.setupTool.form.manufacturer)}
        id={'setup-tool-pv-system__pv_module_type'}
        name={'pv_module_type'}
        placeholder={I18n.t(T.setupTool.selectPlaceholder)}
        collection={pvModules.map((v) => v.id)}
        mapper={(key) => searchByKey('id', key, pvModules, 'name')}
        search={(val) => filterByValue(pvModules, 'name')(val).map((item) => item.id)}
        noResultsComponent={getPvModuleVendorsQueryStatus.pending ? <Loader /> : null}
        dataTestId={testIds.pvAnlageManufacturerDropdown}
        dropDownOptionsDataTestId={testIds.pvAnlageManufacturerDropdownOptions}
        {...(getPvModuleVendorsQueryStatus.error
          ? {
              additionalInfoText: (
                <Translate value={T.setupTool.info.noResults.pvModules} dangerousHTML={true} />
              ),
            }
          : {})}
      />

      <FormInputSelect
        className={classNames('c-setup-tool-pv-system__item', {
          'c-form-input-select--error': getPvModulesQueryStatus.error,
        })}
        form={form}
        label={I18n.t(T.setupTool.form.type)}
        id={'setup-tool-pv-system__pv_module_id'}
        name={'pv_module_id'}
        collection={
          currentVendor && currentVendor.modules ? currentVendor.modules!.map((m) => m.id) : []
        }
        placeholder={I18n.t(T.setupTool.selectPlaceholder)}
        mapper={(key) =>
          currentVendor && currentVendor.modules
            ? searchByKey('id', key, currentVendor.modules, 'name')
            : null
        }
        search={(val) =>
          currentVendor && currentVendor.modules
            ? filterByValue(currentVendor.modules, 'name')(val).map((item) => item.id)
            : []
        }
        disableSearchForEmptyCollection={true}
        noResultsComponent={getPvModulesQueryStatus.pending ? <Loader /> : null}
        dataTestId={testIds.pvAnlageTypeDropdown}
        dropDownOptionsDataTestId={testIds.pvAnlageTypeDropdownOption}
        {...(getPvModulesQueryStatus.error
          ? {
              additionalInfoText: (
                <Translate value={T.setupTool.info.noResults.pvModules} dangerousHTML={true} />
              ),
            }
          : {})}
      />
    </>
  );
};

export const DsoRegistrationPvModule = connect(
  mapStateToProps,
  mapDispatchToProps
)(DsoRegistrationPvModuleComponent);
