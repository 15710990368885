import * as React from 'react';

import * as classNames from 'classnames';

import './DsoRegistrationFormRadioGroup.component.scss';

interface Props {
  children: React.ReactNode;
  className?: ClassValue;
}

export const DsoRegistrationFormRadioGroup: React.FC<Props> = ({ className, children }) => (
  <div className={classNames('c-dso-registration-form-radio-group', className)}>{children}</div>
);
