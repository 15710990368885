import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph, DS } from '@sonnen/shared-web';

import { FormikProps, useFormikContext } from 'formik';

import { VppDocumentationTestIds as testIds } from '+config/testIds';
import { getTypeOfGridReferenceMeter } from '+setupTool/+form';
import {
  DsoRegistrationFormContainer,
  DsoRegistrationFormRadioGroup,
} from '+setupTool/+form/components';
import {
  CounterSuspension,
  counterSuspensionCollection,
  FileCategoryName,
} from '+setupTool/+form/store/+form.dictionary';
import { useSubmissionFileUploadManagement } from '+setupTool/+form/store/+form.hooks';
import { DsoRegistrationRadioType } from '+setupTool/+form/store/types';
import { VppCompletionNotification } from '+setupTool/+vppDocumentation/containers';
import {
  getProductionMeterCollection,
  VppDocumentationInterface,
} from '+setupTool/+vppDocumentation/store';
import { isGsmMeasurementsFieldsVisible } from '+setupTool/+vppDocumentation/store/+vppDocumentation.helpers';
import {
  DatepickerArrowSide,
  FormInput,
  FormInputDate,
  FormInputRadioGroup,
  FormInputSelect,
  FormSectionParagraph,
  FormUploadInput,
} from '+shared/components';
import { searchByKey } from '+utils/array.util';

import { VppDocumentationTooltip } from '../VppDocumentationTooltip';

import './VppDocumentationData.component.scss';

interface VppDocumentationDataProps {
  fields: any;
  isProductionMeterFieldVisible: boolean;
  isFasteningTypeConsumptionFieldVisible: boolean;
  isDsoReferenceNumberPvFieldVisible: boolean;
  isCompletionNotificationVisible: boolean;
  isGsmMeasurementsRequiredFieldsVisible: boolean;
  isGsmMeasurementsOptionalFieldsVisible: boolean;
  isMeterPreparationPictureLoading: boolean;
}

export const VppDocumentationData: React.VFC<VppDocumentationDataProps> = ({
  fields,
  isProductionMeterFieldVisible,
  isFasteningTypeConsumptionFieldVisible,
  isDsoReferenceNumberPvFieldVisible,
  isCompletionNotificationVisible,
  isGsmMeasurementsRequiredFieldsVisible,
  isGsmMeasurementsOptionalFieldsVisible,
  isMeterPreparationPictureLoading,
}) => {
  const form = useFormikContext<VppDocumentationInterface>();
  const { fileUploadSettings, vppUploadedDocuments, uploadSubmissionFile, removeSubmissionFile } =
    useSubmissionFileUploadManagement();

  const meterPicturesSettings = fileUploadSettings[FileCategoryName.METER_PICTURES];
  const typeOfGridReferenceMeterCollection = getTypeOfGridReferenceMeter().map((i) => i.value);

  const handleUploadFiles =
    (fileCategoryName: FileCategoryName) =>
    (files: File[]): void => {
      uploadSubmissionFile(files, fileCategoryName);
    };

  const handleDeleteFiles =
    (fileCategoryName: FileCategoryName) =>
    (fileId?: string): void => {
      const document = vppUploadedDocuments[fileCategoryName];

      if (document && fileId) {
        removeSubmissionFile(fileId);
      }
    };

  const isFreeSpaceInTheUpperPartOfMeterCabinetFieldVisible = () =>
    isGsmMeasurementsFieldsVisible(
      isGsmMeasurementsRequiredFieldsVisible,
      isGsmMeasurementsOptionalFieldsVisible
    ) &&
    [CounterSuspension.EHZ, CounterSuspension.ADAPTER].includes(
      form.values.fastening_type_grid as CounterSuspension
    );

  const isFreeSpaceInTheUpperPartOfMeterCabinetError = () =>
    isFreeSpaceInTheUpperPartOfMeterCabinetFieldVisible() &&
    !form.values.free_space_in_the_upper_part_of_the_meter_cabinet;

  return (
    <>
      <FormSectionParagraph className={'c-vpp-documentation__form-header'}>
        {I18n.t(T.setupTool.vppDocumentation.form.header)}
      </FormSectionParagraph>

      {isFreeSpaceInTheUpperPartOfMeterCabinetError() && (
        <div className={'c-vpp-documentation__alert-container'}>
          <DS.Alert
            theme={DS.AlertTheme.DANGER}
            text={I18n.t(T.setupTool.vppDocumentation.form.warning.insufficientSubunitsAvailable)}
          />
        </div>
      )}

      <DsoRegistrationFormContainer>
        {fields.virtual_pv_system ? (
          <>
            <FormInputDate
              className={'c-dso-commissioning-data__item'}
              form={form}
              label={I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
                subsystemName: fields.virtual_pv_system_subsystem_1_name,
              })}
              name={'virtual_pv_system_subsystem_1_commissioning_date'}
              minDate={new Date('1900-01-01')}
              arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
            />
            <FormInputDate
              className={'c-dso-commissioning-data__item'}
              form={form}
              label={I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
                subsystemName: fields.virtual_pv_system_subsystem_2_name,
              })}
              name={'virtual_pv_system_subsystem_2_commissioning_date'}
              minDate={new Date('1900-01-01')}
              arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
            />
          </>
        ) : (
          <FormInputDate
            className={'c-dso-commissioning-data__item'}
            form={form}
            label={I18n.t(T.setupTool.dsoCommissioning.form.pvCommissioningDate)}
            name={'pv_commissioning_date'}
            minDate={new Date('1900-01-01')}
            arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
            dataTestId={testIds.pvCommissioningDateField}
          />
        )}
        <FormInputDate
          className={'c-dso-commissioning-data__item'}
          form={form}
          label={I18n.t(T.setupTool.dsoCommissioning.form.batteryCommissioningDate)}
          name={'battery_commissioning_date'}
          minDate={new Date('1900-01-01')}
          arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
          dataTestId={testIds.batteryCommissioningDateField}
        />
      </DsoRegistrationFormContainer>

      {isProductionMeterFieldVisible && (
        <DsoRegistrationFormContainer>
          <div className={'c-dso-commissioning-data__item'}>
            <FormSectionParagraph
              className={
                'c-form-section-paragraph--without-extra-margin c-form-section-paragraph--smaller'
              }
            >
              {I18n.t(T.setupTool.form.productionMeterQuestion)}
            </FormSectionParagraph>
            <DsoRegistrationFormRadioGroup>
              <FormInputRadioGroup
                form={form}
                name={'production_meter'}
                collection={getProductionMeterCollection()}
              />
            </DsoRegistrationFormRadioGroup>
          </div>

          {/*
            TODO this field shouldn't be sent to API when YES or val should be null/empty string
          */}

          {form.values.production_meter === DsoRegistrationRadioType.YES && (
            <FormInput
              className={'c-dso-commissioning-data__item'}
              form={form}
              label={I18n.t(T.setupTool.form.meterProductionNumber)}
              name={'number_production_meter'}
            />
          )}
        </DsoRegistrationFormContainer>
      )}

      <DsoRegistrationFormContainer>
        <FormInputSelect
          className={'c-setup-tool-measuring-device__item'}
          form={form}
          label={I18n.t(T.setupTool.form.typeOfGridMeterMounting)}
          id={'setup-tool-measuring-device__fastening_type_grid'}
          name={'fastening_type_grid'}
          collection={counterSuspensionCollection}
          placeholder={I18n.t(T.setupTool.selectPlaceholder)}
          dataTestId={testIds.sonnenMeterGRIDDropdown}
          dropDownOptionsDataTestId={testIds.sonnenMeterGRIDDropdownOptions}
        />

        <div className={'c-dso-commissioning-data__item'}>
          {isDsoReferenceNumberPvFieldVisible && (
            <FormInput
              form={form}
              label={I18n.t(T.setupTool.form.dsoReferenceNumberPv)}
              info={I18n.t(T.setupTool.form.dsoReferenceNumberPvTooltip)}
              name={'dso_reference_number_pv'}
            />
          )}
        </div>

        {isFreeSpaceInTheUpperPartOfMeterCabinetFieldVisible() && (
          <>
            <div className={'c-vpp-documentation__form-radio-group--with-info-icon'}>
              <FormSectionParagraph
                className={
                  'c-form-section-paragraph--without-extra-margin c-form-section-paragraph--smaller'
                }
              >
                {I18n.t(T.setupTool.form.freeSpaceInTheUpperPartOfMeterCabinet)}
              </FormSectionParagraph>
              <VppDocumentationTooltip
                tooltipMessage={I18n.t(
                  T.setupTool.form.freeSpaceInTheUpperPartOfMeterCabinetTooltip
                )}
              />
            </div>
            <DsoRegistrationFormRadioGroup>
              <FormInputRadioGroup
                form={form}
                name={'free_space_in_the_upper_part_of_the_meter_cabinet'}
                collection={[
                  {
                    label: I18n.t(T.setupTool.common.yes),
                    value: true,
                  },
                  {
                    label: I18n.t(T.setupTool.common.no),
                    value: false,
                  },
                ]}
              />
            </DsoRegistrationFormRadioGroup>
          </>
        )}
      </DsoRegistrationFormContainer>

      <DsoRegistrationFormContainer>
        {isFasteningTypeConsumptionFieldVisible && (
          <FormInputSelect
            className={'c-setup-tool-measuring-device__item'}
            form={form}
            label={I18n.t(T.setupTool.form.typeOfConsumptionMeterMounting)}
            id={'setup-tool-measuring-device__fastening_type_consumption'}
            name={'fastening_type_consumption'}
            collection={counterSuspensionCollection}
            placeholder={I18n.t(T.setupTool.selectPlaceholder)}
            dataTestId={testIds.sonnenMeterCONSUMPTIONDropdown}
            dropDownOptionsDataTestId={testIds.sonnenMeterCONSUMPTIONDropdownOptions}
          />
        )}
      </DsoRegistrationFormContainer>

      <DsoRegistrationFormContainer>
        <FormInput
          className={'c-setup-tool-measuring-device__item'}
          form={form}
          label={I18n.t(T.setupTool.form.meterNumber)}
          name={'meter_number'}
          dataTestId={testIds.meterNumberField}
        />

        <FormInputSelect
          className={'c-setup-tool-measuring-device__item'}
          form={form}
          label={I18n.t(T.setupTool.form.typeOfGridReferenceMeter)}
          collection={typeOfGridReferenceMeterCollection}
          id={'setup-tool-measuring-device__type_of_grid_reference_meter'}
          name={'type_of_grid_reference_meter'}
          mapper={(key) => searchByKey('value', key, getTypeOfGridReferenceMeter(), 'name')}
          placeholder={I18n.t(T.setupTool.selectPlaceholder)}
          dataTestId={testIds.typeOfGridReferenceMeterDropdown}
          dropDownOptionsDataTestId={testIds.typeOfGridReferenceMeterDropdownOptions}
        />
      </DsoRegistrationFormContainer>

      <DsoRegistrationFormContainer className={'c-dso-registration-container--with-tooltip'}>
        <>
          <BoldParagraph
            className={
              'c-setup-tool__upload-input-label c-setup-tool__upload-input-label--picture-format'
            }
          >
            {I18n.t(T.setupTool.form.meterPreparationPictures)}
          </BoldParagraph>
          <VppDocumentationTooltip
            tooltipMessage={I18n.t(T.setupTool.form.meterPreparationPicturesTooltip)}
          />
          <FormUploadInput
            form={form}
            name={FileCategoryName.METER_PICTURES}
            maxNumFiles={meterPicturesSettings.maxUploadCount}
            accept={meterPicturesSettings.mimeType}
            maxSize={meterPicturesSettings.maxFileSize}
            maxSizeLabel={meterPicturesSettings.maxFileSizeLabel}
            label={I18n.t(T.setupTool.info.chooseFile)}
            placeholder={I18n.t(T.setupTool.info.fileUpTo, {
              extension: meterPicturesSettings.extensions,
              maxFileSize: meterPicturesSettings.maxFileSizeLabel,
            })}
            isLoading={isMeterPreparationPictureLoading}
            isImmediatelySentToBackend={true}
            showErrorOnlyIfTouched={true}
            onUpload={handleUploadFiles(FileCategoryName.METER_PICTURES)}
            onReset={handleDeleteFiles(FileCategoryName.METER_PICTURES)}
            dataTestId={testIds.meterPicturesUploadButton}
          />

          {isCompletionNotificationVisible && (
            <VppCompletionNotification form={form as FormikProps<unknown>} />
          )}
        </>
      </DsoRegistrationFormContainer>
    </>
  );
};
