import React, { useLayoutEffect, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { LabelSmallCaps } from '+shared/basicComponents/Typography/Labels';
import DropdownWrapper from '+shared/containers/DropdownWrapper/DropdownWrapper';

import { SelectableMeterInstallationStatus, Translations } from '../SitesFiltersArea';

import './FilterDropdownMeterInstallationStatuses.scss';

const { meterInstallationTitle, filtersSelected } = T.customers.filter.dropdownMenu;

type Props = {
  applyMeterInstallationStatusFilter: (id: string) => void;
  isMeterPrepDropdownOpen: boolean;
  toggleDropdown: (isOpen?: boolean) => void;
  meterPreparationStatusesState: SelectableMeterInstallationStatus;
  fstCheckSuccessfulState: SelectableMeterInstallationStatus;
  meterInstallationTranslations: Translations;
  appliedMeterInstallationCount: number;
};

const FilterDropdownMeterInstallationStatuses: React.FC<Props> = ({
  applyMeterInstallationStatusFilter,
  isMeterPrepDropdownOpen,
  toggleDropdown,
  meterPreparationStatusesState,
  fstCheckSuccessfulState,
  meterInstallationTranslations,
  appliedMeterInstallationCount,
}) => {
  const [width, setWidth] = useState(0);
  const listRef = useRef<any>([]);

  const selected = `(${appliedMeterInstallationCount} ${I18n.t(filtersSelected)})`;
  const titleWithSelectionCount =
    I18n.t(meterInstallationTitle) + ` ${appliedMeterInstallationCount > 0 ? selected : ''}`;

  useLayoutEffect(() => {
    // This takes the maximum width of the labels in the dropdown before first component paint,
    // such that the width of the dropdown is adjusted by how long the translation for the label is.
    const labelWidths = listRef.current.map((label: any) => label.offsetWidth);
    const maxWidth = Math.max(...labelWidths);
    setWidth(maxWidth + 28);
  }, []);

  return (
    <DropdownWrapper
      label={titleWithSelectionCount}
      width={width}
      isDropdownOpen={isMeterPrepDropdownOpen}
      toggleDropdown={toggleDropdown}
      dataTestId="meter-preparation-status-dropdown"
    >
      <ul
        className="reusable-class__dropdown-content meter-preparation-status"
        style={{ width }}
        data-testid="meter-preparation-status-dropdown-options"
      >
        {Object.keys(meterPreparationStatusesState).map((status: string) => (
          <li key={status} className="meter-preparation-status__filter-item">
            <button
              onClick={() => applyMeterInstallationStatusFilter(status)}
              ref={(element) => listRef.current.push(element)}
              data-testid={`${status}-status-option`}
            >
              <LabelSmallCaps
                text={I18n.t(meterInstallationTranslations[status].title)}
                className={`meter-preparation-status__filter-item--label ${
                  meterPreparationStatusesState[status] ? 'selected' : ''
                }`}
              />
            </button>
          </li>
        ))}

        <div className="line-divider">
          <LineDivider />
        </div>

        {Object.keys(fstCheckSuccessfulState).map((status: string) => (
          <li key={status} className="meter-preparation-status__filter-item">
            <button
              onClick={() => applyMeterInstallationStatusFilter(status)}
              ref={(element) => listRef.current.push(element)}
              data-testid={`fstCheckSuccessful-status-option`}
            >
              <LabelSmallCaps
                text={I18n.t(meterInstallationTranslations[status].title)}
                className={`meter-preparation-status__filter-item--label ${
                  fstCheckSuccessfulState[status] ? 'selected' : ''
                }`}
              />
            </button>
          </li>
        ))}
      </ul>
    </DropdownWrapper>
  );
};

export default FilterDropdownMeterInstallationStatuses;
