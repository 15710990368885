import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import { PATHS } from '+app/router';
import { TestId } from '+config/testIds';
import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { Lead } from '+shared/store/lead/types';

import './LeadOverviewHeaderWrapper.component.scss';

interface Props {
  lead: Lead;
  breadcrumbAction: () => void;
  leadStage?: string;
  children: React.ReactNode;
}

const navLinkProps = {
  border: false,
  type: 'desktop' as NavLinkType,
};

export const LeadOverviewHeaderWrapper: React.FC<Props> = ({
  lead,
  leadStage,
  breadcrumbAction,
  children,
}) => {
  return (
    <>
      <PageHeader
        tabNav={
          <TabNav
            items={
              <>
                <NavLink
                  {...navLinkProps}
                  action={PATHS.LEAD_OVERVIEW({ leadId: lead.id }, leadStage)}
                  dataTestId={'lead-details-tab'}
                >
                  <Icon.Account className={'c-lead-overview-nav-icon'} />
                  {I18n.t(T.lead.overview._salessolution_.header.leadData)}
                </NavLink>
                <NavLink
                  {...navLinkProps}
                  action={PATHS.LEAD_CONFIGURATION({ leadId: lead.id }, leadStage)}
                  dataTestId={TestId.Lead.SalesConfigurator.Tab}
                >
                  <Icon.Calculation className={'c-lead-overview-nav-icon'} />
                  {I18n.t(T.lead.overview._salessolution_.header.salesConfiguration)}
                </NavLink>
                <NavLink
                  {...navLinkProps}
                  action={PATHS.SETUP_TOOL({ leadId: lead.id }, leadStage)}
                  dataTestId={TestId.Lead.SetupConfigurator.Tab}
                >
                  <Icon.SetupTools className={'c-lead-overview-nav-icon'} />
                  {I18n.t(T.setupTool.setupConfiguration)}
                </NavLink>
              </>
            }
          />
        }
        breadcrumb={
          <Button
            onClick={breadcrumbAction}
            size={ButtonSize.SMALL}
            type={ButtonType.TERTIARY}
            className={'c-lead-header__breadcrumb'}
            dataTestId={'go-back-btn'}
            label={
              <>
                <Icon.Angle className={'c-lead-header__breadcrumb--icon'} />
                {I18n.t(T.customerSingle.header.breadcrumb)}
              </>
            }
          />
        }
        isExpanded={true}
      >
        {children}
      </PageHeader>
    </>
  );
};
