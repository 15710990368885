import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { get, isEmpty, isEqual, isNil } from 'lodash';
import { has } from 'lodash/fp';

import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { mapActions } from '+app/utils';
import { getLastUpdatedLeadSection, getOfferList } from '+lead/+overview/store/+overview.selectors';
import { LeadPageActions } from '+lead/store/+lead.actions';
import {
  hasFlatOfferAcceptedStatus,
  hasFlatOfferSentStatus,
  isLeadSonnenNowInterested,
} from '+lead/store/+lead.helper';
import {
  getLeadData,
  getLeadUpdateQueryStatus,
  getUserPermissions,
} from '+lead/store/+lead.selectors';
import { FormInput, FormInputSelect } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { validateField } from '+shared/components/Form/Form.helpers';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { LeadPatchDto, LeadStatusName } from '+shared/store/lead/types';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { isStatusSet } from '+utils/status.util';

import { LeadOverviewEditWarning } from '../../components/LeadOverviewEditWarning';

import './LeadOverviewEditForm.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLeadData(state),
  offers: getOfferList(state),
  userPermissions: getUserPermissions(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  lastUpdatedLeadSection: getLastUpdatedLeadSection(state),
});

const mapDispatchToProps = mapActions({
  updateLead: LeadPageActions.updateLead,
  clearQuery: QueryActions.init,
  toggleModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeadOverviewEditFormProps;

interface LeadOverviewEditFormProps {
  list: Array<{
    id: string;
    label: string;
    value: React.ReactNode;
    validationSchema?: object;
    editableFields?: Partial<LeadPatchDto>;
    options?: {
      [K in keyof LeadPatchDto & string]?: {
        values: any[];
        translation: any;
      };
    };
    warning?: {
      configurationMade?: string;
      offerSent: string;
    };
  }>;
  index: number;
  onClose: () => void;
  customSubmitAction?: (values: Partial<LeadPatchDto>) => void;
}

const mapPlaceholder = (fieldName: string) => {
  switch (fieldName) {
    case 'firstName':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.firstName);
    case 'lastName':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.lastName);
    case 'street':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.street);
    case 'zipCode':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCode);
    case 'city':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.city);
    case 'email':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.email);
    case 'phone':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.phone);
    case 'mobilePhone':
      return I18n.t(T.lead.boc._salessolution_.form.personalDetails.mobilePhone);
    default:
      return '';
  }
};

interface LeadEditableFields {
  id: string;
  fields: Partial<LeadPatchDto> | undefined;
}

const LeadOverviewEditFormComponent: React.FC<Props> = ({
  actions,
  lead,
  offers,
  onClose,
  customSubmitAction,
  list,
  index,
  userPermissions,
  leadUpdateQueryStatus,
  lastUpdatedLeadSection,
}) => {
  const [warningVisible, setWarningVisible] = React.useState(false);
  const filteredList = list.filter((i) => !isNil(i.value));
  const editableFields: LeadEditableFields = {
    id: filteredList[index].id,
    fields: filteredList[index].editableFields,
  };
  const fields = filteredList[index].editableFields;
  const fieldsOptions = filteredList[index].options;
  const schema = filteredList[index].validationSchema!;
  const warningMessage = filteredList[index].warning;
  const canPartnerEditPersonalData = get(userPermissions, 'leaeditPersonalData', true);
  const partnerCannotEditDataMsg = I18n.t(
    T.lead.overview._salessolution_.editForm.noPermissionToEditValidationError
  );

  React.useEffect(() => {
    actions.clearQuery(LEAD_UPDATE_QUERY);
  }, []);

  React.useEffect(() => {
    if (!warningMessage || hasFlatOfferAcceptedStatus(lead)) {
      return;
    }
    if (hasFlatOfferSentStatus(lead)) {
      setWarningVisible(true);
    } else if (!isEmpty(offers) && warningMessage.configurationMade) {
      setWarningVisible(true);
    }
  }, [lead, offers, warningMessage]);

  React.useEffect(() => {
    if (
      has('email', lastUpdatedLeadSection) &&
      leadUpdateQueryStatus.success &&
      lead &&
      isStatusSet(lead.status.summary[LeadStatusName.INVITATION_SENT]) &&
      !isLeadSonnenNowInterested(lead)
    ) {
      actions.toggleModal(true, ModalId.EMAIL_CHANGED);
      actions.clearQuery(LEAD_UPDATE_QUERY);
    }
  }, [leadUpdateQueryStatus]);

  const onSubmit = (values: Partial<LeadPatchDto>) => {
    if (!isEqual(fields, values)) {
      customSubmitAction ? customSubmitAction(values) : actions.updateLead(values);
    } else {
      onClose();
    }
  };

  const onCancel = () => {
    onClose();
  };

  const renderWarning = () => {
    if (!warningMessage || hasFlatOfferAcceptedStatus(lead)) {
      return;
    }
    if (hasFlatOfferSentStatus(lead)) {
      return <LeadOverviewEditWarning message={warningMessage.offerSent} />;
    }
    if (!isEmpty(offers) && warningMessage.configurationMade) {
      return <LeadOverviewEditWarning message={warningMessage.configurationMade} />;
    }
    return;
  };

  const renderNoPermissionsError = () => (
    <div className={'c-overview-edit-error'}>{partnerCannotEditDataMsg}</div>
  );

  const renderEditFormBody = (
    fieldId: string,
    editableFields: Partial<LeadPatchDto>,
    form: FormikProps<Partial<LeadPatchDto>>,
    canPartnerEditPersonalData: boolean,
    parent: string = ''
  ): React.ReactNode => {
    return Object.keys(editableFields).map((name, index) => {
      if (typeof editableFields[name] === 'object' && !isNil(editableFields[name])) {
        return renderEditFormBody(
          fieldId,
          editableFields[name],
          form,
          canPartnerEditPersonalData,
          name
        );
      } else {
        const fieldName = parent ? `${parent}.${name}` : name;
        const { hasError } = validateField({ name: fieldName, form });

        if (fieldsOptions && fieldsOptions[name]) {
          return (
            <FormInputSelect
              key={name}
              form={form}
              name={fieldName}
              collection={fieldsOptions[name].values}
              mapper={(key) => I18n.t(fieldsOptions[name].translation[key.toLowerCase()])}
              dataTestId={`lead-edit-${fieldId}-${name}-dropdown`}
              dropDownOptionsDataTestId={`lead-edit-${name}-options`}
            />
          );
        }

        return (
          <FormInput
            key={name}
            form={form}
            disabled={!canPartnerEditPersonalData}
            name={fieldName}
            type={'text'}
            className={classNames('c-overview-edit-form__input', {
              'c-overview-edit-form__input--error': hasError,
            })}
            placeholder={mapPlaceholder(name)}
            dataTestId={`lead-edit-${fieldId}-${name}-field`}
          />
        );
      }
    });
  };

  return (
    <>
      {editableFields.fields && (
        <Formik
          initialValues={editableFields.fields}
          validationSchema={schema}
          onSubmit={onSubmit}
          render={(form) => (
            <form
              onSubmit={form.handleSubmit}
              className={classNames('c-overview-edit-form', {
                'c-overview-edit-form--warning': warningVisible,
              })}
            >
              <div className={'c-overview-edit-form__body'}>
                {renderEditFormBody(
                  editableFields.id,
                  editableFields.fields!,
                  form,
                  canPartnerEditPersonalData
                )}
              </div>
              <div className={'c-overview-edit-form__buttons'}>
                <Button
                  className={'c-overview-edit-form__button-cancel'}
                  onClick={onCancel}
                  label={I18n.t(T.lead.overview._salessolution_.editForm.discard)}
                  size={ButtonSize.SMALL}
                  type={ButtonType.TERTIARY}
                  dataTestId={`lead-edit-${editableFields.id}-discard-btn`}
                />
                <Button
                  label={I18n.t(T.lead.overview._salessolution_.editForm.save)}
                  type={ButtonType.SECONDARY}
                  size={ButtonSize.SMALL}
                  mainType={MainType.SUBMIT}
                  className={'c-overview-edit-form__button-submit'}
                  disabled={!isEmpty(form.errors) || !canPartnerEditPersonalData}
                  status={
                    !isEmpty(form.errors) || !canPartnerEditPersonalData
                      ? ButtonStatus.DISABLED
                      : ButtonStatus.ENABLED
                  }
                  dataTestId={`lead-edit-${editableFields.id}-save-btn`}
                />
              </div>
              {renderWarning()}
              {!canPartnerEditPersonalData && renderNoPermissionsError()}
            </form>
          )}
        />
      )}
    </>
  );
};

export const LeadOverviewEditForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadOverviewEditFormComponent);
