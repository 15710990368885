import { Case } from '+app/shared/store/case';

import { Query } from '../query';
import { CaseData } from './types/case.interface';
import { CaseSite } from './types/caseSite.interface';
import { CreatedCase, CreatedCaseAttributes } from './types/createCaseResponse.interface';

export const CREATE_CASE_QUERY = 'createCaseQuery';
export const CASE_COLLECTION_GET_QUERY = 'caseCollectionGetQuery';

export interface CaseState {
  createdCase?: CreatedCaseAttributes;
  activeSite?: CaseSite;
  collection: Case[];
  totalCount: number;
  [CREATE_CASE_QUERY]: Query<CreatedCase>;
  [CASE_COLLECTION_GET_QUERY]: Query<CaseData>;
}

export const initialState: CaseState = {
  createdCase: undefined,
  activeSite: undefined,
  collection: [],
  totalCount: 0,
  [CREATE_CASE_QUERY]: {},
  [CASE_COLLECTION_GET_QUERY]: {},
};
