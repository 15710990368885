import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { ListTable, ListTableHeader, ListTableHeaderItem } from '+shared/components';
import { Case } from '+shared/store/case';

import { CaseListTableItem } from '../CaseListTableItem';
import { CaseListTableNoResults } from '../CaseListTableNoResults';

import './CaseListTable.component.scss';

interface Props {
  items: Case[];
  isPending: boolean;
}

const getTableHeaders = (): ListTableHeaderItem[] => [
  { label: I18n.t(T.cases.list.header.id) },
  { label: I18n.t(T.cases.list.header.status) },
  { label: I18n.t(T.cases.list.header.contactPerson) },
  { label: I18n.t(T.cases.list.header.subject) },
  { label: I18n.t(T.cases.list.header.createdAt) },
  { label: I18n.t(T.cases.list.header.preview) },
];

export const CaseListTable: React.FC<Props> = ({ items, isPending }) => (
  <div className={'c-case-list-table'}>
    <ListTable
      headers={getTableHeaders()}
      items={items}
      isPending={isPending}
      noBorder={true}
      renderHeader={(header) => <ListTableHeader item={header} />}
      renderItem={(item, index) => <CaseListTableItem item={item} rowIndex={index} />}
      renderNoResults={() => <CaseListTableNoResults />}
    />
  </div>
);
