import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';

import { CustomerRouteParams } from '+router/routes';
import { LabelLarge } from '+shared/basicComponents/Typography/Labels';
import { CaseActions } from '+shared/store/case';
import { CustomerSortKey } from '+shared/store/customer/types/customerSort.type';
import { LayoutActions } from '+shared/store/layout';

import { getPrimaryBattery } from '../../../store/siteList.helper';
import { PartnerSite } from '../../../store/types/partnerSite.interface';
import { PartnerSitesBattery } from '../../../store/types/partnerSitesBattery.interface';
import { SortingOrder } from '../TabSites';
import {
  SitesListBattery,
  SitesListCases,
  SitesListCommissioningDate,
  SitesListContract,
  SitesListCustomerName,
  SitesListLocation,
  SitesListSerialNumber,
} from './SitesTableDataCell/SitesTableDataCells';
import SitesTableStatusCell from './SitesTableDataCell/SitesTableStatusCell';
import SitesTableHeaderCell from './SitesTableHeaderCell/SitesTableHeaderCell';

import './SitesTable.scss';

type Props = {
  siteCollection: PartnerSite[];
  searchValue: null | string;
  isSuperUser: boolean;
  getSortOrderForColumn: (column: CustomerSortKey) => SortingOrder;
  handleSort: (column: CustomerSortKey, order: 'ascending' | 'descending') => void;
  pushCustomerPage: (params: CustomerRouteParams) => void;
  toggleModal: typeof LayoutActions.toggleModal;
  setCaseReportActiveSite: typeof CaseActions.setCaseReportActiveSite;
};

const SitesTable: React.FC<Props> = ({
  siteCollection,
  searchValue,
  isSuperUser,
  getSortOrderForColumn,
  handleSort,
  pushCustomerPage,
  toggleModal,
  setCaseReportActiveSite,
}) => {
  const featureFlags = useFlags();
  const resultsCount = siteCollection.length;
  const columns = createColumns(
    getSortOrderForColumn,
    handleSort,
    searchValue,
    toggleModal,
    setCaseReportActiveSite,
    featureFlags.customerListContractColumnVisible,
    isSuperUser,
    resultsCount
  );
  return (
    <div className="table-wrapper">
      <table className="basic-table">
        <thead>
          <tr className="basic-table__header-row">
            {columns.map((column) => (
              <th
                key={column.name}
                className={classNames('basic-table__header-cell', column.headCellClassName)}
              >
                {column.headCellGenerator()}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {siteCollection.map((rowData) => {
            const { id, locked, customer } = rowData;
            const isSiteLocked = Boolean(locked && !isSuperUser);

            return (
              <tr
                key={id}
                className={classNames(
                  'basic-table__data-row',
                  isSiteLocked && 'basic-table__data-row--locked'
                )}
                onClick={
                  isSiteLocked
                    ? undefined
                    : () => pushCustomerPage({ customerId: customer.id, siteId: id })
                }
              >
                {columns.map((column) => (
                  <td
                    key={column.id(rowData)}
                    className={classNames('basic-table__data-cell', column.dataCellClassName)}
                  >
                    {column.dataCellGenerator(rowData)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SitesTable;

function createColumns(
  getSortOrderForColumn: (column: CustomerSortKey) => SortingOrder,
  handleSort: (column: CustomerSortKey, order: 'ascending' | 'descending') => void,
  searchValue: string | null,
  toggleModal: typeof LayoutActions.toggleModal,
  setCaseReportActiveSite: typeof CaseActions.setCaseReportActiveSite,
  contractColumnVisible: boolean,
  isSuperUser: boolean,
  resultsCount: number
): Array<ColumnGenerator<PartnerSite>> {
  const columns: ColumnGenerator<PartnerSite>[] = [];
  const columnsI18n = T.customers.table.columns;
  const searchByCustomerName =
    searchValue !== '' && !isNil(searchValue) && isNaN(Number(searchValue));
  const disabledSorting = resultsCount < 2 || searchByCustomerName;

  // @todo SON-35890 Remove selection box for MVP, can be added later
  /*columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Selection-${rowData.id}`,
      name: 'Selection',
      headCellClassName: 'customer-list__selection',
      headCellGenerator: () => <input type="checkbox" className="customer-list__selection" />,
      dataCellClassName: 'customer-list__selection',
      dataCellGenerator: (rowData: PartnerSite) => <SitesListSelection />,
    })
  );*/

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Customer-${rowData.id}`,
      name: I18n.t(columnsI18n.customer.name),
      headCellClassName: 'sites-list__name',
      headCellGenerator: () => (
        <SitesTableHeaderCell
          name={I18n.t(columnsI18n.customer.name)}
          order={getSortOrderForColumn(CustomerSortKey.LAST_NAME)}
          onOrderChange={(newOrder) => handleSort(CustomerSortKey.LAST_NAME, newOrder)}
          disabledSorting={disabledSorting}
        />
      ),
      dataCellClassName: 'sites-list__name',
      dataCellGenerator: (rowData: PartnerSite) => (
        // IMPORTANT to use customer.name here because for customers which are companies,
        // firsName and lastName attributes are null.
        <SitesListCustomerName name={rowData.customer.name} searchValue={searchValue} />
      ),
    })
  );

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Serial-${rowData.id}`,
      name: I18n.t(columnsI18n.serialNumber.name),
      dataCellGenerator: (rowData: PartnerSite) => (
        <SitesListSerialNumber
          serial={`${rowData.batteries[0]?.serialNumber}`}
          searchValue={searchValue}
        />
      ),
    })
  );

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `sonnenBatterie-${rowData.id}`,
      name: I18n.t(columnsI18n.sonnenBattery.name),
      dataCellGenerator: (rowData: PartnerSite) => {
        const battery: PartnerSitesBattery | null = getPrimaryBattery(rowData);
        if (!battery) return <>{'-'}</>;
        return <SitesListBattery battery={battery} />;
      },
    })
  );

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Location-${rowData.id}`,
      name: I18n.t(columnsI18n.location.name),
      dataCellGenerator: (rowData: PartnerSite) => (
        <SitesListLocation location={`${rowData.street}, ${rowData.postalCode} ${rowData.city}`} />
      ),
    })
  );

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Commissioning-${rowData.id}`,
      name: I18n.t(columnsI18n.commissioningDate.name),
      sortBy: 'commissioning',
      headCellGenerator: () => (
        <SitesTableHeaderCell
          name={I18n.t(columnsI18n.commissioningDate.name)}
          order={getSortOrderForColumn(CustomerSortKey.INSTALLATION_DATE)}
          clickOrder={'desc->asc'}
          onOrderChange={(newOrder) => handleSort(CustomerSortKey.INSTALLATION_DATE, newOrder)}
          disabledSorting={disabledSorting}
        />
      ),
      dataCellGenerator: (rowData: PartnerSite) => (
        <SitesListCommissioningDate
          commissioningDate={getPrimaryBattery(rowData)?.installationDate ?? undefined}
        />
      ),
    })
  );

  if (contractColumnVisible) {
    columns.push(
      createColumn({
        id: (rowData: PartnerSite) => `Contract-${rowData.id}`,
        name: I18n.t(columnsI18n.contract.name),
        dataCellGenerator: (rowData: PartnerSite) => <SitesListContract site={rowData} />,
      })
    );
  }

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Status-${rowData.id}`,
      name: I18n.t(columnsI18n.status.name),
      dataCellGenerator: (rowData: PartnerSite) => (
        <SitesTableStatusCell data={rowData} isSuperUser={isSuperUser} />
      ),
    })
  );

  columns.push(
    createColumn({
      id: (rowData: PartnerSite) => `Cases-${rowData.id}`,
      name: I18n.t(columnsI18n.actions.name),
      dataCellGenerator: (rowData: PartnerSite) => {
        return (
          <SitesListCases
            site={rowData}
            toggleModal={toggleModal}
            setCaseReportActiveSite={setCaseReportActiveSite}
          />
        );
      },
    })
  );

  return columns;
}

function createColumn(options: {
  id: (rowData: PartnerSite) => string;
  name: string;
  sortBy?: string;
  headCellClassName?: string;
  headCellGenerator?: () => JSX.Element;
  dataCellClassName?: string;
  dataCellGenerator: (rowData: PartnerSite) => JSX.Element;
}): ColumnGenerator<PartnerSite> {
  return {
    id: options.id,
    name: options.name,
    headCellClassName: options.headCellClassName,
    headCellGenerator: options.headCellGenerator
      ? options.headCellGenerator
      : () => <LabelLarge text={options.name} />,
    dataCellClassName: options.dataCellClassName,
    dataCellGenerator: options.dataCellGenerator,
  };
}

export type ColumnGenerator<RowDataType> = {
  id: (rowData: RowDataType) => string;
  name: string;
  sortBy?: string;
  headCellClassName?: string;
  headCellGenerator: () => JSX.Element;
  dataCellClassName?: string;
  dataCellGenerator: (rowData: RowDataType) => JSX.Element;
};
