import React, { useState } from 'react';

import { Arrow } from '+shared/basicComponents/Icons';

import './ExpandingInfo.scss';

type ExpandingInfoProps = {
  label: React.ReactNode | string;
  children: React.ReactNode;
};

const ExpandingInfo = ({ label, children }: ExpandingInfoProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="expanding-info">
      <button onClick={() => setOpen(!open)} className="expanding-info__button">
        <div className="expanding-info__button--label">{label}</div>
        <div className={`arrow-button ${open ? 'is-rotated' : ''}`}>
          <Arrow className="arrow-svg" />
        </div>
      </button>
      {open && <>{children}</>}
    </div>
  );
};

export default ExpandingInfo;
