import * as React from 'react';

import CustomerWithFlatDirect from '+assets/img/customerWithFlatDirect.svg';
import CustomerWithFlatX from '+assets/img/customerWithFlatX.svg';
import { LeadProductType } from '+shared/store/lead/types';

import './LeadConfigurationPreviewInitial.component.scss';

export const LeadConfigurationPreviewInitial: React.FC<{ productType: LeadProductType }> = ({
  productType,
}) => (
  <div className={'c-lead-configuration-preview-initial'}>
    <div className={'c-lead-configuration-preview-initial__image-wrapper'}>
      <img
        src={
          productType === LeadProductType.FLAT_DIRECT ? CustomerWithFlatDirect : CustomerWithFlatX
        }
        className={'c-lead-configuration-preview-initial__image'}
        alt={'Customer with contract'}
      />
    </div>
  </div>
);
