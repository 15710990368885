import { DependencyList, ReactNode, useEffect, useState } from 'react';

import { defer, Observable } from 'rxjs';

export enum QueryStatus {
  INIT = 'INIT',
  PEDNING = 'PEDNING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

type UseQueryState<T> =
  | InitUseQueryState
  | PendingUseQueryState
  | FailedUseQueryState
  | SuccessUseQueryState<T>;

export interface InitUseQueryState {
  status: QueryStatus.INIT;
}

export interface PendingUseQueryState {
  status: QueryStatus.PEDNING;
}

export interface FailedUseQueryState {
  status: QueryStatus.FAILED;
  error: any;
}

export interface SuccessUseQueryState<T> {
  status: QueryStatus.SUCCESS;
  result: T;
}

interface UseQueryOpts<Response> {
  call: () => Observable<Response> | Promise<Response>;
}

/**
 * @param config `{ call: Observable | Promise }`
 *
 * @example
 * ```
 * const query = useQuery({
 *   call: () => doSomethingAsync(),
 * })
 * ```
 */
export const useQuery = <Response extends unknown>(
  { call }: UseQueryOpts<Response>,
  depts?: DependencyList
): UseQueryState<Response> => {
  const [state, setState] = useState<UseQueryState<Response>>({
    status: QueryStatus.INIT,
  });

  useEffect(() => {
    setState({ ...state, status: QueryStatus.PEDNING });
    defer(call).subscribe({
      next: (result) => setState({ result, status: QueryStatus.SUCCESS }),
      error: (error) => setState({ error, status: QueryStatus.FAILED }),
    });
  }, depts ?? []);

  return state;
};

/**
 * Match query state to the corresponding status and render something
 * @example
 * ```
 * matchQueryState(state)({
 *   onPending: () => <Loader />,
 *   onSuccess: successState => <div>Success</div>,
 *   onFailure: errorState => <div>Error</div>,
 * })
 * ```
 */
export const matchQueryState =
  <T extends unknown>(state: UseQueryState<T>) =>
  (
    render: Partial<{
      onInit: (state: InitUseQueryState) => ReactNode;
      onPending: (state: PendingUseQueryState) => ReactNode;
      onSuccess: (state: SuccessUseQueryState<T>) => ReactNode;
      onFailure: (state: FailedUseQueryState) => ReactNode;
    }>
  ) => {
    switch (state.status) {
      case QueryStatus.INIT:
        return render.onInit?.(state);
      case QueryStatus.PEDNING:
        return render.onPending?.(state);
      case QueryStatus.SUCCESS:
        return render.onSuccess?.(state);
      case QueryStatus.FAILED:
        return render.onFailure?.(state);
      default:
        return;
    }
  };
