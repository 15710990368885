import { from } from 'rxjs';

import { Config } from '+config/config';

const getYoutubeVideoList = (listOfVideosIds: string) =>
  from(
    fetch(
      // eslint-disable-next-line max-len
      `${Config.YOUTUBE_URL}videos?id=${listOfVideosIds}&part=snippet,contentDetails&key=${Config.YOUTUBE_API}`
    ).then((response) => response.json())
  );

export const YoutubeRepository = {
  getYoutubeVideoList,
};
