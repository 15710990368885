import { CountryCode } from 'src/types/country.type';

export enum Market {
  DE = 'DE',
  AT = 'AT',
  CH = 'CH',
  AU = 'AU',
  IT = 'IT',
  US = 'US',
  GME = 'GME',
  ES = 'ES',
}

export const getMarkets = (
  isSpanishMarketEnabled: boolean
): {
  DE: CountryCode[];
  AT: CountryCode[];
  IT: CountryCode[];
  GME: CountryCode[];
  US: CountryCode[];
  AU: CountryCode[];
  CH: CountryCode[];
  ES?: CountryCode[];
} => {
  return {
    [Market.DE]: [CountryCode.DE],
    [Market.AT]: [CountryCode.AT],
    [Market.IT]: [CountryCode.IT],
    [Market.GME]: [
      CountryCode.AD, // business info: Andorra receives the fallback/universal postcode validation
      CountryCode.BE,
      CountryCode.BG,
      CountryCode.CY,
      CountryCode.CZ,
      CountryCode.DK,
      CountryCode.EE,
      ...(isSpanishMarketEnabled ? [] : [CountryCode.ES]),
      CountryCode.FI,
      CountryCode.FR,
      CountryCode.GB,
      CountryCode.GR,
      CountryCode.HR,
      CountryCode.HU,
      CountryCode.IE,
      CountryCode.LT,
      CountryCode.LU,
      CountryCode.LV,
      CountryCode.MT,
      CountryCode.NL,
      CountryCode.PL,
      CountryCode.PT,
      CountryCode.RO,
      CountryCode.SE,
      CountryCode.SI,
      CountryCode.SK,
    ],
    [Market.US]: [
      CountryCode.US,
      CountryCode.CA,
      CountryCode.PR,
      CountryCode.MX,
      CountryCode.CR,
      CountryCode.KY,
    ],
    [Market.AU]: [CountryCode.AU, CountryCode.NZ],
    [Market.CH]: [CountryCode.CH, CountryCode.LI],
    ...(isSpanishMarketEnabled ? { [Market.ES]: [CountryCode.ES] } : {}),
  };
};

export const getMarketKeys = () => Object.keys(getMarkets(true));

export const getMarketCountries = (
  marketCode: Market,
  isSpanishMarketEnabled: boolean = true
): CountryCode[] => getMarkets(isSpanishMarketEnabled)[marketCode] || [CountryCode.DE];

export const mapCountryCodeToMarket = (
  countryCode: CountryCode | undefined,
  isSpanishMarketEnabled: boolean = true
): Market | undefined => {
  if (countryCode) {
    for (const [market, countryCodes] of Object.entries(getMarkets(isSpanishMarketEnabled))) {
      if (countryCodes.includes(countryCode)) {
        return Market[market];
      }
    }
    return Market[countryCode];
  } else {
    return undefined;
  }
};

// Country and market specific postal codes validations
type Validation = {
  placeholder: string;
  length: number;
  regEx: RegExp;
};

type PostcodesValidations = {
  [key: string]: Validation;
};

export const postcodesValidations: PostcodesValidations = {
  DE: {
    placeholder: '60320',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  AT: {
    placeholder: '1010',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  IT: {
    placeholder: '36051',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  // CH market
  CH: {
    placeholder: '8050',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  LI: {
    placeholder: '9490',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  // AU market
  AU: {
    placeholder: '2599',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  NZ: {
    placeholder: '8041',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  // one universal validation for US market
  US: {
    placeholder: '11550, K1A 0T6',
    length: 10,
    regEx: /^[\w\d\s-]*$/,
  },
  // GME countries
  BE: {
    placeholder: '1049',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  BG: {
    placeholder: '1000',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  CY: {
    placeholder: '4999',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  CZ: {
    placeholder: '160 00, 16000',
    length: 6,
    regEx: /^[0-9\s]*$/,
  },
  DK: {
    placeholder: '2000',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  EE: {
    placeholder: '10111',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  ES: {
    placeholder: '28006',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  FI: {
    placeholder: '00180',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  FR: {
    placeholder: '75008',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  GB: {
    placeholder: 'DT3 6GB, L2 2DP',
    length: 7,
    regEx: /^[\w\d\s]*$/,
  },
  GR: {
    placeholder: '24100, 241 00',
    length: 6,
    regEx: /^[0-9\s]*$/,
  },
  HR: {
    placeholder: '21000',
    length: 5,
    regEx: /^[0-9]*$/,
  },
  HU: {
    placeholder: '2310',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  IE: {
    placeholder: 'D02 AF30',
    length: 8,
    regEx: /^[\w\d\s]*$/,
  },
  LT: {
    placeholder: 'LT-01100, 01100',
    length: 8,
    regEx: /^[\w\d-]*$/,
  },
  LU: {
    placeholder: 'L-2530, 1019',
    length: 6,
    regEx: /^[\w\d-]*$/,
  },
  LV: {
    placeholder: 'LV-1010, 1010',
    length: 7,
    regEx: /^[\w\d-]*$/,
  },
  MT: {
    placeholder: 'VLT 1117, TP01',
    length: 8,
    regEx: /^[\w\d\s]*$/,
  },
  NL: {
    placeholder: '1011 AC, 1011AC',
    length: 7,
    regEx: /^[\w\d\s]*$/,
  },
  PL: {
    placeholder: '87-100',
    length: 6,
    regEx: /^[0-9-]*$/,
  },
  PT: {
    placeholder: '1000-260',
    length: 8,
    regEx: /^[0-9-]*$/,
  },
  RO: {
    placeholder: '507085',
    length: 6,
    regEx: /^[0-9]*$/,
  },
  SE: {
    placeholder: '113 51',
    length: 6,
    regEx: /^[0-9\s]*$/,
  },
  SI: {
    placeholder: '8341',
    length: 4,
    regEx: /^[0-9]*$/,
  },
  SK: {
    placeholder: '811 01, 81101',
    length: 6,
    regEx: /^[0-9\s]*$/,
  },
};

export const getPostcodesValidations = (countryCode: CountryCode): Validation => {
  const fallback = {
    placeholder: '123456',
    length: 9,
    regEx: /^[\w\d\s-]*$/,
  };

  const market = mapCountryCodeToMarket(countryCode);

  if (market === 'US') return postcodesValidations.US;

  return !countryCode || !postcodesValidations[countryCode]
    ? fallback
    : postcodesValidations[countryCode];
};
