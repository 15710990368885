import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { useFeature } from '@sonnen/shared-web';

import { CountryCode } from 'src/types/country.type';
import * as Yup from 'yup';

import { FeatureName } from '+config/featureFlags';
import {
  CaseFormField,
  RecordTypeData,
  RecordTypesData,
  RecordTypeSupport,
} from '+shared/components/CaseReportForm/CaseCommon.types';
import {
  getEServicesCategories,
  getTechnicalCaseCategories,
} from '+shared/components/CaseReportForm/CaseReportForm.helper';

export const validationSchema = () =>
  Yup.object({
    [CaseFormField.RECORD_TYPE]: Yup.object().required(I18n.t(T.report.errors.caseTypeRequired)),
    [CaseFormField.CATEGORY]: Yup.string().when(CaseFormField.RECORD_TYPE, {
      is: (type: RecordTypeData) => type?.key === RecordTypeSupport.E_SERVICES,
      then: (schema: Yup.Schema<string>) => schema.required(I18n.t(T.report.errors.topicRequired)),
      otherwise: (schema: Yup.Schema<string>) => schema.notRequired(),
    }),
    [CaseFormField.SUBCATEGORY]: Yup.string().when(CaseFormField.RECORD_TYPE, {
      is: (type: RecordTypeData) => type?.key === RecordTypeSupport.TECHNICAL,
      then: (schema: Yup.Schema<string>) => schema.required(I18n.t(T.report.errors.topicRequired)),
      otherwise: (schema: Yup.Schema<string>) => schema.notRequired(),
    }),
    [CaseFormField.DESCRIPTION]: Yup.string()
      .required(I18n.t(T.report.inputRequiredError))
      .trim(I18n.t(T.report.fieldCannotBeEmpty)),
  });

const getSupportTypesComponentData = (userCountry: CountryCode | undefined): RecordTypesData => {
  // if market rollout is disabled always insert everything
  const isRolloutDisabled = useFeature(FeatureName.ROLLOUT).isDisabled;

  let recordTypeObject = {
    [RecordTypeSupport.TECHNICAL]: {
      key: RecordTypeSupport.TECHNICAL,
      label: I18n.t(T.help.supportForm.fields.topics.technology),
      categories: getTechnicalCaseCategories(),
    },
  };

  if (isRolloutDisabled || userCountry === CountryCode.DE) {
    const contractCase = {
      [RecordTypeSupport.E_SERVICES]: {
        key: RecordTypeSupport.E_SERVICES,
        label: I18n.t(T.help.supportForm.fields.topics.eServices),
        categories: getEServicesCategories(),
      },
    };
    const setupConfig = {
      [RecordTypeSupport.SETUP_CONFIG]: {
        key: RecordTypeSupport.SETUP_CONFIG,
        label: I18n.t(T.help.supportForm.fields.topics.setup),
      },
    };
    recordTypeObject = { ...recordTypeObject, ...contractCase, ...setupConfig };
  }

  return recordTypeObject;
};

export const SupportFormHelper = {
  getSupportTypesComponentData,
  validationSchema,
};
