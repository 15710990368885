import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';

import { Dashboard } from '+shared/basicComponents/Icons';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { LabelLarge } from '+shared/basicComponents/Typography/Labels';
import { BasicTable, ColumnGenerator, StatusTile, StatusTileColors } from '+shared/components';
import { getOfflineSince } from '+shared/store/battery';
import {
  getFilteredPowerMeterErrorCodes,
  getFilteredPowerMeterErrorColor,
  getFilteredPowerMeterErrorMessages,
  MeterMeasurementDirection,
  PowerMeterLiveState,
} from '+shared/store/battery/types/batteryPowerMeterLiveState.interface';
import { QueryState } from '+shared/store/query';
import { SiteLiveState } from '+shared/store/site/types';

import MeasurementConcept, {
  MeasurementConceptsType,
} from '../MeasurementConcept/MeasurementConcept';

import './PowerMeterLiveStatesCard.scss';

interface Props {
  powerMeterLiveStates: PowerMeterLiveState[];
  siteLiveState?: SiteLiveState;
  powerMeterLiveStatesQueryState: QueryState;
  siteLiveStateQueryState: QueryState;
  measurementConcept: MeasurementConceptsType;
}

type RowDataTypeValue = string | number | JSX.Element;
type RowDataType = { name: string; values: RowDataTypeValue[] };

export const PowerMeterLiveStatesCard: React.FC<Props> = ({
  powerMeterLiveStates,
  siteLiveState,
  powerMeterLiveStatesQueryState,
  siteLiveStateQueryState,
  measurementConcept,
}) => {
  const { isMeasurementConceptVisible } = useFlags();

  const isErrorMessageVisible =
    powerMeterLiveStatesQueryState.error || siteLiveStateQueryState.error;
  const { failedToFetchData, liveData, meters } = T.customerSingle.pvSystemsAndMeters.meters;
  const liveDataTimestamp = new Date().toLocaleTimeString(); // @todo 28894 get time from data
  const label = `${I18n.t(liveData)} - ${liveDataTimestamp}`;

  const batteryHasMeasurementConcept = !isNil(measurementConcept);
  const tableHasData = Boolean(powerMeterLiveStates.length);

  return (
    <div className="c-widget">
      <div className="c-widget__header clamps-table">
        <Dashboard size={26} />
        <LabelLarge text={I18n.t(meters)} className="headline-horizontal-spacing" />

        {siteLiveState && (
          <StatusTile
            label={siteLiveState.online ? label : getOfflineSince(siteLiveState.timestamp)}
            color={siteLiveState.online ? StatusTileColors.GREEN : StatusTileColors.RED}
            hasDot={Boolean(siteLiveState.online)}
            additionalClass={`power-meter-live-states-card__header-status ${
              siteLiveState.online ? '' : 'sw-icofont icofont-warning'
            }`}
          />
        )}

        {isErrorMessageVisible && (
          <StatusTile
            label={I18n.t(failedToFetchData)}
            color={StatusTileColors.YELLOW}
            additionalClass={`power-meter-live-states-card__header-status`}
          />
        )}
      </div>

      <div className={classNames('c-widget__body-wrapper', 'power-meter-live-states-card__body')}>
        {siteLiveStateQueryState.pending ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <>
            {isMeasurementConceptVisible && batteryHasMeasurementConcept && (
              <MeasurementConcept measurementConcept={measurementConcept} />
            )}

            <div className="power-meter-live-states-card__body-table">
              {tableHasData && (
                <BasicTable columns={createColumnGenerators()} data={createTranslatedRows()} />
              )}

              {!tableHasData && powerMeterLiveStatesQueryState.pending && (
                <div className="loader">
                  <Loader />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );

  function createColumnGenerators(): Array<ColumnGenerator<RowDataType>> {
    return [
      {
        name: 'names',
        visible: true,
        headCellGenerator: () => <></>,
        dataCellGenerator: (rowData) => <>{rowData.name}</>,
        dataCellClassName: 'power-meter-live-states-card__body-table-sticky-cell',
      },
      ...powerMeterLiveStates.map((powerMeterLiveState, index) => {
        return {
          name: getPowerMeterDescription(powerMeterLiveState.direction, index),
          visible: true,
          headCellGenerator: () => (
            <>{getPowerMeterDescription(powerMeterLiveState.direction, index)}</>
          ),
          dataCellGenerator: (rowData: RowDataType) => {
            const rowDataNew: RowDataType = { ...rowData };
            rowDataNew.values = makeMinusSignForTableNegativeValuesMoreVisible(rowData.values);
            return <>{rowDataNew.values[index]}</>;
          },
          dataCellClassName: 'power-meter-live-states-card__body-table-normal-cell',
        };
      }),
    ];
  }

  function createTranslatedRows(): RowDataType[] {
    return [
      { name: `${translate('error')}`, values: getPowerMeterError() },
      { name: `${translate('modbusId')}`, values: getValuesOfRow('modbusId') },
      { name: `${translate('channel')}`, values: getValuesOfRow('channel') },
      {
        name: `${translate('current')} L1`,
        values: mapUnitOnValue(getValuesOfRow('currentL1'), 'A'),
      },
      {
        name: `${translate('current')} L2`,
        values: mapUnitOnValue(getValuesOfRow('currentL2'), 'A'),
      },
      {
        name: `${translate('current')} L3`,
        values: mapUnitOnValue(getValuesOfRow('currentL3'), 'A'),
      },
      {
        name: `${translate('voltage')} L1-L2`,
        values: mapUnitOnValue(getValuesOfRow('voltageL1L2'), 'V'),
      },
      {
        name: `${translate('voltage')} L1-N`,
        values: mapUnitOnValue(getValuesOfRow('voltageL1N'), 'V'),
      },
      {
        name: `${translate('voltage')} L2-L3`,
        values: mapUnitOnValue(getValuesOfRow('voltageL2L3'), 'V'),
      },
      {
        name: `${translate('voltage')} L2-N`,
        values: mapUnitOnValue(getValuesOfRow('voltageL2N'), 'V'),
      },
      {
        name: `${translate('voltage')} L3-L1`,
        values: mapUnitOnValue(getValuesOfRow('voltageL3L1'), 'V'),
      },
      {
        name: `${translate('voltage')} L3-N`,
        values: mapUnitOnValue(getValuesOfRow('voltageL3N'), 'V'),
      },
      {
        name: `${translate('power')} L1`,
        values: mapUnitOnValue(getValuesOfRow('powerL1'), 'W'),
      },
      {
        name: `${translate('power')} L2`,
        values: mapUnitOnValue(getValuesOfRow('powerL2'), 'W'),
      },
      {
        name: `${translate('power')} L3`,
        values: mapUnitOnValue(getValuesOfRow('powerL3'), 'W'),
      },
      {
        name: `${translate('apparentPower')}`,
        values: mapUnitOnValue(getValuesOfRow('apparentPowerTotal'), 'VA'),
      },
      {
        name: `${translate('reactivePower')} `,
        values: mapUnitOnValue(getValuesOfRow('reactivePowerTotal'), 'var'),
      },
      {
        name: `${translate('powerTotal')}`,
        values: mapUnitOnValue(getValuesOfRow('powerTotal'), 'W'),
      },
    ];
  }

  function translate(key: keyof typeof T.customerSingle.pvSystemsAndMeters.meters): string {
    return I18n.t(T.customerSingle.pvSystemsAndMeters.meters[key]);
  }

  function getValuesOfRow(key: keyof PowerMeterLiveState): Array<string | number> {
    return powerMeterLiveStates.map((state) => roundToTwoDecimalsIfNumber(state[key]));
  }

  function getPowerMeterDescription(
    meterDirection: MeterMeasurementDirection,
    index: number
  ): string {
    /* from BA:
     * atm if the meterDirection is different than "production" "consumption",
     * "consumption" will be displayed as default
     */
    const translationKey =
      meterDirection === 'grid' || meterDirection === 'difference' ? 'consumption' : meterDirection;

    return `Meter ${index} (${I18n.t(
      T.customerSingle.pvSystemsAndMeters.meterDirection[translationKey]
    )})`;
  }

  function getPowerMeterError(): JSX.Element[] {
    return powerMeterLiveStates.map((powerMeter, index) => (
      <div className={'power-meter-live-states-card__tooltip'}>
        <StatusTile
          key={index}
          label={makeMinusSignForErrorNegativeValuesMoreVisible(
            getFilteredPowerMeterErrorCodes(powerMeter.error)
          )}
          color={getFilteredPowerMeterErrorColor(powerMeter.error)}
          tooltipText={getFilteredPowerMeterErrorMessages(powerMeter.error)}
        />
      </div>
    ));
  }
};

/*
 * Helpers
 * functions which don't need access to the react component's scope
 *
 * Below functions remove the short dash (minus) of negative values,
 * and replace them with the html special character long dash, &ndash;.
 * The reason for this is that our current font has a very short dash (minus) which,
 * on screens with weaker resolution, is barely visible.
 *
 * There are 2 functions because the return types are different
 * and conflict with RowDataType in the table.
 */
export const makeMinusSignForErrorNegativeValuesMoreVisible = (
  errorCode: string
): string | JSX.Element => {
  const errorCodeAsNumber = Number(errorCode);
  const isErrorCodeAsNumberNegativeValue = Math.sign(errorCodeAsNumber) === -1;

  return isErrorCodeAsNumberNegativeValue ? (
    <>
      <b>&ndash;</b>&nbsp;
      {Math.abs(errorCodeAsNumber)}
    </>
  ) : (
    errorCode
  );
};

export const makeMinusSignForTableNegativeValuesMoreVisible = (
  rowValues: RowDataTypeValue[]
): RowDataTypeValue[] => {
  const rowValuesUpdated = rowValues.map((rowValue: RowDataTypeValue) => {
    /* JSX.Element values will be returned without alteration.
     * This is the case for the error row, where cells have a tooltip.
     * See getPowerMeterError() function.
     * All other rows are arrays of string or numbers.
     */
    if (typeof rowValue === 'string' || typeof rowValue === 'number') {
      const extractedValue = rowValue.toString().split(' ')[0];
      const extractedUnit = rowValue.toString().split(' ')[1];
      const valueAsNumber = Number(extractedValue);

      const isValueAsNumberNumberNegative = valueAsNumber < 0;

      return isValueAsNumberNumberNegative ? (
        <>
          <b>&ndash;</b>
          {/* not all rows have a unit appended,
           * so when unit is undefined, empty string is appended */}
          {` ${Math.abs(valueAsNumber)} ${extractedUnit ? extractedUnit : ''}`}
        </>
      ) : (
        rowValue
      );
    } else {
      return rowValue;
    }
  });
  return rowValuesUpdated;
};

const mapUnitOnValue = (values: Array<string | number>, unit: string): string[] =>
  values.map((value) => `${value} ${unit}`);

const roundToTwoDecimalsIfNumber = (value: string | number): string | number => {
  if (typeof value === 'number') {
    return Math.round(value * 100) / 100;
  }
  return value;
};
