import React from 'react';

import { MeasurementConcepts } from '+app/+customer/+pvSystems/components/MeasurementConcept/MeasurementConcept';

export const MeasurementConceptGraph: React.FC<{ conceptType: MeasurementConcepts }> = ({
  conceptType,
}) => (
  <>
    {conceptType === MeasurementConcepts.CONSUMPTION && <MeasurementConceptConsumption />}
    {conceptType === MeasurementConcepts.DIFFERENTIAL && <MeasurementConceptDifferential />}
    {conceptType === MeasurementConcepts.GRID && <MeasurementConceptGrid />}
  </>
);

export const MeasurementConceptConsumption = () => (
  <svg width="280" height="226" viewBox="0 0 280 226" fill="none">
    <line x1="20" y1="117.802" x2="261" y2="117.802" stroke="#0A1535" />
    <circle cx="19.9997" cy="26.058" r="4.26724" fill="#FFCB52" />
    <rect x="19.7153" y="17.8079" width="0.568965" height="2.84483" rx="0.284483" fill="#FFCB52" />
    <rect x="19.7153" y="31.4631" width="0.568965" height="2.84483" rx="0.284483" fill="#FFCB52" />
    <rect
      x="25.6323"
      y="20.0233"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 25.6323 20.0233)"
      fill="#FFCB52"
    />
    <rect
      x="15.9766"
      y="29.6788"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 15.9766 29.6788)"
      fill="#FFCB52"
    />
    <rect
      x="28.25"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 28.25 25.7737)"
      fill="#FFCB52"
    />
    <rect
      x="14.5947"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 14.5947 25.7737)"
      fill="#FFCB52"
    />
    <rect
      x="26.0347"
      y="31.6902"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 26.0347 31.6902)"
      fill="#FFCB52"
    />
    <rect
      x="16.3789"
      y="22.0344"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 16.3789 22.0344)"
      fill="#FFCB52"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8942 50.7579L28.7192 54.0579H25.6124L25.0124 50.7579H27.8942ZM27.7942 50.3579L26.8942 46.7579H24.2851L24.2124 46.3579H26.7942L26.2192 44.0579L23.7942 44.0579L24.2124 46.3579H23.8058L23.3876 44.0579L20.2 44.0579V46.3579H19.8V44.0579L16.6124 44.0579L16.1942 46.3579H15.7876L16.2058 44.0579L13.7808 44.0579L13.2058 46.3579H15.7876L15.7149 46.7579H13.1058L12.2058 50.3579H15.0604L14.9876 50.7579H12.1058L11.2808 54.0579H14.3876L14.9876 50.7579H15.3942L14.7942 54.0579H19.8V50.7579H20.2V54.0579H25.2058L24.6058 50.7579H25.0124L24.9396 50.3579H27.7942ZM24.5331 50.3579L24.6058 50.7579H20.2V50.3579H24.5331ZM19.8 50.3579V50.7579H15.3942L15.4669 50.3579H19.8ZM19.8 50.3579V46.7579H20.2V50.3579H19.8ZM20.2 46.7579V46.3579H23.8058L23.8785 46.7579H20.2ZM24.5331 50.3579L23.8785 46.7579H24.2851L24.9396 50.3579H24.5331ZM15.4669 50.3579H15.0604L15.7149 46.7579H16.1215L15.4669 50.3579ZM16.1215 46.7579L16.1942 46.3579H19.8V46.7579H16.1215ZM14 55.0579H10.6404C10.3151 55.0579 10.0764 54.7522 10.1553 54.4366L12.8107 43.8153C12.9219 43.3702 13.3219 43.0579 13.7808 43.0579H26.2192C26.6781 43.0579 27.078 43.3702 27.1893 43.8153L29.8447 54.4366C29.9236 54.7522 29.6849 55.0579 29.3596 55.0579H26V56.5579C26 56.834 25.7762 57.0579 25.5 57.0579C25.2239 57.0579 25 56.834 25 56.5579V55.0579H15V56.5579C15 56.834 14.7762 57.0579 14.5 57.0579C14.2239 57.0579 14 56.834 14 56.5579V55.0579Z"
      fill="#0A1535"
    />
    <circle cx="79.9997" cy="26.058" r="4.26724" fill="#D3D5D8" />
    <rect x="79.7153" y="17.8079" width="0.568965" height="2.84483" rx="0.284483" fill="#D3D5D8" />
    <rect x="79.7153" y="31.4631" width="0.568965" height="2.84483" rx="0.284483" fill="#D3D5D8" />
    <rect
      x="85.6323"
      y="20.0233"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 85.6323 20.0233)"
      fill="#D3D5D8"
    />
    <rect
      x="75.9766"
      y="29.6788"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 75.9766 29.6788)"
      fill="#D3D5D8"
    />
    <rect
      x="88.25"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 88.25 25.7737)"
      fill="#D3D5D8"
    />
    <rect
      x="74.5947"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 74.5947 25.7737)"
      fill="#D3D5D8"
    />
    <rect
      x="86.0347"
      y="31.6902"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 86.0347 31.6902)"
      fill="#D3D5D8"
    />
    <rect
      x="76.3789"
      y="22.0344"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 76.3789 22.0344)"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.8942 50.7579L88.7192 54.0579H85.6124L85.0124 50.7579H87.8942ZM87.7942 50.3579L86.8942 46.7579H84.2851L84.2124 46.3579H86.7942L86.2192 44.0579L83.7942 44.0579L84.2124 46.3579H83.8058L83.3876 44.0579L80.2 44.0579V46.3579H79.8V44.0579L76.6124 44.0579L76.1942 46.3579H75.7876L76.2058 44.0579L73.7808 44.0579L73.2058 46.3579H75.7876L75.7149 46.7579H73.1058L72.2058 50.3579H75.0604L74.9876 50.7579H72.1058L71.2808 54.0579H74.3876L74.9876 50.7579H75.3942L74.7942 54.0579H79.8V50.7579H80.2V54.0579H85.2058L84.6058 50.7579H85.0124L84.9396 50.3579H87.7942ZM84.5331 50.3579L84.6058 50.7579H80.2V50.3579H84.5331ZM79.8 50.3579V50.7579H75.3942L75.4669 50.3579H79.8ZM79.8 50.3579V46.7579H80.2V50.3579H79.8ZM80.2 46.7579V46.3579H83.8058L83.8785 46.7579H80.2ZM84.5331 50.3579L83.8785 46.7579H84.2851L84.9396 50.3579H84.5331ZM75.4669 50.3579H75.0604L75.7149 46.7579H76.1215L75.4669 50.3579ZM76.1215 46.7579L76.1942 46.3579H79.8V46.7579H76.1215ZM74 55.0579H70.6404C70.3151 55.0579 70.0764 54.7522 70.1553 54.4366L72.8107 43.8153C72.9219 43.3702 73.3219 43.0579 73.7808 43.0579H86.2192C86.6781 43.0579 87.078 43.3702 87.1893 43.8153L89.8447 54.4366C89.9236 54.7522 89.6849 55.0579 89.3596 55.0579H86V56.5579C86 56.834 85.7762 57.0579 85.5 57.0579C85.2239 57.0579 85 56.834 85 56.5579V55.0579H75V56.5579C75 56.834 74.7762 57.0579 74.5 57.0579C74.2239 57.0579 74 56.834 74 56.5579V55.0579Z"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.584 40.4783C140.235 40.2271 139.765 40.2271 139.416 40.4783L130.218 47.0979C129.99 47.2619 129.931 47.5892 130.087 47.8289C130.243 48.0686 130.554 48.13 130.782 47.966L132 47.0897V58.0579C132 58.6102 132.448 59.0579 133 59.0579H135C135.552 59.0579 136 58.6102 136 58.0579V52.0579H140V58.0579C140 58.6102 140.448 59.0579 141 59.0579H147C147.552 59.0579 148 58.6102 148 58.0579V47.0897L149.218 47.966C149.446 48.13 149.757 48.0686 149.913 47.8289C150.069 47.5892 150.01 47.2619 149.782 47.0979L140.584 40.4783ZM133 46.37L140 41.3323L147 46.37V58.0579H141V52.0579C141 51.5056 140.552 51.0579 140 51.0579H136C135.448 51.0579 135 51.5056 135 52.0579V58.0579L133 58.0579V46.37Z"
      fill="#0A1535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M200.584 40.4783C200.235 40.2271 199.765 40.2271 199.416 40.4783L190.218 47.0979C189.99 47.2619 189.931 47.5892 190.087 47.8289C190.243 48.0686 190.554 48.13 190.782 47.966L192 47.0897V58.0579C192 58.6102 192.448 59.0579 193 59.0579H195C195.552 59.0579 196 58.6102 196 58.0579V52.0579H200V58.0579C200 58.6102 200.448 59.0579 201 59.0579H207C207.552 59.0579 208 58.6102 208 58.0579V47.0897L209.218 47.966C209.446 48.13 209.757 48.0686 209.913 47.8289C210.069 47.5892 210.01 47.2619 209.782 47.0979L200.584 40.4783ZM193 46.37L200 41.3323L207 46.37V58.0579H201V52.0579C201 51.5056 200.552 51.0579 200 51.0579H196C195.448 51.0579 195 51.5056 195 52.0579V58.0579L193 58.0579V46.37Z"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.75 40.5579H251.25L251.25 59.5579H268.75V40.5579ZM251.25 39.5579C250.698 39.5579 250.25 40.0056 250.25 40.5579V59.5579C250.25 60.1101 250.698 60.5579 251.25 60.5579H268.75C269.302 60.5579 269.75 60.1101 269.75 59.5579V40.5579C269.75 40.0056 269.302 39.5579 268.75 39.5579H251.25ZM260 47.7579C261.008 47.7579 261.825 46.9408 261.825 45.9329C261.825 44.9249 261.008 44.1079 260 44.1079C258.992 44.1079 258.175 44.9249 258.175 45.9329C258.175 46.9408 258.992 47.7579 260 47.7579ZM260 48.5579C261.45 48.5579 262.625 47.3826 262.625 45.9329C262.625 44.4831 261.45 43.3079 260 43.3079C258.55 43.3079 257.375 44.4831 257.375 45.9329C257.375 47.3826 258.55 48.5579 260 48.5579ZM257.3 50.0579C257.134 50.0579 257 50.1922 257 50.3579C257 50.5235 257.134 50.6579 257.3 50.6579H262.7C262.866 50.6579 263 50.5235 263 50.3579C263 50.1922 262.866 50.0579 262.7 50.0579H257.3Z"
      fill="#0A1535"
    />
    <path d="M20 78L22.8868 73H17.1132L20 78ZM19.5 62V73.5H20.5V62H19.5Z" fill="#0A1535" />
    <circle cx="20" cy="90" r="11.5" fill="white" stroke="#FBB000" />
    <path
      d="M15.4143 93.772C15.4143 93.892 15.5103 94 15.6423 94H16.4223C16.5423 94 16.6503 93.892 16.6503 93.772V90.952H18.3903C19.8543 90.952 21.0663 89.74 21.0663 88.252C21.0663 86.8 19.8543 85.6 18.3783 85.6H15.6423C15.5103 85.6 15.4143 85.708 15.4143 85.828V93.772ZM16.6503 89.776V86.8H18.3543C19.1823 86.8 19.8303 87.424 19.8303 88.24C19.8303 89.104 19.1823 89.776 18.3543 89.776H16.6503ZM23.2481 93.76C23.2481 93.892 23.3561 94 23.4881 94H24.2441C24.3641 94 24.4601 93.892 24.4601 93.76V85.828C24.4601 85.708 24.3641 85.6 24.2441 85.6H23.8721C23.7041 85.6 23.5841 85.648 23.4521 85.756L21.8801 87.184C21.8321 87.244 21.8081 87.34 21.8441 87.436L21.9881 87.868C22.0241 87.976 22.1561 88 22.2641 87.952L23.2481 87.484V93.76Z"
      fill="#FBB000"
    />
    <line x1="20.5" y1="102" x2="20.5" y2="118" stroke="#0A1535" />
    <path d="M80 78L82.8868 73H77.1132L80 78ZM79.5 62V73.5H80.5V62H79.5Z" fill="#D3D5D8" />
    <circle cx="80" cy="90" r="11.5" fill="white" stroke="#D3D5D8" />
    <path
      d="M74.3186 93.772C74.3186 93.892 74.4146 94 74.5466 94H75.3266C75.4466 94 75.5546 93.892 75.5546 93.772V90.952H77.2946C78.7586 90.952 79.9706 89.74 79.9706 88.252C79.9706 86.8 78.7586 85.6 77.2826 85.6H74.5466C74.4146 85.6 74.3186 85.708 74.3186 85.828V93.772ZM75.5546 89.776V86.8H77.2586C78.0866 86.8 78.7346 87.424 78.7346 88.24C78.7346 89.104 78.0866 89.776 77.2586 89.776H75.5546ZM81.0392 93.736C81.0392 93.88 81.1592 94 81.3032 94H81.9152C82.1432 94 82.2392 93.916 82.2392 93.736V90.28C82.3232 90.088 82.8272 89.476 83.7032 89.476C84.4232 89.476 84.7952 90.004 84.7952 90.904V93.736C84.7952 93.88 84.9152 94 85.0592 94H85.7312C85.8632 94 85.9952 93.88 85.9952 93.736V90.952C85.9952 89.512 85.5392 88.336 83.8472 88.336C82.7432 88.336 82.1072 88.96 81.9872 89.104L81.8192 88.624C81.7952 88.516 81.7112 88.456 81.5912 88.456H81.3032C81.1592 88.456 81.0392 88.588 81.0392 88.72V93.736Z"
      fill="#D3D5D8"
    />
    <line x1="80.5" y1="102" x2="80.5" y2="118" stroke="#D3D5D8" />
    <path d="M140 62L137.113 67H142.887L140 62ZM139.5 66.5V78H140.5V66.5H139.5Z" fill="#0A1535" />
    <circle cx="140" cy="90" r="11.5" fill="white" stroke="#02A1C4" />
    <path
      d="M134.254 89.812C134.254 92.212 136.186 94.12 138.586 94.12C139.654 94.12 140.686 93.748 141.502 92.956C141.586 92.872 141.61 92.716 141.514 92.632L140.962 92.092C140.89 92.032 140.782 92.02 140.674 92.104C140.098 92.548 139.486 92.896 138.61 92.896C136.894 92.896 135.586 91.468 135.586 89.788C135.586 88.108 136.882 86.668 138.598 86.668C139.342 86.668 140.11 86.98 140.674 87.472C140.782 87.58 140.89 87.58 140.974 87.472L141.502 86.932C141.598 86.836 141.598 86.692 141.49 86.596C140.674 85.888 139.798 85.48 138.586 85.48C136.186 85.48 134.254 87.412 134.254 89.812ZM143.893 93.76C143.893 93.892 144.001 94 144.133 94H144.889C145.009 94 145.105 93.892 145.105 93.76V85.828C145.105 85.708 145.009 85.6 144.889 85.6H144.517C144.349 85.6 144.229 85.648 144.097 85.756L142.525 87.184C142.477 87.244 142.453 87.34 142.489 87.436L142.633 87.868C142.669 87.976 142.801 88 142.909 87.952L143.893 87.484V93.76Z"
      fill="#02A1C4"
    />
    <line x1="140.5" y1="102" x2="140.5" y2="118" stroke="#0A1535" />
    <path d="M200 62L197.113 67H202.887L200 62ZM199.5 66.5V78H200.5V66.5H199.5Z" fill="#D3D5D8" />
    <circle cx="200" cy="90" r="11.5" fill="white" stroke="#D3D5D8" />
    <path
      d="M193.17 89.812C193.17 92.212 195.102 94.12 197.502 94.12C198.57 94.12 199.602 93.748 200.418 92.956C200.502 92.872 200.526 92.716 200.43 92.632L199.878 92.092C199.806 92.032 199.698 92.02 199.59 92.104C199.014 92.548 198.402 92.896 197.526 92.896C195.81 92.896 194.502 91.468 194.502 89.788C194.502 88.108 195.798 86.668 197.514 86.668C198.258 86.668 199.026 86.98 199.59 87.472C199.698 87.58 199.806 87.58 199.89 87.472L200.418 86.932C200.514 86.836 200.514 86.692 200.406 86.596C199.59 85.888 198.714 85.48 197.502 85.48C195.102 85.48 193.17 87.412 193.17 89.812ZM201.672 93.736C201.672 93.88 201.792 94 201.936 94H202.548C202.776 94 202.872 93.916 202.872 93.736V90.28C202.956 90.088 203.46 89.476 204.336 89.476C205.056 89.476 205.428 90.004 205.428 90.904V93.736C205.428 93.88 205.548 94 205.692 94H206.364C206.496 94 206.628 93.88 206.628 93.736V90.952C206.628 89.512 206.172 88.336 204.48 88.336C203.376 88.336 202.74 88.96 202.62 89.104L202.452 88.624C202.428 88.516 202.344 88.456 202.224 88.456H201.936C201.792 88.456 201.672 88.588 201.672 88.72V93.736Z"
      fill="#D3D5D8"
    />
    <line x1="200.5" y1="102" x2="200.5" y2="118" stroke="#D3D5D8" />
    <line x1="260.5" y1="62" x2="260.5" y2="118" stroke="#0A1535" />
    <line x1="140.5" y1="118" x2="140.5" y2="174" stroke="#0A1535" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.262 176C137.799 176 137.396 176.319 137.289 176.77L136.763 179H131.5C131.224 179 131 179.224 131 179.5C131 179.776 131.224 180 131.5 180H136.528L135.349 185H132.5C132.224 185 132 185.224 132 185.5C132 185.776 132.224 186 132.5 186H135.113L131.104 203H127.5C127.224 203 127 203.224 127 203.5C127 203.776 127.224 204 127.5 204H152.5C152.776 204 153 203.776 153 203.5C153 203.224 152.776 203 152.5 203H148.896L144.887 186H147.5C147.776 186 148 185.776 148 185.5C148 185.224 147.776 185 147.5 185H144.651L143.472 180H148.5C148.776 180 149 179.776 149 179.5C149 179.224 148.776 179 148.5 179H143.237L142.711 176.77C142.604 176.319 142.201 176 141.738 176H138.262ZM146.914 198.951L144.746 189.757L144.735 189.766L140.605 193.4L146.914 198.951ZM147.231 200.296L147.868 203H132.132L132.769 200.296L140 193.933L147.231 200.296ZM139.395 193.4L133.086 198.951L135.254 189.757C135.258 189.76 135.261 189.763 135.265 189.766L139.395 193.4ZM140 192.867L144.383 189.01L140 185.401L135.617 189.01L140 192.867ZM140.629 184.882L144.311 187.915L143.114 182.836L140.629 184.882ZM142.909 181.968L140 184.364L137.091 181.968L138.262 177H141.738L142.909 181.968ZM139.371 184.882L135.689 187.915L136.886 182.836L139.371 184.882Z"
      fill="#0A1535"
    />
  </svg>
);

export const MeasurementConceptGrid = () => (
  <svg width="280" height="226" viewBox="0 0 280 226" fill="none">
    <line x1="21" y1="117.802" x2="262" y2="117.802" stroke="#0A1535" />
    <circle cx="20.0002" cy="26.0578" r="4.26724" fill="#FFCB52" />
    <rect x="19.7156" y="17.8079" width="0.568965" height="2.84483" rx="0.284483" fill="#FFCB52" />
    <rect x="19.7156" y="31.4629" width="0.568965" height="2.84483" rx="0.284483" fill="#FFCB52" />
    <rect
      x="25.6328"
      y="20.0233"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 25.6328 20.0233)"
      fill="#FFCB52"
    />
    <rect
      x="15.9771"
      y="29.6788"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 15.9771 29.6788)"
      fill="#FFCB52"
    />
    <rect
      x="28.25"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 28.25 25.7737)"
      fill="#FFCB52"
    />
    <rect
      x="14.595"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 14.595 25.7737)"
      fill="#FFCB52"
    />
    <rect
      x="26.0349"
      y="31.6904"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 26.0349 31.6904)"
      fill="#FFCB52"
    />
    <rect
      x="16.3792"
      y="22.0347"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 16.3792 22.0347)"
      fill="#FFCB52"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8942 50.7579L28.7192 54.0579H25.6124L25.0124 50.7579H27.8942ZM27.7942 50.3579L26.8942 46.7579H24.2851L24.2124 46.3579H26.7942L26.2192 44.0579L23.7942 44.0579L24.2124 46.3579H23.8058L23.3876 44.0579L20.2 44.0579V46.3579H19.8V44.0579L16.6124 44.0579L16.1942 46.3579H15.7876L16.2058 44.0579L13.7808 44.0579L13.2058 46.3579H15.7876L15.7149 46.7579H13.1058L12.2058 50.3579H15.0604L14.9876 50.7579H12.1058L11.2808 54.0579H14.3876L14.9876 50.7579H15.3942L14.7942 54.0579H19.8V50.7579H20.2V54.0579H25.2058L24.6058 50.7579H25.0124L24.9396 50.3579H27.7942ZM24.5331 50.3579L24.6058 50.7579H20.2V50.3579H24.5331ZM19.8 50.3579V50.7579H15.3942L15.4669 50.3579H19.8ZM19.8 50.3579V46.7579H20.2V50.3579H19.8ZM20.2 46.7579V46.3579H23.8058L23.8785 46.7579H20.2ZM24.5331 50.3579L23.8785 46.7579H24.2851L24.9396 50.3579H24.5331ZM15.4669 50.3579H15.0604L15.7149 46.7579H16.1215L15.4669 50.3579ZM16.1215 46.7579L16.1942 46.3579H19.8V46.7579H16.1215ZM14 55.0579H10.6404C10.3151 55.0579 10.0764 54.7522 10.1553 54.4366L12.8107 43.8153C12.9219 43.3702 13.3219 43.0579 13.7808 43.0579H26.2192C26.6781 43.0579 27.078 43.3702 27.1893 43.8153L29.8447 54.4366C29.9236 54.7522 29.6849 55.0579 29.3596 55.0579H26V56.5579C26 56.834 25.7762 57.0579 25.5 57.0579C25.2239 57.0579 25 56.834 25 56.5579V55.0579H15V56.5579C15 56.834 14.7762 57.0579 14.5 57.0579C14.2239 57.0579 14 56.834 14 56.5579V55.0579Z"
      fill="#0A1535"
    />
    <circle cx="100" cy="26.0578" r="4.26724" fill="#D3D5D8" />
    <rect x="99.7156" y="17.8079" width="0.568965" height="2.84483" rx="0.284483" fill="#D3D5D8" />
    <rect x="99.7156" y="31.4629" width="0.568965" height="2.84483" rx="0.284483" fill="#D3D5D8" />
    <rect
      x="105.633"
      y="20.0233"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 105.633 20.0233)"
      fill="#D3D5D8"
    />
    <rect
      x="95.9771"
      y="29.6788"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 95.9771 29.6788)"
      fill="#D3D5D8"
    />
    <rect
      x="108.25"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 108.25 25.7737)"
      fill="#D3D5D8"
    />
    <rect
      x="94.595"
      y="25.7737"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 94.595 25.7737)"
      fill="#D3D5D8"
    />
    <rect
      x="106.035"
      y="31.6904"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 106.035 31.6904)"
      fill="#D3D5D8"
    />
    <rect
      x="96.3792"
      y="22.0347"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 96.3792 22.0347)"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.894 50.7579L108.719 54.0579H105.612L105.012 50.7579H107.894ZM107.794 50.3579L106.894 46.7579H104.285L104.212 46.3579H106.794L106.219 44.0579L103.794 44.0579L104.212 46.3579H103.806L103.388 44.0579L100.2 44.0579V46.3579H99.8V44.0579L96.6124 44.0579L96.1942 46.3579H95.7876L96.2058 44.0579L93.7808 44.0579L93.2058 46.3579H95.7876L95.7149 46.7579H93.1058L92.2058 50.3579H95.0604L94.9876 50.7579H92.1058L91.2808 54.0579H94.3876L94.9876 50.7579H95.3942L94.7942 54.0579H99.8V50.7579H100.2V54.0579H105.206L104.606 50.7579H105.012L104.94 50.3579H107.794ZM104.533 50.3579L104.606 50.7579H100.2V50.3579H104.533ZM99.8 50.3579V50.7579H95.3942L95.4669 50.3579H99.8ZM99.8 50.3579V46.7579H100.2V50.3579H99.8ZM100.2 46.7579V46.3579H103.806L103.879 46.7579H100.2ZM104.533 50.3579L103.879 46.7579H104.285L104.94 50.3579H104.533ZM95.4669 50.3579H95.0604L95.7149 46.7579H96.1215L95.4669 50.3579ZM96.1215 46.7579L96.1942 46.3579H99.8V46.7579H96.1215ZM94 55.0579H90.6404C90.3151 55.0579 90.0764 54.7522 90.1553 54.4366L92.8107 43.8153C92.9219 43.3702 93.3219 43.0579 93.7808 43.0579H106.219C106.678 43.0579 107.078 43.3702 107.189 43.8153L109.845 54.4366C109.924 54.7522 109.685 55.0579 109.36 55.0579H106V56.5579C106 56.834 105.776 57.0579 105.5 57.0579C105.224 57.0579 105 56.834 105 56.5579V55.0579H95V56.5579C95 56.834 94.7762 57.0579 94.5 57.0579C94.2239 57.0579 94 56.834 94 56.5579V55.0579Z"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.584 40.4783C180.235 40.2271 179.765 40.2271 179.416 40.4783L170.218 47.0979C169.99 47.2619 169.931 47.5892 170.087 47.8289C170.243 48.0686 170.554 48.13 170.782 47.966L172 47.0897V58.0579C172 58.6102 172.448 59.0579 173 59.0579L175 59.0579C175.552 59.0579 176 58.6102 176 58.0579V52.0579H180V58.0579C180 58.6102 180.448 59.0579 181 59.0579H187C187.552 59.0579 188 58.6102 188 58.0579V47.0897L189.218 47.966C189.446 48.13 189.757 48.0686 189.913 47.8289C190.069 47.5892 190.01 47.2619 189.782 47.0979L180.584 40.4783ZM173 46.37L180 41.3323L187 46.37V58.0579H181V52.0579C181 51.5056 180.552 51.0579 180 51.0579H176C175.448 51.0579 175 51.5056 175 52.0579V58.0579L173 58.0579V46.37Z"
      fill="#0A1535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.75 40.5579H251.25L251.25 59.5579H268.75V40.5579ZM251.25 39.5579C250.698 39.5579 250.25 40.0056 250.25 40.5579V59.5579C250.25 60.1101 250.698 60.5579 251.25 60.5579H268.75C269.302 60.5579 269.75 60.1101 269.75 59.5579V40.5579C269.75 40.0056 269.302 39.5579 268.75 39.5579H251.25ZM260 47.7579C261.008 47.7579 261.825 46.9408 261.825 45.9329C261.825 44.9249 261.008 44.1079 260 44.1079C258.992 44.1079 258.175 44.9249 258.175 45.9329C258.175 46.9408 258.992 47.7579 260 47.7579ZM260 48.5579C261.45 48.5579 262.625 47.3826 262.625 45.9329C262.625 44.4831 261.45 43.3079 260 43.3079C258.55 43.3079 257.375 44.4831 257.375 45.9329C257.375 47.3826 258.55 48.5579 260 48.5579ZM257.3 50.0579C257.134 50.0579 257 50.1922 257 50.3579C257 50.5235 257.134 50.6579 257.3 50.6579H262.7C262.866 50.6579 263 50.5235 263 50.3579C263 50.1922 262.866 50.0579 262.7 50.0579H257.3Z"
      fill="#0A1535"
    />
    <path d="M20 78L22.8868 73H17.1132L20 78ZM19.5 62V73.5H20.5V62H19.5Z" fill="#0A1535" />
    <circle cx="20" cy="90" r="11.5" fill="white" stroke="#FBB000" />
    <path
      d="M15.4143 93.772C15.4143 93.892 15.5103 94 15.6423 94H16.4223C16.5423 94 16.6503 93.892 16.6503 93.772V90.952H18.3903C19.8543 90.952 21.0663 89.74 21.0663 88.252C21.0663 86.8 19.8543 85.6 18.3783 85.6H15.6423C15.5103 85.6 15.4143 85.708 15.4143 85.828V93.772ZM16.6503 89.776V86.8H18.3543C19.1823 86.8 19.8303 87.424 19.8303 88.24C19.8303 89.104 19.1823 89.776 18.3543 89.776H16.6503ZM23.2481 93.76C23.2481 93.892 23.3561 94 23.4881 94H24.2441C24.3641 94 24.4601 93.892 24.4601 93.76V85.828C24.4601 85.708 24.3641 85.6 24.2441 85.6H23.8721C23.7041 85.6 23.5841 85.648 23.4521 85.756L21.8801 87.184C21.8321 87.244 21.8081 87.34 21.8441 87.436L21.9881 87.868C22.0241 87.976 22.1561 88 22.2641 87.952L23.2481 87.484V93.76Z"
      fill="#FBB000"
    />
    <line x1="20.5" y1="102" x2="20.5" y2="118" stroke="#0A1535" />
    <path d="M100 78L102.887 73H97.1132L100 78ZM99.5 62V73.5H100.5V62H99.5Z" fill="#D3D5D8" />
    <circle cx="100" cy="90" r="11.5" fill="white" stroke="#D3D5D8" />
    <path
      d="M94.3186 93.772C94.3186 93.892 94.4146 94 94.5466 94H95.3266C95.4466 94 95.5546 93.892 95.5546 93.772V90.952H97.2946C98.7586 90.952 99.9706 89.74 99.9706 88.252C99.9706 86.8 98.7586 85.6 97.2826 85.6H94.5466C94.4146 85.6 94.3186 85.708 94.3186 85.828V93.772ZM95.5546 89.776V86.8H97.2586C98.0866 86.8 98.7346 87.424 98.7346 88.24C98.7346 89.104 98.0866 89.776 97.2586 89.776H95.5546ZM101.039 93.736C101.039 93.88 101.159 94 101.303 94H101.915C102.143 94 102.239 93.916 102.239 93.736V90.28C102.323 90.088 102.827 89.476 103.703 89.476C104.423 89.476 104.795 90.004 104.795 90.904V93.736C104.795 93.88 104.915 94 105.059 94H105.731C105.863 94 105.995 93.88 105.995 93.736V90.952C105.995 89.512 105.539 88.336 103.847 88.336C102.743 88.336 102.107 88.96 101.987 89.104L101.819 88.624C101.795 88.516 101.711 88.456 101.591 88.456H101.303C101.159 88.456 101.039 88.588 101.039 88.72V93.736Z"
      fill="#D3D5D8"
    />
    <line x1="100.5" y1="102" x2="100.5" y2="118" stroke="#D3D5D8" />
    <ellipse cx="12" cy="0.5" rx="12" ry="0.5" transform="matrix(1 0 0 -1 168 63)" fill="white" />
    <path d="M180 63L180 118" stroke="#0A1535" />
    <line x1="260.5" y1="62" x2="260.5" y2="118" stroke="#0A1535" />
    <path d="M140 118V134" stroke="#0A1535" />
    <circle cx="140" cy="146" r="11.5" fill="white" stroke="#61687A" />
    <path
      d="M136.222 145.812C136.222 148.212 138.154 150.12 140.542 150.12C142.282 150.12 143.518 149.328 143.518 149.328C143.566 149.292 143.614 149.196 143.614 149.136V146.232C143.614 146.112 143.518 146.004 143.398 146.004H141.202C141.07 146.004 140.974 146.1 140.974 146.232V146.928C140.974 147.048 141.07 147.144 141.202 147.144H142.378V148.488C142.258 148.536 141.574 148.896 140.578 148.896C138.874 148.896 137.566 147.456 137.566 145.788C137.566 144.108 138.874 142.668 140.578 142.668C141.31 142.668 142.054 142.992 142.618 143.484C142.738 143.592 142.834 143.604 142.93 143.496C143.11 143.304 143.29 143.124 143.47 142.932C143.566 142.836 143.554 142.692 143.458 142.596C142.642 141.9 141.694 141.48 140.542 141.48C138.154 141.48 136.222 143.412 136.222 145.812Z"
      fill="#61687A"
    />
    <path d="M140 158V174" stroke="#0A1535" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.262 176C137.799 176 137.396 176.319 137.289 176.77L136.763 179H131.5C131.224 179 131 179.224 131 179.5C131 179.776 131.224 180 131.5 180H136.528L135.349 185H132.5C132.224 185 132 185.224 132 185.5C132 185.776 132.224 186 132.5 186H135.113L131.104 203H127.5C127.224 203 127 203.224 127 203.5C127 203.776 127.224 204 127.5 204H152.5C152.776 204 153 203.776 153 203.5C153 203.224 152.776 203 152.5 203H148.896L144.887 186H147.5C147.776 186 148 185.776 148 185.5C148 185.224 147.776 185 147.5 185H144.651L143.472 180H148.5C148.776 180 149 179.776 149 179.5C149 179.224 148.776 179 148.5 179H143.237L142.711 176.77C142.604 176.319 142.201 176 141.738 176H138.262ZM146.914 198.951L144.746 189.757L144.735 189.766L140.605 193.4L146.914 198.951ZM147.231 200.296L147.868 203H132.132L132.769 200.296L140 193.933L147.231 200.296ZM139.395 193.4L133.086 198.951L135.254 189.757C135.258 189.76 135.261 189.763 135.265 189.766L139.395 193.4ZM140 192.867L144.383 189.01L140 185.401L135.617 189.01L140 192.867ZM140.629 184.882L144.311 187.915L143.114 182.836L140.629 184.882ZM142.909 181.968L140 184.364L137.091 181.968L138.262 177H141.738L142.909 181.968ZM139.371 184.882L135.689 187.915L136.886 182.836L139.371 184.882Z"
      fill="#0A1535"
    />
  </svg>
);

export const MeasurementConceptDifferential = () => (
  <svg width="280" height="226" viewBox="0 0 280 226" fill="none">
    <circle cx="20.0002" cy="26.0578" r="4.26724" fill="#FFCB52" />
    <rect x="19.7156" y="17.8079" width="0.568965" height="2.84483" rx="0.284483" fill="#FFCB52" />
    <rect x="19.7156" y="31.4629" width="0.568965" height="2.84483" rx="0.284483" fill="#FFCB52" />
    <rect
      x="25.6328"
      y="20.0233"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 25.6328 20.0233)"
      fill="#FFCB52"
    />
    <rect
      x="15.9771"
      y="29.6788"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 15.9771 29.6788)"
      fill="#FFCB52"
    />
    <rect
      x="28.25"
      y="25.7734"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 28.25 25.7734)"
      fill="#FFCB52"
    />
    <rect
      x="14.595"
      y="25.7734"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 14.595 25.7734)"
      fill="#FFCB52"
    />
    <rect
      x="26.0349"
      y="31.6904"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 26.0349 31.6904)"
      fill="#FFCB52"
    />
    <rect
      x="16.3792"
      y="22.0347"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 16.3792 22.0347)"
      fill="#FFCB52"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8942 50.7579L28.7192 54.0579H25.6124L25.0124 50.7579H27.8942ZM27.7942 50.3579L26.8942 46.7579H24.2851L24.2124 46.3579H26.7942L26.2192 44.0579L23.7942 44.0579L24.2124 46.3579H23.8058L23.3876 44.0579L20.2 44.0579V46.3579H19.8V44.0579L16.6124 44.0579L16.1942 46.3579H15.7876L16.2058 44.0579L13.7808 44.0579L13.2058 46.3579H15.7876L15.7149 46.7579H13.1058L12.2058 50.3579H15.0604L14.9876 50.7579H12.1058L11.2808 54.0579H14.3876L14.9876 50.7579H15.3942L14.7942 54.0579H19.8V50.7579H20.2V54.0579H25.2058L24.6058 50.7579H25.0124L24.9396 50.3579H27.7942ZM24.5331 50.3579L24.6058 50.7579H20.2V50.3579H24.5331ZM19.8 50.3579V50.7579H15.3942L15.4669 50.3579H19.8ZM19.8 50.3579V46.7579H20.2V50.3579H19.8ZM20.2 46.7579V46.3579H23.8058L23.8785 46.7579H20.2ZM24.5331 50.3579L23.8785 46.7579H24.2851L24.9396 50.3579H24.5331ZM15.4669 50.3579H15.0604L15.7149 46.7579H16.1215L15.4669 50.3579ZM16.1215 46.7579L16.1942 46.3579H19.8V46.7579H16.1215ZM14 55.0579H10.6404C10.3151 55.0579 10.0764 54.7522 10.1553 54.4366L12.8107 43.8153C12.9219 43.3702 13.3219 43.0579 13.7808 43.0579H26.2192C26.6781 43.0579 27.078 43.3702 27.1893 43.8153L29.8447 54.4366C29.9236 54.7522 29.6849 55.0579 29.3596 55.0579H26V56.5579C26 56.834 25.7762 57.0579 25.5 57.0579C25.2239 57.0579 25 56.834 25 56.5579V55.0579H15V56.5579C15 56.834 14.7762 57.0579 14.5 57.0579C14.2239 57.0579 14 56.834 14 56.5579V55.0579Z"
      fill="#0A1535"
    />
    <circle cx="100" cy="26.0578" r="4.26724" fill="#D3D5D8" />
    <rect x="99.7156" y="17.8079" width="0.568965" height="2.84483" rx="0.284483" fill="#D3D5D8" />
    <rect x="99.7156" y="31.4629" width="0.568965" height="2.84483" rx="0.284483" fill="#D3D5D8" />
    <rect
      x="105.633"
      y="20.0233"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 105.633 20.0233)"
      fill="#D3D5D8"
    />
    <rect
      x="95.9771"
      y="29.6788"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(45 95.9771 29.6788)"
      fill="#D3D5D8"
    />
    <rect
      x="108.25"
      y="25.7734"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 108.25 25.7734)"
      fill="#D3D5D8"
    />
    <rect
      x="94.595"
      y="25.7734"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(90 94.595 25.7734)"
      fill="#D3D5D8"
    />
    <rect
      x="106.035"
      y="31.6904"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 106.035 31.6904)"
      fill="#D3D5D8"
    />
    <rect
      x="96.3792"
      y="22.0347"
      width="0.568965"
      height="2.84483"
      rx="0.284483"
      transform="rotate(135 96.3792 22.0347)"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.894 50.7579L108.719 54.0579H105.612L105.012 50.7579H107.894ZM107.794 50.3579L106.894 46.7579H104.285L104.212 46.3579H106.794L106.219 44.0579L103.794 44.0579L104.212 46.3579H103.806L103.388 44.0579L100.2 44.0579V46.3579H99.8V44.0579L96.6124 44.0579L96.1942 46.3579H95.7876L96.2058 44.0579L93.7808 44.0579L93.2058 46.3579H95.7876L95.7149 46.7579H93.1058L92.2058 50.3579H95.0604L94.9876 50.7579H92.1058L91.2808 54.0579H94.3876L94.9876 50.7579H95.3942L94.7942 54.0579H99.8V50.7579H100.2V54.0579H105.206L104.606 50.7579H105.012L104.94 50.3579H107.794ZM104.533 50.3579L104.606 50.7579H100.2V50.3579H104.533ZM99.8 50.3579V50.7579H95.3942L95.4669 50.3579H99.8ZM99.8 50.3579V46.7579H100.2V50.3579H99.8ZM100.2 46.7579V46.3579H103.806L103.879 46.7579H100.2ZM104.533 50.3579L103.879 46.7579H104.285L104.94 50.3579H104.533ZM95.4669 50.3579H95.0604L95.7149 46.7579H96.1215L95.4669 50.3579ZM96.1215 46.7579L96.1942 46.3579H99.8V46.7579H96.1215ZM94 55.0579H90.6404C90.3151 55.0579 90.0764 54.7522 90.1553 54.4366L92.8107 43.8153C92.9219 43.3702 93.3219 43.0579 93.7808 43.0579H106.219C106.678 43.0579 107.078 43.3702 107.189 43.8153L109.845 54.4366C109.924 54.7522 109.685 55.0579 109.36 55.0579H106V56.5579C106 56.834 105.776 57.0579 105.5 57.0579C105.224 57.0579 105 56.834 105 56.5579V55.0579H95V56.5579C95 56.834 94.7762 57.0579 94.5 57.0579C94.2239 57.0579 94 56.834 94 56.5579V55.0579Z"
      fill="#D3D5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.584 40.4783C180.235 40.2271 179.765 40.2271 179.416 40.4783L170.218 47.0979C169.99 47.2619 169.931 47.5892 170.087 47.8289C170.243 48.0686 170.554 48.13 170.782 47.966L172 47.0897V58.0579C172 58.6102 172.448 59.0579 173 59.0579H175C175.552 59.0579 176 58.6102 176 58.0579V52.0579H180V58.0579C180 58.6102 180.448 59.0579 181 59.0579H187C187.552 59.0579 188 58.6102 188 58.0579V47.0897L189.218 47.966C189.446 48.13 189.757 48.0686 189.913 47.8289C190.069 47.5892 190.01 47.2619 189.782 47.0979L180.584 40.4783ZM173 46.37L180 41.3323L187 46.37V58.0579H181V52.0579C181 51.5056 180.552 51.0579 180 51.0579H176C175.448 51.0579 175 51.5056 175 52.0579V58.0579L173 58.0579V46.37Z"
      fill="#0A1535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.75 40.5579H251.25L251.25 59.5579H268.75V40.5579ZM251.25 39.5579C250.698 39.5579 250.25 40.0056 250.25 40.5579V59.5579C250.25 60.1101 250.698 60.5579 251.25 60.5579H268.75C269.302 60.5579 269.75 60.1101 269.75 59.5579V40.5579C269.75 40.0056 269.302 39.5579 268.75 39.5579H251.25ZM260 47.7579C261.008 47.7579 261.825 46.9408 261.825 45.9329C261.825 44.9249 261.008 44.1079 260 44.1079C258.992 44.1079 258.175 44.9249 258.175 45.9329C258.175 46.9408 258.992 47.7579 260 47.7579ZM260 48.5579C261.45 48.5579 262.625 47.3826 262.625 45.9329C262.625 44.4831 261.45 43.3079 260 43.3079C258.55 43.3079 257.375 44.4831 257.375 45.9329C257.375 47.3826 258.55 48.5579 260 48.5579ZM257.3 50.0579C257.134 50.0579 257 50.1922 257 50.3579C257 50.5235 257.134 50.6579 257.3 50.6579H262.7C262.866 50.6579 263 50.5235 263 50.3579C263 50.1922 262.866 50.0579 262.7 50.0579H257.3Z"
      fill="#0A1535"
    />
    <path d="M20 78L22.8868 73H17.1132L20 78ZM19.5 62V73.5H20.5V62H19.5Z" fill="#0A1535" />
    <circle cx="20" cy="90" r="11.5" fill="white" stroke="#FBB000" />
    <path
      d="M15.4143 93.772C15.4143 93.892 15.5103 94 15.6423 94H16.4223C16.5423 94 16.6503 93.892 16.6503 93.772V90.952H18.3903C19.8543 90.952 21.0663 89.74 21.0663 88.252C21.0663 86.8 19.8543 85.6 18.3783 85.6H15.6423C15.5103 85.6 15.4143 85.708 15.4143 85.828V93.772ZM16.6503 89.776V86.8H18.3543C19.1823 86.8 19.8303 87.424 19.8303 88.24C19.8303 89.104 19.1823 89.776 18.3543 89.776H16.6503ZM23.2481 93.76C23.2481 93.892 23.3561 94 23.4881 94H24.2441C24.3641 94 24.4601 93.892 24.4601 93.76V85.828C24.4601 85.708 24.3641 85.6 24.2441 85.6H23.8721C23.7041 85.6 23.5841 85.648 23.4521 85.756L21.8801 87.184C21.8321 87.244 21.8081 87.34 21.8441 87.436L21.9881 87.868C22.0241 87.976 22.1561 88 22.2641 87.952L23.2481 87.484V93.76Z"
      fill="#FBB000"
    />
    <line x1="20.5" y1="102" x2="20.5" y2="118" stroke="#0A1535" />
    <path d="M100 78L102.887 73H97.1132L100 78ZM99.5 62V73.5H100.5V62H99.5Z" fill="#D3D5D8" />
    <circle cx="100" cy="90" r="11.5" fill="white" stroke="#D3D5D8" />
    <path
      d="M94.3186 93.772C94.3186 93.892 94.4146 94 94.5466 94H95.3266C95.4466 94 95.5546 93.892 95.5546 93.772V90.952H97.2946C98.7586 90.952 99.9706 89.74 99.9706 88.252C99.9706 86.8 98.7586 85.6 97.2826 85.6H94.5466C94.4146 85.6 94.3186 85.708 94.3186 85.828V93.772ZM95.5546 89.776V86.8H97.2586C98.0866 86.8 98.7346 87.424 98.7346 88.24C98.7346 89.104 98.0866 89.776 97.2586 89.776H95.5546ZM101.039 93.736C101.039 93.88 101.159 94 101.303 94H101.915C102.143 94 102.239 93.916 102.239 93.736V90.28C102.323 90.088 102.827 89.476 103.703 89.476C104.423 89.476 104.795 90.004 104.795 90.904V93.736C104.795 93.88 104.915 94 105.059 94H105.731C105.863 94 105.995 93.88 105.995 93.736V90.952C105.995 89.512 105.539 88.336 103.847 88.336C102.743 88.336 102.107 88.96 101.987 89.104L101.819 88.624C101.795 88.516 101.711 88.456 101.591 88.456H101.303C101.159 88.456 101.039 88.588 101.039 88.72V93.736Z"
      fill="#D3D5D8"
    />
    <line x1="100.5" y1="102" x2="100.5" y2="118" stroke="#D3D5D8" />
    <ellipse cx="12" cy="0.5" rx="12" ry="0.5" transform="matrix(1 0 0 -1 168 63)" fill="white" />
    <path d="M180 63L180 118" stroke="#0A1535" />
    <line x1="260.5" y1="62" x2="260.5" y2="118" stroke="#0A1535" />
    <path d="M140 118V134" stroke="#0A1535" />
    <circle cx="140" cy="146" r="11.5" fill="white" stroke="#61687A" />
    <path
      d="M136.656 149.772C136.656 149.892 136.752 150 136.872 150H139.632C141.948 150 143.844 148.116 143.844 145.788C143.844 143.484 141.948 141.6 139.632 141.6H136.872C136.752 141.6 136.656 141.708 136.656 141.828V149.772ZM137.88 148.86V142.74H139.5C141.264 142.74 142.512 144.024 142.512 145.788C142.512 147.564 141.264 148.86 139.5 148.86H137.88Z"
      fill="#61687A"
    />
    <path d="M140 158V174" stroke="#0A1535" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.262 176C137.799 176 137.396 176.319 137.289 176.77L136.763 179H131.5C131.224 179 131 179.224 131 179.5C131 179.776 131.224 180 131.5 180H136.528L135.349 185H132.5C132.224 185 132 185.224 132 185.5C132 185.776 132.224 186 132.5 186H135.113L131.104 203H127.5C127.224 203 127 203.224 127 203.5C127 203.776 127.224 204 127.5 204H152.5C152.776 204 153 203.776 153 203.5C153 203.224 152.776 203 152.5 203H148.896L144.887 186H147.5C147.776 186 148 185.776 148 185.5C148 185.224 147.776 185 147.5 185H144.651L143.472 180H148.5C148.776 180 149 179.776 149 179.5C149 179.224 148.776 179 148.5 179H143.237L142.711 176.77C142.604 176.319 142.201 176 141.738 176H138.262ZM146.914 198.951L144.746 189.757L144.735 189.766L140.605 193.4L146.914 198.951ZM147.231 200.296L147.868 203H132.132L132.769 200.296L140 193.933L147.231 200.296ZM139.395 193.4L133.086 198.951L135.254 189.757C135.258 189.76 135.261 189.763 135.265 189.766L139.395 193.4ZM140 192.867L144.383 189.01L140 185.401L135.617 189.01L140 192.867ZM140.629 184.882L144.311 187.915L143.114 182.836L140.629 184.882ZM142.909 181.968L140 184.364L137.091 181.968L138.262 177H141.738L142.909 181.968ZM139.371 184.882L135.689 187.915L136.886 182.836L139.371 184.882Z"
      fill="#0A1535"
    />
    <path d="M140 166H260.5V118" stroke="#0A1535" />
    <line x1="20" y1="117.5" x2="180" y2="117.5" stroke="#0A1535" />
  </svg>
);
