import { isNil, sortBy } from 'lodash';

import { TimeOfUseValidationError } from '+shared/store/battery';
import { UtilDuration } from '+utils/UtilDuration';

import {
  TARIFF_WINDOW_MINIMAL_COLLISION,
  TARIFF_WINDOW_MINIMUM_DURATION,
} from './battery-tariff.consts';
import { TariffRes, TariffType, TariffWindow, TariffWindowInternal } from './battery-tariff.types';

const mapTariffWindowToTariffElements = (tariffWindow: TariffWindow, index: any): any[] => {
  const start = UtilDuration.ofString(tariffWindow.start).asHours();
  const stop = UtilDuration.ofString(tariffWindow.stop).asHours();

  const isOverlappingMidnight = start > stop;

  return isOverlappingMidnight
    ? [
        {
          id: index,
          start: tariffWindow.start,
          stop: '24:00',
        },
        {
          id: index,
          start: '00:00',
          stop: tariffWindow.stop,
        },
      ]
    : [
        {
          id: index,
          start: tariffWindow.start,
          stop: tariffWindow.stop,
        },
      ];
};

const mapTariffWindowsToInternalRepresentation = (
  tariffWindows: TariffWindow[]
): TariffWindowInternal[] => {
  return tariffWindows.map((tariffWindow) => ({
    window: tariffWindow,
    elements: mapTariffWindowToTariffElements(tariffWindow, `one`),
  }));
};

/**
 * @returns duplicate free collection of tariff windows,
 * it will keep the LAST occurrence of duplicate
 */
const removeDuplicateTariffWindows = (tariffWindows: TariffWindow[]): TariffWindow[] => {
  return Array.from(
    new Map(tariffWindows.map((tariffWindow) => [tariffWindow.id, tariffWindow])).values()
  );
};

/**
 * @returns sorted tariff windows by 'stop' property,
 * it's needed for listing windows in ascending order starting from 00:00
 */
const sortTariffWindows = (tariffWindows: TariffWindow[]): TariffWindow[] => {
  return sortBy(tariffWindows, 'start');
};

const isOverlappingTariffWindowRangeNew = (
  tariffWindows: TariffWindow[],
  tariffWindowNew: TariffWindow
): boolean => {
  let isCollisionDetected = false;
  const tariffWindowNewStart = UtilDuration.ofString(tariffWindowNew.start).asHours();
  const tariffWindowNewStop = UtilDuration.ofString(tariffWindowNew.stop).asHours();
  const tariffWindowInterval = UtilDuration.ofMinutes(TARIFF_WINDOW_MINIMAL_COLLISION).asHours();

  // loop for checking if we have collision
  tariffWindows.forEach((window) => {
    const windowStart = UtilDuration.ofString(window.start).asHours();
    const windowStop = UtilDuration.ofString(window.stop).asHours();

    // checking start
    const collision1 =
      (tariffWindowNewStart >= windowStart + tariffWindowInterval &&
        tariffWindowNewStart <= windowStop - tariffWindowInterval) ||
      tariffWindowNewStart === windowStart;

    // checking stop
    const collision2 =
      (tariffWindowNewStop >= windowStart + tariffWindowInterval &&
        tariffWindowNewStop <= windowStop - tariffWindowInterval) ||
      tariffWindowNewStop === windowStop;

    // checking overlap
    const collision3 =
      tariffWindowNewStart <= windowStop - tariffWindowInterval &&
      tariffWindowNewStop >= windowStart + tariffWindowInterval;

    // to avoid detection collision with self
    if (window.id !== tariffWindowNew.id) {
      if (collision1) {
        isCollisionDetected = collision1;
      }
      if (collision2) {
        isCollisionDetected = collision2;
      }
      if (collision3) {
        isCollisionDetected = collision3;
      }
    }
  });

  return isCollisionDetected;
};

const isTariffWindowToShort = (tariffWindowNew: TariffWindow): boolean => {
  const tariffWindowNewStart = UtilDuration.ofString(tariffWindowNew.start).asHours();
  const tariffWindowNewStop = UtilDuration.ofString(tariffWindowNew.stop).asHours();
  const tariffWindowInterval = UtilDuration.ofMinutes(TARIFF_WINDOW_MINIMUM_DURATION).asHours();

  return Math.abs(tariffWindowNewStop - tariffWindowNewStart) < tariffWindowInterval;
};

const removeTariffWindow = (
  tariffWindows: TariffWindow[],
  tariffWindowToRemove: TariffWindow
): TariffWindow[] => {
  return tariffWindows.filter((tariffWindow) => tariffWindow.id !== tariffWindowToRemove.id);
};

const isTouValidationError = (validationError: any): validationError is TimeOfUseValidationError =>
  'error' in validationError && 'errorType' in validationError;

const convertTariffRes = (
  tariffRes: TariffRes[] | null | undefined | TimeOfUseValidationError,
  id: any
): TariffWindow[] => {
  if (isNil(tariffRes)) return [];

  if (isTouValidationError(tariffRes)) return [];

  return tariffRes.map((tariff) => {
    return 'thresholdPMax' in tariff
      ? { type: TariffType.OFF_PEAK, ...tariff, id: id() }
      : { type: TariffType.PEAK, ...tariff, id: id() };
  });
};

const prepareTimeOfUseDataToSend = (
  data: TariffWindow[]
): Array<{ stop: string; threshold_p_max: number | undefined; start: string }> => {
  return (
    data
      .filter((v) => v.type === TariffType.OFF_PEAK)
      .map((v) => ({
        start: v.start,
        stop: v.stop,
        threshold_p_max: v.thresholdPMax,
      })) || []
  );
};

const prepareHighTariffWindowsDataToSend = (
  data: TariffWindow[]
): Array<{ stop: string; start: string }> => {
  return data
    .filter((v) => v.type === TariffType.PEAK)
    .map((v) => ({
      start: v.start,
      stop: v.stop,
    }));
};

export const tariffWindowManipulator = {
  mapTariffWindowToTariffElements,
  mapTariffWindowsToInternalRepresentation,
  removeDuplicateTariffWindows,
  sortTariffWindows,
  removeTariffWindow,
  convertTariffRes,
  prepareHighTariffWindowsDataToSend,
  prepareTimeOfUseDataToSend,
  isOverlappingTariffWindowRangeNew,
  isTariffWindowLongEnough: isTariffWindowToShort,
};
