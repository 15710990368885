import { uniq } from 'lodash';

import { formatLabelToNumber, isBatteryAvailableForSale } from '+shared/store/lead/lead.helpers';
import { BatteryModelName } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';

export const formFields = {
  MODEL_NAME: 'modelName',
  CAPACITY_GROSS: 'capacityGross',
};

export const mapBatteryModelNameCollection = (productBatteryList: LeadProductBattery[]) =>
  uniq(
    productBatteryList
      .filter(isBatteryAvailableForSale)
      .map((productBattery) => productBattery.modelName)
  );

export const mapBatteryCapacityCollection = (
  productBatteryList: LeadProductBattery[],
  modelName: BatteryModelName
) =>
  productBatteryList
    .filter(isBatteryAvailableForSale)
    .filter((battery) => battery.modelName === modelName)
    .sort(
      (capacityLabelOne, capacityLabelTwo) =>
        formatLabelToNumber(capacityLabelOne.name) - formatLabelToNumber(capacityLabelTwo.name)
    )
    .map((battery) => battery.id);
