import * as React from 'react';

import { ListTable, ListTableBorderColor, ListTableHeader } from '+shared/components';
import { Contact } from '+shared/store/contact/types';
import { Lead } from '+shared/store/lead/types';
import { UserRole } from '+shared/store/user';

import { LeadListTableItem } from '../LeadListTableItem';
import { getLeadListTableHeaders } from './LeadListTable.const';

interface Props {
  items: Lead[];
  isPending: boolean;
  onLeadClick: (leadId: string) => void;
  partnerEmployeeList: Contact[];
  userRoles: UserRole[];
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  toggleAssignLeadModal: (arg: boolean) => void;
  setChosenLead: (arg: Lead) => void;
}

const mapBorderColor = (lead: Lead) =>
  lead.isNewlyAssigned ? ListTableBorderColor.GREEN : undefined;

export const LeadListTable: React.FC<Props> = ({
  items,
  isPending,
  onLeadClick,
  toggleModal,
  isModalOpen,
  partnerEmployeeList,
  userRoles,
  toggleAssignLeadModal,
  setChosenLead,
}) => (
  <ListTable
    className={`c-list-table--lead-list`}
    headers={getLeadListTableHeaders(userRoles)}
    items={items}
    isPending={isPending}
    hoverable={true}
    noBorder={true}
    renderHeader={(header) => (
      <ListTableHeader className={`c-list-table__header--lead-list`} item={header} />
    )}
    renderItem={(item) => (
      <LeadListTableItem
        item={item}
        onLeadClick={onLeadClick}
        partnerEmployeeList={partnerEmployeeList}
        userRoles={userRoles}
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
        toggleAssignLeadModal={toggleAssignLeadModal}
        setChosenLead={setChosenLead}
      />
    )}
    mapBorderLeftColor={mapBorderColor}
    dataTestIdTable="leads-overview-table"
    dataTestIdHeader="leads-overview-table-header"
  />
);
