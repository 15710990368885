import * as React from 'react';
import { Tooltip } from 'react-tooltip';

import './NewFeatureTooltip.component.scss';

interface Props {
  isOverlayActive?: boolean;
  bubbleChildren: React.ReactNode;
  tooltipId: string;
}

export const NewFeatureTooltip: React.FC<Props> = ({
  children,
  isOverlayActive = true,
  bubbleChildren,
  tooltipId,
}) => {
  React.useEffect(() => {
    if (isOverlayActive) {
      document.body.classList.add('body--disabled');
    } else {
      document.body.classList.remove('body--disabled');
    }
  }, [isOverlayActive]);

  return (
    <>
      {isOverlayActive ? (
        <>
          <div className={'c-new-feature-tooltip__active-element-reference'}>
            <div className={'c-new-feature-tooltip__active-element-wrapper'}>{children}</div>
            <div className={'c-new-feature-tooltip__active-element-background'} />
            <Tooltip
              id={tooltipId}
              className="reusable-class__tooltip new-feature-tooltip"
              classNameArrow="reusable-class__tooltip--arrow"
              place="bottom"
              isOpen={true}
              clickable={true}
              offset={20}
            >
              {bubbleChildren}
            </Tooltip>
          </div>
          <div className={'c-new-feature-tooltip__overlay'} />
        </>
      ) : (
        children
      )}
    </>
  );
};
