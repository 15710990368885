import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const BinSmall: React.FC<Props> = ({ color, size = 16, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.11803 2H8.88197L9.38197 3H6.61803L7.11803 2ZM5.5 3L6.22361 1.55279C6.393 1.214 6.73926 1 7.11803 1H8.88197C9.26074 1 9.607 1.214 9.77639 1.55279L10.5 3H12H13H13.5C13.7761 3 14 3.22386 14 3.5C14 3.77614 13.7761 4 13.5 4H13V14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 14.5523 3 14V4H2.5C2.22386 4 2 3.77614 2 3.5C2 3.22386 2.22386 3 2.5 3H3H4H5.5ZM11 4H9.88197H6.11803H5H4V14H12V4H11ZM6 6.5C6 6.22386 6.22386 6 6.5 6C6.77614 6 7 6.22386 7 6.5V11.5C7 11.7761 6.77614 12 6.5 12C6.22386 12 6 11.7761 6 11.5V6.5ZM9.5 6C9.22386 6 9 6.22386 9 6.5V11.5C9 11.7761 9.22386 12 9.5 12C9.77614 12 10 11.7761 10 11.5V6.5C10 6.22386 9.77614 6 9.5 6Z"
      fill={color}
    />
  </svg>
);

export const BinLarge: React.FC<Props> = ({ color, size = 24, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path d="NOT EXPORTED FROM FIGMA" stroke={color} />
  </svg>
);
