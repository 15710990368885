import {
  getHardwareProduct,
  getProductIdFromOffer,
  setFlatStatusToSent,
} from '+app/+lead/+offer/store/+offer.helper';
import { LEAD_ACTIONS, LeadActions } from '+shared/store/lead';
import { LeadImpactAnalysisResponse } from '+shared/store/lead/types';
import { LeadOffer, OfferStatus } from '+shared/store/lead/types/leadOffer.interface';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux/createReducer.util';

import {
  IMPACT_ANALYSIS_ACTIONS,
  ImpactAnalysisActions,
} from '../../+impactAnalysis/store/+impactAnalysis.actions';
import { LEAD_PAGE_ACTIONS, LeadPageActions } from '../../store/+lead.actions';
import { LEAD_OVERVIEW_PAGE_ACTIONS, LeadOverviewPageActions } from './+overview.actions';
import {
  CLOSE_LEAD_QUERY,
  DELETE_OFFER_QUERY,
  GET_LEAD_PRODUCT_AVAILABILITY_QUERY,
  GET_OFFER_LIST_QUERY,
  GET_PARTNER_NOTE_QUERY,
  GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY,
  GET_PRODUCT_BATTERY_LIST_QUERY,
  GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY,
  initialState,
  LeadOverviewPageState,
  POST_PARTNER_NOTE_QUERY,
  RECALCULATE_CONFIGURATION_QUERY,
  SEND_CG_INVITATION_QUERY,
} from './+overview.state';

type Actions = LeadOverviewPageActions | LeadPageActions | LeadActions | ImpactAnalysisActions;

export const reducer = createReducer<LeadOverviewPageState, Actions>(
  (state = initialState, action): LeadOverviewPageState => {
    switch (action.type) {
      case LEAD_OVERVIEW_PAGE_ACTIONS.GET_OFFER_LIST_SUCCESS:
        return {
          ...state,
          offers: action.offers,
          hasActiveDynamicTariffContract: action.hasActiveDynamicTariffContract,
        };
      case LEAD_ACTIONS.SET_CONFIGURATION:
        return {
          ...state,
          offers: state.offers.map((offer) => {
            if (!offer.configuration || offer.configuration.id !== action.configuration.id) {
              return offer;
            }
            return {
              ...offer,
              configuration: action.configuration,
            };
          }),
        };
      case IMPACT_ANALYSIS_ACTIONS.SET_IMPACT_ANALYSIS:
        return {
          ...state,
          offers: state.offers.map((offer) => {
            if (!offer.impactAnalysis || offer.impactAnalysis.id !== action.impactAnalysis.id) {
              return offer;
            }
            return {
              ...offer,
              impactAnalysis: action.impactAnalysis,
            };
          }),
        };
      case IMPACT_ANALYSIS_ACTIONS.DELETE_IMPACT_ANALYSIS_SUCCESS:
        return {
          ...state,
          offers: state.offers.map((offer: LeadOffer) => {
            if (
              !offer.impactAnalysis ||
              offer.impactAnalysis.id !== action.deletedImpactAnalysisId
            ) {
              return offer;
            }
            return {
              ...offer,
              impactAnalysis: {} as LeadImpactAnalysisResponse,
            };
          }),
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_OFFERS:
        return {
          ...state,
          offers: initialState.offers,
          getOfferListQuery: initialState[GET_OFFER_LIST_QUERY],
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.DELETE_OFFER_SUCCESS:
        return {
          ...state,
          offers: state.offers.filter((offer) => offer.id !== action.offerId),
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_UPDATED_LEAD_SECTION:
        return {
          ...state,
          lastUpdatedLeadSection: { ...action.updatedSection },
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.GET_OFFER_DOCUMENT:
      case IMPACT_ANALYSIS_ACTIONS.DOWNLOAD_IMPACT_ANALYSIS_FILE:
        return {
          ...state,
          isOpeningDocument: true,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.OPEN_OFFER_DOCUMENT:
        return {
          ...state,
          documentUrl: action.url,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.DOCUMENT_OPENED:
        return {
          ...state,
          isOpeningDocument: false,
          documentUrl: undefined,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.DOCUMENT_FAILED:
        return {
          ...state,
          isOpeningDocument: false,
          failedDocumentModal: true,
          failedDocument: {
            resourceId: action.resourceId,
            resourceType: action.resourceType,
            documentType: action.documentType,
          },
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CREATE_DOCUMENT:
        return {
          ...state,
          failedDocumentModal: false,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLOSE_DOCUMENT_FAILED:
        return {
          ...state,
          failedDocumentModal: false,
        };
      case LEAD_PAGE_ACTIONS.SET_PARTNER_NOTE:
      case LEAD_OVERVIEW_PAGE_ACTIONS.GET_PARTNER_NOTE_SUCCESS:
        return {
          ...state,
          partnerNotes: action.note,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_PARTNER_NOTE:
        return {
          ...state,
          partnerNotes: initialState.partnerNotes,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_UPDATING_OFFER:
        return {
          ...state,
          updatingOffer: action.offer,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_UPDATING_OFFER:
        return {
          ...state,
          updatingOffer: null,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_NEW_LEAD_ADDRESS:
        return {
          ...state,
          newLeadAddress: action.newAddress,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_NEW_LEAD_ADDRESS:
        return {
          ...state,
          newLeadAddress: initialState.newLeadAddress,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_NEW_ADDRESS_ERROR_ACTIVE:
        return {
          ...state,
          isNewAddressErrorActive: action.isActive,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_PRODUCT_AVAILABILITY:
        return {
          ...state,
          productAvailability: action.productAvailability,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_PRODUCT_AVAILABILITY:
        return {
          ...state,
          productAvailability: initialState.productAvailability,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_PRODUCT_AVAILABILITY_FOR_ADDRESS:
        return {
          ...state,
          productAvailabilityForAddress: action.productAvailability,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_PRODUCT_AVAILABILITY_FOR_ADDRESS:
        return {
          ...state,
          productAvailabilityForAddress: initialState.productAvailability,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SAVE_ASYNC_OFFER_SENT_ID:
        return {
          ...state,
          offerIdToChangeStatus: action.offerId,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_ASYNC_FLAT_OFFER_SENT_STATUS:
        return {
          ...state,
          offers: setFlatStatusToSent(state.offers, action.offerId),
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_ASYNC_OFFER_SENT_ID:
        return {
          ...state,
          offerIdToChangeStatus: undefined,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_PRODUCT_BATTERY_LIST:
        return {
          ...state,
          productBatteryList: action.productBatteryList,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_HARDWARE_OFFER_STATUS:
        return {
          ...state,
          offers: state.offers.map((offer) => ({
            ...offer,
            products: offer.products.map((product) =>
              product.productId === getProductIdFromOffer(state.offers, action.offerId)
                ? {
                    ...product,
                    status: action.status,
                  }
                : product
            ),
          })),
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.SET_BLOCKED_STATUS_ON_DIFFERENT_HW_OFFERS:
        return {
          ...state,
          offers: state.offers.map((offer) =>
            getHardwareProduct(offer)?.productId === action.productId
              ? offer
              : {
                  ...offer,
                  status: OfferStatus.BLOCKED,
                }
          ),
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.DISPLAY_MAX_OFFERS_WARNING:
        return {
          ...state,
          displayMaxOffersWarning: true,
        };
      case LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_MAX_OFFERS_WARNING:
        return {
          ...state,
          displayMaxOffersWarning: false,
        };
      default:
        return {
          ...queryReducer(state, action, [
            GET_OFFER_LIST_QUERY,
            DELETE_OFFER_QUERY,
            POST_PARTNER_NOTE_QUERY,
            GET_PARTNER_NOTE_QUERY,
            RECALCULATE_CONFIGURATION_QUERY,
            CLOSE_LEAD_QUERY,
            SEND_CG_INVITATION_QUERY,
            GET_LEAD_PRODUCT_AVAILABILITY_QUERY,
            GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY,
            GET_PRODUCT_BATTERY_LIST_QUERY,
            GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY,
          ]),
        };
    }
  }
);
