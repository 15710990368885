import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';

import { Formik } from 'formik';

import { mapActions } from '+app/utils';
import { ImpactAnalysisActions } from '+lead/+impactAnalysis/store/+impactAnalysis.actions';
import {
  getCostsForm,
  getImpactAnalysisRecommendation,
  getImpactAnalysisRecommendationQuery,
} from '+lead/+impactAnalysis/store/+impactAnalysis.selectors';
import { getLeadOfferData } from '+lead/+offer/store/+offer.selectors';
import { getLead } from '+lead/store/+lead.selectors';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { HeadlineLarge } from '+shared/basicComponents/Typography/Headlines';
import { Form } from '+shared/components';
import { Card } from '+shared/components/Card/Card';
import { QueryStatus } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';

import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { CalculationResultsForm } from '../../store/+impactAnalysis.interface';
import AmortizationInformation from './components/AmortizationInformation';
import AmortizationWarning from './components/AmortizationWarning';
import CumulativeSavingsAfter20Years from './components/CumulativeSavingsAfter20Years';
import {
  isAmortizationLessThan1Year,
  isAmortizationOver20Years,
  mapToPageData,
} from './LeadImpactAnalysisCalculationResults.helper';

const mapDispatchToProps = mapActions({
  getRecommendation: ImpactAnalysisActions.createImpactAnalysisRecommendation,
  setPdfCreation: ImpactAnalysisActions.setPdfCreationForm,
});

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  offer: getLeadOfferData(state),
  recommendation: getImpactAnalysisRecommendation(state),
  recommendationQuery: getImpactAnalysisRecommendationQuery(state),
  costsForm: getCostsForm(state),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LeadImpactAnalysisCalculationResultsDirectComponent: React.FC<Props> = ({
  actions,
  recommendation,
  recommendationQuery,
  lead,
  offer,
  costsForm,
}) => {
  const wizard = React.useContext(WizardContext);

  const onSubmit = () => {
    if (wizard.complete) {
      wizard.complete();
    }
  };

  useEffect(() => {
    if (lead && offer && costsForm) {
      actions.getRecommendation(lead.id, offer.id);
    }
  }, []);

  return (
    <Formik<CalculationResultsForm> initialValues={{}} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <HeadlineLarge text={I18n.t(T.lead.impactAnalysis._salessolution_.results.title)} />

            <LineDivider />

            {recommendation && recommendationQuery.status === QueryStatus.SUCCESS ? (
              <>
                {(isAmortizationOver20Years(recommendation.amortizationTimeFrame) ||
                  isAmortizationLessThan1Year(recommendation.amortizationTimeFrame)) && (
                  <AmortizationWarning
                    amortizationTimeFrame={recommendation.amortizationTimeFrame}
                    warningText={I18n.t(
                      T.lead.impactAnalysis._salessolution_.results.amortization.warningFlatDirekt
                    )}
                  />
                )}
                <CumulativeSavingsAfter20Years
                  data={mapToPageData(recommendation, costsForm?.version)}
                />
                <AmortizationInformation
                  amortizationTimeFrame={recommendation.amortizationTimeFrame}
                />
              </>
            ) : (
              <div className="c-impact-analysis-results__loader">
                <Loader />
              </div>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export const LeadImpactAnalysisCalculationResultsDirect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadImpactAnalysisCalculationResultsDirectComponent);
