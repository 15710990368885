import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Warning: React.FC<Props> = ({ color = '#0A1535', size = 16, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M8.44024 2.87221L13.766 12.763C13.9454 13.0961 13.7041 13.5 13.3258 13.5H2.67422C2.29588 13.5 2.05461 13.0961 2.23398 12.7629L7.55977 2.87221C7.74855 2.52161 8.25145 2.52161 8.44024 2.87221Z"
      stroke={color}
    />
    <circle cx="8" cy="11.25" r="0.75" fill={color} />
    <path
      d="M8.75 7.14286C8.75 8.08963 8 10 8 10C8 10 7.25 8.08963 7.25 7.14286C7.25 6.19608 7.58579 6 8 6C8.41421 6 8.75 6.19608 8.75 7.14286Z"
      fill={color}
    />
  </svg>
);
