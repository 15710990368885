import { OAuth2TokenResponse } from '@coolio/auth-interceptor';
import { JwtPayload } from 'jwt-decode';
import { PersistedState } from 'redux-persist';

import { ReverseChannelTokenAttributes } from './types/reverseChannel.interface';

export const GENERATE_REVERSE_CHANNEL_TOKEN_QUERY = 'generateReverseChannelTokenQuery';

export enum AuthContext {
  DEFAULT,
  SALESFORCE,
  NONE,
}

export enum PartnerApplications {
  SERVICE_PORTAL = 'Service Portal',
  PARTNER_TOOLBOX = 'Partner Toolbox',
  DEVICE_TOOL_APPLICATION = 'Device Tool Application',
}

export type TokenType = 'invitation' | 'reset-password' | 'confirmation';

export interface AuthConfig {
  responseType: string;
  redirectUri: string;
  clientId: string;
  scope?: string;
  state?: string;
  verifier?: string;
}

export interface AuthSalesforceParams {
  idToken: string;
  tokenType: string;
  issuedAt?: string;
  state?: string;
}

export interface AuthParams {
  code: string;
  state: string;
}

export interface SalesforceTokenStorage {
  impersonateUser: string;
  accessToken: string;
  tokenType: string;
}

export interface AuthState extends PersistedState {
  tokenStorage?: OAuth2TokenResponse;
  salesforceTokenStorage?: SalesforceTokenStorage;
  verifier?: string;
  reverseChannelToken?: ReverseChannelTokenAttributes;
}

export interface JwtDecodedUser extends JwtPayload {
  sub: string;
  customer_number: string;
  partner_billing_country_code: string;
  partner_contact_id: string;
  partner_profile_language: string;
  partner_sf_contact_id: string;
  partner_sf_user_id: string;
  partner_user_roles: string;
  partner_applications: string;
}

export const initialState: AuthState = {};
