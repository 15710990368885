import * as React from 'react';

import './WidgetSimple.component.scss';

interface Props {
  heading?: string;
  subheading?: string;
  grayedHeading?: string;
  dataTestIdHeader?: string;
  dataTestIdContent?: string;
}

export const WidgetSimple: React.SFC<Props> = ({
  heading,
  children,
  subheading,
  grayedHeading,
  dataTestIdHeader,
  dataTestIdContent,
}) => (
  <section className={'c-widget-simple'}>
    <header className={'c-widget-simple__header'} data-testid={dataTestIdHeader}>
      <p className={'c-widget-simple__headline'}>
        {heading}
        {grayedHeading ? (
          <span className={'c-widget-simple__grayed-headline'}>{grayedHeading}</span>
        ) : null}
      </p>
      {subheading && <p className={'c-widget-simple__subheadline'}>{subheading}</p>}
    </header>
    <div className={'c-widget-simple__content'} data-testid={dataTestIdContent}>
      {children}
    </div>
  </section>
);
