import { createOAuth2Interceptor, OAuth2TokenResponse } from '@coolio/auth-interceptor';
import { ContentType, HttpInterceptorFunction } from '@coolio/http';
import { fetchRequestHandler } from '@coolio/http/request-handlers/fetch';

import { Config } from '+config/config';
import { isAuthorizedDomain } from '+shared/network/network.util';
import { AuthActions } from '+shared/store/auth';
import { getAuthTokenStorage, getSalesforceTokenStorage } from '+shared/store/auth/auth.selectors';
import { storeProvider } from '+shared/store/store.provider';
import { Reporter } from '+utils/reporter.util';

const authStorage = {
  getData: () => getAuthTokenStorage(storeProvider.getState()),
  setData: (tokenStorage: OAuth2TokenResponse) => {
    storeProvider.dispatch(AuthActions.setTokenStorage(tokenStorage));
  },
};

export const authInterceptor = createOAuth2Interceptor({
  authStorage,
  canAuthorize: (options) => !!authStorage.getData() && isAuthorizedDomain(options.url),
  oauth: {
    clientId: Config.AUTH.CLIENT_ID,
    clientSecret: Config.AUTH.CLIENT_SECRET,
    refreshTokenUrl: `${Config.AUTH.AUTH_URL}/oauth/token`,
    contentType: ContentType.URL_ENCODED,
    httpClientOptions: {
      requestHandler: fetchRequestHandler(),
    },
  },
  onAuthorizationFailure: (err) => {
    Reporter.log('Failed to authorize');
    Reporter.reportError(err);
    storeProvider.dispatch(AuthActions.logout());
  },
});

export const impersonateInterceptor: HttpInterceptorFunction = (request, options) => {
  if (isAuthorizedDomain(options.url)) {
    const tokens = getSalesforceTokenStorage(storeProvider.getState());
    if (!tokens) {
      return request;
    }

    const { impersonateUser, accessToken, tokenType } = tokens;

    options.headers.Authorization = `${tokenType} ${accessToken}`;
    options.headers['X-Impersonate-User'] = impersonateUser;
  }
  return request;
};

export const authorizationSourceInterceptor: HttpInterceptorFunction = (request, options) => {
  if (isAuthorizedDomain(options.url) && null !== Config.FIREBASE.authorizationSource) {
    options.headers['X-Authorization-Source'] = Config.FIREBASE.authorizationSource;
  }

  return request;
};
