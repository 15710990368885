import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';

import { mapActions } from '+app/utils';
import { BatteryOperatingModeView } from '+customer-battery/containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import { TariffType, TariffWindow } from '+customer-battery/store/battery-tariff';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { BatteryActions } from '+shared/store/battery';
import { dateUtil } from '+utils/date.util';

import './BatteryTimeOfUseScheduleItem.component.scss';

interface SimpleProps {
  tariffWindow: TariffWindow;
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
}

type Props = SimpleProps & ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = mapActions({
  editBatteryTariffWindow: BatteryActions.editBatteryTariffWindow,
  deleteSingleBatteryTariffWindow: BatteryActions.deleteSingleBatteryTariffWindow,
});

const BatteryTimeOfUseScheduleItemComponent: React.FC<Props> = ({
  tariffWindow,
  setBatteryOperatingModeView,
  actions,
}) => {
  const isTariffOffPeak = tariffWindow.type === TariffType.OFF_PEAK;
  const tariffStart = dateUtil.format(dateUtil.ofString(tariffWindow.start, ['HH:mm']), 'h:mma');
  const tariffStop = dateUtil.format(dateUtil.ofString(tariffWindow.stop, ['HH:mm']), 'h:mma');
  const label =
    Number(tariffWindow?.thresholdPMax) === 0
      ? I18n.t(T.customerSingle.batteryDetails.timeOfUse.windowsList.thresholdOffPeakLabelPVOnly)
      : I18n.t(T.customerSingle.batteryDetails.timeOfUse.windowsList.thresholdOffPeakLabel, {
          value: Number(tariffWindow?.thresholdPMax),
        });

  return (
    <div className="c-tariff-window-list-item__body">
      <div className="c-tariff-window-list-item__header">
        <div
          className={classNames('c-tariff-window-list-item__header-marker', {
            'offPeak-color': isTariffOffPeak,
          })}
        />
        {isTariffOffPeak
          ? I18n.t(T.customerSingle.batteryDetails.timeOfUse.offPeak)
          : I18n.t(T.customerSingle.batteryDetails.timeOfUse.peak)}
      </div>
      <div className="c-tariff-window-list-item__content">
        <div className="c-tariff-window-list-item__content-details">
          <p>
            <span>{I18n.t(T.customerSingle.batteryDetails.timeOfUse.windowsList.period)}:</span>
            {I18n.t(T.customerSingle.batteryDetails.timeOfUse.windowsList.periodLabel, {
              start: tariffStart,
              stop: tariffStop,
            })}
          </p>
        </div>
        <div className="c-tariff-window-list-item__content-actions">
          <Button
            label={I18n.t(T.general.basicAcions.delete)}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            onClick={() => actions.deleteSingleBatteryTariffWindow(tariffWindow.id)}
            className="c-tariff-window-list-item__content-actions-button"
          />
          <Button
            label={I18n.t(T.general.basicActions.edit)}
            type={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => {
              actions.editBatteryTariffWindow(tariffWindow);
              setBatteryOperatingModeView(BatteryOperatingModeView.TIME_OF_USE_EDIT);
            }}
            className="c-tariff-window-list-item__content-actions-button"
          />
        </div>
      </div>
      {isTariffOffPeak && (
        <div className="c-tariff-window-list-item__edit">
          <div className="c-tariff-window-list-item__edit-info-container">
            <Icon.InfoCircle
              className={'c-tariff-window-list-item__edit-info-icon'}
              color={'white'}
              secondaryColor={'#0B7AC2'}
            />
            <span className="c-tariff-window-list-item__edit-info-text">{label}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const BatteryTimeOfUseScheduleItem = connect(
  null,
  mapDispatchToProps
)(BatteryTimeOfUseScheduleItemComponent);
