import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph, Card, Loader, PageSubheadline, WarningText } from '@sonnen/shared-web';

import { Form, Formik } from 'formik';
import { get, isEmpty, isUndefined, uniqBy } from 'lodash';

import { LeadHardwareFormStatus } from '+app/+lead/+hardware/components/LeadHardwareFormStatus/LeadHardwareFormStatus.component';
import { HardwarePageActions } from '+app/+lead/+hardware/store/+hardware.actions';
import { getHardwareStatusUpdateQueryStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import { LeadHardwareStatusModal } from '+app/+lead/components/LeadHardwareStatusModal/LeadHardwareStatusModal.component';
import { getLead } from '+lead/store/+lead.selectors';
import { FormErrorBanner, FormInput, FormInputSelect } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import useDebounce from '+shared/hooks/useDebounce';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { LeadOffer, OfferProductStatus } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { searchByKey } from '+utils/array.util';
import { mapActions } from '+utils/redux/mapActions.util';

import { LeadOfferPageActions } from '../../store/+offer.actions';
import {
  getHardwareProduct,
  getProductForStatusChange,
  isHardwareStatusConfirmed,
} from '../../store/+offer.helper';
import {
  getEnergyProviders,
  getGetLeadOfferQueryStatus,
  getLeadChangeUtilityDataQueryStatus,
  getLeadOfferPostOfferQueryStatus,
  getLeadOfferSendOfferQueryStatus,
  getUtilityData,
} from '../../store/+offer.selectors';
import { formFields, OfferFormSchema } from './OfferForm.helper';

import './OfferForm.component.scss';

const PrefilledLine = ({ label, value }: { label: string; value: string }) => (
  <>
    <label className={'c-offer-form__label'}>{label}</label>
    <div className={'c-offer-form__utility-prefilled'}>
      <div>{value}</div>
    </div>
  </>
);

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  offerPostQueryStatus: getLeadOfferPostOfferQueryStatus(state),
  offerSendQueryStatus: getLeadOfferSendOfferQueryStatus(state),
  changeUtilityDataQueryStatus: getLeadChangeUtilityDataQueryStatus(state),
  energyProviders: getEnergyProviders(state),
  utilityData: getUtilityData(state),
  hardwareStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
  getLeadOfferQueryStatus: getGetLeadOfferQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  sendOffer: LeadOfferPageActions.sendOffer,
  getEnergyProviders: LeadOfferPageActions.getEnergyProviders,
  patchUtilityData: LeadOfferPageActions.patchUtilityData,
  toggleModal: LayoutActions.toggleModal,
  updateHardwareStatus: HardwarePageActions.updateHardwareStatus,
  getOffer: LeadOfferPageActions.getOffer,
});

interface ComponentProps {
  offer: LeadOffer;
  isMultiplePv: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

const OfferFormComponent: React.FC<Props> = ({
  actions,
  lead,
  offer,
  offerPostQueryStatus,
  offerSendQueryStatus,
  changeUtilityDataQueryStatus,
  energyProviders,
  utilityData,
  isMultiplePv,
  hardwareStatusUpdateQueryStatus,
  getLeadOfferQueryStatus,
}) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [initialHardwareProductStatus, setInitialHardwareProductStatus] = React.useState<
    OfferProductStatus | undefined
  >(undefined);
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const offerToUpadateRef = React.useRef<LeadOffer | undefined>(undefined);

  React.useEffect(() => {
    actions.getEnergyProviders(debouncedSearchValue);
  }, [debouncedSearchValue]);

  React.useEffect(() => {
    if (hardwareStatusUpdateQueryStatus.success && lead) {
      actions.getOffer(lead.id, offer.id);
    }
  }, [hardwareStatusUpdateQueryStatus]);

  React.useEffect(() => {
    if (
      offer &&
      isUndefined(initialHardwareProductStatus) &&
      !isUndefined(getHardwareProduct(offer))
    ) {
      setInitialHardwareProductStatus(getHardwareProduct(offer)!.status);
    }
  }, [offer]);

  const onSubmit = ({ providerId, meterId }: { providerId: string; meterId: string }) => {
    if (!lead || !offer) {
      return;
    }

    const product = getProductForStatusChange(offer);
    if (!product) {
      return;
    }

    actions.sendOffer(lead.id, offer.id, product.productId);

    if (providerId || meterId) {
      actions.patchUtilityData(providerId, meterId);
    }
  };

  const energyProvider = get(utilityData, 'energyProvider', '');
  const meterId = get(utilityData, 'meterId', '');

  const openHardwareStatusModal = (offer: LeadOffer) => {
    offerToUpadateRef.current = offer;
    actions.toggleModal(true, ModalId.HARDWARE_STATUS_UPDATE_OFFER_FORM);
  };

  const updateHardwareStatus = (status: OfferProductStatus) => {
    const offer = offerToUpadateRef.current;
    if (!lead || !offer) return;

    const product = getHardwareProduct(offer);
    if (!product) return;

    actions.updateHardwareStatus(lead.id, [
      {
        offerId: offer.id,
        productId: product.productId,
        status,
      },
    ]);
  };

  return (
    <Formik
      initialValues={{
        providerId: energyProvider,
        meterId,
        pvSystemKey1: '', // @TODO
        pvSystemKey2: '', // @TODO
      }}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validationSchema={OfferFormSchema}
    >
      {(form) => (
        <Form className={'c-offer-form'}>
          <Card
            footerContent={
              <div data-hj-suppress={true}>
                <Button
                  mainType={MainType.SUBMIT}
                  label={
                    lead
                      ? I18n.t(T.lead.offer._salessolution_.sendButton, {
                          salutation: I18n.t(
                            T.lead.boc._salessolution_.dictionary.salutation[lead!.salutation]
                          ),
                          lastName: lead!.lastName,
                        })
                      : ''
                  }
                  type={ButtonType.PRIMARY}
                  size={ButtonSize.LARGE}
                  loading={offerPostQueryStatus.pending || offerSendQueryStatus.pending}
                  disabled={!isEmpty(form.errors)}
                  status={!isEmpty(form.errors) ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                  dataTestId="send-offer-btn"
                />
              </div>
            }
          >
            <PageSubheadline>
              {I18n.t(T.lead.offer._salessolution_.utilityData.header)}
            </PageSubheadline>
            <div className={'c-offer-form__utility-paragraph'}>
              <BoldParagraph>
                {I18n.t(T.lead.offer._salessolution_.utilityData.description)}
              </BoldParagraph>
            </div>
            <div className={'c-offer-form__utility-input c-offer-form__column'}>
              {!energyProvider ? (
                <FormInputSelect
                  className={'c-guide-acceptance-old-provider__select'}
                  label={I18n.t(T.lead.offer._salessolution_.utilityData.oldProvider)}
                  placeholder={I18n.t(T.lead.offer._salessolution_.utilityData.placeholder)}
                  form={form}
                  name={formFields.PROVIDER_ID}
                  collection={uniqBy(energyProviders, 'id').map((item) => item.id)}
                  mapper={(key) => searchByKey('id', key, energyProviders, 'name')}
                  onInputChange={setSearchValue}
                  dataTestId="old-energy-provider-field"
                />
              ) : (
                <PrefilledLine
                  label={I18n.t(T.lead.offer._salessolution_.utilityData.oldProvider)}
                  value={searchByKey('id', energyProvider, energyProviders, 'name')}
                />
              )}
            </div>

            <div className={'c-offer-form__utility-input c-offer-form__column'}>
              {!meterId ? (
                <FormInput
                  form={form}
                  label={I18n.t(T.lead.offer._salessolution_.utilityData.meterId)}
                  name={formFields.METER_ID}
                  dataTestId="meter-id-field"
                />
              ) : (
                <PrefilledLine
                  label={I18n.t(T.lead.offer._salessolution_.utilityData.meterId)}
                  value={meterId}
                />
              )}
            </div>

            <div className={'c-offer-form__meter-id-warning'}>
              <WarningText text={I18n.t(T.lead.offer._salessolution_.utilityData.meterIdWarning)} />
            </div>

            {initialHardwareProductStatus !== OfferProductStatus.CONFIRMED &&
              !isUndefined(getHardwareProduct(offer)) && (
                <div className={'c-offer-form__hw-status-wrapper'}>
                  {getLeadOfferQueryStatus.pending ? (
                    <div className={'c-offer-form__hw-status-loader'}>
                      <Loader />
                    </div>
                  ) : null}
                  <LeadHardwareFormStatus
                    openModal={() => openHardwareStatusModal(offer)}
                    newHardwareStatus={
                      getHardwareProduct(offer)?.status === OfferProductStatus.DRAFT
                        ? undefined
                        : getHardwareProduct(offer)?.status
                    }
                    shouldDisplayButton={!isHardwareStatusConfirmed(offer)}
                  />
                </div>
              )}

            {offerToUpadateRef && (
              <LeadHardwareStatusModal
                modalId={ModalId.HARDWARE_STATUS_UPDATE_OFFER_FORM}
                offerRef={offerToUpadateRef}
                submitAction={(status) => updateHardwareStatus(status)}
                queryStatus={hardwareStatusUpdateQueryStatus}
              />
            )}

            <FormErrorBanner
              isVisible={
                offerPostQueryStatus.error ||
                changeUtilityDataQueryStatus.error ||
                offerSendQueryStatus.error
              }
              error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
            />
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export const OfferForm = connect(mapStateToProps, mapDispatchToProps)(OfferFormComponent);
