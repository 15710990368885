import { PersistedState } from 'redux-persist';

import { Query } from '+shared/store/query';
import {
  SiteLiveStateV2,
  SiteLiveStateV2Data,
} from '+shared/store/site/types/siteLiveStateV2.interface';

export const GET_HEATPUMP_POLLING_QUERY = 'getSiteLiveV2Query';

export interface HeatpumpState extends PersistedState {
  siteLiveStateV2?: SiteLiveStateV2;
  [GET_HEATPUMP_POLLING_QUERY]: Query<SiteLiveStateV2Data>;
}

export const initialState: HeatpumpState = {
  siteLiveStateV2: undefined,
  [GET_HEATPUMP_POLLING_QUERY]: {},
};
