import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, of } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { CustomerListRouteQueryParams, ROUTES } from '+app/router';
import { RouterActions } from '+app/router/store/router.actions';
import { getRouteQueryParams } from '+app/router/store/router.selectors';
import { dataGuard, mapToState, matchPath, ofType, processQuery, xorDecrypt } from '+app/utils';
import { CustomerListPageActions, GET_COLLECTION_QUERY } from '+customer-list/store';
import { AuthActions } from '+shared/store/auth';
import { CustomerRepository } from '+shared/store/customer/customer.repository';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';

type Action$ = ActionsObservable<AuthActions | CustomerListPageActions>;
type State$ = StateObservable<StoreState>;

export const getCustomerCollection$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    // change after release
    ofType(RouterActions.isReady, CustomerListPageActions.getCustomerCollection),
    debounceTime(250),
    mapToState(state$),
    mergeMap((state) =>
      forkJoin([
        of(state).pipe(
          matchPath(ROUTES.CUSTOMERS_OLD),
          map(getRouteQueryParams),
          map((queryParams) => queryParams as CustomerListRouteQueryParams)
        ),
        of(state).pipe(map(getUserProfileCustomerNumber)),
      ])
    ),
    mergeMap(([queryParams, userProfileCustomerNumber]) =>
      of({}).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            processQuery(
              GET_COLLECTION_QUERY,
              () =>
                CustomerRepository.getCustomerCollection({
                  ...queryParams,
                  search: queryParams.search
                    ? xorDecrypt(queryParams.search, userProfileCustomerNumber)
                    : undefined,
                }),
              {
                onSuccess: (res) =>
                  concat(
                    dataGuard(CustomerListPageActions.setCustomerCollection)(res.elements),
                    dataGuard(CustomerListPageActions.setInstalledBatteriesCount)(
                      res.meta.companyBatterySystemsCount
                    ),
                    dataGuard(CustomerListPageActions.setTotalResourceCount)(
                      res.meta.totalResourceCount
                    )
                  ),
              }
            )
          )
        )
      )
    )
  );

export const epics = combineEpics<any>(getCustomerCollection$);
