import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Loader } from '+shared/basicComponents/Loader/Loader';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { StatusTile, StatusTileColors, StatusTileProps, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { LiveStateRefresher } from '+shared/containers/LiveStateRefresher';
import { Battery } from '+shared/store/battery';
import { getOfflineSince, hasBatteryBackupDevice } from '+shared/store/battery/battery.helpers';
import { BatteryIcStatus } from '+shared/store/battery/types/batteryIcStatus.interface';
import { getStatus } from '+shared/store/query/query.utils';
import { SiteLiveState } from '+shared/store/site/types';

import { mapBatteryIntelligentChargingToLabel } from '../../containers/BatteryIntelligentChargingOperation/BatteryIntelligentChargingOperation.helpers';
import { mapBatteryOperatingModeToTag } from '../../containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.helpers';
import { BatteryLiveCardBody } from '../BatteryLiveCardBody';
import { BatteryOperationsHeader } from '../BatteryOperationsHeader';
import { DetailedBatteryStatusComponent } from '../DetailedBatteryStatus/DetailedBatteryStatus.component';

import './BatteryLiveCard.component.scss';

interface Props {
  battery: Battery;
  siteLiveState: SiteLiveState;
  batteryIcStatus: BatteryIcStatus | undefined;
  queryStatus: ReturnType<typeof getStatus>;
  sliderValue: number[];
  isBatteryOperatingModeInProgress: boolean;
  isBatteryIntelligentChargingInProgress: boolean;
  isBatteryBackupBufferInProgress: boolean;
  isPvFeedInLimitSet: boolean;
  isBatteryIntelligentChargingVisible: boolean;
}

export const BatteryLiveCard: React.FC<Props> = ({
  battery,
  siteLiveState,
  batteryIcStatus,
  queryStatus,
  sliderValue,
  isBatteryOperatingModeInProgress,
  isBatteryIntelligentChargingInProgress,
  isBatteryBackupBufferInProgress,
  isPvFeedInLimitSet,
  isBatteryIntelligentChargingVisible,
}) => {
  const hasBackupDevice = hasBatteryBackupDevice(battery);

  const defaultHeaderStatus: Pick<StatusTileProps, 'label' | 'color'> = {
    label: I18n.t(T.customerSingle.overview.battery.statusOnline),
    color: StatusTileColors.GREEN,
  };
  const [headerStatus, setHeaderStatus] =
    React.useState<Pick<StatusTileProps, 'label' | 'color'>>(defaultHeaderStatus);

  React.useEffect(() => {
    setHeaderStatus({
      label:
        battery && siteLiveState && !siteLiveState.online
          ? getOfflineSince(siteLiveState.timestamp)
          : I18n.t(T.customerSingle.overview.battery.statusOnline),
      color:
        battery && siteLiveState && !siteLiveState.online
          ? StatusTileColors.RED
          : StatusTileColors.GREEN,
    });
  }, [siteLiveState]);

  const { batteryLive } = T.customerSingle.batteryDetails;

  return (
    <Widget
      className={'c-battery-live-card'}
      icon={'SonnenBattery'}
      heading={
        <div className={'c-battery-live-card__header'}>
          {I18n.t(batteryLive.headline)}
          <StatusTile
            additionalClass={'c-battery-live-card__header-status'}
            label={headerStatus.label}
            color={headerStatus.color}
          />
        </div>
      }
      notification={
        <div className={'c-battery-live-card__footer'}>
          <LabelMedium
            className={'c-battery-live-card__subtitle'}
            text={I18n.t(batteryLive.information.title)}
          />
          <BatteryOperationsHeader
            title={I18n.t(batteryLive.operatingMode.headline)}
            label={mapBatteryOperatingModeToTag(
              isBatteryOperatingModeInProgress,
              siteLiveState.batteryOperatingMode
            )}
          />
          {isBatteryIntelligentChargingVisible && (
            <BatteryOperationsHeader
              title={I18n.t(batteryLive.intelligentChargingManagement)}
              label={mapBatteryIntelligentChargingToLabel(
                isBatteryIntelligentChargingInProgress,
                isPvFeedInLimitSet,
                battery.prognosisCharging
              )}
            />
          )}
          {batteryIcStatus && (
            <>
              <LabelMedium
                className={'c-battery-live-card__subtitle'}
                text={I18n.t(batteryLive.detailedStatus.title)}
              />
              <DetailedBatteryStatusComponent batteryIcStatus={batteryIcStatus} />
            </>
          )}
        </div>
      }
      customAction={
        <div className={'c-battery-live-card__actions'}>
          <LiveStateRefresher />
        </div>
      }
    >
      {queryStatus.pending && <Loader className={'c-battery-live-card__loader'} />}
      {!queryStatus.error && battery && (
        <BatteryLiveCardBody
          battery={battery}
          siteLiveState={siteLiveState}
          hasBackupDevice={hasBackupDevice}
          sliderValue={sliderValue}
          isBatteryBackupBufferInProgress={isBatteryBackupBufferInProgress}
        />
      )}
      {queryStatus.error && (
        <WidgetWarning type={'warning'} icon={'warning'}>
          {I18n.t(T.customerSingle.overview.battery.error)}
        </WidgetWarning>
      )}
    </Widget>
  );
};
