import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLead } from '+lead/store/+lead.selectors';
import {
  FileCategoryName,
  REMOVE_FILES_QUERY,
  SEND_FILES_QUERY,
} from '+setupTool/+form/store/+form.dictionary';
import { getVppUploadedDocuments } from '+setupTool/+vppDocumentation/store';
import { getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { getUserProfileId } from '+shared/store/user/user.selectors';

import { DsoRegistrationFormActions } from './+form.actions';
import { fileUploadSettings } from './+form.consts';

export const useSubmissionFileUploadManagement = () => {
  const dispatch = useDispatch();

  const vppUploadedDocuments = useSelector(getVppUploadedDocuments);
  const submissionId = useSelector(getSubmissionId);
  const userId = useSelector(getUserProfileId);
  const lead = useSelector(getLead);

  const uploadSubmissionFile = useCallback(
    (files: File[], fileCategoryName: FileCategoryName) => {
      dispatch(
        DsoRegistrationFormActions.sendDsoDocumentsFile({
          file: files[0],
          userId,
          customerId: lead?.id,
          submissionId,
          category: fileCategoryName,
          queryKey: SEND_FILES_QUERY[fileCategoryName],
        })
      );
    },
    [dispatch]
  );

  const removeSubmissionFile = useCallback(
    (fileId: string) => {
      dispatch(
        DsoRegistrationFormActions.removeDsoDocumentsFile({
          documentId: fileId,
          submissionId,
          queryKey: REMOVE_FILES_QUERY,
        })
      );
    },
    [dispatch]
  );

  return {
    vppUploadedDocuments,
    fileUploadSettings,

    uploadSubmissionFile,
    removeSubmissionFile,
  };
};
