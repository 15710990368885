import React from 'react';

import classNames from 'classnames';

import './Card.scss';

interface Props {
  noHeaderGap?: boolean;
  footerContent?: React.ReactNode;
  header?: React.ReactNode;
}

export type CardProps = Props;

export const Card: React.FC<Props> = ({ noHeaderGap, children, header, footerContent }) => (
  <div className="sw-card">
    {header && (
      <div
        className={classNames('sw-card__header', {
          'sw-card__header--no-gap': noHeaderGap,
        })}
      >
        {header}
      </div>
    )}
    <div className={'sw-card__main'}>{children}</div>

    {footerContent && <div className={'sw-card__footer'}>{footerContent}</div>}
  </div>
);
