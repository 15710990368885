import { __extends } from "tslib";
import { ViewCanvas } from '../canvas';
import { createEventDispatcher, registerEventDispatcher } from '../pointer-event';
import { RequiredViewChanges, View } from '../view';
var isBrowser = typeof window !== 'undefined' && window.requestAnimationFrame;
var RootCanvasView = (function (_super) {
    __extends(RootCanvasView, _super);
    function RootCanvasView(context, canvas) {
        var _this = _super.call(this, context, 'RootCanvasView') || this;
        _this.shouldRun = true;
        _this.dpr = 1;
        _this.isRequired = true;
        _this.run = function () {
            if (!_this.shouldRun) {
                return;
            }
            _this.isRequired = false;
            _this.onSizeChanged();
            _this.measure(_this.canvas);
            _this.layout();
            _this.draw(_this.canvas);
        };
        _this.canvas = new ViewCanvas(canvas);
        var _a = _this.canvas.context.canvas, width = _a.width, height = _a.height;
        _this.rect.r = width;
        _this.rect.b = height;
        _this.lp.width(width).height(height);
        return _this;
    }
    RootCanvasView.prototype.getCanvas = function () {
        return this.canvas.context.canvas;
    };
    RootCanvasView.prototype.getScale = function () {
        return this.dpr;
    };
    RootCanvasView.prototype.setupPointerEvents = function () {
        var canvas = this.canvas.context.canvas;
        if (!canvas || !canvas.addEventListener) {
            throw new Error('Can\'t setup event listeners in canvas element.');
        }
        var dispatcher = createEventDispatcher(this);
        this.clearPointerEvents = registerEventDispatcher(canvas, dispatcher);
    };
    RootCanvasView.prototype.onSizeChanged = function () {
        if (!this.canvas) {
            throw new Error('Called onSizeChanged after RootView got detached from canvas.');
        }
        var ctx = this.canvas.context;
        var canvas = ctx.canvas;
        var parent = canvas.parentElement;
        if (!isBrowser || !canvas.style || !parent) {
            return;
        }
        var dpr = window && window.devicePixelRatio || 1;
        var _a = parent.getBoundingClientRect(), width = _a.width, height = _a.height;
        if (this.rect.r === width && this.rect.b === height && this.dpr === dpr) {
            return;
        }
        this.dpr = dpr;
        canvas.style.width = "".concat(width, "px");
        canvas.style.height = "".concat(height, "px");
        canvas.width = width * dpr;
        canvas.height = height * dpr;
        ctx.scale(dpr, dpr);
        this.rect.r = width;
        this.rect.b = height;
        this.lp.width(width).height(height);
        this.require(RequiredViewChanges.MEASURE);
    };
    RootCanvasView.prototype.onDestroy = function () {
        if (this.clearPointerEvents) {
            this.clearPointerEvents();
        }
        this.shouldRun = false;
    };
    RootCanvasView.prototype.setDebugEnabled = function (enabled) {
        if (this.canvas) {
            this.context.debugEnabled = enabled;
        }
    };
    RootCanvasView.prototype.draw = function (canvas) {
        var _a = this, width = _a.width, height = _a.height;
        if (!this.requireGuard(RequiredViewChanges.DRAW)) {
            return;
        }
        canvas.context.clearRect(0, 0, width, height);
        _super.prototype.draw.call(this, canvas);
    };
    RootCanvasView.prototype.require = function (requiredChanges) {
        var _this = this;
        _super.prototype.require.call(this, requiredChanges);
        if (this.isRequired) {
            return;
        }
        this.isRequired = true;
        if (isBrowser) {
            setImmediate(function () { return requestAnimationFrame(_this.run); });
        }
    };
    RootCanvasView.prototype.onSnapshot = function () {
        return {
            dpr: this.dpr,
        };
    };
    return RootCanvasView;
}(View));
export { RootCanvasView };
