import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as uuid from 'uuid/v4';

import { NewsFormCarrera } from '+app/+newsChannel/components/NewsFormCarrera';
import { NewsFormCarreraHelper } from '+app/+newsChannel/components/NewsFormCarrera/NewsFormCarrera.helpers';
import { Button, ButtonSize, MainType } from '+shared/components/Button';
import { withFirebase } from '+shared/components/FirebaseContext';
import { Firebase } from '+shared/store/firebase/firebase.client';
import {
  Language,
  NewsCarrera,
  NewsFormCarreraType,
  Options,
  Portal,
  RouteNameCarrera,
} from '+shared/store/firebase/types';

import './SendNewsCarrera.component.scss';

type Props = {
  firebase: Firebase;
};

export const SendNewsCarreraComponent: React.FC<Props> = ({ firebase }) => {
  const [isPending, setIsPending] = React.useState(false);
  const [formError, setFormError] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);

  const onSubmit = ({
    countries,
    excludeInRoutes,
    expiryDateTimestamp: formExpiryDateTimestamp,
    includeInRoutes,
    status: formStatus,
    messageLongUk,
    messageLongUs,
    messageLongDe,
    messageLongIt,
    messageShortUk,
    messageShortUs,
    messageShortDe,
    messageShortIt,
    options,
    optionsBatteriesControllerType,
    optionsBatteriesAccessories,
    optionsContractType,
    optionsTariffType,
  }: NewsFormCarreraType) => {
    setIsPending(true);

    const id = uuid();
    const status = NewsFormCarreraHelper.STATUS.find((status) => status.label === formStatus);
    const isIncludeInLoginRoute = includeInRoutes.includes(RouteNameCarrera.LOGIN);

    if (isIncludeInLoginRoute && includeInRoutes.length > 1) {
      setIsPending(false);
      setFormError('Cannot target login page together with other pages');

      return;
    }

    const news: NewsCarrera = {
      countries: isIncludeInLoginRoute ? [] : countries,
      excludeInRoutes,
      expiryDateTimestamp: new Date(formExpiryDateTimestamp),
      id,
      includeInRoutes,
      levelValue: (status && status.value) || 1,
      messages: {
        [Language.UK]: {
          long: messageLongUk,
          short: messageShortUk,
        },
        [Language.US]: {
          long: messageLongUs,
          short: messageShortUs,
        },
        [Language.DE]: {
          long: messageLongDe,
          short: messageShortDe,
        },
        [Language.IT]: {
          long: messageLongIt,
          short: messageShortIt,
        },
      },
      portals: [Portal.Carrera],
      options: isIncludeInLoginRoute ? [] : options,
      optionsDetails: {
        [Options.BATTERIES]: {
          controllerType: isIncludeInLoginRoute ? [] : optionsBatteriesControllerType,
          accessories: isIncludeInLoginRoute ? [] : optionsBatteriesAccessories,
        },
        [Options.CONTRACTS]: {
          contractType: isIncludeInLoginRoute ? [] : optionsContractType,
          tariffType: isIncludeInLoginRoute ? [] : optionsTariffType,
        },
      },
    };

    firebase
      .addNews(Portal.Carrera, news)
      .then(() => {
        setIsPending(false);
        setFormError('');
        setIsSuccess(true);
      })
      .catch((err: Error) => {
        setIsPending(false);
        setFormError(err.message);
      });
  };

  return isSuccess ? (
    <>
      <div className={'c-send-news-carrera__success-text'}>Your news has been sent!</div>
      <Button
        mainType={MainType.SUBMIT}
        label={I18n.t(T.news.channel.sendForm.sendAnotherNews)}
        size={ButtonSize.MEDIUM}
        onClick={() => setIsSuccess(false)}
      />
    </>
  ) : (
    <NewsFormCarrera onSubmit={onSubmit} isLoading={isPending} formError={formError} />
  );
};

export const SendNewsCarrera = withFirebase(SendNewsCarreraComponent);
