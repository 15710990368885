import { createSelector } from 'reselect';

import { getStatus } from '../../shared/store/query/query.utils';
import { StoreState } from '../../shared/store/store.interface';
import { GET_CUSTOMER_QUERY, PUT_CUSTOMER_NOTE_QUERY } from './+customer.state';

export const getCustomerPageState = (state: StoreState) => state.page.customer;

export const getCustomerCommonState = createSelector(getCustomerPageState, (state) => state.common);

export const getSelectedCustomer = createSelector(
  getCustomerCommonState,
  (state) => state.customer
);

export const getSelectedCustomerQueryStatus = createSelector(getCustomerCommonState, (state) =>
  getStatus(state[GET_CUSTOMER_QUERY])
);

export const getNoteSubmitQuery = createSelector(
  getCustomerCommonState,
  (state) => state[PUT_CUSTOMER_NOTE_QUERY]
);

export const getNoteSubmitQueryStatus = createSelector(getNoteSubmitQuery, (query) =>
  getStatus(query)
);

/**
 * CUSTOMER PAGES SELECTORS
 * ------------------------------
 */
export const getCustomerBatteryPageState = createSelector(
  getCustomerPageState,
  (state) => state.battery
);

export const getCustomerPvSystemsPageState = createSelector(
  getCustomerPageState,
  (state) => state.pvSystems
);

export const getCustomerContractPageState = createSelector(
  getCustomerPageState,
  (state) => state.contract
);

export const getCustomerAnalysisPageState = createSelector(
  getCustomerPageState,
  (state) => state.analysis
);

export const getCustomerOverviewPageState = createSelector(
  getCustomerPageState,
  (state) => state.overview
);

export const getCustomerHeatpumpPageState = createSelector(
  getCustomerPageState,
  (state) => state.heatpump
);

export const getCustomerListPageState = createSelector(getCustomerPageState, (state) => state.list);
