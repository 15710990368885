import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isBuffer from 'lodash/isBuffer';
import { getCaseConverter, getHeader } from './helpers';
import { urlEncode } from './helpers/urlEncoding.helper';
import { switchContentType } from './contentType';
import { CFormData } from './formData';
export var bodySerializer = function (_a) {
    var bodyCasing = (_a === void 0 ? {} : _a).bodyCasing;
    var caseConverter = getCaseConverter(bodyCasing);
    var bodySerializers = {
        JSON: function (body) { return JSON.stringify(caseConverter(body)); },
        URL_ENCODED: function (body) { return urlEncode(caseConverter(body)); },
        MULTIPART: function (body) { return CFormData.from(caseConverter(body)); },
        TEXT: function (body) { return String(body); },
    };
    return function (options) {
        var contentTypeHeader = getHeader(options.headers, 'content-type') || '';
        var contentType = contentTypeHeader.split(';')
            .map(function (type) { return type.trim().toLowerCase(); });
        var body = options.body;
        if (isNil(body) || isString(body) || isBuffer(body) || CFormData.isFormData(body)) {
            return body;
        }
        if (typeof body === 'object') {
            for (var _i = 0, contentType_1 = contentType; _i < contentType_1.length; _i++) {
                var type = contentType_1[_i];
                var serializedBody = switchContentType(type, bodySerializers);
                if (serializedBody) {
                    return serializedBody(body);
                }
            }
        }
        throw new Error("Can not serialize request body. Content-Type \"" + contentTypeHeader + "\"");
    };
};
