import { ActionsObservable, combineEpics } from 'redux-observable';
import { map, mergeMap, take } from 'rxjs/operators';

import { RouterActions } from '+router/store/router.actions';

import { dataGuard, makeQuery, ofType } from '../../../utils';
import { LegalActions } from './legal.actions';
import { LegalRepository } from './legal.repository';
import { GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY } from './legal.state';
import { AccountType } from './types/legal.interface';

type Action$ = ActionsObservable<LegalActions>;

const getAcceptedLegalDocumentsWhenReady$ = (action$: Action$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    take(1),
    map(() =>
      LegalActions.getAcceptedLegalDocuments({
        accountType: AccountType.SERVICE_PARTNER,
        onlyLastAccepted: true,
      })
    )
  );

const getAcceptedLegalDocuments$ = (action$: Action$) =>
  action$.pipe(
    ofType(LegalActions.getAcceptedLegalDocuments),
    mergeMap(({ accountType, onlyLastAccepted }) =>
      makeQuery(GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY)({
        call: () => LegalRepository.getAcceptedLegalDocuments(accountType, onlyLastAccepted),
        onSuccess: (res) => dataGuard(LegalActions.setAcceptedLegalDocuments)(res.elements),
      })
    )
  );

export const epics = combineEpics<any>(
  getAcceptedLegalDocumentsWhenReady$,
  getAcceptedLegalDocuments$
);
