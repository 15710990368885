import { Data } from '@coolio/json-api';

export enum DocumentKind {
  TN_C_CUSTOMER_PORTAL = 'tn_c_customer_portal',
  TN_C_PARTNER_PORTAL = 'tn_c_partner_portal',
  PRIVACY_POLICY = 'privacy_policy',
  PARTNER_DECLARATION_CUSTOMER_DATA = 'partner_declaration_customer_data',
}

export enum AccountType {
  CUSTOMER = 'customer',
  SERVICE_PARTNER = 'service_partner',
}

export interface LegalDocumentAttributes {
  accepted: boolean;
  kind: DocumentKind;
  version: string;
  language: string;
  country: string;
  current: boolean;
  url: string;
}

export type LegalDocumentData = Data<LegalDocumentAttributes>;
