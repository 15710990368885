import { I18n, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import { LayoutActions } from '+shared/store/layout';
import { StoreType } from '+shared/store/store.interface';
import { dateUtil } from '+utils/date.util';

import { DEFAULT_LOCALE } from './i18n.config';
import { getLocale, loadCurrentTranslations } from './i18n.helpers';

(I18n as any).setHandleMissingTranslation((key: string, replacements: any) => {
  try {
    const defaultLanguageTranslation = (I18n as any)._fetchTranslation(
      (I18n as any)._translations,
      `${DEFAULT_LOCALE}.${key}`,
      replacements.count
    );
    return (I18n as any)._replace(defaultLanguageTranslation, replacements);
  } catch (_) {
    return key;
  }
});

export const initializeI18n = async (store: StoreType) => {
  store.dispatch(LayoutActions.isLanguageLoading(true));
  syncTranslationWithStore(store);
  const locale = getLocale();
  await loadCurrentTranslations(store, locale);
  setLocale(locale)(store.dispatch);
  dateUtil.setLocale(locale);
  store.dispatch(LayoutActions.isLanguageLoading(false));
};
