import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { MeasurementConceptGraph } from '+shared/basicComponents/Icons';
import { BodyLarge, BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import ExpandingInfo from '+shared/components/ExpandingInfo/ExpandingInfo';

import './MeasurementConcept.scss';

export enum MeasurementConcepts {
  CONSUMPTION = '0',
  DIFFERENTIAL = '2',
  GRID = '3',
}

export type MeasurementConceptsType = MeasurementConcepts | null;

const measurementConceptDictionary = {
  '0': 'consumption',
  '2': 'differential',
  '3': 'grid',
};

type Props = {
  measurementConcept: MeasurementConcepts;
};

const MeasurementConcept: React.FC<Props> = ({ measurementConcept }) => {
  const { showInstructionsButton } = T.customerSingle.pvSystemsAndMeters.measurementConcepts;
  const {
    conceptTitle,
    productionHeadline,
    productionText,
    consumptionHeadline,
    consumptionText,
    paragraphOne,
    paragraphTwo,
  } =
    T.customerSingle.pvSystemsAndMeters.measurementConcepts[
      measurementConceptDictionary[measurementConcept]
    ];

  return (
    <div className="measurement-concept">
      <div>
        <BodyLarge text={I18n.t(conceptTitle)} className="measurement-concept__title" />
        <MeasurementConceptGraph
          conceptType={measurementConcept.toString() as MeasurementConcepts}
        />
      </div>

      <ExpandingInfo label={<LabelMedium text={I18n.t(showInstructionsButton)} />}>
        <div className="measurement-concept__dropdown-content">
          <LabelMedium text={I18n.t(productionHeadline)} />
          <BodyMedium text={I18n.t(productionText)} className="body-spacing" />
          <LabelMedium text={I18n.t(consumptionHeadline)} />
          <BodyMedium text={I18n.t(consumptionText)} className="body-spacing" />
          <BodyMedium text={I18n.t(paragraphOne)} className="body-spacing" />
          <BodyMedium text={I18n.t(paragraphTwo)} className="body-spacing" />
        </div>
      </ExpandingInfo>
    </div>
  );
};

export default MeasurementConcept;
