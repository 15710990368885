import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  AnalysisChartColors,
  AnalysisTooltipSymbolKind,
  BatteryStatusesKey,
  CellDataSeriesKey,
  ChartTooltipStyles,
  EnergyFlowSeriesKey,
  EnergyUnit,
  FormatNumberOptions,
  NumberUnit,
  OtherUnit,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';

import { ChartView, DataContainerTooltipExtension, TooltipEvent } from '@kanva/charts';
import { View } from '@kanva/core';
import { get, isNil, isNumber } from 'lodash';

import { provideLocale } from '+app/i18n/i18n.helpers';
import { formatNumber } from '+utils/format.util.old';
import { celsiusToFahrenheit } from '+utils/temperature';

// Note: this should be definately discussed, I would suggest to leave
// precision 1 for small values less than 1
// This below would make more sense from my perspective
// const precision = value >= 1000 ? 0 : 1;
export const getPrecision = (value: number) => (value >= 1000000 ? 0 : 1);

export const formatChartTooltipSeries =
  ({ numberUnit = NumberUnit.ONE }: FormatNumberOptions) =>
  (value: number | string): string => {
    value = Number(value);

    const precision = numberUnit === NumberUnit.ONE ? 0 : getPrecision(value);
    const transformedValue = value * numberUnit;

    return formatNumber({ precision, minPrecision: 0 })(transformedValue);
  };

const createUnitTransform =
  (unit: EnergyUnit | OtherUnit, numberUnit?: NumberUnit) => (value: number | [number, number]) => {
    return isNumber(value)
      ? `${formatChartTooltipSeries({ numberUnit })(value)} ${unit}`
      : `${formatBandChartLegend(unit)(value[0])} - ${formatBandChartLegend(unit)(
          value[1]
        )} ${formatBandChartUnit(unit)}`;
  };

const formatBandChartLegend = (unit: OtherUnit | EnergyUnit) => (value: number) => {
  const precision = unit === OtherUnit.TEMPERATURE ? 2 : 3;
  const transformedTemp =
    unit === OtherUnit.TEMPERATURE && provideLocale() === 'en-us'
      ? celsiusToFahrenheit(value)
      : value;

  if (isNil(transformedTemp)) {
    return '';
  }

  return formatNumber({ precision })(transformedTemp);
};

const formatBandChartUnit = (unit: OtherUnit | EnergyUnit) =>
  unit === OtherUnit.TEMPERATURE ? (provideLocale() === 'en-us' ? '°F' : '°C') : unit;

const transformWatt = createUnitTransform(EnergyUnit.W, NumberUnit.ONE);
const transformWhToKwh = createUnitTransform(EnergyUnit.KWH, NumberUnit.KILO);
const transformPercent = createUnitTransform(OtherUnit.PERCENT);
const transformTemperature = createUnitTransform(OtherUnit.TEMPERATURE);
const transformVoltage = createUnitTransform(OtherUnit.VOLTAGE);

const getVppActivityLabel = (value: number | [number, number]): string =>
  value === 1
    ? I18n.t(T.customerSingle.analysis.dayChart.vppActivityTooltipActive)
    : I18n.t(T.customerSingle.analysis.dayChart.vppActivityTooltipInactive);

const getChargeLimitsLabel = (value: number | [number, number]): string =>
  value === 1
    ? I18n.t(T.customerSingle.analysis.dayChart.chargeLimits.chargingBlocked)
    : I18n.t(T.customerSingle.analysis.dayChart.chargeLimits.chargingEnabled);

const getCellCareLabel = (value: number | [number, number]): string =>
  value === 1
    ? I18n.t(T.customerSingle.analysis.dayChart.cellCareTooltipInProgress)
    : I18n.t(T.customerSingle.analysis.dayChart.cellCareTooltipInactive);

export const chartTooltipStyles: ChartTooltipStyles = {
  /**
   * AREA CHART
   */
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.BATTERY_USOC]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC],
    style: AnalysisTooltipSymbolKind.LINE,
    transformation: transformPercent,
  },
  [EnergyFlowSeriesKey.BATTERY_CHARGING]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_CHARGING].stroke,
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_DISCHARGING],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.GRID_FEEDIN]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.GRID_FEEDIN],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.GRID_PURCHASE]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.GRID_PURCHASE],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWatt,
  },
  /**
   * LINE CHART
   */
  [BatteryStatusesKey.VPP_ACTIVITY]: {
    color: AnalysisChartColors[BatteryStatusesKey.VPP_ACTIVITY],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: getVppActivityLabel,
  },
  [BatteryStatusesKey.CHARGE_LIMIT]: {
    color: AnalysisChartColors[BatteryStatusesKey.CHARGE_LIMIT],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: getChargeLimitsLabel,
  },
  [BatteryStatusesKey.CELL_CARE]: {
    color: AnalysisChartColors[BatteryStatusesKey.CELL_CARE],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: getCellCareLabel,
  },
  /**
   * BAND CHART
   */
  [CellDataSeriesKey.TEMPERATURE]: {
    color: AnalysisChartColors[CellDataSeriesKey.TEMPERATURE],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformTemperature,
  },
  [CellDataSeriesKey.VOLTAGE]: {
    color: AnalysisChartColors[CellDataSeriesKey.VOLTAGE],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformVoltage,
  },
  /**
   * BAR CHART
   */
  [StatisticsSeriesKey.CONSUMED_ENERGY]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWhToKwh,
  },
  [StatisticsSeriesKey.PRODUCED_ENERGY]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWhToKwh,
  },
};

export const handleTooltipTouchEvent = (
  e: React.TouchEvent,
  view: View<any> | undefined,
  tooltipExtension: DataContainerTooltipExtension | undefined,
  tooltipEvent: TooltipEvent | undefined
) => {
  if (tooltipEvent && tooltipExtension && view && tooltipExtension) {
    const x = e.targetTouches[0].pageX - tooltipEvent.pointerEvent.offset.left;
    tooltipExtension.simulateAbsoluteCanvasPosition(view as ChartView<any, any>, { x, y: 0 });
  }
};

export const seriesKeyTranslationMap = (key: string): string =>
  get(
    {
      // AREA CHART
      [EnergyFlowSeriesKey.PRODUCTION_POWER]: I18n.t(
        T.customerSingle.analysis.dayChart.productionFilter
      ),
      [EnergyFlowSeriesKey.CONSUMPTION_POWER]: I18n.t(
        T.customerSingle.analysis.dayChart.consumptionFilter
      ),
      [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: I18n.t(
        T.customerSingle.analysis.dayChart.directUsageFilter
      ),
      [EnergyFlowSeriesKey.BATTERY_USOC]: I18n.t(
        T.customerSingle.analysis.dayChart.batteryUSOCFilter
      ),
      [EnergyFlowSeriesKey.BATTERY_CHARGING]: I18n.t(
        T.customerSingle.analysis.dayChart.chargingFilter
      ),
      [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: I18n.t(
        T.customerSingle.analysis.dayChart.dischargingFilter
      ),
      [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: I18n.t(
        T.customerSingle.analysis.forecast.consumption
      ),
      [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: I18n.t(
        T.customerSingle.analysis.forecast.production
      ),

      // LINE CHART
      [BatteryStatusesKey.VPP_ACTIVITY]: I18n.t(
        T.customerSingle.analysis.dayChart.vppActivityFilter
      ),
      [BatteryStatusesKey.CHARGE_LIMIT]: I18n.t(
        T.customerSingle.analysis.dayChart.labels.chargeLimit.name
      ),
      [BatteryStatusesKey.CELL_CARE]: I18n.t(T.customerSingle.analysis.dayChart.cellCareFilter),

      // BAND CHART
      [CellDataSeriesKey.TEMPERATURE]: I18n.t(
        T.customerSingle.analysis.dayChart.cellTemperatureTooltip
      ),
      [CellDataSeriesKey.VOLTAGE]: I18n.t(T.customerSingle.analysis.dayChart.cellVoltageTooltip),

      // BAR CHART
      [StatisticsSeriesKey.CONSUMED_ENERGY]: I18n.t(T.history.chart.labels.consumption),
      [StatisticsSeriesKey.PRODUCED_ENERGY]: I18n.t(T.history.chart.labels.production),
    },
    key,
    ''
  );
