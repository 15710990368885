import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Form, Formik, FormikProps } from 'formik';
import { defaultTo, isNil, noop } from 'lodash/fp';

import { PATHS } from '+app/router';
import {
  BatteryIntelligentChargingConfirmModal,
  OpenWidgetTitle,
} from '+customer-battery/components';
import {
  getBattery,
  getIsBatteryIntelligentChargingInProgress,
  getIsBatteryRemoteSettingsAllowed,
  getPvGridFeedInLimit,
  isEatonBattery,
} from '+customer-battery/store';
import { FormInputRadioGroup, TooltipHoverable, WidgetAccordion } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { isImpersonated } from '+shared/store/auth/auth.selectors';
import { BatteryActions } from '+shared/store/battery';
import { StoreState } from '+shared/store/store.interface';
import { goTo } from '+utils/browser.util';
import { mapActions } from '+utils/redux';
import { firstToUppercase } from '+utils/string.util';

import {
  defaultValues,
  IntelligentChargingForm,
  IntelligentChargingFormKeys,
  mapBatteryIntelligentChargingToBoolean,
  mapBatteryIntelligentChargingToTitle,
  mapNotAvailableReasonToTooltip,
} from './BatteryIntelligentChargingOperation.helpers';

import './BatteryIntelligentChargingOperation.component.scss';

const mapStateToProps = (state: StoreState) => ({
  battery: getBattery(state),
  isEaton: isEatonBattery(state),
  isBatteryIntelligentChargingInProgress: getIsBatteryIntelligentChargingInProgress(state),
  pvGridFeedInLimit: getPvGridFeedInLimit(state),
  isImpersonated: isImpersonated(state),
  isBatteryRemoteSettingsAllowed: getIsBatteryRemoteSettingsAllowed(state),
});

const mapDispatchToProps = mapActions({
  setBatteryIntelligentCharging: BatteryActions.setBatteryIntelligentCharging,
  setIsBatteryIntelligentChargingInProgress:
    BatteryActions.setIsBatteryIntelligentChargingInProgress,
});

interface OwnProps {
  isMoreThanOneWidgetActive: boolean;
  onWidgetOpening: (openedWidget: OpenWidgetTitle[]) => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const BatteryIntelligentChargingOperationComponent: React.FC<Props> = ({
  actions,
  battery,
  isEaton,
  isBatteryIntelligentChargingInProgress,
  pvGridFeedInLimit,
  isImpersonated,
  isBatteryRemoteSettingsAllowed,
  isMoreThanOneWidgetActive,
  onWidgetOpening,
}) => {
  const [isIntelligentChargingOpen, setIsIntelligentChargingOpen] = useState<boolean>(false);
  const initialValues = defaultTo(defaultValues)({
    isActive: mapBatteryIntelligentChargingToBoolean(battery?.prognosisCharging),
  });

  const isPvFeedInLimitSet = !isNil(pvGridFeedInLimit) ? pvGridFeedInLimit < 100 : false;

  useEffect(() => {
    if (isIntelligentChargingOpen) {
      onWidgetOpening([OpenWidgetTitle.INTELLIGENT_CHARGING]);
    } else onWidgetOpening([]);
  }, [isIntelligentChargingOpen]);

  const confirm = (form: FormikProps<IntelligentChargingForm>) => (): void => {
    if (battery) {
      actions.setBatteryIntelligentCharging({
        batteryId: battery.id,
        batterySerialNumber: battery.serialNumber,
        intelligentChargingValue: form.values.isActive ? 'on' : 'off',
      });
    }

    if (battery && isBatteryRemoteSettingsAllowed) {
      actions.setIsBatteryIntelligentChargingInProgress(true);
    }

    resetFormSubmittingState(form)();
    setIsIntelligentChargingOpen(false);
  };

  const canPerformAction = (form: FormikProps<IntelligentChargingForm>): boolean =>
    mapBatteryIntelligentChargingToBoolean(battery?.prognosisCharging) !== form.values.isActive;

  const resetFormSubmittingState = (form: FormikProps<IntelligentChargingForm>) => (): void =>
    form.setSubmitting(false);

  const isWidgetAccordionDisabled: boolean =
    isBatteryIntelligentChargingInProgress ||
    isEaton ||
    isImpersonated ||
    !isPvFeedInLimitSet ||
    isMoreThanOneWidgetActive;

  if (pvGridFeedInLimit === null && !isEaton) {
    return null;
  }

  return (
    <TooltipHoverable
      title={mapNotAvailableReasonToTooltip(isEaton)}
      isHoveringDisabled={!isEaton && isPvFeedInLimitSet}
    >
      <WidgetAccordion
        title={mapBatteryIntelligentChargingToTitle(battery?.prognosisCharging)}
        isOpen={isIntelligentChargingOpen}
        isDisabled={isWidgetAccordionDisabled}
        onHeaderClick={() => setIsIntelligentChargingOpen(!isIntelligentChargingOpen)}
      >
        <section className={'c-battery-intelligent-charging-operation'}>
          <Formik<IntelligentChargingForm> initialValues={initialValues} onSubmit={noop}>
            {(form) => (
              <Form>
                <div className={'c-battery-intelligent-charging-operation__info'}>
                  {I18n.t(
                    T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                      .info
                  )}
                </div>
                <a
                  className={'c-battery-intelligent-charging-operation__link'}
                  onClick={() => goTo(PATHS.HELP('sonnenBatterie'))}
                >
                  {I18n.t(
                    T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
                      .linkMore
                  )}
                </a>
                <div className={'c-battery-intelligent-charging-operation__checkboxes'}>
                  <FormInputRadioGroup<Pick<IntelligentChargingForm, 'isActive'>>
                    className={'c-battery-intelligent-charging-operation__radio-group'}
                    name={IntelligentChargingFormKeys.IS_ACTIVE}
                    form={form}
                    isVertical={true}
                    collection={[
                      {
                        label: firstToUppercase(
                          I18n.t(
                            T.customerSingle.batteryDetails.batteryOperations
                              .intelligentChargingManagement.statusOn
                          )
                        ),
                        value: true,
                      },
                      {
                        label: firstToUppercase(
                          I18n.t(
                            T.customerSingle.batteryDetails.batteryOperations
                              .intelligentChargingManagement.statusOff
                          )
                        ),
                        value: false,
                      },
                    ]}
                  />
                </div>
                <div className={'c-battery-intelligent-charging-operation__footer'}>
                  <Button
                    label={I18n.t(
                      T.customerSingle.batteryDetails.batteryOperations
                        .intelligentChargingManagement.buttonCancel
                    )}
                    type={ButtonType.TERTIARY}
                    size={ButtonSize.SMALL}
                    onClick={() => setIsIntelligentChargingOpen(!isIntelligentChargingOpen)}
                  />
                  <Button
                    label={I18n.t(
                      T.customerSingle.batteryDetails.batteryOperations
                        .intelligentChargingManagement.buttonNext
                    )}
                    mainType={MainType.SUBMIT}
                    size={ButtonSize.SMALL}
                    disabled={!canPerformAction(form)}
                    status={!canPerformAction(form) ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                  />
                </div>
                <BatteryIntelligentChargingConfirmModal
                  isActivating={form.values.isActive}
                  isOpen={form.isSubmitting}
                  onCancel={resetFormSubmittingState(form)}
                  onConfirm={confirm(form)}
                />
              </Form>
            )}
          </Formik>
        </section>
      </WidgetAccordion>
    </TooltipHoverable>
  );
};

export const BatteryIntelligentChargingOperation = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryIntelligentChargingOperationComponent);
