import * as React from 'react';

export enum FormInputEventType {
  ON_BLUR = 'onBlur',
  ON_CHANGE = 'onChange',
}

type Pairs<T> = {
  [K in keyof T]: {
    name: K;
    value: T[K];
  };
};

export type FormInputSubscriptionAction<T = any> = {
  type: FormInputEventType;
  payload: NonNullable<Pairs<T>[keyof T]>;
};

export type FormInputSubscriptionPayload<T = any> = FormInputSubscriptionAction<T>['payload'];

export const FormInputSubscriptionContext =
  React.createContext<React.Dispatch<FormInputSubscriptionAction> | null>(null);
