import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, DS } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';

import { useSetupLeadContext } from '+app/+lead/containers/SetupLead/SetupLead.context';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { TestId } from '+config/testIds';
import { PATHS } from '+router/routes';
import {
  DatepickerArrowSide,
  FormInput,
  FormInputDate,
  FormInputRadioGroup,
} from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';
import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox/FormControlledCheckbox.component';
import { FormInputInfo } from '+shared/components/FormInputInfo';
import { ConfigurationPvType, LeadProductType } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { LeadConfigurationPvCommissioningModal } from '../../components/LeadConfigurationPvCommissioningModal';
import { ConfigurationPageActions } from '../../store';
import {
  getConfigurationPeakPowerHint,
  getConfigurationProposal,
  getConfigurationRecommendationSubmitQueryStatus,
} from '../../store/+configuration.selectors';
import { ConfigurationForm, NumberField } from '../../store/types';
import {
  isCommissioningDateAfterMin,
  isProposalForPostEeg,
} from '../LeadConfigurationForm/LeadConfigurationForm.helper';
import {
  formFields,
  getFormFieldsForEachPv,
  getMultiplePvLabelNumber,
  minCommissioningDate,
  PvKind,
} from './LeadConfigurationPv.helper';

import './LeadConfigurationPv.component.scss';

const mapStateToProps = (state: StoreState) => ({
  peakPowerHint: getConfigurationPeakPowerHint(state),
  getConfigurationRecommendationSubmitQueryStatus:
    getConfigurationRecommendationSubmitQueryStatus(state),
  configProposal: getConfigurationProposal(state),
  lead: getLead(state),
});

const mapDispatchToProps = mapActions({
  goToNewFlatDirect: (leadId: string, leadStage?: string, state?: any) =>
    push(PATHS.LEAD_CONFIGURATION_NEW_FLAT_DIRECT({ leadId }, leadStage), state),
  createRecommendation: ConfigurationPageActions.createRecommendation,
  setupClearConfigurationHint: ConfigurationPageActions.setupClearConfigurationHint,
});

interface ComponentProps {
  form: FormikProps<ConfigurationForm>;
  flatType: LeadProductType | undefined;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

const LeadConfigurationPvComponent: React.FC<Props> = ({
  form,
  actions,
  configProposal,
  lead,
  flatType,
}) => {
  const { leadStage } = useSetupLeadContext();
  const leadId = lead?.id;

  const shouldPvCommissioningModalOpenBasedOnField = (fieldValue: NumberField) =>
    isCommissioningDateAfterMin(fieldValue, flatType) && isProposalForPostEeg(fieldValue);

  const isPvCommissioningModalOpen =
    flatType === LeadProductType.FLAT_X &&
    (shouldPvCommissioningModalOpenBasedOnField(form.values.commissioningDate) ||
      shouldPvCommissioningModalOpenBasedOnField(form.values.secondPvCommissioningDate));

  const isFieldError = (field: string) => form.errors[field] && form.touched[field];
  const isFormMultiplePv = form.values.multiplePv;

  const onClosePvCommissioningModal = () => {
    if (shouldPvCommissioningModalOpenBasedOnField(form.values.commissioningDate)) {
      form.setFieldValue(formFields.COMMISSIONING_DATE, '');
    } else if (shouldPvCommissioningModalOpenBasedOnField(form.values.secondPvCommissioningDate)) {
      form.setFieldValue(formFields.SECOND_PV_COMMISSIONING_DATE, '');
    }
  };

  const onSwitchToFlatDirect = () => {
    if (leadId) {
      // INFO:
      // redirect to flat direct configuration with information if user selected multiple PV setting
      actions.goToNewFlatDirect(leadId, leadStage, { isFormMultiplePv });
    }
  };

  const switchView = (type: ConfigurationPvType, pvKind: PvKind) => {
    form.setFieldValue(getFormFieldsForEachPv(pvKind).pvType, type);
    actions.setupClearConfigurationHint();

    actions.createRecommendation({
      ...form.values,
      expectedAutarky: configProposal && configProposal.powerPlant.expectedAutarky?.value,
      pvType: type,
    });
  };

  const renderYieldOrInclinationOrientationFields = (pvKind: PvKind) =>
    form.values[getFormFieldsForEachPv(pvKind).pvType] === ConfigurationPvType.SPECIFIC_YIELD ? (
      <>
        <div className={'c-lead-configuration-pv__input'}>
          {/* PV SPECIFIC YIELD PER YEAR */}
          <FormInput
            form={form}
            label={
              I18n.t(T.lead.configuration._salessolution_.pv.specificYield) +
              ' ' +
              getMultiplePvLabelNumber(isFormMultiplePv, pvKind)
            }
            unit={I18n.t(T.units.kwhPerKwpPerYear)}
            name={getFormFieldsForEachPv(pvKind).specificYield}
            type={'number'}
            hasNoGap={true}
            id={`lead-configuration-specific-yield-${getMultiplePvLabelNumber(
              isFormMultiplePv,
              pvKind
            )}`}
          />
        </div>
        <div
          className={classNames('c-lead-configuration-pv__message-yield', {
            'c-lead-configuration-pv__message-yield--error': isFieldError(
              getFormFieldsForEachPv(pvKind).specificYield
            ),
          })}
        >
          <FormInputInfo>
            {I18n.t(T.lead._salessolution_.configurations.switchToYieldMessage)}{' '}
            <Button
              className={'c-lead-configuration-pv__btn'}
              onClick={() => switchView(ConfigurationPvType.DETAILED, pvKind)}
              label={I18n.t(T.lead._salessolution_.configurations.switchToYieldButton)}
              type={ButtonType.TERTIARY}
            />
          </FormInputInfo>
        </div>
      </>
    ) : (
      <Media query={{ minWidth: breakpointUp('SM') }}>
        {(isDesktopMedia: boolean) => (
          <>
            <div className={'c-lead-configuration-pv__input-group'}>
              {/* PV INCLINATION */}
              <div
                className={classNames(
                  'c-lead-configuration-pv__input',
                  'c-lead-configuration-pv__inclination-input',
                  {
                    'c-lead-configuration-pv__inclination-input--error': isFieldError(
                      getFormFieldsForEachPv(pvKind).inclination
                    ),
                  }
                )}
              >
                <FormInput
                  form={form}
                  info={I18n.t(T.lead.configuration._salessolution_.pv.inclinationInfo)}
                  label={
                    I18n.t(T.lead.configuration._salessolution_.pv.inclination) +
                    ' ' +
                    getMultiplePvLabelNumber(isFormMultiplePv, pvKind)
                  }
                  unit={I18n.t(T.units.degree)}
                  name={getFormFieldsForEachPv(pvKind).inclination}
                  type={'number'}
                  hasNoGap={true}
                  id={`lead-configuration-inclination-${getMultiplePvLabelNumber(
                    isFormMultiplePv,
                    pvKind
                  )}`}
                />
              </div>

              {/* PV ORIENTATION */}
              <div className={'c-lead-configuration-pv__input'}>
                <FormInput
                  form={form}
                  label={
                    I18n.t(T.lead.configuration._salessolution_.pv.orientation) +
                    ' ' +
                    getMultiplePvLabelNumber(isFormMultiplePv, pvKind)
                  }
                  info={I18n.t(T.lead.configuration._salessolution_.pvOrientation.info)}
                  unit={I18n.t(T.units.degree)}
                  name={getFormFieldsForEachPv(pvKind).orientation}
                  type={'number'}
                  hasNoGap={
                    !isDesktopMedia || !isFieldError(getFormFieldsForEachPv(pvKind).inclination)
                  }
                  id={`lead-configuration-orientation-${getMultiplePvLabelNumber(
                    isFormMultiplePv,
                    pvKind
                  )}`}
                />
              </div>
            </div>

            <div
              className={classNames('c-lead-configuration-pv__message-inclination', {
                'c-lead-configuration-pv__message-inclination--error':
                  isFieldError(formFields.ORIENTATION) ||
                  (isDesktopMedia && isFieldError(getFormFieldsForEachPv(pvKind).inclination)),
              })}
            >
              <FormInputInfo>
                {I18n.t(T.lead._salessolution_.configurations.switchToRoofDetailsMessage)}{' '}
                <Button
                  label={I18n.t(T.lead._salessolution_.configurations.switchToRoofDetailsButton)}
                  className={'c-lead-configuration-pv__btn'}
                  onClick={() => switchView(ConfigurationPvType.SPECIFIC_YIELD, pvKind)}
                  type={ButtonType.TERTIARY}
                />
              </FormInputInfo>
            </div>
          </>
        )}
      </Media>
    );

  const renderCommissioningDate = (pvKind: PvKind) => (
    <Media query={{ minWidth: breakpointUp('MD') }}>
      {(isDesktopMedia: boolean) => (
        <div
          className={classNames('c-lead-configuration-pv__input', {
            'c-lead-configuration-pv__second-pv-datepicker': pvKind === PvKind.SECOND_PV,
          })}
        >
          <FormInputDate
            tooltipInfo={
              isFormMultiplePv
                ? I18n.t(T.lead.configuration._salessolution_.pv.commissioningDateTooltip)
                : undefined
            }
            minDate={minCommissioningDate}
            form={form}
            label={
              I18n.t(T.lead.configuration._salessolution_.pv.commissioningDate) +
              ' ' +
              getMultiplePvLabelNumber(isFormMultiplePv, pvKind)
            }
            name={getFormFieldsForEachPv(pvKind).commissioningDate}
            id={`lead-configuration-commissioning-date-${getMultiplePvLabelNumber(
              isFormMultiplePv,
              pvKind
            )}`}
            arrowPosition={isDesktopMedia ? DatepickerArrowSide.TOP : DatepickerArrowSide.TOP_RIGHT}
          />
        </div>
      )}
    </Media>
  );

  const renderPvSystemPower = (pvKind: PvKind) => (
    <div className={'c-lead-configuration-pv__input'}>
      <FormInput
        form={form}
        label={
          I18n.t(T.lead.configuration._salessolution_.pv.systemPower) +
          ' ' +
          getMultiplePvLabelNumber(isFormMultiplePv, pvKind)
        }
        unit={I18n.t(T.units.kwp)}
        name={pvKind === PvKind.FIRST_PV ? formFields.PEAK_POWER : formFields.SECOND_PV_PEAK_POWER}
        type={'number'}
        hasNoGap={true}
        id={`lead-configuration-pv-power-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
      />
    </div>
  );

  const renderPvSystemName = (pvKind: PvKind) => (
    <>
      <p className={'c-lead-configuration-pv__pv-system-name-wrapper'}>
        {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.pvSystemNameHeadline)}
      </p>
      <div className={'c-lead-configuration-pv__column'}>
        <div className={'c-lead-configuration-pv__input'}>
          <FormInput
            form={form}
            label={
              I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.pvSystemName) +
              ' ' +
              getMultiplePvLabelNumber(isFormMultiplePv, pvKind)
            }
            name={
              pvKind === PvKind.FIRST_PV
                ? formFields.FIRST_PV_SYSTEM_NAME
                : formFields.SECOND_PV_SYSTEM_NAME
            }
            type={'text'}
            hasNoGap={true}
            id={`lead-configuration-system-name-${getMultiplePvLabelNumber(
              isFormMultiplePv,
              pvKind
            )}`}
          />
        </div>
      </div>
    </>
  );

  return (
    <div className={'c-lead-configuration-pv'}>
      {/* MULTIPLE PV RADIO BUTTON */}
      <p className={'c-lead-configuration-pv__new-battery-question'}>
        {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.question)}
      </p>
      <div className={'c-lead-configuration-pv__new-battery-radio-btns'}>
        <FormInputRadioGroup
          name={formFields.MULTIPLE_PV}
          form={form}
          collection={[
            {
              label: I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.onePv),
              value: false,
              dataTestId: TestId.Lead.ConfigurationCreation.SinglePvRadioButton,
            },
            {
              label: I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.twoPvs),
              value: true,
              dataTestId: TestId.Lead.ConfigurationCreation.MultiplePvRadioButton,
            },
          ]}
        />
      </div>

      {/* FIRST PV SYSTEM */}
      {isFormMultiplePv ? (
        <>
          <div className={'c-lead-configuration-pv__multiple-pv-warning'}>
            <DS.Alert
              theme={DS.AlertTheme.WARNING}
              text={I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.warning)}
            />
          </div>

          <p className={'c-lead-configuration-pv__multiple-pv-header'}>
            {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.firstPvSystem)}
          </p>
        </>
      ) : null}
      <div className={'c-lead-configuration-pv__columns-wrapper'}>
        <div className={'c-lead-configuration-pv__column'}>
          {renderYieldOrInclinationOrientationFields(PvKind.FIRST_PV)}
        </div>
        <div className={'c-lead-configuration-pv__column'}>
          {renderCommissioningDate(PvKind.FIRST_PV)}
        </div>
      </div>

      {isFormMultiplePv &&
        flatType === LeadProductType.FLAT_DIRECT &&
        !isProposalForPostEeg(form.values.commissioningDate) &&
        form.values.commissioningDate && (
          <div className={'c-lead-configuration-pv__flat-direct-warning'}>
            <DS.Alert
              theme={DS.AlertTheme.WARNING}
              text={I18n.t(
                T.lead.configuration._salessolution_.pv.flatDirect.multiplePvFirstWarning
              )}
            />
          </div>
        )}

      <div className={'c-lead-configuration-pv__column'}>
        {isFormMultiplePv ? renderPvSystemPower(PvKind.FIRST_PV) : null}
      </div>

      {!isFormMultiplePv &&
        flatType === LeadProductType.FLAT_DIRECT &&
        !isProposalForPostEeg(form.values.commissioningDate) &&
        form.values.commissioningDate && (
          <DS.Alert
            theme={DS.AlertTheme.WARNING}
            text={I18n.t(T.lead.configuration._salessolution_.pv.flatDirect.singlePvWarning)}
          />
        )}

      {isFormMultiplePv ? renderPvSystemName(PvKind.FIRST_PV) : null}

      <LeadConfigurationPvCommissioningModal
        isOpen={isPvCommissioningModalOpen}
        onCancel={onClosePvCommissioningModal}
        onSwitch={onSwitchToFlatDirect}
      />

      {/* SECOND PV SYSTEM */}
      {isFormMultiplePv ? (
        <>
          <div className={'c-lead-configuration-pv__multiple-pv-separator'} />

          <p className={'c-lead-configuration-pv__multiple-pv-header'}>
            {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.secondPvSystem)}
          </p>
          <div className={'c-lead-configuration-pv__columns-wrapper'}>
            <div className={'c-lead-configuration-pv__column'}>
              {renderYieldOrInclinationOrientationFields(PvKind.SECOND_PV)}
            </div>

            <div className={'c-lead-configuration-pv__column'}>
              {renderCommissioningDate(PvKind.SECOND_PV)}
            </div>
          </div>

          {isFormMultiplePv &&
            flatType === LeadProductType.FLAT_DIRECT &&
            !isProposalForPostEeg(form.values.secondPvCommissioningDate) &&
            form.values.secondPvCommissioningDate && (
              <div className={'c-lead-configuration-pv__flat-direct-warning'}>
                <DS.Alert
                  theme={DS.AlertTheme.WARNING}
                  text={I18n.t(
                    T.lead.configuration._salessolution_.pv.flatDirect.multiplePvSecondWarning
                  )}
                />
              </div>
            )}

          <div className={'c-lead-configuration-pv__column'}>
            {renderPvSystemPower(PvKind.SECOND_PV)}
          </div>

          {renderPvSystemName(PvKind.SECOND_PV)}

          {!isFormMultiplePv &&
            isProposalForPostEeg(form.values[formFields.SECOND_PV_COMMISSIONING_DATE]) && (
              <div className={'c-lead-configuration-pv__post-eeg-warning'}>
                <DS.Alert
                  theme={DS.AlertTheme.WARNING}
                  text={I18n.t(T.lead.configuration._salessolution_.pv.postEegWarning)}
                />
              </div>
            )}

          <div className={'c-lead-configuration-pv__dso-checkbox'}>
            <FormControlledCheckbox
              form={form}
              label={I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.dsoCheckbox)}
              name={formFields.DSO_CONSENT_TO_COMBINE_PHOTOVOLTAIC_SYSTEMS}
              dataTestId={TestId.Lead.ConfigurationCreation.DsoConsentCheckbox}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export const LeadConfigurationPv = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadConfigurationPvComponent);
