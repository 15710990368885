import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { NewsFormCarrera } from '+app/+newsChannel/components/NewsFormCarrera';
import { NewsFormCarreraHelper } from '+app/+newsChannel/components/NewsFormCarrera/NewsFormCarrera.helpers';
import { NewsFormCustomerHelper } from '+app/+newsChannel/components/NewsFormCustomer/NewsFormCustomer.helpers';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import {
  GenericNews,
  Language,
  News,
  NewsCarrera,
  NewsCP,
  NewsFormCarreraType,
  NewsFormCP,
  Options,
  Portal,
  RouteNameCarrera,
} from '+shared/store/firebase/types/news.interface';
import { isCPNews, isSPNews } from '+utils/news.util';

import { NewsFormCustomer } from '../../components/NewsFormCustomer';
import { NewsFormPartner } from '../../components/NewsFormPartner';

import './EditNews.component.scss';

interface OwnProps {
  news?: GenericNews;
  isModalOpen: boolean;
  closeModal: () => void;
  onSuccessfullyEdit: (news: GenericNews) => void;
  newsPortal: Portal;
}

type Props = OwnProps & FirebaseProps;

export class EditNewsComponent extends React.Component<Props> {
  state = {
    success: false,
    loading: false,
    error: '',
    formState: FormState.FORM,
  };

  onPPSubmit = (values: News) => {
    this.setState({ loading: true });

    const { firebase } = this.props;
    const { level, tag } = values;
    const levelValue = level ? level.value : 0;
    const tagValue = tag ? tag.value : '';

    firebase
      .editNews(Portal.SP, { ...values, levelValue, tagValue })
      .then(() =>
        this.setState(
          {
            loading: false,
            success: true,
            error: '',
            formState: FormState.FORM_SUCCESS,
          },
          () =>
            this.props.onSuccessfullyEdit({
              ...values,
              levelValue,
              tagValue,
            })
        )
      )
      .catch((err: Error) =>
        this.setState({
          loading: false,
          error: err.message,
          formState: FormState.FORM_ERROR,
        })
      );
  };

  isCPEditSubmit = (form: NewsFormCP): form is NewsFormCP & NewsCP => !!form.id;

  onCPSubmit = (values: NewsFormCP) => {
    const { firebase } = this.props;
    if (!this.isCPEditSubmit(values)) {
      return this.setState({
        loading: false,
        error: "Couldn't find news ID",
        formState: FormState.FORM_ERROR,
      });
    }
    this.setState({ loading: true });

    const status = NewsFormCustomerHelper.STATUS.find((status) => status.label === values.status);

    const news: NewsCP = {
      countries: values.countries,
      excludeInRoutes: values.excludeInRoutes,
      expiryDateTimestamp: new Date(values.expiryDateTimestamp),
      id: values.id,
      includeInRoutes: values.includeInRoutes,
      levelValue: (status && status.value) || 1,
      messages: {
        [Language.UK]: {
          long: values.messageLongUk,
          short: values.messageShortUk,
        },
        [Language.US]: {
          long: values.messageLongUs,
          short: values.messageShortUs,
        },
        [Language.DE]: {
          long: values.messageLongDe,
          short: values.messageShortDe,
        },
        [Language.IT]: {
          long: values.messageLongIt,
          short: values.messageShortIt,
        },
      },
      portals: [Portal.CP],
      options: values.options,
      optionsDetails: {
        [Options.BATTERIES]: {
          controllerType: values.optionsBatteriesControllerType,
          accessories: values.optionsBatteriesAccessories,
        },
        [Options.CONTRACTS]: {
          contractType: values.optionsContractType,
          tariffType: values.optionsTariffType,
        },
      },
    };

    firebase
      .editNews(Portal.CP, news)
      .then(() =>
        this.setState(
          {
            loading: false,
            success: true,
            error: '',
            formState: FormState.FORM_SUCCESS,
          },
          () => this.props.onSuccessfullyEdit(news)
        )
      )
      .catch((err: Error) =>
        this.setState({
          loading: false,
          error: err.message,
          formState: FormState.FORM_ERROR,
        })
      );
  };

  isCarreraEditSubmit = (form: NewsFormCarreraType): form is NewsFormCarreraType & NewsCarrera =>
    !!form.id;

  onCarreraSubmit = (values: NewsFormCarreraType) => {
    const { firebase } = this.props;
    if (!this.isCarreraEditSubmit(values)) {
      return this.setState({
        loading: false,
        error: "Couldn't find news ID",
        formState: FormState.FORM_ERROR,
      });
    }
    this.setState({ loading: true });

    const status = NewsFormCarreraHelper.STATUS.find((status) => status.label === values.status);
    const isIncludeInLoginRoute = values.includeInRoutes.includes(RouteNameCarrera.LOGIN);

    if (isIncludeInLoginRoute && values.includeInRoutes.length > 1) {
      this.setState({
        loading: false,
        error: 'Cannot target login page together with other pages',
        formState: FormState.FORM_ERROR,
      });

      return;
    }

    const news: NewsCarrera = {
      countries: isIncludeInLoginRoute ? [] : values.countries,
      excludeInRoutes: values.excludeInRoutes,
      expiryDateTimestamp: new Date(values.expiryDateTimestamp),
      id: values.id,
      includeInRoutes: values.includeInRoutes,
      levelValue: (status && status.value) || 1,
      messages: {
        [Language.UK]: {
          long: values.messageLongUk,
          short: values.messageShortUk,
        },
        [Language.US]: {
          long: values.messageLongUs,
          short: values.messageShortUs,
        },
        [Language.DE]: {
          long: values.messageLongDe,
          short: values.messageShortDe,
        },
        [Language.IT]: {
          long: values.messageLongIt,
          short: values.messageShortIt,
        },
      },
      portals: [Portal.Carrera],
      options: isIncludeInLoginRoute ? [] : values.options,
      optionsDetails: {
        [Options.BATTERIES]: {
          controllerType: isIncludeInLoginRoute ? [] : values.optionsBatteriesControllerType,
          accessories: isIncludeInLoginRoute ? [] : values.optionsBatteriesAccessories,
        },
        [Options.CONTRACTS]: {
          contractType: isIncludeInLoginRoute ? [] : values.optionsContractType,
          tariffType: isIncludeInLoginRoute ? [] : values.optionsTariffType,
        },
      },
    };

    firebase
      .editNews(Portal.Carrera, news)
      .then(() =>
        this.setState(
          {
            loading: false,
            success: true,
            error: '',
            formState: FormState.FORM_SUCCESS,
          },
          () => this.props.onSuccessfullyEdit(news)
        )
      )
      .catch((err: Error) =>
        this.setState({
          loading: false,
          error: err.message,
          formState: FormState.FORM_ERROR,
        })
      );
  };

  showEmptyForm = () => this.setState({ success: false });

  onClose = () => {
    this.props.closeModal();
    this.setState({ formState: FormState.FORM, error: '' });
  };

  onTryAgain = () => this.setState({ formState: FormState.FORM });

  render() {
    const { news, isModalOpen } = this.props;
    const { loading, error, formState } = this.state;

    let form: JSX.Element | undefined = undefined;
    if (news) {
      if (isSPNews(news)) {
        form = (
          <NewsFormPartner
            onSubmit={this.onPPSubmit}
            loading={loading}
            error={error}
            isEdit={true}
            news={news}
          />
        );
      } else if (isCPNews(news)) {
        form = (
          <NewsFormCustomer
            onSubmit={this.onCPSubmit}
            isLoading={loading}
            formError={error}
            isEdit={true}
            news={news}
          />
        );
      } else {
        form = (
          <NewsFormCarrera
            onSubmit={this.onCarreraSubmit}
            isLoading={loading}
            formError={error}
            isEdit={true}
            news={news}
          />
        );
      }
    }

    return (
      <FormModal
        isModalOpen={isModalOpen}
        className={'sw-edit-news__modal'}
        size={'medium'}
        status={formState}
        headlineSuccess={I18n.t(T.news.channel.modal.success)}
        headlineFailure={I18n.t(T.news.channel.modal.error)}
        onClose={this.onClose}
        onTryAgain={this.onTryAgain}
        defaultForm={form}
      />
    );
  }
}

export const EditNews = withFirebase(EditNewsComponent);
