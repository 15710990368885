import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { visibleBatterySystemsStatuses } from '+shared/store/battery';
import { ContractType } from '+shared/store/contract/contract.helpers';

import { PartnerSite } from './types/partnerSite.interface';
import { PartnerSitesBattery } from './types/partnerSitesBattery.interface';
import { PartnerSitesContract } from './types/partnerSitesContract.interface';

export function getPrimaryBattery(site: PartnerSite): PartnerSitesBattery | null {
  const installedOrRegisteredBattery = site.batteries.find(
    (battery) => battery.status && visibleBatterySystemsStatuses.includes(battery.status)
  );
  return installedOrRegisteredBattery ?? site.batteries[0];
}

export function getPrimaryContract(site: PartnerSite): PartnerSitesContract | null {
  return site.contracts?.[0];
}

export const getFormattedPartnerSitesContractType = (contract: PartnerSitesContract): string => {
  const contractType: string = contract.name ?? '';

  const { sonnenFlatDirect, sonnenFlatX, sonnenFlatIt, sonnenFlat, sonnenStrom, sonnenEnergy } =
    T.customerSingle.overview.contractHeader;

  if (contractType.includes(ContractType.ENERGY_GLOBAL)) {
    return contract.tariffVersion === 'direkt 2.0' || contract.tariffVersion === 'direkt'
      ? I18n.t(sonnenFlatDirect)
      : I18n.t(sonnenFlatX);
  }

  if (contractType.includes(ContractType.SONNEN_FLAT_IT)) return I18n.t(sonnenFlatIt);

  if (contractType.includes(ContractType.SONNEN_FLAT)) return I18n.t(sonnenFlat);

  if (contractType.includes(ContractType.SONNEN_STROM)) return I18n.t(sonnenStrom);

  if (contractType.includes(ContractType.ENERGY)) return I18n.t(sonnenEnergy);

  return '—';
};
