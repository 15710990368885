import { PreCommissioningInterface } from '+setupTool/+preCommissioning/store/types';

export const PreCommissioningInitial: PreCommissioningInterface = {
  customerFirstName: '',
  customerLastName: '',
  customerPhoneNumber: '',
  customerEmailAddress: '',
  customerNumber: '',
  partnerCompanyName: '',
  partnerCompanyNumber: '',
  partnerCompanyLogotype: '',
  assetStreetNameAndNumber: '',
  assetZipCode: '',
  assetCity: '',
  assetCountryCode: '',
};
