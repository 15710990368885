import { createLayoutMap, MATCH_PARENT, PARENT_ID, WRAP_CONTENT } from '@kanva/core';

import { Dimensions, Views } from '../AnalysisDayChart/AnalysisDayChart.layout';

export const areaChartLayout = createLayoutMap({
  areaChartWrapper: {
    width: MATCH_PARENT,
    height: Dimensions.AREA_CHART_HEIGHT,
    alignTop: PARENT_ID,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    margin: {
      top: Dimensions.AREA_CHART_MARGIN,
    },
  },
  areaChart: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  areaChartGrid: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: Dimensions.X_AXIS_HEIGHT + Dimensions.AREA_CHART_MARGIN,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    below: Views.AREA_CHART_WRAPPER,
    padding: {
      top: Dimensions.X_AXIS_PADDING + Dimensions.DESKTOP_PADDING,
    },
    margin: {
      bottom: Dimensions.AREA_CHART_MARGIN,
    },
  },
  yAxisLeft: {
    width: WRAP_CONTENT,
    height: Dimensions.AREA_CHART_HEIGHT,
    alignTop: PARENT_ID,
    alignStart: PARENT_ID,
    above: Views.AREA_CHART_X_AXIS,
    padding: {
      top: Dimensions.AREA_CHART_MARGIN,
      left: Dimensions.Y_AXIS_PADDING,
    },
  },
  yAxisRight: {
    width: WRAP_CONTENT,
    height: Dimensions.AREA_CHART_HEIGHT,
    alignTop: PARENT_ID,
    alignEnd: PARENT_ID,
    above: Views.AREA_CHART_X_AXIS,
    padding: {
      top: Dimensions.AREA_CHART_MARGIN,
      right: Dimensions.Y_AXIS_PADDING,
    },
  },
});
