import { SetupDataStepStatus, SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { Query } from '+shared/store/query';
import { Document, Dso, Installers } from '+shared/store/setupTool/types';

import {
  DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
  GET_BATTERIES_QUERY,
  GET_BATTERY_NAMES_QUERY,
  GET_DSO_LIST_QUERY,
  GET_INSTALLERS_QUERY,
  GET_PV_INVERTER_QUERY,
  GET_PV_INVERTER_VENDORS_QUERY,
  GET_PV_INVERTERS_QUERY,
  GET_PV_MODULE_QUERY,
  GET_PV_MODULE_VENDORS_QUERY,
  GET_PV_MODULES_QUERY,
  REMOVE_DOCUMENT_SUBMIT_QUERY,
  REMOVE_INSTALLERS_QUERY,
  SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY,
  SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY,
  SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY,
  SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY,
  SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY,
  SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY,
  SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY,
  SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY,
  SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY,
  SUBMIT_INSTALLER_QUERY,
} from './+form.dictionary';
import { DsoRegistrationCustomerDataInitial } from './schemas/dsoRegistrationCustomerData.schema';
import { DsoRegistrationDocumentsInitial } from './schemas/dsoRegistrationDocuments.schema';
import { DsoRegistrationInstallerDataInitial } from './schemas/dsoRegistrationInstallerData.schema';
import { getDsoRegistrationMeasuringDeviceInitial } from './schemas/dsoRegistrationMeasuringDevice.schema';
import { getDsoRegistrationPvSystemInitial } from './schemas/dsoRegistrationPvSystem.schema';
import { getDsoRegistrationSonnenBatteryInitial } from './schemas/dsoRegistrationSonnenBattery.schema';
import {
  BatterySingleState,
  DsoRegistrationCustomerDataInterface,
  DsoRegistrationDocumentsInterface,
  DsoRegistrationInstallerDataInterface,
  DsoRegistrationMeasuringDeviceInterface,
  DsoRegistrationPvSystemInterface,
  DsoRegistrationSetupDataStatusesInterface,
  DsoRegistrationSonnenBatteryInterface,
  PVInverterSingleState,
  PVModuleSingleState,
} from './types';

export interface DsoRegistrationFormState {
  customerData?: DsoRegistrationCustomerDataInterface;
  measuringDevice?: DsoRegistrationMeasuringDeviceInterface;
  pvSystem?: DsoRegistrationPvSystemInterface;
  sonnenBattery?: DsoRegistrationSonnenBatteryInterface;
  installerData?: DsoRegistrationInstallerDataInterface;
  documents?: DsoRegistrationDocumentsInterface;
  dsoList: Dso[];
  pvModules: PVModuleSingleState[];
  pvInverters: PVInverterSingleState[];
  batteryNames: BatterySingleState[];
  batteryNamesNonUniqueCount: number;
  installersList: Installers[];
  fulfilled: boolean;
  setupDataStatuses: DsoRegistrationSetupDataStatusesInterface;
  activeStep: string;
  pvModuleVendorsAllFetched: boolean;
  pvInverterVendorsAllFetched: boolean;
  isRadioSerialNumberFieldVisible?: boolean;
  uploadedDocuments: Document[];
  updatedAt?: string;
  [DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY]: Query;
  [GET_BATTERIES_QUERY]: Query;
  [GET_BATTERY_NAMES_QUERY]: Query;
  [GET_DSO_LIST_QUERY]: Query;
  [GET_INSTALLERS_QUERY]: Query;
  [GET_PV_MODULE_VENDORS_QUERY]: Query;
  [GET_PV_MODULES_QUERY]: Query;
  [GET_PV_MODULE_QUERY]: Query;
  [GET_PV_INVERTER_VENDORS_QUERY]: Query;
  [GET_PV_INVERTERS_QUERY]: Query;
  [GET_PV_INVERTER_QUERY]: Query;
  [SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY]: Query;
  [SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY]: Query;
  [SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY]: Query;
  [SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY]: Query;
  [SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY]: Query;
  [SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY]: Query;
  [SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY]: Query;
  [SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY]: Query;
  [SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY]: Query;
  [REMOVE_DOCUMENT_SUBMIT_QUERY]: Query;
  [SUBMIT_INSTALLER_QUERY]: Query;
  [REMOVE_INSTALLERS_QUERY]: Query;
}

export const initialState: DsoRegistrationFormState = {
  customerData: DsoRegistrationCustomerDataInitial,
  measuringDevice: getDsoRegistrationMeasuringDeviceInitial(),
  pvSystem: getDsoRegistrationPvSystemInitial(),
  sonnenBattery: getDsoRegistrationSonnenBatteryInitial(),
  installerData: DsoRegistrationInstallerDataInitial,
  documents: DsoRegistrationDocumentsInitial,
  dsoList: [],
  pvModules: [],
  pvInverters: [],
  batteryNames: [],
  batteryNamesNonUniqueCount: 0,
  installersList: [],
  fulfilled: false,
  setupDataStatuses: {
    customerData: SetupDataStepStatus.NOT_STARTED,
    pvSystem: SetupDataStepStatus.NOT_STARTED,
    sonnenBattery: SetupDataStepStatus.NOT_STARTED,
    measuringDevice: SetupDataStepStatus.NOT_STARTED,
    uploadDocuments: SetupDataStepStatus.NOT_STARTED,
    installerData: SetupDataStepStatus.NOT_STARTED,
  },
  activeStep: SubmissionStep.SETUP_DATA_CUSTOMER_DATA,
  pvModuleVendorsAllFetched: false,
  pvInverterVendorsAllFetched: false,
  uploadedDocuments: [],
  [DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY]: {},
  [GET_BATTERIES_QUERY]: {},
  [GET_BATTERY_NAMES_QUERY]: {},
  [GET_DSO_LIST_QUERY]: {},
  [GET_INSTALLERS_QUERY]: {},
  [GET_PV_MODULE_VENDORS_QUERY]: {},
  [GET_PV_MODULES_QUERY]: {},
  [GET_PV_MODULE_QUERY]: {},
  [GET_PV_INVERTER_VENDORS_QUERY]: {},
  [GET_PV_INVERTERS_QUERY]: {},
  [GET_PV_INVERTER_QUERY]: {},
  [SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY]: {},
  [SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY]: {},
  [SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY]: {},
  [SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY]: {},
  [SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY]: {},
  [SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY]: {},
  [SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY]: {},
  [SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY]: {},
  [SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY]: {},
  [REMOVE_DOCUMENT_SUBMIT_QUERY]: {},
  [SUBMIT_INSTALLER_QUERY]: {},
  [REMOVE_INSTALLERS_QUERY]: {},
};
