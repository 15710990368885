import { Query } from '../query';
import { AssignedBattery } from './types/assignedBattery.interface';

export const IDENTIFY_MISSING_BATTERY_QUERY = 'identifyMissigBatteryQuery';
export const CLAIM_BATTERY_OWNERSHIP_QUERY = 'claimBatteryOwnershipQuery';

export interface PartnerState {
  missingBattery: {
    serialNumber?: string;
    postalCode?: string;
    pvPeakPower?: number;
    customerLastName?: string;
    assignedBattery?: AssignedBattery;
  };
  [IDENTIFY_MISSING_BATTERY_QUERY]: Query<any>;
  [CLAIM_BATTERY_OWNERSHIP_QUERY]: Query<AssignedBattery>;
}

export const initialState: PartnerState = {
  missingBattery: {},
  [IDENTIFY_MISSING_BATTERY_QUERY]: {},
  [CLAIM_BATTERY_OWNERSHIP_QUERY]: {},
};
