import { __extends } from "tslib";
import { Paint, RequiredViewChanges } from '@kanva/core';
import { isNumber } from 'lodash';
import { ChartView } from './chart.view';
export var GridLines;
(function (GridLines) {
    GridLines[GridLines["HORIZONTAL"] = 0] = "HORIZONTAL";
    GridLines[GridLines["VERTICAL"] = 1] = "VERTICAL";
    GridLines[GridLines["BOTH"] = 2] = "BOTH";
})(GridLines || (GridLines = {}));
var defaultStyle = {
    paint: new Paint()
        .setStrokeStyle('#000')
        .setLineWidth(1),
};
var ChartGridView = (function (_super) {
    __extends(ChartGridView, _super);
    function ChartGridView(context) {
        var _this = _super.call(this, context, 'ChartGridView', defaultStyle) || this;
        _this.gridLines = GridLines.BOTH;
        _this.center = { x: 0, y: 0 };
        _this.xAxis = [];
        _this.yAxis = [];
        return _this;
    }
    ChartGridView.prototype.onLayout = function () {
        if (!this.dataContainer) {
            this.xAxis = this.yAxis = [];
            return;
        }
        this.xAxis = this.dataContainer.getXAxisData();
        this.yAxis = this.dataContainer.getYAxisData();
    };
    ChartGridView.prototype.getGridLines = function () {
        return this.gridLines;
    };
    ChartGridView.prototype.setGridLines = function (gridLines) {
        this.gridLines = gridLines;
        this.require(RequiredViewChanges.DRAW);
    };
    ChartGridView.prototype.getCenterPoint = function () {
        return this.center;
    };
    ChartGridView.prototype.setCenterPoint = function (center) {
        if (!center) {
            return;
        }
        this.center = center;
        this.require(RequiredViewChanges.DRAW);
    };
    ChartGridView.prototype.getStyle = function () {
        return this.style;
    };
    ChartGridView.prototype.setStyle = function (style) {
        this.style = style || defaultStyle;
        this.require(RequiredViewChanges.DRAW);
    };
    ChartGridView.prototype.onDraw = function (canvas) {
        var _a = this, gridLines = _a.gridLines, innerWidth = _a.innerWidth, innerHeight = _a.innerHeight, dataContainer = _a.dataContainer, center = _a.center, _b = _a.style, paint = _b.paint, centerPaint = _b.centerPaint, xAxis = _a.xAxis, yAxis = _a.yAxis;
        if (!dataContainer) {
            return;
        }
        var ctx = canvas.context;
        var halfLineWidth = paint.lineWidth / 2;
        var _c = dataContainer.getScales(this.innerWidth, this.innerHeight), xScale = _c.xScale, yScale = _c.yScale;
        var x = center.x, y = center.y;
        ctx.translate(halfLineWidth, halfLineWidth);
        ctx.beginPath();
        if (gridLines !== GridLines.HORIZONTAL) {
            for (var i = 0, l = xAxis.length; i < l; i++) {
                var position = xAxis[i].position;
                var scaledPosition = xScale(position);
                if (position === x) {
                    canvas.drawPath(paint);
                    ctx.beginPath();
                }
                ctx.moveTo(scaledPosition, 0);
                ctx.lineTo(scaledPosition, innerHeight);
                if (position === x) {
                    canvas.drawPath(centerPaint || paint);
                    ctx.beginPath();
                }
            }
        }
        if (gridLines !== GridLines.VERTICAL) {
            for (var i = 0, l = yAxis.length; i < l; i++) {
                var position = yAxis[i].position;
                var scaledPosition = yScale(position);
                if (position === y) {
                    canvas.drawPath(paint);
                    ctx.beginPath();
                }
                ctx.moveTo(0, scaledPosition);
                ctx.lineTo(innerWidth, scaledPosition);
                if (position === y) {
                    canvas.drawPath(centerPaint || paint);
                    ctx.beginPath();
                }
            }
        }
        canvas.drawPath(paint);
    };
    ChartGridView.prototype.getCanvasPositionForPoint = function (point) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        var x = xScale(point.x);
        var y = isNumber(point.y) ? yScale(point.y) : 0;
        return {
            x: x,
            y: y,
            absoluteX: this.offsetRect.l + x,
            absoluteY: this.offsetRect.t + y,
        };
    };
    ChartGridView.prototype.getPointForCanvasPosition = function (position) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        return {
            x: xScale.invert(position.x),
            y: yScale.invert(position.y),
        };
    };
    return ChartGridView;
}(ChartView));
export { ChartGridView };
