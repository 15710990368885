import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';

import { TestId } from '+config/testIds';
import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { TooltipHoverable } from '+shared/components';
import { Button } from '+shared/components/Button';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { LeadProductType } from '+shared/store/lead/types';

import './ProductCard.scss';

const {
  startNewConfiguration,
  products: {
    dynamicTariff,
    newConfiguration: {
      bulletPoints: {
        sonnenFlat: { titleBold },
      },
    },
  },
  newConfiguration: {
    products,
    bulletPoints: { sonnenFlat, sonnenFlatDirect },
  },
} = T.lead.configuration._salessolution_;

type CardContent = {
  title: string;
  bulletPoints: string[];
};
type SonnenFlatText = {
  title: string;
  bulletPoints: string;
};

type ProductCardProps = {
  productType: LeadProductType;
  onClick?: () => void;
  disabled?: boolean;
  onHoverText?: string;
};

export const ProductCard = ({ disabled, onClick, productType, onHoverText }: ProductCardProps) => {
  const isFlatDirect = productType === LeadProductType.FLAT_DIRECT;
  const isFlatX = productType === LeadProductType.FLAT_X;
  const headline = I18n.t(isFlatDirect ? products.sonnenFlatDirect : products.sonnenFlat);

  const translations: (flat: SonnenFlatText) => CardContent = (flat) => {
    return { title: I18n.t(flat.title), bulletPoints: I18n.t(flat.bulletPoints).split('\n') };
  };

  const { title, bulletPoints } = isFlatDirect
    ? translations(sonnenFlatDirect)
    : translations(sonnenFlat);

  return (
    <div
      data-testid="product-card"
      className={classNames('product-card', {
        'product-card--disabled': disabled,
        'product-card--decommissioned': isFlatX,
        'product-card--flatDirect': isFlatDirect && !disabled,
      })}
    >
      <div className="product-card__content">
        <div className="product-card__header">
          <Icon.Contract
            className={classNames('product-card__icon', {
              'product-card__icon--disabled': disabled,
            })}
          />

          <LabelLarge text={headline} className="product-card__headline" />

          {(disabled || isFlatX) && <StatusTile label={I18n.t(dynamicTariff.unavailable)} />}

          {!disabled && isFlatDirect && (
            <StatusTile
              label={I18n.t(products.sonnenFlatDirectLabel)}
              color={StatusTileColors.BLUE}
            />
          )}
        </div>

        <div className="product-card__list">
          {isFlatX && <LabelMedium text={I18n.t(titleBold)} className="title-bold" />}

          <BodyMedium text={title} />

          <ul>
            {bulletPoints.map((bulletPoint, index) => (
              <li key={index}>{bulletPoint}</li>
            ))}
          </ul>
        </div>
      </div>

      {!disabled && !isFlatX && (
        <TooltipHoverable
          title={onHoverText}
          isHoveringDisabled={!onHoverText}
          className="product-card__tooltip"
        >
          <Button
            dataTestId={TestId.Lead.ConfigurationOverview.StartNewConfigurationButton}
            onClick={onClick}
            label={
              <>
                <Icon.Plus className="c-lead-list-header__button-icon" />
                {I18n.t(startNewConfiguration)}
              </>
            }
          />
        </TooltipHoverable>
      )}
    </div>
  );
};
