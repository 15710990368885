import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Modal, ModalLayout } from '@sonnen/shared-web';

import { Button, ButtonSize, ButtonType } from '+shared/components/Button';

import { LeadConfigurationFlatDirectIllustration } from '../LeadConfigurationFlatDirectIllustration';

import './LeadConfigurationPvCommissioningModal.component.scss';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSwitch: () => void;
}

export const LeadConfigurationPvCommissioningModal: React.VFC<Props> = ({
  isOpen,
  onCancel,
  onSwitch,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} size={'medium'}>
      <ModalLayout
        title={I18n.t(T.lead.configuration._salessolution_.pv.pvCommissioningModal.title)}
        subtitle={I18n.t(T.lead.configuration._salessolution_.pv.pvCommissioningModal.subtitle)}
        footer={
          <div className={'c-lead-configuration-pv-commissioning-modal__buttons'}>
            <Button
              label={I18n.t(
                T.lead.configuration._salessolution_.pv.pvCommissioningModal.changeDateButton
              )}
              size={ButtonSize.LARGE}
              type={ButtonType.SECONDARY}
              onClick={onCancel}
            />
            <Button
              label={I18n.t(
                T.lead.configuration._salessolution_.pv.pvCommissioningModal.switchFlatButton
              )}
              size={ButtonSize.LARGE}
              type={ButtonType.TERTIARY}
              onClick={onSwitch}
            />
          </div>
        }
      >
        <div className={'c-lead-configuration-pv-commissioning-modal__body'}>
          <div className={'c-lead-configuration-pv-commissioning-modal__body-image'}>
            <LeadConfigurationFlatDirectIllustration />
          </div>
          <div className={'c-lead-configuration-pv-commissioning-modal__body-info'}>
            {I18n.t(T.lead.configuration._salessolution_.pv.pvCommissioningModal.info)}
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};
