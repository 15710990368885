import * as React from 'react';

import { FormCheckbox } from '@sonnen/shared-web';

import { FormikProps } from 'formik';
import { get } from 'lodash/fp';

import './FormInputCheckbox.component.scss';

interface Props<T, U> {
  name: string;
  label: string;
  value: U;
  form: FormikProps<T>;
  className?: ClassValue;
}

export const FormInputCheckbox = <T extends any = any, U extends any = any>({
  form,
  name,
  label,
  value,
}: Props<T, U>) => {
  const id = name + '-' + value;
  const values: any[] = get(name)(form.values);

  return (
    <div className={'c-form-input-checkbox'}>
      <FormCheckbox
        name={id}
        checked={values.includes(value)}
        onChange={() => {
          if (values.includes(value)) {
            const nextValue = values.filter((val) => val !== value);
            form.setFieldValue(name, nextValue);
          } else {
            const nextValue = values.concat(value);
            form.setFieldValue(name, nextValue);
          }
          form.setFieldTouched(name, true, false);
        }}
        label={label}
      />
    </div>
  );
};
