import { map } from 'lodash';

const keyCharAt = (key: string, i: number) => {
  return key.charCodeAt(Math.floor(i % key.length));
};

export const xorEncrypt = (data: string, encryptionKey: string) => {
  return map(data, (char, i) => {
    return char.charCodeAt(0) ^ keyCharAt(encryptionKey, i);
  }).join('a');
};

export const xorDecrypt = (data: string, encryptionKey: string) => {
  const dataArr = data.split('a');
  return map(dataArr, (charCode: any, i: number) => {
    return String.fromCharCode(charCode ^ keyCharAt(encryptionKey, i));
  }).join('');
};

export const encryptSearchTerm = (routerQueryParams: any, encryptionKey: string) => {
  return {
    ...routerQueryParams,
    search: routerQueryParams.search && xorEncrypt(routerQueryParams.search, encryptionKey),
  };
};
