import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Card } from '@sonnen/shared-web';

import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { defaultTo } from 'lodash/fp';

import { getLeadOfferData } from '+app/+lead/+offer/store/+offer.selectors';
import {
  getOfferList,
  getProductBatteryList,
} from '+app/+lead/+overview/store/+overview.selectors';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { Form, FormErrorBanner, FormSubmitButton, StatusTile } from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { LayoutActions } from '+shared/store/layout';
import { LeadProductType } from '+shared/store/lead/types';
import { BatteryModelName } from '+shared/store/lead/types/leadBattery.interface';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { isStatusSet } from '+utils/status.util';

import { LeadConfigurationConsumption } from '../../components';
import { LeadConfigurationHardware } from '../../components/LeadConfigurationHardware';
import {
  CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
  CONFIGURATION_SUBMIT_QUERY,
  ConfigurationPageActions,
} from '../../store';
import { getConfigurationTypeAndVersion } from '../../store/+configuration.helper';
import {
  getConfigurationForm,
  getConfigurationProposal,
  getConfigurationRecommendationSubmitQuery,
  getConfigurationRecommendationSubmitQueryStatus,
  getConfigurationSubmitQuery,
  getConfigurationSubmitQueryStatus,
} from '../../store/+configuration.selectors';
import { configurationFormInitial, ConfigurationSchema } from '../../store/schemas';
import { ConfigurationForm } from '../../store/types';
import { getFormFieldsForEachPv, PvKind } from '../LeadConfigurationPv/LeadConfigurationPv.helper';
import {
  BatteriesWithAccessories,
  findAcceptedHwProduct,
  formFields,
  mapLeadErrorTitleToTranslatedError,
  scrolltoError,
  shouldSkipRecommendation,
} from './LeadConfigurationForm.helper';

import './LeadConfigurationForm.component.scss';

const mapStateToProps = (state: StoreState) => ({
  configurationForm: getConfigurationForm(state),
  getConfigurationSubmitQueryStatus: getConfigurationSubmitQueryStatus(state),
  configurationSubmitQuery: getConfigurationSubmitQuery(state),
  configurationRecommendationSubmitQuery: getConfigurationRecommendationSubmitQuery(state),
  configurationRecommendationSubmitQueryStatus:
    getConfigurationRecommendationSubmitQueryStatus(state),
  existingConfigurationProposal: getConfigurationProposal(state),
  productBatteryList: getProductBatteryList(state),
  offer: getLeadOfferData(state),
  offers: getOfferList(state),
  lead: getLead(state),
});

const mapDispatchToProps = mapActions({
  setConfigurationForm: ConfigurationPageActions.setConfigurationForm,
  removeConfiguration: ConfigurationPageActions.removeConfiguration,
  createRecommendation: ConfigurationPageActions.createRecommendation,
  createOptionalRecommendation: ConfigurationPageActions.createOptionalRecommendation,
  clearConfigurationHint: ConfigurationPageActions.clearConfigurationHint,
  clearQuery: QueryActions.init,
  scrollToTop: LayoutActions.scrollToTop,
  clearConfigurationData: ConfigurationPageActions.clearData,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, StaticContext, { isFormMultiplePv: boolean } | undefined>;

const LeadConfigurationFormComponent: React.FC<Props> = ({
  actions,
  configurationForm,
  getConfigurationSubmitQueryStatus,
  configurationSubmitQuery,
  configurationRecommendationSubmitQuery,
  configurationRecommendationSubmitQueryStatus,
  existingConfigurationProposal,
  productBatteryList,
  offer,
  offers,
  lead,
  location,
}) => {
  const [batteriesWithAccessories, setBatteriesWithAccessories] =
    useState<BatteriesWithAccessories>({});
  const [configurationErrors, setConfigurationErrors] = useState<string[]>([]);

  const initialValues = defaultTo(
    configurationFormInitial(!isStatusSet(lead?.status.summary.hardwareAlreadySold))
  )(configurationForm);
  const { recalculate, calculateButton } = T.lead.configuration._salessolution_;
  const submitButtonLabel = existingConfigurationProposal
    ? I18n.t(recalculate)
    : I18n.t(calculateButton);

  // flatx 1.0 OR flatdirect 2.0
  const { productType, productVersion } = getConfigurationTypeAndVersion(location);

  const shouldSetMultiplePvByRedirect = Boolean(location.state?.isFormMultiplePv);

  useEffect(() => {
    if (getConfigurationSubmitQueryStatus.success) {
      actions.clearQuery(CONFIGURATION_SUBMIT_QUERY);
    }
  }, [getConfigurationSubmitQueryStatus]);

  useEffect(() => {
    const configurationSubmitErrors =
      configurationSubmitQuery.error && configurationSubmitQuery.error.response.parsedBody();

    const configurationRecommendationSubmitErrors =
      configurationRecommendationSubmitQuery.error &&
      configurationRecommendationSubmitQuery.error.response &&
      configurationRecommendationSubmitQuery.error.response.parsedBody();

    Promise.all([configurationSubmitErrors, configurationRecommendationSubmitErrors]).then(
      (resArray) => {
        const errors = resArray.map((res) => (res ? res.errors[0] : {}));

        const filteredErrors = errors
          .filter((error) => !isEmpty(error.title))
          .map(({ title, detail }) => mapLeadErrorTitleToTranslatedError(title || '', detail || ''))
          .filter(
            // only unique keys
            (v: string, i: number, a: string[]) => a.indexOf(v) === i
          );

        setConfigurationErrors(filteredErrors);
        scrolltoError();
      }
    );
  }, [getConfigurationSubmitQueryStatus.error, configurationRecommendationSubmitQueryStatus.error]);

  const onFieldValueChange =
    (form: FormikProps<ConfigurationForm>) =>
    (payload: FormInputSubscriptionPayload<ConfigurationForm>) => {
      const { name, value } = payload;
      const { values: formValues } = form;

      const ignoredFields = [
        formFields.CAPACITY_GROSS,
        formFields.PEAK_POWER,
        formFields.PROTECT_INSTALLED,
        formFields.PROTECT_MODE,
      ];

      const batteryName =
        name === formFields.MODEL_NAME
          ? (value as BatteryModelName)
          : formValues[formFields.MODEL_NAME];

      const commissioningDate =
        name === formFields.COMMISSIONING_DATE
          ? (value as number)
          : formValues[formFields.COMMISSIONING_DATE];

      const isNewBattery =
        name === formFields.NEW_BATTERY ? (value as boolean) : formValues[formFields.NEW_BATTERY];

      if ((ignoredFields as string[]).includes(name)) {
        return;
      }

      if (name === formFields.NEW_BATTERY) {
        form.setFieldValue(formFields.MODEL_NAME, BatteryModelName.SONNENBATTERIE_10_AC);
        form.setFieldValue(formFields.CAPACITY_GROSS, '');
        form.setFieldTouched(formFields.CAPACITY_GROSS, false);
        return;
      }

      if (name === formFields.MODEL_NAME) {
        form.setFieldValue(formFields.CAPACITY_GROSS, '');
        form.setFieldTouched(formFields.CAPACITY_GROSS, false);
      }

      if (existingConfigurationProposal && name === formFields.MULTIPLE_PV) {
        actions.clearConfigurationData();

        // after submitting all form fields are set to touched and new fields appear with errors
        // so it's necessary to set them back to untouched
        Object.values(getFormFieldsForEachPv(PvKind.SECOND_PV)).forEach((fieldName) =>
          form.setFieldTouched(fieldName, false)
        );
        form.setFieldTouched(formFields.DSO_CONSENT_TO_COMBINE_PHOTOVOLTAIC_SYSTEMS, false);
      }

      if (
        shouldSkipRecommendation({ batteryName, commissioningDate, isNewBattery }) ||
        formValues.multiplePv ||
        (name === formFields.MULTIPLE_PV && !value)
      ) {
        actions.clearQuery(CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY);
        return;
      }

      actions.clearConfigurationHint();

      const formValuesChanged = {
        ...formValues,
        productType,
        productVersion,
        [name]: value,
      };

      actions.createRecommendation({
        ...formValuesChanged,
        recommendationMode: 'recommend_min_pv_peak_power_and_battery_capacity_for_zero_cost',
      });

      if (productType === LeadProductType.FLAT_DIRECT) {
        actions.createOptionalRecommendation({
          ...formValuesChanged,
          recommendationMode:
            // eslint-disable-next-line max-len
            'recommend_min_pv_peak_power_and_battery_capacity_for_zero_cost_with_eeg_feed_in_tariff',
        });
      }
    };

  const updateBatteriesWithAccessories = (batteries: BatteriesWithAccessories) => {
    setBatteriesWithAccessories({ ...batteries });
  };

  const onSubmit = (values: ConfigurationForm) => {
    const { protectInstalled, capacityGross } = values;

    values.accessories = protectInstalled
      ? [batteriesWithAccessories[capacityGross].idSelectedAccessory]
      : [];

    if (existingConfigurationProposal) {
      actions.removeConfiguration(existingConfigurationProposal.id);
    }

    if (offer) {
      const battery = productBatteryList.find((battery) => battery.id === offer.battery);

      if (!battery) {
        return;
      }

      actions.setConfigurationForm({
        ...values,
        capacityGross: battery?.id,
        modelName: battery?.modelName,
        productType,
        productVersion,
      });
    } else {
      actions.setConfigurationForm({
        ...values,
        productType,
        productVersion,
      });
    }
    actions.scrollToTop();
  };

  const getProductTag = (productType: LeadProductType): string => {
    switch (productType) {
      case LeadProductType.FLAT_DIRECT:
        return I18n.t(T.lead.configuration._salessolution_.tagFlatDirect).toUpperCase();
      case LeadProductType.FLAT_X:
        return I18n.t(T.lead.configuration._salessolution_.tagFlatX).toUpperCase();
      default:
        return '';
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ConfigurationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      render={(form) => (
        <Form className={'c-lead-configuration-form'}>
          <Card
            header={
              <div className={'c-lead-configuration-form__header-container'}>
                <div className={'c-lead-configuration-form__header-product-type'}>
                  <StatusTile label={getProductTag(productType)} />
                </div>
                <div className={'c-lead-configuration-form__header-container-texts'}>
                  <div className={'c-lead-configuration-form__title'}>
                    {I18n.t(T.lead.configuration._salessolution_.formTitle)}
                  </div>
                  <div className={'c-lead-configuration-form__subtitle'}>
                    {I18n.t(T.lead.configuration._salessolution_.formSubtitle)}
                  </div>
                </div>
              </div>
            }
            footerContent={
              <div className={'c-lead-configuration-form__button-wrapper'}>
                <FormSubmitButton
                  label={submitButtonLabel}
                  isSubmitting={getConfigurationSubmitQueryStatus.pending}
                />
              </div>
            }
          >
            <div className={'c-lead-configuration-form__container'}>
              <FormFieldObserver<ConfigurationForm> onChange={onFieldValueChange(form)}>
                <LeadConfigurationConsumption form={form} />
                <LeadConfigurationHardware
                  form={form}
                  productBatteryList={productBatteryList}
                  existingOffer={offer}
                  previouslyAcceptedHardwareOffer={offers.find((offer) =>
                    findAcceptedHwProduct(offer.products)
                  )}
                  isHardwareAlreadySold={isStatusSet(lead?.status.summary.hardwareAlreadySold)}
                  flatType={productType}
                  shouldSetMultiplePv={shouldSetMultiplePvByRedirect}
                  batteriesWithAccessories={batteriesWithAccessories}
                  updateBatteriesWithAccessories={updateBatteriesWithAccessories}
                />
              </FormFieldObserver>

              {configurationErrors.map((translationKey: string) => (
                <FormErrorBanner
                  key={translationKey}
                  isVisible={
                    getConfigurationSubmitQueryStatus.error ||
                    configurationRecommendationSubmitQueryStatus.error
                  }
                  error={translationKey}
                />
              ))}
            </div>
          </Card>
        </Form>
      )}
    />
  );
};

export const LeadConfigurationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadConfigurationFormComponent));
