import React from 'react';

import './Loader.scss';

type Props = {
  className?: string;
};

const DOTS_TOTAL = 3;

export const Loader: React.FC<Props> = ({ className }) => (
  <span className={`loader-default-class ${className}`}>
    {Array(DOTS_TOTAL)
      .fill(0)
      .map((_, index) => (
        <span key={index} className={'loader__dot'} />
      ))}
  </span>
);
