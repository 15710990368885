import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';

import { CustomerAnalysisEnergyFlow } from '../CustomerAnalysisEnergyFlow';
import { CustomerAnalysisStatistics } from '../CustomerAnalysisStatistics';

export const CustomerAnalysis: React.FC = () => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.ENERGY_ANALYSIS);

  const featureFlags = useFlags();

  return (
    <section className={'c-customer-analysis'}>
      <CustomerAnalysisEnergyFlow featureFlags={featureFlags} />
      <CustomerAnalysisStatistics />
    </section>
  );
};
