import { __assign, __extends } from "tslib";
import { calcDimension, Line, normalizeRadius, parseDimension, } from '@kanva/core';
import { isNumber } from 'lodash';
import { LabelsHelper } from '../utils';
import { ChartView } from './chart.view';
var X_SCALE_BAR_OFFSET = .5;
var defaultStyle = {
    paints: {},
    barWidth: .5,
    barRadius: 0,
    isBackgroundBright: true,
};
var BarChartView = (function (_super) {
    __extends(BarChartView, _super);
    function BarChartView(context) {
        var _this = _super.call(this, context, 'BarChartView', defaultStyle) || this;
        _this.labelsHelper = new LabelsHelper();
        _this.barWidth = parseDimension(undefined);
        _this.series = [];
        _this.seriesLength = 0;
        _this.zeroPoint = 0;
        return _this;
    }
    BarChartView.prototype.getLabelOptions = function () {
        return this.labelsHelper.getOptions();
    };
    BarChartView.prototype.setLabelOptions = function (labels) {
        this.labelsHelper.setOptions(labels);
    };
    BarChartView.prototype.setStyle = function (style) {
        _super.prototype.setStyle.call(this, style);
        this.barWidth = parseDimension(this.style.barWidth);
    };
    BarChartView.prototype.onLayout = function () {
        var _this = this;
        var _a = this, innerWidth = _a.innerWidth, innerHeight = _a.innerHeight, dataContainer = _a.dataContainer;
        if (!dataContainer) {
            this.zeroPoint = 0;
            this.seriesLength = 0;
            this.series = [];
            return;
        }
        var allSeries = dataContainer.getAllDataSeries();
        var yScale = dataContainer.getScales(innerWidth, innerHeight).yScale;
        this.zeroPoint = yScale(0);
        this.seriesLength = dataContainer.getSeriesLength();
        this.series = allSeries
            .filter(function (series) { return !_this.dataSeries.length || _this.dataSeries.includes(series.name); })
            .map(function (series) { return (__assign(__assign({}, series), { data: series.data.map(function (value) { return ({ y: value.y || 0, barY: yScale(value.y || 0) }); }) })); });
    };
    BarChartView.prototype.onDraw = function (canvas) {
        var _a = this, zeroPoint = _a.zeroPoint, allSeries = _a.series, seriesLength = _a.seriesLength, style = _a.style, groupWidth = _a.groupWidth;
        var seriesCount = this.series.length;
        var ctx = canvas.context;
        var radius = normalizeRadius(style.barRadius || 0);
        var barLine = new Line();
        var maxBarWidth = groupWidth / this.series.length;
        var barWidth = calcDimension(this.barWidth, maxBarWidth) || maxBarWidth;
        for (var i = 0, left = 0, l = seriesLength; i < l; i++) {
            var right = left + groupWidth;
            var barRight = left + (groupWidth - barWidth * seriesCount) / 2;
            for (var j = 0; j < seriesCount; j++) {
                var series = allSeries[j];
                var s = style.paints[series.name];
                var _b = series.data[i] || { y: 0, barY: zeroPoint }, y = _b.y, barY = _b.barY;
                var top_1 = Math.min(zeroPoint, barY);
                var bottom = Math.max(zeroPoint, barY);
                var height = Math.abs(top_1 - bottom);
                ctx.beginPath();
                canvas.roundRect(barRight, top_1, barWidth, height, radius);
                canvas.drawPath(s);
                if (this.getLabelOptions()) {
                    barLine.startX = barLine.endX = barRight + barWidth / 2;
                    barLine.startY = zeroPoint;
                    barLine.endY = barY;
                    this.labelsHelper.draw(canvas, y, j, barLine, s);
                }
                barRight += barWidth;
            }
            left = right;
        }
    };
    BarChartView.prototype.getCanvasPositionForPoint = function (point) {
        var yScale = this.getScales().yScale;
        var x = (point.x + X_SCALE_BAR_OFFSET) * this.groupWidth;
        var y = isNumber(point.y) ? yScale(point.y) : 0;
        return {
            x: x,
            y: y,
            absoluteX: this.offsetRect.l + x,
            absoluteY: this.offsetRect.t + y,
        };
    };
    BarChartView.prototype.getPointForCanvasPosition = function (position) {
        if (!this.dataContainer) {
            return undefined;
        }
        var yScale = this.getScales().yScale;
        return {
            x: Math.round(position.x / this.groupWidth - X_SCALE_BAR_OFFSET),
            y: yScale.invert(position.y),
        };
    };
    BarChartView.prototype.getScales = function () {
        return this.dataContainer.getScales(this.innerWidth, this.innerHeight);
    };
    Object.defineProperty(BarChartView.prototype, "groupWidth", {
        get: function () {
            return this.innerWidth / this.seriesLength;
        },
        enumerable: false,
        configurable: true
    });
    return BarChartView;
}(ChartView));
export { BarChartView };
