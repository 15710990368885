import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointDown, Status, StatusType, TableCell } from '@sonnen/shared-web';

import { SetupLeadModal } from '+app/+lead/components/SetupLeadModal/SetupLeadModal';
import { mapActions } from '+app/utils';
import { LeadListBattery, LeadListLocation, SetupLeadStatuses } from '+lead/+list/components';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { getActiveStatusManagementStatuses } from '+setupTool/store/+setupTool.helpers';
import { ButtonDirection } from '+shared/components';
import { ModalId } from '+shared/store/layout';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';

import { LeadName } from '../LeadName/LeadName';

import './SetupLeadListTableItem.component.scss';

const { list, boc } = T.lead;
const cls = 'c-lead-list-table-item';
const cell = `${cls}__cell`;
const setup = `${cell}--setup`;

const mapDispatchToProps = mapActions({
  changeLeadStatus: LeadPageActions.changeLeadStatus,
});

type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;

interface OwnProps {
  item: Lead;
  onLeadClick: (leadId: string) => void;
  onLeadOfferClick: (leadId: string) => void;
  isStatusModalOpen: boolean;
  toggleStatusModal: (arg: boolean, modalId: string) => void;
  userCompanyName: string;
}

export const SetupLeadListTableItemComponent: React.FC<Props> = ({
  actions,
  item,
  onLeadClick,
  onLeadOfferClick,
  isStatusModalOpen,
  toggleStatusModal,
  userCompanyName,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const open = isStatusModalOpen && modalOpen;
  const { id, status, isNewlyAssigned } = item;
  const modalId = id as ModalId;

  useEffect(() => {
    // this is necessary to close the dark transparent Layout behind Modal in case of network error
    if (!modalOpen) toggleStatusModal(false, modalId);
  }, [modalOpen]);

  const closeModal = () => {
    toggleStatusModal(false, modalId);
    setModalOpen(false);
  };
  const openModal = (event: any) => {
    event.stopPropagation();
    toggleStatusModal(true, modalId);
    setModalOpen(true);
  };

  const changeLeadStatus =
    (leadId: string) =>
    (...statusNames: LeadStatusName[]) =>
      actions.changeLeadStatus(leadId, statusNames);

  const configurationButton = () => (
    <button
      className={`${cls}__round-btn-wrapper`}
      data-testid="start-setup-configuration button"
      onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onLeadOfferClick(id);
      }}
    >
      <p>{I18n.t(list._salessolution_.startSetupConfigurationButton)}</p>
      <div
        className={`c-round-button c-round-button--info ${cls}__round-btn`}
        style={{ '--size': 22, '--direction': ButtonDirection.RIGHT } as React.CSSProperties}
      >
        <i className="c-round-button__inner">
          <span className="icofont-angle" />
        </i>
      </div>
    </button>
  );

  return (
    <>
      {open && (
        <div onClick={(e) => e.stopPropagation()}>
          <SetupLeadModal
            closeModal={closeModal}
            modalOpen={open}
            lead={item}
            updateStatuses={changeLeadStatus}
            userCompanyName={userCompanyName}
          />
        </div>
      )}

      <div className={`${cls} ${setup}`} onClick={() => onLeadClick(id)}>
        <Media query={{ maxWidth: breakpointDown('LG') }}>
          {(isMedium: boolean) =>
            isMedium ? (
              <>
                <TableCell className={`${cell} ${cell}--mobile-top`}>
                  <LeadName lead={item} />
                  <LeadListLocation item={item} />
                  <LeadListBattery item={item} />
                </TableCell>

                <TableCell className={`${cell} ${cell}--mobile-bottom`}>
                  <SetupLeadStatuses
                    statuses={getActiveStatusManagementStatuses(status)}
                    openModal={openModal}
                  />
                  {configurationButton()}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell className={`${cell} ${setup}-name`}>
                  <LeadName lead={item} />
                </TableCell>
                <TableCell className={`${cell} ${setup}-location`}>
                  <LeadListLocation item={item} />
                </TableCell>
                <TableCell className={`${cell} ${setup}-battery`}>
                  <LeadListBattery item={item} />
                </TableCell>
                <TableCell className={`${cell} ${setup}-status`}>
                  <SetupLeadStatuses
                    statuses={getActiveStatusManagementStatuses(status)}
                    openModal={openModal}
                  />
                </TableCell>
                <TableCell className={`${cell} ${setup}-configuration`}>
                  {configurationButton()}
                </TableCell>
              </>
            )
          }
        </Media>
      </div>

      {isNewlyAssigned && (
        <Status type={StatusType.SUCCESS} label={I18n.t(list._setuptool_.newLead)} />
      )}

      {isLeadSonnenNowInterested(item) && (
        <Status
          type={StatusType.WARNING}
          label={I18n.t(boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
        />
      )}
    </>
  );
};

export const SetupLeadListTableItem = connect(
  null,
  mapDispatchToProps
)(SetupLeadListTableItemComponent);
