import { createLayoutMap, MATCH_PARENT, PARENT_ID, WRAP_CONTENT } from '@kanva/core';

import { Dimensions, Views } from '../AnalysisDayChart/AnalysisDayChart.layout';

export const bandChartLayout = createLayoutMap({
  bandChartWrapper: {
    width: MATCH_PARENT,
    height: Dimensions.BAND_CHART_HEIGHT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    below: Views.LINE_CHART_WRAPPER,
    margin: {
      top: Dimensions.BAND_CHART_MARGIN,
    },
  },
  bandChart: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  bandChartGrid: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: Dimensions.X_AXIS_HEIGHT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
    below: Views.BAND_CHART_WRAPPER,
    padding: {
      top: Dimensions.X_AXIS_PADDING + Dimensions.DESKTOP_PADDING,
    },
  },
  yAxisLeft: {
    width: WRAP_CONTENT,
    height: Dimensions.BAND_CHART_HEIGHT,
    alignStart: PARENT_ID,
    alignBottom: PARENT_ID,
    above: Views.BAND_CHART_X_AXIS,
    padding: {
      top: Dimensions.BAND_CHART_MARGIN,
      left: Dimensions.Y_AXIS_PADDING,
    },
  },
  yAxisRight: {
    width: WRAP_CONTENT,
    height: Dimensions.BAND_CHART_HEIGHT,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
    above: Views.BAND_CHART_X_AXIS,
    padding: {
      top: Dimensions.BAND_CHART_MARGIN,
      right: Dimensions.Y_AXIS_PADDING,
    },
  },
});
