import { createSelector } from 'reselect';

import { getCustomerHeatpumpPageState } from '+app/+customer/store';
import { getStatus } from '+shared/store/query/query.utils';
import { Device, DeviceType, Heatpump } from '+shared/store/site/types/siteLiveStateV2.interface';

import { GET_HEATPUMP_POLLING_QUERY } from './+heatPump.state';

const getCustomerHeatpumpLiveState = createSelector(
  getCustomerHeatpumpPageState,
  (state) => state.siteLiveStateV2
);

export const getHeatpumpLiveState = createSelector(
  getCustomerHeatpumpLiveState,
  (siteLiveStateV2) =>
    siteLiveStateV2?.devices.filter(
      (item: Device) => item.deviceType === DeviceType.HEATPUMP
    )[0] as Heatpump
);

export const hasSiteHeatpump = createSelector(getCustomerHeatpumpLiveState, (siteLiveStateV2) =>
  Boolean(siteLiveStateV2?.devices.find((item: Device) => item.deviceType === DeviceType.HEATPUMP))
);

export const getHeatpumpPollingQuery = createSelector(getCustomerHeatpumpPageState, (state) =>
  getStatus(state[GET_HEATPUMP_POLLING_QUERY])
);

export const getHeatpumpPollingTimestamp = createSelector(
  getCustomerHeatpumpLiveState,
  (siteLiveStateV2) => siteLiveStateV2?.timestamp
);
