import React, { useState } from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp } from '@sonnen/shared-web/index';

import { SetupLeadModal } from '+app/+lead/components/SetupLeadModal/SetupLeadModal';
import { SetupLeadStatuses } from '+lead/+list';
import { isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { getActiveStatusManagementStatuses } from '+setupTool/store/+setupTool.helpers';
import { AccordionButton } from '+shared/components';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { ModalId } from '+shared/store/layout';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { getStatus } from '+shared/store/query/query.utils';

import { LeadOverviewHeaderTitle, LeadOverviewHeaderWrapper } from '../../components';
import { LeadOverviewInvitation } from '../LeadOverviewInvitation';

import './SetupLeadOverviewHeader.component.scss';

const { accordion } = T.lead.overview._salessolution_.header;

interface SetupLeadOverviewHeaderProps {
  lead: Lead;
  leadStage?: string;
  breadcrumbAction: () => void;
  isModalOpen: boolean;
  toggleModal: (arg: boolean, leadId: ModalId) => void;
  changeLeadStatus: (...status: LeadStatusName[]) => void;
  userCompanyName: string;
  sendCGInvitation: (leadId: string) => void;
  sendCGInvitationQueryStatus: ReturnType<typeof getStatus>;
  hasDynamicTariff: boolean;
}

export const SetupLeadOverviewHeader = ({
  lead,
  leadStage,
  breadcrumbAction,
  isModalOpen,
  toggleModal,
  changeLeadStatus,
  userCompanyName,
  sendCGInvitation,
  sendCGInvitationQueryStatus,
  hasDynamicTariff,
}: SetupLeadOverviewHeaderProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState<boolean>(false);
  const modalId = lead.id as ModalId;

  const closeModal = () => {
    toggleModal(false, modalId);
    setModalOpen(false);
  };
  const openModal = (event: any) => {
    event.stopPropagation();
    toggleModal(true, modalId);
    setModalOpen(true);
  };

  const displayLeadRelatedStatuses = () => (
    <>
      <div className="c-lead-overview-header__lead-statuses">
        {isLeadSonnenNowInterested(lead) && (
          <StatusTile
            label={I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
            color={StatusTileColors.YELLOW}
          />
        )}
      </div>

      <Media query={{ minWidth: breakpointUp('SM') }}>
        {isLeadSonnenNowInterested(lead) && <div className="c-lead-overview-header__separator" />}
      </Media>
    </>
  );

  return (
    <>
      {isModalOpen && modalOpen && (
        <div onClick={(e) => e.stopPropagation()}>
          <SetupLeadModal
            closeModal={closeModal}
            modalOpen={modalOpen}
            lead={lead}
            updateStatuses={changeLeadStatus}
            userCompanyName={userCompanyName}
          />
        </div>
      )}

      <LeadOverviewHeaderWrapper
        lead={lead}
        breadcrumbAction={breadcrumbAction}
        leadStage={leadStage}
      >
        <Media query={{ minWidth: breakpointUp('MD') }}>
          {(isMedium: boolean) =>
            isMedium ? (
              <div className="c-lead-overview-header">
                <div className="c-lead-overview-header__upper">
                  <LeadOverviewHeaderTitle lead={lead} />
                </div>

                <div className="c-lead-overview-header__lower">
                  <div className="c-lead-overview-header__lower-right">
                    {displayLeadRelatedStatuses()}

                    {!hasDynamicTariff && (
                      <LeadOverviewInvitation
                        lead={lead}
                        isPending={sendCGInvitationQueryStatus.pending}
                        sendCGInvitation={sendCGInvitation}
                      />
                    )}
                  </div>

                  <SetupLeadStatuses
                    statuses={getActiveStatusManagementStatuses(lead.status)}
                    openModal={openModal}
                  />
                </div>
              </div>
            ) : (
              <div className="c-lead-overview-header">
                <div className="c-lead-overview-header__mobile-upper">
                  <LeadOverviewHeaderTitle lead={lead} />

                  {!hasDynamicTariff && (
                    <div className="c-lead-overview-header__accordion-wrapper">
                      <AccordionButton
                        isAccordionOpen={mobileAccordionOpen}
                        setAccordionOpen={setMobileAccordionOpen}
                        messageOpen={I18n.t(accordion.showLess)}
                        messageClosed={I18n.t(accordion.showMore)}
                      />
                    </div>
                  )}
                </div>

                <div
                  className={
                    'c-lead-overview-header__mobile-lower' +
                    'c-setup-overview-header__mobile-lower--flex'
                  }
                >
                  {mobileAccordionOpen && !hasDynamicTariff && (
                    <div className="c-lead-overview-header__accordion">
                      <div className="c-lead-overview-header__mobile-lower-right">
                        <LeadOverviewInvitation
                          lead={lead}
                          isPending={sendCGInvitationQueryStatus.pending}
                          sendCGInvitation={sendCGInvitation}
                        />
                      </div>
                      <div className="c-lead-overview-header__separator--bottom" />
                    </div>
                  )}

                  {displayLeadRelatedStatuses()}

                  <SetupLeadStatuses
                    statuses={getActiveStatusManagementStatuses(lead.status)}
                    openModal={openModal}
                  />
                </div>
              </div>
            )
          }
        </Media>
      </LeadOverviewHeaderWrapper>
    </>
  );
};
