import {
  EnergyFlowSeriesKey,
  MAX_FUTURE_DAYS,
  Measurement,
  Point,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';

import { XYPoint } from '@kanva/charts';
import { head } from 'lodash';
import { isEmpty, isNil } from 'lodash/fp';

import { Battery, BatteryWithProduct } from '+shared/store/battery/types';
import {
  MeasurementName,
  SiteLiveStateAttributes,
  SiteMeasurements,
  SiteStatistics,
} from '+shared/store/site/types';
import { dateUtil, TimeUnit } from '+utils/date.util';
import { DateAdapterTimezone } from '+utils/timezone-types';

import analysisChartStyles from '../../containers/CustomerAnalysisEnergyFlow/CustomerAnalysisEnergyFlow.component.scss';
import { SiteForecastData } from '../types/forecast.interface';

export const isUnsupportedBattery = (battery: Battery | undefined) =>
  !isNil(battery) && !isNil(battery.serialNumber) ? Number(battery.serialNumber[0]) > 3 : false;

export const hasMeasurement = (
  attributes: SiteStatistics,
  attributeKey: EnergyFlowSeriesKey | StatisticsSeriesKey
) =>
  Boolean(
    !isEmpty(attributes[attributeKey]) &&
      attributes[attributeKey].some((point: Measurement) => !isNil(point))
  );

export const hasMeasurements = (measurements: SiteMeasurements | undefined) =>
  Boolean(
    !isNil(measurements) &&
      measurements.fields.map((field) => Object.values(MeasurementName).includes(field.name)) &&
      head(Object.values(measurements.values))?.length === measurements.fields.length
  );

export const getLiveSeriesPoint = (timestamp: string, value: number): XYPoint => {
  return {
    x: dateUtil.getUnixFromDateInSeconds(new Date(timestamp)),
    y: value,
  };
};

export const analysisPointAccessor = (
  { x, y }: Partial<XYPoint>,
  _: number,
  all: Array<Partial<XYPoint>>
) => ({
  y: y!,
  x: (x! - all[0].x!) / 60,
});

export const getFirstForecastSeriesPoint = (date: Date) => ({
  x: dateUtil.getUnixFromDateInSeconds(dateUtil.getStartOf(date, TimeUnit.DAY)),
  y: null,
});

export const updateForecastSeries = ({
  forecastSeries,
  liveDataTimestamp,
  liveDataPower,
}: {
  forecastSeries: Point[];
  liveDataTimestamp: string;
  liveDataPower: number | undefined;
}) => {
  if (!forecastSeries.length) {
    return [];
  }

  const liveSeriesPoint = getLiveSeriesPoint(
    liveDataTimestamp,
    liveDataPower || 0 // TODO handle using 0 if measurements data is delayed
  );

  return forecastSeries.length
    ? [
        getFirstForecastSeriesPoint(dateUtil.now()),
        liveSeriesPoint,
        ...forecastSeries.filter((forecast) =>
          dateUtil.isSameOrAfter(
            dateUtil.ofSecondsSince1970(forecast.x),
            dateUtil.ofSecondsSince1970(liveSeriesPoint.x)
          )
        ),
      ]
    : [];
};

export const getLastDataPoint = (dataPoints: XYPoint[]) =>
  dataPoints.length
    ? dataPoints.slice(-1)[0]
    : { x: dateUtil.getUnixFromDateInSeconds(dateUtil.now()), y: 0 };

export const getForecastStartDate = (liveState: SiteLiveStateAttributes | undefined): number => {
  if (liveState && dateUtil.isToday(dateUtil.of(liveState.timestamp))) {
    return dateUtil.getUnixFromDateInSeconds(dateUtil.of(liveState.timestamp));
  }
  return dateUtil.getUnixFromDateInSeconds(dateUtil.now());
};

const filterCurrentDayForecast = (forecasts: SiteForecastData[], date: Date) => {
  if (dateUtil.isToday(date)) {
    return forecasts.filter((forecast) =>
      dateUtil.isBetween(
        dateUtil.of(forecast.forecastDatetime),
        dateUtil.now(),
        dateUtil.getEndOf(dateUtil.now(), TimeUnit.DAY)
      )
    );
  }

  return forecasts.filter((forecast) =>
    dateUtil.isSameDay(dateUtil.of(forecast.forecastDatetime), dateUtil.of(date))
  );
};

const filterForecastForTimeRange = (
  forecasts: SiteForecastData[],
  startDate: Date,
  endDate: Date
) =>
  forecasts.filter((forecast) => {
    const forcastDate = dateUtil.of(forecast.forecastDatetime);
    return dateUtil.isBetween(
      dateUtil.of(forcastDate),
      dateUtil.getStartOf(startDate, TimeUnit.DAY),
      dateUtil.getEndOf(endDate, TimeUnit.DAY)
    );
  });

export const transformForecastData = ({
  startDate,
  endDate,
  lastDataPointTimestamp,
  lastDataPointPower,
  forecasts,
  forecastDataKey,
}: {
  startDate: Date;
  endDate: Date;
  lastDataPointTimestamp: number;
  lastDataPointPower: number;
  forecasts: SiteForecastData[];
  forecastDataKey: string;
}): XYPoint[] => {
  if (!forecasts.length || !dateUtil.isTodayOrAfter(startDate)) {
    return [];
  }

  const isToday = dateUtil.isToday(startDate);
  const isMoreThanOneDay = dateUtil.getDifference(startDate, endDate, 'days') > 1;

  const timeRangeForecast = filterForecastForTimeRange(forecasts, startDate, endDate);
  const singleDayForecast = filterCurrentDayForecast(forecasts, startDate);

  const forecastArray = isMoreThanOneDay ? timeRangeForecast : singleDayForecast;

  return [
    getFirstForecastSeriesPoint(startDate),
    ...(isToday ? [{ x: lastDataPointTimestamp, y: lastDataPointPower }] : []),
    ...forecastArray.map((forecast) => ({
      x: dateUtil.getUnixFromDateInSeconds(dateUtil.of(forecast.forecastDatetime)),
      y: forecast[forecastDataKey],
    })),
  ];
};

export const getLastForecastDate = (forecast: SiteForecastData[]): string | undefined =>
  !isEmpty(forecast) ? forecast[forecast.length - 1].forecastDatetime : undefined;

export const calculateMaxFutureDate = (forecasts: SiteForecastData[]): Date => {
  const lastForecastDate = dateUtil.of(getLastForecastDate(forecasts) ?? dateUtil.now());
  const maxDate = dateUtil.add(dateUtil.now(), MAX_FUTURE_DAYS, 'day');

  return lastForecastDate < maxDate ? lastForecastDate : maxDate;
};

export const getMaxForecastsDate = (
  production: SiteForecastData[] | undefined,
  consumption: SiteForecastData[] | undefined
): Date => {
  const consumptionDate =
    consumption && !isEmpty(consumption) ? calculateMaxFutureDate(consumption) : undefined;
  const productionDate =
    production && !isEmpty(production) ? calculateMaxFutureDate(production) : undefined;

  if (consumptionDate && productionDate) {
    return consumptionDate > productionDate ? consumptionDate : productionDate;
  }
  if (consumptionDate) {
    return consumptionDate;
  }
  if (productionDate) {
    return productionDate;
  }
  return dateUtil.add(dateUtil.now(), 2, 'day');
};

export const ensureBatteryTimeZone = (
  battery: BatteryWithProduct | undefined
): battery is BatteryWithProduct & { timeZone: DateAdapterTimezone } => {
  return !!battery && !!battery.timeZone;
};

export const getAnalysisChartDimensions = () => {
  const [
    areaChartHeightMobile,
    areaChartHeightDesktop,
    lineChartHeight,
    bandChartHeight,
    xAxisHeight,
    areaChartMargin,
    bandChartMargin,
  ] = [
    analysisChartStyles.areaChartHeightMobile,
    analysisChartStyles.areaChartHeightDesktop,
    analysisChartStyles.lineChartHeight,
    analysisChartStyles.bandChartHeight,
    analysisChartStyles.xAxisHeight,
    analysisChartStyles.areaChartMargin,
    analysisChartStyles.bandChartMargin,
  ].map((style) => style.replace('px', ''));

  return {
    areaChartHeightMobile: Number.parseInt(areaChartHeightMobile, 10),
    areaChartHeightDesktop: Number.parseInt(areaChartHeightDesktop, 10),
    lineChartHeight: Number.parseInt(lineChartHeight, 10),
    bandChartHeight: Number.parseInt(bandChartHeight, 10),
    xAxisHeight: Number.parseInt(xAxisHeight, 10),
    areaChartMargin: Number.parseInt(areaChartMargin, 10),
    bandChartMargin: Number.parseInt(bandChartMargin, 10),
  };
};
