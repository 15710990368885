import { defaultTo, flow } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ensureBatteryIdExists } from '+app/+customer/+pvSystems/store/pvSystems.epics';
import { getBatteryFromSite, getSite } from '+app/+customer/store/+customer.helper';
import { ROUTES } from '+app/router';
import { mapPathToParams, mapToState, matchPath, ofType } from '+app/utils';
import { CustomerActions, getSelectedCustomer } from '+customer/store';
import { RouterActions } from '+router/store';
import { Battery, BatteryActions } from '+shared/store/battery';
import { GET_SITE_LIVE_STATE_QUERY, SiteActions } from '+shared/store/site';
import { StoreState } from '+shared/store/store.interface';

import { getBattery } from './+battery.selectors';
import {
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  GET_BATTERY_IC_STATUS_QUERY,
  GET_BATTERY_QUERY,
} from './+battery.state';

type Action$ = ActionsObservable<RouterActions>;
type State$ = StateObservable<StoreState>;

const getBatteryLatestIcStatus$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(BatteryActions.setBattery),
    withLatestFrom(state$),
    mergeMap(([_, state]) =>
      of(state).pipe(
        matchPath(ROUTES.CUSTOMER_BATTERY[0]),
        map(() => getBattery(state)),
        filter(ensureBatteryIdExists),
        filter((battery) => battery.flexStack),
        map((battery) => ({
          batteryId: battery.id,
        })),
        map(({ batteryId }) =>
          BatteryActions.getBatteryIcStatus({
            queryKey: GET_BATTERY_IC_STATUS_QUERY,
            batteryId,
          })
        )
      )
    )
  );

export const getBatteryDetails$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CustomerActions.setCustomer),
    mapToState(state$),
    mergeMap((state) =>
      of(state).pipe(
        mapPathToParams(
          ROUTES.CUSTOMER[0],
          ROUTES.CUSTOMER_BATTERY[0],
          ROUTES.CUSTOMER_PV_SYSTEMS[0]
        ),
        mergeMap(([_, siteId]) =>
          of(siteId).pipe(
            map(() => getSelectedCustomer(state)),
            mergeMap((customer) =>
              of(customer).pipe(
                filter(() => !!customer),
                map(flow(getSite(siteId), getBatteryFromSite(), defaultTo({} as Battery))),
                map((battery) => ({
                  batteryId: battery.id,
                  siteId,
                }))
              )
            )
          )
        )
      )
    ),
    mergeMap(({ batteryId, siteId }) =>
      merge(
        of(BatteryActions.getBattery({ queryKey: GET_BATTERY_QUERY, batteryId })),
        of(SiteActions.getSiteLiveState({ queryKey: GET_SITE_LIVE_STATE_QUERY, siteId })),
        of(
          BatteryActions.getBatteryElectricUnits({
            queryKey: GET_BATTERY_ELECTRIC_UNITS_QUERY,
            batteryId,
          })
        )
      )
    )
  );

export const epics = combineEpics(getBatteryDetails$, getBatteryLatestIcStatus$);
