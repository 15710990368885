import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { WidgetSimple } from '+shared/components';
import { Lead } from '+shared/store/lead/types';

import { generateCheckboxText, getIconByStatus } from '../LeadEditStatusModal.helper';

import './LeadEditStatusSonnenFlat.component.scss';

interface LeadEditStatusSonnenFlatProps {
  leadStatusSummary: Lead['status']['summary'];
  userCompanyName: string;
}

export const LeadEditStatusSonnenFlat: React.FC<LeadEditStatusSonnenFlatProps> = ({
  userCompanyName,
  leadStatusSummary,
}) => (
  <div className={'c-lead-edit-status-modal__widget-wrapper'} data-testid="sonnenflat-offer-table">
    <WidgetSimple
      heading={I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.header)}
      subheading={I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.subheader)}
      dataTestIdHeader="sonnenflat-offer-header"
      dataTestIdContent="sonnenflat-offer-content"
    >
      <div className={'c-lead-edit_status-modal__automatic-statuses-container'}>
        <div
          className={'c-lead-edit_status-modal__automatic-status'}
          data-testid="sonnenflat-offer-sent"
        >
          {getIconByStatus(leadStatusSummary.flatOfferSent)}
          {generateCheckboxText(
            I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.statuses.sent),
            userCompanyName,
            true,
            'sonnenflat-offer-sent-content'
          )}
        </div>
        <div
          className={'c-lead-edit_status-modal__automatic-status'}
          data-testid="sonnenflat-offer-accepted"
        >
          {getIconByStatus(leadStatusSummary.flatOfferAccepted)}
          {generateCheckboxText(
            I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.statuses.accepted),
            I18n.t(T.lead.list._salessolution_.editStatusModal.flatStatuses.customer),
            false,
            'sonnenflat-offer-accepted-content'
          )}
        </div>
        <div
          className={'c-lead-edit_status-modal__automatic-status'}
          data-testid="sonnenflat-offer-confirmed"
        >
          {getIconByStatus(leadStatusSummary.flatOrderConfirmed)}
          {generateCheckboxText(
            I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.statuses.confirmed),
            I18n.t(T.lead.list._salessolution_.editStatusModal.flatStatuses.sonnen),
            false,
            'sonnenflat-order-confirmed-content'
          )}
        </div>
      </div>
    </WidgetSimple>
  </div>
);
