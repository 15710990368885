import { Action, AnyAction, Reducer } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import { createReducer } from './createReducer.util';

const defaultPersistConfig = {
  storage,
  stateReconciler: autoMergeLevel2,
  timeout: 2000,
};

type PartialPersistConfig = Omit<PersistConfig, 'storage'> & {
  storage?: PersistConfig['storage'];
};

export const createPersistedReducer =
  <S extends object, A extends Action = AnyAction>(reducer: Reducer<S, A>) =>
  (persistConfig: PartialPersistConfig): Reducer<S, A> =>
    persistReducer(
      { ...defaultPersistConfig, ...persistConfig },
      createReducer(reducer)
    ) as Reducer;
