import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Button, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';

import './FormSubmitButton.component.scss';

interface Props {
  label?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onClick?: (...args: any[]) => void;
  theme?: ButtonType;
  dataTestId?: string;
}

export const FormSubmitButton: React.FC<Props> = ({
  label,
  isSubmitting,
  isDisabled,
  onClick,
  theme = ButtonType.SECONDARY,
  dataTestId,
}) => (
  <div className={'c-form-submit-button'}>
    <Button
      label={label || I18n.t(T.lead.boc._salessolution_.form.button)}
      type={theme}
      mainType={MainType.SUBMIT}
      onClick={onClick}
      loading={isSubmitting}
      disabled={isDisabled}
      status={isDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
      dataTestId={dataTestId}
    />
  </div>
);
