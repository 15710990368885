export enum Env {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  DEMO = 'demo',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export type AdobeAnalyticsEnvs = {
  // TODO(SON-36967): demo env - to be removed
  [key in Exclude<Env, 'demo'>]: string;
};

export enum LocalBackendService {
  SALES_SOLUTION = 'saso',
  SETUP_TOOLS = 'st',
  COMPONENTS_DATABASE = 'cdb',
}

export const getFilteredEnvironment = (env: Env) => {
  switch (env) {
    case Env.LOCAL:
    case Env.DEVELOPMENT:
    case Env.DEMO:
    case Env.STAGING:
    case Env.PRODUCTION:
      return env;
    default:
      throw new Error(`Invalid environment: ${env}`);
  }
};

const getLocalBackends = (localBackends: string | undefined): LocalBackendService[] =>
  undefined === localBackends ? [] : localBackends.split(',').map(getLocalBackend);

const getLocalBackend = (localBackend: string): LocalBackendService => {
  switch (localBackend) {
    case LocalBackendService.SALES_SOLUTION:
    case LocalBackendService.SETUP_TOOLS:
    case LocalBackendService.COMPONENTS_DATABASE:
      return localBackend;
    default:
      throw new Error(`Invalid backend service: ${localBackend}`);
  }
};

export const getEnvironment = () =>
  getFilteredEnvironment((process.env.REACT_APP_CONFIG as Env) || Env.PRODUCTION);
export const getLocalBackendServices = () => getLocalBackends(process.env.REACT_APP_BACKEND);
