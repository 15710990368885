import { CarouselSlide } from '+shared/containers/Tutorial/Tutorial.helper';

import { ActionsUnion, createAction } from '../../../utils';

export enum TUTORIAL_ACTIONS {
  SET_INITIAL_SLIDE = '[Tutorial] SET_INITIAL_SLIDE',
  TOGGLE_TUTORIAL = '[Tutorial] TOGGLE_TUTORIAL',
  APPEND_CURRENTLY_DISPLAYED_SLIDES = '[Tutorial] APPEND_CURRENTLY_DISPLAYED_SLIDES',
  REMOVE_CURRENTLY_DISPLAYED_SLIDES = '[Tutorial] REMOVE_CURRENTLY_DISPLAYED_SLIDES',
}

export const TutorialActions = {
  setInitialSlide: createAction(
    TUTORIAL_ACTIONS.SET_INITIAL_SLIDE,
    (tutorialId: string, initialSlide: number) => ({ tutorialId, initialSlide })
  ),
  toggleTutorial: createAction(
    TUTORIAL_ACTIONS.TOGGLE_TUTORIAL,
    (tutorialId: string, isOpened: boolean) => ({ tutorialId, isOpened })
  ),
  appendCurrentlyDisplayedSlides: createAction(
    TUTORIAL_ACTIONS.APPEND_CURRENTLY_DISPLAYED_SLIDES,
    (slides: CarouselSlide[]) => ({ slides })
  ),
  removeCurrentlyDisplayedSlides: createAction(TUTORIAL_ACTIONS.REMOVE_CURRENTLY_DISPLAYED_SLIDES),
};

export type TutorialActions = ActionsUnion<typeof TutorialActions>;
