import { createSelector } from 'reselect';

import { getCustomerPvSystemsPageState } from '+customer/store/+customer.selectors';
import { getStatus } from '+shared/store/query/query.utils';

import { GET_BATTERY_POWER_METER_LIVE_STATES_QUERY } from './pvSystems.state';

export const getBatteryPowerMeterLiveStates = createSelector(
  getCustomerPvSystemsPageState,
  (pvSystemsPageState) => pvSystemsPageState.powerMeterLiveStates
);

export const getBatteryPowerMeterLiveStatesQueryStatus = createSelector(
  getCustomerPvSystemsPageState,
  (pvSystemsPageState) => getStatus(pvSystemsPageState[GET_BATTERY_POWER_METER_LIVE_STATES_QUERY])
);

export const getIsBatteryPvGridFeedInLimitInProgress = createSelector(
  getCustomerPvSystemsPageState,
  (pvSystemsPageState) => pvSystemsPageState.isBatteryPvGridFeedInLimitInProgress
);
