export const GET_SETUP_TOOL_SUBMISSION_DATA_QUERY = 'getSetupToolSubmissionDataQuery';

export const SAVE_REGISTRATION_SUBJECT_TYPE_QUERY = 'saveRegistrationSubjectTypeQuery';

export enum SubmissionStep {
  INITIAL = 'initial',

  SETUP_DATA = 'setup-data',
  SETUP_DATA_CUSTOMER_DATA = 'setup-data/customer-data',
  SETUP_DATA_PV_SYSTEM = 'setup-data/pv-system',
  SETUP_DATA_SONNEN_BATTERY = 'setup-data/sonnen-battery',
  SETUP_DATA_MEASURING_DEVICE = 'setup-data/measuring-device',
  SETUP_DATA_UPLOAD_DOCUMENTS = 'setup-data/upload-documents',
  SETUP_DATA_INSTALLER_DATA = 'setup-data/installer-data',
  // two substeps at the same time
  SETUP_DATA_PV_SYSTEM_AND_SONNEN_BATTERY = 'setup-data/pv-system-and-sonnen-battery',

  DSO_REGISTRATION = 'dso-registration',

  DSO_COMMISSIONING = 'dso-commissioning',
  DSO_COMMISSIONING_DATA = 'dso-commissioning/data',
  DSO_COMMISSIONING_DOCUMENTS = 'dso-commissioning/documents',

  PV_REGISTER = 'pv-register',

  VPP_DOCUMENTATION = 'vpp-documentation',
  VPP_PERFORMANCE = 'vpp-performance',
  VPP_COMPLETION_NOTIFICATION = 'vpp-completion-notification',
}

export enum SubmissionStepCamelCase {
  SETUP_DATA = 'setupData',
  SETUP_DATA_CUSTOMER_DATA = 'setupData/customerData',
  SETUP_DATA_PV_SYSTEM = 'setupData/pvSystem',
  SETUP_DATA_SONNEN_BATTERY = 'setupData/sonnenBattery',
  SETUP_DATA_MEASURING_DEVICE = 'setupData/measuringDevice',
  SETUP_DATA_UPLOAD_DOCUMENTS = 'setupData/uploadDocuments',
  SETUP_DATA_INSTALLER_DATA = 'setupData/installerData',

  DSO_REGISTRATION = 'dsoRegistration',

  DSO_COMMISSIONING = 'dsoCommissioning',
  DSO_COMMISSIONING_DATA = 'dsoCommissioning/data',
  DSO_COMMISSIONING_DOCUMENTS = 'dsoCommissioning/documents',

  PV_REGISTER = 'pvRegister',

  VPP_DOCUMENTATION = 'vppDocumentation',
  VPP_PERFORMANCE = 'vppPerformance',
  VPP_COMPLETION_NOTIFICATION = 'vppCompletionNotification',
}

export enum StatusName {
  IN_PROGRESS = 'inProgress',
  DONE = 'done',
  NEEDS_REGENERATION = 'needsRegeneration',
}

export enum SetupDataStepKey {
  CUSTOMER_DATA = 'customer_data',
  PV_SYSTEM = 'pv_system',
  SONNEN_BATTERY = 'sonnen_battery',
  MEASURING_DEVICE = 'measuring_device',
  UPLOAD_DOCUMENTS = 'upload_documents',
  INSTALLER_DATA = 'installer_data',
}

export enum SetupDataStepStatus {
  NOT_STARTED = 'not_started',
  STARTED = 'started',
  COMPLETED = 'completed',
}
