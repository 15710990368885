import { __extends } from "tslib";
import { PointerAction } from '../canvas-pointer-event';
import { GestureDetector } from './gesture-detector';
import { Point, Rect } from '../../canvas';
var DEFAULT_POINTER_COUNT = 1;
var AreaSelectGestureDetector = (function (_super) {
    __extends(AreaSelectGestureDetector, _super);
    function AreaSelectGestureDetector(options) {
        var _this = _super.call(this) || this;
        _this.options = options;
        _this.isSelecting = false;
        return _this;
    }
    AreaSelectGestureDetector.prototype.onGestureEvent = function (event) {
        return !!this.options.selectArea && this.handleAreaSelectGesture(event);
    };
    AreaSelectGestureDetector.prototype.handleAreaSelectGesture = function (event) {
        if (event.pointerCount > DEFAULT_POINTER_COUNT) {
            return false;
        }
        var selectedArea = undefined;
        switch (event.action) {
            case PointerAction.DOWN:
                this.selectStart = new Point(event.primaryPointer.x, event.primaryPointer.y);
                this.isSelecting = true;
                break;
            case PointerAction.MOVE:
                if (event.primaryPointer.pressure === 0) {
                    return false;
                }
                this.selectEnd = new Point(event.primaryPointer.x, event.primaryPointer.y);
                selectedArea = this.selectedArea;
                break;
            case PointerAction.UP:
                this.selectEnd = new Point(event.primaryPointer.x, event.primaryPointer.y);
                this.isSelecting = false;
                selectedArea = this.selectedArea;
                this.selectStart = undefined;
                this.selectEnd = undefined;
                break;
            case PointerAction.CANCEL:
                this.isSelecting = false;
                this.selectStart = undefined;
                this.selectEnd = undefined;
                break;
            default:
                return false;
        }
        return this.options.onAreaSelect({
            pointerEvent: event,
            selectedArea: selectedArea,
            isSelecting: this.isSelecting,
        });
    };
    Object.defineProperty(AreaSelectGestureDetector.prototype, "selectedArea", {
        get: function () {
            return this.selectStart && this.selectEnd && new Rect({
                left: Math.min(this.selectStart.x, this.selectEnd.x),
                bottom: Math.min(this.selectStart.y, this.selectEnd.y),
                right: Math.max(this.selectStart.x, this.selectEnd.x),
                top: Math.max(this.selectStart.y, this.selectEnd.y),
            });
        },
        enumerable: false,
        configurable: true
    });
    return AreaSelectGestureDetector;
}(GestureDetector));
export { AreaSelectGestureDetector };
