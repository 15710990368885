import React from 'react';

import { Close } from '+shared/basicComponents/Icons';
import { LabelSmall } from '+shared/basicComponents/Typography/Labels';

import './FilterLabel.scss';

type Props = {
  text: string;
  handleClick: () => void;
  dataTestId?: string;
};

const FilterLabel: React.FC<Props> = ({ text, handleClick, dataTestId }) => (
  <div className="filter-label">
    <LabelSmall text={text} />
    <button onClick={handleClick} className="filter-label__button" data-testid={dataTestId}>
      <Close />
    </button>
  </div>
);

export default FilterLabel;
