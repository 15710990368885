import * as React from 'react';

import * as classNames from 'classnames';

import {
  UserCertificate,
  UserCertificateStatus,
} from '+shared/store/user/types/userCertificate.interface';

import { getCertificateStatus } from './CertificateListItemHeader.helper';

import './CertificateListItemHeader.component.scss';

interface Props {
  certificate: UserCertificate;
}

export const CertificateListItemHeader: React.FC<Props> = ({ certificate }) => {
  const certificateStatus = getCertificateStatus(certificate);

  return (
    <div className={'c-certificate-list-item-header'}>
      <div>{certificate.certification}</div>
      <div
        className={classNames('c-certificate-list-item-header__status', {
          'c-certificate-list-item-header__status--expired':
            certificate.status === UserCertificateStatus.EXPIRED,
          'c-certificate-list-item-header__status--active':
            certificate.status === UserCertificateStatus.ACTIVE,
        })}
      >
        {certificateStatus}
      </div>
    </div>
  );
};
