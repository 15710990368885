import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { specialLetters } from '+utils/string.util';

const MIN_CHARACTERS = 3;

export const searchSiteSchema = () =>
  Yup.object({
    search: Yup.string()
      .required(I18n.t(T.report.errors.customerRequired))
      .min(MIN_CHARACTERS, I18n.t(T.validation.minCharacters, { min: MIN_CHARACTERS }))
      .matches(
        new RegExp(`^(^$|[a-zA-Z${specialLetters} ]+)$`),
        I18n.t(T.report.errors.customerSpecialCharacters)
      )
      .trim(),
  });
