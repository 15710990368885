import { trim } from '../../../utils/string.util';
import { User } from './user.state';

export const getUserName = <T extends User | undefined>(user: T) =>
  user && trim`${user.firstName} ${user.lastName}`;

export const getUserPicture = <T extends User | undefined>(user: T) => user && user.image;

export const getUserEmail = <T extends User | undefined>(user: T) => user && user.email;

export const getUserAddress = <T extends User | undefined>(user: T) =>
  user && trim`${user.street}\n${user.postalCode} ${user.city}\n${user.countryCode}`;

export const getUserMobile = <T extends User | undefined>(user: T) => user && user.mobile;

export const getUserPhone = <T extends User | undefined>(user: T) => user && user.phone;

export const getUserCompany = <T extends User | undefined>(user: T) => user && user.companyName;

export const getUserRoles = <T extends User | undefined>(user: T) => (user ? user.roles : []);

export const getUserNumber = <T extends User | undefined>(user: T) => user && user.customerNumber;

export const getUserCertificationId = <T extends User | undefined>(user: T) =>
  user && user.certificationId;
