import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';

import { SupportForm } from '+app/+help/+faqAndContact/components/SupportForm/SupportForm.component';
import { mapActions } from '+app/utils';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { CaseActions, getCaseSubmitQueryStatus, getCreatedCaseNumber } from '+shared/store/case';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserCountryCode,
  getUserProfileSalesforceContactId,
} from '+shared/store/user/user.selectors';

import { SupportFormResult } from '../../components/SupportFormResult';

const mapStateToProps = (state: StoreState) => ({
  queryStatus: getCaseSubmitQueryStatus(state),
  contactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
  userCountry: getUserCountryCode(state),
});

const mapDispatchToProps = mapActions({
  createCase: CaseActions.createCase,
  resetCreationProcess: CaseActions.clearCreatedCase,
});

interface State {
  formState: FormState;
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class HelpSupportComponent extends React.PureComponent<Props, State> {
  state = {
    formState: FormState.FORM,
  };

  componentDidUpdate() {
    const { queryStatus } = this.props;

    if (queryStatus.success) {
      this.setState({ formState: FormState.FORM_SUCCESS });
    }

    if (queryStatus.error) {
      this.setState({ formState: FormState.FORM_ERROR });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    const { formState } = this.state;

    if (formState !== FormState.FORM) {
      actions.resetCreationProcess();
      this.setState({ formState: FormState.FORM });
    }
  }

  onSendAnother = () => {
    const { actions } = this.props;
    const { formState } = this.state;

    if (formState !== FormState.FORM) {
      actions.resetCreationProcess();
      this.setState({ formState: FormState.FORM });
    }
  };

  render() {
    const { actions, queryStatus, contactId, caseNumber, userCountry } = this.props;
    const { formState } = this.state;

    return (
      <>
        <PageSubheadline smallGap={true}>{I18n.t(T.help.support)}</PageSubheadline>
        {
          {
            [FormState.FORM]: (
              <SupportForm
                submitForm={actions.createCase}
                isLoading={queryStatus.pending}
                userCountry={userCountry}
                contactId={contactId}
              />
            ),
            [FormState.FORM_ERROR]: <SupportFormResult onTryAgain={this.onSendAnother} />,
            [FormState.FORM_SUCCESS]: (
              <SupportFormResult
                isSuccess={true}
                onTryAgain={this.onSendAnother}
                caseNumber={caseNumber}
              />
            ),
          }[formState]
        }
      </>
    );
  }
}

export const HelpSupport = connect(mapStateToProps, mapDispatchToProps)(HelpSupportComponent);
