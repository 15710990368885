import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, InfoBanner, Loadable, Table } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { CustomerRouteParams } from '+app/router';
import { CaseActions } from '+shared/store/case';
import { Customer } from '+shared/store/customer';
import { LayoutActions } from '+shared/store/layout';
import { getStatus } from '+shared/store/query/query.utils';

import { CustomersTableHeader } from '../CustomersTableHeader';
import { CustomersTableUser } from '../CustomersTableUser';
import { getCustomersTableHeaderList } from './CustomersTable.helper';

import './CustomersTable.component.scss';

interface Props {
  collection: Customer[];
  isPending: boolean;
  contractQueryStatus: ReturnType<typeof getStatus>;
  toggleModal: typeof LayoutActions.toggleModal;
  setCaseReportActiveSite: typeof CaseActions.setCaseReportActiveSite;
  isSuperUser: boolean;
  onSiteClick: (params: CustomerRouteParams) => void;
}

export class CustomersTable extends React.PureComponent<Props> {
  private headerRef = React.createRef<HTMLDivElement>();

  handleTableScroll = (event: React.SyntheticEvent<HTMLDivElement>) => {
    this.headerRef.current!.scrollLeft = event.currentTarget.scrollLeft;
  };

  render() {
    const {
      collection,
      isPending,
      onSiteClick,
      contractQueryStatus,
      toggleModal,
      setCaseReportActiveSite,
      isSuperUser,
    } = this.props;
    const noResults = !collection.length;

    return (
      <>
        <CustomersTableHeader ref={this.headerRef} headerList={getCustomersTableHeaderList()} />
        <div
          className={classNames('c-customers-table', { 'c-customers-table--loading': isPending })}
          onScroll={this.handleTableScroll}
        >
          <Loadable predicate={isPending} transition={'fade'}>
            <Table className={'c-customers-table__inner'} hoverable={false} striped={true}>
              {collection.map((customer, i) => (
                <CustomersTableUser
                  key={`${i}-${customer.id}`}
                  onSiteClick={onSiteClick}
                  customer={customer}
                  toggleModal={toggleModal}
                  setCaseReportActiveSite={setCaseReportActiveSite}
                  contractQueryStatus={contractQueryStatus}
                  isSuperUser={isSuperUser}
                />
              ))}
            </Table>
          </Loadable>
        </div>
        {noResults && !isPending && (
          <div className={'c-customers-table__no-results'}>
            <InfoBanner
              icon={<Icon.Ufo />}
              title={I18n.t(T.dashboard.customersTable.noResultsHeadline)}
              isThemeCard={true}
              subtitle={
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      I18n.t(T.dashboard.customersTable.noResultsSubtitle, {
                        link: I18n.t(T.general.navigation.reportLink),
                      }) || '',
                  }}
                />
              }
            />
          </div>
        )}
      </>
    );
  }
}
