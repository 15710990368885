export enum Events {
  PAGE_LOADED = 'Page Loaded',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
}

export enum Metrics {
  ATTRACT = 'attract',
}

export enum Sections {
  LOGIN = 'Login',
  DASHBOARD = 'Dashboard',
  LEADS = 'Leads',
  SETUP = 'Setup',
  CUSTOMERS = 'Customers',
  CASES = 'Cases',
  HELP = 'Help',
  ACCOUNT = 'Account',
}
enum Dashboard {
  DASHBOARD = 'Dashboard',
  NEWS_INBOX = 'News inbox',
  NEWS_ARTICLE = 'News article',
}
export enum Leads {
  LEADS_INBOX = 'Leads inbox',
  LEAD_CREATION = 'Lead creation',
  LEADS_LIST = 'Leads list',
  DETAILS = 'Lead details', // no URL segment
  OFFER_OVERVIEW = 'Lead offer overview',
  SFX_SALES_CONFIGURATOR = 'Leads sFx sales configurator form',
  SFD_SALES_CONFIGURATOR = 'Leads sFd sales configurator form',
  IMPACT_ANALYSIS = 'Lead Impact Analysis configurator',
  OFFER_COMPOSER = 'Lead offer composer',
  SETUP_CONFIGURATOR = 'Lead setup configurator',
  LEAD_STATUS_MANAGER_MODAL = 'Lead status manager modal', // no URL segment
}
export enum Setup {
  SETUP_CUSTOMERS_LIST = 'Setup customers list',
  DETAILS = 'Setup customer details', // no URL segment
  OFFER_OVERVIEW = 'Setup customer offer overview',
  SETUP_CONFIGURATOR = 'Setup configurator',
  SFX_SALES_CONFIGURATOR = 'Setup sFx sales configurator form',
  SFD_SALES_CONFIGURATOR = 'Setup sFd sales configurator form',
  IMPACT_ANALYSIS = 'Setup Impact Analysis configurator',
  OFFER_COMPOSER = 'Setup offer composer',
  SETUP_DATA_FORM_MODAL = 'Setup data form modal',
  DSO_REGISTRATION_MODAL = 'DSO registration modal',
  DSO_COMMISSIONING_MODAL = 'DSO commissioning modal',
  PVR_MASTR_MODAL = 'PVR MaStR modal',
  VPP_DOCUMENTATION_MODAL = 'VPP documentation modal',
  SETUP_STATUS_MANAGER = 'Setup status manager', // no URL segment
}
export enum Customers {
  CUSTOMERS_LIST = 'Customers list',
  DETAILS = 'Customer details', // no URL segment
  BATTERY_DETAILS = 'Battery details',
  HEAT_PUMP = 'Heat pump',
  PV_SYSTEMS_AND_METERS = 'PV systems and meters',
  ENERGY_ANALYSIS = 'Energy analysis',
  CUSTOMER_CONTRACT = 'Customer contract',
  CUSTOMER_CASES = 'Customer cases',
  REPORT_CASE_FORM_MODAL = 'Customer report case form modal', // no URL segment
}
export enum Cases {
  CASES_LIST = 'Cases list', // same URL segment
  CASE_DETAILS_MODAL = 'Case details modal', // same URL segment
  REPORT_CASE_FORM_MODAL = 'Cases report case form modal', // same URL segment
}
enum Help {
  FAQ_PAGE = 'FAQ page', // no URL segment
  HELP_VIDEO_PAGE = 'Help video page',
}
enum Account {
  ACCOUNT_PERSONAL_DETAILS = 'Account personal details', // no URL segment
  ACCOUNT_COMPANY_DETAILS = 'Account company details',
}

export type PageNames = Dashboard | Leads | Setup | Customers | Cases | Help | Account;

export const PageName = {
  Dashboard,
  Leads,
  Setup,
  Customers,
  Cases,
  Help,
  Account,
};

export interface BasicAnalyticsPageData {
  userId: string;
  userRole: string;
  market: string;
  language: string;
  platform: string;
  cvp: string;
  siteKey: string;
}

export interface Page extends BasicAnalyticsPageData {
  pageName: string;
  siteSection: Sections;
  subSection1: string;
  subSection2: string;
}
export interface Login {
  userRole: string;
  userId: string;
  rememberMe?: string;
}
export interface Logout {
  userRole: string;
  userId: string;
}

export type AdobeDataLayer = {
  event: Events;
  metric: Metrics;
  page?: Page;
  login?: Login;
  logout?: Logout;
};
