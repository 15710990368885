import React from 'react';
import { PlacesType } from 'react-tooltip';

import classNames from 'classnames';
import { isNil } from 'lodash/fp';
import uuid from 'uuid';

import { TooltipCloseOnScroll } from '../TooltipCloseOnScroll/TooltipCloseOnScroll';

import './TruncableText.scss';

interface Props {
  displayedContent: string | JSX.Element;
  tooltipContent?: string | JSX.Element;
  displayedContentClassName: ClassValue;
  tooltipContentClassName?: ClassValue;
  tooltipPosition?: PlacesType;
  offset?: number;
}

export const TruncableText: React.FC<Props> = ({
  displayedContent,
  tooltipContent,
  displayedContentClassName,
  tooltipContentClassName,
  tooltipPosition = 'bottom',
  offset = 8,
}) => {
  const elementRef = React.useRef(null);
  const hasTextOverflowed = calculateTextOverflow(elementRef.current);
  const id = uuid();

  return (
    <div className="truncable-text">
      <div
        className={classNames('truncable-text__content', displayedContentClassName)}
        ref={elementRef}
        data-tooltip-id={id}
        data-tooltip-offset={offset}
      >
        {displayedContent}
      </div>

      {hasTextOverflowed && (
        <TooltipCloseOnScroll
          id={id}
          content={tooltipContent || displayedContent}
          place={tooltipPosition}
          className={tooltipContentClassName}
        />
      )}
    </div>
  );
};

export default TruncableText;

/*
 * Helpers
 */
const calculateTextOverflow = (element: HTMLElement | null): boolean =>
  isNil(element) ? false : element.scrollWidth > element.offsetWidth;
