import { countBy } from 'lodash';

import { createReducer } from '+app/utils';
import { trimFieldValues } from '+setupTool/+form/store/+form.helpers';
import { queryReducer } from '+shared/store/query';
import { DsoRegisterActions } from '+shared/store/setupTool';

import { DSO_REGISTRATION_FORM_ACTIONS, DsoRegistrationFormActions } from './+form.actions';
import {
  DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
  GET_BATTERIES_QUERY,
  GET_BATTERY_NAMES_QUERY,
  GET_DSO_LIST_QUERY,
  GET_INSTALLERS_QUERY,
  GET_PV_INVERTER_VENDORS_QUERY,
  GET_PV_INVERTERS_QUERY,
  GET_PV_MODULE_VENDORS_QUERY,
  GET_PV_MODULES_QUERY,
  REMOVE_INSTALLERS_QUERY,
  SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY,
  SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY,
  SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY,
  SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY,
  SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY,
  SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY,
  SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY,
  SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY,
  SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY,
  SUBMIT_INSTALLER_QUERY,
} from './+form.dictionary';
import { DsoRegistrationFormState, initialState } from './+form.state';
import { BatteryInterface } from './types';

export const reducer = createReducer<
  DsoRegistrationFormState,
  DsoRegistrationFormActions | DsoRegisterActions
>((state = initialState, action): DsoRegistrationFormState => {
  switch (action.type) {
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_CUSTOMER_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ...trimFieldValues(action.customerData),
        },
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_MEASURING_DEVICE:
      return {
        ...state,
        measuringDevice: {
          ...state.measuringDevice,
          ...trimFieldValues(action.measuringDevice),
        },
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_PV_SYSTEM:
      return {
        ...state,
        pvSystem: {
          ...state.pvSystem,
          ...trimFieldValues(action.pvSystem),
        },
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_SONNEN_BATTERY:
      return {
        ...state,
        sonnenBattery: trimFieldValues(action.sonnenBattery),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_INSTALLER_DATA:
      return {
        ...state,
        installerData: trimFieldValues(action.installerData),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_DOCUMENTS_FILE:
      return {
        ...state,
        documents: action.documents,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_DSO_LIST:
      return {
        ...state,
        dsoList: action.dsoList || [],
      };
    case DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_MODULE_VENDORS:
      return {
        ...state,
        pvModules: [...state.pvModules, ...action.collection],
      };
    case DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_MODULES:
      return {
        ...state,
        pvModules: state.pvModules.map((pv) =>
          pv.id === action.vendorId
            ? pv.modules && pv.modules.length > 0
              ? { ...pv, modules: [...pv.modules, ...action.modulesCollection] }
              : { ...pv, modules: [...action.modulesCollection] }
            : pv
        ),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_PV_MODULE_VENDORS_ALL_FETCHED:
      return {
        ...state,
        pvModuleVendorsAllFetched: action.pvModuleVendorsAllFetched,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_PV_MODULE:
      return {
        ...state,
        pvModules: state.pvModules.map((pv) =>
          pv.id === action.vendorId
            ? {
                ...pv,
                modules: pv.modules!.map((module) =>
                  module.id === action.moduleId ? { ...module, ...action.module } : module
                ),
              }
            : pv
        ),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_INVERTER_VENDORS:
      return {
        ...state,
        pvInverters: [...state.pvInverters, ...action.collection],
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_PV_INVERTER_VENDORS_ALL_FETCHED:
      return {
        ...state,
        pvInverterVendorsAllFetched: action.pvInverterVendorsAllFetched,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_INVERTERS:
      return {
        ...state,
        pvInverters: state.pvInverters.map((pv) =>
          pv.id === action.vendorId
            ? pv.inverters && pv.inverters.length > 0
              ? { ...pv, inverters: [...pv.inverters, ...action.invertersCollection] }
              : { ...pv, inverters: [...action.invertersCollection] }
            : pv
        ),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_PV_INVERTER:
      return {
        ...state,
        pvInverters: state.pvInverters.map((pv) =>
          pv.id === action.vendorId
            ? {
                ...pv,
                inv: pv.inverters!.map((inverter) =>
                  inverter.id === action.inverterId ? { ...inverter, ...action.inverter } : inverter
                ),
              }
            : pv
        ),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_BATTERIES:
      const batteriesCollection = action.batteriesCollection.filter(
        (battery: BatteryInterface) => battery.name === action.name
      );
      const nominalCapacitykWhCounts = countBy(
        batteriesCollection,
        (battery: BatteryInterface) => battery.nominalCapacitykWh
      );
      const batteries = batteriesCollection.map((battery: BatteryInterface) => ({
        ...battery,
        displayPower: nominalCapacitykWhCounts[battery.nominalCapacitykWh] > 1,
      }));

      return {
        ...state,
        batteryNames: state.batteryNames.map((batteryName) =>
          batteryName.name === action.name ? { ...batteryName, batteries } : batteryName
        ),
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_BATTERY_NAMES:
      return {
        ...state,
        batteryNames: action.collection || [],
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_BATTERY_NAMES_COUNT:
      return {
        ...state,
        batteryNamesNonUniqueCount: action.batteryNamesNonUniqueCount,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_INSTALLERS:
      return {
        ...state,
        installersList: action.installersList || [],
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_SUBMIT_INSTALLER_QUERY_STATUS:
      return {
        ...state,
        submitInstallerQuery: {
          ...state.submitInstallerQuery,
          status: action.status,
        },
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_REMOVE_INSTALLERS_QUERY_STATUS:
      return {
        ...state,
        removeInstallersQuery: {
          ...state.removeInstallersQuery,
          status: action.status,
        },
      };
    case DSO_REGISTRATION_FORM_ACTIONS.CLEAR_DATA:
      return {
        ...initialState,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.GET_IS_RADIO_SERIAL_NUMBER_FIELD_VISIBLE:
      return {
        ...state,
        isRadioSerialNumberFieldVisible: action.isRadioSerialNumberFieldVisible,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_FULFILLMENT: {
      return {
        ...state,
        fulfilled: action.isFilled,
      };
    }
    case DSO_REGISTRATION_FORM_ACTIONS.SET_SETUP_DATA_STATUSES: {
      return {
        ...state,
        setupDataStatuses: {
          ...state.setupDataStatuses,
          ...action.statuses,
        },
      };
    }
    case DSO_REGISTRATION_FORM_ACTIONS.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.activeStep,
      };
    }
    case DSO_REGISTRATION_FORM_ACTIONS.SET_UPLOADED_DOCUMENTS: {
      return {
        ...state,
        uploadedDocuments: action.documents,
      };
    }
    case DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_MODIFICATION_DATE: {
      return {
        ...state,
        updatedAt: action.date || state.updatedAt,
      };
    }
    default:
      return queryReducer(state, action, [
        DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
        GET_BATTERIES_QUERY,
        GET_BATTERY_NAMES_QUERY,
        GET_DSO_LIST_QUERY,
        GET_INSTALLERS_QUERY,
        GET_PV_INVERTER_VENDORS_QUERY,
        GET_PV_INVERTERS_QUERY,
        GET_PV_MODULE_VENDORS_QUERY,
        GET_PV_MODULES_QUERY,
        SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY,
        SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY,
        SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY,
        SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY,
        SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY,
        SEND_SIGNAL_MEASUREMENSTS_PICTURE_SUBMIT_QUERY,
        SEND_LAN_CONNECTION_PICTURE_SUBMIT_QUERY,
        SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY,
        SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY,
        SUBMIT_INSTALLER_QUERY,
        REMOVE_INSTALLERS_QUERY,
      ]);
  }
});
