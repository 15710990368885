import { Query } from '+shared/store/query';
import { UserCertificate } from '+shared/store/user/types/userCertificate.interface';

export const ACCOUNT_PASSWORD_QUERY = 'accountPasswordQuery';
export const COMPANY_LOGO_UPLOAD_QUERY = 'companyLogoUploadQuery';
export const PROFILE_LOGO_UPLOAD_QUERY = 'profileLogoUploadQuery';
export const COMPANY_DOCS_UPLOAD_QUERY = 'companyDocsUploadQuery';
export const GET_CERTIFICATES_QUERY = 'getCertificatesQuery';

export interface AccountPageState {
  certificates: UserCertificate[] | [];
  [ACCOUNT_PASSWORD_QUERY]: Query<any>;
  [COMPANY_LOGO_UPLOAD_QUERY]: Query<any>;
  [PROFILE_LOGO_UPLOAD_QUERY]: Query<any>;
  [COMPANY_DOCS_UPLOAD_QUERY]: Query<any>;
  [GET_CERTIFICATES_QUERY]: Query<any>;
}

export const initialState: AccountPageState = {
  certificates: [],
  [ACCOUNT_PASSWORD_QUERY]: {},
  [COMPANY_LOGO_UPLOAD_QUERY]: {},
  [PROFILE_LOGO_UPLOAD_QUERY]: {},
  [COMPANY_DOCS_UPLOAD_QUERY]: {},
  [GET_CERTIFICATES_QUERY]: {},
};
