import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, MediaQuery } from '@sonnen/shared-web';

import { PATHS } from '+app/router';
import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { checkRoleRestriction } from '+shared/components/RestrictedToRoles';
import { UserRole } from '+shared/store/user';

import './LeadListHeader.component.scss';

interface Props {
  buttonAction: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  inboxLeadsTotalCount: number;
  userRoles: UserRole[];
}

const navLinkProps = {
  border: false,
  type: 'desktop' as NavLinkType,
};

export const InboxLeadListHeader: React.FC<Props> = ({
  buttonAction,
  inboxLeadsTotalCount,
  userRoles,
}) => (
  <PageHeader
    tabNav={
      checkRoleRestriction(userRoles, [UserRole.COMPANY_COORDINATOR]) ? (
        <TabNav
          items={
            <>
              <NavLink {...navLinkProps} action={PATHS.LEADS_INBOX()} dataTestId="leads-inbox-tab">
                <Icon.Envelope className={'c-lead-list-header__nav-icon'} />
                {I18n.t(T.lead.list._salessolution_.leadsInbox)} ({inboxLeadsTotalCount})
              </NavLink>
              <NavLink {...navLinkProps} action={PATHS.LEADS()} dataTestId="leads-overview-tab">
                <Icon.ManWithDocument className={'c-lead-list-header__nav-icon'} />
                {I18n.t(T.lead.list._salessolution_.pageHeadline)}
              </NavLink>
            </>
          }
        />
      ) : null
    }
    isExpanded={true}
  >
    <div className={'c-lead-list-header__headline'}>
      <span>{I18n.t(T.lead.list._salessolution_.leadsHeadline)}</span>

      <Media query={MediaQuery.UP_XS}>
        {(isMedium: boolean) =>
          isMedium ? (
            <Button
              label={
                <>
                  <Icon.Plus className={'c-lead-list-header__button-icon'} />
                  {I18n.t(T.lead.list._salessolution_.newLeadButton)}
                </>
              }
              size={ButtonSize.MEDIUM}
              onClick={buttonAction}
              type={ButtonType.PRIMARY}
              dataTestId="leads-new-lead-btn"
            />
          ) : (
            <button className={'c-lead-list-header__button--mobile'} onClick={buttonAction}>
              <Icon.Plus className={'c-lead-list-header__icon'} />
            </button>
          )
        }
      </Media>
    </div>
  </PageHeader>
);
