import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, FormWizardHeader, Loader, Wizard, WizardStep } from '@sonnen/shared-web';

import { push } from 'connected-react-router';

import { isFlatDirectConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { getLeadOfferData } from '+app/+lead/+offer/store/+offer.selectors';
import { isOfferBlocked } from '+app/+lead/+overview/containers/LeadOverviewConfigurations/LeadOverviewConfigurations.helper';
import { getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { LeadFlatConfigurationTile } from '+app/+lead/components';
import { useSetupLeadContext } from '+app/+lead/containers/SetupLead/SetupLead.context';
import { isConfigurationMultiplePv } from '+app/+lead/store/+lead.helper';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { NotFound } from '+app/+static';
import { PATHS } from '+app/router';
import { LeadOfferPageActions } from '+lead/+offer/store/+offer.actions';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container, FormHeader } from '+shared/components';
import { LayoutBackground, LayoutContext } from '+shared/containers/Layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { ImpactAnalysisActions } from '../../store/+impactAnalysis.actions';
import { getStepAccessMap } from '../../store/+impactAnalysis.selectors';
import { LeadImpactAnalysisCalculationResultsDirect } from '../LeadImpactAnalysisCalculationResults/LeadImpactAnalysisCalculationResultsDirect';
import { LeadImpactAnalysisCalculationResultsX } from '../LeadImpactAnalysisCalculationResults/LeadImpactAnalysisCalculationResultsX';
import { LeadImpactAnalysisConsumption } from '../LeadImpactAnalysisConsumption';
import { LeadImpactAnalysisCosts } from '../LeadImpactAnalysisCosts';
import { LeadImpactAnalysisPdfCreation } from '../LeadImpactAnalysisPdfCreation';

import './LeadImpactAnalysis.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  offer: getLeadOfferData(state),
  stepAccessMap: getStepAccessMap(state),
  productBatteryList: getProductBatteryList(state),
});

const mapDispatchToProps = mapActions({
  goToLead: (leadId: string, leadStage?: string) =>
    push(PATHS.LEAD_CONFIGURATION({ leadId }, leadStage)),
  clearData: ImpactAnalysisActions.clearData,
  createImpactAnalysis: ImpactAnalysisActions.createImpactAnalysis,
  clearConfigurationData: LeadOfferPageActions.clearData,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const useLayoutBackground = () => {
  const layout = React.useContext(LayoutContext);

  React.useEffect(() => {
    layout.setBackground(LayoutBackground.GRAY);

    return () => {
      layout.resetBackground();
    };
  }, []);
};

const ImpactAnalysisWizard = withRouter(Wizard);

const LeadImpactAnalysisComponent: React.FC<Props> = ({
  actions,
  lead,
  offer,
  stepAccessMap,
  productBatteryList,
}) => {
  const { useTrackPageLoadDependingOnRoute } = useAdobeAnalyticsTracking();
  useTrackPageLoadDependingOnRoute('IMPACT_ANALYSIS');

  useLayoutBackground();
  const configuration = offer?.configuration;
  const { leadStage } = useSetupLeadContext();
  const isFlatDirect = isFlatDirectConfiguration(configuration);

  React.useEffect(() => {
    return () => {
      actions.clearConfigurationData();
      actions.clearData();
    };
  }, []);

  const handleComplete = () => {
    actions.createImpactAnalysis(lead!.id, offer!.id);
    actions.goToLead(lead!.id, leadStage);
  };

  const renderFlatXSteps = () => {
    return (
      <div className={'c-lead-impact-analysis__step'}>
        <ImpactAnalysisWizard
          header={
            <div className={'c-lead-impact-analysis__wizard-header-wrapper'}>
              <FormWizardHeader className={'c-lead-impact-analysis__wizard-header'} />
            </div>
          }
          onComplete={handleComplete}
        >
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.consumption.tag)}
            canAccess={stepAccessMap.consumption}
          >
            <LeadImpactAnalysisConsumption />
          </WizardStep>
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.costs.tag)}
            canAccess={stepAccessMap.productSetup}
          >
            <LeadImpactAnalysisCosts />
          </WizardStep>
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.results.tag)}
            canAccess={stepAccessMap.calculationResults}
          >
            <LeadImpactAnalysisCalculationResultsX />
          </WizardStep>
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.pdfCreation.tag)}
            canAccess={stepAccessMap.pdfCreation}
          >
            <LeadImpactAnalysisPdfCreation />
          </WizardStep>
        </ImpactAnalysisWizard>
      </div>
    );
  };

  const renderFlatDirectSteps = () => {
    return (
      <div className={'c-lead-impact-analysis__step'}>
        <ImpactAnalysisWizard
          header={
            <div className={'c-lead-impact-analysis__wizard-header-wrapper'}>
              <FormWizardHeader className={'c-lead-impact-analysis__wizard-header'} />
            </div>
          }
          onComplete={handleComplete}
        >
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.consumption.tag)}
            canAccess={stepAccessMap.consumption}
          >
            <LeadImpactAnalysisConsumption />
          </WizardStep>
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.costs.tag)}
            canAccess={stepAccessMap.productSetup}
          >
            <LeadImpactAnalysisCosts />
          </WizardStep>
          <WizardStep
            name={I18n.t(T.lead.impactAnalysis._salessolution_.results.tag)}
            canAccess={stepAccessMap.calculationResults}
          >
            <LeadImpactAnalysisCalculationResultsDirect />
          </WizardStep>
        </ImpactAnalysisWizard>
      </div>
    );
  };

  return (
    <>
      <FormHeader
        onClick={() => lead && actions.goToLead(lead.id, leadStage)}
        breadcrumb={I18n.t(T.lead.offer._salessolution_.buttonBack)}
        title={
          lead
            ? `${lead!.firstName} ${lead!.lastName} ${I18n.t(
                T.lead.impactAnalysis._salessolution_.title
              )}`
            : ''
        }
        suppressTitleInHotjar={true}
      />
      <Media query={{ minWidth: breakpointUp('SM') }}>
        {(isDesktopMedia: boolean) => (
          <Container withHorizontalPadding={isDesktopMedia} className={'c-lead-impact-analysis'}>
            {configuration && offer && productBatteryList ? (
              isConfigurationMultiplePv(configuration) || isOfferBlocked(offer) ? (
                <NotFound isFullScreen={false} />
              ) : (
                <div className={'o-grid'}>
                  <div className={'o-grid__column o-grid__column--md-8'}>
                    {isFlatDirect ? renderFlatDirectSteps() : renderFlatXSteps()}
                  </div>
                  <div className={'o-grid__column o-grid__column--md-4'}>
                    <LeadFlatConfigurationTile
                      title={I18n.t(T.lead.offer._salessolution_.configurationName)}
                      configuration={configuration}
                      isTileActionDisabled={true}
                      isPending={false}
                      offer={offer}
                      productBatteryList={productBatteryList}
                    />
                  </div>
                </div>
              )
            ) : (
              <Loader />
            )}
          </Container>
        )}
      </Media>
    </>
  );
};

export const LeadImpactAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadImpactAnalysisComponent);
