import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { WarningText } from '@sonnen/shared-web';

import { FormikProps } from 'formik';
import { get } from 'lodash/fp';

import { LeadForm } from '+app/+lead/store/types';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { FormInput, FormInputIcon } from '+shared/components';
import { toKebabCase } from '+utils/toKebabCase.util';

import '../LeadNewAddress/LeadNewAddress.scss';

interface Props {
  form: FormikProps<LeadForm>;
  isDisabled: boolean;
  isLoading: boolean;
  addressType: 'deliveryAddress' | 'invoiceAddress';
}

export const LeadNewManualAddress: React.FC<Props> = ({
  form,
  isDisabled,
  isLoading,
  addressType,
}) => {
  const isFieldNotEmpty = (field: string) => get(field)(form.values[addressType]);
  const hasFieldError = (field: string) => get(field)(form.errors[addressType]);

  useEffect(() => {
    if (isDisabled) {
      form.setFieldTouched(`${addressType}.zipCode`, false, false);
      form.setFieldTouched(`${addressType}.street`, false, false);
    }
  }, [isDisabled]);

  return (
    <>
      <div className="lead-new-address">
        <div className="lead-new-address--pair">
          <LoadingOverlay
            isLoading={isLoading}
            className={'lead-new-address__loading-overlay-wrapper--short'}
          >
            <FormInput
              form={form}
              label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCode)}
              name={`${addressType}.zipCode`}
              icon={!isDisabled ? FormInputIcon.WARNING : undefined}
              warningMessage={I18n.t(
                T.lead.boc._salessolution_.form.personalDetails.zipCodeWarning
              )}
              dataTestId={`${toKebabCase(addressType)}-zip-code-field`}
              disabled={isDisabled}
            />
          </LoadingOverlay>

          <LoadingOverlay
            isLoading={isLoading}
            className={'lead-new-address__loading-overlay-wrapper'}
          >
            <FormInput
              form={form}
              label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.city)}
              name={`${addressType}.city`}
              icon={
                isFieldNotEmpty('city') && !hasFieldError('city')
                  ? FormInputIcon.CHECKMARK
                  : undefined
              }
              dataTestId={`${toKebabCase(addressType)}-city-field`}
              disabled={isDisabled}
            />
          </LoadingOverlay>
        </div>

        <div className="lead-new-address--pair">
          <LoadingOverlay
            isLoading={isLoading}
            className={'lead-new-address__loading-overlay-wrapper'}
          >
            <FormInput
              form={form}
              label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.street)}
              name={`${addressType}.street`}
              icon={
                isFieldNotEmpty('street') && !hasFieldError('street')
                  ? FormInputIcon.CHECKMARK
                  : undefined
              }
              disabled={isDisabled}
              dataTestId={`${toKebabCase(addressType)}-street-field`}
            />
          </LoadingOverlay>
          <LoadingOverlay
            isLoading={isLoading}
            className={'lead-new-address__loading-overlay-wrapper--short'}
          >
            <FormInput
              form={form}
              label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.houseNo)}
              name={`${addressType}.houseNumber`}
              icon={
                isDisabled
                  ? undefined
                  : isFieldNotEmpty('houseNumber') && !hasFieldError('houseNumber')
                  ? FormInputIcon.CHECKMARK
                  : FormInputIcon.WARNING
              }
              warningMessage={I18n.t(
                T.lead.boc._salessolution_.form.personalDetails.houseNoWarning
              )}
              dataTestId={`${toKebabCase(addressType)}-house-number-field`}
              disabled={isDisabled}
            />
          </LoadingOverlay>
        </div>
      </div>
      {!isDisabled && (
        <WarningText
          text={I18n.t(T.lead.boc._salessolution_.form.personalDetails.addressWarning)}
        />
      )}
    </>
  );
};

interface LoadingOverlayProps {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
  children,
  className,
}) => {
  return (
    <div className={className}>
      {isLoading && <Loader className="lead-new-address__loading-overlay" />}
      {children}
    </div>
  );
};
