import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Icon, Select, Status, StatusType, useCountryFeature } from '@sonnen/shared-web';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { getAssetsAvailability } from '+app/+customer/store/+customer.helper';
import { CustomerRouteParams, PATHS } from '+app/router';
import { CountryFeatureName } from '+config/countryFlags';
import { HeatPump } from '+shared/basicComponents/Icons';
import { NavLink, PageHeader, TabNav } from '+shared/components';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { CaseReportButton } from '+shared/components/CaseReportButton/CaseReportButton';
import { Customer, Site } from '+shared/store/customer';
import { getStatus } from '+shared/store/query/query.utils';
import { trim } from '+utils/string.util';

import './CustomerHeader.component.scss';

const { overview, batteryDetails, heatPump, pvSystemsAndMeters, analysis, contract, cases } =
  T.customerSingle.header.navigation;

interface Props {
  customer: Customer;
  title: string;
  routeParams: CustomerRouteParams;
  siteSelected: Site;
  sites: Site[];
  assetsAvailability: ReturnType<typeof getAssetsAvailability>;
  hasHeatpump: boolean;
  onGoBack: () => void;
  onSiteChange: (site: Site) => void;
  onCaseReportButtonClick: () => void;
  currentSite: Site;
  contractQueryStatus: ReturnType<typeof getStatus>;
}

export const CustomerHeader: React.FC<Props> = ({
  title,
  routeParams,
  sites,
  siteSelected,
  assetsAvailability,
  hasHeatpump,
  onGoBack,
  onSiteChange,
  contractQueryStatus,
  onCaseReportButtonClick,
}) => {
  const { customerPvSystems } = useFlags();
  const { isEnabled: isCustomerContractFeatureEnabled } = useCountryFeature(
    CountryFeatureName.CUSTOMER_CONTRACT
  );

  return (
    <PageHeader
      className="c-customer-header"
      breadcrumb={renderBreadcrumbWithCasesButton(
        onGoBack,
        contractQueryStatus,
        onCaseReportButtonClick
      )}
      tabNav={renderTabNav(
        routeParams,
        assetsAvailability,
        hasHeatpump,
        customerPvSystems,
        isCustomerContractFeatureEnabled
      )}
      isExpanded={true}
    >
      <h1 className="c-customer-header__title" data-hj-suppress={true}>
        {title}
      </h1>
      <div className="c-customer-header__address" data-hj-suppress={true}>
        {sites.length === 1 ? (
          <span className="c-customer-header__address-info">
            {formatAddress(siteSelected, 0, true)}
          </span>
        ) : (
          <>
            <Status
              type={StatusType.WARNING}
              hasDot={false}
              label={I18n.t(T.customerSingle.header.sitesAvailable, { total: sites.length })}
            />
            <Select
              items={sites}
              itemsSelected={siteSelected}
              itemFactory={formatAddress}
              onSelect={onSiteChange}
            />
          </>
        )}
      </div>
    </PageHeader>
  );
};

const renderBreadcrumbWithCasesButton = (
  onGoBack: Props['onGoBack'],
  contractQueryStatus: Props['contractQueryStatus'],
  onCaseReportButtonClick: Props['onCaseReportButtonClick']
) => (
  <>
    <Button
      onClick={onGoBack}
      size={ButtonSize.SMALL}
      type={ButtonType.TERTIARY}
      className="c-customer-header__breadcrumb"
      label={
        <>
          <Icon.Angle className="c-customer-header__breadcrumb--icon" />
          {I18n.t(T.customerSingle.header.breadcrumb)}
        </>
      }
    />
    <CaseReportButton
      className="c-customer-header__report-case-button"
      isLoading={contractQueryStatus.pending}
      onClick={onCaseReportButtonClick}
    >
      <Button
        size={ButtonSize.MEDIUM}
        label={
          <>
            <Icon.OpenFolder className="c-page-header__icon" />
            {I18n.t(T.report.addCase)}
          </>
        }
      />
    </CaseReportButton>
  </>
);

const renderTabNav = (
  routeParams: CustomerRouteParams,
  assetsAvailability: ReturnType<typeof getAssetsAvailability>,
  hasHeatpump: boolean,
  customerPvSystems: boolean,
  isCustomerContractFeatureEnabled: boolean
) => {
  const { hasBattery, hasEatonBattery, hasContract, hasCases } = assetsAvailability;

  return (
    <TabNav
      items={
        <>
          <NavLink type="desktop" action={PATHS.CUSTOMER(routeParams)}>
            <Icofont type="dashboard" className="c-customer-header__nav-icon" />
            <p className="c-customer-header__nav-text">{I18n.t(overview)}</p>
          </NavLink>

          {hasBattery && (
            <NavLink type="desktop" action={PATHS.CUSTOMER_BATTERY(routeParams)}>
              <Icofont type="sonnenbatterie" className="c-customer-header__nav-icon" />
              <p className="c-customer-header__nav-text">{I18n.t(batteryDetails)}</p>
            </NavLink>
          )}

          {hasHeatpump && (
            <NavLink type="desktop" action={PATHS.CUSTOMER_HEAT_PUMP(routeParams)}>
              <HeatPump size={30} className="c-customer-header__nav-icon" />
              <p className="c-customer-header__nav-text">{I18n.t(heatPump)}</p>
            </NavLink>
          )}

          {hasBattery && customerPvSystems && !hasEatonBattery && (
            <NavLink type="desktop" action={PATHS.CUSTOMER_PV_SYSTEMS(routeParams)}>
              <Icofont type="pv" className="c-customer-header__nav-icon" />
              <p className="c-customer-header__nav-text">{I18n.t(pvSystemsAndMeters)}</p>
            </NavLink>
          )}

          {hasBattery && (
            <NavLink type="desktop" action={PATHS.CUSTOMER_ANALYSIS(routeParams)}>
              <Icofont type="diagram" className="c-customer-header__nav-icon" />
              <p className="c-customer-header__nav-text">{I18n.t(analysis)}</p>
            </NavLink>
          )}

          {hasContract && isCustomerContractFeatureEnabled && (
            <NavLink type="desktop" action={PATHS.CUSTOMER_CONTRACT(routeParams)}>
              <Icofont type="contract" className="c-customer-header__nav-icon" />
              <p className="c-customer-header__nav-text">{I18n.t(contract)}</p>
            </NavLink>
          )}

          {hasCases && (
            <NavLink type="desktop" action={PATHS.CUSTOMER_CASES(routeParams)}>
              <Icofont type="warning" className="c-customer-header__nav-icon" />
              <p className="c-customer-header__nav-text">{I18n.t(cases)}</p>
            </NavLink>
          )}
        </>
      }
    />
  );
};

const formatAddress = (site: Site, _: number, selected: boolean): string =>
  selected ? trim`${site.city} ${site.street}` : trim`${site.street} ${site.city}`;
