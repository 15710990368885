export enum TariffType {
  PEAK = 'peak',
  OFF_PEAK = 'off-peak',
}

export interface TariffWindow extends TariffRes {
  id: string;
  type: TariffType;
  thresholdPMaxStatus?: boolean;
}

export interface TariffRes {
  start: string;
  stop: string;
  thresholdPMax?: number | undefined;
}

export type TariffWindowInternal = {
  window: TariffWindow;
  elements: TariffWindow[];
};
