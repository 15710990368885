import { CurrencyUnit, OtherUnit } from '+app/App.constants';

import {
  ConsumptionForm,
  CostsForm,
  CostsFormVersion,
  PdfCreationForm,
} from './+impactAnalysis.interface';

export const mapImpactAnalysisConsumptionFormToDto = (consumptionForm: ConsumptionForm) => ({
  baselineTariffPerKwh: {
    gross: consumptionForm.baselineTariffPerKwh,
    currency: CurrencyUnit.CURRENCY_EUR,
  },
  baselineFeePerMonth: {
    gross: consumptionForm.baselineFeePerMonth,
    currency: CurrencyUnit.CURRENCY_EUR,
  },
  averageElectricityPriceIncreasePerYear: {
    value: consumptionForm.averageElectricityPriceIncreasePerYear,
    unit: OtherUnit.PERCENT,
  },
});

export const mapImpactAnalysisCostsFormToDto = (costsForm: CostsForm) => ({
  version: costsForm.version,
  basic:
    costsForm.version === CostsFormVersion.BASIC
      ? {
          totalRunningCostsPerYear: {
            gross: costsForm.basic.totalRunningCostsPerYear,
            currency: CurrencyUnit.CURRENCY_EUR,
          },
          totalInvestmentCosts: {
            gross: costsForm.basic.totalInvestmentCosts,
            currency: CurrencyUnit.CURRENCY_EUR,
          },
        }
      : undefined,
  detailed:
    costsForm.version === CostsFormVersion.DETAILED
      ? {
          investmentCosts: {
            pvSystemPurchase: {
              gross: costsForm.detailed.pvSystemPurchase,
              currency: CurrencyUnit.CURRENCY_EUR,
            },
            pvSystemInstallation:
              costsForm.detailed.pvSystemInstallation !== ''
                ? {
                    gross: costsForm.detailed.pvSystemInstallation,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
            inverterAndOtherComponentsPurchase:
              costsForm.detailed.inverterAndOtherComponentsPurchase !== ''
                ? {
                    gross: costsForm.detailed.inverterAndOtherComponentsPurchase,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
            batteryPurchase: {
              gross: costsForm.detailed.batteryPurchase,
              currency: CurrencyUnit.CURRENCY_EUR,
            },
            batteryInstallation:
              costsForm.detailed.batteryInstallation !== ''
                ? {
                    gross: costsForm.detailed.batteryInstallation,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
            meterReplacement:
              costsForm.detailed.meterReplacement !== ''
                ? {
                    gross: costsForm.detailed.meterReplacement,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
            otherInstallation:
              costsForm.detailed.otherInstallation !== ''
                ? {
                    gross: costsForm.detailed.otherInstallation,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
          },
          runningCosts: {
            insurancePerYear:
              costsForm.detailed.insurancePerYear !== ''
                ? {
                    gross: costsForm.detailed.insurancePerYear,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
            hardwareMaintenancePerYear:
              costsForm.detailed.hardwareMaintenancePerYear !== ''
                ? {
                    gross: costsForm.detailed.hardwareMaintenancePerYear,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
            additionalRunningCostsPerYear:
              costsForm.detailed.additionalRunningCostsPerYear !== ''
                ? {
                    gross: costsForm.detailed.additionalRunningCostsPerYear,
                    currency: CurrencyUnit.CURRENCY_EUR,
                  }
                : undefined,
          },
        }
      : undefined,
});

export const mapPdfCreationFormToDto = (formFields: PdfCreationForm) => ({
  products: {
    benefits: formFields.pages.productsAndBenefitsPage,
    explanation: formFields.pages.productsExplanationPage,
    interaction: formFields.pages.productsInteractionPage,
  },
  sonnenFlat: {
    info: formFields.pages.sonnenFlatInfoPage,
    contract: formFields.pages.sonnenFlatContractPage,
    energyFutureStandard: formFields.pages.energyFutureStandardPage,
    energyFutureSonnenFlat: formFields.pages.energyFutureSonnenFlatPage,
  },
  financialAnalysis: {
    providerVsSonnen: formFields.pages.financialAnalysisCurrentProviderVsSonnenPage,
    detailed: formFields.pages.financialAnalysisDetailedCalculation20YearsPage,
    amortization: formFields.pages.financialAnalysisAmortizationChartPage,
  },
  environmentalContribution: formFields.pages.environmentalContributionPage,
  yourSpecialist: true,
});
