import React from 'react';

import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';
import { isString } from 'lodash/fp';

import './Widget.scss';

interface Props {
  icon?: keyof typeof Icon | JSX.Element;
  heading?: string | React.ReactNode;
  action?: () => void;
  onImageClick?: () => void;
  customAction?: React.ReactNode;
  image?: string;
  className?: ClassValue;
  notification?: React.ReactNode;
  dataTestId?: string;
  link?: () => void;
}

export const Widget: React.FC<Props> = ({
  icon,
  heading,
  action,
  onImageClick,
  customAction,
  image,
  className,
  children,
  notification,
  dataTestId,
  link,
}) => {
  const iconComponent = isString(icon) ? Icon[icon]({}) : icon;
  const widgetClasses = classNames('c-widget', { 'c-widget--clickable': !!action }, className);

  return (
    <div className={widgetClasses} data-testid={dataTestId}>
      <div className={`c-widget__header ${link ? 'clickable' : ''}`} onClick={link}>
        <div className="c-widget__header-inner">
          <div className="c-widget__icon">{iconComponent}</div>
          <div className="c-widget__heading">{heading}</div>
        </div>

        <div>{customAction && customAction}</div>
      </div>

      <div className="c-widget__body-wrapper">
        {image && (
          <img
            src={image}
            alt="Card"
            className={classNames('c-widget__img', { 'c-widget--clickable': !!onImageClick })}
            onClick={onImageClick}
          />
        )}
        <div className="c-widget__body">{children}</div>

        {notification && <div className="c-widget__notification">{notification}</div>}
      </div>
    </div>
  );
};
