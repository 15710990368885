import packageJson from '../../package.json';
import { Env } from './environment';

export interface AppConfiguration {
  VERSION: string;
  AUTHORIZED_DOMAINS: string[];
  SENTRY_DSN: string;
  DEVELOPMENT_MODE: boolean;
}

// Assume that localhost means development mode
const isInDevelopmentMode = () => ['localhost', '0.0.0.0'].indexOf(window.location.hostname) >= 0;

export const getAppConfig = (env: Env): AppConfiguration => {
  switch (env) {
    case Env.LOCAL:
    case Env.DEVELOPMENT:
    case Env.STAGING:
    case Env.PRODUCTION:
    default:
      return {
        VERSION: packageJson.version,
        AUTHORIZED_DOMAINS: [
          'sonnen.de', // DACH
          // NOTE: currently below rollout domains will have no impact as all
          // API's will be served from 'sonnen.de',
          // adding it only for possible future purposes
          'sonnen.it', // IT
          'sonnenusa.com', // US
          'sonnen.com.au', // AU
          'sonnengroup.com', // GME
          'localhost',
          'us-central1-sonnenportal-dev.cloudfunctions.net',
          'us-central1-sonnenportal-production.cloudfunctions.net',
        ],
        SENTRY_DSN: 'https://f07ee49af67e8fb13d3a35072da6a896@sentry.sonnenbatterie.de/82',
        DEVELOPMENT_MODE: isInDevelopmentMode(),
      };
  }
};
