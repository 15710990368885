import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { TariffType } from '+customer-battery/store/battery-tariff';

export const batteryTariffWindowSchema = () =>
  Yup.object({
    thresholdPMax: Yup.number()
      .integer(I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInteger))
      .min(0, I18n.t(T.validation.greaterThan0))
      .max(
        30000,
        I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.thresholdMaxValue, {
          value: 30000,
        })
      )
      .when('type', {
        is: (value) => value === TariffType.OFF_PEAK,
        then: Yup.number().required(I18n.t(T.register.alert.requiredField)),
      }),
    type: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    thresholdPMaxStatus: Yup.boolean(),
    startHour: Yup.number()
      .min(0, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .max(23, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .required(I18n.t(T.register.alert.requiredField)),
    startMinutes: Yup.number()
      .min(0, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .max(59, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .required(I18n.t(T.register.alert.requiredField)),
    stopHour: Yup.number()
      .min(0, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .max(23, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .required(I18n.t(T.register.alert.requiredField)),
    stopMinutes: Yup.number()
      .min(0, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .max(59, I18n.t(T.customerSingle.batteryDetails.timeOfUse.form.validation.hasToBeInRange))
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const convertData = (data: any) => {
  if (data.thresholdPMaxStatus === false) {
    return { ...data, thresholdPMax: 0 };
  } else {
    return data;
  }
};

interface GetSeparatedTimeType {
  hour: string;
  minutes: string;
}

export const getSeparatedTime = (time: string): GetSeparatedTimeType => {
  const data = time.split(':');
  return {
    hour: data[0],
    minutes: data[1],
  };
};
