import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Status, StatusType } from '@sonnen/shared-web';

import { goTo } from '+app/utils';
import { Button, ButtonType } from '+shared/components/Button';

import './CertificateWarning.component.scss';

const renderIcon = () => <Icofont className={'c-certificate-warning__icon'} type={'warning'} />;

export const CertificateWarning: React.FC = () => (
  <div className={'c-certificate-warning'}>
    <Status
      icon={renderIcon()}
      hasDot={false}
      type={StatusType.DANGER}
      label={I18n.t(T.myAccount.certification.warning)}
    />
    <Button
      className={'c-certificate-warning__link-button'}
      type={ButtonType.TERTIARY}
      label={I18n.t(T.myAccount.certification.warningAction)}
      onClick={() => goTo(I18n.t(T.myAccount.certification.link), '_blank')}
    />
  </div>
);
