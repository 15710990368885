/* eslint-disable max-len */
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { LeadConfigurationTileStatus } from '+lead/components';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';

/**
 * So far hardware "accepted" status is not supported
 * which means that we should fallback it to "sent" status
 * @param status LeadConfigurationTileStatus
 */
export const mapConfigurationTileStatusForHardwareOffer = (
  status: LeadConfigurationTileStatus
): LeadConfigurationTileStatus =>
  status === LeadConfigurationTileStatus.ACCEPTED ? LeadConfigurationTileStatus.SENT : status;

type FlatTileStatus = JSX.Element | null | undefined;

export const renderStatus = (status: LeadConfigurationTileStatus): FlatTileStatus =>
  ({
    [LeadConfigurationTileStatus.SENT]: (
      <div
        className={
          'c-lead-configuration-tile__header-status-offer c-lead-configuration-tile__header-offer-sent'
        }
      >
        <StatusTile
          label={I18n.t(T.lead.configuration._salessolution_.preview.offerSent)}
          color={StatusTileColors.YELLOW}
          noMargin={true}
        />
      </div>
    ),
    [LeadConfigurationTileStatus.ACCEPTED]: (
      <div
        className={
          'c-lead-configuration-tile__header-status-offer c-lead-configuration-tile__header-offer-accepted'
        }
      >
        <StatusTile
          label={I18n.t(T.lead.configuration._salessolution_.preview.accepted)}
          color={StatusTileColors.GREEN}
          noMargin={true}
        />
      </div>
    ),
    [LeadConfigurationTileStatus.CONFIRMED]: (
      <div
        className={
          'c-lead-configuration-tile__header-status-offer c-lead-configuration-tile__header-offer-confirmed'
        }
      >
        <StatusTile
          label={I18n.t(T.lead.configuration._salessolution_.preview.confirmed)}
          color={StatusTileColors.GREEN}
          noMargin={true}
        />
      </div>
    ),
    [LeadConfigurationTileStatus.INVALID]: (
      <div
        className={
          'c-lead-configuration-tile__header-status-offer c-lead-configuration-tile__header-offer-invalid'
        }
      >
        <StatusTile
          label={I18n.t(T.lead.configuration._salessolution_.preview.invalid)}
          color={StatusTileColors.RED}
          noMargin={true}
        />
      </div>
    ),
    [LeadConfigurationTileStatus.EXPIRED]: (
      <div
        className={
          'c-lead-configuration-tile__header-status-offer c-lead-configuration-tile__header-offer-expired'
        }
      >
        <StatusTile
          label={I18n.t(T.lead.configuration._salessolution_.preview.expired.label)}
          color={StatusTileColors.RED}
          noMargin={true}
        />
      </div>
    ),
    [LeadConfigurationTileStatus.PREVIEW]: null,
  }[status]);
