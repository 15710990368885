import React, { useEffect, useState } from 'react';
import { PlacesType, Tooltip } from 'react-tooltip';

import classNames from 'classnames';

import useScroll from '+shared/hooks/useScroll';

type TooltipProps = {
  id: string;
  content: JSX.Element | string;
  clickable?: boolean;
  place?: PlacesType;
  className?: ClassValue;
};

export const TooltipCloseOnScroll: React.FC<TooltipProps> = ({
  id,
  content,
  clickable,
  place = 'bottom',
  className,
}) => {
  const [showTooltipWhenNoScroll, setShowTooltipWhenNoScroll] = useState(true);

  useEffect(() => {
    return setShowTooltipWhenNoScroll(true);
  }, [showTooltipWhenNoScroll]);

  const handleScroll = (isScrolling: boolean) => setShowTooltipWhenNoScroll(!isScrolling);

  return (
    <>
      {showTooltipWhenNoScroll && (
        <Tooltip
          id={id}
          className={classNames('reusable-class__tooltip', className)}
          classNameArrow="reusable-class__tooltip--arrow"
          place={place}
          clickable={clickable}
        >
          <ContentWrapperWithScrollListener handleScroll={handleScroll}>
            {content}
          </ContentWrapperWithScrollListener>
        </Tooltip>
      )}
    </>
  );
};

const ContentWrapperWithScrollListener: React.FC<{
  handleScroll: (isScrolling: boolean) => void;
}> = ({ children, handleScroll }) => {
  const isScrolling = useScroll();
  useEffect(() => handleScroll(isScrolling), [isScrolling]);
  return <>{children}</>;
};
