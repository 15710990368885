import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { push } from 'connected-react-router';

import { PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container, FormHeader } from '+shared/components';

import { LeadNew } from '../LeadNew/LeadNew.component';

const mapStateToProps = () => ({
  // @TODO: Implement when neccessary
});

const mapDispatchToProps = mapActions({
  goToLeads: () => push(PATHS.LEADS()),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const { createTitle } = T.lead._salessolution_;
const { breadcrumb } = T.customerSingle.header;

export const LeadCreatorComponent: React.FC<Props> = ({ actions }) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.LEADS, PageName.Leads.LEAD_CREATION);

  return (
    <>
      <FormHeader
        onClick={actions.goToLeads}
        title={I18n.t(createTitle)}
        breadcrumb={I18n.t(breadcrumb)}
      />
      <Container>
        <LeadNew />
      </Container>
    </>
  );
};

export const LeadCreator = connect(mapStateToProps, mapDispatchToProps)(LeadCreatorComponent);
