import { queryReducer } from '+shared/store/query';

import { createReducer } from '../../../utils';
import { CUSTOMER_ACTIONS, CustomerActions } from './customer.actions';
import { CustomerState, GET_CUSTOMER_LIST_QUERY, initialState } from './customer.state';

export const reducer = createReducer<CustomerState, CustomerActions>(
  (state = initialState, action): CustomerState => {
    switch (action.type) {
      case CUSTOMER_ACTIONS.SET_CUSTOMER_LIST:
        return {
          ...state,
          list: action.list,
        };
      default:
        return queryReducer(state, action, [GET_CUSTOMER_LIST_QUERY]);
    }
  }
);
