import { HttpResponseError } from '@coolio/http';

export const isInvalidTokenError = (error: HttpResponseError | undefined) => {
  if (error && error.response) {
    return error.response
      .parsedBody()
      .then(
        (body) => body.error.code === 'invalid' && body.error.title.toLowerCase().includes('token')
      );
  }

  return false;
};
