import { DsoCommissioningInterface } from '+app/+setupTool/+dsoCommissioning/store/types';
import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';

export interface PVRegisterFormInterface extends DsoCommissioningInterface {
  toc1?: boolean;
  toc2?: boolean;
}

export interface ImpersonateManualUploadFormInterface {
  [FileCategoryName.PVR_CONFIRMATION]: string;
}

export interface SubmissionLogInterface {
  step: {
    nr: string;
    name: string;
  };
  timestamp: string;
  errors: [];
  captchas: [];
}

export interface PVRegisterSubmissionInterface {
  status: string;
  log: SubmissionLogInterface[];
}

export enum PvRegisterState {
  DISABLED = 'disabled',
  ALLOWED_TO_DRAFT = 'allowed_to_draft',
  ALLOWED_TO_PERFORM = 'allowed_to_perform',
}
