// @TODO: Move to shared-web
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { SideInfo } from '@sonnen/shared-web';

export const ContractEnergyInfo: React.FC = () => (
  <SideInfo>
    <h3>{I18n.t(T.customerSingle.contract.sonnenFlat.exceedingEnergyAlert.title)}</h3>
    <p>{I18n.t(T.customerSingle.contract.sonnenFlat.exceedingEnergyAlert.text)}</p>
    <a
      href={I18n.t(T.customerSingle.contract.sonnenFlat.exceedingEnergyAlert.link)}
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      {I18n.t(T.customerSingle.contract.sonnenFlat.exceedingEnergyAlert.linkLabel)}
    </a>
  </SideInfo>
);
