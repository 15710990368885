import { UserRole } from '../user';

export const leadTabAllowedRoles = [UserRole.SALES_AGENT, UserRole.COMPANY_COORDINATOR];

export const setupLeadTabAllowedRoles = [
  UserRole.SALES_AGENT,
  UserRole.COMPANY_COORDINATOR,
  UserRole.SETUP_COORDINATOR,
];

export const salutation = ['mr', 'ms'];
export const countries = ['CH', 'DE', 'AT'];
