import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { LeadStatusName, LeadStatusNameMapper, OfferProductStatus } from '+shared/store/lead/types';

import { hardwareProductStatusNameMapper } from '../../store/+hardware.helper';

import './LeadHardwareFormStatus.component.scss';

interface Props {
  newHardwareStatus?: OfferProductStatus;
  openModal: () => void;
  shouldDisplayButton?: boolean;
}

export const LeadHardwareFormStatus: React.FC<Props> = ({
  newHardwareStatus,
  shouldDisplayButton = true,
  openModal,
}) => {
  const getStatusName = () => {
    if (newHardwareStatus) {
      return hardwareProductStatusNameMapper(newHardwareStatus);
    }
    return LeadStatusNameMapper(LeadStatusName.OPEN);
  };

  const statusToDisplay = getStatusName();

  const openStatusModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    openModal();
  };

  return (
    <div className={'c-lead-hardware-form-status'}>
      <div className={'c-lead-hardware-form-status__headline'}>
        {I18n.t(T.lead.hardware._salessolution_.form.statusChange.headline.statusOfHw)}
      </div>
      {shouldDisplayButton ? (
        <div className={'c-lead-hardware-form-status__subheadline'}>
          {I18n.t(T.lead.hardware._salessolution_.form.statusChange.subheadline.statusUpToDate)}
        </div>
      ) : null}
      <div className={'c-lead-hardware-form-status__update-status-wrapper'}>
        <div className={'c-lead-hardware-form-status__tile-wrapper'}>
          {statusToDisplay ? (
            <StatusTile
              additionalClass={'lead-current-status__tile'}
              compact={false}
              label={statusToDisplay}
              color={StatusTileColors.GREEN}
              dataTestId="new-hardware-status"
            />
          ) : null}
        </div>
        {shouldDisplayButton ? (
          <Button
            label={I18n.t(T.lead.hardware._salessolution_.form.statusChange.button.updateStatus)}
            onClick={(event) => openStatusModal(event)}
            size={ButtonSize.SMALL}
            type={ButtonType.SECONDARY}
            className={'c-lead-hardware-form-status__button'}
            dataTestId="update-hardware-status-btn"
          />
        ) : null}
      </div>
    </div>
  );
};
