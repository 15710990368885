import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

export const mapToState =
  <State extends {}>(state$: StateObservable<State>) =>
  (o: Observable<any>) =>
    o.pipe(
      withLatestFrom(state$),
      map(([, state]) => state)
    );
