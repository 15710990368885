export enum BatteryModelName {
  SONNENBATTERIE_10_AC = 'Sonnenbatterie 10 AC',
  SONNENBATTERIE_10_DC = 'Sonnenbatterie 10 DC',
  SONNENBATTERIE_10_PERFORMANCE_AC = 'Sonnenbatterie 10 Performance AC',
  SONNENBATTERIE_10_PERFORMANCE_DC = 'Sonnenbatterie 10 Performance DC',
  SONNENBATTERIE_ECO_8 = 'eco 8',
  SONNENBATTERIE_ECO_7 = 'eco 7',
  SONNENBATTERIE_ECO_6 = 'eco 6',
  SONNENBATTERIE_10_PERFORMANCE_PLUS_AC = 'Sonnenbatterie 10 Performance+ AC',
}

export const DC_BATTERIES = [
  BatteryModelName.SONNENBATTERIE_10_DC,
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC,
];

export const PERFORMANCE_BATTERIES = [
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_AC,
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC,
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_PLUS_AC,
];

export enum BatteryModule {
  SONNEN = 'sonnen',
  SONY = 'sony',
}

export enum BatteryAccessoryInfo {
  PERFORMANCE = 'Performance',
  AC_ISLAND = 'acisland',
  BACKUP_BUFFER = 'backupbuffer',
  PROTECT_MODE_8000 = '8000',
  PROTECT_MODE_4000 = '4000',
}
