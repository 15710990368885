import { last } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import { dataGuard, makeQuery, mapToState, ofType, polling } from '+app/utils';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { DocumentStatus } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';

import { isOpeningDocument } from '../../+overview/store/+overview.selectors';
import { ImpactAnalysisActions } from './+impactAnalysis.actions';
import { getImpactAnalysis, getImpactAnalysisRecommendationDto } from './+impactAnalysis.selectors';
import {
  CREATE_IMPACT_ANALYSIS_QUERY,
  CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY,
  DELETE_IMPACT_ANALYSIS_QUERY,
  SEND_IMPACT_ANALYSIS_QUERY,
} from './+impactAnalysis.state';

type Action$ = ActionsObservable<ImpactAnalysisActions>;
type State$ = StateObservable<StoreState>;

const createImpactAnalysis$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ImpactAnalysisActions.createImpactAnalysis),
    mergeMap(({ leadId, offerId }) =>
      of(leadId).pipe(
        mapToState(state$),
        map(getImpactAnalysis),
        mergeMap((impactAnalysis) =>
          !impactAnalysis
            ? throwError(new Error('createImpactAnalysis$ :: cannot create impact analysis'))
            : of(impactAnalysis)
        ),
        mergeMap((impactAnalysis) =>
          makeQuery(CREATE_IMPACT_ANALYSIS_QUERY)({
            call: () => LeadRepository.postLeadImpactAnalysis(leadId, offerId, impactAnalysis),
            onSuccess: (res) =>
              of(ImpactAnalysisActions.downloadImpactAnalysisFile(leadId, res.element.id)),
          })
        )
      )
    )
  );

const getImpactAnalysisDocument$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    polling({
      startOn: ImpactAnalysisActions.downloadImpactAnalysisFile,
      stopOn: [
        LeadOverviewPageActions.openOfferDocument,
        LeadOverviewPageActions.documentOpened,
        LeadOverviewPageActions.documentFailed,
      ],
      interval: 1000,
    })(({ leadId, impactAnalysisId }) =>
      LeadRepository.getLeadImpactAnalysis(leadId, impactAnalysisId).pipe(
        map((res) => last(res.element.documents)),
        mergeMap((document) => {
          if (document !== undefined && document.status === DocumentStatus.FAILED) {
            return of(
              LeadOverviewPageActions.documentFailed(impactAnalysisId, 'impact-analysis', 'main')
            );
          }

          return of(document).pipe(
            filter(
              (document) => document !== undefined && document.status === DocumentStatus.CREATED
            ),
            switchMap((document) =>
              LeadRepository.getLeadImpactAnalysisDocumentFile(
                leadId,
                impactAnalysisId,
                document!.id
              )
            ),
            withLatestFrom(state$),
            filter(([_, state]) => isOpeningDocument(state)),
            map(([url]) => LeadOverviewPageActions.openOfferDocument(url))
          );
        })
      )
    )
  );

const deleteLeadImpactAnalysis$ = (action$: Action$) =>
  action$.pipe(
    ofType(ImpactAnalysisActions.deleteImpactAnalysis),
    mergeMap(({ leadId, impactAnalysisId }) =>
      makeQuery(DELETE_IMPACT_ANALYSIS_QUERY)({
        call: () => LeadRepository.deleteLeadImpactAnalysis(leadId, impactAnalysisId),
        onSuccess: () =>
          dataGuard(ImpactAnalysisActions.deleteImpactAnalysisSuccess)(impactAnalysisId),
      })
    )
  );

const sendLeadImpactAnalysis$ = (action$: Action$) =>
  action$.pipe(
    ofType(ImpactAnalysisActions.sendImpactAnalysis),
    mergeMap(({ leadId, offerId, impactAnalysisId }) =>
      makeQuery(SEND_IMPACT_ANALYSIS_QUERY)({
        call: () => LeadRepository.patchLeadImpactAnalysis(leadId, offerId, impactAnalysisId),
        onSuccess: (res) =>
          of(ImpactAnalysisActions.setImpactAnalysis(leadId, offerId, res.element)),
      })
    )
  );

const sendLeadImpactAnalysisRecommendation$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ImpactAnalysisActions.createImpactAnalysisRecommendation),
    mergeMap(({ leadId, offerId }) =>
      of({}).pipe(
        mapToState(state$),
        map(getImpactAnalysisRecommendationDto),
        mergeMap((impactAnalysis) =>
          !impactAnalysis
            ? throwError(
                new Error('createImpactAnalysis$ :: cannot create impact analysis recommendation')
              )
            : of(impactAnalysis)
        ),
        mergeMap((impactAnalysis) =>
          makeQuery(CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY)({
            call: () =>
              LeadRepository.postLeadImpactAnalysisRecommendation(leadId, offerId, impactAnalysis),
            onSuccess: (res) =>
              of(
                ImpactAnalysisActions.createImpactAnalysisRecommendationSuccess(leadId, res!.meta)
              ),
          })
        )
      )
    )
  );

export const epics = combineEpics(
  createImpactAnalysis$,
  getImpactAnalysisDocument$,
  deleteLeadImpactAnalysis$,
  sendLeadImpactAnalysis$,
  sendLeadImpactAnalysisRecommendation$
);
