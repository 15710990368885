import { isNil } from 'lodash';
import { Point } from './point';
var Line = (function () {
    function Line(startX, startY, endX, endY) {
        this.startX = startX || 0;
        this.startY = startY || 0;
        this.endX = endX || 0;
        this.endY = endY || 0;
    }
    Line.prototype.pointAt = function (position, result) {
        if (result === void 0) { result = new Point(); }
        if (position > 1 || position < 0) {
            throw new Error("Invalid position. Expected position=".concat(position, " to be in range <0,1>."));
        }
        result.x = this.startX + (this.endX - this.startX) * position;
        result.y = this.startY + (this.endY - this.startY) * position;
        return result;
    };
    Line.prototype.move = function (distance) {
        var currentLength = this.length();
        if (currentLength) {
            var xOffset = (this.endX - this.startX) / currentLength * distance;
            var yOffset = (this.endY - this.startY) / currentLength * distance;
            this.startX += xOffset;
            this.endX += xOffset;
            this.startY += yOffset;
            this.endY += yOffset;
        }
        else {
            this.startX += distance;
            this.endX += distance;
        }
        return this;
    };
    Line.prototype.extend = function (distance) {
        var currentLength = this.length();
        if (currentLength) {
            this.endX += (this.endX - this.startX) / currentLength * distance;
            this.endY += (this.endY - this.startY) / currentLength * distance;
        }
        else {
            this.endX += distance;
        }
        return this;
    };
    Line.prototype.add = function (xOrLineOrPoint, y) {
        var x;
        if (typeof xOrLineOrPoint === 'number') {
            x = xOrLineOrPoint;
            if (isNil(y)) {
                return this;
            }
        }
        else if (xOrLineOrPoint instanceof Point) {
            x = xOrLineOrPoint.x;
            y = xOrLineOrPoint.y;
        }
        else {
            x = xOrLineOrPoint.endX - xOrLineOrPoint.startX;
            y = xOrLineOrPoint.endY - xOrLineOrPoint.startY;
        }
        this.endX += x;
        this.endY += y;
        return this;
    };
    Line.prototype.offset = function (xOrLineOrPoint, y) {
        var x;
        if (typeof xOrLineOrPoint === 'number') {
            x = xOrLineOrPoint;
            if (isNil(y)) {
                return this;
            }
        }
        else if (xOrLineOrPoint instanceof Point) {
            x = xOrLineOrPoint.x;
            y = xOrLineOrPoint.y;
        }
        else {
            x = xOrLineOrPoint.endX - xOrLineOrPoint.startX;
            y = xOrLineOrPoint.endY - xOrLineOrPoint.startY;
        }
        this.startX += x;
        this.endX += x;
        this.startY += y;
        this.endY += y;
        return this;
    };
    Line.prototype.length = function () {
        var x = this.endX - this.startX;
        var y = this.endY - this.startY;
        return Math.sqrt(x * x + y * y);
    };
    Line.prototype.clone = function () {
        return new Line(this.startX, this.startY, this.endX, this.endY);
    };
    Line.prototype.cloneTo = function (line) {
        line.startX = this.startX;
        line.startY = this.startY;
        line.endX = this.endX;
        line.endY = this.endY;
        return line;
    };
    Line.prototype.toString = function () {
        return "(".concat(this.startX, ",").concat(this.startY, " -> ").concat(this.endX, ", ").concat(this.endY, ")");
    };
    return Line;
}());
export { Line };
