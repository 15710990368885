import { __extends } from "tslib";
import { normalizeRadius, Paint, removeUndefinedProps, RequiredViewChanges, View, } from '@kanva/core';
export var LegendAlignment;
(function (LegendAlignment) {
    LegendAlignment[LegendAlignment["ROW"] = 0] = "ROW";
    LegendAlignment[LegendAlignment["COLUMN"] = 1] = "COLUMN";
})(LegendAlignment || (LegendAlignment = {}));
export var LegendSeriesType;
(function (LegendSeriesType) {
    LegendSeriesType[LegendSeriesType["AREA"] = 0] = "AREA";
    LegendSeriesType[LegendSeriesType["LINE"] = 1] = "LINE";
    LegendSeriesType[LegendSeriesType["BAR"] = 2] = "BAR";
    LegendSeriesType[LegendSeriesType["PIE"] = 3] = "PIE";
})(LegendSeriesType || (LegendSeriesType = {}));
var defaultStyle = {
    labelPaint: new Paint(),
    labelPadding: 4,
    alignment: LegendAlignment.ROW,
};
var LegendView = (function (_super) {
    __extends(LegendView, _super);
    function LegendView(context) {
        var _this = _super.call(this, context, 'LegendView') || this;
        _this.style = defaultStyle;
        _this.dataSeries = [];
        return _this;
    }
    LegendView.prototype.getStyle = function () {
        return this.style;
    };
    LegendView.prototype.setStyle = function (style) {
        this.style = style || defaultStyle;
        this.require(RequiredViewChanges.DRAW);
    };
    LegendView.prototype.getDataSeries = function () {
        return this.dataSeries;
    };
    LegendView.prototype.setDataSeries = function (dataSeries) {
        this.dataSeries = dataSeries;
        this.require(RequiredViewChanges.LAYOUT);
    };
    LegendView.prototype.getInternalWrappedHeight = function () {
        switch (this.style.alignment) {
            case LegendAlignment.ROW:
                return this.font.fontSize;
            case LegendAlignment.COLUMN:
            default:
                return this.dataSeries.length * (this.font.fontSize + this.padding) - this.padding;
        }
    };
    LegendView.prototype.getInternalWrappedWidth = function (canvas) {
        var _this = this;
        var boxWithPadding = this.font.fontSize * 1.5 + this.padding;
        switch (this.style.alignment) {
            case LegendAlignment.ROW:
                return this.dataSeries.reduce(function (value, series) { return value + boxWithPadding + _this.getSeriesTextWidth(canvas, series); }, 0);
            case LegendAlignment.COLUMN:
            default:
                return boxWithPadding +
                    this.dataSeries.reduce(function (value, series) { return Math.max(value, _this.getSeriesTextWidth(canvas, series)); }, 0);
        }
    };
    LegendView.prototype.onDraw = function (canvas) {
        var _a = this, dataSeries = _a.dataSeries, _b = _a.style, _c = _b === void 0 ? defaultStyle : _b, _d = _c.labelPaint, labelPaint = _d === void 0 ? defaultStyle.labelPaint : _d;
        var ctx = canvas.context;
        var fontSize = this.font.fontSize;
        var padding = this.padding;
        switch (this.style.alignment) {
            case LegendAlignment.ROW:
                for (var i = 0, l = dataSeries.length; i < l; i++) {
                    var series = dataSeries[i];
                    var width = canvas.measureText(series.name, labelPaint).width;
                    this.drawDataSeriesSymbol(canvas, series);
                    ctx.translate(fontSize * 1.5, 0);
                    canvas.drawText(0, fontSize, series.name, labelPaint);
                    ctx.translate(padding + width, 0);
                }
                break;
            case LegendAlignment.COLUMN:
            default:
                for (var i = 0, l = dataSeries.length; i < l; i++) {
                    var series = dataSeries[i];
                    this.drawDataSeriesSymbol(canvas, series);
                    canvas.drawText(fontSize * 1.5, fontSize, series.name, labelPaint);
                    ctx.translate(0, fontSize + padding);
                }
                break;
        }
    };
    LegendView.prototype.onSnapshot = function () {
        return {
            style: removeUndefinedProps({
                labelPaint: this.style.labelPaint.snapshot(),
                labelPadding: this.style.labelPadding,
                alignment: LegendAlignment[this.style.alignment || defaultStyle.alignment],
            }),
        };
    };
    LegendView.prototype.drawDataSeriesSymbol = function (canvas, series) {
        var ctx = canvas.context;
        var lineWidth = series.paint.lineWidth || 0;
        var size = this.style.labelPaint.getLineHeight() - lineWidth;
        var radius = normalizeRadius(series.radius);
        ctx.translate(lineWidth / 2, lineWidth / 2);
        switch (series.type) {
            case LegendSeriesType.AREA:
                ctx.beginPath();
                ctx.moveTo(0, size);
                ctx.lineTo(0.33 * size, 0.3 * size);
                ctx.lineTo(0.66 * size, 0.6 * size);
                ctx.lineTo(size, 0.1 * size);
                ctx.lineTo(size, size);
                ctx.closePath();
                break;
            case LegendSeriesType.BAR:
                ctx.beginPath();
                canvas.roundRect(0, .4 * size, size / 3, .6 * size, radius);
                canvas.roundRect(size / 3, .2 * size, size / 3, .8 * size, radius);
                canvas.roundRect(2 * size / 3, .6 * size, size / 3, .4 * size, radius);
                break;
            case LegendSeriesType.LINE:
                ctx.beginPath();
                ctx.moveTo(0, size);
                ctx.lineTo(.33 * size, .3 * size);
                ctx.lineTo(.66 * size, .7 * size);
                ctx.lineTo(size, .1 * size);
                break;
            case LegendSeriesType.PIE:
                ctx.beginPath();
                var pi2 = Math.PI * 2;
                ctx.moveTo(0, size);
                ctx.arc(0, size, size, -.22 * pi2, -.09 * pi2);
                ctx.closePath();
                break;
            default:
                ctx.beginPath();
                canvas.roundRect(0, 0, size, size, radius);
                break;
        }
        canvas.drawPath(series.paint);
        ctx.translate(-lineWidth / 2, -lineWidth / 2);
    };
    LegendView.prototype.getSeriesTextWidth = function (canvas, series) {
        return canvas.measureText(series.name, this.style.labelPaint || defaultStyle.labelPaint).width;
    };
    Object.defineProperty(LegendView.prototype, "padding", {
        get: function () {
            return this.style.labelPadding || defaultStyle.labelPadding;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LegendView.prototype, "font", {
        get: function () {
            return this.style.labelPaint.font || defaultStyle.labelPaint.font;
        },
        enumerable: false,
        configurable: true
    });
    return LegendView;
}(View));
export { LegendView };
