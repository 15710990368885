import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import './FormInputInfo.component.scss';

interface Props {
  children: React.ReactNode;
  isBold?: boolean;
}

export const FormInputInfo: React.FC<Props> = ({ children, isBold = false }) => (
  <div className={'c-form-input-info'}>
    <Icon.Info className={'c-form-input-info__icon'} />
    <p
      className={classNames('c-form-input-info__text', {
        'c-form-input-info__text--bold': isBold,
      })}
    >
      {children}
    </p>
  </div>
);
