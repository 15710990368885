import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { without } from 'lodash';
import * as Yup from 'yup';

import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { dateUtil } from '+utils/date.util';

import { DsoRegistrationPvSystemInterface, DsoRegistrationRadioInvestmentType } from '../types';

export const DsoRegistrationPvSystemSchemaPvAndBattery = () =>
  Yup.object().shape({
    pv_type: Yup.string()
      .matches(/(new_pv_asset|pv_extension)/)
      .required(I18n.t(T.register.alert.requiredField)),
    preferred_installation_date: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), '1899-12-31') : true)
      )
      .test(
        'invalidDateNewerThan',
        I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
        (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
      )
      .trim()
      .required(I18n.t(T.register.alert.requiredField)),
    panel_location: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    panel_alignment: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    tilt_angle: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    pv_module_id: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    pv_module_type: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    pv_module_count: Yup.number()
      .integer(I18n.t(T.validation.wholeNumber))
      .positive(I18n.t(T.validation.greaterThan0))
      .max(9999999999, I18n.t(T.validation.lessThanOrEqual))
      .required(I18n.t(T.register.alert.requiredField)),
    dc_coupled_battery: Yup.boolean().when('pv_type', {
      is: (val) => val === DsoRegistrationRadioInvestmentType.NEW,
      then: Yup.boolean().required(I18n.t(T.register.alert.requiredField)),
    }),
    pv_inverter_id: Yup.string().when('dc_coupled_battery', {
      is: (val) => val === false,
      then: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    }),
    pv_inverter_type: Yup.string().when('dc_coupled_battery', {
      is: (val) => val === false,
      then: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    }),
    number_identical_inverters: Yup.number()
      .integer(I18n.t(T.validation.wholeNumber))
      .positive(I18n.t(T.validation.greaterThan0))
      .when('dc_coupled_battery', {
        is: (val) => val === false,
        then: Yup.number().required(I18n.t(T.register.alert.requiredField)),
      }),

    // NEW

    new_pv_size: Yup.number()
      .positive(I18n.t(T.validation.greaterThan0))
      .nullable(true)
      .default(null)
      .when('pv_type', {
        is: (val) => val === DsoRegistrationRadioInvestmentType.NEW,
        then: Yup.number()
          .nullable(true)
          .default(null)
          .required(I18n.t(T.register.alert.requiredField)),
      }),

    // EXTENSION

    date_of_application: Yup.string()
      .test('invalidDateFormat', I18n.t(T.setupTool.validation.invalidDateFormat), (value) =>
        value ? dateUtil.isValidDateFormat(value) : true
      )
      .test(
        'earliestDate',
        I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
        (value) => (value ? dateUtil.isAfter(dateUtil.of(value), dateUtil.of('1899-12-31')) : true)
      )
      .test(
        'invalidDateNewerThan',
        I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
        (value) => (value ? dateUtil.getDifference(dateUtil.now(), value, 'years') < 2 : true)
      )
      .when('pv_type', {
        is: (val) => val === DsoRegistrationRadioInvestmentType.EXTENSION,
        then: Yup.string().required(I18n.t(T.register.alert.requiredField)),
      }),
    existing_pv_size: Yup.number()
      .positive(I18n.t(T.validation.greaterThan0))
      .nullable(true)
      .default(null)
      .when('pv_type', {
        is: (val) => val === DsoRegistrationRadioInvestmentType.EXTENSION,
        then: Yup.number().required(I18n.t(T.register.alert.requiredField)),
      }),
    existing_pv_inverter_manufacturer: Yup.string().when('pv_type', {
      is: (val) => val === DsoRegistrationRadioInvestmentType.EXTENSION,
      then: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    }),
    existing_pv_inverter_type: Yup.string().when('pv_type', {
      is: (val) => val === DsoRegistrationRadioInvestmentType.EXTENSION,
      then: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    }),
    existing_pv_inverter_count: Yup.number()
      .positive(I18n.t(T.validation.greaterThan0))
      .when('pv_type', {
        is: (val) => val === DsoRegistrationRadioInvestmentType.EXTENSION,
        then: Yup.number().required(I18n.t(T.register.alert.requiredField)),
      }),
    total_pv_size: Yup.number()
      .positive(I18n.t(T.validation.greaterThan0))
      .nullable(true)
      .default(null)
      .when('pv_type', {
        is: (val) => val === DsoRegistrationRadioInvestmentType.EXTENSION,
        then: Yup.number().required(I18n.t(T.register.alert.requiredField)),
      }),
  });

export const DsoRegistrationPvSystemSchemaBatteryOnly = () =>
  Yup.object().shape({
    pv_type: Yup.string()
      .matches(/(new_pv_asset|pv_extension)/)
      .required(I18n.t(T.register.alert.requiredField)),
    pv_module_id: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    pv_module_type: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    pv_module_count: Yup.number()
      .integer(I18n.t(T.validation.wholeNumber))
      .positive(I18n.t(T.validation.greaterThan0))
      .max(9999999999, I18n.t(T.validation.lessThanOrEqual))
      .required(I18n.t(T.register.alert.requiredField)),
    pv_inverter_id: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    pv_inverter_type: Yup.string().required(I18n.t(T.register.alert.requiredField)),
    number_identical_inverters: Yup.number()
      .integer(I18n.t(T.validation.wholeNumber))
      .positive(I18n.t(T.validation.greaterThan0))
      .required(I18n.t(T.register.alert.requiredField)),

    // NEW

    new_pv_size: Yup.number()
      .positive(I18n.t(T.validation.greaterThan0))
      .nullable(true)
      .default(null)
      .when('pv_type', {
        is: (val) => val === DsoRegistrationRadioInvestmentType.NEW,
        then: Yup.number()
          .nullable(true)
          .default(null)
          .required(I18n.t(T.register.alert.requiredField)),
      }),
  });

export const getDsoRegistrationPvSystemSchema = (
  registrationSubjectType?: RegistrationSubjectType
) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationPvSystemSchemaPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationPvSystemSchemaBatteryOnly;
    default:
      return DsoRegistrationPvSystemSchemaPvAndBattery;
  }
};

export const DsoRegistrationPvSystemInitialPvAndBattery: DsoRegistrationPvSystemInterface = {
  pv_type: DsoRegistrationRadioInvestmentType.NEW,
  preferred_installation_date: '',
  panel_location: 'pv_building',
  panel_alignment: '',
  tilt_angle: '',
  pv_module_id: '',
  pv_module_type: '',
  pv_module_count: 1,
  dc_coupled_battery: false,
  pv_inverter_id: '',
  pv_inverter_type: '',
  number_identical_inverters: 1,
  // NEW
  new_pv_size: null,
  // Extension
  date_of_application: '',
  mastr_number: '',
  existing_pv_size: null,
  existing_pv_inverter_manufacturer: '',
  existing_pv_inverter_type: '',
  existing_pv_inverter_count: 1,
};

export const DsoRegistrationPvSystemInitialBatteryOnly: DsoRegistrationPvSystemInterface = {
  pv_type: DsoRegistrationRadioInvestmentType.NEW,
  pv_module_id: '',
  pv_module_type: '',
  pv_module_count: 1,
  pv_inverter_id: '',
  pv_inverter_type: '',
  number_identical_inverters: 1,
};

export const getDsoRegistrationPvSystemInitial = (
  registrationSubjectType?: RegistrationSubjectType
) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationPvSystemInitialPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationPvSystemInitialBatteryOnly;
    default:
      return DsoRegistrationPvSystemInitialPvAndBattery;
  }
};

export const saveablePvSystemFieldsPvAndBattery = [
  'pv_type',
  'preferred_installation_date',
  'panel_location',
  'panel_alignment',
  'tilt_angle',
  'pv_module_id',
  'pv_module_type',
  'pv_module_count',
  'dc_coupled_battery',
  'pv_inverter_id',
  'pv_inverter_type',
  'number_identical_inverters',
  // NEW
  'new_pv_size',
  // Extension
  'date_of_application',
  'existing_pv_size',
  'existing_pv_infeed',
  'mastr_number',
  'existing_pv_inverter_manufacturer',
  'existing_pv_inverter_type',
  'existing_pv_inverter_count',
  'total_pv_size',
];

export const saveablePvSystemFieldsBatteryOnly = [
  'pv_type',
  'pv_module_id',
  'pv_module_type',
  'pv_module_count',
  'pv_inverter_id',
  'pv_inverter_type',
  'number_identical_inverters',
  // NEW
  'new_pv_size',
];

export const getSaveablePvSystemFields = (
  registrationSubjectType?: RegistrationSubjectType,
  pvType?: DsoRegistrationRadioInvestmentType
) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return pvType === DsoRegistrationRadioInvestmentType.EXTENSION
        ? without(saveablePvSystemFieldsPvAndBattery, 'dc_coupled_battery')
        : saveablePvSystemFieldsPvAndBattery;
    case RegistrationSubjectType.BATTERY:
      return saveablePvSystemFieldsBatteryOnly;
    default:
      return saveablePvSystemFieldsPvAndBattery;
  }
};

export const FieldsRequiredOnlyForExtension = [
  'date_of_application',
  'existing_pv_size',
  'existing_pv_inverter_manufacturer',
  'existing_pv_inverter_type',
  'existing_pv_inverter_count',
  'total_pv_size',
];
