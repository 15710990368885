import * as React from 'react';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, useCountryFeature } from '@sonnen/shared-web';

import { CountryFeatureName } from '+config/countryFlags';
import { insertIf } from '+utils/array.util';

import { Header } from './CustomersTable.interface';

enum TableHeader {
  NAME = 'name',
  LOCATION = 'location',
  BATTERY = 'battery',
  CONTRACT = 'contract',
  NOTE = 'note',
  CASE = 'case',
}

export const getCustomersTableHeaderList = (): Header[] => {
  const isCustomerContractFeatureEnabled = useCountryFeature(
    CountryFeatureName.CUSTOMER_CONTRACT
  ).isEnabled;

  return [
    {
      icon: <Icon.Account className={'c-customers-table__icon'} />,
      label: T.dashboard.customersTable.headerList.name,
      name: TableHeader.NAME,
    },
    {
      icon: <Icon.Location className={'c-customers-table__icon'} />,
      label: T.dashboard.customersTable.headerList.location,
      name: TableHeader.LOCATION,
    },
    {
      icon: <Icon.SonnenBattery className={'c-customers-table__icon'} />,
      label: T.dashboard.customersTable.headerList.battery,
      name: TableHeader.BATTERY,
    },
    ...insertIf(isCustomerContractFeatureEnabled, {
      icon: <Icon.Contract className={'c-customers-table__icon'} />,
      label: T.dashboard.customersTable.headerList.contract,
      name: TableHeader.CONTRACT,
    }),
    {
      icon: <Icon.Pen className={'c-customers-table__icon'} />,
      label: T.dashboard.customersTable.headerList.note,
      name: TableHeader.NOTE,
    },
    {
      icon: <Icon.Warning className={'c-customers-table__icon'} />,
      label: T.dashboard.customersTable.headerList.case,
      name: TableHeader.CASE,
    },
  ];
};
