import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Dropdown } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { User as UserIcon } from '+shared/basicComponents/Icons';

import { User } from '../../store/user';
import {
  getUserEmail,
  getUserName,
  getUserNumber,
  getUserPicture,
} from '../../store/user/user.helpers';
import { NavLink } from '../NavLink';
import { NavLinkColor } from '../NavLink/NavLink.helpers';
import { NavUserTrigger } from './NavUserTrigger.component';

import './NavUser.component.scss';

interface Props {
  user: User | void;
  navItems: React.ReactNode;
}

const setDropdownContentClass = (isDesktop: boolean) =>
  classNames(
    'c-nav-user__dropdown-content',
    isDesktop ? 'c-nav-user__dropdown-content--desktop' : 'c-nav-user__dropdown-content--mobile'
  );

export const NavUser: React.SFC<Props> = ({ user = null, navItems }) =>
  user && (
    <Media query={'(min-width: 1200px)'}>
      {(isDesktop: boolean) => (
        <NavLink navLinkColor={NavLinkColor.WHITE} dataTestId="account-tab">
          <Dropdown
            className={'c-nav-user'}
            trigger={<NavUserTrigger user={user} isMobile={!isDesktop} />}
            isOpen={false}
          >
            <div className={setDropdownContentClass(isDesktop)}>
              {isDesktop && (
                <div className={'c-nav-user__user'}>
                  {getUserPicture(user) ? (
                    <img
                      className={'c-nav-user__user-image'}
                      src={getUserPicture(user)}
                      alt={'Logo'}
                      data-hj-suppress={true}
                    />
                  ) : (
                    <UserIcon size={78} color="white" className="user-icon" />
                  )}
                  <div className={'c-nav-user__user-details'}>
                    <p className={'c-nav-user__user-details-name'} data-hj-suppress={true}>
                      {getUserName(user)}
                    </p>
                    <p className={'c-nav-user__user-details-info'} data-hj-suppress={true}>
                      {getUserEmail(user)}
                    </p>
                    <p className={'c-nav-user__user-details-info'} data-hj-suppress={true}>
                      {`${I18n.t(T.general.navigation.userNumber)}: ${getUserNumber(user)}`}
                    </p>
                  </div>
                </div>
              )}
              <ul className={'c-nav-user__navigation'}>{navItems}</ul>
            </div>
          </Dropdown>
        </NavLink>
      )}
    </Media>
  );
