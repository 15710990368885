import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';

import { DetailListTable } from '+shared/components';
import { User } from '+shared/store/user';

import { getPersonalDetailsList } from './PersonalDetails.helper';

interface Props {
  user: User | void;
}

export const PersonalDetails: React.FC<Props> = ({ user = null }) =>
  user && (
    <>
      <PageSubheadline mediumGap={true}>
        {I18n.t(T.myAccount.personalDetails.title)}
      </PageSubheadline>
      <DetailListTable list={getPersonalDetailsList(user)} suppressRowValuesInHotjar={true} />
    </>
  );
