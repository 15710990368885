import React, { FC, useEffect, useState } from 'react';

import { Loader } from '@sonnen/shared-web';

import { getLocale } from '+app/i18n/i18n.helpers';
import { useUserIdentity } from '+shared/store/user';

import { getFeedbackButtonIndex } from './GetFeedbackWrapper.helper';

export const GetFeedbackWrapper: FC = ({ children }) => {
  const { userProfileCountryCode, userProfileRoles } = useUserIdentity();
  const languageCode = getLocale();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    window.usabilla_live = window.lightningjs.require(
      'usabilla_live',
      `//w.usabilla.com/${getFeedbackButtonIndex()}.js`
    );

    if (languageCode && userProfileCountryCode && userProfileRoles.length > 0) {
      window.usabilla_live('data', {
        custom: {
          language: languageCode,
          countryCode: userProfileCountryCode,
          roles: userProfileRoles.toString(),
        },
      });
    }
    setShouldRender(true);
  }, [languageCode, userProfileCountryCode, userProfileRoles]);

  return <>{shouldRender ? children : <Loader />}</>;
};
