import { CellDataSeriesKey, SharedChartColors } from '@sonnen/shared-web';

import {
  AreaChartViewStyle,
  AxisViewStyle,
  ChartGridViewStyle,
  DataDisplayType,
} from '@kanva/charts';
import { Paint, TextAlign, TextBaseline } from '@kanva/core';

import { CHART_FONT } from '+app/+customer/+analysis/+analysis.consts';

/**
 * Band charts styling
 */

export const bandChartStyles: Record<CellDataSeriesKey, AreaChartViewStyle> = {
  [CellDataSeriesKey.TEMPERATURE]: {
    type: DataDisplayType.BAND,
    paint: new Paint()
      .setFillStyle(SharedChartColors.CellTemperatureFill)
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.CellTemperatureStroke),
  },
  [CellDataSeriesKey.VOLTAGE]: {
    type: DataDisplayType.BAND,
    paint: new Paint()
      .setFillStyle(SharedChartColors.CellVoltageFill)
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.CellVoltageStroke),
  },
};

/**
 * Band chart grid styling
 */

export const bandChartGridStyle: ChartGridViewStyle = {
  paint: new Paint().setStrokeStyle(SharedChartColors.Axis).setLineWidth(1),
};

/**
 * Band chart axis styling
 */

const axisPaint = new Paint().setFont(CHART_FONT).setFillStyle(SharedChartColors.Axis);

export const bandChartXAxisStyle: AxisViewStyle = {
  labelPaint: axisPaint.clone().setTextAlign(TextAlign.CENTER).setTextBaseline(TextBaseline.TOP),
  labelPadding: 0,
};

export const bandChartYAxisLeftStyle: AxisViewStyle = {
  labelPaint: axisPaint.clone().setTextAlign(TextAlign.LEFT).setTextBaseline(TextBaseline.BOTTOM),
};

export const bandChartYAxisRightStyle: AxisViewStyle = {
  labelPaint: axisPaint.clone().setTextAlign(TextAlign.RIGHT).setTextBaseline(TextBaseline.BOTTOM),
};
