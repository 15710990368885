import { useEffect, useRef, useState } from 'react';

const useElementDimensions = () => {
  const unitElementRef = useRef<HTMLDivElement>(null);
  const [unitDimensions, setDimensions] = useState<DOMRect>();

  useEffect(() => {
    const element = unitElementRef && unitElementRef.current;

    if (element) {
      const dimensions = element.getBoundingClientRect();
      setDimensions(dimensions);
    }
  }, [unitElementRef]);

  return {
    unitElementRef,
    unitDimensions,
  };
};

export default useElementDimensions;
