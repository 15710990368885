import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import { DetailList, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Battery } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';

import { factorizeBatteryInstallationDetailList } from './BatteryInstallationCard.helper';

interface Props {
  battery: Battery;
  queryStatus: ReturnType<typeof getStatus>;
}

export const BatteryInstallationCard: React.FC<Props> = ({ battery, queryStatus }) => (
  <Widget
    icon={'SetupTools'}
    heading={I18n.t(T.customerSingle.batteryDetails.installation.headline)}
  >
    {queryStatus.pending && <Loader className={'c-battery-live-card__loader'} />}
    {queryStatus.success && battery && (
      <DetailList vertical={true} list={factorizeBatteryInstallationDetailList(battery)} />
    )}
    {queryStatus.error && (
      <WidgetWarning type={'warning'} icon={'warning'}>
        {I18n.t(T.customerSingle.overview.battery.error)}
      </WidgetWarning>
    )}
  </Widget>
);
