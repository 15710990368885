import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';

import { FormSubmitButton } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';

import './LeadImpactAnalysisWizardFooter.component.scss';

interface Props {
  isPending?: boolean;
}

export const LeadImpactAnalysisWizardFooter: React.FC<Props> = ({ isPending }) => {
  const wizard = React.useContext(WizardContext);
  const { isLastStep, progress } = wizard;

  return (
    <div className={'c-impact-analysis-wizard-footer'}>
      {progress > 1 && (
        <div className={'c-impact-analysis-wizard-footer__navigation-prev'}>
          <Button
            label={I18n.t(T.lead.impactAnalysis._salessolution_.back)}
            type={ButtonType.TERTIARY}
            onClick={wizard.prev}
            dataTestId="back-button"
          />
        </div>
      )}
      <FormSubmitButton
        theme={ButtonType.PRIMARY}
        label={
          isLastStep
            ? I18n.t(T.lead.impactAnalysis._salessolution_.result.createPDF)
            : I18n.t(T.lead.impactAnalysis._salessolution_.next)
        }
        isSubmitting={isPending}
        dataTestId={isLastStep ? 'create-pdf-button' : 'next-button'}
      />
    </div>
  );
};
