import { FileCategoryName, FileMimeType } from './+form.dictionary';
import { megaBytesToBytes, megaBytesToLabel, mimeTypeToExtension } from './+form.helpers';

type FileSetting = {
  minUploadCount: number;
  maxUploadCount: number;
  maxFileSize: number;
  maxFileSizeLabel: string;
  mimeType: FileMimeType[];
  extensions: string;
};

const DEFAULT_UPLOAD_SIZE_IN_MB = 20;

const defaultPdfSettings: FileSetting = {
  minUploadCount: 1,
  maxUploadCount: 1,
  maxFileSize: megaBytesToBytes(DEFAULT_UPLOAD_SIZE_IN_MB),
  maxFileSizeLabel: megaBytesToLabel(DEFAULT_UPLOAD_SIZE_IN_MB),
  mimeType: [FileMimeType.APPLICATION_PDF],
  extensions: mimeTypeToExtension([FileMimeType.APPLICATION_PDF]),
};

const defaultImagesSettings: FileSetting = {
  minUploadCount: 1,
  maxUploadCount: 3,
  maxFileSize: megaBytesToBytes(DEFAULT_UPLOAD_SIZE_IN_MB),
  maxFileSizeLabel: megaBytesToLabel(DEFAULT_UPLOAD_SIZE_IN_MB),
  mimeType: [
    FileMimeType.IMAGE_JPG,
    FileMimeType.IMAGE_JPEG,
    FileMimeType.IMAGE_PNG,
    FileMimeType.IMAGE_BMP,
  ],
  extensions: mimeTypeToExtension([
    FileMimeType.IMAGE_JPG,
    FileMimeType.IMAGE_JPEG,
    FileMimeType.IMAGE_PNG,
    FileMimeType.IMAGE_BMP,
  ]),
};

export const fileUploadSettings: Record<FileCategoryName, FileSetting> = {
  [FileCategoryName.POWER_OF_ATTORNEY]: defaultPdfSettings,
  [FileCategoryName.SITE_PLAN]: defaultPdfSettings,
  [FileCategoryName.WIRING_DIAGRAM]: defaultPdfSettings,
  [FileCategoryName.WIRING_DIAGRAM_VPP]: defaultPdfSettings,
  [FileCategoryName.METER_PICTURES]: defaultImagesSettings,
  [FileCategoryName.COMPLETION_NOTIFICATION]: defaultPdfSettings,
  [FileCategoryName.ADDITIONAL_DOCUMENTS]: defaultPdfSettings,
  [FileCategoryName.PVR_CONFIRMATION]: defaultPdfSettings,
  [FileCategoryName.STANDARD_SOLAR_CIRCUIT_DIAGRAM]: {} as any, // not used
  [FileCategoryName.STANDARD_SOLAR_CIRCUIT_DIAGRAM_VPP]: {} as any, // not used
  [FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE]: defaultImagesSettings,
  [FileCategoryName.LAN_CONNECTION_PICTURE]: defaultImagesSettings,
};
