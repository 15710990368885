import * as React from 'react';

import * as classNames from 'classnames';

import { Contact } from '+shared/store/contact/types';

import { mapRoles } from './InboxLeadListPartnerName.helper';

import './InboxLeadListPartnerName.component.scss';

interface Props {
  partner: Contact;
  showRoles?: boolean;
  compact?: boolean;
  whiteSpacesBreak?: boolean;
  partnerInitials?: boolean;
}

const createInitials = ({ firstName, lastName }: Contact) =>
  `${firstName ? firstName.substring(0, 1) : ''}${lastName ? lastName.substring(0, 1) : ''}`;

export const InboxLeadListPartnerName: React.FC<Props> = ({
  partner,
  showRoles = true,
  compact = false,
  whiteSpacesBreak = true,
  partnerInitials = true,
}) => (
  <div className={'c-inbox-lead-list-partner-name'}>
    {partnerInitials && (
      <div
        className={classNames('c-inbox-lead-list-partner-name__circle', {
          'c-inbox-lead-list-partner-name__circle--compact': compact,
        })}
        data-hj-suppress={true}
      >
        {createInitials(partner)}
      </div>
    )}
    <div
      className={classNames('c-inbox-lead-list-partner-name__name-wrapper', {
        'c-inbox-lead-list-partner-name__name-wrapper--centered': !showRoles,
      })}
    >
      <div
        className={classNames('c-inbox-lead-list-partner-name__name', {
          'c-inbox-lead-list-partner-name__nowrap': !whiteSpacesBreak,
        })}
        data-hj-suppress={true}
      >
        {compact ? `${partner.firstName.substring(0, 1)}.` : partner.firstName} {partner.lastName}
      </div>
      {showRoles && (
        <div className={'c-inbox-lead-list-partner-name__role'}>{mapRoles(partner)}</div>
      )}
    </div>
  </div>
);
