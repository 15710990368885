import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { DsoRegistrationRadioType } from '+setupTool/+form/store/types';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store/types';
import { AdditionalFeatures } from '+shared/store/setupTool/types';
import { insertIf } from '+utils/array.util';

import {
  isGsmMeasurementsFieldsVisible,
  mapAdditionalFeaturesToFlags,
} from './+vppDocumentation.helpers';

const vppDocumentationInitialValues: VppDocumentationInterface = {
  pv_commissioning_date: '',
  battery_commissioning_date: '',
  production_meter: DsoRegistrationRadioType.NO,
  number_production_meter: '',
  fastening_type_grid: '',
  fastening_type_consumption: '',
  meter_number: '',
  type_of_grid_reference_meter: '',
  pv_reduction: false,
  meter_cabinet_prepared: false,
  free_space_in_the_upper_part_of_the_meter_cabinet: false,
  virtual_pv_system: undefined,
  virtual_pv_system_subsystem_1_name: undefined,
  virtual_pv_system_subsystem_2_name: undefined,
  virtual_pv_system_subsystem_1_commissioning_date: undefined,
  virtual_pv_system_subsystem_2_commissioning_date: undefined,
  upload_speed_mbits: undefined,
  download_speed_mbits: undefined,
  ping_ms: undefined,
  signal_strength_dbm: undefined,
  lan_connection_available: false,
  [FileCategoryName.METER_PICTURES]: undefined,
  [FileCategoryName.WIRING_DIAGRAM_VPP]: undefined,
  [FileCategoryName.COMPLETION_NOTIFICATION]: undefined,
  [FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE]: undefined,
  [FileCategoryName.LAN_CONNECTION_PICTURE]: undefined,
};

export const getVppDocumentationInitialValues = (
  isDsoReferenceNumberPvFieldVisible?: boolean
): VppDocumentationInterface => {
  return isDsoReferenceNumberPvFieldVisible
    ? {
        ...vppDocumentationInitialValues,
        dso_reference_number_pv: undefined,
      }
    : vppDocumentationInitialValues;
};

export const vppDocumentationFileCategories = [
  FileCategoryName.METER_PICTURES,
  FileCategoryName.WIRING_DIAGRAM_VPP,
  FileCategoryName.COMPLETION_NOTIFICATION,
  FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE,
  FileCategoryName.LAN_CONNECTION_PICTURE,
];

export const getSaveableVppDocumentationFields = (
  additionalFeatures: AdditionalFeatures[],
  isMultiplePvSystemsEnabled: boolean
) => {
  const {
    isDsoReferenceNumberPvFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    isMeterCabinetPreparedFieldVisible,
    isProductionMeterFieldVisible,
    isGsmMeasurementsRequiredFieldsVisible,
    isGsmMeasurementsOptionalFieldsVisible,
  } = mapAdditionalFeaturesToFlags(additionalFeatures);

  return [
    'battery_commissioning_date',
    'fastening_type_grid',
    'meter_number',
    'type_of_grid_reference_meter',
    'pv_reduction',
    ...insertIf(isDsoReferenceNumberPvFieldVisible, 'dso_reference_number_pv'),
    ...insertIf(isFasteningTypeConsumptionFieldVisible, 'fastening_type_consumption'),
    ...insertIf(isMeterCabinetPreparedFieldVisible, 'meter_cabinet_prepared'),
    ...insertIf(isProductionMeterFieldVisible, 'production_meter', 'number_production_meter'),
    ...insertIf(
      isMultiplePvSystemsEnabled,
      'virtual_pv_system_subsystem_1_commissioning_date',
      'virtual_pv_system_subsystem_2_commissioning_date'
    ),
    ...insertIf(!isMultiplePvSystemsEnabled, 'pv_commissioning_date'),
    ...insertIf(
      isGsmMeasurementsFieldsVisible(
        isGsmMeasurementsRequiredFieldsVisible,
        isGsmMeasurementsOptionalFieldsVisible
      ),
      'free_space_in_the_upper_part_of_the_meter_cabinet',
      'upload_speed_mbits',
      'download_speed_mbits',
      'ping_ms',
      'signal_strength_dbm',
      'lan_connection_available'
    ),
  ];
};
