import { ActionsUnion, createAction } from '+app/utils';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { Document, Installers } from '+shared/store/setupTool/types';

import {
  GET_BATTERIES_QUERY,
  GET_BATTERY_NAMES_QUERY,
  GET_DSO_LIST_QUERY,
  GET_INSTALLERS_QUERY,
  GET_PV_INVERTER_VENDORS_QUERY,
  GET_PV_INVERTERS_QUERY,
  GET_PV_MODULE_VENDORS_QUERY,
  GET_PV_MODULES_QUERY,
  REMOVE_INSTALLERS_QUERY,
  SUBMIT_INSTALLER_QUERY,
} from './+form.dictionary';
import {
  DsoRegistrationCustomerDataInterface,
  DsoRegistrationDocumentsInterface,
  DsoRegistrationInstallerDataInterface,
  DsoRegistrationInstallerDataInterfaceForCreatingInstaller,
  DsoRegistrationMeasuringDeviceInterface,
  DsoRegistrationPvSystemInterface,
  DsoRegistrationSetupDataStatusesInterface,
  DsoRegistrationSonnenBatteryInterface,
} from './types';

export enum DSO_REGISTRATION_FORM_ACTIONS {
  SET_FORM_CUSTOMER_DATA = '[+Form] SET_FORM_CUSTOMER_DATA',
  SET_FORM_MEASURING_DEVICE = '[+Form] SET_FORM_MEASURING_DEVICE',
  SET_FORM_PV_SYSTEM = '[+Form] SET_FORM_PV_SYSTEM',
  SET_FORM_SONNEN_BATTERY = '[+Form] SET_FORM_SONNEN_BATTERY',
  SET_FORM_INSTALLER_DATA = '[+Form] SET_FORM_INSTALLER_DATA',
  SET_FORM_DOCUMENTS_FILE = '[+Form] SET_FORM_DOCUMENTS_FILE',
  SEND_DSO_DOCUMENTS_FILE = '[+Form] SEND_DSO_DOCUMENTS_FILE',
  REMOVE_DSO_DOCUMENTS_FILE = '[+Form] REMOVE_DSO_DOCUMENTS_FILE',

  SUBMIT_DSO_REGISTER_FORM_SUCCESS = '[+DsoForm] SUBMIT_DSO_REGISTER_FORM_SUCCESS',
  SUBMIT_DSO_REGISTER_FORM_ERROR = '[+DsoForm] SUBMIT_DSO_REGISTER_FORM_ERROR',

  GET_DSO_LIST = '[+Form] GET_DSO_LIST',
  SET_DSO_LIST = '[+Form] SET_DSO_LIST',

  GET_PV_MODULE_VENDORS = '[+Form] GET_PV_MODULE_VENDORS',
  GET_PV_MODULE_VENDORS_PER_PAGE = '[+Form] GET_PV_MODULE_VENDORS_PER_PAGE',
  APPEND_PV_MODULE_VENDORS = '[+Form] APPEND_PV_MODULE_VENDORS',
  GET_PV_MODULES = '[+Form] GET_PV_MODULES',
  GET_PV_MODULES_PER_PAGE = '[+Form] GET_PV_MODULES_PER_PAGE',
  SET_PV_MODULE_VENDORS_ALL_FETCHED = '[+Form] SET_PV_MODULE_VENDORS_ALL_FETCHED',
  APPEND_PV_MODULES = '[+Form] APPEND_PV_MODULES',
  GET_PV_MODULE = '[+Form] GET_PV_MODULE',
  SET_PV_MODULE = '[+Form] SET_PV_MODULE',

  GET_PV_INVERTER_VENDORS = '[+Form] GET_PV_INVERTER_VENDORS',
  GET_PV_INVERTER_VENDORS_PER_PAGE = '[+Form] GET_PV_INVERTER_VENDORS_PER_PAGE',
  SET_PV_INVERTER_VENDORS_ALL_FETCHED = '[+Form] SET_PV_INVERTER_VENDORS_ALL_FETCHED',
  APPEND_PV_INVERTER_VENDORS = '[+Form] APPEND_PV_INVERTER_VENDORS',
  GET_PV_INVERTERS = '[+Form] GET_PV_INVERTERS',
  GET_PV_INVERTERS_PER_PAGE = '[+Form] GET_PV_INVERTERS_PER_PAGE',
  APPEND_PV_INVERTERS = '[+Form] APPEND_PV_INVERTERS',
  GET_PV_INVERTER = '[+Form] GET_PV_INVERTER',
  SET_PV_INVERTER = '[+Form] SET_PV_INVERTER',

  GET_BATTERIES = '[+Form] GET_BATTERIES',
  SET_BATTERIES = '[+Form] SET_BATTERIES',
  GET_BATTERY_NAMES = '[+Form] GET_BATTERY_NAMES',
  SET_BATTERY_NAMES = '[+Form] SET_BATTERY_NAMES',
  SET_BATTERY_NAMES_COUNT = '[+Form] SET_BATTERY_NAMES_COUNT',

  GET_INSTALLERS = '[+Form] GET_INSTALLERS',
  SET_INSTALLERS = '[+Form] SET_INSTALLERS',
  SUBMIT_INSTALLER = '[+Form] SUBMIT_INSTALLER',
  SET_SUBMIT_INSTALLER_QUERY_STATUS = '[+Form] SET_SUBMIT_INSTALLER_QUERY_STATUS',
  REMOVE_INSTALLERS = '[+Form] REMOVE_INSTALLERS',
  SET_REMOVE_INSTALLERS_QUERY_STATUS = '[+Form] SET_REMOVE_INSTALLERS_QUERY_STATUS',

  CLEAR_DATA = '[+Form] CLEAR_DATA',

  GET_IS_RADIO_SERIAL_NUMBER_FIELD_VISIBLE = '[+Form] GET_IS_RADIO_SERIAL_NUMBER_FIELD_VISIBLE',

  CHECK_FORM_FULFILLMENT = '[+Form] CHECK_FORM_FULFILLMENT',
  SET_FORM_FULFILLMENT = '[+Form] SET_FORM_FULFILLMENT',

  SAVE_SUBMISSION = '[+Form] SAVE_SUBMISSION',

  SET_SETUP_DATA_STATUSES = '[+Form] SET_SETUP_DATA_STATUSES',
  SET_ACTIVE_STEP = '[+Form] SET_ACTIVE_STEP',
  SET_UPLOADED_DOCUMENTS = '[+Form] SET_UPLOADED_DOCUMENTS',
  SET_FORM_MODIFICATION_DATE = '[+Form] SET_FORM_MODIFICATION_DATE',

  TRACK_SETUP_DATA_COMPLETED = '[+DsoRegistration] TRACK_SETUP_DATA_COMPLETED',
}

export const DsoRegistrationFormActions = {
  setDsoCustomerData: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_CUSTOMER_DATA,
    (customerData: DsoRegistrationCustomerDataInterface) => ({ customerData })
  ),
  setDsoMeasuringDevice: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_MEASURING_DEVICE,
    (measuringDevice: DsoRegistrationMeasuringDeviceInterface) => ({ measuringDevice })
  ),
  setDsoPvSystem: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_PV_SYSTEM,
    (pvSystem: Partial<DsoRegistrationPvSystemInterface>) => ({ pvSystem })
  ),
  setDsoSonnenBattery: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_SONNEN_BATTERY,
    (sonnenBattery: DsoRegistrationSonnenBatteryInterface) => ({ sonnenBattery })
  ),
  setDsoInstallerData: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_INSTALLER_DATA,
    (installerData: DsoRegistrationInstallerDataInterface) => ({ installerData })
  ),
  setDsoDocumentsFile: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_DOCUMENTS_FILE,
    (documents: DsoRegistrationDocumentsInterface) => ({ documents })
  ),
  sendDsoDocumentsFile: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SEND_DSO_DOCUMENTS_FILE,
    (config: any /* @TODO API CHANGE */) => ({ config })
  ),
  removeDsoDocumentsFile: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.REMOVE_DSO_DOCUMENTS_FILE,
    (config: any /* @TODO API CHANGE */) => ({ config })
  ),
  submitDsoRegistrationFormSuccess: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SUBMIT_DSO_REGISTER_FORM_SUCCESS,
    (summary: any /* @TODO API CHANGE */) => ({ summary })
  ),
  submitDsoRegistrationFormError: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SUBMIT_DSO_REGISTER_FORM_ERROR,
    (error: any /* @TODO API CHANGE */) => ({ error })
  ),
  getDsoList: createAction(DSO_REGISTRATION_FORM_ACTIONS.GET_DSO_LIST, () => ({
    queryKey: GET_DSO_LIST_QUERY,
  })),
  setDsoList: createAction(DSO_REGISTRATION_FORM_ACTIONS.SET_DSO_LIST, (dsoList: any) => ({
    dsoList,
  })),
  clearData: createAction(DSO_REGISTRATION_FORM_ACTIONS.CLEAR_DATA),
  getPVModuleVendors: createAction(DSO_REGISTRATION_FORM_ACTIONS.GET_PV_MODULE_VENDORS, () => ({
    queryKey: GET_PV_MODULE_VENDORS_QUERY,
  })),
  getPVModuleVendorsPerPage: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_PV_MODULE_VENDORS_PER_PAGE,
    (pageNum: number) => ({ queryKey: GET_PV_MODULE_VENDORS_QUERY, pageNum })
  ),
  appendPVModuleVendors: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_MODULE_VENDORS,
    (collection: any) => ({ collection })
  ),
  getPVModules: createAction(DSO_REGISTRATION_FORM_ACTIONS.GET_PV_MODULES, (vendorId: string) => ({
    queryKey: GET_PV_MODULES_QUERY,
    vendorId,
  })),
  getPVModulesPerPage: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_PV_MODULES_PER_PAGE,
    (vendorId: string, pageNum: number) => ({ queryKey: GET_PV_MODULES_QUERY, vendorId, pageNum })
  ),
  setPvModuleVendorsAllFetched: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_PV_MODULE_VENDORS_ALL_FETCHED,
    (pvModuleVendorsAllFetched: boolean) => ({ pvModuleVendorsAllFetched })
  ),
  appendPVModules: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_MODULES,
    (vendorId: string, modulesCollection: any) => ({ vendorId, modulesCollection })
  ),
  setPVModule: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_PV_MODULE,
    (vendorId: string, moduleId: string, module: any) => ({ vendorId, moduleId, module })
  ),
  getPVInverterVendors: createAction(DSO_REGISTRATION_FORM_ACTIONS.GET_PV_INVERTER_VENDORS, () => ({
    queryKey: GET_PV_INVERTER_VENDORS_QUERY,
  })),
  getPVInverterVendorsPerPage: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_PV_INVERTER_VENDORS_PER_PAGE,
    (pageNum: number) => ({ queryKey: GET_PV_INVERTER_VENDORS_QUERY, pageNum })
  ),
  setPvInverterVendorsAllFetched: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_PV_INVERTER_VENDORS_ALL_FETCHED,
    (pvInverterVendorsAllFetched: boolean) => ({ pvInverterVendorsAllFetched })
  ),
  appendPVInverterVendors: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_INVERTER_VENDORS,
    (collection: any) => ({ collection })
  ),
  getPVInverters: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_PV_INVERTERS,
    (vendorId: string) => ({ queryKey: GET_PV_INVERTERS_QUERY, vendorId })
  ),
  getPVInvertersPerPage: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_PV_INVERTERS_PER_PAGE,
    (vendorId: string, pageNum: number) => ({ queryKey: GET_PV_INVERTERS_QUERY, vendorId, pageNum })
  ),
  appendPVInverters: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.APPEND_PV_INVERTERS,
    (vendorId: string, invertersCollection: any) => ({ vendorId, invertersCollection })
  ),
  setPVInverter: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_PV_INVERTER,
    (vendorId: string, inverterId: string, inverter: any) => ({ vendorId, inverterId, inverter })
  ),
  getBatteries: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_BATTERIES,
    (name: string, pageNum?: number) => ({ queryKey: GET_BATTERIES_QUERY, name, pageNum })
  ),
  setBatteries: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_BATTERIES,
    (name: string, batteriesCollection: any) => ({ batteriesCollection, name })
  ),
  getBatteryNames: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_BATTERY_NAMES,
    (pageNum?: number) => ({ queryKey: GET_BATTERY_NAMES_QUERY, pageNum })
  ),
  setBatteryNames: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_BATTERY_NAMES,
    (collection: any) => ({ collection })
  ),
  setBatteryNamesNonUniqueCount: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_BATTERY_NAMES_COUNT,
    (batteryNamesNonUniqueCount: number) => ({ batteryNamesNonUniqueCount })
  ),
  getInstallers: createAction(DSO_REGISTRATION_FORM_ACTIONS.GET_INSTALLERS, () => ({
    queryKey: GET_INSTALLERS_QUERY,
  })),
  setInstallers: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_INSTALLERS,
    (installersList: Installers[]) => ({ installersList })
  ),
  submitInstaller: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SUBMIT_INSTALLER,
    (values: DsoRegistrationInstallerDataInterfaceForCreatingInstaller) => ({
      queryKey: SUBMIT_INSTALLER_QUERY,
      values,
    })
  ),
  setSubmitInstallerQueryStatus: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_SUBMIT_INSTALLER_QUERY_STATUS,
    (status: any) => ({ status })
  ),
  removeInstallers: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.REMOVE_INSTALLERS,
    (installerIds: string[]) => ({ queryKey: REMOVE_INSTALLERS_QUERY, installerIds })
  ),
  setRemoveInstallersQueryStatus: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_REMOVE_INSTALLERS_QUERY_STATUS,
    (status: any) => ({ status })
  ),
  setIsRadioSerialNumberFieldVisible: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.GET_IS_RADIO_SERIAL_NUMBER_FIELD_VISIBLE,
    (isRadioSerialNumberFieldVisible: boolean) => ({ isRadioSerialNumberFieldVisible })
  ),
  checkFormFulfillment: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.CHECK_FORM_FULFILLMENT,
    (registrationSubject?: RegistrationSubjectType) => ({ registrationSubject })
  ),
  setFormFulfillment: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_FULFILLMENT,
    (isFilled: boolean) => ({ isFilled })
  ),
  setSetupDataStatuses: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_SETUP_DATA_STATUSES,
    (statuses: DsoRegistrationSetupDataStatusesInterface) => ({ statuses })
  ),
  setActiveStep: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_ACTIVE_STEP,
    (activeStep: string) => ({ activeStep })
  ),
  setFormUploadedDocuments: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_UPLOADED_DOCUMENTS,
    (documents: Document[]) => ({ documents })
  ),
  setFormModificationDate: createAction(
    DSO_REGISTRATION_FORM_ACTIONS.SET_FORM_MODIFICATION_DATE,
    (date: string) => ({ date })
  ),
  trackSetupDataCompleted: createAction(DSO_REGISTRATION_FORM_ACTIONS.TRACK_SETUP_DATA_COMPLETED),
};

export type DsoRegistrationFormActions = ActionsUnion<typeof DsoRegistrationFormActions>;
