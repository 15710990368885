import { createLayoutMap, MATCH_PARENT, PARENT_ID } from '@kanva/core';

import { getAnalysisChartDimensions } from '../../store/helpers/+analysis.helpers';
import { ChartVisibleElements } from '../../store/types/chartVisibleElements.interface';

export const Views = {
  AREA_CHART_WRAPPER: 'areaChartWrapper',
  AREA_CHART_X_AXIS: 'areaChartXAxis',
  AREA_CHART_Y_AXIS_LEFT: 'areaChartYAxisLeft',
  AREA_CHART_Y_AXIS_RIGHT: 'areaChartYAxisRight',

  LINE_CHART_WRAPPER: 'lineChartWrapper',
  CHARGE_LIMIT_LINE: 'chargeLimitLine',
  VPP_ACTIVITY_LINE: 'vppActivityLine',
  CELL_CARE_LINE: 'cellCareLine',

  BAND_CHART_WRAPPER: 'bandChartWrapper',
  BAND_CHART_X_AXIS: 'bandChartXAxis',
  BAND_CHART_Y_AXIS_LEFT: 'bandChartYAxisLeft',
  BAND_CHART_Y_AXIS_RIGHT: 'bandChartYAxisRight',
};

const {
  areaChartHeightDesktop,
  lineChartHeight,
  bandChartHeight,
  xAxisHeight,
  areaChartMargin,
  bandChartMargin,
} = getAnalysisChartDimensions();

export const Dimensions = {
  AREA_CHART_HEIGHT: areaChartHeightDesktop,
  LINE_CHART_HEIGHT: lineChartHeight,
  BAND_CHART_HEIGHT: bandChartHeight,
  X_AXIS_HEIGHT: xAxisHeight,

  AREA_CHART_MARGIN: areaChartMargin,
  BAND_CHART_MARGIN: bandChartMargin,
  DESKTOP_PADDING: 10,
  X_AXIS_MARGIN: 10,
  X_AXIS_PADDING: 4,
  Y_AXIS_PADDING: 4,
};

export const createChartLayout = (chartVisibleElements: ChartVisibleElements) =>
  createLayoutMap({
    zoom: {
      width: MATCH_PARENT,
      height: MATCH_PARENT,
      alignStart: PARENT_ID,
      alignEnd: PARENT_ID,
      alignTop: PARENT_ID,
      alignBottom: PARENT_ID,
      above: chartVisibleElements.length ? Views.BAND_CHART_X_AXIS : Views.AREA_CHART_X_AXIS,
      margin: {
        top: Dimensions.AREA_CHART_MARGIN,
      },
    },
  });
