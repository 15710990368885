/* eslint-disable no-case-declarations */
import { isEmpty } from 'lodash/fp';

import { CUSTOMER_ACTIONS, CustomerActions } from '+app/+customer/store';
import { CHARGER_ACTIONS, ChargerActions } from '+shared/store/charger';
import { queryReducer } from '+shared/store/query';
import { SITE_ACTIONS, SiteActions } from '+shared/store/site';

import { createPersistedReducer } from '../../../utils/redux/createPersistedReducer.util';
import { OVERVIEW_ACTIONS, OverviewActions } from './+overview.actions';
import {
  GET_CHARGERS_QUERY,
  GET_OVERVIEW_SITE_LIVE_STATE_QUERY,
  initialState,
  OverviewPageState,
} from './+overview.state';

type Actions = SiteActions | ChargerActions | OverviewActions | CustomerActions;

export const reducer = createPersistedReducer<OverviewPageState, Actions>(
  (state = initialState, action): OverviewPageState => {
    switch (action.type) {
      case SITE_ACTIONS.SET_SITE_CHARGER_LIST:
        const { siteChargers } = action;
        return {
          ...state,
          charger: !isEmpty(siteChargers) ? siteChargers[0] : undefined,
        };
      case CHARGER_ACTIONS.SET_CHARGER_LIVE_STATE:
        const { charger } = state;
        return {
          ...state,
          charger: charger ? { ...charger, liveState: action.chargerLiveState } : undefined,
        };
      case OVERVIEW_ACTIONS.SET_OVERVIEW_LIVE_STATE:
        return {
          ...state,
          overviewLiveState: action.liveState,
        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [
          GET_CHARGERS_QUERY,
          GET_OVERVIEW_SITE_LIVE_STATE_QUERY,
        ]);
    }
  }
)({
  key: 'customer-page-overview',
  blacklist: [GET_CHARGERS_QUERY],
});
