import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { Portal } from '+shared/store/firebase/types';
import { dateUtil } from '+utils/date.util';

import { NewsFormPartnerInterface } from '../types/';

const NewsFormPartnerInitialValues: NewsFormPartnerInterface = {
  id: '',
  portals: [Portal.SP],
  isCPIncluded: false,
  isSPIncluded: true,
  isActive: false,
  date: undefined,
  markets: [],
  level: undefined,
  levelValue: 0,
  tag: undefined,
  tagValue: '',
  roles: [],
  isDisplayedAsNotification: false,
  notificationExpiryDate: '',
  shortMessage: '',
  introduction: '',
  message: '',
  expiryDate: '',
};

export const sendNewsSchema = () =>
  Yup.object().shape({
    level: Yup.object().required(I18n.t(T.news.channel.sendNews.fields.newsType.requiredText)),
    markets: Yup.array()
      .min(1, I18n.t(T.news.channel.sendNews.fields.market.requiredText))
      .of(Yup.string())
      .required(I18n.t(T.news.channel.sendNews.fields.market.requiredText)),
    tag: Yup.object().required(I18n.t(T.news.channel.sendNews.fields.newsTag.requiredText)),
    roles: Yup.array()
      .of(Yup.string())
      .required(I18n.t(T.news.channel.sendNews.fields.roles.requiredText)),
    notificationExpiryDate: Yup.string().when('isDisplayedAsNotification', {
      is: (val) => val,
      then: Yup.string()
        .test(
          'invalidDateFormat',
          I18n.t(T.news.channel.sendNews.fields.validation.invalidDateFormat),
          (value) => (value ? dateUtil.isValidDateFormat(value) : true)
        )
        .required(I18n.t(T.news.channel.sendNews.fields.notificationExpiryDate.requiredText)),
    }),
    expiryDate: Yup.string().test(
      'invalidDateFormat',
      I18n.t(T.news.channel.sendNews.fields.validation.invalidDateFormat),
      (value) => (value ? dateUtil.isValidDateFormat(value) : true)
    ),
    shortMessage: Yup.string()
      .max(130, I18n.t(T.news.channel.sendNews.fields.title.description.max))
      .required(I18n.t(T.news.channel.sendNews.fields.title.requiredText)),
    introduction: Yup.string(),
  });

export const getNewsFormPartnerInitialValues = () => NewsFormPartnerInitialValues;
