import { SharedChartColors } from '@sonnen/shared-web';

import { ChartZoomViewStyle } from '@kanva/charts';
import { Paint, Rect, rgba } from '@kanva/core';

export const zoomSelectAreaStyle: ChartZoomViewStyle = {
  paint: new Paint()
    .setFillStyle(rgba(SharedChartColors.AreaSelect, 0.3))
    .setStrokeStyle(SharedChartColors.AreaSelect),
  borders: new Rect({ left: 1, right: 1, top: 0, bottom: 0 }),
};
