import { Query } from '+shared/store/query';
import { Document } from '+shared/store/setupTool/types';

export const GENERATE_DOCUMENTS_QUERY = 'generateDocumentsQuery';

export interface DsoRegistrationState {
  documents: Document[];
  updatedAt: string;
  [GENERATE_DOCUMENTS_QUERY]: Query;
}

export const initialState: DsoRegistrationState = {
  documents: [],
  updatedAt: '',
  [GENERATE_DOCUMENTS_QUERY]: {},
};

export const DSO_REGISTRATION_DOCUMENT_CATEGORIES = [
  'SUBMISSION_input_summary',
  'DOCSPRING_general',
];
