import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import { getDsoCommissioningFields } from '+app/+setupTool/+dsoCommissioning/store/+dsoCommissioning.selectors';
import { DsoCommissioningInterface } from '+app/+setupTool/+dsoCommissioning/store/types';
import { DsoRegistrationFormContainer } from '+app/+setupTool/+form/components';
import { mapActions } from '+app/utils';
import { CommissioningDataFormTestIds as testIds } from '+config/testIds';
import { getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import {
  DatepickerArrowSide,
  FormInput,
  FormInputDate,
  FormSectionParagraph,
} from '+shared/components';
import { useDispatchInputEvent } from '+shared/hooks/useDispatchInputEvent';
import { StoreState } from '+shared/store/store.interface';

const mapStateToProps = (state: StoreState) => ({
  fields: getDsoCommissioningFields(state),
  registrationSubject: getRegistrationSubject(state),
});

const mapDispatchToProps = mapActions({});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    form: FormikProps<DsoCommissioningInterface>;
    onlyRequiredFields?: boolean;
    alwaysShowPvCommissioningDate?: boolean;
  };

const DsoCommissioningDataFormComponent: React.FC<Props> = ({
  fields,
  registrationSubject,
  form,
  onlyRequiredFields = false,
  alwaysShowPvCommissioningDate = false,
}) => {
  const { onChange: onChangeDispatch } = useDispatchInputEvent();
  const shouldShowPvCommissioningDate =
    alwaysShowPvCommissioningDate || registrationSubject === RegistrationSubjectType.PV_AND_BATTERY;

  React.useEffect(() => {
    if (fields) {
      Object.entries(fields).map(([name, val]) => {
        if (!(isEmpty(val) && isEmpty(form.values[name]))) {
          setTimeout(() => {
            form.setFieldValue(name, val);
            form.setFieldTouched(name, true);
            onChangeDispatch(name, val);
          }, 0);
        }
      });
    }
  }, [fields]);

  return (
    <DsoRegistrationFormContainer>
      <FormSectionParagraph>
        {I18n.t(T.setupTool.dsoCommissioning.form.header)}
      </FormSectionParagraph>
      {onlyRequiredFields || (
        <FormInput
          className={'c-dso-commissioning-data__item'}
          form={form}
          label={`${I18n.t(T.setupTool.dsoCommissioning.form.dsoReferenceNumber)}
                  / ${I18n.t(T.setupTool.form.optional)}`}
          name={'dso_reference_number'}
          dataTestId={testIds.processingNumberNetworkField}
        />
      )}
      {shouldShowPvCommissioningDate && (
        <FormInputDate
          className={'c-dso-commissioning-data__item'}
          form={form}
          label={I18n.t(T.setupTool.dsoCommissioning.form.pvCommissioningDate)}
          name={'pv_commissioning_date'}
          minDate={new Date('1900-01-01')}
          arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
          dataTestId={testIds.pvCommissioningDateField}
        />
      )}
      <FormInputDate
        className={'c-dso-commissioning-data__item'}
        form={form}
        label={I18n.t(T.setupTool.dsoCommissioning.form.batteryCommissioningDate)}
        name={'battery_commissioning_date'}
        minDate={new Date('1900-01-01')}
        arrowPosition={
          shouldShowPvCommissioningDate
            ? DatepickerArrowSide.BOTTOM
            : DatepickerArrowSide.BOTTOM_RIGHT
        }
        dataTestId={testIds.batteryCommissioningDateField}
      />
    </DsoRegistrationFormContainer>
  );
};

export const DsoCommissioningDataForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DsoCommissioningDataFormComponent);
