import { ChangeEvent } from 'react';

import { FieldProps } from 'formik';

import { FormInputEventType, useDispatchInputEvent } from './useDispatchInputEvent';

type FieldInputProps = FieldProps['field'];

const parseValue = (e: ChangeEvent<any>) => {
  const { type, checked, value } = e.target;

  if (/number|range/.test(type)) {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? '' : parsed;
  }

  if (/checkbox/.test(type)) {
    return checked;
  }

  return value;
};

const useInputEvent = (field: FieldInputProps) => {
  const dispatchEventHandlers = useDispatchInputEvent();

  const createHandler =
    <T extends FormInputEventType>(handlerName: T): FieldInputProps[T] =>
    (e: any) => {
      const { name } = e.target;

      const { [handlerName]: formikHandler } = field;
      const { [handlerName]: dispatchHandler } = dispatchEventHandlers;

      formikHandler(e);
      dispatchHandler(name, parseValue(e));
    };

  const handleBlur = createHandler(FormInputEventType.ON_BLUR);
  const handleChange = createHandler(FormInputEventType.ON_CHANGE);

  return {
    onBlur: handleBlur,
    onChange: handleChange,
  };
};

export default useInputEvent;
