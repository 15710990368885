import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNull } from 'lodash';
import * as Yup from 'yup';

export const requiredPasswordLength = 5;

export const isPasswordMatching = (password: string, verification: number) =>
  password.length >= requiredPasswordLength && !isNull(verification) && verification >= 3;

export const isPasswordConfirmationMatching = (password: string, passwordConfirmation: string) =>
  password === passwordConfirmation;

export const isPolicyAccepted = (policy: boolean) => policy;

export const areTermsAccepted = (terms: boolean) => terms;

export const isFormMatching = (
  password: string,
  passwordConfirmation: string,
  verification: number,
  privacyPolicy: boolean,
  termsOfService: boolean
) =>
  isPasswordMatching(password, verification) &&
  isPasswordConfirmationMatching(password, passwordConfirmation) &&
  isPolicyAccepted(privacyPolicy) &&
  areTermsAccepted(termsOfService);

export const shouldCheckboxAlertAppear = (isDirty: boolean, policy: boolean, terms: boolean) =>
  isDirty && (!isPolicyAccepted(policy) || !areTermsAccepted(terms));

export const registerSchema = () =>
  Yup.object({
    password: Yup.string()
      .min(5, I18n.t(T.register.alert.weakPassword, { length: requiredPasswordLength }))
      .required(I18n.t(T.register.alert.requiredField)),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], I18n.t(T.register.alert.differentPasswords))
      .required(I18n.t(T.register.alert.requiredField)),
    termsOfService: Yup.boolean().oneOf([true], I18n.t(T.register.alert.docsNotAccepted)),
    privacyPolicy: Yup.boolean().oneOf([true], I18n.t(T.register.alert.docsNotAccepted)),
  });
