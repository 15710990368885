import { flatMap, flow, groupBy, head, map, mapValues, uniq, values } from 'lodash/fp';

import { Faq } from '+shared/store/faq';
import { slugify } from '+utils/string.util';

export interface AggregatedFaqTopic {
  topicName: string;
  question: string;
  answer: string;
}

export interface AggregatedFaq {
  title: string;
  questions: AggregatedFaqTopic[];
  hash: string;
}

const OPEN_PREFIX = '&open=';

export const checkAccordionOpen = (index: number): boolean => {
  const locationHash = location.hash.split(OPEN_PREFIX);
  const locationHashOpen = locationHash[1];
  if (!locationHashOpen) {
    return false;
  }
  const openAccordions = locationHashOpen.split(',').map(Number);
  return openAccordions.includes(index);
};

const getFaqTopicAndProductNames = (faq: Faq) => [
  ...(faq.topics?.map((topic) => topic.name) ?? []),
  ...(faq.products?.map((product) => product.name) ?? []),
];

const disperseFaqByTopicsAndProducts = (faq: Faq) =>
  flow(
    getFaqTopicAndProductNames,
    uniq,
    map((topicOrProductName) => ({
      topicName: topicOrProductName,
      question: faq.question,
      answer: faq.answer,
    }))
  )(faq);

export const aggregateFaqTopics = (faqList: Faq[]): AggregatedFaq[] =>
  flow(
    flatMap<Faq, AggregatedFaqTopic>(disperseFaqByTopicsAndProducts),
    groupBy((faq) => faq.topicName),
    mapValues((questions) => {
      const title = head(questions)?.topicName ?? 'unknown';
      const hash = '#' + slugify(title);
      return { title, questions, hash };
    }),
    values
  )(faqList);

export const getSelectedAggregatedFaqTopic = (faqList: AggregatedFaq[]): AggregatedFaq =>
  faqList.find(
    (item) => item.hash.toLowerCase() === location.hash.split(OPEN_PREFIX)[0].toLowerCase()
  ) || faqList[0];
