import React, { ReactNode } from 'react';

import classnames from 'classnames';

import './Headlines.scss';

interface Props {
  text: ReactNode;
  className?: string;
}

export const HeadlineHero: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'headline-hero');

  return <h1 className={classNames}>{text}</h1>;
};

export const HeadlineLarge: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'headline-large');

  return <h2 className={classNames}>{text}</h2>;
};

export const HeadlineMedium: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'headline-medium');

  return <h3 className={classNames}>{text}</h3>;
};

export const HeadlineSmall: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'headline-small');

  return <h4 className={classNames}>{text}</h4>;
};
