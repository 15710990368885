import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';

import { SortOrder } from '@coolio/json-api';
import { push } from 'connected-react-router';
import { isNil } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import {
  CustomerListRouteQueryParams,
  CustomerRouteParams,
  PATHS,
  SiteListRouteQueryParams,
} from '+router/routes';
import { getDecryptedRouteQueryParams } from '+router/store/router.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import {
  defaultResultsPerPage,
  isResultsPerPageOutsideAcceptedBounds,
} from '+shared/components/Pagination/PaginationConfiguration.interface';
import { CaseReport } from '+shared/containers/CaseReport';
import { CaseActions } from '+shared/store/case';
import { CustomerSortKey } from '+shared/store/customer/types/customerSort.type';
import { getOpenModalId, LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserCountryCode,
  getUserProfileCustomerNumber,
  getUserProfileRoles,
  isSuperUserRole,
} from '+shared/store/user/user.selectors';
import ResponsivePageWidth from '+shared/templates/ResponsivePageWidth/ResponsivePageWidth';
import { encryptSearchTerm } from '+utils/crypto.util';
import { mapActions } from '+utils/redux';

import {
  getPartnerInstalledBatteriesCount,
  getSiteCollection,
  getSiteListCurrentPage,
  getSiteListQueryStatus,
  getSiteListTotalPages,
  getSiteListTotalResultsCount,
} from '../../store/siteList.selectors';
import SitesControlsMenu from './SitesControlsMenu/SitesControlsMenu';
import SitesFiltersArea, { AppliedFilters } from './SitesFiltersArea/SitesFiltersArea';
import SitesFooter from './SitesFooter/SitesFooter';
import LoaderWidgetForTable from './SitesTable/LoaderWidgetForTable/LoaderWidgetForTable';
import SitesTable from './SitesTable/SitesTable';

import './TabSites.scss';

const mapStateToProps = (state: StoreState) => ({
  siteCollection: getSiteCollection(state),
  totalResultsCount: getSiteListTotalResultsCount(state),
  batteriesInstalledCount: getPartnerInstalledBatteriesCount(state),
  routerQueryParams: getDecryptedRouteQueryParams(state) as SiteListRouteQueryParams,
  sitesCollectionQueryStatus: getSiteListQueryStatus(state),
  totalPages: getSiteListTotalPages(state),
  currentPage: getSiteListCurrentPage(state),
  userProfileCustomerNumber: getUserProfileCustomerNumber(state),
  openModalId: getOpenModalId(state),
  userRoles: getUserProfileRoles(state),
  isSuperUser: isSuperUserRole(state),
  countryCode: getUserCountryCode(state) ?? CountryCode.DE,
});

const mapDispatchToProps = mapActions({
  pushSiteListPage: (params: CustomerListRouteQueryParams) => push(PATHS.CUSTOMERS(params)),
  pushCustomerPage: (params: CustomerRouteParams) => push(PATHS.CUSTOMER(params)),

  toggleModal: LayoutActions.toggleModal,
  setCaseReportActiveSite: CaseActions.setCaseReportActiveSite,
});

type RouteProps = RouteComponentProps<SiteListRouteQueryParams>;

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteProps;

export type SortingOrder = 'ascending' | 'descending' | undefined;

const TabSitesComponent: React.FC<Props> = ({
  siteCollection,
  sitesCollectionQueryStatus,
  totalResultsCount,
  batteriesInstalledCount,
  totalPages,
  currentPage,
  actions,
  routerQueryParams,
  userProfileCustomerNumber,
  isSuperUser,
  countryCode,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.CUSTOMERS_LIST);

  const location = useLocation();
  const filterControlsMenuRef = useRef<any>();
  const tableWidthRef = useRef<any>();
  const footerHeightRef = useRef<any>();

  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [intermediarySearchValue, setIntermediarySearchValue] = useState<null | string>(null);
  const [footerHeightForLoader, setFooterHeightForLoader] = useState<number>(0);
  const [filterControlsIsOpen, setFilterControlsIsOpen] = useState<boolean>(false);
  const [isShadowVisible, setIsShadowVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>({
    postalCodes: undefined,
    meterPreparationStatuses: undefined,
    fstCheckSuccessful: undefined,
  });

  useEffect(() => {
    if (isResultsPerPageOutsideAcceptedBounds(routerQueryParams.size)) {
      querySites({ ...routerQueryParams, size: defaultResultsPerPage });
    }
  }, []);

  useEffect(() => {
    // 1. If page is refreshed, upon component mount,
    // all filters are extracted from query params and applied to the state.
    const { postalCodes, meterPreparationStatuses, fstCheckSuccessful, search } = routerQueryParams;
    const openFilterArea = Boolean(postalCodes || meterPreparationStatuses || fstCheckSuccessful);

    const updatedFilters = {
      ...appliedFilters,
      postalCodes: postalCodes ? new Set(postalCodes.split(',')) : undefined,
      meterPreparationStatuses: meterPreparationStatuses
        ? meterPreparationStatuses.split(',')
        : undefined,
      fstCheckSuccessful: fstCheckSuccessful || undefined,
    };
    setAppliedFilters(updatedFilters);
    setFilterControlsIsOpen(openFilterArea || filterControlsIsOpen);

    // 2.1 Make search result text bold if user refreshes the page,
    // so set default value upon component mount.
    setIntermediarySearchValue(isNil(search) ? null : search);
  }, [location]);

  useEffect(() => {
    // 2.2 Not immediately set the searchValue as what user inputs in the search field.
    // Otherwise the bold text will be immediately rendered in the table on the old values,
    // before the table is populated with the new search result values.
    if (sitesCollectionQueryStatus.pending) setSearchValue(intermediarySearchValue);
    setFooterHeightForLoader(footerHeightRef.current.offsetHeight);
  }, [sitesCollectionQueryStatus, intermediarySearchValue, siteCollection.length]);

  useEffect(() => {
    if (currentPage > totalPages) querySites({ page: String(totalPages) });
  }, [currentPage, totalPages]);

  useEffect(() => {
    const tableTop = tableWidthRef.current.offsetTop;
    let filterControlsMenuHeight = filterControlsMenuRef.current.offsetHeight;

    const listenToScroll = () => {
      filterControlsMenuHeight = filterControlsMenuRef.current.offsetHeight;
      const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const heightOffsetWithMenuClosed = tableTop < windowScroll + 70;
      const heightOffsetWithMenuOpen = tableTop < windowScroll + filterControlsMenuHeight - 44;

      setIsShadowVisible(
        filterControlsIsOpen ? heightOffsetWithMenuOpen : heightOffsetWithMenuClosed
      );
    };

    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [filterControlsIsOpen]);

  // SEARCH HANDLERS
  const querySites = (newParams: SiteListRouteQueryParams) => {
    actions.pushSiteListPage(
      encryptSearchTerm({ ...routerQueryParams, ...newParams }, userProfileCustomerNumber)
    );
  };

  // FILTER HANDLERS
  const buildQueryParams = (appliedFilters: AppliedFilters) => {
    const { postalCodes, meterPreparationStatuses, fstCheckSuccessful } = appliedFilters;
    const updatedQueryParams: { [key: string]: string | undefined } = { ...routerQueryParams };

    updatedQueryParams.postalCodes = postalCodes ? Array.from(postalCodes).join(',') : undefined;
    updatedQueryParams.meterPreparationStatuses = meterPreparationStatuses
      ? meterPreparationStatuses.join(',')
      : undefined;
    updatedQueryParams.fstCheckSuccessful = fstCheckSuccessful || undefined;

    setAppliedFilters({ ...appliedFilters });
    querySites(updatedQueryParams);
  };

  // SORTING ORDER HANDLERS
  const handlePageChange = (
    pageNumber: number,
    recordsPerPage: string,
    routerQueryParams: SiteListRouteQueryParams
  ) => {
    const newParams = {
      ...routerQueryParams,
      page: String(pageNumber),
      size: recordsPerPage,
    };
    querySites(newParams);
  };

  const handleSearch = (value: string, routerQueryParams: SiteListRouteQueryParams) => {
    const newParams = {
      ...routerQueryParams,
      search: value,
      page: '1',
    };

    querySites(newParams);
    setIntermediarySearchValue(value);
  };

  const handleSort = (column: CustomerSortKey, order: 'ascending' | 'descending') => {
    querySites({
      sort: column,
      order: order === 'descending' ? SortOrder.DESCENDING : SortOrder.ASCENDING,
      page: undefined,
    });
  };

  // HELPERS THAT DEPEND ON COMPONENT PROPS
  const getSortOrderForColumn = (column: CustomerSortKey): SortingOrder => {
    const { sort, order } = routerQueryParams;

    if (!sort && column === CustomerSortKey.INSTALLATION_DATE) return 'descending';
    if (sort !== column) return undefined;

    return order === SortOrder.DESCENDING ? 'descending' : 'ascending';
  };

  return (
    <>
      <div
        className={`controls-menu__sticky ${isShadowVisible ? 'drop-shadow' : ''}`}
        ref={filterControlsMenuRef}
      >
        <ResponsivePageWidth>
          <SitesControlsMenu
            totalResultsCount={totalResultsCount}
            batteriesInstalledCount={batteriesInstalledCount}
            toggleSettings={() => {}}
            routerQueryParams={routerQueryParams}
            handleSearch={handleSearch}
            toggleFilterControls={() => setFilterControlsIsOpen((prev) => !prev)}
            filterControlsIsOpen={filterControlsIsOpen}
            countryCode={countryCode}
          />

          {filterControlsIsOpen && (
            <SitesFiltersArea
              appliedFilters={appliedFilters}
              buildQueryParams={buildQueryParams}
              countryCode={countryCode}
            />
          )}
        </ResponsivePageWidth>
      </div>

      <CaseReport />
      <ResponsivePageWidth>
        <div className="wrapper-for-loader-and-table" ref={tableWidthRef}>
          <LoaderWidgetForTable
            tableRows={siteCollection.length}
            footerHeightForLoader={footerHeightForLoader}
            isLoading={sitesCollectionQueryStatus.pending}
          />
          <SitesTable
            getSortOrderForColumn={getSortOrderForColumn}
            handleSort={handleSort}
            searchValue={searchValue}
            siteCollection={siteCollection}
            pushCustomerPage={actions.pushCustomerPage}
            toggleModal={actions.toggleModal}
            setCaseReportActiveSite={actions.setCaseReportActiveSite}
            isSuperUser={isSuperUser}
          />
        </div>

        <SitesFooter
          totalCount={totalResultsCount}
          routerQueryParams={routerQueryParams}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          footerHeightRef={footerHeightRef}
        />
      </ResponsivePageWidth>
    </>
  );
};

const TabSites = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabSitesComponent));
export default TabSites;
