import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { DetailListTable } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Lead } from '+shared/store/lead/types';

import { factorizeLeadHouseDetails } from './LeadOverviewHouseDetails.helper';

interface Props {
  lead: Lead;
}

export const LeadOverviewHouseDetails: React.FC<Props> = ({ lead }) => (
  <Widget
    icon={'HouseOnHill'}
    heading={I18n.t(T.lead.boc._salessolution_.form.houseDetails.headline)}
  >
    <DetailListTable
      list={factorizeLeadHouseDetails(lead.roofDetails)}
      filterNil={false}
      lead={lead}
      hasBottomBorder={false}
    />
  </Widget>
);
