import { defer } from 'rxjs';

import { Config } from '+config/config';
import { TariffRes } from '+customer-battery/store/battery-tariff';
import { httpClient } from '+shared/network/setupToolsNetwork.client';

import { jsonApiClient } from '../../network';
import { BatteryOperatingModeOptions } from '../site/types';
import {
  BatteryElectricUnitsData,
  BatteryIntelligentCharging,
  BatteryWithProductData,
} from './types';
import { BatteryIcStatusData } from './types/batteryIcStatus.interface';
import { BatteryOperationsData } from './types/batteryOperations.interface';
import { PowerMeterLiveStateData } from './types/batteryPowerMeterLiveState.interface';
import { BatteryStatusesData } from './types/batteryStatuses.interface';

const getBattery = (id: string) =>
  defer(() =>
    jsonApiClient
      .get<BatteryWithProductData>(`${Config.API_URL}/battery-systems/${id}?include=product`)
      .expectOne()
      .resolveIncluded()
      .send()
  );

const getBatteryElectricUnits = (id: string) =>
  defer(() =>
    jsonApiClient
      .get<BatteryElectricUnitsData>(`${Config.API_URL}/battery-systems/${id}/electric-units`)
      .expectMany()
      .send()
  );

const getBatteryStatuses = (id: string, start: Date, end: Date) =>
  defer(() =>
    jsonApiClient
      .get<BatteryStatusesData>(`${Config.API_URL}/battery-systems/${id}/statuses`)
      .filter('key', 'latest_battery_special_status')
      .filter('start_at', start.toISOString())
      .filter('end_at', end.toISOString())
      .expectMany()
      .send()
  );

const getBatteryIcStatus = (id: string) =>
  defer(() =>
    jsonApiClient
      .get<BatteryIcStatusData>(`${Config.API_URL}/partners/battery-systems/${id}/latest-ic-status`)
      .expectOne()
      .send()
  );

const getBatteryPowerMeterLiveStates = (id: string) =>
  defer(() =>
    jsonApiClient
      .get<PowerMeterLiveStateData>(
        `${Config.API_URL}/partners/battery-systems/${id}/power-meter-live-states`
      )
      .expectMany()
      .send()
  );

const postBatteryIntelligentCharging = (id: string, value: BatteryIntelligentCharging) =>
  defer(() =>
    jsonApiClient
      .post<BatteryOperationsData>(`${Config.API_URL}/battery-systems/${id}/prognosis-charging`)
      .ofType('prognosis-charging')
      .withAttributes({ value })
      .expectOne()
      .send()
  );

const postBatteryOperatingMode = (id: string, value: BatteryOperatingModeOptions) =>
  defer(() =>
    jsonApiClient
      .post<BatteryOperationsData>(`${Config.API_URL}/battery-systems/${id}/operating-mode`)
      .ofType('operating-mode')
      .withAttributes({ value })
      .expectOne()
      .send()
  );

const postBatteryPvGridFeedInLimit = (id: string, value: string) =>
  defer(() =>
    jsonApiClient
      .post<BatteryOperationsData>(
        `${Config.API_URL}/partners/battery-systems/${id}/pv-grid-feedin-limit`
      )
      .ofType('pv-grid-feedin-limit')
      .withAttributes({ value })
      .expectOne()
      .send()
  );

const postBatteryTimeOfUseSchedule = (id: string, value: TariffRes[]) =>
  defer(() =>
    jsonApiClient
      .post(`${Config.API_URL}/battery-systems/${id}/time-of-use-schedule`)
      .ofType('time-of-use-schedule')
      .withAttributes({ value })
      .expectOne()
      .send()
  );

const postBatteryHighTariffWindowSchedule = (id: string, value: TariffRes[]) =>
  defer(() =>
    jsonApiClient
      .post(`${Config.API_URL}/battery-systems/${id}/high-tariff-windows`)
      .ofType('high-tariff-windows')
      .withAttributes({ value })
      .expectOne()
      .send()
  );

const postBatteryBackupBuffer = (id: string, value: number) => {
  return defer(() =>
    httpClient
      .post(`${Config.API_URL}/battery-systems/${id}/backup-power-buffer`, {
        body: {
          value,
        },
      })
      .then((res) => res.parsedBody())
  );
};

export const BatteryRepository = {
  getBattery,
  getBatteryElectricUnits,
  getBatteryStatuses,
  getBatteryIcStatus,
  getBatteryPowerMeterLiveStates,
  postBatteryIntelligentCharging,
  postBatteryPvGridFeedInLimit,
  postBatteryOperatingMode,
  postBatteryTimeOfUseSchedule,
  postBatteryHighTariffWindowSchedule,
  postBatteryBackupBuffer,
};
