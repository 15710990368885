import * as React from 'react';
import Media from 'react-media';

import { TableCell } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { getInlineAddress } from '+customer-list/store/customer.helpers';
import { Site } from '+shared/store/customer';

import './CustomersTableLocation.component.scss';

interface Props {
  site: Site;
  className?: ClassValue;
}

export const CustomersTableLocation: React.FC<Props> = ({ site, className }) => {
  const classes = classNames('c-customers-table-location', className);

  return (
    <TableCell className={classes}>
      <div className={'c-customers-table-location__inner'} data-hj-suppress={true}>
        <Media query={{ minWidth: 576 }}>
          {(isMedium: boolean) =>
            isMedium ? (
              <>
                <p className={'c-customers-table__paragraph'}>{site.street}</p>
                <p className={'c-customers-table__paragraph'}>
                  {site.postalCode} {site.city}
                </p>
              </>
            ) : (
              <p className={'c-customers-table__paragraph'}>{getInlineAddress(site)}</p>
            )
          }
        </Media>
      </div>
    </TableCell>
  );
};
