import { parse, toSeconds } from 'iso8601-duration';

export class UtilDuration {
  private readonly milliseconds: number;

  private constructor(milliseconds: number) {
    this.milliseconds = milliseconds;
  }

  /**
   * @param duration milliseconds or hh:mm or hh:mm:ss
   */
  public static ofString(duration: string): UtilDuration {
    if (duration.startsWith('P')) return UtilDuration.ofIso8601(duration);

    const parts: string[] = duration.split(':');

    if (parts.length === 1) {
      const milliseconds: number = Number(duration);
      if (isNaN(milliseconds)) {
        return new UtilDuration(0);
      }
      return this.ofMilliseconds(milliseconds);
    }

    if (parts.length > 3) {
      return new UtilDuration(0);
    }

    const partsAsNumbers: number[] = parts.map((part) => Number(part));
    if (partsAsNumbers.some((part) => isNaN(part))) {
      return new UtilDuration(0);
    }
    const hours: number = partsAsNumbers[0];
    const minutes: number = partsAsNumbers[1] ?? 0;
    const seconds: number = partsAsNumbers[2] ?? 0;
    return new UtilDuration(hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000);
  }

  public static ofMinutes(minutes: number): UtilDuration {
    return new UtilDuration(minutes * 60 * 1000);
  }

  public static ofSeconds(seconds: number): UtilDuration {
    return new UtilDuration(seconds * 1000);
  }

  public static ofMilliseconds(milliseconds: number): UtilDuration {
    return new UtilDuration(milliseconds);
  }

  public static ofIso8601(iso8601: string): UtilDuration {
    try {
      const duration = parse(iso8601);
      return new UtilDuration(toSeconds(duration) * 1000);
    } catch (e) {
      return new UtilDuration(0);
    }
  }

  public asWeeks(): number {
    return this.asDays() / 7;
  }

  public asDays(): number {
    return this.asHours() / 24;
  }

  public asHours(): number {
    return this.asMinutes() / 60;
  }

  public asMinutes(): number {
    return this.asSeconds() / 60;
  }

  public asSeconds(): number {
    return this.asMilliseconds() / 1000;
  }

  public asMilliseconds(): number {
    return this.milliseconds;
  }
}
