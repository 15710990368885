import * as React from 'react';

import { ListTable, ListTableHeader } from '+shared/components';
import { Video } from '+shared/store/firebase/types';

import { VideoListItem } from '../VideoListItem';
import { getTableHeaders } from './VideoList.helper';

import './VideoList.component.scss';

interface Props {
  items: Video[];
  isPending: boolean;
  deleteVideo: (videoId: string) => void;
}

export const VideoList: React.FC<Props> = ({ items, isPending, deleteVideo }) => (
  <ListTable
    className={'c-video-list__table'}
    headers={getTableHeaders()}
    items={items}
    isPending={isPending}
    noBorder={true}
    renderHeader={(header) => <ListTableHeader item={header} />}
    renderItem={(item) => (
      <VideoListItem
        id={item.id}
        youtubeId={item.youtubeId}
        title={item.snippet.title}
        thumbnail={item.snippet.thumbnails.default.url}
        categories={item.categories}
        deleteVideo={deleteVideo}
        markets={item.markets}
      />
    )}
  />
);
