import { TariffWindow } from '+customer-battery/store/battery-tariff';
import { BatteryOperatingModeOptions } from '+shared/store/site/types';
import { ActionsUnion, createAction } from '+utils/redux';

import { BatteryElectricUnits, BatteryIntelligentCharging, BatteryWithProduct } from './types';
import { BatteryIcStatus } from './types/batteryIcStatus.interface';
import { BatteryStatuses } from './types/batteryStatuses.interface';

export enum BATTERY_ACTIONS {
  GET_BATTERY = '[Battery] GET_BATTERY',
  SET_BATTERY = '[Battery] SET_BATTERY',
  GET_BATTERY_ELECTRIC_UNITS = '[Battery] GET_BATTERY_ELECTRIC_UNITS',
  SET_BATTERY_ELECTRIC_UNITS = '[Battery] SET_BATTERY_ELECTRIC_UNITS',
  GET_BATTERY_STATUSES = '[Battery] GET_BATTERY_STATUSES',
  SET_BATTERY_STATUSES = '[Battery] SET_BATTERY_STATUSES',
  GET_BATTERY_IC_STATUS = '[Battery] GET_BATTERY_IC_STATUS',
  SET_BATTERY_IC_STATUS = '[Battery] SET_BATTERY_IC_STATUS',
  SET_BATTERY_INTELLIGENT_CHARGING = '[Battery] SET_BATTERY_INTELLIGENT_CHARGING',
  // eslint-disable-next-line max-len
  SET_IS_BATTERY_INTELLIGENT_CHARGING_IN_PROGRESS = '[Battery] SET_IS_BATTERY_INTELLIGENT_CHARGING_IN_PROGRESS',
  START_BATTERY_POLLING = '[Battery] START_BATTERY_POLLING',
  STOP_BATTERY_POLLING = '[Battery] STOP_BATTERY_POLLING',
  SET_BATTERY_OPERATING_MODE = '[Battery] SET_BATTERY_OPERATING_MODE',
  SET_IS_BATTERY_OPERATING_MODE_IN_PROGRESS = '[Battery] SET_IS_BATTERY_OPERATING_MODE_IN_PROGRESS',
  // eslint-disable-next-line max-len
  START_LIVE_STATE_POLLING_ON_BATTERY_CHANGE = '[Battery] START_LIVE_STATE_POLLING_ON_BATTERY_CHANGE',
  STOP_LIVE_STATE_POLLING_ON_BATTERY_CHANGE = '[Battery] STOP_LIVE_STATE_POLLING_ON_BATTERY_CHANGE',
  BATTERY_TARIFF_WINDOW_EDIT = '[Battery] BATTERY_TARIFF_WINDOW_EDIT',
  SINGLE_BATTERY_TARIFF_WINDOW_EDIT = '[Battery] SINGLE_BATTERY_TARIFF_WINDOW_EDIT',
  SINGLE_BATTERY_TARIFF_WINDOW_NEW = '[Battery] SINGLE_BATTERY_TARIFF_WINDOW_NEW',
  SINGLE_BATTERY_TARIFF_WINDOW_DELETE = '[Battery] SINGLE_BATTERY_TARIFF_WINDOW_DELETE',
  POST_BATTERY_TIME_OF_USE = '[Battery] POST_BATTERY_TIME_OF_USE',
  POST_HIGH_TIME_OF_USE = '[Battery] POST_POST_HIGH_TIME_OF_USE',
  UPDATE_TARIFF_WINDOW_SCHEDULE_EDIT = '[Battery] UPDATE_TARIFF_WINDOW_SCHEDULE_EDIT',
  RESET_TARIFF_WINDOW_LOCAL_CHANGES = '[Battery] RESET_TARIFF_WINDOW_LOCAL_CHANGES',
  UPDATE_TARIFF_WINDOW_LOCAL_CHANGES = '[Battery] UPDATE_TARIFF_WINDOW_LOCAL_CHANGES',
  SET_IS_BATTERY_REMOTE_SETTINGS_ALLOWED = '[Battery] SET_IS_BATTERY_REMOTE_SETTINGS_ALLOWED',
  ERROR = '[Battery] ERROR',
  SET_BATTERY_BACKUP_BUFFER = '[Battery] SET_BATTERY_BACKUP_BUFFER',
  SET_BATTERY_BACKUP_BUFFER_IN_PROGRESS = '[Battery] SET_BATTERY_BACKUP_BUFFER_IN_PROGRESS',
}

export const BatteryActions = {
  getBattery: createAction(
    BATTERY_ACTIONS.GET_BATTERY,
    ({ queryKey, batteryId }: { queryKey: string; batteryId: string | undefined }) => ({
      queryKey,
      batteryId,
    })
  ),
  setBattery: createAction(
    BATTERY_ACTIONS.SET_BATTERY,
    (battery: BatteryWithProduct | undefined) => ({ battery })
  ),
  getBatteryElectricUnits: createAction(
    BATTERY_ACTIONS.GET_BATTERY_ELECTRIC_UNITS,
    ({ queryKey, batteryId }: { queryKey: string; batteryId: string }) => ({ queryKey, batteryId })
  ),
  setBatteryElectricUnits: createAction(
    BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS,
    (batteryElectricUnits: BatteryElectricUnits[]) => ({ batteryElectricUnits })
  ),
  getBatteryStatuses: createAction(
    BATTERY_ACTIONS.GET_BATTERY_STATUSES,
    ({
      queryKey,
      batteryId,
      start,
      end,
    }: {
      queryKey: string;
      batteryId: string;
      start: Date;
      end: Date;
    }) => ({ queryKey, batteryId, start, end })
  ),
  setBatteryStatuses: createAction(
    BATTERY_ACTIONS.SET_BATTERY_STATUSES,
    (batteryStatuses: BatteryStatuses[]) => ({ batteryStatuses })
  ),
  getBatteryIcStatus: createAction(
    BATTERY_ACTIONS.GET_BATTERY_IC_STATUS,
    ({ queryKey, batteryId }: { queryKey: string; batteryId: string }) => ({ queryKey, batteryId })
  ),
  setBatteryIcStatus: createAction(
    BATTERY_ACTIONS.SET_BATTERY_IC_STATUS,
    (batteryIcStatus: BatteryIcStatus | undefined) => ({ batteryIcStatus })
  ),
  setBatteryIntelligentCharging: createAction(
    BATTERY_ACTIONS.SET_BATTERY_INTELLIGENT_CHARGING,
    ({
      intelligentChargingValue,
      batteryId,
      batterySerialNumber,
    }: {
      intelligentChargingValue: BatteryIntelligentCharging;
      batteryId: string;
      batterySerialNumber: string;
    }) => ({ intelligentChargingValue, batteryId, batterySerialNumber })
  ),
  startBatteryPolling: createAction(
    BATTERY_ACTIONS.START_BATTERY_POLLING,
    ({ queryKey, batteryId }: { queryKey: string; batteryId: string | undefined }) => ({
      queryKey,
      batteryId,
    })
  ),
  stopBatteryPolling: createAction(BATTERY_ACTIONS.STOP_BATTERY_POLLING),
  setIsBatteryIntelligentChargingInProgress: createAction(
    BATTERY_ACTIONS.SET_IS_BATTERY_INTELLIGENT_CHARGING_IN_PROGRESS,
    (isBatteryIntelligentChargingInProgress: boolean) => ({
      isBatteryIntelligentChargingInProgress,
    })
  ),
  setBatteryOperatingMode: createAction(
    BATTERY_ACTIONS.SET_BATTERY_OPERATING_MODE,
    ({
      operatingMode,
      batteryId,
      siteId,
      batterySerialNumber,
    }: {
      operatingMode: BatteryOperatingModeOptions;
      batteryId: string;
      siteId: string;
      batterySerialNumber: string;
    }) => ({ operatingMode, batteryId, siteId, batterySerialNumber })
  ),
  setIsBatteryOperatingModeInProgress: createAction(
    BATTERY_ACTIONS.SET_IS_BATTERY_OPERATING_MODE_IN_PROGRESS,
    (isBatteryOperatingModeInProgress: boolean) => ({ isBatteryOperatingModeInProgress })
  ),
  startLiveStatePollingOnBatteryChange: createAction(
    BATTERY_ACTIONS.START_LIVE_STATE_POLLING_ON_BATTERY_CHANGE,
    ({ siteId, queryKey }: { siteId: string; queryKey: string }) => ({ siteId, queryKey })
  ),
  stopLiveStatePollingOnBatteryChange: createAction(
    BATTERY_ACTIONS.STOP_LIVE_STATE_POLLING_ON_BATTERY_CHANGE
  ),
  editBatteryTariffWindow: createAction(
    BATTERY_ACTIONS.BATTERY_TARIFF_WINDOW_EDIT,
    (tariffWindow: TariffWindow) => ({ tariffWindow })
  ),
  saveEditedBatteryTariffWindow: createAction(
    BATTERY_ACTIONS.SINGLE_BATTERY_TARIFF_WINDOW_EDIT,
    (tariffWindow: TariffWindow) => ({ tariffWindow })
  ),
  deleteSingleBatteryTariffWindow: createAction(
    BATTERY_ACTIONS.SINGLE_BATTERY_TARIFF_WINDOW_DELETE,
    (tariffWindowId: string) => ({ tariffWindowId })
  ),
  newSingleBatteryTariffWindow: createAction(
    BATTERY_ACTIONS.SINGLE_BATTERY_TARIFF_WINDOW_NEW,
    (tariffWindow: TariffWindow) => ({ tariffWindow })
  ),
  postBatteryTimeOfUse: createAction(
    BATTERY_ACTIONS.POST_BATTERY_TIME_OF_USE,
    ({ tariffWindows, batteryId }: { tariffWindows: any[]; batteryId: string }) => ({
      batteryId,
      tariffWindows,
    })
  ),
  postHighTariffWindow: createAction(
    BATTERY_ACTIONS.POST_HIGH_TIME_OF_USE,
    ({ tariffWindows, batteryId }: { tariffWindows: any[]; batteryId: string }) => ({
      batteryId,
      tariffWindows,
    })
  ),
  updateTariffWindowScheduleEditStatus: createAction(
    BATTERY_ACTIONS.UPDATE_TARIFF_WINDOW_SCHEDULE_EDIT,
    ({
      isTariffWindowScheduleEditedButNotSaved,
    }): { isTariffWindowScheduleEditedButNotSaved: boolean } => ({
      isTariffWindowScheduleEditedButNotSaved,
    })
  ),
  resetLocalChanges: createAction(BATTERY_ACTIONS.RESET_TARIFF_WINDOW_LOCAL_CHANGES),
  updateLocalChanges: createAction(BATTERY_ACTIONS.UPDATE_TARIFF_WINDOW_LOCAL_CHANGES),
  setBatteryRemoteSettingsAllowed: createAction(
    BATTERY_ACTIONS.SET_IS_BATTERY_REMOTE_SETTINGS_ALLOWED,
    (isBatteryRemoteSettingsAllowed: boolean) => ({ isBatteryRemoteSettingsAllowed })
  ),
  setBatteryBackupBuffer: createAction(
    BATTERY_ACTIONS.SET_BATTERY_BACKUP_BUFFER,
    ({ backupBuffer, batteryId }: { backupBuffer: number; batteryId: string }) => ({
      backupBuffer,
      batteryId,
    })
  ),
  setIsBatteryBackupBufferInProgress: createAction(
    BATTERY_ACTIONS.SET_BATTERY_BACKUP_BUFFER_IN_PROGRESS,
    (isBatteryBackupBufferInProgress: boolean) => ({ isBatteryBackupBufferInProgress })
  ),
};

export type BatteryActions = ActionsUnion<typeof BatteryActions>;
