import React from 'react';

type Props = {
  color?: string;
  size?: string;
  className?: string;
};

export const InfoFilled: React.FC<Props> = ({ color = '#0B7AC2', size = '24', className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill={color} />
    <path
      d="M11.186 16.9842C11.186 17.1762 11.346 17.3362 11.538 17.3362H12.434C12.61 17.3362 12.786 17.1762 12.786 16.9842V10.2962C12.786 10.1202 12.61 9.9442 12.434 9.9442H11.538C11.346 9.9442 11.186 10.1202 11.186 10.2962V16.9842ZM11.986 8.3602C12.594 8.3602 13.074 7.8802 13.074 7.2882C13.074 6.6802 12.594 6.2002 11.986 6.2002C11.394 6.2002 10.93 6.6802 10.93 7.2882C10.93 7.8802 11.394 8.3602 11.986 8.3602Z"
      fill="white"
    />
  </svg>
);
