import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import uuid from 'uuid';

import { getTypeOfGridReferenceMeter } from '+app/+setupTool/+form';
import { FileCategoryName } from '+app/+setupTool/+form/store';
import { VppDocumentationInterface } from '+app/+setupTool/+vppDocumentation/store';
import { SignalUnit } from '+app/App.constants';
import { AdditionalFeatures } from '+shared/store/setupTool/types/dsoRegistrationResponse.interface';
import { insertIf } from '+utils/array.util';
import { dateUtil } from '+utils/date.util';

export const getVppDocumentationData = (
  fields: VppDocumentationInterface,
  vppAdditionalFeatures: AdditionalFeatures[]
) => [
  ...insertIf(!!fields.virtual_pv_system, {
    label: I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
      subsystemName: fields.virtual_pv_system_subsystem_1_name,
    }),
    value: dateUtil.format(
      dateUtil.of(fields.virtual_pv_system_subsystem_1_commissioning_date ?? dateUtil.now()),
      'DD.MM.YYYY'
    ),
  }),
  ...insertIf(!!fields.virtual_pv_system, {
    label: I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
      subsystemName: fields.virtual_pv_system_subsystem_2_name,
    }),
    value: dateUtil.format(
      dateUtil.of(fields.virtual_pv_system_subsystem_2_commissioning_date ?? dateUtil.now()),
      'DD.MM.YYYY'
    ),
  }),
  ...insertIf(!fields.virtual_pv_system, {
    label: I18n.t(T.setupTool.dsoCommissioning.form.pvCommissioningDate),
    value: dateUtil.format(dateUtil.of(fields.pv_commissioning_date), 'DD.MM.YYYY'),
  }),
  {
    label: I18n.t(T.setupTool.dsoCommissioning.form.batteryCommissioningDate),
    value: dateUtil.format(dateUtil.of(fields.battery_commissioning_date), 'DD.MM.YYYY'),
  },
  ...insertIf(vppAdditionalFeatures.includes(AdditionalFeatures.PRODUCTION_METER_FIELD), {
    label: I18n.t(T.setupTool.form.productionMeterQuestion),
    value: fields.production_meter,
  }),
  ...insertIf(vppAdditionalFeatures.includes(AdditionalFeatures.PRODUCTION_METER_FIELD), {
    label: I18n.t(T.setupTool.form.meterProductionNumber),
    value: fields.number_production_meter,
  }),
  {
    label: I18n.t(T.setupTool.form.typeOfGridMeterMounting),
    value: fields.fastening_type_grid,
  },
  ...insertIf(
    vppAdditionalFeatures.includes(AdditionalFeatures.GSM_MEASUREMENTS_REQUIRED_FIELDS) ||
      vppAdditionalFeatures.includes(AdditionalFeatures.GSM_MEASUREMENTS_OPTIONAL_FIELDS),
    {
      label: I18n.t(T.setupTool.form.freeSpaceInTheUpperPartOfMeterCabinet),
      value: fields.free_space_in_the_upper_part_of_the_meter_cabinet
        ? I18n.t(T.setupTool.common.yes)
        : I18n.t(T.setupTool.common.no),
    }
  ),
  ...insertIf(vppAdditionalFeatures.includes(AdditionalFeatures.FASTENING_TYPE_CONSUMPTION_FIELD), {
    label: I18n.t(T.setupTool.form.typeOfConsumptionMeterMounting),
    value: fields.fastening_type_consumption,
  }),
  {
    label: I18n.t(T.setupTool.form.meterNumber),
    value: fields.meter_number,
  },
  {
    label: I18n.t(T.setupTool.form.typeOfGridReferenceMeter),
    value: getTypeOfGridReferenceMeter().find(
      (x) => x.value === fields.type_of_grid_reference_meter
    )!.name,
  },
  ...insertIf(vppAdditionalFeatures.includes(AdditionalFeatures.DSO_REFERENCE_NUMBER_PV_FIELD), {
    label: I18n.t(T.setupTool.form.dsoReferenceNumberPv),
    value: fields.dso_reference_number_pv,
  }),
];

export const getVppDocumentationGsmData = (fields: VppDocumentationInterface) => [
  ...insertIf(!!fields.upload_speed_mbits, {
    label: I18n.t(T.setupTool.form.uploadSpeed),
    value: `${fields.upload_speed_mbits} ${SignalUnit.MBIT_PER_SEC}`,
  }),
  ...insertIf(!!fields.download_speed_mbits, {
    label: I18n.t(T.setupTool.form.downloadSpeed),
    value: `${fields.download_speed_mbits} ${SignalUnit.MBIT_PER_SEC}`,
  }),
  ...insertIf(!!fields.ping_ms, {
    label: I18n.t(T.setupTool.form.ping),
    value: `${fields.ping_ms} ${SignalUnit.MS}`,
  }),
  ...insertIf(!!fields.signal_strength_dbm, {
    label: I18n.t(T.setupTool.form.signalStrength),
    value: `${fields.signal_strength_dbm} ${SignalUnit.DBM}`,
  }),
  {
    label: I18n.t(T.setupTool.form.lanConnectionAvailable),
    value: fields.lan_connection_available
      ? I18n.t(T.setupTool.common.yes)
      : I18n.t(T.setupTool.common.no),
  },
];

export const renderUploadedDocuments = (
  fileCategoryName: FileCategoryName,
  defaultFileName?: string
) =>
  Array.isArray(fileCategoryName) &&
  fileCategoryName.map((item: any) => (
    <div key={uuid()} className={'c-dso-registration-file__row'}>
      <div className={'c-dso-registration-file__file-container'}>
        <Icon.Contract className={'c-dso-registration-file__file-icon'} />
        <span className={'c-link__label'}>{item.fileName || defaultFileName}</span>
      </div>
    </div>
  ));
