import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';
import uuid from 'uuid';

import { TestId } from '+config/testIds';
import { FormInputRadioGroup } from '+shared/components';
import { FormFieldError } from '+shared/components/Form/FormFieldError';
import { BatteryAccessoryInfo } from '+shared/store/lead/types';

import { ConfigurationForm } from '../../store/types';
import { formFields } from './index';

interface Props {
  form: FormikProps<ConfigurationForm>;
  question: string;
}

export const ProtectModeRadioButtons: React.FC<Props> = ({ form, question }) => (
  <>
    <p className="lead-configuration-protect__question">{question}</p>

    <div className="lead-configuration-protect__radio-btns">
      <FormInputRadioGroup
        name={formFields.PROTECT_MODE}
        form={form}
        collection={[
          {
            label: I18n.t(T.lead.configuration._salessolution_.protect.acisland),
            value: BatteryAccessoryInfo.AC_ISLAND,
            dataTestId: TestId.Lead.ConfigurationCreation.ProtectModeACRadioButton,
            id: uuid(),
          },
          {
            label: I18n.t(T.lead.configuration._salessolution_.protect.backupbuffer),
            value: BatteryAccessoryInfo.BACKUP_BUFFER,
            dataTestId: TestId.Lead.ConfigurationCreation.ProtectModeBufferRadioButton,
            id: uuid(),
          },
        ]}
      />
      <FormFieldError name={formFields.PROTECT_MODE} form={form} />
    </div>
  </>
);
