import React, { useState } from 'react';

import { TableRow } from '@sonnen/shared-web';

import classNames from 'classnames';

import { CustomerRouteParams } from '+app/router';
import { CaseReportHelper } from '+shared/containers';
import { isAnyBatteryOnSiteVisible } from '+shared/store/battery/battery.helpers';
import { CaseActions } from '+shared/store/case';
import { Customer, Site } from '+shared/store/customer';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getStatus } from '+shared/store/query/query.utils';

import { CustomersTableAccessInfo } from '../CustomersTableAccessInfo';
import { CustomersTableSite } from '../CustomersTableSite';
import { CustomersTableWithoutSite } from '../CustomersTableWithoutSite';

import './CustomersTableUser.component.scss';

interface Props {
  customer: Customer;
  isSuperUser: boolean;
  toggleModal: typeof LayoutActions.toggleModal;
  setCaseReportActiveSite: typeof CaseActions.setCaseReportActiveSite;
  contractQueryStatus: ReturnType<typeof getStatus>;
  onSiteClick: (params: CustomerRouteParams) => void;
}

export const CustomersTableUser: React.FC<Props> = ({
  customer,
  toggleModal,
  setCaseReportActiveSite,
  contractQueryStatus,
  isSuperUser,
  onSiteClick,
}) => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const { id, sites, privacyPolicyAccepted, partnerMonitoringAccepted } = customer;
  const consentGiven = privacyPolicyAccepted && partnerMonitoringAccepted;
  const onCustomerSiteClick = (siteId: string) => {
    onSiteClick({ customerId: id, siteId });
  };

  const handleCaseReportButton = (site: Site) => {
    const activeSite = CaseReportHelper.transformSite(site, customer);
    toggleModal(true, ModalId.CASE_REPORT);
    setCaseReportActiveSite(activeSite);
  };

  const onTableRowClick = () => {
    if (consentGiven) return;
    setNotificationVisible(true);
  };

  const filteredSites = React.useMemo(
    () => sites.filter((site) => isAnyBatteryOnSiteVisible(site.batterySystems)),
    [sites]
  );

  return (
    <TableRow className="customers-table-user" onClick={onTableRowClick}>
      {filteredSites.map((site, index) => (
        <div
          key={customer.id + site.id}
          className={classNames('customers-table-user__row', {
            'customers-table-user__row--enabled': consentGiven,
          })}
          onClick={consentGiven || isSuperUser ? () => onCustomerSiteClick(site.id) : undefined}
        >
          <CustomersTableSite
            contractQueryStatus={contractQueryStatus}
            onCaseReportButtonClick={() => handleCaseReportButton(site)}
            key={site.id}
            customer={customer}
            site={site}
            index={index}
            consentGiven={consentGiven}
          />
          <div className="customers-table-user__delimiter" />
        </div>
      ))}
      {!!sites.length && (
        <CustomersTableWithoutSite customer={customer} consentGiven={consentGiven} />
      )}
      {notificationVisible && (
        <CustomersTableAccessInfo hideNotification={() => setNotificationVisible(false)} />
      )}
    </TableRow>
  );
};
