import React from 'react';

type Props = {
  color?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
};

export const Refresh: React.FC<Props> = ({ color, width, height, className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1207 6.77009C15.3598 4.97532 12.8215 4.2287 10.397 4.78756C7.972 5.34655 5.99291 7.13537 5.16041 9.53207C5.06981 9.79292 4.78489 9.93094 4.52404 9.84033C4.26318 9.74972 4.12517 9.46481 4.21578 9.20395C5.15897 6.48855 7.4058 4.45084 10.1724 3.81311C12.937 3.17585 15.8271 4.02752 17.8289 6.06408L20.9737 9.07459V4.98096C20.9737 4.70481 21.1976 4.48096 21.4737 4.48096C21.7499 4.48096 21.9737 4.70481 21.9737 4.98096V10.2454C21.9737 10.5215 21.7499 10.7454 21.4737 10.7454H16.3063C16.0301 10.7454 15.8063 10.5215 15.8063 10.2454C15.8063 9.96926 16.0301 9.7454 16.3063 9.7454H20.2284L17.1318 6.78111L17.1262 6.77568L17.1207 6.77009ZM3.77156 14.2549H7.69383C7.96997 14.2549 8.19383 14.031 8.19383 13.7549C8.19383 13.4787 7.96997 13.2549 7.69383 13.2549H2.52637C2.25022 13.2549 2.02637 13.4787 2.02637 13.7549V19.0193C2.02637 19.2955 2.25022 19.5193 2.52637 19.5193C2.80251 19.5193 3.02637 19.2955 3.02637 19.0193V14.9259L6.17121 17.9364C8.17306 19.9729 11.0631 20.8246 13.8277 20.1874C16.5943 19.5496 18.8411 17.5119 19.7843 14.7965C19.8749 14.5357 19.7369 14.2507 19.4761 14.1601C19.2152 14.0695 18.9303 14.2075 18.8397 14.4684C18.0072 16.8651 16.0281 18.6539 13.6031 19.2129C11.1787 19.7718 8.64027 19.0251 6.87945 17.2304L6.87396 17.2248L6.8683 17.2194L3.77156 14.2549Z"
      fill={color}
    />
  </svg>
);
