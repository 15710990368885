import * as React from 'react';

import { Icon, TableCell, TableStack, useCountryFeature } from '@sonnen/shared-web';

import { CountryFeatureName } from '+config/countryFlags';
import { getPrimaryBatterySystem, getPrimaryContract } from '+customer-list/store/customer.helpers';
import { RoundButton } from '+shared/components';
import { CaseReportButton } from '+shared/components/CaseReportButton/CaseReportButton';
import { Customer, Site } from '+shared/store/customer';
import { getStatus } from '+shared/store/query/query.utils';

import { CustomersTableBattery } from '../CustomersTableBattery';
import { CustomersTableContract } from '../CustomersTableContract';
import { CustomersTableLocation } from '../CustomersTableLocation';
import { CustomersTableName } from '../CustomersTableName';
import { CustomersTableNoData } from '../CustomersTableNoData';
import { CustomersTableNote } from '../CustomersTableNote';

import './CustomersTableSite.component.scss';

interface ICustomersTableSiteProps {
  customer: Customer;
  site: Site;
  index: number;
  consentGiven: boolean;
  contractQueryStatus: ReturnType<typeof getStatus>;
  onCaseReportButtonClick: () => void;
}

export const CustomersTableSite = ({
  customer,
  site,
  index,
  consentGiven,
  contractQueryStatus,
  onCaseReportButtonClick,
}: ICustomersTableSiteProps) => {
  const battery = getPrimaryBatterySystem(site);
  const contract = getPrimaryContract(site);
  const isCustomerContractFeatureEnabled = useCountryFeature(
    CountryFeatureName.CUSTOMER_CONTRACT
  ).isEnabled;

  return (
    <div className={'c-customers-table-site'} data-testid={`c-customers-table-site-${site.id}`}>
      <TableCell className={'c-customers-table-site__table-cell'} stack={TableStack.HORIZONTAL}>
        {index > 0 && <CustomersTableName customer={customer} consentGiven={consentGiven} />}
        <CustomersTableLocation site={site} />
        {!battery ? (
          <CustomersTableNoData />
        ) : (
          <CustomersTableBattery
            key={battery.id}
            battery={battery}
            className={'c-customers-table-site__battery'}
          />
        )}
        {isCustomerContractFeatureEnabled &&
          (!contract ? (
            <CustomersTableNoData />
          ) : (
            <CustomersTableContract
              key={contract.id}
              contract={contract}
              className={'c-customers-table-site__contract'}
            />
          ))}
        <TableCell className={'c-customers-table-site__note'}>
          {index === 0 && consentGiven && (
            <CustomersTableNote
              key="customer-note"
              customerId={customer.id}
              customerNote={customer.installerNote || ''}
            />
          )}
        </TableCell>
        <TableCell className={'c-customers-table-site__case'}>
          <CaseReportButton
            isLoading={contractQueryStatus.pending}
            onClick={onCaseReportButtonClick}
          >
            <RoundButton label={<Icon.Plus className="case-report-button__icon" />} />
          </CaseReportButton>
        </TableCell>
      </TableCell>
    </div>
  );
};
