import * as React from 'react';

import { FormikProps } from 'formik';

import { FormInputMultiselect } from '+shared/components';
import {
  batteryAccessoriesValueArray,
  batteryControllerTypeValueArray,
  contractTypeValueArray,
  NewsFormCP,
  Options,
  tariffTypeValueArray,
} from '+shared/store/firebase/types';

import { FieldName } from './NewsFormCustomer.helpers';

type Props = {
  form: FormikProps<NewsFormCP>;
};

export const NewsFormCustomerOptionsDetails: React.FC<Props> = ({ form }) => {
  const showBatteryDetails = form.values.options.includes(Options.BATTERIES);
  const showContractDetails = form.values.options.includes(Options.CONTRACTS);

  return (
    <>
      {showBatteryDetails && (
        <>
          <FormInputMultiselect
            form={form}
            name={FieldName.OPTIONS_BATTERIES_CONTROLLER_TYPE}
            label={'Battery controller type'}
            collection={batteryControllerTypeValueArray}
            placeholder={'Any controller'}
          />
          <FormInputMultiselect
            form={form}
            name={FieldName.OPTIONS_BATTERIES_ACCESSORIES}
            label={'Battery accessories'}
            collection={batteryAccessoriesValueArray}
            placeholder={'Any accessory'}
          />
        </>
      )}
      {showContractDetails && (
        <>
          <FormInputMultiselect
            form={form}
            name={FieldName.OPTIONS_CONTRACT_TYPE}
            label={'Contract type'}
            collection={contractTypeValueArray}
            placeholder={'Any contract'}
          />
          <FormInputMultiselect
            form={form}
            name={FieldName.OPTIONS_TARIFF_TYPE}
            label={'Tariff type'}
            collection={tariffTypeValueArray}
            placeholder={'Any tariff'}
          />
        </>
      )}
    </>
  );
};
