import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutActions } from './layout.actions';
import { getNotificationQueue } from './layout.selectors';
import type { NotificationVariant } from './types/notification.interface';

export const useLayoutManagement = () => {
  const dispatch = useDispatch();
  const notificationQueue = useSelector(getNotificationQueue);

  const enqueueNotification = useCallback(
    (message: string, variant?: NotificationVariant) => {
      dispatch(LayoutActions.enqueueNotification(message, variant));
    },
    [dispatch]
  );

  const dequeueNotification = useCallback(() => {
    dispatch(LayoutActions.dequeueNotification());
  }, [dispatch]);

  return {
    notificationQueue,

    enqueueNotification,
    dequeueNotification,
  };
};
