import * as React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as classNames from 'classnames';
import ImageResize from 'quill-image-resize-module-react';

import 'react-quill/dist/quill.snow.css';
import './FormInputEditor.component.scss';

Quill.register('modules/ImageResize', ImageResize);

const FontAttributor = Quill.import('attributors/class/font');

FontAttributor.whitelist = ['headline2', 'headline3', 'bodyText', 'bodyTextBold'];
Quill.register(FontAttributor, true);

interface Props {
  className?: string;
  hasNoGap?: boolean;
  label?: string;
  error?: string;
  value: string;
  onChange: (value: any) => void;
}

export const FormInputEditor: React.FC<Props> = ({
  className,
  hasNoGap,
  label,
  error,
  value,
  onChange,
}) => {
  const formats = [
    'align',
    'blockquote',
    'bullet',
    'font',
    'header',
    'image',
    'link',
    'list',
    'video',
    'script',
    'size',
  ];
  const modules = {
    toolbar: '#toolbar-container',
    clipboard: { matchVisual: false },
    ImageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };

  return (
    <div
      className={classNames('c-form-editor-input', className, {
        'c-form-editor-input--no-gap': hasNoGap && error,
      })}
    >
      {label && <label className={'c-form-editor-input__label'}>{label}</label>}
      <React.Suspense fallback={<div>{I18n.t(T.news.channel.sendNews.editor.loading)}</div>}>
        <div id={'toolbar-container'}>
          <span className={'ql-formats'}>
            <select className={'ql-font'}>
              <option value={'headline2'}>
                {I18n.t(T.news.channel.sendNews.editor.font.headline2)}
              </option>
              <option value={'headline3'}>
                {I18n.t(T.news.channel.sendNews.editor.font.headline3)}
              </option>
              <option value={'bodyText'} selected={true}>
                {I18n.t(T.news.channel.sendNews.editor.font.bodyText)}
              </option>
              <option value={'bodyTextBold'}>
                {I18n.t(T.news.channel.sendNews.editor.font.bodyTextBold)}
              </option>
            </select>
          </span>
          <span className={'ql-formats'}>
            <select className={'ql-align'}>
              <option value={''} />
              <option value={'center'} />
            </select>
            <button className={'ql-script'} value={'sub'} />
            <button className={'ql-script'} value={'super'} />
            <button className={'ql-blockquote'} />
            <button className={'ql-list'} value={'ordered'} />
            <button className={'ql-list'} value={'bullet'} />
          </span>
          <span className={'ql-formats'}>
            <button className={'ql-link'} />
            <button className={'ql-image'} />
            <button className={'ql-video'} />
          </span>
          <span className={'ql-formats'}>
            <button className={'ql-clean'} />
          </span>
        </div>
        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          theme={'snow'}
          className={classNames('c-form-editor-input__field', {
            'c-form-editor-input__field--error': error,
          })}
          formats={formats}
        />
      </React.Suspense>
      <div className={'c-form-editor-input__error'}>
        {error && <div className={'c-form-editor-input__error-message'}>{error}</div>}
      </div>
    </div>
  );
};
