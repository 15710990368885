import { SortOrder } from '@coolio/json-api';

export enum CustomerSortKey {
  LAST_NAME = 'last_name',
  INSTALLATION_DATE = 'installation_date',
}

export const CustomerSortOrder: Record<CustomerSortKey, SortOrder> = {
  [CustomerSortKey.LAST_NAME]: SortOrder.ASCENDING,
  [CustomerSortKey.INSTALLATION_DATE]: SortOrder.DESCENDING,
};
