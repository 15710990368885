import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { BatteryModelName } from '+shared/store/lead/types/leadBattery.interface';
import { LeadOfferProduct, OfferProductStatus } from '+shared/store/lead/types/leadOffer.interface';
import { LeadProductAccessory } from '+shared/store/lead/types/leadProductBattery.interface';
import { LeadProductType } from '+shared/store/lead/types/leadProductType.interface';
import { dateUtil } from '+utils/date.util';

import { formFields as formFieldsBattery } from '../../components/LeadConfigurationBattery/LeadConfigurationBattery.helper';
import { formFields as formFieldsConsumption } from '../../components/LeadConfigurationConsumption/LeadConfigurationConsumption.helper';
import { formFields as formFieldsProtect } from '../../components/LeadConfigurationProtect';
import { maxOldestFlatXCommissioningDate } from '../../store/+configuration.helper';
import { NumberField } from '../../store/types';
import {
  formFields as formFieldsPv,
  minCommissioningDate,
} from '../LeadConfigurationPv/LeadConfigurationPv.helper';

export const formFields = {
  ...formFieldsBattery,
  ...formFieldsConsumption,
  ...formFieldsPv,
  ...formFieldsProtect,
};

export enum ConfigurationCreationValidationErrorTitles {
  SPECIFIC_ERROR = 'ConfigurationCannotGenerateSpecific',
  GENERAL_ERROR = 'ConfigurationCannotGenerateGeneral',
}

export const mapLeadErrorTitleToTranslatedError = (title: string, detail: string) => {
  switch (title) {
    case ConfigurationCreationValidationErrorTitles.SPECIFIC_ERROR:
      return detail;
    case ConfigurationCreationValidationErrorTitles.GENERAL_ERROR:
      return I18n.t(T.lead.boc._salessolution_.form.generalValidationError);
    default:
      return I18n.t(T.lead.boc._salessolution_.form.generalValidationError);
  }
};

export const scrolltoError = () => {
  const error = document.getElementById('form-error-banner');
  if (error !== null) {
    error.scrollIntoView(true);
  }
};

export interface ShouldSkipRecommendationProps {
  batteryName?: BatteryModelName;
  commissioningDate?: NumberField;
  isNewBattery?: boolean;
}

export interface BatteriesWithAccessories {
  [key: string]: {
    accessories: LeadProductAccessory[];
    idSelectedAccessory: string;
  };
}

export const isProposalForPostEeg = (commissioningDate: NumberField) =>
  new Date(commissioningDate) <= maxOldestFlatXCommissioningDate();

export const isCommissioningDateAfterMin = (
  commissioningDate: NumberField,
  flatType: LeadProductType | undefined
) =>
  typeof commissioningDate === 'number'
    ? dateUtil.isSameOrAfter(dateUtil.ofMillisSince1970(commissioningDate), minCommissioningDate)
    : dateUtil.isSameOrAfter(dateUtil.of(commissioningDate), minCommissioningDate);

export const shouldSkipRecommendation = ({
  batteryName,
  commissioningDate,
  isNewBattery,
}: ShouldSkipRecommendationProps) =>
  (!!batteryName &&
    [BatteryModelName.SONNENBATTERIE_ECO_6, BatteryModelName.SONNENBATTERIE_ECO_7].includes(
      batteryName
    )) ||
  (!!commissioningDate && isProposalForPostEeg(commissioningDate)) ||
  !isNewBattery;

export const findAcceptedHwProduct = (products: LeadOfferProduct[]) =>
  products.find(
    (product) =>
      product.productType === LeadProductType.BATTERY &&
      [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(product.status)
  );
