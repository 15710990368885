import * as React from 'react';
import { connect } from 'react-redux';

import { Loader } from '@sonnen/shared-web';

import {
  getCaseCollection,
  getCaseCollectionQueryStatus,
} from '+app/shared/store/case/case.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { StoreState } from '+shared/store/store.interface';

import { CaseCard } from '../../components';

type Props = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: StoreState) => ({
  cases: getCaseCollection(state),
  queryStatus: getCaseCollectionQueryStatus(state),
});

const CustomerCasesComponent: React.FC<Props> = ({ cases, queryStatus }) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.CUSTOMER_CASES);

  return (
    <>
      {queryStatus.pending ? (
        <Loader />
      ) : (
        cases.map((item) => <CaseCard key={item.caseNumber} caseItem={item} />)
      )}
    </>
  );
};

export const CustomerCases = connect(mapStateToProps)(CustomerCasesComponent);
