import { ActionsUnion, createAction } from '../utils';
import { Locale } from './i18n.config';

export enum I18N_ACTIONS {
  SET_LOCALE = '[I18n] SET_LOCALE',
}

export const I18nActions = {
  setLocale: createAction(I18N_ACTIONS.SET_LOCALE, (locale: Locale) => ({ locale })),
};

export type I18nActionsType = ActionsUnion<typeof I18nActions>;
