import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web/src/components/PageSubheadline';

import { FormikProps } from 'formik';

import { DsoRegistrationPvSystemExtensionTestIds as testIds } from '+config/testIds';
import { DsoRegistrationPvSystemShared } from '+setupTool/+form/components';
import { DcCoupledBatteryFlag, DsoRegistrationPvSystemInterface } from '+setupTool/+form/store';
import { removeExponentialValues } from '+setupTool/+form/store/+form.helpers';
import {
  DatepickerArrowSide,
  FormInput,
  FormInputDate,
  FormSectionParagraph,
} from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';

import { DsoRegistrationPvInverter } from '../DsoRegistrationInverter';

interface Props<T extends DcCoupledBatteryFlag> {
  form: FormikProps<T>;
}

export const DsoRegistrationPvSystemExtension = <T extends DcCoupledBatteryFlag>({
  form,
}: Props<T>) => {
  const {
    existingPvSystem,
    general,
    dateOfApplication,
    mastrNummer,
    optional,
    pvSystem,
    power,
    count,
    newPvSystem,
    totalPvPlant,
  } = T.setupTool.form;

  return (
    <>
      <PageSubheadline>{I18n.t(existingPvSystem)}</PageSubheadline>

      <div className="c-form-section">
        <FormSectionParagraph>{I18n.t(general)}</FormSectionParagraph>
        <FormInputDate
          className="c-setup-tool-pv-system__item"
          form={form}
          label={I18n.t(dateOfApplication)}
          name="date_of_application"
          minDate={new Date('1900-01-01')}
          arrowPosition={DatepickerArrowSide.BOTTOM}
          dataTestId={testIds.dateOfApplication}
        />

        {/* todo validation optional*/}
        <FormInput
          className="c-setup-tool-pv-system__item"
          form={form}
          label={`${I18n.t(mastrNummer)} / ${I18n.t(optional)}`}
          name="mastr_number"
          dataTestId={testIds.mastrNumber}
        />

        <FormSectionParagraph>{I18n.t(pvSystem)}</FormSectionParagraph>
        <FormFieldObserver<DsoRegistrationPvSystemInterface>
          onChange={() => removeExponentialValues(form, 'existing_pv_size')}
        >
          <FormInput
            className="c-setup-tool-pv-system__item"
            form={form}
            label={I18n.t(power)}
            name="existing_pv_size"
            id="existing_pv_size"
            unit="kWp"
            type="number"
            step={0.001}
            dataTestId={testIds.existingPvSize}
          />
        </FormFieldObserver>

        <DsoRegistrationPvInverter
          form={form}
          vendorFieldName="existing_pv_inverter_manufacturer"
          typeFieldName="existing_pv_inverter_type"
        />

        <FormFieldObserver<DsoRegistrationPvSystemInterface>
          onChange={() => removeExponentialValues(form, 'existing_pv_inverter_count')}
        >
          <FormInput
            className="c-setup-tool-pv-system__item"
            form={form}
            label={I18n.t(count)}
            name="existing_pv_inverter_count"
            id="existing_pv_inverter_count"
            placeholder={I18n.t(T.setupTool.selectPlaceholder)}
            type="number"
            dataTestId={testIds.existingPvInverterCount}
          />
        </FormFieldObserver>
      </div>

      <PageSubheadline>{I18n.t(newPvSystem)}</PageSubheadline>

      <DsoRegistrationPvSystemShared form={form} isNew={false} />

      <PageSubheadline>{I18n.t(totalPvPlant)}</PageSubheadline>

      {/* todo read only field + calculation logic*/}
      <FormInput
        className="c-setup-tool-pv-system__item"
        form={form}
        label={I18n.t(power)}
        name="total_pv_size"
        unit="kWp"
        disabled={true}
        type="number"
        dataTestId={testIds.totalPvSize}
      />
    </>
  );
};
