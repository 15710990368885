import { createSelector, createStructuredSelector } from 'reselect';

import { GET_SETUP_TOOL_SUBMISSION_DATA_QUERY } from '+setupTool/store/+setupTool.dictionary';
import { getStatus } from '+shared/store/query/query.utils';
import { getConfiguration, getVppDocumentation } from '+shared/store/setupTool';
import { StoreState } from '+shared/store/store.interface';

import {
  getDashboardDsoCommissioningConfig,
  getDashboardDsoRegistrationConfig,
  getDashboardPreCommissioningConfig,
  getDashboardPvRegisterConfig,
  getDashboardSetupDataConfig,
  getDashboardVppConfig,
  SetupToolSteps,
} from './+setupToolSteps.selectors';

export const getDashboardStepList = createStructuredSelector<StoreState, SetupToolSteps>({
  setupDataConfig: getDashboardSetupDataConfig,
  dsoRegistrationConfig: getDashboardDsoRegistrationConfig,
  dsoCommissioningConfig: getDashboardDsoCommissioningConfig,
  pvRegisterConfig: getDashboardPvRegisterConfig,
  vppConfig: getDashboardVppConfig,
  preCommissioningConfig: getDashboardPreCommissioningConfig,
});

export const getSubmissionDataQueryStatus = createSelector(getConfiguration, (state) =>
  getStatus(state[GET_SETUP_TOOL_SUBMISSION_DATA_QUERY])
);

export const getSubmissionId = createSelector(getConfiguration, (state) => state.submissionId);

export const getRegistrationSubject = createSelector(
  getConfiguration,
  (state) => state.registrationSubject
);

export const getErrors = createSelector(getConfiguration, (state) => state.errors);

export const getVppFields = createSelector(getVppDocumentation, (state) => state.fields);

export const getAdditionalFeatures = createSelector(
  getConfiguration,
  (state) => state.additionalFeatures
);
