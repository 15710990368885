import { ActionsUnion, createAction } from '+utils/redux';

import { PartnerSite, PartnerSiteMetaData } from './types/partnerSite.interface';
import { PartnerSitesGeoLocation } from './types/partnerSitesCustomer.interface';

export enum SITE_LIST_PAGE_ACTIONS {
  SET_SITES_COLLECTION = '[+Site] SET_SITES_COLLECTION',
  SET_SITES_COLLECTION_META_DATA = '[#Site] SET_SITES_COLLECTION_META_DATA',
  GET_SITES_GEO_LOCATION = '[+Site] GET_SITES_GEOLOCATION',
  SET_SITES_GEO_LOCATION = '[+Site] SET_SITES_GEOLOCATION',
  SET_INSTALLED_BATTERIES_COUNT = '[+Site] SET_INSTALLED_BATTERIES_COUNT',
}

export const SiteListPageActions = {
  setSitesCollection: createAction(
    SITE_LIST_PAGE_ACTIONS.SET_SITES_COLLECTION,
    (siteCollection: PartnerSite[]) => ({ siteCollection })
  ),
  setSitesCollectionMetaData: createAction(
    SITE_LIST_PAGE_ACTIONS.SET_SITES_COLLECTION_META_DATA,
    (siteListMetaData: PartnerSiteMetaData) => ({ siteListMetaData })
  ),
  getSitesGeoLocation: createAction(
    SITE_LIST_PAGE_ACTIONS.GET_SITES_GEO_LOCATION,
    (postcode: string) => ({ postcode })
  ),
  setSitesGeoLocation: createAction(
    SITE_LIST_PAGE_ACTIONS.SET_SITES_GEO_LOCATION,
    (siteGeoLocations: PartnerSitesGeoLocation[]) => ({ siteGeoLocations })
  ),
  setInstalledBatteriesCount: createAction(
    SITE_LIST_PAGE_ACTIONS.SET_INSTALLED_BATTERIES_COUNT,
    (installedBatteries: number) => ({ installedBatteries })
  ),
};

export type SiteListPageActions = ActionsUnion<typeof SiteListPageActions>;
