import React from 'react';

import { Loader } from '@sonnen/shared-web/src/components/Loader';

import classNames from 'classnames';

import './CaseReportButton.scss';

interface Props {
  className?: ClassValue;
  children: React.ReactNode;
  isLoading: boolean;
  onClick: () => void;
}

export const CaseReportButton: React.FC<Props> = ({ className, onClick, children, isLoading }) => {
  const handleClick = (event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    onClick();
  };

  return isLoading ? (
    <Loader className={className} />
  ) : (
    <div onClick={handleClick} className={classNames(className)}>
      {children}
    </div>
  );
};
