import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { isUndefined } from 'lodash/fp';

import { Dropdown } from '../Dropdown';

import './Select.component.scss';

interface Props<T = any> {
  name?: string;
  className?: ClassValue;
  items: T[];
  itemsSelected: T | T[] | undefined;
  itemFactory: (item: T, itemIndex: number, selected: boolean) => string;
  placeholder?: string | React.ReactNode;
  onSelect: (item: T, e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  searchProps?: {
    onInputChange: (v: string) => void;
    inputValue: string;
  };
  handleOnBlur?: (v: string) => void;
  onFocus?: () => void;
  isControlled?: boolean;
  hasBottomGap?: boolean;
  id?: string;
  info?: string;
  noResultsComponent?: React.ReactNode;
  additionalInfoText?: string;
  isSearchIcon?: boolean;
  noResultsText?: string;
  hasError?: boolean;
  handleShow?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
}

export const Select = <T extends any = any>({
  name,
  className,
  items,
  itemsSelected,
  itemFactory,
  onSelect,
  handleOnBlur,
  onFocus,
  placeholder,
  searchProps,
  isControlled = false,
  hasBottomGap = false,
  id,
  info,
  noResultsComponent,
  additionalInfoText,
  isSearchIcon,
  noResultsText,
  hasError = false,
  handleShow,
}: Props<T>) => {
  const normalizedItemsSelected =
    Array.isArray(itemsSelected) || isUndefined(itemsSelected) ? itemsSelected : [itemsSelected];
  const initiallySelectedItems = isControlled
    ? normalizedItemsSelected
    : normalizedItemsSelected || [items[0]];
  const showPlaceholder = !!placeholder && !normalizedItemsSelected?.length;
  const itemsToChoose = showPlaceholder ? normalizedItemsSelected : initiallySelectedItems;

  const handleOnBlurEvent = (event: React.BaseSyntheticEvent) => {
    const { value } = event.target;

    if (handleOnBlur) handleOnBlur(value);
  };

  const searchableTrigger = (toggle: (arg?: boolean) => void) => (
    <div
      className={classNames('select__trigger', {
        'select__trigger--error': hasError,
      })}
    >
      <div className={'select__trigger-content'}>
        {typeof placeholder === 'string' ? (
          <input
            name={name}
            className={'select__input'}
            onChange={(e) => {
              searchProps!.onInputChange(e.target.value);
              e.target.value !== '' ? toggle(true) : toggle(false);
            }}
            value={searchProps!.inputValue}
            onBlur={handleOnBlurEvent}
            onFocus={onFocus}
            placeholder={placeholder}
          />
        ) : (
          <>
            <input
              name={name}
              className={'select__input'}
              onChange={(e) => {
                searchProps!.onInputChange(e.target.value);
                e.target.value !== '' ? toggle(true) : toggle(false);
              }}
              value={searchProps!.inputValue}
            />
            <span className="position-loader-as-placeholder">{placeholder}</span>
          </>
        )}
      </div>
      {!isEmpty(normalizedItemsSelected) ? (
        <div
          className={'select__trigger-icon select__trigger-icon-clear'}
          onClick={() => {
            searchProps!.onInputChange('');
          }}
        />
      ) : isSearchIcon ? (
        <div className={'select__icon-search-wrapper'}>
          <Icon.Search className={'select__icon-search'} />
        </div>
      ) : (
        <div
          className={'select__trigger-icon select__trigger-icon-arrow'}
          onClick={() => toggle()}
        />
      )}
    </div>
  );

  const defaultTrigger = (
    <div
      className={classNames('select__trigger', {
        'select__trigger--multiple-values':
          initiallySelectedItems && initiallySelectedItems.length > 1,
        'select__trigger--error': hasError,
      })}
    >
      <div className={'select__trigger-content'}>
        {showPlaceholder ? (
          <span className={'select__trigger-placeholder'}>{placeholder}</span>
        ) : (
          <span
            className={classNames(
              'select__trigger-items',
              initiallySelectedItems && {
                'select__trigger-items--multiple-values': initiallySelectedItems.length > 1,
              }
            )}
          >
            {initiallySelectedItems &&
              initiallySelectedItems.map((item) => itemFactory(item, 1, true)).join(', ')}
          </span>
        )}
      </div>
      <div className={'select__trigger-icon select__trigger-icon-arrow'} />
    </div>
  );

  const defaultNoResultsComponent = (noValueText: string = 'No results') => {
    return <li className={classNames('select__list-item')}>{noValueText}</li>;
  };

  const defaultAdditionalInfoComponent = (additionalInfoText: string) => {
    return (
      <li className={classNames('select__list-item select__list-item--info')}>
        <Icon.Info className={'select__info-icon'} />
        <span className={'select__info-text'}>{additionalInfoText}</span>
      </li>
    );
  };

  const isItemSelected = (item: T, selectedItems: T[] | undefined): boolean =>
    selectedItems ? selectedItems.includes(item) : false;

  return (
    <Dropdown
      className={classNames('select', className)}
      trigger={searchProps ? ({ toggle }) => searchableTrigger(toggle) : defaultTrigger}
      handleShow={handleShow}
      id={id}
    >
      <ol
        className={classNames('select__list', {
          'select__list--bottom-gap': hasBottomGap,
        })}
      >
        {info && (
          <li id={id ? `${id}-info` : ''} key={`item-info`} className={'select__info'}>
            {info}
          </li>
        )}
        {items.length
          ? items.map((item, index) => (
              <li
                id={id ? `${id}-${index + 1}` : ''}
                key={`item-${index + 1}`}
                className={classNames('select__list-item', {
                  'select__list-item--selected': isItemSelected(item, itemsToChoose),
                })}
              >
                <a onMouseDown={(e) => e.preventDefault()} onClick={(e) => onSelect(item, e)}>
                  {itemFactory(item, index, isItemSelected(item, itemsToChoose))}
                </a>
              </li>
            ))
          : noResultsComponent
          ? noResultsComponent
          : defaultNoResultsComponent(noResultsText)}
        {additionalInfoText ? defaultAdditionalInfoComponent(additionalInfoText) : null}
      </ol>
    </Dropdown>
  );
};
