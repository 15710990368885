import { push } from 'connected-react-router';
import { isEmpty } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, iif, merge, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { getRouterLocationPathFirstSegment } from '+app/router/store/router.selectors';
import { makeQuery, mapPathToParams, mapToState, ofType, processQuery } from '+app/utils';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { OfferProductStatus } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { StoreState } from '+shared/store/store.interface';

import { LEAD_IN_SETUP_STAGE, PATHS, ROUTES, SETUP_TAB_ROUTE_NAME } from '../../../router';
import { RouterActions } from '../../../router/store';
import { LeadOfferPageActions } from './+offer.actions';
import { getLeadOfferData } from './+offer.selectors';
import {
  GET_ENERGY_PROVIDER_QUERY,
  GET_OFFER_QUERY,
  GET_UTILITY_DATA_QUERY,
  PATCH_UTILITY_DATA_QUERY,
  SEND_OFFER_QUERY,
} from './+offer.state';

type Action$ = ActionsObservable<LeadOfferPageActions>;
type State$ = StateObservable<StoreState>;

const getOfferDetails$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    mapToState(state$),
    filter((state) => getLeadOfferData(state) === undefined),
    mapPathToParams(
      ROUTES.LEAD_OFFER[0],
      ROUTES.SETUP_LEAD_OFFER[0],
      ROUTES.LEAD_IMPACT_ANALYSIS[0],
      ROUTES.SETUP_LEAD_IMPACT_ANALYSIS[0]
    ),
    filter((params) => !isEmpty(params)),
    map(([leadId, offerId]) => LeadOfferPageActions.getOffer(leadId, offerId))
  );

const sendOffer$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadOfferPageActions.sendOffer),
    mergeMap(({ leadId, offerId, productId }) =>
      of(offerId).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            processQuery(
              SEND_OFFER_QUERY,
              () =>
                LeadRepository.patchLeadOfferProduct(
                  leadId,
                  offerId,
                  productId,
                  OfferProductStatus.SENT
                ),
              {
                onSuccess: () =>
                  concat(
                    iif(
                      () => getRouterLocationPathFirstSegment(state) === SETUP_TAB_ROUTE_NAME,
                      of(push(PATHS.LEAD_CONFIGURATION({ leadId }, LEAD_IN_SETUP_STAGE))),
                      of(push(PATHS.LEAD_CONFIGURATION({ leadId })))
                    ),
                    of(LeadPageActions.saveAsyncLeadStatus(LeadStatusName.FLAT_OFFER_SENT)),
                    of(LeadOverviewPageActions.saveAsyncOfferSentId(offerId))
                  ),
              }
            )
          )
        )
      )
    )
  );

const getOffer$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadOfferPageActions.getOffer),
    mergeMap(({ leadId, offerId }) =>
      makeQuery(GET_OFFER_QUERY)({
        call: () => LeadRepository.getLeadOffer(leadId, offerId),
        onSuccess: (res) => merge(of(LeadOfferPageActions.setOffer(leadId, res!.element))),
      })
    )
  );

const getEnergyProviders$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadOfferPageActions.getEnergyProviders),
    mergeMap(({ searchQuery }) =>
      of(searchQuery).pipe(
        processQuery(
          GET_ENERGY_PROVIDER_QUERY,
          () => LeadRepository.getEnergyProviders(searchQuery),
          { onSuccess: (res) => of(LeadOfferPageActions.setEnergyProviders(res!.elements)) }
        )
      )
    )
  );

const getUtilityData$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadOfferPageActions.getUtilityData),
    mergeMap(({ leadId }) =>
      of(leadId).pipe(
        processQuery(GET_UTILITY_DATA_QUERY, () => LeadRepository.getUtilityData(leadId), {
          onSuccess: (res) => of(LeadOfferPageActions.setUtilityData(res!.element)),
        })
      )
    )
  );

const patchUtilityData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(LeadOfferPageActions.patchUtilityData),
    mergeMap(({ oldProvider, meterId }) =>
      of({}).pipe(
        mapToState(state$),
        mapPathToParams(ROUTES.LEAD_OFFER[0], ROUTES.SETUP_LEAD_OFFER[0]),
        mergeMap(([leadId]) =>
          of({}).pipe(
            processQuery(
              PATCH_UTILITY_DATA_QUERY,
              () => LeadRepository.patchUtilityChange({ oldProvider, meterId, leadId }),
              { onSuccess: (res) => of(LeadOfferPageActions.patchUtilityDataSuccess()) }
            )
          )
        )
      )
    )
  );

export const epics = combineEpics(
  getOfferDetails$,
  getOffer$,
  sendOffer$,
  getEnergyProviders$,
  getUtilityData$,
  patchUtilityData$
);
