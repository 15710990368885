import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { CurrencyUnit } from '+app/App.constants';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { formatCurrency } from '+utils/format.util.old';

import { CostRow } from '../LeadImpactAnalysisCalculationResults.helper';
import { PageDetailedCalculation20YearsData } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

const TaxOnSelfConsumptionAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  const formatedExcessTariffNetPerKwh = formatCurrency(
    100 * data.excessTariffNetPerKwh,
    CurrencyUnit.CURRENCY_EUR,
    {
      isMinorUnit: true,
      precision: 1,
    }
  );

  return (
    <div
      className={`c-impact-analysis-results
      c-impact-analysis-results-box-dark-green`}
    >
      <CostRow
        sign={'-'}
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.taxation.title)}
        textType={'labelLarge'}
        value={Math.abs(data.sonnenFlatVatToBePaid.cumulative)}
      />
      <LineDivider className="c-impact-analysis-results__line-divider" />
      <CostRow
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.taxation.info, {
          value: formatedExcessTariffNetPerKwh,
        })}
        textType={'bodyMedium'}
      />
    </div>
  );
};

export default TaxOnSelfConsumptionAfter20Years;
