import { routerMiddleware } from 'connected-react-router';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { persistStore } from 'redux-persist';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { initializeI18n } from '../../i18n/i18n.initializer';
import { history } from '../../router/store';
import { dependencies } from '../../utils';
import { AuthActions } from './auth';
import { StoreState } from './store.interface';
import { storeProvider } from './store.provider';
import { initialState, rootEpic, rootReducer } from './store.root';

export const configureStore = () => {
  const epicMiddleware = createEpicMiddleware({ dependencies });
  const composeEnhancers =
    process.env.REACT_APP_CONFIG === 'development'
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const store = createStore<StoreState, AnyAction, any, any>(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware))
  );

  const bootstrappedAction = AuthActions.isRehydrated();
  const persistor = persistStore(store, {}, () => store.dispatch(bootstrappedAction));

  initializeI18n(store);

  const epic$ = new BehaviorSubject(rootEpic);
  const hotReloadingEpic: Epic = (...args: any[]) => epic$.pipe(switchMap((epic) => epic(...args)));
  epicMiddleware.run(hotReloadingEpic);

  if (process.env.NODE_ENV === 'development' && (module as any).hot) {
    // Enable Webpack hot module replacement for reducers & epics
    (module as any).hot.accept('./store.root.tsx', () => {
      const { rootEpic: nextRootEpic, rootReducer: nextRootReducer } = require('./store.root.tsx');
      store.replaceReducer(nextRootReducer);
      epic$.next(nextRootEpic);
    });
  }

  return { store, persistor };
};

export const { store, persistor } = configureStore();

storeProvider.initialize(store);
