import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DS, FormCheckbox, Icon } from '@sonnen/shared-web';

import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';

import { getBattery, getPvGridFeedInLimit } from '+app/+customer/+battery/store';
import { WidgetAccordion } from '+shared/components';
import { Button, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { StoreState } from '+shared/store/store.interface';
import { formatPercentage } from '+utils/format.util';
import { useLocaleContext } from '+utils/react/locale.provider';
import { mapActions } from '+utils/redux';

import { getIsBatteryPvGridFeedInLimitInProgress } from '../../store';
import { PvSystemsActions } from '../../store/pvSystems.actions';

import './PvFeedInOperation.scss';

const mapStateToProps = (state: StoreState) => ({
  pvGridFeedInLimit: getPvGridFeedInLimit(state),
  battery: getBattery(state),
  isBatteryPvGridFeedInLimitInProgress: getIsBatteryPvGridFeedInLimitInProgress(state),
});

const mapDispatchToProps = mapActions({
  setBatteryPvGridFeedInLimit: PvSystemsActions.setBatteryPvGridFeedInLimit,
  setIsBatteryPvGridFeedInLimitInProgress: PvSystemsActions.setIsBatteryPvGridFeedInLimitInProgress,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const PvFeedInOperationComponent: React.FC<Props> = ({
  pvGridFeedInLimit,
  battery,
  isBatteryPvGridFeedInLimitInProgress,
  actions,
}) => {
  // translations
  const {
    title,
    label,
    confirmationText,
    informationText,
    informationTextLink,
    cancel,
    save,
    valueUnknown,
  } = T.customerSingle.pvSystemsAndMeters.pvSystemOperations.pvFeedInOperation;
  const { hundertWarning, zeroWarning } =
    T.customerSingle.pvSystemsAndMeters.pvSystemOperations.pvFeedInOperation.validationWarning;

  const { pvSystemSetFeedInLimitInformation } = useFlags();
  const { locale } = useLocaleContext();
  const isAccordionDisabled: boolean = isBatteryPvGridFeedInLimitInProgress;
  const pvFeedInLimitNotFormatted: string = !isNil(pvGridFeedInLimit)
    ? String(pvGridFeedInLimit)
    : I18n.t(valueUnknown);
  const pvFeedInLimitFormatted: string = !isNil(pvGridFeedInLimit)
    ? formatPercentage({ locale })(pvGridFeedInLimit)
    : I18n.t(valueUnknown);

  // state
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
  const [isInputFieldInEditMode, setIsInputFieldInEditMode] = useState<boolean>(false);
  const [isConfirmationChecked, setIsConfirmationChecked] = useState<boolean>(false);
  const [pvGridFeedInLimitInput, setPvGridFeedInLimitInput] = useState<string>(
    String(pvGridFeedInLimit)
  );
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [isErrorValidation, setIsErrorValidation] = useState<boolean>(false);

  // effects
  useEffect(() => {
    isInputFieldInEditMode
      ? setPvGridFeedInLimitInput(pvFeedInLimitNotFormatted)
      : setPvGridFeedInLimitInput(pvFeedInLimitFormatted);
  }, [isInputFieldInEditMode, pvFeedInLimitFormatted, pvFeedInLimitNotFormatted]);

  // helper functions
  const regexp: RegExp = new RegExp('^(100|[1-9]?[0-9])$');
  const isValidInput = (input: string): boolean => regexp.test(input);
  const isSubmitButtonDisabled = !isConfirmationChecked || !isValidInput(pvGridFeedInLimitInput);

  const getWarningMessage = (inputToValidate: string): string => {
    if (inputToValidate === '0') {
      setIsErrorValidation(true);
      return I18n.t(zeroWarning);
    }
    if (inputToValidate === '100') {
      setIsErrorValidation(false);
      return I18n.t(hundertWarning);
    }
    setIsErrorValidation(false);
    return '';
  };

  // handler functions
  const handleClickOnHeader = (): void => {
    setIsAccordionOpen(!isAccordionOpen);
    setIsConfirmationChecked(false);
  };

  const handleClickOnEditPen = (): void => {
    setIsInputFieldInEditMode(true);
  };

  const handleClickOnCancel = (): void => {
    setIsInputFieldInEditMode(false);
    setIsConfirmationChecked(false);
    setValidationMessage('');
    setIsErrorValidation(false);
  };

  const handleClickOnConfirmation = (): void => {
    setIsConfirmationChecked(!isConfirmationChecked);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;

    if (!input.length) {
      setPvGridFeedInLimitInput('');
    }
    if (isValidInput(input)) {
      setPvGridFeedInLimitInput(input);
      setValidationMessage(getWarningMessage(input));
    }
  };

  const handleOnSubmit = (): void => {
    const isInputDifferentThanCurrent = String(pvGridFeedInLimit) !== pvGridFeedInLimitInput;

    if (isInputDifferentThanCurrent) {
      actions.setIsBatteryPvGridFeedInLimitInProgress(true);
      actions.setBatteryPvGridFeedInLimit({
        pvGridFeedInLimit: pvGridFeedInLimitInput,
        batteryId: battery?.id,
      });
      setIsAccordionOpen(false);
      setIsConfirmationChecked(false);
      setIsInputFieldInEditMode(false);
      setValidationMessage('');
      setIsErrorValidation(false);
    }
  };

  return (
    <WidgetAccordion
      className={'c-battery-backup-buffer-list-item'}
      title={I18n.t(title)}
      isOpen={isAccordionOpen}
      onHeaderClick={handleClickOnHeader}
      isDisabled={isAccordionDisabled}
    >
      {pvSystemSetFeedInLimitInformation && (
        <div className="pv-feed-in-limit__information">
          <DS.Alert
            theme={DS.AlertTheme.INFO}
            text={
              <span
                className="pv-feed-in-limit__information-text"
                dangerouslySetInnerHTML={{
                  __html:
                    I18n.t(informationText, {
                      link: I18n.t(informationTextLink),
                    }) || '',
                }}
              />
            }
          />
        </div>
      )}

      <label className="pv-feed-in-limit__label">{I18n.t(label)}</label>

      <div className="pv-feed-in-limit">
        <div className="pv-feed-in-limit__input">
          <input
            name="pvFeedInLimit"
            type="text"
            autoComplete="off"
            disabled={!isInputFieldInEditMode}
            value={pvGridFeedInLimitInput}
            onChange={handleOnChange}
            className={classNames('pv-feed-in-limit__input-field', {
              edit: isInputFieldInEditMode,
              error: isErrorValidation,
            })}
          />
          {isInputFieldInEditMode && <label className={`pv-feed-in-limit__input-symbol`}>%</label>}
          {validationMessage && (
            <span
              className={`pv-feed-in-limit__input-validation ${
                isErrorValidation ? 'error-message' : ''
              }`}
            >
              {validationMessage}
            </span>
          )}
        </div>
        {!isInputFieldInEditMode && (
          <span onClick={handleClickOnEditPen}>
            <Icon.Pen className={'c-detail-list-table__edit-icon'} />
          </span>
        )}
      </div>

      {isInputFieldInEditMode && (
        <>
          <div className="pv-feed-in-limit__confirmation">
            <FormCheckbox
              name="pv-feed-in-limit-confirmation"
              checked={isConfirmationChecked}
              onClick={handleClickOnConfirmation}
              label={
                <label className="pv-feed-in-limit__confirmation-label">
                  {I18n.t(confirmationText)}
                </label>
              }
            />
          </div>
          <span className="pv-feed-in-limit__buttons">
            <Button
              label={I18n.t(cancel)}
              type={ButtonType.TERTIARY}
              onClick={handleClickOnCancel}
            />
            <Button
              label={I18n.t(save)}
              type={ButtonType.PRIMARY}
              disabled={isSubmitButtonDisabled}
              status={isSubmitButtonDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              mainType={MainType.SUBMIT}
              onClick={handleOnSubmit}
            />
          </span>
        </>
      )}
    </WidgetAccordion>
  );
};

export const PvFeedInOperation = connect(
  mapStateToProps,
  mapDispatchToProps
)(PvFeedInOperationComponent);
