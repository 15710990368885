import { OAuth2TokenResponse } from '@coolio/auth-interceptor';
import { ContentType } from '@coolio/http';
import { from } from 'rxjs';

import { Config } from '+config/config';
import { jsonApiClient } from '+shared/network/setupToolsNetwork.client';

import { authlessHttpClient } from '../../network/network.client';
import { getRedirectUri } from './auth.helper';
import { TokenType } from './auth.state';
import { ReverseChannelTokenData } from './types/reverseChannel.interface';

const getToken = ({ code, verifier }: { code: string; verifier?: string }) =>
  from(
    authlessHttpClient
      .post<OAuth2TokenResponse>(`${Config.AUTH.AUTH_URL}/oauth/token`, {
        body: {
          grant_type: 'authorization_code',
          code,
          client_id: Config.AUTH.CLIENT_ID,
          client_secret: Config.AUTH.CLIENT_SECRET,
          redirect_uri: getRedirectUri(),
          code_verifier: verifier,
        },
        headers: { 'Content-Type': ContentType.URL_ENCODED },
      })
      .then((res) => res.parsedBody())
  );

const revokeToken = (token?: string) =>
  from(
    authlessHttpClient.post<{}>(`${Config.AUTH.AUTH_URL}/oauth/revoke`, {
      body: {
        token,
        client_id: Config.AUTH.CLIENT_ID,
      },
      headers: { 'Content-Type': ContentType.URL_ENCODED },
    })
  );

const verifyToken = (type: TokenType, token?: string) =>
  from(
    authlessHttpClient.get<{}>(`${Config.API_URL}/token/${type}/?token=${token}`, {
      headers: { 'Content-Type': ContentType.JSON },
    })
  );

const getReverseChannelToken = (id: string | undefined) =>
  from(
    jsonApiClient
      .get<ReverseChannelTokenData>(`${Config.API_URL}/battery-systems/${id}/generate-token`)
      .expectOne()
      .send()
  );

export const AuthRepository = {
  getToken,
  revokeToken,
  verifyToken,
  getReverseChannelToken,
};
