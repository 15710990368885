import { createSelector } from 'reselect';

import { getCustomerOverviewPageState } from '+app/+customer/store/+customer.selectors';
import { getStatus } from '+shared/store/query/query.utils';
import { getSiteLiveStateV2 } from '+shared/store/site/site.selectors';
import { Device, DeviceType, Heatpump } from '+shared/store/site/types/siteLiveStateV2.interface';

import { GET_CHARGERS_QUERY, GET_OVERVIEW_SITE_LIVE_STATE_QUERY } from './+overview.state';

export const getCharger = createSelector(
  getCustomerOverviewPageState,
  (overviewPageState) => overviewPageState.charger
);

export const getChargerId = createSelector(getCharger, (chargerState) => chargerState?.id);

export const getChargersQueryStatus = createSelector(
  getCustomerOverviewPageState,
  (overviewPageState) => getStatus(overviewPageState[GET_CHARGERS_QUERY])
);

export const getOverviewSiteLiveState = createSelector(
  getCustomerOverviewPageState,
  (overviewPageState) => overviewPageState.overviewLiveState
);

export const getOverviewSiteLiveStateQueryStatus = createSelector(
  getCustomerOverviewPageState,
  (overviewPageState) => getStatus(overviewPageState[GET_OVERVIEW_SITE_LIVE_STATE_QUERY])
);

export const hasSiteHeatpump = createSelector(getSiteLiveStateV2, (siteLiveStateV2) =>
  Boolean(siteLiveStateV2?.devices.find((item: Device) => item.deviceType === DeviceType.HEATPUMP))
);

export const getHeatPumpLiveState = createSelector(
  getSiteLiveStateV2,
  (siteLiveStateV2) =>
    siteLiveStateV2?.devices.filter(
      (item: Device) => item.deviceType === DeviceType.HEATPUMP
    )[0] as Heatpump
);
