import { createSelector } from 'reselect';

import { StoreState } from '../store.interface';

export const getLayoutState = (store: StoreState) => store.shared.layout;

export const isNavOpened = createSelector(getLayoutState, ({ isNavOpened }) => isNavOpened);

export const isModalOpen = createSelector(getLayoutState, (state) => state.isModalOpen);

export const getOpenModalId = createSelector(getLayoutState, (state) => state.openModalId);

export const isLanguageLoading = createSelector(
  getLayoutState,
  ({ isLanguageLoading }) => isLanguageLoading
);

export const getNotificationQueue = createSelector(
  getLayoutState,
  (state) => state.notificationQueue
);
