import { Action, AnyAction, Reducer } from 'redux';

import { AUTH_ACTIONS } from '../../shared/store/auth/auth.actions';

export const createReducer =
  <S extends {}, A extends Action = AnyAction>(reducer: Reducer<S, A>) =>
  (state: S | undefined, action: A): S =>
    action.type === AUTH_ACTIONS.CLEAR_STORAGE
      ? reducer(undefined, { ...(action as object), snapshot: state } as any)
      : reducer(state, action);
