import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph, DS } from '@sonnen/shared-web';

import { useFormikContext } from 'formik';

import { SignalUnit } from '+app/App.constants';
import { SignalMeasurementsTestIds as testIds } from '+config/testIds';
import {
  DsoRegistrationFormContainer,
  DsoRegistrationFormRadioGroup,
} from '+setupTool/+form/components';
import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { useSubmissionFileUploadManagement } from '+setupTool/+form/store/+form.hooks';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store';
import { isGsmMeasurementsFieldsVisible } from '+setupTool/+vppDocumentation/store/+vppDocumentation.helpers';
import {
  FormInput,
  FormInputRadioGroup,
  FormSectionParagraph,
  FormUploadInput,
} from '+shared/components';

import { VppDocumentationTooltip } from '../VppDocumentationTooltip';

import './SignalMeasurements.component.scss';

interface SignalMeasurementsProps {
  isWiringDiagramVisible: boolean;
  isGsmMeasurementsRequiredFieldsVisible: boolean;
  isGsmMeasurementsOptionalFieldsVisible: boolean;
  isSignalMeasurementsPictureLoading: boolean;
  isLanConnectionPictureLoading: boolean;
  isWiringDiagramDocumentLoading: boolean;
}

export const SignalMeasurements: React.VFC<SignalMeasurementsProps> = ({
  isWiringDiagramVisible,
  isGsmMeasurementsRequiredFieldsVisible,
  isGsmMeasurementsOptionalFieldsVisible,
  isSignalMeasurementsPictureLoading,
  isLanConnectionPictureLoading,
  isWiringDiagramDocumentLoading,
}) => {
  const form = useFormikContext<VppDocumentationInterface>();
  const { fileUploadSettings, vppUploadedDocuments, uploadSubmissionFile, removeSubmissionFile } =
    useSubmissionFileUploadManagement();

  const isLanConnectionAvailable = Boolean(form.values.lan_connection_available);
  const signalMeasurementsPicturesSettings =
    fileUploadSettings[FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE];
  const lanConnectionPicturesSettings = fileUploadSettings[FileCategoryName.LAN_CONNECTION_PICTURE];
  const wiringDiagramVppSettings = fileUploadSettings[FileCategoryName.WIRING_DIAGRAM_VPP];

  const handleUploadFiles =
    (fileCategoryName: FileCategoryName) =>
    (files: File[]): void => {
      uploadSubmissionFile(files, fileCategoryName);
    };

  const handleDeleteFiles =
    (fileCategoryName: FileCategoryName) =>
    (fileId?: string): void => {
      const document = vppUploadedDocuments[fileCategoryName];

      if (document && fileId) {
        removeSubmissionFile(fileId);
      }
    };

  const isSignalStrengthErrorDisplayed = (): boolean => {
    if (
      form.values.signal_strength_dbm !== undefined &&
      typeof form.values.signal_strength_dbm !== 'string'
    ) {
      return form.values.signal_strength_dbm < -100 || form.values.signal_strength_dbm === 0;
    } else {
      return (
        form.values.download_speed_mbits !== undefined &&
        typeof form.values.download_speed_mbits !== 'string' &&
        form.values.download_speed_mbits < 5
      );
    }
  };

  return (
    <>
      {isGsmMeasurementsFieldsVisible(
        isGsmMeasurementsRequiredFieldsVisible,
        isGsmMeasurementsOptionalFieldsVisible
      ) && (
        <>
          <FormSectionParagraph>
            {I18n.t(T.setupTool.vppDocumentation.form.signalMeasurementsHeader)}
          </FormSectionParagraph>

          <div className={'c-signal-measurements__alert-container'}>
            <DS.Alert
              theme={DS.AlertTheme.WARNING}
              text={I18n.t(T.setupTool.vppDocumentation.form.signalMeasurementsWarning)}
            />
          </div>

          {isSignalStrengthErrorDisplayed() && (
            <div className={'c-signal-measurements__alert-container'}>
              <DS.Alert
                theme={DS.AlertTheme.DANGER}
                text={I18n.t(T.setupTool.vppDocumentation.form.warning.signalStrengthInsufficient)}
              />
            </div>
          )}

          <DsoRegistrationFormContainer className={'c-dso-registration-container'}>
            <FormInput
              className={'c-dso-commissioning-data__item'}
              form={form}
              name={'upload_speed_mbits'}
              label={I18n.t(T.setupTool.form.uploadSpeed)}
              unit={SignalUnit.MBIT_PER_SEC}
              type={'number'}
              dataTestId={testIds.uploadSpeedField}
            />
            <FormInput
              className={'c-dso-commissioning-data__item'}
              form={form}
              name={'download_speed_mbits'}
              label={I18n.t(T.setupTool.form.downloadSpeed)}
              unit={SignalUnit.MBIT_PER_SEC}
              type={'number'}
              dataTestId={testIds.downloadSpeedField}
            />

            <FormInput
              className={'c-dso-commissioning-data__item'}
              form={form}
              name={'ping_ms'}
              label={I18n.t(T.setupTool.form.ping)}
              unit={SignalUnit.MS}
              type={'number'}
              dataTestId={testIds.pingField}
            />
            <FormInput
              className={'c-dso-commissioning-data__item'}
              form={form}
              name={'signal_strength_dbm'}
              label={I18n.t(T.setupTool.form.signalStrength)}
              unit={SignalUnit.DBM}
              type={'number'}
              dataTestId={testIds.signalField}
            />
          </DsoRegistrationFormContainer>

          <DsoRegistrationFormContainer className={'c-dso-registration-container--with-tooltip'}>
            <BoldParagraph
              className={
                'c-setup-tool__upload-input-label c-setup-tool__upload-input-label--picture-format'
              }
            >
              {I18n.t(T.setupTool.form.signalMeasurementsPictures)}
            </BoldParagraph>
            <VppDocumentationTooltip
              tooltipMessage={I18n.t(T.setupTool.form.signalMeasurementsPicturesTooltip)}
            />
            <FormUploadInput
              form={form}
              name={FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE}
              maxNumFiles={signalMeasurementsPicturesSettings.maxUploadCount}
              accept={signalMeasurementsPicturesSettings.mimeType}
              maxSize={signalMeasurementsPicturesSettings.maxFileSize}
              maxSizeLabel={signalMeasurementsPicturesSettings.maxFileSizeLabel}
              label={I18n.t(T.setupTool.info.chooseFile)}
              placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                extension: signalMeasurementsPicturesSettings.extensions,
                maxFileSize: signalMeasurementsPicturesSettings.maxFileSizeLabel,
              })}
              isLoading={isSignalMeasurementsPictureLoading}
              isImmediatelySentToBackend={true}
              showErrorOnlyIfTouched={true}
              onUpload={handleUploadFiles(FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE)}
              onReset={handleDeleteFiles(FileCategoryName.SIGNAL_MEASUREMENSTS_PICTURE)}
              dataTestId={testIds.measurementsPictureUploadButton}
            />
          </DsoRegistrationFormContainer>

          <FormSectionParagraph
            className={
              'c-form-section-paragraph--without-extra-margin c-form-section-paragraph--smaller'
            }
          >
            {I18n.t(T.setupTool.form.lanConnectionAvailable)}
          </FormSectionParagraph>
          <DsoRegistrationFormRadioGroup>
            <FormInputRadioGroup
              form={form}
              name={'lan_connection_available'}
              collection={[
                {
                  label: I18n.t(T.setupTool.common.yes),
                  value: true,
                  dataTestId: testIds.yesLanConnectionAvailableRadioButton,
                },
                {
                  label: I18n.t(T.setupTool.common.no),
                  value: false,
                  dataTestId: testIds.noLanConnectionAvailableRadioButton,
                },
              ]}
            />
          </DsoRegistrationFormRadioGroup>

          <DsoRegistrationFormContainer className={'c-dso-registration-container--with-tooltip'}>
            {isLanConnectionAvailable ? (
              <>
                <BoldParagraph
                  className={
                    'c-setup-tool__upload-input-label' +
                    'c-setup-tool__upload-input-label--picture-format'
                  }
                >
                  {I18n.t(T.setupTool.form.lanConnectionPictures)}
                </BoldParagraph>
                <VppDocumentationTooltip
                  tooltipMessage={I18n.t(T.setupTool.form.lanConnectionPicturesTooltip)}
                />
                <FormUploadInput
                  form={form}
                  name={FileCategoryName.LAN_CONNECTION_PICTURE}
                  maxNumFiles={lanConnectionPicturesSettings.maxUploadCount}
                  accept={lanConnectionPicturesSettings.mimeType}
                  maxSize={lanConnectionPicturesSettings.maxFileSize}
                  maxSizeLabel={lanConnectionPicturesSettings.maxFileSizeLabel}
                  label={I18n.t(T.setupTool.info.chooseFile)}
                  placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                    extension: lanConnectionPicturesSettings.extensions,
                    maxFileSize: lanConnectionPicturesSettings.maxFileSizeLabel,
                  })}
                  isImmediatelySentToBackend={true}
                  showErrorOnlyIfTouched={true}
                  onUpload={handleUploadFiles(FileCategoryName.LAN_CONNECTION_PICTURE)}
                  isLoading={isLanConnectionPictureLoading}
                  onReset={handleDeleteFiles(FileCategoryName.LAN_CONNECTION_PICTURE)}
                />
              </>
            ) : (
              <div className={'c-signal-measurements__alert-container'}>
                <DS.Alert
                  theme={DS.AlertTheme.WARNING}
                  text={I18n.t(T.setupTool.vppDocumentation.form.noLanConnectionWarning)}
                />
              </div>
            )}
          </DsoRegistrationFormContainer>
        </>
      )}

      <DsoRegistrationFormContainer className={'c-dso-registration-container--with-tooltip'}>
        {isWiringDiagramVisible && (
          <>
            <BoldParagraph className={'c-setup-tool__upload-input-label'}>
              {I18n.t(T.setupTool.form.wiringDiagram)}
            </BoldParagraph>
            <VppDocumentationTooltip
              tooltipMessage={I18n.t(T.setupTool.form.wiringDiagramTooltip)}
            />
            <FormUploadInput
              form={form}
              name={FileCategoryName.WIRING_DIAGRAM_VPP}
              maxNumFiles={wiringDiagramVppSettings.maxUploadCount}
              accept={wiringDiagramVppSettings.mimeType}
              maxSize={wiringDiagramVppSettings.maxFileSize}
              maxSizeLabel={wiringDiagramVppSettings.maxFileSizeLabel}
              label={I18n.t(T.setupTool.info.chooseFile)}
              placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                extension: wiringDiagramVppSettings.extensions,
                maxFileSize: wiringDiagramVppSettings.maxFileSizeLabel,
              })}
              isImmediatelySentToBackend={true}
              showErrorOnlyIfTouched={true}
              onUpload={handleUploadFiles(FileCategoryName.WIRING_DIAGRAM_VPP)}
              isLoading={isWiringDiagramDocumentLoading}
              onReset={handleDeleteFiles(FileCategoryName.WIRING_DIAGRAM_VPP)}
              dataTestId={testIds.wiringDiagramUploadButton}
            />
          </>
        )}
      </DsoRegistrationFormContainer>
    </>
  );
};
