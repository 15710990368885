import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Config } from '+config/config';
import { ExternalLink } from '+shared/basicComponents/Icons/ExternalLink';
import useWindowSize from '+shared/hooks/useWindowSize';

import './ToolboxButton.scss';

interface Props {
  impersonateUserId?: string;
}

const { switchToToolbox } = T.general.navigation;

const ToolboxButton = ({ impersonateUserId }: Props) => {
  const desktop = useWindowSize().width >= 1200;
  const iconSize = desktop ? 20 : 16;
  const fontSize = desktop ? '16px' : '14px';
  const padding = desktop ? '14px 30px' : '8px 20px';

  const onClick = () => {
    if (impersonateUserId) {
      window.open(
        `${Config.AUTH.AUTH_URL}/impersonate?user_id=${impersonateUserId}&application=partner_toolbox`
      );
    } else {
      window.location.assign(Config.PARTNER_TOOLBOX_URL);
    }
  };

  return (
    <button className="toolbox-button" style={{ padding }} onClick={onClick}>
      <span className="label" style={{ fontSize }}>
        {I18n.t(switchToToolbox)}
      </span>
      <ExternalLink height={iconSize} width={iconSize} color="#0A1535" />
    </button>
  );
};

export default ToolboxButton;
