import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import './CaseReportSearchNoResults.component.scss';

export const CaseReportSearchNoResults: React.FC = () => (
  <div className={'c-case-report-search-no-results'}>
    <div className={'c-case-report-search-no-results__title'}>{I18n.t(T.report.noResults)}</div>
    <div className={'c-case-report-search-no-results__description'}>
      {I18n.t(T.report.noResultsDescription)}
    </div>
  </div>
);
