import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, of } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { CustomerListPageActions } from '+app/+customer/+list/store';
import { dataGuard, makeQuery, mapToState, matchPath, ofType, xorDecrypt } from '+app/utils';
import { ROUTES, SiteListRouteQueryParams } from '+router/routes';
import { RouterActions } from '+router/store';
import { getRouteQueryParams } from '+router/store/router.selectors';
import { getAllowedPageSize } from '+shared/components/Pagination/PaginationConfiguration.interface';
import { AuthActions } from '+shared/store/auth';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';

import { SiteListPageActions } from './siteList.actions';
import { SiteListRepository } from './siteList.repository';
import {
  GET_PARTNER_SITES_SUMMARY,
  GET_SITES_GEO_LOCATION_QUERY,
  GET_SITES_QUERY,
} from './siteList.state';
import { PartnerSummaryResponse } from './types/partnerSitesSummary.interface';

// Remove Customer List Page actions
type Action$ = ActionsObservable<AuthActions | SiteListPageActions | CustomerListPageActions>;
type State$ = StateObservable<StoreState>;

export const getSitesCollection$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    debounceTime(250),
    mapToState(state$),
    mergeMap((state) =>
      forkJoin([
        of(state).pipe(
          matchPath(ROUTES.CUSTOMERS),
          map(getRouteQueryParams),
          map((queryParams) => queryParams as SiteListRouteQueryParams)
        ),
        of(state).pipe(map(getUserProfileCustomerNumber)),
      ])
    ),
    mergeMap(([queryParams, userProfileCustomerNumber]) =>
      makeQuery(GET_SITES_QUERY)({
        call: () =>
          SiteListRepository.getSitesCollection({
            ...queryParams,
            search: queryParams.search
              ? xorDecrypt(queryParams.search, userProfileCustomerNumber)
              : undefined,
            size: getAllowedPageSize(queryParams.size),
          }),
        onSuccess: (res) =>
          concat(
            dataGuard(SiteListPageActions.setSitesCollection)(res.elements),
            dataGuard(SiteListPageActions.setSitesCollectionMetaData)(res.meta)
          ),
      })
    )
  );

export const getSitesGeoLocation$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteListPageActions.getSitesGeoLocation),
    mergeMap(({ postcode }) =>
      makeQuery(GET_SITES_GEO_LOCATION_QUERY)({
        call: () => SiteListRepository.getSitesGeoLocation(postcode),
        onSuccess: (res) => dataGuard(SiteListPageActions.setSitesGeoLocation)(res.elements),
      })
    )
  );

export const getPartnerSitesSummary$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    mapToState(state$),
    matchPath(ROUTES.CUSTOMERS),
    mergeMap(() =>
      makeQuery(GET_PARTNER_SITES_SUMMARY)({
        call: () => SiteListRepository.getPartnerSitesSummary(),
        onSuccess: (res: PartnerSummaryResponse) => {
          return dataGuard(SiteListPageActions.setInstalledBatteriesCount)(
            res.included[0].attributes.installed
          );
        },
        onFailure: (_) => of(SiteListPageActions.setInstalledBatteriesCount(0)),
      })
    )
  );

export const epics = combineEpics<any>(
  getSitesCollection$,
  getSitesGeoLocation$,
  getPartnerSitesSummary$
);
