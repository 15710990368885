import * as React from 'react';
import { Transition } from 'react-transition-group';
import type { TransitionProps } from 'react-transition-group/Transition';

import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import type { NotificationVariant } from '+app/shared/store/layout';

import './Notification.component.scss';

export type NotificationProps = Omit<TransitionProps, 'in' | 'timeout'> & {
  isOpen: boolean;
  message: React.ReactNode;
  variant?: NotificationVariant;
  onCloseClick?: () => void;
  onNotificationClick?: () => void;
};

export const Notification: React.VFC<NotificationProps> = ({
  isOpen,
  message,
  variant = 'info',
  onCloseClick,
  onNotificationClick,
  ...transitionProps
}) => (
  <Transition in={isOpen} timeout={{ enter: 0, exit: 300 }} {...transitionProps}>
    {(transitionStatus) => (
      <div className={'c-notification'} onClick={onNotificationClick}>
        <div
          className={classNames('c-notification__wrapper', {
            'is-open': transitionStatus === 'entered',
            [`c-notification__wrapper--theme-${variant}`]: variant,
          })}
        >
          <div className={'c-notification__content'}>
            <div className={'c-notification__text'}>{message}</div>
            {onCloseClick && (
              <div className={'c-notification__button'} onClick={onCloseClick}>
                <Icon.Close />
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </Transition>
);
