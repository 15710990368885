import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Alert, MediaQuery, PageHeadline } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';

import { getCloseLeadQueryStatus } from '+app/+lead/+overview/store/+overview.selectors';
import { NewsSection } from '+app/+newsChannel/containers/NewsSection';
import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { LEAD_IN_SETUP_STAGE, LeadListRouteQueryParams, PATHS } from '+app/router';
import { getDecryptedRouteQueryParams } from '+app/router/store/router.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import {
  Container,
  PageBlock,
  Pagination,
  PaginationSummary,
  SearchField,
} from '+shared/components';
import { isModalOpen, LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserCompanyName,
  getUserProfileCustomerNumber,
  getUserProfileRoles,
} from '+shared/store/user/user.selectors';
import { encryptSearchTerm } from '+utils/crypto.util';
import { mapActions } from '+utils/redux';

import { LeadListEmpty } from '../../components/LeadListEmpty';
import { SetupLeadListTable } from '../../components/SetupLeadListTable';
import {
  getSetupLeadCollection,
  getSetupLeadCollectionQueryStatus,
  getSetupLeadCollectionTotalCount,
  getSetupLeadStatusFilters,
} from '../../store/+leadList.selectors';
import { LeadListStatusFilter } from '../LeadListStatusFilter';

import './SetupLeadList.component.scss';

const mapStateToProps = (state: StoreState) => ({
  setupLeadCollection: getSetupLeadCollection(state),
  setupLeadCollectionTotalCount: getSetupLeadCollectionTotalCount(state),
  setupLeadCollectionQueryStatus: getSetupLeadCollectionQueryStatus(state),
  routerQueryParams: getDecryptedRouteQueryParams(state) as Required<LeadListRouteQueryParams>,
  userRoles: getUserProfileRoles(state),
  isStatusModalOpen: isModalOpen(state),
  userCompanyName: getUserCompanyName(state),
  closeLeadQueryStatus: getCloseLeadQueryStatus(state),
  statusFilters: getSetupLeadStatusFilters(state),
  userProfileCustomerNumber: getUserProfileCustomerNumber(state),
});

const mapDispatchToProps = mapActions({
  goToLead: (leadId: string) => push(PATHS.LEAD_OVERVIEW({ leadId }, LEAD_IN_SETUP_STAGE)),
  goToLeadOffer: (leadId: string) => {
    return push(PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE));
  },
  pushLeadsPage: (queryParams: Required<LeadListRouteQueryParams>) =>
    push(PATHS.SETUP_LEADS(queryParams)),
  toggleStatusModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<LeadListRouteQueryParams>;

const LeadListComponent: React.FC<Props> = ({
  actions,
  setupLeadCollection,
  setupLeadCollectionTotalCount,
  setupLeadCollectionQueryStatus,
  routerQueryParams,
  userRoles,
  isStatusModalOpen,
  userCompanyName,
  closeLeadQueryStatus,
  statusFilters,
  userProfileCustomerNumber,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.SETUP, PageName.Setup.SETUP_CUSTOMERS_LIST);

  const shouldShowPagination =
    setupLeadCollectionQueryStatus.success || setupLeadCollectionQueryStatus.error;

  const queryLeads = (newParams: LeadListRouteQueryParams = {}) =>
    actions.pushLeadsPage(
      encryptSearchTerm({ ...routerQueryParams, ...newParams }, userProfileCustomerNumber)
    );

  const onPageChange = (page: number | undefined) => queryLeads({ page: String(page) });

  const onSearch = (search?: string) => queryLeads({ search, page: '1' });

  const onFilter = () => queryLeads({ page: '1' });

  const hasNoResults =
    isEmpty(setupLeadCollection) &&
    (routerQueryParams.search || !isEmpty(statusFilters)) &&
    !setupLeadCollectionQueryStatus.pending;

  return (
    <>
      <Alert
        message={I18n.t(T.lead.list._salessolution_.toastMessage.closedLead)}
        isOpen={closeLeadQueryStatus.success}
      />
      {userRoles && <NewsSection userRoles={userRoles} />}
      <Container className="c-lead-setup-list__container">
        <PageBlock>
          <div className="c-lead-setup-list__headline-wrapper">
            <PageHeadline smallGap={true}>{I18n.t(T.setupTool.pageHeader)}</PageHeadline>
          </div>
        </PageBlock>
        <div className="c-lead-setup-list__header">
          <div className="c-lead-setup-list__search-field">
            <Media query={MediaQuery.UP_SM}>
              {() => (
                <SearchField
                  value={routerQueryParams.search}
                  onSearch={onSearch}
                  dataTestIdInputField="search-input-field"
                  dataTestIdBtn="search-button"
                  placeholder={I18n.t(T.lead.list._salessolution_.search.placeholder)}
                />
              )}
            </Media>
          </div>
          <div className="c-lead-list__status-filter">
            <LeadListStatusFilter inSetup={true} getLeadList={onFilter} />
          </div>
        </div>
        <PaginationSummary
          i18n={T.lead.list._salessolution_.leadsSummary}
          page={routerQueryParams.page}
          recordsTotal={setupLeadCollectionTotalCount}
        />
        <PageBlock>
          <SetupLeadListTable
            items={setupLeadCollection}
            isPending={setupLeadCollectionQueryStatus.pending}
            onLeadClick={actions.goToLead}
            onLeadOfferClick={actions.goToLeadOffer}
            isStatusModalOpen={isStatusModalOpen}
            toggleStatusModal={actions.toggleStatusModal}
            userCompanyName={userCompanyName}
          />
          {hasNoResults && <LeadListEmpty />}
        </PageBlock>
        {shouldShowPagination && (
          <PageBlock className="c-lead-setup-list__pagination">
            <Pagination
              page={routerQueryParams.page}
              recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
              recordsTotal={setupLeadCollectionTotalCount}
              onPageChange={onPageChange}
              dataTestIdBackBtn="back-pagination-button"
              dataTestIdNextBtn="next-pagination-button"
            />
          </PageBlock>
        )}
      </Container>
    </>
  );
};

export const SetupLeadList = connect(mapStateToProps, mapDispatchToProps)(LeadListComponent);
