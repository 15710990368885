"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var helpers_1 = require("./helpers");
var HttpResponseHeaders = (function () {
    function HttpResponseHeaders(headers) {
        if (headers === void 0) { headers = {}; }
        if (typeof headers === 'string') {
            this.map = headers
                .split('\n')
                .reduce(function (map, header) {
                var _a = header
                    .split(':')
                    .map(function (element) { return decodeURIComponent(element.trim()); }), key = _a[0], value = _a[1];
                map[key.toLowerCase()] = value;
                return map;
            }, {});
        }
        else if (headers.forEach) {
            this.map = helpers_1.parseHeaders(headers);
        }
        else {
            this.map = Object.keys(headers).reduce(function (result, key) {
                result[key.toLowerCase()] = headers[key];
                return result;
            }, {});
        }
    }
    HttpResponseHeaders.prototype.set = function (key, value) {
        this.map[key.toLowerCase()] = value;
    };
    HttpResponseHeaders.prototype.get = function (key) {
        return helpers_1.getHeader(this.map, key);
    };
    return HttpResponseHeaders;
}());
exports.HttpResponseHeaders = HttpResponseHeaders;
