import { T } from '@sonnen/shared-i18n/service';

export enum LeadCreationValidationErrorTitles {
  LEAD_INVALID_ADDRESS = 'LeadInvalidAddress',
  LEAD_ALREADY_EXISTS = 'LeadAlreadyExists',
}

export const mapLeadErrorTitleToTranslationKey = (title: string) => {
  switch (title) {
    case LeadCreationValidationErrorTitles.LEAD_INVALID_ADDRESS:
      return T.lead.boc._salessolution_.form.generalAddressValidationError;
    case LeadCreationValidationErrorTitles.LEAD_ALREADY_EXISTS:
      return T.lead.boc._salessolution_.form.leadAlreadyExistsError;
    default:
      return T.lead.boc._salessolution_.form.generalValidationError;
  }
};
