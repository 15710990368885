import { PersistedState } from 'redux-persist';

import { ChargerLiveState } from '+shared/store/charger';
import { Query } from '+shared/store/query';
import { SiteCharger, SiteChargerData } from '+shared/store/site';
import { SiteLiveState, SiteLiveStateData } from '+shared/store/site/types';

export const GET_CHARGERS_QUERY = 'getChargersQuery';
export const GET_OVERVIEW_SITE_LIVE_STATE_QUERY = 'getAnalysisSiteLiveQuery';

interface Charger extends SiteCharger {
  liveState?: ChargerLiveState;
}

export interface OverviewPageState extends PersistedState {
  charger?: Charger;
  // This stores liveState for polling data in CustomerAnalysisWidget
  overviewLiveState?: SiteLiveState;
  [GET_CHARGERS_QUERY]: Query<SiteChargerData>;
  [GET_OVERVIEW_SITE_LIVE_STATE_QUERY]: Query<SiteLiveStateData>;
}

export const initialState: OverviewPageState = {
  charger: undefined,
  overviewLiveState: undefined,
  [GET_OVERVIEW_SITE_LIVE_STATE_QUERY]: {},
  [GET_CHARGERS_QUERY]: {},
};
