import * as React from 'react';

import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as _ from 'lodash';

import { HorizontalPositionProps, TooltipHoverable } from '+shared/components/TooltipHoverable';

import { FormInputRadio } from '../FormInputRadio';

import './FormInputRadioGroup.component.scss';

export interface RadioButton {
  label: string | JSX.Element;
  value: unknown;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  text?: string;
  dataTestId?: string;
  id?: string;
  selectionDisabled?: boolean;
  disabledMessage?: string;
}

interface Props<T> {
  name: string;
  text?: string;
  form: FormikProps<T>;
  className?: ClassValue;
  collection: RadioButton[];
  isVertical?: boolean;
  disabled?: boolean;
}

export const FormInputRadioGroup = <T extends any = any>({
  form,
  name,
  text,
  className,
  collection,
  isVertical,
  disabled = false,
}: Props<T>) => {
  const hasRadioButtonText = !!_.last(collection)?.text;

  return (
    <div className={classNames('c-form-input-radio-group', className)}>
      {text && <p className={'c-form-input-radio-group__text'}>{text}</p>}
      <div
        className={classNames('c-form-input-radio-group__container', {
          'c-form-input-radio-group__container--vertical': isVertical,
          'c-form-input-radio-group__container--vertical__margin--bottom': hasRadioButtonText,
        })}
      >
        {collection.map((radioButton, index) => (
          <TooltipHoverable
            key={index}
            title={radioButton.disabledMessage}
            isHoveringDisabled={!radioButton.selectionDisabled}
            bubbleSide={'bottom-left'}
            horizontalPosition={HorizontalPositionProps.MIDDLE_LEFT}
            topHeight={65}
          >
            <FormInputRadio
              key={index}
              form={form}
              label={radioButton.label}
              name={name}
              value={radioButton.value}
              className={'c-form-input-radio-group__item'}
              onClick={radioButton.onClick}
              disabled={disabled ? disabled : radioButton.selectionDisabled}
              text={radioButton.text}
              dataTestId={radioButton.dataTestId}
              id={radioButton.id}
            />
          </TooltipHoverable>
        ))}
      </div>
    </div>
  );
};
