import { useFeature } from '@sonnen/shared-web';

import { camelCase, flatMap, isUndefined, omitBy } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { CaseListRouteQueryParams } from '+app/router';
import { FeatureName } from '+config/featureFlags';
import {
  QueryArgs,
  QueryParams,
  QueryTypes,
  SortOrder,
} from '+shared/store/graphQL/types/graphQL.types';
import { xorDecrypt } from '+utils/crypto.util';
import { getMarkets, Market } from '+utils/market.util';

import { caseAllFilters, CaseStatus, statusFilterAggregates } from './types/case.interface';
import { CaseCountry } from './types/createCase.interface';

const RESULTS_PER_PAGE = 10;

const caseQueryFields = [
  'id',
  'caseNumber',
  'createdDate',
  'lastModifiedDate',
  'description',
  'subject',
  'status',
  'recordType',
  'createdDate',
  {
    account: ['firstName', 'lastName'],
  },
];

export const defaultCaseQueryArguments = {
  first: RESULTS_PER_PAGE,
  order: { lastModifiedDate: SortOrder.DESC },
};

const calculateOffset = (page: number): number => (page - 1) * RESULTS_PER_PAGE;

const mapAggregatedStatuses = (statusQuery: string | undefined): CaseStatus[] =>
  flatMap((status: string) => statusFilterAggregates[status])(
    statusQuery ? statusQuery.split(',') : caseAllFilters
  );

export const generateCaseQueryArguments = (
  routeParams: CaseListRouteQueryParams,
  userProfileCustomerNumber: string
): QueryArgs => {
  const { order, status, customerId, page, search } = routeParams;
  const formatedOrder = order ? { order: { [camelCase(order)]: SortOrder.DESC } } : undefined;
  const formatedStatus = { status: mapAggregatedStatuses(status) };
  const formatedCustomerId = customerId ? { customer: { id: customerId } } : undefined;
  const offset = page ? { offset: calculateOffset(Number(page)) } : undefined;
  const formatedSearch = search
    ? { search: xorDecrypt(search, userProfileCustomerNumber) }
    : undefined;

  return omitBy(isUndefined, {
    ...defaultCaseQueryArguments,
    ...formatedOrder,
    ...formatedStatus,
    ...formatedCustomerId,
    ...formatedSearch,
    ...offset,
  }) as QueryArgs;
};

export const generateCaseQueryParams = (
  routeParams: CaseListRouteQueryParams,
  userProfileCustomerNumber: string
): QueryParams => {
  const args = routeParams
    ? generateCaseQueryArguments(routeParams, userProfileCustomerNumber)
    : defaultCaseQueryArguments;

  return {
    type: QueryTypes.CASES,
    fields: caseQueryFields,
    args,
  };
};

/**
 * @description Maps ISO country code to the case-specific codes.
 * All not specified countries are mapped as a fallback to the `DACH` region.
 * @param userCountry country ISO code
 * @returns case-specific country code
 */

export const mapUserCountryToCaseCountry = (
  isSpanishMarketEnabled: boolean,
  userCountry: CountryCode | undefined
): CaseCountry | null => {
  const isRolloutDisabled = useFeature(FeatureName.ROLLOUT).isDisabled;
  if (isRolloutDisabled || !userCountry) return CaseCountry.DACH;

  switch (userCountry) {
    case CountryCode.IT:
      return CaseCountry.IT;
    case CountryCode.DE:
    case CountryCode.CH:
    case CountryCode.AT:
      return CaseCountry.DACH;
    case CountryCode.US:
    case CountryCode.CA:
    case CountryCode.PR:
    case CountryCode.MX:
    case CountryCode.CR:
    case CountryCode.KY:
      return CaseCountry.US;
    case CountryCode.AU:
    case CountryCode.NZ:
      return CaseCountry.AU;
    case CountryCode.ES:
    case CountryCode.AD:
      return CaseCountry.ES;
    default:
      return getMarkets(isSpanishMarketEnabled)[Market.GME].includes(userCountry)
        ? CaseCountry.ROW
        : CaseCountry.DACH;
  }
};
