import { __extends } from "tslib";
import { normalizeRadius, Paint, rgba } from '@kanva/core';
import { last } from 'lodash';
import { ChartView } from './chart.view';
var VIEW_TAG = 'LineChartView';
var defaultStyle = {
    background: {
        paint: new Paint()
            .setFillStyle(rgba('#FFF', .1)),
        radius: 0,
        width: 8,
    },
    foreground: {
        paint: new Paint()
            .setFillStyle(rgba('#FFF', .5)),
        radius: 0,
        width: 8,
    },
    minChunkLength: { domain: 0 },
};
var defaultNormalizeData = function (_a) {
    var x = _a.x, y = _a.y;
    return y > 0
        ? { x: x, y: 1 }
        : { x: x, y: 0 };
};
var calculateMinLineLength = function (start, end, scale, customMinLength) {
    return (customMinLength && customMinLength.domain)
        ? Math.max(scale(end + customMinLength.domain) - scale(start), customMinLength.px || 0)
        : 0;
};
var LineChartView = (function (_super) {
    __extends(LineChartView, _super);
    function LineChartView(context, normalizeData) {
        var _this = _super.call(this, context, VIEW_TAG, defaultStyle) || this;
        _this.data = [];
        _this.normalizeData = defaultNormalizeData;
        if (normalizeData)
            _this.normalizeData = normalizeData;
        return _this;
    }
    LineChartView.prototype.onLayout = function () {
        var _this = this;
        var _a = this, dataContainer = _a.dataContainer, dataSeries = _a.dataSeries;
        if (!dataContainer || !dataSeries) {
            this.data = [];
            return;
        }
        if (dataSeries.length > 1) {
            throw new Error("".concat(VIEW_TAG, ": LineChartView supports only one data series at a time."));
        }
        var series = dataContainer.getDataSeries(dataSeries[0]);
        if (!series) {
            this.data = [];
            return;
        }
        this.data = series.data.reduce(function (entries, point) {
            var lastEntry = last(entries);
            var normalizedPoint = _this.normalizeData(point);
            if (!lastEntry || lastEntry.y !== normalizedPoint.y) {
                entries.push({
                    x: [normalizedPoint.x, normalizedPoint.x],
                    y: normalizedPoint.y,
                });
            }
            else {
                lastEntry.x[1] = normalizedPoint.x;
            }
            return entries;
        }, []);
    };
    LineChartView.prototype.getInternalWrappedHeight = function () {
        return Math.max((this.style.background || defaultStyle.background).width, (this.style.foreground || defaultStyle.foreground).width);
    };
    LineChartView.prototype.onDraw = function (canvas) {
        var _a = this, innerWidth = _a.innerWidth, innerHeight = _a.innerHeight, data = _a.data, dataContainer = _a.dataContainer, style = _a.style;
        if (!dataContainer) {
            return;
        }
        var _b = style.background, background = _b === void 0 ? defaultStyle.background : _b, _c = style.foreground, foreground = _c === void 0 ? defaultStyle.foreground : _c, _d = style.minChunkLength, minChunkLength = _d === void 0 ? defaultStyle.minChunkLength : _d;
        var ctx = canvas.context;
        var xScale = this.getScales().xScale;
        if (background.width > 0) {
            ctx.beginPath();
            canvas.roundRect(0, (innerHeight - background.width) / 2, innerWidth, background.width, normalizeRadius(background.radius));
            canvas.drawPath(background.paint);
        }
        if (foreground.width > 0) {
            ctx.beginPath();
            for (var i = 0, l = data.length; i < l; i++) {
                var lineEntry = data[i];
                if (lineEntry.y === 0)
                    continue;
                var minLineLength = calculateMinLineLength(lineEntry.x[0], lineEntry.x[1], xScale, minChunkLength);
                canvas.roundRect(xScale(lineEntry.x[0]), (innerHeight - foreground.width) / 2, xScale(lineEntry.x[1]) - xScale(lineEntry.x[0]) || minLineLength, foreground.width, normalizeRadius(foreground.radius));
            }
            canvas.drawPath(foreground.paint);
        }
    };
    LineChartView.prototype.getScales = function () {
        return this.dataContainer.getScales(this.innerWidth, 1);
    };
    LineChartView.prototype.getCanvasPositionForPoint = function (point) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        var x = xScale(point.x);
        var y = yScale(0);
        return {
            x: x,
            y: y,
            absoluteX: this.offsetRect.l + x,
            absoluteY: this.offsetRect.t + y,
        };
    };
    LineChartView.prototype.getPointForCanvasPosition = function (position) {
        var _a = this.getScales(), xScale = _a.xScale, yScale = _a.yScale;
        return {
            x: xScale.invert(position.x),
            y: yScale.invert(0),
        };
    };
    return LineChartView;
}(ChartView));
export { LineChartView };
