import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

export const defaultResultsPerPage = '20';
export const availableResultsPerPage: string[] = ['10', '20', '40', '80'];

export interface PaginationConfigItem {
  name: string;
  value: string;
}

export interface PaginationConfig {
  availableResultsPerPage?: string[];
  selectedResultsPerPage?: string;
}

export const getAvailableResultsPerPage = (config: PaginationConfig): string[] =>
  config.availableResultsPerPage ?? availableResultsPerPage;

export const getSelectedResultsPerPage = (config: PaginationConfig): string =>
  config.selectedResultsPerPage ?? defaultResultsPerPage;

export const getResultsPerPageItems = (config: PaginationConfig): PaginationConfigItem[] => {
  const values: string[] = [...getAvailableResultsPerPage(config)];
  const selectedResultsPerPage = getSelectedResultsPerPage(config);

  if (!values.includes(selectedResultsPerPage)) values.push(selectedResultsPerPage);

  return values.map((value) => ({ name: getResultsPerPageName(value), value }));
};

export const getSelectedResultsPerPageItem = (config: PaginationConfig): PaginationConfigItem => {
  const value = getSelectedResultsPerPage(config);
  const name = getResultsPerPageName(value);
  return { name, value };
};

export const isResultsPerPageOutsideAcceptedBounds = (pageSize: string | undefined): boolean => {
  if (pageSize === 'undefined') return false;
  return Number(pageSize) <= 0 || Number(pageSize) > 80;
};

export const getAllowedPageSize = (pageSize: string | undefined): string | undefined => {
  if (!pageSize) return pageSize;
  return isResultsPerPageOutsideAcceptedBounds(pageSize) ? defaultResultsPerPage : pageSize;
};

const getResultsPerPageName = (value: string): string =>
  `${value} ${I18n.t(T.general.pageableTable.resultsPerPage)}`;
