import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { PATHS } from '+router/routes';
import { Padlock, Warning } from '+shared/basicComponents/Icons';
import { BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button/Button.component';
import { TooltipCloseOnScroll } from '+shared/components/TooltipCloseOnScroll/TooltipCloseOnScroll';

import { StatusWarningTranslations } from '../SitesTableDataCell/SitesTableDataCell.helper';

import './TooltipsStatusColumn.scss';

const { title, body, button } = T.customers.table.columns.status.lockedSiteTooltip;

type CommonProps = {
  id: string;
  warnings?: StatusWarningTranslations[];
  isSuperUser?: boolean;
};

export const TooltipOnWarningIcon: React.FC<CommonProps> = ({ id, warnings }) => {
  const content = (
    <ul>
      {warnings?.map((warning: StatusWarningTranslations) => (
        <li key={I18n.t(warning.title)} className="tooltip-on-status-icon__content">
          <LabelMedium
            text={I18n.t(warning.title)}
            className="tooltip-on-status-icon__content--title"
          />
          <BodySmall text={I18n.t(warning.content)} />
        </li>
      ))}
    </ul>
  );

  return (
    <div className="tooltip-on-status-icon">
      <span className="icon" data-tooltip-id={id} data-tooltip-offset={10}>
        <Warning size={20} />
      </span>
      <TooltipCloseOnScroll id={id} content={content} className="tooltip-status-column" />
    </div>
  );
};

export const TooltipOnLockedIcon: React.FC<CommonProps> = ({ id, isSuperUser }) => {
  const content = (
    <div className="tooltip-on-status-icon__content">
      <LabelMedium text={I18n.t(title)} className="tooltip-on-status-icon__content--title" />
      <BodySmall text={I18n.t(body)} />
      <Button
        type={ButtonType.TERTIARY}
        size={ButtonSize.SMALL}
        label={
          <a href={PATHS.HELP('partner-portal&open=0')} target="_blank" rel="noreferrer">
            {I18n.t(button)}
          </a>
        }
        className="reusable-class__no-padding tooltip-on-status-icon__content--button"
      />
    </div>
  );

  return (
    <div className="tooltip-on-status-icon">
      <span className="icon" data-tooltip-id={id} data-tooltip-offset={10}>
        <Padlock size={20} />
      </span>

      {!isSuperUser && (
        <TooltipCloseOnScroll
          id={id}
          content={content}
          clickable={true}
          className="tooltip-status-column"
        />
      )}
    </div>
  );
};
