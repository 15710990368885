import { HttpResponse } from '@coolio/http';
import { SortOrder } from '@coolio/json-api';
import { defer } from 'rxjs';

import { Config } from '+config/config';
import { SiteListRouteQueryParams } from '+router/routes';
import { httpClient, jsonApiClient } from '+shared/network';
import { CustomerSortKey, CustomerSortOrder } from '+shared/store/customer/types/customerSort.type';

import { PartnerSiteData, PartnerSiteMetaData } from './types/partnerSite.interface';
import { PartnerSitesGeoLocationData } from './types/partnerSitesCustomer.interface';
import { PartnerSummaryResponse } from './types/partnerSitesSummary.interface';

const getSitesCollection = ({
  search,
  sort,
  order,
  page,
  size,
  postalCodes,
  meterPreparationStatuses,
  fstCheckSuccessful,
}: SiteListRouteQueryParams) => {
  const defaultDescendingOrder = CustomerSortOrder[CustomerSortKey.INSTALLATION_DATE];
  const effectiveSort = !order && !sort ? CustomerSortKey.INSTALLATION_DATE : sort;
  const effectiveOrder = (!order && !sort) || order ? defaultDescendingOrder : SortOrder.ASCENDING;

  return defer(() =>
    jsonApiClient
      .get<PartnerSiteData, PartnerSiteMetaData>(`${Config.API_URL}/partners/sites`)
      .parameter('include', 'customer,batteries.product,contracts.meter_installation')
      .filter('batteries.status', 'installed')
      .filter('query', search)
      .filter('sites.postal_codes', postalCodes)
      .filter('meter_installations.meter_preparation_status', meterPreparationStatuses)
      .filter('meter_installations.fst_check_successful', fstCheckSuccessful)
      .parameter('page[size]', size)
      .pageNumber(page)
      .sort(effectiveSort, effectiveOrder)
      .expectMany()
      .resolveIncluded()
      .send()
  );
};
const getSitesGeoLocation = (postcode: string) =>
  defer(() =>
    jsonApiClient
      .get<PartnerSitesGeoLocationData>(`${Config.API_URL}/partners/sites-geo-locations`)
      .filter('criteria', postcode)
      .expectMany()
      .send()
  );

const getPartnerSitesSummary = () =>
  defer(() =>
    httpClient
      .get(`${Config.API_URL}/partners/summary`, { query: { include: 'batteries' } })
      .then((res: HttpResponse<PartnerSummaryResponse>) => res.parsedBody())
  );

export const SiteListRepository = {
  getSitesCollection,
  getSitesGeoLocation,
  getPartnerSitesSummary,
};
