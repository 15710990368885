import { Data, MergedData } from '@coolio/json-api';

type WattUnit = 'W' | 'Wh';

interface Watt {
  unit: string;
  value: WattUnit;
}

enum HeatpumpStatus {
  HEATING = 'heating',
  STANDBY = 'standby',
  COOLING = 'cooling',
}
type HeatpumpStatusDetail = {
  status: HeatpumpStatus;
};

type ConnectionState = 'online' | 'offline';

export enum DeviceType {
  BATTERY = 'battery',
  PV_SYSTEM = 'pv_system',
  VIRTUAL_METER = 'virtual_meter',
  GRID_CONNECTION = 'grid_connection',
  SITE_CONSUMPTION = 'site_consumption',
  HEATER = 'heater',
  HEATPUMP = 'heatpump',
  EV_CHARGER = 'ev_charger',
}

export interface Device {
  connectionState: ConnectionState;
  deviceLocation: string;
  deviceType: DeviceType;
  powerIn: Watt | null;
  powerOut: Watt | null;
}

export interface Heatpump extends Device {
  pvSurplusIn: Watt | null;
  statusDetails: HeatpumpStatusDetail[] | [];
}

export interface SiteLiveStateV2Attributes {
  id: string | null;
  timestamp: string;
  devices: Device[];
  powerFlows: unknown[];
}

export type SiteLiveStateV2Data = Data<SiteLiveStateV2Attributes>;
export type SiteLiveStateV2 = MergedData<SiteLiveStateV2Data>;
