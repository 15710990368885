import { clamp } from 'lodash/fp';

import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '../../../App.constants';
import { getPagesCount } from '../Pagination/Pagination.helper';

type BoundariesOpts = {
  page: number | undefined;
  recordsTotal: number;
};

export const getPaginationBoundaries = ({ page = 1, recordsTotal }: BoundariesOpts) => {
  const minPage = 1;
  const maxPage = getPagesCount({
    recordsTotal,
    recordsPerPage: TOTAL_RESOURCE_COUNT_PER_PAGE,
  });
  const activePage = clamp(minPage, maxPage)(Number(page) || minPage);
  const first = recordsTotal
    ? Math.max(minPage, (activePage - 1) * TOTAL_RESOURCE_COUNT_PER_PAGE + 1)
    : 0;
  const last = recordsTotal ? Math.min(recordsTotal, first + TOTAL_RESOURCE_COUNT_PER_PAGE - 1) : 0;

  return { first, last, count: recordsTotal };
};
