import { ActionsUnion, createAction } from '../../../utils';
import { AccountType, LegalDocumentAttributes } from './types/legal.interface';

export enum LEGAL_ACTIONS {
  GET_ACCEPTED_LEGAL_DOCUMENTS = '[Legal] GET_ACCEPTED_LEGAL_DOCUMENTS',
  SET_ACCEPTED_LEGAL_DOCUMENTS = '[Legal] SET_ACCEPTED_LEGAL_DOCUMENTS',
}

export const LegalActions = {
  getAcceptedLegalDocuments: createAction(
    LEGAL_ACTIONS.GET_ACCEPTED_LEGAL_DOCUMENTS,
    (opts: { accountType: AccountType; onlyLastAccepted: boolean }) => ({
      accountType: opts.accountType,
      onlyLastAccepted: opts.onlyLastAccepted,
    })
  ),
  setAcceptedLegalDocuments: createAction(
    LEGAL_ACTIONS.SET_ACCEPTED_LEGAL_DOCUMENTS,
    (acceptedLegalDocuments: LegalDocumentAttributes[]) => ({ acceptedLegalDocuments })
  ),
};

export type LegalActions = ActionsUnion<typeof LegalActions>;
