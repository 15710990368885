import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { ModalLayout } from '@sonnen/shared-web';

import { Button, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';

import './MissingBatteryModalLayout.component.scss';

interface Props {
  canSubmit: boolean;
  isLoading: boolean;
  buttonLabel: string;
  onSubmit?: () => void;
  goBack?: () => void;
}

export const MissingBatteryModalLayout: React.FC<Props> = ({
  children,
  canSubmit,
  isLoading,
  buttonLabel,
  onSubmit,
  goBack,
}) => (
  <ModalLayout
    title={I18n.t(T.missingBattery.modalHeader)}
    footer={
      <div className={'c-missing-battery-modal-layout__footer'}>
        {!!goBack && (
          <Button
            label={I18n.t(T.general.basicActions.goBack)}
            type={ButtonType.TERTIARY}
            onClick={goBack}
          />
        )}
        <Button
          label={buttonLabel}
          type={ButtonType.PRIMARY}
          mainType={MainType.SUBMIT}
          disabled={!canSubmit || isLoading}
          status={!canSubmit || isLoading ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
          loading={isLoading}
          onClick={onSubmit}
          dataTestId="next-button"
        />
      </div>
    }
  >
    {children}
  </ModalLayout>
);
