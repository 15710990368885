import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import './PrecommissioningDashboardInfobox.component.scss';

export const PrecommissioningDashboardInfobox: React.FC = () => (
  <div className={'c-setup-tool-step-list-table-item__precommissioning-infobox'}>
    <Icon.Info className={'c-setup-tool-step-list-table-item__precommissioning-infobox__icon'} />
    <span className={'c-setup-tool-step-list-table-item__precommissioning-infobox__text'}>
      {I18n.t(T.setupTool.preCommissioning.infoBox)}
    </span>
  </div>
);
