import * as React from 'react';

import { Status, StatusDecorator, StatusType } from '@sonnen/shared-web';

import { CustomerWarning } from '+shared/store/customer/warnings/warnings.types';

import './CustomerWarnings.component.scss';

interface Props {
  warnings: CustomerWarning[];
}

export const CustomerWarnings: React.SFC<Props> = ({ warnings }) =>
  warnings.length ? (
    <ul className={'c-customer-warnings'}>
      {warnings.map(({ message, messageExtended, type = StatusType.WARNING }) => (
        <li className={'c-customer-warnings__item'} key={message}>
          <StatusDecorator type={type} />
          <div className={'c-customer-warnings__inner'}>
            <div className={'c-customer-warnings__hint-wrapper'}>
              <Status hasDot={false} type={type} label={message} />
            </div>
            {messageExtended && (
              <div className={'c-customer-warnings__message-extended'}>{messageExtended}</div>
            )}
          </div>
        </li>
      ))}
    </ul>
  ) : null;
