import React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from '+app/App.component';
import { ADOBE_ANALYTICS_SCRIPT } from '+config/env.config';
import { AA_LOAD_EVENT } from '+shared/AdobeAnalytics/adobeAnalytics.helpers';
import { Reporter } from '+utils/reporter.util';

import * as serviceWorker from './config/serviceWorker';

// TODO (no-ticket): remove this fonts import when the outdated icofonts will be replaced with SVGs
import '@sonnen/shared-web/_fonts.scss';
import './index.scss';

Reporter.init();
ReactDOM.render(<App />, document.getElementById('root'));

// when we will really need Service Worker, just register it and have fun...
serviceWorker.unregister();

function loadAdobeAnalytics() {
  if (process.env.REACT_APP_CONFIG) {
    const script = document.createElement('script');

    const src = ADOBE_ANALYTICS_SCRIPT[process.env.REACT_APP_CONFIG];

    script.setAttribute('src', src);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('defer', 'true');
    script.setAttribute('async', 'true');

    document.head!.appendChild(script);
  }

  window.removeEventListener(AA_LOAD_EVENT, loadAdobeAnalytics);
}

window.addEventListener(AA_LOAD_EVENT, loadAdobeAnalytics);
