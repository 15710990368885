import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  getBattery,
  getBatteryQueryStatus,
  getPvGridFeedInLimit,
  getPvPeakPower,
} from '+app/+customer/+battery/store';
import {
  getBatteryPowerMeterLiveStates,
  getBatteryPowerMeterLiveStatesQueryStatus,
  getIsBatteryPvGridFeedInLimitInProgress,
} from '+app/+customer/+pvSystems/store/pvSystems.selectors';
import { CustomerRouteParams } from '+app/router';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import TwoColumnsLayout from '+shared/containers/Layout/TwoColumnsLayout';
import { getSiteLiveState, getSiteLiveStateQueryStatus } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { MeasurementConceptsType } from './components/MeasurementConcept/MeasurementConcept';
import { PowerMeterLiveStatesCard } from './components/PowerMeterLiveStatesCard/PowerMeterLiveStatesCard';
import { PvSystemCard } from './components/PvSystemCard/PvSystemCard';
import { PvSystemOperationsCard } from './components/PvSystemOperationsCard/PvSystemOperationsCard';

type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState) => ({
  pvPeakPower: getPvPeakPower(state),
  pvGridFeedInLimit: getPvGridFeedInLimit(state),
  battery: getBattery(state),
  batteryQueryState: getBatteryQueryStatus(state),
  siteLiveState: getSiteLiveState(state),
  siteLiveStateQueryState: getSiteLiveStateQueryStatus(state),
  batteryPowerMeterLiveStates: getBatteryPowerMeterLiveStates(state),
  batteryPowerMeterLiveStatesQueryState: getBatteryPowerMeterLiveStatesQueryStatus(state),
  isBatteryPvGridFeedInLimitInProgress: getIsBatteryPvGridFeedInLimitInProgress(state),
});

const mapDispatchToProps = mapActions({});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps;

const CustomerPvSystemsComponent: React.FC<Props> = ({
  pvPeakPower,
  pvGridFeedInLimit,
  battery,
  batteryQueryState,
  siteLiveState,
  siteLiveStateQueryState,
  batteryPowerMeterLiveStates,
  batteryPowerMeterLiveStatesQueryState,
  isBatteryPvGridFeedInLimitInProgress,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.PV_SYSTEMS_AND_METERS);

  const { pvSystemOperations } = useFlags();

  return (
    <TwoColumnsLayout>
      <PvSystemCard
        pvPeakPower={pvPeakPower}
        pvGridFeedInLimit={pvGridFeedInLimit}
        queryStatus={batteryQueryState}
        isBatteryPvGridFeedInLimitInProgress={isBatteryPvGridFeedInLimitInProgress}
      />
      {pvSystemOperations && <PvSystemOperationsCard />}

      <PowerMeterLiveStatesCard
        powerMeterLiveStates={batteryPowerMeterLiveStates}
        siteLiveState={siteLiveState}
        powerMeterLiveStatesQueryState={batteryPowerMeterLiveStatesQueryState}
        siteLiveStateQueryState={siteLiveStateQueryState}
        measurementConcept={battery?.powerMeterSetup as MeasurementConceptsType}
      />
    </TwoColumnsLayout>
  );
};

const CustomerPvSystems = connect(mapStateToProps, mapDispatchToProps)(CustomerPvSystemsComponent);

export default CustomerPvSystems;
