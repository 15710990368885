import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { ModalLayout } from '@sonnen/shared-web';

import { Formik } from 'formik';
import { CountryCode } from 'src/types/country.type';

import { FormInput, FormInputCheckboxGroup } from '+shared/components';
import { Button, MainType } from '+shared/components/Button';

import { RequestAccessFormHelper, RequestPartnerAccessForm } from './RequestAccessForm.helper';

interface Props {
  onSubmit: (values: RequestPartnerAccessForm) => void;
  isLoading: boolean;
  userCountry: CountryCode | undefined;
}

export const RequestAccessForm: React.FC<Props> = ({ onSubmit, isLoading, userCountry }) => (
  <Formik
    initialValues={RequestAccessFormHelper.requestAccessInitValues}
    validationSchema={RequestAccessFormHelper.requestAccessSchema}
    onSubmit={onSubmit}
    render={(form) => (
      <form onSubmit={form.handleSubmit} className={'c-case-report-form'}>
        <ModalLayout
          title={I18n.t(T.companyAccount.requestAccess.title)}
          subtitle={I18n.t(T.companyAccount.requestAccess.desc)}
          footer={
            <Button
              className={'c-case-report-form__button'}
              mainType={MainType.SUBMIT}
              loading={isLoading}
              label={I18n.t(T.companyAccount.requestAccess.buttons.submit)}
            />
          }
        >
          <>
            <div className={'c-case-report-form__body'}>
              <div className={'c-case-report-form__input'}>
                <FormInput
                  form={form}
                  label={I18n.t(T.companyAccount.requestAccess.form.companyName)}
                  name={'companyName'}
                  type={'text'}
                />
                <FormInput
                  form={form}
                  label={I18n.t(T.companyAccount.requestAccess.form.name)}
                  name={'userName'}
                  type={'text'}
                />
                <FormInput
                  form={form}
                  label={I18n.t(T.companyAccount.requestAccess.form.lastName)}
                  name={'userLastName'}
                  type={'text'}
                />
                <FormInput
                  form={form}
                  label={I18n.t(T.companyAccount.requestAccess.form.email)}
                  name={'email'}
                  type={'text'}
                />
                <FormInputCheckboxGroup
                  collection={RequestAccessFormHelper.getRolesCollection(userCountry)}
                  form={form}
                  name={'roles'}
                  text={I18n.t(T.companyAccount.requestAccess.form.roles)}
                  className={'c-form-input-checkbox-group--modal-form'}
                />
              </div>
            </div>
          </>
        </ModalLayout>
      </form>
    )}
  />
);
