import { __extends } from "tslib";
import { Paint, removeUndefinedProps, RequiredViewChanges, TextAlign, TextBaseline, View, } from '@kanva/core';
import { DataContainerEventType } from '../data-container/data-container.events';
import { prepareAxisPoints } from '../utils';
export var AxisOrientation;
(function (AxisOrientation) {
    AxisOrientation[AxisOrientation["HORIZONTAL"] = 0] = "HORIZONTAL";
    AxisOrientation[AxisOrientation["VERTICAL"] = 1] = "VERTICAL";
})(AxisOrientation || (AxisOrientation = {}));
var defaultStyle = {
    labelPaint: new Paint().setFillStyle('#000'),
    labelPadding: 4,
    wrapLabelsOnEdge: true,
};
var AxisView = (function (_super) {
    __extends(AxisView, _super);
    function AxisView(context) {
        var _this = _super.call(this, context, 'AxisView') || this;
        _this.style = defaultStyle;
        _this.orientation = AxisOrientation.HORIZONTAL;
        _this.data = [];
        _this.onDataChange = function () {
            _this.require(RequiredViewChanges.LAYOUT);
        };
        return _this;
    }
    AxisView.prototype.onLayout = function () {
        this.data = this.getPoints();
    };
    AxisView.prototype.getStyle = function () {
        return this.style;
    };
    AxisView.prototype.setStyle = function (style) {
        this.style = style || defaultStyle;
        this.require(RequiredViewChanges.DRAW);
    };
    AxisView.prototype.getOrientation = function () {
        return this.orientation;
    };
    AxisView.prototype.setOrientation = function (orientation) {
        this.orientation = orientation;
        this.require(RequiredViewChanges.DRAW);
    };
    AxisView.prototype.getDataContainer = function () {
        return this.dataContainer;
    };
    AxisView.prototype.setDataContainer = function (dataContainer) {
        if (this.dataContainer === dataContainer) {
            return;
        }
        if (this.dataContainer) {
            this.dataContainer.removeEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
        }
        this.dataContainer = dataContainer;
        dataContainer.addEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
        this.require(RequiredViewChanges.DRAW);
    };
    AxisView.prototype.getInternalWrappedHeight = function () {
        if (!this.dataContainer) {
            return 0;
        }
        var labelPaint = this.style.labelPaint || defaultStyle.labelPaint;
        var lineHeight = labelPaint.getLineHeight();
        switch (this.orientation) {
            case AxisOrientation.VERTICAL: {
                var axisData = this.dataContainer.getYAxisData();
                var position = axisData.length * lineHeight;
                switch (labelPaint.textBaseline) {
                    case TextBaseline.MIDDLE:
                        return position + lineHeight / 2;
                    case TextBaseline.TOP:
                        return position + lineHeight;
                    case TextBaseline.BOTTOM:
                    default:
                        return position;
                }
            }
            case AxisOrientation.HORIZONTAL:
            default: {
                return lineHeight;
            }
        }
    };
    AxisView.prototype.getInternalWrappedWidth = function (canvas) {
        var _this = this;
        if (!this.dataContainer) {
            return 0;
        }
        switch (this.orientation) {
            case AxisOrientation.VERTICAL: {
                var axisData = this.dataContainer.getYAxisData().map(function (point) { return _this.getPointTextWidth(canvas, point); });
                return Math.max.apply(Math, axisData);
            }
            case AxisOrientation.HORIZONTAL:
            default: {
                var axisData = this.dataContainer.getXAxisData();
                var point = axisData[axisData.length - 1];
                var position = axisData.length;
                var width = this.getPointTextWidth(canvas, point);
                switch ((this.style.labelPaint || defaultStyle.labelPaint).textAlign) {
                    case TextAlign.CENTER:
                        return position + width / 2;
                    case TextAlign.LEFT:
                    case TextAlign.START:
                        return position + width;
                    case TextAlign.RIGHT:
                    case TextAlign.END:
                    default:
                        return position;
                }
            }
        }
    };
    AxisView.prototype.onDestroy = function () {
        if (this.dataContainer) {
            this.dataContainer.removeEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
        }
    };
    AxisView.prototype.onDraw = function (canvas) {
        var _a = this, innerWidth = _a.innerWidth, innerHeight = _a.innerHeight, _b = _a.style, _c = _b === void 0 ? defaultStyle : _b, _d = _c.wrapLabelsOnEdge, wrapLabelsOnEdge = _d === void 0 ? false : _d, _e = _c.labelPaint, labelPaint = _e === void 0 ? defaultStyle.labelPaint : _e, _f = _c.labelPadding, labelPadding = _f === void 0 ? defaultStyle.labelPadding : _f, orientation = _a.orientation;
        var axisData = this.data;
        if (!axisData.length) {
            return;
        }
        var ctx = canvas.context;
        canvas.setPaint(labelPaint);
        if (orientation === AxisOrientation.HORIZONTAL) {
            var maxWidth = (axisData[0] && axisData[1])
                ? Math.abs(axisData[0].position - axisData[1].position)
                : innerWidth;
            var y = void 0;
            switch (labelPaint.textBaseline) {
                case TextBaseline.TOP:
                    y = 0;
                    break;
                case TextBaseline.BOTTOM:
                    y = innerHeight;
                    break;
                default:
                case TextBaseline.MIDDLE:
                    y = innerHeight / 2;
                    break;
            }
            var lastEnd = -this.lp.paddingRect.l;
            for (var i = 0, l = axisData.length; i < l; i++) {
                var point = axisData[i];
                var width = ctx.measureText(point.value).width;
                var positionShift = (ctx.textAlign === 'start' || ctx.textAlign === 'left')
                    ? 0
                    : (ctx.textAlign === 'center')
                        ? -width / 2
                        : -width;
                var start = point.position + positionShift;
                var wrappedStart = wrapLabelsOnEdge ? ((start < 0) ? 0
                    : (start > innerWidth - width) ? innerWidth - width
                        : start) : start;
                if (lastEnd > wrappedStart) {
                    continue;
                }
                ctx.fillText(point.value, wrappedStart - positionShift, y, maxWidth);
                lastEnd = wrappedStart + width + labelPadding;
            }
        }
        else {
            var maxWidth = innerWidth;
            var x = void 0;
            switch (labelPaint.textAlign) {
                case TextAlign.START:
                case TextAlign.LEFT:
                    x = 0;
                    break;
                case TextAlign.END:
                case TextAlign.RIGHT:
                    x = innerWidth;
                    break;
                default:
                case TextAlign.CENTER:
                    x = innerWidth / 2;
                    break;
            }
            var height = labelPaint.getLineHeight();
            var positionShift = (ctx.textBaseline === 'bottom')
                ? -height
                : (ctx.textBaseline === 'middle')
                    ? -height / 2
                    : 0;
            var lastEnd = innerHeight + this.lp.paddingRect.b;
            for (var i = 0, l = axisData.length; i < l; i++) {
                var point = axisData[i];
                var start = point.position + positionShift;
                var wrappedStart = wrapLabelsOnEdge ? ((start < 0) ? 0
                    : (start > innerHeight - height) ? innerHeight - height
                        : start) : start;
                if (lastEnd < wrappedStart) {
                    continue;
                }
                ctx.fillText(point.value, x, point.position, maxWidth);
                lastEnd = start - height - labelPadding;
            }
        }
    };
    AxisView.prototype.onSnapshot = function () {
        return {
            style: removeUndefinedProps(this.style),
            orientation: AxisOrientation[this.orientation],
        };
    };
    AxisView.prototype.getPointTextWidth = function (canvas, point) {
        return canvas.measureText(point.value, this.style.labelPaint || defaultStyle.labelPaint).width;
    };
    AxisView.prototype.getPoints = function () {
        if (!this.dataContainer) {
            return [];
        }
        var scales = this.dataContainer.getScales(this.innerWidth, this.innerHeight);
        switch (this.orientation) {
            case AxisOrientation.HORIZONTAL:
                return prepareAxisPoints(this.dataContainer.getXAxisData(), scales.xScale);
            case AxisOrientation.VERTICAL:
            default:
                return prepareAxisPoints(this.dataContainer.getYAxisData(), scales.yScale);
        }
    };
    return AxisView;
}(View));
export { AxisView };
