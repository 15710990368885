import { queryReducer } from '+shared/store/query';

import { createReducer } from '../../../utils';
import { LEGAL_ACTIONS, LegalActions } from './legal.actions';
import { GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY, initialState, LegalState } from './legal.state';

export const reducer = createReducer<LegalState, LegalActions>(
  (state = initialState, action): LegalState => {
    switch (action.type) {
      case LEGAL_ACTIONS.SET_ACCEPTED_LEGAL_DOCUMENTS:
        return {
          ...state,
          acceptedLegalDocuments: action.acceptedLegalDocuments,
        };
      default:
        return queryReducer(state, action, [GET_ACCEPTED_LEGAL_DOCUMENTS_QUERY]);
    }
  }
);
