import { useEffect, useState } from 'react';

const useNewFeatureTooltipIntroduction = (featureName: string) => {
  const [isFeatureIntroduced, setIsFeatureIntroduced] = useState<boolean>(
    localStorage.getItem(`${featureName}`) === 'true'
  );

  useEffect(() => {
    if (isFeatureIntroduced) {
      localStorage.setItem(`${featureName}`, 'true');
    }
  }, [isFeatureIntroduced]);

  return {
    isFeatureIntroduced,
    setIsFeatureIntroduced,
  };
};

export default useNewFeatureTooltipIntroduction;
