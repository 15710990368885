import { EnergyUnit, OtherUnit } from '@sonnen/shared-web';

import { Data, MergedData } from '@coolio/json-api';

import { Resolution } from './resolution.interface';

export enum MeasurementMethod {
  BATTERY = 'battery',
  METER_GRID = 'meter-grid',
  METER_GRID_PV = 'meter-grid-pv',
  METER_GRID_PV_FEED_IN = 'meter-grid-pv-feed-in',
  METER_ERROR = 'meter-error',
}

export enum MeasurementName {
  PRODUCTION = 'production',
  CONSUMPTION = 'consumption',
  BATTERY_CHARGE = 'battery_charge',
  BATTERY_DISCHARGE = 'battery_discharge',
  GRID_FEEDIN = 'grid_feedin',
  GRID_CONSUMPTION = 'grid_consumption',
  BATTERY_STATE_OF_CHARGE = 'battery_state_of_charge',
  DIRECT_CONSUMPTION = 'direct_consumption',
}

export interface MeasurementFields {
  index: number;
  name: MeasurementName;
  unit: EnergyUnit.W | OtherUnit.PERCENT;
}

export interface SiteMeasurementsFilters {
  start: Date;
  end: Date;
}

export interface SiteMeasurementsAttributes {
  id: string;
  type: string;
  startAt: string;
  endAt: string;
  resolution: Resolution;
  fields: MeasurementFields[];
  values: Record<string, number[]>;
}

export type SiteMeasurementsData = Data<SiteMeasurementsAttributes>;
export type SiteMeasurements = MergedData<SiteMeasurementsData>;
