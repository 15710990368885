import { CarouselSlide } from '+shared/containers/Tutorial/Tutorial.helper';

export enum TooltipSide {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export interface TutorialState {
  isOpened: object;
  initialSlide: object;
  currentlyDisplayedSlides: CarouselSlide[];
}

export const initialState: TutorialState = {
  isOpened: {
    // TODO uncomment when we enable tutorial tooltips
    // casesList: localStorage.getItem('tutorial_opened_casesList') !== 'false',
    // leadsInbox: localStorage.getItem('tutorial_opened_leadsInbox') !== 'false',
  },
  initialSlide: {
    leadDetails: 0,
    setupConfigurator: 0,
    customersList: 0,
    // TODO uncomment when we enable tutorial tooltips
    // casesList: 0,
    // leadsInbox: 0,
  },
  currentlyDisplayedSlides: [],
};
