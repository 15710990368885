import * as Yup from 'yup';

import {
  batteryAccessoriesValueArray,
  batteryControllerTypeValueArray,
  contractTypeValueArray,
  optionsValueArray,
  RouteName,
  tariffTypeValueArray,
} from '+shared/store/firebase/types';

import { FieldName, NewsFormCustomerHelper } from './NewsFormCustomer.helpers';

const messageShortValidationSchema = Yup.string().max(
  130,
  'Please provide a title up to 130 characters'
);

const newsGeneralFormValidationSchema = {
  [FieldName.STATUS]: Yup.string()
    .required('Select news type')
    .oneOf(NewsFormCustomerHelper.STATUS.map((level) => level.label)),
  [FieldName.MESSAGE_SHORT_UK]: messageShortValidationSchema.required('Title is required'),
  [FieldName.MESSAGE_SHORT_US]: messageShortValidationSchema,
  [FieldName.MESSAGE_SHORT_DE]: messageShortValidationSchema,
  [FieldName.MESSAGE_SHORT_IT]: messageShortValidationSchema,
  [FieldName.MESSAGE_LONG_UK]: Yup.string(),
  [FieldName.MESSAGE_LONG_US]: Yup.string(),
  [FieldName.MESSAGE_LONG_DE]: Yup.string(),
  [FieldName.MESSAGE_LONG_IT]: Yup.string(),
  [FieldName.OPTIONS]: Yup.array().of(Yup.string().oneOf(optionsValueArray)),
  [FieldName.OPTIONS_BATTERIES_CONTROLLER_TYPE]: Yup.array().of(
    Yup.string().oneOf(batteryControllerTypeValueArray)
  ),
  [FieldName.OPTIONS_BATTERIES_ACCESSORIES]: Yup.array().of(
    Yup.string().oneOf(batteryAccessoriesValueArray)
  ),
  [FieldName.OPTIONS_CONTRACT_TYPE]: Yup.array().of(Yup.string().oneOf(contractTypeValueArray)),
  [FieldName.OPTIONS_TARIFF_TYPE]: Yup.array().of(Yup.string().oneOf(tariffTypeValueArray)),
  [FieldName.INCLUDE_IN_ROUTES]: Yup.array().of(Yup.string().oneOf(Object.values(RouteName))),
  [FieldName.EXCLUDE_IN_ROUTES]: Yup.array().of(Yup.string().oneOf(Object.values(RouteName))),
  [FieldName.EXPIRY_DATE_TIMESTAMP]: Yup.date().required('Select expiry date'),
};

// @TODO uncomment, when editing implemented
export const sendNewsFormValidationSchema = Yup.object({
  ...newsGeneralFormValidationSchema,
  // [FieldName.EXPIRY_DATE_TIMESTAMP]: Yup.date()
  //   .min(dateUtil.todayFormatted(), 'Expiry date cannot be earlier than today')
  //   .required('Select expiry date'),
});

// export const editNewsFormValidationSchema = Yup.object({
//   ...newsGeneralFormValidationSchema,
//   [FieldName.EXPIRY_DATE_TIMESTAMP]: Yup.date()
//     .required('Select expiry date'),
// });
