export var TextAlign;
(function (TextAlign) {
    TextAlign["START"] = "start";
    TextAlign["END"] = "end";
    TextAlign["LEFT"] = "left";
    TextAlign["RIGHT"] = "right";
    TextAlign["CENTER"] = "center";
})(TextAlign || (TextAlign = {}));
export var TextBaseline;
(function (TextBaseline) {
    TextBaseline["TOP"] = "top";
    TextBaseline["MIDDLE"] = "middle";
    TextBaseline["BOTTOM"] = "bottom";
})(TextBaseline || (TextBaseline = {}));
export var parseFont = function (_a) {
    var fontStyle = _a.fontStyle, fontVariant = _a.fontVariant, fontWeight = _a.fontWeight, fontSize = _a.fontSize, fontFamily = _a.fontFamily;
    return ((fontStyle ? fontStyle + ' ' : '') +
        (fontVariant ? fontVariant + ' ' : '') +
        (fontWeight ? fontWeight + ' ' : '') +
        (fontSize ? fontSize + 'px ' : '') +
        (fontFamily ? fontFamily + ' ' : '')).slice(0, -1);
};
