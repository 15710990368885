import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  DefaultParagraph,
  Icon,
  LinkButton,
  WarningText,
  WarningTextTheme,
} from '@sonnen/shared-web';

import { FormikProps } from 'formik';
import { isEmpty, isUndefined } from 'lodash/fp';

import {
  hasFlatDirectProduct,
  hasFlatXProduct,
  isHardwareOnlyOffer,
} from '+app/+lead/+overview/store/+overview.helper';
import { isNotNil } from '+app/utils';
import { TestId } from '+config/testIds';
import {
  getHardwareProduct,
  isAnyFlatFromBundleSold,
  isAnyOfferFlatOnly,
} from '+lead/+offer/store/+offer.helper';
import {
  isOfferBlocked,
  reachedOffersLimit,
} from '+lead/+overview/containers/LeadOverviewConfigurations/LeadOverviewConfigurations.helper';
import { FormErrorBanner, WidgetSimple } from '+shared/components';
import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { findBatteryInBatteryList } from '+shared/store/battery/battery.helpers';
import {
  batteryModelNameMap,
  batteryParametersToLabelMapper,
} from '+shared/store/lead/lead.helpers';
import { Lead, LeadOffer } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { renderErrorMessage } from '+utils/error.util';
import { useLocaleContext } from '+utils/react/locale.provider';
import { isStatusSet } from '+utils/status.util';

import {
  formFields,
  generateCheckboxText,
  getUniqueOffersWithHardwareProductsSortedByBundles,
  isHardwareStatusFormFieldActive,
  isOneHardwareConfirmedChecked,
  LeadEditStatusForm,
  onContactedValueChange,
  onHwAlreadySoldValueChange,
  onHwConfirmedValueChange,
  onHwSentValueChange,
  onSiteVisitArrangedValueChange,
  onSiteVisitDoneValueChange,
} from '../LeadEditStatusModal.helper';

import './LeadEditStatusHardware.component.scss';

type LeadEditStatusHardwareProps = {
  form: FormikProps<LeadEditStatusForm>;
  userCompanyName: string;
  leadStatusSummary: Lead['status']['summary'];
  hasErrors: boolean;
  offers: LeadOffer[];
  productBatteryList: LeadProductBattery[];
  isMoveToSetupEnabled: (
    leadStatusSummary: Lead['status']['summary'],
    values: LeadEditStatusForm
  ) => boolean;
  goToCreateHardwareOffer: () => void;
  displayMaxOffersWarning: () => void;
  hasDynamicTariff: boolean;
};

const {
  hwStatuses,
  hwAlreadySold,
  firstContact,
  HWOffer,
  createNewHardware: { description, descriptionExisting, link },
} = T.lead.list._salessolution_.editStatusModal;
const { editStatusModal } = T.lead.list._salessolution_;

export const LeadEditStatusHardware = ({
  form,
  userCompanyName,
  leadStatusSummary,
  hasErrors,
  offers,
  productBatteryList,
  goToCreateHardwareOffer,
  displayMaxOffersWarning,
  hasDynamicTariff,
}: LeadEditStatusHardwareProps) => {
  const { locale } = useLocaleContext();
  const { initialValues, values: formValues } = form;

  const hardwareProducts = getUniqueOffersWithHardwareProductsSortedByBundles(offers);

  const anyHardwareConfirmed = !isEmpty(initialValues.hardwareConfirmed);
  const anyFlatSold = isNotNil(leadStatusSummary.flatOfferAccepted);
  const hardwareAlreadySold = isStatusSet(leadStatusSummary.hardwareAlreadySold);
  const flatOnlyOffer = isAnyOfferFlatOnly(offers);
  const noHWProducts = isEmpty(hardwareProducts);
  const hasReachedOffersLimit = reachedOffersLimit(offers.length);

  const showHardwareOfferWidget =
    (!noHWProducts || !anyFlatSold || flatOnlyOffer) && !hasDynamicTariff;
  const createHardware = !anyHardwareConfirmed && !anyFlatSold && !hardwareAlreadySold;

  const renderCheckboxesPerOffer = (offer: LeadOffer, checkboxIndex: number) => {
    const battery = findBatteryInBatteryList(offer, productBatteryList);
    const productId = getHardwareProduct(offer)?.productId;
    const isHardwareSentActive = isHardwareStatusFormFieldActive(formFields.HARDWARE_SENT)(offer);
    const isHardwareConfirmedActive = isHardwareStatusFormFieldActive(
      formFields.HARDWARE_CONFIRMED
    )(offer);

    if (isUndefined(battery) || isUndefined(productId)) return null;

    const getProductName = (offer: LeadOffer): string => {
      if (isHardwareOnlyOffer(offer)) return I18n.t(editStatusModal.offer.hwOnly);
      if (hasFlatXProduct(offer)) return I18n.t(editStatusModal.offer.sonnenFlatX);
      if (hasFlatDirectProduct(offer)) return I18n.t(editStatusModal.offer.sonnenFlatDirect);
      return 'Unknown offer';
    };

    const batteryDetails = `${getProductName(offer)}: ${batteryModelNameMap(
      battery.modelName
    )} - ${batteryParametersToLabelMapper(locale)(battery.parameters)}`;

    return (
      <span key={checkboxIndex}>
        <p className="lead-status-modal__battery-details">{batteryDetails}</p>

        <FormFieldObserver<LeadEditStatusForm> onChange={onHwSentValueChange(offer)(form)}>
          <FormControlledCheckbox
            form={form}
            id={`${formFields.HARDWARE_SENT}[${checkboxIndex}]`}
            label={generateCheckboxText(I18n.t(hwStatuses.hwOfferSent), userCompanyName)}
            value={productId}
            name={formFields.HARDWARE_SENT}
            isConnected={true}
            isConnectionActive={Boolean(
              isHardwareConfirmedActive(formValues) && !isHardwareConfirmedActive(initialValues)
            )}
            disabled={
              Boolean(isHardwareSentActive(initialValues)) ||
              anyHardwareConfirmed ||
              hardwareAlreadySold ||
              isOfferBlocked(offer)
            }
            dataTestId="hardware-offer-sent-checkbox"
          />
        </FormFieldObserver>

        <FormFieldObserver<LeadEditStatusForm> onChange={onHwConfirmedValueChange(offer)(form)}>
          <FormControlledCheckbox
            form={form}
            id={`${formFields.HARDWARE_CONFIRMED}[${checkboxIndex}]`}
            label={generateCheckboxText(I18n.t(hwStatuses.hwConfirmed), userCompanyName)}
            value={productId}
            name={formFields.HARDWARE_CONFIRMED}
            suppressErrorHighlight={!isHardwareConfirmedActive(formValues)}
            disabled={
              !!isHardwareConfirmedActive(initialValues) ||
              anyHardwareConfirmed ||
              hardwareAlreadySold ||
              isOfferBlocked(offer)
            }
            dataTestId="hardware-offer-confirmed-checkbox"
          />
        </FormFieldObserver>
      </span>
    );
  };

  return (
    <div>
      <div className="lead-status-modal__widget-wrapper" data-testid="first-contact-table">
        <WidgetSimple
          heading={I18n.t(firstContact)}
          dataTestIdHeader="first-contact-header"
          dataTestIdContent="first-contact-content"
        >
          <FormFieldObserver<LeadEditStatusForm> onChange={onContactedValueChange(form)}>
            <FormControlledCheckbox
              form={form}
              label={generateCheckboxText(I18n.t(hwStatuses.contacted), userCompanyName)}
              isConnected={true}
              isConnectionActive={
                formValues[formFields.ON_SITE_VISIT_ARRANGED] &&
                !initialValues[formFields.CONTACTED]
              }
              name={formFields.CONTACTED}
              disabled={initialValues[formFields.CONTACTED]}
              dataTestId={TestId.Lead.StatusManager.ContactedCheckbox}
            />
          </FormFieldObserver>

          <FormFieldObserver<LeadEditStatusForm> onChange={onSiteVisitArrangedValueChange(form)}>
            <FormControlledCheckbox
              form={form}
              label={generateCheckboxText(I18n.t(hwStatuses.onSiteVisitArranged), userCompanyName)}
              isConnected={true}
              isConnectionActive={
                formValues[formFields.ON_SITE_VISIT_DONE] &&
                !initialValues[formFields.ON_SITE_VISIT_ARRANGED]
              }
              name={formFields.ON_SITE_VISIT_ARRANGED}
              disabled={initialValues[formFields.ON_SITE_VISIT_ARRANGED]}
              dataTestId={TestId.Lead.StatusManager.OnSiteVisitArrangedCheckbox}
            />
          </FormFieldObserver>

          <FormFieldObserver<LeadEditStatusForm> onChange={onSiteVisitDoneValueChange(form)}>
            <FormControlledCheckbox
              form={form}
              label={generateCheckboxText(I18n.t(hwStatuses.onSiteVisitDone), userCompanyName)}
              name={formFields.ON_SITE_VISIT_DONE}
              disabled={initialValues[formFields.ON_SITE_VISIT_DONE]}
              dataTestId={TestId.Lead.StatusManager.OnSiteVisitDoneCheckbox}
            />
          </FormFieldObserver>
        </WidgetSimple>
      </div>

      {showHardwareOfferWidget && (
        <div className="lead-status-modal__widget-wrapper" data-testid="hardware-offer-table">
          <WidgetSimple
            heading={I18n.t(HWOffer)}
            dataTestIdHeader="hardware-offer-header"
            dataTestIdContent="hardware-offer-content"
          >
            {flatOnlyOffer && (
              <>
                <p className="lead-status-modal__battery-details">
                  {I18n.t(hwAlreadySold.headline)}
                </p>
                <FormFieldObserver<LeadEditStatusForm> onChange={onHwAlreadySoldValueChange(form)}>
                  <FormControlledCheckbox
                    form={form}
                    id={formFields.HARDWARE_ALREADY_SOLD}
                    label={generateCheckboxText(I18n.t(hwStatuses.hwAlreadySold), userCompanyName)}
                    name={formFields.HARDWARE_ALREADY_SOLD}
                    disabled={
                      anyHardwareConfirmed || hardwareAlreadySold || isAnyFlatFromBundleSold(offers)
                    }
                    dataTestId={TestId.Lead.StatusManager.CustomerAlreadyOwnsBatteryCheckbox}
                  />
                </FormFieldObserver>
              </>
            )}

            {hardwareProducts.map(renderCheckboxesPerOffer)}

            {form.errors && (
              <div className="lead-status-modal__warning-wrapper">
                {form.errors.hardwareConfirmed &&
                  form.touched.hardwareConfirmed &&
                  renderErrorMessage(form.errors.hardwareConfirmed)((errorMessage) => (
                    <WarningText theme={WarningTextTheme.ERROR} text={errorMessage} />
                  ))}
              </div>
            )}

            {form.dirty &&
              (isOneHardwareConfirmedChecked(formValues) || formValues.hardwareAlreadySold) &&
              isEmpty(form.errors) && (
                <div className="lead-status-modal__warning-wrapper">
                  <WarningText
                    text={I18n.t(T.lead.hardware._salessolution_.statusModal.warning.hwConfirmed)}
                  />
                </div>
              )}

            {createHardware && (
              <>
                <DefaultParagraph className="lead-status-modal__new-hardware-paragraph">
                  {I18n.t(noHWProducts && !flatOnlyOffer ? description : descriptionExisting)}
                </DefaultParagraph>

                <LinkButton
                  className="lead-status-modal__new-hardware-link"
                  iconClass="lead-status-modal__new-hardware-link-icon"
                  onClick={
                    !hasReachedOffersLimit ? goToCreateHardwareOffer : displayMaxOffersWarning
                  }
                  icon={<Icon.Calculation />}
                  dataTestId="create-new-hardware-btn"
                >
                  {I18n.t(link)}
                </LinkButton>
              </>
            )}
          </WidgetSimple>
        </div>
      )}

      <FormErrorBanner
        isVisible={hasErrors}
        error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
      />
    </div>
  );
};
