import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { HeadlineMedium } from '+shared/basicComponents/Typography/Headlines';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { FormInput } from '+shared/components';

import { ImpactAnalysisCostsForm } from './LeadImpactAnalysisCosts.component';
import { formFields } from './LeadImpactAnalysisCosts.helper';

const ImpactAnalysisCostsBasic: React.FC<ImpactAnalysisCostsForm> = ({ form, currency }) => {
  const { nineteenPercentValueAddedTaxes, zeroPercentValueAddedTaxes } =
    T.lead.impactAnalysis._salessolution_;
  const { oneoffInvestmentCosts, totalOneoffInvestmentCosts, runningCosts, annualRunningCosts } =
    T.lead.impactAnalysis._salessolution_.costs;
  return (
    <>
      <HeadlineMedium
        className="c-impact-analysis-costs-headline-medium"
        text={I18n.t(oneoffInvestmentCosts)}
      />

      <div className="c-impact-analysis-costs-input-group">
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={<LabelMedium text={I18n.t(totalOneoffInvestmentCosts)} />}
            name={formFields.BASIC.TOTAL_ONE_OFF_INVESTMENT_COSTS}
            form={form}
            type={'number'}
            unit={currency}
            helperLabel={<BodySmall text={I18n.t(zeroPercentValueAddedTaxes)} />}
            dataTestId="total-investment-costs-field"
          />
        </div>
      </div>

      <HeadlineMedium
        className="c-impact-analysis-costs-headline-medium"
        text={I18n.t(runningCosts)}
      />

      <div className="c-impact-analysis-costs-input-group">
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={<LabelMedium text={I18n.t(annualRunningCosts)} />}
            name={formFields.BASIC.ANNUAL_RUNNING_COSTS}
            form={form}
            type={'number'}
            unit={currency}
            helperLabel={<BodySmall text={I18n.t(nineteenPercentValueAddedTaxes)} />}
            dataTestId="annual-running-costs-field"
          />
        </div>
      </div>
    </>
  );
};

export default ImpactAnalysisCostsBasic;
