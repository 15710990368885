var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getBoundaryFromContentTypeHeader, getCaseConverter, processMultipartBody } from './helpers';
import { urlDecode } from './helpers/urlEncoding.helper';
import { switchContentType } from './contentType';
export var bodyParser = function (_a) {
    var _b = _a === void 0 ? {} : _a, bodyCasing = _b.bodyCasing, customCaseConverter = _b.customCaseConverter, _c = _b.defaultParser, defaultParser = _c === void 0 ? function (raw) { return raw.arrayBuffer(); } : _c;
    var caseConverter = customCaseConverter !== null && customCaseConverter !== void 0 ? customCaseConverter : getCaseConverter(bodyCasing);
    var getContentType = function (rawResponse) { return (rawResponse.headers.get('content-type') || '')
        .split(';')
        .map(function (part) { return part.trim().toLowerCase(); })
        .filter(Boolean); };
    var bodyParsers = {
        TEXT: function (raw) { return raw.text(); },
        JSON: function (raw) { return raw.text()
            .then(function (body) { return body ? caseConverter(JSON.parse(body)) : null; })
            .catch(function (err) {
            throw new Error("Response body that was passed to bodyParser is invalid. " + err);
        }); },
        MULTIPART: function (raw) { return raw.text()
            .then(function (body) { return processMultipartBody(body, getBoundaryFromContentTypeHeader(getContentType(raw))); }); },
        URL_ENCODED: function (raw) { return raw.text()
            .then(urlDecode)
            .then(caseConverter); },
    };
    return function (rawResponse) {
        var contentType = getContentType(rawResponse)[0];
        var parsedBody = switchContentType(contentType, bodyParsers, defaultParser);
        return __assign(__assign({}, rawResponse), { parsedBody: function () { return parsedBody(rawResponse); } });
    };
};
