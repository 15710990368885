export type MeasurementForecastResolution = '15m';

export interface SiteForecast {
  serial: number;
  forecastDatetime: string;
  computedAt: string;
  resolution?: MeasurementForecastResolution;
}

export const FORECAST_VALUE_PRODUCTION = 'forecastValueProductionW';
export const FORECAST_VALUE_CONSUMPTION = 'forecastValueConsumptionW';

export interface SiteForecastProduction extends SiteForecast {
  [FORECAST_VALUE_PRODUCTION]: number;
}

export interface SiteForecastConsumption extends SiteForecast {
  [FORECAST_VALUE_CONSUMPTION]: number;
}

export type SiteForecastData = SiteForecastProduction | SiteForecastConsumption;

export interface SiteForecastProductionResponse {
  data: SiteForecastProduction[];
  message: string;
}

export interface SiteForecastConsumptionResponse {
  data: SiteForecastConsumption[];
  message: string;
}
