import * as React from 'react';
import { connect } from 'react-redux';

import { firestore } from 'firebase/app';
import { orderBy } from 'lodash';

import { NewsListTable } from '+app/+newsChannel/components/NewsListTable';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { getFirebaseNewsList } from '+shared/store/firebase/firebase.selectors';
import { GenericNews, ListItemNews, Portal } from '+shared/store/firebase/types/news.interface';
import { StoreState } from '+shared/store/store.interface';
import { dateUtil } from '+utils/date.util';
import { getNewsStatus, getNewsTimestamp, isSPNews } from '+utils/news.util';
import { mapActions } from '+utils/redux/mapActions.util';

import { EditNews } from '../EditNews';

const mapStateToProps = (state: StoreState) => ({
  newsList: getFirebaseNewsList(state),
});

const mapDispatchToProps = mapActions({
  setNewsList: FirebaseActions.setNewsList,
});

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  FirebaseProps & {
    newsPortal: Portal;
  };

export const NewsHistoryComponent: React.FC<Props> = ({
  newsPortal,
  firebase,
  newsList,
  actions,
}) => {
  const [isPending, setIsPending] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [activeNews, setActiveNews] = React.useState(undefined as GenericNews | undefined);

  React.useEffect(() => {
    getNewsList();
  }, []);

  const checkActive = (expiryDate: Date) => {
    if (!expiryDate) {
      return true;
    }
    return !dateUtil.isBeforeCurrentMinute(expiryDate);
  };

  const setNewsActive = (news: GenericNews): ListItemNews => ({
    ...news,
    isActive: isSPNews(news) ? getNewsStatus(news) : checkActive(getNewsTimestamp(news)),
  });

  const getNewsList = () => {
    firebase
      .getSortedByExpiryDateNewsList(newsPortal)
      .then((querySnapshot: firestore.QuerySnapshot) => {
        let newsList: ListItemNews[] = [];

        querySnapshot.forEach((doc: any) => {
          const news = setNewsActive(doc.data());
          newsList.push(news);
        });
        if (newsPortal === Portal.CP) {
          newsList = newsList.filter((item) => item.portals.includes(Portal.CP));
        }

        const orderedList = orderBy(newsList, ['isActive', 'date.seconds'], ['desc', 'desc']);
        actions.setNewsList(orderedList);
      })
      // @TODO display message for user
      .catch((error: Error) => console.log('Firebase error:', error))
      // @TODO move to store
      .finally(() => setIsPending(false));
  };

  const editNewsListHistory = (editedNews: GenericNews) => {
    actions.setNewsList(
      newsList.map((news) => (news.id === editedNews.id ? setNewsActive(editedNews) : news))
    );
  };

  const toggleModal = (bool: boolean) => setIsModalOpen(bool);

  const openNewsEditMode = (news: GenericNews) => {
    toggleModal(true);
    setActiveNews(news);
  };

  const deleteNews = (news: GenericNews) => {
    firebase
      .deleteNews(newsPortal, news)
      .then(() => getNewsList())
      .catch(() => console.log('Cannot delete news!')); // @TODO display message for user
  };

  return (
    <>
      <NewsListTable
        items={newsList}
        isPending={isPending}
        openNewsEditMode={openNewsEditMode}
        deleteNews={deleteNews}
        newsPortal={newsPortal}
      />
      <EditNews
        isModalOpen={isModalOpen}
        news={activeNews}
        closeModal={() => toggleModal(false)}
        onSuccessfullyEdit={editNewsListHistory}
      />
    </>
  );
};

export const NewsHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(NewsHistoryComponent));
