import { defer } from 'rxjs';

import { Config } from '+config/config';

import { jsonApiClient } from '../../network';
import { AccountType, LegalDocumentData } from './types/legal.interface';

const getAcceptedLegalDocuments = (accountType: AccountType, onlyLastAccepted: boolean) =>
  defer(() =>
    jsonApiClient
      .get<LegalDocumentData>(`${Config.API_URL}/legal/documents/accepted/`)
      .parameter('account_type', accountType)
      .parameter('only_last_accepted', onlyLastAccepted)
      .expectMany()
      .resolveIncluded()
      .send()
  );

export const LegalRepository = {
  getAcceptedLegalDocuments,
};
