import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Widget } from '+shared/components/Widget/Widget';

import { PvFeedInOperation } from '../PvFeedInOperation/PvFeedInOperation';

export const PvSystemOperationsCard: React.FC = () => (
  <Widget
    className="c-battery-operations-card"
    heading={I18n.t(T.customerSingle.pvSystemsAndMeters.pvSystemOperations.title)}
    icon="Settings"
  >
    <ul>
      <PvFeedInOperation />
    </ul>
  </Widget>
);
