import { HttpResponseError } from '@coolio/http';

export enum QueryStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

export interface Query<T = any> {
  status?: QueryStatus;
  response?: T;
  error?: HttpResponseError;
}

export interface QueryState {
  success: boolean;
  pending: boolean;
  error: boolean;
  notReady: boolean;
}
