import { __extends } from "tslib";
import { PointerAction } from '../canvas-pointer-event';
import { GestureDetector } from './gesture-detector';
var WHEEL_SCROLL_SPAN = 100;
var ScaleGestureDetector = (function (_super) {
    __extends(ScaleGestureDetector, _super);
    function ScaleGestureDetector(options) {
        var _this = _super.call(this) || this;
        _this.options = options;
        return _this;
    }
    ScaleGestureDetector.prototype.onGestureEvent = function (event) {
        return ((!!this.options.scroll && this.handleWheelGesture(event)) ||
            (!!this.options.multitouch && this.handleMultitouchGesture(event)));
    };
    ScaleGestureDetector.prototype.handleWheelGesture = function (pointerEvent) {
        if (pointerEvent.action !== PointerAction.SCROLL) {
            return false;
        }
        var _a = pointerEvent.primaryPointer, centerX = _a.x, centerY = _a.y;
        var previous = {
            centerX: centerX,
            centerY: centerY,
            spanX: WHEEL_SCROLL_SPAN,
            spanY: WHEEL_SCROLL_SPAN,
            span: Math.sqrt(2 * WHEEL_SCROLL_SPAN * WHEEL_SCROLL_SPAN),
        };
        var spanX = previous.spanX - pointerEvent.scrollY;
        var spanY = previous.spanY - pointerEvent.scrollX;
        var span = Math.sqrt(spanX * spanX + spanY * spanY);
        var current = {
            centerX: centerX,
            centerY: centerY,
            span: span,
            spanX: spanX,
            spanY: spanY,
        };
        return this.options.onScale({
            pointerEvent: pointerEvent,
            current: current,
            previous: previous,
            scaleFactor: previous.span ? current.span / previous.span : 1,
            scaleFactorX: previous.spanX ? current.spanX / previous.spanX : 1,
            scaleFactorY: previous.spanY ? current.spanY / previous.spanY : 1,
        });
    };
    ScaleGestureDetector.prototype.handleMultitouchGesture = function (pointerEvent) {
        if (pointerEvent.pointerCount !== 2) {
            this.previous = undefined;
            return false;
        }
        var x = pointerEvent.pointers[1].x - pointerEvent.pointers[0].x;
        var y = pointerEvent.pointers[1].y - pointerEvent.pointers[0].y;
        var current = {
            spanX: Math.abs(x),
            spanY: Math.abs(y),
            span: Math.sqrt(x * x + y * y),
            centerX: (pointerEvent.pointers[0].x + pointerEvent.pointers[1].x) / 2,
            centerY: (pointerEvent.pointers[0].y + pointerEvent.pointers[1].y) / 2,
        };
        if (!this.previous) {
            this.previous = current;
        }
        var previous = this.previous;
        var result = this.options.onScale({
            pointerEvent: pointerEvent,
            current: current,
            previous: previous,
            scaleFactor: previous.span ? current.span / previous.span : 1,
            scaleFactorX: previous.spanX ? current.spanX / previous.spanX : 1,
            scaleFactorY: previous.spanY ? current.spanY / previous.spanY : 1,
        });
        this.previous = current;
        return result;
    };
    return ScaleGestureDetector;
}(GestureDetector));
export { ScaleGestureDetector };
