import {
  StatisticsResolution,
  StatisticsSelectedDate,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';

import { isNil } from 'lodash';

import { SiteStatistics } from '+shared/store/site/types';
import { dateUtil } from '+utils/date.util';

import { hasMeasurement } from './+analysis.helpers';

export const transformToSiteStatisticsFilters = (
  statisticsSelectedDate: StatisticsSelectedDate
) => {
  const { date, resolution, period } = statisticsSelectedDate;
  return {
    start: dateUtil.getStartOf(date, period),
    end: dateUtil.getEndOf(date, period),
    resolution,
  };
};

export const defaultStatisticsFilters = {
  start: dateUtil.getStartOfDate(dateUtil.todayDate()),
  end: dateUtil.getEndOfDate(dateUtil.todayDate()),
  resolution: StatisticsResolution.HOUR,
};

export const hasSiteStatistics = (statistics: SiteStatistics | undefined) =>
  Boolean(
    !isNil(statistics) &&
      !isNil(statistics.measurementMethod) &&
      (hasMeasurement(statistics, StatisticsSeriesKey.PRODUCED_ENERGY) ||
        hasMeasurement(statistics, StatisticsSeriesKey.CONSUMED_ENERGY) ||
        hasMeasurement(statistics, StatisticsSeriesKey.GRID_FEEDIN_ENERGY) ||
        hasMeasurement(statistics, StatisticsSeriesKey.GRID_PURCHASE_ENERGY)) &&
      statistics.measurementMethod !== 'meter-error'
  );
