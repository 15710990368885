import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';
import uuid from 'uuid';

import { TestId } from '+config/testIds';
import { FormInputRadioGroup } from '+shared/components';

import { ConfigurationForm } from '../../store/types';
import { formFields } from './index';

interface Props {
  form: FormikProps<ConfigurationForm>;
  question: string;
}

export const ProtectInstalledRadioButtons: React.FC<Props> = ({ form, question }) => (
  <>
    <p className="lead-configuration-protect__question">{question}</p>

    <div className="lead-configuration-protect__radio-btns">
      <FormInputRadioGroup
        name={formFields.PROTECT_INSTALLED}
        form={form}
        collection={[
          {
            label: I18n.t(T.lead.offer._salessolution_.yes),
            value: true,
            dataTestId: TestId.Lead.ConfigurationCreation.ProtectInstalledYesRadioButton,
            id: uuid(),
          },
          {
            label: I18n.t(T.lead.offer._salessolution_.no),
            value: false,
            dataTestId: TestId.Lead.ConfigurationCreation.ProtectInstalledNoRadioButton,
            id: uuid(),
          },
        ]}
      />
    </div>
  </>
);
