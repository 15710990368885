import { useSelector } from 'react-redux';

import {
  getUserCountryCode,
  getUserLanguage,
  getUserProfileCustomerNumber,
  getUserProfileId,
  getUserProfileRoles,
  getUserProfileSalesforceContactId,
  getUserProfileSalesforceUserId,
} from '+shared/store/user/user.selectors';

export const useUserIdentity = () => ({
  userProfileCountryCode: useSelector(getUserCountryCode) as string,
  userProfileLanguage: useSelector(getUserLanguage) as string,
  userProfileRoles: useSelector(getUserProfileRoles),
  userProfileId: useSelector(getUserProfileId),
  userProfileCustomerNumber: useSelector(getUserProfileCustomerNumber),
  userProfileSalesforceUserId: useSelector(getUserProfileSalesforceUserId) as string,
  userProfileSalesforceContactId: useSelector(getUserProfileSalesforceContactId),
});
