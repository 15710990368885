import { roundToNearest } from './math.util';
export var prepareAxisValues = function (scale, params, seriesLength) {
    if (seriesLength === void 0) { seriesLength = 10; }
    var _a = params.labelAccessor, labelAccessor = _a === void 0 ? String : _a, isGrouped = params.isGrouped, _b = params.roundTo, roundTo = _b === void 0 ? 1 : _b, useApproximateValues = params.useApproximateValues, _c = params.tickCount, tickCount = _c === void 0 ? seriesLength : _c;
    if (useApproximateValues) {
        return scale.ticks(tickCount).map(function (tick, index) { return ({
            value: labelAccessor(tick, index),
            position: tick,
        }); });
    }
    var axis = new Array(tickCount);
    var _d = scale.domain(), min = _d[0], max = _d[1];
    var tickDistance = (max - min) / (tickCount - 1);
    for (var i = 0; i < tickCount; i++) {
        var rawValue = min + tickDistance * i;
        axis[i] = {
            value: labelAccessor(roundToNearest(rawValue, roundTo), i),
            position: isGrouped
                ? (.5 + i) * (tickCount - 1) / tickCount
                : rawValue,
        };
    }
    return axis;
};
export var prepareAxisPoints = function (axisValues, scale) {
    return axisValues.map(function (_a) {
        var position = _a.position, value = _a.value;
        return ({
            value: value,
            position: scale(position),
        });
    });
};
