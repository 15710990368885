import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { CountryCode } from '@sonnen/shared-web';

import * as Yup from 'yup';

import { UserRole } from '+shared/store/user';
import { mapCountryCodeToMarket, Market } from '+utils/market.util';

export interface RequestPartnerAccessForm {
  companyName?: string;
  userName?: string;
  userLastName?: string;
  email?: string;
  roles?: UserRole[];
}

const setRequestAccessDesc = (
  companyName: string | undefined,
  userName: string | undefined,
  userLastName: string | undefined,
  email: string | undefined,
  roles: UserRole[] | undefined
) =>
  `Company Name: ${companyName};
  User Name: ${userName};
  User Last Name: ${userLastName};
  User email: ${email};
  Roles: ${roles};`;

const requestAccessInitValues = {
  companyName: '',
  userName: '',
  userLastName: '',
  email: '',
  description: '',
  attachment: null,
  roles: [],
};

const requestAccessSchema = () =>
  Yup.object({
    companyName: Yup.string().required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
    userName: Yup.string().required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
    userLastName: Yup.string().required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
    email: Yup.string()
      .email()
      .required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
    roles: Yup.array().required(I18n.t(T.companyAccount.requestAccess.alert.atLeastOneRole)),
  });

export const getAvailableRolesForMarket = (market: Market | undefined): UserRole[] => {
  if (!market) return [];

  switch (market) {
    case Market.DE:
      return [
        UserRole.COMPANY_COORDINATOR,
        UserRole.SALES_AGENT,
        UserRole.SERVICE_COORDINATOR,
        UserRole.SETUP_COORDINATOR,
      ];
    default:
      return [UserRole.COMPANY_COORDINATOR, UserRole.SERVICE_COORDINATOR];
  }
};

const getRolesCollection = (
  userCountry: CountryCode | undefined
): Array<{ value: string; label: string }> => {
  if (!userCountry) return [];

  const market = mapCountryCodeToMarket(userCountry);
  const roles = getAvailableRolesForMarket(market);

  return roles.map((role) => ({
    value: role,
    label: I18n.t(T.myAccount.personalDetails.rolesNames[role]),
  }));
};

export const RequestAccessFormHelper = {
  requestAccessInitValues,
  requestAccessSchema,
  getRolesCollection,
  setRequestAccessDesc,
};
