import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';

import { CostRow } from '../LeadImpactAnalysisCalculationResults.helper';
import { PageDetailedCalculation20YearsData } from '../LeadImpactAnalysisCalculationResults.types';

import '../LeadImpactAnalysisCalculationResults.scss';

const SonnenFlatCostsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  const { nineteenPercentValueAddedTaxes } = T.lead.impactAnalysis._salessolution_;

  return data.sonnenFlatBenefitsAfter20Years.cumulative !== undefined ? (
    <div
      className={`c-impact-analysis-results
        c-impact-analysis-results-box-dark-blue`}
    >
      {!isNil(data.sonnenFlatBenefitsAfter20Years.monthlyPrepaymentExcessEnergy) && (
        <CostRow
          sign={'-'}
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.costsTitle)}
          textType={'labelLarge'}
          textHelperLabel={`(${I18n.t(nineteenPercentValueAddedTaxes)})`}
          value={data.sonnenFlatBenefitsAfter20Years.monthlyPrepaymentExcessEnergy}
        />
      )}
      <LineDivider className="c-impact-analysis-results__line-divider" />
      <CostRow
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.prepayment)}
        textType={'bodyMedium'}
      />
    </div>
  ) : (
    <div />
  );
};

export default SonnenFlatCostsAfter20Years;
