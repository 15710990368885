import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { MediaQuery } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';

import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import {
  getAssignLeadQueryStatus,
  getIsAssignLeadModalOpen,
  getPartnerEmployeeList,
  getSelectedLead,
} from '+app/+lead/store/+lead.selectors';
import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { LeadListRouteQueryParams, PATHS } from '+app/router';
import { getDecryptedRouteQueryParams } from '+app/router/store/router.selectors';
import { LeadListAssignModal } from '+lead/+list/components/LeadListAssignModal';
import { LeadListPageActions } from '+lead/+list/store';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import {
  Container,
  PageBlock,
  Pagination,
  PaginationSummary,
  SearchField,
} from '+shared/components';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserProfileCustomerNumber,
  getUserProfileRoles,
  getUserProfileSalesforceContactId,
} from '+shared/store/user/user.selectors';
import { encryptSearchTerm } from '+utils/crypto.util';
import { mapActions } from '+utils/redux';

import { LeadListEmpty } from '../../components/LeadListEmpty';
import { LeadListTable } from '../../components/LeadListTable';
import {
  getLeadCollection,
  getLeadsCollectionQueryStatus,
  getLeadsCollectionTotalCount,
  getLeadStatusFilters,
} from '../../store/+leadList.selectors';
import { LeadListStatusFilter } from '../LeadListStatusFilter';

import './LeadList.component.scss';

const mapStateToProps = (state: StoreState) => ({
  leadCollection: getLeadCollection(state),
  leadCollectionTotalCount: getLeadsCollectionTotalCount(state),
  leadCollectionQueryStatus: getLeadsCollectionQueryStatus(state),
  routerQueryParams: getDecryptedRouteQueryParams(state) as Required<LeadListRouteQueryParams>,
  partnerEmployeeList: getPartnerEmployeeList(state),
  userRoles: getUserProfileRoles(state),
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  chosenLead: getSelectedLead(state),
  isAssignLeadModalOpen: getIsAssignLeadModalOpen(state),
  userProfileSalesforceContactId: getUserProfileSalesforceContactId(state),
  assignLeadQueryStatus: getAssignLeadQueryStatus(state),
  statusFilters: getLeadStatusFilters(state),
  userProfileCustomerNumber: getUserProfileCustomerNumber(state),
});

const mapDispatchToProps = mapActions({
  goToLead: (leadId: string) => push(PATHS.LEAD_OVERVIEW({ leadId })),
  goToCreateLead: () => push(PATHS.LEAD_NEW()),
  goToLeadOffer: (leadId: string) => push(PATHS.LEAD_CONFIGURATION({ leadId })),
  pushLeadsPage: (queryParams: Required<LeadListRouteQueryParams>) =>
    push(PATHS.LEADS(queryParams)),
  getPartnersEmployeeList: LeadPageActions.getPartnersEmployeeList,
  toggleModal: LayoutActions.toggleModal,
  setChosenInboxLead: LeadPageActions.setSelectedLead,
  toggleAssignLeadModal: LeadPageActions.toggleAssignLeadModal,
  reassignPartnerToLead: LeadListPageActions.reassignPartnerToLead,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<LeadListRouteQueryParams>;

/**
 * @note: Mocked list view
 * @TODO: Implement the view when needed
 */
const LeadListComponent: React.FC<Props> = ({
  actions,
  leadCollection,
  leadCollectionTotalCount,
  leadCollectionQueryStatus,
  routerQueryParams,
  partnerEmployeeList,
  userRoles,
  isModalOpen,
  openModalId,
  chosenLead,
  isAssignLeadModalOpen,
  userProfileSalesforceContactId,
  assignLeadQueryStatus,
  statusFilters,
  userProfileCustomerNumber,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.LEADS, PageName.Leads.LEADS_LIST);

  React.useEffect(() => {
    actions.getPartnersEmployeeList();
  }, []);

  const shouldShowPagination = leadCollectionQueryStatus.success || leadCollectionQueryStatus.error;

  const queryLeads = (newParams: LeadListRouteQueryParams = {}) =>
    actions.pushLeadsPage(
      encryptSearchTerm({ ...routerQueryParams, ...newParams }, userProfileCustomerNumber)
    );

  const onPageChange = (page: number | undefined) => queryLeads({ page: String(page) });

  const onSearch = (search?: string) => queryLeads({ search, page: '1' });

  const onFilter = () => queryLeads({ page: '1' });

  const hasNoResults =
    isEmpty(leadCollection) &&
    (routerQueryParams.search || !isEmpty(statusFilters)) &&
    !leadCollectionQueryStatus.pending;

  return (
    <Container>
      <div className={'c-lead-list__header'}>
        <div className={'c-lead-list__search-field'}>
          <Media query={MediaQuery.UP_SM}>
            {(isMedium: boolean) => (
              <SearchField
                value={routerQueryParams.search}
                onSearch={onSearch}
                placeholder={I18n.t(T.lead.list._salessolution_.search.placeholder)}
                isAutoFocus={isMedium}
                dataTestIdInputField="leads-overview-search-field"
                dataTestIdBtn="leads-overview-search-btn"
              />
            )}
          </Media>
        </div>
        <div className={'c-lead-list__status-filter'}>
          <LeadListStatusFilter getLeadList={onFilter} />
        </div>
      </div>

      <PaginationSummary
        i18n={T.lead.list._salessolution_.leadsSummary}
        page={routerQueryParams.page}
        recordsTotal={leadCollectionTotalCount}
      />
      <PageBlock>
        <LeadListTable
          items={leadCollection}
          isPending={leadCollectionQueryStatus.pending}
          onLeadClick={actions.goToLead}
          partnerEmployeeList={partnerEmployeeList}
          userRoles={userRoles}
          isModalOpen={openModalId === ModalId.LEAD_EDIT_STATUS}
          toggleModal={actions.toggleModal}
          toggleAssignLeadModal={actions.toggleAssignLeadModal}
          setChosenLead={actions.setChosenInboxLead}
        />
        {hasNoResults && <LeadListEmpty />}
      </PageBlock>
      {shouldShowPagination && (
        <PageBlock className={`c-lead-list__pagination`}>
          <Pagination
            page={routerQueryParams.page}
            recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
            recordsTotal={leadCollectionTotalCount}
            onPageChange={onPageChange}
            dataTestIdBackBtn="leads-overview-pagination-back-btn"
            dataTestIdNextBtn="leads-overview-pagination-next-btn"
          />
        </PageBlock>
      )}

      <LeadListAssignModal
        isModalOpen={isModalOpen && isAssignLeadModalOpen}
        toggleAssignLeadModal={actions.toggleAssignLeadModal}
        toggleModal={actions.toggleModal}
        chosenLead={chosenLead}
        partnerEmployeeList={partnerEmployeeList}
        assignPartner={actions.reassignPartnerToLead}
        userProfileSalesforceContactId={userProfileSalesforceContactId}
        isPending={assignLeadQueryStatus.pending}
        isError={assignLeadQueryStatus.error}
      />
    </Container>
  );
};

export const LeadList = connect(mapStateToProps, mapDispatchToProps)(LeadListComponent);
