import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { defaults } from 'lodash/fp';

import { provideLocale } from '+app/i18n/i18n.helpers';

import { CurrencyUnit, EnergyUnit, HOUR_UNIX, MINUTE_UNIX, PeakPowerUnit } from '../App.constants';
import { provideCurrentLocale } from '../i18n/i18n.provider';
import { dateUtil } from './date.util';
import { getFahrenheitFromCelsius } from './temperature';
import { UtilDuration } from './UtilDuration';

/**
 * @deprecated use localized `formatNumber` from `format.util.tsx` instead
 */
export const formatNumber =
  ({ precision = 0, minPrecision = precision }: { precision?: number; minPrecision?: number }) =>
  (value: number): string =>
    value.toLocaleString(provideCurrentLocale(), {
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: precision,
    });

export const formatTime = (time: Date): string => dateUtil.format(time, 'H:mm');

export const formatDuration = (duration: string): string => {
  let seconds = UtilDuration.ofString(duration).asSeconds();
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;

  const pad = (num: number) => ('0' + num).slice(-2);

  const hoursString = hours > 0 ? `${pad(hours)}:` : '';

  return `${hoursString}${pad(minutes)}:${pad(seconds)}`;
};

export const formatTimeBetween = (start: Date, end: Date): string => {
  const seconds = Math.abs(
    dateUtil.getUnixFromDateInSeconds(end) - dateUtil.getUnixFromDateInSeconds(start)
  );
  const hours = Math.floor(seconds / HOUR_UNIX);
  const minutes = Math.floor(seconds / MINUTE_UNIX - hours * MINUTE_UNIX);
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes} h`;
};

export const formatSerialNumber = (serialNumber: string) => `# ${serialNumber}`;

/**
 * @deprecated use localized `formatPercentage` from `format.util.tsx` instead
 */
export const formatPercentage = (value: number, precision?: number) =>
  `${formatNumber({ precision })(value)}%`;

/**
 * @deprecated use localized `formatEnergy` from `format.util.tsx` instead
 */
export const formatEnergy = ({
  whValue,
  unit = EnergyUnit.WH,
  precision = 1,
  minPrecision = precision,
}: {
  whValue: number;
  unit: EnergyUnit;
  precision: number;
  minPrecision?: number;
}) =>
  I18n.t(unit === EnergyUnit.KWH ? T.units.energyKwh : T.units.energyWh, {
    value: formatNumber({ precision, minPrecision })(
      unit === EnergyUnit.KWH ? whValue / 1000 : whValue
    ),
  });

/**
 * @deprecated use localized `formatPower` from `format.util.tsx` instead
 */
export const formatPower = (powerW: number, unit: EnergyUnit = EnergyUnit.W, precision = 0) =>
  I18n.t(unit === EnergyUnit.KW ? T.units.energykW : T.units.energyW, {
    value: formatNumber({ precision })(unit === EnergyUnit.KW ? powerW / 1000 : powerW),
  });

/**
 * @deprecated use localized `formatPeakPower` from `format.util.tsx` instead
 */
export const formatPeakPower = (
  wpValue: number,
  unit: PeakPowerUnit = PeakPowerUnit.WP,
  precision = 1
) =>
  I18n.t(unit === PeakPowerUnit.KWP ? T.units.peakPowerKwp : T.units.peakPowerWp, {
    value: formatNumber({ precision })(unit === PeakPowerUnit.KWP ? wpValue / 1000 : wpValue),
  });

export const formatBatteryConsumption = (value: number) =>
  I18n.t(T.units.batteryConsumption, { value: formatNumber({ precision: 1 })(value) });

export const formatAmperage = (value: number) =>
  I18n.t(T.units.amperage, {
    value: formatNumber({ precision: value <= 0.01 ? 0 : value < 10 ? 1 : 0 })(value),
  });

export const formatVoltage = (value: number): string =>
  `${formatNumber({ precision: 2 })(value)} V`;

export const checkEnergyUnit = (value: number) => (value < 1000 ? EnergyUnit.WH : EnergyUnit.KWH);

export const formatDegree = (value: number | string) => `${value}°`;

export const formatCelsiusDegree = (value: number): string => `${value} °C`;

export const formatTemperature = (value: number): string =>
  provideLocale() === 'en-us'
    ? `${getFahrenheitFromCelsius(value)} °F`
    : formatCelsiusDegree(value);

interface FormatCurrencyOpts {
  isMinorUnit?: boolean;
  precision?: number;
}

export const formatCurrency = (
  value: number | string,
  currency: CurrencyUnit,
  opts?: FormatCurrencyOpts
): string => {
  const { isMinorUnit, precision } = defaults({
    isMinorUnit: false,
    precision: 2,
  })(opts);
  const formattedValue = formatNumber({ precision })(Number(value));

  switch (currency) {
    case CurrencyUnit.CURRENCY_EUR:
      if (isMinorUnit) {
        return `${formattedValue} ct`;
      }
      return `${formattedValue} €`;
    default:
      return String(value);
  }
};

export const formatSquareMeters = (value: number | string) => `${value} m²`;

export const formatTextWithSpecialCharacters = (text: string) =>
  text.replace(/ß/g, `<span style="text-transform: none">ß</span>`);
