import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { ModalLayout } from '@sonnen/shared-web';

import { Formik } from 'formik';

import { Button, ButtonType, MainType } from '+shared/components/Button';
import { FormInput } from '+shared/components/Form/FormInput';

import { initialValues, resetPasswordSchema } from './ResetPasswordForm.helper';
import { ResetPasswordForm as ResetPasswordFormValues } from './types/ResetPasswordForm.interfaces';

import './ResetPasswordForm.component.scss';

interface Props {
  submitForm: (values: ResetPasswordFormValues) => void;
  onClose: () => void;
  isLoading: boolean;
}

export const ResetPasswordForm: React.FC<Props> = ({ submitForm, onClose, isLoading }) => {
  const onFormSubmit = (values: ResetPasswordFormValues) => submitForm(values);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordSchema}
      onSubmit={onFormSubmit}
      render={(form) => (
        <form onSubmit={form.handleSubmit} className={'c-reset-password-form'}>
          <ModalLayout
            title={I18n.t(T.resetPassword.form.title)}
            footer={
              <div className={'c-reset-password-form__button-wrapper'}>
                <Button
                  label={I18n.t(T.resetPassword.buttons.cancel)}
                  type={ButtonType.TERTIARY}
                  onClick={onClose}
                  className={'c-reset-password-form__button'}
                />
                <Button
                  label={I18n.t(T.resetPassword.buttons.save)}
                  type={ButtonType.PRIMARY}
                  loading={isLoading}
                  mainType={MainType.SUBMIT}
                  className={'c-reset-password-form__button'}
                />
              </div>
            }
          >
            <div className={'c-reset-password-form__body'}>
              <FormInput
                form={form}
                label={I18n.t(T.resetPassword.form.previousPassword)}
                name={'previousPassword'}
                type={'password'}
                className={'c-reset-password-form__input'}
              />
              <FormInput
                form={form}
                label={I18n.t(T.resetPassword.form.password)}
                name={'password'}
                type={'password'}
                className={'c-reset-password-form__input'}
              />
              <FormInput
                form={form}
                label={I18n.t(T.resetPassword.form.confirmPassword)}
                name={'passwordConfirmation'}
                type={'password'}
                className={'c-reset-password-form__input'}
              />
            </div>
          </ModalLayout>
        </form>
      )}
    />
  );
};
