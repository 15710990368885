import { SetupDataStepStatus } from '+setupTool/store/+setupTool.dictionary';

import { FileCategoryName } from '../+form.dictionary';

export enum DsoRegistrationRadioInvestmentType {
  NEW = 'new_pv_asset',
  EXTENSION = 'pv_extension',
}

export enum DsoRegistrationRadioType {
  YES = 'Ja',
  NO = 'Nein',
}

export enum CouplingType {
  AC = 'AC',
  DC = 'DC',
}

export type NumberField = number | '';

export interface DsoRegistrationCustomerDataInterface {
  customer_salutation: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_street?: string;
  customer_street_number?: string;
  origin_customer_street_and_number: string;
  customer_zip_code: string;
  customer_city: string;
  customer_email: string;
  customer_phone: string;
  customer_birthday: string;
  customer_type: DsoRegistrationRadioType;
  asset_street?: string;
  asset_street_number?: string;
  origin_asset_street_and_number: string;
  asset_zip_code: string;
  asset_city: string;
  lot_number: string;
  dso_id: string;
  is_online_portal: boolean;
  [FileCategoryName.POWER_OF_ATTORNEY]?: string;
}

export interface DsoRegistrationInstallerDataInterface {
  installer_id?: string;
  installer_company: string;
  installer_first_name: string;
  installer_last_name: string;
  installer_full_name?: string;
  installer_company_street: string;
  installer_company_street_number: string;
  installer_zip_code: string;
  installer_city: string;
  installer_email: string;
  installer_phone: string;
  installer_certification_number: string;
  installer_certification_authority: string;
  user_name?: string;
  password?: string;
  is_online_portal?: boolean;
}

export interface DsoRegistrationInstallerDataInterfaceForCreatingInstaller {
  company: string;
  first_name: string;
  last_name: string;
  company_street: string;
  company_street_number: string;
  zip_code: string;
  city: string;
  email: string;
  phone: string;
  certification_number: string;
  certification_authority: string;
}

export interface DsoRegistrationDocumentsInterface {
  standardSolarCircuitDiagram: boolean;
  [FileCategoryName.SITE_PLAN]?: string;
  [FileCategoryName.WIRING_DIAGRAM]?: string;
  [FileCategoryName.ADDITIONAL_DOCUMENTS]?: string;
}

export interface DsoRegistrationPvSystemInterface {
  pv_type: DsoRegistrationRadioInvestmentType;
  preferred_installation_date?: string;
  panel_location?: string;
  panel_alignment?: string;
  tilt_angle?: string;
  pv_module_id: string;
  pv_module_type: string;
  pv_module_count: number;
  dc_coupled_battery?: boolean;
  pv_inverter_id: string;
  pv_inverter_type: string;
  number_identical_inverters: number;
  // NEW
  new_pv_size?: number | null;
  // Extension
  date_of_application?: string;
  existing_pv_size?: number | null;
  existing_pv_infeed?: string;
  mastr_number?: string;
  existing_pv_inverter_manufacturer?: string;
  existing_pv_inverter_type?: string;
  existing_pv_inverter_count?: number;
  total_pv_size?: number;
}

export type DcCoupledBatteryFlag = Pick<DsoRegistrationPvSystemInterface, 'dc_coupled_battery'>;
export type DsoRegistrationCredentials = Pick<
  DsoRegistrationInstallerDataInterface,
  'user_name' | 'password'
>;

export interface DsoRegistrationSonnenBatteryInterface {
  battery_name: string;
  battery_id: string;
  battery_nominal_capacity_kWh: string;
  preferred_installation_date?: string;
}

export interface DsoRegistrationMeasuringDeviceInterface {
  meter_number: string;
  type_of_grid_reference_meter: string;
  measuring_concept?: string;
  feed_in_management?: string;
  serial_number_FRE: string;
  house_connection_power_A: number | null;
  meter_fuse: number | null;
  meter_fastening_type: string;
}

export interface PVModuleInterface {
  id: string;
  name: string;
  peakPowerkWp?: number;
}

export interface PVModuleSingleState {
  id: string;
  name: string;
  modules?: PVModuleInterface[];
}

export interface PVInverterInterface {
  id: string;
  name: string;
  peakPowerkWp?: number;
}

export interface PVInverterSingleState {
  id: string;
  name: string;
  inverters?: PVInverterInterface[];
}

export interface BatterySingleState {
  id: string;
  name: string;
  couplingType: CouplingType;
  batteries: BatteryInterface[];
}

export interface BatteryInterface {
  id: string;
  name: string;
  nominalCapacitykWh: string;
  nominalPowerkW: string;
  displayPower?: boolean;
}

export interface DsoRegistrationSetupDataStatusesInterface {
  customerData?: SetupDataStepStatus;
  pvSystem?: SetupDataStepStatus;
  sonnenBattery?: SetupDataStepStatus;
  measuringDevice?: SetupDataStepStatus;
  uploadDocuments?: SetupDataStepStatus;
  installerData?: SetupDataStepStatus;
}
