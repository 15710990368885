import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';

import { LeadForm } from '+app/+lead/store/types';
import { LabelLarge } from '+shared/basicComponents/Typography/Labels';
import { FormInput, FormInputPhone, FormInputRadio, FormInputSelect } from '+shared/components';
import { salutation as leadSalutation } from '+shared/store/lead';
import { LeadCustomerType } from '+shared/store/lead/types';

import './LeadNewCustomerDetails.component.scss';

const {
  dictionary,
  form: {
    personalDetails: {
      customerDetails,
      salutation,
      title,
      company,
      firstName,
      lastName,
      customerEmail,
      phone,
      mobilePhone,
    },
  },
} = T.lead.boc._salessolution_;

const getCountryCode = ({ values }: FormikProps<LeadForm>) => values.deliveryAddress.country;
interface Props {
  form: FormikProps<LeadForm>;
}

export const LeadNewCustomerDetails: React.FC<Props> = ({ form }) => (
  <>
    <LabelLarge text={I18n.t(customerDetails)} />

    <div className="c-lead-new-customer-details">
      <div className={'c-lead-new-customer-details__radio-group'}>
        <FormInputRadio
          form={form}
          label={I18n.t(dictionary.customerType[LeadCustomerType.PRIVATE])}
          name={'customerType'}
          value={LeadCustomerType.PRIVATE}
          dataTestId="private-person-radio"
        />
        <FormInputRadio
          form={form}
          label={I18n.t(dictionary.customerType[LeadCustomerType.BUSINESS])}
          name={'customerType'}
          value={LeadCustomerType.BUSINESS}
          dataTestId="business-customer-radio"
        />
      </div>

      <div className={'c-lead-new-customer-details__input-wrapper--short'}>
        <FormInputSelect
          className={'c-lead-new-customer-details__input-field'}
          form={form}
          collection={leadSalutation}
          name={'salutation'}
          label={I18n.t(salutation)}
          mapper={(key) => I18n.t(dictionary.salutation[key])}
          dataTestId="salutation-dropdown"
          dropDownOptionsDataTestId="salutation-dropdown-option"
        />

        <FormInput
          className={'c-lead-new-customer-details__input-field'}
          form={form}
          label={I18n.t(title)}
          name={'title'}
          isRequired={false}
          dataTestId="title-field"
        />
      </div>

      {form.values.customerType === LeadCustomerType.BUSINESS && (
        <FormInput
          form={form}
          label={I18n.t(company)}
          name={'company'}
          dataTestId="company-field"
        />
      )}

      <div className={'c-lead-new-customer-details__input-wrapper'}>
        <FormInput
          className={'c-lead-new-customer-details__input-field'}
          form={form}
          label={I18n.t(firstName)}
          name={'firstName'}
          dataTestId="first-name-field"
        />

        <FormInput
          className={'c-lead-new-customer-details__input-field'}
          form={form}
          label={I18n.t(lastName)}
          name={'lastName'}
          dataTestId="last-name-field"
        />
      </div>

      <div className={'c-lead-new-customer-details__input-wrapper'}>
        <FormInput
          className={'c-lead-new-customer-details__input-field'}
          form={form}
          label={I18n.t(customerEmail)}
          name={'email'}
          dataTestId="customer-email-field"
        />
        <FormInputPhone
          className={'c-lead-new-customer-details__input-field'}
          country={getCountryCode(form)}
          form={form}
          label={I18n.t(phone)}
          name={'phone'}
          dataTestId="phone-number-field"
        />
      </div>
      <div className={'c-lead-new-customer-details__input-wrapper--single'}>
        <FormInputPhone
          country={getCountryCode(form)}
          form={form}
          label={I18n.t(mobilePhone)}
          name={'mobilePhone'}
          isRequired={false}
          dataTestId="mobile-phone-number-field"
        />
      </div>
    </div>
  </>
);
