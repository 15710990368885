import { deepKeyMap, splitWords } from '@coolio/http';

import { Config } from '+config/config';

export const isAuthorizedDomain = (url: string): boolean => {
  const { hostname } = new URL(url);

  return Config.AUTHORIZED_DOMAINS.some(
    (domain) => hostname === domain || hostname.endsWith(`.${domain}`)
  );
};

export const toCamelCaseExceptForDates = (object: any) =>
  deepKeyMap(object, (key) => {
    if (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(key)) {
      return key;
    }

    return splitWords(key)
      .map((word, index) =>
        index > 0 ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
      )
      .join('');
  });
