import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { AccordionButton } from '+shared/components';
import { LeadAttributes, LeadConfigurationResponseAttributes } from '+shared/store/lead/types';

import { LeadConfigurationBox } from '../LeadConfigurationBox';
import {
  factorizeFifthBoxDetails,
  factorizeFirstBoxDetails,
  factorizeFourthBoxDetails,
  factorizeSecondBoxDetails,
  factorizeThirdBoxDetails,
  getCurrencyForMarket,
} from './LeadConfigurationPreview.helper';

import './LeadConfigurationPreview.component.scss';

interface Props {
  configProposal: LeadConfigurationResponseAttributes;
  lead: LeadAttributes;
}

export const LeadConfigurationPreview: React.FC<Props> = ({ configProposal }) => {
  const [areSectionsOpen, setAreSectionsOpen] = useState<boolean>(false);

  const { showDetails, showLess } = T.lead.configuration._salessolution_.summary.detailsButton;

  const currency = getCurrencyForMarket(configProposal ? configProposal.market : undefined);

  // These helpers return the result for smart_enterprise taxation
  const factorizedConfDetails = [
    factorizeFirstBoxDetails(configProposal, currency),
    factorizeSecondBoxDetails(configProposal, currency),
    factorizeThirdBoxDetails(configProposal, currency),
    factorizeFourthBoxDetails(configProposal, currency),
    factorizeFifthBoxDetails(configProposal, currency),
  ];

  return (
    <div className={'c-lead-configuration-preview'}>
      {factorizedConfDetails.map((factorizedConfDetail, index) => (
        <div className={'c-lead-configurtion-preview__box'} key={`configuration-preview-${index}`}>
          <LeadConfigurationBox
            parameters={factorizedConfDetail}
            key={index}
            areSectionsOpen={areSectionsOpen}
          />
        </div>
      ))}
      <div className="c-lead-configuration-preview__button">
        <AccordionButton
          isAccordionOpen={areSectionsOpen}
          setAccordionOpen={(isAccordionOpen) => setAreSectionsOpen(isAccordionOpen)}
          messageOpen={I18n.t(showLess)}
          messageClosed={I18n.t(showDetails)}
        />
      </div>
    </div>
  );
};
