import * as React from 'react';

import * as classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import { get } from 'lodash/fp';

import useInputEvent from '+shared/hooks/useInputEvent';

import './FormInputRadio.component.scss';

interface Props<T, U> {
  name: string;
  label: string | JSX.Element;
  value: U;
  form: FormikProps<T>;
  className?: ClassValue;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  text?: string;
  dataTestId?: string;
  id?: string;
}

type ObservableInputProps = FieldProps & {
  fieldValue: string;
  dataTestId?: string;
};

const ObservableInputElement = ({
  form,
  field,
  fieldValue,
  dataTestId,
  ...props
}: ObservableInputProps) => {
  const { onChange } = useInputEvent(field);

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange(e);
    form.setFieldValue(field.name, fieldValue);
    form.setFieldTouched(field.name);
  };

  const checked = get(field.name)(form.values) === fieldValue;

  return (
    <input
      {...props}
      {...field}
      value={fieldValue}
      checked={checked}
      onChange={handleOnChange}
      data-testid={dataTestId}
    />
  );
};

export const FormInputRadio = <T extends any = any, U extends any = any>({
  name,
  label,
  value,
  className,
  onClick,
  disabled = false,
  text,
  dataTestId,
  id,
}: Props<T, U>) => {
  const calculatedId = id ?? name + '-' + value;

  return (
    <div className={classNames('c-form-input-radio', className)}>
      <Field
        component={ObservableInputElement}
        name={name}
        className={'c-form-input-radio-radio'}
        type={'radio'}
        id={calculatedId}
        fieldValue={value}
        dataTestId={dataTestId}
        onClick={onClick}
        disabled={disabled}
      />
      <label className={'c-form-input-radio-label'} htmlFor={calculatedId}>
        <span className={'c-form-input-radio-circle'} />
        {label}
      </label>
      <div className={'c-form-input-radio__text'}>{text}</div>
    </div>
  );
};
