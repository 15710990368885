import { createSelector } from 'reselect';

import { StoreState } from '../store.interface';

export const getLegal = (state: StoreState) => state.shared.legal;

export const getAcceptedLegalDocuments = createSelector(
  getLegal,
  (legal) => legal.acceptedLegalDocuments
);
