import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import { PATHS } from '+app/router';
import { history } from '+app/router/store/router.reducer';
import { FormBanner } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';
import { AssignedBattery } from '+shared/store/partner/types/assignedBattery.interface';
import { ClaimBatteryAttributes } from '+shared/store/partner/types/claimBattery.interface';
import { QueryState } from '+shared/store/query';

import { MissingBatteryModalLayout } from '../MissingBatteryModalLayout';

import './MissingBatteryClaimSuccess.component.scss';

interface Props {
  closeMissingBatteryModal: () => void;
  assignedBattery: AssignedBattery;
  claimBatteryData: ClaimBatteryAttributes;
  queryState: QueryState;
}

const getNoConsentBannerMessage = (closeAndRedirect: (path: string) => void) => {
  return (
    <p>
      {I18n.t(T.missingBattery.banner.noConsent.linkPrefix)}
      &nbsp;
      <Button
        label={I18n.t(T.missingBattery.banner.noConsent.link)}
        type={ButtonType.TERTIARY}
        onClick={() => closeAndRedirect(`${PATHS.HELP()}#partner-portal&open=0`)}
      />
      &nbsp;
      {I18n.t(T.missingBattery.banner.noConsent.linkSufix)}
    </p>
  );
};

const createField = ({ label, value }: { label: string; value: string | number }) => (
  <div className={'c-missing-battery-claim-success__field'}>
    <span className={'c-missing-battery-claim-success__field-label'}>{label}</span>
    <span className={'c-missing-battery-claim-success__field-value'}>{value}</span>
  </div>
);

export const MissingBatteryClaimSuccess: React.FC<Props> = ({
  closeMissingBatteryModal,
  assignedBattery,
  claimBatteryData,
  queryState,
}) => {
  const closeAndRedirect = (path: string) => {
    closeMissingBatteryModal();
    history.push(path);
  };
  const message = I18n.t(T.missingBattery.banner.claimSuccess);

  const isConsentGiven =
    assignedBattery.privacyPolicyAccepted && assignedBattery.partnerMonitoringAccepted;

  return (
    <MissingBatteryModalLayout
      isLoading={false}
      canSubmit={true}
      buttonLabel={
        isConsentGiven
          ? I18n.t(T.missingBattery.button.customersOverview)
          : I18n.t(T.missingBattery.button.customersList)
      }
      onSubmit={() =>
        isConsentGiven
          ? closeAndRedirect(
              PATHS.CUSTOMER({
                customerId: assignedBattery.id,
                siteId: assignedBattery.siteId,
              })
            )
          : // TODO: adjust below path when decided if API will be modified
            // in order to respond with customer Id
            closeAndRedirect(PATHS.CUSTOMERS())
      }
    >
      <div className={'c-missing-battery-claim-success__banner'}>
        <FormBanner
          iconPositionTop={true}
          isVisible={true}
          type={'default'}
          message={<span dangerouslySetInnerHTML={{ __html: message }} />}
          icon={
            <Icon.DoneCircle
              secondaryColor={'#fff'}
              className={'c-missing-battery-claim-success__done-icon'}
            />
          }
          boxShadow={false}
        />
      </div>
      <div className={'c-missing-battery-claim-success__banner'}>
        <FormBanner
          iconPositionTop={true}
          isVisible={!isConsentGiven}
          type={'default'}
          message={getNoConsentBannerMessage(closeAndRedirect)}
          // TODO: tidy up icons logic in SW
          icon={
            <div className={'c-missing-battery-claim-success__padlock-icon-circle'}>
              <Icon.Padlock className={'c-missing-battery-claim-success__padlock-icon'} />
            </div>
          }
          boxShadow={false}
        />
      </div>
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6'} data-hj-suppress={true}>
          {createField({
            label: I18n.t(T.missingBattery.field.serialNumber),
            value: claimBatteryData.serialNumber,
          })}
          {createField({
            label: I18n.t(T.missingBattery.field.lastName),
            value: claimBatteryData.customerLastName,
          })}
        </div>
        <div className={'o-grid__column o-grid__column--md-6'}>
          {createField({
            label: I18n.t(T.missingBattery.field.postalCode),
            value: claimBatteryData.postalCode,
          })}
          {createField({
            label: I18n.t(T.missingBattery.field.pvPeakPower),
            value: `${claimBatteryData.pvPeakPower}`,
          })}
        </div>
      </div>
    </MissingBatteryModalLayout>
  );
};
