import React, { useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  AnalysisDatePicker,
  AnalysisToolbar,
  DateShift,
  DateSwitcher,
  getMinimalMeasurementDate,
  getResolutionFromPeriod,
  Icon,
  shiftDate,
  TimeUnit,
} from '@sonnen/shared-web';

import {
  getBattery,
  getBatteryInstallationDate,
} from '+app/+customer/+battery/store/+battery.selectors';
import { getLocale } from '+app/i18n/i18n.helpers';
import { CsvDownloadButton } from '+shared/components/CsvDownloadButton/CsvDownloadButton';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { dateUtil } from '+utils/date.util';
import { mapActions } from '+utils/redux';

import { AnalysisActions, getBarChartSeries, getStatisticsSelectedDate } from '../../store';
import { CsvExportableData, getCSVData } from '../CustomerAnalysis/CustomerAnalysis.helpers';
import {
  DATE_FORMATS,
  formatDate,
  getFilenameFormat,
  preformatCsvData,
} from './CustomerAnalysisStatisticsToolbar.helper';

import './CustomerAnalysisStatisticsToolbar.component.scss';

const mapStateToProps = (state: StoreState) => ({
  statisticsSelectedDate: getStatisticsSelectedDate(state),
  batteryInstallationDate: getBatteryInstallationDate(state),
  barChartSeries: getBarChartSeries(state),
  battery: getBattery(state),
  userProfile: getUserProfile(state),
});

const mapDispatchToProps = mapActions({
  setStatisticsSelectedDate: AnalysisActions.setStatisticsDate,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    isLoading?: boolean;
  };

const CustomerAnalysisStatisticsToolbarComponent: React.FC<Props> = ({
  statisticsSelectedDate,
  batteryInstallationDate,
  actions,
  barChartSeries,
  battery,
  userProfile,
  isLoading,
}) => {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [isDatePickerOpen, toggleDatePicker] = useState(false);

  const changeDate = (selectedDatePeriod: TimeUnit) => (selectedDate: Date) => {
    actions.setStatisticsSelectedDate({
      period: selectedDatePeriod,
      date: selectedDate,
      resolution: getResolutionFromPeriod(selectedDatePeriod),
    });
    toggleDatePicker(false);
  };

  const shiftSelectedDate = (shift: DateShift) => {
    const { period, date } = statisticsSelectedDate;
    changeDate(statisticsSelectedDate.period)(shiftDate(date, period, shift));
  };

  const { period, date } = statisticsSelectedDate;
  const minimalDate = getMinimalMeasurementDate(batteryInstallationDate);

  const prepareCSVData = () => {
    if (!barChartSeries) {
      setCsvData([]);
      return;
    }

    const entries: CsvExportableData = preformatCsvData(date, barChartSeries, period);
    const csv = getCSVData(
      entries,
      Object.keys(barChartSeries),
      battery?.timeZone,
      userProfile?.countryCode
    );
    setCsvData(csv);
  };

  const filename = `${battery?.serialNumber}_${getFilenameFormat(date, period)}_statistics.csv`;

  return (
    <div className="customer-analysis-statistics-toolbar">
      <AnalysisToolbar
        dateSwitcherTitle={I18n.t(T.customerSingle.analysis.statistics.datePickerLabel)}
        datePicker={
          <AnalysisDatePicker
            isActive={isDatePickerOpen}
            date={date}
            minDate={minimalDate}
            onDayClick={changeDate(TimeUnit.DAY)}
            onMonthClick={changeDate(TimeUnit.MONTH)}
            onYearClick={changeDate(TimeUnit.YEAR)}
            activeDatePicker={period}
            tabsTriggers={[
              { label: I18n.t(T.customerSingle.analysis.dateLabels.day) },
              { label: I18n.t(T.customerSingle.analysis.dateLabels.month) },
              { label: I18n.t(T.customerSingle.analysis.dateLabels.year) },
            ]}
            locale={getLocale()}
          />
        }
        dateSwitcher={
          <DateSwitcher
            icon={
              <Icon.Calendar className="customer-analysis-statistics-toolbar__date-switcher-icon" />
            }
            date={date}
            dateFormatter={() => formatDate(date, DATE_FORMATS[period])}
            canGoPrev={dateUtil.isAfter(dateUtil.of(date), minimalDate)}
            canGoNext={!dateUtil.isCurrent(period)(date)}
            onPrevSelected={() => shiftSelectedDate(DateShift.BACK)}
            onNextSelected={() => shiftSelectedDate(DateShift.FORWARD)}
            onDateSelected={() => toggleDatePicker(!isDatePickerOpen)}
          />
        }
        onDatePickerOutsideClick={() => toggleDatePicker(false)}
      />
      <CsvDownloadButton
        prepareCSVData={prepareCSVData}
        data={csvData}
        filename={filename}
        isLoading={isLoading}
      />
    </div>
  );
};

export const CustomerAnalysisStatisticsToolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAnalysisStatisticsToolbarComponent);
