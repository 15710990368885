import { defer } from 'rxjs';

import { Config } from '../../../../config';
import { jsonApiClient } from '../../network';
import {
  defaultStatisticFilters,
  getMeasurementsDefaultDateRange,
  getResolutionForTimeRange,
} from './site.helpers';
import {
  SiteCellDataData,
  SiteChargeLimitsData,
  SiteChargeLimitsFilters,
  SiteChargerData,
  SiteData,
  SiteLiveStateData,
  SiteMeasurementsData,
  SiteMeasurementsFilters,
  SiteStatisticsData,
  SiteStatisticsFilters,
} from './types';
import { SiteLiveStateV2Data } from './types/siteLiveStateV2.interface';

const getSiteMeasurements = (id: string, filters?: SiteMeasurementsFilters) => {
  const { start, end } = filters || getMeasurementsDefaultDateRange();

  return defer(() =>
    jsonApiClient
      .get<SiteMeasurementsData>(`${Config.API_URL_V2}/sites/${id}/measurements`)
      .filter('start_at', start.toISOString())
      .filter('end_at', end.toISOString())
      .filter('resolution', getResolutionForTimeRange(start, end))
      .expectOne()
      .send()
  );
};

const getSiteChargers = (id: string) =>
  defer(() =>
    jsonApiClient.get<SiteChargerData>(`${Config.API_URL}/sites/${id}/chargers`).expectMany().send()
  );

const getSiteChargeLimits = (id: string, filters?: SiteChargeLimitsFilters) => {
  const { start, end } = filters || defaultStatisticFilters;

  return defer(() =>
    jsonApiClient
      .get<SiteChargeLimitsData>(`${Config.API_URL}/sites/${id}/charge-limits`)
      .filter('start_at', start.toISOString())
      .filter('end_at', end.toISOString())
      .expectOne()
      .send()
  );
};

const getSiteStatistics = (id: string, filters?: SiteStatisticsFilters) => {
  const { start, end, resolution } = filters || defaultStatisticFilters;

  return defer(() =>
    jsonApiClient
      .get<SiteStatisticsData>(`${Config.API_URL}/sites/${id}/statistics`)
      .filter('start', start.toISOString())
      .filter('end', end.toISOString())
      .filter('resolution', resolution)
      .expectOne()
      .send()
  );
};

const getSiteLiveState = (id: string) =>
  defer(() =>
    jsonApiClient
      .get<SiteLiveStateData>(`${Config.API_URL}/sites/${id}/live-state`)
      .expectOne()
      .send()
  );

const getSite = (id: string) =>
  defer(() => jsonApiClient.get<SiteData>(`${Config.API_URL}/sites/${id}`).expectOne().send());

const getSiteCellData = (id: string, filters?: SiteMeasurementsFilters) => {
  const { start, end } = filters || getMeasurementsDefaultDateRange();

  return defer(() =>
    jsonApiClient
      .get<SiteCellDataData>(`${Config.API_URL}/sites/${id}/cellpack-data`)
      .filter('start_at', start.toISOString())
      .filter('end_at', end.toISOString())
      .filter('resolution', getResolutionForTimeRange(start, end))
      .expectOne()
      .send()
  );
};

const getSiteLiveStateV2 = (siteId: string) =>
  defer(() =>
    jsonApiClient
      .get<SiteLiveStateV2Data>(`${Config.API_URL_V2}/sites/${siteId}/live-state`)
      .expectOne()
      .send()
  );

export const SiteRepository = {
  getSiteMeasurements,
  getSiteChargers,
  getSiteChargeLimits,
  getSiteStatistics,
  getSiteLiveState,
  getSite,
  getSiteCellData,
  getSiteLiveStateV2,
};
