import React from 'react';

import { Checkmark } from '+shared/basicComponents/Icons';

import './Checkbox.scss';

type FormCheckboxProps = {
  id: string;
  name: string;
  label?: React.ReactNode | string;
  isChecked: boolean;
  onChange?: () => void;
  isDisabled?: boolean;
  className?: string;
  dataTestId?: string;
};

const Checkbox: React.FC<FormCheckboxProps> = ({
  id,
  name,
  label,
  onChange,
  isDisabled,
  isChecked,
  className,
  dataTestId,
}) => (
  <label className={`basic-checkbox ${className ? className : ''}`} htmlFor={id}>
    <input
      id={id}
      type="checkbox"
      checked={isChecked}
      name={name}
      onChange={onChange}
      disabled={isDisabled}
      data-testid={dataTestId}
    />
    <span className="basic-checkbox__styled-checkbox">
      <Checkmark />
    </span>
    {label && <>{label}</>}
  </label>
);

export default Checkbox;
