import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as classNames from 'classnames';

import { getFullAddress, getPrimaryEmail } from '+customer-list/store/customer.helpers';
import { DetailList } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Customer, Site } from '+shared/store/customer';

interface Props {
  customer: Customer;
  site: Site;
  className?: ClassValue;
}

export const CustomerDetailsWidget: React.FC<Props> = ({ className, customer, site }) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={'Account'}
    heading={I18n.t(T.customerSingle.overview.contact.headline)}
  >
    <DetailList
      list={[
        {
          label: I18n.t(T.customerSingle.overview.contact.address),
          value: getFullAddress(site),
          type: 'info',
        },
        {
          label: I18n.t(T.customerSingle.overview.contact.email),
          value: getPrimaryEmail(customer),
        },
        { label: I18n.t(T.customerSingle.overview.contact.phone), value: customer.phone },
        {
          label: I18n.t(T.customerSingle.overview.contact.customerNumber),
          value: customer.customerNumber,
        },
      ]}
    />
  </Widget>
);
