import { Data, MergedData } from '@coolio/json-api';

import { ContractType } from '../contract.helpers';

type TariffVersion = 'sFh 1.0' | 'sFh 2.0' | 'sFh 2.1' | 'sFh 3.0' | 'X' | 'direkt' | 'direkt 2.0';

export interface MeterInstallationAttributes {
  changeNotificationToDso: boolean | null;
  resultMeterChange: ResultMeterChange | null;
  meterPreparationStatus: MeterPreparationStatus | null;
  fstCheckSuccessful: FstCheckResults | null;
  startWimProcess: boolean;
}

export enum ContractMeterProcessStatus {
  IN_PROCESS = 'in_process',
  METER_PLACES_PREPARED = 'meter_places_prepared',
  PARTIAL_SUCCESS = 'partial_success',
  FAILURE = 'failure',
  SUCCESS = 'success',
}

export enum FstCheckResults {
  YES = 'yes',
  NO = 'no', // FILTER: FST nicht erfolgreich getestet
  NOT_REQUIRED = 'fst_not_required',
}

export enum MeterPreparationStatus {
  NOT_NEEDED = 'not_needed',
  MISSING = 'missing', // FILTER: ZV ausstehend
  SUBMITTED = 'submitted',
  IN_CLEARING = 'in_clearing', // FILTER: Rückfrage zu ZV
  CHECKED = 'checked',
}

export enum ResultMeterChange {
  SUCCESSFUL = 'successful',
  ABORTED = 'aborted',
}

export interface ContractAttributes {
  name: string;
  contractNumber: string;
  contractType: ContractType;
  tariffType: string;
  tariffVersion: TariffVersion;
  orderedAt: string;
  activatedAt: string;
  deliveryStartAt: string | null;
  startAt: string;
  endAt: string;
  status: string;
  annualConsumption: number;
  annualConsumptionTotal: number;
  appointedEnergyRate: number;
  amountOfFreeEnergy: number | null;
  billingStreet: string;
  billingStreetNumber: string;
  billingPostalCode: string;
  billingCity: string;
  billingState: string;
  billingCountryCode: string;
  shippingStreet: string;
  shippingStreetNumber: string;
  shippingPostalCode: string;
  shippingCity: string;
  shippingState: string;
  shippingCountryCode: string;
  contractingPartySalutation: string;
  contractingPartyAcademicTitle: string;
  contractingPartnerFirstname: string;
  contractingPartnerLastname: string;
  contractingPartyEmail: string;
  contractingPartyPhone: string;
  contractingPartyBirthday: string;
  contractingPartyDatevNo: string;
  salesforceContractId: string;
  bankFirstname: string;
  bankLastname: string;
  bankIban: string;
  bankSwift: string;
  bankSepaDueAt: string;
  meterInstallation?: MeterInstallationAttributes;
}

export type ContractData = Data<ContractAttributes>;
export type Contract = MergedData<ContractData>;

export type MeterInstallationData = Data<MeterInstallationAttributes>;
export type MeterInstallation = MergedData<MeterInstallationData>;
