import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { NavLinkColor } from '+shared/components/NavLink/NavLink.helpers';

import { NavLink, StatusTile, StatusTileColors } from '../../components';
import {
  ActionLinks,
  NavSonnenWorldContext,
  useNavSonnenWorldContext,
} from './NavSonnenWorld.context';

import './NavSonnenWorld.component.scss';

interface NavSonnenWorldProps {
  children: React.ReactNode;
  actionLinkMap: Partial<ActionLinks>;
}

export const NavSonnenWorld = ({ children, actionLinkMap }: NavSonnenWorldProps) => {
  return (
    <NavSonnenWorldContext.Provider value={{ actionLinkMap }}>
      {children}
    </NavSonnenWorldContext.Provider>
  );
};

NavSonnenWorld.PartnerApp = () => {
  const { actionLinkMap } = useNavSonnenWorldContext();

  return (
    <NavLink
      action={actionLinkMap.partnerAppLink}
      type={'mobile'}
      border={true}
      navLinkColor={NavLinkColor.WHITE}
      dataTestId="partnerapp-subtab"
    >
      {I18n.t(T.general.navigation.partnerApp)}
      <StatusTile
        label={I18n.t(T.general.navigation.sonnenDriveLabel)}
        color={StatusTileColors.BLUE}
        additionalClass="nav-status-tile"
      />
    </NavLink>
  );
};

NavSonnenWorld.Marketing = () => {
  const { actionLinkMap } = useNavSonnenWorldContext();

  return (
    <NavLink
      action={actionLinkMap.marketingLink}
      type={'mobile'}
      border={true}
      navLinkColor={NavLinkColor.WHITE}
      dataTestId="marketing-documents-subtab"
    >
      {I18n.t(T.general.navigation.marketingSupport)}
    </NavLink>
  );
};

NavSonnenWorld.GeneralDocuments = () => {
  const { actionLinkMap } = useNavSonnenWorldContext();

  return (
    <NavLink
      action={actionLinkMap.generalDocumentsLink}
      type={'mobile'}
      border={true}
      navLinkColor={NavLinkColor.WHITE}
      dataTestId="general-documents-subtab"
    >
      {I18n.t(T.general.navigation.documentsGeneral)}
    </NavLink>
  );
};

NavSonnenWorld.TechnicalDocuments = () => {
  const { actionLinkMap } = useNavSonnenWorldContext();

  return (
    <NavLink
      action={actionLinkMap.technicalDocumentsLink}
      type={'mobile'}
      border={true}
      navLinkColor={NavLinkColor.WHITE}
      dataTestId="technical-and-service-documents-subtab"
    >
      {I18n.t(T.general.navigation.documentsService)}
    </NavLink>
  );
};

NavSonnenWorld.EAcademy = () => {
  const { actionLinkMap } = useNavSonnenWorldContext();

  return (
    <NavLink
      action={actionLinkMap.eAcademyLink}
      type={'mobile'}
      border={true}
      navLinkColor={NavLinkColor.WHITE}
      dataTestId="e-academy-subtab"
    >
      {I18n.t(T.general.navigation.eLearning)}
    </NavLink>
  );
};

NavSonnenWorld.ReleaseNotes = () => {
  const { actionLinkMap } = useNavSonnenWorldContext();

  return (
    <NavLink
      action={actionLinkMap.releaseNotesLink}
      type={'mobile'}
      border={true}
      navLinkColor={NavLinkColor.WHITE}
      dataTestId="release-notes-subtab"
    >
      {I18n.t(T.general.navigation.releaseNotes)}
    </NavLink>
  );
};
