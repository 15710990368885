import { __assign, __extends } from "tslib";
import { CanvasPointerEvent, PointerAction } from '@kanva/core';
import defaultsDeep from 'lodash/defaultsDeep';
import { DataContainerEventType } from './data-container.events';
import { DataContainerExtension } from './data-container.extension';
export var TOOLTIP_EXTENSION = 'DataContainerTooltipExtension';
var DataContainerTooltipExtension = (function (_super) {
    __extends(DataContainerTooltipExtension, _super);
    function DataContainerTooltipExtension(options) {
        var _this = _super.call(this, TOOLTIP_EXTENSION) || this;
        _this.lastPointerEvent = new CanvasPointerEvent();
        _this.position = { x: 0, y: 0 };
        _this.onDataChange = function () { return _this.onChartPointerEvent(_this.lastPointerEvent); };
        _this.options = defaultsDeep(options, {
            enableOnHover: true,
        });
        return _this;
    }
    DataContainerTooltipExtension.prototype.onChartPointerEvent = function (event) {
        var isSupportedPressure = this.options.enableOnHover &&
            event.primaryPointer.pressure === 0 || event.primaryPointer.pressure > 0;
        var isSupportedEventType = event.action === PointerAction.MOVE || event.action === PointerAction.OVER;
        var isTargetPresent = !!event.target;
        if (!isSupportedPressure || !isSupportedEventType || !isTargetPresent) {
            return false;
        }
        event.cloneTo(this.lastPointerEvent);
        var chartView = event.target;
        var point = chartView.getPointForCanvasPosition(event.primaryPointer);
        if (!point) {
            return false;
        }
        var match = this.dataContainers.reduce(function (match, dataContainer) {
            return dataContainer.getYValuesMatch(point.x, match);
        }, undefined);
        if (!match) {
            return false;
        }
        var position = chartView.getCanvasPositionForPoint(match.primary);
        var snap = {
            x: position.absoluteX,
            y: position.absoluteY,
        };
        if (this.options.onTooltipEvent) {
            this.options.onTooltipEvent(__assign(__assign({ pointerEvent: event }, point), { match: match, snap: snap }));
        }
        return true;
    };
    DataContainerTooltipExtension.prototype.simulateAbsoluteCanvasPosition = function (view, absolutePosition) {
        var event = this.lastPointerEvent || new CanvasPointerEvent();
        event.target = view;
        event.pointerCount = 1;
        event.primaryPointer.pressure = .5;
        event.primaryPointer.x = absolutePosition.x - view.offsetRect.l;
        event.primaryPointer.y = absolutePosition.y - view.offsetRect.t;
        event.action = PointerAction.MOVE;
        this.onChartPointerEvent(event);
    };
    DataContainerTooltipExtension.prototype.onAttach = function (dataContainer) {
        dataContainer.addEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
    };
    DataContainerTooltipExtension.prototype.onDetach = function (dataContainer) {
        dataContainer.removeEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
    };
    return DataContainerTooltipExtension;
}(DataContainerExtension));
export { DataContainerTooltipExtension };
