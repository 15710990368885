import React from 'react';

import classNames from 'classnames';

import './BasicTable.component.scss';

export type ColumnGenerator<RowDataType> = {
  name: string;
  visible: boolean;
  sortBy?: string;
  headCellClassName?: string;
  headCellGenerator: () => JSX.Element;
  dataCellClassName?: string;
  dataCellGenerator: (rowData: RowDataType) => JSX.Element;
};

interface Props<RowDataType> {
  className?: string;
  columns: Array<ColumnGenerator<RowDataType>>;
  data: RowDataType[];
}

export const BasicTable = <RowDataType extends unknown>({
  className,
  columns,
  data,
}: Props<RowDataType>) => {
  return (
    <table className={classNames('basic-table', className)}>
      <thead>{generateHeaderRow()}</thead>
      <tbody>{data.map((rowData, index) => generateDataRow(rowData, index))}</tbody>
    </table>
  );

  function generateHeaderRow(): JSX.Element {
    return (
      <tr className="basic-table__header-row">
        {columns.map((column, index) => (
          <th
            key={column.name}
            className={classNames(
              'basic-table__header-cell',
              column.headCellClassName,
              `${index === 0 ? 'first-column-header-cell-sticky' : ''}`
            )}
          >
            {column.headCellGenerator()}
          </th>
        ))}
      </tr>
    );
  }

  function generateDataRow(rowData: RowDataType, index: number): JSX.Element {
    return (
      <tr key={index} className="basic-table__data-row">
        {columns.map((column, index) => (
          <td
            key={index}
            className={classNames('basic-table__data-cell', column.dataCellClassName)}
          >
            {column.dataCellGenerator(rowData)}
          </td>
        ))}
      </tr>
    );
  }
};
