import { createReducer } from '+app/utils';
import { queryReducer } from '+shared/store/query';

import { PARTNER_ACTIONS, PartnerActions } from './partner.actions';
import {
  CLAIM_BATTERY_OWNERSHIP_QUERY,
  IDENTIFY_MISSING_BATTERY_QUERY,
  initialState,
  PartnerState,
} from './partner.state';

export const reducer = createReducer<PartnerState, PartnerActions>(
  (state = initialState, action): PartnerState => {
    switch (action.type) {
      case PARTNER_ACTIONS.IDENTIFY_MISSING_BATTERY:
        return {
          ...state,
          missingBattery: {
            ...state.missingBattery,
            serialNumber: action.serialNumber,
          },
        };
      case PARTNER_ACTIONS.CLEAR_MISSING_BATTERY_STATE:
        return {
          ...initialState,
        };
      case PARTNER_ACTIONS.SET_ASSIGNED_BATTERY:
        return {
          ...state,
          missingBattery: {
            ...state.missingBattery,
            assignedBattery: {
              ...action.assignedBattery,
              // TODO: remove when coolio fixed - properly converting included object data
              siteId:
                action.assignedBatteryRaw.included && action.assignedBatteryRaw.included.length
                  ? action.assignedBatteryRaw.included[0].id
                  : '',
            },
          },
        };
      case PARTNER_ACTIONS.CLAIM_MISSING_BATTERY_OWNERSHIP:
        return {
          ...state,
          missingBattery: {
            ...state.missingBattery,
            ...action.claimBattery,
          },
        };
      default:
        return queryReducer(state, action, [
          IDENTIFY_MISSING_BATTERY_QUERY,
          CLAIM_BATTERY_OWNERSHIP_QUERY,
        ]);
    }
  }
);
