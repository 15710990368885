import { createReducer } from '+app/utils/redux';
import { queryReducer } from '+shared/store/query';

import { YOUTUBE_ACTIONS, YoutubeActions } from './youtube.actions';
import { GET_YOUTUBE_VIDEO_LIST_QUERY, initialState, YoutubeState } from './youtube.state';

export const reducer = createReducer<YoutubeState, YoutubeActions>(
  (state = initialState, action): YoutubeState => {
    switch (action.type) {
      case YOUTUBE_ACTIONS.SET_YOUTUBE_VIDEO_LIST:
        return {
          ...state,
          videoList: action.videoList,
        };
      case YOUTUBE_ACTIONS.CLEAR_YOUTUBE_VIDEO_LIST:
        return {
          ...state,
          videoList: [],
          [GET_YOUTUBE_VIDEO_LIST_QUERY]: {},
        };
      default:
        return queryReducer(state, action, [GET_YOUTUBE_VIDEO_LIST_QUERY]);
    }
  }
);
