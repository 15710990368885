import React from 'react';

type Props = {
  color?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
};

export const ExternalLink: React.FC<Props> = ({ color, width, height, className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3C3.67157 3 3 3.67157 3 4.5V15.5C3 16.3284 3.67157 17 4.5 17H15.5C16.3284 17 17 16.3284 17 15.5V10.5C17 10.0858 16.6642 9.75 16.25 9.75C15.8358 9.75 15.5 10.0858 15.5 10.5V15.5H4.5V4.5H9.5C9.91421 4.5 10.25 4.16421 10.25 3.75C10.25 3.33579 9.91421 3 9.5 3H4.5ZM12.5 3C12.2239 3 12 3.22386 12 3.5C12 3.77614 12.2239 4 12.5 4H15.2929L9.64645 9.64645C9.45118 9.84171 9.45118 10.1583 9.64645 10.3536C9.84171 10.5488 10.1583 10.5488 10.3536 10.3536L16 4.70711V7.5C16 7.77614 16.2239 8 16.5 8C16.7761 8 17 7.77614 17 7.5V3.5V3H16.5H12.5Z"
      fill={color}
    />
  </svg>
);
