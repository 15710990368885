import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { DS, Icon, Modal } from '@sonnen/shared-web';

import { Form, Formik } from 'formik';

import { getDynamicTariff, getLeadQueryStatus } from '+app/+lead/store/+lead.selectors';
import { SetupLeadEditStatus } from '+app/+lead/store/types';
import { mapActions } from '+app/utils';
import { ROUTES } from '+router/routes';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { BodyMedium, BodySmall } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { WidgetSimple } from '+shared/components';
import { Button, ButtonStatus } from '+shared/components/Button';
import StatusCheckbox, {
  IStatusCheckbox,
} from '+shared/components/Form/StatusCheckbox/StatusCheckbox';
import ModalLayout from '+shared/components/ModalLayout/ModalLayout';
import { LeadActions } from '+shared/store/lead';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { StoreState } from '+shared/store/store.interface';
import { insertIf } from '+utils/array.util';

import { SetupInfoIconTooltip } from '../SetupInfoIconTooltip';
import { formFields, mapUserStatusToInitialValues } from './SetupLeadModal.helper';

import './SetupLeadModal.scss';

const {
  status: { owner },
  warning,
  button,
  subheadline,
  description,
  section,
} = T.setupTool.statusManagement;

const {
  dsoRegistrationCompleted,
  dsoCommissioningCompleted,
  bNetzARegistrationCompleted,
  taxOfficeRegistrationCompleted,
  hardwareCommissioningCompleted,
  dsoMeterInstalled,
  flatOfferSent,
  flatOfferAccepted,
  flatOrderConfirmed,
  vppPreparationDone,
} = T.shared._salessolution_.lead.status;

const mapStateToProps = (state: StoreState) => ({
  hasDynamicTariff: getDynamicTariff(state),
  leadQueryStatus: getLeadQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getLead: LeadActions.getLead,
});

type ComponentProps = {
  modalOpen: boolean;
  lead: Lead;
  closeModal: () => void;
  updateStatuses: (...status: LeadStatusName[]) => void;
  userCompanyName: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps &
  RouteComponentProps;

const SetupLeadModalComponent = ({
  modalOpen,
  lead,
  updateStatuses,
  userCompanyName,
  actions,
  hasDynamicTariff,
  leadQueryStatus,
  closeModal,
  location,
}: Props) => {
  useEffect(() => {
    // lead needs to be fetched only if Modal is opened from the SetupLeadListTable
    if (location.pathname === ROUTES.SETUP_LEADS[0]) actions.getLead(lead.id, 'getLeadQuery');
  }, []);

  const onSubmit = (values: SetupLeadEditStatus) => {
    if (!lead) return;
    updateStatuses(
      ...insertIf(
        values.dsoRegistrationCompleted !== initialValues.dsoRegistrationCompleted,
        LeadStatusName.DSO_REGISTRATION_COMPLETED
      ),
      ...insertIf(
        values.dsoCommissioningCompleted !== initialValues.dsoCommissioningCompleted,
        LeadStatusName.DSO_COMMISSIONING_COMPLETED
      ),
      ...insertIf(
        values.bNetzARegistrationCompleted !== initialValues.bNetzARegistrationCompleted,
        LeadStatusName.BNETZA_REGISTRATION_COMPLETED
      ),
      ...insertIf(
        values.taxOfficeRegistrationCompleted !== initialValues.taxOfficeRegistrationCompleted,
        LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED
      ),
      ...insertIf(
        values.hardwareCommissioningCompleted !== initialValues.hardwareCommissioningCompleted,
        LeadStatusName.HW_COMMISSIONING_COMPLETED
      ),
      ...insertIf(
        values.dsoMeterInstalled !== initialValues.dsoMeterInstalled,
        LeadStatusName.DSO_METER_INSTALLED
      )
    );
  };

  const initialValues = mapUserStatusToInitialValues(lead);

  const mapText = (label: string, owner: string) => (
    <>
      <LabelMedium text={I18n.t(label)} />
      <BodySmall text={owner} />
    </>
  );

  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      className="modal"
      dataTestId="setup-tool-status_management_modal"
    >
      {leadQueryStatus.pending ? (
        <Loader className="lead-status-modal__loader" />
      ) : (
        <div className="lead-setup-modal" data-hj-suppress={true}>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={onSubmit}
          >
            {(form) => (
              <Form className="lead-setup-modal__form">
                <ModalLayout
                  section={Sections.SETUP}
                  page={PageName.Setup.SETUP_STATUS_MANAGER}
                  title={`${lead.firstName} ${lead.lastName}`}
                  footer={
                    <>
                      {form.dirty && (
                        <div className="lead-setup-modal__warning">
                          <Icon.Warning className="lead-setup-modal__warning-icon" />
                          <BodyMedium text={I18n.t(warning)} />
                        </div>
                      )}
                      <div className="lead-setup-modal__buttons">
                        <Button
                          name="setup-tool-lead_status_save_button"
                          className="lead-setup-modal__footer-button"
                          mainType={DS.MainType.SUBMIT}
                          label={I18n.t(button.save)}
                          disabled={!form.dirty}
                          status={!form.dirty ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                        />
                      </div>
                    </>
                  }
                >
                  <LabelLarge text={I18n.t(subheadline)} />
                  <BodyMedium text={I18n.t(description)} />

                  <div className="lead-setup-modal__section">
                    <WidgetSimple heading={I18n.t(section.header.registration)}>
                      <StatusCheckboxWithInfo
                        form={form}
                        label={mapText(dsoRegistrationCompleted, userCompanyName)}
                        name={formFields.DSO_REGISTRATION_COMPLETED}
                        initialValue={initialValues.dsoRegistrationCompleted}
                      />

                      <StatusCheckboxWithInfo
                        form={form}
                        label={mapText(dsoCommissioningCompleted, userCompanyName)}
                        name={formFields.DSO_COMMISSIONING_COMPLETED}
                        initialValue={initialValues.dsoCommissioningCompleted}
                      />

                      <StatusCheckboxWithInfo
                        form={form}
                        label={mapText(
                          bNetzARegistrationCompleted,
                          `${userCompanyName}/${I18n.t(owner.customer)}`
                        )}
                        name={formFields.BNETZA_REGISTRATION_COMPLETED}
                        initialValue={initialValues.bNetzARegistrationCompleted}
                      />

                      {!hasDynamicTariff && (
                        <StatusCheckboxWithInfo
                          form={form}
                          label={mapText(taxOfficeRegistrationCompleted, I18n.t(owner.customer))}
                          name={formFields.TAX_OFFICE_REGISTRATION_COMPLETED}
                          initialValue={initialValues.taxOfficeRegistrationCompleted}
                          statusAutomatic={true}
                        />
                      )}
                    </WidgetSimple>
                  </div>

                  <div className="lead-setup-modal__section">
                    <WidgetSimple heading={I18n.t(section.header.hardwareSetup)}>
                      <StatusCheckboxWithInfo
                        form={form}
                        label={mapText(hardwareCommissioningCompleted, userCompanyName)}
                        name={formFields.HW_COMMISSIONING_COMPLETED}
                        initialValue={initialValues.hardwareCommissioningCompleted}
                      />

                      <StatusCheckboxWithInfo
                        form={form}
                        label={mapText(dsoMeterInstalled, userCompanyName)}
                        name={formFields.DSO_METER_INSTALLED}
                        initialValue={initialValues.dsoMeterInstalled}
                      />
                    </WidgetSimple>
                  </div>

                  {!hasDynamicTariff && (
                    <div className="lead-setup-modal__section">
                      <WidgetSimple heading={I18n.t(section.header.sonnenFlatSetup)}>
                        <StatusCheckboxWithInfo
                          form={form}
                          label={mapText(flatOfferSent, userCompanyName)}
                          name={formFields.FLAT_OFFER_SENT}
                          initialValue={initialValues.flatOfferSent}
                          statusAutomatic={true}
                        />

                        <StatusCheckboxWithInfo
                          form={form}
                          label={mapText(flatOfferAccepted, I18n.t(owner.customer))}
                          name={formFields.FLAT_OFFER_ACCEPTED}
                          initialValue={initialValues.flatOfferAccepted}
                          statusAutomatic={true}
                          suppressLabelInHotjar={false}
                        />

                        <StatusCheckboxWithInfo
                          form={form}
                          label={mapText(flatOrderConfirmed, I18n.t(owner.Sonnen))}
                          name={formFields.FLAT_ORDER_CONFIRMED}
                          initialValue={initialValues.flatOrderConfirmed}
                          statusAutomatic={true}
                        />

                        <StatusCheckboxWithInfo
                          form={form}
                          label={mapText(vppPreparationDone, userCompanyName)}
                          name={formFields.VPP_PREPARATION_DONE}
                          initialValue={initialValues.vppPreparationDone}
                          statusAutomatic={true}
                        />
                      </WidgetSimple>
                    </div>
                  )}
                </ModalLayout>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export const SetupLeadModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SetupLeadModalComponent));

const StatusCheckboxWithInfo = (props: IStatusCheckbox<any>) => (
  <div className="lead-setup-modal__row">
    <StatusCheckbox {...props} />
    <SetupInfoIconTooltip statusName={props.name} />
  </div>
);
