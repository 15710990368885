import { i18nReducer } from 'react-redux-i18n';

import { combineReducers, Reducer } from 'redux';
import { combineEpics } from 'redux-observable';

import * as accountPageStore from '+app/+account/store';
import { epics as featAccountEpics } from '+app/+account/store/+account.epic';
import { epics as featCaseEpics } from '+app/+case/store/+case.epics';
import * as contactPageStore from '+app/+contact/store';
import { epics as featContactEpics } from '+app/+contact/store/+contact.epics';
import { epics as siteListEpics } from '+app/+customer/+listNew/store/siteList.epics';
import { epics as featDashboardEpics } from '+customer/+list/store/+customerList.epics';
import * as customerPageStore from '+customer/store';
import { epics as featCustomerEpics } from '+customer/store/+customer.epics';
import * as leadPageStore from '+lead/store';
import { epics as featLeadEpics } from '+lead/store/+lead.epics';
import * as registerPageStore from '+register/store';
import { epics as featRegisterEpics } from '+register/store/+register.epics';
import * as setupToolPageStore from '+setupTool/store';
import { epics as featSetupToolEpics } from '+setupTool/store/+setupTool.epics';
import { epics as staticEpics } from '+static/store/+static.epics';

import { epics as i18nEpics } from '../../i18n/i18n.epics';
import * as routerStore from '../../router/store';
import { epics as routerEpics } from '../../router/store/router.epics';
import * as authStore from './auth';
import { epics as authEpics } from './auth/auth.epics';
import { epics as batteryEpics } from './battery/battery.epics';
import * as sharedCaseStore from './case';
import { epics as caseEpics } from './case/case.epics';
import { epics as chargerEpics } from './charger/charger.epics';
import { epics as contactEpics } from './contact/contact.epics';
import { epics as contractEpics } from './contract/contract.epics';
import * as customerStore from './customer';
import { epics as customerEpics } from './customer/customer.epics';
import * as firebaseStore from './firebase';
import { epics as firebaseEpics } from './firebase/firebase.epics';
import * as layoutStore from './layout';
import { epics as layoutEpics } from './layout/layout.epics';
import { epics as leadEpics } from './lead/lead.epics';
import * as legalStore from './legal';
import { epics as legalEpics } from './legal/legal.epics';
import * as newsMainStore from './newsMain';
import * as partnerStore from './partner';
import { epics as partnerEpics } from './partner/partner.epics';
import { epics as setupToolEpics } from './setupTool/setupTool.epics';
import * as siteStore from './site';
import { epics as siteEpics } from './site/site.epics';
import { PageState, SharedState, StoreState } from './store.interface';
import * as syncStore from './sync';
import { epics as syncEpics } from './sync/sync.epics';
import * as tutorialStore from './tutorial';
import * as userStore from './user';
import { epics as userEpics } from './user/user.epics';
import * as youtubeStore from './youtube';
import { epics as youtubeEpics } from './youtube/youtube.epics';

// REDUCERS
// ------------------------------------

const sharedReducer = combineReducers<SharedState>({
  auth: authStore.reducer,
  case: sharedCaseStore.reducer,
  customer: customerStore.reducer,
  firebase: firebaseStore.reducer,
  layout: layoutStore.reducer,
  legal: legalStore.reducer,
  newsMain: newsMainStore.reducer,
  partner: partnerStore.reducer,
  tutorial: tutorialStore.reducer,
  site: siteStore.reducer,
  sync: syncStore.reducer,
  user: userStore.reducer,
  youtube: youtubeStore.reducer,
});

const pageReducer = combineReducers<PageState>({
  account: accountPageStore.reducer,
  contact: contactPageStore.reducer,
  customer: customerPageStore.reducer,
  lead: leadPageStore.reducer,
  register: registerPageStore.reducer,
  setupTool: setupToolPageStore.reducer,
});

export const rootReducer = combineReducers<StoreState>({
  i18n: i18nReducer as Reducer,
  router: routerStore.reducer,
  page: pageReducer,
  shared: sharedReducer,
});

// INITIAL STATE
// ------------------------------------

export const initialState = rootReducer(undefined, { type: '' });

// EPICS
// ------------------------------------

export const rootEpic = combineEpics(
  siteListEpics,
  authEpics,
  syncEpics,
  siteEpics,
  batteryEpics,
  caseEpics,
  contactEpics,
  contractEpics,
  customerEpics,
  leadEpics,
  i18nEpics,
  routerEpics,
  featCustomerEpics,
  featDashboardEpics,
  featRegisterEpics,
  featCaseEpics,
  featLeadEpics,
  featContactEpics,
  featAccountEpics,
  layoutEpics,
  legalEpics,
  staticEpics,
  featSetupToolEpics,
  setupToolEpics,
  chargerEpics,
  userEpics,
  firebaseEpics,
  youtubeEpics,
  partnerEpics
);
