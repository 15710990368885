import { from } from 'rxjs';

import { Config } from '+config/config';
import { firebaseHttpClient } from '+shared/network/network.client';

const BASE_URL = `${Config.FIREBASE.firebaseFunctionsUrl}/news`;

const postNewsMarkAsRead = (newsId: string) =>
  from(
    firebaseHttpClient.post(`${BASE_URL}/mark-as-read/${newsId}`).then((body) => body.parsedBody())
  );

export const FirebaseRepository = {
  postNewsMarkAsRead,
};
