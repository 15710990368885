import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  ClickOutside,
  DateSwitcher,
  Icofont,
  Icon,
  TimeRangePickerDates,
} from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash/fp';

import { getBattery, isEatonBattery } from '+app/+customer/+battery/store/+battery.selectors';
import {
  getCurrentDataSeries,
  getCurrentSelectedDates,
  getDatepickerMaxDate,
  getInitialDataSeries,
  getInitialSelectedDates,
  getIsResolutionChanged,
  getSelectedDataSeriesKeys,
} from '+customer/+analysis/store';
import { AccordionButton, DatepickerArrowSide, Legend, TimeRangePicker } from '+shared/components';
import { CsvDownloadButton } from '+shared/components/CsvDownloadButton/CsvDownloadButton';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { dateUtil, TimeUnit } from '+utils/date.util';
import { formatDate } from '+utils/format.util';
import { mapActions } from '+utils/redux/mapActions.util';

import { AnalysisActions } from '../../store';
import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import {
  convertToCsvExportableData,
  getCSVData,
} from '../CustomerAnalysis/CustomerAnalysis.helpers';
import {
  createDayViewQuickLinks,
  createMonthViewQuickLinks,
  prepareDisabledFilters,
  prepareFilters,
} from './CustomerAnalysisDayToolbar.helpers';

import './CustomerAnalysisDayToolbar.component.scss';

const {
  dayChart: { chooseStartDate, chooseEndDate, apply, cancel, filters },
  dateLabels: { day, month, year },
} = T.customerSingle.analysis;

type CustomerAnalysisDayToolbarProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    isLoading: boolean;
  };

const mapStateToProps = (state: StoreState) => ({
  selectedDataSeriesKeys: getSelectedDataSeriesKeys(state),
  initialSelectedDates: getInitialSelectedDates(state),
  currentSelectedDates: getCurrentSelectedDates(state),
  initialDataSeries: getInitialDataSeries(state),
  currentDataSeries: getCurrentDataSeries(state),
  isResolutionChanged: getIsResolutionChanged(state),
  logicalMaxDate: getDatepickerMaxDate(state),
  battery: getBattery(state),
  isEatonBattery: isEatonBattery(state),
  userProfile: getUserProfile(state),
});

const mapDispatchToProps = mapActions({
  toggleDataSeries: AnalysisActions.toggleDataSeries,
  setInitialChartDates: AnalysisActions.setInitialChartDates,
});

const CustomerAnalysisDayToolbarComponent: React.VFC<CustomerAnalysisDayToolbarProps> = ({
  actions,
  initialSelectedDates,
  currentSelectedDates,
  initialDataSeries,
  currentDataSeries,
  selectedDataSeriesKeys,
  isResolutionChanged,
  logicalMaxDate,
  battery,
  isEatonBattery,
  userProfile,
  isLoading,
}) => {
  const featureFlags = useFlags();
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>(false);
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = React.useState<boolean>(false);

  const [startDate, endDate] = initialSelectedDates;
  const [zoomBasedStartDate, zoomBasedEndDate] = isResolutionChanged
    ? currentSelectedDates!
    : initialSelectedDates;

  const shouldIncludeForecastQuickLink = dateUtil.isTimezoneUsersLocale(
    battery?.timeZone || 'Europe/Berlin'
  );
  const dayViewQuickLinks = createDayViewQuickLinks(shouldIncludeForecastQuickLink);
  const monthViewQuickLinks = createMonthViewQuickLinks();
  const isForecastQuickLinkActive =
    dateUtil.isToday(startDate) && dateUtil.isSameDay(endDate, logicalMaxDate);

  const minDate = dateUtil.getStartOf(dateUtil.of('2014-01-01'), TimeUnit.DAY);
  const maxDate = dateUtil.getStartOf(dateUtil.now(), TimeUnit.DAY);

  /**
   * Utils
   */

  const debounceSetDates = useCallback(
    debounce(300, ([startAt, endAt]: TimeRangePickerDates) =>
      actions.setInitialChartDates([startAt, endAt])
    ),
    []
  );

  const csvFileName = useMemo(() => {
    const start = dateUtil.format(startDate, 'YYYY-MM-DD');
    const end = dateUtil.format(endDate, 'YYYY-MM-DD');
    return `${battery?.serialNumber}_${start}_${end}_measurements.csv`;
  }, [startDate, endDate]);

  const prepareCSVData = () => {
    const { data } = isResolutionChanged ? currentDataSeries : initialDataSeries;
    const csv = convertToCsvExportableData(data);
    setCsvData(getCSVData(csv, Object.keys(data), battery?.timeZone, userProfile?.countryCode));
  };

  const createStartDateConfig = useCallback(() => {
    return {
      canGoPrev:
        dateUtil.isAfter(dateUtil.of(startDate), minDate, 'day') && !isForecastQuickLinkActive,
      canGoNext:
        dateUtil.isBefore(dateUtil.of(startDate), endDate, 'day') && !isForecastQuickLinkActive,
      onPrevSelected: () => debounceSetDates([dateUtil.subtract(startDate, 1, 'day'), endDate]),
      onNextSelected: () => debounceSetDates([dateUtil.add(startDate, 1, 'day'), endDate]),
    };
  }, [startDate, endDate, minDate, isForecastQuickLinkActive]);

  const createEndDateConfig = useCallback(() => {
    return {
      canGoPrev:
        dateUtil.isAfter(dateUtil.of(endDate), startDate, 'day') && !isForecastQuickLinkActive,
      // @TODO switch moment back to maxDate as soon as
      //  we allow to add future days to a time range
      canGoNext:
        dateUtil.isBefore(dateUtil.of(endDate), dateUtil.now(), 'day') &&
        !isForecastQuickLinkActive,
      onPrevSelected: () => debounceSetDates([startDate, dateUtil.subtract(endDate, 1, 'day')]),
      onNextSelected: () => debounceSetDates([startDate, dateUtil.add(endDate, 1, 'day')]),
    };
  }, [startDate, endDate, logicalMaxDate, isForecastQuickLinkActive]);

  const startDateConfig = createStartDateConfig();
  const endDateConfig = createEndDateConfig();

  /**
   * Handlers
   */

  const handleCloseDatePicker = (): void => {
    setIsDatePickerOpen(false);
  };

  const handleOnApplyClick = (selectedDates: TimeRangePickerDates): void => {
    actions.setInitialChartDates(selectedDates);
    handleCloseDatePicker();
  };

  const handleOnFilterClick = (
    isFiltersDrawerOpen: boolean,
    event?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    setIsFiltersDrawerOpen(isFiltersDrawerOpen);
  };

  return (
    <>
      <div className="c-customer-analysis-day-toolbar">
        <div className="c-customer-analysis-day-toolbar__dates">
          <ClickOutside onClick={handleCloseDatePicker}>
            <div className="c-customer-analysis-day-toolbar__dates-switcher-wrapper">
              <div className="c-customer-analysis-day-toolbar__date-switcher">
                <span className="c-customer-analysis-day-toolbar__label">
                  {I18n.t(chooseStartDate) + ':'}
                </span>
                <DateSwitcher
                  icon={<Icofont type="calendar" />}
                  date={startDate}
                  dateFormatter={formatDate}
                  canGoPrev={startDateConfig.canGoPrev}
                  canGoNext={startDateConfig.canGoNext}
                  onPrevSelected={startDateConfig.onPrevSelected}
                  onNextSelected={startDateConfig.onNextSelected}
                  onDateSelected={() =>
                    setIsDatePickerOpen((currentIsDatePickerOpen) => !currentIsDatePickerOpen)
                  }
                />
              </div>

              <div className="customer-analysis-day-toolbar__date-switcher">
                <span
                  className={
                    'c-customer-analysis-day-toolbar__label' +
                    ' ' +
                    'c-customer-analysis-day-toolbar__label--end'
                  }
                >
                  {I18n.t(chooseEndDate) + ':'}
                </span>
                <DateSwitcher
                  icon={<Icofont type="calendar" />}
                  date={endDate}
                  dateFormatter={formatDate}
                  canGoPrev={endDateConfig.canGoPrev}
                  canGoNext={endDateConfig.canGoNext}
                  onPrevSelected={endDateConfig.onPrevSelected}
                  onNextSelected={endDateConfig.onNextSelected}
                  onDateSelected={() =>
                    setIsDatePickerOpen((currentIsDatePickerOpen) => !currentIsDatePickerOpen)
                  }
                />
              </div>

              <div className="c-customer-analysis-day-toolbar__date-picker-wrapper">
                <TimeRangePicker
                  isActive={isDatePickerOpen}
                  arrowPosition={DatepickerArrowSide.TOP}
                  selectedDates={initialSelectedDates}
                  minDate={minDate}
                  logicalMaxDate={logicalMaxDate}
                  visualMaxDate={maxDate}
                  dayViewQuickLinks={dayViewQuickLinks}
                  monthViewQuickLinks={monthViewQuickLinks}
                  tabs={[{ label: I18n.t(day) }, { label: I18n.t(month) }, { label: I18n.t(year) }]}
                  applyLabel={I18n.t(apply)}
                  cancelLabel={I18n.t(cancel)}
                  onApplyClick={handleOnApplyClick}
                  onCancelClick={handleCloseDatePicker}
                />
              </div>
            </div>
          </ClickOutside>
        </div>

        <div className="c-customer-analysis-day-toolbar__filter-csv">
          <AccordionButton
            isAccordionOpen={isFiltersDrawerOpen}
            setAccordionOpen={handleOnFilterClick}
            messageOpen={I18n.t(filters)}
            messageClosed={I18n.t(filters)}
            additionalIcon={<Icon.Filter />}
          />

          <CsvDownloadButton
            isLoading={isLoading}
            data={csvData}
            prepareCSVData={prepareCSVData}
            filename={csvFileName}
          />
        </div>
      </div>

      <div
        className={classNames('c-customer-analysis-day-toolbar__filters', {
          'is-open': isFiltersDrawerOpen,
        })}
      >
        <Legend
          dataSeries={prepareFilters(
            selectedDataSeriesKeys,
            prepareDisabledFilters(
              zoomBasedStartDate,
              zoomBasedEndDate,
              isResolutionChanged,
              initialDataSeries.data,
              currentDataSeries.data,
              isEatonBattery
            ),
            featureFlags
          )}
          onClick={(key) => actions.toggleDataSeries(key as ChartDataSeriesKey)}
        />
      </div>
    </>
  );
};

export const CustomerAnalysisDayToolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAnalysisDayToolbarComponent);
