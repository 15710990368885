import * as React from 'react';

import * as classNames from 'classnames';

import './SelectButton.component.scss';

interface SelectButtonProps {
  icon: React.ReactNode;
  label: string;
  className?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  dataTestId?: string;
}

export const SelectButton: React.FC<SelectButtonProps> = ({
  icon,
  label,
  onClick,
  className,
  isActive = false,
  isDisabled = false,
  type = 'button',
  dataTestId,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(`c-select-button`, className, {
        'c-select-button--active': isActive,
        'c-select-button--disabled': isDisabled,
      })}
      data-testid={dataTestId}
    >
      <span className={'c-select-button__icon'}>{icon}</span>
      <span className={'c-select-button__text'}>{label}</span>
    </button>
  );
};
