import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import { HighlightChangeElement } from '+shared/containers/HighlightChangeElement';
import { Battery } from '+shared/store/battery';
import { isBatteryInStandby } from '+shared/store/battery/battery.helpers';
import { SiteLiveState } from '+shared/store/site/types';
import { formatPercentage } from '+utils/format.util';
import { useLocaleContext } from '+utils/react/locale.provider';

import { BatteryLiveCardSoc } from '../BatteryLiveCardSoc';
import { LiveCardStatus } from './BatteryLiveStatusList.component';

const showOnlineStatusWithDetails = (siteLiveState: SiteLiveState) => {
  const { batteryCharging, batteryDischarging, gridFeedin, gridPurchase } = siteLiveState;

  const batteryOnlineStatus = I18n.t(T.customerSingle.overview.battery.statusOnline);

  const batteryChargingStatus = () => {
    if (batteryCharging === 0 && batteryDischarging === 0) {
      return '';
    } else {
      return batteryCharging > batteryDischarging
        ? ` & ${I18n.t(T.customerSingle.overview.battery.statusCharging)}`
        : ` & ${I18n.t(T.customerSingle.overview.battery.statusDischarging)}`;
    }
  };

  const batteryFeedinOrPurchaseStatus = () => {
    if (gridFeedin === 0 && gridPurchase === 0) {
      return '';
    } else {
      return gridFeedin > gridPurchase
        ? ` & ${I18n.t(T.customerSingle.overview.battery.statusFeedingIn)}`
        : ` & ${I18n.t(T.customerSingle.overview.battery.statusPurchasing)}`;
    }
  };

  // eslint-disable-next-line max-len
  const batteryStatusWithDetails = `${batteryOnlineStatus}${batteryChargingStatus()}${batteryFeedinOrPurchaseStatus()}`;

  return batteryStatusWithDetails;
};

const getBatteryState = (siteLiveState: SiteLiveState): string =>
  siteLiveState.online
    ? isBatteryInStandby(siteLiveState)
      ? I18n.t(T.customerSingle.overview.battery.statusStandby)
      : showOnlineStatusWithDetails(siteLiveState)
    : I18n.t(T.customerSingle.overview.battery.statusOffline);

const getBatteryChargeCyclesComponent = ({ batteryChargeCycles }: Battery) =>
  !isNil(batteryChargeCycles) && batteryChargeCycles !== 0 ? (
    <HighlightChangeElement value={batteryChargeCycles}>
      {batteryChargeCycles}
    </HighlightChangeElement>
  ) : null;

const getBatteryStateOfCharge = (soc: number): string => {
  const { locale } = useLocaleContext();

  if (isNil(soc)) return '';

  return formatPercentage({ locale })(soc);
};

export const factorizeBatteryLeftSide = (siteLiveState: SiteLiveState): LiveCardStatus[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.stateOfCharge),
    value: (
      <HighlightChangeElement value={siteLiveState.batteryUsoc}>
        <BatteryLiveCardSoc soc={getBatteryStateOfCharge(siteLiveState.batteryUsoc)} />
      </HighlightChangeElement>
    ),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.state),
    value: (
      <HighlightChangeElement value={getBatteryState(siteLiveState)}>
        {getBatteryState(siteLiveState)}
      </HighlightChangeElement>
    ),
  },
];

export const factorizeBatteryRightSide = (
  battery: Battery,
  isBatteryBackupBufferInProgress: boolean,
  hasBackupDevice: boolean
): LiveCardStatus[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.backup),
    value: (
      <HighlightChangeElement value={`${battery.backupPowerBuffer}`}>
        <BatteryLiveCardSoc
          soc={mapBackupBufferToLabel(
            isBatteryBackupBufferInProgress,
            hasBackupDevice,
            battery.backupPowerBuffer
          )}
        />
      </HighlightChangeElement>
    ),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.chargeCycles),
    value: getBatteryChargeCyclesComponent(battery),
  },
];

export const mapBackupBufferToLabel = (
  setBackupPowerBufferIsPending: boolean,
  hasBackupDevice: boolean,
  backupPowerBuffer: number | null | undefined
): string => {
  const { locale } = useLocaleContext();

  const isNotDefined = isNil(backupPowerBuffer) || !hasBackupDevice;

  if (isNotDefined) return '-';

  if (setBackupPowerBufferIsPending)
    return I18n.t(
      T.customerSingle.batteryDetails.batteryOperations.changeBackupBuffer.statusPending
    );

  return formatPercentage({ locale })(backupPowerBuffer);
};
