import { Query } from '../query';
import { User } from '../user/user.state';

export const SYNC_QUERY = 'syncQuery';

export interface SyncData {
  profile?: User;
}

export interface SyncState {
  syncQuery: Query<SyncData>;
}

export const initialState: SyncState = {
  syncQuery: {},
};
