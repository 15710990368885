import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  BASE_TICK_COUNT,
  BatteryStatusesKey,
  CellDataSeriesKey,
  EnergyFlowSeriesKey,
  Loader,
} from '@sonnen/shared-web';

import {
  AreaChartView,
  DataContainer,
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  DataContainerTransformExtensionOptions,
  PointAccessor,
} from '@kanva/charts';
import { ChartZoomView } from '@kanva/charts-react';
import { View } from '@kanva/core';
import { Kanva, TextView } from '@kanva/react';
import * as classNames from 'classnames';
import { defaultTo, isNumber } from 'lodash';
import { DeepPartial } from 'redux';

import { analysisPointAccessor } from '+app/+customer/+analysis/store/helpers/+analysis.helpers';
import { EnergyUnit } from '+app/App.constants';
import { getMinAndMaxValues } from '+utils/array.util';
import { goTo } from '+utils/browser.util';
import {
  formatPercentage,
  formatPower,
  formatTemperature,
  formatVoltage,
} from '+utils/format.util.old';

import { PATHS } from '../../../../router';
import {
  factorizePointToDate,
  getLablesValueRoundToByResolution,
  resolveAreaXPosition,
} from '../../containers/CustomerAnalysisEnergyFlow/CustomerAnalysisEnergyFlow.helper';
import { mapDataSeries } from '../../store/helpers/+analysisStatistics.helpers';
import { ChartDataSeries, ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import { ChartVisibleElements } from '../../store/types/chartVisibleElements.interface';
import { AnalysisAreaChart } from '../AnalysisAreaChart';
import { AnalysisBandChart } from '../AnalysisBandChart';
import { AnalysisLineChart } from '../AnalysisLineChart';
import { AnalysisLineChartTooltip } from '../AnalysisLineChartTooltip';
import { createChartLayout } from './AnalysisDayChart.layout';
import { zoomSelectAreaStyle } from './AnalysisDayChart.styles';

import './AnalysisDayChart.component.scss';

interface AnalysisDayChartProps {
  selectedDates: [Date, Date];
  dataSeries: ChartDataSeries;
  selectedDataSeriesKeys: ChartDataSeriesKey[];
  tooltipExtension: DataContainerTooltipExtension;
  transformExtension: DataContainerTransformExtension;
  scaleOptions: DeepPartial<DataContainerTransformExtensionOptions>;
  chartHeight: number;
  isChartZoomed: boolean;
  isResolutionChanged: boolean;
  isLoading: boolean;
  isEaton: boolean;
  chartVisibleElements: ChartVisibleElements;
  onMount: (x: number) => (view: View<any>) => void;
}

export class AnalysisDayChart extends React.PureComponent<AnalysisDayChartProps> {
  private readonly defaultDataContainer = new DataContainer()
    .setXBoundsExtension([0, 24 * 60])
    .setXAxisParameters({
      labelAccessor: factorizePointToDate('xAxis')(...this.props.selectedDates),
      roundTo: getLablesValueRoundToByResolution(...this.props.selectedDates, this.props.isEaton),
      tickCount: BASE_TICK_COUNT,
    })
    .setYAxisParameters({
      labelAccessor: (value) => formatPower(value, EnergyUnit.KW, 2),
      roundTo: 25,
      tickCount: BASE_TICK_COUNT,
    })
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  private readonly percentageDataContainer = new DataContainer()
    .setXBoundsExtension([0, 24 * 60])
    .setYBoundsExtension([0, 100])
    .setYAxisParameters({
      labelAccessor: (value) => formatPercentage(value),
      roundTo: 5,
      tickCount: BASE_TICK_COUNT,
    })
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  private readonly cellTemperatureDataContainer = new DataContainer()
    .setXBoundsExtension([0, 24 * 60])
    .setXAxisParameters({
      labelAccessor: factorizePointToDate('xAxis')(...this.props.selectedDates),
      roundTo: getLablesValueRoundToByResolution(...this.props.selectedDates, this.props.isEaton),
      tickCount: BASE_TICK_COUNT,
    })
    .setYAxisParameters({
      labelAccessor: formatTemperature,
      roundTo: 1,
      tickCount: 3,
    })
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  private readonly cellVoltageDataContainer = new DataContainer()
    .setXBoundsExtension([0, 24 * 60])
    .setYAxisParameters({
      labelAccessor: formatVoltage,
      roundTo: 0.01,
      tickCount: 3,
    })
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  private readonly chargeLimitTextViewRef = React.createRef<InstanceType<typeof TextView>>();
  private readonly vppActivityTextViewRef = React.createRef<InstanceType<typeof TextView>>();
  private readonly cellCareTextViewRef = React.createRef<InstanceType<typeof TextView>>();

  constructor(props: AnalysisDayChartProps) {
    super(props);
  }

  setDataSeries(dataSeries: ChartDataSeries): void {
    // INFO: order of below data series dictates order of occurrence in analysis tooltip
    this.defaultDataContainer.setData(
      mapDataSeries({
        [EnergyFlowSeriesKey.PRODUCTION_POWER]: dataSeries[EnergyFlowSeriesKey.PRODUCTION_POWER],
        [EnergyFlowSeriesKey.CONSUMPTION_POWER]: dataSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER],
        [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]:
          dataSeries[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
        [EnergyFlowSeriesKey.BATTERY_CHARGING]: dataSeries[EnergyFlowSeriesKey.BATTERY_CHARGING],
        [EnergyFlowSeriesKey.BATTERY_DISCHARGING]:
          dataSeries[EnergyFlowSeriesKey.BATTERY_DISCHARGING],
        [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]:
          dataSeries[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER],
        [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]:
          dataSeries[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER],
        [BatteryStatusesKey.VPP_ACTIVITY]: dataSeries[BatteryStatusesKey.VPP_ACTIVITY],
        [BatteryStatusesKey.CHARGE_LIMIT]: dataSeries[BatteryStatusesKey.CHARGE_LIMIT],
        [BatteryStatusesKey.CELL_CARE]: dataSeries[BatteryStatusesKey.CELL_CARE],
      })
    );

    this.percentageDataContainer.setData(
      mapDataSeries({
        [EnergyFlowSeriesKey.BATTERY_USOC]: dataSeries[EnergyFlowSeriesKey.BATTERY_USOC],
      })
    );

    this.cellTemperatureDataContainer.setData(
      mapDataSeries({
        [CellDataSeriesKey.TEMPERATURE]: dataSeries[CellDataSeriesKey.TEMPERATURE],
      })
    );

    this.cellVoltageDataContainer.setData(
      mapDataSeries({
        [CellDataSeriesKey.VOLTAGE]: dataSeries[CellDataSeriesKey.VOLTAGE],
      })
    );
  }

  handleMount = (view: View<any>): void => {
    const { onMount, selectedDates } = this.props;
    const position = resolveAreaXPosition(EnergyFlowSeriesKey.CONSUMPTION_POWER)(
      this.defaultDataContainer,
      selectedDates
    );
    const { absoluteX } = (view as AreaChartView).getCanvasPositionForPoint({ x: position, y: 0 });

    onMount(absoluteX)(view);
  };

  handleUpdateDataContainerYBounds = (
    dataContainer: DataContainer<any>,
    dataSerieKey: CellDataSeriesKey
  ): void => {
    const yValues = this.props.dataSeries[dataSerieKey]
      .flatMap((point) => point.y)
      .filter(isNumber);
    const [min, max] = getMinAndMaxValues(yValues);

    if (min && max) {
      const diff = max - min;
      dataContainer.setYBoundsExtension([min - diff, max + diff]);
    }
  };

  handleCalculateTooltipsPosition = () => {
    const chargeLimitTextView = this.chargeLimitTextViewRef.current?.view;
    const vppActivityTextView = this.vppActivityTextViewRef.current?.view;
    const cellCareTextView = this.cellCareTextViewRef.current?.view;
    // @ts-ignore
    const chargeLimitTextViewParent = this.chargeLimitTextViewRef.current?.view?.parent;
    // @ts-ignore
    const vppActivityTextViewParent = this.vppActivityTextViewRef.current?.view?.parent;
    // @ts-ignore
    const cellCareTextViewParent = this.cellCareTextViewRef.current?.view?.parent;
    const offsetToCenterIcon = 14;

    const chargeLimitTooltipPosition = {
      // @ts-ignore
      left: defaultTo(chargeLimitTextView?.innerRect?.r, 0),
      top:
        defaultTo(chargeLimitTextViewParent?.offsetRect?.t, 0) +
        // @ts-ignore
        defaultTo(chargeLimitTextView?.innerRect?.b, 0) -
        offsetToCenterIcon,
    };

    const vppActivityTooltipPosition = {
      // @ts-ignore
      left: defaultTo(vppActivityTextView?.innerRect?.r, 0),
      top:
        defaultTo(vppActivityTextViewParent?.offsetRect?.t, 0) +
        // @ts-ignore
        defaultTo(vppActivityTextView?.innerRect?.b, 0) -
        offsetToCenterIcon,
    };

    const cellCareTooltipPosition = {
      // @ts-ignore
      left: defaultTo(cellCareTextView?.innerRect?.r, 0),
      top:
        defaultTo(cellCareTextViewParent?.offsetRect?.t, 0) +
        // @ts-ignore
        defaultTo(cellCareTextView?.innerRect?.b, 0) -
        offsetToCenterIcon,
    };

    return {
      chargeLimitTooltipPosition,
      vppActivityTooltipPosition,
      cellCareTooltipPosition,
    };
  };

  componentDidMount(): void {
    const { tooltipExtension, scaleOptions, transformExtension } = this.props;

    this.setDataSeries(this.props.dataSeries);
    transformExtension.setOptions(scaleOptions);
    this.defaultDataContainer.addExtension(transformExtension, tooltipExtension);
    this.percentageDataContainer.addExtension(transformExtension, tooltipExtension);
    this.cellTemperatureDataContainer.addExtension(transformExtension, tooltipExtension);
    this.cellVoltageDataContainer.addExtension(transformExtension, tooltipExtension);
  }

  componentDidUpdate(prevProps: Readonly<AnalysisDayChartProps>): void {
    const {
      dataSeries,
      selectedDates,
      isResolutionChanged,
      scaleOptions,
      transformExtension,
      isEaton,
    } = this.props;

    if (
      prevProps.dataSeries[CellDataSeriesKey.TEMPERATURE] !==
      dataSeries[CellDataSeriesKey.TEMPERATURE]
    ) {
      this.handleUpdateDataContainerYBounds(
        this.cellTemperatureDataContainer,
        CellDataSeriesKey.TEMPERATURE
      );
    }

    if (prevProps.dataSeries[CellDataSeriesKey.VOLTAGE] !== dataSeries[CellDataSeriesKey.VOLTAGE]) {
      this.handleUpdateDataContainerYBounds(
        this.cellVoltageDataContainer,
        CellDataSeriesKey.VOLTAGE
      );
    }

    if (prevProps.dataSeries !== dataSeries) {
      this.setDataSeries(dataSeries);
      transformExtension.setOptions(scaleOptions);

      if (isResolutionChanged) {
        this.defaultDataContainer.setXBoundsExtension([
          0,
          this.defaultDataContainer.getSeriesLength(),
        ]);
        this.percentageDataContainer.setXBoundsExtension([
          0,
          this.percentageDataContainer.getSeriesLength(),
        ]);
        this.cellTemperatureDataContainer.setXBoundsExtension([
          0,
          this.cellTemperatureDataContainer.getSeriesLength(),
        ]);
        this.cellVoltageDataContainer.setXBoundsExtension([
          0,
          this.cellVoltageDataContainer.getSeriesLength(),
        ]);
      }
    }

    if (prevProps.selectedDates !== selectedDates) {
      this.defaultDataContainer.setXAxisParameters({
        ...this.defaultDataContainer.getXAxisParameters(),
        labelAccessor: factorizePointToDate('xAxis')(...selectedDates),
        roundTo: getLablesValueRoundToByResolution(...selectedDates, isEaton),
      });
      this.cellTemperatureDataContainer.setXAxisParameters({
        ...this.defaultDataContainer.getXAxisParameters(),
        labelAccessor: factorizePointToDate('xAxis')(...selectedDates),
        roundTo: getLablesValueRoundToByResolution(...selectedDates, isEaton),
      });
    }
  }

  componentWillUnmount(): void {
    const { tooltipExtension, transformExtension } = this.props;

    this.defaultDataContainer.removeExtension(transformExtension, tooltipExtension);
    this.percentageDataContainer.removeExtension(transformExtension, tooltipExtension);
    this.cellTemperatureDataContainer.removeExtension(transformExtension, tooltipExtension);
    this.cellVoltageDataContainer.removeExtension(transformExtension, tooltipExtension);
  }

  render() {
    const { isChartZoomed, isLoading, chartVisibleElements, chartHeight } = this.props;
    const { chargeLimitTooltipPosition, vppActivityTooltipPosition, cellCareTooltipPosition } =
      this.handleCalculateTooltipsPosition();

    return (
      <div className={'c-analysis-day-chart'}>
        <Kanva
          className={'c-analysis-day-chart__canvas'}
          style={{ height: chartHeight }}
          enablePointerEvents={true}
        >
          <AnalysisAreaChart
            measurementsDataContainer={this.defaultDataContainer}
            percentageDataContainer={this.percentageDataContainer}
            selectedDataSeriesKeys={this.props.selectedDataSeriesKeys}
            isChartZoomed={isChartZoomed}
            handleMount={this.handleMount}
          />

          <AnalysisLineChart
            measurementsDataContainer={this.defaultDataContainer}
            chartVisibleElements={chartVisibleElements}
            chargeLimitTextViewRef={this.chargeLimitTextViewRef}
            vppActivityTextViewRef={this.vppActivityTextViewRef}
            cellCareTextViewRef={this.cellCareTextViewRef}
          />

          <AnalysisBandChart
            cellTemperatureDataContainer={this.cellTemperatureDataContainer}
            cellVoltageDataContainer={this.cellVoltageDataContainer}
            selectedDataSeriesKeys={this.props.selectedDataSeriesKeys}
            chartVisibleElements={chartVisibleElements}
            isChartZoomed={isChartZoomed}
          />

          <ChartZoomView
            dataContainer={this.defaultDataContainer}
            layoutParams={createChartLayout(chartVisibleElements).zoom}
            style={zoomSelectAreaStyle}
          />
        </Kanva>

        <div
          className={'c-analysis-day-chart__line-chart-tooltip-wrapper'}
          style={{ ...chargeLimitTooltipPosition }}
        >
          {chartVisibleElements.includes(BatteryStatusesKey.CHARGE_LIMIT) && (
            <AnalysisLineChartTooltip
              tooltip={
                <div className={'c-analysis-day-chart__line-chart-tooltip'}>
                  {I18n.t(T.customerSingle.analysis.dayChart.labels.chargeLimit.tooltip)}
                  <a onClick={() => goTo(PATHS.HELP('sonnenBatterie'))}>
                    {I18n.t(T.customerSingle.analysis.dayChart.labels.chargeLimit.tooltipLink)}
                  </a>
                </div>
              }
            />
          )}
        </div>
        <div
          className={'c-analysis-day-chart__line-chart-tooltip-wrapper'}
          style={{ ...vppActivityTooltipPosition }}
        >
          {chartVisibleElements.includes(BatteryStatusesKey.VPP_ACTIVITY) && (
            <AnalysisLineChartTooltip
              tooltip={
                <div className={'c-analysis-day-chart__line-chart-tooltip'}>
                  {I18n.t(T.customerSingle.analysis.dayChart.labels.vppActivity.tooltip)}
                  <a onClick={() => goTo(PATHS.HELP('sonnenVPP'))}>
                    {I18n.t(T.customerSingle.analysis.dayChart.labels.vppActivity.tooltipLink)}
                  </a>
                </div>
              }
            />
          )}
        </div>
        <div
          className={'c-analysis-day-chart__line-chart-tooltip-wrapper'}
          style={{ ...cellCareTooltipPosition }}
        >
          {chartVisibleElements.includes(BatteryStatusesKey.CELL_CARE) && (
            <AnalysisLineChartTooltip
              tooltip={
                <div className={'c-analysis-day-chart__line-chart-tooltip'}>
                  {I18n.t(T.customerSingle.analysis.dayChart.labels.cellCare.tooltip)}
                  <a onClick={() => goTo(PATHS.HELP('sonnenBatterie'))}>
                    {I18n.t(T.customerSingle.analysis.dayChart.labels.cellCare.tooltipLink)}
                  </a>
                </div>
              }
            />
          )}
        </div>
        <div
          className={classNames('c-analysis-day-chart__loader-container', {
            'is-active': isLoading,
          })}
        >
          <Loader className={'c-analysis-day-chart__loader'} />
        </div>
      </div>
    );
  }
}
