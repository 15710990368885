import { AnalysisChartColors, BatteryStatusesKey, SharedChartColors } from '@sonnen/shared-web';

import { LineChartViewStyle } from '@kanva/charts';
import { Paint, TextBaseline } from '@kanva/core';

import { CHART_FONT } from '+app/+customer/+analysis/+analysis.consts';

/**
 * Line charts styling
 */

const sharedLineSeriesStyle = {
  foreground: {
    width: 12,
    radius: 3,
  },
  background: {
    paint: new Paint().setFillStyle(SharedChartColors.Axis),
    width: 2,
    radius: 4,
  },
  minChunkLength: { domain: 0.1, px: 1 },
};

export const LineSeriesStyle: Record<BatteryStatusesKey, LineChartViewStyle> = {
  [BatteryStatusesKey.CHARGE_LIMIT]: {
    ...sharedLineSeriesStyle,
    foreground: {
      ...sharedLineSeriesStyle.foreground,
      paint: new Paint().setFillStyle(AnalysisChartColors[BatteryStatusesKey.CHARGE_LIMIT]),
    },
  },
  [BatteryStatusesKey.VPP_ACTIVITY]: {
    ...sharedLineSeriesStyle,
    foreground: {
      ...sharedLineSeriesStyle.foreground,
      paint: new Paint().setFillStyle(AnalysisChartColors[BatteryStatusesKey.VPP_ACTIVITY]),
    },
  },
  [BatteryStatusesKey.CELL_CARE]: {
    ...sharedLineSeriesStyle,
    foreground: {
      ...sharedLineSeriesStyle.foreground,
      paint: new Paint().setFillStyle(AnalysisChartColors[BatteryStatusesKey.CELL_CARE]),
    },
  },
};

/**
 * Line chart text label styling
 */

export const lineLabelPaint = new Paint()
  .setFillStyle(SharedChartColors.Labeling)
  .setTextBaseline(TextBaseline.BOTTOM)
  .setFont(CHART_FONT);
