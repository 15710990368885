import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, Loader } from '@sonnen/shared-web';

import { push } from 'connected-react-router';

import { isOfferBlocked } from '+app/+lead/+overview/containers/LeadOverviewConfigurations/LeadOverviewConfigurations.helper';
import { getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { LeadFlatConfigurationTile } from '+app/+lead/components';
import { useSetupLeadContext } from '+app/+lead/containers/SetupLead/SetupLead.context';
import { isConfigurationMultiplePv, isVppBonusGranted } from '+app/+lead/store/+lead.helper';
import { getLead, getLeadQueryStatus } from '+app/+lead/store/+lead.selectors';
import { NotFound } from '+app/+static/containers/NotFound/NotFound.component';
import { PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container, FormHeader } from '+shared/components';
import { LayoutBackground, LayoutContext } from '+shared/containers/Layout';
import { StoreState } from '+shared/store/store.interface';

import { LeadOfferScrollButton } from '../../components/LeadOfferScrollButton';
import { LeadOfferPageActions } from '../../store/+offer.actions';
import { getLeadOfferData } from '../../store/+offer.selectors';
import { OfferForm } from '../OfferForm';

import './LeadOfferComposer.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  offer: getLeadOfferData(state),
  leadQueryStatus: getLeadQueryStatus(state),
  productBatteryList: getProductBatteryList(state),
});

const mapDispatchToProps = mapActions({
  goToLead: (leadId: string, leadStage?: string) =>
    push(PATHS.LEAD_OVERVIEW({ leadId }, leadStage)),
  clearData: LeadOfferPageActions.clearData,
  getEnergyProviders: LeadOfferPageActions.getEnergyProviders,
  getUtilityData: LeadOfferPageActions.getUtilityData,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LeadOfferComposerComponent: React.FC<Props> = ({
  actions,
  lead,
  offer,
  leadQueryStatus,
  productBatteryList,
}) => {
  const { useTrackPageLoadDependingOnRoute } = useAdobeAnalyticsTracking();
  useTrackPageLoadDependingOnRoute('OFFER_COMPOSER');

  const layout = React.useContext(LayoutContext);
  const configuration = offer?.configuration;
  const { leadStage } = useSetupLeadContext();

  React.useEffect(() => {
    if (lead) {
      actions.getUtilityData(lead.id);
    }
  }, [lead]);

  React.useEffect(() => {
    layout.setBackground(LayoutBackground.GRAY);
    actions.getEnergyProviders('');

    return () => {
      layout.resetBackground();
      actions.clearData();
    };
  }, []);

  return (
    <>
      <FormHeader
        onClick={() => lead && actions.goToLead(lead.id, leadStage)}
        breadcrumb={I18n.t(T.lead.offer._salessolution_.buttonBack)}
        title={
          lead
            ? I18n.t(T.lead.offer._salessolution_.headline, {
                firstName: lead!.firstName,
                lastName: lead!.lastName,
              })
            : ''
        }
        suppressTitleInHotjar={true}
        isLoading={leadQueryStatus.pending}
      />

      <Media query={{ minWidth: breakpointUp('SM') }}>
        {(isDesktopMedia: boolean) => (
          <Container withHorizontalPadding={isDesktopMedia} className={'c-lead-offer-composer'}>
            {offer && configuration && productBatteryList ? (
              isOfferBlocked(offer) ? (
                <NotFound isFullScreen={false} />
              ) : (
                <div className={'o-grid'}>
                  <div className={'o-grid__column o-grid__column--sm-12 o-grid__column--no-gap-sm'}>
                    <LeadOfferScrollButton isVppBonus={isVppBonusGranted(configuration)} />
                  </div>

                  <div className={'o-grid__column o-grid__column--md-8'}>
                    <OfferForm
                      offer={offer}
                      isMultiplePv={isConfigurationMultiplePv(configuration)}
                    />
                  </div>

                  <div
                    className={'o-grid__column o-grid__column--md-4 c-lead-offer-composer__sidebar'}
                  >
                    <LeadFlatConfigurationTile
                      title={I18n.t(T.lead.offer._salessolution_.configurationName)}
                      configuration={configuration}
                      isTileActionDisabled={true}
                      isPending={false}
                      offer={offer}
                      productBatteryList={productBatteryList}
                    />
                  </div>
                </div>
              )
            ) : (
              <Loader />
            )}
          </Container>
        )}
      </Media>
    </>
  );
};

export const LeadOfferComposer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadOfferComposerComponent);
