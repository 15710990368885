import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as classNames from 'classnames';

import './FormFieldLabel.component.scss';

interface Props {
  label: string | JSX.Element;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export const FormFieldLabel: React.FC<Props> = ({
  label,
  children,
  isRequired = true,
  isDisabled = false,
}) => (
  <>
    <label
      className={classNames('c-form-field-label', {
        'c-form-field-label--disabled': isDisabled,
      })}
    >
      {label}
      {!isRequired && (
        <span className={'c-lead-new__optional-text-info'}>{` (${I18n.t(
          T.lead._salessolution_.optional
        ).toLowerCase()})`}</span>
      )}
    </label>
    {children}
  </>
);
