import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Link } from '@sonnen/shared-web';

import { isNil } from 'lodash';

export const getDocumentsList = (documents: any) => [
  {
    label: I18n.t(T.companyAccount.legalDocuments.termsAndConditions),
    value: !isNil(documents.termsAndConditions) ? (
      <Link
        label={documents.termsAndConditions.substring(
          documents.termsAndConditions.lastIndexOf('/') + 1
        )}
        href={documents.termsAndConditions}
        isTargetBlank={true}
      />
    ) : (
      '-'
    ),
  },
  {
    label: I18n.t(T.companyAccount.legalDocuments.cancellationPolicy),
    value: !isNil(documents.cancellationPolicy) ? (
      <Link
        label={documents.cancellationPolicy.substring(
          documents.cancellationPolicy.lastIndexOf('/') + 1
        )}
        href={documents.cancellationPolicy}
        isTargetBlank={true}
      />
    ) : (
      '-'
    ),
  },
  {
    label: I18n.t(T.companyAccount.legalDocuments.dataPrivacyPolicy),
    value: !isNil(documents.dataPrivacyPolicy) ? (
      <Link
        label={documents.dataPrivacyPolicy.substring(
          documents.dataPrivacyPolicy.lastIndexOf('/') + 1
        )}
        href={documents.dataPrivacyPolicy}
        isTargetBlank={true}
      />
    ) : (
      '-'
    ),
  },
];
