import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { UserRole } from '+shared/store/user';

const getNewsType = () => [
  {
    label: I18n.t(T.newsChannel.level.failure),
    value: 1,
  },
  {
    label: I18n.t(T.newsChannel.level.warning),
    value: 2,
  },
  {
    label: I18n.t(T.newsChannel.level.info),
    value: 3,
  },
];

const getNewsTag = () => [
  {
    value: 'important',
    label: I18n.t(T.newsChannel.tag.important),
  },
  {
    label: I18n.t(T.newsChannel.tag.new),
    value: 'new',
  },
  {
    label: I18n.t(T.newsChannel.tag.information),
    value: 'information',
  },
  {
    label: I18n.t(T.newsChannel.tag.urgentUpdate),
    value: 'urgentUpdate',
  },
  {
    label: I18n.t(T.newsChannel.tag.sonnenNews),
    value: 'sonnenNews',
  },
  {
    label: I18n.t(T.newsChannel.tag.sales),
    value: 'sales',
  },
  {
    label: I18n.t(T.newsChannel.tag.technicalDepartment),
    value: 'technicalDepartment',
  },
  {
    label: I18n.t(T.newsChannel.tag.partnerPortal),
    value: 'partnerPortal',
  },
];

const portalsCollection = [
  {
    value: 'SP',
    label: 'Partner Portal',
  },
  {
    value: 'CP',
    label: 'Customer Portal',
  },
];

const isDisplayedAsNotificationCollection = () => [
  {
    value: false,
    label: I18n.t(T.news.channel.sendNews.fields.isDisplayedAsNotification.value.no),
  },
  {
    value: true,
    label: I18n.t(T.news.channel.sendNews.fields.isDisplayedAsNotification.value.yes),
  },
];

const rolesCollection = () =>
  Object.keys(UserRole)
    .filter((role) => role !== 'SUPER_USER')
    .map((role) => ({
      value: UserRole[role],
      label: I18n.t(T.news.channel.rolesNames[UserRole[role]]),
    }));

export const SendNewsPartnerHelper = {
  getNewsType,
  getNewsTag,
  portalsCollection,
  rolesCollection,
  isDisplayedAsNotificationCollection,
};
