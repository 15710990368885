import { createReducer } from '+app/utils';
import { CUSTOMER_ACTIONS, CustomerActions } from '+customer/store';
import { queryReducer } from '+shared/store/query';

import { PV_SYSTEMS_ACTIONS, PvSystemsActions } from './pvSystems.actions';
import {
  GET_BATTERY_POWER_METER_LIVE_STATES_QUERY,
  initialState,
  PvSystemsPageState,
} from './pvSystems.state';

export const reducer = createReducer<PvSystemsPageState, PvSystemsActions | CustomerActions>(
  (state = initialState, action): PvSystemsPageState => {
    switch (action.type) {
      case PV_SYSTEMS_ACTIONS.SET_BATTERY_POWER_METER_LIVE_STATES:
        return {
          ...state,
          powerMeterLiveStates: action.powerMeterLiveStates,
        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      case PV_SYSTEMS_ACTIONS.SET_BATTERY_PV_GRID_FEED_IN_LIMIT_IN_PROGRESS:
        return {
          ...state,
          isBatteryPvGridFeedInLimitInProgress: action.isBatteryPvGridFeedInLimitInProgress,
        };
      default:
        return queryReducer(state, action, [GET_BATTERY_POWER_METER_LIVE_STATES_QUERY]);
    }
  }
);
