import {
  GenericNews,
  ListItemNews,
  News,
  NewsCP,
  Portal,
} from '+shared/store/firebase/types/news.interface';

import { dateUtil } from './date.util';

export const isSPNews = (news: GenericNews): news is News => news.portals.includes(Portal.SP);

export const isCPNews = (news: GenericNews): news is NewsCP => news.portals.includes(Portal.CP);

export const isSPListNews = (news: ListItemNews): news is ListItemNews<News> =>
  news.portals.includes(Portal.SP);

export const getNewsTimestamp = (news: GenericNews): Date => {
  if (isSPNews(news)) {
    const expiryDate = news.expiryDate
      ? dateUtil.of(news.expiryDate)
      : dateUtil.ofSecondsSince1970(0);
    const notificationExpiryDate = news.notificationExpiryDate
      ? dateUtil.of(news.notificationExpiryDate)
      : dateUtil.ofSecondsSince1970(0);
    // TODO: SON-27542 this fixes also a bug, check if this is right
    return dateUtil.isSameOrAfter(expiryDate, notificationExpiryDate)
      ? expiryDate
      : notificationExpiryDate;
  } else {
    return news.expiryDateTimestamp instanceof Date
      ? news.expiryDateTimestamp
      : news.expiryDateTimestamp.toDate();
  }
};

export const getNewsStatus = (news: GenericNews): boolean => {
  let newsDate: Date;

  if (isSPNews(news)) {
    if (!news.expiryDate) {
      return true;
    }

    const expiryDate = dateUtil.of(news.expiryDate);
    if (
      !news.notificationExpiryDate ||
      dateUtil.isSameOrAfter(expiryDate, news.notificationExpiryDate)
    ) {
      newsDate = expiryDate;
    } else {
      newsDate = dateUtil.of(news.notificationExpiryDate);
    }
  } else {
    if (news.expiryDateTimestamp instanceof Date) {
      newsDate = news.expiryDateTimestamp;
    } else {
      newsDate = news.expiryDateTimestamp.toDate();
    }
  }

  return dateUtil.isTodayOrAfter(newsDate);
};
