import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isEmpty } from 'lodash';

import {
  isFlatDirectConfiguration,
  isFlatXConfiguration,
} from '+app/+lead/+configuration/store/+configuration.helper';
import { isFlatOnlyOffer, isSentOrDraftOfferExpired } from '+app/+lead/+offer/store/+offer.helper';
import { isConfigurationMultiplePv } from '+app/+lead/store/+lead.helper';
import { CurrencyUnit, IMPACT_ANALYSIS_CUT_OFF_DATE } from '+app/App.constants';
import {
  formatMeasurementEnergy,
  formatMeasurementPeakPower,
  formatPricePerKwh,
} from '+lead-configuration/components/LeadConfigurationPreview';
import {
  batteryModelNameMap,
  batteryParametersToLabelMapper,
} from '+shared/store/lead/lead.helpers';
import {
  BatteryAccessoryInfo,
  LeadConfigurationResponseAttributes,
  LeadConfigurationResponsePvSystem,
  LeadOffer,
  LeadOfferProduct,
  LeadOfferTaxRegulation,
  NonMeasurementValues,
  OfferProductStatus,
} from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { dateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';
import { formatCurrency, formatDegree, formatPercentage } from '+utils/format.util.old';

import { LeadConfigurationLineType } from '../LeadConfigurationLine';

export const factorizeConfMainDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationAttribute[] => [
  // Free usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.userAllowance)}:`,
    value:
      formatMeasurementEnergy(configuration.usageAllowancePerYear) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'labelMedium',
  },
  // Basic Fee
  {
    title: isFlatDirectConfiguration(configuration)
      ? `${I18n.t(T.lead.configuration._salessolution_.preview.monthlyFee)}:`
      : `${I18n.t(T.lead.configuration._salessolution_.preview.basicFee)}:`,
    value:
      formatCurrency(configuration.baseFeeGrossPerMonth.value, currency) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
    type: 'labelMedium',
  },
];

export interface ConfigurationAttribute {
  title: string;
  value: string;
  type: LeadConfigurationLineType;
  inCollapsedViewVisible?: boolean;
  helperLabel?: string;
}

/**
 * Returns the configuration for the small_enterprise taxation mode.
 * For Small enterprise taxation configurations, we show more information and additional helper text
 */
export const getSmallEnterpriseTaxationConfigurationDetails = (
  conf: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationAttribute[] => [
  // Monthly pre-payment
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.monthlyPrepayment),
    value:
      formatCurrency(conf.initialAdvancePaymentGrossPerMonth.value, currency) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
    type: 'labelMedium',
    inCollapsedViewVisible: true,
  },
  // Excess energy
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.excessEnergy),
    value:
      formatMeasurementEnergy(conf.powerPlant.expectedExcessEnergyPerYear) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // Excess tariff
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.excessTarriff),
    value: formatPricePerKwh(conf.excessTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
    type: 'bodySmall',
    inCollapsedViewVisible: false,
    helperLabel: `${I18n.t(T.lead.configuration._salessolution_.preview.excessTariffHelper, {
      price: formatPricePerKwh(conf.valueAddedTaxPerKwh.value, currency, {
        asMinorUnit: true,
      }),
      vat: parseFloat(conf.valueAddedTax.value.toString()),
    })}`,
  },
  // Expected cashback
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.expectedCashback),
    value:
      formatCurrency(conf.powerPlant.expectedCashbackNetPerYear.value, currency) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'labelMedium',
    inCollapsedViewVisible: true,
  },
  // Expected unused free usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedUnusedUsageAllowance)}:`,
    value:
      formatMeasurementEnergy(conf.powerPlant.expectedUnusedUsageAllowancePerYear) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // Cashback rate
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.cashbackRate)}:`,
    value: formatPricePerKwh(conf.cashbackTariffNetPerKwh.value, currency, {
      asMinorUnit: true,
    }),
    type: 'bodySmall',
    inCollapsedViewVisible: false,
    helperLabel: I18n.t(T.lead.configuration._salessolution_.preview.cashbackRateHelper),
  },
  // vat total on used usage allowance
  {
    title: `${I18n.t(
      T.lead.configuration._salessolution_.preview.expectedVatOnUsedUsageAllowance
    )}:`,
    value:
      formatCurrency(conf.powerPlant.expectedVatOnUsedUsageAllowancePerYear.value, currency) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'labelMedium',
    inCollapsedViewVisible: true,
  },
  // prognosed used usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedUsedUsageAllowance)}:`,
    value:
      formatMeasurementEnergy(conf.powerPlant.expectedUsedUsageAllowancePerYear) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // vat in ct/kWh on used usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.vatPerKwhOnUsedUsageAllowance)}:`,
    value: formatPricePerKwh(conf.valueAddedTaxPerKwh?.value, currency, {
      asMinorUnit: true,
    }),
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // VPP bonus
  {
    title: I18n.t(T.lead.configuration._salessolution_.vpp.title),
    value:
      conf.powerPlant.guaranteedVppBonusGranted && conf.powerPlant.guaranteedVppBonusNetPerYear
        ? `${I18n.t(T.lead.configuration._salessolution_.vpp.minimum)} ` +
          formatCurrency(conf.powerPlant.guaranteedVppBonusNetPerYear.value, currency) +
          `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
        : I18n.t(T.lead.configuration._salessolution_.vpp.notEligible),
    type: 'labelMedium',
    inCollapsedViewVisible: true,
    helperLabel: I18n.t(T.lead.configuration._salessolution_.vpp.zeroPercentVatHelper),
  },
];

/**
 * Returns the configuration for the standard taxation mode.
 * For Small enterprise taxation configurations, we show more information and additional helper text
 */
export const getStandardTaxationConfigurationDetails = (
  conf: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationAttribute[] => [
  // Monthly pre-payment
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.monthlyPrepayment),
    value:
      formatCurrency(conf.initialAdvancePaymentGrossPerMonth.value, currency) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
    type: 'labelMedium',
    inCollapsedViewVisible: true,
  },
  // Excess energy
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.excessEnergy),
    value:
      formatMeasurementEnergy(conf.powerPlant.expectedExcessEnergyPerYear) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // Excess tariff
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.excessTarriff),
    value: formatPricePerKwh(conf.excessTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // Expected cashback
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.expectedCashback),
    value:
      formatCurrency(conf.powerPlant.expectedCashbackGrossPerYear.value, currency) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'labelMedium',
    inCollapsedViewVisible: true,
  },
  // Expected unused free usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedUnusedUsageAllowance)}:`,
    value:
      formatMeasurementEnergy(conf.powerPlant.expectedUnusedUsageAllowancePerYear) +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // Cashback rate
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.cashbackRate)}:`,
    value: formatPricePerKwh(conf.cashbackTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
    type: 'bodySmall',
    inCollapsedViewVisible: false,
  },
  // VPP bonus
  {
    title: I18n.t(T.lead.configuration._salessolution_.vpp.title),
    value:
      conf.powerPlant.guaranteedVppBonusGranted && conf.powerPlant.guaranteedVppBonusGrossPerYear
        ? `${I18n.t(T.lead.configuration._salessolution_.vpp.minimum)} ` +
          formatCurrency(conf.powerPlant.guaranteedVppBonusGrossPerYear.value, currency) +
          `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
        : I18n.t(T.lead.configuration._salessolution_.vpp.notEligible),
    type: 'labelMedium',
    inCollapsedViewVisible: true,
  },
];

export const factorizeHouseholdDetails = (
  conf: LeadConfigurationResponseAttributes
): ConfigurationAttribute[] => {
  const hasEVehicle = !isEmpty(conf?.expectedElectricityConsumption.electricCar);
  const hasHeatPump = !isEmpty(conf?.expectedElectricityConsumption.heatPump);

  return [
    {
      title: I18n.t(T.lead.configuration._salessolution_.preview.totalElectricityConsumption),
      value:
        formatMeasurementEnergy(conf.expectedElectricityConsumption.totalConsumptionPerYear) +
        `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      type: 'bodySmall',
    },
    {
      title:
        I18n.t(T.lead.configuration._salessolution_.preview.autarky) +
        getLargeConsumersMessage(hasEVehicle, hasHeatPump),
      value: formatPercentage(conf.powerPlant.expectedAutarky?.value, 2),
      type: 'bodySmall',
    },
  ];
};

const mapRoofDetailsName = (
  photovoltaicSystem:
    | LeadConfigurationResponseAttributes['photovoltaicSystem']
    | LeadConfigurationResponsePvSystem
) =>
  photovoltaicSystem.inclination?.value
    ? I18n.t(T.lead.configuration._salessolution_.pv.orientationInclination)
    : I18n.t(T.lead.configuration._salessolution_.pv.specificYield);

const mapRoofDetailsValue = (
  photovoltaicSystem:
    | LeadConfigurationResponseAttributes['photovoltaicSystem']
    | LeadConfigurationResponsePvSystem
) =>
  photovoltaicSystem.inclination?.value && photovoltaicSystem.orientation?.value
    ? `${formatDegree(photovoltaicSystem.orientation.value)}, ${formatDegree(
        photovoltaicSystem.inclination.value
      )}`
    : // eslint-disable-next-line max-len
      `${photovoltaicSystem.specificYieldPerYear?.value} ${photovoltaicSystem.specificYieldPerYear?.unit}` +
      `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`;

export const factorizePvSystemDetails = (
  conf: LeadConfigurationResponseAttributes
): ConfigurationAttribute[] => [
  {
    title: mapRoofDetailsName(conf.photovoltaicSystem),
    value: mapRoofDetailsValue(conf.photovoltaicSystem),
    type: 'bodySmall',
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.systemPower),
    value: formatMeasurementPeakPower(conf.photovoltaicSystem.peakPower),
    type: 'bodySmall',
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.commDate),
    value: formatDate(conf.photovoltaicSystem.commissioningDate),
    type: 'bodySmall',
  },
];

export const factorizeMultiplePvSystemDetails = (
  pvSystem: LeadConfigurationResponsePvSystem
): ConfigurationAttribute[] => [
  {
    title: mapRoofDetailsName(pvSystem),
    value: mapRoofDetailsValue(pvSystem),
    type: 'bodySmall',
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.systemPower),
    value: formatMeasurementPeakPower(pvSystem.peakPower),
    type: 'bodySmall',
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.commDate),
    value: formatDate(pvSystem.commissioningDate),
    type: 'bodySmall',
  },
];

export const factorizeBatteryDetails =
  (locale: string) =>
  (battery: LeadProductBattery): ConfigurationAttribute[] =>
    [
      {
        title: I18n.t(T.customerSingle.overview.battery.model),
        value: batteryModelNameMap(battery.modelName),
        type: 'bodySmall',
      },
      {
        title: I18n.t(T.customerSingle.overview.battery.capacity),
        value: batteryParametersToLabelMapper(locale)(battery.parameters),
        type: 'bodySmall',
      },
    ];

export const factorizeAccessoryDetails = (
  batteryAccessory: NonMeasurementValues[] | undefined
): ConfigurationAttribute[] | null => {
  if (!batteryAccessory) return null;

  const {
    acisland,
    backupbuffer,
    operatingMode: operatingModeTranslation,
  } = T.lead.configuration._salessolution_.protect;

  const sonnenProtect: ConfigurationAttribute[] = [];

  batteryAccessory.forEach((element) => {
    const { value } = element;

    let protectMode: string = BatteryAccessoryInfo.PROTECT_MODE_8000;
    let operatingMode = '';

    if (value.includes(BatteryAccessoryInfo.AC_ISLAND)) {
      operatingMode = I18n.t(acisland);
    } else if (value.includes(BatteryAccessoryInfo.BACKUP_BUFFER)) {
      operatingMode = I18n.t(backupbuffer);
    } else {
      protectMode = BatteryAccessoryInfo.PROTECT_MODE_4000;
    }

    sonnenProtect.push({
      title: I18n.t(T.lead.configuration._salessolution_.hardware.sonnenProtect),
      value: protectMode,
      type: 'bodySmall',
    });

    if (protectMode === BatteryAccessoryInfo.PROTECT_MODE_8000)
      sonnenProtect.push({
        title: I18n.t(operatingModeTranslation),
        value: operatingMode,
        type: 'bodySmall',
      });
  });

  return sonnenProtect;
};

const isSmallEnterpriseAllowedForSinglePv = (
  configuration: LeadConfigurationResponseAttributes
): boolean => {
  return dateUtil.isSameOrAfter(
    configuration.photovoltaicSystem.commissioningDate,
    new Date(IMPACT_ANALYSIS_CUT_OFF_DATE),
    'day'
  );
};

const isSmallEnterpriseAllowedForMultiplePv = (
  subSystems: LeadConfigurationResponsePvSystem[]
): boolean => {
  return subSystems.every((system) =>
    dateUtil.isSameOrAfter(system.commissioningDate, new Date(IMPACT_ANALYSIS_CUT_OFF_DATE), 'day')
  );
};

export const determineTaxRegulation = (
  configuration: LeadConfigurationResponseAttributes
): LeadOfferTaxRegulation => {
  if (isConfigurationMultiplePv(configuration)) {
    return isSmallEnterpriseAllowedForMultiplePv(configuration.photovoltaicSystem.subSystems!)
      ? LeadOfferTaxRegulation.SMALL_ENTERPRISE
      : LeadOfferTaxRegulation.STANDARD;
  }
  return isSmallEnterpriseAllowedForSinglePv(configuration)
    ? LeadOfferTaxRegulation.SMALL_ENTERPRISE
    : LeadOfferTaxRegulation.STANDARD;
};

export const isImpactAnalysisAllowedForTaxRegulation = (
  configuration: LeadConfigurationResponseAttributes
): boolean => {
  const taxRegulation = determineTaxRegulation(configuration);

  return taxRegulation === LeadOfferTaxRegulation.SMALL_ENTERPRISE;
};

export const getImpactAnalysisNotAvailableMsg = (
  offer: LeadOffer,
  configuration: LeadConfigurationResponseAttributes
): string | undefined => {
  const {
    iaNotAvailableForMultiplePV,
    iaNotAvailableForOldOffers,
    commissioningDateTooOldForImpactAnalysis,
  } = T.lead.overview._salessolution_.warning;

  if (isSentOrDraftOfferExpired(offer)) {
    return undefined; // specified to not show any message
  }

  // SON-37623: Block IA for offers created before release without taxRegulation. Remove later
  if (!offer.taxRegulation) {
    return I18n.t(iaNotAvailableForOldOffers);
  }

  if (isConfigurationMultiplePv(configuration)) {
    return I18n.t(iaNotAvailableForMultiplePV);
  }

  if (!isImpactAnalysisAllowedForTaxRegulation(configuration)) {
    return I18n.t(commissioningDateTooOldForImpactAnalysis);
  }

  return undefined;
};

export const getLargeConsumersMessage = (hasEVehicle: boolean, hasHeatPump: boolean) => {
  if (!hasEVehicle && !hasHeatPump) {
    return '';
  }

  const includingMsg = ` ${I18n.t(T.lead.configuration._salessolution_.largeConsumers.including)} `;
  const eVehicleMsg = hasEVehicle
    ? I18n.t(T.lead.configuration._salessolution_.largeConsumers.eVehicle)
    : '';
  const heatPumpMsg = hasHeatPump
    ? I18n.t(T.lead.configuration._salessolution_.largeConsumers.heatPump)
    : '';
  const andMsg =
    hasEVehicle && hasHeatPump
      ? ` ${I18n.t(T.lead.configuration._salessolution_.largeConsumers.and)} `
      : '';
  return includingMsg + eVehicleMsg + andMsg + heatPumpMsg;
};

const isFlatDirectWithExistingHardware = (
  configuration: LeadConfigurationResponseAttributes | undefined,
  offer: LeadOffer
) => isFlatDirectConfiguration(configuration) && isFlatOnlyOffer(offer);

const isFlatDirectWithNewHardware = (
  configuration: LeadConfigurationResponseAttributes | undefined,
  offer: LeadOffer
) => isFlatDirectConfiguration(configuration) && !isFlatOnlyOffer(offer);

const isFlatXWithExistingHardware = (
  configuration: LeadConfigurationResponseAttributes | undefined,
  offer: LeadOffer
) => isFlatXConfiguration(configuration) && isFlatOnlyOffer(offer);

const isFlatXWithNewHardware = (
  configuration: LeadConfigurationResponseAttributes | undefined,
  offer: LeadOffer
) => isFlatXConfiguration(configuration) && !isFlatOnlyOffer(offer);

export const getProductName = (
  configuration: LeadConfigurationResponseAttributes | undefined,
  offer: LeadOffer
): string => {
  if (isFlatDirectWithExistingHardware(configuration, offer)) {
    return I18n.t(
      T.lead._salessolution_.configurations.configurationTileHeader
        .sonnenFlatDirectWithExistingHardware
    );
  }
  if (isFlatDirectWithNewHardware(configuration, offer)) {
    return I18n.t(
      T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatDirectWithNewHardware
    );
  }
  if (isFlatXWithExistingHardware(configuration, offer)) {
    return I18n.t(
      T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatXWithExistingHardware
    );
  }
  if (isFlatXWithNewHardware(configuration, offer)) {
    return I18n.t(
      T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatXWithNewHardware
    );
  }
  return 'Unknown offer';
};

export type OfferProductExpiration = {
  label: string;
  value: string;
};

export const getFlatOfferExpiredAtDate = (
  offerProduct: LeadOfferProduct | undefined
): OfferProductExpiration | null => {
  if (!offerProduct) {
    return null;
  }
  if (!offerProduct.sentExpiresAt && offerProduct.status === OfferProductStatus.SENT_EXPIRED) {
    return null;
  }
  if (!offerProduct.sentExpiresAt && offerProduct.status === OfferProductStatus.SENT) {
    return null;
  }
  if (!offerProduct.draftExpiresAt && offerProduct.status === OfferProductStatus.DRAFT_EXPIRED) {
    return null;
  }
  if (!offerProduct.draftExpiresAt && offerProduct.status === OfferProductStatus.DRAFT) {
    return null;
  }

  const status = offerProduct.status;

  switch (status) {
    case OfferProductStatus.SENT:
      return {
        label: I18n.t(T.lead._salessolution_.configurations.expiresAt),
        value: formatDate(offerProduct.sentExpiresAt ?? dateUtil.now()),
      };
    case OfferProductStatus.SENT_EXPIRED:
      return {
        label: I18n.t(T.lead._salessolution_.configurations.expiredAt),
        value: formatDate(offerProduct.sentExpiresAt ?? dateUtil.now()),
      };
    case OfferProductStatus.DRAFT_EXPIRED:
      return {
        label: I18n.t(T.lead._salessolution_.configurations.expiredAt),
        value: formatDate(offerProduct.draftExpiresAt ?? dateUtil.now()),
      };
    case OfferProductStatus.DRAFT:
      return {
        label: I18n.t(T.lead._salessolution_.configurations.expiresAt),
        value: formatDate(offerProduct.draftExpiresAt ?? dateUtil.now()),
      };
    default:
      return null;
  }
};
