import { Query } from '+shared/store/query';

import { AdditionalFeatures, Document } from '../../../shared/store/setupTool/types';
import { getVppDocumentationInitialValues } from './+vppDocumentation.const';
import { VppRegisterStatus } from './+vppDocumentation.dictionary';
import { VppDocumentationInterface, VppState } from './types';

export const VPP_CONFIG_SUBMIT_QUERY = 'vppDocumentationConfigQuery';
export const VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY = 'vppGenerateCompletionNotificationQuery';

export interface VppDocumentationState {
  fields: VppDocumentationInterface;
  uploadedDocuments: Document[];
  completionNotificationDocument?: Document;
  completionNotificationRegeneratedAt?: string;
  submissionStatus?: VppRegisterStatus;
  vppAdditionalFeatures: AdditionalFeatures[];
  vppState: VppState;
  [VPP_CONFIG_SUBMIT_QUERY]: Query;
  [VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY]: Query;
}

export const initialState: VppDocumentationState = {
  fields: getVppDocumentationInitialValues(),
  uploadedDocuments: [],
  completionNotificationDocument: undefined,
  completionNotificationRegeneratedAt: undefined,
  submissionStatus: undefined,
  vppAdditionalFeatures: [],
  vppState: VppState.DISABLED,
  [VPP_CONFIG_SUBMIT_QUERY]: {},
  [VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY]: {},
};
