import React from 'react';

import classNames from 'classnames';

import './SwitchButton.scss';

interface Props {
  id: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  dataTestId: string;
}

const SwitchButton: React.FC<Props> = ({ id, isChecked, isDisabled, onChange, dataTestId }) => {
  return (
    <div className="switch-button" data-testid={dataTestId}>
      <input
        className="switch-button__checkbox"
        id={id}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
      />
      <label
        className={classNames('switch-button__label', {
          'switch-button__label--checked': isChecked,
        })}
        htmlFor={id}
      >
        <span
          className={classNames('switch-button__label-circle', {
            'switch-button__label-circle--checked': isChecked,
          })}
        />
      </label>
    </div>
  );
};

export default SwitchButton;
