import { __extends } from "tslib";
import { Paint, TextBaseline, WRAP_CONTENT } from '../canvas';
import { RequiredViewChanges, View } from '../view';
var TextView = (function (_super) {
    __extends(TextView, _super);
    function TextView(context) {
        var _this = _super.call(this, context, 'TextView') || this;
        _this.textPaint = new Paint();
        _this.textString = '';
        _this.text = [];
        return _this;
    }
    TextView.prototype.getTextPaint = function () {
        return this.textPaint.font;
    };
    TextView.prototype.setTextPaint = function (paint) {
        this.textPaint = paint;
        this.refresh();
    };
    TextView.prototype.getText = function () {
        return this.textString;
    };
    TextView.prototype.setText = function (text) {
        if (text === this.textString) {
            return;
        }
        this.textString = text;
        this.text = text ? text.split('\n') : [];
        this.refresh();
    };
    TextView.prototype.getY = function (textBaseline) {
        var lineHeight = this.getLineHeight();
        var textHeight = this.text.length * lineHeight;
        switch (textBaseline) {
            case TextBaseline.BOTTOM:
                return this.innerHeight - textHeight + lineHeight;
            case TextBaseline.MIDDLE:
                return (this.innerHeight - textHeight + lineHeight) / 2;
            case TextBaseline.TOP:
            default:
                return 0;
        }
    };
    TextView.prototype.getInternalWrappedWidth = function (canvas) {
        var _a = this, text = _a.text, textPaint = _a.textPaint;
        return canvas
            ? text.reduce(function (maxWidth, line) { return Math.max(maxWidth, canvas.measureText(line, textPaint).width); }, 0)
            : undefined;
    };
    TextView.prototype.getInternalWrappedHeight = function () {
        return this.getLineHeight() * this.text.length;
    };
    TextView.prototype.onDraw = function (canvas) {
        var _a = this, width = _a.width, text = _a.text, textPaint = _a.textPaint;
        var lineHeight = this.getLineHeight();
        var y = this.getY(this.textPaint.textBaseline);
        for (var i = 0, l = text.length; i < l; i++) {
            canvas.drawText(0, y + i * lineHeight, text[i], textPaint, undefined, width);
        }
    };
    TextView.prototype.onSnapshot = function () {
        return {
            text: this.textString,
            textPaint: this.textPaint.snapshot(),
        };
    };
    TextView.prototype.refresh = function () {
        if (this.lp.w === WRAP_CONTENT || this.lp.h === WRAP_CONTENT) {
            this.require(RequiredViewChanges.MEASURE);
        }
        else {
            this.require(RequiredViewChanges.DRAW);
        }
    };
    TextView.prototype.getLineHeight = function () {
        return this.textPaint && this.textPaint.getLineHeight();
    };
    return TextView;
}(View));
export { TextView };
