import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { CaseAggregatedStatus } from '+shared/store/case';

import { StatusTileColors } from '../StatusTile';

export const setCaseStatusAggregated = (status: CaseAggregatedStatus) =>
  ({
    [CaseAggregatedStatus.OPEN]: I18n.t(T.cases.statusFilter.open),
    [CaseAggregatedStatus.IN_PROGRESS]: I18n.t(T.cases.statusFilter.inProgress),
    [CaseAggregatedStatus.RESOLVED]: I18n.t(T.cases.statusFilter.resolved),
  }[status]);

export const setCaseStatusAggregatedColor = (status: CaseAggregatedStatus) =>
  ({
    [CaseAggregatedStatus.OPEN]: StatusTileColors.BLUE,
    [CaseAggregatedStatus.IN_PROGRESS]: StatusTileColors.YELLOW,
    [CaseAggregatedStatus.RESOLVED]: StatusTileColors.GREEN,
  }[status]);
