import React from 'react';

import { BodyLarge } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge, LabelSmall } from '+shared/basicComponents/Typography/Labels';

import { ConfigurationBoxHeaderSize, ConfigurationBoxLine } from '../../LeadConfigurationPreview';

type Props = Omit<ConfigurationBoxLine, 'alwaysVisible'>;

const LeadConfigurationBoxLine: React.FC<Props> = ({ title, value, size, helperText }) => (
  <>
    <div className="c-lead-configuration-box__headline">
      {size === ConfigurationBoxHeaderSize.LARGE ? (
        <h4 className={`c-lead-configuration-box__headline--${size}`}>{title}</h4>
      ) : (
        <BodyLarge text={title} />
      )}
      {helperText && (
        <LabelSmall
          text={helperText}
          className={`
            c-lead-configuration-box__headline-helper-text
            c-lead-configuration-box__headline-helper-text--${size}
          `}
        />
      )}
    </div>
    <div className={'c-lead-configuration-box__subheadline-wrapper'}>
      <LabelLarge text={value} />
    </div>
  </>
);

export default LeadConfigurationBoxLine;
