import { createSelector } from 'reselect';

import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';

import {
  ASSIGN_PARTNER_TO_LEAD_QUERY,
  CHANGE_LEAD_STATUS_QUERY,
  DECLINE_LEAD_QUERY,
  GET_DELIVERY_ADDRESS_QUERY,
  GET_INVOICE_ADDRESS_QUERY,
  GET_LEAD_QUERY,
  LEAD_SUBMIT_QUERY,
  LEAD_UPDATE_QUERY,
  VALIDATE_LEAD_ADDRESS_QUERY,
} from './+lead.state';

export const getLeadPage = (state: StoreState) => state.page.lead;

export const getLeadCommonState = createSelector(getLeadPage, (state) => state.common);

export const getLead = createSelector(getLeadCommonState, (state) => state.lead);

export const getDynamicTariff = createSelector(
  getLeadCommonState,
  (state) => state.hasActiveDynamicTariffContract
);

export const getUserPermissions = createSelector(
  getLeadCommonState,
  (state) => state.userPermissions
);

export const getLeadQuery = createSelector(getLeadCommonState, (state) => state[GET_LEAD_QUERY]);

export const getLeadQueryStatus = createSelector(getLeadQuery, (query) => getStatus(query));

export const getLeadStatus = createSelector(
  getLead,
  (lead) => lead && lead.status.currentStatus && lead.status.currentStatus.name
);

export const getLeadForm = createSelector(getLeadCommonState, (state) => state.leadForm);

export const getLeadSubmitQuery = createSelector(
  getLeadCommonState,
  (state) => state[LEAD_SUBMIT_QUERY]
);

export const getLeadSubmitQueryStatus = createSelector(getLeadSubmitQuery, (query) =>
  getStatus(query)
);

export const getLeadUpdateQuery = createSelector(
  getLeadCommonState,
  (state) => state[LEAD_UPDATE_QUERY]
);

export const getLeadUpdateQueryStatus = createSelector(getLeadUpdateQuery, (query) =>
  getStatus(query)
);

export const getPartnerId = createSelector(
  getLead,
  (state) => state && state.partner && state.partner.contactId
);

export const getPartnerNotesId = createSelector(getLead, (lead) => lead && lead.note);

export const getChangeLeadStatusQuery = createSelector(
  getLeadCommonState,
  (state) => state[CHANGE_LEAD_STATUS_QUERY]
);

export const getChangeLeadStatusQueryStatus = createSelector(getChangeLeadStatusQuery, (query) =>
  getStatus(query)
);

export const getPartnerEmployeeList = createSelector(
  getLeadCommonState,
  (state) => state.partnerEmployeeList
);

export const getPartnerAssignment = createSelector(
  getLeadCommonState,
  (state) => state.partnerAssignment
);

export const getSelectedLead = createSelector(getPartnerAssignment, (state) => state.selectedLead);

export const getIsAssignLeadModalOpen = createSelector(
  getPartnerAssignment,
  (state) => state.isAssignLeadModalOpen
);

export const getIsDeclineLeadModalOpen = createSelector(
  getPartnerAssignment,
  (state) => state.isDeclineLeadModalOpen
);

export const getAssignLeadQuery = createSelector(
  getLeadCommonState,
  (state) => state[ASSIGN_PARTNER_TO_LEAD_QUERY]
);

export const getAssignLeadQueryStatus = createSelector(getAssignLeadQuery, (query) =>
  getStatus(query)
);

export const getDeclineLeadQuery = createSelector(
  getLeadCommonState,
  (state) => state[DECLINE_LEAD_QUERY]
);

export const getDeclineLeadQueryStatus = createSelector(getDeclineLeadQuery, (query) =>
  getStatus(query)
);

export const getDeliveryAddressSuggestions = createSelector(
  getLeadCommonState,
  (state) => state.deliveryAddressSuggestions
);

export const getDeliveryAddressSuggestionsQuery = createSelector(
  getLeadCommonState,
  (state) => state[GET_DELIVERY_ADDRESS_QUERY]
);

export const getInvoiceAddressSuggestions = createSelector(
  getLeadCommonState,
  (state) => state.invoiceAddressSuggestions
);

export const getInvoiceAddressSuggestionsQuery = createSelector(
  getLeadCommonState,
  (state) => state[GET_INVOICE_ADDRESS_QUERY]
);

export const getValidateLeadAddressQuery = createSelector(
  getLeadCommonState,
  (state) => state[VALIDATE_LEAD_ADDRESS_QUERY]
);

export const getValidateLeadAddressQueryStatus = createSelector(
  getValidateLeadAddressQuery,
  getStatus
);

export const getIsAddressValid = createSelector(
  getLeadCommonState,
  (state) => state.isAddressValid
);

export const getOpenAddressEdit = createSelector(
  getLeadCommonState,
  (state) => state.openAddressEdit
);

export const getAsyncLeadStatusToBeSaved = createSelector(
  getLeadCommonState,
  (state) => state.leadStatusToBeChanged
);

// SUB-STATE SELECTORS

export const getLeadConfiguration = createSelector(getLeadPage, (state) => state.configuration);

export const getLeadOverview = createSelector(getLeadPage, (state) => state.overview);

export const getLeadOffer = createSelector(getLeadPage, (state) => state.offer);

export const getLeadHardware = createSelector(getLeadPage, (state) => state.hardware);

export const getLeadImpactAnalysis = createSelector(getLeadPage, (state) => state.impactAnalysis);

export const getLeadData = createSelector(getLeadCommonState, (state) => state.lead);

export const getLeadId = createSelector(getLeadData, (lead) => (lead ? lead.id : null));
