export const formFields = {
  SPECIFIC_YIELD_PER_YEAR: 'specificYieldPerYear',
  INCLINATION: 'inclination',
  ORIENTATION: 'orientation',
  COMMISSIONING_DATE: 'commissioningDate',
  PEAK_POWER: 'peakPower',
  PV_TYPE: 'pvType',
  FIRST_PV_SYSTEM_NAME: 'firstPvSystemName',
  SECOND_PV_SPECIFIC_YIELD_PER_YEAR: 'secondPvSpecificYieldPerYear',
  SECOND_PV_INCLINATION: 'secondPvInclination',
  SECOND_PV_ORIENTATION: 'secondPvOrientation',
  SECOND_PV_COMMISSIONING_DATE: 'secondPvCommissioningDate',
  SECOND_PV_PEAK_POWER: 'secondPvPeakPower',
  SECOND_PV_PV_TYPE: 'secondPvPvType',
  SECOND_PV_SYSTEM_NAME: 'secondPvSystemName',
  MULTIPLE_PV: 'multiplePv',
  DSO_CONSENT_TO_COMBINE_PHOTOVOLTAIC_SYSTEMS: 'dsoConsentToCombinePhotovoltaicSystems',
} as const;

export const minCommissioningDate = new Date('1960-01-01');

export enum PvKind {
  FIRST_PV = 'firstPv',
  SECOND_PV = 'secondPv',
}

export const getFormFieldsForEachPv = (pvKind: PvKind) =>
  ({
    [PvKind.FIRST_PV]: {
      specificYield: formFields.SPECIFIC_YIELD_PER_YEAR,
      inclination: formFields.INCLINATION,
      orientation: formFields.ORIENTATION,
      peakPower: formFields.PEAK_POWER,
      commissioningDate: formFields.COMMISSIONING_DATE,
      pvType: formFields.PV_TYPE,
      systemName: formFields.FIRST_PV_SYSTEM_NAME,
    },
    [PvKind.SECOND_PV]: {
      specificYield: formFields.SECOND_PV_SPECIFIC_YIELD_PER_YEAR,
      inclination: formFields.SECOND_PV_INCLINATION,
      orientation: formFields.SECOND_PV_ORIENTATION,
      peakPower: formFields.SECOND_PV_PEAK_POWER,
      commissioningDate: formFields.SECOND_PV_COMMISSIONING_DATE,
      pvType: formFields.SECOND_PV_PV_TYPE,
      systemName: formFields.SECOND_PV_SYSTEM_NAME,
    },
  }[pvKind]);

export const getMultiplePvLabelNumber = (multiplePv: boolean, pvKind: PvKind) => {
  if (!multiplePv) {
    return '';
  }

  return pvKind === PvKind.FIRST_PV ? '(1)' : '(2)';
};
