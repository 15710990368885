var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as qs from 'qs';
import merge from 'lodash/merge';
export var urlDestruct = function (url, options) {
    var _a = url.split('?'), path = _a[0], query = _a[1];
    return {
        url: path,
        query: query ? urlDecode(query, options) : {},
    };
};
export var urlCombine = function (sourceUrl, sourceQuery, options) {
    var _a = urlDestruct(sourceUrl), url = _a.url, query = _a.query;
    var queryString = urlEncode(merge(sourceQuery, query), __assign(__assign({}, options), { addQueryPrefix: false }));
    return url + (queryString ? "?" + queryString : '');
};
export var urlEncode = function (value, options) { return qs.stringify(value, __assign({ encodeValuesOnly: true }, options)); };
export var urlDecode = function (value, options) { return qs.parse(value, options); };
