import { startOfYear, subYears } from 'date-fns';
import { Location } from 'history';
import { head, last } from 'lodash';
import { isNumber } from 'lodash/fp';

import { EnergyUnit, OtherUnit, PeakPowerUnit } from '+app/App.constants';
import { ROUTES } from '+router/routes';
import {
  LeadConfigurationResponseAttributes,
  LeadProductType,
  LeadProductVersion,
} from '+shared/store/lead/types';
import { dateUtil } from '+utils/date.util';

import {
  getFormFieldsForEachPv,
  PvKind,
} from '../containers/LeadConfigurationPv/LeadConfigurationPv.helper';
import { ConfigurationForm } from './types/configuration.interface';

// Oldest allowed day: First january 20 years ago from the current year
export const maxOldestFlatXCommissioningDate = (): Date => startOfYear(subYears(new Date(), 20));

export const isFlatDirectConfiguration = (
  configuration: LeadConfigurationResponseAttributes | undefined
) => configuration?.product === LeadProductType.FLAT_DIRECT;

export const isFlatXConfiguration = (
  configuration: LeadConfigurationResponseAttributes | undefined
) => configuration?.product === LeadProductType.FLAT_X;

export const createSinglePvSystem = (configurationForm: ConfigurationForm, pvKind: PvKind) => ({
  peakPower: {
    value: configurationForm[getFormFieldsForEachPv(pvKind).peakPower],
    unit: PeakPowerUnit.KWP,
  },
  inclination: isNumber(configurationForm[getFormFieldsForEachPv(pvKind).inclination])
    ? {
        value: configurationForm[getFormFieldsForEachPv(pvKind).inclination],
        unit: OtherUnit.DEGREE,
      }
    : undefined,
  orientation: isNumber(configurationForm[getFormFieldsForEachPv(pvKind).orientation])
    ? {
        value: configurationForm[getFormFieldsForEachPv(pvKind).orientation],
        unit: OtherUnit.DEGREE,
      }
    : undefined,
  specificYieldPerYear: isNumber(configurationForm[getFormFieldsForEachPv(pvKind).specificYield])
    ? {
        value: configurationForm[getFormFieldsForEachPv(pvKind).specificYield],
        unit: EnergyUnit.KWH_PER_KWP,
      }
    : undefined,
  commissioningDate: configurationForm[getFormFieldsForEachPv(pvKind).commissioningDate],
  systemName: configurationForm[getFormFieldsForEachPv(pvKind).systemName],
});

export const arePhotovoltaicSystemsCompatible = (
  firstPvCommissioningDate: Date,
  secondPvCommissioningDate: Date
) => {
  const diffInMonths = Math.abs(
    dateUtil.getDifference(firstPvCommissioningDate, secondPvCommissioningDate, 'months')
  );

  const acceptableDifferenceMonthsCount = 12;

  return diffInMonths >= acceptableDifferenceMonthsCount;
};

const getRouteLastElement = (route: string | undefined) => last(route?.split('/'));

type ConfigurationTypeAndVersion = {
  productType: LeadProductType;
  productVersion: LeadProductVersion;
};

export const getConfigurationTypeAndVersion = (location: Location): ConfigurationTypeAndVersion => {
  const newFlatDirectRouteIdentifier = getRouteLastElement(
    head(ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT)
  );

  if (newFlatDirectRouteIdentifier && location.pathname.includes(newFlatDirectRouteIdentifier)) {
    return {
      productType: LeadProductType.FLAT_DIRECT,
      productVersion: LeadProductVersion.V_2_0,
    };
  }

  return {
    productType: LeadProductType.FLAT_X,
    productVersion: LeadProductVersion.V_1_0,
  };
};
