import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DS } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { FormikProps } from 'formik';

import { getLead } from '+app/+lead/store/+lead.selectors';
import { PATHS } from '+router/routes';
import { LeadProductType } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { ConfigurationForm } from '../../store/types';
import { ProtectInstalledRadioButtons } from './ProtectInstalledRadioButtons';
import { ProtectModeRadioButtons } from './ProtectModeRadioButtons';
import { SwitchFlatModal } from './SwitchFlatModal';

import './index.scss';

export const formFields = {
  PROTECT_INSTALLED: 'protectInstalled',
  PROTECT_MODE: 'protectMode',
} as const;

interface ComponentProps {
  form: FormikProps<ConfigurationForm>;
  flatType: LeadProductType;
  isProtectModeAC: boolean;
  isPerformanceBattery: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
});

const mapDispatchToProps = mapActions({
  goToNewFlatDirect: (leadId: string, leadStage?: string, state?: any) =>
    push(PATHS.LEAD_CONFIGURATION_NEW_FLAT_DIRECT({ leadId }, leadStage), state),
});

export const LeadConfigurationProtectComponent: React.FC<Props> = ({
  form,
  flatType,
  lead,
  actions,
  isProtectModeAC,
  isPerformanceBattery,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [keepFlatX, setKeepFlatX] = useState(false);

  const { protectInstalled: isProtectInstalled, protectMode } = form.values;
  const isFlatDirect = flatType === LeadProductType.FLAT_DIRECT;

  const leadId = lead ? lead.id : undefined;

  const { sonnenProtect } = T.lead.configuration._salessolution_.hardware;
  const {
    protect4000InstalledQuestion,
    protect8000InstalledQuestion,
    protectModeQuestion,
    infoBox,
    warningBox,
  } = T.lead.configuration._salessolution_.protect;

  const protectInstalledQuestion = isPerformanceBattery
    ? protect8000InstalledQuestion
    : protect4000InstalledQuestion;

  useEffect(() => {
    if (!isProtectInstalled || !isPerformanceBattery) {
      /* reset protectMode input if user:
       *    - switches protectInstalled back from Y to N
       *    - changes to a battery that does not support protect mode
       * */
      form.setFieldValue('protectMode', undefined);
    }
  }, [isProtectInstalled, isPerformanceBattery]);

  useEffect(() => {
    isProtectModeAC && !isFlatDirect ? setModalIsOpen(true) : setModalIsOpen(false);

    if (!isProtectModeAC) setKeepFlatX(false);
  }, [protectMode]);

  const onKeepFlatX = () => {
    setModalIsOpen(false);
    setKeepFlatX(true);
  };

  const onSwitchToFlatDirect = () => {
    if (leadId) actions.goToNewFlatDirect(leadId);
  };

  return (
    <div className="lead-configuration-protect">
      <p className="lead-configuration-protect__subheadline">{I18n.t(sonnenProtect)}</p>

      <ProtectInstalledRadioButtons form={form} question={I18n.t(protectInstalledQuestion)} />

      {isPerformanceBattery && isProtectInstalled && (
        <>
          <ProtectModeRadioButtons form={form} question={I18n.t(protectModeQuestion)} />

          {isProtectModeAC && !isFlatDirect && keepFlatX && (
            <>
              <div className="lead-configuration-protect__info-box">
                <DS.Alert theme={DS.AlertTheme.INFO} text={I18n.t(infoBox)} />
              </div>
              <DS.Alert theme={DS.AlertTheme.WARNING} text={I18n.t(warningBox)} />
            </>
          )}
        </>
      )}

      <SwitchFlatModal isOpen={modalIsOpen} onKeep={onKeepFlatX} onSwitch={onSwitchToFlatDirect} />
    </div>
  );
};

export const LeadConfigurationProtect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadConfigurationProtectComponent);
