import * as React from 'react';

export enum LayoutBackground {
  WHITE,
  GRAY,
}

export interface LayoutContext {
  setBackground: (bg: LayoutBackground) => void;
  resetBackground: () => void;
}

export const LayoutContext = React.createContext<LayoutContext>({
  setBackground: (bg: LayoutBackground) => null,
  resetBackground: () => null,
});
