import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import * as Yup from 'yup';

import { ConfigurationConsumptionForm } from '../types';

export const ConfigurationConsumptionSchema = () =>
  Yup.object().shape({
    totalConsumptionPerYear: Yup.number()
      .integer(I18n.t(T.validation.wholeNumber))
      .positive(I18n.t(T.validation.greaterThan0))
      .required(I18n.t(T.register.alert.requiredField)),
    heatPump: Yup.boolean(),
    eVehicle: Yup.boolean(),
  });

export const configurationConsumptionFormInitial: ConfigurationConsumptionForm = {
  totalConsumptionPerYear: '',
  eVehicle: false,
  heatPump: false,
};
