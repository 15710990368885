import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import { Carousel } from '@sonnen/shared-web/src/components/Carousel/Carousel.component';

import * as uuid from 'uuid';

import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { TutorialSlide } from '+shared/components/TutorialSlide';
import { StoreState } from '+shared/store/store.interface';
import { TutorialActions } from '+shared/store/tutorial';
import {
  getTutorialInitialSlideObject,
  getTutorialOpenedObject,
} from '+shared/store/tutorial/tutorial.selectors';
import { mapActions } from '+utils/redux/mapActions.util';

import { getSlides } from './Tutorial.helper';

import './Tutorial.component.scss';

interface TutorialProps {
  id: string;
  dataTestId?: string;
}

const mapStateToProps = (state: StoreState) => ({
  tutorialInitialSlideObject: getTutorialInitialSlideObject(state),
  tutorialOpenedObject: getTutorialOpenedObject(state),
});

const mapDispatchToProps = mapActions({
  toggleTutorial: TutorialActions.toggleTutorial,
  appendCurrentlyDisplayedSlides: TutorialActions.appendCurrentlyDisplayedSlides,
  removeCurrentlyDisplayedSlides: TutorialActions.removeCurrentlyDisplayedSlides,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TutorialProps;

const TutorialComponent: React.FC<Props> = ({
  id,
  tutorialInitialSlideObject,
  tutorialOpenedObject,
  actions,
  dataTestId,
}) => {
  const isTutorialOpened = tutorialOpenedObject[id];
  const initialSlideNumber = tutorialInitialSlideObject[id];
  const slides = getSlides(id);

  const toggleTutorialWithTracking = () => {
    actions.toggleTutorial(id, !isTutorialOpened);
  };

  React.useEffect(() => {
    actions.appendCurrentlyDisplayedSlides(slides);

    const tutorialContentElement = document.getElementById('_' + id);
    if (tutorialContentElement) {
      const arrowElements = tutorialContentElement.getElementsByClassName(
        'sw-carousel-arrow__button'
      ) as any;
      const videoElements = document.getElementsByClassName('tutorial-step__video') as any;
      if (arrowElements) {
        for (const arrowElement of arrowElements) {
          arrowElement.addEventListener('click', () => {
            if (videoElements) {
              Array.prototype.forEach.call(videoElements, (videoElement) => {
                videoElement.contentWindow.postMessage(
                  JSON.stringify({ event: 'command', func: 'pauseVideo' }),
                  '*'
                );
              });
            }
          });
        }
      }
    }

    return () => {
      actions.removeCurrentlyDisplayedSlides();
    };
  }, [isTutorialOpened]);

  return (
    <section className={'tutorial'} id={'_' + id}>
      <header className={'tutorial__header'}>
        <p className={'tutorial__title'}>
          <Icon.PlayVideo className={'tutorial__icon'} />
          {I18n.t(T.tutorialComponent.title)}
        </p>
        <Button
          name={'tutorial-visibility-switcher'}
          size={ButtonSize.SMALL}
          type={ButtonType.SECONDARY}
          label={
            isTutorialOpened
              ? I18n.t(T.tutorialComponent.hideGuide)
              : I18n.t(T.tutorialComponent.showGuide)
          }
          dataTestId={dataTestId || id}
          onClick={toggleTutorialWithTracking}
        />
      </header>
      {isTutorialOpened && (
        <div className={'tutorial__content'}>
          <Carousel
            arrowsOnDesktop={true}
            arrowsOnMobile={true}
            autoplay={false}
            infinite={true}
            initialSlide={initialSlideNumber}
            goToInitialSlideAfterComponentUpdate={true}
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            speed={600}
          >
            {slides.map(
              ({
                slideNumber,
                slideType,
                title,
                description,
                linkHref,
                linkText,
                imgSrc,
                videoSrc,
              }) => (
                <TutorialSlide
                  key={uuid.v4()}
                  numAllSlides={slides.length}
                  slideNumber={slideNumber}
                  slideType={slideType}
                  title={title}
                  description={description}
                  linkHref={linkHref}
                  linkText={linkText}
                  imgSrc={imgSrc}
                  videoSrc={videoSrc}
                />
              )
            )}
          </Carousel>
        </div>
      )}
    </section>
  );
};

export const Tutorial = connect(mapStateToProps, mapDispatchToProps)(TutorialComponent);
