import { PointerAction } from '../canvas-pointer-event';
var GestureDetector = (function () {
    function GestureDetector() {
        this.previousEvent = null;
    }
    GestureDetector.prototype.onPointerEvent = function (event) {
        if (!this.previousEvent ||
            event.action === PointerAction.CANCEL ||
            event.action === PointerAction.UP) {
            this.previousEvent = event;
        }
        var handled = this.onGestureEvent(event, this.previousEvent);
        this.previousEvent = event;
        return handled;
    };
    return GestureDetector;
}());
export { GestureDetector };
