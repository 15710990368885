import { Line, normalizeRadius, Point, TextAlign, TextBaseline, } from '@kanva/core';
export var LabelPosition;
(function (LabelPosition) {
    LabelPosition[LabelPosition["START"] = 0] = "START";
    LabelPosition[LabelPosition["CENTER"] = 1] = "CENTER";
    LabelPosition[LabelPosition["END"] = 2] = "END";
    LabelPosition[LabelPosition["OUT"] = 3] = "OUT";
})(LabelPosition || (LabelPosition = {}));
var labelPaintOverrides = {
    textBaseline: TextBaseline.BOTTOM,
    textAlign: TextAlign.CENTER,
};
var getPaint = function (paint, contrastPaint, backgroundIsBright) { return paint && (!contrastPaint
    ? paint
    : backgroundIsBright === paint.isBright() ? contrastPaint : paint); };
var LabelsHelper = (function () {
    function LabelsHelper() {
        this.temporaryPoint = new Point();
        this.temporaryLine = new Line();
        this.fillRadius = normalizeRadius(0);
    }
    LabelsHelper.prototype.getOptions = function () {
        return this.options;
    };
    LabelsHelper.prototype.setOptions = function (options) {
        this.options = options;
        this.fillRadius = normalizeRadius(options.backgroundRadius);
    };
    LabelsHelper.prototype.draw = function (canvas, value, index, line, lineBackground) {
        if (!this.options) {
            throw new Error('LabelsHelper requires "options" to be present in order to draw labels.');
        }
        var _a = this.options, _b = _a.padding, padding = _b === void 0 ? 0 : _b, _c = _a.margin, margin = _c === void 0 ? 0 : _c, labelsPaint = _a.labelsPaint, backgroundPaint = _a.backgroundPaint, contrastLabelsPaint = _a.contrastLabelsPaint, contrastBackgroundPaint = _a.contrastBackgroundPaint, position = _a.position, labelAccessor = _a.labelAccessor, _d = _a.isBackgroundBright, isBackgroundBright = _d === void 0 ? true : _d;
        var _e = this, temporaryPoint = _e.temporaryPoint, temporaryLine = _e.temporaryLine, fillRadius = _e.fillRadius;
        var textHeight = labelsPaint.font.fontSize;
        var spacing = padding + margin;
        line.cloneTo(temporaryLine);
        switch (position) {
            case LabelPosition.START:
                temporaryLine
                    .move(spacing)
                    .extend(-2 * spacing - (line.startY < line.endY ? textHeight : 0))
                    .pointAt(0, temporaryPoint);
                break;
            case LabelPosition.END:
                temporaryLine
                    .move(padding)
                    .extend(-2 * spacing - (line.startY > line.endY ? textHeight : 0))
                    .pointAt(1, temporaryPoint);
                break;
            case LabelPosition.CENTER:
                temporaryLine
                    .move(padding)
                    .extend(-2 * spacing + textHeight * (line.startY < line.endY ? 1.5 : -.5))
                    .pointAt(.5, temporaryPoint);
                break;
            case LabelPosition.OUT:
            default:
                temporaryLine
                    .extend(spacing + (line.startY < line.endY ? textHeight : 0))
                    .pointAt(1, temporaryPoint);
                break;
        }
        var x = temporaryPoint.x, y = temporaryPoint.y;
        var textInsideBar = position !== LabelPosition.OUT && temporaryLine.length() >= textHeight + padding * 2;
        if (!textInsideBar && position !== LabelPosition.OUT) {
            line.cloneTo(temporaryLine)
                .extend(padding + margin + (line.startY < line.endY ? textHeight : 0))
                .pointAt(1, temporaryPoint);
            x = temporaryPoint.x;
            y = temporaryPoint.y;
        }
        var backgroundIsBright = textInsideBar ? lineBackground.isBright() : isBackgroundBright;
        var textPaint = getPaint(labelsPaint, contrastLabelsPaint, backgroundIsBright);
        var blockPaint = textPaint === labelsPaint ? backgroundPaint : contrastBackgroundPaint;
        var text = labelAccessor(value, index);
        if (blockPaint) {
            var width = canvas.measureText(text, textPaint).width;
            canvas.context.beginPath();
            canvas.roundRect(x - width / 2 - padding, y - textHeight - padding, width + 2 * padding, textHeight + 2 * padding, fillRadius);
            canvas.drawPath(blockPaint);
        }
        canvas.drawText(x, y, text, textPaint, labelPaintOverrides);
    };
    return LabelsHelper;
}());
export { LabelsHelper };
