import { from } from 'rxjs';

import { Config } from '+config/config';
import { httpClient } from '+shared/network/network.client';

import { generateQuery, getCasesQueryResults } from './graphQL.helpers';
import { CasesQueryResult, QueryParams } from './types';

const getCaseList = (params: QueryParams) =>
  from(
    httpClient
      .post<CasesQueryResult>(`${Config.AUTH.AUTH_URL}/graphql`, {
        body: { query: generateQuery(params) },
      })
      .then((response) => response.parsedBody())
      .then(getCasesQueryResults)
  );

export const GraphQLRepository = {
  getCaseList,
};
