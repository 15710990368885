import React from 'react';

import classNames from 'classnames';

import { dateUtil } from '+utils/date.util';
import { UtilDuration } from '+utils/UtilDuration';

import {
  TARIFF_WINDOW_MINIMUM_DURATION,
  TariffType,
  TariffWindowInternal,
} from '../../store/battery-tariff';

import './BatteryTariffWindow.component.scss';

interface BatteryTariffWindowProps extends TariffWindowInternal {
  pixelsPerChunk: number;
}

const mapTariffWindowRangeToChunks = (tariffWindowRange: number): number => {
  const tariffWindowInterval = UtilDuration.ofMinutes(TARIFF_WINDOW_MINIMUM_DURATION).asHours();

  return tariffWindowRange / tariffWindowInterval;
};

export const BatteryTariffWindow: React.FC<BatteryTariffWindowProps> = ({
  window,
  elements,
  pixelsPerChunk,
}) => {
  return (
    <>
      {elements.map((tariffElement, index) => {
        const scheduleStart = UtilDuration.ofString('00:00').asHours();
        const tariffStart = UtilDuration.ofString(tariffElement.start).asHours();
        const tariffStop = UtilDuration.ofString(tariffElement.stop).asHours();
        const tariffStartLabel = dateUtil.format(
          dateUtil.ofString(tariffElement.start, ['HH:mm']),
          'h:mma'
        );
        const showTariffStart = tariffStartLabel !== '12:00am';

        const tariffWindowChunks = mapTariffWindowRangeToChunks(Math.abs(tariffStart - tariffStop));
        const tariffWindowWidth = tariffWindowChunks * pixelsPerChunk;

        const tariffWindowOffsetChunks = mapTariffWindowRangeToChunks(
          Math.abs(scheduleStart - tariffStart)
        );
        const tariffWindowOffsetLeft = tariffWindowOffsetChunks * pixelsPerChunk;

        return (
          <div
            key={index}
            style={{ width: tariffWindowWidth, left: tariffWindowOffsetLeft }}
            className={classNames('battery_tariff__window', {
              'battery_tariff__window--offPeak': window.type === TariffType.OFF_PEAK,
              'battery_tariff__window--peak': window.type === TariffType.PEAK,
            })}
          >
            {showTariffStart && (
              <span className={'battery_tariff__window--time_stamp_label'}>{tariffStartLabel}</span>
            )}
            {showTariffStart && <div className={'battery_tariff__window--left_border'} />}
          </div>
        );
      })}
    </>
  );
};
