import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pushEvent } from './adobeAnalytics.helpers';
import {
  Cases,
  Customers,
  Events,
  Leads,
  Metrics,
  PageName,
  PageNames,
  Sections,
  Setup,
} from './adobeAnalytics.type';
import { AdobeAnalyticsContext } from './AdobeAnalyticsContext/adobeAnalyticsContext';

export const useAdobeAnalyticsTracking = () => {
  const useTrackPageLoad = (siteSection?: Sections, pageName?: PageNames) => {
    const { basicAnalyticsPageData } = useContext(AdobeAnalyticsContext);

    useEffect(() => {
      if (!pageName || !siteSection) return;

      pushEvent({
        event: Events.PAGE_LOADED,
        metric: Metrics.ATTRACT,
        page: {
          pageName,
          siteSection,
          subSection1: '',
          subSection2: '',
          ...basicAnalyticsPageData,
        },
      });
    }, []);
  };

  const useTrackPageLoadDependingOnRoute = (
    page: keyof typeof Leads | keyof typeof Setup | keyof typeof Customers | keyof typeof Cases
  ) => {
    const { pathname } = useLocation();
    const section: Sections =
      Sections[
        pathname.includes('leads')
          ? 'LEADS'
          : pathname.includes('setup')
          ? 'SETUP'
          : pathname.includes('cases')
          ? 'CASES'
          : 'CUSTOMERS'
      ];

    useTrackPageLoad(section, PageName[section][page]);
  };

  return {
    useTrackPageLoad,
    useTrackPageLoadDependingOnRoute,
  };
};
