import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DefaultParagraph } from '@sonnen/shared-web';

import { FormStatus } from '+shared/components/FormStatus';

import { Button, ButtonSize } from '../Button';

import './CaseReportError.component.scss';

interface Props {
  onTryAgain: () => void;
}

export const CaseReportError: React.FC<Props> = ({ onTryAgain }) => {
  return (
    <FormStatus
      isSuccess={false}
      headline={I18n.t(T.report.error.headline)}
      footer={
        <Button
          label={I18n.t(T.report.error.submitBtn)}
          size={ButtonSize.SMALL}
          onClick={onTryAgain}
          className={'c-case-report-error__button'}
        />
      }
    >
      <>
        <DefaultParagraph className={'c-case-report-error__paragraph'}>
          {I18n.t(T.report.error.paragraph)}
        </DefaultParagraph>
      </>
    </FormStatus>
  );
};
