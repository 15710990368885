export var isXYArray = function (data) {
    return Array.isArray(data) && (data.length === 0 || (data[0].x !== undefined && data[0].y !== undefined));
};
export var mergeXY = function (x, y) {
    var result = new Array(x.length);
    for (var i = 0, l = x.length; i < l; i++) {
        result[i] = { x: x[i], y: y[i] };
    }
    return result;
};
