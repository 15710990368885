// TODO: clean-up
// this was brought raw from shared-web

import * as React from 'react';

import { DS } from '@sonnen/shared-web';

import classNames from 'classnames';

import { Loader } from '+shared/basicComponents/Loader/Loader';

import { useLoadingStateButtonWidth } from './hooks';
import { useSuccessTimingHook } from './hooks/useSuccessTiming.hook';

import './Button.component.scss';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonStyle {
  DEFAULT = 'default',
  LEFT_ICON = 'left_icon',
  RIGHT_ICON = 'right_icon',
}

export enum ButtonStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum MainType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
}

export type Props = {
  label?: string | JSX.Element;
  onClick?: (...args: any[]) => void;
  loading?: boolean;
  loadingLabel?: string;
  success?: boolean;
  successLabel?: string;
  disabled?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  style?: ButtonStyle;
  status?: ButtonStatus;
  dataTestId?: string;
  className?: string;
  mainType?: MainType;
  name?: string;
  isBlock?: boolean;
};

export const Button: React.FC<Props> = ({
  onClick,
  label,
  loading = false,
  loadingLabel,
  success = false,
  successLabel,
  disabled = false,
  type = ButtonType.PRIMARY,
  size = ButtonSize.MEDIUM,
  style = ButtonStyle.DEFAULT,
  status = ButtonStatus.ENABLED,
  dataTestId,
  className,
  mainType = MainType.BUTTON,
  name,
  isBlock = false,
}) => {
  const loader = (
    <div
      className={classNames('ds-button__loader', {
        [`ds-button__loader--${size}`]: size,
      })}
    >
      <Loader />
    </div>
  );

  const successMark = (
    <div
      className={classNames('ds-button__success', {
        [`ds-button__success--${size}`]: size,
        [`ds-button__success--${type}`]: type,
      })}
    >
      <DS.Icon.Check />
    </div>
  );

  const { width, componentsToRenderHidden } = useLoadingStateButtonWidth(
    label,
    loader,
    loadingLabel
  );
  const isSuccess = useSuccessTimingHook(success);

  return (
    <button
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      type={mainType}
      name={name}
      data-testid={dataTestId}
      className={classNames('ds-button', className, {
        [`ds-button--${type}`]: type,
        [`ds-button--${size}`]: size,
        [`ds-button--${style}`]: style,
        [`ds-button--${status}`]: status,
        'ds-button--cursor-disabled': disabled,
        'sw-button--block': isBlock,
      })}
    >
      {style === ButtonStyle.LEFT_ICON && (
        <div className={'ds-button__arrow ds-button__arrow--left'}>
          <DS.Icon.Angle />
        </div>
      )}
      <span style={{ minWidth: `${width}px` } as React.CSSProperties}>
        {loading && !isSuccess && (
          <>
            {loader}
            {loadingLabel}
          </>
        )}
        {isSuccess && (
          <>
            {successMark}
            {successLabel}
          </>
        )}
        {!loading && !isSuccess && <>{label}</>}
      </span>
      {style === ButtonStyle.RIGHT_ICON && (
        <div className={'ds-button__arrow ds-button__arrow--right'}>
          <DS.Icon.Angle />
        </div>
      )}

      {/* NOTE: those components need to be rendered to calculate their width */}
      <span
        aria-hidden={true}
        style={{ visibility: 'hidden', position: 'absolute' } as React.CSSProperties}
      >
        {componentsToRenderHidden.map((comp, index) => {
          return (
            <span key={index} style={{ position: 'absolute' }}>
              {comp}
            </span>
          );
        })}
      </span>
    </button>
  );
};
