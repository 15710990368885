import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DiscreteProgressBar, Icon } from '@sonnen/shared-web';

import { ContractParagraph } from '../ContractParagraph';

import './ContractEnergyMeter.component.scss';

interface Props {
  percentage: number;
  maxEnergyAmount: number;
}

const metterBoxQuantity = 20;

export const ContractEnergyMeter: React.FC<Props> = ({ percentage, maxEnergyAmount }) => (
  <div className={'c-contract-energy-meter'}>
    <DiscreteProgressBar
      percentage={percentage}
      boxQuantity={metterBoxQuantity}
      label={
        <Translate
          value={T.customerSingle.contract.sonnenFlat.meterLegendMessage}
          percentage={percentage}
          dangerousHTML={true}
        />
      }
      labelIcon={<Icon.House />}
    />
    <div className={'c-contract-energy-meter__footer'}>
      <ContractParagraph>
        {I18n.t(T.customerSingle.contract.sonnenFlat.meterBottomLabel, {
          amount: `${maxEnergyAmount} kWh`,
        })}
      </ContractParagraph>
    </div>
  </div>
);
