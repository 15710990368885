import { isNotNil } from '+utils/array.util';
import { isStatusSet } from '+utils/status.util';

import {
  CommonStatusUpdate,
  FLAT_OFFER_STATUS_NAMES,
  FlatOfferSent,
  FlatStatusUpdate,
  HARDWARE_OFFER_STATUS_NAMES,
  HardwareStatusUpdate,
  IN_SETUP_STATUS_NAMES,
  InSetupStatusUpdate,
  Lead,
  LeadStatusName,
  LeadStatusUpdate,
} from './types';

export const isHardwareStatusUpdate = (
  status: LeadStatusUpdate | null | undefined
): status is HardwareStatusUpdate =>
  isNotNil(status) && HARDWARE_OFFER_STATUS_NAMES.includes(status.name);

export const isFlatStatusUpdate = (
  status: LeadStatusUpdate | null | undefined
): status is FlatStatusUpdate => isNotNil(status) && FLAT_OFFER_STATUS_NAMES.includes(status.name);

export const isInSetupStatusUpdate = (
  status: LeadStatusUpdate | null | undefined
): status is InSetupStatusUpdate => isNotNil(status) && IN_SETUP_STATUS_NAMES.includes(status.name);

export const isCommonStatusUpdate = (
  status: LeadStatusUpdate | null | undefined
): status is CommonStatusUpdate =>
  isNotNil(status) &&
  !isHardwareStatusUpdate(status) &&
  !isFlatStatusUpdate(status) &&
  !isInSetupStatusUpdate(status);

export const isFlatSentStatusUpdate = (
  status: LeadStatusUpdate | null | undefined
): status is FlatOfferSent => status?.name === LeadStatusName.FLAT_OFFER_SENT;

export const isHardwareConfirmedActive = (lead: Lead): boolean =>
  isStatusSet(lead.status.summary[LeadStatusName.HW_ORDER_CONFIRMED]);
