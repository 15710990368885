import * as React from 'react';

import { DefaultParagraph } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { Formik } from 'formik';

import { FormInputDate, FormInputMultiselect, FormInputSelect } from '+shared/components';
import { Button, ButtonSize, MainType } from '+shared/components/Button';
import {
  CountryCodeArrayValue,
  NewsCarrera,
  NewsFormCarreraType,
  optionsValueArray,
  RouteNameCarrera,
} from '+shared/store/firebase/types';

import { FieldName, NewsFormCarreraHelper } from './NewsFormCarrera.helpers';
import { sendNewsFormValidationSchema } from './NewsFormCarrera.schema';
import { NewsFormCarreraMessages } from './NewsFormCarreraMessages.component';
import { NewsFormCarreraOptionsDetails } from './NewsFormCarreraOptionsDetails.component';

import './NewsFormCarrera.component.scss';

type Props = {
  onSubmit: (data: NewsFormCarreraType) => void;
  isLoading: boolean;
  formError: string;
  isEdit?: boolean;
  news?: NewsCarrera;
};

export const NewsFormCarrera: React.FC<Props> = ({
  onSubmit,
  news,
  formError,
  isLoading,
  isEdit,
}) => (
  <>
    <Formik<NewsFormCarreraType>
      onSubmit={onSubmit}
      initialValues={
        (news && NewsFormCarreraHelper.mapNewsToForm(news)) || NewsFormCarreraHelper.initialValues
      }
      validationSchema={sendNewsFormValidationSchema}
    >
      {(form) => (
        <form onSubmit={form.handleSubmit}>
          <div
            className={classNames('o-grid', {
              'c-news-form-carrera--vertical': isEdit,
            })}
          >
            <div
              className={classNames('o-grid__column o-grid__column--md-4', {
                'c-news-form-carrera__section--wide': isEdit,
              })}
            >
              <section>
                <DefaultParagraph className={'c-news-form-carrera__section-title'}>
                  {'Options'}
                </DefaultParagraph>
                <FormInputSelect<NewsFormCarreraType, string>
                  className={'c-news-form-carrera__news-style-dropdown'}
                  form={form}
                  name={FieldName.STATUS}
                  label={'News style:'}
                  collection={NewsFormCarreraHelper.statusCollection}
                />
                <FormInputDate
                  form={form}
                  className={'c-news-form-carrera__date-input'}
                  label={'Expiry date:'}
                  name={FieldName.EXPIRY_DATE_TIMESTAMP}
                />
              </section>
              <section>
                <DefaultParagraph className={'c-news-form-carrera__section-title'}>
                  {'Targeting'}
                </DefaultParagraph>
                {!form.values[FieldName.INCLUDE_IN_ROUTES].includes(RouteNameCarrera.LOGIN) && (
                  <FormInputMultiselect
                    className={'c-news-form-carrera__countries-dropdown'}
                    form={form}
                    name={FieldName.COUNTRIES}
                    label={'Countries:'}
                    collection={CountryCodeArrayValue}
                    placeholder={'All countires'}
                  />
                )}
                <FormInputMultiselect
                  form={form}
                  mapper={NewsFormCarreraHelper.getRouteLabel}
                  name={FieldName.INCLUDE_IN_ROUTES}
                  label={'Customer Portal pages:'}
                  collection={Object.values(RouteNameCarrera)}
                  placeholder={'All pages except for login'}
                />
                {!form.values[FieldName.INCLUDE_IN_ROUTES].includes(RouteNameCarrera.LOGIN) && (
                  <div>
                    <FormInputMultiselect
                      form={form}
                      name={FieldName.OPTIONS}
                      label={'User assets:'}
                      collection={optionsValueArray}
                      placeholder={'Any asset'}
                    />
                    <NewsFormCarreraOptionsDetails form={form} />
                  </div>
                )}
              </section>
            </div>
            <div
              className={classNames('c-news-form-carrera__column-separator', {
                'c-news-form-carrera__column-separator--horizontal': isEdit,
              })}
            />
            <div
              className={classNames('o-grid__column o-grid__column--md-4', {
                'c-news-form-carrera__section--wide': isEdit,
              })}
            >
              <section>
                <DefaultParagraph className={'c-news-form-carrera__section-title'}>
                  {'Translations'}
                </DefaultParagraph>
                <NewsFormCarreraMessages form={form} news={news} />
              </section>
            </div>
            <div className={'c-news-form-carrera__btn-wrapper'}>
              <Button
                loading={isLoading}
                size={ButtonSize.SMALL}
                label={'Send'}
                mainType={MainType.SUBMIT}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
    {formError}
  </>
);
