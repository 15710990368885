import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';

import { compareVersions } from 'compare-versions';
import { isNil } from 'lodash';

import { CountryFeatureName } from '+config/index';
import { RadioButton } from '+shared/components/Form/FormInputRadioGroup/FormInputRadioGroup.component';
import { BatteryOperatingModeOptions } from '+shared/store/site/types';
import { insertIf } from '+utils/array.util';

export const isSoftwareVersionOlderThanAllowedVersion = (
  batterySoftwareVersion: string | undefined | null,
  allowedSoftwareVersion: string
): boolean => {
  if (isNil(batterySoftwareVersion)) return false;

  const compareValue = compareVersions(batterySoftwareVersion, allowedSoftwareVersion);

  // -1 version is smaller/older
  // 1 version is greater/newer
  // 0 same version
  return compareValue === -1;
};

export const getOperatingModeDisabledMessage = (isBatteryVppActive: boolean): string => {
  if (isBatteryVppActive) {
    return I18n.t(
      T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
        .timeOfUseDisabledVppMessage
    );
  }

  return I18n.t(
    T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
      .timeOfUseDisabledSoftwareMessage
  );
};

export const getAvailableOperatingModes = (
  isBatteryVppActive: boolean,
  isTimeOfUseEnabled: boolean
): RadioButton[] => {
  const { isEnabled: isTimeOfUseInCountryAvailable } = useCountryFeature(
    CountryFeatureName.SET_TIME_OF_USE_MODE
  );
  const { isEnabled: isModuleExtensionModeInCountryAvailable } = useCountryFeature(
    CountryFeatureName.SET_MODULE_EXTENSION_MODE
  );

  return [
    {
      label: I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.selfConsumption),
      value: BatteryOperatingModeOptions.SELF_CONSUMPTION,
      text: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
          .selfConsumptionDescription
      ),
    },
    ...insertIf(isModuleExtensionModeInCountryAvailable, {
      label: I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.moduleExtension),
      value: BatteryOperatingModeOptions.MODULE_EXTENSION,
      text: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
          .moduleExtensionDescription
      ),
    }),
    ...insertIf(isTimeOfUseInCountryAvailable && isTimeOfUseEnabled, {
      label: I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.timeOfUse),
      value: BatteryOperatingModeOptions.US_TIME_OF_USE,
      text: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.timeOfUseDescription
      ),
      selectionDisabled: isBatteryVppActive,
      disabledMessage: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
          .timeOfUseDisabledVppMessage
      ),
    }),
  ];
};
