import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { BatteryOperatingModeOptions } from '+shared/store/site/types';

export const mapOperatingModeToInfo = (activeOperatingMode: string | undefined | null): string => {
  switch (activeOperatingMode) {
    case BatteryOperatingModeOptions.SELF_CONSUMPTION:
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.confirmationModal
          .changeToSelfConsumptionText
      );
    case BatteryOperatingModeOptions.MODULE_EXTENSION:
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.confirmationModal
          .changeToModuleExtension
      );
    case BatteryOperatingModeOptions.US_TIME_OF_USE:
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.confirmationModal
          .changeToTimeOfUse
      );
    default:
      return '';
  }
};
