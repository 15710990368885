import { __extends } from "tslib";
import { PointerAction } from '../canvas-pointer-event';
import { GestureDetector } from './gesture-detector';
var DragGestureDetector = (function (_super) {
    __extends(DragGestureDetector, _super);
    function DragGestureDetector(options) {
        var _this = _super.call(this) || this;
        _this.options = options;
        return _this;
    }
    DragGestureDetector.prototype.onGestureEvent = function (event) {
        return !!this.options.drag && this.handleDragGesture(event);
    };
    DragGestureDetector.prototype.handleDragGesture = function (event) {
        var _a = this.options.pointers, pointers = _a === void 0 ? 1 : _a;
        if (event.action === PointerAction.MOVE && event.pointerCount === pointers && event.primaryPointer.pressure > 0) {
            var _b = event.primaryPointer, deltaX = _b.deltaX, deltaY = _b.deltaY;
            if (deltaX === 0 && deltaY === 0) {
                return false;
            }
            return this.options.onDrag({
                pointerEvent: event,
                deltaX: deltaX,
                deltaY: deltaY,
            });
        }
        return false;
    };
    return DragGestureDetector;
}(GestureDetector));
export { DragGestureDetector };
