import { HttpMethod } from '@coolio/http';
import { CreationalRequestBuilder, RequestBuilder } from './requestBuilders';
var JsonApiClient = (function () {
    function JsonApiClient(httpClient) {
        this.httpClient = httpClient;
    }
    JsonApiClient.prototype.get = function (uri) {
        return new RequestBuilder(this.httpClient, { method: HttpMethod.GET, sorting: true }, uri);
    };
    JsonApiClient.prototype.post = function (uri) {
        return new CreationalRequestBuilder(this.httpClient, { method: HttpMethod.POST }, uri);
    };
    JsonApiClient.prototype.delete = function (uri) {
        return new RequestBuilder(this.httpClient, { method: HttpMethod.DELETE }, uri);
    };
    JsonApiClient.prototype.put = function (uri) {
        return new CreationalRequestBuilder(this.httpClient, { method: HttpMethod.PUT }, uri);
    };
    JsonApiClient.prototype.patch = function (uri) {
        return new CreationalRequestBuilder(this.httpClient, { method: HttpMethod.PATCH }, uri);
    };
    return JsonApiClient;
}());
export { JsonApiClient };
