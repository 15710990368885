import * as React from 'react';

import * as classNames from 'classnames';

import './PageBlock.component.scss';

interface Props {
  className?: ClassValue;
  desktopDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  mobileDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  flexFlow?: 'nowrap' | 'wrap';
}

export const PageBlock: React.FC<Props> = ({
  children,
  className,
  desktopDirection = 'column',
  mobileDirection = 'column',
  flexFlow = 'nowrap',
}) => (
  <div
    className={classNames('c-page-block', className)}
    style={
      {
        '--desktop-direction': desktopDirection,
        '--mobile-direction': mobileDirection,
        '--flex-flow': flexFlow,
      } as React.CSSProperties
    }
  >
    {children}
  </div>
);
