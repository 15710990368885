import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { determineTaxRegulation } from '+app/+lead/components/LeadFlatConfigurationTile/LeadFlatConfigurationTile.helper';
import { CurrencyUnit, EnergyUnit, PeakPowerUnit } from '+app/App.constants';
import {
  LeadConfigurationMarket,
  LeadConfigurationResponseAttributes,
  LeadMeasurement,
  LeadOfferTaxRegulation,
} from '+shared/store/lead/types';
import { formatCurrency, formatEnergy, formatPeakPower } from '+utils/format.util.old';

import { isFlatDirectConfiguration } from '../../store/+configuration.helper';

export const formatMeasurementEnergy = (measurement: LeadMeasurement<EnergyUnit>, precision = 0) =>
  formatEnergy({
    whValue: Number(measurement.value) * (measurement.unit === EnergyUnit.KWH ? 1000 : 1),
    unit: measurement.unit,
    precision,
  });

export const formatMeasurementPeakPower = (measurement: LeadMeasurement<PeakPowerUnit>) =>
  formatPeakPower(
    Number(measurement.value) * (measurement.unit === PeakPowerUnit.KWP ? 1000 : 1),
    measurement.unit,
    3
  );

export const formatPricePerKwh = (
  value: number | string,
  currency: CurrencyUnit,
  { asMinorUnit } = { asMinorUnit: false }
) => {
  const corrected = asMinorUnit
    ? typeof value === 'string'
      ? parseFloat(value) * 100
      : value * 100
    : value;
  return formatCurrency(corrected, currency, { isMinorUnit: asMinorUnit }) + `/${EnergyUnit.KWH}`;
};

export const getCurrencyForMarket = (market: LeadConfigurationMarket | undefined) => {
  switch (market) {
    case LeadConfigurationMarket.DE:
      return CurrencyUnit.CURRENCY_EUR;
    default:
      return CurrencyUnit.CURRENCY_EUR;
  }
};

const isSmallEnterpriseRegulation = (taxRegulation: LeadOfferTaxRegulation): boolean =>
  taxRegulation === LeadOfferTaxRegulation.SMALL_ENTERPRISE;

export enum ConfigurationBoxHeaderSize {
  LARGE = 'large',
  MEDIUM = 'medium',
}

export interface ConfigurationBoxLine {
  title: string;
  value: string;
  size: ConfigurationBoxHeaderSize;
  alwaysVisible: boolean;
  helperText?: string;
}

export const factorizeFirstBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationBoxLine[] => [
  // Free usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.userAllowance)}:`,
    value: `${formatMeasurementEnergy(configuration.usageAllowancePerYear)}/${I18n.t(
      T.lead.configuration._salessolution_.preview.year
    )}`,
    size: ConfigurationBoxHeaderSize.LARGE,
    alwaysVisible: true,
  },
  // Basic Fee
  {
    title: isFlatDirectConfiguration(configuration)
      ? `${I18n.t(T.lead.configuration._salessolution_.preview.monthlyFee)}:`
      : `${I18n.t(T.lead.configuration._salessolution_.preview.basicFee)}:`,
    value: `${formatCurrency(configuration.baseFeeGrossPerMonth.value, currency)}
      /${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
    size: ConfigurationBoxHeaderSize.LARGE,
    alwaysVisible: true,
  },
];

export const factorizeSecondBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationBoxLine[] => {
  const taxRegulation = determineTaxRegulation(configuration);

  return [
    // Monthly pre-payment
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.monthlyPrepayment)}:`,
      value: `${formatCurrency(
        configuration.initialAdvancePaymentGrossPerMonth.value,
        currency
      )}/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
      alwaysVisible: true,
    },
    // Excess energy
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.excessEnergy)}:`,
      value: `${formatMeasurementEnergy(
        configuration.powerPlant.expectedExcessEnergyPerYear
      )}/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.MEDIUM,
      alwaysVisible: false,
    },
    // Excess tariff
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.excessTarriff)}:`,
      value: formatPricePerKwh(configuration.excessTariffGrossPerKwh.value, currency, {
        asMinorUnit: true,
      }),
      size: ConfigurationBoxHeaderSize.MEDIUM,
      alwaysVisible: false,
      helperText: isSmallEnterpriseRegulation(taxRegulation)
        ? `${I18n.t(T.lead.configuration._salessolution_.preview.excessTariffHelper, {
            price: formatPricePerKwh(configuration.valueAddedTaxPerKwh.value, currency, {
              asMinorUnit: true,
            }),
            vat: parseFloat(configuration.valueAddedTax.value.toString()),
          })}`
        : undefined,
    },
  ];
};

export const factorizeThirdBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationBoxLine[] => {
  const taxRegulation = determineTaxRegulation(configuration);

  const expectedCashback = isSmallEnterpriseRegulation(taxRegulation)
    ? configuration.powerPlant.expectedCashbackNetPerYear.value
    : configuration.powerPlant.expectedCashbackGrossPerYear.value;

  const cashbackTariffNetPerKwh = isSmallEnterpriseRegulation(taxRegulation)
    ? configuration.cashbackTariffNetPerKwh.value
    : configuration.cashbackTariffGrossPerKwh.value;

  return [
    // Expected cashback
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedCashback)}:`,
      value: `${formatCurrency(expectedCashback, currency)}
        /${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
      alwaysVisible: true,
    },
    // Expected unused free usage allowance
    {
      title: `${I18n.t(
        T.lead.configuration._salessolution_.preview.expectedUnusedUsageAllowance
      )}:`,
      value: `${formatMeasurementEnergy(
        configuration.powerPlant.expectedUnusedUsageAllowancePerYear
      )}/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.MEDIUM,
      alwaysVisible: false,
    },
    // Cashback rate
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.cashbackRate)}:`,
      value: formatPricePerKwh(cashbackTariffNetPerKwh, currency, {
        asMinorUnit: true,
      }),
      size: ConfigurationBoxHeaderSize.MEDIUM,
      alwaysVisible: false,
      helperText: isSmallEnterpriseRegulation(taxRegulation)
        ? I18n.t(T.lead.configuration._salessolution_.preview.cashbackRateHelper)
        : undefined,
    },
  ];
};

export const factorizeFourthBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationBoxLine[] => {
  const taxRegulation = determineTaxRegulation(configuration);

  if (taxRegulation === LeadOfferTaxRegulation.STANDARD) return [];

  return [
    // Umsatzsteuer auf genutzte Freistrommenge
    {
      title: `${I18n.t(
        T.lead.configuration._salessolution_.preview.expectedVatOnUsedUsageAllowance
      )}:`,
      value: `${formatCurrency(
        configuration.powerPlant.expectedVatOnUsedUsageAllowancePerYear.value,
        currency
      )}/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
      alwaysVisible: true,
    },
    // Prognostizierte genutzte Freistrommenge
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedUsedUsageAllowance)}:`,
      value: `${formatMeasurementEnergy(configuration.powerPlant.expectedUsedUsageAllowancePerYear)}
        /${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.MEDIUM,
      alwaysVisible: false,
    },
    // Gesetzl. USt. auf genutzte Freistrommenge
    {
      title: `${I18n.t(
        T.lead.configuration._salessolution_.preview.vatPerKwhOnUsedUsageAllowance
      )}:`,
      value: formatPricePerKwh(configuration.valueAddedTaxPerKwh?.value, currency, {
        asMinorUnit: true,
      }),
      size: ConfigurationBoxHeaderSize.MEDIUM,
      alwaysVisible: false,
    },
  ];
};

export const factorizeFifthBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit
): ConfigurationBoxLine[] => {
  const taxRegulation = determineTaxRegulation(configuration);

  const {
    guaranteedVppBonusGranted,
    guaranteedVppBonusNetPerYear,
    guaranteedVppBonusGrossPerYear,
  } = configuration.powerPlant;

  const vppBonus =
    guaranteedVppBonusGranted && guaranteedVppBonusNetPerYear && guaranteedVppBonusGrossPerYear
      ? isSmallEnterpriseRegulation(taxRegulation)
        ? guaranteedVppBonusNetPerYear.value
        : guaranteedVppBonusGrossPerYear.value
      : undefined;

  return [
    // VPP bonus
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.vpp.title)}:`,
      value: vppBonus
        ? `${I18n.t(T.lead.configuration._salessolution_.vpp.minimum)}
            ${formatCurrency(vppBonus, currency)}
            /${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
        : I18n.t(T.lead.configuration._salessolution_.vpp.notEligible),
      size: ConfigurationBoxHeaderSize.LARGE,
      alwaysVisible: true,
      helperText: isSmallEnterpriseRegulation(taxRegulation)
        ? I18n.t(T.lead.configuration._salessolution_.vpp.zeroPercentVatHelper)
        : undefined,
    },
  ];
};
