import { useCountryFeature, useFeature } from '@sonnen/shared-web';

import { CountryFeatureName, FeatureName } from '+config/index';

export const isRolloutLimitedCountryFeatureEnabled = (countryFeatureName: CountryFeatureName) => {
  const isRolloutDisabled = useFeature(FeatureName.ROLLOUT).isDisabled;
  const isCountryFeatureEnabled = useCountryFeature(countryFeatureName).isEnabled;

  return isRolloutDisabled ? true : isCountryFeatureEnabled;
};
