import React from 'react';
import { connect } from 'react-redux';

import { Loader } from '@sonnen/shared-web/src/components';

import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import TwoColumnsLayout from '+shared/containers/Layout/TwoColumnsLayout';
import { StoreState } from '+shared/store/store.interface';
import { dateUtil } from '+utils/date.util';

import { HeatPumpStatusCard } from './components/HeatPumpStatusCard/HeatPumpStatusCard';
import {
  getHeatpumpLiveState,
  getHeatpumpPollingQuery,
  getHeatpumpPollingTimestamp,
  hasSiteHeatpump,
} from './store/+heatPump.selectors';

const mapStateToProps = (state: StoreState) => ({
  heatpump: getHeatpumpLiveState(state),
  queryStatus: getHeatpumpPollingQuery(state),
  siteLiveStateV2Timestamp: getHeatpumpPollingTimestamp(state),
  hasHeatpump: hasSiteHeatpump(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const CustomerHeatPumpComponent = ({ heatpump, queryStatus, siteLiveStateV2Timestamp }: Props) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.HEAT_PUMP);

  if (!heatpump || !siteLiveStateV2Timestamp) return <Loader />;

  return (
    <>
      <TwoColumnsLayout>
        <HeatPumpStatusCard
          heatpump={heatpump}
          queryStatus={queryStatus}
          timestamp={dateUtil.format(new Date(siteLiveStateV2Timestamp), 'HH:mm:ss')}
        />
        {/* //TODO: hidden by SON-42944, to be reintroduced when BE data available */}
        {/* <HeatPumpOperationsCard /> */}
      </TwoColumnsLayout>
      <TwoColumnsLayout>
        {/* //TODO: hidden by SON-42944, to be reintroduced when BE data available */}
        {/* <HeatPumpTechSpecsCard queryStatus={queryStatus} /> */}
      </TwoColumnsLayout>
    </>
  );
};

export const CustomerHeatPump = connect(mapStateToProps)(CustomerHeatPumpComponent);
