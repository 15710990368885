import * as app from 'firebase/app';
import { CountryCode } from 'src/types/country.type';

import { Config } from '+config/config';
import { mapCountryCodeToMarket, Market } from '+utils/market.util';

import { ContactDetails, VideoFirebase } from './types';
import { GenericNews, Portal } from './types/news.interface';

import 'firebase/auth';
import 'firebase/firestore';

export interface FirebaseProps {
  firebase: Firebase;
}

export class Firebase {
  firestore: app.firestore.Firestore;
  auth: app.auth.Auth;

  constructor() {
    app.initializeApp(Config.FIREBASE);
    app.auth().setPersistence(app.auth.Auth.Persistence.NONE);
    this.firestore = app.firestore();
    this.auth = app.auth();
  }

  // ** Authentication **
  isFirebaseAuthenticated = () => !!this.auth.currentUser;

  firebaseLogin = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  firestoreSignout = () => this.auth.signOut();

  // ** News **
  newsCollection = (newsPortal: Portal) => {
    if (newsPortal === Portal.SP) {
      return this.firestore.collection('partnerPortalNews');
    }
    if (newsPortal === Portal.CP) {
      return this.firestore.collection('news');
    }
    return this.firestore.collection('carreraNews');
  };

  readNewsCollection = () => this.firestore.collection('readPartnerPortalNews');

  getCurrentNewsCollection = (
    isSpanishMarketEnabled: boolean,
    newsPortal: Portal,
    userCountryCode: CountryCode
  ) =>
    this.newsCollection(newsPortal)
      .where(
        'markets',
        'array-contains',
        mapCountryCodeToMarket(userCountryCode, isSpanishMarketEnabled)
      )
      .orderBy('levelValue');

  addNews = (newsPortal: Portal, data: GenericNews) =>
    this.newsCollection(newsPortal).doc(data.id).set(data);

  editNews = (newsPortal: Portal, data: GenericNews) =>
    this.newsCollection(newsPortal).doc(data.id).update(data);

  deleteNews = (newsPortal: Portal, data: GenericNews) =>
    this.newsCollection(newsPortal).doc(data.id).delete();

  getSortedByExpiryDateNewsList = (newsPortal: Portal) => this.newsCollection(newsPortal).get();

  getReadNewsCollectionByUser = (partnerId: string) =>
    this.readNewsCollection().where('userId', '==', partnerId).get();

  // ** Videos **
  videosCollection = () => this.firestore.collection('videos');

  getVideosList = () => this.videosCollection().get();

  getVideosPerMarketList = (isSpanishMarketEnabled: boolean, userCountryCode: CountryCode) =>
    this.videosCollection()
      .where(
        'markets',
        'array-contains',
        mapCountryCodeToMarket(userCountryCode, isSpanishMarketEnabled)
      )
      .get();

  addVideo = (video: VideoFirebase) => this.videosCollection().doc(video.id).set(video);

  deleteVideo = (id: string) => this.videosCollection().doc(id).delete();

  // ** Contact details **
  getContactDetailsByMarket = (market: Market): Promise<ContactDetails> =>
    this.firestore
      .collection('contact')
      .doc(market)
      .get()
      .then((snapshot) => {
        const data = snapshot.data() as ContactDetails | undefined;
        const defaultData = { market };
        return data ?? defaultData;
      });
}
