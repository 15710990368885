import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { insertIf } from '@sonnen/shared-web';

import { difference, intersection, isNil } from 'lodash';

import { CategoryName, SelectCategory } from '+app/shared/store/firebase/types';
import { Market } from '+utils/market.util';

const areOnlyListedMarketsIncluded = (
  availableMarkets: Market[],
  checkedMarkets: Market[] | undefined
) =>
  !isNil(checkedMarkets) &&
  intersection(availableMarkets, checkedMarkets).length !== 0 &&
  difference(checkedMarkets, availableMarkets).length === 0;

export const getAddVideoCategoriesByMarkets = (markets: Market[] | undefined) => [
  { label: I18n.t(T.videosSubpage.categories.portal), hash: CategoryName.PORTAL },
  { label: I18n.t(T.videosSubpage.categories.other), hash: CategoryName.OTHER },
  { label: I18n.t(T.videosSubpage.categories.installation), hash: CategoryName.INSTALLATION },
  { label: I18n.t(T.videosSubpage.categories.sonnenBattery), hash: CategoryName.SONNEN_BATTERY },
  ...insertIf(areOnlyListedMarketsIncluded([Market.DE, Market.IT], markets), {
    label: I18n.t(T.videosSubpage.categories.sonnenFlat),
    hash: CategoryName.SONNEN_FLAT,
  }),
  { label: I18n.t(T.videosSubpage.categories.sonnenCharger), hash: CategoryName.SONNEN_CHARGER },
  ...insertIf(areOnlyListedMarketsIncluded([Market.AU], markets), {
    label: I18n.t(T.videosSubpage.categories.energyProducts),
    hash: CategoryName.ENERGY_PRODUCTS,
  }),
  ...insertIf(areOnlyListedMarketsIncluded([Market.DE, Market.AT, Market.CH], markets), {
    label: I18n.t(T.videosSubpage.categories.partnerApp),
    hash: CategoryName.PARTNER_APP,
  }),
];

export const getCategories = (market: Market | undefined): SelectCategory[] => [
  { label: I18n.t(T.videosSubpage.categories.allVideos), hash: CategoryName.ALL_VIDEOS },
  ...getAddVideoCategoriesByMarkets(market ? [market] : undefined),
];
