import React from 'react';

import { Icon } from '@sonnen/shared-web';

import classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import { get } from 'lodash';

import useInputEvent from '+shared/hooks/useInputEvent';

import './StatusCheckbox.scss';

export interface IStatusCheckbox<T> {
  name: string;
  label?: React.ReactNode;
  form: FormikProps<T>;
  initialValue: boolean;
  statusAutomatic?: boolean;
  suppressLabelInHotjar?: boolean;
}

const ObservableInputElement = ({ form, field, ...props }: FieldProps) => {
  const { onChange } = useInputEvent(field);
  return <input {...props} {...field} checked={get(form.values, field.name)} onChange={onChange} />;
};

const StatusCheckbox = <T extends any = any>({
  form,
  name,
  label,
  initialValue,
  statusAutomatic,
  suppressLabelInHotjar = true,
}: IStatusCheckbox<T>) => {
  const checkboxName = `${name}_checkbox`;
  const id = form.values[name] ? `${checkboxName}_checked` : `${checkboxName}_unchecked`;

  let IconComponent = Icon.Checkmark;
  let iconClass = 'status-checkbox__box-icon';

  if (initialValue) {
    iconClass = `${iconClass} ${iconClass}--status-set`;
  }
  if (statusAutomatic) {
    IconComponent = Icon.StatusAutomatic;
    iconClass = `${iconClass} ${iconClass}--automatic`;

    if (initialValue) {
      IconComponent = Icon.Checkmark;
      iconClass = `${iconClass} ${iconClass}--status-set`;
    }
  }

  return (
    <div className="status-checkbox">
      <Field
        component={ObservableInputElement}
        name={name}
        className="status-checkbox__input"
        disabled={statusAutomatic || initialValue}
        type="checkbox"
        id={id}
      />
      <label className="status-checkbox__label" htmlFor={id}>
        <span
          className={classNames('status-checkbox__box', {
            'status-checkbox__box--automatic': statusAutomatic,
            'status-checkbox__box--status-set': initialValue,
          })}
        >
          <IconComponent className={iconClass} />
        </span>

        <div className="status-checkbox__text" data-hj-suppress={suppressLabelInHotjar}>
          {label}
        </div>
      </label>
    </div>
  );
};

export default StatusCheckbox;
