import { __extends } from "tslib";
import { RequiredViewChanges, View } from '@kanva/core';
import { removeUndefinedProps } from '@kanva/core';
import { DataContainerEventType } from '../data-container/data-container.events';
var ChartView = (function (_super) {
    __extends(ChartView, _super);
    function ChartView(context, name, defaultStyle) {
        var _this = _super.call(this, context, name) || this;
        _this.defaultStyle = defaultStyle;
        _this.dataSeries = [];
        _this.onDataChange = function () {
            _this.require(RequiredViewChanges.LAYOUT);
        };
        _this.style = defaultStyle;
        return _this;
    }
    ChartView.prototype.getStyle = function () {
        return this.style;
    };
    ChartView.prototype.setStyle = function (style) {
        this.style = style || this.defaultStyle;
        this.require(RequiredViewChanges.DRAW);
    };
    ChartView.prototype.onPointerEvent = function (event) {
        return !!this.dataContainer && this.dataContainer.onChartPointerEvent(event);
    };
    ChartView.prototype.getScales = function () {
        return this.dataContainer.getScales(this.innerWidth, this.innerHeight);
    };
    ChartView.prototype.getDataSeries = function () {
        return this.dataSeries;
    };
    ChartView.prototype.setDataSeries = function (series) {
        this.dataSeries = !series ? [] : Array.isArray(series) ? series : [series];
        this.require(RequiredViewChanges.DRAW);
    };
    ChartView.prototype.getDataContainer = function () {
        return this.dataContainer;
    };
    ChartView.prototype.setDataContainer = function (dataContainer) {
        if (this.dataContainer === dataContainer) {
            return;
        }
        if (this.dataContainer) {
            this.dataContainer.removeEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
        }
        this.onDataContainerChanged(dataContainer, this.dataContainer);
        this.dataContainer = dataContainer;
        dataContainer.addEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
        this.require(RequiredViewChanges.LAYOUT);
    };
    ChartView.prototype.onDataContainerChanged = function (_dataContainer, _oldDataContainer) {
    };
    ChartView.prototype.onDestroy = function () {
        if (this.dataContainer) {
            this.dataContainer.removeEventListener(DataContainerEventType.DATA_CHANGE, this.onDataChange);
        }
    };
    ChartView.prototype.onSnapshot = function () {
        return {
            style: removeUndefinedProps(this.style),
            dataSeries: this.dataSeries,
        };
    };
    return ChartView;
}(View));
export { ChartView };
