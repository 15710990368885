import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import { UserCertificate } from '+shared/store/user/types/userCertificate.interface';
import { formatDate } from '+utils/format.util';

export const getCertificateDetails = (certificate: UserCertificate) => [
  {
    label: I18n.t(T.myAccount.certification.startDate),
    value: !isNil(certificate.startDate)
      ? formatDate(certificate.startDate)
      : I18n.t(T.myAccount.certification.noCertificationDateMessage),
  },
  {
    label: I18n.t(T.myAccount.certification.expirationDate),
    value: !isNil(certificate.endDate)
      ? formatDate(certificate.endDate)
      : I18n.t(T.myAccount.certification.noCertificationDateMessage),
  },
];
