import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { head, isUndefined } from 'lodash/fp';

import { Site } from '+app/shared/store/customer/types/customerSite.type';
import { hasPvSystem } from '+shared/store/battery/battery.helpers';
import { getCaseCollection } from '+shared/store/case/case.selectors';
import {
  isContractSonnenFlat,
  isContractSonnenFlatX,
} from '+shared/store/contract/contract.helpers';
import { Customer } from '+shared/store/customer';
import {
  BatteryOperatingMode,
  BatteryOperatingModeOptions,
} from '+shared/store/site/types/siteLiveState.interface';
import { StoreState } from '+shared/store/store.interface';
import { findElementById } from '+utils/array.util';

import { getBattery, isEatonBattery } from '../+battery/store/+battery.selectors';
import { getPrimaryBatterySystem } from '../+list/store/customer.helpers';

export const getCustomer = (customerId: string) => (collection: Customer[]) =>
  findElementById(collection, customerId);

export const getSite = (siteId?: string) => (customer: Customer | undefined) =>
  !isUndefined(customer)
    ? siteId
      ? findElementById(customer.sites, siteId)
      : head(customer.sites)
    : undefined;

export const getBatteryFromSite = (batteryId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? batteryId
      ? findElementById(site.batterySystems, batteryId)
      : getPrimaryBatterySystem(site)
    : undefined;

export const getContract = (contractId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? contractId
      ? findElementById(site.contracts, contractId)
      : head(site.contracts)
    : undefined;

export const getCharger = (chargerId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? chargerId
      ? findElementById(site.chargers, chargerId)
      : head(site.chargers)
    : undefined;

export const getMeterInstallation = (site?: Site) =>
  site && site.contracts && site.contracts[0] && site.contracts[0].meterInstallation;

export const getAssetsAvailability = (site: Site | undefined, state: StoreState) => {
  const battery = getBattery(state);
  const contract = getContract()(site);
  const cases = getCaseCollection(state);
  const charger = getCharger()(site);
  const isEaton = isEatonBattery(state);
  const sonnenFlat = isContractSonnenFlat(contract);
  const sonnenFlatX = isContractSonnenFlatX(contract);

  return {
    hasBattery: !!battery,
    hasEatonBattery: !!isEaton,
    hasContract: !!contract,
    hasPvSystem: hasPvSystem(battery),
    hasCases: !!cases.length,
    hasCharger: !!charger,
    hasSonnenFlat: !!sonnenFlat,
    hasSonnenFlatX: !!sonnenFlatX,
  };
};

export const mapBatteryOperatingModeToLabel = (
  batteryOperatingMode?: BatteryOperatingMode
): string => {
  switch (batteryOperatingMode) {
    case BatteryOperatingModeOptions.SELF_CONSUMPTION:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.selfConsumption);
    case BatteryOperatingModeOptions.MODULE_EXTENSION:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.moduleExtension);
    case BatteryOperatingModeOptions.US_TIME_OF_USE:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.timeOfUse);
    case BatteryOperatingModeOptions.CELL_BALANCING:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.cellBalancing);
    case BatteryOperatingModeOptions.US_BACKUP_POWER:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.backupPower);
    case BatteryOperatingModeOptions.US_AUTOMATIC:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.automatic);
    case BatteryOperatingModeOptions.FINAL_INSPECTION:
      // For Intelligent Mode, we also show Final_Inspection, because the Spree treats them as one
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.finalInspection);
    case BatteryOperatingModeOptions.INTELLIGENT_MODE:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.finalInspection);
    case BatteryOperatingModeOptions.MANUAL:
      return I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.manual);
    default:
      return '-';
  }
};
