var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isNil from 'lodash/isNil';
import { Headers } from './types';
export var DEFAULT_RESOURCE_LIMIT = 10;
var JsonApiRequestData = (function () {
    function JsonApiRequestData(uri, options) {
        var _a;
        var _this = this;
        this.uri = uri;
        this.options = options;
        this.query = {};
        this.sort = [];
        this.limit = DEFAULT_RESOURCE_LIMIT;
        this.page = 1;
        var params;
        _a = uri.split('?'), this.uri = _a[0], params = _a[1];
        if (params) {
            params.split('&').forEach(function (param) {
                var _a;
                var _b = param.split('='), key = _b[0], value = _b[1];
                switch (key) {
                    case 'sort':
                        (_a = _this.sort).push.apply(_a, value.split(','));
                        break;
                    case 'page[number]':
                        _this.page = parseInt(value, 10);
                        break;
                    case 'page[limit]':
                        _this.limit = parseInt(value, 10);
                        break;
                    case 'page[offset]':
                        _this.offset = parseInt(value, 10);
                        break;
                    default:
                        _this.query[key] = decodeURIComponent(value);
                        break;
                }
            });
        }
    }
    JsonApiRequestData.prototype.getRequestOptions = function (options) {
        return __assign(__assign({}, options), { method: this.options.method, query: __assign(__assign({}, this.getParameters()), options === null || options === void 0 ? void 0 : options.query), headers: __assign(__assign({}, Headers), options === null || options === void 0 ? void 0 : options.headers), body: this.body });
    };
    JsonApiRequestData.prototype.getParameters = function () {
        var _a = this, options = _a.options, query = _a.query, sort = _a.sort, limit = _a.limit, offset = _a.offset, page = _a.page;
        var params = __assign({}, query);
        if (options.sorting && sort.length) {
            params.sort = sort.join(',');
        }
        if (options.pagination) {
            params['page[limit]'] = limit.toString();
            if (isNil(offset)) {
                params['page[number]'] = page.toString();
            }
            else {
                params['page[offset]'] = (offset * limit).toString();
            }
        }
        return params;
    };
    return JsonApiRequestData;
}());
export { JsonApiRequestData };
