import * as React from 'react';

import { Icon } from '@sonnen/shared-web';

import { TooltipClickable } from '+shared/components';

import './AnalysisLineChartTooltip.component.scss';

interface AnalysisLineChartTooltipProps {
  tooltip: React.ReactNode;
}

export const AnalysisLineChartTooltip: React.FC<AnalysisLineChartTooltipProps> = ({ tooltip }) => (
  <div className={'c-analysis-line-chart-tooltip'}>
    <TooltipClickable
      trigger={
        <span className={'c-analysis-line-chart-tooltip__icon'}>
          <Icon.Info />
        </span>
      }
    >
      {tooltip}
    </TooltipClickable>
  </div>
);
