import { CUSTOMER_ACTIONS, CustomerActions } from '+app/+customer/store';
import { queryReducer } from '+shared/store/query';

import { createReducer } from '../../../utils';
import { SITE_ACTIONS, SiteActions } from './site.actions';
import {
  GET_SITE_CELL_DATA_QUERY,
  GET_SITE_CHARGE_LIMITS_QUERY,
  GET_SITE_LIVE_STATE_QUERY,
  GET_SITE_LIVE_STATE_V2_QUERY,
  GET_SITE_QUERY,
  GET_SITE_STATISTICS_QUERY,
  initialState,
  SiteState,
} from './site.state';

export const reducer = createReducer<SiteState, SiteActions | CustomerActions>(
  (state = initialState, action): SiteState => {
    switch (action.type) {
      case SITE_ACTIONS.SET_SITE:
        return {
          ...state,
          site: action.site,
        };
      case SITE_ACTIONS.SET_SITE_LIVE_STATE:
        return {
          ...state,
          liveState: action.liveState,
        };
      case SITE_ACTIONS.SET_SITE_LIVE_STATE_V2:
        return {
          ...state,
          liveStateV2: action.liveStateV2,
        };
      case SITE_ACTIONS.CLEAR_SITE:
        return {
          ...initialState,
        };
      case SITE_ACTIONS.SET_SITE_STATISTICS:
        return {
          ...state,
          statistics: action.statistics,
        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [
          GET_SITE_QUERY,
          GET_SITE_LIVE_STATE_QUERY,
          GET_SITE_LIVE_STATE_V2_QUERY,
          GET_SITE_STATISTICS_QUERY,
          GET_SITE_CHARGE_LIMITS_QUERY,
          GET_SITE_CELL_DATA_QUERY,
        ]);
    }
  }
);
