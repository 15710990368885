import * as React from 'react';

import { DS } from '@sonnen/shared-web';

import { Battery } from '+shared/store/battery';
import { SiteLiveState } from '+shared/store/site/types';

import { factorizeBatteryLeftSide, factorizeBatteryRightSide } from './BatteryLiveCardBody.helper';
import { BatteryLiveStatusList } from './BatteryLiveStatusList.component';

import './BatteryLiveCardBody.component.scss';

interface Props {
  battery: Battery;
  siteLiveState: SiteLiveState;
  hasBackupDevice: boolean;
  sliderValue: number[];
  isBatteryBackupBufferInProgress: boolean;
}

export const BatteryLiveCardBody: React.FC<Props> = ({
  battery,
  siteLiveState,
  hasBackupDevice,
  sliderValue,
  isBatteryBackupBufferInProgress,
}) => {
  const { backupPowerBuffer } = battery;
  const { batteryInverterState, batteryUsoc, online } = siteLiveState;
  const batteryChargeLevel = online ? batteryUsoc : 0;
  const batteryBackupLevel = backupPowerBuffer ? backupPowerBuffer : 0;
  const batteryStatus = !!online && batteryInverterState ? batteryInverterState : undefined;

  return (
    <div className={'c-battery-live-card-body'}>
      <BatteryLiveStatusList
        statusList={factorizeBatteryLeftSide(siteLiveState)}
        alignment={'right'}
      />
      <div className={'c-battery-live-card-body__cell-wrapper'}>
        <DS.NewBatteryCell
          chargeLevel={batteryChargeLevel}
          backupLevel={batteryBackupLevel}
          backupSlider={sliderValue[0]}
          status={batteryStatus}
          hasBackupDevice={hasBackupDevice}
          isDisabled={!online}
        />
      </div>
      <BatteryLiveStatusList
        statusList={factorizeBatteryRightSide(
          battery,
          isBatteryBackupBufferInProgress,
          hasBackupDevice
        )}
        alignment={'left'}
      />
    </div>
  );
};
