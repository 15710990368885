import { forOwn, isArray, isFunction, isObject } from 'lodash';
export var removeUndefinedProps = function (object) {
    for (var key in object) {
        if (object.hasOwnProperty(key) && object[key] === undefined) {
            delete object[key];
        }
    }
    return object;
};
export var removeEqualProps = function (object, defaultObject) {
    for (var key in object) {
        if (object[key] === defaultObject[key]) {
            delete object[key];
        }
    }
    return object;
};
export var deepFreeze = function (object) {
    if (isObject(object) || isArray(object) || isFunction(object)) {
        Object.freeze(object);
        forOwn(object, deepFreeze);
    }
    return object;
};
