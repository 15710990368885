import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import {
  UserCertificate,
  UserCertificateStatus,
} from '+shared/store/user/types/userCertificate.interface';

export const getCertificateStatus = (certificate: UserCertificate): string => {
  switch (certificate.status) {
    case UserCertificateStatus.ACTIVE:
      return I18n.t(T.myAccount.certification.statusValue.active);
    case UserCertificateStatus.EXPIRED:
      return I18n.t(T.myAccount.certification.statusValue.expired);
    default:
      return '';
  }
};
