import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { FormikProps } from 'formik';

import { searchByKey } from '+app/utils';
import { DsoRegistrationPvSystemTestIds as testIds } from '+config/testIds';
import { DsoRegistrationFormRadioGroup } from '+setupTool/+form/components';
import { DcCoupledBatteryFlag, DsoRegistrationPvSystemInterface } from '+setupTool/+form/store';
import { removeExponentialValues } from '+setupTool/+form/store/+form.helpers';
import {
  DatepickerArrowSide,
  FormInput,
  FormInputDate,
  FormInputRadioGroup,
  FormInputSelect,
  FormSectionParagraph,
  RadioButton,
} from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';

import { DsoRegistrationPvInverter } from '../DsoRegistrationInverter';
import { DsoRegistrationPvModule } from '../DsoRegistrationPVModule';

interface Props<T extends DcCoupledBatteryFlag> {
  form: FormikProps<T>;
  isNew?: boolean;
  batteryOnly?: boolean;
}

export const panelLocation = () => [
  { name: I18n.t(T.setupTool.form.housetop), value: 'pv_building' },
  { name: I18n.t(T.setupTool.form.openSpace), value: 'pv_open_space' },
  { name: I18n.t(T.setupTool.form.other), value: 'pv_location_other' },
];

export const tiltAngle = () => [
  I18n.t(T.setupTool.form.degreesBelow20),
  I18n.t(T.setupTool.form.degrees20To40),
  I18n.t(T.setupTool.form.degrees40To60),
  I18n.t(T.setupTool.form.degreesAbove60),
  I18n.t(T.setupTool.form.facadeIntegration),
  I18n.t(T.setupTool.form.tracked),
];

export const panelAlignment = () => [
  I18n.t(T.setupTool.form.south),
  I18n.t(T.setupTool.form.southwest),
  I18n.t(T.setupTool.form.southeast),
  I18n.t(T.setupTool.form.west),
  I18n.t(T.setupTool.form.east),
  I18n.t(T.setupTool.form.eastwest),
  I18n.t(T.setupTool.form.northwest),
  I18n.t(T.setupTool.form.northeast),
  I18n.t(T.setupTool.form.north),
  I18n.t(T.setupTool.form.tracked),
];

const panelLocationCollection = panelLocation().map((i) => i.value);

export const DsoRegistrationPvSystemShared = <T extends DcCoupledBatteryFlag>({
  form,
  isNew,
  batteryOnly,
}: Props<T>) => {
  const radioInputCollection: RadioButton[] = [
    {
      value: true,
      label: I18n.t(T.setupTool.common.yes),
      dataTestId: testIds.dcCoupledBatteryYesRadioButton,
    },
    {
      value: false,
      label: I18n.t(T.setupTool.common.no),
      dataTestId: testIds.dcCoupledBatteryNoRadioButton,
    },
  ];

  return (
    <div className="c-form-section">
      {!batteryOnly && (
        <>
          <FormSectionParagraph>{I18n.t(T.setupTool.form.general)}</FormSectionParagraph>
          <FormInputDate
            className="c-setup-tool-pv-system__item"
            form={form}
            label={I18n.t(T.setupTool.form.preferredInstallationDate)}
            name="preferred_installation_date"
            minDate={new Date('1900-01-01')}
            arrowPosition={DatepickerArrowSide.BOTTOM}
            dataTestId={testIds.preferredInstallationDate}
          />

          <FormInputSelect
            className="c-setup-tool-pv-system__item"
            form={form}
            label={I18n.t(T.setupTool.form.panelLocation)}
            id="setup-tool-pv-system__panel_location"
            name="panel_location"
            collection={panelLocationCollection}
            placeholder={I18n.t(T.setupTool.selectPlaceholder)}
            mapper={(key) => searchByKey('value', key, panelLocation(), 'name')}
            dataTestId={testIds.panelLocation}
            dropDownOptionsDataTestId={testIds.panelLocationDropdownOptions}
          />

          <FormInputSelect
            className="c-setup-tool-pv-system__item"
            form={form}
            label={I18n.t(T.setupTool.form.panelAlignment)}
            id="setup-tool-pv-system__panel_alignment"
            name="panel_alignment"
            collection={panelAlignment()}
            placeholder={I18n.t(T.setupTool.selectPlaceholder)}
            dataTestId={testIds.panelAlignment}
            dropDownOptionsDataTestId={testIds.panelAlignmentDropdownOptions}
          />

          <FormInputSelect
            className="c-setup-tool-pv-system__item"
            form={form}
            label={I18n.t(T.setupTool.form.tiltAngle)}
            id="setup-tool-pv-system__tilt_angle"
            name="tilt_angle"
            collection={tiltAngle()}
            placeholder={I18n.t(T.setupTool.selectPlaceholder)}
            dataTestId={testIds.tiltAngle}
            dropDownOptionsDataTestId={testIds.tiltAngleoptions}
          />
        </>
      )}

      <DsoRegistrationPvModule form={form} isNew={isNew} />

      <FormFieldObserver<DsoRegistrationPvSystemInterface>
        onChange={() => removeExponentialValues(form, 'pv_module_count')}
      >
        <FormInput
          className="c-setup-tool-pv-system__item"
          form={form}
          label={I18n.t(T.setupTool.form.count)}
          name="pv_module_count"
          id="pv_module_count"
          placeholder={I18n.t(T.setupTool.selectPlaceholder)}
          type="number"
          dataTestId={testIds.pvAnlageQuantityField}
        />
      </FormFieldObserver>

      {isNew && (
        <FormInput
          className="c-setup-tool-pv-system__item"
          form={form}
          label={I18n.t(T.setupTool.form.power)}
          name="new_pv_size"
          unit="kWp"
          type="number"
          disabled={true}
          dataTestId={testIds.newPvSize}
        />
      )}
      <DsoRegistrationFormRadioGroup
        className={
          !isNew || batteryOnly
            ? 'c-dso-registration-form-radio-group--hidden'
            : 'c-dso-registration-form-radio-group--controlling-subsection'
        }
      >
        <FormInputRadioGroup
          collection={radioInputCollection}
          form={form}
          name="dc_coupled_battery"
          text={I18n.t(T.setupTool.form.dcCoupledBatteryQuestion)}
        />
      </DsoRegistrationFormRadioGroup>
      {(!form.values.dc_coupled_battery || batteryOnly) && (
        <>
          <DsoRegistrationPvInverter
            form={form}
            vendorFieldName="pv_inverter_type"
            typeFieldName="pv_inverter_id"
            isNew={isNew}
          />

          <FormFieldObserver<DsoRegistrationPvSystemInterface>
            onChange={() => removeExponentialValues(form, 'number_identical_inverters')}
          >
            <FormInput
              className="c-setup-tool-pv-system__item"
              form={form}
              label={I18n.t(T.setupTool.form.count)}
              name="number_identical_inverters"
              id="number_identical_inverters"
              placeholder={I18n.t(T.setupTool.selectPlaceholder)}
              type="number"
              dataTestId={testIds.inverterQuantityField}
            />
          </FormFieldObserver>
        </>
      )}
    </div>
  );
};
