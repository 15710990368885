import { Contact } from '+shared/store/contact/types';
import { Query } from '+shared/store/query';

export const CONTACT_COLLECTION_GET_QUERY = 'contactCollectionGetQuery';
export const CONTACT_ASSIGN_ROLE_QUERY = 'contactAssignRoleQuery';
export const CONTACT_DENY_ROLE_QUERY = 'contactDenyRoleQuery';

interface CurrentlyEditedRole {
  contactId?: string;
  roleName?: string;
}

export interface ContactListPageState {
  collection: Contact[];
  totalResourceCount: number;
  currentlyEditedRole: CurrentlyEditedRole;
  [CONTACT_COLLECTION_GET_QUERY]: Query<any>;
  [CONTACT_ASSIGN_ROLE_QUERY]: Query<any>;
  [CONTACT_DENY_ROLE_QUERY]: Query<any>;
}

export const initialState: ContactListPageState = {
  collection: [],
  totalResourceCount: 0,
  currentlyEditedRole: {},
  [CONTACT_COLLECTION_GET_QUERY]: {},
  [CONTACT_ASSIGN_ROLE_QUERY]: {},
  [CONTACT_DENY_ROLE_QUERY]: {},
};
