import React from 'react';

import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelLarge } from '+shared/basicComponents/Typography/Labels';

import { Button, ButtonType } from '../Button';

import './NewFeatureTooltipContent.scss';

type OwnProps = {
  header: string;
  paragraphOne: string;
  paragraphTwo?: string;
  buttonLabel: string;
  setFilterNewFeatureDismissed: (arg: boolean) => void;
};

const NewFeatureTooltipContent: React.FC<OwnProps> = ({
  header,
  paragraphOne,
  paragraphTwo,
  buttonLabel,
  setFilterNewFeatureDismissed,
}) => {
  return (
    <div className="new-feature-tooltip-content">
      <LabelLarge text={header} className="new-feature-tooltip-content__header" />
      <BodyMedium text={paragraphOne} />
      {paragraphTwo && (
        <>
          <br />
          <BodyMedium text={paragraphTwo} />
        </>
      )}
      <div className={'new-feature-tooltip-content__button-wrapper'}>
        <Button
          type={ButtonType.TERTIARY}
          className={'reusable-class__no-padding'}
          onClick={() => setFilterNewFeatureDismissed(true)}
          label={buttonLabel}
        />
      </div>
    </div>
  );
};

export default NewFeatureTooltipContent;
