import * as React from 'react';
import { connect } from 'react-redux';

import {
  getLeadStatusFilters,
  getSetupLeadStatusFilters,
} from '+lead/+list/store/+leadList.selectors';
import { StatusFilter } from '+shared/components';
import { LeadStatusNameMapper } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';

import { LeadListPageActions } from '../../store';
import { getStatusGroups } from './LeadListStatusFilter.const';

const mapStateToProps = (state: StoreState) => ({
  leadStatusFilters: getLeadStatusFilters(state),
  setupLeadStatusFilters: getSetupLeadStatusFilters(state),
});

const mapDispatchToProps = mapActions({
  setLeadStatusFilters: LeadListPageActions.setLeadStatusFilters,
  setSetupLeadStatusFilters: LeadListPageActions.setSetupLeadStatusFilters,
  getInboxLeadList: LeadListPageActions.getInboxLeadList,
  setLeadPreviousStatusFilters: LeadListPageActions.setLeadPreviousStatusFilters,
  setSetupLeadPreviousStatusFilters: LeadListPageActions.setSetupLeadPreviousStatusFilters,
  clearStatusFilters: LeadListPageActions.clearLeadStatusFilters,
  clearSetupStatusFilters: LeadListPageActions.clearSetupLeadStatusFilters,
  clearPreviousStatusFilters: LeadListPageActions.clearPreviousLeadStatusFilters,
  clearPreviousSetupStatusFilters: LeadListPageActions.clearPreviousSetupLeadStatusFilters,
});

interface ComponentProps {
  inSetup?: boolean;
  getLeadList: () => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  ComponentProps;

const LeadListStatusFilterComponent: React.FC<Props> = ({
  actions,
  leadStatusFilters,
  setupLeadStatusFilters,
  inSetup,
  getLeadList,
}) => {
  const initialFilters = inSetup ? setupLeadStatusFilters : leadStatusFilters;
  const statusGroups = React.useMemo(
    () =>
      getStatusGroups(!!inSetup).map((group) =>
        group.map((status) => ({
          name: status,
          label: LeadStatusNameMapper(status),
        }))
      ),
    []
  );

  const setStatusFilters = inSetup
    ? actions.setSetupLeadStatusFilters
    : actions.setLeadStatusFilters;
  const setPreviousStatusFilters = inSetup
    ? actions.setSetupLeadPreviousStatusFilters
    : actions.setLeadPreviousStatusFilters;
  const clearStatusFilters = inSetup ? actions.clearSetupStatusFilters : actions.clearStatusFilters;
  const clearPreviousStatusFilters = inSetup
    ? actions.clearPreviousSetupStatusFilters
    : actions.clearPreviousStatusFilters;

  React.useEffect(() => {
    actions.getInboxLeadList();
  }, []);

  return (
    <StatusFilter
      initialFilters={initialFilters}
      statusGroups={statusGroups}
      setStatusFilters={setStatusFilters}
      setPreviousStatusFilters={setPreviousStatusFilters}
      clearStatusFilters={clearStatusFilters}
      clearPreviousStatusFilters={clearPreviousStatusFilters}
      getList={getLeadList}
    />
  );
};

export const LeadListStatusFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadListStatusFilterComponent);
