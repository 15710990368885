import jwt_decode from 'jwt-decode';
import { createSelector } from 'reselect';

import { StoreState } from '../store.interface';
import { JwtDecodedUser } from './auth.state';

export const getAuthState = (state: StoreState) => state.shared.auth;

export const getAuthTokenStorage = createSelector(getAuthState, ({ tokenStorage }) => tokenStorage);

export const getSalesforceTokenStorage = createSelector(
  getAuthState,
  ({ salesforceTokenStorage }) => salesforceTokenStorage
);

export const getAccessToken = createSelector(
  getAuthTokenStorage,
  (tokenStorage) => tokenStorage && tokenStorage.accessToken
);

export const getPartnerApplications = createSelector(
  getAuthTokenStorage,
  (tokenStorage) =>
    tokenStorage?.idToken && jwt_decode<JwtDecodedUser>(tokenStorage.idToken).partner_applications
);

export const getRefreshToken = createSelector(
  getAuthTokenStorage,
  (tokenStorage) => tokenStorage && tokenStorage.refreshToken
);

export const isAuthenticated = createSelector(
  getAuthState,
  ({ tokenStorage, salesforceTokenStorage }) =>
    !!(
      (tokenStorage && tokenStorage.accessToken) ||
      (salesforceTokenStorage && salesforceTokenStorage.accessToken)
    )
);

export const isImpersonated = createSelector(
  getAuthState,
  ({ salesforceTokenStorage }) =>
    !!(salesforceTokenStorage && salesforceTokenStorage.impersonateUser)
);

export const getCodeVerifier = createSelector(getAuthState, ({ verifier }) => verifier);
