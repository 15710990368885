import React from 'react';

import { Icon } from '@sonnen/shared-web/src/ds/components/Icon';

import classNames from 'classnames';

import './Alert.scss';

export enum AlertTheme {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

type AlertPropsBasic = {
  headline?: string;
  text?: React.ReactNode;
  theme?: AlertTheme;
};

type AlertPropsDismissible = AlertPropsBasic & {
  dismissLabel: string;
  onDismiss: () => void;
};

type AlertPropsNotDismissible = AlertPropsBasic & {
  dismissLabel?: undefined;
  onDismiss?: undefined;
};

export type AlertProps = AlertPropsDismissible | AlertPropsNotDismissible;

const renderThemeIcon = (theme: AlertTheme) => {
  switch (theme) {
    case AlertTheme.DANGER:
      return <Icon.Danger />;
    case AlertTheme.INFO:
      return <Icon.Info />;
    case AlertTheme.SUCCESS:
      return <Icon.Success />;
    case AlertTheme.WARNING:
      return <Icon.Warning />;
    default:
      return <Icon.Info />;
  }
};

export const Alert = ({
  dismissLabel,
  headline,
  onDismiss,
  text,
  theme = AlertTheme.INFO,
}: AlertProps) => (
  <div
    className={classNames('ds-alert', {
      [`ds-alert--${theme}`]: theme,
    })}
  >
    <span aria-hidden={true} className={'ds-alert__marker'}>
      {renderThemeIcon(theme)}
    </span>
    <div className={'ds-alert__content'}>
      {headline && <p className={'ds-alert__headline'}>{headline}</p>}
      {text && <div className={'ds-alert__text'}>{text}</div>}
    </div>
    {onDismiss && dismissLabel && (
      <div className={'ds-alert__actions'}>
        <button
          aria-label={dismissLabel}
          className={'ds-alert__button'}
          onClick={onDismiss}
          type={'button'}
        >
          <Icon.X />
        </button>
      </div>
    )}
  </div>
);
