import * as dateFns from 'date-fns';
import { de, enAU, enGB, enUS, es, it } from 'date-fns/locale';
import * as dateFnsTz from 'date-fns-tz';

import { Locale } from '+app/i18n/i18n.config';

import { Reporter } from './reporter.util';
import { DateAdapterTimezone } from './timezone-types';

export enum TimeUnit {
  MINUTE = 'minute',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

type DateFormat =
  | 'YYYY-MM-DD'
  | 'DD.MM.YYYY'
  | 'DD/MM/YYYY'
  | 'Do MMMM YYYY'
  | 'Do[\xa0]MMM[\xa0]HH:mm'
  | 'Do[\xa0]MMM[\xa0]HH:mm[\xa0]'
  | 'HH:mm'
  | 'HH:mm:ss'
  | 'H:mm'
  | 'h:mma'
  | 'L'
  | 'L LTS';

type Period = 'millisecond' | 'hour' | 'day' | 'calendarDay' | 'month' | 'year';
type PeriodPlural = 'milliseconds' | 'hours' | 'days' | 'calendarDays' | 'months' | 'years';
type Unit = 'minute' | 'day' | 'year';
// granularity 'day' means calendarDay
type Granularity = 'millisecond' | 'day';

export interface DateAdapter {
  setLocale(locale: Locale): void;
  now(): Date;
  /** @deprecated this returns exactly the same as now (not the start of the day) */
  todayDate(): Date;
  /** @deprecated use format instead */
  todayFormatted(): string;
  unixDate(year: number, month: number, day: number, hour: number, minute: number): number;
  isValidDateFormat(date: string): boolean;
  of(date: Date | string): Date;
  ofString(date: string, format: ['HH:mm']): Date;
  ofMillisSince1970(milliseconds: number): Date;
  ofSecondsSince1970(seconds: number): Date;
  getDateFromUnixDate(date: number): Date;
  getUnixFromDateInSeconds(date: Date): number;
  add(date: Date, amount: number, unit: Unit): Date;
  subtract(date: Date, amount: number, unit: Unit): Date;
  getStartOfDate(date: Date): Date;
  getStartOf(date: Date, unit: TimeUnit): Date;
  getEndOfDate(date: Date): Date;
  getEndOf(date: Date, unit: TimeUnit): Date;
  isCurrent(period: TimeUnit): (date: Date) => boolean;
  getTimeDurationInMinutesTillNow(date: string): number;
  format(date: Date, format: DateFormat): string;
  isDelayed(latestDate: Date, msDelay: number): boolean;
  isBeforeCurrentMinute(date: Date): boolean;
  isTodayOrBefore(date: Date): boolean;
  isTodayOrAfter(date: Date): boolean;
  isAfterToday(date: Date): boolean;
  isSameDay(date: Date, otherDate: Date): boolean;
  isToday(date: Date): boolean;
  isYesterday(date: Date): boolean;
  getDifference(startDate: Date, endDate: Date, period: PeriodPlural): number;
  isBefore(date: Date | string, compareDate: Date | string, granularity?: Granularity): boolean;
  isSameOrBefore(
    date: Date | string,
    compareDate: Date | string,
    granularity?: Granularity
  ): boolean;
  isAfter(date: Date | string, compareDate: Date | string, granularity?: Granularity): boolean;
  isSameOrAfter(
    date: Date | string,
    compareDate: Date | string,
    granularity?: Granularity
  ): boolean;
  isBetween(date: Date, start: Date, end: Date): boolean;
  toTimezone(date: Date, timeZone: DateAdapterTimezone): Date;
  isTimezoneUsersLocale(timeZone: DateAdapterTimezone): boolean;
  getTimezoneAbbreviation(date: Date, timezone: string): string;
  getWeekDaysTranslations(): string[];
  getWeekDaysShortTranslations(): string[];
  getMonthTranslations(): string[];
}

class DateFnsAdapter implements DateAdapter {
  private locale: Locale | undefined;

  public setLocale(locale: Locale): void {
    dateFns.setDefaultOptions({ locale: DateFnsAdapter.toDateFnsLocale(locale) });
    this.locale = locale;
  }

  private static toDateFnsLocale(locale: Locale): dateFns.Locale {
    const dateFnsLocales = {
      [Locale.EN_US]: enUS,
      [Locale.EN_GB]: enGB,
      [Locale.DE]: de,
      [Locale.IT]: it,
      [Locale.ES]: es,
    };
    const dateFnsLocale: dateFns.Locale | undefined = dateFnsLocales[locale];
    if (!dateFnsLocale) {
      DateFnsAdapter.warn(`Unsupported locale: ${locale}`);
      return enUS;
    }
    return dateFnsLocale;
  }

  public now(): Date {
    return new Date();
  }

  public todayDate(): Date {
    return dateFns.toDate(this.now());
  }

  public todayFormatted() {
    return this.format(this.now(), 'YYYY-MM-DD');
  }

  public unixDate(year: number, month: number, day: number, hour: number, minute: number): number {
    return Math.floor(new Date(year, month, day, hour, minute).getTime() / 1000);
  }

  public isValidDateFormat(date: string): boolean {
    const parsedDate: Date = dateFns.parseISO(date);
    return dateFns.isValid(parsedDate);
  }

  public of(date: Date | string): Date {
    return typeof date === 'string' ? dateFns.parseISO(date) : dateFns.toDate(date);
  }

  public ofString(date: string, format: ['HH:mm']): Date {
    return dateFns.parse(date, format[0], new Date());
  }

  public ofMillisSince1970(milliseconds: number): Date {
    return new Date(milliseconds);
  }

  public ofSecondsSince1970(seconds: number): Date {
    return new Date(seconds * 1000);
  }

  public getDateFromUnixDate(date: number): Date {
    return new Date(date * 1000);
  }

  public getUnixFromDateInSeconds(date: Date): number {
    return Math.floor(date.getTime() / 1000);
  }

  public add(date: Date, amount: number, unit: Unit): Date {
    switch (unit) {
      case 'minute':
        return dateFns.addMinutes(date, amount);
      case 'day':
        return dateFns.addDays(date, amount);
      case 'year':
        return dateFns.addYears(date, amount);
      default:
        DateFnsAdapter.warn(`not implemented TimeUnit '${unit}'`);
        return date;
    }
  }

  public subtract(date: Date, amount: number, unit: Unit): Date {
    switch (unit) {
      case 'minute':
        return dateFns.subMinutes(date, amount);
      case 'day':
        return dateFns.subDays(date, amount);
      case 'year':
        return dateFns.subYears(date, amount);
      default:
        DateFnsAdapter.warn(`not implemented TimeUnit '${unit}'`);
        return date;
    }
  }

  public getStartOfDate(date: Date): Date {
    return this.getStartOf(date, TimeUnit.DAY);
  }

  public getStartOf(date: Date, unit: TimeUnit): Date {
    switch (unit) {
      case TimeUnit.MINUTE:
        return dateFns.startOfMinute(date);
      case TimeUnit.DAY:
        return dateFns.startOfDay(date);
      case TimeUnit.MONTH:
        return dateFns.startOfMonth(date);
      case TimeUnit.YEAR:
        return dateFns.startOfYear(date);
      default:
        DateFnsAdapter.warn(`not implemented TimeUnit '${unit}'`);
        return date;
    }
  }

  public getEndOfDate(date: Date): Date {
    return this.getEndOf(date, TimeUnit.DAY);
  }

  public getEndOf(date: Date, unit: TimeUnit): Date {
    switch (unit) {
      case TimeUnit.MINUTE:
        return dateFns.endOfMinute(date);
      case TimeUnit.DAY:
        return dateFns.endOfDay(date);
      case TimeUnit.MONTH:
        return dateFns.endOfMonth(date);
      case TimeUnit.YEAR:
        return dateFns.endOfYear(date);
      default:
        DateFnsAdapter.warn(`not implemented TimeUnit '${unit}'`);
        return date;
    }
  }

  public isCurrent(period: TimeUnit): (date: Date) => boolean {
    let dateFnsFunction: (dateLeft: Date, dateRight: Date) => boolean;
    switch (period) {
      case TimeUnit.MINUTE:
        dateFnsFunction = dateFns.isSameMinute;
        break;
      case TimeUnit.DAY:
        dateFnsFunction = dateFns.isSameDay;
        break;
      case TimeUnit.MONTH:
        dateFnsFunction = dateFns.isSameMonth;
        break;
      case TimeUnit.YEAR:
        dateFnsFunction = dateFns.isSameYear;
        break;
      default:
        DateFnsAdapter.warn(`not implemented TimeUnit '${period}'`);
        dateFnsFunction = () => false;
    }

    return (date: Date) => this.isCurrentFunction(date, dateFnsFunction);
  }

  private isCurrentFunction(
    date: Date,
    dateFnsFunction: (dateLeft: Date, dateRight: Date) => boolean
  ): boolean {
    return dateFnsFunction(date, new Date());
  }

  public getTimeDurationInMinutesTillNow(date: string): number {
    return dateFns.differenceInMinutes(new Date(), this.of(date));
  }

  public format(date: Date, format: DateFormat): string {
    try {
      const dateFnsFormat: string = format
        .replace('YYYY', 'yyyy')
        .replace('DD', 'dd')
        .replace('Do', 'do')
        .replaceAll('[\xa0]', '\xa0')
        .replace('a', 'aaa')
        .replace('L', 'P')
        .replace('LTS', 'pp');
      return dateFns.format(date, dateFnsFormat);
    } catch (error) {
      DateFnsAdapter.warn(`dateFns.format: ${error}, date is ${date}, format is ${format}`);
      return 'Invalid date';
    }
  }

  public isDelayed(latestDate: Date, msDelay: number): boolean {
    return latestDate.getTime() + msDelay <= Date.now();
  }

  public isBeforeCurrentMinute(date: Date): boolean {
    const now: Date = new Date();
    return !dateFns.isSameMinute(date, now) && dateFns.isBefore(date, now);
  }

  public isTodayOrBefore(date: Date): boolean {
    return dateFns.isToday(date) || dateFns.isBefore(date, new Date());
  }

  public isTodayOrAfter(date: Date): boolean {
    return dateFns.isToday(date) || dateFns.isAfter(date, new Date());
  }

  public isAfterToday(date: Date): boolean {
    return !dateFns.isToday(date) && dateFns.isAfter(date, new Date());
  }

  public isSameDay(date: Date, otherDate: Date): boolean {
    return dateFns.isSameDay(date, otherDate);
  }

  public isToday(date: Date): boolean {
    return dateFns.isToday(date);
  }

  public isYesterday(date: Date): boolean {
    return dateFns.isYesterday(date);
  }

  public getDifference(
    startDate: Date | string,
    endDate: Date | string,
    period: PeriodPlural = 'days'
  ): number {
    if (typeof startDate === 'string') startDate = this.of(startDate);
    if (typeof endDate === 'string') endDate = this.of(endDate);

    switch (period) {
      case 'milliseconds':
        return dateFns.differenceInMilliseconds(endDate, startDate);
      case 'hours':
        return dateFns.differenceInHours(endDate, startDate);
      case 'days':
        return dateFns.differenceInDays(endDate, startDate);
      case 'calendarDays':
        return dateFns.differenceInCalendarDays(endDate, startDate);
      case 'months':
        return dateFns.differenceInMonths(endDate, startDate);
      case 'years':
        return dateFns.differenceInYears(endDate, startDate);
      default:
        DateFnsAdapter.warn(`not implemented TimeUnit '${period}'`);
        return 0;
    }
  }

  public getDifferenceNew(
    startDate: Date | string,
    endDate: Date | string,
    period: Period
  ): number {
    if (typeof startDate === 'string') startDate = this.of(startDate);
    if (typeof endDate === 'string') endDate = this.of(endDate);
    return this.getDifference(startDate, endDate, DateFnsAdapter.timeUnitToPlural(period));
  }

  private static timeUnitToPlural(unit: Period = 'day'): PeriodPlural {
    return (unit + 's') as PeriodPlural;
  }

  /**
   * @param granularity 'day' means calendarDay
   */
  public isBefore(
    date: Date | string,
    compareDate: Date | string,
    granularity?: Granularity
  ): boolean {
    const period = granularity === 'day' ? 'calendarDay' : granularity;
    return this.getDifferenceNew(date, compareDate, period ?? 'millisecond') > 0;
  }

  /**
   * @param granularity 'day' means calendarDay
   */
  public isSameOrBefore(
    date: Date | string,
    compareDate: Date | string,
    granularity?: Granularity
  ): boolean {
    const period = granularity === 'day' ? 'calendarDay' : granularity;
    return this.getDifferenceNew(date, compareDate, period ?? 'millisecond') >= 0;
  }

  /**
   * @param granularity 'day' means calendarDay
   */
  public isAfter(
    date: Date | string,
    compareDate: Date | string,
    granularity?: Granularity
  ): boolean {
    const period = granularity === 'day' ? 'calendarDay' : granularity;
    return this.getDifferenceNew(date, compareDate, period ?? 'millisecond') < 0;
  }

  /**
   * @param granularity 'day' means calendarDay
   */
  public isSameOrAfter(
    date: Date | string,
    compareDate: Date | string,
    granularity?: Granularity
  ): boolean {
    const period = granularity === 'day' ? 'calendarDay' : granularity;
    return this.getDifferenceNew(date, compareDate, period ?? 'millisecond') <= 0;
  }

  public isBetween(date: Date, start: Date, end: Date): boolean {
    return this.isAfter(date, start) && this.isBefore(date, end);
  }

  /**
   * @returns date in different timezone with local time preserved,
   * note that it will point to different point in time
   */
  public toTimezone(date: Date, timezone: DateAdapterTimezone): Date {
    try {
      const localTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTimezoneOffset: number = dateFnsTz.getTimezoneOffset(localTimezone, date);
      const targetTimezoneOffset: number = dateFnsTz.getTimezoneOffset(timezone, date);
      const timezoneOffsetDifference: number = localTimezoneOffset - targetTimezoneOffset;
      return this.add(date, timezoneOffsetDifference / 1000 / 60, 'minute');
    } catch (error) {
      DateFnsAdapter.warn(`dateFns.toTimezone: ${error}, date is ${date}, timezone is ${timezone}`);
      return date;
    }
  }

  public isTimezoneUsersLocale(timezone: DateAdapterTimezone): boolean {
    const usersLocaleTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      dateFnsTz.getTimezoneOffset(usersLocaleTimezone) === dateFnsTz.getTimezoneOffset(timezone)
    );
  }

  public getTimezoneAbbreviation(date: Date, timezone: string): string {
    let dateFnsResult: string = this.getTimezoneAbbreviationForLocale(date, timezone, enGB);
    if (dateFnsResult.startsWith('GMT')) {
      dateFnsResult = this.getTimezoneAbbreviationForLocale(date, timezone, enUS);
    }
    if (dateFnsResult.startsWith('GMT')) {
      dateFnsResult = this.getTimezoneAbbreviationForLocale(date, timezone, enAU);
    }
    if (dateFnsResult.startsWith('GMT')) {
      const locale: dateFns.Locale | undefined = dateFns.getDefaultOptions()['locale'];
      if (locale) {
        dateFnsResult = this.getTimezoneAbbreviationForLocale(date, timezone, locale);
      }
    }
    return dateFnsResult;
  }

  private getTimezoneAbbreviationForLocale(
    date: Date,
    timeZone: string,
    locale: dateFns.Locale
  ): string {
    const zonedDate: Date = dateFnsTz.utcToZonedTime(date, timeZone);
    return dateFnsTz.format(zonedDate, 'zzz', { timeZone, locale });
  }

  public getWeekDaysTranslations(): string[] {
    const weekDayDates: Date[] = dateFns.eachDayOfInterval({
      start: dateFns.startOfWeek(new Date(), { weekStartsOn: 0 }),
      end: dateFns.endOfWeek(new Date(), { weekStartsOn: 0 }),
    });
    return weekDayDates.map((date: Date) => dateFns.format(date, 'EEEE'));
  }

  public getWeekDaysShortTranslations(): string[] {
    const weekDayDates: Date[] = dateFns.eachDayOfInterval({
      start: dateFns.startOfWeek(new Date(), { weekStartsOn: 0 }),
      end: dateFns.endOfWeek(new Date(), { weekStartsOn: 0 }),
    });
    return weekDayDates.map((date: Date) => dateFns.format(date, 'EEEEEE'));
  }

  public getMonthTranslations(): string[] {
    const monthDates: Date[] = dateFns.eachMonthOfInterval({
      start: dateFns.startOfYear(new Date()),
      end: dateFns.endOfYear(new Date()),
    });
    return monthDates.map((date: Date) => dateFns.format(date, 'MMMM'));
  }

  public static warn(message: string): void {
    // eslint-disable-next-line no-console
    console.trace(message);
    Reporter.log(message);
  }
}

export const dateUtil: DateAdapter = new DateFnsAdapter();
