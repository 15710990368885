import React from 'react';
import { I18n } from 'react-redux-i18n';

import { BinSmall } from '+shared/basicComponents/Icons';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';

import FilterLabel from '../FilterLabel/FilterLabel';
import { AppliedFilters, FilterType, Translations } from '../SitesFiltersArea';

import './AppliedFiltersSection.scss';

type Props = {
  appliedFilters: AppliedFilters;
  meterInstallationTranslations: Translations;
  removeSingleFilter: (filter: string, filterType: FilterType) => void;
  resetFilters: (filterType: FilterType) => void;
  appliedMeterInstallationCount: number;
};

const AppliedFiltersSection: React.FC<Props> = ({
  appliedFilters,
  meterInstallationTranslations,
  removeSingleFilter,
  resetFilters,
  appliedMeterInstallationCount,
}) => {
  const { postalCodes, meterPreparationStatuses, fstCheckSuccessful } = appliedFilters;

  const showResetPostalCodesButton = Boolean(postalCodes && postalCodes?.size > 1);
  const showResetMeterInstallationStatusesButton = Boolean(appliedMeterInstallationCount > 1);
  const showLineDividerBetweenFilterTypes = Boolean(postalCodes && appliedMeterInstallationCount);

  return (
    <ul className="filters-area__applied-filters-list">
      {postalCodes &&
        Array.from(postalCodes).map((filter, index) => (
          <li key={filter} className="filters-area__applied-filters-list--filter">
            <FilterLabel
              text={filter}
              handleClick={() => removeSingleFilter(filter, 'postalCodes')}
              dataTestId={`delete-postcode-filter-btn-${index}`}
            />
          </li>
        ))}

      {showResetPostalCodesButton && (
        <button
          onClick={() => resetFilters('postalCodes')}
          className="button-icon"
          data-testid="delete-all-postcode-filters-btn"
        >
          <BinSmall className="reset-filters" />
        </button>
      )}

      {showLineDividerBetweenFilterTypes && (
        <div className="line-divider">
          <LineDivider width={1} height={30} />
        </div>
      )}

      {meterPreparationStatuses?.map((status, index) => (
        <li key={status} className="filters-area__applied-filters-list--filter">
          <FilterLabel
            text={I18n.t(meterInstallationTranslations[status].title)}
            handleClick={() => removeSingleFilter(status, 'meterInstallationStatuses')}
            dataTestId={`${status}-delete-status-filter-btn`}
          />
        </li>
      ))}

      {fstCheckSuccessful && (
        <li key={fstCheckSuccessful} className="filters-area__applied-filters-list--filter">
          <FilterLabel
            text={I18n.t(meterInstallationTranslations[fstCheckSuccessful].title)}
            handleClick={() => removeSingleFilter(fstCheckSuccessful, 'meterInstallationStatuses')}
            dataTestId={`fstCheckSuccessful-delete-postcode-filter-btn`}
          />
        </li>
      )}

      {showResetMeterInstallationStatusesButton && (
        <button
          onClick={() => resetFilters('meterInstallationStatuses')}
          className="button-icon"
          data-testid="delete-all-status-filters-btn"
        >
          <BinSmall className="reset-filters" />
        </button>
      )}
    </ul>
  );
};

export default AppliedFiltersSection;
