import { createSelector } from 'reselect';

import { getCustomerBatteryPageState } from '+customer/store/+customer.selectors';
import { BatteryType } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';

import {
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  GET_BATTERY_QUERY,
  GET_BATTERY_STATUSES_QUERY,
  PUT_BATTERY_NOTE_QUERY,
} from './+battery.state';

/**
 * BATTERY SELECTORS
 * ------------------------------
 */
export const getBattery = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.battery
);

export const getBatteryTariffWindows = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.tariffWindows
);

export const getTariffWindowScheduleEditState = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.isTariffWindowScheduleEditedButNotSaved
);

export const getEditBatteryTariffWindow = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.editedTariffWindow
);

export const getBatterySoftwareVersion = createSelector(
  getBattery,
  (battery) => battery?.softwareVersion
);

export const getBatteryQueryStatus = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => getStatus(batteryPageState[GET_BATTERY_QUERY])
);

export const getBatteryStatusesQueryStatus = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => getStatus(batteryPageState[GET_BATTERY_STATUSES_QUERY])
);

export const getNoteSubmitQuery = createSelector(
  getCustomerBatteryPageState,
  (state) => state[PUT_BATTERY_NOTE_QUERY]
);

export const getNoteSubmitQueryStatus = createSelector(getNoteSubmitQuery, (query) =>
  getStatus(query)
);

export const isEatonBattery = createSelector(
  getBattery,
  (data) => (data && data.controllerType) === BatteryType.EATON
);

const heaterConnectionStatus = createSelector(
  getBattery,
  (battery) => battery?.heaterConnectionStatus
);

export const isHeaterStatusConnected = createSelector(
  heaterConnectionStatus,
  (heaterStatus) => !!heaterStatus
);

const backupDeviceType = createSelector(getBattery, (battery) => battery?.backupDeviceType);

export const isBackupDeviceTypeIsBackupBoxOrProtect = createSelector(
  backupDeviceType,
  (type) => type === 'backupbox' || type === 'protect'
);

export const backupPowerBuffer = createSelector(
  getBattery,
  (battery) => battery?.backupPowerBuffer
);

export const getBatteryInstallationDate = createSelector(
  getBattery,
  (battery) => battery?.installationDate
);

export const isBatteryVppActive = createSelector(
  getBattery,
  (battery) => battery?.vppOnboarded === 'commercialised'
);

export const getBatteryIntelligentCharging = createSelector(
  getBattery,
  (battery) => battery?.prognosisCharging
);

export const getIsBatteryIntelligentChargingInProgress = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.isBatteryIntelligentChargingInProgress
);

export const getIsBatteryBackupBufferInProgress = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.isBatteryBackupBufferInProgress
);

export const getPvGridFeedInLimit = createSelector(
  getBattery,
  (battery) => battery?.pvGridFeedInLimit
);

export const getBatteryTimezone = createSelector(getBattery, (battery) => battery?.timeZone);

export const getPvPeakPower = createSelector(getBattery, (battery) => battery?.pvPeakPower);

export const getIsBatteryOperatingModeInProgress = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.isBatteryOperatingModeInProgress
);

export const getIsBatteryRemoteSettingsAllowed = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.isBatteryRemoteSettingsAllowed
);

/**
 * BATTERY ELECTRIC UNITS SELECTORS
 * ------------------------------
 */
export const getBatteryElectricUnits = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.batteryElectricUnits
);

export const getBatteryElectricUnitsQueryStatus = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => getStatus(batteryPageState[GET_BATTERY_ELECTRIC_UNITS_QUERY])
);

/**
 * BATTERY IC STATUS SELECTORS
 * ------------------------------
 */
export const getBatteryIcStatus = createSelector(
  getCustomerBatteryPageState,
  (batteryPageState) => batteryPageState.batteryIcStatus
);
