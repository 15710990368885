import { ActionsObservable, combineEpics } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';

import { ofType } from '+app/utils';
import { LayoutActions } from '+shared/store/layout';

type Action$ = ActionsObservable<LayoutActions>;

export const scrollToTop$ = (action$: Action$) =>
  action$.pipe(
    ofType(LayoutActions.scrollToTop),
    tap(() => window.scrollTo(0, 0)),
    ignoreElements()
  );

export const toggleModal$ = (action$: Action$) =>
  action$.pipe(
    ofType(LayoutActions.toggleModal),
    tap(({ isModalOpen }) => {
      document.body.classList[isModalOpen ? 'add' : 'remove']('body--disabled');
    }),
    ignoreElements()
  );

export const toggleNavigation$ = (action$: Action$) =>
  action$.pipe(
    ofType(LayoutActions.toggleNavigation),
    tap(({ isActive }) => document.body.classList[isActive ? 'add' : 'remove']('body--disabled')),
    ignoreElements()
  );

export const epics = combineEpics<any>(scrollToTop$, toggleModal$, toggleNavigation$);
