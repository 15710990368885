import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  BatteryStatusesKey,
  CellDataSeriesKey,
  DS,
  EnergyFlowSeriesKey,
  SharedChartColors,
  useCountryFeature,
  useFeature,
} from '@sonnen/shared-web';

import { Font } from '@kanva/core';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

import { CountryFeatureName, FeatureName } from '+config/index';
import { insertIf } from '+utils/array.util';

export const CHART_FONT: Font = {
  fontFamily: 'Brandon Text',
  fontWeight: 500,
  fontSize: 10,
};

export const getAvailableFilters = (featureFlags: LDFlagSet) => {
  const isBatteryChargeLimitEnabled = featureFlags.chargeLimits;
  const { isEnabled: isVppActivityEnabled } = useCountryFeature(
    CountryFeatureName.VPP_ACTIVITY_GRAPH
  );
  const { isEnabled: isBatteryCellCareEnabled } = useFeature(FeatureName.BATTERY_CELL_CARE);
  const { isEnabled: isBatteryCellDataEnabled } = useFeature(FeatureName.BATTERY_CELL_DATA);

  return [
    {
      key: EnergyFlowSeriesKey.PRODUCTION_POWER,
      color: SharedChartColors.ProductionStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.productionFilter),
    },
    {
      key: EnergyFlowSeriesKey.CONSUMPTION_POWER,
      color: SharedChartColors.ConsumptionStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.consumptionFilter),
    },
    {
      key: EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
      color: SharedChartColors.DirektUsageStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.directUsageFilter),
    },
    {
      key: EnergyFlowSeriesKey.BATTERY_USOC,
      color: SharedChartColors.Usoc,
      name: I18n.t(T.customerSingle.analysis.dayChart.batteryUSOCFilter),
      markerShape: DS.BadgeMarkerShape.LINE,
    },
    {
      key: EnergyFlowSeriesKey.BATTERY_CHARGING,
      color: SharedChartColors.BatteryChargingStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.chargingFilter),
    },
    {
      key: EnergyFlowSeriesKey.BATTERY_DISCHARGING,
      color: SharedChartColors.BatteryDischargingStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.dischargingFilter),
    },
    ...insertIf(isBatteryChargeLimitEnabled, {
      key: BatteryStatusesKey.CHARGE_LIMIT,
      color: SharedChartColors.ChargeLimit,
      name: I18n.t(T.customerSingle.analysis.dayChart.labels.chargeLimit.name),
    }),
    ...insertIf(isVppActivityEnabled, {
      key: BatteryStatusesKey.VPP_ACTIVITY,
      color: SharedChartColors.VppActivity,
      name: I18n.t(T.customerSingle.analysis.dayChart.vppActivityFilter),
    }),
    ...insertIf(isBatteryCellCareEnabled, {
      key: BatteryStatusesKey.CELL_CARE,
      color: SharedChartColors.CellCare,
      name: I18n.t(T.customerSingle.analysis.dayChart.cellCareFilter),
    }),
    ...insertIf(isBatteryCellDataEnabled, {
      key: CellDataSeriesKey.TEMPERATURE,
      color: SharedChartColors.CellTemperatureStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.cellTemperatureFilter),
    }),
    ...insertIf(isBatteryCellDataEnabled, {
      key: CellDataSeriesKey.VOLTAGE,
      color: SharedChartColors.CellVoltageStroke,
      name: I18n.t(T.customerSingle.analysis.dayChart.cellVoltageFilter),
    }),
  ];
};
