import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, useCountryFeature } from '@sonnen/shared-web';

import { FormikProps } from 'formik';
import { isObject } from 'lodash/fp';
import * as Yup from 'yup';

import { CountryFeatureName } from '+config/countryFlags';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import {
  DynamicField,
  EServicesCaseCategory,
  HeatPumpCategory,
  OtherCategory,
  PVSystemCategory,
  SF_ACCOUNT_FIELD_NAME,
  SF_CONTRACT_FIELD_NAME,
  SonnenBatterieCategory,
  SonnenChargerCategory,
  TechnicalCaseCategory,
  TechnicalCaseSubcategoryType,
} from '+shared/store/case/types/createCase.interface';

import { isEServices } from './CaseCommon.helper';
import {
  CaseFormField,
  CaseFormValues,
  CategorySelectItem,
  RecordTypeCase,
  RecordTypeData,
  RecordTypesData,
  SelectItem,
} from './CaseCommon.types';

const TOPIC_MAX_CHARACTERS = 200;

export const getEServicesCategories = (): CategorySelectItem[] => [
  {
    name: I18n.t(T.help.supportForm.fields.categories.customerData),
    value: EServicesCaseCategory.CUSTOMER_DATA,
  },
  {
    name: I18n.t(T.help.supportForm.fields.categories.cession),
    value: EServicesCaseCategory.CESSION,
  },
  {
    name: I18n.t(T.help.supportForm.fields.categories.supply),
    value: EServicesCaseCategory.SUPPLY,
  },
  {
    name: I18n.t(T.help.supportForm.fields.categories.booking),
    value: EServicesCaseCategory.BOOKING,
  },
  {
    name: I18n.t(T.help.supportForm.fields.categories.meterChange),
    value: EServicesCaseCategory.METER_CHANGE,
  },
  {
    name: I18n.t(T.help.supportForm.fields.categories.directMarketing),
    value: EServicesCaseCategory.DIRECT_MARKETING,
  },
  { name: I18n.t(T.help.supportForm.fields.categories.vpp), value: EServicesCaseCategory.VPP },
  {
    name: I18n.t(T.help.supportForm.fields.categories.productInformation),
    value: EServicesCaseCategory.PRODUCT_INFORMATION,
  },
  {
    name: I18n.t(T.help.supportForm.fields.categories.remaining),
    value: EServicesCaseCategory.REMAINING,
  },
];

const i18Scope = T.cases.creation.technicalCase;

const getSubcategories = (
  subcategories: TechnicalCaseSubcategoryType,
  subcategoriesName: string
): SelectItem[] => {
  return Object.keys(subcategories).map((subcategory) => ({
    name: I18n.t(i18Scope[subcategoriesName][subcategory]),
    value: subcategories[subcategory],
  }));
};

export const getTechnicalCaseCategories = (): CategorySelectItem[] => [
  {
    name: I18n.t(i18Scope.sonnenBatterie.title),
    value: TechnicalCaseCategory.SONNEN_BATTERY,
    subcategories: getSubcategories(SonnenBatterieCategory, TechnicalCaseCategory.SONNEN_BATTERY),
  },
  {
    name: I18n.t(i18Scope.sonnenCharger.title),
    value: TechnicalCaseCategory.SONNEN_CHARGER,
    subcategories: getSubcategories(SonnenChargerCategory, TechnicalCaseCategory.SONNEN_CHARGER),
  },
  {
    name: I18n.t(i18Scope.pvSystem.title),
    value: TechnicalCaseCategory.PV_SYSTEM,
    subcategories: getSubcategories(PVSystemCategory, TechnicalCaseCategory.PV_SYSTEM),
  },
  {
    name: I18n.t(i18Scope.heatpump.title),
    value: TechnicalCaseCategory.HEAT_PUMP,
    subcategories: getSubcategories(HeatPumpCategory, TechnicalCaseCategory.HEAT_PUMP),
  },
  {
    name: I18n.t(i18Scope.other.title),
    value: TechnicalCaseCategory.OTHER,
    subcategories: getSubcategories(OtherCategory, TechnicalCaseCategory.OTHER),
  },
];

const getCaseTypesComponentData = (activeSite?: CaseSite): RecordTypesData => {
  const isCustomerContractFeatureEnabled = useCountryFeature(
    CountryFeatureName.CONTRACT_CASE_CREATION
  ).isEnabled;

  const technicalCase = {
    [RecordTypeCase.TECHNICAL]: {
      key: RecordTypeCase.TECHNICAL,
      label: I18n.t(T.report.form.types.technicalCase.label),
      icon: <Icon.SonnenBattery />,
      categories: getTechnicalCaseCategories(),
      description: I18n.t(T.report.form.types.technicalCase.description),
      disabled: !activeSite || (activeSite && !activeSite.battery),
    },
  };

  const contractCase = isCustomerContractFeatureEnabled
    ? {
        [RecordTypeCase.E_SERVICES]: {
          key: RecordTypeCase.E_SERVICES,
          label: I18n.t(T.report.form.types.eServices.label),
          icon: <Icon.Contract viewBox={{ w: 25, h: 25 }} />,
          categories: getEServicesCategories(),
          description: I18n.t(T.report.form.types.eServices.description),
          disabled: !activeSite || (activeSite && !activeSite.contract),
        },
      }
    : {};

  const otherCase = {
    [RecordTypeCase.OTHER]: {
      key: RecordTypeCase.OTHER,
      label: I18n.t(T.report.form.types.other.label),
      icon: <Icon.Tools />,
      description: I18n.t(T.report.form.types.other.description),
      disabled: !activeSite,
    },
  };

  return {
    ...technicalCase,
    ...contractCase,
    ...otherCase,
  };
};

const getSubject = ({
  recordType,
  category,
  batteryNumber,
  contractNumber,
}: {
  recordType: RecordTypeData;
  category: CategorySelectItem | string;
  batteryNumber?: string;
  contractNumber?: string;
}) => {
  const { key, label } = recordType;

  const defaultPrefix = 'XXXXX';
  const batteryPrefix = batteryNumber || defaultPrefix;
  const contractPrefix = contractNumber || '';
  const subjectPrefix = isEServices(key) ? contractPrefix : batteryPrefix;
  const subjectCategory = isObject(category) ? category.name : category;

  return `${subjectPrefix ? `${subjectPrefix} | ` : ''}${label}${
    subjectCategory ? ` | ${subjectCategory}` : ''
  }`;
};

const validationSchema = () =>
  Yup.object({
    [CaseFormField.DESCRIPTION]: Yup.string()
      .required(I18n.t(T.report.inputRequiredError))
      .trim(I18n.t(T.report.fieldCannotBeEmpty)),
    [CaseFormField.RECORD_TYPE]: Yup.object().required(I18n.t(T.report.errors.caseTypeRequired)),
    [CaseFormField.CATEGORY]: Yup.string()
      .required(I18n.t(T.report.errors.topicRequired))
      .max(
        TOPIC_MAX_CHARACTERS,
        I18n.t(T.report.errors.topicMaxLength, { number: TOPIC_MAX_CHARACTERS })
      ),
    [CaseFormField.SUBCATEGORY]: Yup.string().when(CaseFormField.RECORD_TYPE, {
      is: (type: RecordTypeData) => type?.key === RecordTypeCase.TECHNICAL,
      then: (schema: Yup.Schema<string>) => schema.required(I18n.t(T.report.errors.topicRequired)),
      otherwise: (schema: Yup.Schema<string>) => schema.notRequired(),
    }),
  });

const getDynamicFields = (salesforceContractId?: string, salesforceAccountId?: string) => {
  const dynamicFields: DynamicField[] = [];

  if (salesforceContractId) {
    dynamicFields.push({
      field: SF_CONTRACT_FIELD_NAME,
      value: salesforceContractId,
    });
  }

  if (salesforceAccountId) {
    dynamicFields.push({
      field: SF_ACCOUNT_FIELD_NAME,
      value: salesforceAccountId,
    });
  }

  return dynamicFields;
};

const resetFormValues = (form: FormikProps<CaseFormValues>) => {
  form.setFieldValue(CaseFormField.CATEGORY, '');
  form.setFieldValue(CaseFormField.RECORD_TYPE, undefined);
  form.setFieldError(CaseFormField.CATEGORY, '');
  form.setFieldError(CaseFormField.CATEGORY, '');
  form.setFieldError(CaseFormField.RECORD_TYPE, '');
  form.setFieldTouched(CaseFormField.CATEGORY, false);
  form.setFieldTouched(CaseFormField.CATEGORY, false);
  form.setFieldTouched(CaseFormField.RECORD_TYPE, false);
};

export const CaseFormHelper = {
  getCaseTypesComponentData,
  validationSchema,
  getDynamicFields,
  getSubject,
  resetFormValues,
};
