import * as React from 'react';

import { Bubble, Icon } from '@sonnen/shared-web';

import './VppDocumentationTooltip.component.scss';

interface VppDocumentationTooltipProps {
  tooltipMessage: string | React.ReactNode;
}

export const VppDocumentationTooltip: React.FC<VppDocumentationTooltipProps> = ({
  tooltipMessage,
}) => {
  return (
    <div className={'c-vpp-documentation-tooltip'}>
      <Icon.Info className={'c-vpp-documentation-tooltip__info-icon'} />
      <div className={'c-vpp-documentation-tooltip__bubble-wrapper'}>
        <Bubble side={'top-left'} isThemeDense={false}>
          {tooltipMessage}
        </Bubble>
      </div>
    </div>
  );
};
