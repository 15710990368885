import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  BatteryStatusesKey,
  CellDataSeriesKey,
  TimeRangePickerQuickLink,
  TimeRangePickerQuickLinkType,
} from '@sonnen/shared-web';

import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { isEmpty } from 'lodash';

import { getAvailableFilters } from '+app/+customer/+analysis/+analysis.consts';
import { DAYS_IN_WEEK, HOURS_IN_DAY } from '+app/App.constants';
import NewFeatureTooltipContent from '+shared/components/NewFeatureTooltipContent/NewFeatureTooltipContent';
import { insertIf } from '+utils/array.util';
import { dateUtil } from '+utils/date.util';

import { ChartDataSeries, ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';

export const prepareFilters = (
  activeDataSeries: ChartDataSeriesKey[],
  disabledFilters: ReturnType<typeof prepareDisabledFilters>,
  featureFlags: LDFlagSet
) =>
  getAvailableFilters(featureFlags).map((serie) => ({
    ...serie,
    isActive: activeDataSeries.includes(serie.key),
    isDisabled: Object.keys(disabledFilters).includes(serie.key),
    disabledTooltipText: disabledFilters[serie.key],
  }));

// TODO: move to shared analysis chart helpers
export const prepareDisabledFilters = (
  zoomBasedStartDate: Date,
  zoomBasedEndDate: Date,
  isResolutionChanged: boolean,
  initialDataSeries: ChartDataSeries,
  currentDataSeries: ChartDataSeries,
  isEatonBattery: boolean
): Partial<Record<BatteryStatusesKey | CellDataSeriesKey, string>> => {
  /**
   * Base values
   */
  const cellCareDataSeries = isResolutionChanged
    ? currentDataSeries[BatteryStatusesKey.CELL_CARE]
    : initialDataSeries[BatteryStatusesKey.CELL_CARE];

  /**
   * Predicates
   */
  const isMoreThanWeek =
    dateUtil.getDifference(zoomBasedStartDate, zoomBasedEndDate, 'days') >= DAYS_IN_WEEK;
  const isMoreThanDay =
    dateUtil.getDifference(zoomBasedStartDate, zoomBasedEndDate, 'hours') >= HOURS_IN_DAY;
  const isLessThanDay =
    dateUtil.getDifference(zoomBasedStartDate, zoomBasedEndDate, 'hours') <= HOURS_IN_DAY;
  const isCellCareDataEmpty = isEmpty(cellCareDataSeries);
  const isChargeLimitDataEmpty = isEmpty(initialDataSeries[BatteryStatusesKey.CHARGE_LIMIT]);

  /**
   * Computed values
   */
  const cellCareTooltipText =
    isLessThanDay && isCellCareDataEmpty
      ? I18n.t(T.customerSingle.analysis.dayChart.cellCareNoDataAvailableTooltip)
      : I18n.t(T.customerSingle.analysis.dayChart.cellCareFilterDisabledToolTip);

  const cellDataTooltipText = isEatonBattery
    ? I18n.t(T.customerSingle.batteryDetails.batteryOperations.notAvailableTooltip)
    : I18n.t(T.customerSingle.analysis.dayChart.cellDataFilterDisabledTooltip);

  return {
    ...((isMoreThanWeek || isEatonBattery) && {
      [CellDataSeriesKey.TEMPERATURE]: cellDataTooltipText,
      [CellDataSeriesKey.VOLTAGE]: cellDataTooltipText,
    }),
    ...(isMoreThanDay && {
      [BatteryStatusesKey.VPP_ACTIVITY]: I18n.t(
        T.customerSingle.analysis.dayChart.vppActivityFilterDisabledTooltip
      ),
      [BatteryStatusesKey.CELL_CARE]: cellCareTooltipText,
    }),
    ...(isChargeLimitDataEmpty && {
      [BatteryStatusesKey.CHARGE_LIMIT]: I18n.t(
        T.customerSingle.analysis.dayChart.chargeLimitFilterDisabledTooltip
      ),
    }),
    ...(isCellCareDataEmpty && {
      [BatteryStatusesKey.CELL_CARE]: cellCareTooltipText,
    }),
  };
};

export const createDayViewQuickLinks = (
  includeForecastQuickLink: boolean
): TimeRangePickerQuickLink[] => [
  {
    type: TimeRangePickerQuickLinkType.TODAY,
    label: I18n.t(T.customerSingle.analysis.quickLinks.today),
  },
  {
    type: TimeRangePickerQuickLinkType.YESTERDAY,
    label: I18n.t(T.customerSingle.analysis.quickLinks.yesterday),
  },
  {
    type: TimeRangePickerQuickLinkType.LAST_7_DAYS,
    label: I18n.t(T.customerSingle.analysis.quickLinks.last7Days),
  },
  {
    type: TimeRangePickerQuickLinkType.LAST_14_DAYS,
    label: I18n.t(T.customerSingle.analysis.quickLinks.last14Days),
  },
  {
    type: TimeRangePickerQuickLinkType.LAST_30_DAYS,
    label: I18n.t(T.customerSingle.analysis.quickLinks.last30Days),
  },
  {
    type: TimeRangePickerQuickLinkType.LAST_3_MONTHS,
    label: I18n.t(T.customerSingle.analysis.quickLinks.last3Months),
  },
  ...insertIf(includeForecastQuickLink, {
    type: TimeRangePickerQuickLinkType.FORECAST,
    label: I18n.t(T.customerSingle.analysis.quickLinks.forecast),
  }),
];

export const createMonthViewQuickLinks = (): TimeRangePickerQuickLink[] => {
  return [
    {
      type: TimeRangePickerQuickLinkType.CURRENT_MONTH,
      label: I18n.t(T.customerSingle.analysis.quickLinks.currentMonth),
    },
    {
      type: TimeRangePickerQuickLinkType.LAST_MONTH,
      label: I18n.t(T.customerSingle.analysis.quickLinks.lastMonth),
    },
    {
      type: TimeRangePickerQuickLinkType.LAST_6_MONTHS,
      label: I18n.t(T.customerSingle.analysis.quickLinks.last6Months),
    },
    {
      type: TimeRangePickerQuickLinkType.LAST_12_MONTHS,
      label: I18n.t(T.customerSingle.analysis.quickLinks.last12Months),
    },
  ];
};

export const getNewFeatureTooltipChildren = (
  setFilterNewFeatureDismissed: (arg: boolean) => void
): React.ReactNode => (
  <NewFeatureTooltipContent
    header={I18n.t(T.customerSingle.analysis.newFeatureTooltip.csvHeader)}
    paragraphOne={I18n.t(T.customerSingle.analysis.newFeatureTooltip.csvDescription)}
    buttonLabel={I18n.t(T.customerSingle.analysis.newFeatureTooltip.csvButton)}
    setFilterNewFeatureDismissed={setFilterNewFeatureDismissed}
  />
);
