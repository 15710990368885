import { findLastIndex } from 'lodash';
var colorRegex = /^(?:#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2}))|(?:#([0-9a-f])([0-9a-f])([0-9a-f]))|(?:rgb\((\d+),\s*(\d+),\s*(\d+)\))|(?:rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\))$/i;
export var parseColor = function (colorString) {
    if (!colorString) {
        return undefined;
    }
    var colorMatch = colorString.match(colorRegex);
    if (!colorMatch) {
        return undefined;
    }
    var isHex = findLastIndex(colorMatch, Boolean) <= 6;
    var _a = colorMatch.filter(Boolean), rString = _a[1], gString = _a[2], bString = _a[3], aString = _a[4];
    var radix = isHex ? 16 : 10;
    var parse = function (s) { return parseInt(s.length === 1 ? s + s : s, radix); };
    return {
        r: parse(rString),
        g: parse(gString),
        b: parse(bString),
        a: aString ? parseFloat(aString) : undefined,
    };
};
export var rgba = function (color, alpha) {
    var c = parseColor(color);
    return "rgba(".concat(c.r, ", ").concat(c.g, ", ").concat(c.b, ", ").concat(alpha, ")");
};
export var luminance = function (c) { return (0.299 * c.r + 0.587 * c.g + 0.114 * c.b) / 255; };
export var isBright = function (color) { return luminance(color) > 0.5; };
