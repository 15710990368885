import { isArray } from 'lodash/fp';
import pathToRegExp from 'path-to-regexp';

import { RESTRICTED_PATHS } from '../routes';

export const matchPath = (pathsToMatch: string[] | string) => {
  const pathRegexps = isArray(pathsToMatch)
    ? pathsToMatch.map((path) => pathToRegExp(path))
    : [pathToRegExp(pathsToMatch)];

  return (path: string) => pathRegexps.some((pathRegexp) => pathRegexp.test(path));
};

export const getSearchParams = (key: string) => (path: string) =>
  new URLSearchParams(path).get(key) as string;

export const getPathParams = (pathsToMatch: string[], keys?: pathToRegExp.Key[]) => {
  const regExpArr = pathsToMatch.map((path) => pathToRegExp(path, keys));
  return (path: string) => {
    const regExp = regExpArr.find((regExp) => regExp.test(path));
    return regExp ? regExp.exec(path) : [];
  };
};

export const locationEquals = (
  l1: Partial<Location> | undefined,
  l2: Partial<Location> | undefined
): boolean =>
  l1 === l2 ||
  (!!l1 && !!l2 && l1.pathname === l2.pathname && l1.search === l2.search && l1.hash === l2.hash);

export const isRestrictedRoute = matchPath(RESTRICTED_PATHS);
