import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, Loadable } from '@sonnen/shared-web';

import { push } from 'connected-react-router';

import { getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { LeadHardwareStatusModal } from '+app/+lead/components/LeadHardwareStatusModal';
import { useSetupLeadContext } from '+app/+lead/containers/SetupLead/SetupLead.context';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { getLead, getLeadQueryStatus } from '+app/+lead/store/+lead.selectors';
import { PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { Container, FormHeader } from '+shared/components';
import { LayoutBackground, LayoutContext } from '+shared/containers/Layout';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';

import { LeadHardwareForm } from '../../components';
import { HardwarePageActions } from '../../store';
import {
  getHardwareSaveQueryStatus,
  getHardwareStatusUpdateQueryStatus,
  getNewHardwareStatus,
} from '../../store/+hardware.selectors';

import './LeadHardwareNew.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  leadQueryStatus: getLeadQueryStatus(state),
  hardwareStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
  hardwareSaveQueryStatus: getHardwareSaveQueryStatus(state),
  productBatteryList: getProductBatteryList(state),
  newHardwareStatus: getNewHardwareStatus(state),
});

const mapDispatchToProps = mapActions({
  saveHardwareOffer: HardwarePageActions.saveHardwareOffer,
  goToLeadConfigurations: (leadId: string, leadStage?: string) =>
    push(PATHS.LEAD_CONFIGURATION({ leadId }, leadStage)),
  toggleModal: LayoutActions.toggleModal,
  changeLeadStatus: LeadPageActions.changeLeadStatus,
  setNewHardwareStatus: HardwarePageActions.setNewHardwareStatus,
  clearNewHardwareStatus: HardwarePageActions.clearNewHardwareStatus,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LeadHardwareNewComponent: React.FC<Props> = ({
  actions,
  lead,
  leadQueryStatus,
  productBatteryList,
  newHardwareStatus,
  hardwareSaveQueryStatus,
  hardwareStatusUpdateQueryStatus,
}) => {
  const layout = React.useContext(LayoutContext);
  const { leadStage } = useSetupLeadContext();

  React.useEffect(() => {
    layout.setBackground(LayoutBackground.GRAY);

    return () => {
      layout.resetBackground();
    };
  }, []);

  React.useEffect(() => {
    if (newHardwareStatus) {
      actions.toggleModal(false);
    }
  }, [newHardwareStatus]);

  React.useEffect(() => {
    if (newHardwareStatus) {
      goToOverview();
      actions.clearNewHardwareStatus();
    }
  }, [hardwareStatusUpdateQueryStatus.success]);

  const goToOverview = () => {
    if (lead) {
      actions.goToLeadConfigurations(lead.id, leadStage);
    }
  };

  const renderContent = () => (
    <div className={'o-grid c-lead-hardware-new'}>
      <div className={'o-grid__column o-grid__column--sm-12 o-grid__column--no-gap-sm'}>
        {lead && (
          <LeadHardwareForm
            lead={lead}
            saveHardwareOffer={actions.saveHardwareOffer}
            productBatteryList={productBatteryList}
            isPending={hardwareSaveQueryStatus.pending || hardwareStatusUpdateQueryStatus.pending}
            hasError={hardwareSaveQueryStatus.error || hardwareStatusUpdateQueryStatus.error}
            toggleModal={actions.toggleModal}
            newHardwareStatus={newHardwareStatus}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className={'c-lead-hardware-new__header-wrapper'}>
        <FormHeader
          onClick={goToOverview}
          breadcrumb={I18n.t(T.lead.offer._salessolution_.buttonBack)}
          title={
            lead
              ? I18n.t(T.lead.hardware._salessolution_.headline.hardwareOfferFor, {
                  firstName: lead.firstName,
                  lastName: lead.lastName,
                })
              : ''
          }
          isLoading={leadQueryStatus.pending}
          suppressTitleInHotjar={true}
        />
      </div>
      <Loadable predicate={leadQueryStatus.pending}>
        <Media query={{ minWidth: breakpointUp('SM') }}>
          {(isDesktopMedia: boolean) => (
            <Container
              withHorizontalPadding={isDesktopMedia}
              className={'c-lead-hardware-new__container'}
            >
              {renderContent()}
            </Container>
          )}
        </Media>
      </Loadable>

      <LeadHardwareStatusModal
        modalId={ModalId.HARDWARE_STATUS_UPDATE_NEW_OFFER}
        submitAction={actions.setNewHardwareStatus}
        queryStatus={hardwareSaveQueryStatus}
      />
    </>
  );
};

export const LeadHardwareNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadHardwareNewComponent);
