import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { CaseReportSearchResults, SearchField } from '+shared/components';
import { CaseActions } from '+shared/store/case';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import { CustomerActions } from '+shared/store/customer';
import { QueryState } from '+shared/store/query';

import { CaseReportSearchResultsItem } from '../CaseReportSearchResultsItem';
import { FormFieldLabel } from '../Form/FormFieldLabel';
import { searchSiteSchema } from './CaseReportSearchSite.shema';

import './CaseReportSearchSite.component.scss';

type Props = {
  getCustomerList: typeof CustomerActions.getCustomerList;
  siteList: CaseSite[];
  customerListQueryStatus: QueryState;
  setActiveSite: typeof CaseActions.setCaseReportActiveSite;
  activeSite?: CaseSite;
};

export const CaseReportSearchSite: React.FC<Props> = ({
  siteList,
  customerListQueryStatus,
  getCustomerList,
  setActiveSite,
  activeSite,
}) => {
  const [isSearchResultsVisible, setIsSearchResultsVisible] = React.useState<boolean>(false);

  const handleSearch = (search: string) => {
    setIsSearchResultsVisible(true);
    getCustomerList({ search });
  };

  const handleSiteClick = (site: CaseSite) => {
    setActiveSite(site);
    setIsSearchResultsVisible(false);
  };

  return (
    <div className={'c-case-report-search-site'}>
      <FormFieldLabel label={I18n.t(T.report.searchLabel)} />
      <SearchField
        isAutoSearch={false}
        onSearch={handleSearch}
        onChange={() => setIsSearchResultsVisible(false)}
        placeholder={I18n.t(T.report.searchPlaceholder)}
        isFullWidth={true}
        validationSchema={searchSiteSchema}
        dataTestIdInputField="customer-search-field"
        dataTestIdBtn="customer-search-btn"
      />
      {isSearchResultsVisible && (
        <CaseReportSearchResults
          queryStatus={customerListQueryStatus}
          results={siteList}
          renderResult={(item) => (
            <CaseReportSearchResultsItem item={item} handleClick={() => handleSiteClick(item)} />
          )}
        />
      )}
    </div>
  );
};
