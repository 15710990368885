import { createSelector } from 'reselect';

import { getStatus } from '+shared/store/query/query.utils';
import { getDsoRegistration } from '+shared/store/setupTool';

export const getDsoRegistrationDocuments = createSelector(
  getDsoRegistration,
  (state) => state.documents
);

export const getLatestDocumentsGenerationDate = createSelector(
  getDsoRegistration,
  (state) => state.updatedAt
);

export const getDsoGenerateDocumentsQuery = createSelector(
  getDsoRegistration,
  (state) => state.generateDocumentsQuery
);

export const getDsoGenerateDocumentsQueryStatus = createSelector(
  getDsoGenerateDocumentsQuery,
  (state) => getStatus(state)
);
