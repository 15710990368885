import { Data, MergedData } from '@coolio/json-api';

export enum UserCertificateStatus {
  EXPIRED = 'Expired',
  ACTIVE = 'Active',
}

export interface UserCertificateAttributes {
  name: string;
  id: string;
  certification: string;
  startDate: string | null;
  endDate: string | null;
  status: string;
}

export type UserCertificateData = Data<UserCertificateAttributes>;
export type UserCertificate = MergedData<UserCertificateData>;
