import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Modal } from '@sonnen/shared-web/src/components/Modal';

import { isNil } from 'lodash';

import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { CaseDetail, CaseStatus } from '+shared/components';
import ModalLayout from '+shared/components/ModalLayout/ModalLayout';
import { Case } from '+shared/store/case';
import { formatDate } from '+utils/format.util';

import './CaseListTableModal.component.scss';

interface Props {
  caseItem: Case;
  isOpen: boolean;
  onClose: () => void;
}

export const CaseListTableModal: React.FC<Props> = ({
  caseItem: { account, caseNumber, status, createdDate, lastModifiedDate, subject, description },
  isOpen,
  onClose,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    onOutsideClick={onClose}
    size={'small'}
    dataTestId="case-preview-modal"
  >
    <ModalLayout
      title={`${I18n.t(T.cases.modal.case)} #${caseNumber}`}
      status={<CaseStatus status={status} />}
      section={Sections.CASES}
      page={PageName.Cases.CASE_DETAILS_MODAL}
    >
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--sm-6'}>
          <CaseDetail
            label={I18n.t(T.cases.modal.createdAt)}
            value={!isNil(createdDate) ? formatDate(createdDate) : '-'}
          />
        </div>
        <div className={'o-grid__column o-grid__column--sm-6'}>
          <CaseDetail
            label={I18n.t(T.cases.modal.lastModifiedAt)}
            value={!isNil(lastModifiedDate) ? formatDate(lastModifiedDate) : '-'}
          />
        </div>
        <div className={'o-grid__column o-grid__column--sm-12'}>
          <CaseDetail
            label={I18n.t(T.cases.modal.customer)}
            value={
              !isNil(account) && account.lastName
                ? `${account.firstName || ''} ${account.lastName}`
                : '-'
            }
            isBold={true}
          />
        </div>
        <div className={'o-grid__column o-grid__column--sm-12'}>
          <CaseDetail label={I18n.t(T.cases.modal.subject)} value={subject} isBold={true} />
        </div>
        <div className={'o-grid__column o-grid__column--sm-12'}>
          <CaseDetail label={I18n.t(T.cases.modal.caseDescription)} value={description} />
        </div>
        <div className={'o-grid__column o-grid__column--sm-12'}>
          {/* TODO waiting for API */}
          {/* <Attachment url={commissionAssistantAttachmentUrl} /> */}
        </div>
      </div>
    </ModalLayout>
  </Modal>
);
