import { Action } from 'redux';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';

import { makeQuery, ofType } from '../../../utils';
import { GraphQLRepository } from '../graphQL/graphQL.repository';
import { CaseActions } from './case.actions';
import { generateCaseQueryParams } from './case.helper';
import { CaseRepository } from './case.repository';

type Action$ = ActionsObservable<CaseActions>;
type State$ = StateObservable<StoreState>;

export const createCase$ = (action$: Action$): Observable<Action> =>
  action$.pipe(
    ofType(CaseActions.createCase),
    mergeMap(({ queryKey, createCaseAttributes }) =>
      makeQuery(queryKey)({
        call: () => CaseRepository.createCase(createCaseAttributes),
        onSuccess: (res) => of(CaseActions.setCreatedCase(res.element)),
      })
    )
  );

export const getCaseList$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(CaseActions.getCaseList),
    withLatestFrom(state$),
    mergeMap(([{ queryKey, queryParams }, state]) =>
      makeQuery(queryKey)({
        call: () =>
          GraphQLRepository.getCaseList(
            generateCaseQueryParams(queryParams, getUserProfileCustomerNumber(state))
          ),
        onSuccess: (res) => of(CaseActions.setCaseList(res.list, res.totalCount)),
      })
    )
  );

export const epics = combineEpics<any>(getCaseList$, createCase$);
