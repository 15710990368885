import * as React from 'react';

import { Icofont } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { clamp, compose } from 'lodash/fp';

import { Button, ButtonSize, ButtonStatus, ButtonType } from '../Button';
import {
  getPages,
  getPagesCount,
  getPagesToRender,
  isPaginationHead,
  isPaginationTail,
} from './Pagination.helper';

import './Pagination.component.scss';

interface Props {
  page: number | string | undefined;
  recordsPerPage: number;
  recordsTotal: number;
  onPageChange: (page: number) => void;
  dataTestIdBackBtn?: string;
  dataTestIdNextBtn?: string;
}

export const Pagination: React.SFC<Props> = ({
  recordsTotal,
  page,
  recordsPerPage,
  onPageChange,
  dataTestIdBackBtn,
  dataTestIdNextBtn,
}) => {
  const pages = compose(getPages(1), getPagesCount)({ recordsTotal, recordsPerPage });
  const minPage = 1;
  const maxPage = pages.length;
  const activePage = clamp(minPage, maxPage)(Number(page) || minPage);
  const pagesToRender = getPagesToRender(activePage)(pages);

  return recordsTotal ? (
    <div className={'c-pagination'}>
      <Button
        label={<Icofont type={'angle'} />}
        className={'c-pagination__btn-prev'}
        size={ButtonSize.LARGE}
        type={ButtonType.TERTIARY}
        disabled={minPage === activePage}
        onClick={() => onPageChange(activePage - 1)}
        dataTestId={dataTestIdBackBtn}
      />

      <span className={'c-pagination__page-list'}>
        {!isPaginationHead(activePage) && (
          <>
            <Button
              label={`${minPage}`}
              key={minPage}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
              className={'c-pagination__page'}
              onClick={() => onPageChange(minPage)}
            />
            <span className={'c-pagination__rest'}>{'...'}</span>
          </>
        )}
        {pagesToRender.map((page) => (
          <Button
            label={`${page}`}
            key={page}
            size={ButtonSize.SMALL}
            type={ButtonType.TERTIARY}
            className={classNames('c-pagination__page', {
              'is-active': activePage === page,
            })}
            onClick={() => activePage !== page && onPageChange(page)}
          />
        ))}
        {!isPaginationTail(activePage, maxPage) && (
          <>
            <span className={'c-pagination__rest'}>{'...'}</span>
            <Button
              label={`${maxPage}`}
              key={maxPage}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
              className={'c-pagination__page'}
              onClick={() => onPageChange(maxPage)}
            />
          </>
        )}
      </span>
      <Button
        label={<Icofont type={'angle'} />}
        size={ButtonSize.LARGE}
        type={ButtonType.TERTIARY}
        className={'c-pagination__btn-next'}
        disabled={maxPage === activePage}
        status={maxPage === activePage ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
        onClick={() => onPageChange(activePage + 1)}
        dataTestId={dataTestIdNextBtn}
      />
    </div>
  ) : null;
};
