import * as React from 'react';

import { Bubble, ClickOutside, DS } from '@sonnen/shared-web';

import { ChartDataSeriesKey } from '+app/+customer/+analysis/store/types/chartDataSeries.interface';

import './Legend.component.scss';

export type LegendDataSeries = {
  key: ChartDataSeriesKey;
  name: string;
  isActive: boolean;
  color?: string;
  markerShape?: DS.BadgeMarkerShape;
  isDisabled: boolean;
  disabledTooltipText?: string;
};

interface LegendProps {
  dataSeries: LegendDataSeries[];
  onClick: (serieKey: string) => void;
}

export const Legend: React.FC<LegendProps> = ({ dataSeries, onClick }) => {
  const [disabledTooltipVisible, setDisabledTooltipVisible] = React.useState<
    ChartDataSeriesKey | undefined
  >(undefined);

  return (
    <div className={'c-legend'}>
      {dataSeries.map(
        ({
          key,
          name,
          isActive,
          isDisabled,
          color,
          disabledTooltipText,
          markerShape = DS.BadgeMarkerShape.CIRCLE,
        }) => {
          return (
            <div
              key={key}
              className={'c-legend__badge'}
              style={{ '--badge-button-theme-color': color } as React.CSSProperties}
            >
              <DS.BadgeButton
                value={key}
                label={name}
                isActive={isActive && !isDisabled}
                marker={markerShape}
                onClick={isDisabled ? () => setDisabledTooltipVisible(key) : onClick}
              />

              {disabledTooltipVisible === key && isDisabled && (
                <ClickOutside onClick={() => setDisabledTooltipVisible(undefined)}>
                  <div className={'c-legend__tooltip'}>
                    <Bubble side={'top'} isThemeDense={false}>
                      {disabledTooltipText}
                    </Bubble>
                  </div>
                </ClickOutside>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};
