var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import isNil from 'lodash/isNil';
import { JsonListResponse } from '../responses';
import { resolveRelationships } from '../helpers';
import { JsonApiSender } from './jsonApi.sender';
var JsonApiManySender = (function (_super) {
    __extends(JsonApiManySender, _super);
    function JsonApiManySender(httpClient, requestData) {
        var _this = _super.call(this, httpClient, requestData) || this;
        _this.includedGroups = {};
        requestData.options.pagination = true;
        return _this;
    }
    JsonApiManySender.prototype.addToResponse = function (response) {
        this.previousResponse = response;
        return this;
    };
    JsonApiManySender.prototype.groupIncluded = function (groups) {
        this.includedGroups = groups;
        return this;
    };
    JsonApiManySender.prototype.send = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a, limit, offset, body, newData, data, oldIncluded, groups, _loop_1, this_1, name_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.request(options)];
                    case 1:
                        response = _b.sent();
                        _a = this.requestData, limit = _a.limit, offset = _a.offset;
                        return [4, response.parsedBody()];
                    case 2:
                        body = _b.sent();
                        newData = body.data;
                        if (this.resolveIncludedRelationships && body.included) {
                            newData = resolveRelationships(newData, body.included);
                        }
                        if (this.previousResponse && !isNil(offset)) {
                            data = this.previousResponse.raw.data.slice(0);
                            data.splice.apply(data, __spreadArrays([offset * limit, limit], newData));
                            body.data = data;
                            oldIncluded = this.previousResponse.raw.included;
                            if (oldIncluded) {
                                body.included = (body.included || []).concat(oldIncluded);
                            }
                        }
                        groups = {};
                        if (body.included) {
                            _loop_1 = function (name_1) {
                                if (this_1.includedGroups.hasOwnProperty(name_1)) {
                                    var type_1 = this_1.includedGroups[name_1].type;
                                    groups[name_1] = body.included.filter(function (included) { return included.type === type_1; });
                                }
                            };
                            this_1 = this;
                            for (name_1 in this.includedGroups) {
                                _loop_1(name_1);
                            }
                        }
                        return [2, new JsonListResponse(body, response, limit, offset, groups)];
                }
            });
        });
    };
    return JsonApiManySender;
}(JsonApiSender));
export { JsonApiManySender };
