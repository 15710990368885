import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import { LeadNewAddress } from '+app/+lead/components/LeadNewAddress/LeadNewAddress';
import { LeadNewCustomerDetails } from '+app/+lead/components/LeadNewCustomerDetails';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import {
  getDeliveryAddressSuggestions,
  getDeliveryAddressSuggestionsQuery,
  getInvoiceAddressSuggestions,
  getInvoiceAddressSuggestionsQuery,
  getLeadForm,
  getLeadSubmitQuery,
  getLeadSubmitQueryStatus,
} from '+app/+lead/store/+lead.selectors';
import { LEAD_SUBMIT_QUERY } from '+app/+lead/store/+lead.state';
import { LeadCreationFormSchema, leadFormInitial } from '+app/+lead/store/schemas';
import { LeadForm } from '+app/+lead/store/types';
import LineDivider from '+shared/basicComponents/LineDivider/LineDivider';
import { LabelLarge } from '+shared/basicComponents/Typography/Labels';
import { FormCheckbox, FormErrorBanner } from '+shared/components';
import { Button, ButtonStatus, MainType } from '+shared/components/Button';
import { LayoutActions } from '+shared/store/layout';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';

import { mapLeadErrorTitleToTranslationKey } from './LeadNew.helper';

import './LeadNew.component.scss';

const mapStateToProps = (state: StoreState) => ({
  leadForm: getLeadForm(state),
  leadSubmitQuery: getLeadSubmitQuery(state),
  leadSubmitQueryStatus: getLeadSubmitQueryStatus(state),
  deliveryAddressSuggestions: getDeliveryAddressSuggestions(state),
  deliveryAddressQuery: getDeliveryAddressSuggestionsQuery(state),
  invoiceAddressSuggestions: getInvoiceAddressSuggestions(state),
  invoiceAddressQuery: getInvoiceAddressSuggestionsQuery(state),
});

const mapDispatchToProps = mapActions({
  createLead: LeadPageActions.createLead,
  toggleModal: LayoutActions.toggleModal,
  clearQuery: QueryActions.init,
  getDeliveryAddressSuggestions: LeadPageActions.getDeliveryAddressSuggestions,
  getInvoiceAddressSuggestions: LeadPageActions.getInvoiceAddressSuggestions,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface Error {
  title: string;
}

const { create, gdprConsent, marketingMailing, optional } = T.lead._salessolution_;
const { generalValidationError } = T.lead.boc._salessolution_.form;
const { deliveryAddress, invoiceAddress, anotherAddressCheckbox } =
  T.lead.boc._salessolution_.form.personalDetails;

const LeadNewComponent: React.FC<Props> = ({
  actions,
  leadSubmitQuery,
  leadSubmitQueryStatus,
  deliveryAddressSuggestions,
  deliveryAddressQuery,
  invoiceAddressSuggestions,
  invoiceAddressQuery,
}) => {
  const [leadErrors, setLeadErrors] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      actions.clearQuery(LEAD_SUBMIT_QUERY);
    };
  }, []);

  useEffect(() => {
    if (leadSubmitQuery.error && leadSubmitQuery.error.response) {
      leadSubmitQuery.error.response.parsedBody().then((res) => {
        const filteredErrors = res.errors
          .filter((error: Error) => !isEmpty(error.title))
          .map(({ title }: Error) => mapLeadErrorTitleToTranslationKey(title || ''))
          .filter(
            // only unique keys
            (v: string, i: number, a: string[]) => a.indexOf(v) === i
          );
        setLeadErrors(filteredErrors);
      });
    }
  }, [leadSubmitQuery.error]);

  const onSubmit = (values: LeadForm) => {
    const newLeadData: LeadForm = { ...values };
    if (newLeadData.invoiceAddress) {
      const newInvoiceAddress = { ...newLeadData.invoiceAddress };
      newInvoiceAddress.street = `${newInvoiceAddress.street} ${newInvoiceAddress.houseNumber}`;
      newLeadData.invoiceAddress = { ...newInvoiceAddress };
    }
    actions.createLead(newLeadData);
  };

  return (
    <Formik
      initialValues={leadFormInitial}
      validationSchema={LeadCreationFormSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {(form) => (
        <Form className="lead-new">
          <div className="lead-new__main">
            <LeadNewCustomerDetails form={form} />

            <LabelLarge
              text={I18n.t(deliveryAddress)}
              className="c-lead-new-delivery-address__label-spacing"
            />
            <LeadNewAddress
              addressType={'deliveryAddress'}
              form={form}
              addressSuggestions={deliveryAddressSuggestions}
              addressQuery={deliveryAddressQuery}
              getAddressSuggestions={actions.getDeliveryAddressSuggestions}
            />

            <div className="lead-new__invoice-address-checkbox-wrapper">
              <LabelLarge
                text={I18n.t(invoiceAddress)}
                className="lead-new__invoice-address-checkbox-wrapper--title"
              />
              <FormCheckbox
                form={form}
                label={I18n.t(anotherAddressCheckbox)}
                name={'hasDifferentInvoiceAddress'}
                dataTestId="invoice-address-checkbox"
              />
            </div>

            {form.values.hasDifferentInvoiceAddress && (
              <LeadNewAddress
                addressType="invoiceAddress"
                form={form}
                addressSuggestions={invoiceAddressSuggestions}
                addressQuery={invoiceAddressQuery}
                getAddressSuggestions={actions.getInvoiceAddressSuggestions}
              />
            )}

            <LineDivider />

            {leadErrors ? (
              leadErrors.map((translationKey) => (
                <FormErrorBanner
                  key={translationKey}
                  isVisible={leadSubmitQueryStatus.error}
                  error={I18n.t(translationKey)}
                />
              ))
            ) : (
              <FormErrorBanner
                isVisible={leadSubmitQueryStatus.error}
                error={I18n.t(generalValidationError)}
              />
            )}
          </div>

          <div className="lead-new__checkboxes">
            <FormCheckbox
              form={form}
              label={I18n.t(gdprConsent)}
              name="agreedToGdpr"
              dataTestId="confirm-checkbox1"
            />

            <FormCheckbox
              form={form}
              label={`(${I18n.t(optional).toLowerCase()}) ${I18n.t(marketingMailing)}`}
              name="agreedToMarketingMailing"
              dataTestId="confirm-checkbox2"
            />
          </div>

          <div className="lead-new__footer">
            <Button
              mainType={MainType.SUBMIT}
              label={I18n.t(create)}
              loading={leadSubmitQueryStatus.pending}
              disabled={!form.dirty && !form.isValid}
              status={!form.dirty && !form.isValid ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              dataTestId="create-lead-button"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const LeadNew = connect(mapStateToProps, mapDispatchToProps)(LeadNewComponent);
