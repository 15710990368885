import * as React from 'react';
import Media from 'react-media';

import { breakpointUp } from '@sonnen/shared-web';

import { getInlineAddress } from '+shared/store/lead/lead.helpers';
import { Lead } from '+shared/store/lead/types';

import './LeadListLocation.component.scss';

interface Props {
  item: Lead;
}

export const LeadListLocation: React.FC<Props> = ({ item }) => (
  <div className="c-lead-list-location">
    <Media query={{ minWidth: breakpointUp('LG') }}>
      {(isMedium: boolean) =>
        isMedium && item.deliveryAddress ? (
          <>
            <Paragraph>{item.deliveryAddress.street}</Paragraph>
            <Paragraph>
              {item.deliveryAddress.zipCode} {item.deliveryAddress.city}
            </Paragraph>
          </>
        ) : (
          <Paragraph>{item.deliveryAddress && getInlineAddress(item.deliveryAddress)}</Paragraph>
        )
      }
    </Media>
  </div>
);

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <p className="c-lead-list-location__paragraph" data-hj-suppress={true}>
    {children}
  </p>
);
