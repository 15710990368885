import {
  DSO_REGISTRATION_FORM_ACTIONS,
  DsoRegistrationFormActions,
} from '+app/+setupTool/+form/store/+form.actions';
import { createReducer } from '+app/utils';
import { queryReducer } from '+shared/store/query';
import { DsoRegisterActions } from '+shared/store/setupTool';

import { PV_REGISTER_ACTIONS, PVRegisterActions } from './+pvRegister.actions';
import {
  initialState,
  PV_REGISTER_CONFIG_SUBMIT_QUERY,
  PV_REGISTER_MANUALLY_QUERY,
  PV_REGISTER_PATCH_SUBMISSION_QUERY,
  PV_REGISTER_POLLING_SUBMIT_QUERY,
  PVRegisterState,
  SOLVE_CAPTCHA_PV_QUERY,
} from './+pvRegister.state';

export const reducer = createReducer<
  PVRegisterState,
  PVRegisterActions | DsoRegistrationFormActions | DsoRegisterActions
>((state = initialState, action): PVRegisterState => {
  switch (action.type) {
    case PV_REGISTER_ACTIONS.SUBMIT_REGISTER_PV_ERROR:
      return {
        ...state,
        registrationError: action.error,
      };
    case PV_REGISTER_ACTIONS.GET_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submission: action.response.pvr,
      };
    case PV_REGISTER_ACTIONS.SET_GENERATED_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case PV_REGISTER_ACTIONS.SET_MODIFICATION_DATE: {
      return {
        ...state,
        updatedAt: action.date || state.updatedAt,
      };
    }
    case PV_REGISTER_ACTIONS.INIT:
      return {
        ...state,
        submission: action.data,
      };
    case DSO_REGISTRATION_FORM_ACTIONS.CLEAR_DATA:
      return {
        ...initialState,
      };
    case PV_REGISTER_ACTIONS.SET_PV_REGISTER_STATE:
      return {
        ...state,
        pvRegisterState: action.pvRegisterState,
      };
    default:
      return queryReducer(state, action, [
        PV_REGISTER_PATCH_SUBMISSION_QUERY,
        PV_REGISTER_CONFIG_SUBMIT_QUERY,
        PV_REGISTER_POLLING_SUBMIT_QUERY,
        PV_REGISTER_MANUALLY_QUERY,
        SOLVE_CAPTCHA_PV_QUERY,
      ]);
  }
});
