import { BatteryStatusesKey, EnergyFlowSeriesKey } from '@sonnen/shared-web';

import { XYPoint } from '@kanva/charts';
import { createSelector } from 'reselect';

import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';

import { GET_SITE_MEASUREMENTS_QUERY } from './+analysis.state';
import { getMaxForecastsDate, hasMeasurements } from './helpers/+analysis.helpers';

export const getAnalysisState = (store: StoreState) => store.page.customer.analysis;

export const getAnalysisDayChart = createSelector(getAnalysisState, (state) => state.dayChart);

export const getInitialDataSeries = createSelector(
  getAnalysisDayChart,
  (state) => state.initialDataSeries
);

export const getCurrentDataSeries = createSelector(
  getAnalysisDayChart,
  (state) => state.currentDataSeries
);

export const getSelectedDataSeriesKeys = createSelector(
  getAnalysisDayChart,
  (state) => state.selectedDataSeriesKeys
);

export const getInitialSelectedDates = createSelector(
  getInitialDataSeries,
  (initialDataSeries) => initialDataSeries.selectedDates
);

export const getCurrentSelectedDates = createSelector(
  getCurrentDataSeries,
  (currentDataSeries) => currentDataSeries.selectedDates
);

export const getIsResolutionChanged = createSelector(
  getAnalysisDayChart,
  (state) => state.isResolutionChanged
);

export const getForecastProductionSeries = createSelector(
  getInitialDataSeries,
  ({ data }) => data[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]
);

export const getForecastConsumptionSeries = createSelector(
  getInitialDataSeries,
  ({ data }) => data[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]
);

export const getInitialSiteChargeLimitSeries = createSelector(
  getInitialDataSeries,
  ({ data }) => data[BatteryStatusesKey.CHARGE_LIMIT]
);

export const getSiteMeasurements = createSelector(
  getAnalysisDayChart,
  (state) => state.siteMeasurements
);

export const hasSiteMeasurements = createSelector(getSiteMeasurements, (measurements) =>
  hasMeasurements(measurements)
);

export const isUnsupportedBattery = createSelector(
  getAnalysisDayChart,
  (state) => state.isUnsupportedBattery
);

export const getSiteMeasurementsQueryStatus = createSelector(getAnalysisState, (state) =>
  getStatus(state[GET_SITE_MEASUREMENTS_QUERY])
);

export const areDataSeriesEmpty = createSelector(
  getInitialDataSeries,
  ({ data }) =>
    !(
      data.productionPower.length &&
      data.consumptionPower.length &&
      data.directUsagePower.length &&
      data.batteryUsoc.length
    )
);

export const getAreaChartProduction = createSelector(
  getInitialDataSeries,
  ({ data }) => data[EnergyFlowSeriesKey.PRODUCTION_POWER] as XYPoint[]
);

export const getAreaChartConsumption = createSelector(
  getInitialDataSeries,
  ({ data }) => data[EnergyFlowSeriesKey.CONSUMPTION_POWER] as XYPoint[]
);

export const getForecastConsumptionFull = createSelector(
  getAnalysisDayChart,
  (state) => state.forecastConsumptionFull
);

export const getForecastProductionFull = createSelector(
  getAnalysisDayChart,
  (state) => state.forecastProductionFull
);

export const hasForecast = createSelector(
  getForecastProductionSeries,
  getForecastConsumptionSeries,
  (consumption, production): boolean =>
    Boolean((consumption && consumption.length) || (production && production.length))
);

export const getDatepickerMaxDate = createSelector(
  getForecastProductionFull,
  getForecastConsumptionFull,
  (production, consumption): Date => getMaxForecastsDate(production, consumption)
);

const getStatistics = createSelector(getAnalysisState, (state) => state.statistics);

export const getStatisticsSelectedDate = createSelector(
  getStatistics,
  (statistics) => statistics.selectedDate
);

export const getPieChartSeries = createSelector(
  getStatistics,
  (statistics) => statistics.pieChartSeries
);

export const getBarChartSeries = createSelector(
  getStatistics,
  (statistics) => statistics.barChartSeries
);
