import React, { ReactNode } from 'react';

import classnames from 'classnames';

import './Labels.scss';

interface Props {
  text: ReactNode;
  className?: string;
}

export const LabelLarge: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'label-large');

  return <span className={classNames}>{text}</span>;
};

export const LabelMedium: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'label-medium');

  return <span className={classNames}>{text}</span>;
};

export const LabelSmall: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'label-small');

  return <span className={classNames}>{text}</span>;
};

export const LabelSmallCaps: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'label-small-caps');

  return <span className={classNames}>{text}</span>;
};
