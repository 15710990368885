import * as React from 'react';

import classNames from 'classnames';

import { getSocialIcon } from './FooterSocial.helper';

import './FooterSocial.component.scss';

export enum SocialIcon {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  LINKED_IN = 'linkedin',
  INSTAGRAM = 'instagram',
}

interface Props {
  icon: SocialIcon;
  link: string;
}

export const FooterSocial: React.FC<Props> = ({ icon, link }) => (
  <a
    className={classNames('c-footer-social', `c-footer-social--${icon}`)}
    href={link}
    target={'_blank'}
    rel={'noopener noreferrer'}
  >
    {getSocialIcon(icon)}
  </a>
);
