import { ActionsUnion, createAction } from '+app/utils';
import { AdditionalFeatures, Document } from '+shared/store/setupTool/types';

import { VppRegisterStatus } from './+vppDocumentation.dictionary';
import { VppDocumentationInterface, VppState } from './types';

export enum VPP_DOCUMENTATION_ACTIONS {
  SET_INITIAL_STATE = '[+VppDocumentation] SET_INITIAL_STATE',
  SET_FIELDS = '[+VppDocumentation] SET_FIELDS',
  SET_UPLOADED_DOCUMENTS = '[+VppDocumentation] SET_UPLOADED_DOCUMENTS',
  GENERATE_COMPLETION_NOTIFICATION = '[+VppDocumentation] GENERATE_COMPLETION_NOTIFICATION',
  SET_COMPLETION_NOTIFICATION_DOCUMENT = '[+VppDocumentation] SET_COMPLETION_NOTIFICATION_DOCUMENT',
  // eslint-disable-next-line max-len
  SET_COMPLETION_MODIFICATION_REGENERATED_AT = '[+VppDocumentation] SET_COMPLETION_MODIFICATION_REGENERATED_AT',
  SET_SUBMISSION_STATUS = '[+VppDocumentation] SET_SUBMISSION_STATUS',
  SAVE_DATA_AND_PERFORM_VPP = '[+VppDocumentation] SAVE_DATA_AND_PERFORM_VPP',
  // eslint-disable-next-line max-len
  SAVE_DATA_AND_PERFORM_VPP_ONLY_VPP_FIELDS = '[+VppDocumentation] SAVE_DATA_AND_PERFORM_VPP_ONLY_VPP_FIELDS',
  SET_VPP_ADDITIONAL_FEATURES = '[+VppDocumentation] SET_VPP_ADDITIONAL_FEATURES',
  SET_VPP_STATE = '[+VppDocumentation] SET_VPP_STATE',
}

export const VppDocumentationActions = {
  setInitialState: createAction(VPP_DOCUMENTATION_ACTIONS.SET_INITIAL_STATE),
  setFields: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_FIELDS,
    (fields: VppDocumentationInterface) => ({ fields })
  ),
  setVppUploadedDocuments: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_UPLOADED_DOCUMENTS,
    (documents: Document[]) => ({ documents })
  ),
  generateCompletionNotification: createAction(
    VPP_DOCUMENTATION_ACTIONS.GENERATE_COMPLETION_NOTIFICATION
  ),
  setCompletionNotificationDocument: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_NOTIFICATION_DOCUMENT,
    (document: Document) => ({ document })
  ),
  setCompletionNotificationRegeneratedAt: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_MODIFICATION_REGENERATED_AT,
    (regeneratedAt: string) => ({ regeneratedAt })
  ),
  setSubmissionStatus: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_SUBMISSION_STATUS,
    (submissionStatus: VppRegisterStatus | undefined) => ({ submissionStatus })
  ),
  saveDataAndPerformVpp: createAction(
    VPP_DOCUMENTATION_ACTIONS.SAVE_DATA_AND_PERFORM_VPP,
    (fields: VppDocumentationInterface) => ({ fields })
  ),
  saveDataAndPerformVppOnlyVppFields: createAction(
    VPP_DOCUMENTATION_ACTIONS.SAVE_DATA_AND_PERFORM_VPP_ONLY_VPP_FIELDS,
    (fields: VppDocumentationInterface) => ({ fields })
  ),
  setVppAdditionalFeatures: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_VPP_ADDITIONAL_FEATURES,
    (vppAdditionalFeatures: AdditionalFeatures[]) => ({ vppAdditionalFeatures })
  ),
  setVppState: createAction(VPP_DOCUMENTATION_ACTIONS.SET_VPP_STATE, (vppState: VppState) => ({
    vppState,
  })),
};

export type VppDocumentationActions = ActionsUnion<typeof VppDocumentationActions>;
