import * as React from 'react';

import * as classNames from 'classnames';

import './BatteryLiveStatusList.component.scss';

export interface LiveCardStatus {
  label: string;
  value: React.ReactNode | string;
}
interface Props {
  statusList: LiveCardStatus[];
  className?: ClassValue;
  alignment?: 'left' | 'right';
}

export const BatteryLiveStatusList: React.FC<Props> = ({ statusList, className, alignment }) => {
  return (
    <div
      className={classNames('c-battery-live-status-list', {
        'c-battery-live-status-list--align-right': alignment === 'right',
        'c-battery-live-status-list--align-left': alignment === 'left',
      })}
    >
      {statusList.map((batteryStatus) => (
        <div
          key={batteryStatus.label}
          className={classNames('c-battery-live-status-list__row', {
            'c-battery-live-status-list__row--align-right': alignment === 'right',
            'c-battery-live-status-list__row--align-left': alignment === 'left',
          })}
        >
          <div
            className={classNames('c-battery-live-status-list__label', {
              'c-battery-live-status-list__label--align-right': alignment === 'right',
              'c-battery-live-status-list__label--align-left': alignment === 'left',
            })}
          >
            {batteryStatus.label}
          </div>
          <div
            className={classNames('c-battery-live-status-list__value', {
              'c-battery-live-status-list__value--end': alignment === 'right',
              'c-battery-live-status-list__value--start': alignment === 'left',
            })}
          >
            {batteryStatus.value}
          </div>
        </div>
      ))}
    </div>
  );
};
