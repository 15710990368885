import { createPersistedReducer } from '+app/utils';

import { AUTH_ACTIONS, AuthActions } from './auth.actions';
import { AuthState, initialState } from './auth.state';

export const reducer = createPersistedReducer<AuthState, AuthActions>(
  (state = initialState, action): AuthState => {
    switch (action.type) {
      case AUTH_ACTIONS.LOGIN_START:
        return {
          ...state,
          verifier: action.verifier,
        };
      case AUTH_ACTIONS.SET_TOKEN_STORAGE:
        return {
          ...state,
          tokenStorage: action.tokenStorage && { ...action.tokenStorage },
          salesforceTokenStorage: undefined,
          verifier: undefined,
        };
      case AUTH_ACTIONS.SET_SALESFORCE_TOKEN_STORAGE:
        return {
          ...state,
          salesforceTokenStorage: action.salesforceTokenStorage && {
            ...action.salesforceTokenStorage,
          },
          tokenStorage: undefined,
        };
      case AUTH_ACTIONS.MIGRATE_AUTH:
        const { accessToken, tokenType, refreshToken, expiresIn } = state as any;
        const migratedState = {
          ...state,
          verifier: undefined,
          accessToken: undefined,
          tokenType: undefined,
          refreshToken: undefined,
          expiresIn: undefined,
          tokenStorage: { accessToken, tokenType, refreshToken, expiresIn },
        };
        return migratedState;
      case AUTH_ACTIONS.SAVE_REVERSE_CHANNEL_TOKEN:
        return {
          ...state,
          reverseChannelToken: action.reverseChannelToken,
        };
      default:
        return state;
    }
  }
)({
  key: 'auth',
});
