import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { FormCheckbox } from '@sonnen/shared-web';

import { mapActions } from '+app/utils';
import { BatteryTimeOfUseScheduleGraph } from '+customer-battery/components/BatteryTimeOfUseSchedule/BatteryTimeOfUseScheduleGraph';
import { BatteryTimeOfUseScheduleList } from '+customer-battery/components/BatteryTimeOfUseSchedule/BatteryTimeOfUseScheduleList';
import { BatteryOperatingModeView } from '+customer-battery/containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import { getBattery, getTariffWindowScheduleEditState } from '+customer-battery/store';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { BatteryActions } from '+shared/store/battery';
import { StoreState } from '+shared/store/store.interface';
import { getUserCountryCode } from '+shared/store/user/user.selectors';

import { TariffWindow } from '../../store/battery-tariff';

import './BatteryTimeOfUseSchedule.component.scss';

interface BasicProps {
  tariffWindows: TariffWindow[];
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
}

const mapStateToProps = (state: StoreState) => ({
  battery: getBattery(state),
  batteryTariffWindowDirtyState: getTariffWindowScheduleEditState(state),
  isTariffWindowScheduleEditedButNotSaved: getTariffWindowScheduleEditState(state),
  userCountry: getUserCountryCode(state),
});

const mapDispatchToProps = mapActions({
  postBatteryTimeOfUse: BatteryActions.postBatteryTimeOfUse,
  resetLocalChanges: BatteryActions.resetLocalChanges,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BatteryTimeOfUseScheduleComponent: React.FC<Props & BasicProps> = ({
  tariffWindows,
  setBatteryOperatingModeView,
  isTariffWindowScheduleEditedButNotSaved,
  battery,
  actions,
  userCountry,
}) => {
  const [checkbox, setCheckbox] = React.useState(false);

  const handleOnSaveClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    actions.postBatteryTimeOfUse({
      tariffWindows,
      batteryId: battery?.id ?? '',
    });
    setCheckbox(false);
  };

  return (
    <div className={'c_battery_time_of_use_schedule__container'}>
      <h3 className={'c_battery_time_of_use_schedule__header'}>
        {I18n.t(T.customerSingle.batteryDetails.timeOfUse.header)}
      </h3>
      <BatteryTimeOfUseScheduleGraph tariffWindows={tariffWindows} />
      <BatteryTimeOfUseScheduleList
        tariffWindows={tariffWindows}
        setBatteryOperatingModeView={setBatteryOperatingModeView}
      />

      <div className="c-tariff-window-list-item__new">
        <Button
          label={I18n.t(T.customerSingle.batteryDetails.timeOfUse.action.addNewTariffWindow)}
          type={ButtonType.TERTIARY}
          size={ButtonSize.SMALL}
          onClick={() => setBatteryOperatingModeView(BatteryOperatingModeView.TIME_OF_USE_NEW)}
          className={'c-tariff-window-list-item__new--button'}
        />
        <FormCheckbox
          checked={checkbox}
          name={'accept'}
          label={I18n.t(T.customerSingle.batteryDetails.timeOfUse.action.acceptChanges)}
          onClick={() => setCheckbox(!checkbox)}
        />
      </div>
      <div className={'c-battery-operating-mode-operation__footer'}>
        <Button
          label={I18n.t(T.general.basicActions.goBack)}
          type={ButtonType.TERTIARY}
          size={ButtonSize.SMALL}
          onClick={() => {
            setBatteryOperatingModeView(BatteryOperatingModeView.OPERATIONS_FORM);
            actions.resetLocalChanges();
          }}
        />
        <Button
          label={I18n.t(T.customerSingle.batteryDetails.timeOfUse.action.saveAllChanges)}
          type={ButtonType.PRIMARY}
          size={ButtonSize.SMALL}
          disabled={!isTariffWindowScheduleEditedButNotSaved || !checkbox}
          status={
            !isTariffWindowScheduleEditedButNotSaved || !checkbox
              ? ButtonStatus.DISABLED
              : ButtonStatus.ENABLED
          }
          onClick={handleOnSaveClick}
        />
      </div>
    </div>
  );
};

export const BatteryTimeOfUseSchedule = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryTimeOfUseScheduleComponent);
