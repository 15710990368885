import { createReducer } from '+app/utils';
import { NEWS_MAIN_ACTIONS, NewsMainActions } from '+shared/store/newsMain/newsMain.actions';
import { initialState, NewsMainState } from '+shared/store/newsMain/newsMain.state';

type Actions = NewsMainActions;

export const reducer = createReducer<NewsMainState, Actions>(
  (state = initialState, action: NewsMainState) => {
    switch (action.type) {
      case NEWS_MAIN_ACTIONS.SET_ACTIVE_NEWS_ID:
        return {
          ...state,
          activeNewsId: action.activeNewsId,
        };
      default:
        return state;
    }
  }
);
