import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { iif, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { RouterActions } from '+app/router/store';
import { isLocationChangeAction } from '+app/router/store/router.actions';
import { isPathChangingFromCustomerToAnother } from '+app/router/store/router.selectors';
import { StoreState } from '+shared/store/store.interface';

import { dataGuard, makeQuery, ofType, polling } from '../../../utils';
import { GET_SITE_LIVE_STATE_QUERY } from '.';
import { SiteActions } from './site.actions';
import { validateDateFilters } from './site.helpers';
import { SiteRepository } from './site.repository';

type Action$ = ActionsObservable<SiteActions>;
type State$ = StateObservable<StoreState>;

const getSiteMeasurements$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteMeasurements),
    mergeMap(({ queryKey, siteId, start, end }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteMeasurements(siteId, validateDateFilters(start, end)),
        onSuccess: (res) => dataGuard(SiteActions.setSiteMeasurements)(res.element),
      })
    )
  );

const getSiteChargerList$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteChargerList),
    mergeMap(({ queryKey, siteId }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteChargers(siteId),
        onSuccess: (res) => dataGuard(SiteActions.setSiteChargerList)(res.elements),
      })
    )
  );

const getSiteChargeLimits$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteChargeLimits),
    mergeMap(({ queryKey, siteId, start, end }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteChargeLimits(siteId, validateDateFilters(start, end)),
        onSuccess: (res) => dataGuard(SiteActions.setSiteChargeLimits)(res.element),
      })
    )
  );

const getSiteStatistics$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteStatistics),
    mergeMap(({ queryKey, siteId, filters }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteStatistics(siteId, filters),
        onSuccess: (res) => dataGuard(SiteActions.setSiteStatistics)(res.element),
      })
    )
  );

const getSite$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSite),
    mergeMap(({ queryKey, siteId }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSite(siteId),
        onSuccess: (res) => dataGuard(SiteActions.setSite)(res.element),
      })
    )
  );

const getSiteLiveState$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteLiveState),
    mergeMap(({ queryKey, siteId }) =>
      iif(
        () => !!siteId,
        makeQuery(queryKey)({
          call: () => SiteRepository.getSiteLiveState(siteId),
          onSuccess: (res) => dataGuard(SiteActions.setSiteLiveState)(res.element),
          onFailure: () => of(SiteActions.setSiteLiveState(undefined)),
        }),
        of(SiteActions.setSiteLiveState(undefined))
      )
    )
  );

const getSiteLiveStateV2$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteLiveStateV2),
    mergeMap(({ queryKey, siteId }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteLiveStateV2(siteId),
        onSuccess: (res) => dataGuard(SiteActions.setSiteLiveStateV2)(res.element),
        onFailure: () => of(SiteActions.setSiteLiveStateV2(undefined)),
      })
    )
  );

const triggerSiteLiveStatePolling$ = (action$: Action$) =>
  action$.pipe(
    polling({
      startOn: SiteActions.startPolling,
      stopOn: [SiteActions.stopPolling],
      interval: 5000,
      // TODO: pass this queryKey as parameter if possible
    })(({ siteId }) =>
      makeQuery(GET_SITE_LIVE_STATE_QUERY)({
        call: () => SiteRepository.getSiteLiveState(siteId),
        onSuccess: (res) => dataGuard(SiteActions.setSiteLiveState)(res.element),
      })
    )
  );

const getSiteCellData$ = (action$: Action$) =>
  action$.pipe(
    ofType(SiteActions.getSiteCellData),
    mergeMap(({ queryKey, siteId, start, end }) =>
      makeQuery(queryKey)({
        call: () => SiteRepository.getSiteCellData(siteId, validateDateFilters(start, end)),
        onSuccess: (res) => dataGuard(SiteActions.setSiteCellData)(res.element),
      })
    )
  );

const clearSite$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange, RouterActions.changeCustomerSite),
    withLatestFrom(state$),
    filter(([action, state]) =>
      isLocationChangeAction(action) ? isPathChangingFromCustomerToAnother(state) : true
    ),
    map(() => SiteActions.clearSite())
  );

export const epics = combineEpics(
  getSiteMeasurements$,
  getSiteChargerList$,
  getSiteChargeLimits$,
  getSiteStatistics$,
  getSite$,
  getSiteLiveState$,
  getSiteLiveStateV2$,
  triggerSiteLiveStatePolling$,
  getSiteCellData$,
  clearSite$
);
