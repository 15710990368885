import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { LanguageSelect } from '@sonnen/shared-web';

import { CountryCode } from 'src/types/country.type';

import { Locale } from '+app/i18n/i18n.config';
import { getForMarketAvailableLanguages } from '+app/i18n/i18n.helpers';
import { getLanguageFromCurrentLocale } from '+app/i18n/i18n.selectors';
import { mapActions } from '+app/utils';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import { getAcceptedLegalDocuments } from '+shared/store/legal/legal.selectors';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { mapCountryCodeToMarket, Market } from '+utils/market.util';

import { I18nActions } from '../../../i18n/i18n.actions';
import { Footer, FooterNavItem, FooterSocial, SocialIcon } from '../../components';
import { DocumentKind } from '../../store/legal';
import { StoreState } from '../../store/store.interface';
import {
  getFacebookAvailableCountries,
  getFacebookLink,
  getImprintLink,
  getInstagramAvailableCountries,
  getInstagramLink,
  getLinkedInAvailableCountries,
  getLinkedInLink,
  getTwitterAvailableCountries,
  getTwitterLink,
  getYoutubeAvailableCountries,
  getYoutubeLink,
} from './LayoutFooter.helper';

interface ComponentProps {
  isTransparent: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  currentLanguage: getLanguageFromCurrentLocale(state),
  userCountry: getUserCountryCode(state) ?? CountryCode.DE,
  acceptedLegalDocuments: getAcceptedLegalDocuments(state),
});

const mapDispatchToProps = mapActions({
  setLocale: I18nActions.setLocale,
});

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  ComponentProps;

const LayoutFooterComponent: React.FC<Props> = ({
  currentLanguage,
  userCountry,
  isTransparent,
  acceptedLegalDocuments,
  actions,
}) => {
  const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');

  const userMarket = mapCountryCodeToMarket(userCountry, isSpanishMarketEnabled) ?? Market.DE;

  const renderFooterNavItems = () => {
    const availableLanguages = getForMarketAvailableLanguages(isSpanishMarketEnabled, userMarket);

    const termsOfUse = acceptedLegalDocuments.find(
      (document) => document.kind === DocumentKind.TN_C_PARTNER_PORTAL
    );
    const jointControllerAgreement = acceptedLegalDocuments.find(
      (document) => document.kind === DocumentKind.PARTNER_DECLARATION_CUSTOMER_DATA
    );

    return (
      <>
        <FooterNavItem>
          <LanguageSelect
            selected={currentLanguage}
            langs={availableLanguages}
            onChange={(locale) => actions.setLocale(locale as Locale)}
          />
        </FooterNavItem>

        {termsOfUse && (
          <FooterNavItem isExternal={true} action={termsOfUse.url}>
            {I18n.t(T.general.footerNavigation.termsOfUse)}
          </FooterNavItem>
        )}

        <FooterNavItem isExternal={true} action={getImprintLink(userMarket)}>
          {I18n.t(T.general.footerNavigation.imprint)}
        </FooterNavItem>

        {jointControllerAgreement && (
          <FooterNavItem isExternal={true} action={jointControllerAgreement.url}>
            {I18n.t(T.general.footerNavigation.jointController)}
          </FooterNavItem>
        )}
      </>
    );
  };

  const renderSocialItems = () => (
    <>
      {getFacebookAvailableCountries().includes(userMarket) && (
        <FooterSocial icon={SocialIcon.FACEBOOK} link={getFacebookLink(userMarket)} />
      )}
      {getTwitterAvailableCountries().includes(userMarket) && (
        <FooterSocial icon={SocialIcon.TWITTER} link={getTwitterLink(userMarket)} />
      )}
      {getYoutubeAvailableCountries().includes(userMarket) && (
        <FooterSocial icon={SocialIcon.YOUTUBE} link={getYoutubeLink(userMarket)} />
      )}
      {getLinkedInAvailableCountries().includes(userMarket) && (
        <FooterSocial icon={SocialIcon.LINKED_IN} link={getLinkedInLink(userMarket)} />
      )}
      {getInstagramAvailableCountries().includes(userMarket) && (
        <FooterSocial icon={SocialIcon.INSTAGRAM} link={getInstagramLink(userMarket)} />
      )}
    </>
  );

  return (
    <Footer
      isTransparent={isTransparent}
      copyrightNotice={I18n.t(T.general.copyrights)}
      navItems={renderFooterNavItems()}
      socialItems={renderSocialItems()}
    />
  );
};

export const LayoutFooter = connect(mapStateToProps, mapDispatchToProps)(LayoutFooterComponent);
