import { __extends } from "tslib";
import { DragGestureDetector, ScaleGestureDetector, Point, AreaSelectGestureDetector, } from '@kanva/core';
import defaultsDeep from 'lodash/defaultsDeep';
import { floorToNearest } from '../utils';
import { DataContainerEventType } from './data-container.events';
import { DataContainerExtension } from './data-container.extension';
export var TRANSFORM_EXTENSION = 'DataContainerTransformExtension';
var DEFAULT_OPTIONS = {
    scale: {
        limit: {
            x: [1, 1],
            y: [1, 1],
        },
        multitouch: true,
        scroll: true,
        selectArea: false,
        drag: true,
        listenerThreshold: 1,
        minSelectedAreaThreshold: {
            x: 50,
            y: 0,
        },
    },
    pan: {
        pointers: 1,
    },
};
var DataContainerTransformExtension = (function (_super) {
    __extends(DataContainerTransformExtension, _super);
    function DataContainerTransformExtension(transformOptions) {
        var _this = _super.call(this, TRANSFORM_EXTENSION) || this;
        _this.scale = { x: 1, y: 1 };
        _this.translate = { x: 0, y: 0 };
        _this.getScales = function (_a) {
            var payload = _a.payload;
            return ({
                xScale: _this.xScale(payload.xScale),
                yScale: _this.yScale(payload.yScale),
            });
        };
        _this.onScale = function (event) {
            var _a = _this.scale, oldScaleX = _a.x, oldScaleY = _a.y;
            var _b = _this.scales, xScale = _b.xScale, yScale = _b.yScale;
            return _this.setScale({
                x: oldScaleX * event.scaleFactorX,
                y: oldScaleY * event.scaleFactorY,
            }, {
                x: xScale.invert(event.current.centerX),
                y: yScale.invert(event.current.centerY),
            });
        };
        _this.onDrag = function (event) {
            var xScale = _this.scales.xScale;
            var _a = xScale.domain(), domainMin = _a[0], domainMax = _a[1];
            var domainWidth = domainMax - domainMin;
            var windowWidth = domainWidth / _this.scale.x;
            var newTranslateX = _this.translate.x - (xScale.invert(event.deltaX) - xScale.invert(0));
            if (_this.translate.x !== newTranslateX) {
                _this.translate.x = Math.max(0, Math.min(domainWidth - windowWidth, newTranslateX));
                _this.postEvent(DataContainerEventType.DATA_CHANGE);
                return true;
            }
            return false;
        };
        _this.onAreaSelect = function (event) {
            var isSelecting = event.isSelecting, selectedArea = event.selectedArea;
            if (isSelecting) {
                _this.postEvent(DataContainerEventType.AREA_SELECT, selectedArea);
                return true;
            }
            else {
                _this.postEvent(DataContainerEventType.AREA_SELECT, undefined);
                return selectedArea && _this.isSufficientAreaSelected(selectedArea)
                    ? _this.setSelectedArea(selectedArea)
                    : true;
            }
        };
        var _a = _this.updateOptions(transformOptions), options = _a.options, areaSelectGestureDetector = _a.areaSelectGestureDetector, dragGestureDetector = _a.dragGestureDetector, scaleGestureDetector = _a.scaleGestureDetector;
        _this.options = options;
        _this.areaSelectGestureDetector = areaSelectGestureDetector;
        _this.dragGestureDetector = dragGestureDetector;
        _this.scaleGestureDetector = scaleGestureDetector;
        return _this;
    }
    DataContainerTransformExtension.prototype.setOptions = function (transformOptions) {
        var _a = this.updateOptions(transformOptions), options = _a.options, areaSelectGestureDetector = _a.areaSelectGestureDetector, dragGestureDetector = _a.dragGestureDetector, scaleGestureDetector = _a.scaleGestureDetector;
        Object.assign(this.options, options);
        this.areaSelectGestureDetector = areaSelectGestureDetector;
        this.dragGestureDetector = dragGestureDetector;
        this.scaleGestureDetector = scaleGestureDetector;
    };
    DataContainerTransformExtension.prototype.onChartPointerEvent = function (event) {
        this.scales = event.target.getScales();
        return (this.scaleGestureDetector.onPointerEvent(event) ||
            this.dragGestureDetector.onPointerEvent(event) ||
            this.areaSelectGestureDetector.onPointerEvent(event));
    };
    DataContainerTransformExtension.prototype.setSelectedArea = function (area) {
        var _a = this.options.scale, limit = _a.limit, listener = _a.listener, listenerThreshold = _a.listenerThreshold;
        if (!this.scales) {
            return false;
        }
        var _b = this.scales, xScale = _b.xScale, yScale = _b.yScale;
        var start = new Point(xScale.invert(area.l), xScale.invert(area.t));
        var end = new Point(xScale.invert(area.r), xScale.invert(area.b));
        var scale = new Point(this.calculateScale(start.x, end.x, xScale), this.calculateScale(start.y, end.y, yScale));
        var scaleX = Math.max(limit.x[0], Math.min(limit.x[1], scale.x));
        var scaleY = Math.max(limit.y[0], Math.min(limit.y[1], scale.y));
        var _c = this.scale, oldScaleX = _c.x, oldScaleY = _c.y;
        if (oldScaleX !== scaleX) {
            this.scale.x = scaleX;
            this.translate.x = start.x;
        }
        if (oldScaleY !== scaleY) {
            this.scale.y = scaleY;
            this.translate.y = start.y;
        }
        if (oldScaleX !== scaleX || oldScaleY !== scaleY) {
            if (listener && (floorToNearest(oldScaleX, listenerThreshold) !== floorToNearest(scaleX, listenerThreshold) ||
                floorToNearest(oldScaleY, listenerThreshold) !== floorToNearest(scaleY, listenerThreshold))) {
                listener({
                    scaleX: scaleX,
                    scaleY: scaleY,
                    dataContainers: this.dataContainers,
                    start: start,
                    end: end,
                });
            }
            return true;
        }
        return false;
    };
    DataContainerTransformExtension.prototype.setScale = function (scale, center) {
        if (center === void 0) { center = { x: 0, y: 0 }; }
        var _a = this.options.scale, limit = _a.limit, listener = _a.listener, listenerThreshold = _a.listenerThreshold;
        var _b = this.scale, oldScaleX = _b.x, oldScaleY = _b.y;
        var scaleX = Math.max(limit.x[0], Math.min(limit.x[1], scale.x));
        var scaleY = Math.max(limit.y[0], Math.min(limit.y[1], scale.y));
        if (!this.scales) {
            return false;
        }
        var _c = this.scales, xScale = _c.xScale, yScale = _c.yScale;
        if (oldScaleX !== scaleX) {
            this.translate.x = this.normalizeScaleTranslate(this.translate.x, center.x, scaleX, oldScaleX, xScale);
            this.scale.x = scaleX;
        }
        if (oldScaleY !== scaleY) {
            this.translate.y = this.normalizeScaleTranslate(this.translate.y, center.y, scaleY, oldScaleY, yScale);
            this.scale.y = scaleY;
        }
        if (oldScaleX !== scaleX || oldScaleY !== scaleY) {
            if (listener && (floorToNearest(oldScaleX, listenerThreshold) !== floorToNearest(scaleX, listenerThreshold) ||
                floorToNearest(oldScaleY, listenerThreshold) !== floorToNearest(scaleY, listenerThreshold))) {
                listener({
                    scaleX: scaleX,
                    scaleY: scaleY,
                    dataContainers: this.dataContainers,
                });
            }
            this.postEvent(DataContainerEventType.DATA_CHANGE);
            return true;
        }
        return false;
    };
    DataContainerTransformExtension.prototype.onAttach = function (dataContainer) {
        dataContainer.addEventListener(DataContainerEventType.GET_SCALES, this.getScales);
    };
    DataContainerTransformExtension.prototype.onDetach = function (dataContainer) {
        dataContainer.removeEventListener(DataContainerEventType.GET_SCALES, this.getScales);
    };
    DataContainerTransformExtension.prototype.updateOptions = function (optionsInput) {
        var options = defaultsDeep({}, optionsInput, this.options || DEFAULT_OPTIONS);
        var scale = options.scale;
        var scaleGestureDetector = new ScaleGestureDetector({
            onScale: this.onScale,
            scroll: scale.scroll,
            multitouch: scale.multitouch,
        });
        var dragGestureDetector = new DragGestureDetector({
            onDrag: this.onDrag,
            drag: scale.drag && !scale.selectArea,
        });
        var areaSelectGestureDetector = new AreaSelectGestureDetector({
            onAreaSelect: this.onAreaSelect,
            selectArea: scale.selectArea,
        });
        return {
            options: options,
            scaleGestureDetector: scaleGestureDetector,
            dragGestureDetector: dragGestureDetector,
            areaSelectGestureDetector: areaSelectGestureDetector,
        };
    };
    DataContainerTransformExtension.prototype.xScale = function (scale) {
        var _a = scale.domain(), domainMin = _a[0], domainMax = _a[1];
        var _b = scale.range(), rangeMin = _b[0], rangeMax = _b[1];
        var rangeWidth = rangeMax - rangeMin;
        var domainWidth = domainMax - domainMin;
        var translateX = (this.translate.x) / domainWidth * rangeWidth;
        return scale.range([
            (rangeMin - translateX) * this.scale.x,
            (rangeMax - translateX) * this.scale.x,
        ]);
    };
    DataContainerTransformExtension.prototype.yScale = function (scale) {
        var _a = scale.domain(), domainMin = _a[0], domainMax = _a[1];
        var _b = scale.range(), rangeMin = _b[0], rangeMax = _b[1];
        var rangeWidth = rangeMax - rangeMin;
        var domainWidth = domainMax - domainMin;
        var translateY = (this.translate.y) / domainWidth * rangeWidth;
        return scale.range([
            (rangeMin - translateY) * this.scale.y,
            (rangeMax - translateY) * this.scale.y,
        ]);
    };
    DataContainerTransformExtension.prototype.calculateScale = function (start, end, scaleFunction) {
        var _a = scaleFunction.domain(), domainMin = _a[0], domainMax = _a[1];
        var domainWidth = domainMax - domainMin;
        var windowWidth = end - start;
        return domainWidth / windowWidth;
    };
    DataContainerTransformExtension.prototype.normalizeScaleTranslate = function (translate, center, scale, oldScale, scaleFunction) {
        var _a = scaleFunction.domain(), domainMin = _a[0], domainMax = _a[1];
        var domainWidth = domainMax - domainMin;
        var windowWidth = domainWidth / scale;
        var oldWindowWidth = domainWidth / oldScale;
        var oldTranslate = translate + domainMin;
        var percentage = (center - oldTranslate) / oldWindowWidth;
        var newTranslate = center - percentage * windowWidth;
        return Math.max(0, Math.min(domainWidth - windowWidth, newTranslate - domainMin));
    };
    DataContainerTransformExtension.prototype.isSufficientAreaSelected = function (area) {
        return area.width >= this.options.scale.minSelectedAreaThreshold.x
            && area.height >= this.options.scale.minSelectedAreaThreshold.y;
    };
    return DataContainerTransformExtension;
}(DataContainerExtension));
export { DataContainerTransformExtension };
