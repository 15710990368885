const BigDecimal = require('js-big-decimal');

export const fixedWithoutRounding = (value: number, decimals: number): string => {
  const bigDecimalValue = new BigDecimal(value);
  const bigDecimalMultiplier = new BigDecimal(Math.pow(10, decimals));

  return bigDecimalValue
    .multiply(bigDecimalMultiplier)
    .floor(decimals)
    .divide(bigDecimalMultiplier, decimals)
    .getValue();
};
