import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { push } from 'connected-react-router';

import { PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { LeadInvalidAddressModal } from '+lead/components';
import { LeadPageActions } from '+lead/store/+lead.actions';
import {
  getIsAddressValid,
  getLead,
  getValidateLeadAddressQueryStatus,
} from '+lead/store/+lead.selectors';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  openModalId: getOpenModalId(state),
  queryStatus: getValidateLeadAddressQueryStatus(state),
  isAddressValid: getIsAddressValid(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  clearAddressValidation: LeadPageActions.validateLeadAddressClear,
  editLeadAddress: LeadPageActions.editLeadAddress,
  goToLead: (leadId: string) => push(PATHS.LEAD_OVERVIEW({ leadId })),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LeadInvalidManualAddressModalComponent: React.FC<Props> = ({
  openModalId,
  isAddressValid,
  queryStatus,
  lead,
  actions,
}) => {
  const handleSubmit = () => {
    actions.clearAddressValidation();
  };

  const handleClose = () => {
    if (lead) {
      actions.goToLead(lead.id);
    }
    actions.editLeadAddress();
  };

  const handleToggle = (value: boolean) => {
    actions.clearAddressValidation();
    actions.toggleModal(value);
  };

  React.useEffect(() => {
    if (queryStatus.success && isAddressValid === false) {
      actions.toggleModal(true, ModalId.LEAD_INVALID_ADDRESS);
    }
  }, [queryStatus, isAddressValid]);

  React.useEffect(() => {
    return () => {
      actions.clearAddressValidation();
    };
  }, []);

  if (isAddressValid === undefined || !lead || !lead.deliveryAddress) {
    return null;
  }

  return (
    <LeadInvalidAddressModal
      isModalOpen={openModalId === ModalId.LEAD_INVALID_ADDRESS}
      isPending={false}
      toggleModal={handleToggle}
      address={lead.deliveryAddress}
      submitLabel={I18n.t(T.lead._salessolution_.invalidAdressModal.create)}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export const LeadInvalidManualAddressModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadInvalidManualAddressModalComponent);
