import { SiteLiveStateV2 } from '+shared/store/site/types/siteLiveStateV2.interface';
import { ActionsUnion, createAction } from '+utils/redux';

export enum HEAT_PUMP_ACTIONS {
  SET_SITE_LIVE_STATE_V2_HEATPUMP = '[Heatpump] SET_SITE_LIVE_STATE_V2_HEATPUMP',
  START_HEAT_PUMP_LIVE_STATE_POLLING = '[Heatpump] START_HEAT_PUMP_LIVE_STATE_POLLING',
  STOP_HEAT_PUMP_LIVE_STATE_POLLING = '[Heatpump] STOP_HEAT_PUMP_LIVE_STATE_POLLING',
}

export const HeatPumpActions = {
  startHeatPumpLiveStatePolling: createAction(
    HEAT_PUMP_ACTIONS.START_HEAT_PUMP_LIVE_STATE_POLLING,
    (siteId: string) => ({ siteId })
  ),
  stopHeatPumpLiveStatePolling: createAction(HEAT_PUMP_ACTIONS.STOP_HEAT_PUMP_LIVE_STATE_POLLING),
  setHeatpumpFromSiteLiveStateV2: createAction(
    HEAT_PUMP_ACTIONS.SET_SITE_LIVE_STATE_V2_HEATPUMP,
    (siteLiveStateV2: SiteLiveStateV2 | undefined) => ({ siteLiveStateV2 })
  ),
};

export type HeatPumpActions = ActionsUnion<typeof HeatPumpActions>;
