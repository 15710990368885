import { Query } from '../query/query.state';
import { ListItemNews, News, ReadNewsPP } from './types/news.interface';
import { Video } from './types/video.interface';

export const MARK_NEWS_AS_READ_QUERY = 'markNewsAsReadQuery';

export interface FirebaseState {
  newsList: ListItemNews[];
  readNewsList: ReadNewsPP[];
  notificationsList: Array<ListItemNews<News>>;
  videoList: Video[];
  isNewsListFetched: boolean;
  isReadNewsListFetched: boolean;
  isNotificationsListFetched: boolean;
  [MARK_NEWS_AS_READ_QUERY]: Query<any>;
}

export const initialState: FirebaseState = {
  newsList: [],
  readNewsList: [],
  notificationsList: [],
  videoList: [],
  isNewsListFetched: false,
  isReadNewsListFetched: false,
  isNotificationsListFetched: false,
  [MARK_NEWS_AS_READ_QUERY]: {},
};
