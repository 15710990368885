export var ContentType;
(function (ContentType) {
    ContentType["BINARY"] = "application/octet-stream";
    ContentType["JSON"] = "application/json";
    ContentType["MULTIPART_FORM"] = "multipart/form-data";
    ContentType["TEXT"] = "text/plain";
    ContentType["URL_ENCODED"] = "application/x-www-form-urlencoded";
    ContentType["VND_JSON"] = "application/vnd.api+json";
})(ContentType || (ContentType = {}));
export var ContentTypeRegex = {
    JSON: /^application\/(json|.+\+json)$/,
    MULTIPART: /^multipart\//,
    TEXT: /^text\//,
    URL_ENCODED: /^application\/x-www-form-urlencoded$/,
};
export function switchContentType(contentType, map, defaultResult) {
    for (var key in map) {
        if (map.hasOwnProperty(key)) {
            if (ContentTypeRegex[key].test(contentType)) {
                return map[key];
            }
        }
    }
    return defaultResult;
}
