import { Point, StatisticsResolution } from '@sonnen/shared-web';

import { XYPoint } from '@kanva/charts';
import { isNil } from 'lodash/fp';

import { HOUR_UNIX, HOURS_IN_DAY, MINUTE_UNIX } from '+app/App.constants';
import { insertIf } from '+utils/array.util';
import { dateUtil, TimeUnit } from '+utils/date.util';

import { BatteryStatuses } from '../battery/types/batteryStatuses.interface';
import { Address } from '../customer';
import {
  CellDataFields,
  CellDataName,
  MeasurementFields,
  MeasurementName,
  SiteChargeLimits,
  SiteChargeLimitsFilters,
  SiteMeasurementsFilters,
} from './types';
import { Resolution } from './types/resolution.interface';

export const getMeasurementsDefaultDateRange = () => ({
  start: dateUtil.getStartOf(dateUtil.now(), TimeUnit.DAY),
  end: dateUtil.getEndOf(dateUtil.now(), TimeUnit.DAY),
});

export const convertResolutionToUnixMultiplier = (resolution: Resolution): number => {
  switch (resolution) {
    case '1-minute':
      return 60;
    case '3-minutes':
      return 3 * 60;
    case '5-minutes':
      return 5 * 60;
    case '1-hour':
      return 60 * 60;
    case '3-hours':
      return 3 * 60 * 60;
    default:
      throw new Error('Unknown resolution');
  }
};

export const validateDateFilters = (
  start: Date | undefined,
  end: Date | undefined
): SiteChargeLimitsFilters | SiteMeasurementsFilters | undefined =>
  start && end ? { start, end } : undefined;

export const getMeasurementsPropertyIndex = (
  measurementName: MeasurementName,
  fields: MeasurementFields[]
): number | undefined => fields.find((field) => field.name === measurementName)?.index;

export const getCellDataPropertyIndex = (
  cellDataFieldName: CellDataName,
  fields: CellDataFields[]
): number | undefined => fields.find((field) => field.name === cellDataFieldName)?.index;

export const normalizeMeasurementsToTime = (
  measurementsValues: Record<string, number[]>,
  startDate: Date,
  endDate: Date,
  isEaton: boolean,
  fieldIndex?: number
): Point[] => {
  if (isNil(fieldIndex) || isNil(measurementsValues)) {
    return [];
  }

  return Object.entries(measurementsValues)
    .map(([timestamp, values]) => ({
      x: dateUtil.getUnixFromDateInSeconds(
        dateUtil.getStartOf(dateUtil.of(timestamp), TimeUnit.MINUTE)
      ),
      y: values[fieldIndex],
    }))
    .reduce<Point[]>((acc, measurementsPoint, index, measurementsArray) => {
      const startDateUnix = dateUtil.getUnixFromDateInSeconds(startDate);
      const firstMeasurementsPointAvailableIsNotStartDate = measurementsPoint.x !== startDateUnix;
      const differenceBetweenFistMeasurementsPointAvailableAndStartDate =
        measurementsPoint.x - startDateUnix;

      if (index === 0) {
        return [
          ...insertIf(firstMeasurementsPointAvailableIsNotStartDate, {
            x: measurementsPoint.x - differenceBetweenFistMeasurementsPointAvailableAndStartDate,
            y: null,
          }),
          measurementsPoint,
        ];
      }

      const differenceBetweenCurrentAndPreviousMeasurementsPoint =
        measurementsPoint.x - measurementsArray[index - 1].x;
      const resolution = getResolutionForTimeRange(new Date(startDate), new Date(endDate), isEaton);
      const resolutionWithMultiplier = convertResolutionToUnixMultiplier(resolution);

      if (
        !isEaton &&
        differenceBetweenCurrentAndPreviousMeasurementsPoint > resolutionWithMultiplier
      ) {
        return [
          ...acc,
          {
            x: measurementsArray[index - 1].x + resolutionWithMultiplier,
            y: null,
          },
          ...insertIf(
            differenceBetweenCurrentAndPreviousMeasurementsPoint > 2 * resolutionWithMultiplier,
            {
              x: measurementsPoint.x - resolutionWithMultiplier,
              y: null,
            }
          ),
          measurementsPoint,
        ];
      }

      // it's added for forecast to extend the chart to reach the end date
      // if it's removed then the chart ends with the last point available from forecast
      // and labels on X axis are not aligned

      if (
        dateUtil.isTodayOrAfter(dateUtil.of(startDate)) &&
        index === measurementsArray.length - 1
      ) {
        return [
          ...acc,
          {
            x: dateUtil.getUnixFromDateInSeconds(dateUtil.getStartOf(endDate, TimeUnit.MINUTE)),
            y: null,
          },
        ];
      }

      // For eaton batteries datagaps are shown which are bigger than 6 min.
      // Discuss if 4min are enough
      if (
        isEaton &&
        differenceBetweenCurrentAndPreviousMeasurementsPoint > 2 * resolutionWithMultiplier
      ) {
        return [
          ...acc,
          {
            x: measurementsArray[index - 1].x + resolutionWithMultiplier,
            y: null,
          },
          {
            x: measurementsPoint.x - resolutionWithMultiplier,
            y: null,
          },
          measurementsPoint,
        ];
      }

      return [...acc, measurementsPoint];
    }, []);
};

export const normalizeBatteryStatusesToTime = (
  batteryStatuses: BatteryStatuses[] | undefined,
  startDate: Date
): XYPoint[] =>
  !isNil(batteryStatuses)
    ? batteryStatuses
        .map((batteryStatus: BatteryStatuses) => ({
          x: dateUtil.getUnixFromDateInSeconds(
            dateUtil.getStartOf(dateUtil.of(batteryStatus.timestamp), TimeUnit.MINUTE)
          ),
          y: 1,
        }))
        .reduce<XYPoint[]>((acc, batteryStatusPoint, index, batteryStatusesArray) => {
          if (index === 0) {
            return [
              {
                x: dateUtil.getUnixFromDateInSeconds(startDate),
                y: 0,
              },
              ...insertIf(
                batteryStatusPoint.x - dateUtil.getUnixFromDateInSeconds(startDate) > 60,
                {
                  x: batteryStatusPoint.x - 60,
                  y: 0,
                }
              ),
              batteryStatusPoint,
            ];
          }

          const differenceBetweenCurrentAndPreviousBatteryStatusPoint =
            batteryStatusPoint.x - batteryStatusesArray[index - 1].x;

          if (differenceBetweenCurrentAndPreviousBatteryStatusPoint > 60) {
            return [
              ...acc,
              {
                x: batteryStatusesArray[index - 1].x + 60,
                y: 0,
              },
              ...insertIf(differenceBetweenCurrentAndPreviousBatteryStatusPoint > 120, {
                x: batteryStatusPoint.x - 60,
                y: 0,
              }),
              batteryStatusPoint,
            ];
          }

          return [...acc, batteryStatusPoint];
        }, [])
    : [];

export const normalizeCellDataToTime = (
  cellDataValues: Record<string, number[]>,
  startDate: Date,
  endDate: Date,
  minFieldIndex?: number,
  maxFieldIndex?: number
): Array<XYPoint<[number, number] | null>> => {
  if (isNil(minFieldIndex) || isNil(maxFieldIndex) || isNil(cellDataValues)) {
    return [];
  }

  return Object.entries(cellDataValues)
    .map(
      ([timestamp, values]) =>
        ({
          x: dateUtil.getUnixFromDateInSeconds(
            dateUtil.getStartOf(dateUtil.of(timestamp), TimeUnit.MINUTE)
          ),
          y: [values[minFieldIndex], values[maxFieldIndex]],
        } as XYPoint<[number, number]>)
    )
    .reduce<Array<XYPoint<[number, number] | null>>>((acc, cellDataPoint, index, cellDataArray) => {
      const startDateUnix = dateUtil.getUnixFromDateInSeconds(startDate);
      const firstCellDataPointAvailableIsNotStartDate = cellDataPoint.x !== startDateUnix;
      const differenceBetweenFistCellDataPointAvailableAndStartDate =
        cellDataPoint.x - startDateUnix;

      if (index === 0) {
        return [
          ...insertIf(firstCellDataPointAvailableIsNotStartDate, {
            x: cellDataPoint.x - differenceBetweenFistCellDataPointAvailableAndStartDate,
            y: null,
          }),
          cellDataPoint,
        ];
      }

      const differenceBetweenCurrentAndPreviousCellDataPoint =
        cellDataPoint.x - cellDataArray[index - 1].x;
      const resolution = getResolutionForTimeRange(startDate, endDate);
      const resolutionWithMultiplier = convertResolutionToUnixMultiplier(resolution);

      if (differenceBetweenCurrentAndPreviousCellDataPoint > resolutionWithMultiplier) {
        return [
          ...acc,
          {
            x: cellDataArray[index - 1].x + resolutionWithMultiplier,
            y: null,
          },
          ...insertIf(
            differenceBetweenCurrentAndPreviousCellDataPoint > 2 * resolutionWithMultiplier,
            {
              x: cellDataPoint.x - resolutionWithMultiplier,
              y: null,
            }
          ),
          cellDataPoint,
        ];
      }

      if (dateUtil.isTodayOrAfter(dateUtil.of(startDate)) && index === cellDataArray.length - 1) {
        return [
          ...acc,
          {
            x: dateUtil.getUnixFromDateInSeconds(dateUtil.getStartOf(endDate, TimeUnit.MINUTE)),
            y: null,
          },
        ];
      }

      return [...acc, cellDataPoint];
    }, []);
};

export const normalizeChargeLimitsToTime = (
  siteChargeLimits: SiteChargeLimits,
  startDate: Date
): XYPoint[] => {
  const wrapPointInFront = (point: XYPoint): XYPoint => ({
    x: point.x - MINUTE_UNIX,
    y: 0,
  });

  const wrapPointInBack = (point: XYPoint): XYPoint => ({
    x: point.x + HOUR_UNIX,
    y: 1,
  });

  return siteChargeLimits.chargeLimitsActiveAt
    .map((chargeLimitActiveAt) => ({
      x: dateUtil.getUnixFromDateInSeconds(dateUtil.of(chargeLimitActiveAt.replace('/PT1H', ''))),
      y: 1,
    }))
    .reduce<XYPoint[]>((acc, chargeLimitPoint, index, chargeLimitsArray) => {
      const startingPoint = {
        x: dateUtil.getUnixFromDateInSeconds(startDate),
        y: 0,
      };
      const differenceBetweenCurrentAndStartingPoint = chargeLimitPoint.x - startingPoint.x;
      const isSinglePointArray = chargeLimitsArray.length === 1;

      if (index === 0) {
        return [
          startingPoint,
          ...insertIf(
            differenceBetweenCurrentAndStartingPoint > HOUR_UNIX,
            wrapPointInFront(chargeLimitPoint)
          ),
          chargeLimitPoint,
          ...insertIf(isSinglePointArray, wrapPointInBack(chargeLimitPoint)),
        ];
      }

      const previousChargeLimitPoint = chargeLimitsArray[index - 1];
      const differenceBetweenCurrentAndPreviousPoint =
        chargeLimitPoint.x - previousChargeLimitPoint.x;
      const isLastPointIteration = index === chargeLimitsArray.length - 1;

      if (differenceBetweenCurrentAndPreviousPoint > HOUR_UNIX) {
        return [
          ...acc,
          wrapPointInBack(previousChargeLimitPoint),
          {
            x: previousChargeLimitPoint.x + HOUR_UNIX + MINUTE_UNIX,
            y: 0,
          },
          wrapPointInFront(chargeLimitPoint),
          chargeLimitPoint,
          ...insertIf(isLastPointIteration, wrapPointInBack(chargeLimitPoint)),
        ];
      }

      return [
        ...acc,
        chargeLimitPoint,
        ...insertIf(isLastPointIteration, wrapPointInBack(chargeLimitPoint)),
      ];
    }, []);
};

// NOTE: discuss if needed, if so apply in normalizeMeasurementsToTime in return
const removeLastNullElements = (measurements: XYPoint[]): XYPoint[] => {
  if (measurements.length && measurements[measurements.length - 1].y === null) {
    measurements.pop();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return removeLastNullElements(measurements);
  }
  return measurements;
};

export const defaultStatisticFilters = {
  start: dateUtil.getStartOf(dateUtil.now(), TimeUnit.YEAR),
  end: dateUtil.getEndOf(dateUtil.now(), TimeUnit.YEAR),
  resolution: StatisticsResolution.TOTAL,
};

export const isLiveDataDelayed = (latestLiveDate: string | undefined, delay: number = 60000) =>
  latestLiveDate && dateUtil.isDelayed(new Date(latestLiveDate), delay);

export const getSiteAddress = (address: Address): string | undefined => {
  const { street, postalCode, city } = address;
  if (street && postalCode && city) {
    return `${city}, ${street} ${postalCode}`;
  }
  return undefined;
};

export const getResolutionForTimeRange = (
  start: Date,
  end: Date,
  isEaton?: boolean
): Resolution => {
  const rangeInHours = dateUtil.getDifference(start, end, 'hours');

  const hoursInOneDay = 1 * HOURS_IN_DAY;
  const hoursInSevenDays = 7 * HOURS_IN_DAY;
  const hoursInThreeMonths = 3 * 31 * HOURS_IN_DAY;

  if (rangeInHours > hoursInOneDay && rangeInHours <= hoursInSevenDays) {
    return '5-minutes';
  }

  if (rangeInHours > hoursInSevenDays && rangeInHours <= hoursInThreeMonths) {
    return '1-hour';
  }

  if (rangeInHours > hoursInThreeMonths) {
    return '3-hours';
  }

  if (isEaton && rangeInHours <= hoursInOneDay) {
    return '3-minutes';
  }

  return '1-minute';
};
