import * as React from 'react';

import { EnergyFlowSeriesKey, SharedChartColors } from '@sonnen/shared-web';

import { AxisOrientation, DataContainer, GridLines } from '@kanva/charts';
import {
  AreaChartView as AreaChartViewComponent,
  AxisView,
  ChartGridView,
} from '@kanva/charts-react';
import { View, Visibility } from '@kanva/core';
import { View as ViewComponent } from '@kanva/react';

import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import { Views } from '../AnalysisDayChart/AnalysisDayChart.layout';
import { areaChartLayout } from './AnalysisAreaChart.layout';
import {
  areaChartGridStyle,
  areaChartStyles,
  areaChartXAxisStyle,
  areaChartYAxisLeftStyle,
  areaChartYAxisRightStyle,
} from './AnalysisAreaChart.styles';

interface AnalysisAreaChartProps {
  measurementsDataContainer: DataContainer<any>;
  percentageDataContainer: DataContainer<any>;
  selectedDataSeriesKeys: ChartDataSeriesKey[];
  isChartZoomed: boolean;
  handleMount: (view: View<any>) => void;
}

export const AnalysisAreaChart: React.FC<AnalysisAreaChartProps> = ({
  measurementsDataContainer,
  percentageDataContainer,
  selectedDataSeriesKeys,
  isChartZoomed,
  handleMount,
}) => {
  const isChartVisibile = (seriesKey: ChartDataSeriesKey): Visibility => {
    return selectedDataSeriesKeys.includes(seriesKey) ? Visibility.VISIBLE : Visibility.INVISIBLE;
  };

  const isYAxisRightVisible = (): Visibility => {
    return percentageDataContainer.getSeriesLength() !== 0
      ? Visibility.VISIBLE
      : Visibility.INVISIBLE;
  };

  const isSocFilterActivated = selectedDataSeriesKeys.includes(EnergyFlowSeriesKey.BATTERY_USOC);

  return (
    <>
      <ViewComponent id={Views.AREA_CHART_WRAPPER} layoutParams={areaChartLayout.areaChartWrapper}>
        <ChartGridView
          layoutParams={areaChartLayout.areaChartGrid}
          dataContainer={measurementsDataContainer}
          gridLines={GridLines.HORIZONTAL}
          style={areaChartGridStyle}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.PRODUCTION_POWER}
          visibility={isChartVisibile(EnergyFlowSeriesKey.PRODUCTION_POWER)}
          style={areaChartStyles.productionPower}
          onMount={handleMount}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.CONSUMPTION_POWER}
          visibility={isChartVisibile(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
          style={areaChartStyles.consumptionPower}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.DIRECT_USAGE_POWER}
          visibility={isChartVisibile(EnergyFlowSeriesKey.DIRECT_USAGE_POWER)}
          style={areaChartStyles.directUsagePower}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={percentageDataContainer}
          dataSeries={EnergyFlowSeriesKey.BATTERY_USOC}
          visibility={isChartVisibile(EnergyFlowSeriesKey.BATTERY_USOC)}
          style={areaChartStyles.batteryUsoc}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.BATTERY_CHARGING}
          visibility={isChartVisibile(EnergyFlowSeriesKey.BATTERY_CHARGING)}
          style={areaChartStyles.batteryCharging}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.BATTERY_DISCHARGING}
          visibility={isChartVisibile(EnergyFlowSeriesKey.BATTERY_DISCHARGING)}
          style={areaChartStyles.batteryDischarging}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER}
          visibility={isChartVisibile(EnergyFlowSeriesKey.PRODUCTION_POWER)}
          style={areaChartStyles[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]}
        />
        <AreaChartViewComponent
          layoutParams={areaChartLayout.areaChart}
          dataContainer={measurementsDataContainer}
          dataSeries={EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER}
          visibility={isChartVisibile(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
          style={areaChartStyles[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]}
        />
      </ViewComponent>

      <AxisView
        id={Views.AREA_CHART_X_AXIS}
        layoutParams={areaChartLayout.xAxis}
        dataContainer={measurementsDataContainer}
        orientation={AxisOrientation.HORIZONTAL}
        border={{ top: 2 }}
        borderColor={SharedChartColors.Axis}
        style={{ ...areaChartXAxisStyle, wrapLabelsOnEdge: !isChartZoomed }}
      />
      <AxisView
        id={Views.AREA_CHART_Y_AXIS_LEFT}
        layoutParams={areaChartLayout.yAxisLeft}
        dataContainer={measurementsDataContainer}
        orientation={AxisOrientation.VERTICAL}
        style={areaChartYAxisLeftStyle}
      />
      {isSocFilterActivated && (
        <AxisView
          id={Views.AREA_CHART_Y_AXIS_RIGHT}
          layoutParams={areaChartLayout.yAxisRight}
          dataContainer={percentageDataContainer}
          orientation={AxisOrientation.VERTICAL}
          style={areaChartYAxisRightStyle}
          visibility={isYAxisRightVisible()}
        />
      )}
    </>
  );
};
