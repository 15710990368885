export var precision = function (a) {
    if (!isFinite(a)) {
        return 0;
    }
    var e = 1;
    var p = 0;
    while (Math.round(a * e) / e !== a) {
        e *= 10;
        p++;
    }
    return p;
};
export var roundToNearest = function (value, nearest) {
    return Math.round(value / nearest) * nearest;
};
export var floorToNearest = function (value, nearest) {
    return Math.floor(value / nearest) * nearest;
};
