import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, TooltipConfirmation } from '@sonnen/shared-web';

import { LeadConfigurationTileStatus } from '+lead/components';
import { Button, ButtonType } from '+shared/components/Button';

import './LeadFlatConfigurationExpiredTile.component.scss';

interface Props {
  status: LeadConfigurationTileStatus;
  isHardwareProductAccepted: boolean;
  isOfferBlocked: boolean;
  onRecalculation: () => void;
  onDelete: () => void;
}

export const LeadFlatConfigurationExpiredTile: React.FC<Props> = ({
  onRecalculation,
  onDelete,
  status,
  isHardwareProductAccepted,
  isOfferBlocked,
}) => {
  const [isPopupConfirmationOpen, setPopupConfirmationOpen] = React.useState<boolean>(false);
  const isExpired = status === LeadConfigurationTileStatus.EXPIRED;

  return (
    <div className={'c-lead-flat-configuration-tile__recalculation-wrapper'}>
      {isExpired && isHardwareProductAccepted && (
        <Button
          onClick={() => setPopupConfirmationOpen(true)}
          type={ButtonType.TERTIARY}
          label={
            <div className={'c-lead-flat-configuration-tile__recalculation-wrapper-label'}>
              <div className={'c-lead-flat-configuration-tile__recalculation-icon-blue'}>
                <Icon.Trash />
              </div>
              <span>{I18n.t(T.lead.configuration._salessolution_.preview.deleteOffer)}</span>
            </div>
          }
        />
      )}

      {onRecalculation && isExpired && !isOfferBlocked && (
        <Button
          onClick={onRecalculation}
          type={ButtonType.SECONDARY}
          label={
            <div className={'c-lead-flat-configuration-tile__recalculation-wrapper-label'}>
              <div className={'c-lead-flat-configuration-tile__recalculation-icon-black'}>
                <Icon.ReCalculate viewBox={{ w: 16 }} />
              </div>
              <span>{I18n.t(T.lead.configuration._salessolution_.preview.recalculationCTA)}</span>
            </div>
          }
        />
      )}

      {
        <TooltipConfirmation
          isOpen={isPopupConfirmationOpen}
          closeAction={() => setPopupConfirmationOpen(false)}
          confirmAction={onDelete}
          confirmationText={I18n.t(
            T.lead.configuration._salessolution_.actions.confirmPopup.message
          )}
          confirmBtnText={I18n.t(T.lead.configuration._salessolution_.actions.confirmPopup.yes)}
          cancelBtnText={I18n.t(T.lead.configuration._salessolution_.actions.confirmPopup.no)}
          isCentered={true}
        />
      }
    </div>
  );
};
