import mapValues from 'lodash/mapValues';
export var isData = function (data) {
    return !!data.attributes;
};
export var mergeElementData = function (data) {
    return Object.assign({
        id: data.id,
        type: data.type,
        self: data.links && data.links.self,
    }, data.relationships && mapValues(data.relationships, function (rel) {
        if (!rel.data) {
            return undefined;
        }
        if (!Array.isArray(rel.data)) {
            return isData(rel.data) ? mergeElementData(rel.data) : rel.data.id;
        }
        return rel.data.some(isData)
            ? rel.data.map(mergeElementData)
            : rel.data.map(function (data) { return data.id; });
    }), data.attributes);
};
export var findIncludedRelationship = function (relationship, included) { return included.find(function (element) { return element.id === relationship.id && (element.type === relationship.type ||
    element.type === relationship.type.replace(/-/g, '_')); }); };
export function resolveRelationships(data, included) {
    if (!data) {
        return data;
    }
    if (Array.isArray(data)) {
        return data.map(function (d) { return resolveRelationships(d, included); });
    }
    if (isData(data)) {
        var relationships = data.relationships;
        if (relationships) {
            for (var key in relationships) {
                if (relationships.hasOwnProperty(key)) {
                    var relationship_1 = relationships[key];
                    if (relationship_1) {
                        relationship_1.data = resolveRelationships(relationship_1.data, included);
                    }
                }
            }
        }
        return data;
    }
    var relationship = findIncludedRelationship(data, included);
    return relationship ? resolveRelationships(relationship, included) : data;
}
export var includedGroup = function (type) { return ({
    type: type,
}); };
