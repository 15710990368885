import * as React from 'react';

import { Bubble, ClickOutside } from '@sonnen/shared-web';

import classNames from 'classnames';

import './TooltipClickable.component.scss';

interface Props {
  className?: ClassValue;
  trigger: React.ReactNode;
}

export const TooltipClickable: React.FC<Props> = ({ children, trigger, className }) => {
  const [isActive, setIsActive] = React.useState(false);

  const onClickOutside = () => setIsActive(false);
  const onTriggerClick = () => setIsActive(!isActive);

  return (
    <ClickOutside onClick={onClickOutside}>
      <span className={classNames('tooltip-clickable', className)}>
        <span className={'tooltip-clickable__trigger'} onClick={onTriggerClick}>
          {trigger}
        </span>
        {isActive && (
          <div className={'tooltip-clickable__bubble'}>
            <Bubble side={'top-left'}>{children}</Bubble>
          </div>
        )}
      </span>
    </ClickOutside>
  );
};
