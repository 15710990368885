import { BatteryStatusesKey } from '@sonnen/shared-web';

import { createLayoutMap, MATCH_PARENT, PARENT_ID } from '@kanva/core';

import { ChartVisibleElements } from '../../store/types/chartVisibleElements.interface';
import { Dimensions, Views } from '../AnalysisDayChart/AnalysisDayChart.layout';

export const createLineChartLayout = (chartVisibleElements: ChartVisibleElements) => {
  const visibleLineCharts = chartVisibleElements.filter(
    (element) =>
      element === BatteryStatusesKey.CHARGE_LIMIT ||
      element === BatteryStatusesKey.VPP_ACTIVITY ||
      element === BatteryStatusesKey.CELL_CARE
  );

  const isChargeLimitLineChartVisible = chartVisibleElements.includes(
    BatteryStatusesKey.CHARGE_LIMIT
  );
  const isVppActivityLineChartVisible = chartVisibleElements.includes(
    BatteryStatusesKey.VPP_ACTIVITY
  );

  return createLayoutMap({
    lineChartWrapper: {
      width: MATCH_PARENT,
      height: Dimensions.LINE_CHART_HEIGHT * visibleLineCharts.length,
      alignStart: PARENT_ID,
      alignEnd: PARENT_ID,
      below: Views.AREA_CHART_X_AXIS,
    },
    chargeLimitLineChart: {
      width: MATCH_PARENT,
      height: Dimensions.LINE_CHART_HEIGHT,
    },
    vppActivityLineChart: {
      width: MATCH_PARENT,
      height: Dimensions.LINE_CHART_HEIGHT,
      below: isChargeLimitLineChartVisible ? Views.CHARGE_LIMIT_LINE : undefined,
    },
    cellCareLineChart: {
      width: MATCH_PARENT,
      height: Dimensions.LINE_CHART_HEIGHT,
      below: isVppActivityLineChartVisible
        ? Views.VPP_ACTIVITY_LINE
        : isChargeLimitLineChartVisible
        ? Views.CHARGE_LIMIT_LINE
        : undefined,
    },
    lineChartLabel: {
      centerVertical: true,
      padding: {
        top: 35,
      },
    },
  });
};
