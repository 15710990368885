import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, useLocation } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, Loadable } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash/fp';

import { LeadOfferPageActions } from '+app/+lead/+offer/store/+offer.actions';
import { getFlatProduct } from '+app/+lead/+offer/store/+offer.helper';
import {
  getGetLeadOfferQueryStatus,
  getLeadOfferData,
} from '+app/+lead/+offer/store/+offer.selectors';
import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import { isDsoBlackListed } from '+app/+lead/+overview/store/+overview.helper';
import {
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityStatus,
  getOfferListQueryStatus,
} from '+app/+lead/+overview/store/+overview.selectors';
import { useSetupLeadContext } from '+app/+lead/containers/SetupLead/SetupLead.context';
import { isLeadSonnenNowInterested } from '+app/+lead/store/+lead.helper';
import { getLead, getLeadQueryStatus } from '+app/+lead/store/+lead.selectors';
import { NotFound } from '+app/+static';
import { PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { Container, FormHeader } from '+shared/components';
import { LayoutBackground, LayoutContext } from '+shared/containers/Layout';
import { Lead as LeadType } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';

import { ConfigurationPageActions } from '../../store';
import { getConfigurationProposal } from '../../store/+configuration.selectors';
import { LeadConfigurationForm, LeadConfigurationSummary } from '..';

import './LeadConfigurationNew.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  leadQueryStatus: getLeadQueryStatus(state),
  configurationProposal: getConfigurationProposal(state),
  productAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityQueryStatus: getLeadOverviewProductAvailabilityStatus(state),
  offerListQueryStatus: getOfferListQueryStatus(state),
  getGetLeadOfferQueryStatus: getGetLeadOfferQueryStatus(state),
  offer: getLeadOfferData(state),
});

const mapDispatchToProps = mapActions({
  goToLeadConfigurations: (leadId: string, leadStage?: string) =>
    push(PATHS.LEAD_CONFIGURATION({ leadId }, leadStage)),
  removeConfiguration: ConfigurationPageActions.removeConfiguration,
  clearConfigurationData: ConfigurationPageActions.clearData,
  getProductAvailability: LeadOverviewPageActions.getProductAvailability,
  clearOfferData: LeadOfferPageActions.clearData,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LeadConfigurationNewComponent: React.FC<Props> = ({
  actions,
  lead,
  leadQueryStatus,
  configurationProposal,
  productAvailability,
  productAvailabilityQueryStatus,
  getGetLeadOfferQueryStatus,
  offer,
  offerListQueryStatus,
}) => {
  const { useTrackPageLoadDependingOnRoute } = useAdobeAnalyticsTracking();
  const { pathname } = useLocation();
  const isFlatDirect = pathname.includes('new-flat-direct');
  const pageName = isFlatDirect ? 'SFD_SALES_CONFIGURATOR' : 'SFX_SALES_CONFIGURATOR';
  useTrackPageLoadDependingOnRoute(pageName);

  const layout = React.useContext(LayoutContext);
  const { leadStage } = useSetupLeadContext();

  React.useEffect(() => {
    layout.setBackground(LayoutBackground.WHITE);

    return () => {
      actions.clearConfigurationData();
      // this one has to be cleared after we entered configurator with prefilled hw offer data
      actions.clearOfferData();
      layout.resetBackground();
    };
  }, []);

  React.useEffect(() => {
    if (lead && lead.dso && lead.deliveryAddress && isEmpty(productAvailability)) {
      actions.getProductAvailability();
    }
  }, [leadQueryStatus]);

  const goToOverview = () => {
    if (configurationProposal) {
      actions.removeConfiguration(configurationProposal.id);
    }
    if (lead) {
      actions.goToLeadConfigurations(lead.id, leadStage);
    }
  };

  const renderContent = () => (
    <div className={'o-grid c-lead-configuration-new'}>
      <div className={'o-grid__column o-grid__column--md-8 o-grid__column--no-gap'}>
        <LeadConfigurationForm />
      </div>
      <div className={'o-grid__column o-grid__column--md-4 o-grid__column--no-gap'}>
        <LeadConfigurationSummary />
      </div>
    </div>
  );

  const isLeadConstraint = () =>
    lead && (isLeadSonnenNowInterested(lead) || isDsoBlackListed(productAvailability, lead.dso));
  const isOfferConstraint = () => offer && getFlatProduct(offer);

  const getRouteParams = (lead: LeadType | undefined) => ({
    leadId: lead?.id ?? '',
  });
  const routeParams = getRouteParams(lead);
  const featureFlags = useFlags();

  return (
    <>
      {!featureFlags.newFlatDirect && <Redirect to={PATHS.LEAD_CONFIGURATION_NEW(routeParams)} />}
      <div className={'c-lead-configuration-new__header-wrapper'}>
        <FormHeader
          onClick={goToOverview}
          breadcrumb={I18n.t(T.lead.offer._salessolution_.buttonBack)}
          title={
            lead
              ? I18n.t(T.lead.configuration._salessolution_.headline, {
                  firstName: lead!.firstName,
                  lastName: lead!.lastName,
                })
              : ''
          }
          isLoading={leadQueryStatus.pending}
          suppressTitleInHotjar={true}
        />
      </div>
      <Loadable
        predicate={
          leadQueryStatus.pending ||
          productAvailabilityQueryStatus.pending ||
          getGetLeadOfferQueryStatus.pending ||
          offerListQueryStatus.pending
        }
      >
        {/*
          isOfferConstraint - we block the access to configurator to offers,
          which have flat product already
        */}
        {isLeadConstraint() || isOfferConstraint() ? (
          <NotFound isFullScreen={false} />
        ) : (
          <Media query={{ minWidth: breakpointUp('MD') }}>
            {(isDesktopMedia: boolean) =>
              isDesktopMedia ? (
                <Container
                  withHorizontalPadding={true}
                  withVerticalPadding={true}
                  className={'c-lead-configuration-new__container'}
                >
                  {renderContent()}
                </Container>
              ) : (
                renderContent()
              )
            }
          </Media>
        )}
      </Loadable>
    </>
  );
};

export const LeadConfigurationNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadConfigurationNewComponent);
