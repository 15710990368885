import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader, Modal, WarningText } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';

import { getNewHardwareStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import { getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { LeadEditHardwareStatus } from '+app/+lead/store/types';
import { FormErrorBanner, WidgetSimple } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import ModalLayout from '+shared/components/ModalLayout/ModalLayout';
import usePrevious from '+shared/hooks/usePrevious';
import { findBatteryInBatteryList } from '+shared/store/battery/battery.helpers';
import { getOpenModalId, LayoutActions, ModalId } from '+shared/store/layout';
import {
  batteryModelNameMap,
  batteryParametersToLabelMapper,
} from '+shared/store/lead/lead.helpers';
import { LeadOffer, OfferProductStatus } from '+shared/store/lead/types';
import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { getUserCompanyName } from '+shared/store/user/user.selectors';
import { useLocaleContext } from '+utils/react/locale.provider';
import { mapActions } from '+utils/redux';

import {
  findStatusToUpdate,
  formFields,
  mapNewHardwareStatusToInitialValues,
  mapOfferProductStatusToInitialValues,
  onHwConfirmedValueChange,
  onHwSentValueChange,
} from './LeadHardwareStatusModal.helper';

import './LeadHardwareStatusModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userCompanyName: getUserCompanyName(state),
  newHardwareStatus: getNewHardwareStatus(state),
  openModalId: getOpenModalId(state),
  lead: getLead(state),
  productBatteryList: getProductBatteryList(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
});

interface ComponentProps {
  modalId:
    | ModalId.HARDWARE_STATUS_UPDATE_NEW_OFFER
    | ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW
    | ModalId.HARDWARE_STATUS_UPDATE_OFFER_FORM;
  submitAction: (status: OfferProductStatus) => void;
  queryStatus: ReturnType<typeof getStatus>;
  offerRef?: React.MutableRefObject<LeadOffer | undefined>;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

export const checkboxTextGenerator = (label: string, owner: string, bold: boolean = false) => (
  <div>
    <p className={'c-lead-hardware-status-modal__status-name'}>{label}</p>
    <p
      data-hj-suppress={true}
      className={classNames('c-lead-hardware-status-modal__status-owner', {
        'c-lead-hardware-status-modal__status-owner--bold': bold,
      })}
    >
      {owner}
    </p>
  </div>
);

const LeadHardwareStatusModalComponent: React.FC<Props> = ({
  actions,
  modalId,
  openModalId,
  lead,
  offerRef,
  userCompanyName,
  newHardwareStatus,
  queryStatus,
  productBatteryList,
  submitAction,
}) => {
  const previousQueryStatus = usePrevious(queryStatus);
  const { locale } = useLocaleContext();

  React.useEffect(() => {
    return () => {
      actions.toggleModal(false);
    };
  }, []);

  React.useEffect(() => {
    const hasQueryStatusChangedToSuccess = previousQueryStatus.pending && queryStatus.success;

    if (hasQueryStatusChangedToSuccess) {
      actions.toggleModal(false);
    }
  }, [previousQueryStatus, queryStatus]);

  const initialValues = offerRef
    ? mapOfferProductStatusToInitialValues(offerRef.current)
    : mapNewHardwareStatusToInitialValues(newHardwareStatus);
  const battery = offerRef?.current
    ? findBatteryInBatteryList(offerRef?.current, productBatteryList)
    : undefined;

  const onSubmit = (values: LeadEditHardwareStatus) => {
    const statusToUpdate = findStatusToUpdate(initialValues, values);
    if (statusToUpdate) {
      submitAction(statusToUpdate);
    }
  };

  const renderStatusModalFooter = (form: FormikProps<LeadEditHardwareStatus>) => (
    <div className={'c-lead-hardware-status-modal__footer'}>
      <div className={'c-lead-hardware-status-modal__buttons-wrapper'}>
        {modalId === ModalId.HARDWARE_STATUS_UPDATE_NEW_OFFER && (
          <Button
            className={classNames('c-lead-hardware-status-modal__footer-button', {
              'c-lead-hardware-status-modal__footer-button--disabled': queryStatus.pending,
            })}
            label={I18n.t(T.lead.hardware._salessolution_.statusModal.button.updateLater)}
            type={ButtonType.TERTIARY}
            onClick={() => actions.toggleModal(false)}
            dataTestId="update-later-btn"
          />
        )}
        <div>
          <Button
            label={I18n.t(T.general.basicActions.save)}
            size={ButtonSize.MEDIUM}
            mainType={MainType.SUBMIT}
            disabled={!form.dirty || queryStatus.pending || queryStatus.error}
            status={
              !form.dirty || queryStatus.pending || queryStatus.error
                ? ButtonStatus.DISABLED
                : ButtonStatus.ENABLED
            }
            dataTestId="update-hardware-save-btn"
          />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={openModalId === modalId}
      onClose={() => actions.toggleModal(false)}
      className={'c-lead-hardware-status-modal__wrapper'}
      dataTestId="update-hardware-status-modal"
    >
      {queryStatus.pending && <Loader className={'c-lead-hardware-status-modal__loader'} />}
      <div className={'c-lead-hardware-status-modal'} data-hj-suppress={true}>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={onSubmit}
          render={(form) => (
            <Form className={'c-lead-hardware-status-modal-form'}>
              <ModalLayout
                title={
                  lead &&
                  I18n.t(T.lead.hardware._salessolution_.statusModal.title, {
                    firstName: lead.firstName,
                    lastName: lead.lastName,
                  })
                }
                subtitle={I18n.t(T.lead.hardware._salessolution_.statusModal.subtitle)}
                footer={renderStatusModalFooter(form)}
                dataTestIdTitle="update-and-move-to-setup-title"
              >
                <div className={'c-lead-hardware-status-modal__input'}>
                  <div className={'c-lead-hardware-status-modal__widget-wrapper'}>
                    <WidgetSimple
                      heading={I18n.t(T.lead.list._salessolution_.editStatusModal.HWOffer)}
                      grayedHeading={
                        battery
                          ? `: ${batteryModelNameMap(
                              battery.modelName
                            )} - ${batteryParametersToLabelMapper(locale)(battery.parameters)}`
                          : ''
                      }
                      dataTestIdHeader="hardware-offer-header"
                      dataTestIdContent="hardware-offer-content"
                    >
                      <FormFieldObserver<LeadEditHardwareStatus>
                        onChange={onHwSentValueChange(form)}
                      >
                        <FormControlledCheckbox
                          form={form}
                          label={checkboxTextGenerator(
                            I18n.t(
                              T.lead.list._salessolution_.editStatusModal.hwStatuses.hwOfferSent
                            ),
                            userCompanyName,
                            true
                          )}
                          name={formFields.HARDWARE_OFFER_SENT}
                          isConnected={true}
                          isConnectionActive={
                            form.values[formFields.HARDWARE_ORDER_CONFIRMED] &&
                            !initialValues[formFields.HARDWARE_OFFER_SENT]
                          }
                          disabled={initialValues[formFields.HARDWARE_OFFER_SENT]}
                          dataTestId="hardware-offer-sent-checkbox"
                        />
                      </FormFieldObserver>

                      <FormFieldObserver<LeadEditHardwareStatus>
                        onChange={onHwConfirmedValueChange(form)}
                      >
                        <FormControlledCheckbox
                          form={form}
                          label={checkboxTextGenerator(
                            I18n.t(
                              T.lead.list._salessolution_.editStatusModal.hwStatuses.hwConfirmed
                            ),
                            userCompanyName,
                            true
                          )}
                          name={formFields.HARDWARE_ORDER_CONFIRMED}
                          disabled={initialValues[formFields.HARDWARE_ORDER_CONFIRMED]}
                          dataTestId="hardware-order-confirmed-checkbox"
                        />
                      </FormFieldObserver>
                    </WidgetSimple>
                  </div>

                  {form.values[formFields.HARDWARE_ORDER_CONFIRMED] ? (
                    <WarningText
                      text={I18n.t(T.lead.hardware._salessolution_.statusModal.warning.hwConfirmed)}
                    />
                  ) : null}
                </div>
                <FormErrorBanner
                  isVisible={queryStatus.error}
                  error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
                />
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

export const LeadHardwareStatusModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadHardwareStatusModalComponent);
