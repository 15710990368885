export enum LeadCountry {
  AU = 'AU',
  DE = 'DE',
  GB = 'GB',
  IT = 'IT',
  US = 'US',
  AT = 'AT',
  CH = 'CH',
}

export enum LeadAddressKind {
  REVIEWED = 'reviewed',
}
export interface LeadAddress {
  street: string;
  houseNumber?: string;
  zipCode: string;
  city: string;
  country: LeadCountry;
  kind?: LeadAddressKind;
}

export interface LeadLocation {
  city: string;
  zipCode: string;
}

export interface LeadDetailedAddress {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: LeadCountry;
  kind?: LeadAddressKind;
}
