import { Data, MergedData, Relationship } from '@coolio/json-api';
import { PersistedState } from 'redux-persist';
import { CountryCode } from 'src/types/country.type';

import { Query } from '../query';
import { UserCertificate } from './types/userCertificate.interface';

export const GET_USER_QUERY = 'getUserQuery';
export const GET_USER_PROFILE_QUERY = 'getUserProfileQuery';
export const GET_COMPANY_QUERY = 'getCompanyQuery';
export const GET_USER_PASSWORD_QUERY = 'getUserPasswordQuery';
export const GET_LOGO_QUERY = 'getLogoQuery';

export enum UserRole {
  COMPANY_COORDINATOR = 'company_coordinator',
  SALES_AGENT = 'sales_agent',
  SETUP_COORDINATOR = 'setup_coordinator',
  SERVICE_COORDINATOR = 'service_coordinator',
  SUPER_USER = 'superuser',
}

export type UserAttributes = {
  id: string;
  roles: UserRole[];
  customerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
  mobile: string;
  phone: string;
  companyName: string;
  accountBillingCountryCode: CountryCode;
  language?: string;
  timeZone?: string;
  salesforceUserId?: string;
  salesforceContactId: string;
  image?: string;
  certificates: UserCertificate[] | [];
  certificationId: string;
};

export type UserRelations = {
  defaultSite?: Relationship;
};

export type UserData = Data<UserAttributes, UserRelations>;
export type User = MergedData<UserData>;

export type UserProfile = {
  profile: UserAttributes;
  type: string;
};

export type CompanyAttributes = {
  id: string;
  name: string;
  logotypeUrl: string;
  termsAndConditionsUrl: string;
  dataPrivacyPolicyUrl: string;
  cancellationPolicyUrl: string;
};

export type CompanyData = Data<CompanyAttributes>;
export type Company = MergedData<CompanyData>;

export type UserProfileExtendedAttributes = {
  image: string;
};

export type UserProfileExtendedData = Data<UserProfileExtendedAttributes>;
export type UserProfileExtended = MergedData<UserProfileExtendedData>;

export interface UserState extends PersistedState {
  profile?: User;
  company?: Company;
  certificates: UserCertificate[] | [];
  getUserQuery: Query<User>;
  getCompanyQuery: Query<User>;
  getUserPasswordQuery: Query<any>;
  getLogoQuery: Query<any>;
  getUserProfileQuery: Query<any>;
}

export const initialState: UserState = {
  profile: undefined,
  company: undefined,
  certificates: [],
  getUserQuery: {},
  getCompanyQuery: {},
  getUserPasswordQuery: {},
  getLogoQuery: {},
  getUserProfileQuery: {},
};
