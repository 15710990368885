import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Modal, ModalLayout } from '@sonnen/shared-web';

import { Button, ButtonSize, ButtonType, MainType } from '+shared/components/Button';

import './LeadOverviewEmailChangedModal.component.scss';

interface Props {
  isModalOpen: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  email: string;
}

export const LeadOverviewEmailChangedModal: React.FC<Props> = ({
  isModalOpen,
  handleConfirm,
  handleClose,
  email,
}) => (
  <Modal
    size={'medium'}
    isOpen={isModalOpen}
    onClose={handleClose}
    className={'c-lead-overview-email-changed-modal'}
  >
    <ModalLayout
      footer={
        <div className={'c-lead-overview-email-changed-modal__buttons'}>
          <div className={'c-lead-overview-email-changed-modal__buttons-wrapper'}>
            <Button
              className={'c-lead-overview-email-changed-modal__link-button'}
              onClick={handleClose}
              label={I18n.t(T.lead.overview._salessolution_.emailChangedModal.sendLater)}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
            />
            <Button
              label={I18n.t(T.lead.overview._salessolution_.emailChangedModal.confirm)}
              size={ButtonSize.LARGE}
              mainType={MainType.SUBMIT}
              onClick={() => handleConfirm()}
            />
          </div>
        </div>
      }
    >
      <div className={'c-lead-overview-email-changed-modal__inner'}>
        <div>
          <Icon.PaperPlane className={'c-lead-overview-email-changed-modal__icon-plane'} />
        </div>
        <p className={'c-lead-overview-email-changed-modal__heading'}>
          {I18n.t(T.lead.overview._salessolution_.emailChangedModal.heading)}
        </p>
        <div className={'c-lead-overview-email-changed-modal__content'}>
          <p className={'c-lead-overview-email-changed-modal__paragraph'}>
            {I18n.t(T.lead.overview._salessolution_.emailChangedModal.content)}
          </p>
        </div>
        <div className={'c-lead-overview-email-changed-modal__email'}>
          <Icon.Envelope className={'c-lead-overview-email-changed-modal__icon-envelope'} />
          {email}
        </div>
      </div>
    </ModalLayout>
  </Modal>
);
