import { Locale } from '+app/i18n/i18n.config';

export interface Document {
  documentType: DocumentType;
  language: Locale;
  country: string;
}

export enum DocumentType {
  TERMS_AND_CONDITIONS = 'TnC Customer Portal',
  PRIVACY_POLICY = 'Privacy Policy',
}
