import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isEmpty } from 'lodash';

import { Contact } from '+shared/store/contact/types';
import { RoleNames } from '+shared/store/role/types/role.interface';
import { UserRole } from '+shared/store/user';

const mapRoleName = (roleName: string) => {
  switch (roleName) {
    case UserRole.COMPANY_COORDINATOR:
      return T.lead.list._salessolution_.assignModal.companyCoordinator;
    case UserRole.SALES_AGENT:
      return T.lead.list._salessolution_.assignModal.salesAgent;
    case UserRole.SERVICE_COORDINATOR:
      return T.lead.list._salessolution_.assignModal.serviceCoordinator;
    case UserRole.SETUP_COORDINATOR:
      return T.lead.list._salessolution_.assignModal.setupCoordinator;
    default:
      return '';
  }
};

export const mapRoles = (partner: Contact) =>
  partner.roles.reduce(
    (acc, curr) =>
      `${acc}${!isEmpty(acc) && Object.values(RoleNames).includes(curr.name) ? ',' : ''} ${I18n.t(
        mapRoleName(curr.name)
      )}`,
    ''
  );
