import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loadable, useCountryFeature, useFeature } from '@sonnen/shared-web';

import { getLead } from '+app/+lead/store/+lead.selectors';
import { getVppState, VppState } from '+app/+setupTool/+vppDocumentation/store';
import { StatusName } from '+app/+setupTool/store/+setupTool.dictionary';
import { SetupToolStep } from '+app/+setupTool/store/types';
import { mapActions } from '+app/utils';
import { CountryFeatureName, FeatureName } from '+config/index';
import { TestId } from '+config/testIds';
import { PreCommissioningActions } from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import { ErrorBox, SetupToolInfo, SetupToolTable } from '+setupTool/components';
import {
  SetupToolRegistrationSelect,
  SetupToolRegistrationSubjectConfirmationModal,
} from '+setupTool/containers';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import {
  getDashboardStepList,
  getErrors,
  getSubmissionId,
} from '+setupTool/store/+setupTool.selectors';
import { Container, PageBlock, RolloutLimiter } from '+shared/components';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { Tutorial } from '+shared/containers/Tutorial';
import { StoreState } from '+shared/store/store.interface';
import { isStatusSet } from '+utils/status.util';

import './SetupToolDashboard.component.scss';

const {
  dashboard: {
    registrationTools,
    preCommissioningTools,
    preCommissioningToolsDescription,
    registrationToolsDescription,
    sonnenFlatTools,
    sonnenFlatToolsDescription,
  },
  infoBox: { leadSectionFunctionalities, leadSectionFunctionalitiesNonSetup },
} = T.setupTool;

const mapStateToProps = (state: StoreState) => ({
  stepList: getDashboardStepList(state),
  submissionId: getSubmissionId(state),
  errors: getErrors(state),
  lead: getLead(state),
  vppState: getVppState(state),
});

const mapDispatchToProps = mapActions({
  getSetupToolSubmissionData: SetupToolActions.getSetupToolSubmissionData,
  getPreCommissioningData: PreCommissioningActions.getPreCommissioningData,
  clearErrors: SetupToolActions.clearErrors,
  clearPreCommissioningGenerationQueryStatus:
    PreCommissioningActions.clearPreCommissioningGenerationQueryStatus,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const SetupToolDashboardComponent: React.FC<Props> = ({
  stepList,
  submissionId,
  errors,
  actions,
  lead,
  vppState,
}) => {
  const { isEnabled: isRolloutEnabled } = useFeature(FeatureName.ROLLOUT);
  const { isEnabled: isSetupPhaseEnabled, isDisabled: isSetupPhaseDisabled } = useCountryFeature(
    CountryFeatureName.SETUP_PHASE
  );

  const isLoading = isRolloutEnabled && isSetupPhaseDisabled ? false : !submissionId;
  const isLeadInSetup = isStatusSet(lead?.status.summary.inSetup);

  useEffect(() => {
    if (!lead) return;

    const isLeadAllowedToDoPrecomissioningInLeadPhase =
      !isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE) &&
      isStatusSet(lead?.status.summary.hardwareOrderConfirmed);

    if (isLeadInSetup || isLeadAllowedToDoPrecomissioningInLeadPhase) {
      actions.getPreCommissioningData(lead.id);
    }

    if (isRolloutEnabled) {
      if (isSetupPhaseEnabled) {
        actions.getSetupToolSubmissionData(lead.id);
      }
    } else {
      actions.getSetupToolSubmissionData(lead.id);
    }

    return () => {
      actions.clearErrors();
      actions.clearPreCommissioningGenerationQueryStatus();
    };
  }, []);

  const getProcessedVppConfig = (): SetupToolStep => {
    const vppConfig: SetupToolStep = stepList.vppConfig!; // @todo fix undefined

    if (vppState === VppState.ALLOWED_TO_PERFORM_AGAIN) {
      return {
        ...vppConfig,
        status: StatusName.IN_PROGRESS,
      };
    }
    if (vppState === VppState.PERFORMED) {
      return {
        ...vppConfig,
        status: StatusName.DONE,
      };
    }
    return vppConfig;
  };

  return (
    <Container>
      {!submissionId && <ErrorBox errors={errors} />}

      <Loadable predicate={isLoading}>
        <RolloutLimiter countryFeatureName={CountryFeatureName.TUTORIAL_COMPONENTS}>
          <Tutorial
            id="setupConfigurator"
            dataTestId={TestId.Lead.SetupConfigurator.ShowGuideButton}
          />
        </RolloutLimiter>
        <PageBlock>
          {!isLeadInSetup ? (
            isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE) ? (
              <SetupToolInfo contentText={leadSectionFunctionalities} />
            ) : (
              <SetupToolInfo contentText={leadSectionFunctionalitiesNonSetup} />
            )
          ) : null}

          <ErrorBox errors={errors} />

          <SetupToolTable
            headline={I18n.t(preCommissioningTools)}
            description={I18n.t(preCommissioningToolsDescription)}
            isH1Headline={false}
            isCollapsed={
              isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)
                ? !isLeadInSetup
                : false
            }
            items={[stepList.preCommissioningConfig!]}
            dataTestId={TestId.Lead.SetupConfigurator.ToolsPreInstallationExpandBtn}
          />

          <RolloutLimiter countryFeatureName={CountryFeatureName.SETUP_PHASE}>
            <SetupToolTable
              headline={I18n.t(registrationTools)}
              description={I18n.t(registrationToolsDescription)}
              isH1Headline={true}
              additionalHeaderContent={<SetupToolRegistrationSelect />}
              items={[
                stepList.setupDataConfig!,
                stepList.dsoRegistrationConfig!,
                stepList.dsoCommissioningConfig!,
                stepList.pvRegisterConfig!,
              ]}
              dataTestId={TestId.Lead.SetupConfigurator.RegistrationToolsExpandButton}
            />

            {stepList.vppConfig && stepList.vppConfig.disabled && (
              <SetupToolInfo
                contentText={T.setupTool.infoBox.vppFunctionalities}
                hasCloseButton={false}
              />
            )}

            <SetupToolTable
              headline={I18n.t(sonnenFlatTools)}
              description={I18n.t(sonnenFlatToolsDescription)}
              isH1Headline={false}
              items={[getProcessedVppConfig()]}
              dataTestId={TestId.Lead.SetupConfigurator.SonnenFlatToolsExpandButton}
            />
          </RolloutLimiter>
        </PageBlock>

        <RolloutLimiter countryFeatureName={CountryFeatureName.SETUP_PHASE}>
          <SetupToolRegistrationSubjectConfirmationModal />
        </RolloutLimiter>
      </Loadable>
    </Container>
  );
};

export const SetupToolDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupToolDashboardComponent);
