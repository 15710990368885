import { LeadStatus } from '+shared/store/lead/types/leadStatus.interface';

export type LeadStatusSummary = LeadStatus['summary'];
export type LeadStatusKeys = keyof LeadStatusSummary;
export type LeadStatusValues = LeadStatusSummary[LeadStatusKeys];

export enum LeadStatusGroup {
  IGNORED = 'ignored',
  FLAT = 'flat',
  HARDWARE = 'hardware',
  COMMON = 'common',
  IN_SETUP = 'inSetup',
}
