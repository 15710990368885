import React from 'react';

import { BatteryTimeOfUseScheduleItem } from '+customer-battery/components/BatteryTimeOfUseSchedule/BatteryTimeOfUseScheduleList/BatteryTimeOfUseScheduleItem';
import { BatteryOperatingModeView } from '+customer-battery/containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import { TariffWindow } from '+customer-battery/store/battery-tariff';

interface Props {
  tariffWindows: TariffWindow[];
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
}

export const BatteryTimeOfUseScheduleList: React.FC<Props> = ({
  tariffWindows,
  setBatteryOperatingModeView,
}) => {
  return (
    <>
      {tariffWindows.map((tariffWindow: TariffWindow) => (
        <BatteryTimeOfUseScheduleItem
          key={tariffWindow.id}
          tariffWindow={tariffWindow}
          setBatteryOperatingModeView={setBatteryOperatingModeView}
        />
      ))}
    </>
  );
};
