import React from 'react';

import classNames from 'classnames';

import './index.scss';

interface Props {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
}

export const InputBox: React.FC<Props> = (props: Props) => {
  return (
    <input
      className={classNames('input-box', props.className)}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
};
