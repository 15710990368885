import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { getBattery } from '+app/+customer/+battery/store';
import { ROUTES } from '+app/router';
import { dataGuard, makeQuery, mapToState, matchPath, ofType, polling } from '+app/utils';
import { CustomerActions } from '+customer/store';
import { RouterActions } from '+router/store';
import { isLocationChangeFromPath } from '+router/store/router.selectors';
import { BatteryActions, BatteryWithProduct } from '+shared/store/battery';
import { BatteryRepository } from '+shared/store/battery/battery.repository';
import { LayoutActions } from '+shared/store/layout';
import { SiteActions } from '+shared/store/site';
import { getSiteLiveState } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';

import { PvSystemsActions } from './pvSystems.actions';
import {
  GET_BATTERY_POWER_METER_LIVE_STATES_QUERY,
  POST_BATTERY_PV_GRID_FEED_IN_LIMIT_POLLING_QUERY,
  POST_BATTERY_PV_GRID_FEED_IN_LIMIT_QUERY,
} from './pvSystems.state';

type Action$ = ActionsObservable<PvSystemsActions>;
type State$ = StateObservable<StoreState>;

export const ensureBatteryIdExists = (
  battery: BatteryWithProduct | undefined
): battery is BatteryWithProduct => {
  return !!battery && 'id' in battery;
};

const getBatteryPowerMeterLiveStates$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(BatteryActions.setBattery, SiteActions.setSiteLiveState),
    withLatestFrom(state$),
    mergeMap(([_, state]) =>
      of(state).pipe(
        matchPath(ROUTES.CUSTOMER_PV_SYSTEMS[0]),
        filter(() => !!getSiteLiveState(state)?.online),
        map(() => getBattery(state)),
        filter(ensureBatteryIdExists),
        map((battery) => ({
          batteryId: battery.id,
        })),
        map(({ batteryId }) =>
          PvSystemsActions.startBatteryPowerMeterPolling({
            queryKey: GET_BATTERY_POWER_METER_LIVE_STATES_QUERY,
            batteryId,
          })
        )
      )
    )
  );

const startBatteryPowerMeterLiveStatesPolling$ = (action$: Action$) =>
  action$.pipe(
    polling({
      startOn: PvSystemsActions.startBatteryPowerMeterPolling,
      stopOn: [PvSystemsActions.stopBatteryPowerMeterPolling, CustomerActions.clearCustomerData],
      interval: 5000,
    })(({ queryKey, batteryId }) =>
      makeQuery(queryKey)({
        call: () => BatteryRepository.getBatteryPowerMeterLiveStates(batteryId),
        onSuccess: (res) =>
          dataGuard(PvSystemsActions.setBatteryPowerMeterLiveStates)(res.elements),
      })
    )
  );

const stopBatteryPowerMeterLiveStatesPolling$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.locationChange),
    mapToState(state$),
    map(isLocationChangeFromPath(ROUTES.CUSTOMER_PV_SYSTEMS)),
    filter((isMatch) => !!isMatch),
    map(() => PvSystemsActions.stopBatteryPowerMeterPolling())
  );

const setBatteryPvGridFeedInLimit$ = (action$: Action$) =>
  action$.pipe(
    ofType(PvSystemsActions.setBatteryPvGridFeedInLimit),
    mergeMap(({ pvGridFeedInLimit, batteryId }) =>
      makeQuery(POST_BATTERY_PV_GRID_FEED_IN_LIMIT_QUERY)({
        call: () => BatteryRepository.postBatteryPvGridFeedInLimit(batteryId, pvGridFeedInLimit),
        onSuccess: () =>
          of(
            BatteryActions.startBatteryPolling({
              queryKey: POST_BATTERY_PV_GRID_FEED_IN_LIMIT_POLLING_QUERY,
              batteryId,
            })
          ),
        onFailure: () =>
          merge(
            of(PvSystemsActions.setIsBatteryPvGridFeedInLimitInProgress(false)),
            of(
              LayoutActions.enqueueNotification(
                I18n.t(
                  T.customerSingle.pvSystemsAndMeters.pvSystemOperations.pvFeedInOperation
                    .notifications.failed
                ),
                'error'
              )
            )
          ),
      })
    )
  );

export const epics = combineEpics(
  getBatteryPowerMeterLiveStates$,
  startBatteryPowerMeterLiveStatesPolling$,
  stopBatteryPowerMeterLiveStatesPolling$,
  setBatteryPvGridFeedInLimit$
);
