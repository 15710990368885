import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DefaultParagraph } from '@sonnen/shared-web';

import { Formik, FormikProps } from 'formik';
import { isObject } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { FormInputSelect } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, MainType } from '+shared/components/Button';
import {
  getCommonSubmitFields,
  getTechnicalCaseLabel,
  isEServices,
} from '+shared/components/CaseReportForm/CaseCommon.helper';
import {
  CaseFormField,
  CaseFormValues,
  initialValues,
  RecordTypeSupport,
} from '+shared/components/CaseReportForm/CaseCommon.types';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import { CaseActions } from '+shared/store/case';

import { SupportFormHelper } from './SupportForm.helper';

import './SupportForm.component.scss';

interface Props {
  submitForm: typeof CaseActions.createCase;
  isLoading: boolean;
  contactId: string;
  userCountry: CountryCode | undefined;
}

const { getSupportTypesComponentData, validationSchema } = SupportFormHelper;

export const SupportForm: React.FC<Props> = ({ submitForm, isLoading, contactId, userCountry }) => {
  const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');
  const recordTypesObject = getSupportTypesComponentData(userCountry);

  const onFormSubmit = (values: CaseFormValues) => {
    if (!values.recordType) return;

    const { recordType, description, category, subcategory } = values;

    const eServices = isEServices(recordType.key);
    const subject = `Partner Portal User support request: ${recordType.key}`;

    submitForm({
      recordType: eServices ? RecordTypeSupport.E_SERVICES : RecordTypeSupport.TECHNICAL,
      subject,
      description,
      contactId,
      ...getCommonSubmitFields(
        category,
        subcategory,
        recordType.key,
        isSpanishMarketEnabled,
        userCountry
      ),
    });
  };

  const clearField = (form: FormikProps<CaseFormValues>, field: CaseFormField) => {
    form.setFieldValue(field, '');
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={onFormSubmit}
      render={(form) => {
        const {
          values: { recordType, category },
        } = form;
        const eServices = isEServices(recordType?.key);
        const technicalCase = recordType?.key === RecordTypeSupport.TECHNICAL;
        const caseLabel = I18n.t(getTechnicalCaseLabel(technicalCase));

        const hint = eServices ? 'eServices' : technicalCase ? 'technology' : null;

        const recordTypes = Object.values(recordTypesObject);
        const categories = recordType ? recordTypesObject[recordType.key]?.categories : undefined;
        const subcategories = isObject(category) ? category.subcategories : undefined;

        return (
          <form onSubmit={form.handleSubmit}>
            <DefaultParagraph className={'c-support-form__title'}>
              {I18n.t(T.help.supportForm.title)}
            </DefaultParagraph>

            <FormInputSelect
              name={CaseFormField.RECORD_TYPE}
              form={form}
              collection={recordTypes}
              mapper={(recordType) => recordType.label}
              label={I18n.t(T.report.form.labels.type)}
              onSelect={() => clearField(form, CaseFormField.CATEGORY)}
            />

            <DefaultParagraph className="c-support-form__hint">
              {hint ? I18n.t(`${T.help.supportForm.fields.topicHints[hint]}`) : ''}
            </DefaultParagraph>

            {categories && (
              <div className="c-support-form__category">
                <FormInputSelect
                  name={CaseFormField.CATEGORY}
                  form={form}
                  className="c-case-report-form__topic"
                  collection={categories}
                  mapper={(category) => category.name}
                  label={caseLabel}
                  dataTestId="case-category-field"
                  onSelect={() => clearField(form, CaseFormField.SUBCATEGORY)}
                />
              </div>
            )}

            {subcategories && (
              <div className="c-support-form__category">
                <FormInputSelect
                  name={CaseFormField.SUBCATEGORY}
                  form={form}
                  collection={subcategories}
                  mapper={(category) => category.name}
                  label={I18n.t(T.report.form.labels.category)}
                />
              </div>
            )}

            <FormTextarea
              form={form}
              placeholder={I18n.t(T.help.supportForm.fields.messagePlaceholder)}
              name={CaseFormField.DESCRIPTION}
              dataTestId="case-description-field"
              label={I18n.t(T.help.supportForm.fields.message)}
            />

            <Button
              mainType={MainType.SUBMIT}
              loading={isLoading}
              size={ButtonSize.SMALL}
              label={I18n.t(T.help.supportForm.buttons.submit)}
              status={!form.isValid ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              disabled={!form.isValid}
            />
          </form>
        );
      }}
    />
  );
};
