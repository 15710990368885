import * as React from 'react';

export const useComponentWidth = (component: React.ReactText | JSX.Element | undefined) => {
  const [width, setWidth] = React.useState(0);
  React.useLayoutEffect(() => {
    setWidth(ref.current?.getBoundingClientRect().width || 0);
  }, [component]);

  const ref = React.createRef<HTMLSpanElement>();
  const componentWithRef = isJsxElement(component) ? (
    React.cloneElement(component, { ref })
  ) : (
    <span ref={ref}>{component || ''}</span>
  );
  return [width, componentWithRef] as const;
};

const isJsxElement = (
  component: React.ReactText | JSX.Element | undefined
): component is JSX.Element => {
  if (!component) {
    return false;
  }

  return typeof component !== 'string' && typeof component !== 'number';
};
