import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DefaultParagraph } from '@sonnen/shared-web';

import { FormStatus } from '+shared/components/FormStatus';

import { Button, ButtonSize, ButtonType } from '../Button';

import './CaseReportSuccess.component.scss';

interface Props {
  onClose: () => void;
  onMore?: () => void;
  caseNumber: string | undefined;
}

export const CaseReportSuccess: React.FC<Props> = ({ onClose, onMore, caseNumber }) => {
  return (
    <FormStatus
      headline={I18n.t(T.report.success.headline, { caseId: caseNumber })}
      isSuccess={true}
      footer={
        <div className={'c-case-report-success__buttons'}>
          {onMore && (
            <Button
              label={I18n.t(T.report.success.moreBtn)}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
              onClick={onMore}
            />
          )}
          <Button
            label={I18n.t(T.report.success.doneBtn)}
            size={ButtonSize.SMALL}
            onClick={onClose}
          />
        </div>
      }
    >
      <DefaultParagraph>{I18n.t(T.report.success.paragraph)}</DefaultParagraph>
    </FormStatus>
  );
};
