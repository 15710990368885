import React from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import uuid from 'uuid';

import { mapActions } from '+app/utils';
import { BatteryTimeOfUseForm } from '+customer-battery/components/BatteryTimeOfUseForm';
import {
  batteryTariffWindowSchema,
  convertData,
} from '+customer-battery/components/BatteryTimeOfUseForm/BatteryTimeOfUseForm.helper';
import { BatteryOperatingModeView } from '+customer-battery/containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import { getBatteryTariffWindows } from '+customer-battery/store';
import { TariffType, TariffWindow } from '+customer-battery/store/battery-tariff';
import { BatteryActions } from '+shared/store/battery';
import { StoreState } from '+shared/store/store.interface';

interface SimpleProps {
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
}

type Props = SimpleProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: StoreState) => ({
  tariffWindows: getBatteryTariffWindows(state),
});

const mapDispatchToProps = mapActions({
  newSingleBatteryTariffWindow: BatteryActions.newSingleBatteryTariffWindow,
});

const BatteryTimeOfUseNewComponent: React.FC<Props> = ({
  actions,
  setBatteryOperatingModeView,
  tariffWindows,
}) => {
  const submitHandler = (data: TariffWindow) => {
    actions.newSingleBatteryTariffWindow(convertData(data));
    setBatteryOperatingModeView(BatteryOperatingModeView.TIME_OF_USE_PREVIEW);
  };

  return (
    <Formik
      initialValues={{
        type: TariffType.OFF_PEAK,
        id: uuid.v4(),
        thresholdPMax: 0,
        start: '',
        stop: '',
        thresholdPMaxStatus: false,
        startHour: '',
        startMinutes: '',
        stopHour: '',
        stopMinutes: '',
      }}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={batteryTariffWindowSchema}
      onSubmit={submitHandler}
    >
      {(form) => (
        <>
          <BatteryTimeOfUseForm
            form={form}
            setBatteryOperatingModeView={setBatteryOperatingModeView}
            tariffWindows={tariffWindows}
          />
        </>
      )}
    </Formik>
  );
};

export const BatteryTimeOfUseNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryTimeOfUseNewComponent);
