import React from 'react';

type Props = {
  color?: string;
  size?: string | number;
  className?: string;
};

export const Arrow: React.FC<Props> = ({ color, size = 16, className }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.20217 5.73291C1.93261 5.45087 1.93261 4.99358 2.20217 4.71153C2.44478 4.45769 2.82306 4.43231 3.0928 4.63538L3.17836 4.71153L8 9.75667L12.8216 4.71153C13.0643 4.45769 13.4425 4.43231 13.7123 4.63538L13.7978 4.71153C14.0404 4.96537 14.0647 5.36116 13.8706 5.64339L13.7978 5.73291L8.48809 11.2885C8.24548 11.5423 7.86721 11.5677 7.59747 11.3646L7.51191 11.2885L2.20217 5.73291Z"
      fill={color}
    />
  </svg>
);
